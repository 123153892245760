import React, { useCallback, useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import _ from "lodash";

import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";
const ProtocolBoxWhiskerChart = ({
	data,
	tukeyData,
	tukeyRunning,
	axisYTitle,
	chartHeight,
	allTreatmentData,
	needToRerunStats,
	needToRerunNormalization,
	initialGroupTrialDataCheckedCount,
	isCompiled = undefined,
	allTreatmentsUnchecked = false,
	hideUncheckedTrialData = false,
	disableColors = false
}) => {
	const colors = !disableColors
		? [
				"#920000",
				"#006DDB",
				"#B66DFF",
				"#24FF24",
				"#FFFF6D",
				"#004949",
				"#009292",
				"#FF6DB6",
				"#FFB6DB",
				"#490092",
				"#924900",
				"#DB6D00",
				"#6DB6FF",
				"#B6DBFF",
				"#000000"
		  ]
		: ["#920000"];
	const shapes = ["circle"];
	let colorIdx = 0;
	let shapeIdx = 0;
	const CanvasJSChart = CanvasJSReact.CanvasJSChart;

	//-- Data
	const [chartOptions, setChartOptions] = useState(null);

	//-- UI Control
	const [chartLoading, setChartLoading] = useState(true);

	useEffect(() => {
		if (!chartOptions && data?.length > 0 && (tukeyData?.length > 0 || tukeyData === undefined)) {
			stageChartOptions();
		} else {
			stageChartOptionsCallback();
		}
	}, [data, tukeyData, disableColors]);

	const stageChartOptionsCallback = useCallback(
		_.throttle(() => {
			if (data?.length > 0 && (tukeyData?.length > 0 || tukeyData === undefined)) {
				stageChartOptions();
			} else if (data.length === 0) {
				setChartLoading(false);
			}
		}, 500),
		[data, tukeyData, disableColors]
	);

	const getNextMarker = () => {
		const marker = { color: colors[colorIdx], shape: shapes[shapeIdx] };
		if (colorIdx + 1 >= colors.length) {
			colorIdx = 0;
			//shapeIdx++;
		} else {
			colorIdx++;
		}
		return marker;
	};

	function stageChartOptions() {
		let scatterPlots = {};
		let unGroupedScatterPlots = [];
		let boxWhiskerData = [];
		let isAnyTreatmentDeselected = false;
		let tempInitialGroupTrialDataCheckedCount = initialGroupTrialDataCheckedCount ?? [];

		let groupedTreatments = _.groupBy(data, "trialTreatmentId");
		let uniqueGroupedTreatments = _.uniqBy(data, "trialTreatmentId");
		let uniqueTreatmentsList = _.map(uniqueGroupedTreatments, (ugt) => {
			return ugt.trialTreatmentId;
		});
		let uniqueGroupedTukeyTreatments = _.uniqBy(tukeyData, "trialTreatmentId");
		let tukeyDataList = _.map(uniqueGroupedTukeyTreatments, (ugt) => {
			return ugt.trialTreatmentId;
		});
		let uniqueAllGroupedTreatments = allTreatmentData
			? _.uniqBy(allTreatmentData, "trialTreatmentId")
			: uniqueGroupedTreatments;
		let checkedTreatmentCountUpdated = _.map(uniqueAllGroupedTreatments, (d) => {
			return d.treatmentChecked;
		});

		let treatmentIds = [];
		_.filter(tempInitialGroupTrialDataCheckedCount, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});

		//Logic to hide the auc letters when treatments are unchecked
		if (
			!_.isEqual(tempInitialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
			tempInitialGroupTrialDataCheckedCount?.length !== 0
		) {
			isAnyTreatmentDeselected = true;
		} else if (initialGroupTrialDataCheckedCount?.length === 0) {
			if (
				_.some(uniqueGroupedTreatments, (data) => !data.treatmentChecked) ||
				_.some(checkedTreatmentCountUpdated, (ctcu) => ctcu === false)
			) {
				isAnyTreatmentDeselected = true;
			} else {
				isAnyTreatmentDeselected = false;
			}
		} else {
			isAnyTreatmentDeselected = false;
		}

		_.map(uniqueAllGroupedTreatments, (utr) => {
			if (
				utr.treatmentChecked &&
				(_.isEmpty(tukeyDataList)
					? uniqueTreatmentsList.includes(utr.trialTreatmentId)
					: tukeyDataList.includes(utr.trialTreatmentId)) &&
				!needToRerunNormalization &&
				!allTreatmentsUnchecked &&
				!treatmentIds.includes(utr.trialTreatmentId) &&
				(_.some(utr.plots, (p) => p.plotId) || utr.plotName)
			) {
				let areas = [];
				_.map(groupedTreatments[utr.trialTreatmentId], (treatment) => {
					areas.push(treatment.average);
					if (isCompiled) {
						unGroupedScatterPlots.push({
							x: treatment.trialTreatmentId,
							y: treatment.average,
							plotName: treatment.plotName ?? _.map(treatment.plots, "plotName").join(", "),
							trialName: treatment.trialName,
							trialId: treatment.trialId,
							city: treatment.city,
							stateName: treatment.stateName,
							cooperatorName: treatment.cooperatorName ?? "Unknown Cooperator",
							rep: treatment.plotReplicate ?? 0
						});
					} else {
						unGroupedScatterPlots.push({
							x: treatment.trialTreatmentId,
							y: treatment.average,
							plotName: treatment.plotName,
							rep: treatment.plotReplicate
						});
					}
				});

				if (areas.length > 0) {
					const median = Number(medianBy(_.map(groupedTreatments[utr.trialTreatmentId], "average")).toFixed(5));
					const lowerValues = getValuesAboveOrBelowMedian(areas, median, false);
					const higherValues = getValuesAboveOrBelowMedian(areas, median, true);
					const q1 = Number(medianBy(lowerValues).toFixed(5));
					const q3 = Number(medianBy(higherValues).toFixed(5));
					let min = Number(Math.min(...areas).toFixed(5));
					let max = Number(Math.max(...areas).toFixed(5));
					boxWhiskerData.push({
						label: utr.trialTreatmentId,
						x: utr.trialTreatmentId,
						y: [min, q1, q3, max, median]
					});
				}
			} else {
				boxWhiskerData.push({
					label: utr.trialTreatmentId,
					x: utr.trialTreatmentId,
					y: null
				});
			}
		});

		if (isCompiled) {
			scatterPlots = _.groupBy(unGroupedScatterPlots, "trialId");
		} else {
			scatterPlots = _.groupBy(unGroupedScatterPlots, "rep");
		}

		let tukeyValues = [];
		if (tukeyData && tukeyData.length > 0) {
			_.map(tukeyData, ({ trialTreatmentId, tukeyLetters }) => {
				tukeyValues.push({
					label:
						!needToRerunStats && !needToRerunNormalization && !hideUncheckedTrialData && !allTreatmentsUnchecked
							? tukeyLetters
							: " ",
					y: null,
					x: trialTreatmentId
				});
			});

			let allLettersAreA = _.every(tukeyValues, ["label", "a"]);
			if (allLettersAreA) {
				_.map(tukeyValues, (tv) => {
					tv.label = "-";
				});
			}

			tukeyValues = _.uniqBy(tukeyValues, "x");
			let usedTreatments = _.map(tukeyValues, "x");

			_.map(uniqueAllGroupedTreatments, (utr) => {
				if (!usedTreatments.includes(utr.trialTreatmentId)) {
					tukeyValues.push({
						label: " ",
						y: null,
						x: utr.trialTreatmentId
					});
				}
			});

			_.map(usedTreatments, (utr) => {
				if (
					!uniqueTreatmentsList.includes(utr) ||
					!_.find(uniqueAllGroupedTreatments, (uat) => {
						return uat.trialTreatmentId === utr;
					})?.treatmentChecked ||
					isAnyTreatmentDeselected
				) {
					let val = _.find(tukeyValues, (tv) => {
						return tv.x === utr;
					});
					val.label = " ";
					val.y = null;
				}
			});

			tukeyValues = _.map(_.sortBy(tukeyValues, "x"), (tv) => {
				return {
					label: tv.label,
					y: null
				};
			});
		}

		let opt = {
			animationEnabled: true,
			theme: "light2",
			legend: {
				horizontalAlign: "right",
				fontSize: 12
			},
			axisY: {
				title: axisYTitle,
				titleFontWeight: "bold",
				includeZero: false
			},
			axisX: {
				title: "Treatment",
				titeFontSize: 20,
				includeZero: false,
				interval: 1
			},
			axisX2: {
				includeZero: false,
				interval: 1,
				labelAngle: 90
			},
			height: chartHeight ?? 400,
			responsive: false,
			data: [
				{
					type: "boxAndWhisker",
					color: "black",
					upperBoxColor: "#DAE3F3",
					lowerBoxColor: "#67A2DB",
					indexLabelFontSize: 16,
					dataPoints: boxWhiskerData
				},
				{
					axisXType: "secondary",
					type: "line",
					color: "#00000000",
					showInLegend: !disableColors,
					name: isCompiled ? "Cooperators" : "Replicates",
					dataPoints: tukeyValues
				}
			]
		};

		// let keys = Object.entries(scatterPlots);

		for (let [i, sp] of Object.entries(scatterPlots)) {
			const marker = getNextMarker();

			let toolTip = "";
			if (isCompiled === "averages") {
				toolTip = `<b>{trialName}</b> <br/><b>{city}, {stateName}</b> <br/><b>Treatment: </b>{x} <br/><b>Avg. Value: </b>{y}`;
			} else if (isCompiled === "allReps") {
				toolTip = `<b>{trialName}</b> <br/><b>{city}, {stateName}</b> <br/><b>Plot: </b>{plotName} <br/><b>Treatment: </b>{x} <br/><b>Replicate: </b>{rep} <br/><b>Value: </b>{y}`;
			} else {
				toolTip = `<b>Plot: </b>{plotName} <br/><b>Treatment: </b>{x} <br/><b>Replicate: </b>{rep} <br/><b>Value: </b>{y}`;
			}

			opt.data.push({
				type: "scatter",
				color: marker.color,
				markerType: marker.shape,
				markerBorderColor: "black",
				markerBorderThickness: 1,
				name: `${isCompiled ? sp[0].cooperatorName : i}`,
				toolTipContent: toolTip,
				showInLegend: !disableColors,
				dataPoints: sp
			});
		}

		if (!tukeyRunning && tukeyValues.length > 0) {
			opt.data.push({
				axisXType: "secondary",
				type: "line",
				dataPoints: tukeyValues
			});
		}

		setChartOptions(opt);
		setChartLoading(false);
	}

	function medianBy(array) {
		array.sort((a, b) => b - a);
		const length = array.length;
		if (length % 2 == 0) {
			return (array[length / 2] + array[length / 2 - 1]) / 2;
		} else {
			return array[Math.floor(length / 2)];
		}
	}

	function getValuesAboveOrBelowMedian(values, median, aboveMedian) {
		if (!values || values.length === 0) return 0;

		values.sort(function (a, b) {
			return a - b;
		});

		var half = Math.floor(values.length / 2);

		if (values.length % 2) {
			return aboveMedian ? values.slice(half + 1, values.length) : values.slice(0, half);
		}

		//-- This should only happen with data normalization CONTROL, GSP, or COMMERCIAL
		let allValuesAreTheSame = _.every(values, (v) => {
			return Number(v).toFixed(5) === Number(median).toFixed(5);
		});
		if (allValuesAreTheSame) {
			return [median];
		}

		return aboveMedian
			? _.filter(values, (value) => {
					return value > median;
			  })
			: _.filter(values, (value) => {
					return value < median;
			  });
	}
	return (
		<Segment basic loading={chartLoading}>
			<CanvasJSChart
				options={chartOptions}
				containerProps={{ width: "100%", position: "relative", height: chartHeight + "px" }}
			/>
		</Segment>
	);
};

ProtocolBoxWhiskerChart.propTypes = {
	data: PropTypes.array,
	tukeyData: PropTypes.array,
	tukeyRunning: PropTypes.bool,
	selectedAssessmentOption: PropTypes.string,
	axisYTitle: PropTypes.string,
	chartHeight: PropTypes.number,
	allTreatmentData: PropTypes.array,
	needToRerunStats: PropTypes.bool,
	needToRerunNormalization: PropTypes.bool,
	initialGroupTrialDataCheckedCount: PropTypes.array,
	allTreatmentsUnchecked: PropTypes.bool,
	hideUncheckedTrialData: PropTypes.bool,
	isCompiled: PropTypes.oneOf(["averages", "allReps", undefined]),
	disableColors: PropTypes.bool
};

export default ProtocolBoxWhiskerChart;
