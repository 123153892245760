import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function flightElevationOrthoUploadReducer(state = initialState.flightElevationOrthoUploads, action) {
	switch (action.type) {
		case types.ORTHO_ADD_FLIGHT_ELEVATION_ORTHO_UPLOAD: {
			let newArray = state.slice();
			newArray.splice(state.length, 0, action.orthoToAdd);
			return newArray;
		}
		case types.ORTHO_UPDATE_UPLOADING_ELEVATION_ORTHO_PROGRESS: {
			return state.map((x) => {
				if (x.flightId === action.orthoToUpdate.flightId) {
					return {
						...x,
						...action.orthoToUpdate
					};
				}
				return x;
			});
		}
		case types.ORTHO_REMOVE_ELEVATION_ORTHO_UPLOAD: {
			return state.filter((x) => x.flightId !== action.flightIdToRemove);
		}
		default:
			return state;
	}
}
