import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Form, Grid, Loader, Segment, Table } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import * as trialActions from "../../../../redux/actions/trialActions";
import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";
import * as editTrialAuth from "../EditTrial/editTrialAuth";
import TreatmentCSVUpload from "../../../Lumber/TreatmentCSVUpload";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";

type CSVTreatment = {
	description?: string;
	name?: string;
	type?: string;
	trialTreatmentId: number;
};

type Treatment = {
	treatmentDescription?: string;
	treatmentId: string;
	treatmentName?: string;
	treatmentTypeDescription?: string;
	treatmentTypeId?: string;
	treatmentTypeName?: string;
	trialTreatmentId: number;
};

type TreatmentTypeOption = {
	key: string;
	value: string;
	text: string;
};

type ValidationMessage = {
	id: string;
	name: boolean;
	description: boolean;
	message: string;
};

type Validation = {
	isValid: boolean;
	treatments: { messages: ValidationMessage[] };
	messages: string[];
};

type Props = {
	trialId: string;
	trialName: string;
	trialDetails: string;
	initTreatments: Treatment[];
	navigateToNextStep: () => void;
	navigateToPreviousStep: () => void;
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	setSaveAction: React.Dispatch<React.SetStateAction<() => Promise<any>>>;
};

const TrialTreatments: FC<Props> = ({
	trialId,
	trialName,
	trialDetails,
	initTreatments,
	navigateToNextStep,
	navigateToPreviousStep,
	setIsDirty,
	setSaveAction
}) => {
	const userAuth = useUserAuth();
	const userSettings = useUserSettings();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [defaultTrialTypeId, setDefaultTrialTypeId] = useState<string>();

	const [treatments, setTreatmentsSilently] = useState(initTreatments ?? []);
	// const [treatmentsAreVaild, setTreatmentsArevValid] = useState();
	// const [test, setTest] = useState<TreatmentTypeOption>();
	const [treatmentTypeOptions, setTreatmentTypeOptions] = useState<TreatmentTypeOption[]>([]);

	const save = useCallback(async () => {
		setIsSaving(true);

		const validation = validateTreatments();
		const treatmentsToSave = treatments.reduce((acc, tr) => {
			if (tr.treatmentTypeId === null) {
				tr.treatmentTypeId = defaultTrialTypeId;
			}

			// if (!!tr.treatmentName?.trim()) {
			acc.push(tr);
			// } else {
			// 	ignoredBlank = true;
			// }

			return acc;
		}, [] as Treatment[]);

		if (!validation.isValid) {
			setIsSaving(true);
			console.log(validation);
			toast.error("Failed to save.");
			// for (let message of validation.treatments.messages) {
			// 	toast.error(message);
			// }
			return false;
		}

		try {
			const accessToken = await getTokenSilently();
			await dispatch(trialActions.updateTrialTreatments(userAuth.currentClientId, treatmentsToSave, accessToken));
			setIsSaving(false);
			setIsDirty(false);
			toast.success("Treatments saved successfully.");
			return true;
		} catch (err) {
			setIsSaving(false);
			console.error(err);
			toast.error("Trial Treatments failed to save.");
			return false;
		}
	}, [treatments, userAuth.currentClientId]);

	useEffect(() => {
		if (userAuth.currentClientId && moduleNavigation.trialId) {
			getTrialOptions(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		setSaveAction(() => save);
	}, [save]);

	const setTreatments = (tmnts) => {
		setIsDirty(true);
		setTreatmentsSilently(tmnts);
	};

	const getTrialOptions = async (clientId) => {
		const accessToken = await getTokenSilently();
		setLoading(true);
		trialActions
			.getTrialOptions(
				clientId,
				moduleNavigation.trialId,
				accessToken
			)(dispatch)
			.then((res) => {
				initializeTreatmentTypeOptions(res.treatmentTypes);
				setLoading(false);
			})
			.catch((err) => {
				toast.error("Error loading trial options.");
				console.log(err);
				setLoading(false);
			});
	};

	const initializeTreatmentTypeOptions = (treatmentTypes) => {
		const treatmentTypeOpts = _.map(treatmentTypes, (tt) => {
			if (tt.name === "Test") {
				setDefaultTrialTypeId(tt.id);
			}
			const opt = {
				key: tt.id,
				value: tt.id,
				text: tt.name
			};
			return opt;
		});
		setTreatmentTypeOptions(treatmentTypeOpts);
	};

	const validateTreatments = useCallback(() => {
		let validation: Validation = {
			isValid: true,
			treatments: { messages: [] },
			messages: []
		};

		treatments.forEach((treatment) => {
			if (treatment.treatmentName && treatment.treatmentName.length > 50) {
				validation.treatments.messages.push({
					id: treatment.treatmentId,
					name: true,
					description: false,
					message: "Treatment Name cannot be more than 50 characters."
				});
				validation.isValid = false;
			}

			if (treatment.treatmentDescription && treatment.treatmentDescription.length > 200) {
				if (validation.treatments.messages.length === 0) {
					validation.messages.push("Treatment errors were found. Please review the reatment list.");
				}

				const err = {
					id: treatment.treatmentId,
					name: false,
					description: true,
					message: "Treatment Description cannot be more than 200 characters."
				};
				validation.treatments.messages.push(err);
				validation.isValid = false;
			}
		});

		return validation;
	}, []);

	// const saveTreatments = async (treatments) => {
	// 	const treatmentValidation = validateTreatments();
	// 	const accessToken = await getTokenSilently();

	// 	if (!treatmentValidation.isValid) {
	// 		window.scrollTo(0, 0);
	// 		return;
	// 	}

	// 	setIsSaving(true);

	// 	try {
	// 		const res = await dispatch(trialActions.updateTrialTreatments(userAuth.currentClientId, treatments, accessToken));

	// 		if (res.statusCode === 200) {
	// 			toast.success("Treatments saved successfully");
	// 		} else if (res.statusCode === 400) {
	// 			if (res.messages && res.messages.length > 0) {
	// 				res.messages.forEach((m) => {
	// 					if (m.type === "Informational") {
	// 						toast.info(m.text);
	// 					} else if (m.type === "FailedValidation") {
	// 						toast.error(m.text);
	// 					}
	// 				});
	// 			}
	// 		}
	// 	} catch (error) {
	// 		if (error && error.errors) {
	// 			error.errors.forEach((e) => toast.error(e));
	// 		} else {
	// 			toast.error("Trial failed to update");
	// 		}
	// 	} finally {
	// 		setIsSaving(false);
	// 		setIsTreatmentDirty(false);
	// 	}
	// };

	function saveTreatmentsFromValidCSV(results: CSVTreatment[]) {
		const tmnts = results.map((r, idx) => {
			const treatment = treatments.find((t) => t.trialTreatmentId === idx + 1) as Treatment;
			treatment.treatmentName = r.name;
			const typeId = r.type
				? treatmentTypeOptions.find((tto) => tto.text.toUpperCase() === r.type!.toUpperCase())?.value
				: undefined;
			treatment.treatmentTypeId = typeId;
			treatment.treatmentDescription = r.description;
			return treatment;
		});

		setTreatments([...tmnts]);

		save();
	}

	function clearTreatments() {
		const newTreatments = produce(treatments, (draft) => {
			draft = draft.map((_r, idx) => {
				const treatment = draft.find((t) => t.trialTreatmentId === idx + 1) as WritableDraft<Treatment>;
				treatment.treatmentName = "";
				treatment.treatmentTypeId = undefined;
				treatment.treatmentDescription = "";
				return treatment;
			});
		});

		setTreatments(newTreatments);
	}

	return loading ? (
		<Loader active />
	) : (
		<>
			<Segment basic style={{ minHeight: 50 }}>
				<div style={{ display: "flex", alignItems: "end", overflow: "hidden" }}>
					<Button
						style={{ marginRight: 10 }}
						color="black"
						content="Back"
						onClick={() => {
							navigateToPreviousStep();
						}}
					/>

					<h2 style={{ margin: 0 }}>Treatments</h2>

					<div style={{ flexGrow: 1, minWidth: 10 }} />

					<TrialFavoritingWidget
						style={{ display: "inline", alignSelf: "center" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						trialId={trialId.toUpperCase()}
						userSettings={userSettings}
					></TrialFavoritingWidget>
					<h2
						style={{
							margin: 0,
							marginRight: 10,
							color: "rgba(7, 55, 99, 0.75)",
							textOverflow: "ellipsis",
							overflow: "hidden"
						}}
						title={`${trialName} (${trialDetails})`}
					>
						<i>
							{trialName} ({trialDetails})
						</i>
					</h2>

					<Button
						id="form-button-continue"
						color="green"
						content="Save and Continue"
						loading={loading || isSaving}
						disabled={loading || isSaving}
						onClick={() =>
							save().then((res) => {
								if (res) navigateToNextStep();
							})
						}
					/>
					<Button
						id="form-button-save"
						primary
						content="Save"
						loading={loading || isSaving}
						disabled={loading || isSaving}
						onClick={() => save()}
					/>
				</div>

				<hr style={{ clear: "both" }} />
			</Segment>
			<Segment basic style={{ marginTop: 0 }}>
				<Form>
					<Grid>
						<Grid.Row style={{ padding: 0 }}>
							<Grid.Column>
								<TreatmentCSVUpload
									hasImportedData={true}
									onAcceptCSV={saveTreatmentsFromValidCSV}
									onClearTreatments={clearTreatments}
									context="trial"
									trialData={{
										id: trialId,
										name: trialName,
										treatmentCount: treatments.length,
										clientId: userAuth.currentClientId,
										context: "trial"
									}}
									currentData={(() => {
										return treatments?.map((t) => ({
											Treatment: t.trialTreatmentId,
											Name: t.treatmentName,
											Type: treatmentTypeOptions?.find((tto) => tto.value === t.treatmentTypeId)?.text,
											Description: t.treatmentDescription
										}));
									})()}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column style={{ maxHeight: 500, overflowY: "auto" }}>
								<Table
									id="form-table-treatment-listing"
									celled
									striped
									sortable
									selectable
									color="blue"
									textAlign="center"
								>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={1}>Treatment</Table.HeaderCell>
											<Table.HeaderCell width={7}>Name</Table.HeaderCell>
											<Table.HeaderCell width={2}>Type</Table.HeaderCell>
											<Table.HeaderCell width={6}>Description</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{treatments.map(
											({ treatmentId, trialTreatmentId, treatmentName, treatmentTypeId, treatmentDescription }) => {
												return (
													<Table.Row key={treatmentId}>
														<Table.Cell>{trialTreatmentId}</Table.Cell>
														<Table.Cell>
															<Form.Input
																fluid
																key={treatmentId}
																id={`form-input-treatment-name-${treatmentId}`}
																value={treatmentName ?? ""}
																onChange={(e) => {
																	const newTreatments = _.map(treatments, (tr) => {
																		if (tr.treatmentId === treatmentId) {
																			return { ...tr, treatmentName: e.target.value };
																		}
																		return tr;
																	});
																	setTreatments(newTreatments);
																}}
																// error={!!trialValidation?.treatments?.messages.find((t) => t.id === treatmentId && t.name)}
																readOnly={!editTrialAuth.userCanEditTreatments(userAuth)}
																style={{ opacity: 1, color: "#000000" }}
															/>
														</Table.Cell>
														<Table.Cell>
															<Form.Select
																fluid
																id={`form-select-treatment-type-${treatmentId}`}
																options={treatmentTypeOptions}
																value={treatmentTypeId ?? undefined}
																placeholder="Select"
																onChange={(_e, { value }) => {
																	const newTreatments = _.map(treatments, (tr) => {
																		if (tr.treatmentId === treatmentId) {
																			return { ...tr, treatmentTypeId: value as string };
																		}
																		return tr;
																	});
																	setTreatments(newTreatments);
																}}
																loading={!treatmentTypeOptions || treatmentTypeOptions.length === 0}
																disabled={!editTrialAuth.userCanEditTreatments(userAuth)}
																style={{ opacity: 1, color: "#000000" }}
															/>
														</Table.Cell>
														<Table.Cell>
															<Form.Input
																fluid
																key={treatmentId}
																id={`form-input-treatment-description-${treatmentId}`}
																value={treatmentDescription ?? ""}
																onChange={(e) => {
																	const newTreatments = _.map(treatments, (tr) => {
																		if (tr.treatmentId === treatmentId) {
																			return { ...tr, treatmentDescription: e.target.value };
																		}
																		return tr;
																	});
																	setTreatments(newTreatments);
																}}
																// error={
																// 	!!trialValidation?.treatments?.messages.find((t) => t.id === treatmentId && t.description)
																// }
																readOnly={!editTrialAuth.userCanEditTreatments(userAuth)}
																style={{ opacity: 1, color: "#000000" }}
															/>
														</Table.Cell>
													</Table.Row>
												);
											}
										)}
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Segment>
		</>
	);
};

export default TrialTreatments;
