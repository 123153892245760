import React, { useState } from "react";

import { Form, Table, Segment, Pagination, Dropdown, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import Tooltip from "rc-tooltip";

import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";
import ProtocolFavoritingWidget from "../Widgets/Favorites/ProtocolFavoritingWidget";
import { useUserSettings } from "../../../hooks/useUserSettings";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";

const ProtocolSearchResults = ({ protocolList }) => {
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const userSettings = useUserSettings();

	//-- DataSource
	const [protocols, setProtocols] = useState([]);

	//-- Protocol Log Table
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages] = useState(1);

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			setProtocols(
				_.sortBy(protocols, [(p) => (p[clickedColumn] ? p[clickedColumn].toLowerCase() : p[clickedColumn])])
			);
			setSortDirection("ascending");

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setProtocols(protocols.reverse());
		setProtocols(protocols);
	}

	return (
		<Segment basic>
			<Form>
				<Table celled striped sortable selectable color="blue">
					<Table.Header>
						<Table.Row textAlign="center">
							<Table.HeaderCell
								sorted={sortColumn === "protocolName" ? sortDirection : null}
								onClick={() => handleSort("protocolName")}
							>
								Protocol Name
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "clientName" ? sortDirection : null}
								onClick={() => handleSort("clientName")}
							>
								Client
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "locations" ? sortDirection : null}
								onClick={() => handleSort("locations")}
							>
								Locations
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "crops" ? sortDirection : null}
								onClick={() => handleSort("crops")}
							>
								Crop
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "growingSeason" ? sortDirection : null}
								onClick={() => handleSort("growingSeason")}
							>
								Growing Season
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "owner" ? sortDirection : null}
								onClick={() => handleSort("owner")}
							>
								Owner
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "variablesTested" ? sortDirection : null}
								onClick={() => handleSort("variablesTested")}
							>
								Variables Tested
							</Table.HeaderCell>
							<Table.HeaderCell>Actions</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(protocolList, (p) => {
							return (
								<Table.Row key={p.id}>
									<Table.Cell>
										{userAuth.hasApplicationArea(ApplicationArea.ProtocolSearch, p.clientId) ? (
											<Link to={moduleNavigation.createProtocolLink(true, p.id, "overview-status")}>
												{p.protocolName}
											</Link>
										) : (
											p.protocolName
										)}
									</Table.Cell>
									<Table.Cell>{p.clientName}</Table.Cell>
									<Table.Cell>{p.geographyName}</Table.Cell>
									<Table.Cell>{p.cropName}</Table.Cell>
									<Table.Cell>{p.growingSeasonName}</Table.Cell>
									<Table.Cell>{p.owner}</Table.Cell>
									<Table.Cell>
										{_.map(
											_.filter(p?.protocolVariables, (pv) => pv.checked),
											(protocolVariables, index) => {
												return <div key={index}>{protocolVariables.name}</div>;
											}
										)}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{userAuth.hasApplicationArea(ApplicationArea.ProtocolSearch, p.clientId) && (
											<>
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Edit Protocol</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Link to={moduleNavigation.createProtocolLink(false, p.id, "info", null)}>
														<Icon.Group>
															<Icon name="folder" corner="top left" style={{ fontSize: 15, textShadow: "unset" }} />
															<Icon name="pencil" corner="bottom right" style={{ fontSize: 8 }} />
														</Icon.Group>
													</Link>
												</Tooltip>
												<span style={{ display: "inline-block", width: 7 }} />
											</>
										)}
										{userAuth.hasApplicationArea(ApplicationArea.ProtocolOverview, p.clientId) ? (
											<>
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Overview and Status</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Link to={moduleNavigation.createProtocolLink(false, p.id, "overview-status", null)}>
														<Icon.Group>
															<Icon name="dashboard" corner="top left" style={{ fontSize: 15, textShadow: "unset" }} />
														</Icon.Group>
													</Link>
												</Tooltip>
												<span style={{ display: "inline-block", width: 7 }} />
											</>
										) : null}
										{userAuth.hasApplicationArea(ApplicationArea.ProtocolAnalysis, p.clientId) ? (
											<>
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Analysis</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Link to={moduleNavigation.createProtocolLink(false, p.id, "analysis", null)}>
														<Icon.Group>
															<Icon
																name="chart bar outline"
																corner="top left"
																style={{ fontSize: 15, textShadow: "unset" }}
															/>
														</Icon.Group>
													</Link>
												</Tooltip>
											</>
										) : null}

										<ProtocolFavoritingWidget
											key={p.id}
											clientId={userAuth.currentClientId}
											protocolId={p.id}
											userSettings={userSettings}
											displayMode={"table"}
										></ProtocolFavoritingWidget>
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row textAlign="right">
							<Table.HeaderCell colSpan="11">
								<span style={{ marginRight: 10 }}>
									{protocolList?.length === 1
										? `${protocolList?.length} protocols found`
										: `${protocolList?.length} protocols found`}
								</span>
								<Pagination
									onPageChange={(e, pageInfo) => {
										setActivePage(pageInfo.activePage);
									}}
									boundaryRange={5}
									siblingRange={1}
									totalPages={totalPages}
									activePage={activePage}
								/>
								<Dropdown
									onChange={(e, { value }) => {
										setItemsPerPage(value);
									}}
									selection
									options={[
										{ key: 1, text: 5, value: 5 },
										{ key: 2, text: 10, value: 10 },
										{ key: 3, text: 15, value: 15 },
										{ key: 4, text: 25, value: 25 },
										{ key: 5, text: 50, value: 50 }
									]}
									value={itemsPerPage}
									compact
									style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
								/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Form>
		</Segment>
	);
};

ProtocolSearchResults.propTypes = {
	protocolList: PropTypes.array.isRequired
};

export default ProtocolSearchResults;
