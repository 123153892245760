const autoAlignApiUri = process.env.aerialPlotConnections.aerialAutoAlignApi.baseUrl;
const aerialPlotApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function createFlightsQuantifiedRegions(plotData, accessToken, fieldQuantifiedRegionIds = []) {
	const qrFormData = new FormData();

	let index = 0;
	for (let plot of plotData) {
		qrFormData.append(`PlotData[${index}].Id`, plot.id);
		qrFormData.append(`PlotData[${index}].FlightId`, plot.flightId);
		qrFormData.append(`PlotData[${index}].FieldId`, plot.fieldId);
		qrFormData.append(`PlotData[${index}].ScaleFactor`, plot.scaleFactor);
		qrFormData.append(`PlotData[${index}].FieldRangeLength`, plot.fieldRangeLength);
		qrFormData.append(`PlotData[${index}].FieldColumnWidth`, plot.fieldColWidth);
		qrFormData.append(`PlotData[${index}].RowSpacing`, plot.rowSpacing);
		qrFormData.append(`PlotData[${index}].RowCount`, plot.rowCount);
		qrFormData.append(`PlotData[${index}].PlanterOffset`, plot.planterOffset);
		qrFormData.append(`PlotData[${index}].LL.Lat`, plot.ll.lat);
		qrFormData.append(`PlotData[${index}].LL.Long`, plot.ll.long);
		qrFormData.append(`PlotData[${index}].UL.Lat`, plot.ul.lat);
		qrFormData.append(`PlotData[${index}].UL.Long`, plot.ul.long);
		qrFormData.append(`PlotData[${index}].LR.Lat`, plot.lr.lat);
		qrFormData.append(`PlotData[${index}].LR.Long`, plot.lr.long);
		qrFormData.append(`PlotData[${index}].UR.Lat`, plot.ur.lat);
		qrFormData.append(`PlotData[${index}].UR.Long`, plot.ur.long);

		index++;
	}

	if (fieldQuantifiedRegionIds.length > 0) {
		index = 0;
		for (let id of fieldQuantifiedRegionIds) {
			qrFormData.append(`FieldQuantifiedRegionId[${index}]`, id);

			index++;
		}
	}

	return fetch(`${autoAlignApiUri}createFlightsQuantifiedRegions`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: qrFormData
	})
		.then((res) => res)
		.then((data) => data);
}

export function updateFlightsQuantifiedRegions(plotData, accessToken) {
	const qrFormData = new FormData();

	let index = 0;
	for (let plot of plotData) {
		qrFormData.append(`PlotData[${index}].Id`, plot.id);
		qrFormData.append(`PlotData[${index}].FlightId`, plot.flightId);
		qrFormData.append(`PlotData[${index}].FieldId`, plot.fieldId);
		qrFormData.append(`PlotData[${index}].ScaleFactor`, plot.scaleFactor);
		qrFormData.append(`PlotData[${index}].FieldRangeLength`, plot.fieldRangeLength);
		qrFormData.append(`PlotData[${index}].FieldColumnWidth`, plot.fieldColWidth);
		qrFormData.append(`PlotData[${index}].RowSpacing`, plot.rowSpacing);
		qrFormData.append(`PlotData[${index}].RowCount`, plot.rowCount);
		qrFormData.append(`PlotData[${index}].PlanterOffset`, plot.planterOffset);
		qrFormData.append(`PlotData[${index}].LL.Lat`, plot.ll.lat);
		qrFormData.append(`PlotData[${index}].LL.Long`, plot.ll.long);
		qrFormData.append(`PlotData[${index}].UL.Lat`, plot.ul.lat);
		qrFormData.append(`PlotData[${index}].UL.Long`, plot.ul.long);
		qrFormData.append(`PlotData[${index}].LR.Lat`, plot.lr.lat);
		qrFormData.append(`PlotData[${index}].LR.Long`, plot.lr.long);
		qrFormData.append(`PlotData[${index}].UR.Lat`, plot.ur.lat);
		qrFormData.append(`PlotData[${index}].UR.Long`, plot.ur.long);

		index++;
	}

	return fetch(`${autoAlignApiUri}updateFlightsQuantifiedRegions`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: qrFormData
	})
		.then((res) => res)
		.then((data) => data);
}

export function checkIfFlightsQuantifiedRegionsExist(flightId, clientId, accessToken) {
	return fetch(
		`${aerialPlotApiUri}api/v1/QuantifiedRegions/CheckFlightsQuantifiedRegions?flightId=${flightId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}
