import React from "react";
import { Segment, Checkbox, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";

import _ from "lodash";

const PianoChartLocationSelector = ({ locations, updateSelectedLocation }) => {
	return (
		<Segment basic>
			<Grid>
				<label>Location</label>
				{_.map(locations, (l) => {
					return (
						<Grid.Row key={l.trialId}>
							<Checkbox
								label={`${l.city}, ${l.stateName}`}
								checked={l.checked}
								onChange={(event, { checked }) => {
									updateSelectedLocation(l.trialId, checked);
								}}
							/>
						</Grid.Row>
					);
				})}
			</Grid>
		</Segment>
	);
};

PianoChartLocationSelector.propTypes = {
	locations: PropTypes.array,
	updateSelectedLocation: PropTypes.func
};

export default PianoChartLocationSelector;
