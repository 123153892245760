import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import _ from "lodash";

export default function protocolDataReducer(state = initialState.protocolData, action) {
	switch (action.type) {
		case types.PROTOCOLS_GET_PROTOCOL_DATA_SUCCESS:
			return {
				...action.protocolData.protocolData,
				treatmentData: action.protocolData.treatmentData ?? [],
				applications: action.protocolData.applications ?? []
			};

		case types.PROTOCOLS_DELETE_PROTOCOL_SUCCESS:
			return initialState.protocolData;

		case types.PROTOCOLS_CREATE_PROTOCOL_SUCCESS:
			return { ...action.protocolData, applications: [], treatmentData: [] };

		case types.PROTOCOLS_UPDATE_PROTOCOL_SUCCESS:
			return { ...action.protocolData, applications: state.applications, treatmentData: state.treatmentData };

		case types.PROTOCOLS_RESET_PROTOCOL_DATA_SUCCESS:
			return initialState.protocolData;

		case types.APPLICATIONS_GET_APPLICATIONS_SUCCESS:
			if (_.isEqual(state.applications, action.applications)) {
				return state;
			} else {
				return { ...state, applications: action.applications };
			}

		case types.APPLICATIONS_SAVE_APPLICATIONS_SUCCESS:
			return { ...state, applications: action.applications };

		case types.APPLICATIONS_UPDATE_APPLICATIONS_SUCCESS:
			return { ...state, applications: action.applications };

		case types.PROTOCOLS_GET_PROTOCOL_TREATMENT_DATA_SUCCESS:
			if (_.isEqual(state.treatmentData, action.treatmentData.protocolTreatments)) {
				return state;
			} else {
				return { ...state, treatmentData: action.treatmentData.protocolTreatments };
			}

		case types.PROTOCOLS_CREATE_PROTOCOL_TREATMENT_DATA_SUCCESS:
			return { ...state, treatmentData: action.treatmentData.protocolTreatments };

		case types.PROTOCOLS_UPDATE_PROTOCOL_TREATMENT_DATA_SUCCESS:
			return { ...state, treatmentData: action.treatmentData.protocolTreatments };

		default:
			return state;
	}
}
