import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { Segment, Table, Pagination, Dropdown, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

const PublishedTrialsFlightsTable = ({ publishedTrialsFlightsData }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "MM/DD/YYYY";

	//-- Data source
	const [dataSource, setDataSource] = useState(publishedTrialsFlightsData);

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- UI Control
	const [canViewActions, setCanViewActions] = useState(false);

	//-- Initial view load
	useEffect(() => {
		if (publishedTrialsFlightsData) {
			setTotalPages(Math.ceil(publishedTrialsFlightsData.length / itemsPerPage));
			setDataSource(publishedTrialsFlightsData);
			setTableData(dataSource);
		}
	}, [publishedTrialsFlightsData]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn === "publishedDate") {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn]).format(DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total|treatmentId/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	};

	useEffect(() => {
		if (userAuth.isReady) {
			const isApAdmin = userAuth.isApAdmin;
			const dataSupported =
				dataSource.length > 0 &&
				dataSource.filter((ds) => {
					return ds.view;
				}).length > 0;
			setCanViewActions(isApAdmin || dataSupported);
		}
	}, [userAuth.isReady, userAuth.isApAdmin, dataSource.length]);

	// prettier-ignore
	return (
		<Segment basic style={{paddingLeft: "unset"}}>
    	<Table celled striped sortable selectable color="blue" textAlign="center">
    	  <Table.Header>
    	    <Table.Row>
    	      <Table.HeaderCell
    	          sorted={sortColumn === 'trialName' ? sortDirection : null}
    	          onClick={() => handleSort('trialName')}>Trial</Table.HeaderCell>
    	      <Table.HeaderCell
    	          sorted={sortColumn === 'flightName' ? sortDirection : null}
    	          onClick={() => handleSort('flightName')}>Flight</Table.HeaderCell>
    	      <Table.HeaderCell
    	          sorted={sortColumn === 'publishedDate' ? sortDirection : null}
    	          onClick={() => handleSort('publishedDate')}>Published</Table.HeaderCell>
    	      <Table.HeaderCell
    	          sorted={sortColumn === 'company' ? sortDirection : null}
    	          onClick={() => handleSort('company')}>Company</Table.HeaderCell>
						<Table.HeaderCell
    	          sorted={sortColumn === 'publishedByClientName' ? sortDirection : null}
    	          onClick={() => handleSort('publishedByClientName')}>Published By</Table.HeaderCell>
						<Table.HeaderCell
    	          sorted={sortColumn === 'publishedToFirstName' ? sortDirection : null}
    	          onClick={() => handleSort('publishedToFirstName')}>Published To</Table.HeaderCell>
						<Table.HeaderCell
    	          sorted={sortColumn === 'publishedToClientName' ? sortDirection : null}
    	          onClick={() => handleSort('publishedToClientName')}>Published To Org</Table.HeaderCell>
						{canViewActions && <Table.HeaderCell>Actions</Table.HeaderCell>}
    	    </Table.Row>
    	  </Table.Header>

    	  <Table.Body>
    	    {
    	      _.map(tableData, ({trialId, trialName, flightId, flightName, fieldId, flightDate, publishedDate, publishedByClientName, publishedByUserFirstName, publishedByUserLastName, publishedByEmailAddress, publishedToEmailAddress, publishedToFirstName, publishedToLastName, view, publishedToClientName, company}, index) => {
    	        return (
								<Table.Row key={index}>
    	            <Table.Cell>{view ? <Link to={moduleNavigation.createTrialLink(false, trialId)}>{trialName}</Link> : <>{trialName}</>}</Table.Cell>
									<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<>
													Flight Date: <span style={{ opacity: 0.75 }}>{moment(flightDate).local().format(DATE_FORMAT)}</span>
												</>
											}
											transitionName="rc-tooltip-zoom"
										>
										<Table.Cell>
											{userAuth.isApAdmin ? <Link to={moduleNavigation.createFlightLink(false, flightId)}>{flightName}</Link>: <>{flightName}</>}
										</Table.Cell>
									</Tooltip>
									<Table.Cell>{moment(publishedDate).local().format(DATE_FORMAT)}</Table.Cell>
									<Table.Cell>{company}
									</Table.Cell>
									<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<>
													Publisher: <span style={{ opacity: 0.75 }}>{publishedByUserFirstName} {publishedByUserLastName}</span>
												</>
											}
											transitionName="rc-tooltip-zoom"
										>
										<Table.Cell>
											<a href={`mailto:${publishedByEmailAddress}`}>
												{publishedByClientName}
											</a>
										</Table.Cell>
									</Tooltip>
									<Table.Cell>
										{<a href={`mailto:${publishedToEmailAddress}`}>
											{publishedToFirstName} {publishedToLastName}</a>}
									</Table.Cell>
									<Table.Cell warning={publishedToClientName === "Unassigned"}>
										{publishedToClientName}
									</Table.Cell>
									{(userAuth.isApAdmin || view) ? (
										<Table.Cell textAlign="center">
											<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={
														<p>Edit</p>
													}
													transitionName="rc-tooltip-zoom"
											>
												<Link to={moduleNavigation.createTrialLink(false, trialId, "edit", userAuth.isExpertLicense || userAuth.isProLicense || userAuth.isStandardLicense ? fieldId : null)}>
													<Icon.Group>
														<Icon name="leaf" corner="top left" style={{ fontSize: 11, textShadow: "unset" }} />
														<Icon name="pencil" corner="bottom right" style={{ fontSize: 6 }} />
													</Icon.Group>
												</Link>
											</Tooltip>
											<span style={{display: "inline-block", width: 7}} />
											<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={
														<p>Heatmap</p>
													}
													transitionName="rc-tooltip-zoom"
											>
												<Link to={moduleNavigation.createTrialLink(false, trialId, "heatmap", userAuth.isExpertLicense || userAuth.isProLicense || userAuth.isStandardLicense ? fieldId : null)}><Icon name="map" /></Link>
											</Tooltip>
											<span style={{display: "inline-block", width: 5}} />
											<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={
														<p>Analysis</p>
													}
													transitionName="rc-tooltip-zoom"
											>
												<Link to={moduleNavigation.createTrialLink(false, trialId, "analysis", userAuth.isExpertLicense || userAuth.isProLicense || userAuth.isStandardLicense ? fieldId : null)}><Icon name="chart bar outline" /></Link>
											</Tooltip>
											<span style={{display: "inline-block", width: 5}} />
											<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={
														<p>Outline</p>
													}
													transitionName="rc-tooltip-zoom"
											>
												<Link to={moduleNavigation.createTrialLink(false, trialId, "outline", userAuth.isExpertLicense || userAuth.isProLicense || userAuth.isStandardLicense ? fieldId : null)}><Icon name="table" /></Link>
											</Tooltip>
										</Table.Cell>
									) : canViewActions ? (
										<Table.Cell textAlign="center">
											-
										</Table.Cell>
									) : null}
    	          </Table.Row>
    	        );
    	      })
    	    }
    	  </Table.Body>

    	  <Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="13">
							<span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} published trial` : `${dataSource.length} published trials`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
    	        <Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 10, value: 10 },
									{ key: 2, text: 15, value: 15 },
									{ key: 3, text: 25, value: 25 },
									{ key: 4, text: 50, value: 50 },
									{ key: 5, text: 100, value: 100 },
									{ key: 6, text: 1000, value: 1000 }
								]}
    	          value={itemsPerPage}
    	          compact
    	          style={{marginLeft: 5, height: 42, paddingTop: 12}}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>

    	</Table>
  	</Segment>)
};

PublishedTrialsFlightsTable.propTypes = {
	publishedTrialsFlightsData: PropTypes.array.isRequired
};

export default PublishedTrialsFlightsTable;
