import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Grid, Icon, Loader, Segment, Table } from "semantic-ui-react";

import * as trialApi from "../../../../apis/trialApi";
import { UseMutateFunction, useQuery } from "@tanstack/react-query";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";

type TrialInfo = {
	trialDataQualityId: string;
	trialExclusionStatusId: string;
	trialSiteResponseId: string;
};

type Props = {
	trialInfo: TrialInfo[];
	onSave: UseMutateFunction<any, Error, TrialInfo[], unknown>;
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
	setSaveAction: React.Dispatch<React.SetStateAction<() => void>>;
};

const MetaTagsTable: FC<Props> = ({ trialInfo, onSave, setIsDirty, setIsEditing, setSaveAction }) => {
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	const getOptions = async (clientId: string) => {
		const accessToken = await getTokenSilently();
		const json = trialApi.getTrialMetaTagOptions(clientId, accessToken);
		return json;
	};

	const { data: metaTagOptions } = useQuery({
		queryKey: ["meta tag options", userAuth.currentClientId],
		queryFn: () => getOptions(userAuth.currentClientId),
		select: (data) => data.data,
		enabled: !!userAuth.currentClientId
	});

	const dataStatusOptions = useMemo(
		() =>
			metaTagOptions?.trialExclusionStatuses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const dataQualityOptions = useMemo(
		() =>
			metaTagOptions?.trialDataQualities.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const responseCategoriesOptions = useMemo(
		() =>
			metaTagOptions?.trialSiteResponses.map((opt) => ({
				key: opt.id,
				text: opt.name,
				value: opt.id
			})) ?? [],
		[metaTagOptions]
	);

	const [editing, setEditing] = useState(false);
	const [editedTrialInfo, setEditedTrialInfoSilently] = useState(trialInfo);
	const setEditedTrialInfo: React.Dispatch<React.SetStateAction<TrialInfo[]>> = (info) => {
		setIsDirty(true);
		setEditedTrialInfoSilently(info);
	};

	useEffect(() => {
		if (!!onSave) {
			setSaveAction(() => () => onSave(editedTrialInfo));
		}
	}, [editedTrialInfo]);

	useEffect(() => {
		setEditedTrialInfoSilently(trialInfo);
	}, [trialInfo]);

	useEffect(() => {
		setIsEditing(editing);
	}, [editing]);

	return editedTrialInfo.length === trialInfo.length ? (
		<>
			<Segment basic style={{ width: "fit-content", marginTop: 0 }}>
				<div className="metaTagsTableControls">
					{!editing && <Button content="Edit" icon="pencil" labelPosition="left" onClick={() => setEditing(true)} />}

					{editing && (
						<>
							<Button
								content="Cancel"
								negative
								icon="trash"
								labelPosition="left"
								onClick={() => {
									setEditedTrialInfoSilently([...trialInfo]);
									setEditing(false);
								}}
							/>
							<Button
								content="Save"
								positive
								icon="check"
								labelPosition="left"
								onClick={() => {
									onSave(editedTrialInfo);
									setIsDirty(false);
									setEditing(false);
								}}
							/>
						</>
					)}
				</div>
				<div style={{ clear: "both" }} />
				<Table celled fixed className={`metaTagsTable${editing ? " editing" : ""}`} style={{ width: "fit-content" }}>
					<Table.Row>
						<Table.Cell className="rowTitle">Exclusion Status</Table.Cell>
						{editedTrialInfo.map((ti, i) => {
							return (
								<Table.Cell style={{ width: 152, overflow: "visible" }}>
									<Dropdown
										className={`tableDropdown${
											trialInfo[i].trialExclusionStatusId !== ti.trialExclusionStatusId ? " dirty" : ""
										}`}
										options={dataStatusOptions}
										value={ti.trialExclusionStatusId}
										disabled={!editing}
										onChange={(_, { value }) => {
											setEditedTrialInfo((prev) => {
												const temp = [...prev];
												temp[i] = { ...prev[i], trialExclusionStatusId: value as string };
												return temp;
											});
										}}
									/>
								</Table.Cell>
							);
						})}
					</Table.Row>

					<Table.Row>
						<Table.Cell className="rowTitle">Data Quality</Table.Cell>
						{editedTrialInfo.map((ti, i) => {
							return (
								<Table.Cell style={{ width: 152, overflow: "visible" }}>
									<Dropdown
										className={`tableDropdown${
											trialInfo[i].trialDataQualityId !== ti.trialDataQualityId ? " dirty" : ""
										}`}
										options={dataQualityOptions}
										value={ti.trialDataQualityId}
										disabled={!editing}
										onChange={(_, { value }) => {
											setEditedTrialInfo((prev) => {
												const temp = [...prev];
												temp[i] = { ...prev[i], trialDataQualityId: value as string };
												return temp;
											});
										}}
									/>
								</Table.Cell>
							);
						})}
					</Table.Row>

					<Table.Row>
						<Table.Cell className="rowTitle">Site Response</Table.Cell>
						{editedTrialInfo.map((ti, i) => {
							return (
								<Table.Cell style={{ width: 152, overflow: "visible" }}>
									<Dropdown
										className={`tableDropdown${
											trialInfo[i].trialSiteResponseId !== ti.trialSiteResponseId ? " dirty" : ""
										}`}
										options={responseCategoriesOptions}
										value={ti.trialSiteResponseId}
										disabled={!editing}
										onChange={(_, { value }) => {
											setEditedTrialInfo((prev) => {
												const temp = [...prev];
												temp[i] = { ...prev[i], trialSiteResponseId: value as string };
												return temp;
											});
										}}
									/>
								</Table.Cell>
							);
						})}
					</Table.Row>
				</Table>
			</Segment>
		</>
	) : (
		<Loader active />
	);
};

export default MetaTagsTable;
