import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import moment from "moment";
import _ from "lodash";

import { Segment, Table, Pagination, Dropdown, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

import { useUserAuth } from "../../../../hooks/useUserAuth";

const FlightLogResults = ({ flightLogs }) => {
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	const DATE_FORMAT = "MM/DD/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState(flightLogs);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	useEffect(() => {
		if (flightLogs) {
			setTotalPages(Math.ceil(flightLogs.length / itemsPerPage));
			setTableData(dataSource);
			setupInitialLoad(flightLogs);
		}
	}, [flightLogs]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	function setupInitialLoad(flightLogs) {
		setDataSource(
			_.sortBy(flightLogs, function (d) {
				return moment(d.dateAndTime, DATE_FORMAT);
			}).reverse()
		);
	}

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/farms/i)) {
				// Will sort list of farms
				let sortedFarms = _.map(_.map(dataSource, clickedColumn), (farms) => {
					return _.sortBy(farms, [(f) => f.name]);
				});

				let sortedData = _.map(dataSource, (d, index) => {
					d.farms = sortedFarms[index];
					return d;
				});

				// Will sort all flights
				setDataSource(
					_.sortBy(
						sortedData,
						(d) => {
							let farmListLength = d[clickedColumn].length;
							let dIndex = _.findIndex(sortedData, d);
							return sortDirection === "ascending" || !sortDirection
								? sortedFarms[dIndex][0].name
								: sortedFarms[dIndex][farmListLength - 1].name;
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/cooperators/i)) {
				let sortedCooperators = _.map(dataSource, (d, index) => {
					d.cooperators = _.sortBy(d.cooperators, [
						(f) => f?.cooperatorName?.toLowerCase(),
						sortDirection === "ascending" ? "asc" : "desc"
					]);
					d.cooperators[0].index = index;
					return d;
				});

				let sortOrder = _.map(sortedCooperators, "cooperators");
				sortOrder = _.sortBy(sortOrder, [(so) => so[0]?.cooperatorName?.toLowerCase()]);

				let sortedData = [];
				_.map(sortOrder, (so) => {
					sortedData.push(sortedCooperators[so[0].index]);
				});

				setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
				let sortedDataSource = sortedData;
				setDataSource(sortedDataSource);
				setTableData(sortedDataSource);
			} else if (clickedColumn.match(/num|fields/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else if (clickedColumn.match(/analysis/i)) {
				setDataSource(_.sortBy(dataSource, [(d) => (_.every(d.orthoImages, ["plotAnalysis", true]))]));
			}
			else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn]?.toLowerCase().trim() : d[clickedColumn])])
				);
			}
			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		} else if (clickedColumn.match(/farms/i)) {
			let sortedFarms = _.map(dataSource, (d, index) => {
				d.farms = _.sortBy(d.farms, [(f) => f.name?.toLowerCase()]);
				if (sortDirection === "ascending") {
					d.farms = d.farms.reverse();
				}
				d.farms[0].index = index;
				return d;
			});

			let sortOrder = _.map(sortedFarms, "farms");
			sortOrder = _.sortBy(sortOrder, [(so) => so[0].name?.toLowerCase()]);

			let sortedData = [];
			_.map(sortOrder, (so) => {
				sortedData.push(sortedFarms[so[0].index]);
			});

			setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
			setDataSource(sortDirection === "ascending" ? sortedData.reverse() : sortedData);
			setTableData(dataSource);
		} else if (clickedColumn.match(/cooperators/i)) {
			let sortedCooperators = _.map(dataSource, (d, index) => {
				d.cooperators = _.sortBy(d.cooperators, [(f) => f?.cooperatorName?.toLowerCase()]);
				if (sortDirection === "ascending") {
					d.cooperators = d.cooperators.reverse();
				}
				d.cooperators[0].index = index;
				return d;
			});

			let sortOrder = _.map(sortedCooperators, "cooperators");
			sortOrder = _.sortBy(sortOrder, [(so) => so[0]?.cooperatorName?.toLowerCase()]);

			let sortedData = [];
			_.map(sortOrder, (so) => {
				sortedData.push(sortedCooperators[so[0].index]);
			});

			setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
			setDataSource(sortDirection === "ascending" ? sortedData.reverse() : sortedData);
			setTableData(dataSource);
		}

		if (!clickedColumn.match(/farms/i) && !clickedColumn.match(/cooperators/i)) {
			setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
			setDataSource(dataSource.reverse());
			setTableData(dataSource);
		}
	}

	// prettier-ignore
	return (<Segment basic>
		<span style={{ float: "right" }}>{dataSource.length === 1 ? `${dataSource.length} flight found` : `${dataSource.length} flights found`}</span>
		<div style={{ clear: "both" }} />
		<div style={{ overflowX: "auto" }}>
			<Table celled striped sortable selectable color="blue">
				<Table.Header>
					<Table.Row textAlign="center">
						<Table.HeaderCell
							sorted={sortColumn === 'name' ? sortDirection : null}
							onClick={() => handleSort('name')}>
							Flight Name
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'dateAndTime' ? sortDirection : null}
							onClick={() => handleSort('dateAndTime')}>Flight Date</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'flightParameters' ? sortDirection : null}
						>Flight Parameters</Table.HeaderCell>
						{
							userAuth.isApAdmin ?
								<Table.HeaderCell
									sorted={sortColumn === 'flightConditions' ? sortDirection : null}>Flight Conditions</Table.HeaderCell> : null
						}
						<Table.HeaderCell
							sorted={sortColumn === 'clientName' ? sortDirection : null}
							onClick={() => handleSort('clientName')}>Client</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'cooperators' ? sortDirection : null}
							onClick={() => handleSort('cooperators')}>Cooperators</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'operator' ? sortDirection : null}
							onClick={() => handleSort('operator')}>Operator</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'farms' ? sortDirection : null}
							onClick={() => handleSort('farms')}>Farms</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'fields' ? sortDirection : null}
							onClick={() => handleSort('fields')}>Fields</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'uas' ? sortDirection : null}
							onClick={() => handleSort('uas')}>UAS</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'growingSeasons' ? sortDirection : null}>Growing Seasons</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'imageUpload' ? sortDirection : null}>Image Upload</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === 'analysis' ? sortDirection : null}
							onClick={() => handleSort('analysis')}>Plot Analysis</Table.HeaderCell>
						{(_.some(tableData, (td) => {
							return userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, td.clientId) ||
								userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysis, td.clientId)
						})) && <Table.HeaderCell>
								Actions
							</Table.HeaderCell>}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{
						_.map(tableData, (flightLog) => {
							return (
								<Table.Row key={flightLog.flightId}>
									<Table.Cell>
										<Link to={moduleNavigation.createFlightLink(false, flightLog.flightId)}>
											{flightLog.name}
										</Link>
									</Table.Cell>
									<Table.Cell>{flightLog.dateAndTime}</Table.Cell>
									<Table.Cell>
										<div>
											{`Altitude: ${flightLog.altitude} ft.`}
											<br />
										</div>
										{userAuth.isApAdmin && flightLog.droneSpeed !== null ?
											<div>
												{`Drone Speed: ${flightLog.droneSpeed} mph`}
												<br />
											</div> : null
										}
									</Table.Cell>
									{
										userAuth.isApAdmin ?
											<Table.Cell>
												{flightLog.inFlightPauses !== null && flightLog.inFlightPauses !== "" ?
													<div>
														{`Pauses: ${flightLog.inFlightPauses === true ? "Yes" : "No"}`}
														<br />
													</div> : null
												}
												{flightLog.windSpeed !== null ?
													<div>
														{`Wind Speed: ${flightLog.windSpeed} mph`}
														<br />
													</div> : null
												}
												{flightLog.cloudCoverage !== null && flightLog.cloudCoverage !== "" ?
													<div>
														{`Cloud Coverage: ${flightLog.cloudCoverage}`}
														<br />
													</div> : null
												}
											</Table.Cell> : null
									}
									<Table.Cell>
										{flightLog.clientName}
									</Table.Cell>
									<Table.Cell>
										{_.map(flightLog.cooperators, (c) => {
											return (
												<div key={c.cooperatorCompanyId}>
													{c.cooperatorName}
													<br />
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell>{flightLog.operator}</Table.Cell>
									<Table.Cell>
										{_.map(flightLog.farms, farm => {
											return (
												<div key={farm.farmId}>
													{farm.name}
													<br />
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell>{`${flightLog.fields} / ${flightLog.totalFields}`}</Table.Cell>
									<Table.Cell>{flightLog.uas}</Table.Cell>
									<Table.Cell>
										{_.map(flightLog.growingSeasons, growingSeason => {
											return (
												<div key={growingSeason.growingSeasonId}>
													{growingSeason.name}
													<br />
												</div>
											);
										})}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{_.map(flightLog.orthoImages, (ortho, index) => {
											if (ortho.isUploading === true || ortho.uploaded === true) {
												return <div key={index}>
													{ortho.abbreviation}{ortho.uploadFailed ? ' - Failed' : ortho.mapboxUploadFailed ? ' - Mapbox Failed' : ''}
												</div>
											}
										})}
									</Table.Cell>
									<Table.Cell>
										{_.every(flightLog.orthoImages, ["plotAnalysis", true])
											? "Complete"
											: "Incomplete"}
									</Table.Cell>
									{userAuth.hasApplicationArea(ApplicationArea.FlightEdit, flightLog.clientId) && <Table.Cell textAlign="center">
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<p>Edit</p>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Link to={moduleNavigation.createFlightLink(false, flightLog.flightId, "info")}>
												<Icon.Group>
													<Icon name="paper plane outline" corner="top left" style={{ fontSize: 11, textShadow: "unset" }} />
													<Icon name="pencil" corner="bottom right" style={{ fontSize: 6 }} />
												</Icon.Group>
											</Link>
										</Tooltip>
										<span style={{ display: "inline-block", width: 7 }} />
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<p>Analysis</p>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Link to={moduleNavigation.createFlightLink(false, flightLog.flightId, "plot-analysis")}><Icon name="chart bar outline" /></Link>
										</Tooltip>
									</Table.Cell>}
								</Table.Row>
							);
						})
					}
				</Table.Body>

				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="14">
							<span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} flight found` : `${dataSource.length} flights found`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>

			</Table>
		</div>
	</Segment>)
};

FlightLogResults.propTypes = {
	flightLogs: PropTypes.array,
	userAuth: PropTypes.object.isRequired
};

export default FlightLogResults;
