import _ from "lodash";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Pagination, Segment, Table } from "semantic-ui-react";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import ProtocolFavoritingWidget from "../../Widgets/Favorites/ProtocolFavoritingWidget";

import damagedIcon from "../../../../assets/excludedNoBorder.svg";
import partialExcludedIcon from "../../../../assets/partialExcludedNoBorder.svg";
import partialFlagIcon from "../../../../assets/partialFlagNoBorder.svg";
import flaggedIcon from "../../../../assets/flaggedNoBorder.svg";

import moment from "moment";

const ProtocolLogResults = ({
	protocolList,
	geographyOptions,
	cropOptions,
	growingSeasonOptions,
	protocolOptions,
	variablesTestedOptions
}) => {
	const userAuth = useUserAuth();
	const userSettings = useUserSettings();
	const moduleNavigation = useModuleNavigation();

	const [dataSource, setDataSource] = useState(protocolList);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const DATE_FORMAT = "M/D/YYYY";

	useEffect(() => {
		setDataSource(protocolList);
	}, [protocolList]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages !== 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn === "protocolVariables") {
				let filteredProtocols = _.sortBy(dataSource, [
					(p) => _.filter(p.protocolVariables, (pv) => pv.checked)[0]?.name
				]);

				setDataSource(filteredProtocols);
			} else if (clickedColumn.match(/num/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase().trim() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			return;
		}
		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
	};

	function getFullName(person) {
		if (!person) {
			return;
		}
		return `${person.firstName} ${person.lastName}`;
	}

	return (
		<Segment basic>
			<Table celled striped sortable selectable color="blue">
				<Table.Header>
					<Table.Row textAlign="center">
						<Table.HeaderCell
							sorted={sortColumn === "protocolName" ? sortDirection : null}
							onClick={() => handleSort("protocolName")}
						>
							Protocol Name
						</Table.HeaderCell>
						<Table.HeaderCell>Trials</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "clientName" ? sortDirection : null}
							onClick={() => handleSort("clientName")}
						>
							Client
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "geographyName" ? sortDirection : null}
							onClick={() => handleSort("geographyName")}
						>
							Location
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "cropName" ? sortDirection : null}
							onClick={() => handleSort("cropName")}
						>
							Crop
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "growingSeasonName" ? sortDirection : null}
							onClick={() => handleSort("growingSeasonName")}
						>
							Growing Season
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "owner" ? sortDirection : null}
							onClick={() => handleSort("owner")}
						>
							Owner
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={sortColumn === "protocolVariables" ? sortDirection : null}
							onClick={() => handleSort("protocolVariables")}
						>
							Variables Tested
						</Table.HeaderCell>
						{userAuth.isApAdmin ? <Table.HeaderCell>Status</Table.HeaderCell> : null}
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (p) => {
						return (
							<Table.Row key={p.id}>
								<Table.Cell>
									<Link to={moduleNavigation.createProtocolLink(true, p.id, "overview-status")}>{p.protocolName}</Link>
								</Table.Cell>
								<Table.Cell>
									{_.map(p.protocolTrials, (pt) => {
										return (
											<>
												<Link to={moduleNavigation.createTrialLink(false, pt.trialId, "analysis")} target="_blank">
													{pt.trialName}
												</Link>
												<br />
											</>
										);
									})}
								</Table.Cell>
								<Table.Cell>{p.clientName}</Table.Cell>
								<Table.Cell>
									{
										_.find(geographyOptions, (geography) => {
											return geography.key == p?.geographyId;
										})?.text
									}
								</Table.Cell>
								<Table.Cell>
									{
										_.find(cropOptions, (crop) => {
											return crop.key == p?.cropId;
										})?.text
									}
								</Table.Cell>
								<Table.Cell>
									{
										_.find(growingSeasonOptions, (growingSeason) => {
											return growingSeason.key == p?.growingSeasonId;
										})?.text
									}
								</Table.Cell>
								<Table.Cell>
									{getFullName(
										_.find(protocolOptions.protocolOwners, (protocolOwner) => {
											return protocolOwner.personId == p?.protocolOwnerId;
										})
									)}
								</Table.Cell>
								<Table.Cell>
									{_.map(p?.protocolVariables, (protocolVariables, index) => {
										return (
											<div key={index}>
												{" "}
												{
													_.find(variablesTestedOptions, (variablesTestedOptions) => {
														return (
															variablesTestedOptions.key == protocolVariables.id && protocolVariables.checked == true
														);
													})?.text
												}
											</div>
										);
									})}
								</Table.Cell>
								{userAuth.isApAdmin ? (
									<Table.Cell textAlign="center">
										{p.hasExclusions || p.hasPartialExclusions ? (
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={`Protocol has trials with ${p.hasExclusions ? "whole plot" : "partial"} exclusions`}
												transitionName="rc-tooltip-zoom"
											>
												<img
													src={p.hasExclusions ? damagedIcon : partialExcludedIcon}
													color={"#d03f2c"}
													width={p.hasExclusions ? "18px" : "19px"}
													style={{ marginLeft: "-2px" }}
												></img>
											</Tooltip>
										) : null}
										{p.hasFlaggings || p.hasPartialFlaggings ? (
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={`Protocol has trials with ${p.hasFlaggings ? "whole plot" : "partial"} flaggings`}
												transitionName="rc-tooltip-zoom"
											>
												<img
													src={p.hasFlaggings ? flaggedIcon : partialFlagIcon}
													color={"#ffa500"}
													width={p.hasFlaggings ? "18px" : "19px"}
													style={{ marginRight: "2px" }}
												></img>
											</Tooltip>
										) : null}
									</Table.Cell>
								) : null}
								<Table.Cell textAlign="center">
									{userAuth.hasApplicationArea(ApplicationArea.ProtocolEdit, p.clientId) && (
										<>
											<Tooltip
												placement="bottom"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												trigger="hover"
												overlay={<p>Edit Protocol</p>}
												transitionName="rc-tooltip-zoom"
											>
												<Link to={moduleNavigation.createProtocolLink(false, p.id, "info", null)}>
													<Icon.Group>
														<Icon name="folder" corner="top left" style={{ fontSize: 15, textShadow: "unset" }} />
														<Icon name="pencil" corner="bottom right" style={{ fontSize: 8 }} />
													</Icon.Group>
												</Link>
											</Tooltip>
											<span style={{ display: "inline-block", width: 7 }} />
										</>
									)}

									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={<p>Overview and Status</p>}
										transitionName="rc-tooltip-zoom"
									>
										<Link to={moduleNavigation.createProtocolLink(false, p.id, "overview-status", null)}>
											<Icon.Group>
												<Icon name="dashboard" corner="top left" style={{ fontSize: 15, textShadow: "unset" }} />
											</Icon.Group>
										</Link>
									</Tooltip>
									<span style={{ display: "inline-block", width: 7 }} />
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={<p>Analysis</p>}
										transitionName="rc-tooltip-zoom"
									>
										<Link to={moduleNavigation.createProtocolLink(false, p.id, "analysis", null)}>
											<Icon.Group>
												<Icon
													name="chart bar outline"
													corner="top left"
													style={{ fontSize: 15, textShadow: "unset" }}
												/>
											</Icon.Group>
										</Link>
									</Tooltip>
									<ProtocolFavoritingWidget
										clientId={userAuth.currentClientId}
										protocolId={p.id}
										userSettings={userSettings}
										displayMode={"table"}
									></ProtocolFavoritingWidget>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="11">
							<span style={{ marginRight: 10 }}>
								{dataSource?.length === 1
									? `${dataSource?.length} protocol found`
									: `${dataSource?.length} protocols found`}
							</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</Segment>
	);
};

export default ProtocolLogResults;

ProtocolLogResults.propTypes = {
	protocolList: PropTypes.array,
	geographyOptions: PropTypes.array,
	cropOptions: PropTypes.array,
	growingSeasonOptions: PropTypes.array,
	protocolOptions: PropTypes.object,
	variablesTestedOptions: PropTypes.array
	// parentIsDashboard: PropTypes.bool,
	// protocolStatuses: PropTypes.array
};
