import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../../../auth/auth0";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

import { Form, Grid, Segment, Button, Loader, Modal } from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

import * as assessmentActions from "../../../redux/actions/assessmentActions";
import * as groundDataActions from "../../../redux/actions/groundDataActions";

import AssessmentLogResults from "./AssessmentLogResults";

const AssessmentLog = (isTrial, isField) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const DATE_FORMAT = "MM/DD/YYYY";

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [assessmentLogOptions, setAssessmentLogOptions] = useState(null);
	const [assessmentLogs, setAssessmentLogs] = useState([]);
	const [filteredAssessmentLogs, setFilteredAssessmentLogs] = useState([]);

	//-- Dropdown Options
	const [assessmentOptions, setAssessmentOptions] = useState([]);
	const [growthStageOptions, setGrowthStageOptions] = useState([]);
	const [growthPhaseOptions, setGrowthPhaseOptions] = useState([]);
	const [dateCollectedOptions, setDateCollectedOptions] = useState([]);
	const [collectedByOptions, setCollectedByOptions] = useState([]);

	//-- Selected Otpions
	const [selectedAssessment, setSelectedAssessment] = useState("all");
	const [selectedGrowthStage, setSelectedGrowthStage] = useState("all");
	const [selectedGrowthPhase, setSelectedGrowthPhase] = useState("all");
	const [selectedDateCollected, setSelectedDateCollected] = useState("all");
	const [selectedCollectedBy, setSelectedCollectedBy] = useState("all");
	const [selectedDataset, setSelectedDataset] = useState(null);

	//-- UI Control
	const [loadingOptions, setLoadingOptions] = useState(true);
	const [loadingAssessmentLog, setLoadingAssessmentLog] = useState(true);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEraseModal, setShowEraseModal] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			if (!assessmentLogOptions) {
				getFilterOptions();
			} else {
				setInitialGrowthStages(assessmentLogOptions.growthStageOptions);
				setInitialGrowthPhases(assessmentLogOptions.growthPhaseOptions);
				getAssessmentLog();
			}
		}
	}, [userAuth.isReady, clientId, assessmentLogOptions]);

	useEffect(() => {
		if (assessmentLogs) {
			handleFilter();
		}
	}, [assessmentLogs]);

	async function getFilterOptions() {
		const accessToken = await getTokenSilently();
		dispatch(assessmentActions.getAssessmentLogOptions(isTrial ? moduleNavigation.trialId : null, isField ? moduleNavigation.fieldId : null, accessToken, clientId))
			.then((res) => {
				setAssessmentLogOptions(res.data);
				setLoadingOptions(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to load assessment log results. Please try again.");
				setLoadingOptions(false);
			});
	}

	function setInitialGrowthStages(growthStages) {
		const growthStageOpts = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(growthStages, (gso) => {
				const opt = { key: gso.id, value: gso.id, text: gso.name };
				return opt;
			})
		);
		setGrowthStageOptions(growthStageOpts);
	}

	function setInitialAssessmentOptions(assessments) {
		let filteredAssessments = _.map(assessments, (a) => {
			return { id: a.assessmentId, name: a.assessment };
		});
		filteredAssessments = _.uniqBy(filteredAssessments, "id");

		const assessmentOpts = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(filteredAssessments, (ao) => {
				const opt = { key: ao.id, value: ao.id, text: ao.name };
				return opt;
			})
		);
		setAssessmentOptions(assessmentOpts);
	}

	function setInitialGrowthPhases(growthPhases) {
		const growthPhaseOpts = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(growthPhases, (gpo) => {
				const opt = { key: gpo.id, value: gpo.id, text: gpo.name };
				return opt;
			})
		);
		setGrowthPhaseOptions(growthPhaseOpts);
	}

	function setInitialDateCollectedOptions(data) {
		let filteredDates = _.map(data, (d) => moment(new Date(d.assessmentDate)).local().format(DATE_FORMAT));
		filteredDates = _.uniq(filteredDates);

		const dateCollectedOpts = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(filteredDates, (fd) => {
				const opt = { key: fd, value: fd, text: fd };
				return opt;
			})
		);
		setDateCollectedOptions(dateCollectedOpts);
	}

	function setInitialCollectedByOptions(data) {
		let filteredPersons = _.map(data, (d) => {
			return { id: d.collectedByPersonId, name: d.collectedByName };
		});
		filteredPersons = _.uniqBy(filteredPersons, "id");

		const collectedByOpts = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(filteredPersons, (fp) => {
				const opt = { key: fp.id, value: fp.id, text: fp.name };
				return opt;
			})
		);
		setCollectedByOptions(collectedByOpts);
	}

	async function getAssessmentLog() {
		const accessToken = await getTokenSilently();
		setLoadingAssessmentLog(true);
		dispatch(
			assessmentActions.getAssessmentLog(
				accessToken,
				isTrial ? moduleNavigation.trialId : null,
				isField ? moduleNavigation.fieldId : null,
				clientId
			)
		)
			.then((res) => {
				setInitialDateCollectedOptions(res.data);
				setInitialCollectedByOptions(res.data);
				setInitialAssessmentOptions(res.data);
				setAssessmentLogs(res.data);
				setLoadingAssessmentLog(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to load assessment log results. Please try again.");
				setLoadingAssessmentLog(false);
			});
	}

	function handleFilter() {
		if (assessmentLogs.length > 0) {
			let groupedLogs = _.groupBy(_.cloneDeep(assessmentLogs), "datasetId");
			let groupedLists = _.map(groupedLogs, (gl) => gl[0]);

			_.map(groupedLists, (gl) => {
				let assessments = _.map(groupedLogs[gl.datasetId], (filter) => {
					return { assessmentId: filter.assessmentId, assessment: filter.assessment };
				});

				gl.assessmentId = _.map(assessments, "assessmentId");
				gl.assessment = _.map(assessments, "assessment");
			});

			_.map(groupedLists, gl => {
				if (gl.groundDataTimingMethodType) {
					if (gl.groundDataTimingMethodType === "Date") {
						gl.plannedTiming = moment(new Date(gl.groundDataTimingDateUtc)).local().format(DATE_FORMAT);
					} else if (gl.groundDataTimingMethodType === "Text") {
						gl.plannedTiming = gl.groundDataTimingText;
					} else if (gl.groundDataTimingMethodType === "StartEnd") {
						gl.plannedTiming = `${gl.groundDataTimingStartName} - ${gl.groundDataTimingEndName}`;
					} else if (gl.groundDataTimingMethodType === "int") {
						gl.plannedTiming = `${gl.groundDataTimingInteger} ${gl.groundDataTimingMethodName}`;
					} else if (gl.groundDataTimingMethodType === "None") {
						gl.plannedTiming = gl.growthPhaseName ?? "TBD";
					} else if (gl.groundDataTimingMethodType === "Start") {
						gl.plannedTiming = gl.groundDataTimingStartName;
					} else {
						gl.plannedTiming = gl.growthStage ?? "TBD";
					}
				} else {
					gl.plannedTiming = null;
				}
			});

			let filteredList = groupedLists.filter((aLog) => {
				const included =
					//-- Assessment
					(selectedAssessment === "all" || aLog.assessmentId.includes(selectedAssessment)) &&
					//-- Growth Stage
					(selectedGrowthStage === "all" || aLog.growthStageId === selectedGrowthStage) &&
					//-- Growth Phase
					(selectedGrowthPhase === "all" || aLog.growthPhaseId === selectedGrowthPhase) &&
					//-- Date Collected
					(selectedDateCollected === "all" ||
						moment(new Date(aLog.assessmentDate)).local().format(DATE_FORMAT) === selectedDateCollected) &&
					//-- Collected By
					(selectedCollectedBy === "all" || aLog.collectedByPersonId === selectedCollectedBy);
				return included;
			});

			filteredList = _.orderBy(filteredList, ["datasetNumericalId", "assessmentDate"], ["desc"]);

			setFilteredAssessmentLogs(filteredList);
		}
	}

	function resetFilter() {
		setSelectedAssessment("all");
		setSelectedGrowthStage("all");
		setSelectedGrowthPhase("all");
		setSelectedDateCollected("all");
		setSelectedCollectedBy("all");
	}

	async function deleteDataset() {
		const accessToken = await getTokenSilently();
		setModalLoading(true);
		dispatch(groundDataActions.deleteDataset(clientId, selectedDataset, accessToken))
			.then(() => {
				toast.success("Dataset deleted successfully.");
				setAssessmentLogs(
					_.filter(assessmentLogs, aLog => aLog.datasetId !== selectedDataset)
				)
				setShowDeleteModal(false);
				setModalLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to delete dataset.");
				setShowDeleteModal(false);
				setModalLoading(false);
			});
	}

	async function eraseDataset() {
		const accessToken = await getTokenSilently();
		setModalLoading(true);
		dispatch(groundDataActions.eraseDataset(clientId, selectedDataset, accessToken))
			.then(() => {
				toast.success("Dataset erased successfully.");
				let newLogs = _.map(assessmentLogs, aLog => {
					if (aLog.datasetId === selectedDataset) {
						aLog.collectedByPersonId = null;
						aLog.collectedByName = '';
						aLog.growthStage = null;
						aLog.growthStageId = null;
						aLog.assessmentDate = null;
					}
					return aLog
				});
				setAssessmentLogs(newLogs)
				setShowEraseModal(false);
				setModalLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to erase dataset.");
				setShowEraseModal(false);
				setModalLoading(false);
			});
	}
	
	return loadingOptions ? (
		<Loader active />
	) : assessmentLogOptions === null || assessmentLogOptions.length === 0 ? (
		<Segment basic>
			<p>{"Assessment Log could not be loaded. Please try again. If the problem persists, please contact support."}</p>
		</Segment>
	) : (
		<Segment basic>
			<Form onSubmit={handleFilter}>
				<Grid verticalAlign="middle">
				<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-date-collected">Date Collected</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Field>
								<Form.Select
									id="form-select-date-collected"
									search
									options={dateCollectedOptions}
									value={selectedDateCollected}
									onChange={(event, { value }) => {
										setSelectedDateCollected(value);
									}}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-assessment">Assessment</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-assessment"
								search
								options={assessmentOptions}
								value={selectedAssessment}
								onChange={(event, { value }) => {
									setSelectedAssessment(value);
								}}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-assessment">Actual Growth Stage</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-assessment"
								search
								options={growthStageOptions}
								value={selectedGrowthStage}
								onChange={(event, { value }) => {
									setSelectedGrowthStage(value);
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-growth-phase">Growth Phase</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-growth-phase"
								search
								options={growthPhaseOptions}
								value={selectedGrowthPhase}
								onChange={(event, { value }) => {
									setSelectedGrowthPhase(value);
								}}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-collected-by">Collected By</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-collected-by"
								search
								options={collectedByOptions}
								value={selectedCollectedBy}
								onChange={(event, { value }) => {
									setSelectedCollectedBy(value);
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row textAlign="right">
						<Grid.Column>
							<Form.Field>
								<Button id="form-button-clear-filter" content="Reset" onClick={resetFilter} />
								<Button id="form-button-filter" primary content="Filter" />
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
			{loadingAssessmentLog ? (
				<Loader active inline="centered" />
			) : assessmentLogs === null || assessmentLogs.length === 0 ? (
				<Segment>
					<p>
						{"Assessment Log could not be loaded. Please try again. If the problem persists, please contact support."}
					</p>
				</Segment>
			) : (
				<>
					<AssessmentLogResults 
						assessmentLogs={filteredAssessmentLogs} 
						growthPhaseOptions={growthPhaseOptions} 
						growthStageOptions={growthStageOptions} 
						setSelectedDataset={(id) => setSelectedDataset(id)} 
						setShowDeleteModal={(val) => setShowDeleteModal(val)}
						setShowEraseModal={(val) => setShowEraseModal(val)}
						isTrial={isTrial.isTrial}
					/>
					<Modal open={showDeleteModal || showEraseModal}>
						<Modal.Header>You are about to {showDeleteModal ? 'delete' : 'erase'} this dataset</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								{!isTrial.isTrial && 
									<p>{showDeleteModal ? 'Deleting' : 'Erasing'} on the field level will {showDeleteModal ? 'delete' : 'erase'} all trial datasets associated with this dataset.</p>
								}
								<p>Do you want to continue?</p>
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type="button"
								floated="right"
								onClick={() => showDeleteModal ? deleteDataset() : eraseDataset()}
								negative
								loading={modalLoading}
								disabled={modalLoading}
							>
								{showDeleteModal ? 'Delete' : 'Erase'}
							</Button>
							<Button type="button" floated="right" onClick={() => showDeleteModal ? setShowDeleteModal(false) : setShowEraseModal(false)} disabled={modalLoading}>
								Cancel
							</Button>
							<br style={{ clear: "both" }} />
						</Modal.Actions>
					</Modal>
				</>
			)}
		</Segment>
	);
};

AssessmentLog.propTypes = {
	isTrial: PropTypes.bool,
	isField: PropTypes.bool
};

export default AssessmentLog;
