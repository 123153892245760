import React, { useState, useEffect } from "react";
import { Dropdown, Segment, Grid, Form, Button, Icon, Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import { DateTimePicker } from "react-widgets";
import _ from "lodash";

const Application = ({ application, applicationQuestions, removeApplication, updateApplication }) => {
	// Data
	const [applicationTypeOptions, setApplicationTypeOptions] = useState(null);
	const [applicationMethodOptions, setApplicationMethodOptions] = useState(null);
	const [growthPhaseOptions, setGrowthPhaseOptions] = useState(null);
	const [timingMethodOptions, setTimingMethodOptions] = useState(null);
	const [, setSpecificTimingOptions] = useState(null);
	const [growthStageStartOptions, setGrowthStageStartOptions] = useState(null);
	const [growthStageEndOptions, setGrowthStageEndOptions] = useState(null);
	const [selectedTimingMethodType, setSelectedTimingMethodType] = useState(null);
	const [applicationPlacementOptions, setApplicationPlacementOptions] = useState(null);

	useEffect(() => {
		if (applicationQuestions) {
			initializeApplicationTypeOptions(applicationQuestions.applicationTypes);
			initializeApplicationMethodOptions(applicationQuestions.applicationMethods);
			initializeGrowthPhaseOptions(applicationQuestions.groundDataGrowthPhases);
			initializeGrowthStageOptions(applicationQuestions.growthStages);
			initializeTimingMethodOptions(applicationQuestions.timingMethods);
			initializeSpecificTimingOptions(applicationQuestions.specificTimings);
			initializeApplicationPlacementOptions(applicationQuestions.applicationPlacements);
		}
	}, [applicationQuestions]);

	function initializeApplicationTypeOptions(applicationTypes) {
		const applicationTypeOpts = _.map(applicationTypes, (at) => {
			const opt = {
				key: at.id,
				value: at.id,
				text: at.name
			};
			return opt;
		});
		setApplicationTypeOptions(applicationTypeOpts);
	}

	function initializeApplicationPlacementOptions(applicationPlacements) {
		const applicationPlacementOpts = _.map(applicationPlacements, (ap) => {
			const opt = {
				key: ap.id,
				value: ap.id,
				text: ap.name
			};
			return opt;
		});
		setApplicationPlacementOptions(applicationPlacementOpts);
	}

	function initializeGrowthStageOptions(growthStages) {
		const growthStageOptions = _.map(growthStages, (gs) => {
			const opt = {
				key: gs.id,
				value: gs.id,
				text: gs.name
			};
			return opt;
		});
		setGrowthStageStartOptions(growthStageOptions);
		setGrowthStageEndOptions(growthStageOptions);
	}

	function initializeApplicationMethodOptions(applicationMethods) {
		const applicationMethodOpts = _.map(applicationMethods, (am) => {
			const opt = {
				key: am.id,
				value: am.id,
				text: am.name
			};
			return opt;
		});
		setApplicationMethodOptions(applicationMethodOpts);
	}

	function initializeGrowthPhaseOptions(growthPhases) {
		const growthPhaseOpts = _.map(growthPhases, (gp) => {
			const opt = {
				key: gp.id,
				value: gp.id,
				text: gp.name
			};
			return opt;
		});
		setGrowthPhaseOptions(growthPhaseOpts);
	}

	function initializeTimingMethodOptions(timingMethods) {
		const timingMethodOpts = _.map(timingMethods, (tm) => {
			const opt = {
				key: tm.id,
				value: tm.id,
				text: tm.name,
				methodtype: tm.methodType
			};
			return opt;
		});
		if (application.groundDataTimingMethodId) {
			let defaultTimingMethod = _.find(timingMethods, { id: application.groundDataTimingMethodId })?.methodType;
			setSelectedTimingMethodType(defaultTimingMethod);
		} else {
			let defaultTimingMethod = _.find(timingMethods, { id: timingMethodOpts[0]?.key })?.methodType;
			setSelectedTimingMethodType(defaultTimingMethod);
			updateApplication(application.id, "groundDataTimingMethodId", timingMethodOpts[0]?.key);
		}
		setTimingMethodOptions(timingMethodOpts);
	}

	function initializeSpecificTimingOptions(specificTimings) {
		const specificTimingOpts = _.map(specificTimings, (at) => {
			const opt = {
				key: at.id,
				value: at.id,
				text: at.name
			};
			return opt;
		});
		setSpecificTimingOptions(specificTimingOpts);
	}

	function handleTimingChange(value) {
		let methodType = _.find(timingMethodOptions, { key: value })?.methodtype;
		setSelectedTimingMethodType(methodType);
		updateApplication(application.id, "groundDataTimingMethodId", value);
	}

	function handleTargetGrowthStageStartChanged(growthStageStart) {
		const endStageOptions = _.map(growthStageStartOptions);
		let index = _.findIndex(growthStageStartOptions, { key: growthStageStart });
		const filteredEndStageOptions = endStageOptions.splice(index + 1, growthStageStartOptions.length - 1);
		setGrowthStageEndOptions(filteredEndStageOptions);
		updateApplication(application.id, "growthStageStartStageId", growthStageStart);
	}

	return (
		<>
			<Segment basic style={{ minHeight: 50 }}>
				<Grid key={application.id}>
					<Grid.Row textAlign="center" verticalAlign="middle" style={{ padding: "5px" }}>
						<Grid.Column width="1">
							<Button circular icon negative onClick={() => removeApplication(application)}>
								<Icon name="minus" corner="bottom right" />
							</Button>
						</Grid.Column>
						<Grid.Column width="1">
							<Header as="h3">{application.code}</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Dropdown
								id="applciation-type-dropdown"
								selection
								fluid
								options={applicationTypeOptions}
								onChange={(event, { value }) => {
									updateApplication(application.id, "applicationTypeId", value);
								}}
								value={application.applicationTypeId}
							/>
						</Grid.Column>
						<Grid.Column width="2">
							<Dropdown
								id="applciation-method-dropdown"
								selection
								fluid
								options={applicationMethodOptions}
								onChange={(event, { value }) => {
									updateApplication(application.id, "applicationMethodId", value);
								}}
								value={application.applicationMethodId}
							/>
						</Grid.Column>
						<Grid.Column width="2">
							<Dropdown
								id="applciation-placement-dropdown"
								selection
								fluid
								options={applicationPlacementOptions}
								onChange={(event, { value }) => {
									updateApplication(application.id, "applicationPlacementId", value);
								}}
								value={application.applicationPlacementId}
							/>
						</Grid.Column>
						<Grid.Column width="2">
							<Dropdown
								id="growth-phase-dropdown"
								selection
								fluid
								options={growthPhaseOptions}
								onChange={(event, { value }) => {
									updateApplication(application.id, "groundDataGrowthPhaseId", value);
								}}
								value={application.groundDataGrowthPhaseId}
							/>
						</Grid.Column>
						<Grid.Column width="2">
							<Dropdown
								id="timing-method-dropdown"
								selection
								fluid
								options={timingMethodOptions}
								onChange={(event, { value }) => {
									handleTimingChange(value);
								}}
								value={application.groundDataTimingMethodId}
							/>
						</Grid.Column>
						{selectedTimingMethodType != "None" ? (
							<Grid.Column textAlign="left" width="2">
								{selectedTimingMethodType === "Date" && (
									<Form.Field
										id="form-specific-timing"
										control={DateTimePicker}
										includeTime={true}
										value={application.growthPhaseDateUTC ? new Date(application.growthPhaseDateUTC) : null}
										onChange={(date) => {
											if (date) {
												updateApplication(application.id, "growthPhaseDateUTC", date);
											} else {
												updateApplication(application.id, "growthPhaseDateUTC", null);
											}
										}}
									/>
								)}
								{selectedTimingMethodType === "Text" && (
									<Form.Input
										id="form-specific-timing"
										defaultValue={application.growthPhaseText}
										onBlur={(event) => {
											updateApplication(application.id, "growthPhaseText", event.target.value);
										}}
									/>
								)}
								{selectedTimingMethodType === "StartEnd" && (
									<Grid columns={2} widths="equal">
										<Grid.Row>
											<Grid.Column>
												<Dropdown
													id="growth-stage-start-dropdown"
													selection
													fluid
													options={growthStageStartOptions}
													onChange={(event, { value }) => {
														handleTargetGrowthStageStartChanged(value);
													}}
													value={application.growthStageStartStageId}
												/>
											</Grid.Column>
											<Grid.Column>
												<Dropdown
													id="growth-stage-end-dropdown"
													selection
													fluid
													options={growthStageEndOptions}
													onChange={(event, { value }) => {
														updateApplication(application.id, "growthStageEndStageId", value);
													}}
													value={application.growthStageEndStageId}
												/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								)}
								{selectedTimingMethodType === "Start" && (
									<Form.Select
										id="form-target-growth-stage-start"
										fluid
										search
										options={growthStageStartOptions}
										value={application.GrowthStageStartStageId}
										onChange={(event, { value }) => {
											updateApplication(application.id, "GrowthStageStartStageId", value);
										}}
									></Form.Select>
								)}
								{selectedTimingMethodType === "int" && (
									<Form.Input
										id="form-specific-timing"
										defaultValue={application.growthPhaseInteger}
										fluid
										onBlur={(event) => {
											if (!isNaN(event.target.value)) {
												updateApplication(application.id, "growthPhaseInteger", event.target.value);
											}
										}}
									/>
								)}
							</Grid.Column>
						) : (
							<Grid.Column textAlign="left" width="2"></Grid.Column>
						)}
						<Grid.Column width="2">
							<Form.Input
								placeholder="Notes"
								defaultValue={application.notes}
								onBlur={(event) => {
									let value = event.target.value;
									updateApplication(application.id, "notes", value);
								}}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</>
	);
};

Application.propTypes = {
	application: PropTypes.object,
	applicationQuestions: PropTypes.object,
	removeApplication: PropTypes.func,
	updateApplication: PropTypes.func
};
export default Application;
