import React, { useState, useImperativeHandle } from "react";
import { Grid, Button } from "semantic-ui-react";

import PropTypes from "prop-types";

const ShapeFileControls = React.forwardRef(({ numberOfPoints }, ref) => {
	ShapeFileControls.displayName = "ShapeFileSelections";

	useImperativeHandle(ref, () => {
		return {
			setLowerLeftPoint: setLowerLeftPoint,
			setUpperLeftPoint: setUpperLeftPoint,
			setPointToSet: setPointToSet,
			pointToSet: pointToSet,
			lowerLeftPoint: lowerLeftPoint,
			upperLeftPoint: upperLeftPoint
		};
	});

	//-- Data
	const [lowerLeftPoint, setLowerLeftPoint] = useState(null);
	const [upperLeftPoint, setUpperLeftPoint] = useState(null);

	//-- UI Control
	const [pointToSet, setPointToSet] = useState("ll");

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column width="3">
						<Button
							icon
							toggle
							active={pointToSet == "ll"}
							onClick={() => {
								setPointToSet("ll");
							}}
						>
							Select Lower Left
						</Button>
					</Grid.Column>
					<Grid.Column width="13">
						<h2>
							Lower Left Point:{" "}
							{lowerLeftPoint
								? `${lowerLeftPoint?.geometry.coordinates[1]}, ${lowerLeftPoint?.geometry.coordinates[0]}`
								: ""}
						</h2>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="3">
						<Button
							icon
							toggle
							active={pointToSet == "ul"}
							onClick={() => {
								setPointToSet("ul");
							}}
						>
							Select Upper Left
						</Button>
					</Grid.Column>
					<Grid.Column width="13">
						<h2>
							Upper Left Point:{" "}
							{upperLeftPoint
								? `${upperLeftPoint?.geometry.coordinates[1]}, ${upperLeftPoint?.geometry.coordinates[0]}`
								: ""}
						</h2>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<label>Select {pointToSet == "ll" ? "Lower Left Point" : "Upper Left Point"}</label>
			<label style={{ float: "right" }}>Number of points: {numberOfPoints}</label>
		</>
	);
});

ShapeFileControls.propTypes = {
	numberOfPoints: PropTypes.number
};

export default ShapeFileControls;
