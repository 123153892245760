import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

import { getDistinctObjects } from "../../../utilities/jsUtils";
import { getAnalysisOptions } from "./orthoControlFunctions";

import * as fieldActions from "../../../redux/actions/fieldActions";
import * as orthoViewerActions from "../../../redux/actions/orthoViewerActions";

import Timelineslider from "../../Lumber/TimelineSlider";

const OrthoTimeline = React.forwardRef(
	({ level, selectedOrthoImageTypeId, imageFound, heatmapTimelineRef }, parentRef) => {
		OrthoTimeline.displayName = "OrthoTimeline";
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();
		const moduleNavigation = useModuleNavigation();
		const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
		const currentReduxFlightId = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightId : null));

		useImperativeHandle(parentRef, () => {
			return {
				refresh: () => {
					setRefresh(refresh + 1);
				},
				currentFlightId: flightId,
				getCurrentFlightId: () => {
					return currentFlightId;
				},
				setCurrentFlightId: (targetFlightId) => {
					setCurrentFlightId(targetFlightId);
				},
				getCurrentFlightDate: () => {
					return currentFlightDate;
				}
			};
		});

		//-- Refresh mechanism
		const [refresh, setRefresh] = useState(0);

		//-- Data source
		const [flights, setFlights] = useState(null);
		const [currentFlightId, setCurrentFlightId] = useState(null);
		const [currentFlightDate, setCurrentFlightDate] = useState(null);

		//-- Timeline nodes
		const [flightNodes, setFlightNodes] = useState(null);
		const [tempFlightNodes, setTempFlightNodes] = useState([]);

		//-- Selected values
		const [flightId, setFlightId] = useState(null);

		//-- Lists of nodes for ortho/sensor combinations
		const [filteredNodes, setFilteredNodes] = useState([]);

		useEffect(() => {
			if (clientId && (moduleNavigation.fieldId || moduleNavigation.trialId)) {
				getFlightsWithFieldOrTrial();
			}
		}, [clientId]);

		useEffect(() => {
			if (currentReduxFlightId) {
				setCurrentFlightId(currentReduxFlightId);
				setCurrentFlightDate(_.find(flightNodes, { id: currentReduxFlightId })?.date);
			}
		}, [currentReduxFlightId]);

		useEffect(() => {
			if (currentFlightId && filteredNodes.length > 0) {
				setRefresh(refresh + 1);
			}
		}, [currentFlightId]);

		async function getFlightsWithFieldOrTrial() {
			const accessToken = await getTokenSilently();
			dispatch(
				fieldActions.getFlightsWithFieldOrTrial(
					clientId,
					moduleNavigation.fieldId,
					moduleNavigation.trialId,
					accessToken
				)
			)
				.then((res) => {
					setFlights(res);
				})
				.catch((err) => {
					imageFound(false);
					console.log(err);
				});
		}

		useEffect(() => {
			if (flights && flights.length > 0) {
				let fn = _.map(
					flights,
					({
						flightId,
						flightDateAndTime,
						trialId,
						orthoImageTypeId,
						sensorId,
						view,
						publishedToTrialOwner,
						publishedToTrialSponsor
					}) => {
						const opt = {
							id: flightId,
							date: moment(flightDateAndTime).local(),
							published:
								view === "Researcher" ? publishedToTrialOwner : view === "Owner" ? publishedToTrialSponsor : false,
							color:
								view === "Researcher"
									? publishedToTrialOwner
										? "#77F777"
										: "#2185D0"
									: view === "Owner"
									? publishedToTrialSponsor
										? "#77F777"
										: "#2185D0"
									: "#2185D0",
							clickable: true,
							firstActive: moduleNavigation.flightId === flightId || moduleNavigation.timelineFlightId === flightId,
							trialId: trialId,
							orthoImageTypeId: orthoImageTypeId,
							sensorId: sensorId,
							tooltip: (
								<>
									{`Flight Date & Time`} <br /> {moment(flightDateAndTime).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
								</>
							)
						};

						return opt;
					}
				);

				if (level === "trial") {
					fn = fn.filter((f) => f.trialId === moduleNavigation.trialId);
				}

				let filteredNodesClone = [];
				_.map(fn, (node) => {
					// If ortho/sensor combination hasn't been filtered, create new node obj
					// else add node to filtered list
					let filteredIndex = _.findIndex(filteredNodesClone, {
						orthoImageTypeId: node.orthoImageTypeId
					});
					if (filteredIndex === -1) {
						filteredNodesClone.push({
							orthoImageTypeId: node.orthoImageTypeId,
							nodes: [node]
						});
					} else {
						filteredNodesClone[filteredIndex].nodes.push(node);
					}
				});

				if (level === "field") {
					_.forEach(filteredNodesClone, (nodeList) => {
						nodeList.nodes = getDistinctObjects(nodeList.nodes);
					});
				}

				setFilteredNodes(filteredNodesClone);
				setFlightNodes(filteredNodesClone[0].nodes);
			}
		}, [flights]);

		useEffect(() => {
			if (filteredNodes.length > 0) {
				let filteredNodesSet = _.find(filteredNodes, {
					orthoImageTypeId: selectedOrthoImageTypeId()
				})?.nodes;

				if (filteredNodesSet?.length > 0) {
					filteredNodesSet.forEach((fns) => {
						if (fns.id === currentFlightId) {
							fns.firstActive = true;
						} else {
							fns.firstActive = false;
						}
					});

					// Refresh if nodes are the same
					let originalIds = _.map(flightNodes, "id");
					let filteredIds = _.map(filteredNodesSet, "id");

					if (_.isEqual(originalIds, filteredIds)) {
						setTempFlightNodes(filteredNodesSet);
						setFlightNodes([]);
					} else {
						setFlightNodes(filteredNodesSet);
					}
				}
			}
		}, [refresh]);

		useEffect(() => {
			if (flightNodes && flightNodes.length === 0) {
				setFlightNodes(tempFlightNodes);
				setTempFlightNodes([]);
			}
		}, [flightNodes]);

		useEffect(() => {
			if (flightId) {
				dispatch(orthoViewerActions.setCurrentFlightId(flightId));
			}

			return function cleanup() {
				dispatch(orthoViewerActions.setCurrentFlightId(null));
			};
		}, [flightId]);

		useEffect(() => {
			//-- For getting rid of the lint error for now...will need to do something when we implement trial viewer
		}, [level]);

		function updateHeatmapAnalysisId(selectedFlightId) {
			if (heatmapTimelineRef?.current) {
				let analysisOpts = getAnalysisOptions().analysisOptions;
				let matchingAnalysis = _.find(
					analysisOpts,
					(ao) => ao.orthoImageTypeId === selectedOrthoImageTypeId() && !ao.analysisName.includes("Stand Count")
				);

				if (!matchingAnalysis) {
					matchingAnalysis = _.find(analysisOpts, { orthoImageTypeId: selectedOrthoImageTypeId() });
				}

				if (
					matchingAnalysis &&
					matchingAnalysis?.analysisId !== heatmapTimelineRef.current.getSelectedAnalysisId()?.split("/")[0]
				) {
					heatmapTimelineRef.current.setSelectedAnalysisId(
						`${matchingAnalysis.analysisId}/${matchingAnalysis.analysisTypeId}`
					);
					heatmapTimelineRef.current.setSelectedFlightId(selectedFlightId);
				} else {
					heatmapTimelineRef.current.setSelectedFlightId(selectedFlightId);
				}
			}
		}

		return (
			flightNodes && (
				<>
					<Segment basic style={{ paddingTop: 5, paddingRight: "unset", paddingBottom: 30 }}>
						<Segment basic style={{ width: "90%", margin: "0 auto" }}>
							<Timelineslider
								dates={flightNodes}
								updateSelected={(selected) => {
									setFlightId(selected.id);
									updateHeatmapAnalysisId(selected.id);
								}}
							/>
						</Segment>
					</Segment>
				</>
			)
		);
	}
);

OrthoTimeline.propTypes = {
	level: PropTypes.oneOf(["field", "trial"]),
	selectedOrthoImageTypeId: PropTypes.func.isRequired,
	imageFound: PropTypes.func.isRequired,
	heatmapTimelineRef: PropTypes.object
};

export default OrthoTimeline;
