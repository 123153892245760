import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Form, Grid, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as analysisActions from "../../../../redux/actions/analysisActions";

import PropTypes from "prop-types";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import PianoChart from "./PianoChart";
import PianoChartLocationSelector from "./PianoChartLocationSelector";

const ProtocolPianoCharts = ({
	assessmentOptions,
	plannedTimingOptions,
	dataNormalizationOptions,
	treatmentData,
	trialIds
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Dropdown Options
	const [filteredDataNormalizationOptions, setFilteredDataNormalizationOptions] = useState(null);
	const [selectedAssessmentOption, setSelectedAssessmentOptions] = useState(null);
	const [selectedPlannedTimingOption, setSelectedPlannedTimingOptions] = useState(null);
	const [selectedDataNormalizationOption, setSelectedDataNormalizationOption] = useState(null);

	//-- Data Sources
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	const [rawTrialData, setRawTrialData] = useState(null);
	const [siteSpecificData, setSiteSpecificData] = useState([]);
	const [groupedSiteSpecificDataByTrial, setGroupedSiteSpecificDataByTrial] = useState([]);
	const [filteredGroupedSiteSpecificDataByTrial, setFilteredGroupedSiteSpecificDataByTrial] = useState([]);
	const [selectedAverageTreatments, setSelectedAverageTreatments] = useState(null);
	const [locations, setLocations] = useState(null);
	const [minimumAverage, setMinimumAverage] = useState(null);
	const [maximumAverage, setMaximumAverage] = useState(null);

	//Data Analysis Critera
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (plannedTimingOptions?.length > 0) {
			let timings =
				_.filter(plannedTimingOptions, (pto) => {
					return _.some(pto.grounddataassessmentids, (gdai) => {
						return gdai === selectedAssessmentOption;
					});
				}) ?? [];

			setSelectedPlannedTimingOptions(timings[0]?.key);
		}
	}, [plannedTimingOptions, selectedAssessmentOption]);

	useEffect(() => {
		let filteredDataOptions = dataNormalizationOptions;
		if (dataNormalizationOptions) {
			//-- remove NONE, % MEAN, and RANK ORDER
			filteredDataOptions = _.filter(filteredDataOptions, (fdo) => fdo.treatmenttypeid || fdo.text === "% MEAN");
		}
		if (filteredDataOptions && treatmentData) {
			const filteredValues = _.filter(filteredDataOptions, (dno) => {
				const check =
					dno.treatmenttypeid === null ||
					_.some(treatmentData, (td) => {
						return dno.treatmenttypeid == td.treatmentTypeId;
					});
				return check;
			});
			setSelectedDataNormalizationOption(filteredValues[0]?.key);
			setFilteredDataNormalizationOptions(filteredValues);
		}
	}, [dataNormalizationOptions, treatmentData]);

	useEffect(() => {
		if (assessmentOptions.length > 0) {
			const isCurveModel =
				_.find(assessmentOptions, (ao) => {
					return ao.key === selectedAssessmentOption;
				})?.iscurvemodel === String(true);

			//-- Checks if the current selected planned timing id exists in the assessment subset
			const isPlannedTimingInAssessment = _.map(
				_.filter(plannedTimingOptions, (pto) => {
					return _.some(pto.grounddataassessmentids, (gdai) => {
						return gdai === selectedAssessmentOption;
					});
				}),
				"key"
			)?.includes(selectedPlannedTimingOption);

			if ((selectedAssessmentOption && isPlannedTimingInAssessment) || isCurveModel) {
				getTrialData();
			} else {
				setLoading(false);
			}
		}
	}, [selectedAssessmentOption, selectedPlannedTimingOption]);

	useEffect(() => {
		if (rawTrialData && reduxProtocolData && selectedDataNormalizationOption) {
			calculateSiteData();
		}
	}, [rawTrialData, reduxProtocolData, selectedDataNormalizationOption]);

	useEffect(() => {
		getLocations();
	}, [groupedSiteSpecificDataByTrial]);

	useEffect(() => {
		if (siteSpecificData?.length > 0) {
			updateSiteAverages();
		}
	}, [siteSpecificData]);

	useEffect(() => {
		if (assessmentOptions?.length > 0 && selectedAssessmentOption === null) {
			setSelectedAssessmentOptions(assessmentOptions[0]?.key);
		}
	}, [assessmentOptions]);

	useEffect(() => {
		if (locations?.length > 0) {
			const siteAverages = updateSiteAverages();
			let filteredSiteSpecificData = [];
			const groupedSiteSpecificDataByTrialClone = _.cloneDeep(groupedSiteSpecificDataByTrial);
			_.map(groupedSiteSpecificDataByTrialClone, (g) => {
				let filteredData = _.filter(g, (f) => {
					let location = _.find(locations, (l) => {
						return l.trialId === f.trialId;
					});
					return location?.checked === true;
				});
				filteredSiteSpecificData.push(filteredData);
			});

			calculatePianoChart(filteredSiteSpecificData, siteAverages);
			setFilteredGroupedSiteSpecificDataByTrial(filteredSiteSpecificData);
		}
	}, [locations, groupedSiteSpecificDataByTrial]);

	function calculatePianoChart(siteData, siteAverages) {
		let minAverage = null;
		let maxAverage = null;
		_.map(siteData, (treatment) => {
			_.map(treatment, (t) => {
				const average = _.find(siteAverages, (sat) => {
					return sat.trialId == t.trialId;
				}).average;
				const averagedNumber = t.average / average;
				t.average = parseFloat(Number(averagedNumber * 100 - 100).toFixed(2));
				if (minAverage === null || minAverage > t.average) {
					minAverage = t.average;
				}

				if (maxAverage === null || maxAverage < t.average) {
					maxAverage = t.average;
				}
			});
		});
		setMinimumAverage(minAverage);
		setMaximumAverage(maxAverage);
	}

	async function getTrialData() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});
		await Promise.all(
			_.map(trialIds, (id) => {
				return dispatch(
					analysisActions.getProtocolAnalysisResultsForPianoCharts(
						userAuth.currentClientId,
						id,
						accessToken,
						assessment.isgrounddata == String(true) ? selectedAssessmentOption : "",
						assessment.isgrounddata == String(true) ? selectedPlannedTimingOption : "",
						assessment.iscurvemodel == String(true) ? assessment.curvemodelanalysisid : "",
						assessment.iscurvemodel == String(true) ? assessment.analysisid : "",
						false,
						assessment.iscurvemodel == String(true) ? assessment.analysistypeid : "",
						false,
						removeOutliers,
						removeDamagedOrExcluded,
						outlierType
					)
				).then((res) => {
					setNeedToUpdateAnalytics(false);
					return res.plotAnalysisResults;
				});
			})
		)
			.then((res) => {
				setLoading(false);
				let filteredTreatments = [];
				_.filter(res, (r) => {
					let f = _.filter(r, (trial) => {
						return trial.trialTreatmentId <= reduxProtocolData.treatments && trial.excludeFromAssessment === false;
					});
					if (f && f.length > 0) {
						filteredTreatments.push(f);
					}
				});
				setRawTrialData(filteredTreatments);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toast.error("Failed to load trial data.");
			});
	}

	function calculateSiteData() {
		let siteData = [];
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === selectedAssessmentOption;
		});
		_.map(rawTrialData, (td) => {
			let trialData = [];
			for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
				var trialTreatmentData = _.map(
					_.filter(td, (trial) => {
						return (
							trial.trialTreatmentId === i &&
							trial.excluded !== true &&
							(trial.groundDatasetAssessmentValue !== null || trial.curveModelAnalysisValue !== null)
						);
					}),
					assessment.isgrounddata == String(true) ? "groundDatasetAssessmentValue" : "curveModelAnalysisValue"
				);
				if (td?.length > 0) {
					let siteObject = {
						trialId: td[0].trialId,
						trialName: td[0].trialName,
						trialTreatmentId: i,
						average: 0,
						excluded: true,
						stateName: td[0].stateAbbreviation,
						city: td[0].city,
						assessmentName: td[0].assessmentName ?? td[0].curveModelName,
						treatmentName: _.find(td, (t) => {
							return t.trialTreatmentId === i;
						})?.treatmentName
					};

					if (trialTreatmentData.length > 0) {
						trialTreatmentData = _.map(trialTreatmentData, (data) => {
							return data;
						});
						const average = _.meanBy(trialTreatmentData);
						siteObject.average = average;

						siteObject.excluded = false;
					}

					trialData.push(siteObject);
				}
			}
			siteData = siteData.concat(trialData);
		});
		setSiteSpecificData(siteData);
		let groupedSiteData = _.groupBy(siteData, "trialTreatmentId");
		groupedSiteData = _.map(groupedSiteData, (g) => {
			return g;
		});
		setGroupedSiteSpecificDataByTrial(groupedSiteData);
	}

	function updateSiteAverages() {
		let siteAverages = [];
		const selectedDataNormalizationTreatmentType = _.find(dataNormalizationOptions, [
			"value",
			selectedDataNormalizationOption
		]);

		const currentTreatment = _.find(treatmentData, {
			treatmentTypeId: selectedDataNormalizationTreatmentType.treatmenttypeid
		});

		if (currentTreatment) {
			siteAverages = _.filter(siteSpecificData, { trialTreatmentId: currentTreatment.trialTreatmentId });
		} else if (selectedDataNormalizationTreatmentType.text === "% MEAN") {
			const groupedSiteSpecificData = _.groupBy(siteSpecificData, "trialId");
			siteAverages = _.map(groupedSiteSpecificData, (gssd) => {
				const averages = _.map(gssd, "average");
				const average = _.meanBy(averages);
				return { trialId: gssd[0].trialId, average: average };
			});
		}
		setSelectedAverageTreatments(siteAverages);
		return _.cloneDeep(siteAverages);
	}

	function getLocations() {
		let uniqTrials = _.uniqBy(siteSpecificData, "trialId");
		let locs = _.map(uniqTrials, (ut) => {
			return { trialId: ut.trialId, city: ut.city, stateName: ut.stateName, checked: true };
		});
		setLocations(locs);
	}

	function updateSelectedLocation(trialId, checked) {
		let updatedLocations = locations;
		let locationToUpdate = _.find(updatedLocations, (ul) => {
			return ul.trialId === trialId;
		});
		locationToUpdate.checked = checked;
		setLocations([...updatedLocations]);
	}

	let chartIndex = -1;
	return assessmentOptions.length < 1 ? (
		<Segment style={{ width: "100%" }}>
			<p>{"We could not find data for this protocol."}</p>
		</Segment>
	) : (
		<Segment basic>
			<Form>
				<Segment className="ribbonBanner">
					<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
						<div>
							<Form.Field>
								<label htmlFor="form-assessment">Assessment</label>
							</Form.Field>
							<Form.Select
								id="form-assessment"
								selection
								fluid
								options={assessmentOptions ?? []}
								onChange={(event, { value }) => {
									setSelectedAssessmentOptions(value);
								}}
								value={selectedAssessmentOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-planned-timing">Planned Timing</label>
							</Form.Field>
							<Form.Select
								id="form-planned-timing"
								selection
								fluid
								disabled={
									_.find(assessmentOptions, (ao) => {
										return ao.key === selectedAssessmentOption;
									})?.iscurvemodel === String(true)
								}
								options={
									_.filter(plannedTimingOptions, (pto) => {
										return _.some(pto.grounddataassessmentids, (gdai) => {
											return gdai === selectedAssessmentOption;
										});
									}) ?? []
								}
								onChange={(event, { value }) => {
									setSelectedPlannedTimingOptions(value);
								}}
								value={selectedPlannedTimingOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-data-normalization">Data normalization</label>
							</Form.Field>
							<Form.Select
								id="form-data-normalization"
								selection
								fluid
								options={filteredDataNormalizationOptions ?? []}
								onChange={(event, { value }) => {
									setSelectedDataNormalizationOption(value);
								}}
								value={selectedDataNormalizationOption}
							/>
						</div>
						<div>
							<Form.Field>
								<label htmlFor="form-assessment">Remove Exclusions</label>
							</Form.Field>
							<DataAnalysisCriteria
								removeDamagedOrExcluded={removeDamagedOrExcluded}
								removeOutliers={removeOutliers}
								outlierType={outlierType}
								setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
								setRemoveOutliers={setRemoveOutliers}
								setOutlierType={setOutlierType}
								setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
							/>
							<Popup
								trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
								content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
							/>
						</div>
						<div style={{ alignSelf: "end" }}>
							<Button
								primary={!needToUpdateAnalytics}
								negative={needToUpdateAnalytics}
								content="Update Analytics"
								onClick={() => {
									getTrialData();
								}}
								disabled={loading}
								loading={loading}
							/>
						</div>
					</div>
				</Segment>
				{loading ? (
					<Loader active inline="centered" />
				) : (
					<>
						<Grid>
							<Grid.Column width="2">
								{locations && locations?.length > 0 ? (
									<PianoChartLocationSelector locations={locations} updateSelectedLocation={updateSelectedLocation} />
								) : null}
							</Grid.Column>
							<Grid.Column width="14">
								{_.map(_.chunk(filteredGroupedSiteSpecificDataByTrial, 4), (chunk, outerIndex) => {
									chartIndex = outerIndex === 0 ? -1 : chartIndex;
									return (
										<Grid key={outerIndex}>
											{_.map(chunk, (c) => {
												chartIndex = chartIndex + 1;
												return (
													<Grid.Column width="4" key={chartIndex}>
														<PianoChart
															siteData={c}
															selectedAverageTreatments={selectedAverageTreatments}
															trialTreatmentId={groupedSiteSpecificDataByTrial[chartIndex][0]?.trialTreatmentId}
															treatmentName={groupedSiteSpecificDataByTrial[chartIndex][0]?.treatmentName}
															minimumAverage={minimumAverage}
															maximumAverage={maximumAverage}
															needToUpdateAnalytics={needToUpdateAnalytics}
														/>
													</Grid.Column>
												);
											})}
										</Grid>
									);
								})}
							</Grid.Column>
						</Grid>
					</>
				)}
			</Form>
		</Segment>
	);
};

ProtocolPianoCharts.propTypes = {
	assessmentOptions: PropTypes.array,
	plannedTimingOptions: PropTypes.array,
	dataNormalizationOptions: PropTypes.array,
	treatmentData: PropTypes.array,
	trialIds: PropTypes.array
};

export default ProtocolPianoCharts;
