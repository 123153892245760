import _ from "lodash";

export const AERIAL = "Aerial";
export const UNIFORMITY = "Uniformity";
export const STAND_COUNT = "Stand Count";

//-- Field Heatmap, Trial Heatmap, Timecourse
export function getOrthoTypes(data) {
	const filteredData = _.filter(data, (d) => {
		return !d.curveModelAnalysisId;
	});
	let orthos = _.uniqBy(filteredData, (d) => [d.analysisId, d.analysisTypeId, d.quantifiedRegionTypeId].join());

	let orthoTypes = _.map(orthos, (o) => {
		return {
			key: o.orthoImageTypeId,
			value: o.orthoImageTypeId,
			text: o.orthoImageTypeAbbr,
			analysisid: o.analysisId,
			quantifiedregiontypeid: o.quantifiedRegionTypeId,
			analysistypeid: o.analysisTypeId,
			orthoimagetypeid: o.orthoImageTypeId,
			fullname: o.orthoImageTypeAbbr,
			type: "timeSeries"
		};
	});
	//-- Remove null values
	orthoTypes = _.filter(orthoTypes, (ot) => {
		return ot.key;
	});

	return orthoTypes;
}

//-- Trial Box Whisker, Summary Table
export function getOrthoTypesForTrialAnalysis(data) {
	let orthos = _.uniqBy(data, (d) => [d.id, d.analysisTypeId, d.quantifiedRegionTypeId].join());
	let orthoTypes = _.map(orthos, (o) => {
		return {
			key: o.orthoImageTypeId,
			value: o.orthoImageTypeId,
			text: o.displayName ?? o.name,
			quantifiedregiontypeid: o.quantifiedRegionTypeId,
			analysisid: o.id,
			analysistypeid: o.analysisTypeId,
			orthoimagetypeid: o.orthoImageTypeId,
			fullname: o.displayName ?? o.name,
			type: "timeSeries"
		};
	});
	//-- Remove null values
	orthoTypes = _.filter(orthoTypes, (ot) => {
		return ot.key;
	});

	return orthoTypes;
}

//-- Field Heatmap, Trial Heatmap
export function getGroundDataPresets(groundData) {
	let anlyss = _.filter(groundData, (gd) => {
		return gd.assessmentId;
	});
	let idsWithSubsamples = _.uniq(
		_.map(
			_.filter(anlyss, (a) => a.subsample && a.subsample > 1),
			"assessmentId"
		)
	);

	let gd = _.filter(anlyss, (a) => !idsWithSubsamples.includes(a.assessmentId));
	gd = _.uniqBy(gd, "assessmentId");

	let presets = _.map(gd, (g) => {
		return { key: g.assessmentId, value: g.assessmentId, text: g.assessmentName, type: "groundData" };
	});

	return presets;
}

//-- Trial Box Whisker, Summary Table
export function getGroundDataPresetsForTrialAnalysis(groundData) {
	let gd = _.filter(groundData, (gd) => {
		return gd.id;
	});
	gd = _.uniqBy(gd, "id");

	let presets = _.map(gd, (g) => {
		return { key: g.id, value: g.id, text: g.name, type: "groundData" };
	});

	return presets;
}

//-- Trial Box Whisker, Summary Table
export function getTimeSeriesPresetsForTrialAnalysis(timeSeriesData) {
	let gd = _.filter(timeSeriesData, (gd) => {
		return gd.id;
	});
	gd = _.uniqBy(gd, "quantifiedRegionTypeId");

	let presets = _.map(gd, (g) => {
		return { key: g.id, analysistypeid: g.analysisTypeId, quantifiedregiontypeid: g.quantifiedRegionTypeId, value: g.id, text: g.name, type: "timeSeries" };
	});

	return presets;
}

//-- Field Heatmap, Trial Heatmap
export function getGrowthCurvePresets(growthCurves) {
	let uniqGrowthCurves = _.uniqBy(growthCurves, "curveModelName");

	//-- Remove null values
	uniqGrowthCurves = _.filter(uniqGrowthCurves, (gc) => {
		return gc.curveModelName;
	});
	let presets = _.map(uniqGrowthCurves, (gc) => {
		return {
			key: gc.curveModelName,
			value: gc.curveModelName,
			text: gc.curveModelName,
			type: "growthCurve",
			analysisid: gc.analysisId,
			analysistypeid: gc.analysisTypeId,
			curvemodelanalysisid: gc.curveModelAnalysisId,
			quantifiedregiontypeid: gc.quantifiedRegionTypeId,
			curvemodelid: gc.curveModelId
		};
	});

	return presets;
}

export function getDefaultQuantifiedRegionType(quantifiedRegionTypes) {
	return (
		_.find(quantifiedRegionTypes, (q) => {
			return q.text === "Plot-Centered with Subsamples";
		})?.value ?? quantifiedRegionTypes[0]?.value
	);
}

export function getSummaryTableCurveModelPresetName(rawPresetOptions, availableGrowthCurves, growthCurve) {
	const gc = _.find(availableGrowthCurves, (agc) => {
		return agc.curveModelName === growthCurve.id;
	});

	if (!gc) {
		return "Failed to load growth curve";
	}
	const preset = _.find(rawPresetOptions, (rpo) => {
		return (
			rpo.analysisId === gc.analysisId &&
			rpo.curveModelAnalysisId === gc.curveModelAnalysisId &&
			rpo.analysisTypeId === gc.analysisTypeId &&
			(rpo.quantifiedRegionTypeId === gc.quantifiedRegionTypeId ||
				rpo.secondaryQuantifiedRegionTypeId === gc.quantifiedRegionTypeId)
		);
	});
	return preset?.name ?? growthCurve.name;
}

export function isPlotUniformity(rawAnalysisTypes, analysisTypeId) {
	const analysisType = _.find(rawAnalysisTypes, (rat) => {
		return rat.id === analysisTypeId;
	});

	return analysisType?.name === UNIFORMITY;
}
