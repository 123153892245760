import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function orthoViewerReducer(state = initialState.orthoViewer, action) {
	switch (action.type) {
		case types.OV_SET_CURRENT_FLIGHT_ID:
			return { ...state, currentFlightId: action.flightId };

		default:
			return state;
	}
}
