import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./sliderComponents";

import { format, isEqual, addDays } from "date-fns";
import { Icon } from "semantic-ui-react";

const TimelineSlider = ({ dates = [], updateSelected = () => {} }) => {
	const minuteInMs = 1000 * 60; //-- 1000 ms in a second * 60 seconds in a minute

	const [workingDates, setWorkingDates] = useState([]);

	const [isValid, setIsValid] = useState(false);
	const [earliestDate, setEarliestDate] = useState(0);
	const [latestDate, setLatestDate] = useState(0);

	const [selectedId, setSelectedId] = useState(0);
	const [selectedRealDate, setSelectedRealDate] = useState(0);
	const [selectedValue, setSelectedValue] = useState(0);
	const [selectedPercent, setselectedPercent] = useState(0);

	// EXAMPLE NODE BEING PASSED IN VIA THE DATES ARRAY
	// const timelineNode = {
	// 	id: Guid,
	// 	date: moment(date).local(),
	// 	color: "#4183c4",
	// 	clickable: true,
	// 	firstActive: moduleNavigation.flightId/trialId === id ? true : false,
	// 	tooltip: (
	// 		<>
	// 			{`Flight Date & Time`} <br /> {moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
	// 		</>
	// 	)
	// };

	useEffect(() => {
		if (!dates) {
			setIsValid(false);
			console.warn("Timeline Slider was supplied with a null object. Timeline Slider will be hidden.");
		}

		//-- react-compound-slider will throw an error if the min and max values are the same
		if (dates.length === 1 && (typeof dates[0]?.date !== "object" || dates[0]?.date.date())) {
			dates[0].date = +dates[0].date;
			dates[0].timelineDate = +dates[0].date;
			setSelectedId(dates[0].id);
			setSelectedRealDate(format(new Date(dates[0].date), "MMM dd, yyyy"));
			setSelectedValue(dates[0].timelineDate);
			updateSelected(dates[0]);
			setEarliestDate(dates[0].timelineDate);
			setLatestDate(dates[0].timelineDate + 1);

			setWorkingDates(dates);
			setIsValid(true);
		} else if (dates.length > 0) {
			//-- Sort by date (earliest to latest)
			dates = dates.sort((a, b) => {
				return a.date - b.date;
			});

			try {
				let lastId = "";
				let lastDate = new Date();
				let lastTimelineDate = {};
				let duplicateDateCounter = 0;

				dates.forEach((d) => {
					duplicateDateCounter++;

					if ((!d.id && d.id !== "0") || !d.date || !d.color) {
						throw `Missing data: ${JSON.stringify(d)}`;
					}

					//-- Check for duplicate Ids
					if (d.id === lastId) {
						throw `Duplicate Id found: ${d.id}`;
					}

					//-- Find set timelineDate value taking into consideration duplicated dates (spead them out across the timeline so the user can see all dates, but the value of each data point will be accurate)
					const dateObj = new Date(d.date);
					dateObj.setHours(0, 0, 0, 0);
					// console.log(`${lastDate}   --  ${dateObj}  --  ${lastDate.valueOf() === dateObj.valueOf()}`);
					isEqual(lastDate, dateObj) || isEqual(lastTimelineDate, dateObj)
						? (duplicateDateCounter += 1)
						: (duplicateDateCounter += 0);

					// console.log(duplicateDateCounter);

					const tDateObj = addDays(dateObj, duplicateDateCounter);
					d.timelineDate = +tDateObj;

					d.date = +d.date;
					lastId = d.id;
					lastDate = dateObj;
					lastDate.setHours(0, 0, 0, 0);
					lastTimelineDate = tDateObj;
					lastTimelineDate.setHours(0, 0, 0, 0);
				});

				let firstActiveDate = dates.find(({ firstActive }) => firstActive);
				if (!firstActiveDate) {
					firstActiveDate = dates[dates.length - 1];
				}

				setSelectedId(firstActiveDate.id);
				setSelectedRealDate(format(new Date(firstActiveDate.date), "MMM dd, yyyy"));
				setSelectedValue(firstActiveDate.timelineDate);
				updateSelected(firstActiveDate);
				setEarliestDate(dates[0].timelineDate);
				setLatestDate(dates[dates.length - 1].timelineDate);

				setWorkingDates(dates);
				setIsValid(true);
			} catch (ex) {
				console.warn(`TimelineSlider encoutered an error: ${ex}. TimelineSlider will be hidden.`);
				console.warn(dates);
				setIsValid(false);
			}
		}
	}, [dates]);

	function slide(ms) {
		//-- TODO: eliminate this workaround https://github.com/facebook/react/issues/18147
		setTimeout(() => {
			if (ms) {
				const min = earliestDate;
				const max = latestDate - min;

				//-- Find the value in the array that's closest to the slider value the user chose
				const closest = workingDates
					.filter(({ clickable }) => clickable)
					.reduce((prev, curr) => (Math.abs(curr.timelineDate - ms) < Math.abs(prev.timelineDate - ms) ? curr : prev));
				const percent = ((closest.timelineDate - min) / max) * 100;

				setSelectedId(closest.id);
				setSelectedRealDate(format(new Date(closest.date), "MMM dd, yyyy"));
				setSelectedValue(closest.timelineDate);
				updateSelected(closest);
				setselectedPercent(percent);

				// console.log(
				//   `id: ${closest.id}   -   timelineDate: ${new Date(closest.timelineDate)}   -   realDate: ${new Date(
				//     closest.realDate
				//   )}`
				// );
			}
		});
	}

	function slideFirst() {
		if (dates.length > 0) {
			const first = workingDates[0];
			slide(first.timelineDate);
		}
	}

	function slidePrevious() {
		if (dates.length > 0) {
			let currentIndex = -1;

			workingDates.find(({ id }, index) => {
				if (id === selectedId) {
					currentIndex = index;
					return true;
				} else {
					return false;
				}
			});

			if (currentIndex === 0) {
				return;
			}

			if (currentIndex > -1 && currentIndex <= workingDates.length - 1) {
				const next = workingDates[currentIndex - 1];
				slide(next.timelineDate);
			}
		}
	}

	function slideNext() {
		if (dates.length > 0) {
			let currentIndex = -1;

			workingDates.find(({ id }, index) => {
				if (id === selectedId) {
					currentIndex = index;
					return true;
				} else {
					return false;
				}
			});

			if (currentIndex !== -1 && currentIndex < workingDates.length - 1) {
				const next = workingDates[currentIndex + 1];
				slide(next.timelineDate);
			}
		}
	}

	function slideLast() {
		if (dates.length > 0) {
			const last = workingDates[workingDates.length - 1];
			slide(last.timelineDate);
		}
	}

	//-- AG: Not sure why I have to do values={[selectedValue + 1]}, but it works, so I'm going with it
	return (
		isValid && (
			<>
				<Slider
					mode={1}
					step={minuteInMs}
					domain={[+earliestDate, +latestDate]}
					rootStyle={{ position: "relative", width: "100%" }}
					onUpdate={slide}
					onChange={slide}
					values={[selectedValue + 1]}
				>
					<Icon
						name="step backward"
						size="large"
						onClick={() => slideFirst()}
						style={{ position: "absolute", left: -90, top: -10, cursor: "pointer" }}
					/>
					<Icon
						name="angle left"
						size="large"
						onClick={() => slidePrevious()}
						style={{ position: "absolute", left: -70, top: -10, cursor: "pointer" }}
					/>
					<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
					<Handles>
						{({ handles, getHandleProps }) => (
							<div>
								{handles.map((handle) => {
									handle.percent = selectedPercent;
									handle.id = selectedId;
									handle.date = selectedRealDate;
									return (
										<Handle
											key={handle.id}
											handle={handle}
											domain={[+earliestDate, +latestDate]}
											getHandleProps={getHandleProps}
										/>
									);
								})}
							</div>
						)}
					</Handles>
					<Tracks left={true} right={false}>
						{({ tracks, getTrackProps }) => {
							return (
								<div>
									{tracks.map(({ id, source, target }) => {
										target.value = selectedValue;
										target.percent = selectedPercent;
										return <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />;
									})}
								</div>
							);
						}}
					</Tracks>
					<Ticks values={workingDates.map(({ timelineDate }) => timelineDate)}>
						{({ ticks }) => {
							ticks.forEach((t, i) => {
								t.id = workingDates[i].id;
								t.value = workingDates[i].date;
								t.color = workingDates[i].color;
								t.tooltip = workingDates[i].tooltip;
								t.marginTopMultiplier = 1;
							});
							return (
								<div>
									{ticks.map((tick) => {
										return (
											<Tick
												key={tick.id}
												tick={tick}
												count={ticks.length}
												format={(ms) => format(new Date(ms), "MMM dd, yy")}
												color={tick.color}
											/>
										);
									})}
								</div>
							);
						}}
					</Ticks>
					<Icon
						name="angle right"
						size="large"
						onClick={() => slideNext()}
						style={{ position: "absolute", right: -70, top: -10, cursor: "pointer" }}
					/>
					<Icon
						name="step forward"
						size="large"
						onClick={() => slideLast()}
						style={{ position: "absolute", right: -90, top: -10, cursor: "pointer" }}
					/>
				</Slider>
			</>
		)
	);
};

TimelineSlider.propTypes = {
	dates: PropTypes.array,
	updateSelected: PropTypes.func
};

export default TimelineSlider;
