import React, { useEffect } from "react";
import { Segment, Button, Loader } from "semantic-ui-react";

import { useAuth0 } from "../auth0";
import { useUserAuth } from "../../hooks/useUserAuth";
import { useModuleNavigation } from "../../hooks/useModuleNavigation";

const Login = () => {
	const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		//-- If the user is logged in and manually goes to /login, redirect them to the home page b/c they weren't trying to access any other part of the app
		if (isAuthenticated && userAuth.isReady) {
			userAuth.goHome();
		}
	}, [isAuthenticated, userAuth.isReady]);

	return loading ? (
		<Loader active />
	) : (
		<Segment textAlign="center">
			<h1>aerialPLOT</h1>
			<Button onClick={() => moduleNavigation.goToSupport()}>Support</Button>
			<Button primary onClick={() => loginWithRedirect()}>
				Login
			</Button>
		</Segment>
	);
};

export default Login;
