// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button,\ninput[type=\"number\"] {\n  -webkit-appearance: none;\n  margin: 0;\n  -moz-appearance: textfield;\n}\n\n#unitConverterForm .disabled,\n#unitConverterForm .disabled * {\n  opacity: 1 !important;\n  color: #000000 !important;\n  border: unset !important;\n}\n\n#unitOneValueInput,\n#unitTwoValueInput {\n  width: 50%;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/UnitConverter/styles.css"],"names":[],"mappings":"AAAA;;;EAGE,wBAAwB;EACxB,SAAS;EACT,0BAA0B;AAC5B;;AAEA;;EAEE,qBAAqB;EACrB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;;EAEE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":["input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button,\ninput[type=\"number\"] {\n  -webkit-appearance: none;\n  margin: 0;\n  -moz-appearance: textfield;\n}\n\n#unitConverterForm .disabled,\n#unitConverterForm .disabled * {\n  opacity: 1 !important;\n  color: #000000 !important;\n  border: unset !important;\n}\n\n#unitOneValueInput,\n#unitTwoValueInput {\n  width: 50%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unitConverterForm": "unitConverterForm",
	"disabled": "disabled",
	"unitOneValueInput": "unitOneValueInput",
	"unitTwoValueInput": "unitTwoValueInput"
};
export default ___CSS_LOADER_EXPORT___;
