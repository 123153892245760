import React, { useRef, useState } from "react";
import { Grid, Input, Button, Icon, Popup, Modal } from "semantic-ui-react";

import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";

import * as fieldActions from "../../../../redux/actions/fieldActions";
import ShapeFileMapboxComponent from "./shapeFileMapboxComponent";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

const ShapeFileUpload = ({ saveShapeFileCoordinates }) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	const shpFileInputRef = useRef();
	const shxFileInputRef = useRef();

	//-- Data
	const [shpFile, setShpFile] = useState(null);
	const [shxFile, setShxFile] = useState(null);
	const [shapeFilePoints, setShapeFilePoints] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(false);
	const [showShapeFileModal, setShowShapeFileModal] = useState(false);
	const [savingShapeFilePoints, setSavingShapeFilePoints] = useState(false);

	function handleShpFileBrowse() {
		shpFileInputRef.current.click();
	}

	function handleShxFileBrowse() {
		shxFileInputRef.current.click();
	}

	function shpFileSelectedHandler(event) {
		event.persist();

		if (event.target.files.length > 0) {
			const shpFileData = event.target.files[0];
			setShpFile(shpFileData);
		}
	}

	function shxFileSelectedHandler(event) {
		event.persist();

		if (event.target.files.length > 0) {
			const shxFileData = event.target.files[0];
			setShxFile(shxFileData);
		}
	}

	function shpFileRemovedHandler() {
		//-- Need to set the value of the file input to null. Otherwise, the onChange event won't fire if you try selecting another file.
		document.getElementById("shpFileUpload").value = null;
		setShpFile(null);
	}

	function shxFileRemovedHandler() {
		//-- Need to set the value of the file input to null. Otherwise, the onChange event won't fire if you try selecting another file.
		document.getElementById("shxFileUpload").value = null;
		setShxFile(null);
	}

	async function getShapeFilePoints() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getShapeFilePoints(shpFile, shxFile, accessToken))
			.then((res) => {
				setLoading(false);
				setShapeFilePoints(res.data);
				setShowShapeFileModal(true);
			})
			.catch((err) => {
				toast.error("Failed to get points from Shape File. Please ensure that the .shp and .shx match.");
				setLoading(false);
				console.log(err);
			});
	}

	function saveShapeFileSelectionsModal() {
		setSavingShapeFilePoints(true);
	}

	function saveShapeFileSelections(lowerLeftPoint, upperLeftPoint) {
		saveShapeFileCoordinates(lowerLeftPoint, upperLeftPoint);
		setShowShapeFileModal(false);
		setSavingShapeFilePoints(false);
	}

	return (
		<>
			<Grid columns="16">
				<Grid.Row columns="16" verticalAlign="bottom">
					<Grid.Column width={8}>
						<label style={{ display: "unset" }}>Shape File</label>
						<Popup
							content="Shape File Upload. File extension is .shp"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input type="text" action readOnly value={shpFile?.name ?? ""}>
							<Button
								content="Browse..."
								labelPosition="left"
								icon="file"
								style={{ borderTopRightRadius: "unset", borderBottomRightRadius: "unset" }}
								onClick={() => {
									handleShpFileBrowse();
								}}
							/>
							<input style={{ borderTopLeftRadius: "unset", borderBottomLeftRadius: "unset" }} />
							<Button icon onClick={shpFileRemovedHandler}>
								<Icon name="trash" />
							</Button>
						</Input>
						<input
							id="shpFileUpload"
							ref={shpFileInputRef}
							type="file"
							accept=".shp"
							hidden
							onChange={shpFileSelectedHandler}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row verticalAlign="bottom" style={{ padding: "unset" }}>
					<Grid.Column width={8}>
						<label style={{ display: "unset" }}>Shx File</label>
						<Popup
							content="Shx File Upload. File extension is .shx"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input type="text" action readOnly value={shxFile?.name ?? ""}>
							<Button
								content="Browse..."
								labelPosition="left"
								icon="file"
								style={{ borderTopRightRadius: "unset", borderBottomRightRadius: "unset" }}
								onClick={() => {
									handleShxFileBrowse();
								}}
							/>
							<input style={{ borderTopLeftRadius: "unset", borderBottomLeftRadius: "unset" }} />
							<Button icon onClick={shxFileRemovedHandler}>
								<Icon name="trash" />
							</Button>
						</Input>
						<input
							id="shxFileUpload"
							ref={shxFileInputRef}
							type="file"
							accept=".shx"
							hidden
							onChange={shxFileSelectedHandler}
						/>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 20, paddingRight: "unset" }}>
						<Button
							content="Upload Files"
							loading={loading}
							disabled={!shpFile || !shxFile || loading}
							style={{ height: 37.8, paddingTop: 6 }}
							onClick={getShapeFilePoints}
						/>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 20, paddingRight: "unset" }}>
						<Modal
							trigger={
								<Button
									content="Shape File Results"
									loading={loading}
									disabled={loading || !shapeFilePoints}
									style={{ height: 37.8, paddingTop: 6 }}
									onClick={() => setShowShapeFileModal(true)}
								/>
							}
							open={showShapeFileModal}
							size="fullscreen"
							centered
						>
							<Modal.Header>{"Shape File Results"}</Modal.Header>
							<Modal.Content scrolling>
								<Modal.Description></Modal.Description>
								<ShapeFileMapboxComponent
									shapeFilePoints={shapeFilePoints}
									savingShapeFilePoints={savingShapeFilePoints}
									saveShapeFilePoints={saveShapeFileSelections}
								/>
							</Modal.Content>
							<Modal.Actions>
								<Button onClick={() => saveShapeFileSelectionsModal()}>Save</Button>
								<Button onClick={() => setShowShapeFileModal(false)}>Close</Button>
							</Modal.Actions>
						</Modal>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

ShapeFileUpload.propTypes = {
	saveShapeFileCoordinates: PropTypes.func
};

export default ShapeFileUpload;
