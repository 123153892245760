export const supportedUnitTypes = [
  { unitType: "acceleration", displayText: "Acceleration", enabled: false },
  { unitType: "angle", displayText: "Angle", enabled: false },
  {
    unitType: "apparentPower",
    displayText: "Apparent Power",
    enabled: false
  },
  { unitType: "area", displayText: "Area", enabled: false },
  { unitType: "charge", displayText: "Charge", enabled: false },
  { unitType: "current", displayText: "Current", enabled: false },
  { unitType: "digital", displayText: "Digital", enabled: false },
  { unitType: "each", displayText: "Dach", enabled: false },
  { unitType: "energy", displayText: "Energy", enabled: false },
  { unitType: "force", displayText: "Force", enabled: false },
  { unitType: "frequency", displayText: "Frequency", enabled: false },
  { unitType: "illuminance", displayText: "Illuminance", enabled: false },
  { unitType: "length", displayText: "Length", enabled: true },
  { unitType: "mass", displayText: "Mass", enabled: false },
  { unitType: "pace", displayText: "Pace", enabled: false },
  { unitType: "partsPer", displayText: "Parts Per", enabled: false },
  { unitType: "power", displayText: "power", enabled: false },
  { unitType: "pressure", displayText: "Pressure", enabled: false },
  {
    unitType: "reactiveEnergy",
    displayText: "Reactive Energy",
    enabled: false
  },
  {
    unitType: "reactivePower",
    displayText: "Reactive Power",
    enabled: false
  },
  { unitType: "speed", displayText: "Speed", enabled: false },
  { unitType: "temperature", displayText: "Temperature", enabled: false },
  { unitType: "time", displayText: "Time", enabled: false },
  { unitType: "voltage", displayText: "Voltage", enabled: false },
  { unitType: "volume", displayText: "Volume", enabled: false },
  {
    unitType: "volumeFlowRate",
    displayText: "Volume Flow Rate",
    enabled: false
  }
];

export default {
  supportedUnitTypes
};
