import * as types from "./actionTypes";
import * as sensorApi from "../../apis/sensorApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getSensorSuccess() {
	return { type: types.SENSOR_GET_SENSOR_SUCCESS };
}

export function getSensorDetailsSuccess() {
	return { type: types.SENSOR_GET_SENSOR_DETAILS_SUCCESS };
}

export function createSensorSuccess() {
	return { type: types.SENSOR_CREATE_SUCCESS };
}

export function updateSensorSuccess() {
	return { type: types.SENSOR_UPDATE_SUCCESS };
}

export function deleteSensorSuccess() {
	return { type: types.SENSOR_DELETE_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getSensors(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return sensorApi
				.getSensors(accessToken, clientId)
				.then((res) => {
					dispatch(getSensorSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getSensorDetailsData(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return sensorApi
				.getSensorDetailsData(accessToken, clientId)
				.then((res) => {
					dispatch(getSensorDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createSensor(accessToken, sensor) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return sensorApi
				.createSensor(accessToken, sensor)
				.then((res) => {
					dispatch(createSensorSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateSensor(accessToken, sensor) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return sensorApi
				.updateSensor(accessToken, sensor)
				.then((res) => {
					dispatch(updateSensorSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteSensor(accessToken, sensorId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return sensorApi
				.deleteSensor(accessToken, sensorId)
				.then((res) => {
					dispatch(deleteSensorSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
