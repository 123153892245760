import * as types from "./actionTypes";
import * as statisticsApi from "../../apis/statisticsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getMeanComparisonForAucSuccess() {
	return { type: types.STATISTICS_GET_MEAN_COMPARISON_FOR_AUC_SUCCESS };
}

export function resetMeanComparisonData() {
	return { type: types.RESET_TIMECOURSE_MEAN_COMPARISON_DATA };
}

export function getMeanComparisonForTimecourseSuccess() {
	return {
		type: types.STATISTICS_GET_MEAN_COMPARISON_FOR_TIMECOURSE_SUCCESS
	};
}

export function getMeanComparisonOptionsSuccess() {
	return { type: types.STATISTICS_GET_MEAN_COMPARISON_OPTIONS_SUCCESS };
}

export function getCompiledTukeyDataSuccess() {
	return { type: types.STATISTICS_GET_COMPILED_TUKEY_DATA_SUCCESS };
}

export function getProtocolSingleTrialTukeyDataSuccess() {
	return { type: types.STATISTICS_GET_PROTOCOL_SINGLE_TRIAL_TUKEY_DATA_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getMeanComparisonForAuc(aucData, clientId, meanComparison, alphaId, isDataUnbalanced, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return statisticsApi
				.getMeanComparisonForAuc(aucData, clientId, meanComparison, alphaId, isDataUnbalanced, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getMeanComparisonForAucSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getMeanComparisonForTimecourse(
	timecourseData,
	clientId,
	analysisId,
	meanComparisonId,
	alphaId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return statisticsApi
				.getMeanComparisonForTimecourse(
					timecourseData,
					clientId,
					analysisId,
					meanComparisonId,
					alphaId,
					accessToken,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getMeanComparisonForTimecourseSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCompiledTukeyData(data, alphaId, meanComparisonId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return statisticsApi
				.getProtocolTukeyData(data, alphaId, meanComparisonId, clientId, accessToken)
				.then((res) => {
					if (res.error) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getCompiledTukeyDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolSingleTrialTukeyData(data, alphaId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return statisticsApi
				.getProtocolTukeyData(data, alphaId, clientId, accessToken)
				.then((res) => {
					if (res.error) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getProtocolSingleTrialTukeyDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getMeanComparisonsOptions(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return statisticsApi
				.getMeanComparisonsOptions(clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getMeanComparisonOptionsSuccess(res));
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
