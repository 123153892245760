import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";

import _ from "lodash";

const AucBoxWhiskerChart = ({
	data,
	allData,
	analysisName,
	meanComparison,
	alphaValue,
	runMeanComparison,
	needToUpdateAnalytics,
	isInitialLoad,
	selectedAnalysisOption,
	groundTrialDataChecked,
	initialGroupTrialDataCheckedCount,
	initialFlightDataCheckedCount,
	flightDataChecked,
	needToRerunStats,
	timeSeriesPresets,
	setIsBoxWhiskerChartLoading,
	boxWhiskerMeanComparisonDataAuc,
	boxWhiskerAllMeanComparisonDataAuc,
	selectedAnalysisType,
	disableColors
}) => {
	const CanvasJSChart = CanvasJSReact.CanvasJSChart;

	//-- Data
	const [meanComparisonId, setMeanComparisonId] = useState(null);
	const [alphaId, setAlphaId] = useState(null);
	const [chartOptions, setChartOptions] = useState([]);
	const [noData, setNoData] = useState(true);

	//-- UI Control
	const [chartLoading, setChartLoading] = useState(true);

	const colors = !disableColors
		? [
				"#920000",
				"#006DDB",
				"#B66DFF",
				"#24FF24",
				"#FFFF6D",
				"#004949",
				"#009292",
				"#FF6DB6",
				"#FFB6DB",
				"#490092",
				"#924900",
				"#DB6D00",
				"#6DB6FF",
				"#B6DBFF",
				"#000000"
		  ]
		: ["#920000"];
	const shapes = ["circle"];
	let colorIdx = 0;
	let shapeIdx = 0;

	useEffect(() => {
		if (meanComparison && !needToUpdateAnalytics) {
			setMeanComparisonId(meanComparison);
		}
	}, [meanComparison]);

	useEffect(() => {
		if (alphaValue && !needToUpdateAnalytics) {
			setAlphaId(alphaValue);
		}
	}, [alphaValue]);

	useEffect(() => {
		if (!_.isEmpty(boxWhiskerMeanComparisonDataAuc) && isInitialLoad) {
			stageChartOptions(boxWhiskerMeanComparisonDataAuc);
		}
	}, [boxWhiskerMeanComparisonDataAuc, disableColors]);

	useEffect(() => {
		if (data.length > 0) {
			setNoData(false);
			if (runMeanComparison === true && meanComparisonId && alphaId) {
				let tempAnalysisTypeId = _.find(timeSeriesPresets, (rpo) => rpo.name === analysisName)?.analysisTypeId;
				tempAnalysisTypeId = tempAnalysisTypeId ?? selectedAnalysisType;

				let filteredFooterData = _.filter(boxWhiskerAllMeanComparisonDataAuc, (ar) => {
					return ar[0] === selectedAnalysisOption && ar[1] === tempAnalysisTypeId;
				});
				if (!_.isEmpty(filteredFooterData)) {
					let chartData = filteredFooterData[0];
					stageChartOptions(chartData[2]);
				} else {
					stageChartOptions(null);
				}
			} else {
				if (_.isEmpty(boxWhiskerMeanComparisonDataAuc) || boxWhiskerMeanComparisonDataAuc === null) {
					stageChartOptions(null);
				}
			}
		} else {
			setNoData(true);
			setChartLoading(false);
			setIsBoxWhiskerChartLoading(false);
		}
	}, [data, meanComparisonId, alphaId]);

	//Use Effect to swap chart data based on assessment selection
	useEffect(() => {
		if (alphaValue && meanComparison) {
			if (!_.isEmpty(boxWhiskerAllMeanComparisonDataAuc)) {
				let tempAnalysisTypeId = _.find(timeSeriesPresets, (rpo) => rpo.name === analysisName)?.analysisTypeId;
				tempAnalysisTypeId = tempAnalysisTypeId ?? selectedAnalysisType;

				let filteredFooterData = _.filter(boxWhiskerAllMeanComparisonDataAuc, (ar) => {
					return ar[0] === selectedAnalysisOption && ar[1] === tempAnalysisTypeId;
				});
				if (!_.isEmpty(filteredFooterData)) {
					let chartData = filteredFooterData[0];
					stageChartOptions(chartData[2]);
				}
			}
		}
	}, [groundTrialDataChecked, alphaValue, meanComparison]);

	const getNextMarker = () => {
		const marker = { color: colors[colorIdx], shape: shapes[shapeIdx] };
		if (colorIdx + 1 >= colors.length) {
			colorIdx = 0;
			//shapeIdx++;
		} else {
			colorIdx++;
		}
		return marker;
	};

	function stageChartOptions(results) {
		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});
		let filteredAllData = [];
		let filteredAllDataByAnalyisType = [];
		let tempAnalysisTypeId = _.find(timeSeriesPresets, (rpo) => rpo.name === analysisName)?.analysisTypeId;
		tempAnalysisTypeId = tempAnalysisTypeId ?? selectedAnalysisType;

		filteredAllDataByAnalyisType = _.filter(allData, (ar) => {
			return ar.analysisTypeId === tempAnalysisTypeId;
		});

		_.filter(filteredAllDataByAnalyisType, (ad) => {
			_.filter(ad, (a) => {
				if (a.analysisId === selectedAnalysisOption) {
					filteredAllData.push(a);
				}
			});
		});

		let uniqueTreatmentIds = _.uniqBy(filteredAllData.length > 0 ? filteredAllData : data, "trialTreatmentId");
		uniqueTreatmentIds = _.map(uniqueTreatmentIds, (uti) => {
			return uti.trialTreatmentId;
		});
		data = _.filter(filteredAllData.length > 0 ? filteredAllData : data, (res) => {
			return !_.includes(treatmentIds, res.trialTreatmentId);
		});

		let isAnyTreatmentDeselected = false;
		let isAnyFlightDeselected = false;

		let checkedTreatmentCountUpdated = _.map(groundTrialDataChecked, (gtdc) => {
			return gtdc === true;
		});

		let checkedFlightsCountUpdated = _.map(flightDataChecked, (fdc) => {
			return fdc.checked === true;
		});

		//Logic to set the flag to remove all data based on flight unchecking
		if (
			!_.isEqual(initialFlightDataCheckedCount, checkedFlightsCountUpdated) &&
			initialFlightDataCheckedCount.length !== 0 &&
			initialFlightDataCheckedCount.length === checkedFlightsCountUpdated.length
		) {
			isAnyFlightDeselected = true;
		} else {
			if (
				_.some(flightDataChecked, (ut) => !ut.checked) &&
				!_.isEqual(initialFlightDataCheckedCount, checkedFlightsCountUpdated)
			) {
				isAnyFlightDeselected = true;
			} else {
				isAnyFlightDeselected = false;
			}
		}

		//This case handles removing letters on the fly. This case handles the after initial page load deselections.
		if (
			!_.isEqual(initialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
			initialGroupTrialDataCheckedCount.length !== 0
		) {
			isAnyTreatmentDeselected = true;
		}

		let scatterPlots = {};
		let boxWhiskerData = [];
		let meanComparisonLetterData = [];
		let groupedTreatments = _.groupBy(data, "trialTreatmentId");
		let maxTreatmentId = 0;
		if (data[0]?.maxTreatmentId == 0) {
			let temp = _.maxBy(data, "trialTreatmentId");
			maxTreatmentId = temp.trialTreatmentId;
		} else {
			maxTreatmentId = data[0]?.maxTreatmentId;
		}
		if (maxTreatmentId === undefined) {
			maxTreatmentId = _.max(uniqueTreatmentIds);
		}
		for (let i = 1; i < maxTreatmentId + 1; i++) {
			if (_.map(data, "trialTreatmentId").includes(i) && !isAnyFlightDeselected) {
				let areas = [];
				_.map(groupedTreatments[i], (treatment) => {
					areas.push(treatment.totalAuc);
					if (!scatterPlots[treatment.plotReplicate]) {
						scatterPlots[treatment.plotReplicate] = [];
					}
					scatterPlots[treatment.plotReplicate].push({
						x: treatment.trialTreatmentId,
						y: treatment.totalAuc,
						plotName: treatment.plotName
					});
				});
				const median = medianBy(areas);
				const lowerValues = getValuesAboveOrBelowMedian(areas, median, false);
				const higherValues = getValuesAboveOrBelowMedian(areas, median, true);
				const q1 = Number(medianBy(lowerValues).toFixed(5));
				const q3 = Number(medianBy(higherValues).toFixed(5));
				let min = Number(Math.min(...areas).toFixed(5));
				let max = Number(Math.max(...areas).toFixed(5));
				boxWhiskerData.push({
					label: i,
					x: i,
					y: [min, q1, q3, max, median]
				});
			} else {
				boxWhiskerData.push({
					label: i,
					x: i,
					y: null
				});
			}
		}

		if (results && !isAnyFlightDeselected && !needToRerunStats && !_.isEmpty(data)) {
			let tempLetteringPairs = _.filter(results.letteringPairs, (res) => {
				return !_.includes(treatmentIds, res.treatmentId);
			});
			tempLetteringPairs = _.filter(tempLetteringPairs, (res) => {
				return _.includes(uniqueTreatmentIds, res.treatmentId);
			});
			let allLettersAreA = _.map(tempLetteringPairs, "letter").every((letters) => letters === "a");

			for (let i = 1; i < maxTreatmentId + 1; i++) {
				let treatmentExists = _.findIndex(tempLetteringPairs, { treatmentId: i }) !== -1;
				if (!treatmentExists) {
					tempLetteringPairs.push({
						treatmentId: i,
						letter: null
					});
				}
			}

			let sortedLetters = _.orderBy(tempLetteringPairs, ["treatmentId"], ["asc"]);

			//This case handles removing letters on the fly. This case handles the initial page load.
			if (initialGroupTrialDataCheckedCount.length === 0) {
				isAnyTreatmentDeselected = _.some(sortedLetters, (sl) => {
					return sl.letter === null;
				});
			}

			_.map(sortedLetters, ({ treatmentId, letter }) => {
				meanComparisonLetterData.push({
					label: isAnyTreatmentDeselected
						? " "
						: allLettersAreA && letter !== null
						? "-"
						: letter === null
						? " "
						: letter,
					y: null,
					x: treatmentId
				});
			});
		} else {
			for (var i = 0; i < maxTreatmentId; i++) {
				meanComparisonLetterData.push({
					label: " ",
					y: null,
					x: i
				});
			}
		}

		let opt = {
			animationEnabled: true,
			theme: "light2",
			axisY: {
				title: analysisName,
				includeZero: false
			},
			axisX: {
				title: "Treatment",
				titeFontSize: 20,
				includeZero: false,
				interval: 1
			},
			axisX2: {
				includeZero: false,
				interval: 1,
				labelAngle: 90
			},
			data: [
				{
					type: "boxAndWhisker",
					color: "black",
					upperBoxColor: "#DAE3F3",
					lowerBoxColor: "#67A2DB",
					indexLabelFontSize: 16,
					dataPoints: boxWhiskerData
				},
				(meanComparisonId ?? meanComparison) && runMeanComparison
					? {
							axisXType: "secondary",
							name: "Replicates",
							color: "#00000000",
							showInLegend: !disableColors,
							type: "line",
							dataPoints: meanComparisonLetterData
					  }
					: null
			]
		};

		for (let [i, sp] of Object.entries(scatterPlots)) {
			const marker = getNextMarker();
			opt.data.push({
				type: "scatter",
				color: marker.color,
				markerType: marker.shape,
				markerBorderColor: "black",
				markerBorderThickness: 1,
				name: `${i}`,
				toolTipContent: `<b>Plot: </b>{plotName} <br/><b>Treatment: </b>{x} <br/><b>Replicate: </b>${i} <br/><b>Value: </b>{y}`,
				showInLegend: !disableColors,
				dataPoints: sp
			});
		}

		opt.data = _.filter(opt.data, (d) => {
			return d !== null;
		});
		setChartOptions(opt);
		setChartLoading(false);
		setIsBoxWhiskerChartLoading(false);
	}

	function medianBy(array) {
		array.sort((a, b) => b - a);
		const length = array.length;
		if (length % 2 == 0) {
			return (array[length / 2] + array[length / 2 - 1]) / 2;
		} else {
			return array[Math.floor(length / 2)];
		}
	}

	function getValuesAboveOrBelowMedian(values, median, aboveMedian) {
		if (!values || values.length === 0) return 0;

		values.sort(function (a, b) {
			return a - b;
		});

		var half = Math.floor(values.length / 2);

		if (values.length % 2) {
			return aboveMedian ? values.slice(half + 1, values.length) : values.slice(0, half);
		}

		return aboveMedian
			? _.filter(values, (value) => {
					return value > median;
			  })
			: _.filter(values, (value) => {
					return value < median;
			  });
	}

	return (
		<Segment basic loading={chartLoading}>
			{noData == true ? (
				<p> There is no data selected for the box and whisker chart.</p>
			) : (
				<CanvasJSChart options={chartOptions} />
			)}
		</Segment>
	);
};

AucBoxWhiskerChart.propTypes = {
	data: PropTypes.array,
	allData: PropTypes.array,
	analysisName: PropTypes.string,
	meanComparison: PropTypes.string,
	alphaValue: PropTypes.string,
	runMeanComparison: PropTypes.bool,
	needToUpdateAnalytics: PropTypes.bool,
	isInitialLoad: PropTypes.bool,
	selectedAnalysisOption: PropTypes.string,
	groundTrialDataChecked: PropTypes.array,
	initialGroupTrialDataCheckedCount: PropTypes.array,
	initialFlightDataCheckedCount: PropTypes.array,
	flightDataChecked: PropTypes.array,
	needToRerunStats: PropTypes.bool,
	timeSeriesPresets: PropTypes.array,
	setIsBoxWhiskerChartLoading: PropTypes.func,
	boxWhiskerMeanComparisonDataAuc: PropTypes.array,
	boxWhiskerAllMeanComparisonDataAuc: PropTypes.array,
	selectedAnalysisType: PropTypes.string,
	disableColors: PropTypes.bool
};
export default AucBoxWhiskerChart;
