import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Segment, Table, Loader } from "semantic-ui-react";
import _ from "lodash";

import {
	NEUTRAL_CORRELATION_COLOR,
	NEGATIVE_CORRELATION_COLOR,
	POSITIVE_CORRELATION_COLOR
} from "../CorrelationColors";

import CorrelationColorGradient from "../CorrelationColorGradient";
import calculateOverlappingCorrelationValue from "../CalculateOverlappingCorrelationValue";
import "./style.scss";

const AerialLYTXCorrelationMatrix = ({ growthCurveData, anchorAssessmentData, colors }) => {
	const [growthSectionShown, setGrowthSectionShown] = useState(false);
	const [senescenceSectionShown, setSenescenceSectionShown] = useState(false);
	const [fullSeasonSectionShown, setFullSeasonSectionShown] = useState(false);
	const [loadingShownSections, setLoadingShownSections] = useState(true);

	// 1. When the growth curve data changes, determine which sections ought to be shown.
	useEffect(() => {
		determineShownSections();
	}, [growthCurveData]);

	function determineShownSections() {
		let growthDataPresent = _.some(growthCurveData, { curveModelName: "Growth" });
		growthDataPresent ? setGrowthSectionShown(true) : setGrowthSectionShown(false);

		let scenescenceDataPresent = _.some(growthCurveData, { curveModelName: "Senescence" });
		scenescenceDataPresent ? setSenescenceSectionShown(true) : setSenescenceSectionShown(false);

		let fullSeasonDataPresent = _.some(growthCurveData, { curveModelName: "Full Season" });
		fullSeasonDataPresent ? setFullSeasonSectionShown(true) : setFullSeasonSectionShown(false);

		setLoadingShownSections(false);
	}

	function buildGrowthTable() {
		let tableStyle = "aerial-correlation-table five-cell-wide";
		let headerColumnWidth = "5";
		let elementToReturn = (
			<Table celled structured className={tableStyle}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell colSpan={headerColumnWidth} className="table-header-cell">
							Growth
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell></Table.HeaderCell>
						<Table.HeaderCell>RATE</Table.HeaderCell>
						<Table.HeaderCell>MAX</Table.HeaderCell>
						<Table.HeaderCell>DAYS TO 50%</Table.HeaderCell>
						<Table.HeaderCell>AUC</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{buildGrowthRows()}</Table.Body>
			</Table>
		);

		return elementToReturn;
	}

	function buildGrowthRows() {
		let orthoGroupedData = _.groupBy(growthCurveData, "abbreviation");
		let growthRowElements = _.map(orthoGroupedData, (ogd) => {
			let key = ogd[0].abbreviation;
			let currentRowCells = [];
			let headerCell = (
				<Table.HeaderCell key={key + "-header-cell"} title={key}>
					{key}
				</Table.HeaderCell>
			);
			currentRowCells.push(headerCell);
			currentRowCells.push(buildCorrelationCell("Growth", "Growth Rate", key));
			currentRowCells.push(buildCorrelationCell("Growth", "Max Growth", key));
			currentRowCells.push(buildCorrelationCell("Growth", "Days to 50% Max", key));
			currentRowCells.push(buildCorrelationCell("Growth", "Growth AUC", key));

			let currentRow = <Table.Row>{currentRowCells}</Table.Row>;
			return currentRow;
		});

		return growthRowElements;
	}

	function buildSenescenceTable() {
		let tableStyle = "";
		let headerColumnWidth = "";
		let columnHeaders = [];
		if (!growthSectionShown) {
			tableStyle = "aerial-correlation-table five-cell-wide";
			headerColumnWidth = "5";
			columnHeaders = [
				<Table.HeaderCell key="senescence-empty-header-cell"></Table.HeaderCell>,
				<Table.HeaderCell key="senescence-rate-header-cell">RATE</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-max-header-cell">MAX</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-days-to-fifty-header-cell">DAYS TO 50%</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-auc-header-cell">AUC</Table.HeaderCell>
			];
		} else {
			tableStyle = "aerial-correlation-table four-cell-wide";
			headerColumnWidth = "4";
			columnHeaders = [
				<Table.HeaderCell key="senescence-rate-header-cell">RATE</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-max-header-cell">MAX</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-days-to-fifty-header-cell">DAYS TO 50%</Table.HeaderCell>,
				<Table.HeaderCell key="senescence-auce-header-cell">AUC</Table.HeaderCell>
			];
		}

		let elementToReturn = (
			<Table celled structured className={tableStyle}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell colSpan={headerColumnWidth} className="table-header-cell">
							Senescence
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row>{columnHeaders}</Table.Row>
				</Table.Header>
				<Table.Body>{buildSenescenceRows()}</Table.Body>
			</Table>
		);

		return elementToReturn;
	}

	function buildSenescenceRows() {
		let orthoGroupedData = _.groupBy(growthCurveData, "abbreviation");
		let senescenceRowElements = _.map(orthoGroupedData, (ogd) => {
			let key = ogd[0].abbreviation;
			let currentRowCells = [];
			if (!growthSectionShown) {
				let headerCell = (
					<Table.HeaderCell key={key + "-header-cell"} title={key}>
						{key}
					</Table.HeaderCell>
				);
				currentRowCells.push(headerCell);
			}
			currentRowCells.push(buildCorrelationCell("Senescence", "Senescence Rate", key));
			currentRowCells.push(buildCorrelationCell("Senescence", "Max Senescence", key));
			currentRowCells.push(buildCorrelationCell("Senescence", "Days to 50% Senescence", key));
			currentRowCells.push(buildCorrelationCell("Senescence", "Senescence AUC", key));

			let currentRow = <Table.Row>{currentRowCells}</Table.Row>;
			return currentRow;
		});

		return senescenceRowElements;
	}

	function buildFullSeasonTable() {
		let tableStyle = "";
		let headerColumnWidth = "";
		let columnHeaders = [];
		if (!growthSectionShown && !senescenceSectionShown) {
			tableStyle = "aerial-correlation-table full-season three-cell-wide";
			headerColumnWidth = "3";
			columnHeaders = [
				<Table.HeaderCell key="full-season-empty-header-cell" colSpan></Table.HeaderCell>,
				<Table.HeaderCell key="full-season-cpp-header-cell">CPP</Table.HeaderCell>,
				<Table.HeaderCell key="full-season-auce-header-cell">AUC</Table.HeaderCell>
			];
		} else {
			tableStyle = "aerial-correlation-table full-season two-cell-wide";
			headerColumnWidth = "2";
			columnHeaders = [
				<Table.HeaderCell key="full-season-cpp-header-cell">CPP</Table.HeaderCell>,
				<Table.HeaderCell key="full-season-auc-header-cell">AUC</Table.HeaderCell>
			];
		}

		let elementToReturn = (
			<Table celled structured className={tableStyle}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell colSpan={headerColumnWidth} className="table-header-cell">
							Full Season
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row>{columnHeaders}</Table.Row>
				</Table.Header>
				<Table.Body>{buildFullRows()}</Table.Body>
			</Table>
		);

		return elementToReturn;
	}

	function buildFullRows() {
		let orthoGroupedData = _.groupBy(growthCurveData, "abbreviation");

		let fullRowElements = _.map(orthoGroupedData, (ogd) => {
			let key = ogd[0].abbreviation;

			let currentRowCells = [];
			if (!growthSectionShown && !senescenceSectionShown) {
				let headerCell = (
					<Table.HeaderCell key={key + "-header-cell"} title={key}>
						{key}
					</Table.HeaderCell>
				);
				currentRowCells.push(headerCell);
			}
			currentRowCells.push(buildCorrelationCell("Full Season", "Crop Production Potential", key));
			currentRowCells.push(buildCorrelationCell("Full Season", "Full Season AUC", key));

			let currentRow = <Table.Row>{currentRowCells}</Table.Row>;

			return currentRow;
		});

		return fullRowElements;
	}

	// Build a semantic Table.Cell for a given curve model and analysis.
	// EX: "Growth" as the curve model and "Growth Rate" as the analysis.
	function buildCorrelationCell(curveModelName, curveModelAnalysisName, targetOrtho) {
		let rawObjects = _.filter(growthCurveData, (gcd) => {
			return (
				gcd.curveModelName === curveModelName &&
				gcd.curveModelAnalysisName === curveModelAnalysisName &&
				gcd.abbreviation === targetOrtho &&
				gcd.isGDU === false &&
				!gcd.displayName.includes("Unif")
			);
		});

		let rawValues = _.map(rawObjects, (ro) => {
			return {
				plotRange: ro.plotRange,
				plotColumn: ro.plotColumn,
				value: ro.value,
				plotId: ro.plotId,
				excludeFromAssessment: ro.excludeFromAssessment
			};
		});

		let correlationValue = calculateOverlappingCorrelationValue(rawValues, anchorAssessmentData);

		let correlationCellColor = CorrelationColorGradient(
			colors.negativeColor,
			colors.neutralColor,
			colors.positiveColor,
			-1,
			1,
			correlationValue
		);

		let correlationCell = (
			<Table.Cell
				key={curveModelName + "-" + curveModelAnalysisName + "-correlation-cell"}
				style={{
					backgroundColor:
						"rgb(" + correlationCellColor.red + "," + correlationCellColor.green + "," + correlationCellColor.blue + ")"
				}}
			>
				{isNaN(correlationValue) ? "-" : parseFloat(correlationValue.toFixed(3))}
			</Table.Cell>
		);

		return correlationCell;
	}

	return (
		<Segment basic style={{ overflow: "auto" }}>
			{loadingShownSections ? (
				<Loader active={true} />
			) : (
				<>
					{growthSectionShown ? buildGrowthTable() : <></>}
					{senescenceSectionShown ? buildSenescenceTable() : <></>}
					{fullSeasonSectionShown ? buildFullSeasonTable() : <></>}
				</>
			)}
		</Segment>
	);
};

AerialLYTXCorrelationMatrix.propTypes = {
	growthCurveData: PropTypes.array,
	anchorAssessmentData: PropTypes.array,
	colors: PropTypes.object
};

AerialLYTXCorrelationMatrix.defaultProps = {
	growthCurveData: [],
	anchorAssessmentData: [],
	colors: {
		negativeColor: NEGATIVE_CORRELATION_COLOR,
		neutralColor: NEUTRAL_CORRELATION_COLOR,
		positiveColor: POSITIVE_CORRELATION_COLOR
	}
};

export default AerialLYTXCorrelationMatrix;
