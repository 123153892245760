import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Form,Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as orthoImageTypeActions from "../../../../redux/actions/orthoImageTypeActions";

const OrthoImageTypeManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const [clientName, setClientName] = useState(null);
	const userAuth = useUserAuth();
	const dispatch = useDispatch();	

	//-- Data Source
	const [orthoImageTypes, setOrthoImageTypes] = useState(null);
	const [selectedOrthoImageType, setSelectedOrthoImageType] = useState(null);

	//-- UI Control
	// const [mode, setMode] = useState("update");	
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			setClientName(userAuth.user.userInfo.clientMemberships.find((cm) => cm.clientId === clientId).clientName);
			getOrthoImageTypeDetails(clientId);
		}
	}, [userAuth.isReady, clientId]);

	useEffect(() => {
		if (orthoImageTypes !== null ) 
		{
			setLoading(false);
		}
	}, [orthoImageTypes]);

	const getOrthoImageTypeDetails = async (clientId) => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(orthoImageTypeActions.getOrthoImageTypeDetails(clientId, accessToken))
			.then((res) => {
				console.log(res)
				setOrthoImageTypes(res.data)
			})
			.catch(() => {
				toast.error("Unable to load user info. Please try again.");
				setLoading(false);
			});
	}

	const orthoImageTypeSelected = (orthoImageTypeId, orthoImageTypeName) => {
		if (orthoImageTypeId || orthoImageTypeName) {
			const selOrthoImageType = orthoImageTypes.find((o) => o.id === orthoImageTypeId || o.name === orthoImageTypeName);
			setSelectedOrthoImageType(selOrthoImageType);
		}
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Ortho Image Type Management</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{clientName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
			<Form>
				<Grid id="user-management-grid" columns="equal">
					<Grid.Row>
						<Grid.Column width={4}>
							<label htmlFor="form-select-person" className="fieldLabel">
								Ortho Image Type
							</label>
							<List
								id="form-select-ortho-image-type"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 500,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(orthoImageTypes, ({id, name }) => {
									return (
										<List.Item
											key={id}
											active={selectedOrthoImageType?.id === id}
											onClick={() => {
												orthoImageTypeSelected(id, name);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-ortho-image-type-name"
									width={6}
									label="Name"
									value={selectedOrthoImageType?.name ?? ""}
									required
									// onChange={(event) => setSelectedOrthoImageType({ ...selectedOrthoImageType, name: event.target.value })}
								/>
								<Form.Input
									id="form-ortho-image-type-type"
									width={6}
									label="Type"
									value={selectedOrthoImageType?.type ?? ""}
									required
									// onChange={(event) => setSelectedOrthoImageType({ ...selectedOrthoImageType, type: event.target.value })}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
							<Form.Input
									id="form-ortho-image-type-abbreviation"
									width={6}
									label="Abbreviation"
									value={selectedOrthoImageType?.abbreviation ?? ""}
									required
									// onChange={(event) => setSelectedOrthoImageType({ ...selectedOrthoImageType, abbreviation: event.target.value })}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
							<Form.Input
									id="form-ortho-image-type-description"
									width={6}
									label="Description"
									value={selectedOrthoImageType?.description ?? ""}
									required
									// onChange={(event) => setSelectedOrthoImageType({ ...selectedOrthoImageType, description: event.target.value })}
								/>
							</Form.Group>
							<hr style={{ clear: "both" }} />
							<Form.Group>
								<Form.Checkbox
									id="form-ortho-image-type-enabled"
									width={3}
									label="Enabled?"
									style={{ marginTop: 33 }}
									checked={selectedOrthoImageType?.enabled ?? false}
									// onChange={(event, data) => {
									// 	setSelectedOrthoImageType({ ...selectedOrthoImageType, enabled: data.checked });
									// }}
								/>
							</Form.Group>
							<Form.Group widths="equal" style={{ marginTop: 50 }}>
								{/* <Form.Button floated="right" color="green" content="Save" onClick={() => saveUser()} /> */}
							</Form.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
			)}
		</Segment>
	);
};

export default  OrthoImageTypeManagement;
