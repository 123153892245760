import * as types from "./actionTypes";
import * as fieldApi from "../../apis/fieldApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getFieldsSuccess(fields) {
	return { type: types.FIELD_GET_FIELDS_SUCCESS, fields };
}

export function getUsedFieldsSuccess(fields) {
	return { type: types.FIELD_GET_USED_FIELDS_SUCCESS, fields };
}

export function getUnusedFieldsSuccess(fields) {
	return { type: types.FIELD_GET_UNUSED_FIELDS_SUCCESS, fields };
}

export function saveNewFieldSuccess(field) {
	return { type: types.FIELD_SAVE_NEW_FIELD_SUCCESS, field };
}

export function getFieldInfoSuccess() {
	return { type: types.FIELD_GET_FIELD_INFO_SUCCESS };
}

export function getFieldSuccess() {
	return { type: types.FIELD_GET_FIELD_SUCCESS };
}

export function editFieldSuccess() {
	return { type: types.FIELD_EDIT_FIELD_SUCCESS };
}

export function deleteFieldSuccess() {
	return { type: types.FIELD_DELETE_FIELD_SUCCESS };
}

export function getFieldSearchDataOptionsSuccess() {
	return { type: types.FIELD_GET_FIELD_SEARCH_DATA_OPTIONS_SUCCESS };
}

export function searchForFieldsSuccess() {
	return { type: types.FIELD_SEARCH_FOR_FIELDS_SUCCESS };
}

export function getFlightsWithFieldOrTrialSuccess(flights) {
	return { type: types.FIELD_GET_FLIGHTS_WITH_FIELD_OR_TRIAL_SUCCESS, flights };
}

export function deleteFieldInFlightSuccess() {
	return { type: types.FIELD_DELETE_FIELD_IN_FLIGHT_SUCCESS };
}

export function getFieldOutlineDataSuccess() {
	return { type: types.FIELD_GET_OUTLINE_DATA_SUCCESS };
}

export function getFieldAndPlotsSuccess() {
	return { type: types.FIELD_GET_FIELD_AND_PLOTS_SUCCESS };
}

export function getFieldsInFlightInformationSuccess() {
	return { type: types.FIELD_GET_FIELDS_IN_FLIGHT_INFORMATION_SUCCESS };
}

export function getShapeFilePointsSuccess() {
	return { type: types.FIELD_GET_SHAPE_FILE_POINTS_SUCCESS };
}

export function getFieldLayoutInfoSuccess() {
	return { type: types.FIELD_GET_FIELD_LAYOUT_INFO_SUCCESS };
}

export function editFieldLayoutSuccess() {
	return { type: types.FIELD_EDIT_FIELD_LAYOUT_SUCCESS };
}

export function getFieldAdvancedAnalyticsDataSuccess() {
	return { type: types.FIELD_GET_FIELD_ADVANCED_ANALYTICS_DATA_SUCCESS };
}

export function saveFieldQuantifiedRegionsSuccess() {
	return { type: types.FIELD_SAVE_FIELD_QUANTIFIED_REGIONS_SUCCESS };
}

export function getNewFieldInfoSuccess() {
	return { type: types.FIELD_GET_NEW_FIELD_INFO_SUCCESS };
}

export function saveFieldCoordinatesSuccess() {
	return { type: types.FIELD_SAVE_FIELD_COORDINATES_SUCCESS };
}

export function getDefaultViewerSettingForFieldSuccess() {
	return { type: types.FIELD_GET_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS };
}

export function createDefaultViewerSettingForFieldSuccess() {
	return { type: types.FIELD_CREATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS };
}

export function updateDefaultViewerSettingForFieldSuccess() {
	return { type: types.FIELD_UPDATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS };
}

export function getPlotAnalysisStatusForFieldSuccess() {
	return { type: types.FIELD_GET_PLOT_ANALYSIS_STATUS_FOR_FIELD_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getFields(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldsInFlight(flightId, clientId, accessToken)
				.then((fields) => {
					dispatch(getFieldsSuccess(fields));
					resolve(fields);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getUsedFields(flightId, orthoImageTypeId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getUsedFieldsInFlight(flightId, orthoImageTypeId, clientId, accessToken)
				.then((fields) => {
					dispatch(getUsedFieldsSuccess(fields));
					resolve(fields);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getUnusedFields(clientId, flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getUnusedFieldsInFlight(clientId, flightId, accessToken)
				.then((fields) => {
					dispatch(getUnusedFieldsSuccess(fields));
					resolve(fields);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldInfo(clientId, flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldInfo(clientId, flightId, accessToken)
				.then((res) => {
					dispatch(getFieldSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveNewField(field, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.saveNewField(field, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(saveNewFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getField(fieldId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getField(fieldId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(saveNewFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function editField(field, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.editField(field, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(editFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteField(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.deleteField(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.ok === false) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(deleteFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldSearchDataOptions(clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldSearchDataOptions(clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldSearchDataOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function searchForFields(searchParams, clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.searchForFields(searchParams, clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(searchForFieldsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFlightsWithFieldOrTrial(clientId, fieldId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFlightsWithFieldOrTrial(clientId, fieldId, trialId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFlightsWithFieldOrTrialSuccess(res));
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldOutlineData(clientId, fieldId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldOutlineData(clientId, fieldId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldOutlineDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFieldInFlight(flightId, fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.deleteFieldInFlight(flightId, fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(deleteFieldInFlightSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldAndPlots(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldAndPlots(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldAndPlotsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldsInFlightInfomration(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldsInFlightOrthoInformation(flightId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldsInFlightInformationSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getShapeFilePoints(shpFile, shxFile, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getShapeFilePoints(shpFile, shxFile, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getShapeFilePointsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldLayoutInfo(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldLayoutInfo(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldAndPlotsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function editFieldLayout(field, fieldLayout, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.editFieldLayout(field, fieldLayout, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(editFieldLayoutSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFieldAdvancedAnalyticsData(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getFieldAdvancedAnalyticsData(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFieldAdvancedAnalyticsDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getNewFieldInfo(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getNewFieldInfo(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getNewFieldInfoSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveFieldQuantifiedRegions(
	fieldId,
	clientId,
	quantifiedRegionsToCreate,
	quantifiedRegionsToUpdate,
	quantifiedRegionsToDelete,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.saveFieldQuantifiedRegions(
					fieldId,
					clientId,
					quantifiedRegionsToCreate,
					quantifiedRegionsToUpdate,
					quantifiedRegionsToDelete,
					accessToken
				)
				.then((res) => {
					if (res.errors || res.statusCode !== 200) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(saveFieldQuantifiedRegionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveFieldCoordinates(fieldId, llLat, ulLat, llLong, ulLong, degreeHeading, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.saveFieldCoordinates(fieldId, llLat, ulLat, llLong, ulLong, degreeHeading, clientId, accessToken)
				.then((res) => {
					if (res?.errors || res.statusCode !== 200) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(saveFieldCoordinatesSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getDefaultViewerSettingForField(fieldId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getDefaultViewerSettingForField(fieldId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getDefaultViewerSettingForFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createDefaultViewerSettingForField(
	clientId,
	fieldId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.createDefaultViewerSettingForField(
					clientId,
					fieldId,
					toggleHeatmapData,
					toggleImageType,
					viewableFlightId,
					viewableOrthoImageTypeId,
					groundDatasetId,
					groundDataAssessmentId,
					curveModelAnalysisId,
					analysisIdForCurveModel,
					analysisId,
					analysisFlightId,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(createDefaultViewerSettingForFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateDefaultViewerSettingForField(
	clientId,
	id,
	fieldId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.updateDefaultViewerSettingForField(
					clientId,
					id,
					fieldId,
					toggleHeatmapData,
					toggleImageType,
					viewableFlightId,
					viewableOrthoImageTypeId,
					groundDatasetId,
					groundDataAssessmentId,
					curveModelAnalysisId,
					analysisIdForCurveModel,
					analysisId,
					analysisFlightId,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(updateDefaultViewerSettingForFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPlotAnalysisStatusForField(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return fieldApi
				.getPlotAnalysisStatusForField(fieldId, clientId, accessToken)
				.then((res) => {
					dispatch(getPlotAnalysisStatusForFieldSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
