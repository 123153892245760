import React, { useState, useEffect } from "react";
import { Segment, Table, Dropdown, Pagination, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";

const AssociatedTrialsTable = ({ trialList, removeAssociatedTrial, saving }) => {
	const DATE_FORMAT = "MM/DD/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState(trialList);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- Initial view load
	useEffect(() => {
		if (trialList) {
			setTotalPages(Math.ceil(trialList.length / itemsPerPage));
			setDataSource(trialList);
			setTableData(dataSource);
		}
	}, [trialList]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn]).format(DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	};

	return dataSource.length === 0 ? (
		<></>
	) : (
		<Segment basic>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "trialName" ? sortDirection : null}
							onClick={() => handleSort("trialName")}
						>
							Trial Name
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "trialOwnerFirstName" ? sortDirection : null}
							onClick={() => handleSort("trialOwnerFirstName")}
						>
							Trial Owner
						</Table.HeaderCell>

						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "state" ? sortDirection : null}
							onClick={() => handleSort("state")}
						>
							State
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "cooperator" ? sortDirection : null}
							onClick={() => handleSort("cooperator")}
						>
							Cooperator
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "numberOfTreatments" ? sortDirection : null}
							onClick={() => handleSort("numberOfTreatments")}
						>
							# Treatments
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="center"
							sorted={sortColumn === "plantDate" ? sortDirection : null}
							onClick={() => handleSort("plantDate")}
						>
							Plant Date
						</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(
						tableData,
						({
							trialId,
							trialName,
							trialOwnerFirstName,
							trialOwnerLastName,
							stateName,
							cooperatorName,
							numberOfTreatments,
							plantDate
						}) => {
							return (
								<Table.Row key={trialId}>
									<Table.Cell>{trialName}</Table.Cell>
									<Table.Cell>
										{trialOwnerFirstName} {trialOwnerLastName}
									</Table.Cell>
									<Table.Cell>{stateName}</Table.Cell>
									<Table.Cell>{cooperatorName}</Table.Cell>
									<Table.Cell>{numberOfTreatments}</Table.Cell>
									<Table.Cell>{plantDate ? moment(plantDate).local().format(DATE_FORMAT) : ""}</Table.Cell>
									<Table.Cell>
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={<p>Delete</p>}
											transitionName="rc-tooltip-zoom"
										>
											<Icon
												name="trash alternate"
												size="large"
												style={{ cursor: "pointer" }}
												disabled={saving}
												onClick={() => {
													var copy = [...dataSource];
													copy = _.filter(copy, (c) => {
														return c.trialId !== trialId;
													});
													setDataSource(copy);
													removeAssociatedTrial(trialId);
												}}
											/>
										</Tooltip>
									</Table.Cell>
								</Table.Row>
							);
						}
					)}
				</Table.Body>
				<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="12">
							<span style={{ marginRight: 10 }}>
								{dataSource.length === 1 ? `${dataSource.length} trial` : `${dataSource.length} trials`}
							</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
							<Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
								value={itemsPerPage}
								compact
								style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</Segment>
	);
};

AssociatedTrialsTable.propTypes = {
	trialList: PropTypes.array,
	removeAssociatedTrial: PropTypes.func,
	saving: PropTypes.bool
};

export default AssociatedTrialsTable;
