import React, { useEffect } from "react";

import { Loader } from "semantic-ui-react";

import { useUserAuth } from "../../../hooks/useUserAuth";

const Home = () => {
	const userAuth = useUserAuth();
	useEffect(() => {
		if (userAuth.isReady) {
			userAuth.goHome();
		}
	}, [userAuth.isReady]);

	return <Loader active />;
};

export default Home;
