export function logIn(accessToken) {
	return fetch(process.env.aerialPlotConnections.aerialPlotApi.baseUrl + "api/v1/Users/Login", {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getPersons(accessToken) {
	return fetch(process.env.aerialPlotConnections.aerialPlotApi.baseUrl + "api/v1/Users/GetPersons", {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getPersonsByClient(accessToken, clientId) {
	return fetch(
		process.env.aerialPlotConnections.aerialPlotApi.baseUrl + `api/v1/Users/GetPersonsByClient?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getPersonDetail(accessToken, personId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/GetPersonInfo?personId=${personId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updatePerson(accessToken, person, clientId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/UpdatePerson?clientId=${clientId}`,
		{
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(person)
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updatePersonUser(accessToken, personUserObject) {
	const personFormData = new FormData();

	for (const name in personUserObject) {
		if (name !== "enabledExternalIds") {
			let val = personUserObject[name] === "" ? null : personUserObject[name];

			if (val) {
				if (typeof val === "string") val = val?.trim();
				personFormData.set(name, val);
			}
		} else {
			let index = 0;
			for (const eId of personUserObject.enabledExternalIds) {
				personFormData.append(`UserExternalIds[${index}]`, eId);
				index++;
			}
		}
	}

	return fetch(`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/UpdatePersonUser`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: personFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createNewPerson(accessToken, person, clientId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/createNewPerson?clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(person)
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
export function createUserForPerson(accessToken, personId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/CreateUserForPerson?personId=${personId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createNewUser(accessToken, newUserObject) {
	const userFormData = new FormData();

	for (const name in newUserObject) {
		let val = newUserObject[name] === "" ? null : newUserObject[name];

		if (val) {
			if (typeof val === "string") val = val?.trim();
			userFormData.set(name, val);
		}
	}

	return fetch(`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/CreateNewUser`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: userFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deletePerson(accessToken, personId, clientId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/DeletePerson?personId=${personId}&clientId=${clientId}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function emailLoginInstructions(accessToken, userId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Users/EmailLoginInstructions?userId=${userId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
