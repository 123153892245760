const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getGroundDataInfo(fieldId, trialId, cropId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/GroundData?fieldId=${fieldId}&trialId=${trialId}&clientId=${clientId}${
			cropId ? `&cropId=${cropId}` : ""
		}`,
		{
			headers: {
				method: "GET",
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createGroundData(groundDataCsv, newGroundDataSetsVm, outlineData, trialId, accessToken) {
	const groundDataSetsFormData = new FormData();
	let datasetIndex = 0;
	let index = 0;

	for (const name in newGroundDataSetsVm) {
		let val = newGroundDataSetsVm[name] === "" ? null : newGroundDataSetsVm[name];
		if (typeof val !== "object") {
			groundDataSetsFormData.set(name, val);
		}
	}

	if (groundDataCsv) {
		groundDataSetsFormData.append("groundDataCsv", groundDataCsv, groundDataCsv.name);
	}

	groundDataSetsFormData.set("trialId", trialId ?? "");

	for (const newGroundDataVm of newGroundDataSetsVm.groundDataSets) {
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.DateCollected`,
			newGroundDataVm.groundDataset?.actualDateCollected &&
				newGroundDataVm.groundDataset?.actualDateCollected !== "Invalid date"
				? newGroundDataVm.groundDataset?.actualDateCollected
				: ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.DatasetNumericalId`,
			newGroundDataVm.datasetNumericalId ?? ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.CollectedByPersonId`,
			newGroundDataVm.groundDataset?.collectedByPersonId ?? ""
		);
		groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.FieldId`, outlineData[0].fieldId);
		groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.TrialId`, trialId ?? "");
		groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.Crop`, outlineData[0].crop);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataTimingMethodId`,
			newGroundDataVm.groundDataset?.selectedTimingMethodId ?? ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GrowthStageId`,
			newGroundDataVm.groundDataset?.growthStageId && newGroundDataVm.groundDataset?.growthStageId !== null
				? newGroundDataVm.groundDataset?.growthStageId
				: ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataGrowthPhaseId`,
			newGroundDataVm.groundDataset?.growthPhaseId ?? ""
		);
		if (newGroundDataVm.groundDataset?.trialsToIncludeForField?.length > 0) {
			let trialIdIndex = 0;
			for (const trialIds of newGroundDataVm.groundDataset?.trialsToIncludeForField) {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.TrialsToIncludeForField[${trialIdIndex}]`,
					trialIds
				);
				trialIdIndex++;
			}
		}

		if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Date") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseDate`,
				newGroundDataVm.groundDataset?.plannedTiming && newGroundDataVm.groundDataset?.plannedTiming !== "Invalid date"
					? newGroundDataVm.groundDataset?.plannedTiming
					: ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "StartEnd") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseEndStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageEnd ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "int") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseInteger`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Text") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseText`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Start") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
		}

		index = 0;
		for (const data of newGroundDataVm.assessments) {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].DateCollected`,
				newGroundDataVm.groundDataset?.currentDateCollected &&
					newGroundDataVm.groundDataset?.currentDateCollected !== "Invalid date"
					? newGroundDataVm.groundDataset?.currentDateCollected
					: ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Name`,
				data.assessment.name
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataAssessmentId`,
				data.assessment.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].AverageFor`,
				data?.assessment?.averageFor ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataUnitId`,
				data.unit.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleId`,
				data.samplesPerPlot?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SampleDefinitionNumber`,
				data.sampleDefinitionNumber?.name ?? "1"
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SamplesPerPlot`,
				data.samplesPerPlot?.name ?? "1"
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataDeviceId`,
				data.device?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataMethodId`,
				data.method.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceId`,
				data.source.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceName`,
				data.source.name
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionTypeId`,
				data.groundDataSampleDefinitionPart?.groundDataSampleDefinitionTypeId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionPartId`,
				data.groundDataSampleDefinitionPart?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDatasetTemplateId`,
				data.templateId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Width`,
				data.width ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Length`,
				data.length ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Radius`,
				data.radius ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Order`,
				index + 1
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Notes`,
				data.notes ?? ""
			);
			index++;
		}

		datasetIndex++;
	}

	index = 0;
	for (const data of outlineData) {
		groundDataSetsFormData.append(`PlotInfo[${index}].PlotId`, data.plotId);
		groundDataSetsFormData.append(`PlotInfo[${index}].Range`, data.range);
		groundDataSetsFormData.append(`PlotInfo[${index}].Column`, data.column);
		groundDataSetsFormData.append(`PlotInfo[${index}].TrialId`, data.trialId ?? "");
		groundDataSetsFormData.append(`PlotInfo[${index}].FieldId`, data.fieldId);
		index++;
	}

	index = 0;
	for (const validation of newGroundDataSetsVm.groundDataValidation) {
		groundDataSetsFormData.append(`GroundDataValidation[${index}].AssessmentName`, validation.assessmentName);
		groundDataSetsFormData.append(`GroundDataValidation[${index}].DataType`, validation.dataType);
		groundDataSetsFormData.append(`GroundDataValidation[${index}].NumOfPlots`, validation.numOfPlots);

		index++;
	}

	return fetch(`${aerialApiUri}api/v1/GroundData`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: groundDataSetsFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function inspectGroundData(groundDataCsv, newGroundDataSetsVm, outlineData, accessToken) {
	const groundDataSetsFormData = new FormData();
	let datasetIndex = 0;
	let index = 0;

	for (const name in newGroundDataSetsVm) {
		let val = newGroundDataSetsVm[name] === "" ? null : newGroundDataSetsVm[name];
		if (typeof val !== "object") {
			groundDataSetsFormData.set(name, val);
		}
	}

	groundDataSetsFormData.append("groundDataCsv", groundDataCsv, groundDataCsv.name);

	for (const newGroundDataVm of newGroundDataSetsVm.groundDataSets) {
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.DateCollected`,
			newGroundDataVm.groundDataset?.actualDateCollected &&
				newGroundDataVm.groundDataset?.actualDateCollected !== "Invalid date"
				? newGroundDataVm.groundDataset?.actualDateCollected
				: ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.CollectedByPersonId`,
			newGroundDataVm.groundDataset?.collectedByPersonId ?? ""
		);

		groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.FieldId`, outlineData[0].fieldId);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GrowthStageId`,
			newGroundDataVm.groundDataset?.growthStageId ?? ""
		);
		groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.Crop`, outlineData[0].crop);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDatasetTemplateId`,
			newGroundDataVm.groundDataset?.groundDatasetTemplateId &&
				newGroundDataVm.groundDataset?.groundDatasetTemplateId !== "none"
				? newGroundDataVm.groundDataset?.groundDatasetTemplateId
				: ""
		);

		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataTimingMethodId`,
			newGroundDataVm.groundDataset?.selectedTimingMethodId ?? ""
		);
		groundDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataGrowthPhaseId`,
			newGroundDataVm.groundDataset?.growthPhaseId ?? ""
		);

		if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Date") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseDate`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "StartEnd") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseEndStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageEnd ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "int") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseInteger`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Text") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseText`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Start") {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
		}
		index = 0;
		for (const data of newGroundDataVm.assessments) {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].DateCollected`,
				newGroundDataVm.groundDataset?.currentDateCollected &&
					newGroundDataVm.groundDataset?.currentDateCollected !== "Invalid date"
					? newGroundDataVm.groundDataset?.currentDateCollected
					: ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Name`,
				data.assessment.name
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataAssessmentId`,
				data.assessment.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].AverageFor`,
				data?.assessment?.averageFor ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataUnitId`,
				data.unit.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleId`,
				data.sampleDefinitionNumber?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SampleDefinitionNumber`,
				data.sampleDefinitionNumber?.name ?? "1"
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataDeviceId`,
				data.device?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataMethodId`,
				data.method.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceId`,
				data.source.id
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceName`,
				data.source.name
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionTypeId`,
				data.groundDataSamplePart?.groundDataSampleDefinitionTypeId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionPartId`,
				data.groundDataSamplePart?.id ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Width`,
				data.width ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Length`,
				data.length ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Radius`,
				data.radius ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Order`,
				index + 1
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Notes`,
				data.notes ?? ""
			);
			index++;
		}

		datasetIndex++;
	}

	index = 0;
	for (const data of outlineData) {
		groundDataSetsFormData.append(`PlotInfo[${index}].PlotId`, data.plotId);
		groundDataSetsFormData.append(`PlotInfo[${index}].Range`, data.range);
		groundDataSetsFormData.append(`PlotInfo[${index}].Column`, data.column);
		groundDataSetsFormData.append(`PlotInfo[${index}].TrialId`, data.trialId ?? "");
		index++;
	}

	index = 0;
	for (const validation of newGroundDataSetsVm.groundDataValidation) {
		groundDataSetsFormData.append(`GroundDataValidation[${index}].AssessmentName`, validation.assessmentName);
		groundDataSetsFormData.append(`GroundDataValidation[${index}].DataType`, validation.dataType);
		groundDataSetsFormData.append(`GroundDataValidation[${index}].NumOfPlots`, validation.numOfPlots);

		index++;
	}
	return fetch(`${aerialApiUri}api/v1/GroundData/InspectGroundData`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: groundDataSetsFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function editGroundData(groundDataCsv, editGroundDataSetsVm, outlineData, fieldId, trialId, accessToken) {
	const groundDataSetsFormData = new FormData();
	let datasetIndex = 0;
	let index = 0;

	for (const name in editGroundDataSetsVm) {
		let val = editGroundDataSetsVm[name] === "" ? null : editGroundDataSetsVm[name];
		if (typeof val !== "object") {
			groundDataSetsFormData.set(name, val);
		}
	}

	groundDataSetsFormData.set("trialId", trialId ?? "");
	groundDataSetsFormData.set("fieldId", fieldId ?? "");

	if (groundDataCsv) {
		groundDataSetsFormData.append("groundDataCsv", groundDataCsv, groundDataCsv.name);
	}

	if (editGroundDataSetsVm) {
		for (const editGroundDataVm of editGroundDataSetsVm.groundDataSets) {
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.Id`,
				editGroundDataVm.datasetId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.DatasetNumericalId`,
				editGroundDataVm.datasetNumericalId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.DateCollected`,
				editGroundDataVm.groundDataset?.actualDateCollected &&
					editGroundDataVm.groundDataset?.actualDateCollected !== "Invalid date"
					? editGroundDataVm.groundDataset?.actualDateCollected
					: ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.CollectedByPersonId`,
				editGroundDataVm.groundDataset?.collectedByPersonId ?? ""
			);
			groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.FieldId`, outlineData[0].fieldId);
			groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.TrialId`, trialId ?? "");
			groundDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.Crop`, outlineData[0].crop);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GroundDataTimingMethodId`,
				editGroundDataVm.groundDataset?.selectedTimingMethodId ?? ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthStageId`,
				editGroundDataVm.groundDataset?.growthStageId && editGroundDataVm.groundDataset?.growthStageId !== null
					? editGroundDataVm.groundDataset?.growthStageId
					: ""
			);
			groundDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GroundDataGrowthPhaseId`,
				editGroundDataVm.groundDataset?.growthPhaseId ?? ""
			);

			if (editGroundDataVm.groundDataset?.trialsToIncludeForField?.length > 0) {
				let trialIdIndex = 0;
				for (const trialIds of editGroundDataVm.groundDataset?.trialsToIncludeForField) {
					groundDataSetsFormData.append(
						`GroundDataSets[${datasetIndex}].groundDataset.TrialsToIncludeForField[${trialIdIndex}]`,
						trialIds
					);
					trialIdIndex++;
				}
			}

			if (editGroundDataVm.groundDataset?.selectedTimingMethodType === "Date") {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseDate`,
					editGroundDataVm.groundDataset?.plannedTiming &&
						editGroundDataVm.groundDataset?.plannedTiming !== "Invalid date"
						? editGroundDataVm.groundDataset?.plannedTiming
						: ""
				);
			} else if (editGroundDataVm.groundDataset?.selectedTimingMethodType === "StartEnd") {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
					editGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseEndStageId`,
					editGroundDataVm.groundDataset?.plannedTiming?.growthStageEnd ?? ""
				);
			} else if (editGroundDataVm.groundDataset?.selectedTimingMethodType === "int") {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseInteger`,
					editGroundDataVm.groundDataset?.plannedTiming ?? ""
				);
			} else if (editGroundDataVm.groundDataset?.selectedTimingMethodType === "Text") {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseText`,
					editGroundDataVm.groundDataset?.plannedTiming ?? ""
				);
			} else if (editGroundDataVm.groundDataset?.selectedTimingMethodType === "Start") {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
					editGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
				);
			}

			index = 0;
			for (const data of editGroundDataVm.assessments) {
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Id`,
					data.groundDatasetAssessmentDataId ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].DateCollected`,
					editGroundDataVm.groundDataset?.currentDateCollected &&
						editGroundDataVm.groundDataset?.currentDateCollected !== "Invalid date"
						? editGroundDataVm.groundDataset?.currentDateCollected
						: ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Name`,
					data.assessment.name
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataAssessmentId`,
					data.assessment.id
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].AverageFor`,
					data?.assessment?.averageFor ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataUnitId`,
					data.unit.id
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleId`,
					data.samplesPerPlot?.id ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SampleDefinitionNumber`,
					data.sampleDefinitionNumber?.name ?? "1"
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SamplesPerPlot`,
					data.samplesPerPlot?.name ?? "1"
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataDeviceId`,
					data.device?.id ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataMethodId`,
					data.method.id
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceId`,
					data.source.id
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceName`,
					data.source.name
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionTypeId`,
					data.groundDataSampleDefinitionPart?.groundDataSampleDefinitionTypeId ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionPartId`,
					data.groundDataSampleDefinitionPart?.id ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDatasetTemplateId`,
					data.templateId ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Width`,
					data.width ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Length`,
					data.length ?? ""
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Order`,
					index + 1
				);
				groundDataSetsFormData.append(
					`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Notes`,
					data.notes ?? ""
				);
				index++;
			}

			datasetIndex++;
		}

		index = 0;
		for (const validation of editGroundDataSetsVm.groundDataValidation) {
			groundDataSetsFormData.append(`GroundDataValidation[${index}].AssessmentName`, validation.assessmentName);
			groundDataSetsFormData.append(`GroundDataValidation[${index}].DataType`, validation.dataType);
			groundDataSetsFormData.append(`GroundDataValidation[${index}].NumOfPlots`, validation.numOfPlots);

			index++;
		}
	}

	if (outlineData) {
		index = 0;
		for (const data of outlineData) {
			groundDataSetsFormData.append(`PlotInfo[${index}].PlotId`, data.plotId);
			groundDataSetsFormData.append(`PlotInfo[${index}].Range`, data.range);
			groundDataSetsFormData.append(`PlotInfo[${index}].Column`, data.column);
			groundDataSetsFormData.append(`PlotInfo[${index}].TrialId`, data.trialId ?? "");
			groundDataSetsFormData.append(`PlotInfo[${index}].FieldId`, data.fieldId);
			index++;
		}
	}

	return fetch(`${aerialApiUri}api/v1/GroundData`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: groundDataSetsFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function deleteDataset(clientId, datasetId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/GroundData/Delete?clientId=${clientId}&datasetId=${datasetId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function eraseDataset(clientId, datasetId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/GroundData/Erase?clientId=${clientId}&datasetId=${datasetId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getGroundDatasets(clientId, accessToken, fieldId = null, trialId = null, protocolId = null) {
	return fetch(
		`${aerialApiUri}api/v1/GroundData/GetGroundDatasets?clientId=${clientId}
		${fieldId ? `&fieldId=${fieldId}` : ""}
		${trialId ? `&trialId=${trialId}` : ""}	
		${protocolId ? `&protocolId=${protocolId}` : ""}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}
