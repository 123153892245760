const CorrelationColorGradient = (
	negativeColor,
	neutralColor,
	positiveColor,
	negativeBound,
	positiveBound,
	targetValue
) => {
	// Positive Correlation
	if (targetValue > 0) {
		let percentagePositive = targetValue / positiveBound;
		let percentageNeutral = 1 - percentagePositive;
		return {
			red: Math.round(positiveColor.red * percentagePositive + neutralColor.red * percentageNeutral),
			green: Math.round(positiveColor.green * percentagePositive + neutralColor.green * percentageNeutral),
			blue: Math.round(positiveColor.blue * percentagePositive + neutralColor.blue * percentageNeutral)
		};
	}
	// Negative Correlation
	else if (targetValue < 0) {
		let percentageNegative = Math.abs(targetValue / negativeBound);
		let percentageNeutral = 1 - percentageNegative;
		return {
			red: Math.round(negativeColor.red * percentageNegative + neutralColor.red * percentageNeutral),
			green: Math.round(negativeColor.green * percentageNegative + neutralColor.green * percentageNeutral),
			blue: Math.round(negativeColor.blue * percentageNegative + neutralColor.blue * percentageNeutral)
		};
	}
	// No Correlation
	return neutralColor;
};

export default CorrelationColorGradient;
