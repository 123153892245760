import * as types from "./actionTypes";
import * as assessmentApi from "../../apis/assessmentApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getAssessmentLogOptionsSuccess() {
	return { type: types.ASSESSMENTS_GET_ASSESSMENT_LOG_OPTIONS_SUCCESS };
}

export function getAssessmentLogSuccess() {
	return { type: types.ASSESSMENTS_GET_ASSESSMENT_LOG_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getAssessmentLogOptions(trialId, fieldId, accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return assessmentApi
				.getAssessmentLogOptions(trialId, fieldId, accessToken, clientId)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res.data);
					}
					dispatch(getAssessmentLogOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAssessmentLog(accessToken, trialId, fieldId, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return assessmentApi
				.getAssessmentLog(accessToken, trialId, fieldId, clientId)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res.data);
					}
					dispatch(getAssessmentLogSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
