import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

//import _ from "lodash";

import { toast } from "react-toastify";

import { Grid, Input, Button, Icon, Modal } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";
//import * as groundDataActions from "../../../redux/actions/groundDataActions";

//import WeatherDataInspectionResults from "./WeatherDataInspectionResults";

const UploadWeatherDataCSV = ({
	//trialFieldData,
	trialWeatherData,
	//onFileSelected,
	//onFileRemoved,
	onInspectionSuccessful,
	weatherData//,
	//outlineData,
	//createWeatherDataSetObject
}) => {
	const dispatch = useDispatch();
	const fileInputRef = useRef();
  const { getTokenSilently } = useAuth0();

	const [trialDataFile, setTrialDataFile] = useState(null);
	const [runningTrialInspections, setRunningTrialInspections] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const [inspectionResults, setInspectionResults] = useState(null);
	const [dataOverwriteWarning, showDataOverwriteWarning] = useState(false);
	//const [weatherDataColumns, setWeatherDataColumns] = useState([]);
	const [array, setArray] = useState([]);

	const fileReader = new FileReader();

	useEffect(() => {
		runInspection();
	}, [trialDataFile]);

	const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

	async function runInspection() {
		if (trialDataFile) {
			setRunningTrialInspections(true);
			const accessToken = await getTokenSilently();
			//let weatherDataSetsObj = weatherData ? createWeatherDataSetObject() : null;
			fileReader.onload = function(event){
				const text = event.target.result;
				csvFileToArray(text);
			}
			fileReader.readAsText(trialDataFile);
			console.log(array);
			dispatch(
				//weatherData
					 weatherDataActions.inspectWeatherData(trialDataFile, array, useUserAuth.currentClientId, accessToken) //check to see if this is still going to work
					//: weatherDataActions.inspectWeatherData(trialDataFile, trialWeatherData, accessToken)//check to see if this is still going to work
			)
				.then((res) => {
					if (weatherData) {
						//let assessmentColumns = res.validations.importWeatherData[0].assessmentColumns;
						//Get the property names of all of the dynamic assessment columns
						//let filteredColumns = Object.getOwnPropertyNames(assessmentColumns);

						/*setWeatherDataColumns(
							_.map(filteredColumns, (fc) => {
								return {
									name: fc,
									vr: `${fc}Vr`
								};
							})
						);*/
					}

					setInspectionResults(res);
					setShowResults(true);
					setRunningTrialInspections(false);
					onInspectionSuccessful(res);
				})
				.catch((error) => {
					if (error.errors && error.errors.length > 0) {
						toast.error(error.errors[0]);
					} else if (error) {
						toast.error(error);
					} else {
						toast.error("An unknown error occurred while inspecting the plot data file.");
					}
					setRunningTrialInspections(false);
				});
		}
	}

	function handleFileBrowse() {
		if (trialWeatherData?.fieldHasImportedData) { 
			showDataOverwriteWarning(true);
		} else {
			fileInputRef.current.click();
		}
	}

	function fileSelectedHandler(event) {
		event.persist();
		if (event.target.files.length > 0) {
			const weatherDataCsv = event.target.files[0];
			setTrialDataFile(weatherDataCsv);
			//onFileSelected(weatherDataCsv);
		}
	}


	const fileRemovedHandler = () => {
		//-- Need to set the value of the file input to null. Otherwise, the onChange event won't fire if you try selecting another file.
		document.getElementById("weatherDataLayoutUpload").value = null;
		setTrialDataFile(null);
		//onFileRemoved();
	};

	return (
		<>
			<Grid columns="16">
				<Grid.Row columns="16" verticalAlign="bottom" style={{ padding: "unset" }}>
					<Grid.Column width={8}>
						<Input type="text" action readOnly value={trialDataFile?.name ?? ""}>
							<Button
								content="Browse..."
								labelPosition="left"
								icon="file"
								disabled={
									weatherData
										? !!trialDataFile || !trialWeatherData?.clientId
										: !!trialDataFile /*||
										  !trialWeatherData?.clientId ||
										  !trialWeatherData?.name*/
								}
								style={{ borderTopRightRadius: "unset", borderBottomRightRadius: "unset" }}
								onClick={() => {
									handleFileBrowse();
								}}
							/>
							<input style={{ borderTopLeftRadius: "unset", borderBottomLeftRadius: "unset" }} />
							<Button icon disabled={!trialDataFile} onClick={fileRemovedHandler}>
								<Icon name="trash" />
							</Button>
						</Input>
						<input
							id="weatherDataLayoutUpload"
							ref={fileInputRef}
							type="file"
							accept=".csv"
							hidden
							onChange={fileSelectedHandler}
						/>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 20, paddingRight: "unset" }}>
						<Modal
							size="large"
							trigger={
								<Button
									content="Inspection Results"
									positive={
										trialDataFile && !runningTrialInspections && inspectionResults && inspectionResults.areValid
									}
									negative={
										trialDataFile && !runningTrialInspections && inspectionResults && !inspectionResults.areValid
									}
									loading={runningTrialInspections}
									disabled={!trialDataFile || runningTrialInspections}
									onClick={() => setShowResults(true)}
									style={{ height: 37.8, paddingTop: 6 }}
								/>
							}
							open={showResults}
							onClose={() => setShowResults(false)}
						>
							<Modal.Header>{weatherData ? "Weather Data Inspection" : "Trial Data Inspection"}</Modal.Header>
							<Modal.Content scrolling>
								<Modal.Description>
									{/*<WeatherDataInspectionResults
										inspectionResults={inspectionResults}
										groundDataColumns={weatherDataColumns}
						/>*/}
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button onClick={() => setShowResults(false)}>Close</Button>
							</Modal.Actions>
						</Modal>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 10, paddingRight: "unset" }}>
						<Button
							content="Rerun Inspection"
							loading={runningTrialInspections}
							disabled={!trialDataFile || runningTrialInspections}
							onClick={() => runInspection()}
							style={{ height: 37.8, paddingTop: 6 }}
						/>
					</Grid.Column>
				</Grid.Row>
				{!weatherData && (
					<Grid.Row columns="16" verticalAlign="top" style={{ padding: "unset" }}>
						<Grid.Column width={4}>
							<Link to="/WeatherDataTemplate.csv" target="_blank" download style={{ fontSize: 12 }}>
								Weather Data Layout Template
							</Link>
						</Grid.Column>
						<Grid.Column width={4}></Grid.Column>
					</Grid.Row>
				)}
			</Grid>
			<Modal open={dataOverwriteWarning}>
				<Modal.Header>Import Weather Data Layout</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<p>
							Any layout information previously imported will be replaced with the data in the new file. To see current
							weather data layout, click the <i>Current weather data layout</i> link.
						</p>
						<p>
							If you would like to edit the layout for this weather data, download the current weather data layout, make your changes,
							and then import those changes.
						</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						type="button"
						floated="right"
						onClick={() => {
							showDataOverwriteWarning(false);
							fileInputRef.current.click();
						}}
						primary
					>
						Continue
					</Button>
					<Button type="button" floated="right" onClick={() => showDataOverwriteWarning(false)}>
						Cancel
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
};

UploadWeatherDataCSV.propTypes = {
	/*trialWeatherData: PropTypes.object.isRequired,
	onFileSelected: PropTypes.func.isRequired,
	onFileRemoved: PropTypes.func.isRequired,
	onInspectionSuccessful: PropTypes.func.isRequired,*/
	trialWeatherData: PropTypes.object,
	onFileSelected: PropTypes.func,
	onFileRemoved: PropTypes.func,
	onInspectionSuccessful: PropTypes.func,
	weatherData: PropTypes.bool,
	outlineData: PropTypes.array,
	createWeatherDataSetObject: PropTypes.func 
};

export default UploadWeatherDataCSV;
