import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as flightActions from "../../../redux/actions/flightActions";
import * as fieldActions from "../../../redux/actions/fieldActions";
import * as orthoViewerActions from "../../../redux/actions/orthoViewerActions";
import * as orthoActions from "../../../redux/actions/orthoActions";
import { Grid, Dropdown, Icon } from "semantic-ui-react";
import * as turf from "@turf/turf";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { applyLastAppliedShader } from "./orthoControlFunctions";

const OrthoDropdownComponent = React.forwardRef(
	(
		{
			level,
			edit,
			viewer,
			setCenterCoordinate,
			setOrthoIdToPass,
			setOrthoTypes,
			heatmapTimelineRef,
			buildMapImage,
			movePlot,
			handleFieldDropdownChange,
			removeFieldGrid,
			jumpToCoords,
			redrawPlots,
			imageFound
		},
		ref
	) => {
		const { getTokenSilently } = useAuth0();
		const dispatch = useDispatch();
		const moduleNavigation = useModuleNavigation();
		const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
		const reduxFlightId = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightId : null));

		const [orthoOptions, setOrthoOptions] = useState(null);
		let sensorChanged = false;
		const [orthoId, setOrthoId] = useState(null);
		const [thirdDropdownOptions, setThirdDropdownOptions] = useState([]);
		const [thirdDropdownId, setThirdDropdownId] = useState("all");
		const [imageCoords, setImageCoords] = useState([]);
		const [coords, setCoords] = useState(null);
		const [flightImages, setFlightImages] = useState([]);
		const [imageBands, setImageBands] = useState(null);
		const [imageHistogram, setImageHistogram] = useState([]);
		const [allFlightOptions, setAllFlightOptions] = useState([]);

		// Expose a few properties from this layer to the parent component.
		useImperativeHandle(ref, () => {
			return {
				setThirdDropdownOptions: updateThirdDropdownOptions,
				currentThirdDropdownOptions: getThirdDropdownOptions,
				setThirdDropdownId: (id) => {
					setThirdDropdownId(id);
				},
				handleDeleteClick: handleDeleteClick,
				getFlightImage: getFlightImage,
				coords: coords,
				getSelectedOrthoId: () => {
					return orthoId;
				},
				setSelectedOrthoId: (selectedOrthoId) => {
					setOrthoId(selectedOrthoId);
				},
				getImageHistogram: () => {
					return imageHistogram;
				},
				getFlightOptions: allFlightOptions
			};
		});

		function updateThirdDropdownOptions(options) {
			setThirdDropdownOptions(options);
		}

		function getThirdDropdownOptions() {
			return thirdDropdownOptions;
		}

		useEffect(() => {
			if (orthoId && (reduxFlightId || moduleNavigation.flightId)) {
				getFlightImage();
			}
		}, [orthoId, reduxFlightId]);

		useEffect(() => {
			if (orthoId) {
				setOrthoIdToPass(orthoId);

				if (level !== "flight") {
					updateHeatmapAnalysisId();
				}

				applyLastAppliedShader(0, 1);
			}
		}, [orthoId]);

		useEffect(() => {
			if (imageBands) {
				let band = _.find(imageBands, (e) => e.colorInterpretation === "Gray" || e.colorInterpretation === "Green");
				if (band?.histogram) {
					setImageHistogram(band.histogram.buckets);
				} else {
					setImageHistogram([]);
				}
			}
		}, [imageBands]);

		function updateHeatmapAnalysisId() {
			if (heatmapTimelineRef?.current) {
				let analysisOpts = heatmapTimelineRef.current.getAnalysisOptions();

				let matchingOrtho = _.find(orthoOptions, { key: orthoId });
				if (matchingOrtho) {
					//-- Set analysis not to stand counts at first
					let matchingAnalysis = _.find(
						analysisOpts,
						(ao) => ao.text.includes(matchingOrtho.text) && !ao.text.includes("Stand Count")
					);

					if (!matchingAnalysis) {
						matchingAnalysis = _.find(analysisOpts, (ao) => ao.text.includes(matchingOrtho.text));
					}

					if (matchingAnalysis) {
						heatmapTimelineRef.current.setSelectedAnalysisId(matchingAnalysis.key);
					}
				}
			}
		}

		async function getFlightImage() {
			//-- not searching for sensor since flightId-orthoImageTypeId is unique already
			let flightImageId = _.find(flightImages, {
				flightId: reduxFlightId ?? moduleNavigation.flightId,
				orthoImageTypeId: orthoId,
				fieldId: level === "field" ? moduleNavigation.fieldId : null,
				trialId: level === "trial" ? moduleNavigation.trialId : null
			})?.flightImageId;

			if (flightImageId) {
				const accessToken = await getTokenSilently();
				dispatch(
					orthoActions.getViewerFlightImage(
						reduxFlightId ?? moduleNavigation.flightId,
						flightImageId,
						clientId,
						accessToken
					)
				)
					.then((res) => {
						let first = false;
						if (imageCoords.length === 0) {
							setImageCoords([
								[res.llLat, res.llLong],
								[res.ulLat, res.ulLong],
								[res.urLat, res.urLong],
								[res.lrLat, res.lrLong]
							]);
							jumpToCoords(getCenterCoordinates(res).geometry.coordinates);
							setCenterCoordinate(getCenterCoordinates(res).geometry.coordinates);
							setCoords(getCenterCoordinates(res).geometry.coordinates);
							first = true;
						}

						if (level === "field") {
							redrawPlots(reduxFlightId, moduleNavigation.fieldId, null, sensorChanged, res, orthoId, first);
						} else if (level === "trial") {
							redrawPlots(reduxFlightId, null, moduleNavigation.trialId, sensorChanged, res, orthoId, first);
						} else {
							buildMapImage(res, orthoId);
						}
						setImageBands(res.imageBands);
					})
					.catch((err) => {
						//-- If the user navigates to a page with this component and navigates away before the image loads, this error would appear
						//--	Changed it from a toast notification to a console error so the user doesn't see it in this case
						imageFound(false);
						console.log("Error loading flight image.");
						console.log(err);
					});
			}
		}

		// 1. Setup Event Listeners and get flights.
		const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
		useEffect(() => {
			let map = document.querySelector(".mapboxgl-canvas");
			map.addEventListener(
				"keydown",
				(e) => {
					if (arrowKeys.includes(e.key) || e.key === "Shift") {
						e.preventDefault();
						e.stopPropagation();
						movePlot(e.key, e);
					}
				},
				false
			);

			if (level !== "flight") {
				getFlights();
			} else {
				getOrthosForFlight(moduleNavigation.flightId);
			}
		}, []);

		async function getFlights() {
			const accessToken = await getTokenSilently();
			dispatch(
				fieldActions.getFlightsWithFieldOrTrial(
					clientId,
					moduleNavigation.fieldId,
					moduleNavigation.trialId,
					accessToken
				)
			).then((res) => {
				if (res.length === 0) {
					imageFound(false);
				}

				let uniqueFlights = res;

				if (level === "trial") {
					uniqueFlights = uniqueFlights.filter((uf) => uf.trialId === moduleNavigation.trialId);
				}

				uniqueFlights = _.uniqBy(uniqueFlights, "flightId");
				uniqueFlights = _.orderBy(uniqueFlights, ["flightDateAndTime"]);

				setAllFlightOptions(uniqueFlights);

				if (!reduxFlightId) {
					orthoViewerActions.setCurrentFlightId(moduleNavigation.flightId ?? uniqueFlights[0].flightId);
					getOrthosForFlight(moduleNavigation.flightId ?? uniqueFlights[0].flightId);
				} else {
					getOrthosForFlight();
				}
			});
		}

		async function getOrthosForFlight(flightId = null) {
			const accessToken = await getTokenSilently();
			dispatch(
				flightActions.getViewerOrthoOptions(
					level === "flight" ? moduleNavigation.flightId : "",
					level === "field" ? moduleNavigation.fieldId : "",
					level === "trial" ? moduleNavigation.trialId : "",
					clientId,
					accessToken
				)
			)
				.then((res) => {
					setFlightImages(res.flightImages);
					setOrthoTypes(_.uniqBy(res.flightImages, "orthoImageTypeId"));

					let orthoOpts = _.map(_.uniqBy(res.flightImages, "orthoImageTypeId"), ({ orthoImageTypeId }) => {
						let orthoImageType = _.find(res.flightSensors, { orthoImageTypeId: orthoImageTypeId });
						const opt = {
							key: orthoImageTypeId,
							value: orthoImageTypeId,
							text: orthoImageType?.displayName ?? orthoImageType?.orthoImageType ?? ""
						};
						return opt;
					});

					orthoOpts = _.sortBy(
						orthoOpts,
						[(opt) => _.find(res.flightSensors, { orthoImageTypeId: opt.key }).order || Infinity, "text"],
						["desc"]
					);

					setOrthoOptions(orthoOpts);

					let imagesOnSelectedFlight = _.filter(res.flightImages, (fi) => fi.flightId === (flightId ?? reduxFlightId));
					let ndviIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "NDVI" })?.orthoImageTypeId
					});
					let rgbIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "RGB" })?.orthoImageTypeId
					});
					let rapidIndex = _.findIndex(imagesOnSelectedFlight, {
						orthoImageTypeId: _.find(res.flightSensors, { orthoImageType: "Rapid_RGB" })?.orthoImageTypeId
					});

					let imageIndex =
						ndviIndex !== -1 ? ndviIndex : rgbIndex !== -1 ? rgbIndex : rapidIndex !== -1 ? rapidIndex : 0;

					setOrthoId(imagesOnSelectedFlight[imageIndex]?.orthoImageTypeId);
				})
				.catch(() => {
					imageFound(false);
				});
		}

		useEffect(() => {
			if (orthoId) {
				getThirdDropdown();
			}
		}, [orthoId]);

		async function getThirdDropdown() {
			const accessToken = await getTokenSilently();
			if (level === "flight") {
				if (!edit) {
					dispatch(fieldActions.getUsedFields(moduleNavigation.flightId, orthoId, clientId, accessToken)).then(
						(res) => {
							setThirdDropdownOptions(
								[{ key: "all", value: "all", text: "All" }].concat(
									_.map(res, (record) => {
										const opt = {
											key: record.fieldId,
											value: record.fieldId,
											text: record.name,
											icon: (
												<>
													<Icon
														style={{ float: "right" }}
														name="trash"
														link
														onClick={(e) => handleDeleteClick(e, record.fieldId)}
													/>
													<Icon style={{ float: "right" }} name="warning" />
												</>
											)
										};
										return opt;
									})
								)
							);
						}
					);
				} else if (!viewer) {
					dispatch(fieldActions.getFields(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
						setThirdDropdownOptions(
							[{ key: "all", value: "all", text: "All" }].concat(
								_.map(res, (record) => {
									const opt = {
										key: record.id,
										value: record.id,
										text: record.name,
										icon: (
											<>
												<Icon
													style={{ float: "right" }}
													name="trash"
													link
													onClick={(e) => handleDeleteClick(e, record.id)}
												/>
												<Icon style={{ float: "right" }} name="check" />
											</>
										)
									};
									return opt;
								})
							)
						);
					});
				} else {
					dispatch(fieldActions.getFields(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
						setThirdDropdownOptions(
							[{ key: "all", value: "all", text: "All" }].concat(
								_.map(res, (record) => {
									const opt = {
										key: record.id,
										value: record.id,
										text: record.name
									};
									return opt;
								})
							)
						);
					});
				}
			}
		}

		useEffect(() => {
			if (orthoId) {
				//-- Check if new ortho is on current flight
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightId === (reduxFlightId ?? moduleNavigation.flightId)
				);
				let orthosWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");
				if (!orthosWithFlight.includes(orthoId)) {
					orthoViewerActions.setCurrentFlightId(imagesWithFlight[0].flightId);
				}
			}
		}, [orthoId]);

		useEffect(() => {
			if (thirdDropdownId && thirdDropdownOptions.length > 0) {
				let option = _.find(thirdDropdownOptions, { key: thirdDropdownId });
				if (level === "flight") {
					handleFieldDropdownChange(option);
				}
			}
		}, [thirdDropdownId]);

		useEffect(() => {
			if (flightImages?.length > 0) {
				let imagesWithFlight = _.filter(
					flightImages,
					(fi) => fi.flightId === (reduxFlightId ?? moduleNavigation.flightId)
				);
				let imageTypesWithFlight = _.map(imagesWithFlight, "orthoImageTypeId");

				if (!imageTypesWithFlight.includes(orthoId)) {
					setOrthoId(imageTypesWithFlight[0]);
				}
			}
		}, [reduxFlightId]);

		const getCenterCoordinates = (res) => {
			let center = turf.midpoint(turf.point([res.llLong, res.llLat]), turf.point([res.urLong, res.urLat]));

			return center;
		};

		const handleDeleteClick = (e, fieldId) => {
			removeFieldGrid(fieldId);
			e.preventDefault();
			e.stopPropagation();
		};

		return (
			<Grid id="orthoViewerGrid" stackable={false} style={{ width: "100%" }}>
				<Grid.Row verticalAlign="middle" centered style={{ paddingTop: 0, paddingBottom: 0 }}>
					<Grid.Column textAlign="right" width={level !== "flight" ? "4" : "1"} style={{ padding: "0px" }}>
						<label style={{ fontSize: "16px" }} htmlFor="select-ortho">
							<strong>Orthos</strong>
						</label>
					</Grid.Column>
					<Grid.Column width={level !== "flight" ? "10" : "3"}>
						<Dropdown
							id="select-ortho"
							selection
							fluid
							search
							options={orthoOptions}
							value={orthoId}
							onChange={(event, { value }) => {
								setOrthoId(value);
							}}
							loading={!orthoOptions}
							placeholder={"None found"}
						/>
					</Grid.Column>
					{level === "flight" && (
						<>
							<Grid.Column width={level !== "flight" ? "2" : "1"}>
								<label htmlFor="select-ortho">
									<strong>{"Fields"}</strong>
								</label>
							</Grid.Column>
							<Grid.Column width="3">
								<Dropdown
									id="select-ortho"
									fluid
									selection
									search
									options={thirdDropdownOptions}
									placeholder={"None found"}
									value={thirdDropdownOptions && thirdDropdownOptions.length === 0 ? null : thirdDropdownId}
									onChange={(event, { value }) => {
										setThirdDropdownId(value);
									}}
									//-- Admittedly this is a bandaid, but it works well enough
									loading={!orthoOptions}
								/>
							</Grid.Column>
						</>
					)}
				</Grid.Row>
			</Grid>
		);
	}
);

OrthoDropdownComponent.propTypes = {
	level: PropTypes.string.isRequired,
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool.isRequired,
	setCenterCoordinate: PropTypes.func.isRequired,
	buildMapImage: PropTypes.func.isRequired,
	movePlot: PropTypes.func.isRequired,
	handleFieldDropdownChange: PropTypes.func.isRequired,
	setOrthoIdToPass: PropTypes.func.isRequired,
	removeFieldGrid: PropTypes.func.isRequired,
	setOrthoTypes: PropTypes.func.isRequired,
	jumpToCoords: PropTypes.func.isRequired,
	redrawPlots: PropTypes.func.isRequired,
	imageFound: PropTypes.func.isRequired,
	heatmapTimelineRef: PropTypes.object
};

OrthoDropdownComponent.displayName = "OrthoDropdownComponent";

export default OrthoDropdownComponent;
