// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* AG: I've come to learn that when using Semantic UI and you want to override classes or selectors, you must use !important if you're not using LESS and customizing the look through that */\n\ninput:read-only {\n\topacity: 1 !important;\n\tcolor: #000000 !important;\n\tbackground-color: #eeeeee !important;\n}\n\n#form-current-memberships {\n\tpadding: 5px !important;\n\tfont-size: 1rem !important;\n}\n\n#potential-member-item {\n\theight: 15px !important;\n}\n\n#potential-user-item {\n\theight: 15px !important;\n}\n\n.fieldLabel {\n\tfont-size: 0.92857143em;\n\tfont-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Settings/styles.css"],"names":[],"mappings":"AAAA,6LAA6L;;AAE7L;CACC,qBAAqB;CACrB,yBAAyB;CACzB,oCAAoC;AACrC;;AAEA;CACC,uBAAuB;CACvB,0BAA0B;AAC3B;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,uBAAuB;CACvB,gBAAgB;AACjB","sourcesContent":["/* AG: I've come to learn that when using Semantic UI and you want to override classes or selectors, you must use !important if you're not using LESS and customizing the look through that */\n\ninput:read-only {\n\topacity: 1 !important;\n\tcolor: #000000 !important;\n\tbackground-color: #eeeeee !important;\n}\n\n#form-current-memberships {\n\tpadding: 5px !important;\n\tfont-size: 1rem !important;\n}\n\n#potential-member-item {\n\theight: 15px !important;\n}\n\n#potential-user-item {\n\theight: 15px !important;\n}\n\n.fieldLabel {\n\tfont-size: 0.92857143em;\n\tfont-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-current-memberships": "form-current-memberships",
	"potential-member-item": "potential-member-item",
	"potential-user-item": "potential-user-item",
	"fieldLabel": "fieldLabel"
};
export default ___CSS_LOADER_EXPORT___;
