import * as types from "./actionTypes";
import * as clientApi from "../../apis/clientApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function setCurrentClientIdSuccess(clientId) {
	return { type: types.CLIENT_SET_CURRENT_ID_SUCCESS, clientId };
}

export function getClientsSuccess() {
	return { type: types.CLIENT_GET_CLIENTS_SUCCESS };
}

export function getClientInfoSuccess() {
	return { type: types.CLIENT_GET_CLIENT_INFO_SUCCESS };
}

export function getLicensesSuccess() {
	return { type: types.CLIENT_GET_LICENSES_SUCCESS };
}

export function updateClientSuccess() {
	return { type: types.CLIENT_UPDATE_CLIENT_SUCCESS };
}

export function createClientSuccess() {
	return { type: types.CLIENT_CREATE_CLIENT_SUCCESS };
}

export function removeMembershipSuccess() {
	return { type: types.CLIENT_REMOVE_MEMBERSHIP_SUCCESS };
}

export function updateMemberSuccess() {
	return { type: types.CLIENT_UPDATE_MEMBER_SUCCESS };
}

export function getPotentialMembersSuccess() {
	return { type: types.CLIENT_GET_POTENTIAL_MEMBERS_SUCCESS };
}

export function addMemberSuccess() {
	return { type: types.CLIENT_CREATE_CLIENT_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function setCurrentClientId(clientId) {
	return function (dispatch) {
		dispatch(setCurrentClientIdSuccess(clientId));
	};
}

export function getClients(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.getClients(clientId, accessToken)
				.then((res) => {
					dispatch(getClientsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getClientInfo(accessToken, clientId, currentClientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.getClientInfo(accessToken, clientId, currentClientId)
				.then((res) => {
					dispatch(getClientInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getLicenses(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.getLicenses(clientId, accessToken)
				.then((res) => {
					dispatch(getLicensesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateClient(accessToken, clientUpdate, currentClientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.updateClient(accessToken, clientUpdate, currentClientId)
				.then((res) => {
					dispatch(updateClientSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createClient(accessToken, clientNew, currentClientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.createClient(accessToken, clientNew, currentClientId)
				.then((res) => {
					dispatch(createClientSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function removeMembership(accessToken, membershipId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.removeMembership(accessToken, membershipId)
				.then((res) => {
					dispatch(removeMembershipSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateMember(accessToken, memberObject) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.updateMember(accessToken, memberObject)
				.then((res) => {
					dispatch(updateMemberSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPotentialMembers(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.getPotentialMembers(clientId, accessToken)
				.then((res) => {
					dispatch(getPotentialMembersSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function addMember(accessToken, newMember) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientApi
				.addMember(accessToken, newMember)
				.then((res) => {
					dispatch(addMemberSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
