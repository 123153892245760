//-- http://www.andrewnoske.com/wiki/Code_-_heatmaps_and_color_gradients

class ColorGradient {
	constructor(min, mid, max, reveresed = false) {
		this.createHeatMapGradient(min, mid, max, reveresed);
	}

	//-- An array of color points in ascending value.
	colorPoints = [];

	//-- Places a 3 color heapmap gradient into the "color" vector:
	createHeatMapGradient(min, mid, max, reversed) {
		this.colorPoints = reversed
			? [
					{ r: 0, g: 255, b: 0, a: 0.5, v: min }, //-- Red
					{ r: 255, g: 255, b: 0, a: 0.5, v: mid }, //-- Yellow
					{ r: 255, g: 0, b: 0, a: 0.5, v: max } //-- Green
			  ]
			: [
					{ r: 255, g: 0, b: 0, a: 0.5, v: min }, //-- Green
					{ r: 255, g: 255, b: 0, a: 0.5, v: mid }, //-- Yellow
					{ r: 0, g: 255, b: 0, a: 0.5, v: max } //-- Red
			  ];
	}

	//-- Inputs a (value) between 0 and 1 and outputs the (red), (green) and (blue)
	//-- values representing that position in the gradient.
	getColorAtValue(value) {
		if (this.colorPoints.length === 0) return { r: 0, g: 0, b: 0 };

		for (let i = 0; i < this.colorPoints.length; i++) {
			let currentColor = this.colorPoints[i];
			if (value <= currentColor.v) {
				let previousColor = null;

				if (i === 0) {
					previousColor = this.colorPoints[0];
				} else {
					previousColor = this.colorPoints[i - 1];
				}

				const valueDiff = previousColor.v - currentColor.v;
				const fractBetween = valueDiff === 0 ? 0 : (value - currentColor.v) / valueDiff;
				const r = (previousColor.r - currentColor.r) * fractBetween + currentColor.r;
				const g = (previousColor.g - currentColor.g) * fractBetween + currentColor.g;
				const b = (previousColor.b - currentColor.b) * fractBetween + currentColor.b;

				//-- r,g,b,alpha
				return `rgb(${r}, ${g}, ${b}, .5)`;
			}
		}
	}

	//-- Same function as above, returning rgb values only
	getRgbValuesAtValue(value) {
		if (this.colorPoints.length === 0) return { r: 0, g: 0, b: 0 };

		for (let i = 0; i < this.colorPoints.length; i++) {
			let currentColor = this.colorPoints[i];
			if (value <= currentColor.v) {
				let previousColor = null;

				if (i === 0) {
					previousColor = this.colorPoints[0];
				} else {
					previousColor = this.colorPoints[i - 1];
				}

				const valueDiff = previousColor.v - currentColor.v;
				const fractBetween = valueDiff === 0 ? 0 : (value - currentColor.v) / valueDiff;
				const r = (previousColor.r - currentColor.r) * fractBetween + currentColor.r;
				const g = (previousColor.g - currentColor.g) * fractBetween + currentColor.g;
				const b = (previousColor.b - currentColor.b) * fractBetween + currentColor.b;

				//-- r,g,b
				return [r, g, b];
			}
		}
	}
}

export default ColorGradient;
