import _ from "lodash";
import { jStat } from "jstat";

/* Expects two arrays of elements of objects in the format:
    {
        plotRange: x,
        plotColumn: y,
        Value: z
    }
*/
function calculateOverlappingCorrelationValue(aElements, bElements) {

    // Ignore all of the marked plots.
    let unmarkedAElements = _.filter(aElements, (element)=>{ return element.excludeFromAssessment == false });
    let unmarkedBElements = _.filter(bElements, (element)=>{ return element.excludeFromAssessment == false });

    // Find all of the elements from the 'A' collection which have an element with matching Column and Range values from the 'B' collection.
    let elementsFromAInBoth = _.intersectionWith(unmarkedAElements, unmarkedBElements, (aElement, bElement) => {
        return aElement.plotRange == bElement.plotRange && aElement.plotColumn == bElement.plotColumn;
    });
    // Sort all of the intersecting elements from the 'A' collection.
    let sortedElementsFromAInBoth = _.orderBy(elementsFromAInBoth, ["plotRange", "plotColumn"], ["asc", "asc"]);
    // Get the data values for the intersecting elements from the 'A' collection.
    let aData = _.map(sortedElementsFromAInBoth, 'value');

    // Find all of the elements from the 'B' collection which have an element with matching Column and Range values from the 'A' collection.
    let elementsFromBInBoth = _.intersectionWith(unmarkedBElements, unmarkedAElements, (bElement, aElement) => {
        return aElement.plotRange == bElement.plotRange && aElement.plotColumn == bElement.plotColumn;
    });
    // Sort all of the intersecting elements from the 'B' collection.
    let sortedElementsFromBInBoth = _.orderBy(elementsFromBInBoth, ["plotRange", "plotColumn"], ["asc", "asc"]);
    // Get the data values for the intersecting elements from the 'B' collection.
    let bData = _.map(sortedElementsFromBInBoth, 'value');

    return jStat.corrcoeff(aData, bData);
}

export default calculateOverlappingCorrelationValue