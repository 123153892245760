import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAuth0 } from "../auth0";

import { Segment, Button } from "semantic-ui-react";

import { useModuleNavigation } from "../../hooks/useModuleNavigation";

const SetupNeeded = () => {
	const history = useHistory();
	const { isAuthenticated } = useAuth0();

	const user = useSelector((state) => (state.user ? state.user : null));

	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		//-- If the user is logged in and manually goes to /setup-needed, redirect them to the home page b/c they weren't trying to access any other part of the app
		if (isAuthenticated && user && !user.setupNeeded && user.userInfo) {
			moduleNavigation.goHome();
		}
	}, []);

	return (
		<Segment style={{ marginTop: 15 }} textAlign="center">
			<h1>Welcome to aerialPLOT!</h1>
			<h4>We look forward to working with you!</h4>
			<br />
			<p>
				{
					"Before you can get started using aerialPLOT, we need to set up your account. If you just signed up you can be sure we are working on it. If you'd like to get the status of your account, please contact us."
				}
				<a href="mailto:support@aerialplot.com">support@aerialplot.com</a>
			</p>
			<br />
			<Button onClick={() => history.push("/logout")}>Logout</Button>
		</Segment>
	);
};

export default SetupNeeded;
