import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import _ from "lodash";

import { Form, Grid, List, Checkbox } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";

import * as uasActions from "../../../../redux/actions/uasActions";

const NewEditUasForm = ({
	orthoImageTypes,
	sensorsSupportedOrthos,
	analysesRequiredOrthos,
	clientId,
	uavs,
	sensors,
	originalUas,
	originalUasSensors,
	onCreateUas,
	onUpdateUas,
	onDeleteUas,
	onCancel = () => {}
}) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Control
	const [mode, setMode] = useState();

	//-- Working Data
	const [uasId, setUasId] = useState(null);
	const [uasName, setUasName] = useState(null);
	const [uasDescription, setUasDescription] = useState(null);
	const [uavId, setUavId] = useState(null);
	const [uasSensors, setUasSensors] = useState([]);
	const [canDelete, setCanDelete] = useState(false);
	const [canEdit, setCanEdit] = useState(false);

	//-- Options
	const [uavOptions, setUavOptions] = useState(null);
	const [sensorOptions, setSensorOptions] = useState(null);

	//-- UI Control
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (originalUas) {
			setMode("update");
			setUasId(originalUas.uasId);
			setUasName(originalUas.uasName);
			setUasDescription(originalUas.uasDescription);
			setUavId(originalUas.uavId);
			setUasSensors(originalUasSensors);
			setCanDelete(originalUas.canDelete);
			setCanEdit(originalUas.canDelete);
		} else {
			setMode("create");
			setCanDelete(false);
			setCanEdit(true);
		}
	}, [originalUas, originalUasSensors]);

	useEffect(() => {
		if (!uavOptions) {
			const uavOpts = _.map(uavs, ({ id, name }) => {
				const opt = {
					key: id,
					value: id,
					text: name
				};
				return opt;
			});
			setUavOptions(uavOpts);
		}

		if (!sensorOptions) {
			const senOpts = _.map(sensors, ({ id, name }) => {
				const opt = {
					key: id,
					value: id,
					text: name
				};
				return opt;
			});
			setSensorOptions(senOpts);
		}
	}, [uavOptions, sensorOptions]);

	const createUas = async () => {
		const uasObj = {
			clientId,
			name: uasName?.trim(),
			description: uasDescription,
			uavId: uavId,
			sensors: uasSensors
		};

		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(uasActions.createUas(accessToken, uasObj))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("UAS created");
					onCreateUas(uasObj);
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onCreateUas(uasObj);
								toast.success("UAS created");
								toast.info(m.text);
								setSaving(false);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
								setSaving(false);
							}
						});
					}
				}
			})
			.catch(() => {
				toast.error("Unable to create UAS. Please try again.");
				setSaving(false);
			});
	};

	const updateUas = async () => {
		const uasObj = {
			clientId,
			uasId: uasId,
			name: uasName?.trim(),
			description: uasDescription,
			uavId: uavId,
			sensors: uasSensors
		};

		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(uasActions.updateUas(accessToken, uasObj))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("UAS updated");
					onUpdateUas(uasObj);
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onUpdateUas(uasObj);
								toast.success("UAS updated");
								toast.info(m.text);
								setSaving(false);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
								setSaving(false);
							}
						});
					}
				}
			})
			.catch((error) => {
				toast.error("Unable to update UAS. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	const deleteUas = async () => {
		setDeleting(true);

		const accessToken = await getTokenSilently();
		dispatch(uasActions.deleteUas(accessToken, uasId, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("UAS deleted");
					onDeleteUas();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onDeleteUas();
								toast.success("UAS deleted");
								toast.info(m.text);
								setDeleting(false);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
								setDeleting(false);
							}
						});
					}
				}
			})
			.catch((error) => {
				toast.error("Unable to delete UAS. Please try again.");
				console.log(error);
				setDeleting(false);
			});
	};

	const createCancel = () => {
		onCancel();
	};

	const updateCancel = () => {
		resetForm();
	};

	const resetForm = () => {
		setUasId(originalUas.uasId);
		setUasName(originalUas.uasName);
		setUasDescription(originalUas.uasDescription);
		setUavId(originalUas.uavId);
		setUasSensors(originalUasSensors);
	};

	return (
		<Form>
			<Grid id="configure-uas-form-grid" columns="equal">
				<Grid.Row style={{ paddingBottom: "unset" }}>
					<Grid.Column>
						<Form.Group widths={"equal"}>
							<Form.Input
								id="form-input-name"
								width={12}
								label="Name"
								value={uasName ?? ""}
								required
								onChange={(event) => setUasName(event.target.value)}
							/>
						</Form.Group>
						<Form.Group style={{ marginBottom: 3 }}>
							<label htmlFor="form-text-area-description" className="fieldLabel" style={{ marginLeft: 7 }}>
								Description
							</label>
						</Form.Group>
						<Form.Group>
							<Form.TextArea
								id="form-text-area-description"
								width={16}
								value={uasDescription ?? ""}
								onChange={(event) => setUasDescription(event.target.value)}
								rows="5"
							/>
						</Form.Group>
						<Form.Group widths={"equal"}>
							{canEdit && (
								<Form.Select
									id="form-dropdown-uavs"
									width={12}
									label="UAV"
									placeholder="Choose UAV"
									options={uavOptions ?? []}
									value={uavId}
									required
									onChange={(event, { value }) => {
										setUavId(value);
									}}
								/>
							)}
							{!canEdit && (
								<Form.Input
									id="form-input-uav"
									width={12}
									label="UAV"
									readOnly
									value={
										uavOptions?.find((uav) => uav.value === uavId)
											? uavOptions.find((uav) => uav.value === uavId).text
											: ""
									}
								/>
							)}
						</Form.Group>
						<>
							<label htmlFor="form-select-sensor-ids" className="fieldLabel">
								Sensor{canEdit ? <span style={{ color: "#db2828" }}>*</span> : null}
							</label>
							<List
								id="form-select-sensor-ids"
								size="big"
								style={{
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: canEdit ? "#FFFFFF" : "#EEEEEE",
									marginTop: 4,
									minHeight: 100,
									maxHeight: 168
								}}
							>
								{_.map(_.orderBy(sensorOptions, ["text"], ["asc"]), ({ value, text }) => {
									return (
										<List.Item key={value}>
											<List.Content>
												<Checkbox
													readOnly={!canEdit}
													label={text}
													style={{ padding: 5 }}
													checked={uasSensors.includes(value)}
													onChange={(event, data) => {
														if (data.checked) {
															setUasSensors(uasSensors.concat([value]));
														} else {
															setUasSensors(uasSensors.filter((s) => s !== value));
														}
													}}
												/>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</>
						<Form.Group>
							<Form.Field width={8}>
								<label htmlFor="form-label-available-ortho-types" className="fieldLabel">
									Available Ortho Types
								</label>
							</Form.Field>
							<Form.Field width={8}>
								<label htmlFor="form-label-available-anaylses" className="fieldLabel">
									Available Analyses
								</label>
							</Form.Field>
						</Form.Group>
						<Form.Group>
							<Form.Field width={8}>
								{_.map(
									_.orderBy(
										sensorsSupportedOrthos.reduce((senSupOrthos, s) => {
											if (
												uasSensors.includes(s.sensorId) &&
												!senSupOrthos.some((sen) => sen.orthoImageTypeId === s.orthoImageTypeId)
											) {
												return senSupOrthos.concat(s);
											}
											return senSupOrthos;
										}, []),
										["orthoImageTypeName"],
										["asc"]
									),
									({ orthoImageTypeId, orthoImageTypeName }, index) => {
										return (
											<React.Fragment key={index}>
												<span>
													{orthoImageTypeName} (
													{orthoImageTypes.find((oit) => oit.id === orthoImageTypeId).abbreviation})
												</span>
												<br />
											</React.Fragment>
										);
									}
								)}
							</Form.Field>
							<Form.Field width={8}>
								{_.map(
									_.orderBy(
										sensorsSupportedOrthos.reduce((analyses, s) => {
											if (
												uasSensors.includes(s.sensorId) &&
												!analyses.some((an) => an.orthoImageTypeId === s.orthoImageTypeId)
											) {
												return analyses.concat(
													analysesRequiredOrthos.filter((a) => a.orthoImageTypeId === s.orthoImageTypeId)
												);
											}
											return analyses;
										}, []),
										["analysisName"],
										["asc"]
									),
									({ analysisName }, index) => {
										return (
											<React.Fragment key={index}>
												<span>{analysisName}</span>
												<br />
											</React.Fragment>
										);
									}
								)}
							</Form.Field>
						</Form.Group>
						<Form.Group style={{ float: "right" }}>
							<Form.Button
								floated="right"
								content="Cancel"
								onClick={() => {
									if (mode === "create") {
										createCancel();
									} else {
										updateCancel();
									}
								}}
								disabled={saving || deleting}
							/>
							{mode === "update" && canDelete && (
								<Form.Button
									floated="right"
									negative
									content="Delete"
									onClick={() => {
										deleteUas();
									}}
									disabled={deleting}
									loading={deleting}
								/>
							)}
							<Form.Button
								floated="right"
								color="green"
								content={mode === "create" ? "Create" : "Update"}
								onClick={() => {
									if (mode === "create") {
										createUas();
									} else {
										updateUas();
									}
								}}
								disabled={saving}
								loading={saving}
							/>
						</Form.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form>
	);
};

NewEditUasForm.propTypes = {
	orthoImageTypes: PropTypes.array.isRequired,
	sensorsSupportedOrthos: PropTypes.array.isRequired,
	analysesRequiredOrthos: PropTypes.array.isRequired,
	clientId: PropTypes.string.isRequired,
	uavs: PropTypes.array.isRequired,
	sensors: PropTypes.array.isRequired,
	originalUas: PropTypes.object,
	originalUasSensors: PropTypes.array,
	onCreateUas: PropTypes.func,
	onUpdateUas: PropTypes.func,
	onCancel: PropTypes.func,
	onDeleteUas: PropTypes.func
};

export default NewEditUasForm;
