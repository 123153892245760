import React, { useEffect, useState } from "react";
import { Segment, Table, Checkbox, Grid, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

import * as tableShader from "../../../Lumber/TableShader";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";

import _ from "lodash";
import { std } from "mathjs";

const ProtocolSiteSpecificSummaryTable = ({
	siteSpecificData,
	updateCheckedTrial,
	appenedPercent,
	disableCheckBoxes,
	tukeyData,
	tukeyDataRunning,
	treatmentsChecked,
	hideUncheckedTreatmentData
}) => {
	//-- Data Sources
	const [tableHeaderData, setTableHeaderData] = useState(null);
	const [tableData, setTableData] = useState(null);
	const [meanData, setMeanData] = useState(null);
	const [localSiteSpecificData, setLocalSiteSpecificData] = useState([]);

	useEffect(() => {
		if (siteSpecificData && siteSpecificData[0]?.assessmentId !== localSiteSpecificData[0]?.assessmentId) {
			setupTableData();
		}
	}, [siteSpecificData]);

	useEffect(() => {
		if (tukeyData && tukeyData.length > 0) {
			updateTableData();
		}
	}, [tukeyData]);

	function getTableData() {
		//Filter out trials with no selected assessment data
		const orderedData = _.filter(
			_.sortBy(siteSpecificData, ["stateName", "city", "trialName", "cooperatorName", "trialId"]),
			(d) => d.average !== null
		);
		//Filter out unchecked treatments to re-color cells
		let filteredOrderedData = [];
		if (treatmentsChecked?.length > 0) {
			filteredOrderedData = _.filter(orderedData, (od) => {
				return _.find(treatmentsChecked, (tc) => tc.trialTreatmentId === od.trialTreatmentId)?.treatmentChecked;
			});
		}
		const orderedHeaderData = _.uniqBy(filteredOrderedData, "trialId");
		setTableHeaderData(orderedHeaderData);
		const groupedTrials = _.groupBy(
			_.filter(filteredOrderedData, (d) => d.plots?.length > 0),
			"trialId"
		);
		calculateMeanAndStandardDeviation(groupedTrials);
		Object.keys(groupedTrials).length > 0 &&
		groupedTrials[Object.keys(groupedTrials)[0]][0]?.assessmentName.includes("MG50")
			? tableShader.calculateShadingForGroupedTreatmentReversed(groupedTrials, "average", "2, 185, 95")
			: tableShader.calculateShadingForGroupedTreatment(groupedTrials, "average", "2, 185, 95");

		return _.groupBy(orderedData, "trialTreatmentId");
	}

	function setupTableData() {
		const tTableData = getTableData();
		setTableData(tTableData);
		setLocalSiteSpecificData(siteSpecificData);
	}

	function calculateMeanAndStandardDeviation(groupedTrials) {
		let averages = [];
		_.map(groupedTrials, (gt, i) => {
			const filteredTrialData = _.filter(gt, (d) => {
				return d.excluded === false;
			});
			const values = _.map(filteredTrialData, "average");
			if (_.every(values, (v) => v === 0 || v === null)) {
				const average = {
					assessmentId: gt[0].assessmentId,
					plannedTimingId: gt[0].plannedTimingId,
					average: 0,
					standardDeviation: 0,
					trialId: i
				};
				averages.push(average);
			} else {
				const mean = _.meanBy(values);
				const standardDeviation = (std(values) / mean) * 100;
				const average = {
					assessmentId: gt[0].assessmentId,
					plannedTimingId: gt[0].plannedTimingId,
					average: valuePercisionConverter.getPercision(mean),
					standardDeviation: valuePercisionConverter.getPercision(standardDeviation),
					trialId: i
				};
				averages.push(average);
			}
		});

		_.map(averages, (umd) => {
			let meanComparisonValue = _.find(tukeyData, (td) => {
				return td.trialId === umd.trialId;
			});

			umd.pValue = meanComparisonValue?.pValue;
			umd.cv = meanComparisonValue?.cv;
		});

		setMeanData(averages);
	}

	function updateTableData() {
		let updatedTableData = getTableData();

		_.map(updatedTableData, (utd, trialTreatmentId) => {
			_.map(utd, (trialData) => {
				let tukeyLetters = _.find(
					tukeyData,
					(td) => td.trialId === trialData.trialId && Number(td.trialTreatmentId) === Number(trialTreatmentId)
				)?.tukeyLetters;

				trialData.tukeyLetters = tukeyLetters;
			});
		});

		//-- Recreates table to look at single trial
		//---- If all letters per trial are 'a', set them to '-'
		if (updatedTableData) {
			_.map(tableHeaderData, (thd) => {
				let trialData = _.map(updatedTableData, (utd) => {
					const td = _.find(utd, (trialData) => {
						return trialData.trialId === thd.trialId;
					});
					return td;
				});

				trialData = _.filter(trialData, (td) => {
					return !!td && td.average !== null && !td.excluded;
				});

				let allLettersAreA = _.every(trialData, ["tukeyLetters", "a"]);

				if (allLettersAreA) {
					_.map(trialData, (td) => {
						td.tukeyLetters = "-";
					});
				}
			});
		}

		setTableData(updatedTableData);
	}

	return (
		<Segment basic style={{ width: "fit-content" }}>
			<Table celled fixed striped selectable color="blue" textAlign="center" style={{ width: "fit-content" }}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell className="rowTitle" style={{ width: 130, textAlign: "center" }} />
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ width: 152 }}>
									<Checkbox
										checked={thd.trialChecked}
										disabled={disableCheckBoxes}
										onChange={() => {
											updateCheckedTrial(!thd.trialChecked, thd.trialId);
										}}
									/>
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell className="rowTitle">Trial Name</Table.HeaderCell>
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
									<span dangerouslySetInnerHTML={{ __html: thd.trialName.replaceAll("_", "_<wbr/>") }} />
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
					{tableHeaderData?.some((thd) => thd.cooperatorName) && (
						<Table.Row>
							<Table.HeaderCell className="rowTitle">Cooperator Name</Table.HeaderCell>
							{_.map(tableHeaderData, (thd) => {
								return (
									<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
										{thd.cooperatorName}
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
					)}
					<Table.Row>
						<Table.HeaderCell className="rowTitle">Farm Location</Table.HeaderCell>
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
									{thd.city}, {thd.stateName}
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (td, index) => {
						return (
							<Table.Row key={index}>
								<Table.Cell className="rowTitle">{td[0]?.trialTreatmentId}</Table.Cell>
								{_.map(tableHeaderData, (thd) => {
									const treatment = _.find(td, { trialId: thd.trialId });
									return treatment ? (
										<Table.Cell
											style={{
												backgroundColor:
													!treatment.trialChecked ||
													!_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
														?.treatmentChecked
														? "lightGray"
														: treatment.color,
												width: "150px"
											}}
										>
											<Grid style={{ width: "inherit", height: "inherit" }}>
												<Grid.Row style={{ padding: "inherit" }}>
													<Grid.Column width="1" />
													<Grid.Column width="9" style={{ padding: 0 }}>
														{!treatment.treatmentChecked
															? ""
															: treatment.excluded === true
															? "-"
															: valuePercisionConverter.getPercision(treatment.average)}
														{!treatment.treatmentChecked
															? ""
															: appenedPercent && treatment.excluded === false
															? "%"
															: ""}
													</Grid.Column>
													{
														<Grid.Column width="3" style={{ padding: 0 }}>
															{tukeyDataRunning || disableCheckBoxes ? (
																<Loader active size="tiny" style={{ zIndex: "0" }} />
															) : treatment.excluded === true ||
															  !treatment.treatmentChecked ||
															  !treatment.trialChecked ||
															  hideUncheckedTreatmentData ? (
																""
															) : (
																_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
																	?.treatmentChecked && treatment.tukeyLetters
															)}
														</Grid.Column>
													}
												</Grid.Row>
											</Grid>
										</Table.Cell>
									) : (
										<Table.Cell style={{ width: "150px" }}>{"-"}</Table.Cell>
									);
								})}
							</Table.Row>
						);
					})}

					<Table.Row>
						<Table.Cell className="rowTitle">Global Mean</Table.Cell>
						{_.map(meanData, (md) => {
							return (
								<Table.Cell>
									{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
									hideUncheckedTreatmentData
										? ""
										: md.average}
									{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
									hideUncheckedTreatmentData
										? ""
										: appenedPercent
										? "%"
										: ""}
								</Table.Cell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.Cell className="rowTitle">CV</Table.Cell>
						{_.map(meanData, (md) => {
							return (_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
								hideUncheckedTreatmentData ? (
								<Table.Cell></Table.Cell>
							) : (
								<Table.Cell>{Math.abs(md?.cv).toFixed(2)} %</Table.Cell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.Cell className="rowTitle">P-Value</Table.Cell>
						{_.map(meanData, (md) => {
							return (
								<Table.Cell>
									{(_.some(treatmentsChecked, (tc) => !tc.treatmentChecked) && hideUncheckedTreatmentData) ||
									hideUncheckedTreatmentData
										? ""
										: Number(Number(md?.pValue).toFixed(5)) === 0
										? "<0.00001"
										: Number(Number(md?.pValue).toFixed(5))}
								</Table.Cell>
							);
						})}
					</Table.Row>
				</Table.Body>
			</Table>
		</Segment>
	);
};

ProtocolSiteSpecificSummaryTable.propTypes = {
	siteSpecificData: PropTypes.array,
	updateCheckedTrial: PropTypes.func,
	appenedPercent: PropTypes.bool,
	disableCheckBoxes: PropTypes.bool,
	tukeyData: PropTypes.array,
	tukeyDataRunning: PropTypes.bool,
	treatmentsChecked: PropTypes.array,
	hideUncheckedTreatmentData: PropTypes.bool
};

export default ProtocolSiteSpecificSummaryTable;
