import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import * as ControlFunctions from "./orthoControlFunctions.js";
import FieldWizard from "../../Content/Fields/FieldWizard/index.js";
import * as fieldActions from "../../../redux/actions/fieldActions";

import { useAuth0 } from "../../../auth/auth0";
import { useDispatch, useSelector } from "react-redux";

const AddFieldModal = React.forwardRef(({ draw, canvas, dropdownRef, componentRefs }, addFieldModal) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [modalOpen, setModalOpen] = useState(false);

	AddFieldModal.displayName = "AddFieldModal";

	function handleCancel() {
		draw.current.draw.changeMode("simple_select");
		setModalOpen(false);
	}

	async function handleFinish(fieldId) {
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getNewFieldInfo(fieldId, clientId, accessToken)).then((res) => {
			ControlFunctions.handleFieldAdd(
				res.plots,
				res.field,
				res.field.id,
				res.quantifiedRegions,
				draw,
				canvas(),
				dropdownRef,
				componentRefs
			);
			setModalOpen(false);
		});
	}

	// Creating hidden button and calling fake click to trigger modal to open
	// - Workaround for not using states in the layer above
	return (
		<>
			<Modal
				trigger={
					<Button
						style={{ visibility: "hidden" }}
						ref={addFieldModal}
						onClick={() => {
							setModalOpen(true);
						}}
					/>
				}
				open={modalOpen}
				size="fullscreen"
			>
				<Modal.Header>Add New Field</Modal.Header>
				<Modal.Content>
					{/* <NewFieldForm
						handleCancel={() => handleCancel()}
						handleSave={(res, field) => handleSave(res, field)}
						handleDelete={null}
						mode="new"
					/> */}
					<FieldWizard
						activeStep="info"
						handleCancel={() => handleCancel()}
						handleFinish={(fieldId) => handleFinish(fieldId)}
						fromViewer={true}
						mode="new"
					/>
				</Modal.Content>
			</Modal>
		</>
	);
});

AddFieldModal.propTypes = {
	draw: PropTypes.object.isRequired,
	canvas: PropTypes.object,
	dropdownRef: PropTypes.object,
	componentRefs: PropTypes.object
};

export default AddFieldModal;
