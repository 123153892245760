import moment from "moment";

export function validateTrial(trial) {
	const validation = { isValid: true, messages: [], treatments: { messages: [] } };

	if (!trial.name || trial.name.length > 50) {
		validation.messages.push("Trial Name cannot be empty or more than 50 characters.");
		validation.nameError = true;
		validation.isValid = false;
	}

	if (!trial.trialOwnerCompanyId || trial.trialOwnerCompanyId === "select") {
		validation.messages.push("Company is required.");
		validation.companyError = true;
		validation.isValid = false;
	}

	if (trial.plantDate && !moment.isDate(trial.plantDate)) {
		validation.messages.push("Plant Date is invalid.");
		validation.plantDateError = true;
		validation.isValid = false;
	}

	if (trial.harvestDate && !moment.isDate(trial.harvestDate)) {
		validation.messages.push("Harvest Date is invalid.");
		validation.harvestDateError = true;
		validation.isValid = false;
	}

	if (trial.harvestDate && trial.plantDate && trial.harvestDate < trial.plantDate) {
		validation.messages.push("Plant Date must be before Harvest Date.");
		validation.harvestDateError = true;
		validation.plantDateError = true;
		validation.isValid = false;
	}

	const treatmentsValidation = validateTreatments(trial.treatments);
	validation.isValid &&= treatmentsValidation.isValid;
	validation.messages.push(...treatmentsValidation.messages);
	validation.treatments = treatmentsValidation.treatments;

	if (trial.notes && trial.notes.length > 200) {
		validation.messages.push("Notes cannot be more than 200 characters.");
		validation.notesError = true;
		validation.isValid = false;
	}

	if (
		trial.trialSponsorClientId === trial.trialOwnerClientId &&
		trial.trialOwnerClientId !== null &&
		trial.trialSponsorClientId !== null
	) {
		validation.messages.push("Trial sponsor org cannot be the same as the trial owner org.");
		validation.trialSponsorClientId = true;
		validation.isValid = false;
	}

	if (!trial.flightScheduleId) {
		validation.messages.push("Flight schedule needs to be set when saving a trial.");
		validation.flightScheduleId = true;
		validation.isValid = false;
	}

	return validation;
}

export function validateTreatments(treatments) {
	const validation = { isValid: true, messages: [], treatments: { messages: [] } };

	treatments.forEach((treatment) => {
		if (treatment.treatmentName && treatment.treatmentName.length > 50) {
			validation.treatments.messages.push({
				id: treatment.treatmentId,
				name: true,
				description: false,
				message: "Treatment Name cannot be more than 50 characters."
			});
			validation.isValid = false;
		}

		if (treatment.treatmentDescription && treatment.treatmentDescription.length > 200) {
			if (validation.treatments.messages.length === 0) {
				validation.messages.push("Treatment errors were found. Please review the reatment list.");
			}

			const err = {
				id: treatment.treatmentId,
				name: false,
				description: true,
				message: "Treatment Description cannot be more than 200 characters."
			};
			validation.treatments.messages.push(err);
			validation.isValid = false;
		}
	});

	return validation;
}
