import * as types from "./actionTypes";
import * as farmApi from "../../apis/farmApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getFarmsSuccess() {
	return { type: types.FARM_GET_FARMS_SUCCESS };
}

export function getFarmInfoSuccess() {
	return { type: types.FARM_GET_FARM_INFO_SUCCESS };
}

export function createFarmSuccess() {
	return { type: types.FARM_CREATE_FARM_SUCCESS };
}

export function updateFarmSuccess() {
	return { type: types.FARM_UPDATE_FARM_SUCCESS };
}

export function deleteFarmSuccess() {
	return { type: types.FARM_DELETE_FARM_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getFarms(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarms(accessToken, clientId)
				.then((res) => {
					dispatch(getFarmsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFarmInfo(accessToken, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.getFarmInfo(accessToken, farmId)
				.then((res) => {
					dispatch(getFarmInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createFarm(accessToken, newFarm) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.createFarm(accessToken, newFarm)
				.then((res) => {
					dispatch(createFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateFarm(accessToken, updateFarm) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.updateFarm(accessToken, updateFarm)
				.then((res) => {
					dispatch(updateFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFarm(accessToken, clientId, farmId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return farmApi
				.deleteFarm(accessToken, clientId, farmId)
				.then((res) => {
					dispatch(deleteFarmSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
