import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Header, Loader, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as tableShader from "../../../Lumber/TableShader";
import SummaryTableResults from "./summaryTableResults";
import SummaryTableSelections from "./summaryTableSelections";

const SummaryTable = () => {
	const DATE_FORMAT = "M/D/YYYY";

	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	//-- Data Source
	//-- rawTrialOptions is the result from getTrialOptions
	const [rawTrialOptions, setRawTrialOptions] = useState(null);
	const [trialDataResults, setTrialDataResults] = useState([]);
	const [footerData, setFooterData] = useState([]);
	const [allTreatmentData, setAllTreatmentData] = useState([]);
	const [assessmentData, setAssessmentData] = useState([]);
	const [summaryTableSelections, setSummaryTableSelections] = useState([]);
	const [assessmentTypes, setAssessmentTypes] = useState([]);

	//-- Options
	//-- These options are all set in the summary table selections component
	const [selectedAlphaOption, setSelectedAlphaOption] = useState("");
	const [selectedMeanComparisonOption, setSelectedMeanComparisonOption] = useState("");
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [assessmentOptions, setAssessmentOptions] = useState([]);
	const [summaryTablePresetOptions, setSummaryTablePresetOptions] = useState([]);
	const [selectedPresetOption, setSelectedPresetOption] = useState("");
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);

	//-- UI Control
	const [assessmentsLoading, setAssessmentsLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);

	//Data Analysis Critera
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	useEffect(() => {
		if (userAuth.currentClientId && moduleNavigation.trialId) {
			getTrialOptions();
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		if (rawTrialOptions) {
			stageAlphaOptions(rawTrialOptions.alphaOptions, rawTrialOptions.defaultAlphaValueId);
			stageMeanComparisonOptions(rawTrialOptions.meanComparisonOptions, rawTrialOptions.defaultMeanComparisonId);
			stageQauntifiedRegionTypeOptions(rawTrialOptions.quantifiedRegionTypeOptions);
			let presetOptionsTemp = stagePresetOptions(rawTrialOptions.summaryTablePresets);
			stageAssessmentOptions(presetOptionsTemp, true);
		}
	}, [rawTrialOptions]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (
				userAuth.currentClientId &&
				moduleNavigation.trialId &&
				selectedMeanComparisonOption &&
				selectedAlphaOption &&
				summaryTableSelections?.length > 0
			) {
				getAllTrialData(summaryTableSelections, false);
			}
		}
	}, [selectedAlphaOption, selectedMeanComparisonOption]);

	useEffect(() => {
		if (!needToUpdateAnalytics) {
			if (selectedPresetOption !== "none" && assessmentTypes.length > 0 && !assessmentsLoading) {
				stageAssessmentOptions(summaryTablePresetOptions, true);
			}
		}
	}, [selectedPresetOption]);

	//-- Getting DDL Options
	async function getTrialOptions() {
		setOptionsLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getSummaryTableDataOptions(userAuth.currentClientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				res.data.quantifiedRegionTypeOptions = _.filter(res.data.quantifiedRegionTypeOptions, (qrto) => {
					return qrto.name.includes("Stand Count");
				});
				let quantifiedRegionTypeId =
					res.data.quantifiedRegionTypeOptions?.length > 0 ? res.data.quantifiedRegionTypeOptions[0].id : null;
				_.map(res.data.trialAnalysisOptions, (tao) => {
					tao.analysisTypes = _.filter(tao.analysisTypes, (at) => {
						return at.name.includes("Stand Count") && at.quantifiedRegionTypeId === quantifiedRegionTypeId;
					});
				});
				res.data.trialAnalysisOptions = _.filter(res.data.trialAnalysisOptions, (tao) => tao.analysisTypes.length > 0);
				setRawTrialOptions(res.data);
				setOptionsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to load trial options. Please try again or contact an aerialPLOT admin.");
			});
	}

	function stagePresetOptions(presets) {
		let tempOptions = [{ key: "none", value: "none", text: "None" }];

		tempOptions = tempOptions.concat(
			_.map(presets, (p) => {
				const opt = { key: p.id, value: p.id, text: p.name, default: p.default };
				return opt;
			})
		);

		setSummaryTablePresetOptions(tempOptions);
		return tempOptions;
	}

	function stageAlphaOptions(opts, defaultAlphaValueId) {
		//-- setting alpha options
		let alphaOptionsTemp = [];
		_.forEach(opts, (option) => {
			alphaOptionsTemp.push({ key: option.id, value: option.id, text: option.value });
		});

		let defaultAlphaOption = _.find(alphaOptionsTemp, (ao) => {
			return ao.text == 0.1;
		});

		let alphaId = defaultAlphaValueId ?? defaultAlphaOption.key;
		setSelectedAlphaOption(alphaId);
		setAlphaOptions(alphaOptionsTemp);
	}

	function stageMeanComparisonOptions(opts, defaultMeanComparisonId) {
		//-- setting alpha options
		let meanComparisonTemp = [];
		_.forEach(opts, (option) => {
			meanComparisonTemp.push({ key: option.id, value: option.id, text: option.name });
		});

		let defaultMeanComparison = _.find(meanComparisonTemp, (mct) => {
			return mct.text == "Tukey HSD";
		});

		let meanComparisonId = defaultMeanComparisonId ?? defaultMeanComparison.key;
		setSelectedMeanComparisonOption(meanComparisonId);
		setMeanComparisonOptions(meanComparisonTemp);
	}

	function stageQauntifiedRegionTypeOptions(opts) {
		const qrOpts = _.map(opts, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setQuantifiedRegionTypeOptions(qrOpts);
	}

	function stageAssessmentOptions(summaryTablePresetOptions, appendToTable) {
		let groundDataAssessmentOptions = getGroundDataAssessmentOptions(rawTrialOptions.groundDataOptions);
		let trialAnalysisAssessmentOptions = getTrialAnalysisAssessmentOptions(rawTrialOptions.trialAnalysisOptions);
		let curveModelAssessmentOptions = getCurveModelAssessmentOptions(
			rawTrialOptions.growthCurveOptions,
			rawTrialOptions.presetOptions.curveModelAnalysisDisplayNames
		);

		//-- Concat all of the similar data types together
		let combinedAssessmentData = []
			.concat(trialAnalysisAssessmentOptions.assessmentOptions)
			.concat(groundDataAssessmentOptions.assessmentOptions)
			.concat(curveModelAssessmentOptions.assessmentOptions);
		combinedAssessmentData = _.orderBy(combinedAssessmentData, "date");
		setAssessmentData(combinedAssessmentData);

		let combinedAssessmentTypes = []
			.concat(trialAnalysisAssessmentOptions.combinedAssessmentTypes)
			.concat(groundDataAssessmentOptions.combinedAssessmentTypes)
			.concat(curveModelAssessmentOptions.combinedAssessmentTypes);
		// combinedAssessmentTypes = _.uniqBy(combinedAssessmentTypes, "id");
		combinedAssessmentTypes = _.uniqWith(combinedAssessmentTypes, (a, b) => {
			return a.id === b.id && a.quantifiedRegionTypeId === b.quantifiedRegionTypeId;
		});

		let combinedAssessmentDates = []
			.concat(trialAnalysisAssessmentOptions.combinedAssessmentDates)
			.concat(groundDataAssessmentOptions.combinedAssessmentDates)
			.concat(curveModelAssessmentOptions.combinedAssessmentDates);
		combinedAssessmentDates = _.orderBy(combinedAssessmentDates, "date");

		let aOpts = stageAssessmentOptionsDropDownList(combinedAssessmentTypes);

		//-- Setup the summary table loading selections from either saved summary table data or preset options
		let initialAssessmentSelections = getInitialAssessmentSelections(
			combinedAssessmentTypes,
			summaryTablePresetOptions,
			rawTrialOptions.presetOptions.growthCurvePresets
		);

		let initialSummaryTableSelections = createInitialSummaryTableSelections(
			initialAssessmentSelections,
			combinedAssessmentTypes,
			combinedAssessmentDates,
			combinedAssessmentData,
			aOpts,
			rawTrialOptions.groundDataOptions,
			rawTrialOptions.trialAnalysisOptions
		);

		//Push stand count to the end of the array. It was causing all sorts of issues when it was at the top
		let standCountIndex = null;
		_.map(initialSummaryTableSelections, (ists, index) => {
			if (ists.displayName.includes("Stand Count (LAI")) {
				ists.analysisId = ists.id;
				standCountIndex = index;
			}
		});

		if (standCountIndex !== null) {
			initialSummaryTableSelections.push(initialSummaryTableSelections.splice(standCountIndex, 1)[0]);
		}

		//-- Check if this is the first load. On initial load, the selectedAlphaOption and selectedMeanComparisonOption will be empty strings
		if (selectedAlphaOption !== "" || selectedMeanComparisonOption !== "") {
			getAllTrialDataThatIsNotLoaded(initialSummaryTableSelections, appendToTable);
		}
		setSummaryTableSelections(initialSummaryTableSelections);
		setAssessmentTypes(combinedAssessmentTypes);
	}

	function getGroundDataAssessmentOptions(groundDataOptions) {
		let combinedAssessmentTypes = [];
		let combinedAssessmentDates = [];
		let assessmentOptions = [];

		_.forEach(groundDataOptions, ({ groundDataAssessments, groundDatasetId, assessmentDate }) => {
			assessmentOptions.push({
				id: groundDatasetId,
				date: assessmentDate,
				enabled: true,
				checked: true
			});

			//-- Setting the type of assessment
			_.forEach(groundDataAssessments, (gda) => {
				gda.type = "groundData";
			});

			//-- Creating the list of ground data assessment types
			combinedAssessmentTypes = combinedAssessmentTypes.concat(groundDataAssessments);

			//-- Creating list of dates for ground data
			_.forEach(groundDataAssessments, (gda) => {
				combinedAssessmentDates.push({
					id: groundDatasetId,
					date: assessmentDate,
					assessmentType: gda.id,
					type: "grounddata",
					name: gda.name,
					displayName: gda.name
				});
			});
		});

		combinedAssessmentTypes = _.orderBy(combinedAssessmentTypes, ["name"], ["asc"]);

		//-- Creating the ground data header in DDL
		if (groundDataOptions.length > 0) {
			combinedAssessmentTypes.unshift({ id: "groundDataHeader", name: "Ground Data" });
		}

		return {
			assessmentOptions: assessmentOptions,
			combinedAssessmentTypes: combinedAssessmentTypes,
			combinedAssessmentDates: combinedAssessmentDates
		};
	}

	function getCurveModelAssessmentOptions(growthCurveOptions, displayNames) {
		let combinedAssessmentTypes = [];
		_.forEach(
			growthCurveOptions,
			({
				curveModelName,
				curveModelAnalysisEndDate,
				curveModelAnalysisStartDate,
				analysisId,
				curveModelAnalysisId,
				checked,
				enabled,
				selected,
				analysisTypeId,
				quantifiedRegionTypeId
			}) => {
				let matchingDisplayName = _.find(
					displayNames,
					(dn) =>
						dn.analysisId === analysisId &&
						dn.analysisTypeId === analysisTypeId &&
						dn.primaryCurveModelAnalysisId === curveModelAnalysisId &&
						//	dn.secondaryCurveModelAnalysisId === curveModelAnalysisId) &&
						(dn.primaryQuantifiedRegionTypeId === quantifiedRegionTypeId ||
							dn.secondaryQuantifiedRegionTypeId === quantifiedRegionTypeId)
				);

				//-- Create the list of curve model assessment types
				combinedAssessmentTypes.push({
					id: curveModelName,
					name: curveModelName,
					enabled: enabled,
					checked: checked,
					selected: selected,
					type: "growthCurve",
					endDate: curveModelAnalysisEndDate,
					startDate: curveModelAnalysisStartDate,
					analysisId: analysisId,
					curveModelAnalysisId: curveModelAnalysisId,
					analysisTypeId: analysisTypeId,
					quantifiedRegionTypeId: quantifiedRegionTypeId,
					order: matchingDisplayName?.order,
					displayName: matchingDisplayName?.name
				});
			}
		);

		combinedAssessmentTypes = _.sortBy(combinedAssessmentTypes, [(cat) => cat.order || Infinity, "name"], ["desc"]);
		//-- Creating the curve model header in DDL
		if (growthCurveOptions.length > 0) {
			combinedAssessmentTypes.unshift({ id: "growthCurveHeader", name: "Growth Curve" });
		}

		return {
			assessmentOptions: [],
			combinedAssessmentTypes: combinedAssessmentTypes,
			combinedAssessmentDates: []
		};
	}

	function getTrialAnalysisAssessmentOptions(trialAnalysisOptions) {
		let combinedAssessmentTypes = [];
		let combinedAssessmentDates = [];
		let assessmentOptions = [];

		_.forEach(trialAnalysisOptions, ({ analysisTypes, flightId, flightDate }) => {
			assessmentOptions.push({
				id: flightId,
				date: flightDate,
				enabled: true,
				checked: true
			});

			//-- Setting the type of assessment
			_.forEach(analysisTypes, (at) => {
				at.type = "analysis";
			});

			//-- Creating the list of flight data assessment types
			combinedAssessmentTypes = combinedAssessmentTypes.concat(analysisTypes);

			//-- Creating list of dates for flight data
			_.forEach(analysisTypes, (at) => {
				combinedAssessmentDates.push({
					id: flightId,
					date: flightDate,
					assessmentType: at.id,
					checked: at.checked,
					type: at.type,
					name: at.name
				});
			});
		});

		combinedAssessmentTypes = _.orderBy(combinedAssessmentTypes, ["name"], ["asc"]);

		//-- Creating the flight data header in DDL
		if (trialAnalysisOptions.length > 0) {
			combinedAssessmentTypes.unshift({ id: "analysesHeader", name: "Analyses" });
		}

		return {
			assessmentOptions: assessmentOptions,
			combinedAssessmentTypes: combinedAssessmentTypes,
			combinedAssessmentDates: combinedAssessmentDates
		};
	}

	function stageAssessmentOptionsDropDownList(combinedAssessmentTypes) {
		const aOpts = _.map(combinedAssessmentTypes, (a) => {
			const isHeader = a.id.includes("Header");
			return {
				key: a.id,
				value: a.id,
				text: a.name,
				content: isHeader ? <Header as="h4" content={a.name} /> : null,
				disabled: isHeader
			};
		});
		setAssessmentOptions(aOpts);
		return aOpts;
	}

	//-- This function setups the initial assessment selections
	//-- If there is saved assessment selections in the AssessmentSelectionsForSummaryTable table, those will get initialized
	//-- Else we need to initialize the Assessment Preset data
	function getInitialAssessmentSelections(combinedAssessmentTypes, summaryTablePresetOptions, options) {
		//-- Get the assessments that are saved
		let savedAssessments = _.filter(combinedAssessmentTypes, (types) => {
			return types.selected == true && types.enabled == true;
		});

		//-- Check if there are no saved assessments and a preset option is not selected
		if (savedAssessments.length > 0 && !selectedPresetOption) {
			setSelectedPresetOption("none");
		} else {
			//-- Setup preset summary table selections
			let selectedPresetOptionTemp =
				selectedPresetOption ||
				(_.find(summaryTablePresetOptions, { default: true })?.key ?? summaryTablePresetOptions[0]?.key);

			savedAssessments = createPresetAssessmentSelections(selectedPresetOptionTemp, combinedAssessmentTypes);
		}

		if (!userAuth.isApAdmin) {
			savedAssessments = _.filter(savedAssessments, (sa) => {
				return _.some(options, (o) => {
					return (
						(sa.curveModelAnalysisId === o.curveModelAnalysisId &&
							sa.analysisId === o.analysisId &&
							sa.analysisTypeId === o.analysisTypeId &&
							sa.quantifiedRegionTypeId === o.quantifiedRegionTypeId) ||
						sa.type !== "growthCurve"
					);
				});
			});
		}

		return savedAssessments;
	}

	function createPresetAssessmentSelections(selectedSummaryTablePresetOption, assessmentTypes) {
		//-- If none, user is customizing selections or page is just loading
		if (selectedSummaryTablePresetOption !== "none") {
			//-- Find matching preset
			let matchingPreset = _.find(rawTrialOptions.summaryTablePresets, { id: selectedSummaryTablePresetOption });

			if (matchingPreset) {
				//-- Turn off all assessments
				let updatedAssessmentsChecked = _.map(assessmentTypes, (ac) => {
					if (!ac.id.includes("Header")) {
						ac.selected = false;
						ac.enabled = false;
					}

					return ac;
				});

				/* fake type for refrence
				type assessmentToCheck {
					analysisId?: string,
					analysisTypeId?: string,
					growthCurveAnalysisId?: string,
					groundDataAssessmentId?: string,
					quantifiedRegionTypeId?: string,
				}; */
				//-- Get all assessments to turn on
				let allAssessmentsToCheck = [];
				for (let { presetAnalyses, presetCurveModels, presetGroundData } of matchingPreset.sections) {
					allAssessmentsToCheck = [
						...allAssessmentsToCheck,
						..._.map(presetAnalyses, (pa) => ({ analysisId: pa.analysisId }))
					];
					allAssessmentsToCheck = [
						...allAssessmentsToCheck,
						..._.map(presetCurveModels, (pcm) => ({
							analysisId: pcm.analysisId,
							analysisTypeId: pcm.analysisTypeId,
							curveModelAnalysisId: pcm.curveModelAnalysisId,
							quantifiedRegionTypeId: pcm.quantifiedRegionTypeId
						}))
					];
					allAssessmentsToCheck = [
						...allAssessmentsToCheck,
						..._.map(presetGroundData, (pgd) => ({ groundDataAssessmentId: pgd.groundDataAssessmentId }))
					];
				}

				//-- Check if any assessment types match preset assessments
				//-- Remove header entries
				const existingAssessmentIds = _.map(
					_.filter(assessmentTypes, (at) => !at.id.includes("Header")),
					(at) => at.curveModelAnalysisId ?? at.id
				);
				let presetAssessmentExists = _.some(allAssessmentsToCheck, (aatc) => {
					return (
						existingAssessmentIds.includes(aatc.groundDataAssessmentId) ||
						existingAssessmentIds.includes(aatc.analysisId) ||
						existingAssessmentIds.includes(aatc.curveModelAnalysisId)
					);
				});

				//-- If no assessments exist, turn on all assessments available
				//-- set preset to none
				if (!presetAssessmentExists) {
					_.forEach(assessmentTypes, (at) => {
						if (!at.id.includes("Header")) {
							at.selected = true;
							at.enabled = true;
							at.checked = true;
						}
					});

					selectedSummaryTablePresetOption = "none";
				} else {
					//-- Turn on assessments that are included in preset
					for (let uac of updatedAssessmentsChecked) {
						let selected = false;
						for (let atc of allAssessmentsToCheck) {
							if (atc.curveModelAnalysisId) {
								selected = uac.curveModelAnalysisId === atc.curveModelAnalysisId;
								if (atc.analysisId && selected) {
									selected =
										uac.analysisId === atc.analysisId &&
										uac.analysisTypeId === atc.analysisTypeId &&
										uac.quantifiedRegionTypeId === atc.quantifiedRegionTypeId;
								}
							} else if (atc.analysisId) {
								selected = uac?.name.includes("Stand Count (LAI")
									? uac.id === atc.analysisId
									: uac.analysisId === atc.analysisId;
							} else if (atc.groundDataAssessmentId) {
								selected = uac.id === atc.groundDataAssessmentId;
							}

							if (selected) break;
						}

						uac.selected = selected;
						uac.enabled = selected;
						uac.checked = selected;
					}
				}

				//-- Filter out all assessment that are not enabled and selected
				updatedAssessmentsChecked = _.filter(updatedAssessmentsChecked, (types) => {
					return types.selected == true && types.enabled == true;
				});

				setSelectedPresetOption(selectedSummaryTablePresetOption);

				return updatedAssessmentsChecked;
			}
		}
	}

	//-- stage summary table selections on first time page load and preset option switch
	function createInitialSummaryTableSelections(
		initialAssessmentSelections,
		combinedAssessmentTypes,
		combinedAssessmentDates,
		combinedAssessmentData,
		aOpts,
		groundDataOptions,
		trialAnalysisOptions
	) {
		let initialSummaryTableSelections = [];
		combinedAssessmentDates = _.orderBy(combinedAssessmentDates, "date");
		_.map(initialAssessmentSelections, (sd) => {
			const isAnalysis = sd.type === "analysis";
			const isGrowthCurveAnalysis = sd.type === "growthCurve";
			//-- check if the current selection is a curve model
			if (sd.curveModelAnalysisId) {
				initialSummaryTableSelections = initialSummaryTableSelections.concat({
					id: sd.name,
					name: sd.name,
					assessments: null,
					endDate: sd.endDate,
					startDate: sd.startDate,
					checked: true,
					enabled: true,
					selected: sd.selected,
					isCurveModel: true,
					analysisValue: null,
					isAnalysis: isAnalysis,
					isGrowthCurveAnalysis: isGrowthCurveAnalysis,
					analysisId: sd.analysisId,
					curveModelAnalysisId: sd.curveModelAnalysisId,
					initialLoad: true,
					displayName: sd.displayName ?? sd.name,
					analysisTypeId: sd.analysisTypeId
				});
			} else {
				let flightAssessmentIndex = _.findIndex(combinedAssessmentTypes, (cat) => {
					return (
						(cat.id === sd.id && cat.quantifiedRegionTypeId === sd.quantifiedRegionTypeId) ||
						cat.id === sd.assessmentType
					);
				});
				const analysis =
					flightAssessmentIndex !== -1 ? combinedAssessmentTypes[flightAssessmentIndex] : combinedAssessmentTypes[1];
				const analysisValue = analysis.id;
				const quantifiedRegionTypeValue = analysis.quantifiedRegionTypeId;

				const copyCombinedAssessmentDates = _.cloneDeep(combinedAssessmentDates);
				let assessmentSummaryTableOptions = stageAssessmentsForInitialSummaryTableSelections(
					groundDataOptions,
					trialAnalysisOptions,
					copyCombinedAssessmentDates,
					combinedAssessmentData,
					isAnalysis,
					analysisValue,
					quantifiedRegionTypeValue,
					aOpts
				);
				const opt = aOpts[flightAssessmentIndex !== -1 ? flightAssessmentIndex : 1];
				initialSummaryTableSelections = initialSummaryTableSelections.concat({
					id: opt.value,
					name: opt.text,
					displayName: opt.text,
					assessments: _.orderBy(assessmentSummaryTableOptions, "date", "asc"),
					analysisValue: analysisValue,
					isAnalysis: isAnalysis,
					isGrowthCurveAnalysis: isGrowthCurveAnalysis,
					analysisId: sd.analysisId,
					curveModelAnalysisId: sd.curveModelAnalysisId,
					quantifiedRegionTypeOptionId: sd.quantifiedRegionTypeId,
					initialLoad: true
				});
			}
		});

		return initialSummaryTableSelections;
	}

	function stageAssessmentsForInitialSummaryTableSelections(
		groundDataOptions,
		trialAnalysisOptions,
		copyCombinedAssessmentDates,
		combinedAssessmentData,
		isAnalysis,
		analysisValue,
		quantifiedRegionTypeId,
		aOpts
	) {
		let assessments = _.map(_.uniqBy(combinedAssessmentData, "id"), function (cad) {
			let assessmentCheckboxes = _.find(copyCombinedAssessmentDates, function (x) {
				return x.id === cad.id;
			});

			assessmentCheckboxes = isAnalysis
				? stageAnalysisAssessment(
						assessmentCheckboxes,
						analysisValue,
						quantifiedRegionTypeId,
						trialAnalysisOptions,
						aOpts
				  )
				: stageGroundDataAssessment(assessmentCheckboxes, analysisValue, groundDataOptions, aOpts);

			return assessmentCheckboxes;
		});

		return assessments;
	}

	//-- Stage flight analysis assessments (checkboxes)
	function stageAnalysisAssessment(
		assessmentCheckBox,
		analysisValue,
		quantifiedRegionTypeId,
		trialAnalysisOptions,
		aOpts
	) {
		assessmentCheckBox.enabled = _.some(trialAnalysisOptions, (tao) => {
			return (
				tao.flightId === assessmentCheckBox.id &&
				_.some(tao.analysisTypes, { id: analysisValue, quantifiedRegionTypeId: quantifiedRegionTypeId })
			);
		});
		assessmentCheckBox.checked = _.some(trialAnalysisOptions, (tao) => {
			return (
				tao.flightId === assessmentCheckBox.id &&
				_.some(tao.analysisTypes, { id: analysisValue, quantifiedRegionTypeId: quantifiedRegionTypeId, checked: true })
			);
		});
		assessmentCheckBox.type = "analysis";
		assessmentCheckBox.groundDataAssessmentId = "";
		assessmentCheckBox.assessmentType = analysisValue;
		assessmentCheckBox.analysisId = analysisValue;
		assessmentCheckBox.quantifiedRegionTypeId = quantifiedRegionTypeId;
		assessmentCheckBox.name = _.find(aOpts, function (x) {
			return x.value === analysisValue;
		}).text;
		return assessmentCheckBox;
	}

	//-- Stage ground data assessments (checkboxes)
	function stageGroundDataAssessment(assessmentCheckBox, analysisValue, groundDataOptions, aOpts) {
		assessmentCheckBox.enabled = _.some(groundDataOptions, (gdo) => {
			return (
				gdo.groundDatasetId === assessmentCheckBox.id &&
				gdo.assessmentDate === assessmentCheckBox.date &&
				_.some(gdo.groundDataAssessments, { id: analysisValue })
			);
		});
		assessmentCheckBox.checked = _.some(groundDataOptions, (gdo) => {
			return (
				gdo.groundDatasetId === assessmentCheckBox.id &&
				_.some(gdo.groundDataAssessments, { id: analysisValue, checked: true })
			);
		});
		assessmentCheckBox.analysisId = "";
		assessmentCheckBox.type = "grounddata";
		assessmentCheckBox.groundDataAssessmentId = analysisValue;
		assessmentCheckBox.assessmentType = analysisValue;
		assessmentCheckBox.name = _.find(aOpts, function (x) {
			return x.value === analysisValue;
		}).text;
		assessmentCheckBox.displayName = assessmentCheckBox.name;
		return assessmentCheckBox;
	}

	//-- When the summary table preset is selected, we need to load the data that has not been loaded
	async function getAllTrialDataThatIsNotLoaded(selections, appendToTable) {
		let selectionsClone = _.cloneDeep(selections);
		let filteredSelections = _.filter(selectionsClone, (s) => {
			let analysisValuesExist = doesAnalysisExistInTreatmentData(s.analysisId);
			//-- Ground data is setup really dumb. If there is no analysisId, then we use the analysisValue, which is the ground data id
			let groundDataValuesExist = doesGroundDataExistInTreatmentData(s.analysisId ? null : s.analysisValue);
			let curveModelDataValuesExist = doesCurveModelDataExistInTreatmentData(
				s.analysisId,
				s.curveModelAnalysisId,
				s.analysisTypeId
			);
			if (!analysisValuesExist && !groundDataValuesExist && !curveModelDataValuesExist) {
				return s;
			}
		});

		if (filteredSelections.length > 0 && appendToTable) {
			getAllTrialData(filteredSelections, appendToTable);
		} else {
			getAllTrialData(selections, false);
		}
	}

	//-- API call to get all trial data from summary table selections
	async function getAllTrialData(selections, appendToCurrentData) {
		setAssessmentsLoading(true);
		const accessToken = await getTokenSilently();
		await Promise.all(
			_.map(selections, (analysis) => {
				let analysisTypeId = getGrowthCurveAnalysisType(analysis.name);

				//Need to check again if the selection is growth curve analysis
				const assessmentType = _.find(assessmentTypes, (at) => {
					return at.name === analysis.name;
				});

				if (analysisTypeId === undefined) {
					analysisTypeId = assessmentType.analysisTypeId;
				}
				let isGrowthCurveAnalysis = assessmentType?.type === "growthCurve";

				return dispatch(
					analysisActions.getAnalysisResultsForSummaryTable(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						isGrowthCurveAnalysis ? analysis.analysisId : analysis.isAnalysis ? analysis.analysisValue : null,
						analysis.quantifiedRegionTypeOptionId,
						analysisTypeId,
						isGrowthCurveAnalysis ? null : analysis.isAnalysis ? null : analysis.analysisValue,
						isGrowthCurveAnalysis ? analysis.curveModelAnalysisId : null,
						selectedMeanComparisonOption,
						selectedAlphaOption,
						accessToken,
						removeDamagedOrExcluded,
						removeOutliers,
						outlierType
					)
				).then((res) => {
					return res;
				});
			})
		)
			.then((res) => {
				_.map(res, (r) => {
					if (r.length > 0) {
						setupTreatmentData(r);
					}
				});

				let flattenedResponse = _.flatten(res);
				flattenedResponse = _.sortBy(flattenedResponse, ["flightDate", "dateCollected"]);

				const groupedTreatments = groupTreatments(flattenedResponse, appendToCurrentData);
				setTrialDataResults(groupedTreatments);

				setupFooterData(groupedTreatments);
				setAssessmentsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setAssessmentsLoading(false);
				toast.error("Failed to load trial data.");
			});
	}

	//-- API call to get all trial data from the summary table selection
	async function getSingleAssessmentTrialData(
		analysisId,
		groundDataAssessmentId,
		growthCurveAnalysisId,
		quantifiedRegionTypeId = null,
		analysisTypeId = null
	) {
		if (!assessmentsLoading) {
			let analysisValuesExist = doesAnalysisExistInTreatmentData(analysisId, quantifiedRegionTypeId);
			let groundDataValuesExist = doesGroundDataExistInTreatmentData(groundDataAssessmentId);
			let curveModelDataValuesExist = doesCurveModelDataExistInTreatmentData(
				analysisId,
				growthCurveAnalysisId,
				analysisTypeId
			);

			if (!analysisValuesExist && !groundDataValuesExist && !curveModelDataValuesExist && !needToUpdateAnalytics) {
				setAssessmentsLoading(true);
				const accessToken = await getTokenSilently();
				dispatch(
					analysisActions.getAnalysisResultsForSummaryTable(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						analysisId,
						quantifiedRegionTypeId,
						analysisTypeId,
						groundDataAssessmentId,
						growthCurveAnalysisId,
						selectedMeanComparisonOption,
						selectedAlphaOption,
						accessToken
					)
				)
					.then((res) => {
						res = _.sortBy(res, ["flightDate", "dateCollected"]);
						setupTreatmentData(res);
						const groupedTreatments = groupTreatments(res, true);
						setTrialDataResults(groupedTreatments);

						setupFooterData(groupedTreatments);
						setAssessmentsLoading(false);
					})
					.catch((err) => {
						console.log(err);
						setAssessmentsLoading(false);
						toast.error("Failed to load trial data.");
					});
			}
		}
	}

	//-- Check if flight analysis id exists in treatment data
	function doesAnalysisExistInTreatmentData(analysisId, quantifiedRegionTypeId) {
		return (
			analysisId !== null &&
			_.some(allTreatmentData, {
				analysisId: analysisId,
				quantifiedRegionTypeId: quantifiedRegionTypeId,
				curveModelAnalysisId: null
			})
		);
	}

	//-- Check if ground data exists in treatment data
	function doesGroundDataExistInTreatmentData(groundDataAssessmentId) {
		return (
			groundDataAssessmentId !== null &&
			_.some(allTreatmentData, {
				groundDataAssessmentId: groundDataAssessmentId,
				curveModelAnalysisId: null
			})
		);
	}

	//-- Check if curve model exists in treatment data
	function doesCurveModelDataExistInTreatmentData(analysisId, growthCurveAnalysisId, analysisTypeId) {
		return (
			growthCurveAnalysisId !== null &&
			_.some(allTreatmentData, (atd) => {
				let currentAnalysisTypeId = getGrowthCurveAnalysisType(atd.curveModelName);
				return (
					atd.analysisId === analysisId &&
					atd.curveModelAnalysisId === growthCurveAnalysisId &&
					currentAnalysisTypeId === analysisTypeId
				);
			})
		);
	}

	function getGrowthCurveAnalysisType(growthCurveName) {
		const result = _.find(rawTrialOptions.growthCurveOptions, (gco) => {
			return gco.curveModelName === growthCurveName;
		});

		return result?.analysisTypeId;
	}

	//-- sets up treatments with analysis value, table shading, and mean comparison value
	//-- Called after API call is successful
	function setupTreatmentData(r) {
		const isAnalysisType = r[0].analysisName;
		const isGrowthCurveType = r[0].curveModelName;

		//Need to calculate mean manually for stand counts
		if (isAnalysisType === "Stand Count (LAI)" || isAnalysisType === "Stand Count (LAI_M3M)") {
			let mean = _.meanBy(r, "standCountAnalysisResultValue");
			_.map(r, (value) => {
				value.mean = mean;
			});
		}
		//-- Get treatments by dataset
		let treatmentsByAssessment = isGrowthCurveType
			? _.groupBy(r, "curveModelAnalysisId")
			: isAnalysisType
			? _.groupBy(r, "flightId")
			: _.groupBy(r, "groundDatasetId");

		_.map(treatmentsByAssessment, (treatments) => {
			let orderedList = _.orderBy(treatments, ["trialResultAverageValue"], ["desc"]);

			//-- Gets treatments by assessment type
			let treatmentsByType = isGrowthCurveType
				? _.groupBy(r, "curveModelAnalysisId")
				: isAnalysisType
				? _.groupBy(orderedList, "analysisId")
				: _.groupBy(orderedList, "groundDataAssessmentId");

			//-- Setup mean comparison values
			_.map(treatmentsByType, (analysis) => {
				let allMeanComparisonLetterA = true;
				_.forEach(analysis, (treat) => {
					if (treat.letter && treat.letter !== "a") {
						allMeanComparisonLetterA = false;
						return false;
					}
				});

				if (allMeanComparisonLetterA) {
					_.map(analysis, (treat) => {
						treat.letter = "-";
					});
				}
			});

			//-- Setup cell shading
			let returnList = _.map(treatmentsByType, (treatment) => {
				const treatmentsToShade = _.filter(treatment, (t) => {
					return !t.hasNoPlotData;
				});
				let innerList = tableShader.calculateShadingForTreatment(
					treatmentsToShade,
					"trialResultAverageValue",
					"99, 184, 115"
				);

				return innerList;
			});
			return returnList;
		});

		return r;
	}

	//-- groups selected assessments by treatment
	function groupTreatments(res, appendData) {
		let allTreatmentDataT = [];
		if (appendData === true) {
			allTreatmentDataT = allTreatmentData.concat(_.orderBy(res, ["trialTreatmentId"], ["asc"]));
		} else {
			allTreatmentDataT = _.orderBy(res, ["trialTreatmentId"], ["asc"]);
		}
		let groupedTreatments = _.groupBy(_.orderBy(allTreatmentDataT, ["trialTreatmentId"], ["asc"]), "trialTreatmentId");

		let biggestTreatmentLength = Math.max(..._.map(groupedTreatments, (el) => el.length));
		let biggestTreatment = _.find(groupedTreatments, (gt) => gt.length === biggestTreatmentLength);
		let incompleteTreatments = _.filter(groupedTreatments, (gt) => gt.length !== biggestTreatmentLength);

		_.map(incompleteTreatments, (it) => {
			_.map(biggestTreatment, (b, i) => {
				let assessmentExists =
					_.findIndex(it, (ia) => {
						if (ia.curveModelAnalysisId) {
							return ia.curveModelAnalysisId === b.curveModelAnalysisId && ia.analysisId === b.analysisId;
						} else if (ia.groundDataAssessmentId) {
							return ia.groundDataAssessmentId === b.groundDataAssessmentId && ia.dateCollected === b.dateCollected;
						} else if (ia.analysisId) {
							return ia.analysisId === b.analysisId && ia.flightDate === b.flightDate;
						}
					}) !== -1;

				if (!assessmentExists) {
					it.splice(i, 0, { ...b, hasNoPlotData: true });
				}
			});
		});

		groupedTreatments = _.map(groupedTreatments, function (gt) {
			return gt.curveModelName
				? _.orderBy(gt, ["curveModelName"], ["asc"])
				: gt.name
				? _.orderBy(gt, ["analysisName"], ["asc"])
				: _.orderBy(gt, ["groundDataAssessmentName"], ["asc"]);
		});
		setAllTreatmentData(allTreatmentDataT);

		return groupedTreatments;
	}

	function setupFooterData(groupedTreatments) {
		let footerDataToParse = groupedTreatments[0];
		let footerDataToAdd = [
			_.map(footerDataToParse, (treatment) => {
				return { ...treatment, footerName: "Global Mean" };
			}),
			_.map(footerDataToParse, (treatment) => {
				return { ...treatment, footerName: "CV" };
			}),
			_.map(footerDataToParse, (treatment) => {
				return { ...treatment, footerName: "P-Value" };
			})
		];
		setFooterData(footerDataToAdd);
	}

	function updateAnalytics() {
		if (selectedPresetOption === "none") {
			getAllTrialData(summaryTableSelections, false);
		} else {
			stageAssessmentOptions(summaryTablePresetOptions, false);
		}

		setNeedToUpdateAnalytics(false);
	}

	return optionsLoading === true ? (
		<Segment style={{ width: "100%" }}>
			<Loader active inline="centered" />
		</Segment>
	) : !optionsLoading && !assessmentsLoading && _.isEmpty(assessmentOptions) ? (
		<Segment style={{ width: "100%" }}>
			<p>{"We could not find data for this trial."}</p>
		</Segment>
	) : (
		<Segment style={{ width: "100%", overflowX: "scroll" }}>
			<SummaryTableSelections
				selectedAlphaOption={selectedAlphaOption}
				setSelectedAlphaOption={setSelectedAlphaOption}
				selectedMeanComparisonOption={selectedMeanComparisonOption}
				setSelectedMeanComparisonOption={setSelectedMeanComparisonOption}
				summaryTableSelections={summaryTableSelections}
				setSummaryTableSelections={setSummaryTableSelections}
				getSingleAssessmentTrialData={getSingleAssessmentTrialData}
				fetchingData={assessmentsLoading}
				rawTrialOptions={rawTrialOptions}
				assessmentData={assessmentData}
				meanComparisonOptions={meanComparisonOptions}
				alphaOptions={alphaOptions}
				assessmentOptions={assessmentOptions}
				summaryTablePresetOptions={summaryTablePresetOptions}
				selectedPresetOption={selectedPresetOption}
				setSelectedPresetOption={setSelectedPresetOption}
				quantifiedRegionTypeOptions={quantifiedRegionTypeOptions}
				rawPresetOptions={rawTrialOptions.presetOptions}
				updateAnalytics={updateAnalytics}
				setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
				needToUpdateAnalytics={needToUpdateAnalytics}
				removeDamagedOrExcluded={removeDamagedOrExcluded}
				setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
				removeOutliers={removeOutliers}
				setRemoveOutliers={setRemoveOutliers}
				outlierType={outlierType}
				setOutlierType={setOutlierType}
			/>
			{assessmentsLoading ? (
				<Loader active inline="centered" />
			) : (
				<SummaryTableResults
					trialDataResults={trialDataResults}
					summaryTableSelections={summaryTableSelections}
					DATE_FORMAT={DATE_FORMAT}
					footerData={footerData}
					selectedPresetOption={_.find(rawTrialOptions.summaryTablePresets, (stp) => {
						return stp.id === selectedPresetOption;
					})}
					needToUpdateAnalytics={needToUpdateAnalytics}
				/>
			)}
		</Segment>
	);
};

export default SummaryTable;
