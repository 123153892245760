import * as types from "./actionTypes";
import * as groundDataApi from "../../apis/groundDataApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getGroundDataInfoSuccess() {
	return { type: types.GROUND_DATA_GET_GROUND_DATA_INFO_SUCCESS };
}

export function createGroundDataSuccess() {
	return { type: types.GROUND_DATA_CREATE_GROUND_DATA_SUCCESS };
}

export function inspectGroundDataSuccess() {
	return { type: types.GROUND_DATA_INSPECT_GROUND_DATA_SUCCESS };
}

export function deleteDatasetSuccess() {
	return { type: types.GROUND_DATA_DELETE_DATASET_SUCCESS };
}

export function eraseDatasetSuccess() {
	return { type: types.GROUND_DATA_ERASE_DATASET_SUCCESS };
}

export function getGroundDatasetsSuccess() {
	return { type: types.GROUND_DATA_GET_GROUND_DATASETS_SUCCESS };
}

export function editGroundDataSuccess() {
	return { type: types.GROUND_DATA_EDIT_GROUND_DATA_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getGroundDataInfo(fieldId, trialId, clientId, accessToken, cropId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.getGroundDataInfo(fieldId, trialId, cropId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						console.log(res);
						reject(res.data);
					}
					dispatch(getGroundDataInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createGroundData(groundDataCsv, newGroundDataVm, outlineData, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.createGroundData(groundDataCsv, newGroundDataVm, outlineData, trialId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						console.log(res);
						reject(res.data);
					}
					dispatch(createGroundDataSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function inspectGroundData(groundDataCsv, newGroundDataVm, outlineData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.inspectGroundData(groundDataCsv, newGroundDataVm, outlineData, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						console.log(res);
						reject(res.data);
					}
					dispatch(inspectGroundDataSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteDataset(clientId, datasetId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.deleteDataset(clientId, datasetId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200 && res.statusCode !== 204) {
						console.log(res);
						reject(res.data);
					}
					dispatch(deleteDatasetSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function eraseDataset(clientId, datasetId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.eraseDataset(clientId, datasetId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200 && res.statusCode !== 204) {
						console.log(res);
						reject(res.data);
					}
					dispatch(eraseDatasetSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getGroundDatasets(clientId, accessToken, fieldId, trialId, protocolId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.getGroundDatasets(clientId, accessToken, fieldId, trialId, protocolId)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res);
					}
					dispatch(getGroundDatasetsSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function editGroundData(groundDataCsv, editGroundDataSetsVm, outlineData, fieldId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return groundDataApi
				.editGroundData(groundDataCsv, editGroundDataSetsVm, outlineData, fieldId, trialId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res);
					}
					dispatch(editGroundDataSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
