import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function entityNamesReducer(state = initialState.entityNames, action) {
	switch (action.type) {
		case types.ENTITIES_GET_ENTITY_NAMES_SUCCESS:
			return action.entityNames;

		default:
			return state;
	}
}
