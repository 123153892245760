import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Segment, Loader, Image, Grid, Button, Input } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { toast } from "react-toastify";

import * as clientLogoActions from "../../../../redux/actions/clientLogoActions";

const LogoManagement = () => {
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();
	const fileInputRef = useRef();

	const defaultLogo = "https://react.semantic-ui.com/images/wireframe/square-image.png";

	//-- UI Control
	const [loading, setLoading] = useState(true);

	//-- Data Management
	const [imageUrl, setImageUrl] = useState(defaultLogo);
	const [selectedFile, setSelectedFile] = useState(null);

	useEffect(() => {
		getClientLogo();
	}, []);

	const getClientLogo = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();

		dispatch(clientLogoActions.getClientLogoUrl(userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res) {
					setImageUrl(res);
				}

				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	};

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const uploadLogo = async () => {
		if (selectedFile.size < 1024000) {
			setLoading(true);
			const accessToken = await getTokenSilently();

			dispatch(clientLogoActions.uploadClientLogo(selectedFile, userAuth.currentClientId, accessToken))
				.then(() => {
					toast.success("Logo uploaded sucessfully.");
					getClientLogo();
				})
				.catch((err) => {
					console.error(err);
					toast.error("An error occurred when uploading client logo.");
					setLoading(false);
				});
		} else {
			toast.error("Logo size must be under 1MB");
		}
	};

	const deleteLogo = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();

		dispatch(clientLogoActions.deleteClientLogo(userAuth.currentClientId, accessToken))
			.then(() => {
				toast.success("Logo deleted successfully.");
				setImageUrl(defaultLogo);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toast.error("An error occurred when deleting client logo.");
				setLoading(false);
			});
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15, paddingBottom: "unset" }}>
			<h2 style={{ float: "left" }}>Manage Logo</h2>
			<hr style={{ clear: "both" }} />
			{loading ? (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			) : (
				<Grid>
					<Grid.Row>
						<Grid.Column width="5">
							<Image src={imageUrl} size="medium" rounded />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="1">
							<Button
								content="Browse..."
								labelPosition="left"
								icon="file"
								onClick={() => fileInputRef.current.click()}
								style={{ height: "100%" }}
							/>
							<input ref={fileInputRef} type="file" accept=".jpg,.png,.svg" hidden onChange={fileSelectedHandler} />
						</Grid.Column>
						<Grid.Column width="2">
							<Input fluid type="text" readOnly value={selectedFile ? selectedFile.name : ""} />
						</Grid.Column>
						<Grid.Column width="1" style={{ padding: 0 }}>
							<Button primary content="Upload" onClick={() => uploadLogo()} floated="right" disabled={!selectedFile} />
						</Grid.Column>
						<Grid.Column width="1" style={{ padding: 0 }}>
							<Button negative content="Delete" onClick={() => deleteLogo()} disabled={imageUrl === defaultLogo} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Segment>
	);
};

export default LogoManagement;
