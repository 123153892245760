import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Segment, Grid, Loader } from "semantic-ui-react";
import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import BulkFlightTrialsTable from "./BulkFlightTrialsTable";

import * as trialActions from "../../../../redux/actions/trialActions";

const BulkPublishing = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	//--Bulk Publishing Data
	const [bulkTrialFlightData, setBulkTrialFlightData] = useState([]);
	const [growingSeasonData, setGrowingSeasonData] = useState([]);
	const [companyData, setCompanyData] = useState([]);
	const [farmData, setFarmData] = useState([]);
	const [trialOwnerData, setTrialOwnerData] = useState([]);
	const [cropData, setCropData] = useState([]);
	const [flightScheduleData, setFlightScheduleData] = useState([]);
	const [flightScheduleDataOptions, setFligtScheduleDataOptions] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(true);

	//-- Initial view load
	useEffect(() => {
		if (userAuth.currentClientId) {
			getBulkTrialPublishingData(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	async function getBulkTrialPublishingData(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getBulkTrialPublishingData(clientId, accessToken))
			.then((res) => {
				if (res) {
					setLoadingData(false);

					setBulkTrialFlightData(res.groupedBulkTrialFlights);
					let growingSeasonOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.growingSeasonId, trial.growingSeasonName].join();
					});
					setGrowingSeasonData(growingSeasonOptions);

					let companyOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.companyName].join();
					});
					setCompanyData(companyOptions);

					let farmOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.farmName].join();
					});
					setFarmData(farmOptions);

					let trialOwnerOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.trialOwner].join();
					});
					setTrialOwnerData(trialOwnerOptions);

					let cropOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.cropName].join();
					});
					setCropData(cropOptions);

					let flightScheduleOptions = _.uniqBy(res.groupedBulkTrialFlights, (trial) => {
						return [trial.flightScheduleName].join();
					});
					setFlightScheduleData(flightScheduleOptions);

					setFligtScheduleDataOptions(res.flightScheduleOptionsVm);

					setLoading(false);

				}
			})
			.catch((err) => {
				console.log("Error loading trial data");
				console.log(err);
				setLoading(false);
			});
	}

	return loading ? (
		<Loader active />
	) : !loading ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Bulk Publication Dashboard</h2>
			<hr />
			<Segment>
				<Grid.Column>
					<BulkFlightTrialsTable
						trials={bulkTrialFlightData}
						growingSeasonData={growingSeasonData}
						companyData={companyData}
						farmData={farmData}
						trialOwnerData={trialOwnerData}
						cropData={cropData}
						flightScheduleData={flightScheduleData}
						flightScheduleDataOptions={flightScheduleDataOptions}
						getBulkTrialPublishingData={getBulkTrialPublishingData}
						loadingData={loadingData}
					/>
				</Grid.Column>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Bulk Publication Dashboard</h2>

		</Segment>
	);
};

export default BulkPublishing;
