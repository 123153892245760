const aerialImageUrl = process.env.aerialPlotConnections.aerialImageApi.baseUrl + "api/v1/";

export function getFlightImage(clientId, flightId, orthoImageTypeId, fieldId, trialId, plotId, accessToken) {
	return fetch(
		`${aerialImageUrl}FlightImages?clientId=${clientId}&flightId=${flightId}&orthoImageTypeId=${orthoImageTypeId}&fieldId=${fieldId}&trialId=${trialId}&plotId=${plotId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			if (res.status === 204) {
				return {
					id: null,
					llLat: null,
					llLong: null,
					ulLat: null,
					ulLong: null,
					lrLat: null,
					lrLong: null,
					urLat: null,
					urLong: null
				};
			} else {
				return res.json();
			}
		})
		.then((data) => data);
}

export function isUploaded(flightId, clientId, accessToken) {
	return fetch(aerialImageUrl + `FlightImages/IsUploaded?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function modifyUas(modifyUas, accessToken) {
	return fetch(aerialImageUrl + "FlightImages/ModifyUas", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(modifyUas)
	}).then((res) => res);
}

export function checkCutImages(flightId, clientId, accessToken) {
	return fetch(`${aerialImageUrl}FlightImages/CheckCutImages?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}
