// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.fullscreen.modal {\n\tleft: 2.5% !important;\n}\n\n.ui.fullscreen.scrolling.modal {\n\tleft: 2.5% !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Trials/TrialCurveModels/styles.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;AACtB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".ui.fullscreen.modal {\n\tleft: 2.5% !important;\n}\n\n.ui.fullscreen.scrolling.modal {\n\tleft: 2.5% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"fullscreen": "fullscreen",
	"modal": "modal",
	"scrolling": "scrolling"
};
export default ___CSS_LOADER_EXPORT___;
