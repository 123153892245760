import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "../auth/auth0";

import * as clientLogoActions from "../redux/actions/clientLogoActions";
import * as trialActions from "../redux/actions/trialActions";
import * as protocolActions from "../redux/actions/protocolActions";

export const useUserSettings = () => {
	//-- Instantiate hooks
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	//-- User Info
	const currentClientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Client Info
	const [currentClientLogoUri, setCurrentClientLogoUri] = useState(null);

	//-- Favorites Info
	const [trialFavoriteGuids, setTrialFavoriteGuids] = useState(null);
	const [protocolFavoriteGuids, setProtocolFavoriteGuids] = useState(null);

	useEffect(() => {
		getClientLogoUri();
		getProtocolFavoriteGuids(()=>{});
		getTrialFavoriteGuids(()=>{});
	}, [currentClientId]);

	const getClientLogoUri = async () => {
		if (currentClientId) {
			const accessToken = await getTokenSilently();

			dispatch(clientLogoActions.getClientLogoUrl(currentClientId, accessToken)).then((res) => {
				setCurrentClientLogoUri(res);
			});
		}
	};

	const getTrialFavoriteGuids = async (callback) => {
		if (currentClientId) {
			const accessToken = await getTokenSilently();

			dispatch(trialActions.getTrialFavoriteGuids(currentClientId, accessToken))
				.then((res) => {
					setTrialFavoriteGuids(res.data);
					callback();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const trialIsFavorited = (targetTrial) => {
		var trialIsFavorited = trialFavoriteGuids.includes(targetTrial);
		return trialIsFavorited;
	};

	const getProtocolFavoriteGuids = async (callback) => {
		if (currentClientId) {
			const accessToken = await getTokenSilently();

			dispatch(protocolActions.getProtocolFavoriteGuids(currentClientId, accessToken))
				.then((res) => {
					setProtocolFavoriteGuids(res.data);
					callback();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const protocolIsFavorited = (targetProtocol) => {
		var protocolIsFavorited = protocolFavoriteGuids.includes(targetProtocol);
		return protocolIsFavorited;
	}

	const userSettings = {
		currentClientId,
		currentClientLogoUri,
		getClientLogoUri,
		getTrialFavoriteGuids,
		trialIsFavorited,
		trialFavoriteGuids,
		getProtocolFavoriteGuids,
		protocolIsFavorited,
		protocolFavoriteGuids
	};

	return userSettings;
};
