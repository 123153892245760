import React from "react";
import { Segment } from "semantic-ui-react";

import Breadcrumbs from "./Breadcrumbs";

const HeaderSecondary = () => {
  return (
    <nav>
      <Segment style={{ padding: 10, border: "unset", borderRadius: "unset", backgroundColor: "#CCCCCC" }}>
        <Breadcrumbs />
      </Segment>
    </nav>
  );
};

export default HeaderSecondary;
