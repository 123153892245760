// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trial-favoriting-button.ui.button {\n    padding: 0px\n}\n\n.protocol-favoriting-button.ui.button {\n    padding: 0px\n}", "",{"version":3,"sources":["webpack://./src/components/Content/Widgets/Favorites/style.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".trial-favoriting-button.ui.button {\n    padding: 0px\n}\n\n.protocol-favoriting-button.ui.button {\n    padding: 0px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trial-favoriting-button": "trial-favoriting-button",
	"ui": "ui",
	"button": "button",
	"protocol-favoriting-button": "protocol-favoriting-button"
};
export default ___CSS_LOADER_EXPORT___;
