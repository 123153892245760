import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

export function userCanEditTrial(userAuth) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	return false;
}

export function userCanEditTrialName(userAuth) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	return false;
}

export function userCanEditCompany(userAuth, trialClientId) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	//-- User clientId isn't the client that created the trial
	if (userAuth.currentClientId !== trialClientId) {
		return false;
	}

	return false;
}

export function userCanEditTrialOwner(userAuth) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	return false;
}

export function userCanEditPlantDate(userAuth, trialClientId) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	//-- User clientId is the client that created the trial
	if (userAuth.currentClientId !== trialClientId) {
		return false;
	}

	return false;
}

export function userCanEditHarvestDate(userAuth, trialClientId) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	//-- User clientId is the client that created the trial
	if (userAuth.currentClientId !== trialClientId) {
		return false;
	}

	return false;
}

export function userCanEditTreatments(userAuth) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	return false;
}

export function userCanEditNotes(userAuth) {
	//-- ApAdmin can always edit no matter which client they are viewing
	if (userAuth.isApAdmin || userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId)) {
		return true;
	}

	return false;
}
