import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import { Button, Segment, Table, Dropdown, Checkbox, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import { produce } from "immer";
import moment from "moment";
import _ from "lodash";

import ContentEditable from "react-contenteditable";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as protocolActions from "../../../../redux/actions/protocolActions";

import ProtocolFavoritingWidget from "../../Widgets/Favorites/ProtocolFavoritingWidget";

import "./styles.css";
import TreatmentCSVUpload from "../../../Lumber/TreatmentCSVUpload";

const Treatments = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();

	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	//-- Data
	const [protocolData, setProtocolData] = useState({});
	const [protocolTreatmentData, setProtocolTreatmentData] = useState([]);
	const [renderedProtocolTreatmentData, setRenderedProtocolTreatmentData] = useState([]);
	const [protocolTreatmentApplicationData, setProtocolTreatmentApplicationData] = useState([]);

	//-- Data Sources
	const [treatmentTypeOptions, setTreatmentTypeOptions] = useState([]);
	const [protocolTreatmentApplications, setProtocolTreatmentApplications] = useState([]);

	//-- UI Control
	const [initialLoading, setInitialLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [formErrors, setFormErrors] = useState([]);

	useEffect(() => {
		if (reduxProtocolData !== null) {
			setProtocolData(reduxProtocolData);
		}
	}, [reduxProtocolData]);

	useEffect(() => {
		if (treatmentTypeOptions.length === 0) {
			getProtocolTreatmentOptions();
		}
	}, []);

	useEffect(() => {
		if (protocolData?.id !== null && errors.length === 0) {
			populateErrorList();
		}
	}, [protocolData]);

	useEffect(() => {
		if (formErrors.length !== 0) {
			setErrors(formErrors);
		}
	}, [formErrors]);

	async function getProtocolTreatmentOptions() {
		setInitialLoading(true);
		const accessToken = await getTokenSilently();

		dispatch(
			protocolActions.getProtocolTreatmentOptions(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				let ttOpts = _.map(res.data.treatmentTypes, (treatmentType) => {
					const opt = {
						key: treatmentType.id,
						value: treatmentType.id,
						text: treatmentType.name
					};
					return opt;
				});
				setTreatmentTypeOptions(ttOpts);

				let fixedProtocolTreatmentApplications = res.data.protocolTreatmentApplications;
				_.map(fixedProtocolTreatmentApplications, (fpta) => {
					if (fpta.groundDataTimingMethodType === "Date") {
						fpta.timingDisplay = moment(new Date(fpta.growthPhaseDateUtc)).local().format("MM/DD/YYYY");
					} else if (fpta.groundDataTimingMethodType === "Text") {
						fpta.timingDisplay = fpta.growthPhaseText;
					} else if (fpta.groundDataTimingMethodType === "StartEnd") {
						fpta.timingDisplay = `${fpta.growthStageStart} - ${fpta.growthStageEnd}`;
					} else if (fpta.groundDataTimingMethodType === "int") {
						fpta.timingDisplay = `${fpta.growthPhaseInteger} ${fpta.groundDataTimingName}`;
					} else {
						fpta.timingDisplay = fpta.growthStageStart;
					}
				});

				setProtocolTreatmentApplications(fixedProtocolTreatmentApplications);

				getProtocolTreatmentData(ttOpts, fixedProtocolTreatmentApplications);

				setInitialLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setInitialLoading(false);
			});
	}

	async function getProtocolTreatmentData(treatmentTypes, applicationData) {
		setInitialLoading(true);
		const accessToken = await getTokenSilently();

		dispatch(
			protocolActions.getProtocolTreatmentData(moduleNavigation.protocolId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				let protocolTreatments = _.cloneDeep(res.data.protocolTreatments);

				if (res.data?.protocolTreatments?.length > 0) {
					if (res.data.protocolTreatments.length !== reduxProtocolData?.treatments) {
						let protocolTreatmentsToAdd = reduxProtocolData.treatments - res.data.protocolTreatments.length;

						for (let i = 0; i < protocolTreatmentsToAdd; i++) {
							let protocolTreatmentToAdd = setupSingleEmptyProtocolTreatment(treatmentTypes);
							protocolTreatmentToAdd.trialTreatmentId = res.data.protocolTreatments.length + i + 1;

							protocolTreatments.push(protocolTreatmentToAdd);
						}
					}

					setProtocolTreatmentData(protocolTreatments);
					setRenderedProtocolTreatmentData(protocolTreatments);
				} else {
					setupEmptyProtocolTreatments(treatmentTypes);
				}

				let protocolApplications = _.cloneDeep(res.data.protocolTreatmentApplications);

				if (res.data?.protocolTreatmentApplications?.length > 0) {
					if (
						res.data.protocolTreatmentApplications.length !==
						applicationData.length * reduxProtocolData?.treatments
					) {
						let existingApplications = _.uniq(_.map(res.data.protocolTreatmentApplications, "applicationId"));
						let protocolApplicationsToAdd = _.filter(applicationData, (ad) => !existingApplications.includes(ad.id));

						_.map(protocolTreatments, (pt) => {
							_.map(protocolApplicationsToAdd, ({ id }) => {
								let protocolApplicationToAdd = {
									trialTreatmentId: pt.trialTreatmentId,
									applicationId: id,
									checked: false,
									protocolTreatmentId: pt.id
								};

								protocolApplications.push(protocolApplicationToAdd);
							});
						});
					}

					setProtocolTreatmentApplicationData(protocolApplications);
				} else {
					setupEmptyProtocolTreatmentApplications(applicationData);
				}

				setInitialLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setInitialLoading(false);
			});
	}

	function setupEmptyProtocolTreatments(treatmentTypes) {
		if (reduxProtocolData?.treatments !== null) {
			let protocolTreatments = [];

			for (let i = 0; i < reduxProtocolData.treatments; i++) {
				let protocolTreatmentToAdd = setupSingleEmptyProtocolTreatment(treatmentTypes);
				protocolTreatmentToAdd.trialTreatmentId = i + 1;

				protocolTreatments.push(protocolTreatmentToAdd);
			}

			setProtocolTreatmentData(protocolTreatments);
			setRenderedProtocolTreatmentData(protocolTreatments);
		} else {
			toast.error("Could not find any treatments for this protocol");
		}
	}

	//-- Useful if user changes number of protocols on info page
	function setupSingleEmptyProtocolTreatment(treatmentTypes) {
		return {
			id: null,
			trialTreatmentId: null,
			name: null,
			confidentialName: null,
			description: null,
			seed: null,
			treatmentTypeId: _.find(treatmentTypes, { text: "Test" })?.key,
			protocolId: moduleNavigation.protocolId
		};
	}

	function setupEmptyProtocolTreatmentApplications(applicationData) {
		if (reduxProtocolData?.treatments !== null && applicationData.length !== 0) {
			let protocolTreatmentApplicationsToAdd = [];

			for (let i = 0; i < reduxProtocolData.treatments; i++) {
				_.map(applicationData, (pta) => {
					let protocolTreatmentApplicationToAdd = {
						trialTreatmentId: i + 1,
						applicationId: pta.id,
						checked: false,
						protocolTreatmentId: null
					};

					protocolTreatmentApplicationsToAdd.push(protocolTreatmentApplicationToAdd);
				});
			}

			setProtocolTreatmentApplicationData(protocolTreatmentApplicationsToAdd);
		}
	}

	function populateErrorList() {
		let errorList = [];

		for (var i = 1; i < reduxProtocolData?.treatments + 1; i++) {
			errorList.push({
				trialTreatmentId: i,
				name: false,
				confidentialName: false,
				description: false,
				seed: false
			});
		}

		setErrors(errorList);
		setFormErrors(errorList);
	}

	async function handleSave(continueProcess) {
		let valid = validate();

		if (valid) {
			setLoading(true);
			const accessToken = await getTokenSilently();
			console.log(continueProcess);

			let data = {
				protocolTreatments: protocolTreatmentData,
				protocolTreatmentApplications: protocolTreatmentApplicationData
			};

			if (_.some(protocolTreatmentData, (ptd) => ptd.id !== null)) {
				dispatch(protocolActions.updateProtocolTreatmentData(data, userAuth.currentClientId, accessToken))
					.then((res) => {
						toast.success("Data saved successfully");
						if (continueProcess) {
							history.push(moduleNavigation.createProtocolLink(true, null, "assessments"));
						} else {
							setProtocolTreatmentData(res.data.protocolTreatments);
							setProtocolTreatmentApplicationData(res.data.protocolTreatmentApplications);
						}
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
						toast.error("There was a problem saving. Please try again later");
						setLoading(false);
					});
			} else {
				dispatch(protocolActions.createProtocolTreatmentData(data, userAuth.currentClientId, accessToken))
					.then((res) => {
						toast.success("Data saved successfully");
						if (continueProcess) {
							history.push(moduleNavigation.createProtocolLink(true, null, "assessments"));
						} else {
							setProtocolTreatmentData(res.data.protocolTreatments);
							setProtocolTreatmentApplicationData(res.data.protocolTreatmentApplications);
						}
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
						toast.error("There was a problem saving. Please try again later");
						setLoading(false);
					});
			}
		}
	}

	function validate() {
		let valid = true;
		let errorList = formErrors;
		let nameEmpty = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, name }) => {
			if (name === null || name === "") {
				valid = false;
				nameEmpty = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].name = true;
				}
			}
		});

		if (nameEmpty) toast.error("Treatment name cannot be empty.");

		let nameTooLong = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, name }) => {
			if (name?.length > 50 ?? false) {
				valid = false;
				nameTooLong = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].name = true;
				}
			}
		});

		if (nameTooLong) toast.error("Treatment name cannot be more than 50 characters.");

		let nameHasIllegalChar = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, name }) => {
			if (name.match(/,/g)) {
				valid = false;
				nameHasIllegalChar = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].name = true;
				}
			}
		});

		if (nameHasIllegalChar) toast.error('Treatment name has an illegal character in it: ",".');

		let confidentialNameTooLong = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, confidentialName }) => {
			if (confidentialName?.length > 50) {
				valid = false;
				confidentialNameTooLong = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].confidentialName = true;
				}
			}
		});

		if (confidentialNameTooLong) toast.error("Confidential Name cannot be more than 50 characters.");

		let descriptionTooLong = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, description }) => {
			if (description?.length > 50) {
				valid = false;
				descriptionTooLong = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].description = true;
				}
			}
		});

		if (descriptionTooLong) toast.error("Description cannot be more than 50 characters.");

		let seedTooLong = false;

		_.map(protocolTreatmentData, ({ trialTreatmentId, seed }) => {
			if (seed?.length > 50) {
				valid = false;
				seedTooLong = true;
				let matchingIndex = _.findIndex(errorList, { trialTreatmentId: trialTreatmentId });
				if (matchingIndex !== -1) {
					errorList[matchingIndex].seed = true;
				}
			}
		});

		if (seedTooLong) toast.error("Seed cannot be more than 50 characters.");

		let treatmentTypeUniqueness = [];
		_.map(treatmentTypeOptions, ({ key, text }) => {
			treatmentTypeUniqueness.push({
				id: key,
				name: text,
				treatments: _.filter(protocolTreatmentData, ({ treatmentTypeId }) => treatmentTypeId === key)
			});
		});

		let controlTypeCount = _.find(treatmentTypeUniqueness, { name: "Control" })?.treatments.length ?? 0;
		let commercialTypeCount = _.find(treatmentTypeUniqueness, { name: "Commercial" })?.treatments.length ?? 0;
		let gspTypeCount = _.find(treatmentTypeUniqueness, { name: "GSP" })?.treatments.length ?? 0;

		if (controlTypeCount > 1) {
			toast.error("Can only have 1 Control treatment type");
			valid = false;
		}

		if (commercialTypeCount > 1) {
			toast.error("Can only have 1 Commercial treatment type");
			valid = false;
		}

		if (gspTypeCount > 1) {
			toast.error("Can only have 1 GSP treatment type");
			valid = false;
		}

		if (controlTypeCount === 0 && commercialTypeCount === 0 && gspTypeCount === 0) {
			toast.error("Must have at least 1 Control, Commercial, or GSP treatment.");
			valid = false;
		}

		setFormErrors(errorList);
		return valid;
	}

	function setTreatmentsFromValidCSV(results) {
		setProtocolTreatmentData((prev) =>
			prev.map((t, idx) => ({
				...t,
				name: results[idx].name,
				confidentialName: results[idx].confidentialName,
				description: results[idx].description,
				seed: results[idx].seed,
				treatmentTypeId: treatmentTypeOptions.find((tto) => tto.text.toUpperCase() === results[idx].type.toUpperCase())
					.value
			}))
		);

		setRenderedProtocolTreatmentData((prev) =>
			prev.map((t, idx) => ({
				...t,
				name: results[idx].name,
				confidentialName: results[idx].confidentialName,
				description: results[idx].description,
				seed: results[idx].seed,
				treatmentTypeId: treatmentTypeOptions.find((tto) => tto.text.toUpperCase() === results[idx].type.toUpperCase())
					.value
			}))
		);
	}

	function clearTreatments() {
		setProtocolTreatmentData((prev) =>
			prev.map((t) => ({
				...t,
				name: null,
				confidentialName: null,
				description: null,
				seed: null,
				treatmentTypeId: null
			}))
		);

		setRenderedProtocolTreatmentData((prev) =>
			prev.map((t) => ({
				...t,
				name: null,
				confidentialName: null,
				description: null,
				seed: null,
				treatmentTypeId: null
			}))
		);
	}

	return initialLoading || protocolTreatmentApplicationData.length === 0 ? (
		<Loader active inline="centered" />
	) : (
		<>
			<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
				<Button
					id="form-button-save"
					floated="right"
					primary
					content={"Save"}
					onClick={() => {
						handleSave(false);
					}}
					disabled={loading}
					loading={loading}
				/>
				<Button
					id="form-button-continue"
					floated="right"
					color="green"
					content={"Save and Continue"}
					onClick={() => {
						handleSave(true);
					}}
					disabled={loading}
					loading={loading}
				/>
				<Button
					id="form-button-back"
					floated="left"
					secondary
					content={"Back"}
					onClick={() => {
						history.push(moduleNavigation.createProtocolLink(true, null, "applications"));
					}}
					disabled={loading}
					loading={loading}
				/>
			</Segment>
			<Segment basic id="protocol-info-form-segment" style={{ marginBottom: 30 }}>
				<h2 style={{ float: "left" }}>Treatments</h2>
				<div style={{ float: "right" }}>
					<ProtocolFavoritingWidget
						style={{ display: "inline" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						protocolId={moduleNavigation.protocolId.toUpperCase()}
						userSettings={userSettings}
					></ProtocolFavoritingWidget>
					<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{reduxProtocolData?.protocolName ?? ""}</i>
					</h2>
				</div>
				<hr style={{ clear: "both" }} />
				<TreatmentCSVUpload
					hasImportedData={true}
					onAcceptCSV={setTreatmentsFromValidCSV}
					onClearTreatments={clearTreatments}
					context="protocol"
					trialData={{
						id: protocolData.id,
						name: protocolData.protocolName,
						treatmentCount: renderedProtocolTreatmentData.length,
						clientId: userAuth.currentClientId,
						context: "protocol"
					}}
					currentData={renderedProtocolTreatmentData.map((t) => ({
						Treatment: t.trialTreatmentId,
						Type: treatmentTypeOptions?.find((tto) => tto.value === t.treatmentTypeId)?.text,
						Name: t.name,
						"Confidential Name": t.confidentialName,
						Description: t.description,
						Seed: t.seed
					}))}
				/>
				<Table celled striped fixed>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan="6"></Table.HeaderCell>
							{/* Loop over applications */}
							{_.map(protocolTreatmentApplications, (pta) => {
								return (
									<Table.HeaderCell width={1} textAlign="center" verticalAlign="bottom" key={pta.code}>
										{pta.applicationTypeName}
										<br />
										{pta.applicationMethodName}
										<br />
										{pta.timingDisplay}
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
						<Table.Row>
							<Table.HeaderCell textAlign="center" width={1}>
								#
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" width={3}>
								Name
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" width={3}>
								Confidential Name
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" width={3}>
								Description
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">Seed</Table.HeaderCell>
							{/* Loop over applications */}
							{_.map(protocolTreatmentApplications, (pta) => {
								return (
									<Table.HeaderCell textAlign="center" key={pta.code}>
										App {pta.code}
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(renderedProtocolTreatmentData, (ptd, index) => {
							return (
								<Table.Row textAlign="center" key={index}>
									<Table.Cell>{ptd.trialTreatmentId}</Table.Cell>
									<Table.Cell style={{ overflow: "visible" }}>
										<Dropdown
											search
											disabled={loading}
											options={treatmentTypeOptions}
											value={ptd.treatmentTypeId}
											// eslint-disable-next-line no-unused-vars
											onChange={(event, { value }) => {
												let protocolTreatmentDataClone = _.cloneDeep(protocolTreatmentData);
												protocolTreatmentDataClone[index].treatmentTypeId = value;
												setProtocolTreatmentData(protocolTreatmentDataClone);

												const renderedProtocolTreatmentDataClone = produce(renderedProtocolTreatmentData, (data) => {
													data[index] = { ...renderedProtocolTreatmentData[index], treatmentTypeId: value };
												});
												setRenderedProtocolTreatmentData(renderedProtocolTreatmentDataClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell className="narrow">
										<ContentEditable
											className="content-editable"
											html={ptd.name ?? ""}
											disabled={loading}
											onPaste={(event) => {
												event.preventDefault();

												let text = event.clipboardData.getData("text/plain");
												text = text.replace(/\r+\n+/g, " ");
												text = text.trim();
												document.execCommand("insertHTML", false, text);
											}}
											onChange={(event) => {
												let renderedValue = event.target.value;
												let trueValue = event.currentTarget.textContent;
												trueValue = trueValue.replace(/&nbsp;/gi, " ");
												const protocolTreatmentDataClone = produce(protocolTreatmentData, (data) => {
													data[index] = { ...protocolTreatmentData[index], name: trueValue };
												});
												setProtocolTreatmentData(protocolTreatmentDataClone);

												const renderedProtocolTreatmentDataClone = produce(renderedProtocolTreatmentData, (data) => {
													data[index] = { ...renderedProtocolTreatmentData[index], name: renderedValue };
												});
												setRenderedProtocolTreatmentData(renderedProtocolTreatmentDataClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell className="narrow">
										<ContentEditable
											className="content-editable"
											html={ptd.confidentialName ?? ""}
											disabled={loading}
											onPaste={(event) => {
												event.preventDefault();

												const text = event.clipboardData.getData("text/plain");
												document.execCommand("insertText", false, text);
											}}
											onChange={(event) => {
												let value = event.target.value;
												let trueValue = event.currentTarget.textContent;
												trueValue = trueValue.replace(/&nbsp;/gi, " ");
												const protocolTreatmentDataClone = produce(protocolTreatmentData, (data) => {
													data[index] = { ...protocolTreatmentData[index], confidentialName: trueValue };
												});
												setProtocolTreatmentData(protocolTreatmentDataClone);

												const renderedProtocolTreatmentDataClone = produce(renderedProtocolTreatmentData, (data) => {
													data[index] = { ...renderedProtocolTreatmentData[index], confidentialName: value };
												});
												setRenderedProtocolTreatmentData(renderedProtocolTreatmentDataClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell className="narrow">
										<ContentEditable
											className="content-editable"
											html={ptd.description ?? ""}
											disabled={loading}
											onPaste={(event) => {
												event.preventDefault();

												const text = event.clipboardData.getData("text/plain");
												document.execCommand("insertText", false, text);
											}}
											onChange={(event) => {
												let value = event.target.value;
												value = value.replace(/&nbsp;/gi, " ");
												const protocolTreatmentDataClone = produce(protocolTreatmentData, (data) => {
													data[index] = { ...protocolTreatmentData[index], description: value };
												});
												setProtocolTreatmentData(protocolTreatmentDataClone);

												const renderedProtocolTreatmentDataClone = produce(renderedProtocolTreatmentData, (data) => {
													data[index] = { ...renderedProtocolTreatmentData[index], description: value };
												});
												setRenderedProtocolTreatmentData(renderedProtocolTreatmentDataClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell className="narrow">
										<ContentEditable
											className="content-editable"
											html={ptd.seed ?? ""}
											disabled={loading}
											onPaste={(event) => {
												event.preventDefault();

												const text = event.clipboardData.getData("text/plain");
												document.execCommand("insertText", false, text);
											}}
											onChange={(event) => {
												let value = event.target.value;
												value = value.replace(/&nbsp;/gi, " ");
												const protocolTreatmentDataClone = produce(protocolTreatmentData, (data) => {
													data[index] = { ...protocolTreatmentData[index], seed: value };
												});
												setProtocolTreatmentData(protocolTreatmentDataClone);

												const renderedProtocolTreatmentDataClone = produce(renderedProtocolTreatmentData, (data) => {
													data[index] = { ...renderedProtocolTreatmentData[index], seed: value };
												});
												setRenderedProtocolTreatmentData(renderedProtocolTreatmentDataClone);
											}}
											onBlur={(event) => {
												let value = event.target.textContent;
												if (value.length > 50) {
													toast.error("Seed must be below 50 characters.");
													let errorClone = formErrors;
													let matchingIndex = _.findIndex(errorClone, { trialTreatmentId: ptd.trialTreatmentId });
													if (matchingIndex !== -1) {
														errorClone[matchingIndex].seed = true;
													}
													setFormErrors(errorClone);
												} else {
													let errorClone = formErrors;
													let matchingIndex = _.findIndex(errorClone, { trialTreatmentId: ptd.trialTreatmentId });
													if (matchingIndex !== -1) {
														errorClone[matchingIndex].seed = false;
													}
													setFormErrors(errorClone);
												}
											}}
										/>
									</Table.Cell>
									{_.map(protocolTreatmentApplications, (pta, i) => {
										return (
											<Table.Cell key={i}>
												<Checkbox
													key={`${i}${pta.id}`}
													disabled={loading}
													//defaultChecked={_.find(protocolTreatmentApplicationData, { applicationId: pta.id, trialTreatmentId: ptd.trialTreatmentId })?.checked ?? false}
													onChange={(event, { checked }) => {
														let matchingIndex = _.findIndex(protocolTreatmentApplicationData, {
															applicationId: pta.id,
															trialTreatmentId: ptd.trialTreatmentId
														});
														let protocolTreatmentApplicationDataClone = _.cloneDeep(protocolTreatmentApplicationData);
														protocolTreatmentApplicationDataClone[matchingIndex].checked = checked;
														setProtocolTreatmentApplicationData(protocolTreatmentApplicationDataClone);
													}}
													checked={
														_.find(protocolTreatmentApplicationData, {
															applicationId: pta.id,
															trialTreatmentId: ptd.trialTreatmentId
														})?.checked ?? false
													}
												/>
											</Table.Cell>
										);
									})}
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Segment>
		</>
	);
};

export default Treatments;
