import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAuth0 } from "../auth0";
import { useModuleNavigation } from "../../hooks/useModuleNavigation";
import { useCookies } from "react-cookie";
import _ from "lodash";

import * as eulaActions from "../../redux/actions/eulaActions";

import { Segment, Form, Button } from "semantic-ui-react";

const Eula = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => (state.user ? state.user : null));

	const { isAuthenticated, getTokenSilently, logout } = useAuth0();

	const moduleNavigation = useModuleNavigation();

	const [cookies, setCookie, removeCookie] = useCookies(["eulaAgreement"]);

	//-- Data
	const [eulaText, setEulaText] = useState("");

	useEffect(() => {
		checkEulaStatus(user);
	}, [user]);

	async function getEula() {
		const accessToken = await getTokenSilently();
		dispatch(eulaActions.getEula(accessToken)).then((res) => {
			setEulaText(res.data);
		});
	}

	async function getEulaStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(eulaActions.getEulaStatus(accessToken));
		return res.data.accepted && !res.data.expired;
	}

	async function setEulaStatus(accepted) {
		const accessToken = await getTokenSilently();
		dispatch(eulaActions.setEulaStatus(accessToken, accepted));
	}

	async function checkEulaStatus(user) {
		if (user) {
			//-- If the user is logged in and manually goes to /eula, redirect them to the home page b/c they weren't trying to access any other part of the app
			const eulaAgreement = _.some(cookies.eulaAgreement, (ua) => {
				return ua.userId === user.userInfo.basic.userId;
			});
			if (isAuthenticated && user && user.userInfo && eulaAgreement) {
				moduleNavigation.goHome();
			} else if (isAuthenticated && user && user.userInfo && (await getEulaStatus())) {
				agree(false);
			} else {
				getEula();
			}
		} else {
			moduleNavigation.goToLogin();
		}
	}

	function agree(updateDatabase = true) {
		if (updateDatabase) {
			setEulaStatus(true);
		}

		let usersAcceptedEula = [];
		if (cookies.eulaAgreement) {
			usersAcceptedEula = cookies.eulaAgreement;
		}
		let agreement = { userId: user.userInfo.basic.userId };
		usersAcceptedEula.push(agreement);
		let today = new Date();
		const sixMonths = new Date(today.setMonth(today.getMonth() + 6));
		setCookie("eulaAgreement", usersAcceptedEula, { path: "/", expires: sixMonths });
		moduleNavigation.goHome();
	}

	function disagree() {
		setEulaStatus(false);
		moduleNavigation.removeCurrentPath();
		removeCookie("eulaAgreement");
		logout({ returnTo: process.env.aerialPlotConnections.aerialPlotWeb.returnTo });
	}

	return eulaText === "" ? null : (
		<Segment>
			<Form>
				<div
					style={{ maxHeight: 600, height: "100%", overflowY: "auto" }}
					dangerouslySetInnerHTML={{ __html: eulaText }}
				/>
				<Form.Field style={{ textAlign: "center", marginTop: "30px" }} inline>
					<Button negative content="Disagree" onClick={disagree} />
					<Button primary content="Accept" onClick={agree} />
				</Form.Field>
			</Form>
		</Segment>
	);
};

export default Eula;
