import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import { Loader, Segment } from "semantic-ui-react";

import AssessmentLog from "../../../Lumber/AssessmentLog";

const FieldAssessmentLog = () => {
	const moduleNavigation = useModuleNavigation();

	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	const [fieldName, setFieldName] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (entityNames.fieldId && moduleNavigation.fieldId.toUpperCase() === entityNames.fieldId.toUpperCase()) {
			setFieldName(entityNames.fieldName);
			setLoading(false);
		}
	}, [entityNames]);

	return loading ? (
		<Loader active />
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Assessment Log</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{fieldName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			<AssessmentLog isTrial={false} isField={true} />
		</Segment>
	);
};

export default FieldAssessmentLog;
