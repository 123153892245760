import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Checkbox, Divider, Form, Grid, Input, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as protocolActions from "../../../../redux/actions/protocolActions";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import ProtocolLogResults from "./ProtocolLogResults";

const ProtocolLog = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const allSelection = { key: "all", value: "all", text: "All" };

	//-- DataSource
	const [originalProtocols, setOriginalProtocols] = useState([]);
	const [protocols, setProtocols] = useState([]);

	//-- Dropdown Options
	const [protocolOwnerOptions, setProtocolOwnerOptions] = useState([allSelection]);
	const [cropOptions, setCropOptions] = useState([allSelection]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([allSelection]);
	const [geographyOptions, setGeographyOptions] = useState([allSelection]);
	const [plotSizeOptions, setPlotSizeOptions] = useState([allSelection]);
	const [regulatoryOptions, setRegulatoryOptions] = useState([]);
	const [originalRegulatoryOptions, setOriginalRegulatoryOptions] = useState([]);
	const [variablesTestedOptions, setVariablesTestedOptions] = useState([]);
	const [originalVariablesTestedOptions, setOriginalVariablesTestedOptions] = useState([]);
	const [searchAllClients, setSearchAllClients] = useState(false);
	const [hasSearchedAllClients, setHasSearchedAllClients] = useState(false);
	const [protocolNameOptions, setProtocolNameOptions] = useState([allSelection]);
	const [trialNameOptions, setTrialNameOptions] = useState([allSelection]);
	const [isInitialGSSet, setIsInitialGSSet] = useState(false);

	//-- Data
	const [protocolInfo, setProtocolInfo] = useState(setInitialProtocolInfo());
	const [protocolOptions, setProtocolOptions] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && userAuth.currentClientId) {
			getDropdownOptions();
		}
	}, [userAuth.isReady, userAuth.currentClientId]);

	useEffect(() => {
		if (
			_.some(protocolOptions)
		) {
			getProtocols();
		}
	}, [protocolOptions]);

	useEffect(() => {
		if (originalProtocols && isInitialGSSet) {
			filterProtocols();
		}
	}, [originalProtocols, isInitialGSSet]);

	function setInitialProtocolInfo(growingSeason = null) {
		return {
			id: "all",
			protocolId: "all", //-- user defined id
			protocolName: "all",
			protocolOwnerId: "all",
			cropId: "all",
			growingSeasonId: growingSeason ?? 'all',
			geographyId: "all",
			treatments: "all",
			replicates: "all",
			plotSizeId: "all",
			designId: "all",
			meanComparisonId: "all",
			alphaId: "all",
			yieldRequired: true,
			yieldNotRequired: true,
			regulatories: _.cloneDeep(originalRegulatoryOptions),
			variablesTested: _.cloneDeep(originalVariablesTestedOptions),
			rotation: "all",
			genetics: "all",
			herbicides: "all",
			fertilizer: "all",
			pesticides: "all",
			other: "all",
			objectives: "all",
			keyword: "",
			trialId: "all"
		};
	}

	function setInitialProtocolOwnerOptions(options) {
		let filteredOpt = (_.uniqBy(options, "protocolOwnerId"))
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.protocolOwnerId, value: o.protocolOwnerId, text: o.owner };
		});

		let orderedOptionList = _.orderBy(optionList, [optionList => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setProtocolOwnerOptions(orderedOptionList);

		if (
			protocolInfo.protocolOwnerId &&
			!_.some(optionList, (o) => {
				return o.personId === protocolInfo.protocolOwnerId;
			})
		) {
			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolOwnerId: "all" }));
		}
	}

	function setInitialCropOptions(options) {
		let filteredOpt = (_.uniqBy(options, "cropId"))
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.cropId, value: o.cropId, text: o.cropName };
		});

		let orderedOptionList = _.orderBy(optionList, ['text']);
		orderedOptionList.unshift(allSelection);
		setCropOptions(orderedOptionList);
	}

	function setInitialGrowingSeasonOptions(options) {
		let filteredOpt = (_.uniqBy(options, "growingSeasonId"))
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.growingSeasonId, value: o.growingSeasonId, text: o.growingSeasonName };
		});

		let orderedOptionList = _.orderBy(optionList, ['text'], ['desc']);
		orderedOptionList.unshift(allSelection);
		setGrowingSeasonOptions(orderedOptionList);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(optionList, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? optionList[0]?.value;
		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, growingSeasonId: currentGrowingSeasonSelection }));
		if (currentGrowingSeasonSelection) {
			setIsInitialGSSet(true);
		}
	}

	function setInitialGeographyOptions(options) {
		let filteredOpt = (_.uniqBy(options, "geographyId"))
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.geographyId, value: o.geographyId, text: o.geographyName };
		});

		let orderedOptionList = _.orderBy(optionList, ['text']);
		orderedOptionList.unshift(allSelection);
		setGeographyOptions(orderedOptionList);
	}

	function setInitialPlotSizeOptions(options) {
		let filteredOpt = (_.uniqBy(options, "plotSizeId"))
		let optionList = _.map(filteredOpt, (o) => {
			let plotSize = _.find(protocolOptions?.plotSizes, (po) => po.id === o.plotSizeId);
			if (plotSize) {
				return { key: o.plotSizeId, value: o.plotSizeId, text: `${plotSize.name} (${plotSize.description})` };
			}
		});

		let orderedOptionList = _.orderBy(optionList, ['text']);
		orderedOptionList.unshift(allSelection);
		setPlotSizeOptions(orderedOptionList);
	}

	function setInitialRegulatoryOptions(options) {
		let filteredOpt = _.uniqBy(_.map(_.filter(_.map(options, (o) => { return _.filter(o, (fo) => fo?.checked) }), (t) => { return t.length > 0 }), (v) => { return v[0] }), (u) => u.id);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.id, value: o.id, text: o.name, checked: false };
		});
		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, regulatories: optionList }));
		let orderedOptionList = _.orderBy(optionList, ['text']);
		setRegulatoryOptions(orderedOptionList);
		setOriginalRegulatoryOptions(optionList);
	}

	function setInitialVariablesTested(options) {
		let filteredOpt = _.uniqBy(_.map(_.filter(_.map(options, (o) => { return _.filter(o, (fo) => fo?.checked) }), (t) => { return t.length > 0 }), (v) => { return v[0] }), (u) => u.id);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.id, value: o.id, text: o.name, checked: false };
		});

		setProtocolInfo((protocolInfo) => ({ ...protocolInfo, variablesTested: optionList }));
		let orderedOptionList = _.orderBy(optionList, ['text']);
		setVariablesTestedOptions(orderedOptionList);
		setOriginalVariablesTestedOptions(optionList);
	}

	function setInitialProtocolNameOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.protocolName };
		});

		let orderedOptionList = _.orderBy(optionList, [optionList => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setProtocolNameOptions(orderedOptionList);

	}

	function setInitialTrialNameOptions(options) {
		let filteredOpt = _.uniqBy(_.flatten(_.filter(_.map(options), (pt) => { return pt.length > 0 })), (u) => u.trialId);
		let optionList = _.map(filteredOpt, (o) => {
			return { key: o.trialId, value: o.trialId, text: o.trialName };
		});

		let orderedOptionList = _.orderBy(optionList, [optionList => optionList.text.toLowerCase().trim()]);
		orderedOptionList.unshift(allSelection);
		setTrialNameOptions(orderedOptionList);
	}

	function filterProtocols(customProtocolInfo = null) {
		if (searchAllClients === true && hasSearchedAllClients === false) {
			getProtocols();
			getDropdownOptions();
			setHasSearchedAllClients(true);
		} else {
			var protocolsClone = _.cloneDeep(originalProtocols);
			let protocolInfoToFilter = customProtocolInfo ?? protocolInfo;

			const checkedRegulatories = _.map(
				_.filter(protocolInfoToFilter.regulatories, (reg) => {
					return reg.checked;
				}),
				"key"
			); //return only the ids of checked regulatories
			const checkedVariablesTested = _.map(
				_.filter(protocolInfoToFilter.variablesTested, (vt) => {
					return vt.checked;
				}),
				"key"
			); //return only the ids of checked regulatories

			let protocolsTemp = _.filter(protocolsClone, (protocol) => {
				return (
					(searchAllClients === true ||
						(searchAllClients === false && protocol.clientId === userAuth.currentClientId)) &&
					(protocolInfoToFilter?.cropId === "all" ? true : protocolInfoToFilter.cropId === protocol.cropId) &&
					((protocolInfoToFilter.yieldRequired === true && protocolInfoToFilter.yieldNotRequired === true) ||
						(protocolInfoToFilter.yieldNotRequired === true && protocol.yield === false) ||
						(protocolInfoToFilter.yieldRequired === true && protocol.yield === true)) &&
					(protocolInfoToFilter?.protocolOwnerId === "all"
						? true
						: protocolInfoToFilter.protocolOwnerId === protocol.protocolOwnerId) &&
					(protocolInfoToFilter?.growingSeasonId === "all"
						? true
						: protocolInfoToFilter.growingSeasonId === protocol.growingSeasonId) &&
					(protocolInfoToFilter?.geographyId === "all"
						? true
						: protocolInfoToFilter.geographyId === protocol.geographyId) &&
					(protocolInfoToFilter?.plotSizeId === "all"
						? true
						: protocolInfoToFilter.plotSizeId === protocol.plotSizeId) &&
					(protocolInfoToFilter?.id === "all"
						? true
						: protocolInfoToFilter.id === protocol.id) &&
					(_.some(protocolInfoToFilter?.regulatories, (r) => {
						return r.checked === true;
					})
						? _.some(protocol.regulatories, (reg1) => {
							return reg1.checked === true && checkedRegulatories.includes(reg1.id);
						})
						: true) &&
					(_.some(protocolInfoToFilter?.variablesTested, (v) => {
						return v.checked === true;
					})
						? _.some(protocol.protocolVariables, (var1) => {
							return var1.checked === true && checkedVariablesTested.includes(var1.id);
						})
						: true) &&
					(protocolInfoToFilter?.trialId === "all"
						? true
						: _.map(protocol.protocolTrials, 'trialId').includes(protocolInfoToFilter.trialId)) &&
					(protocolInfoToFilter.keyword == ""
						? true
						: //-- Keyword filtering
						protocol.protocolId?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.protocolName?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.treatments?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.replicates?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.rotation?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.genetics?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.herbicides?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.fertilizer?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.pesticides?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.other?.toString().includes(protocolInfoToFilter.keyword) ||
						protocol.objectives?.toString().includes(protocolInfoToFilter.keyword))
				);
			});
			setProtocols(protocolsTemp);
		}
	}

	async function getDropdownOptions() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolInfoOptions(userAuth.currentClientId, searchAllClients, accessToken))
			.then((res) => {
				setProtocolOptions(res.data);
				setLoading(false);
			})
			.catch((err) => {
				toast.error(err);
				setLoading(false);
			});
	}

	async function getProtocols() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocols(userAuth.currentClientId, searchAllClients, accessToken)).then((res) => {
			res.data = _.filter(res.data, (d) => {
				if (userAuth.hasApplicationArea(ApplicationArea.ProtocolSearch, d.clientId)) {
					return d;
				}
			});
			//Set options
			setOriginalProtocols(res.data);
			setProtocols(res.data);
			setInitialProtocolNameOptions(res.data);
			setInitialCropOptions(res.data);
			setInitialGrowingSeasonOptions(res.data);
			setInitialGeographyOptions(res.data);
			setInitialProtocolOwnerOptions(res.data);
			setInitialPlotSizeOptions(res.data);
			setInitialRegulatoryOptions(_.map(res.data, (rd) => { return rd.regulatories }));
			setInitialVariablesTested(_.map(res.data, (rd) => { return rd.protocolVariables }));
			setInitialTrialNameOptions(_.map(res.data, (rd) => { return rd.protocolTrials }));

			res.data = _.filter(res.data, (d) => {
				return d.clientId === userAuth.currentClientId;
			});

			setLoading(false);
		});
	}

	const handleReset = () => {
		let resetProtocolInfo = setInitialProtocolInfo("all");
		setProtocolInfo(resetProtocolInfo);

		filterProtocols(resetProtocolInfo);
	};

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	return (
		<>
			<Segment style={{ marginLeft: 50 }}>
				<Segment basic id="protocol-log-segment" style={{ minHeight: 50, marginBottom: 30 }}>
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column style={{ fontSize: "1.2em" }}>Protocol Search</Grid.Column>
							</Grid.Row>
							<Divider />
							<Grid.Row>
								<Grid.Column width="6">
									{" "}
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-protocol-owner">Protocol Name</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-protocol-name"
												search
												options={protocolNameOptions}
												value={protocolInfo?.id}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, id: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-protocol-owner">Protocol Owner</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-protocol-owner"
												search
												options={protocolOwnerOptions}
												value={protocolInfo?.protocolOwnerId}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolOwnerId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-crop">Crop</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-crop"
												search
												options={cropOptions}
												value={protocolInfo?.cropId}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, cropId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-growing-season">Growing Season</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-growing-season"
												search
												options={growingSeasonOptions}
												value={protocolInfo?.growingSeasonId ?? "all"}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, growingSeasonId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-geography">Geography</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-geography"
												search
												options={geographyOptions}
												value={protocolInfo?.geographyId}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, geographyId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-plot-size">Plot Size</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-plot-size"
												search
												options={plotSizeOptions}
												value={protocolInfo?.plotSizeId}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, plotSizeId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="4" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-protocol-owner">Trial Name</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="8" textAlign="right">
											<Form.Select
												id="form-select-trial-name"
												search
												options={trialNameOptions}
												value={protocolInfo?.trialId}
												loading={loading}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, trialId: value }));
												}}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid.Column>
								<Grid.Column width="3">
									{" "}
									<Grid.Row>
										<Grid.Column>
											<p style={{ fontSize: "1.2em" }}>Yield Required?</p>
											<Form.Field>
												<Checkbox
													label="Yes"
													disabled={loading}
													checked={protocolInfo?.yieldRequired}
													onChange={(event, { checked }) => {
														setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yieldRequired: checked }));
													}}
												/>
											</Form.Field>
											<Form.Field>
												<Checkbox
													label="No"
													disabled={loading}
													checked={protocolInfo?.yieldNotRequired}
													onChange={(event, { checked }) => {
														setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yieldNotRequired: checked }));
													}}
													style={{ marginTop: "10px" }}
												/>
											</Form.Field>
										</Grid.Column>
									</Grid.Row>
									<br />
									<Grid.Row>
										<Grid.Column>
											<p style={{ fontSize: "1.2em" }}>Regulatory:</p>
											{_.map(regulatoryOptions, (option) => {
												return (
													<Grid.Row key={option.key}>
														<Checkbox
															label={option.text}
															style={{ marginTop: "10px" }}
															checked={_.find(protocolInfo?.regulatories, { key: option.value })?.checked}
															onChange={(event, { checked }) => {
																let matchingIndex = _.findIndex(protocolInfo?.regulatories, { key: option.value });
																let updatedRegulatory = _.cloneDeep(protocolInfo?.regulatories);
																if (matchingIndex > -1) {
																	updatedRegulatory[matchingIndex].checked = checked;
																	setProtocolInfo((protocolInfo) => ({
																		...protocolInfo,
																		regulatories: updatedRegulatory
																	}));
																}
															}}
														/>
													</Grid.Row>
												);
											})}
										</Grid.Column>
									</Grid.Row>
								</Grid.Column>
								<Grid.Column width="7">
									<p style={{ fontSize: "1.2em" }}>Variables Tested:</p>
									<Grid>
										<Grid.Row>
											{_.map(variablesTestedOptions, (option) => {
												return (
													<Checkbox
														key={option.key}
														label={option.text}
														style={{ marginTop: "10px", marginLeft: "10px" }}
														checked={_.find(protocolInfo?.variablesTested, { key: option.value })?.checked}
														onChange={(event, { checked }) => {
															let matchingIndex = _.findIndex(protocolInfo?.variablesTested, { key: option.value });
															let updatedVariabledTested = _.cloneDeep(protocolInfo?.variablesTested);
															if (matchingIndex > -1) {
																updatedVariabledTested[matchingIndex].checked = checked;

																setProtocolInfo((protocolInfo) => ({
																	...protocolInfo,
																	variablesTested: updatedVariabledTested
																}));
															}
														}}
													/>
												);
											})}
										</Grid.Row>
										<Grid.Row>
											<Input
												id="keyword-search"
												label="Keyword Search:"
												value={protocolInfo?.keyword}
												onChange={(e, { value }) => {
													setProtocolInfo((protocolInfo) => ({
														...protocolInfo,
														keyword: value
													}));
												}}
											/>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width="2">
									<Form.Group inline>
										<Form.Field style={{ marginBottom: "5px" }}>
											<label htmlFor="form-search-all-clients">Search All Clients</label>
										</Form.Field>
										<Form.Checkbox
											id="form-search-all-clients"
											checked={searchAllClients}
											onChange={(e, { checked }) => {
												setSearchAllClients(checked);
											}}
										/>
									</Form.Group>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Segment basic>
							<Button
								id="form-button-filter"
								floated="right"
								primary
								content={"Filter"}
								onClick={() => {
									filterProtocols();
								}}
								disabled={loading}
								loading={loading}
							/>
							<Button
								id="form-button-reset"
								floated="right"
								content={"Reset"}
								onClick={() => {
									handleReset();
								}}
								disabled={loading}
								loading={loading}
							/>
						</Segment>
					</Form>
				</Segment>
				<Segment basic loading={loading}>
					<ProtocolLogResults
						protocolList={protocols}
						protocolOptions={protocolOptions}
						variablesTestedOptions={variablesTestedOptions}
						cropOptions={cropOptions}
						geographyOptions={geographyOptions}
						growingSeasonOptions={growingSeasonOptions}
					/>
				</Segment>
			</Segment>
		</>
	);
};

export default ProtocolLog;
