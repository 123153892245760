import React, { useState, useEffect } from "react";
import { Segment, Dropdown, Grid, Loader, Header } from "semantic-ui-react";
import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { jStat } from "jstat";
import _ from "lodash";
import moment from "moment";
import * as uuid from "uuid";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import { toast } from "react-toastify";

const CorrelationPlots = () => {
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const DATE_FORMAT = "M/D/YYYY";

	const CanvasJSChart = CanvasJSReact.CanvasJSChart;
	const [chartOptions, setChartOptions] = useState({});

	//-- Data
	const [analysisResults, setAnalysisResults] = useState([]);
	const [filteredXAnalysisResults, setFilteredXAnalysisResults] = useState([]);
	const [filteredYAnalysisResults, setFilteredYAnalysisResults] = useState([]);
	const [rValue, setRValue] = useState("");
	const [cachedData, setCachedData] = useState([]);
	const [aucAssessments, setAucAssessments] = useState([]);
	const [selectedAucAxis, setSelectedAucAxis] = useState("");
	const [treatmentData, setTreatmentData] = useState([]);

	//-- Dropdown Options
	const [analysisOptions, setAnalysisOptions] = useState([]);
	const [selectedXAssessmentOption, setSelectedXAssessmentOption] = useState("");
	const [selectedYAssessmentOption, setSelectedYAssessmentOption] = useState("");
	const [selectedXAssessmentOptionIsAuc, setSelectedXAssessmentOptionIsAuc] = useState(false);
	const [selectedYAssessmentOptionIsAuc, setSelectedYAssessmentOptionIsAuc] = useState(false);
	const [selectedXAssessmentOptionIsGrowthCurve, setSelectedXAssessmentOptionIsGrowthCurve] = useState(false);
	const [selectedYAssessmentOptionIsGrowthCurve, setSelectedYAssessmentOptionIsGrowthCurve] = useState(false);
	const [flightOptions, setFlightOptions] = useState([]);
	const [filteredXFlightOptions, setFilteredXFlightOptions] = useState([]);
	const [filteredYFlightOptions, setFilteredYFlightOptions] = useState([]);
	const [selectedXFlightOption, setSelectedXFlightOption] = useState("");
	const [selectedYFlightOption, setSelectedYFlightOption] = useState("");
	const [selectedXAucFlightOption, setSelectedXAucFlightOption] = useState("");
	const [selectedYAucFlightOption, setSelectedYAucFlightOption] = useState("");
	const [filteredXAucFlightOptions, setFilteredXAucFlightOptions] = useState([]);
	const [filteredYAucFlightOptions, setFilteredYAucFlightOptions] = useState([]);
	const [combinedDataOptions, setCombinedDataOptions] = useState([]);

	//--UI Control
	const [loading, setLoading] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);

	useEffect(() => {
		getDataOptions();
	}, []);

	useEffect(() => {
		if (selectedXFlightOption && selectedXAssessmentOption && !selectedXAssessmentOptionIsAuc) {
			updateCanvasData("x");
		}
	}, [selectedXFlightOption, selectedXAssessmentOption]);

	useEffect(() => {
		if (selectedYFlightOption && selectedYAssessmentOption && !selectedYAssessmentOptionIsAuc) {
			updateCanvasData("y");
		}
	}, [selectedYFlightOption, selectedYAssessmentOption]);

	useEffect(() => {
		if (selectedXAssessmentOption && flightOptions && flightOptions.length > 0) {
			if (
				_.some(combinedDataOptions, (cdo) => {
					return cdo.assessmentId == selectedXAssessmentOption && cdo.type == "Growth Curve";
				})
			) {
				updateCanvasData("x");
			} else {
				changeFlightOptions("x");
			}
		}
	}, [selectedXAssessmentOption]);

	useEffect(() => {
		if (selectedYAssessmentOption && flightOptions && flightOptions.length > 0) {
			if (
				_.some(combinedDataOptions, (cdo) => {
					return cdo.assessmentId == selectedYAssessmentOption && cdo.type == "Growth Curve";
				})
			) {
				updateCanvasData("y");
			} else {
				changeFlightOptions("y");
			}
		}
	}, [selectedYAssessmentOption]);

	useEffect(() => {
		if (selectedXAssessmentOptionIsAuc && selectedXAucFlightOption && filteredXAucFlightOptions.length > 0) {
			setupAucData("x");
		}
	}, [selectedXAssessmentOptionIsAuc, selectedXFlightOption, selectedXAucFlightOption, selectedXAssessmentOption]);

	useEffect(() => {
		if (selectedYAssessmentOptionIsAuc && selectedYAucFlightOption !== "" && filteredYAucFlightOptions.length > 0) {
			setupAucData("y");
		}
	}, [selectedYAssessmentOptionIsAuc, selectedYFlightOption, selectedYAucFlightOption, selectedYAssessmentOption]);

	useEffect(() => {
		if (
			analysisResults.length > 0 &&
			Object.keys(filteredXAnalysisResults).length > 0 &&
			Object.keys(filteredYAnalysisResults).length > 0
		) {
			stageChartOptions();
		}
	}, [filteredXAnalysisResults, filteredYAnalysisResults]);

	useEffect(() => {
		if (
			cachedData.length > 0 &&
			(selectedXAssessmentOptionIsAuc || selectedYAssessmentOptionIsAuc) &&
			aucAssessments.length > 0
		) {
			stageAucData();
		}
	}, [cachedData]);

	async function getDataOptions() {
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.getTrialAnalysisDataOptions(userAuth.currentClientId, moduleNavigation.trialId, accessToken)
		).then((res) => {
			dispatch(trialActions.getTrialData(userAuth.currentClientId, moduleNavigation.trialId, accessToken))
				.then((trialData) => {
					setTreatmentData(trialData.treatments);

					let combinedAssessmentTypes = [];
					let combinedAssessmentDates = [];
					let combinedAssessmentOptions = [];

					if (trialData.trialInfo.plantDate !== null) {
						combinedAssessmentDates.push({
							id: "Plant Date",
							date: trialData.trialInfo.plantDate
						});
					}

					_.forEach(res.data.trialAnalysisOptions, ({ analysisTypes, flightId, flightDate }) => {
						_.map(analysisTypes, ({ id }) => {
							combinedAssessmentOptions.push({
								assessmentId: flightId,
								assessmentType: id,
								type: "Flight"
							});
						});
						combinedAssessmentTypes.push({
							id: "flightSection",
							name: "Analyses"
						});
						combinedAssessmentTypes = combinedAssessmentTypes.concat(analysisTypes);
						combinedAssessmentDates.push({
							id: flightId,
							date: flightDate
						});
					});

					_.forEach(res.data.groundDataOptions, ({ groundDataAssessments, groundDatasetId, assessmentDate }) => {
						_.map(groundDataAssessments, ({ id }) => {
							combinedAssessmentOptions.push({
								assessmentId: groundDatasetId,
								assessmentType: id,
								type: "Ground Data"
							});
						});
						combinedAssessmentTypes.push({
							id: "groundDataSection",
							name: "Ground Data"
						});
						combinedAssessmentTypes = combinedAssessmentTypes.concat(groundDataAssessments);
						combinedAssessmentDates.push({
							id: groundDatasetId,
							date: assessmentDate
						});
					});

					_.forEach(
						res.data.growthCurveOptions,
						({
							curveModelName,
							curveModelAnalysisEndDate,
							curveModelAnalysisStartDate,
							analysisId,
							curveModelAnalysisId,
							checked,
							enabled,
							selected
						}) => {
							combinedAssessmentOptions.push({
								assessmentId: curveModelName,
								assessmentType: curveModelName,
								type: "Growth Curve"
							});
							combinedAssessmentTypes.push({ id: "growthCurveSection", name: "Growth Curve" });
							combinedAssessmentTypes.push({
								id: curveModelName,
								name: curveModelName,
								enabled: enabled,
								checked: checked,
								selected: selected,
								type: "growthCurve",
								endDate: curveModelAnalysisEndDate,
								startDate: curveModelAnalysisStartDate,
								analysisId: analysisId,
								curveModelAnalysisId: curveModelAnalysisId
							});
						}
					);

					if (trialData.trialInfo.harvestDate !== null) {
						combinedAssessmentDates.push({
							id: "Harvest Date",
							date: trialData.trialInfo.harvestDate
						});
					}

					let combinedAssessmentOptionsWithAuc = combinedAssessmentOptions;
					let combinedAssessmentTypesWithAuc = combinedAssessmentTypes;

					_.map(combinedAssessmentOptions, ({ assessmentId, assessmentType, type }) => {
						combinedAssessmentOptionsWithAuc.push({
							assessmentId: assessmentId,
							assessmentType: `auc-${assessmentType}`,
							type: type
						});
					});

					let addedSectionFlag = false;
					_.map(combinedAssessmentTypes, ({ id, name }) => {
						if (!id.includes("Section")) {
							let numFlightsWithAssessments = _.filter(
								combinedAssessmentOptions,
								(opt) => opt.assessmentType === id
							).length;

							let isGroundData = _.find(combinedAssessmentOptions, { assessmentType: id })?.type === "Ground Data";
							let isGrowthCurveData =
								_.find(combinedAssessmentOptions, { assessmentType: id })?.type === "Growth Curve";

							if (!isGroundData && !isGrowthCurveData) {
								if (numFlightsWithAssessments > 1) {
									if (!addedSectionFlag) {
										combinedAssessmentTypesWithAuc.push({
											id: "aucSection",
											name: "AUC"
										});
										addedSectionFlag = true;
									}

									combinedAssessmentTypesWithAuc.push({
										id: `auc-${id}`,
										name: `AUC - ${name}`
									});
								}
							}
						}
					});

					setCombinedDataOptions(combinedAssessmentOptionsWithAuc);
					combinedAssessmentTypesWithAuc = _.uniqBy(combinedAssessmentTypesWithAuc, "id");
					const combinedAssessmentList = _.map(combinedAssessmentTypesWithAuc, (assessment) => {
						if (assessment.id.includes("Section")) {
							return {
								key: assessment.id,
								value: assessment.id,
								text: assessment.name,
								disabled: true,
								content: <Header content={assessment.name} as="h4" />
							};
						} else if (assessment.curveModelAnalysisId) {
							return {
								key: assessment.id,
								value: assessment.id,
								text: assessment.name,
								analysisid: assessment.analysisId,
								curvemodelanalysisid: assessment.curveModelAnalysisId
							};
						} else {
							return { key: assessment.id, value: assessment.id, text: assessment.name };
						}
					});
					setAnalysisOptions(combinedAssessmentList);

					// Default to NDVI if available
					let ndviIndex = _.findIndex(combinedAssessmentList, { text: "Aerial NDVI" });
					let usedIndex = 0;
					let analysisXValue = "";
					if (ndviIndex !== -1) {
						analysisXValue = combinedAssessmentList[ndviIndex].value;
					} else {
						usedIndex = nextValidAssessmentIndex(combinedAssessmentList, usedIndex);
						analysisXValue = combinedAssessmentList[usedIndex].value;
					}

					let analysisYValue = "";
					usedIndex = nextValidAssessmentIndex(combinedAssessmentList, usedIndex);
					analysisYValue = combinedAssessmentList[usedIndex].value;

					setSelectedXAssessmentOptionIsAuc(analysisXValue.startsWith("auc-"));
					setSelectedYAssessmentOptionIsAuc(analysisYValue.startsWith("auc-"));

					setSelectedXAssessmentOption(analysisXValue);
					setSelectedYAssessmentOption(analysisYValue);

					const flights = _.orderBy(combinedAssessmentDates, ["date"], ["asc"]);
					const flightList = _.map(flights, (flight) => {
						let isPlantDate = flight.id === "Plant Date";
						let isHarvestDate = flight.id === "Harvest Date";
						let startingText = isPlantDate || isHarvestDate ? `${flight.id} - ` : "";

						return {
							key: flight.id,
							value: flight.id,
							text: `${startingText}${moment(flight.date).local().format(DATE_FORMAT)}`
						};
					});

					setFlightOptions(flightList);
					const filteredXFlightList = _.filter(flightList, (flight) => {
						if ((flight.key === "Plant Date" || flight.key === "Harvest Date") && analysisXValue.startsWith("auc-")) {
							return true;
						}

						let isGroundData = _.findIndex(res.data.groundDataOptions, { groundDatasetId: flight.key }) !== -1;
						if (isGroundData) {
							return _.some(res.data.groundDataOptions, (r) => {
								return (
									r.groundDatasetId === flight.value && // Checks if ground dataset id matches
									_.some(r.groundDataAssessments, (gda) => {
										// Checks if assessment id exists in dataset
										return gda.id === analysisXValue;
									})
								);
							});
						} else {
							return _.some(res.data.trialAnalysisOptions, (r) => {
								return (
									r.flightId === flight.value &&
									_.some(r.analysisTypes, (at) => {
										return at.id === analysisXValue;
									})
								);
							});
						}
					});

					const filteredYFlightList = _.filter(flightList, (flight) => {
						if ((flight.key === "Plant Date" || flight.key === "Harvest Date") && analysisYValue.startsWith("auc-")) {
							return true;
						}

						let isGroundData = _.findIndex(res.data.groundDataOptions, { groundDatasetId: flight.key }) !== -1;
						if (isGroundData) {
							return _.some(res.data.groundDataOptions, (r) => {
								return (
									r.groundDatasetId === flight.value && // Checks if ground dataset id matches
									_.some(r.groundDataAssessments, (gda) => {
										// Checks if assessment id exists in dataset
										return gda.id === analysisYValue;
									})
								);
							});
						} else {
							return _.some(res.data.trialAnalysisOptions, (r) => {
								return (
									r.flightId === flight.value &&
									_.some(r.analysisTypes, (at) => {
										return at.id === analysisYValue;
									})
								);
							});
						}
					});

					setFilteredXFlightOptions(filteredXFlightList);
					setSelectedXFlightOption(filteredXFlightList[0].value);
					setFilteredYFlightOptions(filteredYFlightList);
					setSelectedYFlightOption(filteredYFlightList[0].value);
				})
				.catch(() => {
					setLoading(false);
					toast.error("No trial data to load.");
				});
		});
	}

	function nextValidAssessmentIndex(assessmentList, startIndex) {
		for (let i = startIndex; i < assessmentList.length; i++) {
			if (!assessmentList[i].key.includes("Section")) {
				return i;
			}
		}
	}

	async function getAnalysisData(axis) {
		let assessmentOption = axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption;

		let isGroundData =
			_.find(combinedDataOptions, {
				assessmentId: axis === "x" ? selectedXFlightOption : selectedYFlightOption
			}).type === "Ground Data";

		let isGrowthCurveData = _.some(combinedDataOptions, {
			assessmentId: axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption,
			type: "Growth Curve"
		});

		setChartLoading(true);
		setChartOptions([]);
		const accessToken = await getTokenSilently();
		dispatch(
			isGroundData
				? analysisActions.getCorrelationPlotAnalysisResultsForTrial(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						null, // analysisId
						null, // flightId
						assessmentOption, // groundDatasetAssessmentId
						axis === "x" ? selectedXFlightOption : selectedYFlightOption, // groundDatasetId
						null, //curveModelAnalysisId
						accessToken
				  )
				: isGrowthCurveData
				? analysisActions.getCorrelationPlotAnalysisResultsForTrial(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						_.find(analysisOptions, (ao) => {
							return ao.key == (axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption);
						})?.analysisid, // analysisId
						null, // flightId
						null, // groundDatasetAssessmentId
						null, // groundDatasetId
						_.find(analysisOptions, (ao) => {
							return ao.key == (axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption);
						})?.curvemodelanalysisid, //curveModelAnalysisId
						accessToken
				  )
				: analysisActions.getCorrelationPlotAnalysisResultsForTrial(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						assessmentOption, // analysisId
						axis === "x" ? selectedXFlightOption : selectedYFlightOption, // flightId
						null, // groundDatasetAssessmentId
						null, // groundDatasetId
						null, //curveModelAnalysisId
						accessToken
				  )
		)
			.then((res) => {
				setAnalysisResults(res);
				stageResults(res, axis, false);
				setLoading(false);
			})
			.catch(() => {
				setChartLoading(false);
				setLoading(false);
			});
	}

	async function getAnalysisDataForRange(axis, assessmentId = []) {
		let assessmentOption = axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption;
		if (assessmentId.length > 0) {
			assessmentOption = assessmentOption.substring(4);
		}

		let isGroundData =
			_.find(combinedDataOptions, {
				assessmentId: assessmentId[0]
			}).type === "Ground Data";

		setChartLoading(true);
		setChartOptions([]);
		const accessToken = await getTokenSilently();
		// Waits for all results to be returned and then adds to cached data
		// eslint-disable-next-line
		const data = await Promise.all(
			_.map(assessmentId, (a) => {
				return dispatch(
					isGroundData
						? analysisActions.getCorrelationPlotAnalysisResultsForTrial(
								userAuth.currentClientId,
								moduleNavigation.trialId,
								null, // analysisId
								null, // flightId
								assessmentOption, // groundDatasetAssessmentId
								a, // groundDatasetId
								null, // curveModelAnalysisId
								accessToken
						  )
						: analysisActions.getCorrelationPlotAnalysisResultsForTrial(
								userAuth.currentClientId,
								moduleNavigation.trialId,
								assessmentOption, // analysisId
								a, // flightId
								null, // groundDatasetAssessmentId
								null, // groundDatasetId
								null, // curveModelAnalysisId
								accessToken
						  )
				).then((res) => {
					return res;
				});
			})
		)
			.then((res) => {
				let currentResults = [];

				_.forEach(res, (r) => {
					currentResults.push({
						assessmentType: assessmentOption,
						assessmentId: r[0].groundDatasetId ?? r[0].flightId,
						data: r
					});
				});

				setCachedData(cachedData.concat(currentResults));
				setLoading(false);
			})
			.catch(() => {
				setChartLoading(false);
				setLoading(false);
			});
	}

	function changeFlightOptions(axis) {
		const filteredFlightList = _.filter(flightOptions, (flight) => {
			if (
				(flight.key === "Plant Date" || flight.key === "Harvest Date") &&
				(axis === "x" ? selectedXAssessmentOptionIsAuc : selectedYAssessmentOptionIsAuc)
			) {
				return true;
			}

			return _.some(combinedDataOptions, ({ assessmentId, assessmentType }) => {
				return (
					assessmentId === flight.key &&
					assessmentType === (axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption)
				);
			});
		});

		axis === "x" ? setFilteredXFlightOptions(filteredFlightList) : setFilteredYFlightOptions(filteredFlightList);

		if (axis === "x" ? !selectedXAssessmentOptionIsAuc : !selectedYAssessmentOptionIsAuc) {
			let selectedFlightExistsInOptions =
				_.findIndex(filteredFlightList, { value: axis === "x" ? selectedXFlightOption : selectedYFlightOption }) !== -1;
			if (!selectedFlightExistsInOptions) {
				axis === "x"
					? setSelectedXFlightOption(filteredFlightList[0].value)
					: setSelectedYFlightOption(filteredFlightList[0].value);
			}

			axis === "x" ? setFilteredXAucFlightOptions([]) : setFilteredYAucFlightOptions([]);
		} else {
			let plantDateExists = _.findIndex(filteredFlightList, { key: "Plant Date" });
			let harvestDateExists = _.findIndex(filteredFlightList, { key: "Harvest Date" });

			let beginningDate = filteredFlightList[plantDateExists !== -1 ? plantDateExists : 0].value;
			let endDate =
				filteredFlightList[harvestDateExists !== -1 ? harvestDateExists : filteredFlightList.length - 1].value;

			axis === "x"
				? setFilteredXAucFlightOptions(filteredFlightList)
				: setFilteredYAucFlightOptions(filteredFlightList);

			axis === "x" ? setSelectedXFlightOption(beginningDate) : setSelectedYFlightOption(beginningDate);
			axis === "x" ? setSelectedXAucFlightOption(endDate) : setSelectedYAucFlightOption(endDate);
		}
	}

	function updateCanvasData(axis) {
		let isGrowthCurveData = _.some(combinedDataOptions, {
			assessmentId: axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption,
			type: "Growth Curve"
		});

		let assessmentCombinationExists =
			_.findIndex(combinedDataOptions, {
				assessmentId: axis === "x" ? selectedXFlightOption : selectedYFlightOption,
				assessmentType: axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption
			}) !== -1;

		if (assessmentCombinationExists) {
			let dataIsCached = _.findIndex(cachedData, {
				assessmentType: axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption,
				assessmentId: axis === "x" ? selectedXFlightOption : selectedYFlightOption
			});
			setRValue("");
			if (dataIsCached !== -1) {
				stageResults(cachedData[dataIsCached].data, axis, true);
			} else {
				getAnalysisData(axis);
			}
		} else if (isGrowthCurveData) {
			let dataIsCached = _.findIndex(cachedData, {
				type: "Growth Curve",
				assessmentId: axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption
			});
			setRValue("");
			if (dataIsCached !== -1) {
				stageResults(cachedData[dataIsCached].data, axis, true);
			} else {
				getAnalysisData(axis);
			}
		}
	}

	function stageResults(trialData, axis, isCached) {
		let assessmentOption = axis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption;

		//groupBy will make create a property per unique trialTreatmentId on a singular object
		trialData = _.sortBy(trialData, ["trialTreatmentId", "plotReplicate"]);
		let res = _.groupBy(trialData, "trialTreatmentId");
		if (!isCached) {
			setCachedData(
				cachedData.concat([
					{
						assessmentType: assessmentOption,
						assessmentId: axis === "x" ? selectedXFlightOption : selectedYFlightOption,
						data: trialData
					}
				])
			);
		}

		if (axis === "x") {
			setFilteredXAnalysisResults(res);
		} else {
			setFilteredYAnalysisResults(res);
		}
	}

	function stageChartOptions() {
		let originalXValuesName = _.find(analysisOptions, (opt) => {
			return opt.value === selectedXAssessmentOption;
		});
		let originalYValuesName = _.find(analysisOptions, (opt) => {
			return opt.value === selectedYAssessmentOption;
		});

		let scatterPlot = [];
		let xValuesName = originalXValuesName ? originalXValuesName.text.toUpperCase() : "ANALYSIS";
		let yValuesName = originalYValuesName ? originalYValuesName.text.toUpperCase() : "ANALYSIS";

		let xAnalysisIsStandCount = originalXValuesName.text.includes("Stand Count");
		let yAnalysisIsStandCount = originalYValuesName.text.includes("Stand Count");
		_.map(filteredXAnalysisResults, (results, i) => {
			let values =
				results[0].flightId == null
					? results[0].curveModelAnalysisId
						? _.map(
								_.filter(results, (r) => !r.excludeFromAssessment),
								"plotAnalysisResultValue"
						  )
						: _.map(
								_.filter(results, (r) => !r.excludeFromAssessment),
								"groundDatasetAssessmentValue"
						  )
					: xAnalysisIsStandCount
					? _.map(
							_.filter(results, (r) => !r.excludeFromAssessment),
							"standCountAnalysisResultValue"
					  )
					: _.map(
							_.filter(results, (r) => !r.excludeFromAssessment),
							"plotAnalysisResultValue"
					  );
			_.map(values, (value, j) => {
				if (value !== null) {
					scatterPlot.push({ x: value, y: 0, index: [i, j] });
				}
			});
		});

		_.map(filteredYAnalysisResults, (results, i) => {
			let values =
				results[0].flightId == null
					? results[0].curveModelAnalysisId
						? _.map(
								_.filter(results, (r) => !r.excludeFromAssessment),
								"plotAnalysisResultValue"
						  )
						: _.map(results, "groundDatasetAssessmentValue")
					: yAnalysisIsStandCount
					? _.map(results, "standCountAnalysisResultValue")
					: _.map(results, "plotAnalysisResultValue");
			_.map(values, (value, j) => {
				let scatterIndex = _.findIndex(scatterPlot, { index: [i, j] });
				if (scatterIndex !== -1) {
					if (value !== null && !results[j].excludeFromAssessment) {
						scatterPlot[scatterIndex].y = value;
					} else {
						scatterPlot.splice(scatterIndex, 1);
					}
				}
			});
		});

		xValuesName = xValuesName ? xValuesName.toUpperCase() : "ANALYSIS";
		yValuesName = yValuesName ? yValuesName.toUpperCase() : "ANALYSIS";

		// Calculate Pearson's Correlation
		let xSequence = [];
		let ySequence = [];
		_.map(scatterPlot, ({ x, y }) => {
			xSequence.push(x);
			ySequence.push(y);
		});

		let pearsonCorrelation = jStat.corrcoeff(xSequence, ySequence);

		setRValue(pearsonCorrelation);

		let N = xSequence.length;
		let sumX = _.reduce(xSequence, (a, b) => {
			return a + b;
		});
		let sumY = _.reduce(ySequence, (a, b) => {
			return a + b;
		});

		let sumXy = 0;
		_.map(xSequence, (x, index) => {
			sumXy += x * ySequence[index];
		});
		let a = sumXy * xSequence.length;
		let b = sumX * sumY;

		let xSquare = 0;
		_.map(xSequence, (x) => {
			xSquare += Math.pow(x, 2);
		});
		let c = xSquare * xSequence.length;
		let d = Math.pow(sumX, 2);
		let slope = (a - b) / (c - d);

		let intercept = (sumY - slope * sumX) / N;

		let linePoints = [];
		linePoints.push({ x: _.min(xSequence), y: slope * _.min(xSequence) + intercept });
		linePoints.push({ x: _.max(xSequence), y: slope * _.max(xSequence) + intercept });

		let filteredScatterPlot = _.filter(scatterPlot, ({ x, y }) => {
			return x !== null && y !== null;
		});
		let opt = {
			animationEnabled: true,
			theme: "light2",
			title: {
				text: "Trial Data"
			},
			axisY: {
				title: yValuesName,
				includeZero: false
			},
			axisX: {
				title: xValuesName,
				includeZero: false
			},
			data: [
				{
					type: "line",
					dataPoints: linePoints,
					toolTipContent: null
				},
				{
					type: "scatter",
					color: "blue",
					toolTipContent: null,
					dataPoints: filteredScatterPlot
				}
			]
		};

		setChartLoading(false);
		setChartOptions(opt);
	}

	async function setupAucData(axis) {
		setChartLoading(true);
		let flightOptions = axis === "x" ? filteredXFlightOptions : filteredYFlightOptions;

		let startDateIndex = _.findIndex(flightOptions, {
			key: axis === "x" ? selectedXFlightOption : selectedYFlightOption
		});

		let endDateIndex = _.findIndex(flightOptions, {
			key: axis === "x" ? selectedXAucFlightOption : selectedYAucFlightOption
		});

		if (startDateIndex < endDateIndex) {
			setRValue("");
			let assessmentsToCache = [];
			let aucAssessments = [];
			for (let i = startDateIndex; i <= endDateIndex; i++) {
				let assessmentId = axis === "x" ? filteredXFlightOptions[i].key : filteredYFlightOptions[i].key;
				let assessmentOption =
					axis === "x" ? selectedXAssessmentOption.substring(4) : selectedYAssessmentOption.substring(4); // Removes "auc-"
				if (assessmentId !== "Plant Date" && assessmentId !== "Harvest Date") {
					let dataIsCached = _.findIndex(cachedData, {
						assessmentType: assessmentOption,
						assessmentId: assessmentId
					});

					if (dataIsCached === -1) {
						assessmentsToCache.push(assessmentId);
					}
				}

				aucAssessments.push({
					id: assessmentId,
					type: assessmentOption
				});
			}

			setAucAssessments(aucAssessments);
			setSelectedAucAxis(axis);

			if (assessmentsToCache.length > 0) {
				getAnalysisDataForRange(axis, assessmentsToCache);
			} else {
				stageAucData(aucAssessments, axis);
			}
		} else {
			toast.error("Assessment start date cannot be on or after assessment end date.");
			setChartLoading(false);
		}
	}

	function addEmptyFlightForPlantOrHarvestDate(date, treatments, analysis, type) {
		if (date !== null) {
			const hasFlightDateFlight = _.some(treatments, (treatmentData) => {
				return (
					moment(treatmentData.flightId ? treatmentData.flightDate : treatmentData.groundDatasetDate)
						.local()
						.format(DATE_FORMAT) === moment(date).local().format(DATE_FORMAT)
				);
			});

			if (!hasFlightDateFlight) {
				let uniquePlotReplicates = _.uniqBy(treatments, "plotReplicate");
				let treatmentsToReturn = [];

				const flightId = uuid.v4();
				_.forEach(treatmentData, (treatment) => {
					_.forEach(uniquePlotReplicates, (rep) => {
						const obj = {
							analysisId: analysis.value,
							analysisName: analysis.text,
							clientId: userAuth.currentClientId,
							flightDate: type === "Flight" ? date : null,
							flightId: type === "Flight" ? flightId : null,
							flightName: "",
							plotAnalysisResultValue: 0,
							plotReplicate: rep.plotReplicate,
							plotRange: rep.plotRange,
							trialId: uuid.v4(),
							trialName: "",
							trialSampleSize: "",
							trialTreatmentId: treatment.trialTreatmentId,
							groundDatasetAssessmentValue: 0,
							groundDatasetId: type === "Flight" ? null : flightId,
							groundDatasetDate: type === "Flight" ? null : date
						};
						treatmentsToReturn.push(obj);
					});
				});
				return treatmentsToReturn;
			}
		}
	}

	function stageAucData(assessments = null, axis = null) {
		let data = [];
		_.map(assessments === null ? aucAssessments : assessments, (assessment) => {
			if (assessment.id === "Plant Date" || assessment.id === "Harvest Date") {
				let plantHarvestDate = _.find(flightOptions, { key: assessment.id }).text.split(" - ")[1];
				let exampleData = cachedData[0].data;
				let analysisOpt = _.find(analysisOptions, {
					key: selectedAucAxis === "x" ? selectedXAssessmentOption : selectedYAssessmentOption
				});
				let analysisType = _.find(combinedDataOptions, { assessmentType: assessment.type }).type;

				let emptyData = addEmptyFlightForPlantOrHarvestDate(plantHarvestDate, exampleData, analysisOpt, analysisType);
				if (emptyData) {
					data = data.concat(emptyData);
				}
			} else {
				data = data.concat(_.find(cachedData, { assessmentType: assessment.type, assessmentId: assessment.id }).data);
			}
		});

		data = _.sortBy(data, [
			(d) => {
				return d.flightDate ? moment(d.flightDate) : moment(d.groundDatasetDate);
			}
		]);

		setAucAssessments([]);

		let aucValues = calculateAucForTreatmentsAndReps(data);
		aucValues = _.sortBy(aucValues, ["trialTreatmentId", "plotReplicate"]);
		aucValues = _.groupBy(aucValues, "trialTreatmentId");
		(axis === null ? selectedAucAxis === "x" : axis === "x")
			? setFilteredXAnalysisResults(aucValues)
			: setFilteredYAnalysisResults(aucValues);

		setSelectedAucAxis("");
	}

	function calculateAucForTreatmentsAndReps(treatmentValues) {
		let grouping = _.groupBy(treatmentValues, (tv) => {
			return `${tv.trialTreatmentId}-${tv.plotReplicate}`;
		});

		return _.map(grouping, (trialReplicates) => {
			let auc = calculateAreaUnderTheCurve(_.filter(trialReplicates, (tr) => !tr.excludeFromAssessment));
			return {
				flightId: trialReplicates[0].flightId,
				trialTreatmentId: trialReplicates[0].trialTreatmentId,
				plotReplicate: trialReplicates[0].plotReplicate,
				range: trialReplicates[0].plotRange,
				groundDatasetAssessmentValue: auc,
				standCountAnalysisResultValue: auc,
				plotAnalysisResultValue: auc,
				excludeFromAssessment: _.some(trialReplicates, (tr) => tr.excludeFromAssessment)
			};
		});
	}

	function calculateAreaUnderTheCurve(treatmentData) {
		let areaUnderTheCurve = 0;
		let compoundedArea = 0;
		if (treatmentData.length < 2) {
			return areaUnderTheCurve;
		}
		_.map(treatmentData, (treatment, index) => {
			if (index !== treatmentData.length - 1) {
				const base = treatment.plotAnalysisResultValue + treatmentData[index + 1].plotAnalysisResultValue;
				const currentTreatmentDate = moment(treatment.flightDate).startOf("day");
				const nextTreatmentDate = moment(treatmentData[index + 1].flightDate).startOf("day");
				const height = nextTreatmentDate.diff(currentTreatmentDate, "days");
				compoundedArea += (1 / 2) * height * base;
			}
		});

		areaUnderTheCurve = compoundedArea;
		return areaUnderTheCurve;
	}

	return loading ? (
		<Loader active inline="centered" />
	) : analysisResults.length === 0 ? (
		<Segment>
			<p>{"We could not find analysis data for this trial."}</p>
		</Segment>
	) : (
		<div>
			<Grid>
				<Grid.Row>
					<Grid.Column width="4">
						<label htmlFor="dropdown-select-x-values" style={{ display: "unset" }}>
							X-Values
						</label>
						<Dropdown
							id="dropdown-select-x-values"
							selection
							fluid
							options={analysisOptions}
							onChange={(event, { value }) => {
								setSelectedXAssessmentOptionIsAuc(value.startsWith("auc-"));
								setSelectedXAssessmentOptionIsGrowthCurve(value.includes("_"));
								setSelectedXAssessmentOption(value);
							}}
							value={selectedXAssessmentOption}
						/>
					</Grid.Column>
					{!selectedXAssessmentOptionIsGrowthCurve && (
						<Grid.Column width="4">
							<label htmlFor="dropdown-select-flight" style={{ display: "unset" }}>
								{selectedXAssessmentOptionIsAuc ? "Assessment Start Date" : "Assessment Date"}
							</label>
							<Dropdown
								id="dropdown-select-flight"
								width="1"
								selection
								fluid
								options={filteredXFlightOptions}
								onChange={(event, { value }) => {
									setSelectedXFlightOption(value);
								}}
								value={selectedXFlightOption}
							/>
						</Grid.Column>
					)}
					{selectedXAssessmentOptionIsAuc && (
						<>
							<Grid.Column width="4">
								<label htmlFor="dropdown-select-flight" style={{ display: "unset" }}>
									Assessment End Date
								</label>
								<Dropdown
									id="dropdown-select-flight"
									width="1"
									selection
									fluid
									options={filteredXAucFlightOptions}
									onChange={(event, { value }) => {
										setSelectedXAucFlightOption(value);
									}}
									value={selectedXAucFlightOption}
								/>
							</Grid.Column>
						</>
					)}
					{rValue !== "" && rValue === rValue && (
						<Grid.Column width="2" style={{ top: "110px", zIndex: "1000" }} floated="right">
							<h2>r: {`${rValue.toFixed(4)}`}</h2> {/* &#178; */}
						</Grid.Column>
					)}
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="4">
						<label htmlFor="dropdown-select-y-values" style={{ display: "unset" }}>
							Y-Values
						</label>
						<Dropdown
							id="dropdown-select-y-values"
							selection
							fluid
							options={analysisOptions}
							onChange={(event, { value }) => {
								setSelectedYAssessmentOptionIsAuc(value.startsWith("auc-"));
								setSelectedYAssessmentOptionIsGrowthCurve(value.includes("_")); //checking for growth curve
								setSelectedYAssessmentOption(value);
							}}
							value={selectedYAssessmentOption}
						/>
					</Grid.Column>
					{!selectedYAssessmentOptionIsGrowthCurve && (
						<Grid.Column width="4">
							<label htmlFor="dropdown-select-flight" style={{ display: "unset" }}>
								{selectedYAssessmentOptionIsAuc ? "Assessment Start Date" : "Assessment Date"}
							</label>
							<Dropdown
								id="dropdown-select-flight"
								width="1"
								selection
								fluid
								options={filteredYFlightOptions}
								onChange={(event, { value }) => {
									setSelectedYFlightOption(value);
								}}
								value={selectedYFlightOption}
							/>
						</Grid.Column>
					)}
					{selectedYAssessmentOptionIsAuc && (
						<>
							<Grid.Column width="4">
								<label htmlFor="dropdown-select-flight" style={{ display: "unset" }}>
									Assessment End Date
								</label>
								<Dropdown
									id="dropdown-select-flight"
									width="1"
									selection
									fluid
									options={filteredYAucFlightOptions}
									onChange={(event, { value }) => {
										setSelectedYAucFlightOption(value);
									}}
									value={selectedYAucFlightOption}
								/>
							</Grid.Column>
						</>
					)}
				</Grid.Row>
			</Grid>

			<Segment loading={chartLoading}>
				<CanvasJSChart key={selectedXAssessmentOption} options={chartOptions} />
			</Segment>
		</div>
	);
};

export default CorrelationPlots;
