import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Form } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";

import * as userActions from "../../../../redux/actions/userActions";

const PersonManagement = () => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Data Source
	const [persons, setPersons] = useState(null);
	const [selectedPerson, setSelectedPerson] = useState(null);
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Dropdown Options
	const [personOptions, setPersonOptions] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (clientId) {
			getPersons(true);
		}

		setMode("update");
	}, [clientId]);

	async function getPersons(selectFirstPerson = true) {
		const accessToken = await getTokenSilently();
		dispatch(userActions.getPersonsByClient(accessToken, clientId)).then((res) => {
			setPersons(res.data);
			populatePersonOptions(res.data, selectFirstPerson);
		});
	}

	function populatePersonOptions(pOpts, selectFirstPerson) {
		const perOpts = _.map(pOpts, ({ personId, firstName, lastName, emailAddress }) => {
			const opt = {
				key: personId,
				value: personId,
				text: firstName + " " + lastName,
				emailAddress
			};
			return opt;
		});

		if (perOpts.length > 0 && selectFirstPerson) {
			getPersonDetail(perOpts[0].value, pOpts);
		}

		setPersonOptions(perOpts);
	}

	function getPersonDetail(personId, listOfPersons) {
		const personOpt = listOfPersons ?? persons;
		const per = _.find(personOpt, (p) => {
			return p.personId === personId;
		});
		setSelectedPerson(per);
		setMode("update");
	}

	function prepareNewPersonForm() {
		setSelectedPerson(null);
		setMode("create");
	}

	async function savePerson() {
		if (selectedPerson?.systemCreated) {
			toast.warn("The selected person is a system created person and cannot be edited.");
		} else {
			setSaving(true);
			const accessToken = await getTokenSilently();
			if (mode === "create") {
				dispatch(userActions.createNewPerson(accessToken, selectedPerson, clientId))
					.then((res) => {
						if (res.statusCode === 200) {
							getPersons(true);
							toast.success("Created!");
						} else if (res.statusCode === 400) {
							if (res.messages && res.messages.length > 0) {
								res.messages.forEach((m) => {
									if (m.type === "Informational") {
										setSelectedPerson(res.data);
										toast.info(m.text);
									} else if (m.type === "FailedValidation") {
										toast.error(m.text);
									}
								});
							}
						}
						setSaving(false);
					})
					.catch((err) => {
						toast.error("Unable to create new user. Please try again.");
						setSaving(false);
						console.log(err);
					});
			} else if (mode === "update") {
				dispatch(userActions.updatePerson(accessToken, selectedPerson, clientId))
					.then((res) => {
						if (res.statusCode === 200) {
							getPersons(false);
							toast.success("Saved!");
						} else if (res.statusCode === 400) {
							if (res.messages && res.messages.length > 0) {
								res.messages.forEach((m) => {
									if (m.type === "Informational") {
										toast.info(m.text);
									} else if (m.type === "FailedValidation") {
										toast.error(m.text);
									}
								});
							}
						}
						setSaving(false);
					})
					.catch((err) => {
						console.log(err);
						setSaving(false);
						toast.error("Unable to save user. Please try again.");
					});
			}
		}
	}

	async function deletePerson() {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(userActions.deletePerson(accessToken, selectedPerson.personId, clientId))
			.then(() => {
				getPersons(true);
				setDeleting(false);
			})
			.catch((err) => {
				setDeleting(false);
				console.log(err);
				toast.error("Failed to delete person.");
			});
	}

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Person Management</h2>
			<hr style={{ clear: "both" }} />
			<Form>
				<Grid id="user-management-grid" columns="equal">
					<Grid.Row>
						<Grid.Column width={4}>
							<label htmlFor="form-select-person" className="fieldLabel">
								Persons
							</label>
							<Form.Button
								id="new-user"
								color="green"
								content="New Person"
								style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
								onClick={() => {
									prepareNewPersonForm();
								}}
							/>
							<List
								id="form-select-person"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 500,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(personOptions, ({ key, value, text, emailAddress }) => {
									return (
										<List.Item
											key={key}
											active={selectedPerson?.personId === value}
											onClick={() => {
												getPersonDetail(value);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{text}</span>
												{emailAddress && <span style={{ float: "right", fontSize: 12 }}>({emailAddress})</span>}
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-first-name"
									width={6}
									label="First Name"
									value={selectedPerson?.firstName ?? ""}
									required
									onChange={(event) => setSelectedPerson({ ...selectedPerson, firstName: event.target.value })}
									readOnly={selectedPerson?.systemCreated}
								/>
								<Form.Input
									id="form-person-last-name"
									width={6}
									label="Last Name"
									value={selectedPerson?.lastName ?? ""}
									required
									onChange={(event) => setSelectedPerson({ ...selectedPerson, lastName: event.target.value })}
									readOnly={selectedPerson?.systemCreated}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-email-address"
									width={6}
									label="Email Address"
									value={selectedPerson?.emailAddress ?? ""}
									onChange={(event) => setSelectedPerson({ ...selectedPerson, emailAddress: event.target.value })}
									readOnly={selectedPerson?.systemCreated}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-phone"
									width={6}
									label="Phone"
									value={selectedPerson?.phone ?? ""}
									onChange={(event) => setSelectedPerson({ ...selectedPerson, phone: event.target.value })}
									readOnly={selectedPerson?.systemCreated}
								/>
								<Form.Input
									id="form-person-phone-ext"
									width={6}
									label="Phone Ext."
									value={selectedPerson?.phoneExt ?? ""}
									onChange={(event) => setSelectedPerson({ ...selectedPerson, phoneExt: event.target.value })}
									readOnly={selectedPerson?.systemCreated}
								/>
							</Form.Group>
							<hr style={{ clear: "both" }} />
							<Form.Group>
								<Form.Checkbox
									id="form-person-enabled"
									width={3}
									label="Person Enabled?"
									style={{ marginTop: 33 }}
									checked={selectedPerson?.personEnabled ?? false}
									onChange={(event, data) => {
										setSelectedPerson({ ...selectedPerson, personEnabled: data.checked });
									}}
									readOnly={selectedPerson?.systemCreated}
								/>
								<Form.Checkbox
									id="form-is-user"
									width={3}
									label="Is User?"
									style={{ marginTop: 33 }}
									checked={selectedPerson?.userId ? true : false}
									readOnly
								/>
								<Form.Checkbox
									id="form-user-enabled"
									width={3}
									label="User Enabled?"
									style={{ marginTop: 33 }}
									checked={selectedPerson?.userEnabled ?? false}
									readOnly
								/>
							</Form.Group>
							<Form.Group style={{ marginTop: 50, float: "right" }}>
								{mode === "update" && selectedPerson?.canDelete && !selectedPerson?.systemCreated ? (
									<Form.Button
										floated="right"
										color="red"
										content="Remove"
										loading={deleting}
										disabled={deleting || saving}
										onClick={() => {
											deletePerson();
										}}
									/>
								) : null}
								<Form.Button
									floated="right"
									color="green"
									content="Save"
									loading={saving}
									disabled={saving || deleting}
									onClick={() => {
										savePerson();
									}}
								/>
							</Form.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default PersonManagement;
