import * as types from "./actionTypes";
import * as growingSeasonApi from "../../apis/growingSeasonApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getGrowingSeasonDetailsSuccess() {
	return { type: types.GROWING_SEASON_GET_GROWING_SEASON_DETAILS_SUCCESS };
}

export function createGrowingSeasonSuccess() {
	return { type: types.GROWING_SEASON_CREATE_SUCCESS };
}

export function updateGrowingSeasonSuccess() {
	return { type: types.GROWING_SEASON_UPDATE_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getGrowingSeasonDetails(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return growingSeasonApi
				.getGrowingSeasonDetails(accessToken, clientId)
				.then((res) => {
					dispatch(getGrowingSeasonDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createGrowingSeason(accessToken, season) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return growingSeasonApi
				.createGrowingSeason(accessToken, season)
				.then((res) => {
					dispatch(createGrowingSeasonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateGrowingSeason(accessToken, season) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return growingSeasonApi
				.updateGrowingSeason(accessToken, season)
				.then((res) => {
					dispatch(updateGrowingSeasonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}