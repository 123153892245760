import moment from "moment";
const DATE_FORMAT = "MM/DD/YYYY h:mma";
export function getApplicationOptions(clientId, cropId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/ApplicationOptions?clientId=${clientId}&cropId=${cropId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolApplications(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialApplications(trialId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/GetTrialApplications?trialId=${trialId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

function generateApplicationsData(applications) {
	const applicationsData = new FormData();
	for (let i = 0; i < applications.length; i++) {
		let application = applications[i];

		applicationsData.append(`Applications[${i}].Id`, application.id);
		if (application.protocolId) {
			applicationsData.append(`Applications[${i}].ProtocolId`, application.protocolId);
		} else if (application.trialId) {
			applicationsData.append(`Applications[${i}].TrialId`, application.trialId);
		}
		applicationsData.append(`Applications[${i}].ApplicationTypeId`, application.applicationTypeId);
		applicationsData.append(`Applications[${i}].ApplicationMethodId`, application.applicationMethodId);
		applicationsData.append(`Applications[${i}].ApplicationPlacementId`, application.applicationPlacementId);
		applicationsData.append(`Applications[${i}].GroundDataGrowthPhaseId`, application.groundDataGrowthPhaseId);
		applicationsData.append(`Applications[${i}].GroundDataTimingMethodId`, application.groundDataTimingMethodId);
		applicationsData.append(
			`Applications[${i}].GrowthPhaseText`,
			application.growthPhaseText ? application.growthPhaseText : "" //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${i}].GrowthPhaseInteger`,
			application.growthPhaseInteger ? application.growthPhaseInteger : ""
		);
		applicationsData.append(
			`Applications[${i}].GrowthStageStartStageId`,
			application.growthStageStartStageId ? application.growthStageStartStageId : ""
		);
		applicationsData.append(
			`Applications[${i}].GrowthStageEndStageId`,
			application.growthStageEndStageId ? application.growthStageEndStageId : ""
		);
		applicationsData.append(`Applications[${i}].Code`, application.code);
		applicationsData.append(`Applications[${i}].Notes`, application.notes ? application.notes : "");
		applicationsData.append(
			`Applications[${i}].GrowthPhaseDateUTC`,
			application.growthPhaseDateUTC ? moment(application.growthPhaseDateUTC).format(DATE_FORMAT) : "" //TODO name probably needs changed??
		);
	}
	return applicationsData;
}

export function createProtocolApplications(applications, clientId, accessToken) {
	debugger;
	const applicationsData = generateApplicationsData(applications);

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: applicationsData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

// export function createTrialApplications(applications, clientId, accessToken) {
// 	const applicationsData = generateApplicationsData(applications);

// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/CreateTrialApplications?clientId=${clientId}`,
// 		{
// 			method: "POST",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: applicationsData
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }

// export function updateProtocolApplications(applications, clientId, accessToken) {
// 	const applicationsData = generateApplicationsData(applications);

// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/UpdateProtocolApplications?clientId=${clientId}`,
// 		{
// 			method: "POST",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: applicationsData
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }

// export function updateTrialApplications(applications, clientId, accessToken) {
// 	const applicationsData = generateApplicationsData(applications);

// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/UpdateTrialApplications?clientId=${clientId}`,
// 		{
// 			method: "POST",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: applicationsData
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }

// export function deleteProtocolApplications(applicationIds, protocolId, clientId, accessToken) {
// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/DeleteProtocolApplications?protocolId=${protocolId}&clientId=${clientId}`,
// 		{
// 			method: "DELETE",
// 			headers: {
// 				"Content-Type": "application/json",
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: JSON.stringify(applicationIds)
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }

// export function deleteTrialApplications(applicationIds, trialId, clientId, accessToken) {
// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/DeleteTrialApplications?trialId=${trialId}&clientId=${clientId}`,
// 		{
// 			method: "DELETE",
// 			headers: {
// 				"Content-Type": "application/json",
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: JSON.stringify(applicationIds)
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }

export function saveApplications(applications, protocolId, clientId, accessToken) {
	const applicationsData = new FormData();
	let applicationIndex = 0;
	for (const application of applications) {
		applicationsData.append(`Applications[${applicationIndex}].Id`, application.id);
		applicationsData.append(`Applications[${applicationIndex}].ClientId`, clientId);
		applicationsData.append(`Applications[${applicationIndex}].Code`, application.code);
		applicationsData.append(`Applications[${applicationIndex}].ProtocolId`, application.protocolId);
		applicationsData.append(`Applications[${applicationIndex}].ApplicationTypeId`, application.applicationTypeId);
		applicationsData.append(`Applications[${applicationIndex}].ApplicationMethodId`, application.applicationMethodId);
		applicationsData.append(
			`Applications[${applicationIndex}].GroundDataGrowthPhaseId`,
			application.groundDataGrowthPhaseId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GroundDataTimingMethodId`,
			application.groundDataTimingMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseText`,
			application.growthPhaseText ? application.growthPhaseText : "" //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseDateUTC`,
			application.growthPhaseDateUTC ? moment(application.growthPhaseDateUTC).format(DATE_FORMAT) : "" //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthStageStartStageId`,
			application.growthStageStartStageId ? application.growthStageStartStageId : ""
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthStageEndStageId`,
			application.growthStageEndStageId ? application.growthStageEndStageId : ""
		);
		applicationsData.append(`Applications[${applicationIndex}].Notes`, application.notes ? application.notes : "");
		applicationsData.append(
			`Applications[${applicationIndex}].ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseInteger`,
			application.growthPhaseInteger ? application.growthPhaseInteger : ""
		);
		applicationIndex++;
	}
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: applicationsData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateApplications(applications, clientId, accessToken) {
	const applicationsData = new FormData();
	let applicationIndex = 0;
	const protocolId = applications[0].protocolId;

	for (const application of applications) {
		applicationsData.append(`Applications[${applicationIndex}].Id`, application.id);

		applicationsData.append(`Applications[${applicationIndex}].Application.ClientId`, clientId);
		applicationsData.append(`Applications[${applicationIndex}].Application.ProtocolId`, application.protocolId);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationTypeId`,
			application.applicationTypeId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationMethodId`,
			application.applicationMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GroundDataGrowthPhaseId`,
			application.groundDataGrowthPhaseId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GroundDataTimingMethodId`,
			application.groundDataTimingMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthPhaseText`,
			application.notes //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthStageStartStageId`,
			application.growthStageStartStageId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthStageEndStageId`,
			application.growthStageEndStageId
		);
		applicationsData.append(`Applications[${applicationIndex}].Application.Notes`, application.notes);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationIndex++;
	}
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: applicationsData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

// export function propagateProtocolApplicationsToTrials(applications, protocolId, clientId, accessToken) {
// 	const applicationsData = generateApplicationsData(applications);

// 	return fetch(
// 		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/PropagateProtocolApplicationsToTrials?protocolId=${protocolId}&clientId=${clientId}`,
// 		{
// 			method: "POST",
// 			headers: {
// 				Authorization: `Bearer ${accessToken}`
// 			},
// 			body: applicationsData
// 		}
// 	)
// 		.then((res) => {
// 			return res.json();
// 		})
// 		.then((data) => {
// 			return data;
// 		});
// }
