import React, { useEffect, useState } from "react";
import { Segment, Table, Checkbox, Grid, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

import * as tableShader from "../../../Lumber/TableShader";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";

import _ from "lodash";
import { std } from "mathjs";

const ProtocolSiteSpecificTable = ({
	siteSpecificData,
	updateCheckedTrial,
	appenedPercent,
	disableCheckBoxes,
	tukeyData,
	tukeyDataLoading,
	hideMeanComparisonResults,
	needToRerunNormalization,
	initialGroupTrialDataCheckedCount,
	needToRerunStats,
	allMeanComparisonData,
	uniqueAnalysesCount,
	hideCheckAll
}) => {
	//-- Data Sources
	const [tableHeaderData, setTableHeaderData] = useState(null);
	const [tableData, setTableData] = useState(null);
	const [meanData, setMeanData] = useState(null);
	const [singleSiteTukeyLoading, setSingleSiteTukeyLoading] = useState(false);
	const [hideLetters, setHideLetters] = useState(false);
	const [previousTukeyData, setPreviousTukeyData] = useState(null);
	const [toggleAll, setToggleAll] = useState(true);

	useEffect(() => {
		if (siteSpecificData) {
			setupTableData();
			let uniqueAllGroupedTreatments = _.uniqBy(siteSpecificData, "trialTreatmentId");
			let checkedTreatmentCountUpdated = _.map(uniqueAllGroupedTreatments, (data) => {
				return data.treatmentChecked;
			});

			//Logic to hide the auc letters when treatments are unchecked
			if (
				!_.isEqual(initialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
				initialGroupTrialDataCheckedCount !== undefined &&
				initialGroupTrialDataCheckedCount?.length !== 0
			) {
				setHideLetters(true);
			} else if (initialGroupTrialDataCheckedCount?.length === 0) {
				if (_.some(uniqueAllGroupedTreatments, (data) => !data?.treatmentChecked)) {
					setHideLetters(true);
				} else {
					setHideLetters(false);
				}
			} else {
				setHideLetters(false);
			}
		}
	}, [siteSpecificData]);

	useEffect(() => {
		//-- We want to run this even when length is 0 to clear site specific table cld

		if (tukeyData && tukeyData.length > 0 && !_.isEqual(tukeyData, previousTukeyData)) {
			setSingleSiteTukeyLoading((!tukeyData || tukeyData?.length === 0) && !hideMeanComparisonResults);
			updateTableData();
			setPreviousTukeyData(tukeyData);
		}
	}, [tukeyData]);

	async function updateCheckAll() {
		const toggleValue = toggleAll;
		setToggleAll(!toggleValue);
		return;
	}

	function toggleCheckAll() {
		let tempTableData = _.cloneDeep(tableHeaderData);
		tempTableData.map((trial) => {
			trial.trialChecked = !toggleAll;
			updateCheckedTrial(trial.trialChecked, trial.trialId);
		});
		setTableHeaderData(tempTableData);
	}

	function getTableData() {
		const orderedData = _.filter(
			_.sortBy(siteSpecificData, ["stateName", "city", "trialName", "cooperatorName", "trialId"]),
			(d) => d.average !== null
		);
		const orderedHeaderData = _.uniqBy(orderedData, "trialId");
		setTableHeaderData(orderedHeaderData);
		const groupedTrials = _.groupBy(
			_.filter(orderedData, (d) => _.some(d.plots, (p) => p.plotId)),
			"trialId"
		);
		let averages = calculateMeanAndStandardDeviation(groupedTrials);
		Object.keys(groupedTrials).length > 0 &&
		groupedTrials[Object.keys(groupedTrials)[0]][0]?.assessmentName?.includes("MG50")
			? tableShader.calculateShadingForGroupedTreatmentReversed(groupedTrials, "average", "2, 185, 95")
			: tableShader.calculateShadingForGroupedTreatment(groupedTrials, "average", "2, 185, 95");
		const groupedData = _.groupBy(orderedData, "trialTreatmentId");

		if (!_.isEmpty(tukeyData)) {
			_.map(averages, (umd) => {
				let meanComparisonValue = _.find(tukeyData, (td) => {
					return td.trialId === umd.trialId;
				});

				umd.pValue = meanComparisonValue?.pValue;
				umd.cv = meanComparisonValue?.cv;
				umd.mean = valuePercisionConverter.getPercision(meanComparisonValue?.mean)?.toFixed(2);
			});
		}

		setMeanData(averages);
		return groupedData;
	}

	function setupTableData() {
		const tTableData = getTableData();
		setTableData(tTableData);
	}

	function calculateMeanAndStandardDeviation(groupedTrials) {
		let averages = [];
		_.map(groupedTrials, (gt, i) => {
			let filtered = _.filter(gt, (t) => {
				return t.treatmentChecked;
			});
			const values = _.map(filtered, "average");

			//Use this to filter out null average data. This will update the protocol normalization and box whisker charts properly
			const nonNullValues = _.filter(values, (v) => {
				return v !== null;
			});
			const areAllValsNull = nonNullValues.length === 0;
			const mean = _.meanBy(_.filter(values, (v) => v !== null));
			const standardDeviation =
				isNaN(mean) || areAllValsNull ? "" : (std(_.filter(values, (v) => v !== null)) / mean) * 100;
			const average = {
				average: mean === isNaN(mean) || areAllValsNull ? "" : valuePercisionConverter.getPercision(mean).toFixed(2),
				standardDeviation:
					isNaN(mean) || areAllValsNull ? "" : valuePercisionConverter.getPercision(standardDeviation).toFixed(2),
				trialId: i
			};
			averages.push(average);
		});

		return averages;
	}

	function updateTableData() {
		let updatedTableData = getTableData();

		_.map(updatedTableData, (utd, trialTreatmentId) => {
			_.map(utd, (trialData) => {
				let tukeyLetters = _.find(
					tukeyData,
					(td) =>
						td.trialId === trialData.trialId &&
						Number(td.trialTreatmentId) === Number(trialTreatmentId) &&
						trialData.treatmentChecked
				)?.tukeyLetters;

				trialData.tukeyLetters = tukeyLetters;
			});
		});

		//-- Recreates table to look at single trial
		//---- If all letters per trial are 'a', set them to '-'
		if (updatedTableData) {
			_.map(tableHeaderData, (thd) => {
				let trialData = _.map(updatedTableData, (utd) => {
					const td = _.find(utd, (trialData) => {
						return trialData.trialId === thd.trialId;
					});
					return td;
				});

				trialData = _.filter(trialData, (td) => {
					return !!td && td.treatmentChecked && td.average !== null && !td.excluded;
				});

				let allLettersAreA = _.every(trialData, ["tukeyLetters", "a"]);

				if (allLettersAreA) {
					_.map(trialData, (td) => {
						td.tukeyLetters = "-";
					});
				}
			});
		}

		setTableData(updatedTableData);
	}

	return (
		<Segment basic style={{ width: "fit-content" }}>
			<Table celled fixed striped selectable color="blue" textAlign="center" style={{ width: "fit-content" }}>
				<Table.Header>
					<Table.Row>
						{hideCheckAll ? (
							<Table.HeaderCell className="rowTitle" style={{ width: 130, textAlign: "center" }} />
						) : (
							<Table.HeaderCell className="rowTitle" style={{ width: 130, textAlign: "center" }}>
								<Checkbox
									label="Check All"
									checked={toggleAll}
									disabled={disableCheckBoxes || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
									onClick={() => {
										if (!disableCheckBoxes && allMeanComparisonData?.length === uniqueAnalysesCount?.length) {
											updateCheckAll().then(toggleCheckAll());
										}
									}}
								/>
							</Table.HeaderCell>
						)}
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ width: 152 }}>
									<Checkbox
										checked={thd.trialChecked}
										disabled={disableCheckBoxes || allMeanComparisonData?.length !== uniqueAnalysesCount?.length}
										onChange={() => {
											updateCheckedTrial(!thd.trialChecked, thd.trialId);
										}}
									/>
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell className="rowTitle">Trial Name</Table.HeaderCell>
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
									<span dangerouslySetInnerHTML={{ __html: thd.trialName.replaceAll("_", "_<wbr/>") }} />
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell className="rowTitle">Cooperator Name</Table.HeaderCell>
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
									{thd.cooperatorName}
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell className="rowTitle">Farm Location</Table.HeaderCell>
						{_.map(tableHeaderData, (thd) => {
							return (
								<Table.HeaderCell style={{ fontWeight: "100", background: "white" }} key={thd.trialId}>
									{thd.city}, {thd.stateName}
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (td, index) => {
						return (
							<Table.Row key={index}>
								<Table.Cell className="rowTitle">{td[0]?.trialTreatmentId}</Table.Cell>
								{_.map(tableHeaderData, (thd) => {
									const treatment = _.find(td, { trialId: thd.trialId });
									return treatment ? (
										<Table.Cell
											style={{
												color: needToRerunNormalization
													? ""
													: treatment.treatmentChecked && treatment.trialChecked
													? treatment.textColor
													: "black",
												backgroundColor: needToRerunNormalization
													? ""
													: treatment.treatmentChecked && treatment.trialChecked
													? treatment.color
													: "lightGray",
												width: "150px"
											}}
										>
											<Grid style={{ width: "inherit", height: "inherit" }}>
												<Grid.Row style={{ padding: "inherit" }}>
													<Grid.Column width="1" />
													<Grid.Column width="9" style={{ padding: 0 }}>
														{!treatment.treatmentChecked || needToRerunNormalization
															? ""
															: treatment.excluded === true
															? "-"
															: valuePercisionConverter.getPercision(treatment.average)}
														{!treatment.treatmentChecked || needToRerunNormalization
															? ""
															: appenedPercent && treatment.excluded === false
															? "%"
															: ""}
													</Grid.Column>
													{
														<Grid.Column width="3" style={{ padding: 0 }}>
															{tukeyDataLoading || disableCheckBoxes || singleSiteTukeyLoading ? (
																<Loader active size="tiny" style={{ zIndex: "0" }} />
															) : treatment.excluded === true ||
															  !treatment.treatmentChecked ||
															  needToRerunNormalization ||
															  !treatment.trialChecked ? (
																""
															) : hideLetters || needToRerunStats ? null : (
																treatment.tukeyLetters
															)}
														</Grid.Column>
													}
												</Grid.Row>
											</Grid>
										</Table.Cell>
									) : (
										<Table.Cell style={{ width: "150px" }}>{"-"}</Table.Cell>
									);
								})}
							</Table.Row>
						);
					})}
					<Table.Row>
						<Table.Cell className="rowTitle">Global Mean</Table.Cell>
						{_.map(meanData, (md) => {
							return (
								<Table.Cell>
									{needToRerunNormalization || hideLetters
										? ""
										: valuePercisionConverter.getPercision(md?.mean) ??
										  valuePercisionConverter.getPercision(md?.average)}
									{appenedPercent && !needToRerunNormalization && !hideLetters ? "%" : ""}
								</Table.Cell>
							);
						})}
					</Table.Row>
					<Table.Row>
						<Table.Cell className="rowTitle">CV</Table.Cell>
						{_.map(meanData, (md) => {
							return needToRerunNormalization ||
								hideLetters ||
								tukeyDataLoading ||
								disableCheckBoxes ||
								singleSiteTukeyLoading ? (
								<Table.Cell></Table.Cell>
							) : (
								<Table.Cell>{Math.abs(valuePercisionConverter.getPercision(md?.cv)).toFixed(2)}%</Table.Cell>
							);
						})}
					</Table.Row>
					{
						<Table.Row>
							<Table.Cell className="rowTitle">P-Value</Table.Cell>
							{_.map(meanData, (md) => {
								return (
									<Table.Cell>
										{!hideLetters &&
										!needToRerunNormalization &&
										!needToRerunStats &&
										!tukeyDataLoading &&
										!disableCheckBoxes &&
										!singleSiteTukeyLoading
											? Number(Number(md?.pValue).toFixed(5)) === 0
												? "<0.00001"
												: Number(Number(md?.pValue).toFixed(5))
											: null}
									</Table.Cell>
								);
							})}
						</Table.Row>
					}
				</Table.Body>
			</Table>
		</Segment>
	);
};

ProtocolSiteSpecificTable.propTypes = {
	siteSpecificData: PropTypes.array,
	updateCheckedTrial: PropTypes.func,
	appenedPercent: PropTypes.bool,
	disableCheckBoxes: PropTypes.bool,
	tukeyData: PropTypes.array,
	tukeyDataLoading: PropTypes.bool,
	hideMeanComparisonResults: PropTypes.bool,
	needToRerunNormalization: PropTypes.bool,
	initialGroupTrialDataCheckedCount: PropTypes.array,
	needToRerunStats: PropTypes.bool,
	allMeanComparisonData: PropTypes.array,
	uniqueAnalysesCount: PropTypes.array,
	hideCheckAll: PropTypes.bool
};

export default ProtocolSiteSpecificTable;
