import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Segment, Grid, Loader } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import * as trialActions from "../../../../redux/actions/trialActions";

import FlightsForTrialTable from "./FlightsForTrialTable";
import PubUnpubTrialFlights from "../../../Lumber/PubUnpubTrialFlights";
import FlightsForTrialOwnerTable from "./FlightsForTrialOwnerTable";
import FlightsForTrialSponsorTable from "./FlightsForTrialSponsorTable";
import TrialInfo from "./TrialInfo";
import TrialInfoForTrialSponsor from "./TrialInfoForTrialSponsor";
import TrialInfoForTrialOwner from "./TrialInfoForTrialOwner";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const TrialPublishing = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const userSettings = useUserSettings();

	//-- Trial data
	const [workingTrial, setWorkingTrial] = useState({});
	const [trialFlights, setTrialFlights] = useState([]);
	const [trialName, setTrialName] = useState(null);
	const [trialOwnerEmailAddress, setTrialOwnerEmailAddress] = useState({});
	const [trialOwnerUserId, setTrialOwnerUserId] = useState(null);
	const [trialOwnerClientId, setTrialOwnerClientId] = useState(null);
	const [trialSponsorEmailAddress, setTrialSponsorEmailAddress] = useState(null);
	const [isTrialOwnerOrTrialSponsor, setIsTrialOwnerOrTrialSponsor] = useState(false);
	const [isTrialOwner, setIsTrialOwner] = useState(false);
	const [isTrialSponsor, setIsTrialSponsor] = useState(false);
	const [flightScheduleOptions, setFlightScheduleOptions] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(false);

	useEffect(() => {
		if (userAuth.currentClientId && moduleNavigation.trialId) {
			getTrialData(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	async function getTrialData(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getTrialPublishingData(clientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				if (res) {
					setTrialData(res.trialData);
					setTrialFlights(res.trialFlights.concat(res.doNotPublishTrialFlights));
					setFlightScheduleOptions(res.flightScheduleOptions);
					setLoading(false);
					setRefreshing(false);
				}
			})
			.catch((err) => {
				console.log("Error loading trial data");
				console.log(err);
				setLoading(false);
				setRefreshing(false);
			});
	}

	const setTrialData = (trialData) => {
		const td = {
			clientId: trialData.trialInfo.clientId,
			id: trialData.trialInfo.trialId,
			name: trialData.trialInfo.trialName,
			companyId: trialData.trialInfo.trialOwnerCompanyId,
			company: trialData.trialInfo.trialOwnerCompanyName,
			trialOwnerCompanyName: trialData.trialInfo.trialOwnerCompanyName,
			trialOwnerClientId: trialData.trialInfo.trialOwnerClientId,
			trialOwnerClientName: trialData.trialInfo.trialOwnerClientName,
			trialOwnerPersonId: trialData.trialInfo.trialOwnerPersonId,
			trialOwnerIsPending: trialData.trialInfo.trialOwnerIsPending,
			trialOwner: trialData.trialInfo.trialOwnerPersonId
				? `${trialData.trialInfo.trialOwnerFirstName} ${trialData.trialInfo.trialOwnerLastName}`
				: "N/A",
			trialOwnerEmail: trialData.trialInfo.trialOwnerEmailAddress,
			trialSponsorCompanyName: trialData.trialInfo.trialSponsorCompanyName ?? "N/A",
			trialSponsorClientId: trialData.trialInfo.trialSponsorClientId,
			trialSponsorClientName: trialData.trialInfo.trialSponsorClientName,
			trialSponsorPersonId: trialData.trialInfo.trialSponsorPersonId,
			trialSponsorIsPending: trialData.trialInfo.trialSponsorIsPending,
			trialSponsor: trialData.trialInfo.trialSponsorPersonId
				? `${trialData.trialInfo.trialSponsorFirstName} ${trialData.trialInfo.trialSponsorLastName}`
				: "N/A",
			trialSponsorEmail: trialData.trialInfo.trialSponsorEmailAddress,
			fieldResearcher: trialData.trialInfo.fieldResearcher,
			location: trialData.trialInfo.farmName,
			gpsCoordinates:
				trialData.trialInfo.plot101Lat && trialData.trialInfo.plot101Long
					? `${trialData.trialInfo.plot101Lat}, ${trialData.trialInfo.plot101Long}`
					: ``,
			plantDate: trialData.trialInfo.plantDate ? new Date(trialData.trialInfo.plantDate) : null,
			harvestDate: trialData.trialInfo.harvestDate ? new Date(trialData.trialInfo.harvestDate) : null,
			treatments: trialData.treatments,
			repCount: trialData.trialInfo.repCount,
			notes: trialData.trialInfo.notes ? trialData.trialInfo.notes : "",
			cooperatorName: trialData.trialInfo.cooperatorName,
			city: trialData.trialInfo.farmCity,
			state: trialData.trialInfo.farmState,
			flightScheduleId: trialData.trialInfo.flightScheduleId
		};

		setTrialOwnerEmailAddress(td.trialOwnerEmail);
		setTrialSponsorEmailAddress(td.trialSponsorEmail);

		setTrialName(td.name);
		setWorkingTrial(td);
		setTrialOwnerUserId(trialData.trialInfo.trialOwnerUserId);
		setTrialOwnerClientId(trialData.trialInfo.trialOwnerClientId);

		setIsTrialOwnerOrTrialSponsor(
			trialData.trialInfo.trialOwnerClientId === userAuth.currentClientId ||
			trialData.trialInfo.trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
			trialData.trialInfo.trialSponsorClientId === userAuth.currentClientId ||
			trialData.trialInfo.trialSponsorUserId === userAuth.user.userInfo.basic.userId
		);

		setIsTrialOwner(
			trialData.trialInfo.trialOwnerClientId === userAuth.currentClientId ||
			trialData.trialInfo.trialOwnerUserId === userAuth.user.userInfo.basic.userId
		);

		setIsTrialSponsor(
			trialData.trialInfo.trialSponsorClientId === userAuth.currentClientId ||
			trialData.trialInfo.trialSponsorUserId === userAuth.user.userInfo.basic.userId
		);
	};

	function handlePublishUnpublishSave() {
		setRefreshing(true);
		getTrialData(userAuth.currentClientId);
	}

	return loading ? (
		<Loader active />
	) : !loading && !trialName ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Publishing</h2>
			<hr />
			<Segment>
				<p>{"This trial has not been flown or could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Publishing</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
				></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{`${trialName} (${workingTrial.cooperatorName}, ${workingTrial.city}, ${workingTrial.state})`}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			{isTrialSponsor ? (
				<TrialInfoForTrialSponsor
					workingTrial={workingTrial}
					trialOwnerEmailAddress={trialOwnerEmailAddress}
					trialSponsorEmailAddress={trialSponsorEmailAddress}
					isTrialOwnerOrTrialSponsor={isTrialOwnerOrTrialSponsor}
					isTrialOwner={isTrialOwner}
				/>
			) : isTrialOwner ? (
				<TrialInfoForTrialOwner
					workingTrial={workingTrial}
					trialOwnerEmailAddress={trialOwnerEmailAddress}
					trialSponsorEmailAddress={trialSponsorEmailAddress}
					isTrialOwnerOrTrialSponsor={isTrialOwnerOrTrialSponsor}
					isTrialOwner={isTrialOwner}
				/>
			) : (
				<TrialInfo
					workingTrial={workingTrial}
					trialOwnerEmailAddress={trialOwnerEmailAddress}
					trialSponsorEmailAddress={trialSponsorEmailAddress}
					isTrialOwnerOrTrialSponsor={isTrialOwnerOrTrialSponsor}
					isTrialOwner={isTrialOwner}
				/>
			)}

			<Grid>
				<Grid.Row textAlign="right">
					<Grid.Column>
						{refreshing && <Loader active />}
						{!refreshing && (
							<>
								{workingTrial.clientId === userAuth.currentClientId ||
									trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
									(trialOwnerClientId === userAuth.currentClientId &&
										userAuth.hasApplicationArea(ApplicationArea.TrialPublishing, userAuth.currentClientId)) ? (
									<PubUnpubTrialFlights
										trialFlights={trialFlights}
										isTrialOwner={
											trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
											trialOwnerClientId === userAuth.currentClientId
										}
										handlePublishUnpublishSave={handlePublishUnpublishSave}
										trialUserName={isTrialOwner ? workingTrial.trialSponsor : workingTrial.trialOwner}
										isPending={isTrialOwner ? workingTrial.trialSponsorIsPending : workingTrial.trialOwnerIsPending}
										flightScheduleId={workingTrial.flightScheduleId}
										flightScheduleOptions={flightScheduleOptions}
										cooperatorName={workingTrial.cooperatorName}
										city={workingTrial.city}
										state={workingTrial.state}
										trialName={workingTrial.name}
									/>
								) : null}

								{isTrialSponsor ? (
									<FlightsForTrialSponsorTable trialFlights={trialFlights} />
								) : isTrialOwner ? (
									<FlightsForTrialOwnerTable trialFlights={trialFlights} />
								) : (
									<FlightsForTrialTable trialFlights={trialFlights} />
								)}
							</>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default TrialPublishing;
