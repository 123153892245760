export function getCompanyManagementInfo(clientId, accessToken) {
	return fetch(
		process.env.aerialPlotConnections.aerialPlotApi.baseUrl +
			`api/v1/Companies/GetCompanyManagementInfo?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createCompany(company, accessToken) {
	company.name = company.name?.trim();
	company.companyCode = company.companyCode?.trim();

	return fetch(process.env.aerialPlotConnections.aerialPlotApi.baseUrl + `api/v1/Companies`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(company)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function editCompany(company, accessToken) {
	company.name = company.name?.trim();
	company.companyCode = company.companyCode?.trim();

	return fetch(process.env.aerialPlotConnections.aerialPlotApi.baseUrl + `api/v1/Companies`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(company)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteCompany(companyId, clientId, accessToken) {
	return fetch(
		process.env.aerialPlotConnections.aerialPlotApi.baseUrl +
			`api/v1/Companies?companyId=${companyId}&clientId=${clientId}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function generateCompanyCode(clientId, companyName, accessToken) {
	return fetch(
		process.env.aerialPlotConnections.aerialPlotApi.baseUrl +
			`api/v1/Companies/GenerateCompanyCode?clientId=${clientId}&companyName=${companyName}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getCooperatorCompanies(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Companies/GetCooperatorCompanies?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getCompanyTypes(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Companies/GetCompanyTypes?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}
