import * as types from "./actionTypes";
import * as userApi from "../../apis/userApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function userLogInSuccess(userInfo) {
	return { type: types.USER_LOG_IN_SUCCESS, userInfo };
}

export function getPersonsSuccess() {
	return { type: types.USER_GET_PERSONS_SUCCESS };
}

export function getPersonsByClientSuccess() {
	return { type: types.USER_GET_PERSONS_BY_CLIENT_SUCCESS };
}

export function getPersonDetailSuccess() {
	return { type: types.USER_GET_PERSON_DETAIL_SUCCESS };
}

export function updatePersonSuccess() {
	return { type: types.USER_UPDATE_PERSON_SUCCESS };
}

export function updatePersonUserSuccess() {
	return { type: types.USER_UPDATE_PERSON_USER_SUCCESS };
}

export function createUserForPersonSuccess() {
	return { type: types.USER_CREATE_USER_FOR_PERSON_SUCCESS };
}

export function createNewPersonSuccess() {
	return { type: types.USER_CREATE_NEW_PERSON_SUCCESS };
}

export function createNewUserSuccess() {
	return { type: types.USER_CREATE_NEW_USER_SUCCESS };
}

export function deletePersonSuccess() {
	return { type: types.DELETE_PERSON_SUCCESS };
}

export function emailLoginInstructionsSuccess() {
	return { type: types.USER_EMAIL_LOGIN_INSTRUCTIONS_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function logIn(accessToken) {
	return function (dispatch) {
		dispatch(beginApiCall());

		return userApi
			.logIn(accessToken)
			.then((userInfo) => {
				dispatch(userLogInSuccess(userInfo));
			})
			.catch((error) => {
				dispatch(apiCallError(error));
				throw error;
			});
	};
}

export function getPersons(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.getPersons(accessToken)
				.then((res) => {
					dispatch(getPersonsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPersonsByClient(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.getPersonsByClient(accessToken, clientId)
				.then((res) => {
					dispatch(getPersonsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPersonDetail(accessToken, personId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.getPersonDetail(accessToken, personId)
				.then((res) => {
					dispatch(getPersonDetailSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updatePerson(accessToken, person, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.updatePerson(accessToken, person, clientId)
				.then((res) => {
					dispatch(updatePersonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updatePersonUser(accessToken, personUserObject) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.updatePersonUser(accessToken, personUserObject)
				.then((res) => {
					dispatch(updatePersonUserSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createUserForPerson(accessToken, personId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.createUserForPerson(accessToken, personId)
				.then((res) => {
					dispatch(createUserForPersonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createNewPerson(accessToken, person, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.createNewPerson(accessToken, person, clientId)
				.then((res) => {
					dispatch(createNewPersonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createNewUser(accessToken, personObj) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.createNewUser(accessToken, personObj)
				.then((res) => {
					dispatch(createNewUserSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deletePerson(accessToken, personId, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.deletePerson(accessToken, personId, clientId)
				.then((res) => {
					dispatch(deletePersonSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function emailLoginInstructions(accessToken, userId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userApi
				.emailLoginInstructions(accessToken, userId)
				.then((res) => {
					dispatch(emailLoginInstructionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
