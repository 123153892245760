import React from "react";
import PropTypes from "prop-types";
//import { Checkbox, Segment, Header, Dropdown } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const DataAnalysisCriteria = ({
	removeDamagedOrExcluded,
	// removeOutliers,
	// outlierType,
	setRemoveDamagedOrExcluded,
	// setRemoveOutliers,
	// setOutlierType,
	setNeedToUpdateAnalytics,
	disabled = false
}) => {
	/*const outlierOptions = [
		{
			key: 'Extreme',
			value: 3,
			text: 'Extreme (3x IQR)'
		},
		{
			key: 'Mild',
			value: 1.5,
			text: 'Mild (1.5x IQR)'
		}
	]*/

	const handleRemoveDamageOrExcluded = () => {
		setRemoveDamagedOrExcluded(!removeDamagedOrExcluded);
		setNeedToUpdateAnalytics(true);
	};

	/*
	const handleRemoveOutliers = () => {
		setRemoveOutliers(!removeOutliers);
		setNeedToUpdateAnalytics(true);
	}*/

	/*
	const handleOutlierType = (e, {value}) => {
		setOutlierType(value);
		if(removeOutliers){
			setNeedToUpdateAnalytics(true);
		}	
	}
	*/

	return (
		<>
			{/*<Segment>
				<Header>Data Analysis Criteria</Header>
				<Checkbox label='Remove Damaged/Excluded Regions' defaultChecked onChange={handleRemoveDamageOrExcluded}></Checkbox><br/>
				<Checkbox label='Remove Outliers' onChange={handleRemoveOutliers} disabled></Checkbox>&nbsp;
				<Dropdown options={outlierOptions} value={outlierType} onChange={handleOutlierType} disabled selection></Dropdown>
	</Segment>*/}
			<Checkbox toggle defaultChecked disabled={disabled} onChange={handleRemoveDamageOrExcluded}></Checkbox>
		</>
	);
};

DataAnalysisCriteria.propTypes = {
	removeDamagedOrExcluded: PropTypes.bool,
	// removeOutliers: PropTypes.bool,
	// outlierType: PropTypes.number,
	setRemoveDamagedOrExcluded: PropTypes.func,
	// setRemoveOutliers: PropTypes.func,
	// setOutlierType: PropTypes.func,
	setNeedToUpdateAnalytics: PropTypes.func,
	disabled: PropTypes.bool
};

export default DataAnalysisCriteria;
