import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import CanvasJSReact from "../../ThirdParty/CanvasJS/canvasjs.react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Handle, SliderRail, Track, Tick } from "./sliderComponents";

const Histogram = ({
	buckets = [],
	min = 0,
	max = 255,
	count = 256,
	skipLow = false,
	skipHigh = false,
	slider = false,
	ticks = false,
	onSliderChange = () => {},
	onSliderUpdate = () => {},
	rootStyle = {},
	sliderValues = [0, 255]
}) => {
	const CanvasJSChart = CanvasJSReact.CanvasJSChart;

	if (buckets.length === count) {
		if (skipLow) {
			buckets[min] = 0;
		}

		if (skipHigh) {
			buckets[max] = 0;
		}
	}

	const data = _.map(buckets, (v, i) => ({ x: i, y: v }));
	const splitData = [[], [], []];
	splitData[0] = _.filter(data, (_v, i) => i <= sliderValues[0]);
	splitData[1] = _.filter(data, (_v, i) => i >= sliderValues[0] && i <= sliderValues[1]);
	splitData[2] = _.filter(data, (_v, i) => i >= sliderValues[1]);

	const sliderStyle = {
		position: "relative",
		bottom: 8,
		width: "calc(100% - 20px)",
		margin: "auto"
	};

	const chartOptions = {
		backgroundColor: "transparent",
		toolTip: {
			enabled: false
		},
		axisY: {
			minimum: 0,
			maximum: Math.max(...buckets),
			gridThickness: 0,
			tickLength: 0,
			lineThickness: 0,
			labelFormatter: () => {
				return " ";
			}
		},
		axisX: {
			minimum: min,
			maximum: max,
			tickLength: 0,
			labelFormatter: () => {
				return " ";
			}
		},
		data: [
			{
				type: "splineArea",
				color: "#CCCCCC",
				lineColor: "#CCCCCC",
				fillOpacity: 1,
				markerSize: 0,
				mouseover: () => {},
				highlightEnabled: false,
				dataPoints: splitData[0]
			},
			{
				type: "splineArea",
				color: "#333333",
				lineColor: "#333333",
				fillOpacity: 1,
				markerSize: 0,
				mouseover: () => {},
				highlightEnabled: false,
				dataPoints: splitData[1]
			},
			{
				type: "splineArea",
				color: "#CCCCCC",
				lineColor: "#CCCCCC",
				fillOpacity: 1,
				markerSize: 0,
				mouseover: () => {},
				highlightEnabled: false,
				dataPoints: splitData[2]
			}
		]
	};

	return (
		<div style={{ paddingBottom: ticks && 20, ...rootStyle }}>
			{buckets.length > 0 && (
				<CanvasJSChart options={chartOptions} containerProps={{ height: "inherit", width: "inherit" }} />
			)}
			{slider && (
				<Slider
					mode={2}
					step={1}
					domain={[min, max]}
					rootStyle={sliderStyle}
					onChange={(v) => {
						onSliderChange(v);
					}}
					onUpdate={(v) => {
						onSliderUpdate(v);
					}}
					values={sliderValues}
				>
					<Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
					<Handles>
						{({ handles, getHandleProps }) => (
							<div className="slider-handles">
								{handles.map((handle) => (
									<Handle key={handle.id} handle={handle} domain={[min, max]} getHandleProps={getHandleProps} />
								))}
							</div>
						)}
					</Handles>
					<Tracks left={false} right={false}>
						{({ tracks, getTrackProps }) => (
							<div className="slider-tracks">
								{tracks.map(({ id, source, target }) => (
									<Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
								))}
							</div>
						)}
					</Tracks>
					{ticks && (
						<Ticks values={Array.from({ length: 18 }, (_, i) => i * 15)}>
							{({ ticks }) => (
								<div className="slider-ticks">
									{ticks.map((tick) => (
										<Tick key={tick.id} tick={tick} count={ticks.length} />
									))}
								</div>
							)}
						</Ticks>
					)}
				</Slider>
			)}
		</div>
	);
};

Histogram.propTypes = {
	buckets: PropTypes.array.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	count: PropTypes.number,
	skipLow: PropTypes.bool,
	skipHigh: PropTypes.bool,
	slider: PropTypes.bool,
	ticks: PropTypes.bool,
	onSliderChange: PropTypes.func,
	onSliderUpdate: PropTypes.func,
	rootStyle: PropTypes.object,
	sliderValues: PropTypes.array
};

export default Histogram;
