import _ from "lodash";


	export function removeOutliers(values, outlierType){
		const median = Number(medianBy(values).toFixed(5));
		const lowerValues = getValuesAboveOrBelowMedian(values, median, false);
		const higherValues = getValuesAboveOrBelowMedian(values, median, true);
		const q1 = lowerValues.length === 0 ? median : Number(medianBy(lowerValues).toFixed(5));
		const q3 = higherValues.length === 0 ? median : Number(medianBy(higherValues).toFixed(5));
		const interquartileRange = q3 - q1;
		const lowerOutlierThreshold = q1 - outlierType * interquartileRange;
		const upperOutlierThreshold = q3 + outlierType * interquartileRange;

		let outliers = [];
			_.map(values, (value) => {
				if (value < lowerOutlierThreshold || value > upperOutlierThreshold) {
					outliers.push(value);
				}
			});

			return outliers;
		}

		export function getMedian(values){
			return Number(medianBy(values).toFixed(5));
		}

		export function getQ1(values){
			const median = getMedian(values);
			const lowerValues = getValuesAboveOrBelowMedian(values, median, false);

			return lowerValues.length === 0 ? median : Number(medianBy(lowerValues).toFixed(5));
		}

		export function getQ3(values){
			const median = getMedian(values);
			const higherValues = getValuesAboveOrBelowMedian(values, median, true);

			return higherValues.length === 0 ? median : Number(medianBy(higherValues).toFixed(5));
		}

		function getValuesAboveOrBelowMedian(values, median, aboveMedian) {
			if (values.length === 0) return 0;
	
			values.sort(function (a, b) {
				return a - b;
			});
	
			var half = Math.floor(values.length / 2);
	
			if (values.length % 2) {
				return aboveMedian ? values.slice(half + 1, values.length) : values.slice(0, half);
			}
	
			let filteredValues = aboveMedian
				? _.filter(values, (value) => {
						return value > median;
					})
				: _.filter(values, (value) => {
						return value < median;
					});
	
			filteredValues = _.filter(filteredValues, (fv) => {
				return fv != null;
			});
	
			return filteredValues;
		}

		function medianBy(array) {
			array.sort((a, b) => b - a);
			const length = array.length;
			if (length % 2 == 0) {
				return (array[length / 2] + array[length / 2 - 1]) / 2;
			} else {
				return array[Math.floor(length / 2)];
			}
		}
