import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points.js";

const OverrideSimpleSelect = MapboxDraw.modes.simple_select;

const _onDrag = OverrideSimpleSelect.onDrag;

OverrideSimpleSelect.onDrag = function (state, e) {
	const result = _onDrag.apply(this, [state, e]);

	this.map.fire("draw.liveUpdate", {});

	return result;
};

OverrideSimpleSelect.toDisplayFeatures = function (state, geojson, display) {
	geojson.properties.active = this.isSelected(geojson.properties.id)
		? Constants.activeStates.ACTIVE
		: Constants.activeStates.INACTIVE;
	display(geojson);
	this.fireActionable();
	if (
		geojson.properties.active !== Constants.activeStates.ACTIVE ||
		geojson.geometry.type === Constants.geojsonTypes.POINT
	)
		return;
	if (geojson.properties?.user_meta === "drawn_feature") {
		createSupplementaryPoints(geojson).forEach(display);
	}
};

export default OverrideSimpleSelect;
