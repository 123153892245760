import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import _ from "lodash";
import MapboxGl from "mapbox-gl";
import moment from "moment";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Checkbox, Divider, Grid, Icon, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";
import * as fieldActions from "../../../redux/actions/fieldActions.js";
import * as trialActions from "../../../redux/actions/trialActions.js";
import { ApplicationArea } from "../ApplicationAreas";
import AddFieldModal from "./AddFieldModal.js";
import OrthoTimeline from "./OrthoTimeline";
import ViewerDataExportModal from "./ViewerDataExportModal";
import AddExistingFieldsModal from "./addExistingFieldsModal";
import AlignPlotsModal from "./alignPlotsModal.js";
import EditFieldModal from "./editFieldModal.js";
import HeatmapTimeline from "./heatmapTimeline";
import OrthoAnalyzedModal from "./orthoAnalyzedModal.js";
import {
	AddField,
	AlignPlots,
	ApplyShader,
	DEFMarkerToggle,
	FieldBoundaryToggle,
	FieldLabelToggle,
	FieldPivotLlSet,
	FieldPivotToggle,
	FitToHeight,
	FitToWidth,
	FlagPlots,
	HeatmapOverlayLabels,
	LockToggle,
	LowerLeftToggle,
	MapboxStyleToggle,
	MeasureDistance,
	PlotLabelToggle,
	PlotToggle,
	QuantifiedRegionToggle,
	ResetOrientation,
	Rotate,
	RotateLeft,
	//ViewerDataExportButton,
	RotateRight,
	RowToggle,
	ScaleInward,
	ScaleOutward,
	ShaderHistogram,
	TrialLabelToggle,
	TrialToggle
} from "./orthoControlComponents.js";
import * as ControlFunctions from "./orthoControlFunctions.js";
import OrthoDropdownParentComponent from "./orthoDropdownParentComponent.js";
import { styles } from "./orthoStyles.js";
import OverrideSimpleSelect from "./overrideSimpleSelect";
import "./styles.css";
import NewFieldMode from "./DrawModes/NewFieldMode.js";
import LineMeasurementMode from "./DrawModes/LineMeasurementMode.js";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import createSupplementaryPoints from "@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points.js";
import { useQueryClient } from "@tanstack/react-query";

const Ortho = ({ level, edit, viewer, setOrthoImageTypes, setDraw, setImageFound }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const queryClient = useQueryClient();

	let canvas = null;
	let dropdownRef = React.createRef();
	let addExistingFieldsModal = React.createRef();
	let addFieldModal = React.createRef();
	let draw = React.createRef();
	let centerCoordinate = [];
	let orthoImageTypeId = null;
	let openPopup = null;
	let plotPopup = null;
	let statusPopup = null;
	let selectedPlot = null;
	let selectedSubsample = null;
	let orthoAnalyzedModal = React.createRef();
	let alignPlotsModal = React.createRef();
	let viewerDataExportModal = React.createRef();
	let orthoTimelineRef = React.createRef();
	const [mapboxStyle, setMapboxStyle] = useState("mapbox://styles/mapbox/satellite-streets-v11");

	let controlComponentRefs = {
		llToggleRef: React.createRef(),
		fieldToggleRef: React.createRef(),
		plotToggleRef: React.createRef(),
		rowToggleRef: React.createRef(),
		fitToHeightRef: React.createRef(),
		fitToWidthRef: React.createRef(),
		fieldLabelToggleRef: React.createRef(),
		resetOrientationRef: React.createRef(),
		lockToggleRef: React.createRef(),
		fieldPivotToggleRef: React.createRef(),
		fieldPivotLlSetRef: React.createRef(),
		rotateLeftRef: React.createRef(),
		rotateRightRef: React.createRef(),
		scaleOutwardRef: React.createRef(),
		scaleInwardRef: React.createRef(),
		trialToggle: React.createRef(),
		trialLabelToggleRef: React.createRef(),
		plotLabelToggleRef: React.createRef(),
		defMarkerToggleRef: React.createRef(),
		flagPlotsRef: React.createRef(),
		alignPlotsRef: React.createRef(),
		measureDistanceRef: React.createRef(),
		quantifiedRegionToggleRef: React.createRef(),
		heatmapOverlayLabelsRef: React.createRef(),
		editFieldModal: React.createRef(),
		heatmapTimelineRef: React.createRef(),
		applyShaderRef: React.createRef(),
		shaderHistogramRef: React.createRef()
	};

	const setStyle = (style) => {
		ControlFunctions.uncombinePlotsInField(draw);
		setMapboxStyle(style);
	};

	const Map = ReactMapboxGl({
		accessToken: process.env.MAPBOX_ACCESS_TOKEN,
		maxZoom: 26,
		bearingSnap: 0
	});

	let overrideSimpleSelect = _.cloneDeep(MapboxDraw.modes.simple_select);
	let overrideDirectSelect = _.cloneDeep(MapboxDraw.modes.direct_select);

	if (level !== "flight" || (edit && viewer)) {
		overrideSimpleSelect = _.extend(overrideSimpleSelect, {
			onDrag(state, e) {
				if (this.getSelected()[0].properties.meta === "drawn_feature") {
					if (state.canDragMove) return this.dragMove(state, e);
					if (this.drawConfig.boxSelect && state.canBoxSelect) return this.whileBoxSelect(state, e);
				}
			},
			toDisplayFeatures(state, geojson, display) {
				geojson.properties.active = this.isSelected(geojson.properties.id)
					? Constants.activeStates.ACTIVE
					: Constants.activeStates.INACTIVE;
				display(geojson);
				this.fireActionable();
				if (
					geojson.properties.active !== Constants.activeStates.ACTIVE ||
					geojson.geometry.type === Constants.geojsonTypes.POINT
				)
					return;
				if (geojson.properties?.user_meta === "drawn_feature") {
					createSupplementaryPoints(geojson).forEach(display);
				}
			}
		});

		overrideDirectSelect = _.extend(overrideDirectSelect, {
			onDrag(state, e) {
				if (state.canDragMove !== true || this.getSelected()[0].properties.meta !== "drawn_feature") return;
				state.dragMoving = true;
				e.originalEvent.stopPropagation();

				const delta = {
					lng: e.lngLat.lng - state.dragMoveLocation.lng,
					lat: e.lngLat.lat - state.dragMoveLocation.lat
				};
				if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
				else this.dragFeature(state, e, delta);

				state.dragMoveLocation = e.lngLat;
			},
			toDisplayFeatures(state, geojson, push) {
				if (state.featureId === geojson.properties.id) {
					geojson.properties.active = "true";
					push(geojson);
					if (state.feature?.properties?.meta === "drawn_feature") {
						createSupplementaryPoints(geojson, {
							map: this.map,
							midpoints: true,
							selectedPaths: state.selectedCoordPaths
						}).forEach(push);
					}
				} else {
					geojson.properties.active = "false";
					push(geojson);
				}
				this.fireActionable(state);
			}
		});
	} else {
		overrideSimpleSelect = OverrideSimpleSelect;
		overrideDirectSelect = _.extend(overrideDirectSelect, {
			toDisplayFeatures(state, geojson, push) {
				if (state.featureId === geojson.properties.id) {
					geojson.properties.active = "true";
					push(geojson);
					if (state.feature?.properties?.meta === "drawn_feature") {
						createSupplementaryPoints(geojson, {
							map: this.map,
							midpoints: true,
							selectedPaths: state.selectedCoordPaths
						}).forEach(push);
					}
				} else {
					geojson.properties.active = "false";
					push(geojson);
				}
				this.fireActionable(state);
			}
		});
	}

	const handleSelectRow = () => {
		if (selectedPlot) {
			ControlFunctions.selectRow(selectedPlot, draw);
			controlComponentRefs.flagPlotsRef.current.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleRow(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef.current.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef.current.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectColumn = () => {
		if (selectedPlot) {
			ControlFunctions.selectColumn(selectedPlot, draw);
			controlComponentRefs.flagPlotsRef.current.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleColumn(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef.current.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef.current.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectGrid = () => {
		if (selectedPlot) {
			ControlFunctions.selectGrid(selectedPlot, draw, level);
			controlComponentRefs.flagPlotsRef.current.isSubsample(false);
		} else if (selectedSubsample) {
			ControlFunctions.selectSubsampleGrid(selectedSubsample, draw);
			controlComponentRefs.flagPlotsRef.current.isSubsample(true);
		}

		controlComponentRefs.flagPlotsRef.current.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	const handleSelectRep = () => {
		ControlFunctions.selectRep(selectedPlot, selectedSubsample, draw);
		controlComponentRefs.flagPlotsRef.current.isSubsample(false);

		controlComponentRefs.flagPlotsRef.current.setDisabled(false);
		document.getElementsByClassName("mapboxgl-canvas")[0]?.focus();
	};

	function setupControls(evt) {
		canvas = evt;
		ControlFunctions.resetControls();
		if (dropdownRef.current) {
			dropdownRef.current.showDropdowns();

			canvas.on("mousedown", (e) => {
				if (e.originalEvent.button === 2) {
					//-- Checking for a combined field being selected
					let featuresAtPoint = draw.current.draw.getSelected();
					if (featuresAtPoint?.features[0]?.properties.type === "field") {
						e.preventDefault();
						ControlFunctions.uncombinePlotsInField(draw);
					}
				}
			});

			if (level === "flight") {
				ControlFunctions.addExistingPlotsInFlight(
					moduleNavigation.flightId,
					getTokenSilently,
					dispatch,
					draw,
					canvas,
					controlComponentRefs,
					clientId
				);

				setDraw(draw);

				ControlFunctions.fieldLabelToggle(canvas, controlComponentRefs.fieldLabelToggleRef);

				controlComponentRefs.shaderHistogramRef.current.setDisabled(false);
				controlComponentRefs.applyShaderRef.current.setDisabled(false);

				if (!edit && !viewer) {
					ControlFunctions.plotToggle(draw);
					controlComponentRefs.plotToggleRef.current.setToggleState(false);
				}

				canvas.on("mouseover", "field-labels", () => {
					canvas.getCanvas().style.cursor = "pointer";
				});

				canvas.on("mouseleave", "field-labels", () => {
					canvas.getCanvas().style.cursor = "";
				});

				canvas.on("click", "field-labels", (e) => {
					history.push(moduleNavigation.createFieldLink(true, e.features[0].properties.field));
				});

				canvas.on("mouseover", "trial-labels", () => {
					canvas.getCanvas().style.cursor = "pointer";
				});

				canvas.on("mouseleave", "trial-labels", () => {
					canvas.getCanvas().style.cursor = "";
				});

				canvas.on("click", "trial-labels", (e) => {
					history.push(moduleNavigation.createTrialLink(true, e.features[0].properties.trial, "viewer"));
				});
			}
			if (level === "field") {
				ControlFunctions.fieldLabelToggle(canvas, controlComponentRefs.fieldLabelToggleRef);

				if (!controlComponentRefs.trialToggle.current.checkToggleState) {
					ControlFunctions.trialToggle(draw);
					controlComponentRefs.trialToggle.current.setTrialToggled(true);
				}

				if (!controlComponentRefs.trialLabelToggleRef.current.checkToggleState) {
					ControlFunctions.trialLabelToggle(canvas);
					controlComponentRefs.trialLabelToggleRef.current.setToggleState(true);
				}

				canvas.on("mouseover", "trial-labels", () => {
					canvas.getCanvas().style.cursor = "pointer";
				});

				canvas.on("mouseleave", "trial-labels", () => {
					canvas.getCanvas().style.cursor = "";
				});

				canvas.on("click", "trial-labels", (e) => {
					history.push(moduleNavigation.createTrialLink(true, e.features[0].properties.trial, "viewer"));
				});

				_.map(controlComponentRefs, (componentRef) => {
					if (
						componentRef &&
						componentRef.current &&
						componentRef != controlComponentRefs.flagPlotsRef &&
						componentRef != controlComponentRefs.quantifiedRegionToggleRef &&
						componentRef != controlComponentRefs.heatmapOverlayLabelsRef &&
						componentRef.current.setDisabled
					) {
						componentRef.current.setDisabled(false);
					}
				});
			}
			if (level === "trial") {
				ControlFunctions.fieldBoundaryToggle(draw);
				ControlFunctions.lowerLeftToggle(draw);

				if (!controlComponentRefs.trialLabelToggleRef.current.checkToggleState) {
					ControlFunctions.trialLabelToggle(canvas);
					controlComponentRefs.trialLabelToggleRef.current.setToggleState(true);
				}

				if (!controlComponentRefs.plotLabelToggleRef.current.checkToggleState) {
					ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs);
					controlComponentRefs.plotLabelToggleRef.current.setToggleState(true);
				}

				if (!controlComponentRefs.defMarkerToggleRef.current.checkToggleState) {
					ControlFunctions.defMarkerToggle(canvas, controlComponentRefs);
				}

				_.map(controlComponentRefs, (componentRef) => {
					if (
						componentRef?.current?.setDisabled &&
						componentRef != controlComponentRefs.flagPlotsRef &&
						componentRef != controlComponentRefs.quantifiedRegionToggleRef &&
						componentRef != controlComponentRefs.heatmapOverlayLabelsRef &&
						componentRef.current.setDisabled
					) {
						componentRef.current.setDisabled(false);
					}
				});
			}

			canvas.on("contextmenu", (e) => {
				if (ControlFunctions.isFieldCombined()) {
					ControlFunctions.uncombinePlotsInField(draw);

					let featuresAtPoint = canvas.queryRenderedFeatures(e.point, {
						layers: ["gl-draw-polygon-fill-inactive.cold", "gl-draw-polygon-fill-active.hot"]
					});

					let indexOfPlot = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "plot" });
					if (indexOfPlot !== -1) {
						selectedPlot =
							featuresAtPoint[indexOfPlot].properties.user_plotId ?? featuresAtPoint[indexOfPlot].properties.id;
					}

					draw.current.draw.changeMode("simple_select", {
						featureIds: [selectedPlot]
					});
				}

				let featuresAtPoint = canvas.queryRenderedFeatures(e.point, {
					layers: ["gl-draw-polygon-fill-inactive.cold", "gl-draw-polygon-fill-active.hot"]
				});

				let indexOfQuantifiedRegion = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "subsample" });
				let indexOfPlot = _.findIndex(_.map(featuresAtPoint, "properties"), { user_type: "plot" });
				if (indexOfQuantifiedRegion !== -1) {
					selectedSubsample = featuresAtPoint[indexOfQuantifiedRegion].properties.id;
				} else if (indexOfPlot !== -1) {
					selectedPlot =
						featuresAtPoint[indexOfPlot].properties.user_plotId ?? featuresAtPoint[indexOfPlot].properties.id;
				}

				if (indexOfQuantifiedRegion !== -1 || indexOfPlot !== -1) {
					if (plotPopup !== null) {
						plotPopup.remove();
					}

					if (level !== "trial") {
						plotPopup = new MapboxGl.Popup({ closeButton: false })
							.setLngLat(e.lngLat)
							.setHTML(
								`<div class="ui grid" style="font-size: medium" id="plotPopup">
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-row">Select Range</button>
										</div>
									</div>
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-column">Select Column</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-grid">Select Grid</button>
										</div>
									</div>
								</div>`
							)
							.addTo(canvas);
					} else {
						plotPopup = new MapboxGl.Popup({ closeButton: false })
							.setLngLat(e.lngLat)
							.setHTML(
								`<div class="ui grid" style="font-size: medium" id="plotPopup">
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-rep">Select Replicate</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-row">Select Range</button>
										</div>
									</div>
									<div class="row">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-column">Select Column</button>
										</div>
									</div>
									<div class="row" style="padding: 0 !important">
										<div class="left aligned sixteen wide column">
											<button class="ui primary button" style="width: 100%" id="button-select-grid">Select Grid</button>
										</div>
									</div>
								</div>`
							)
							.addTo(canvas);
					}

					document.getElementById("button-select-row").addEventListener("click", () => {
						handleSelectRow();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					document.getElementById("button-select-column").addEventListener("click", () => {
						handleSelectColumn();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					document.getElementById("button-select-grid").addEventListener("click", () => {
						handleSelectGrid();
						selectedPlot = null;
						selectedSubsample = null;
						plotPopup.remove();
					});
					if (level === "trial") {
						document.getElementById("button-select-rep").addEventListener("click", () => {
							handleSelectRep();
							selectedPlot = null;
							selectedSubsample = null;
							plotPopup.remove();
						});
					}
				}
			});

			canvas.on("mouseleave", "plotPopup", () => {
				plotPopup.remove();
			});

			canvas.on("draw.liveUpdate", () => {
				ControlFunctions.handleDragging(draw);
			});

			canvas.on("mouseenter", "field-labels", (e) => {
				openPopup = new MapboxGl.Popup({ closeButton: false })
					.setLngLat(e.features[0].geometry.coordinates)
					.setHTML(
						`<div class="ui grid" style="font-size: medium">
								<div class="row" style="padding: 0 !important">
									<div class="left aligned six wide column">
										<p>${e.features[0].properties.crop}</p>
									</div>
									<div class="ten wide column">
										<p>${e.features[0].properties.city}, ${e.features[0].properties.state}</p>
									</div>
								</div>
								<div class="row" style="padding: 0 !important">
									<div class="left aligned six wide column">
										<p>Planted:</p>
									</div>
									<div class="ten wide column">
										<p>${e.features[0].properties.plantDate !== -1 ? e.features[0].properties.plantDate : "No Date Set"}</p>
									</div>
								</div>
								<div class="row" style="padding: 0 !important">
									<div class="left aligned six wide column">
										<p>Harvested:</p>
									</div>
									<div class="ten wide column">
										<p>${e.features[0].properties.harvestDate !== -1 ? e.features[0].properties.harvestDate : "No Date Set"}</p>
									</div>
								</div>
								<div class="row" style="padding: 0 !important; margin-top: 5px">
									<div class="left aligned sixteen wide column">
										<p>${e.features[0].properties.columns} Columns X ${e.features[0].properties.ranges} Ranges</p>
									</div>
								</div>
						</div>`
					)
					.addTo(canvas);
			});

			canvas.on("mouseleave", "field-labels", () => {
				openPopup.remove();
			});

			canvas.on("mouseenter", `def-labels`, (e) => {
				statusPopup = new MapboxGl.Popup({ closeOnClick: false, closeButton: false, anchor: "top-left" })
					.setLngLat(e.features[0].geometry.coordinates)
					.setOffset([32, 0])
					.setMaxWidth("500px")
					.setHTML(
						`<div class="ui" style="font-size: 18px">
							<h2><u>Annotation Summary for Plot ${e.features[0].properties.plotName ?? ""}</u></h2>
							${_.reduce(
								Object.entries(JSON.parse(e.features[0].properties.statuses)),
								(acc, lst) => {
									return (
										acc +
										`<div>
											<u>${
												new Date(parseInt(lst[0])).toLocaleString() === new Date(Date.UTC(0, 0)).toLocaleString()
													? "All Dates"
													: moment(parseInt(lst[0])).format("MM/DD/YY")
											}:</u>
											${_.reduce(
												_.flatten(lst[1]),
												(a, ls) => {
													if (ls.level === "Partial") {
														return (
															a +
															_.reduce(
																Object.keys(ls.quantifiedRegionNames),
																(ac) => {
																	return (
																		ac +
																		`<div>
																	<p><b>Partially ${ls.status}:</b> ${ls.annotationClassifier ? ls.annotationClassifier : ""} ${
																			ls.notes ? " - " + ls.notes : ""
																		}${ls.notes || ls.annotationClassifier ? " - " : ""}${ls.modifiedBy} on ${
																			ls.modifiedDateTime
																		}</p>
																</div>`
																	);
																},
																""
															)
														);
													}
													return (
														a +
														`<div>
													<p><b>${ls.status}:</b> ${ls.annotationClassifier ? ls.annotationClassifier : ""} ${ls.notes ? " - " + ls.notes : ""}${
															ls.notes || ls.annotationClassifier ? " - " : ""
														}${ls.modifiedBy} on ${ls.modifiedDateTime}</p>
												</div>`
													);
												},
												""
											)}
								</div><br />`
									);
								},
								""
							)}
					</div>`
					);
				statusPopup.addTo(canvas);
			});

			canvas.on("mouseleave", `def-labels`, () => {
				statusPopup.remove();
			});
		}
	}

	function SetControlsToDefaultViewerSetting() {
		let existingDefaultViewerSetting = ControlFunctions.getDefaultViewerSetting();

		// if (existingDefaultViewerSetting === null) {
		// 	document.getElementById("reset-button").disabled = true;
		// } else {
		// 	document.getElementById("reset-button").disabled = false;
		if (existingDefaultViewerSetting) {
			orthoTimelineRef?.current?.setCurrentFlightId(existingDefaultViewerSetting.viewableFlightId);
			dropdownRef?.current?.innerRef?.current?.setSelectedOrthoId(
				existingDefaultViewerSetting.viewableOrthoImageTypeId
			);
			if (existingDefaultViewerSetting.groundDataAssessmentId !== null) {
				//Ground Data
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(
					existingDefaultViewerSetting.groundDataSetId
				);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(
					existingDefaultViewerSetting.groundDataAssessmentId
				);
			} else if (existingDefaultViewerSetting.curveModelAnalysisId !== null) {
				// Curve Model
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(null);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(
					existingDefaultViewerSetting.analysisIdForCurveModel + "/" + existingDefaultViewerSetting.curveModelAnalysisId
				);
			} else {
				// Analysis
				controlComponentRefs.heatmapTimelineRef.current.setSelectedFlightId(
					existingDefaultViewerSetting.analysisFlightId
				);
				controlComponentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(existingDefaultViewerSetting.analysisId);
			}

			controlComponentRefs.heatmapTimelineRef.current.setHeatmapEnabled(existingDefaultViewerSetting.toggleHeatmapData);
		}
	}

	async function handleResetButtonClicked() {
		// document.getElementById("reset-button").disabled = false;

		const accessToken = await getTokenSilently();
		var selectedAnalysisType = controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisType();
		if (level === "field") {
			var defaultViewerSettingForField = {
				fieldId: moduleNavigation.fieldId,
				toggleHeatmapData: controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled(),
				toggleImageType: true,
				viewableFlightId: orthoTimelineRef.current.getCurrentFlightId(),
				viewableOrthoImageTypeId: dropdownRef.current.innerRef.current.getSelectedOrthoId(),
				groundDataSetId:
					selectedAnalysisType == "Assessment"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
						: null,
				groundDataAssessmentId:
					selectedAnalysisType == "Assessment"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				curveModelAnalysisId:
					selectedAnalysisType == "CurveModel"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[1]
						: null,
				analysisIdForCurveModel:
					selectedAnalysisType == "CurveModel"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				analysisId:
					selectedAnalysisType == "Analysis"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				analysisFlightId:
					selectedAnalysisType == "Analysis"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
						: null
			};

			var currentDefaultViewerSettingForField = ControlFunctions.getDefaultViewerSetting();
			if (currentDefaultViewerSettingForField === null) {
				dispatch(
					fieldActions.createDefaultViewerSettingForField(
						clientId,
						defaultViewerSettingForField.fieldId,
						defaultViewerSettingForField.toggleHeatmapData,
						defaultViewerSettingForField.toggleImageType,
						defaultViewerSettingForField.viewableFlightId,
						defaultViewerSettingForField.viewableOrthoImageTypeId,
						defaultViewerSettingForField.groundDatasetId,
						defaultViewerSettingForField.groundDataAssessmentId,
						defaultViewerSettingForField.curveModelAnalysisId,
						defaultViewerSettingForField.analysisIdForCurveModel,
						defaultViewerSettingForField.analysisId,
						defaultViewerSettingForField.analysisFlightId,
						accessToken
					)
				).then((res) => {
					defaultViewerSettingForField.id = res.data;
					ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForField);
					toast.success("Default viewer settings created successfully.");
				});
			} else {
				dispatch(
					fieldActions.updateDefaultViewerSettingForField(
						clientId,
						currentDefaultViewerSettingForField.id,
						defaultViewerSettingForField.fieldId,
						defaultViewerSettingForField.toggleHeatmapData,
						defaultViewerSettingForField.toggleImageType,
						defaultViewerSettingForField.viewableFlightId,
						defaultViewerSettingForField.viewableOrthoImageTypeId,
						defaultViewerSettingForField.groundDatasetId,
						defaultViewerSettingForField.groundDataAssessmentId,
						defaultViewerSettingForField.curveModelAnalysisId,
						defaultViewerSettingForField.analysisIdForCurveModel,
						defaultViewerSettingForField.analysisId,
						defaultViewerSettingForField.analysisFlightId,
						accessToken
					)
				).then(() => {
					defaultViewerSettingForField.id = currentDefaultViewerSettingForField.id;
					ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForField);
					toast.success("Default viewer settings updated successfully.");
				});
			}

			ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
		} else if (level === "trial") {
			var defaultViewerSettingForTrial = {
				trialId: moduleNavigation.trialId,
				toggleHeatmapData: controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled(),
				toggleImageType: true,
				viewableFlightId: orthoTimelineRef.current.getCurrentFlightId(),
				viewableOrthoImageTypeId: dropdownRef.current.innerRef.current.getSelectedOrthoId(),
				groundDataSetId:
					selectedAnalysisType == "Assessment"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
						: null,
				groundDataAssessmentId:
					selectedAnalysisType == "Assessment"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				curveModelAnalysisId:
					selectedAnalysisType == "CurveModel"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[1]
						: null,
				analysisIdForCurveModel:
					selectedAnalysisType == "CurveModel"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				analysisId:
					selectedAnalysisType == "Analysis"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedAnalysisId().split("/")[0]
						: null,
				analysisFlightId:
					selectedAnalysisType == "Analysis"
						? controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()
						: null
			};

			var currentDefaultViewerSettingForTrial = ControlFunctions.getDefaultViewerSetting();
			if (currentDefaultViewerSettingForTrial === null) {
				dispatch(
					trialActions.createDefaultViewerSettingForTrial(
						clientId,
						defaultViewerSettingForTrial.trialId,
						defaultViewerSettingForTrial.toggleHeatmapData,
						defaultViewerSettingForTrial.toggleImageType,
						defaultViewerSettingForTrial.viewableFlightId,
						defaultViewerSettingForTrial.viewableOrthoImageTypeId,
						defaultViewerSettingForTrial.groundDatasetId,
						defaultViewerSettingForTrial.groundDataAssessmentId,
						defaultViewerSettingForTrial.curveModelAnalysisId,
						defaultViewerSettingForTrial.analysisIdForCurveModel,
						defaultViewerSettingForTrial.analysisId,
						defaultViewerSettingForTrial.analysisFlightId,
						accessToken
					)
				).then((res) => {
					defaultViewerSettingForTrial.id = res.data;
					ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
					toast.success("Default viewer settings created successfully.");
				});
			} else {
				dispatch(
					trialActions.updateDefaultViewerSettingForTrial(
						clientId,
						currentDefaultViewerSettingForTrial.id,
						defaultViewerSettingForTrial.trialId,
						defaultViewerSettingForTrial.toggleHeatmapData,
						defaultViewerSettingForTrial.toggleImageType,
						defaultViewerSettingForTrial.viewableFlightId,
						defaultViewerSettingForTrial.viewableOrthoImageTypeId,
						defaultViewerSettingForTrial.groundDatasetId,
						defaultViewerSettingForTrial.groundDataAssessmentId,
						defaultViewerSettingForTrial.curveModelAnalysisId,
						defaultViewerSettingForTrial.analysisIdForCurveModel,
						defaultViewerSettingForTrial.analysisId,
						defaultViewerSettingForTrial.analysisFlightId,
						accessToken
					)
				).then(() => {
					defaultViewerSettingForTrial.id = currentDefaultViewerSettingForTrial.id;
					ControlFunctions.setDefaultViewerSetting(defaultViewerSettingForTrial);
					toast.success("Default viewer settings updated successfully.");
				});
			}
		}
	}

	return (
		<Segment basic id="orthoViewerSegment">
			<Grid id="orthoViewerGrid" stackable={false}>
				<Grid.Row style={{ paddingBottom: "unset" }}>
					<Grid.Column width="1" />
					{level !== "flight" && (
						<>
							<Grid.Column width="2">
								<Grid id="orthoViewerDefaultButtonGrid" centered>
									<Grid.Row>
										<Button
											style={{ width: "100%", marginBottom: "3px", fontSize: "16px" }}
											color={"green"}
											onClick={() => {
												handleResetButtonClicked();
											}}
										>
											Save as Default
										</Button>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: "4px" }}>
										{/* <Button
											id="reset-button"
											style={{ width: "100%", marginBottom: "3px", fontSize: "16px" }}
											negative
											onClick={() => {
												SetControlsToDefaultViewerSetting();
											}}
										>
											Reset
										</Button> */}
									</Grid.Row>
									{/* </Grid.Column> */}
								</Grid>
							</Grid.Column>
						</>
					)}
					<Grid.Column width="13">
						<Grid>
							<Grid.Row style={{ paddingBottom: 0 }}>
								<Grid.Column width={level === "flight" ? "16" : "5"}>
									<OrthoDropdownParentComponent
										ref={dropdownRef}
										edit={edit}
										level={level}
										viewer={viewer}
										setCenterCoordinate={(coord) => (centerCoordinate = coord)}
										setOrthoImageTypeId={(id) => {
											orthoImageTypeId = id;
											if (level !== "flight") {
												orthoTimelineRef.current.refresh();
											}
										}}
										heatmapTimelineRef={controlComponentRefs.heatmapTimelineRef}
										setOrthoTypes={(types) => setOrthoImageTypes(types)}
										buildMapImage={(res, orthoId) =>
											ControlFunctions.buildMapImage(res, orthoId, canvas, edit, controlComponentRefs)
										}
										movePlot={(key, e) =>
											ControlFunctions.movePlot(key, draw, canvas, orthoAnalyzedModal, level, edit, viewer, e)
										}
										handleFieldDropdownChange={(option) =>
											ControlFunctions.handleFieldDropdownChange(
												draw,
												canvas,
												edit,
												option,
												moduleNavigation.flightId,
												getTokenSilently,
												dispatch,
												centerCoordinate,
												orthoImageTypeId,
												clientId,
												controlComponentRefs
											)
										}
										removeFieldGrid={(fieldId) =>
											ControlFunctions.removeFieldGrid(
												moduleNavigation.flightId,
												fieldId,
												clientId,
												draw,
												canvas,
												getTokenSilently,
												dispatch,
												dropdownRef,
												edit,
												controlComponentRefs
											)
										}
										jumpToCoords={(coords) =>
											canvas.jumpTo({
												bearing: 0,
												center: coords,
												zoom: level === "field" ? 18 : level === "trial" ? 19 : 17,
												pitch: 0
											})
										}
										redrawPlots={(flightId, fieldId, trialId, sensorChanged, res, orthoId, first) =>
											ControlFunctions.redrawPlots(
												flightId,
												fieldId,
												trialId,
												sensorChanged,
												res,
												orthoId,
												getTokenSilently,
												dispatch,
												draw,
												canvas,
												edit,
												first,
												controlComponentRefs,
												clientId,
												SetControlsToDefaultViewerSetting
											)
										}
										setOrthoId={ControlFunctions.setOrthoId}
										imageFound={(truth) => setImageFound(truth)}
									/>
								</Grid.Column>
								{level !== "flight" && (
									<>
										<Grid.Column width="10">
											<OrthoTimeline
												level={level}
												selectedOrthoImageTypeId={() => {
													return orthoImageTypeId;
												}}
												imageFound={(truth) => setImageFound(truth)}
												heatmapTimelineRef={controlComponentRefs.heatmapTimelineRef}
												ref={orthoTimelineRef}
											/>
										</Grid.Column>
										<Grid.Column width="1" verticalAlign="top" style={{ paddingLeft: 30, marginTop: 8 }}>
											<Checkbox toggle disabled={true} defaultChecked />
										</Grid.Column>
									</>
								)}
							</Grid.Row>
							{level !== "flight" && (
								<Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
									<HeatmapTimeline
										ref={controlComponentRefs.heatmapTimelineRef}
										level={level}
										heatmapOverlayShading={() =>
											ControlFunctions.heatmapOverlayShading(draw, canvas, controlComponentRefs)
										}
										heatmapOverlayLabels={() =>
											ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs)
										}
										setPlotAnalysisResults={(values) => ControlFunctions.setPlotAnalysisResults(values)}
										heatmapOverlayLabelButton={controlComponentRefs.heatmapOverlayLabelsRef}
										plotLabels={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
										plotLabelButton={controlComponentRefs.plotLabelToggleRef}
									/>
								</Grid.Row>
							)}
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row verticalAlign="top" style={{ paddingBottom: "unset", display: "inline-block" }}>
					<Grid.Column className="uniform-button-column" width="1" textAlign="center">
						{level === "flight" ? (
							<>
								<LowerLeftToggle
									ref={controlComponentRefs.llToggleRef}
									lowerLeftToggle={() => ControlFunctions.lowerLeftToggle(draw)}
								/>
								<FieldBoundaryToggle
									ref={controlComponentRefs.fieldToggleRef}
									fieldBoundaryToggle={() => ControlFunctions.fieldBoundaryToggle(draw)}
								/>
								<PlotToggle
									ref={controlComponentRefs.plotToggleRef}
									plotToggle={() => ControlFunctions.plotToggle(draw)}
								/>
								<RowToggle ref={controlComponentRefs.rowToggleRef} rowToggle={() => ControlFunctions.rowToggle(draw)} />
								<TrialToggle
									ref={controlComponentRefs.trialToggle}
									trialToggle={() => ControlFunctions.trialToggle(draw)}
								/>
								<FieldLabelToggle
									ref={controlComponentRefs.fieldLabelToggleRef}
									fieldLabelToggle={() => ControlFunctions.fieldLabelToggle(canvas)}
								/>
								<TrialLabelToggle
									ref={controlComponentRefs.trialLabelToggleRef}
									trialLabelToggle={() => {
										ControlFunctions.trialLabelToggle(canvas);
									}}
								/>
								<PlotLabelToggle
									ref={controlComponentRefs.plotLabelToggleRef}
									plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
								/>
								<DEFMarkerToggle
									ref={controlComponentRefs.defMarkerToggleRef}
									defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
								/>
								<FitToHeight
									ref={controlComponentRefs.fitToHeightRef}
									fitToHeight={() => ControlFunctions.fitToHeight(canvas, "field")}
								/>
								<FitToWidth
									ref={controlComponentRefs.fitToWidthRef}
									fitToWidth={() => ControlFunctions.fitToWidth(canvas, "field")}
								/>
								<ResetOrientation
									ref={controlComponentRefs.resetOrientationRef}
									resetOrientation={() =>
										ControlFunctions.resetOrientation(
											canvas,
											centerCoordinate,
											level,
											draw,
											dropdownRef,
											controlComponentRefs
										)
									}
								/>
								<MeasureDistance
									ref={controlComponentRefs.measureDistanceRef}
									measureDistance={() =>
										ControlFunctions.MeasureDistanceToggle(draw, controlComponentRefs.measureDistanceRef)
									}
									clearMeasurements={() =>
										ControlFunctions.clearMeasurements(draw, controlComponentRefs.measureDistanceRef)
									}
								/>

								{!viewer && (
									<>
										<Divider />
										<LockToggle
											ref={controlComponentRefs.lockToggleRef}
											lockToggle={() => {
												ControlFunctions.lockToggle(draw, canvas, dropdownRef, controlComponentRefs);
												controlComponentRefs.alignPlotsRef.current.setDisabled(true);
											}}
										/>
										{edit && (
											<AddField
												addField={() => ControlFunctions.addField(draw, controlComponentRefs.measureDistanceRef)}
											/>
										)}
										<AlignPlots ref={controlComponentRefs.alignPlotsRef} alignPlotsModal={alignPlotsModal} />
									</>
								)}
								<EditFieldModal
									ref={controlComponentRefs.editFieldModal}
									validateSelectedField={() => ControlFunctions.validateSelectedField(draw)}
									deselectField={() => ControlFunctions.uncombinePlotsInField(draw, false)}
									disableAlignFieldsButton={() => controlComponentRefs.alignPlotsRef.current.setDisabled(true)}
									dropdownRef={dropdownRef}
								/>
								<FlagPlots
									ref={controlComponentRefs.flagPlotsRef}
									markedPlotOptions={() => ControlFunctions.getMarkedPlotOptions()}
									applyChanges={(checkedOptions, notes) =>
										ControlFunctions.handleApplyStatuses(
											checkedOptions,
											notes,
											draw,
											orthoTimelineRef,
											controlComponentRefs.flagPlotsRef,
											clientId,
											dispatch,
											getTokenSilently,
											canvas,
											controlComponentRefs,
											moduleNavigation.flightId,
											dropdownRef.current.innerRef.current.getFlightOptions,
											queryClient
										)
									}
									draw={draw}
									markedPlots={() => ControlFunctions.getMarkedPlots()}
									markedSubsamples={() => ControlFunctions.getMarkedSubsamples()}
									flightId={() => moduleNavigation.flightId}
									dropdownRef={dropdownRef}
									deleteExistingFeatures={(features) =>
										ControlFunctions.deleteExistingMarkedFeatures(
											features,
											draw,
											dispatch,
											getTokenSilently,
											clientId,
											canvas,
											controlComponentRefs,
											controlComponentRefs.flagPlotsRef,
											queryClient
										)
									}
									getSubsamples={() => ControlFunctions.getSubsamples()}
									annotationClassOptions={() => ControlFunctions.getAnnotationClassOptions()}
									getFlightId={() => controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()}
									checkAnnotationPermissions={(markedPlots, markedQuantifiedRegions, type) =>
										ControlFunctions.checkAnnotationPermissions(
											markedPlots,
											markedQuantifiedRegions,
											clientId,
											dispatch,
											getTokenSilently,
											controlComponentRefs.flagPlotsRef,
											type
										)
									}
								/>
							</>
						) : level === "field" ? (
							<>
								<LowerLeftToggle
									ref={controlComponentRefs.llToggleRef}
									lowerLeftToggle={() => ControlFunctions.lowerLeftToggle(draw)}
								/>
								<FieldBoundaryToggle
									ref={controlComponentRefs.fieldToggleRef}
									fieldBoundaryToggle={() => ControlFunctions.fieldBoundaryToggle(draw)}
								/>
								<PlotToggle
									ref={controlComponentRefs.plotToggleRef}
									plotToggle={() => ControlFunctions.plotToggle(draw)}
								/>
								<RowToggle ref={controlComponentRefs.rowToggleRef} rowToggle={() => ControlFunctions.rowToggle(draw)} />
								<TrialToggle
									ref={controlComponentRefs.trialToggle}
									trialToggle={() => ControlFunctions.trialToggle(draw)}
								/>
								<FieldLabelToggle
									ref={controlComponentRefs.fieldLabelToggleRef}
									fieldLabelToggle={() => ControlFunctions.fieldLabelToggle(canvas)}
								/>
								<TrialLabelToggle
									ref={controlComponentRefs.trialLabelToggleRef}
									trialLabelToggle={() => {
										ControlFunctions.trialLabelToggle(
											canvas,
											controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled()
										);
									}}
								/>
								<PlotLabelToggle
									ref={controlComponentRefs.plotLabelToggleRef}
									plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
								/>
								<DEFMarkerToggle
									ref={controlComponentRefs.defMarkerToggleRef}
									defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
								/>
								<QuantifiedRegionToggle
									quantifiedRegionToggle={(type) => ControlFunctions.quantifiedRegionToggle(draw, false, type)}
									ref={controlComponentRefs.quantifiedRegionToggleRef}
								/>
								<FitToHeight
									ref={controlComponentRefs.fitToHeightRef}
									fitToHeight={() => ControlFunctions.fitToHeight(canvas, "field")}
								/>
								<FitToWidth
									ref={controlComponentRefs.fitToWidthRef}
									fitToWidth={() => ControlFunctions.fitToWidth(canvas, "field")}
								/>
								<RotateRight rotateRight={() => ControlFunctions.rotateFeatureRight(canvas, level)} level={level} />
								<RotateLeft rotateLeft={() => ControlFunctions.rotateFeatureLeft(canvas, level)} level={level} />
								<ResetOrientation
									ref={controlComponentRefs.resetOrientationRef}
									resetOrientation={() =>
										ControlFunctions.resetOrientation(
											canvas,
											centerCoordinate,
											level,
											draw,
											dropdownRef,
											controlComponentRefs
										)
									}
								/>
								<MeasureDistance
									ref={controlComponentRefs.measureDistanceRef}
									measureDistance={() =>
										ControlFunctions.MeasureDistanceToggle(draw, controlComponentRefs.measureDistanceRef)
									}
									clearMeasurements={() =>
										ControlFunctions.clearMeasurements(draw, controlComponentRefs.measureDistanceRef)
									}
								/>
								{userAuth.hasApplicationArea(ApplicationArea.FieldViewer, userAuth.currentClientId) &&
									userAuth.hasApplicationArea(ApplicationArea.FieldVisualHeatmapValues, userAuth.currentClientId) && (
										<HeatmapOverlayLabels
											ref={controlComponentRefs.heatmapOverlayLabelsRef}
											heatmapOverlayLabels={() =>
												ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs, orthoImageTypeId)
											}
										/>
									)}
								<FlagPlots
									ref={controlComponentRefs.flagPlotsRef}
									markedPlotOptions={() => ControlFunctions.getMarkedPlotOptions()}
									applyChanges={(checkedOptions, notes) =>
										ControlFunctions.handleApplyStatuses(
											checkedOptions,
											notes,
											draw,
											orthoTimelineRef,
											controlComponentRefs.flagPlotsRef,
											clientId,
											dispatch,
											getTokenSilently,
											canvas,
											controlComponentRefs,
											null,
											dropdownRef.current.innerRef.current.getFlightOptions,
											queryClient
										)
									}
									draw={draw}
									markedPlots={() => ControlFunctions.getMarkedPlots()}
									flightId={() => ControlFunctions.getCurrentFlightId()}
									dropdownRef={dropdownRef}
									markedSubsamples={() => ControlFunctions.getMarkedSubsamples()}
									deleteExistingFeatures={(features) =>
										ControlFunctions.deleteExistingMarkedFeatures(
											features,
											draw,
											dispatch,
											getTokenSilently,
											clientId,
											canvas,
											controlComponentRefs,
											controlComponentRefs.flagPlotsRef,
											queryClient
										)
									}
									getSubsamples={() => ControlFunctions.getSubsamples()}
									annotationClassOptions={() => ControlFunctions.getAnnotationClassOptions()}
									getFlightId={() => controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()}
									checkAnnotationPermissions={(markedPlots, markedQuantifiedRegions, type) =>
										ControlFunctions.checkAnnotationPermissions(
											markedPlots,
											markedQuantifiedRegions,
											clientId,
											dispatch,
											getTokenSilently,
											controlComponentRefs.flagPlotsRef,
											type
										)
									}
								/>
							</>
						) : (
							<>
								<PlotToggle
									ref={controlComponentRefs.plotToggleRef}
									plotToggle={() => ControlFunctions.plotToggle(draw)}
								/>
								<RowToggle ref={controlComponentRefs.rowToggleRef} rowToggle={() => ControlFunctions.rowToggle(draw)} />
								<TrialLabelToggle
									ref={controlComponentRefs.trialLabelToggleRef}
									trialLabelToggle={() => {
										ControlFunctions.trialLabelToggle(
											canvas,
											controlComponentRefs.heatmapTimelineRef.current.getHeatmapEnabled()
										);
									}}
								/>
								<PlotLabelToggle
									ref={controlComponentRefs.plotLabelToggleRef}
									plotLabelToggle={() => ControlFunctions.plotLabelToggle(draw, canvas, controlComponentRefs)}
								/>
								{userAuth.hasApplicationArea(ApplicationArea.TrialVisualHeatmapValues, userAuth.currentClientId) && (
									<HeatmapOverlayLabels
										ref={controlComponentRefs.heatmapOverlayLabelsRef}
										heatmapOverlayLabels={() =>
											ControlFunctions.heatmapOverlayLabels(draw, canvas, controlComponentRefs, orthoImageTypeId)
										}
									/>
								)}
								<DEFMarkerToggle
									ref={controlComponentRefs.defMarkerToggleRef}
									defMarkerToggle={() => ControlFunctions.defMarkerToggle(canvas, controlComponentRefs)}
								/>
								<QuantifiedRegionToggle
									quantifiedRegionToggle={(type) => ControlFunctions.quantifiedRegionToggle(draw, false, type)}
									ref={controlComponentRefs.quantifiedRegionToggleRef}
								/>
								<FitToHeight
									ref={controlComponentRefs.fitToHeightRef}
									fitToHeight={() => ControlFunctions.fitToHeight(canvas, "trial")}
								/>
								<FitToWidth
									ref={controlComponentRefs.fitToWidthRef}
									fitToWidth={() => ControlFunctions.fitToWidth(canvas, "trial")}
								/>
								<RotateRight rotateRight={() => ControlFunctions.rotateFeatureRight(canvas, level)} level={level} />
								<RotateLeft rotateLeft={() => ControlFunctions.rotateFeatureLeft(canvas, level)} level={level} />
								<ResetOrientation
									ref={controlComponentRefs.resetOrientationRef}
									resetOrientation={() =>
										ControlFunctions.resetOrientation(
											canvas,
											centerCoordinate,
											level,
											draw,
											dropdownRef,
											controlComponentRefs
										)
									}
								/>
								<MeasureDistance
									ref={controlComponentRefs.measureDistanceRef}
									measureDistance={() =>
										ControlFunctions.MeasureDistanceToggle(draw, controlComponentRefs.measureDistanceRef)
									}
									clearMeasurements={() =>
										ControlFunctions.clearMeasurements(draw, controlComponentRefs.measureDistanceRef)
									}
								/>
								<FlagPlots
									ref={controlComponentRefs.flagPlotsRef}
									markedPlotOptions={() => ControlFunctions.getMarkedPlotOptions()}
									applyChanges={(checkedOptions, notes) =>
										ControlFunctions.handleApplyStatuses(
											checkedOptions,
											notes,
											draw,
											orthoTimelineRef,
											controlComponentRefs.flagPlotsRef,
											clientId,
											dispatch,
											getTokenSilently,
											canvas,
											controlComponentRefs,
											null,
											dropdownRef.current.innerRef.current.getFlightOptions,
											queryClient
										)
									}
									draw={draw}
									markedPlots={() => ControlFunctions.getMarkedPlots()}
									flightId={() => ControlFunctions.getCurrentFlightId()}
									dropdownRef={dropdownRef}
									markedSubsamples={() => ControlFunctions.getMarkedSubsamples()}
									deleteExistingFeatures={(features) =>
										ControlFunctions.deleteExistingMarkedFeatures(
											features,
											draw,
											dispatch,
											getTokenSilently,
											clientId,
											canvas,
											controlComponentRefs,
											controlComponentRefs.flagPlotsRef,
											queryClient
										)
									}
									getSubsamples={() => ControlFunctions.getSubsamples()}
									annotationClassOptions={() => ControlFunctions.getAnnotationClassOptions()}
									getFlightId={() => controlComponentRefs.heatmapTimelineRef.current.getSelectedFlightId()}
									checkAnnotationPermissions={(markedPlots, markedQuantifiedRegions, type) =>
										ControlFunctions.checkAnnotationPermissions(
											markedPlots,
											markedQuantifiedRegions,
											clientId,
											dispatch,
											getTokenSilently,
											controlComponentRefs.flagPlotsRef,
											type
										)
									}
								/>
							</>
						)}
					</Grid.Column>
					<Grid.Column width="1" style={{ position: "absolute", zIndex: "1", padding: "2%" }}>
						{/*<ViewerDataExportButton viewerDataExportModalRef={viewerDataExportModal}></ViewerDataExportButton>*/}
						<MapboxStyleToggle styleToggle={() => ControlFunctions.styleToggle()} setStyle={setStyle} />
						<Divider hidden />
						<ApplyShader
							removeShader={() => ControlFunctions.removeShader(canvas)}
							applyThermalShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyThermalShader(canvas, minThreshold, maxThreshold)
							}
							applyRYGShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyRYGShader(canvas, minThreshold, maxThreshold)
							}
							applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyGreenFireBlueShader(canvas, minThreshold, maxThreshold)
							}
							applySpectralShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applySpectralShader(canvas, minThreshold, maxThreshold)
							}
							applyBlueYellowShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyBlueYellowShader(canvas, minThreshold, maxThreshold)
							}
							applyClampedShader={(minThreshold, maxThreshold) => {
								ControlFunctions.applyClampedShader(canvas, minThreshold, maxThreshold);
							}}
							ref={controlComponentRefs.applyShaderRef}
						/>
						<ShaderHistogram
							applyClampedShader={(minThreshold, maxThreshold) => {
								if (canvas) {
									ControlFunctions.applyClampedShader(canvas, minThreshold, maxThreshold);
								}
							}}
							applyThermalShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyThermalShader(canvas, minThreshold, maxThreshold)
							}
							applyRYGShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyRYGShader(canvas, minThreshold, maxThreshold)
							}
							applyGreenFireBlueShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyGreenFireBlueShader(canvas, minThreshold, maxThreshold)
							}
							applySpectralShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applySpectralShader(canvas, minThreshold, maxThreshold)
							}
							applyBlueYellowShader={(minThreshold, maxThreshold) =>
								ControlFunctions.applyBlueYellowShader(canvas, minThreshold, maxThreshold)
							}
							dropdownRef={dropdownRef}
							ref={controlComponentRefs.shaderHistogramRef}
						/>
						{level === "flight" && !viewer ? (
							<>
								<Divider hidden />
								<Rotate
									ref={controlComponentRefs.rotateRightRef}
									rotate={(e) => ControlFunctions.rotateRight(draw, orthoAnalyzedModal, canvas, e)}
									flip={false}
								/>
								<Rotate
									ref={controlComponentRefs.rotateLeftRef}
									rotate={(e) => ControlFunctions.rotateLeft(draw, orthoAnalyzedModal, canvas, e)}
									flip={true}
								/>
								<FieldPivotToggle
									ref={controlComponentRefs.fieldPivotToggleRef}
									fieldPivotToggle={() => ControlFunctions.fieldPivotToggle(draw)}
								/>
								<FieldPivotLlSet
									ref={controlComponentRefs.fieldPivotLlSetRef}
									fieldPivotLlSet={() => ControlFunctions.fieldPivotLlSet(draw)}
								/>
								<Divider hidden />
								<ScaleOutward
									ref={controlComponentRefs.scaleOutwardRef}
									scaleOutward={(e) => ControlFunctions.scaleOutward(draw, e)}
								/>
								<ScaleInward
									ref={controlComponentRefs.scaleInwardRef}
									scaleInward={(e) => ControlFunctions.scaleInward(draw, e)}
								/>
							</>
						) : null}
					</Grid.Column>
					<Grid.Column stretched width="15">
						<Segment basic style={{ padding: "2px", border: "solid 1px" }}>
							<div id="distance" class="distance-container"></div>
							<Map
								style={mapboxStyle}
								containerStyle={{
									height: "calc(100vh - 200px)",
									width: "100%"
								}}
								center={[0, 0]}
								zoom={[1]}
								onClick={(_, event) =>
									ControlFunctions.handleClickEvent(draw, canvas, dropdownRef, controlComponentRefs, level, event)
								}
								onStyleLoad={(e) => {
									if (e && !canvas) {
										setupControls(e);
									}
									//Check to see if the style was manually toggled
									if (ControlFunctions.checkStyleToggle()) {
										canvas = e;
										dropdownRef.current.innerRef.current.getFlightImage();
										ControlFunctions.refreshToggles(draw, canvas, controlComponentRefs);
										canvas.jumpTo({
											bearing: 0,
											center: dropdownRef.current.innerRef.current.coords,
											zoom: level === "field" ? 18 : level === "trial" ? 19 : 17,
											pitch: 0
										});
										if (level === "trial") {
											ControlFunctions.fitToHeight(canvas, "trial");
										} else if (level != "flight") {
											ControlFunctions.fitToHeight(canvas, "field");
										}
										ControlFunctions.styleToggle();
										setupControls(e);
									}

									const nav = new MapboxGl.NavigationControl({
										showZoom: false,
										visualizePitch: true
									});
									e.addControl(nav);
								}}
							>
								<DrawControl
									displayControlsDefault={false}
									userProperties={true}
									controls={{}}
									styles={styles()}
									modes={{
										...MapboxDraw.modes,
										new_field_mode: NewFieldMode,
										simple_select: overrideSimpleSelect,
										direct_select: overrideDirectSelect,
										line_measure: LineMeasurementMode
									}}
									ref={draw}
									onDrawUpdate={(e) => ControlFunctions.handleUpdate(e, draw, false, orthoAnalyzedModal, canvas)}
									onDrawCreate={(e) =>
										ControlFunctions.handleCreate(
											e,
											draw,
											canvas,
											dropdownRef,
											controlComponentRefs,
											addFieldModal,
											addExistingFieldsModal,
											clientId,
											getTokenSilently,
											dispatch,
											moduleNavigation.flightId
										)
									}
									onDrawCombine={(e) => {
										ControlFunctions.combine(e);
									}}
									onDrawUncombine={(e) => {
										ControlFunctions.uncombine(e, draw, canvas, controlComponentRefs);
									}}
								/>
							</Map>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column></Grid.Column>
					{level === "flight" && !viewer && !edit && (
						//-- Align Fields (i.e. Ortho Alignment)
						<Grid.Column width="8">
							<strong>Align Fields</strong>
							<ol>
								<li>Zoom to the area where the field resides.</li>
								<li>
									Align the plots in the field by selecting the plots and then using the arrow keys.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<div>
												Align the grid cells (i.e. plots) so the crop lines (toggled by using the toolbar to the left)
												lay on top of the rows of crops within the plot.
											</div>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</li>
								<li>
									<>
										Lock the field using the padlock button in the toolbar to the left. This will confirm your changes.
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<div>
													The grid will appear orange until the field is locked. When the grid is aligned to your
													liking, locking the field will confirm the correct positioning of the grid.
													<br />
													Not locking the field will result in lost changes if you leave the page or Save and Continue.
												</div>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Icon name="info circle"></Icon>
										</Tooltip>
									</>
								</li>
								<li>Add fields with existing location data by using the Fields dropdown list above the map.</li>
								<li>
									Remove fields from the flight by using the trash can button in the Fields dropdown list above the map.
								</li>
							</ol>
						</Grid.Column>
					)}
					{level === "flight" && !viewer && edit && (
						//-- Add New Fields (i.e. Plot Segmentation)
						<Grid.Column width="8">
							<strong>Add New Field</strong>
							<ol>
								<li>Zoom to the area where the field resides.</li>
								<li>In the toolbar to the left, click the Add Field button (+).</li>
								<li>
									<>
										Click the spot on the image where the Lower Left corner of the field resides.
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={"This identifies plot (1, 1) in the field."}
											transitionName="rc-tooltip-zoom"
										>
											<Icon name="info circle"></Icon>
										</Tooltip>
									</>
								</li>
								<li>Click the spot on the image where the Upper Left corner of the field resides.</li>
								<li>
									<>
										Add an existing field or create a new field at the marked location.
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<div>A grid will be generated for you based on the field information you gave aerialPLOT.</div>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Icon name="info circle"></Icon>
										</Tooltip>
									</>
								</li>
								<li>
									Align the plots in the field by selecting the plots and then using the arrow keys.
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={
											<div>
												Align the grid cells (i.e. plots) so the crop lines (toggled by using the toolbar to the left)
												lay on top of the rows of crops within the plot.
												<br />
												Individual plots can be moved and rotated, or multiple plots can be manipulated at the same
												time.
											</div>
										}
										transitionName="rc-tooltip-zoom"
									>
										<Icon name="info circle"></Icon>
									</Tooltip>
								</li>
								<li>
									<>
										Lock the field using the padlock button in the toolbar to the left. This will confirm your changes.
										<Tooltip
											placement="bottom"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											trigger="hover"
											overlay={
												<div>
													The grid will appear orange until the field is locked. When the grid is aligned to your
													liking, locking the field will confirm the correct positioning of the grid.
													<br />
													Not locking the field will result in lost changes if you leave the page or Save and Continue.
												</div>
											}
											transitionName="rc-tooltip-zoom"
										>
											<Icon name="info circle"></Icon>
										</Tooltip>
									</>
								</li>
								<li>
									Remove fields from the flight by using the trash can button in the Fields dropdown list above the map.
								</li>
							</ol>
						</Grid.Column>
					)}
					<Grid.Column width="7">
						{level === "flight" && !viewer && (
							<>
								<strong>Map Controls</strong>
								<ul>
									<li>
										<i>Select Field</i>: Toggle the Lower Left corners using the toolbar to the left. Click the Lower
										Left corner of the field.
									</li>
									<li>
										<i>Select Area</i>: Hold shift and click-drag mouse
									</li>
									<li>
										<i>Nudge Plot</i>: Select plot and use arrow keys
									</li>
									<li>
										<i>Fine Controls</i>: Hold ctrl and nudge plots or use the rotate buttons
									</li>
									<li>
										<i>Coarse Controls</i>: Hold alt and nudge plots or use the rotate buttons
									</li>
								</ul>
								<strong>Auto Alignment Legend</strong>
								<ul className="autoAlignLegend">
									<li className="failed">Failed Alignment</li>
									<li className="poor">Poor Alignment</li>
									<li className="low">Low Confidence Alignment</li>
									<li className="medium">Medium Confidence Alignment</li>
								</ul>
							</>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{(!edit || !viewer) && (
				<>
					<AddExistingFieldsModal
						ref={addExistingFieldsModal}
						draw={draw}
						addFieldModal={addFieldModal}
						canvas={() => {
							return canvas;
						}}
						dropdownRef={dropdownRef}
						componentRefs={controlComponentRefs}
					/>
					<AddFieldModal
						ref={addFieldModal}
						draw={draw}
						canvas={() => {
							return canvas;
						}}
						dropdownRef={dropdownRef}
						componentRefs={controlComponentRefs}
					/>
					<OrthoAnalyzedModal ref={orthoAnalyzedModal} />
				</>
			)}
			{!viewer && (
				<AlignPlotsModal
					ref={alignPlotsModal}
					draw={draw}
					canvas={() => {
						return canvas;
					}}
					orthoImageTypeId={() => {
						return orthoImageTypeId;
					}}
					controlComponentRefs={controlComponentRefs}
				/>
			)}
			<ViewerDataExportModal ref={viewerDataExportModal}></ViewerDataExportModal>
		</Segment>
	);
};

Ortho.propTypes = {
	level: PropTypes.oneOf(["flight", "field", "trial"]),
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool,
	setOrthoImageTypes: PropTypes.func.isRequired,
	setDraw: PropTypes.func,
	setImageFound: PropTypes.func.isRequired
};

export default Ortho;
