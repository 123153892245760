// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".firstRow {\n\tmargin-top: 30px;\n}\n\n.rowHeight {\n\tmargin-top: 40px;\n}\n.modal.ui.modal .content.scrolling.content {\n\tmax-height: calc(80vh);\n}\n\n.ui.table thead tr:first-child > th {\n\ttop: 0 !important;\n}\n\n.ui.multiple.dropdown > .text {\n\tmargin-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/GroundData/NewGroundData/styles.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;AACA;CACC,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,cAAc;AACf","sourcesContent":[".firstRow {\n\tmargin-top: 30px;\n}\n\n.rowHeight {\n\tmargin-top: 40px;\n}\n.modal.ui.modal .content.scrolling.content {\n\tmax-height: calc(80vh);\n}\n\n.ui.table thead tr:first-child > th {\n\ttop: 0 !important;\n}\n\n.ui.multiple.dropdown > .text {\n\tmargin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstRow": "firstRow",
	"rowHeight": "rowHeight",
	"modal": "modal",
	"ui": "ui",
	"content": "content",
	"scrolling": "scrolling",
	"table": "table",
	"multiple": "multiple",
	"dropdown": "dropdown",
	"text": "text"
};
export default ___CSS_LOADER_EXPORT___;
