import * as types from "./actionTypes";
import * as uavApi from "../../apis/uavApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getUavSuccess() {
	return { type: types.UAV_GET_UAV_SUCCESS };
}

export function getUavDetailsSuccess() {
	return { type: types.UAV_GET_UAV_DETAILS_SUCCESS };
}

export function createUavSuccess() {
	return { type: types.UAV_CREATE_SUCCESS };
}

export function updateUavSuccess() {
	return { type: types.UAV_UPDATE_SUCCESS };
}

export function deleteUavSuccess() {
	return { type: types.UAV_DELETE_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getUavs(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uavApi
				.getUavs(accessToken, clientId)
				.then((res) => {
					dispatch(getUavSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getUavDetailsData(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uavApi
				.getUavDetailsData(accessToken, clientId)
				.then((res) => {
					dispatch(getUavDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createUav(accessToken, uav) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uavApi
				.createUav(accessToken, uav)
				.then((res) => {
					dispatch(createUavSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateUav(accessToken, uav) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uavApi
				.updateUav(accessToken, uav)
				.then((res) => {
					dispatch(updateUavSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteUav(accessToken, uavId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uavApi
				.deleteUav(accessToken, uavId)
				.then((res) => {
					dispatch(deleteUavSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
