import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import _ from "lodash";

import Tooltip from "rc-tooltip";

import { Menu, Icon } from "semantic-ui-react";

import { useUserAuth } from "../../../hooks/useUserAuth";

import "./style.css";

const LeftNav = ({ navItems, activeItem }) => {
	const userAuth = useUserAuth();

	return (
		<Menu
			id="leftNav"
			vertical
			icon
			style={{
				height: "100%",
				border: "unset",
				backgroundColor: "rgb(65,101,134)",
				borderRadius: "unset"
			}}
		>
			{navItems && navItems.length && activeItem
				? _.map(navItems, (navItem, index) => {
						return (
							navItem.enabled &&
							navItem.showInNav &&
							userAuth.userCanAccessModule(navItem) && (
								<Tooltip
									key={index}
									placement="right"
									align={{ offset: [-8, 0] }}
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={<p>{navItem.linkText}</p>}
									transitionName="rc-tooltip-zoom"
								>
									<Menu.Item
										key={navItem.id}
										as={Link}
										name={navItem.name}
										to={navItem.paths[0]}
										style={{ height: 50 }}
										active={activeItem.name === navItem.name}
									>
										<Icon.Group>
											{navItem.cornerIcon ? (
												<>
													<Icon name={navItem.icon} corner="top left" style={{ fontSize: 22, textShadow: "unset" }} />
													<Icon name={navItem.cornerIcon} corner="bottom right" style={{ fontSize: 12 }} />
													{/* textShadow: "-1px -1px 0 rgb(255, 255, 255, 1), 1px -1px 0 rgb(255, 255, 255, 1), -1px 1px 0 rgb(255, 255, 255, 1), 1px 1px 0 rgb(255, 255, 255, 1)" */}
												</>
											) : (
												<Icon name={navItem.icon} size="large" />
											)}
										</Icon.Group>
									</Menu.Item>
								</Tooltip>
							)
						);
				  })
				: null}
		</Menu>
	);
};

LeftNav.propTypes = {
	navItems: PropTypes.array.isRequired,
	activeItem: PropTypes.object.isRequired
};

export default LeftNav;
