// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".horizontalArrowShaft {\n\theight: 1px;\n\tbackground-color: black;\n}\n\n.horizontalArrowHead {\n\twidth: 0px;\n\theight: 0px;\n\tborder: 5px solid transparent;\n\tborder-right: 0;\n\tborder-left: 7px solid black;\n}\n\n.verticalArrowShaft {\n\twidth: 1px;\n\tbackground-color: black;\n}\n\n.verticalArrowHead {\n\twidth: 0px;\n\theight: 0px;\n\tborder: 5px solid transparent;\n\tborder-top: 0;\n\tborder-bottom: 7px solid black;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Trials/TrialMapping/arrow.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,uBAAuB;AACxB;;AAEA;CACC,UAAU;CACV,WAAW;CACX,6BAA6B;CAC7B,eAAe;CACf,4BAA4B;AAC7B;;AAEA;CACC,UAAU;CACV,uBAAuB;AACxB;;AAEA;CACC,UAAU;CACV,WAAW;CACX,6BAA6B;CAC7B,aAAa;CACb,8BAA8B;AAC/B","sourcesContent":[".horizontalArrowShaft {\n\theight: 1px;\n\tbackground-color: black;\n}\n\n.horizontalArrowHead {\n\twidth: 0px;\n\theight: 0px;\n\tborder: 5px solid transparent;\n\tborder-right: 0;\n\tborder-left: 7px solid black;\n}\n\n.verticalArrowShaft {\n\twidth: 1px;\n\tbackground-color: black;\n}\n\n.verticalArrowHead {\n\twidth: 0px;\n\theight: 0px;\n\tborder: 5px solid transparent;\n\tborder-top: 0;\n\tborder-bottom: 7px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontalArrowShaft": "horizontalArrowShaft",
	"horizontalArrowHead": "horizontalArrowHead",
	"verticalArrowShaft": "verticalArrowShaft",
	"verticalArrowHead": "verticalArrowHead"
};
export default ___CSS_LOADER_EXPORT___;
