import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Grid, Button, Icon, Checkbox } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import TimecourseComponent from "../../../Lumber/Timecourse";
import { toast } from "react-toastify";

const BulkAssessmentsModal = ({
	clickedPhenotypeId,
	tableData,
	flights,
	flightSelections,
	plotAnalysisResults,
	trialData,
	canCalculateGdus,
	calculateGdus,
	setBatchAssessments,
	setFlightSelections,
	setModalClosed
}) => {
	//-- Data Source
	const [selectedPhenotypeId, setSelectedPhenotypeId] = useState(clickedPhenotypeId);
	const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);
	const [flightsInPhenotype, setFlightsInPhenotype] = useState([]);
	const [filteredPlotAnalysisResults, setFilteredPlotAnalysisResults] = useState([]);

	//-- UI Control
	const [isFirst, setIsFirst] = useState(false);
	const [isLast, setIsLast] = useState(false);
	const [checkedFlights, setCheckedFlights] = useState([]);
	const [viewPlantFlight, setViewPlantFlight] = useState(false);
	const [viewHarvestFlight, setViewHarvestFlight] = useState(false);

	const DATE_FORMAT = "M/D";

	//-- Setting up flights for current phenotype
	useEffect(() => {
		if (flights && selectedPhenotypeId) {
			let selectedPhenotypeData = _.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId });
			let filteredFlights = [];

			if (_.some(flights, (f) => f.plantFlight)) {
				filteredFlights.push(_.find(flights, "plantFlight"));
			}

			filteredFlights = [
				...filteredFlights,
				..._.filter(flights, (f) => f.analysisIds.includes(selectedPhenotypeData.analysisId))
			];

			if (_.some(flights, (f) => f.harvestFlight)) {
				filteredFlights.push(_.find(flights, "harvestFlight"));
			}

			setFlightsInPhenotype(filteredFlights);
		}
	}, [flights, selectedPhenotypeId]);

	//-- Sets up checkbox states on component load
	useEffect(() => {
		if (flightSelections && tableData) {
			let checkboxes = [];
			_.map(tableData, (td) => {
				let selectionsForPhenotype = _.filter(
					flightSelections,
					(fs) =>
						fs.analysisId === td.analysisId &&
						fs.analysisTypeId === td.analysisTypeId &&
						fs.curveModelId === td.curveModelId &&
						(fs.quantifiedRegionTypeId === td.quantifiedRegionTypeId ||
							fs.quantifiedRegionTypeId === td.secondaryQuantifiedRegionTypeId)
				);

				let checkboxOpt = {
					curveModelPhenotypeId: td.curveModelPhenotypeId,
					selections: selectionsForPhenotype
				};

				checkboxes.push(checkboxOpt);
			});

			setCheckedFlights(checkboxes);

			//-- Fliter out plot analysis results
			let selections = _.find(checkboxes, { curveModelPhenotypeId: selectedPhenotypeId })?.selections;
			let localFilteredPlotAnalysisResults = _.filter(plotAnalysisResults, (par) => {
				if (selections) {
					return _.map(selections, "flightId").includes(par.flightId);
				} else return true;
			});

			let analysisIdForPhenotype = _.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.analysisId;
			setSelectedAnalysisId(analysisIdForPhenotype);

			setFilteredPlotAnalysisResults(localFilteredPlotAnalysisResults);
			setViewPlantFlight(_.some(selections, "plantFlight"));
			setViewHarvestFlight(_.some(selections, "harvestFlight"));
		}
	}, [flightSelections, tableData]);

	//-- Check if clicked phenotype is first or last
	useEffect(() => {
		if (clickedPhenotypeId && tableData?.length > 0) {
			checkIfFirst(clickedPhenotypeId);
			checkIfLast(clickedPhenotypeId);
		}
	}, [clickedPhenotypeId]);

	useEffect(() => {
		if (selectedPhenotypeId) {
			//-- Fliter out plot analysis results
			if (checkedFlights?.length > 0) {
				let selections = _.find(checkedFlights, { curveModelPhenotypeId: selectedPhenotypeId })?.selections;
				let localFilteredPlotAnalysisResults = _.filter(plotAnalysisResults, (par) => {
					if (selections) {
						return _.map(selections, "flightId").includes(par.flightId);
					} else return true;
				});

				let analysisIdForPhenotype = _.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.analysisId;
				setSelectedAnalysisId(analysisIdForPhenotype);

				setFilteredPlotAnalysisResults(localFilteredPlotAnalysisResults);
				setViewPlantFlight(_.some(selections, "plantFlight"));
				setViewHarvestFlight(_.some(selections, "harvestFlight"));
			}
		}
	}, [selectedPhenotypeId]);

	//-- Checks if current phenotype is first in table data list
	const checkIfFirst = (phenotypeId) => {
		if (tableData?.length > 0) {
			let index = _.findIndex(tableData, { curveModelPhenotypeId: phenotypeId });
			setIsFirst(index === 0);
		}
	};

	//-- Checks if current phenotype is last in table data list
	const checkIfLast = (phenotypeId) => {
		if (tableData?.length > 0) {
			let index = _.findIndex(tableData, { curveModelPhenotypeId: phenotypeId });
			setIsLast(index === tableData.length - 1);
		}
	};

	//-- Jump to first phenotype in table data list
	const first = () => {
		if (tableData?.length > 0) {
			let phenotypeId = tableData[0].curveModelPhenotypeId;
			setSelectedPhenotypeId(phenotypeId);
			checkIfFirst(phenotypeId);
			checkIfLast(phenotypeId);
		}
	};

	//-- Jump to last phenotype in table data list
	const last = () => {
		if (tableData?.length > 0) {
			let phenotypeId = tableData[tableData.length - 1].curveModelPhenotypeId;
			setSelectedPhenotypeId(phenotypeId);
			checkIfFirst(phenotypeId);
			checkIfLast(phenotypeId);
		}
	};

	//-- Jump to next phenotype in table data list
	const next = () => {
		if (tableData?.length > 0) {
			let currentIndex = _.findIndex(tableData, { curveModelPhenotypeId: selectedPhenotypeId });
			let phenotypeId = tableData[currentIndex + 1].curveModelPhenotypeId;
			setSelectedPhenotypeId(phenotypeId);
			checkIfFirst(phenotypeId);
			checkIfLast(phenotypeId);
		}
	};

	//-- Jump to previous phenotype in table data list
	const previous = () => {
		if (tableData?.length > 0) {
			let currentIndex = _.findIndex(tableData, { curveModelPhenotypeId: selectedPhenotypeId });
			let phenotypeId = tableData[currentIndex - 1].curveModelPhenotypeId;
			setSelectedPhenotypeId(phenotypeId);
			checkIfFirst(phenotypeId);
			checkIfLast(phenotypeId);
		}
	};

	//-- Updates flight selection list base on checked value
	//-- Used in select all flights as well
	const selectFlight = (flight, checked, localFs = null) => {
		let localFlightSelections = localFs ?? _.cloneDeep(flightSelections);
		let selectedPhenotype = _.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId });

		let uniqueFlightsForAnalysis = _.uniqBy(
			_.filter(
				plotAnalysisResults,
				(p) =>
					p.analysisId === selectedPhenotype.analysisId &&
					(p.quantifiedRegionTypeId === selectedPhenotype.quantifiedRegionTypeId ||
						p.quantifiedRegionTypeId === selectedPhenotype.secondaryQuantifiedRegionTypeId)
			),
			"flightId"
		);

		if (checked) {
			let flightToAdd = {
				analysisId: selectedPhenotype.analysisId,
				analysisTypeId: selectedPhenotype.analysisTypeId,
				curveModelId: selectedPhenotype.curveModelId,
				quantifiedRegionTypeId: selectedPhenotype.quantifiedRegionTypeId,
				flightId: !(flight.plantFlight || flight.harvestFlight) ? flight.flightId : null,
				harvestFlight: flight.harvestFlight ?? false,
				plantFlight: flight.plantFlight ?? false,
				existsOnAnalysis: _.map(uniqueFlightsForAnalysis, "flightId").includes(flight.flightId)
			};

			localFlightSelections.push(flightToAdd);
		} else {
			if (flight.plantFlight) {
				localFlightSelections = _.filter(
					localFlightSelections,
					(f) =>
						!(
							f.analysisId === selectedPhenotype.analysisId &&
							f.analysisTypeId === selectedPhenotype.analysisTypeId &&
							f.curveModelId === selectedPhenotype.curveModelId &&
							(f.quantifiedRegionTypeId === selectedPhenotype.quantifiedRegionTypeId ||
								f.quantifiedRegionTypeId === selectedPhenotype.secondaryQuantifiedRegionTypeId) &&
							f.plantFlight === flight.plantFlight
						)
				);
			} else if (flight.harvestFlight) {
				localFlightSelections = _.filter(
					localFlightSelections,
					(f) =>
						!(
							f.analysisId === selectedPhenotype.analysisId &&
							f.analysisTypeId === selectedPhenotype.analysisTypeId &&
							f.curveModelId === selectedPhenotype.curveModelId &&
							(f.quantifiedRegionTypeId === selectedPhenotype.quantifiedRegionTypeId ||
								f.quantifiedRegionTypeId === selectedPhenotype.secondaryQuantifiedRegionTypeId) &&
							f.harvestFlight === flight.harvestFlight
						)
				);
			} else {
				localFlightSelections = _.filter(
					localFlightSelections,
					(f) =>
						!(
							f.analysisId === selectedPhenotype.analysisId &&
							f.analysisTypeId === selectedPhenotype.analysisTypeId &&
							f.curveModelId === selectedPhenotype.curveModelId &&
							(f.quantifiedRegionTypeId === selectedPhenotype.quantifiedRegionTypeId ||
								f.quantifiedRegionTypeId === selectedPhenotype.secondaryQuantifiedRegionTypeId) &&
							f.flightId === flight.flightId
						)
				);
			}
		}

		if (!localFs) {
			setFlightSelections(localFlightSelections);
		} else {
			return localFlightSelections;
		}
	};

	//-- Updates flight selections in bulk
	const selectAllFlights = (checked) => {
		let localFlightSelections = _.cloneDeep(flightSelections);

		if (checked) {
			//-- Only update flight selections that aren't alraedy selected
			let flightSelectionsForPhenotype = _.find(checkedFlights, {
				curveModelPhenotypeId: selectedPhenotypeId
			})?.selections;
			let flightsToUpdate = _.filter(
				flightsInPhenotype,
				(f) =>
					_.find(
						flightSelectionsForPhenotype,
						(fsp) =>
							(fsp.flightId && fsp.flightId === f.flightId) ||
							(fsp.plantFlight && f.plantFlight) ||
							(fsp.harvestFlight && f.harvestFlight)
					) === undefined
			);

			_.map(flightsToUpdate, (f) => {
				localFlightSelections = selectFlight(f, checked, localFlightSelections);
			});
		} else {
			let selectedPhenotype = _.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId });

			localFlightSelections = _.filter(
				localFlightSelections,
				(f) =>
					!(
						f.analysisId === selectedPhenotype.analysisId &&
						f.analysisTypeId === selectedPhenotype.analysisTypeId &&
						f.curveModelId === selectedPhenotype.curveModelId &&
						(f.quantifiedRegionTypeId === selectedPhenotype.quantifiedRegionTypeId ||
							f.quantifiedRegionTypeId === selectedPhenotype.secondaryQuantifiedRegionTypeId)
					)
			);
		}

		setFlightSelections(localFlightSelections);
	};

	//-- Saves changes made in modal for current phenotype and updates status
	const addToBatch = () => {
		let checkedPhenotypeFlights = _.find(checkedFlights, { curveModelPhenotypeId: selectedPhenotypeId })?.selections?.length;
		if (checkedPhenotypeFlights < 2) {
			return toast.error("Need to select two or more flights");
		}
		let localTableData = _.cloneDeep(tableData);
		let selectedPhenotypeIndex = _.findIndex(localTableData, { curveModelPhenotypeId: selectedPhenotypeId });
		localTableData[selectedPhenotypeIndex].status = "Ready - Batched";
		setBatchAssessments(localTableData);
	};

	const removeFromBatch = () => {
		let localTableData = _.cloneDeep(tableData);
		let selectedPhenotypeIndex = _.findIndex(localTableData, { curveModelPhenotypeId: selectedPhenotypeId });
		localTableData[selectedPhenotypeIndex].status = localTableData[selectedPhenotypeIndex].savedStatus ?? null;
		setBatchAssessments(localTableData);
	};

	return (
		<Grid>
			<Grid.Row>
				<Grid.Column width="4">
					<Grid>
						<Grid.Row verticalAlign="middle">
							<Grid.Column width="2">
								<Button icon onClick={() => first()} disabled={isFirst}>
									<Icon name="fast backward" />
								</Button>
							</Grid.Column>
							<Grid.Column width="2">
								<Button icon onClick={() => previous()} disabled={isFirst}>
									<Icon name="play" style={{ transform: "rotate(180deg)" }} />
								</Button>
							</Grid.Column>
							<Grid.Column width="1" />
							<Grid.Column width="6" textAlign="center" verticalAlign="middle">
								<h2>{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.curveModelPhenotypeName}</h2>
							</Grid.Column>
							<Grid.Column width="2">
								<Button icon onClick={() => next()} disabled={isLast}>
									<Icon name="play" />
								</Button>
							</Grid.Column>
							<Grid.Column width="2">
								<Button icon onClick={() => last()} disabled={isLast}>
									<Icon name="fast forward" />
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width="12" textAlign="right" verticalAlign="middle">
					<Button onClick={() => setModalClosed()}>Close</Button>
					<Button primary onClick={() => addToBatch()}>
						Add To Batch
					</Button>
					<Button
						negative
						onClick={() => removeFromBatch()}
						disabled={_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.status !== "Ready - Batched"}
					>
						Remove From Batch
					</Button>
					<Icon.Group verticalAlign="middle" size="big" style={{ marginLeft: "10px" }}>
						<Icon name="shopping cart" />
						{_.find(tableData, { status: "Ready - Batched" }) && (
							<div
								style={{
									top: "-15px",
									right: "-15px",
									left: "auto",
									bottom: "auto",
									width: "1.3em",
									height: "1em",
									backgroundColor: "red",
									color: "white",
									position: "absolute",
									textAlign: "center"
								}}
							>
								{_.filter(tableData, (td) => td.status === "Ready - Batched").length}
							</div>
						)}
					</Icon.Group>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width="4">
					<Grid
						style={{
							width: "100%",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							position: "absolute",
							fontSize: "1.25em"
						}}
					>
						<Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
							<Grid.Column width="9" textAlign="right">
								Ortho:
							</Grid.Column>
							<Grid.Column width="7" textAlign="left">
								{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.orthoImageTypeAbbreviation}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
							<Grid.Column width="9" textAlign="right">
								Timing:
							</Grid.Column>
							<Grid.Column width="7" textAlign="left">
								{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.curveModelName}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
							<Grid.Column width="9" textAlign="right">
								Model:
							</Grid.Column>
							<Grid.Column width="7" textAlign="left">
								{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.curveModelFitFunctionName}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
							<Grid.Column width="9" textAlign="right">
								Status:
							</Grid.Column>
							{!_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.status && (
								<Grid.Column width="7" textAlign="left" style={{ color: "grey" }}>
									Not Yet Analyzed
								</Grid.Column>
							)}
							{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.status === "Ready - Batched" && (
								<Grid.Column width="7" textAlign="left" style={{ color: "#2185d0" }}>
									Ready - Batched
								</Grid.Column>
							)}
							{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.status === "Success" && (
								<Grid.Column width="7" textAlign="left" style={{ color: "#2cbf1f" }}>
									Success
								</Grid.Column>
							)}
							{_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.status === "Error" && (
								<Grid.Column width="7" textAlign="left" style={{ color: "#db2828" }}>
									Run Failed
								</Grid.Column>
							)}
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width="12">
					<TimecourseComponent
						showAnalysisSelection={false}
						showTable={false}
						showLineChart={true}
						showBoxWhisker={false}
						runMeanComparison={false}
						analysisDataResults={filteredPlotAnalysisResults}
						analysis={selectedAnalysisId}
						trialDataResults={trialData}
						viewPlantFlight={viewPlantFlight}
						viewHarvestFlight={viewHarvestFlight}
						parentSelectedAnalysisType={
							_.find(tableData, { curveModelPhenotypeId: selectedPhenotypeId })?.analysisTypeId
						}
						isForAnalysisTimecoursePage={false}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={2 + (flightsInPhenotype?.length ?? 0)}>
				<Grid.Column textAlign="center" width="2">
					<Checkbox
						onClick={(e, { checked }) => {
							selectAllFlights(checked);
							removeFromBatch();
						}}
						checked={
							_.find(checkedFlights, { curveModelPhenotypeId: selectedPhenotypeId })?.selections?.length ===
							flightsInPhenotype?.length
						}
					/>
					<br />
					Check All
				</Grid.Column>
				{_.map(flightsInPhenotype, (f, i) => {
					return (
						<Grid.Column key={i} textAlign="center">
							<Checkbox
								onClick={(e, { checked }) => {
									selectFlight(f, checked);
									removeFromBatch();
								}}
								checked={
									_.find(
										_.find(checkedFlights, { curveModelPhenotypeId: selectedPhenotypeId })?.selections,
										(s) =>
											(f.flightId && f.flightId === s.flightId) ||
											(f.plantFlight && s.plantFlight) ||
											(f.harvestFlight && s.harvestFlight)
									) != undefined
								}
							/>
							<br />
							{moment(f.flightDate).format(DATE_FORMAT)}
							{canCalculateGdus && (
								<>
									<br />({calculateGdus(f)})
								</>
							)}
						</Grid.Column>
					);
				})}
			</Grid.Row>
		</Grid>
	);
};

BulkAssessmentsModal.propTypes = {
	clickedPhenotypeId: PropTypes.string,
	tableData: PropTypes.array.isRequired,
	flights: PropTypes.array.isRequired,
	flightSelections: PropTypes.array.isRequired,
	plotAnalysisResults: PropTypes.array.isRequired,
	trialData: PropTypes.object.isRequired,
	canCalculateGdus: PropTypes.bool.isRequired,
	calculateGdus: PropTypes.func.isRequired,
	setBatchAssessments: PropTypes.func.isRequired,
	setFlightSelections: PropTypes.func.isRequired,
	setModalClosed: PropTypes.func.isRequired
};

export default BulkAssessmentsModal;
