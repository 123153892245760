import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Loader, Segment, Button, Table, Dropdown, Pagination, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as farmActions from "../../../../redux/actions/farmActions";

import FmAddEditFarm from "../../../Lumber/Farms/FmAddEditFarm";

const FarmManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	//-- Data Source
	const [clientName, setClientName] = useState(null);
	const [farms, setFarms] = useState(null);

	//-- Selected Data
	const [selectedFarm, setSelectedFarm] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			setClientName(userAuth.user.userInfo.clientMemberships.find((cm) => cm.clientId === clientId).clientName);
			getFarms(clientId);
		}
	}, [userAuth.isReady, clientId]);

	const getFarms = async (clientId) => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(farmActions.getFarms(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setFarms(res.data);
					setLoading(false);
				} else if (res.statusCode === 404) {
					setLoading(false);
				} else {
					toast.error("Unable to load farms. Please try again.");
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load farms. Please try again.");
				setLoading(false);
			});

		setLoading(false);
	};

	const farmCreated = () => {
		getFarms(clientId);
		setShowCreateModal(false);
	};

	const farmUpdated = () => {
		getFarms(clientId);
		setShowUpdateModal(false);
	};

	const deleteFarm = async (farmId) => {
		const accessToken = await getTokenSilently();

		dispatch(farmActions.deleteFarm(accessToken, clientId, farmId))
			.then((res) => {
				if (res.statusCode === 200) {
					getFarms(clientId);
					toast.success("Farm deleted successfully");
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								getFarms(clientId);
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
			})
			.catch((err) => {
				toast.error("Unable to delete farm. Please try again.");
				console.log(err);
			});
	};

	const modalCancelled = () => {
		setShowCreateModal(false);
		setShowUpdateModal(false);
	};

	const handleOpenCreateModal = () => {
		setShowCreateModal(true);
	};

	const handleOpenUpdateModal = (farmId) => {
		const selFarm = farms.find((f) => f.farmId === farmId);
		setSelectedFarm(selFarm);
		setShowUpdateModal(true);
	};

	//-- Table
	const DATE_FORMAT = "MM/DD/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState([]);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	useEffect(() => {
		if (farms) {
			setTotalPages(Math.ceil(farms.length / itemsPerPage));
			setDataSource(farms);
			setTableData(dataSource);
		}
	}, [farms]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	}

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Farm Management</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{clientName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{loading && <Loader active />}
			{!loading && (
				<>
					<Modal
						open={showCreateModal}
						trigger={
							<Button
								floated="right"
								style={{ marginBottom: 10 }}
								color="green"
								content="New Farm"
								onClick={() => {
									handleOpenCreateModal();
								}}
							/>
						}
					>
						<Modal.Header>New Farm</Modal.Header>
						<Modal.Content style={{ paddingBottom: "unset" }}>
							<FmAddEditFarm
								clientId={clientId}
								farm={null}
								onCancel={modalCancelled}
								onFarmAdded={farmCreated}
								onFarmUpdated={farmUpdated}
							/>
						</Modal.Content>
					</Modal>
					<Table celled striped sortable selectable color="blue">
						<Table.Header>
							<Table.Row textAlign="center">
								<Table.HeaderCell
									sorted={sortColumn === "farmName" ? sortDirection : null}
									onClick={() => handleSort("farmName")}
								>
									Name (abbr.)
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "address1" ? sortDirection : null}
									onClick={() => handleSort("address1")}
								>
									Address
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "city" ? sortDirection : null}
									onClick={() => handleSort("city")}
								>
									City
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "state" ? sortDirection : null}
									onClick={() => handleSort("state")}
								>
									State
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "zip" ? sortDirection : null}
									onClick={() => handleSort("zip")}
								>
									Zip
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "county" ? sortDirection : null}
									onClick={() => handleSort("county")}
								>
									County
								</Table.HeaderCell>
								<Table.HeaderCell>Lat, Long</Table.HeaderCell>
								<Table.HeaderCell>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{_.map(
								tableData,
								({
									farmId,
									farmName,
									abbreviation,
									address1,
									address2,
									city,
									stateName,
									zip,
									county,
									lat,
									long,
									canDelete
								}) => {
									return (
										<Table.Row key={farmId}>
											<Table.Cell>{`${farmName} (${abbreviation})`}</Table.Cell>
											<Table.Cell>
												{address1} <br /> {address2}
											</Table.Cell>
											<Table.Cell textAlign="center">{city}</Table.Cell>
											<Table.Cell textAlign="center">{stateName}</Table.Cell>
											<Table.Cell textAlign="center">{zip}</Table.Cell>
											<Table.Cell textAlign="center">{county}</Table.Cell>
											<Table.Cell textAlign="center">{lat && long ? `${lat}, ${long}` : null}</Table.Cell>
											<Table.Cell textAlign="center">
												<Button.Group>
													<Button
														primary
														content="Manage"
														onClick={() => {
															handleOpenUpdateModal(farmId);
														}}
													/>
													{canDelete && (
														<>
															<Button.Or />
															<Button
																negative
																content="Delete"
																onClick={() => {
																	deleteFarm(farmId);
																}}
															/>
														</>
													)}
												</Button.Group>
											</Table.Cell>
										</Table.Row>
									);
								}
							)}
						</Table.Body>

						<Table.Footer>
							<Table.Row textAlign="right">
								<Table.HeaderCell colSpan="11">
									<span style={{ marginRight: 10 }}>
										{dataSource.length === 1 ? `${dataSource.length} farm found` : `${dataSource.length} farms found`}
									</span>
									<Pagination
										onPageChange={(e, pageInfo) => {
											setActivePage(pageInfo.activePage);
										}}
										boundaryRange={5}
										siblingRange={1}
										totalPages={totalPages}
										activePage={activePage}
									/>
									<Dropdown
										onChange={(e, { value }) => {
											setItemsPerPage(value);
										}}
										selection
										options={[
											{ key: 1, text: 5, value: 5 },
											{ key: 2, text: 10, value: 10 },
											{ key: 3, text: 15, value: 15 },
											{ key: 4, text: 25, value: 25 },
											{ key: 5, text: 50, value: 50 }
										]}
										value={itemsPerPage}
										compact
										style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
									/>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					</Table>
					<Modal open={showUpdateModal}>
						<Modal.Header>Edit Farm</Modal.Header>
						<Modal.Content style={{ paddingBottom: "unset" }}>
							<FmAddEditFarm
								clientId={clientId}
								farm={selectedFarm}
								onCancel={modalCancelled}
								onFarmAdded={farmCreated}
								onFarmUpdated={farmUpdated}
							/>
						</Modal.Content>
					</Modal>
				</>
			)}
		</Segment>
	);
};

export default FarmManagement;
