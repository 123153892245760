import * as types from "./actionTypes";
import * as treatmentApi from "../../apis/treatmentApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

function inspectTreatmentDataSuccess() {
	return { type: types.TREATMENTS_INSPECT_TREATMENT_DATA_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function inspectTreatmentData(treatmentInfoCSV, trialData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return treatmentApi
				.inspectTreatmentData(treatmentInfoCSV, trialData, accessToken)
				.then((res) => {
					if (res.errors) {
						dispatch(apiCallError(res));
						reject(res);
					} else {
						dispatch(inspectTreatmentDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
