import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import _ from "lodash";

import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";
const PianoChart = ({
	selectedAverageTreatments,
	siteData,
	trialTreatmentId,
	treatmentName,
	minimumAverage,
	maximumAverage,
	needToUpdateAnalytics
}) => {
	const CanvasJSChart = CanvasJSReact.CanvasJSChart;

	//-- Data
	const [chartOptions, setChartOptions] = useState([]);

	//-- UI Control
	const [chartLoading, setChartLoading] = useState(true);

	useEffect(() => {
		if (siteData && selectedAverageTreatments) {
				stageChartOptions();
		}
	}, [siteData, selectedAverageTreatments]);


	function calculateDataPoints() {
		let dataPoints = _.map(siteData, (sd) => {
			return { y: sd.average, label: `${sd.city}, ${sd.stateName}`, color: "#67A2DB" };
		});

		dataPoints = _.orderBy(dataPoints, ["y"]);
		return !dataPoints || dataPoints?.length === 0 ? [] : dataPoints;
	}

	function calculateWinRate(dataPoints) {
		let numberOfWins = _.countBy(dataPoints, (dp) => {
			return dp.y > 0;
		});
		if (!numberOfWins.true || numberOfWins.true === 0) {
			return 0;
		}
		let winRate = Number((numberOfWins.true / dataPoints.length) * 100);
		return winRate % 1 === 0 ? winRate : winRate.toFixed(2);
	}

	function stageChartOptions() {
		let data = calculateDataPoints();
		let winRate = calculateWinRate(data);
		let subTitleName = treatmentName ? `${trialTreatmentId} - ${treatmentName}` : trialTreatmentId;
		let opt = {
			animationEnabled: true,
			theme: "light2",
			title: {
				text: `${winRate}%`,
				horizontalAlign: "right",
				fontSize: 25,
				fontWeight: "bold",
				margin: 5
			},
			subtitles: [{ text: `${subTitleName}`, margin: 0, fontSize: 18, wrap: false }],
			axisY: {
				title: "% Difference",
				includeZero: false,
				minimum: minimumAverage,
				maximum: maximumAverage
			},
			axisX: {
				includeZero: false,
				interval: 1
			},
			axisX2: {
				includeZero: false,
				interval: 1,
				labelAngle: 90
			},
			data: [
				{
					type: "column",
					color: "black",
					indexLabel: "{y}%",
					indexLabelFontSize: 16,
					dataPoints: data
				}
			]
		};
		setChartOptions(opt);
		setChartLoading(false);
	}
	return (
		<Segment basic loading={chartLoading && !needToUpdateAnalytics}>
			<CanvasJSChart options={chartOptions} />
		</Segment>
	);
};

PianoChart.propTypes = {
	selectedAverageTreatments: PropTypes.array,
	siteData: PropTypes.array,
	trialTreatmentId: PropTypes.number,
	treatmentName: PropTypes.string,
	minimumAverage: PropTypes.number,
	maximumAverage: PropTypes.number,
	needToUpdateAnalytics: PropTypes.bool
};

export default PianoChart;
