const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getStates(clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Lookups/GetStates?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
