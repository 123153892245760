import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, FC } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Button, Grid, Loader, Segment, Table } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0/index.js";
import { useUserAuth } from "../../../../hooks/useUserAuth.js";
import { useUserSettings } from "../../../../hooks/useUserSettings.js";
import * as trialActions from "../../../../redux/actions/trialActions.js";
import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget.js";
import TrialMap from "./TrialMap";

type props = {
	trialId: string;
	trialName: string;
	trialDetails: string;
	treatments: trialMapData[];
	navigateToNextStep: () => {};
	navigateToPreviousStep: () => {};
};

type trialMapData = {
	treatmentId: string;
	trialTreatmentId: number;
	treatmentName: string;
	treatmentDescription: string;
	treatmentTypeName: string;
	plotId: string;
	plotName: string;
	column: number;
	range: number;
	seed: string;
	replicate: number;
	color?: string;
};

enum colors {
	"#a9a9a9",
	"#e6194B",
	"#3cb44b",
	"#ffe119",
	"#4363d8",
	"#f58231",
	"#911eb4",
	"#42d4f4",
	"#f032e6",
	"#bfef45",
	"#fabed4",
	"#469990",
	"#dcbeff",
	"#9A6324",
	"#fffac8",
	"#800000",
	"#aaffc3",
	"#808000",
	"#ffd8b1",
	"#000075",
	"#000000"
}

const TrialMapping: FC<props> = ({
	trialId,
	trialName,
	trialDetails,
	treatments,
	navigateToNextStep,
	navigateToPreviousStep
}) => {
	const userAuth = useUserAuth();
	const userSettings = useUserSettings();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	const [plots, setPlots] = useState<trialMapData[][]>([]);

	const [loading, setLoading] = useState(true);

	const [colorMap, setColorMap] = useState<{ [key: string]: string }>({});

	useEffect(() => {
		(async () => {
			if (userAuth.currentClientId) {
				await retrieveTrialPlots(userAuth.currentClientId);
			}
			setLoading(false);
		})();
	}, []);

	const retrieveTrialPlots = async (clientId) => {
		try {
			const accessToken = await getTokenSilently();
			const res = await trialActions.getTrialPlotsForMapping(trialId, clientId, accessToken)(dispatch);
			parsePlots(res.data);
		} catch (err) {
			toast.error("Failed to retrieve trial data.");
			console.error(err);
		}
	};

	const parsePlots = (data: trialMapData[]) => {
		// first plot should be the top left corner
		// use this to get ending range/starting col

		let startingRange = 999,
			startingColumn = 999,
			endingRange = 0,
			endingColumn = 0;

		for (let plot of data) {
			startingRange = plot.range < startingRange ? plot.range : startingRange;
			startingColumn = plot.column < startingColumn ? plot.column : startingColumn;
			endingRange = plot.range > endingRange ? plot.range : endingRange;
			endingColumn = plot.column > endingColumn ? plot.column : endingColumn;
		}

		const parsedPlots = Array.from(
			Array(endingRange - startingRange + 1),
			() => new Array(endingColumn - startingColumn + 1)
		);

		let colorIdx = 0;
		const tempMap = { ...colorMap };

		for (let plot of data) {
			// build color map
			if (!(plot.treatmentId in tempMap)) {
				if (plot.treatmentTypeName === "Control") {
					tempMap[plot.treatmentId] = "#ffffff";
				} else {
					tempMap[plot.treatmentId] = colors[(colorIdx % 20) + 1];
					colorIdx++;
				}
			}
			plot.color = tempMap[plot.treatmentId];

			// range is descending while column is ascending
			parsedPlots[endingRange - plot.range][plot.column - startingColumn] = plot;
		}

		setColorMap(tempMap);
		setPlots(parsedPlots);
	};

	return loading ? (
		<Loader active />
	) : (
		<>
			<Segment basic style={{ minHeight: 50 }}>
				<div style={{ display: "flex", alignItems: "end", whiteSpace: "nowrap", overflow: "hidden" }}>
					<Button
						style={{ marginRight: 10 }}
						color="black"
						content="Back"
						onClick={() => {
							navigateToPreviousStep();
						}}
					/>

					<h2 style={{ margin: 0 }}>Mapping</h2>

					<div style={{ flexGrow: 1, minWidth: 10 }} />

					<TrialFavoritingWidget
						style={{ display: "inline", alignSelf: "center" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						trialId={trialId.toUpperCase()}
						userSettings={userSettings}
					></TrialFavoritingWidget>
					<h2
						style={{
							margin: 0,
							marginRight: 10,
							color: "rgba(7, 55, 99, 0.75)",
							textOverflow: "ellipsis",
							overflow: "hidden"
						}}
						title={`${trialName} (${trialDetails})`}
					>
						<i>
							{trialName} ({trialDetails})
						</i>
					</h2>
				</div>

				<hr style={{ clear: "both" }} />
			</Segment>
			<Grid columns="equal" padded>
				<Grid.Row>
					<Grid.Column>
						<Table celled structured>
							<Table.Header>
								<Table.Row textAlign="center">
									<Table.HeaderCell colSpan={3}>Treatment</Table.HeaderCell>
								</Table.Row>
								<Table.Row textAlign="center">
									<Table.HeaderCell width={1}>#</Table.HeaderCell>
									<Table.HeaderCell>Name</Table.HeaderCell>
									<Table.HeaderCell style={{ width: 50 }}>Color</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{_.map(treatments, (tr) => {
									return (
										<Table.Row>
											<Table.Cell textAlign="center">{tr.trialTreatmentId}</Table.Cell>
											<Table.Cell>
												{tr.treatmentName} {tr.treatmentTypeName === "Control" && "(Control)"}
											</Table.Cell>
											<Table.Cell style={{ backgroundColor: colorMap[tr.treatmentId] }} />
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Grid.Column>
					<Grid.Column stretched>
						<div style={{ border: "1px solid rgba(34,36,38,.15)", display: "flex", flexGrow: 1 }}>
							<TransformWrapper limitToBounds={false} minScale={0.1} centerOnInit>
								{({ zoomIn, zoomOut, centerView }) => (
									<React.Fragment>
										<div className="tools" style={{ float: "left", display: "flex", gap: 4 }}>
											<Button icon="plus" onClick={() => zoomIn()} />
											<Button icon="minus" onClick={() => zoomOut()} />
											<Button icon="refresh" onClick={() => centerView(1)} />
										</div>
										<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
											<TrialMap plots={plots} />
										</TransformComponent>
									</React.Fragment>
								)}
							</TransformWrapper>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

TrialMapping.propTypes = {
	trialId: PropTypes.string.isRequired,
	trialName: PropTypes.string.isRequired,
	treatments: PropTypes.array.isRequired,
	navigateToNextStep: PropTypes.func.isRequired,
	navigateToPreviousStep: PropTypes.func.isRequired
};

export default TrialMapping;
