import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

const PublicRoute = ({ children, ...rest }) => {
  return <Route {...rest}>{children}</Route>;
};

PublicRoute.propTypes = {
  children: PropTypes.element.isRequired
};

export default PublicRoute;
