import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function clientReducer(state = initialState.clients, action) {
	switch (action.type) {
		case types.CLIENT_SET_CURRENT_ID_SUCCESS:
			return { ...state, currentId: action.clientId };

		default:
			return state;
	}
}
