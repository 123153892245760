import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Segment, Loader, Button } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";

import FieldCsvUpload from "./FieldCsvUpload";
import FieldOutlineTable from "../FieldOutline/FieldOutlineTable";

import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as ControlFunctions from "../../../Lumber/OrthoViewer/orthoControlFunctions";
import * as flightsPlotsActions from "../../../../redux/actions/flightsPlotsActions";
import { validateForm } from "./Validation";
import DeleteConfirmationModal from "../FieldWizard/deleteModal";

const FieldLayout = ({ fieldId, fromViewer, handleFinish, handleCancel, navigateToNextStep, navigateBack }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { getTokenSilently } = useAuth0();

	const [layoutInfo, setLayoutInfo] = useState(null);
	const [fieldOutlineData, setFieldOutlineData] = useState(null);
	const [csvData, setCsvData] = useState(null);
	const [fieldLayoutCsvFile, setFieldLayoutCsvFile] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [fieldLayoutInspection, setFieldLayoutInspection] = useState(null);
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (fieldId) {
			setLoading(true);
			getLayoutInfo();
		}
	}, [fieldId]);

	async function getLayoutInfo() {
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getFieldLayoutInfo(fieldId, clientId, accessToken))
			.then((res) => {
				setLayoutInfo(res.layoutInfo);
				setCsvInfo(res.outlineData);
				setFieldOutlineData(res.outlineData);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				toast.error("Failed to load Field Layout data.");
			});
	}

	function setCsvInfo(fieldOutlineData) {
		if (fieldOutlineData && fieldOutlineData.length > 0) {
			const fieldName = fieldOutlineData[0].field;

			setFileName(`${fieldName}_FieldOutline_${moment().format("YYYYMMDD_hhmma")}.csv`);

			setCsvData(
				fieldOutlineData.map((fod) => {
					return {
						Field: fod.field,
						Range: fod.range,
						Column: fod.column,
						Company: fod.company,
						Trial: fod.trial,
						Treatment: fod.trialTreatmentId,
						Plot: fod.plot,
						Rep: fod.rep,
						Seed: fod.seed
					};
				})
			);
		}
	}

	async function editFieldLayout(continueToNextPage, finish) {
		if (!fieldLayoutCsvFile) {
			handleNoLayoutFile(continueToNextPage, finish);
			return;
		}
		let validate = validateForm(fieldLayoutInspection);
		if (validate?.error) {
			toast.error("The CSV uploaded contained errors. Please fix the CSV file and try again.");
			return;
		}
		setSaving(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.editFieldLayout(layoutInfo, fieldLayoutCsvFile, accessToken))
			.then(() => {
				if (fieldLayoutInspection?.areValid) {
					toast.info("Preparing new trial images.");
					handleTrialCutting(accessToken);
				} else {
					setSaving(false);
				}

				if (finish && handleFinish) {
					handleFinish(fieldId);
				} else {
					toast.success("Field Layout updated successfully.");

					if (continueToNextPage) {
						navigateToNextStep();
					} else {
						getLayoutInfo();
					}
				}
			})
			.catch((err) => {
				setSaving(false);
				console.log(err);
				toast.error("Failed to edit Field Layout data.");
			});
	}

	function handleNoLayoutFile(continueToNextPage, finish) {
		if (finish && handleFinish) {
			handleFinish(fieldId);
		} else if (continueToNextPage) {
			navigateToNextStep();
		} else {
			toast.warn("Please select a field layout CSV to upload.");
		}
	}

	function getCsvFile(updatedFieldLayoutCsvFile) {
		setFieldLayoutCsvFile(updatedFieldLayoutCsvFile);
	}

	async function deleteField() {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.deleteField(fieldId, clientId, accessToken))
			.then(() => {
				setDeleting(false);

				if (fromViewer) {
					handleCancel();
				} else {
					history.push("/fields");
				}
			})
			.catch((err) => {
				console.log(err);
				//TODO: add the validation if the field is linked to a flight
				toast.error("Field failed to delete");
			});
	}

	function handleTrialCutting(accessToken) {
		dispatch(fieldActions.getFlightsWithFieldOrTrial(clientId, fieldId, null, accessToken))
			.then(async (flights) => {
				let flightOrthoCombinations = [];

				_.map(flights, ({ flightId, orthoImageTypeId, sensorId }) => {
					if (
						!flightOrthoCombinations.some((flight) => {
							return (
								flight.flightId === flightId &&
								flight.orthoImageTypeId === orthoImageTypeId &&
								flight.sensorId === sensorId
							);
						})
					) {
						flightOrthoCombinations.push({
							flightId: flightId,
							orthoImageTypeId: orthoImageTypeId,
							sensorId: sensorId
						});
					}
				});

				let existingFlights = _.uniqBy(flightOrthoCombinations, "flightId");

				// eslint-disable-next-line
				const allPlotsFound = await Promise.all(
					_.map(existingFlights, async ({ flightId }, index) => {
						await dispatch(
							flightsPlotsActions.getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken)
						).then((res) => {
							existingFlights[index].plots = _.map(res.plots, (plot) => {
								return {
									id: plot.plotId,
									type: "Feature",
									properties: {
										type: "plot",
										field: plot.fieldId,
										fieldName: plot.fieldName,
										trial: plot.trialId,
										trialName: `${plot.companyName} - ${plot.trialName}`,
										fieldColWidth: plot.plotColumnWidth,
										locked: true
									},
									geometry: {
										type: "Polygon",
										coordinates: [
											[
												[plot.llLong, plot.llLat],
												[plot.ulLong, plot.ulLat],
												[plot.urLong, plot.urLat],
												[plot.lrLong, plot.lrLat],
												[plot.llLong, plot.llLat]
											]
										]
									}
								};
							});
						});
					})
				);

				// eslint-disable-next-line
				const cutComplete = await Promise.all(
					_.map(existingFlights, async ({ flightId, plots }) => {
						ControlFunctions.setPlots(plots);

						let orthoImageTypes = _.map(
							_.filter(flightOrthoCombinations, { flightId: flightId }),
							({ orthoImageTypeId, sensorId }) => {
								return {
									orthoImageTypeId: orthoImageTypeId,
									sensorId: sensorId
								};
							}
						);

						await ControlFunctions.cutImage(clientId, flightId, orthoImageTypes, accessToken, dispatch, true, true, [
							{ id: fieldId }
						]);
					})
				);

				setSaving(false);
			})
			.catch(() => {
				// Will enter if field isn't flown
				setSaving(false);
			});
	}

	return loading ? (
		<Loader active inline="centered" />
	) : (
		<Segment basic>
			<Segment basic style={{ marginBottom: 50 }}>
				<Button
					id="form-button-back"
					floated="left"
					color="black"
					content={"Back"}
					disabled={saving || deleting}
					onClick={() => {
						navigateBack();
					}}
				/>
				{!fromViewer && (
					<DeleteConfirmationModal
						handleDeleteField={() => deleteField()}
						floated="right"
						isDeleting={deleting}
						disabled={saving || deleting}
						loading={deleting}
					/>
				)}
				<Button
					id="form-button-save"
					floated="right"
					color="blue"
					content={"Save"}
					onClick={() => editFieldLayout(false, false)}
					disabled={saving || deleting}
					loading={saving}
				/>
				<Button
					id="form-button-save-and-continue"
					floated="right"
					color="green"
					content={"Save & Continue"}
					onClick={() => editFieldLayout(true, false)}
					disabled={saving || deleting}
					loading={saving}
				/>
				{fromViewer && (
					<Button
						id="form-button-cancel"
						type="button"
						floated="right"
						disabled={saving}
						onClick={() => {
							setSaving(true);
							handleCancel();
						}}
					>
						Cancel
					</Button>
				)}
			</Segment>
			<FieldCsvUpload
				fieldInfo={layoutInfo}
				fieldFileName={fileName}
				fieldCsvData={csvData}
				saving={saving}
				getCsvFileCallback={getCsvFile}
				setFieldLayoutInspection={setFieldLayoutInspection}
			/>
			<FieldOutlineTable fieldOutlineData={fieldOutlineData} />
		</Segment>
	);
};

FieldLayout.propTypes = {
	fieldId: PropTypes.string,
	fromViewer: PropTypes.bool,
	handleFinish: PropTypes.func,
	handleCancel: PropTypes.func,
	navigateToNextStep: PropTypes.func,
	navigateBack: PropTypes.func
};

export default FieldLayout;
