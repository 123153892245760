const autoAlignApiUri = process.env.aerialPlotConnections.aerialAutoAlignApi.baseUrl;

export function alignPlots(objectToPass, clientId, accessToken) {
	const alignPlotsDataVm = new FormData();
	let index = 0;

	for (const name in objectToPass) {
		let val = objectToPass[name] === "" ? null : objectToPass[name];
		if (typeof val !== "object") {
			alignPlotsDataVm.set(name, val);
		}
	}

	for (const plot of objectToPass.plots) {
		let coords = `${plot.llLat}/${plot.llLong}/${plot.ulLat}/${plot.ulLong}/${plot.urLat}/${plot.urLong}/${plot.lrLat}/${plot.lrLong}`;

		alignPlotsDataVm.append(`Plots[${index}].Column`, plot.column ?? "");
		alignPlotsDataVm.append(`Plots[${index}].Range`, plot.range ?? "");
		alignPlotsDataVm.append(`Plots[${index}].Coords`, coords);

		index++;
	}

	index = 0;
	for (const range of objectToPass.rangeData) {
		let coords = `${range.llLat}/${range.llLong}/${range.ulLat}/${range.ulLong}/${range.urLat}/${range.urLong}/${range.lrLat}/${range.lrLong}`;

		alignPlotsDataVm.append(`RangeData[${index}].Range`, range.range ?? "");
		alignPlotsDataVm.append(`RangeData[${index}].Coords`, coords);

		index++;
	}
	return fetch(`${autoAlignApiUri}autoAlign/${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: "POST",
		body: alignPlotsDataVm
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function scalePlots(objectToPass, clientId, accessToken) {
	const scalePlotsDataVm = new FormData();
	let index = 0;

	for (const name in objectToPass) {
		let val = objectToPass[name] === "" ? null : objectToPass[name];
		if (typeof val !== "object") {
			scalePlotsDataVm.set(name, val);
		}
	}

	for (const plot of objectToPass.plots) {
		let coords = `${plot.llLat}/${plot.llLong}/${plot.ulLat}/${plot.ulLong}/${plot.urLat}/${plot.urLong}/${plot.lrLat}/${plot.lrLong}`;

		scalePlotsDataVm.append(`Plots[${index}].Column`, plot.column ?? "");
		scalePlotsDataVm.append(`Plots[${index}].Range`, plot.range ?? "");
		scalePlotsDataVm.append(`Plots[${index}].Coords`, coords);

		index++;
	}

	index = 0;
	for (const range of objectToPass.rangeData) {
		let coords = `${range.llLat}/${range.llLong}/${range.ulLat}/${range.ulLong}/${range.urLat}/${range.urLong}/${range.lrLat}/${range.lrLong}`;

		scalePlotsDataVm.append(`RangeData[${index}].Range`, range.range ?? "");
		scalePlotsDataVm.append(`RangeData[${index}].Coords`, coords);

		index++;
	}
	return fetch(`${autoAlignApiUri}autoScale/${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: "POST",
		body: scalePlotsDataVm
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function scaleAndAlignPlots(objectToPass, clientId, accessToken) {
	const scaleAndAlignPlotsDataVm = new FormData();
	let index = 0;

	for (const name in objectToPass) {
		let val = objectToPass[name] === "" ? null : objectToPass[name];
		if (typeof val !== "object") {
			scaleAndAlignPlotsDataVm.set(name, val);
		}
	}

	for (const plot of objectToPass.plots) {
		let coords = `${plot.llLat}/${plot.llLong}/${plot.ulLat}/${plot.ulLong}/${plot.urLat}/${plot.urLong}/${plot.lrLat}/${plot.lrLong}`;

		scaleAndAlignPlotsDataVm.append(`Plots[${index}].Column`, plot.column ?? "");
		scaleAndAlignPlotsDataVm.append(`Plots[${index}].Range`, plot.range ?? "");
		scaleAndAlignPlotsDataVm.append(`Plots[${index}].Coords`, coords);

		index++;
	}

	index = 0;
	for (const range of objectToPass.rangeData) {
		let coords = `${range.llLat}/${range.llLong}/${range.ulLat}/${range.ulLong}/${range.urLat}/${range.urLong}/${range.lrLat}/${range.lrLong}`;

		scaleAndAlignPlotsDataVm.append(`RangeData[${index}].Range`, range.range ?? "");
		scaleAndAlignPlotsDataVm.append(`RangeData[${index}].Coords`, coords);

		index++;
	}
	return fetch(`${autoAlignApiUri}autoScaleAndAlign/${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: "POST",
		body: scaleAndAlignPlotsDataVm
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}
