import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Segment, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as trialActions from "../../../../redux/actions/trialActions";

import PublishedTrialsFlightsTable from "./PublishedTrialsFlightsTable";

const PublishedTrials = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	//-- Query results
	const [publishedTrialsFlights, setPublishedTrialsFlights] = useState(null);

	//-- UI Control
	const [initialLoading, setInitialLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(1);

	useEffect(() => {
		if (userAuth.currentClientId) {
			setRefreshing(true);
			getTrialsPublishedToClientOrUser(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	async function getTrialsPublishedToClientOrUser(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getTrialsPublishedToClientOrUser(clientId, accessToken))
			.then((res) => {
				const stagedTrialFlights = _.map(res, (trial) => {
					if (
						userAuth.currentClientId === trial.trialOwnerClientId ||
						userAuth.user.userInfo.basic.userId === trial.trialOwnerUserId
					) {
						trial.view = "Owner";
					} else if (
						(userAuth.currentClientId === trial.trialSponsorClientId ||
							userAuth.user.userInfo.basic.userId === trial.trialSponsorUserId) &&
						trial.publishedToTrialSponsor
					) {
						trial.view = "Sponsor";
					} else if (userAuth.currentClientId === trial.publishedByClientId) {
						trial.view = "Researcher";
					}

					return setupTrialFlightObject(trial);
				});

				setPublishedTrialsFlights(stagedTrialFlights);
				setInitialLoading(false);
				setRefreshing(false);
			})
			.catch((err) => {
				toast.error("Error loading Published Trials. Please try again.");
				setInitialLoading(false);
				setRefreshing(false);
				console.log(err);
			});
	}

	const setupTrialFlightObject = (trial) => {
		const stagedTrialFlight = {
			trialId: trial.trialId,
			trialName: trial.trialName,
			flightId: trial.flightId,
			flightDate: trial.flightDate,
			flightName: trial.flightName,
			publishedDate: trial.publishedDate,
			view: trial.view,
			publishedToTrialSponsor: trial.publishedToTrialSponsor,
			company:
				trial.view !== "Sponsor"
					? trial.view === "Owner"
						? trial.trialSponsorClientName
							? trial.trialSponsorClientName
							: trial.trialSponsorCompanyName
							? trial.trialSponsorCompanyName
							: trial.trialOwnerClientName
						: trial.trialOwnerClientName
						? trial.trialOwnerClientName
						: trial.trialOwnerCompanyName
					: trial.trialSponsorClientName,
			publishedByClientName:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.publishedByClientName
						: trial.trialOwnerClientName
					: trial.trialOwnerClientName,

			publishedByUserFirstName:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.publishedByUserFirstName
						: trial.trialOwnerFirstName
					: trial.trialOwnerFirstName,
			publishedByUserLastName:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.publishedByUserLastName
						: trial.trialOwnerLastName
					: trial.trialOwnerLastName,
			publishedByEmailAddress:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.publishedByUserEmailAddress
						: trial.trialOwnerEmailAddress
					: trial.trialOwnerEmailAddress,
			publishedToFirstName: trial.view !== "Sponsor" ? trial.trialOwnerFirstName : trial.trialSponsorFirstName,
			publishedToLastName: trial.view !== "Sponsor" ? trial.trialOwnerLastName : trial.trialSponsorLastName,
			publishedToEmailAddress:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.trialOwnerEmailAddress
						: trial.trialSponsorEmailAddress
					: trial.trialSponsorEmailAddress,
			publishedToClientName:
				trial.view !== "Sponsor"
					? trial.view === "Researcher" || (trial.view === "Owner" && !trial.publishedToTrialSponsor)
						? trial.trialOwnerClientId && trial.trialOwnerClientId !== "00000000-0000-0000-0000-000000000000"
							? trial.trialOwnerClientName
							: "Unassigned"
						: trial.trialSponsorClientId && trial.trialSponsorClientId !== "00000000-0000-0000-0000-000000000000"
						? trial.trialSponsorClientName
						: "Unassigned"
					: trial.trialSponsorClientId && trial.trialSponsorClientId !== "00000000-0000-0000-0000-000000000000"
					? trial.trialSponsorClientName
					: "Unassigned"
		};

		return stagedTrialFlight;
	};

	return initialLoading ? (
		<Loader active />
	) : !initialLoading && (!publishedTrialsFlights || publishedTrialsFlights.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Published Trials</h2>
			<hr />
			<Segment>
				<p>{"We couldn't find any published trials."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Published Trials</h2>
			<hr />
			{refreshing && <Loader active />}
			{!refreshing && <PublishedTrialsFlightsTable publishedTrialsFlightsData={publishedTrialsFlights} />}
		</Segment>
	);
};

export default PublishedTrials;
