import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Input, Modal, Button, Dropdown, Grid } from "semantic-ui-react";
import styles from "./styles.css";

const AzureFilesBrowser = ({
	data,
	open,
	onClose,
	onConfirmOrtho,
	onConfirmElevation,
	onConfirmMultiband,
	callerComponentId,
	componentIds
}) => {
	const AzureFileBrowseTableColumns = {
		FILENAME: "fileName",
		DIRECTORYNAME: "directoryName",
		FILESIZE: "fileSize",
		CREATEDON: "createdOn"
	};

	const ColumnSortDirections = {
		ASC: "ascending",
		DESC: "descending"
	};

	const [sortColumn, setSortColumn] = useState(AzureFileBrowseTableColumns.CREATEDON);
	const [sortDirection, setSortDirection] = useState(ColumnSortDirections.DESC);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedDirectory, setSelectedDirectory] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);

	useEffect(() => {
		//-- Reset row selection when modal is reopened
		setSelectedRow(null);
		setSelectedRowIndex(null);
	}, [open]);

	const handleSort = (clickedColumn) => () => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			setSortDirection(ColumnSortDirections.ASC);
			return;
		}

		setSortDirection(sortDirection === ColumnSortDirections.ASC ? ColumnSortDirections.DESC : ColumnSortDirections.ASC);
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleDirectoryChange = (_, { value }) => {
		setSelectedDirectory(value);
	};

	const handleClearDirectory = () => {
		setSelectedDirectory("");
	};

	const handleRowClick = (item, index) => {
		setSelectedRowIndex(index);
		setSelectedRow(item);
	};

	const handleConfirmSelection = () => {
		const selectedFile = selectedRow !== null ? selectedRow : null;

		if (callerComponentId === componentIds.ELEVATION) {
			onConfirmElevation(selectedFile);
		} else if (callerComponentId === componentIds.MULTIBAND) {
			onConfirmMultiband(selectedFile);
		} else if (typeof callerComponentId === "string") {
			onConfirmOrtho(selectedFile);
		}

		onClose();
	};

	const convertBytesToMB = (bytes) => {
		return (bytes / (1024 * 1024)).toFixed(2);
	};

	const formatCreatedOn = (dateString) => {
		const date = new Date(dateString);
		return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
	};

	const getDirectories = () => {
		const directories = [...new Set(data.map((item) => item.directoryName))];
		return directories.map((directory) => ({ key: directory, text: directory, value: directory }));
	};

	const filteredData = () => {
		let filtered = data;
		if (selectedDirectory) {
			filtered = filtered.filter((item) => item.directoryName === selectedDirectory);
		}
		if (searchQuery) {
			filtered = filtered.filter((item) => item.fileName.toLowerCase().includes(searchQuery.toLowerCase()));
		}
		return filtered;
	};

	const sortedData = () => {
		return [...filteredData()].sort((a, b) => {
			let aValue = a[sortColumn];
			let bValue = b[sortColumn];

			if (sortColumn === AzureFileBrowseTableColumns.CREATEDON) {
				aValue = new Date(a.properties[sortColumn]);
				bValue = new Date(b.properties[sortColumn]);
			}

			if (typeof aValue === "string" && typeof bValue === "string") {
				return sortDirection === ColumnSortDirections.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
			} else {
				return sortDirection === ColumnSortDirections.ASC ? aValue - bValue : bValue - aValue;
			}
		});
	};

	const renderRows = () =>
		sortedData().map((item, index) => (
			<Table.Row key={index} onClick={() => handleRowClick(item, index)} active={selectedRowIndex === index}>
				<Table.Cell>{item.fileName}</Table.Cell>
				<Table.Cell>{item.directoryName}</Table.Cell>
				<Table.Cell>{convertBytesToMB(item.fileSize)} MB</Table.Cell>
				<Table.Cell>{formatCreatedOn(item.properties.createdOn)}</Table.Cell>
			</Table.Row>
		));

	return (
		<Modal open={open} onClose={onClose} size="large">
			<Modal.Header>Azure Files Browser</Modal.Header>
			<Modal.Content scrolling>
				<Grid columns={2}>
					<Grid.Column width={4}>
						<Dropdown
							placeholder="Select Directory"
							icon="folder"
							className="icon"
							floating
							labeled
							button
							fluid
							selection
							clearable
							options={getDirectories()}
							value={selectedDirectory}
							onChange={handleDirectoryChange}
							onClear={handleClearDirectory}
						/>
					</Grid.Column>
					<Grid.Column width={12}>
						<Input
							placeholder="Search files..."
							icon="search"
							iconPosition="left"
							fluid
							value={searchQuery}
							onChange={handleSearchChange}
						/>
					</Grid.Column>
				</Grid>
				<div className={styles.azureFileBrowseTableWrapper}>
					<Table celled selectable sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell
									sorted={sortColumn === AzureFileBrowseTableColumns.FILENAME ? sortDirection : null}
									onClick={handleSort(AzureFileBrowseTableColumns.FILENAME)}
								>
									File Name
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === AzureFileBrowseTableColumns.DIRECTORYNAME ? sortDirection : null}
									onClick={handleSort(AzureFileBrowseTableColumns.DIRECTORYNAME)}
								>
									Directory Name
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === AzureFileBrowseTableColumns.FILESIZE ? sortDirection : null}
									onClick={handleSort(AzureFileBrowseTableColumns.FILESIZE)}
								>
									File Size (MB)
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === AzureFileBrowseTableColumns.CREATEDON ? sortDirection : null}
									onClick={handleSort(AzureFileBrowseTableColumns.CREATEDON)}
								>
									Uploaded On
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>{renderRows()}</Table.Body>
					</Table>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button content={"Cancel"} onClick={onClose} negative />
				<Button
					content={"Confirm Selection"}
					onClick={handleConfirmSelection}
					positive
					disabled={selectedRow === null}
				/>
			</Modal.Actions>
		</Modal>
	);
};

AzureFilesBrowser.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			fileName: PropTypes.string.isRequired,
			fileSize: PropTypes.number.isRequired,
			properties: PropTypes.shape({
				createdOn: PropTypes.string.isRequired
			}).isRequired,
			directoryName: PropTypes.string.isRequired
		})
	).isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onConfirmOrtho: PropTypes.func,
	onConfirmElevation: PropTypes.func,
	onConfirmMultiband: PropTypes.func,
	callerComponentId: PropTypes.any,
	componentIds: PropTypes.object
};

export default AzureFilesBrowser;
