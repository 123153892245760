import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { Segment, Form, Checkbox, Grid } from "semantic-ui-react";

import * as areauUnderTheCurveFunctions from "../../../Lumber/AreaUnderTheCurve";

import TimecourseComponent from "../../../Lumber/Timecourse";

const FlightSelection = ({
	timecourseData,
	trialData,
	analyses,
	getSelectedFlights,
	getAucData,
	getOtherAucData,
	flightsInCurveModel,
	curveFitFunctions,
	analysisTypes,
	curveModelBatchAssessments,
	getFlightSelectionAnalysisId,
	getFlightSelectionCurveFitFunctionId,
	allMeanComparisonOptions,
	gduByDay,
	quantifiedRegionTypeOptions,
	quantifiedRegionTypeValue,
	getFlightSelectionQuantifiedRegionTypeId,
	getFlightSelectionAnalysisTypeId,
	fitFunctionValue
}) => {
	const [analysisDataResults, setAnalysisDataResults] = useState(timecourseData);
	const [analysisDropdownOptions, setAnalysisDropdownOptions] = useState([]);
	const [analysisValue, setAnalysisValue] = useState(null);
	const [curveFitFunctionOptions, setCurveFitFunctionOptions] = useState([]);
	const [curveFitFunctionValue, setCurveFitFunctionValue] = useState(null);
	const [selectedQuantifiedRegionTypeValue, setSelectedQuantifiedRegionTypeValue] = useState(null);
	const [analysisTypeOptions, setAnalysisTypeOptions] = useState([]);
	const [selectedAnalysisTypeId, setSelectedAnalysisTypeId] = useState(null);
	const [presetAssessment, setPresetAssessment] = useState(null);
	const [presetAssessmentOptions, setPresetAssessmentOptions] = useState([]);
	const [useGDU, setUseGDU] = useState(false);
	const [hasGduFormula, setHasGduFormula] = useState(false);
	const [switchingPreset, setSwitchingPreset] = useState(false);
	const weatherDataEnabled = true;

	useEffect(() => {
		if (curveModelBatchAssessments?.length > 0) {
			let opts = [{ key: "Custom", value: "Custom", text: "Custom" }].concat(
				_.map(
					_.filter(
						curveModelBatchAssessments,
						(cmba) =>
							_.map(analyses, "analysisId").includes(cmba.analysisId) &&
							_.map(quantifiedRegionTypeOptions, "key").includes(cmba.quantifiedRegionTypeId)
					),
					(cmba) => {
						return {
							key: cmba.curveModelPhenotypeId,
							value: cmba.curveModelPhenotypeId,
							text: cmba.curveModelPhenotypeName
						};
					}
				)
			);

			setPresetAssessmentOptions(opts);
			setPresetAssessment(presetAssessment ?? opts[1]?.key ?? opts[0]?.key); //-- Try to default to not a custom preset
		}
	}, [curveModelBatchAssessments]);

	useEffect(() => {
		if (analysisTypes?.length > 0) {
			let opts = _.map(analysisTypes, (at) => {
				return {
					key: at.id,
					value: at.id,
					text: at.name
				};
			});

			setAnalysisTypeOptions(opts);
			setSelectedAnalysisTypeId(opts[0]?.key);
			getFlightSelectionAnalysisTypeId(opts[0]?.key);
		}
	}, [analysisTypes]);

	useEffect(() => {
		if (!selectedQuantifiedRegionTypeValue) {
			setSelectedQuantifiedRegionTypeValue(quantifiedRegionTypeValue);
			getFlightSelectionQuantifiedRegionTypeId(quantifiedRegionTypeValue);
		}
	}, [quantifiedRegionTypeValue]);

	useEffect(() => {
		if (analyses?.length > 0) {
			createAnalysisOptions();
		}
	}, [analyses]);

	useEffect(() => {
		if (curveFitFunctions?.length > 0) {
			createCurveFitOptions();
		}
	}, [curveFitFunctions]);

	useEffect(() => {
		if (selectedQuantifiedRegionTypeValue) {
			const values = _.filter(timecourseData, (t) => {
				return t.quantifiedRegionTypeId === selectedQuantifiedRegionTypeValue;
			});
			setAnalysisDataResults(values);
		}
	}, [selectedQuantifiedRegionTypeValue]);

	//-- Triggers whenever ddls making up presets gets changed
	useEffect(() => {
		if (analysisValue && selectedAnalysisTypeId && selectedQuantifiedRegionTypeValue && curveFitFunctionValue) {
			let matchingPreset = _.find(
				curveModelBatchAssessments,
				(cmba) =>
					cmba.analysisId === analysisValue &&
					cmba.analysisTypeId === selectedAnalysisTypeId &&
					cmba.quantifiedRegionTypeId === selectedQuantifiedRegionTypeValue &&
					cmba.curveModelFitFunctionId === curveFitFunctionValue
			);

			if (!switchingPreset) {
				setSwitchingPreset(true);
				setPresetAssessment(matchingPreset?.curveModelPhenotypeId ?? "Custom");
				handlePresetAssessmentSwitch(matchingPreset?.curveModelPhenotypeId ?? "Custom");
			}
		}
	}, [analysisValue, selectedAnalysisTypeId, selectedQuantifiedRegionTypeValue, curveFitFunctionValue]);

	//-- Sets resulting ddls when preset is changed
	//-- Put in place to not watch 2 separate states
	function handlePresetAssessmentSwitch(curveModelPhenotypeId) {
		if (curveModelPhenotypeId && curveModelPhenotypeId !== "Custom" && presetAssessment !== curveModelPhenotypeId) {
			let matchingPreset = _.find(curveModelBatchAssessments, { curveModelPhenotypeId: curveModelPhenotypeId });
			if (matchingPreset) {
				setAnalysisValue(matchingPreset.analysisId);
				setSelectedAnalysisTypeId(matchingPreset.analysisTypeId);
				setSelectedQuantifiedRegionTypeValue(matchingPreset.quantifiedRegionTypeId);
				setCurveFitFunctionValue(matchingPreset.curveModelFitFunctionId);

				getFlightSelectionAnalysisId(matchingPreset.analysisId);
				getFlightSelectionAnalysisTypeId(matchingPreset.analysisTypeId);
				getFlightSelectionQuantifiedRegionTypeId(matchingPreset.quantifiedRegionTypeId);
				getFlightSelectionCurveFitFunctionId(matchingPreset.curveModelFitFunctionId);

				setSwitchingPreset(false);
			}
		} else {
			setSwitchingPreset(false);
		}
	}

	function createAnalysisOptions() {
		let analysis = _.cloneDeep(analysisValue);
		const dropdownOptions = _.map(analyses, (a) => {
			const opt = {
				key: a.analysisId,
				value: a.analysisId,
				text: a.analysisDisplayName
			};
			if (a.analysisDisplayName === "NDVI" && !analysis) {
				analysis = a.analysisId;
			}

			return opt;
		});
		if (analysis === null) {
			analysis = analyses[0]?.analysisId;
		}
		setAnalysisValue(analysis);
		getFlightSelectionAnalysisId(analysis);
		setAnalysisDropdownOptions(dropdownOptions);
	}

	function createCurveFitOptions() {
		const dropdownOptions = _.map(curveFitFunctions, (cff) => {
			const opt = {
				key: cff.id,
				value: cff.id,
				text: cff.name
			};

			return opt;
		});

		setCurveFitFunctionValue(fitFunctionValue ?? dropdownOptions[0]?.value);
		getFlightSelectionCurveFitFunctionId(fitFunctionValue ?? dropdownOptions[0]?.value);
		setCurveFitFunctionOptions(dropdownOptions);
	}

	function handleSetGdu(value) {
		setUseGDU(value);
	}

	function getHasGduFormula(gdu) {
		setHasGduFormula(gdu);
	}

	return (
		<Segment basic>
			<Form>
				<Form.Group>
					<Form.Select
						id="select-analysis"
						label="Assessment"
						width="2"
						selection
						options={presetAssessmentOptions}
						value={presetAssessment}
						onChange={(e, { value }) => {
							setSwitchingPreset(true);
							setPresetAssessment(value);
							handlePresetAssessmentSwitch(value);
						}}
					/>
					<br />
					<Form.Select
						id="select-analysis"
						label="Ortho"
						width="2"
						selection
						options={analysisDropdownOptions}
						value={analysisValue}
						onChange={(e, { value }) => {
							setAnalysisValue(value);
							getFlightSelectionAnalysisId(value);
						}}
					/>
					<Form.Select
						id="select-analysis-type"
						label="Analysis Type"
						width="2"
						selection
						options={analysisTypeOptions}
						value={selectedAnalysisTypeId}
						onChange={(e, { value }) => {
							setSelectedAnalysisTypeId(value);
							getFlightSelectionAnalysisTypeId(value);
						}}
					/>
					<Form.Select
						id="select-quantified-region-type"
						label="Quantified Region Type"
						width="2"
						selection
						options={quantifiedRegionTypeOptions}
						value={selectedQuantifiedRegionTypeValue}
						onChange={(e, { value }) => {
							setSelectedQuantifiedRegionTypeValue(value);
							getFlightSelectionQuantifiedRegionTypeId(value);
						}}
					/>
					<Form.Select
						id="select-curve-fit-function"
						width="2"
						label="Curve Fit Function:"
						selection
						options={curveFitFunctionOptions}
						value={curveFitFunctionValue}
						onChange={(e, { value }) => {
							setCurveFitFunctionValue(value);
							getFlightSelectionCurveFitFunctionId(value);
						}}
					/>
				</Form.Group>
				{weatherDataEnabled && hasGduFormula && trialData?.trialInfo?.plantDate ? (
					<Checkbox
						toggle
						id="use-gdus"
						label="Use Growing Degree Units"
						onChange={(e, { checked }) => {
							handleSetGdu(checked);
						}}
					/>
				) : (
					<Grid.Column width={"2"}>
						<Checkbox toggle readOnly={true} label={"Use Growing Degree Units For Graph"} />
						{areauUnderTheCurveFunctions.buildGduErrorPopup(hasGduFormula, trialData?.trialInfo?.plantDate)}
					</Grid.Column>
				)}
			</Form>

			{analysisDataResults?.length > 0 && trialData && analysisValue ? (
				<TimecourseComponent
					showAnalysisSelection={false}
					showTable={true}
					showLineChart={true}
					showBoxWhisker={false}
					analysisDataResults={analysisDataResults}
					trialDataResults={trialData}
					viewPlantFlight={true}
					viewHarvestFlight={true}
					getSelectedFlights={getSelectedFlights}
					getAucData={getAucData}
					getOtherAucData={getOtherAucData}
					analysis={analysisValue}
					flightsInCurveModel={flightsInCurveModel}
					allMeanComparisonOptions={allMeanComparisonOptions}
					runMeanComparison={false}
					gduList={trialData?.trialInfo?.plantDate ? gduByDay : []}
					useGDUTimecourse={useGDU}
					getHasGduFormula={getHasGduFormula}
					quantifiedRegionTypeValue={selectedQuantifiedRegionTypeValue}
					parentSelectedAnalysisType={selectedAnalysisTypeId}
					plantDate={trialData?.trialInfo?.plantDate}
					isForAnalysisTimecoursePage={false}
				/>
			) : (
				<>Currently, there is no data to display</>
			)}
		</Segment>
	);
};

FlightSelection.propTypes = {
	timecourseData: PropTypes.array,
	trialData: PropTypes.object,
	analyses: PropTypes.array,
	getSelectedFlights: PropTypes.func,
	getAucData: PropTypes.func,
	getOtherAucData: PropTypes.func,
	flightsInCurveModel: PropTypes.array,
	curveFitFunctions: PropTypes.array,
	analysisTypes: PropTypes.array,
	curveModelBatchAssessments: PropTypes.array,
	getFlightSelectionAnalysisId: PropTypes.func,
	getFlightSelectionCurveFitFunctionId: PropTypes.func,
	allMeanComparisonOptions: PropTypes.object,
	gduByDay: PropTypes.array,
	quantifiedRegionTypeOptions: PropTypes.array,
	quantifiedRegionTypeValue: PropTypes.string,
	getFlightSelectionQuantifiedRegionTypeId: PropTypes.func,
	getFlightSelectionAnalysisTypeId: PropTypes.func,
	fitFunctionValue: PropTypes.string
};

export default FlightSelection;
