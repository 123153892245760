import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";

import { Segment, Table, Pagination, Dropdown } from "semantic-ui-react";

import "../styles.css";

const CmMembership = ({ clientData }) => {
	//-- Table
	const DATE_FORMAT = "MM/DD/YYYY";

	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [dataSource, setDataSource] = useState([]);

	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	useEffect(() => {
		if (clientData && clientData.memberships) {
			setTotalPages(Math.ceil(clientData.memberships.length / itemsPerPage));
			setDataSource(clientData.memberships);
			setTableData(dataSource);
		}
	}, [clientData]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	}

	// prettier-ignore
	return (
		<Segment basic>
			<Table celled striped sortable selectable color="blue">
      	<Table.Header>
      	  <Table.Row textAlign="center">
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'member' ? sortDirection : null}
      	        onClick={() => handleSort('member')}>Member</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'hasUser' ? sortDirection : null}
      	        onClick={() => handleSort('hasUser')}>Has User</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'userEnabled' ? sortDirection : null}
      	        onClick={() => handleSort('userEnabled')}>User Enabled</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'personEnabled' ? sortDirection : null}
      	        onClick={() => handleSort('personEnabled')}>Person Enabled</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'emailAddress' ? sortDirection : null}
      	        onClick={() => handleSort('emailAddress')}>Email Address</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'memberSince' ? sortDirection : null}
      	        onClick={() => handleSort('memberSince')}>Member Since</Table.HeaderCell>
      	    <Table.HeaderCell
      	        sorted={sortColumn === 'addedBy' ? sortDirection : null}
      	        onClick={() => handleSort('addedBy')}>Added By</Table.HeaderCell>
						<Table.HeaderCell>Licenses</Table.HeaderCell>
      	  </Table.Row>
      	</Table.Header>
			
      	<Table.Body>
      	    {
      	    _.map(tableData, ({clientMembershipId, userId, userEnabled, personEnabled, firstName, lastName, emailAddress, memberSinceDate, addedByUserFirstName, addedByUserLastName}) => {
      	      return (
      	        <Table.Row key={clientMembershipId}>
      	          <Table.Cell>{`${firstName} ${lastName}`}</Table.Cell>
									<Table.Cell textAlign="center">{userId === null || userId === undefined ? "No" : "Yes"}</Table.Cell>
									<Table.Cell textAlign="center">{userId === null || userId === undefined ? "-" : userEnabled ? "Yes" : "No"}</Table.Cell>
									<Table.Cell textAlign="center">{personEnabled ? "Yes" : "No"}</Table.Cell>
									<Table.Cell textAlign="center">
										{ emailAddress ? <a href={`mailto:${emailAddress}`}>{emailAddress}</a> : "-" }
									</Table.Cell>
      	          <Table.Cell textAlign="center">{moment(memberSinceDate).local().format(DATE_FORMAT)}</Table.Cell>
      	          <Table.Cell textAlign="center">{addedByUserFirstName === "System" ? addedByUserFirstName : `${addedByUserFirstName} ${addedByUserLastName}`}</Table.Cell>
      	          <Table.Cell textAlign="center">
      	            {_.map(_.orderBy(clientData.licenses, ["licenseName"], ["asc"]).filter(p => p.userId === userId), license => {
      	              return (
      	                <div key={license.licenseId}>
      	                  {license.licenseName}
      	                  <br />
      	                </div>
      	              );
      	            })}
      	          </Table.Cell>
      	        </Table.Row>
      	      );
      	    })
      	  }
      	</Table.Body>
				
      	<Table.Footer>
					<Table.Row textAlign="right">
						<Table.HeaderCell colSpan="11">
      	      <span style={{ marginRight: 10 }}>{dataSource.length === 1 ? `${dataSource.length} member found` : `${dataSource.length} members found`}</span>
							<Pagination
								onPageChange={(e, pageInfo) => {
									setActivePage(pageInfo.activePage);
								}}
								boundaryRange={5}
								siblingRange={1}
								totalPages={totalPages}
								activePage={activePage}
							/>
      	      <Dropdown
								onChange={(e, { value }) => {
									setItemsPerPage(value);
								}}
								selection
								options={[
									{ key: 1, text: 5, value: 5 },
									{ key: 2, text: 10, value: 10 },
									{ key: 3, text: 15, value: 15 },
									{ key: 4, text: 25, value: 25 },
									{ key: 5, text: 50, value: 50 }
								]}
      	        value={itemsPerPage}
      	        compact
      	        style={{marginLeft: 5, height: 42, paddingTop: 12}}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
    	</Table>
		</Segment>
	);
};

CmMembership.propTypes = {
	clientData: PropTypes.object,
	onMemberSaved: PropTypes.func.isRequired,
	onMemberRemoved: PropTypes.func.isRequired
};

export default CmMembership;
