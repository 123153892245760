import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { useAuth0 } from "../../../auth/auth0";
import * as treatmentActions from "../../../redux/actions/treatmentActions";
import TreatmentDataInspectionResults from "./TreatmentDataInspectionResults";
import "./styles.css";

const TreatmentCSVUpload = ({
	hasImportedData,
	context = "trial",
	trialData,
	currentData,
	onSelectFile = () => {},
	onAcceptCSV = (results) => {
		results;
	},
	onRemoveFile = () => {},
	onClearTreatments = () => {}
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const fileInputRef = useRef();
	const [selectedFile, setSelectedFile] = useState(null);
	const [inspecting, setInspecting] = useState(false);
	const [inspectionResults, setInspectionResults] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [openOverwriteWarning, setOpenOverwriteWarning] = useState(false);
	const [clearConfirmed, setClearConfirmed] = useState(false);

	useEffect(() => {
		if (selectedFile) {
			onSelectFile(selectedFile);
			inpsectCSV(selectedFile);
		}
	}, [selectedFile]);

	useEffect(() => {
		if (clearConfirmed) {
			window.addEventListener("click", unconfirmClear, { once: true });
		}

		return () => {
			window.removeEventListener("click", unconfirmClear);
		};
	}, [clearConfirmed]);

	const handleBrowseFiles = () => {
		if (hasImportedData) {
			setOpenOverwriteWarning(true);
		} else if (fileInputRef.current) {
			setSelectedFile(null);
			fileInputRef.current.value = null;
			fileInputRef.current.click();
		}
	};

	const handleSelectFile = (e) => {
		e.persist();

		if (e.target.files.length > 0) {
			const csv = e.target.files[0];
			setSelectedFile(csv);
		}
	};

	const handleRemoveFile = () => {
		fileInputRef.current.value = null;
		setSelectedFile(null);
		setInspectionResults(null);
		onRemoveFile();
	};

	const inpsectCSV = async (csv) => {
		if (csv) {
			setInspecting(true);
			const accessToken = await getTokenSilently();
			try {
				const res = await dispatch(treatmentActions.inspectTreatmentData(csv, trialData, accessToken));
				setInspectionResults(res);
				setOpenModal(true);
			} catch (er) {
				toast.error(er.errors[0]);
				handleRemoveFile();
			} finally {
				setInspecting(false);
			}
		}
	};

	const unconfirmClear = useCallback(() => {
		setClearConfirmed(false);
	}, []);

	const handleClearData = useCallback(
		(e) => {
			e.stopPropagation();
			if (clearConfirmed) {
				onClearTreatments();
				setClearConfirmed(false);
			} else {
				setClearConfirmed(true);
			}
		},
		[clearConfirmed]
	);

	return (
		<>
			<div style={{ marginBottom: 14 }}>
				<div style={{ fontSize: 16, marginBottom: 14, fontWeight: 700, lineHeight: "16px" }}>Upload Treatments CSV</div>
				<div style={{ display: "flex" }}>
					<div style={{ flexGrow: 3, maxWidth: 500 }}>
						<Input
							className="fileUploadInput"
							fluid
							type="text"
							action
							actionPosition="left"
							readOnly
							value={selectedFile?.name ?? ""}
							disabled={inspecting}
						>
							<Button
								content="Browse..."
								labelPosition="left"
								loading={inspecting}
								icon="file"
								onClick={handleBrowseFiles}
							/>
							<input
								style={{
									width: "auto",
									textOverflow: "ellipsis"
								}}
							/>
							<Button icon="trash" onClick={handleRemoveFile} />
						</Input>
						<input
							id="fieldLayoutUpload"
							ref={fileInputRef}
							type="file"
							accept=".csv"
							hidden
							onChange={handleSelectFile}
						/>
					</div>
					<div style={{ flexGrow: 1 }} />
					<div style={{ display: "flex", gap: 10 }}>
						<Button
							color={inspectionResults ? (inspectionResults.areValid ? "green" : "red") : undefined}
							content="Inspection Results"
							labelPosition="left"
							icon="clipboard check"
							onClick={() => setOpenModal(true)}
						/>
						<Button
							className={clearConfirmed ? "confirmAction" : ""}
							color={clearConfirmed ? "red" : undefined}
							labelPosition="left"
							content={clearConfirmed ? "Confirm?" : "Clear"}
							icon="eraser"
							onClick={handleClearData}
						/>
						<CSVLink
							className="ui icon left labeled button"
							filename={`${trialData.name} - Treatment Data.csv`}
							data={currentData}
							enclosingCharacter=""
							style={{ color: "rgba(0, 0, 0, .6)", lineHeight: "16px" }}
						>
							<Icon name="download" />
							Current Data
						</CSVLink>
					</div>
				</div>
			</div>
			<Modal open={openOverwriteWarning}>
				<Modal.Header>Import Treatment Data</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<p>Uploading a file will overwrite any data currently in the table.</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button type="button" onClick={() => setOpenOverwriteWarning(false)}>
						Cancel
					</Button>
					<Button
						type="button"
						onClick={() => {
							setOpenOverwriteWarning(false);
							setSelectedFile(null);
							fileInputRef.current.value = null;
							fileInputRef.current.click();
						}}
						primary
					>
						Continue
					</Button>
				</Modal.Actions>
			</Modal>
			<TreatmentDataInspectionResults
				willSave={context === "trial"}
				open={openModal}
				setOpen={setOpenModal}
				onAccept={() => onAcceptCSV(inspectionResults.inspections.importTreatmentData)}
				inspectionResults={inspectionResults}
				columns={
					context === "trial"
						? ["name", "type", "description"]
						: ["type", "name", "confidentialName", "description", "seed"]
				}
			/>
		</>
	);
};

TreatmentCSVUpload.propTypes = {
	onSelectFile: PropTypes.func,
	onAcceptCSV: PropTypes.func.isRequired,
	onClearTreatments: PropTypes.func,
	context: PropTypes.string,
	trialData: PropTypes.object.isRequired,
	hasImportedData: PropTypes.bool,
	onRemoveFile: PropTypes.func,
	currentData: PropTypes.array
};

export default TreatmentCSVUpload;
