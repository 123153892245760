import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Segment, Loader } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import moment from "moment";

import * as groundDataActions from "../../../../redux/actions/groundDataActions";
import NewGroundData from "../../../Lumber/GroundData/NewGroundData";

import { toast } from "react-toastify";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

const TrialGroundData = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const history = useHistory();

	const userSettings = useUserSettings();

	//-- Data Sources
	const [groundDataQuestions, setGroundDataQuestions] = useState(null);
	const [growthStages, setGrowthStages] = useState([]);
	const [persons, setPersons] = useState([]);
	const [trialOutlineData, setTrialOutlineData] = useState([]);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));
	const [trialName, setTrialName] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userAuth.currentClientId && !groundDataQuestions) {
			getGroundDataInfo();
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		if (entityNames?.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			setLoading(false);
		}
	}, [entityNames]);

	async function getGroundDataInfo() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(groundDataActions.getGroundDataInfo("", moduleNavigation.trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				//TODO need to add grwth phases here
				setGroundDataQuestions(res.data.groundDataQuestions);
				setGrowthStages(res.data.growthStages);
				setPersons(res.data.persons);
				setTrialOutlineData(res.data.trialOutlineData);
				setLoading(false);
			})
			.catch((err) => {
				toast.error("Error trial ground data info. Please try again.");
				console.log(err);
				setLoading(false);
			});
	}

	function onSave() {
		history.push(moduleNavigation.createTrialLink(true, null, "analysis"));
	}

	return loading || !groundDataQuestions ? (
		<Loader active />
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Build Your Own Trial Ground Dataset</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
				></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			<NewGroundData
				groundDataQuestions={groundDataQuestions}
				growthStages={growthStages}
				persons={persons}
				outlineData={trialOutlineData}
				fileName={`${trialOutlineData[0]?.trial}_GroundData_${moment().format("YYYYMMDD_hhmma")}.csv`}
				onSave={onSave}
			/>
		</Segment>
	);
};

export default TrialGroundData;
