import * as types from "./actionTypes";
import * as autoAlignApi from "../../apis/autoAlignApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function alignPlotsSuccess() {
	return { type: types.ANALYSIS_ALIGN_PLOTS_SUCCESS };
}

export function scalePlotsSuccess() {
	return { type: types.ANALYSIS_SCALE_PLOTS_SUCCESS };
}

export function scaleAndAlignPlotsSuccess() {
	return { type: types.ANALYSIS_SCALE_AND_ALIGN_PLOTS_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function alignPlots(objectToPass, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return autoAlignApi
				.alignPlots(objectToPass, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(alignPlotsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function scalePlots(objectToPass, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return autoAlignApi
				.scalePlots(objectToPass, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(scalePlotsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function scaleAndAlignPlots(objectToPass, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return autoAlignApi
				.scaleAndAlignPlots(objectToPass, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(scaleAndAlignPlotsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
