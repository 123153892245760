const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getClients(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Clients/GetClients?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getClientInfo(accessToken, clientId, currentClientId) {
	return fetch(`${aerialPlotApi}api/v1/Clients?clientId=${clientId}&currentClientId=${currentClientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getLicenses(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Clients/GetLicenses?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateClient(accessToken, clientUpdate, currentClientId) {
	const clientFormData = new FormData();

	for (const name in clientUpdate) {
		let val = clientUpdate[name] === "" ? null : clientUpdate[name];

		if (val) {
			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			clientFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Clients?currentClientId=${currentClientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: clientFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createClient(accessToken, clientNew, currentClientId) {
	const clientFormData = new FormData();

	for (const name in clientNew) {
		let val = clientNew[name] === "" ? null : clientNew[name];

		if (val) {
			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			clientFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Clients?currentClientId=${currentClientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: clientFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function removeMembership(accessToken, clientMembershipId) {
	return fetch(`${aerialPlotApi}api/v1/Clients/RemoveMembership?clientMembershipId=${clientMembershipId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateMember(accessToken, memberObject) {
	const memberFormData = new FormData();

	for (const name in memberObject) {
		if (name !== "licenses") {
			let val = memberObject[name] === "" ? null : memberObject[name];

			if (val) {
				if (name.match(/date/gim) && val) {
					val = val.toLocaleDateString();
				}

				memberFormData.set(name, val);
			}
		} else {
			let index = 0;
			for (const rId of memberObject.licenses) {
				memberFormData.append(`Licenses[${index}]`, rId);
				index++;
			}
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Clients/UpdateMember`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: memberFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getPotentialMembers(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Clients/GetPotentialMembers?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function addMember(accessToken, newMember) {
	const memberFormData = new FormData();

	for (const name in newMember) {
		if (name !== "licenses") {
			let val = newMember[name] === "" ? null : newMember[name];

			if (val) {
				if (name.match(/date/gim) && val) {
					val = val.toLocaleDateString();
				}

				memberFormData.set(name, val);
			}
		} else {
			let index = 0;
			for (const rId of newMember.licenses) {
				memberFormData.append(`Licenses[${index}]`, rId);
				index++;
			}
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Clients/AddMember`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: memberFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
