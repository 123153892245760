import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function orthoUploadReducer(state = initialState.uploadingOrthos, action) {
	switch (action.type) {
		case types.ORTHO_ADD_ORTHO_UPLOAD: {
			let newArray = state.slice();
			newArray.splice(state.length, 0, action.orthoToAdd);
			return newArray;
		}
		case types.ORTHO_UPDATE_UPLOADING_ORTHO_PROGRESS: {
			return state.map((x) => {
				if (x.flightImageId === action.orthoToUpdate.flightImageId) {
					return {
						...x,
						...action.orthoToUpdate
					};
				}
				return x;
			});
		}
		case types.ORTHO_REMOVE_ORTHO_UPLOAD: {
			return state.filter((x) => x.flightImageId !== action.orthoIdToRemove);
		}
		default:
			return state;
	}
}
