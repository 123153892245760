// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".centerText {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.ui.form .field {\n\tmargin: 0;\n\tmargin-right: 0.25em;\n}\n\n.ui.form .disabled.field,\n.ui.form .disabled.fields .field,\n.ui.form .field :disabled {\n\topacity: 0.45 !important;\n}\n\n.ui.sortable.table thead th.sorted,\n.ui.sortable.table thead th:hover {\n\tbackground: #f3f3f3 !important;\n}\n\n.azureFileBrowseTableWrapper {\n\tmax-height: 500px;\n\toverflow-y: auto;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Flights/OrthoUpload/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,SAAS;CACT,oBAAoB;AACrB;;AAEA;;;CAGC,wBAAwB;AACzB;;AAEA;;CAEC,8BAA8B;AAC/B;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;CAChB,gBAAgB;AACjB","sourcesContent":[".centerText {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.ui.form .field {\n\tmargin: 0;\n\tmargin-right: 0.25em;\n}\n\n.ui.form .disabled.field,\n.ui.form .disabled.fields .field,\n.ui.form .field :disabled {\n\topacity: 0.45 !important;\n}\n\n.ui.sortable.table thead th.sorted,\n.ui.sortable.table thead th:hover {\n\tbackground: #f3f3f3 !important;\n}\n\n.azureFileBrowseTableWrapper {\n\tmax-height: 500px;\n\toverflow-y: auto;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerText": "centerText",
	"ui": "ui",
	"form": "form",
	"field": "field",
	"disabled": "disabled",
	"fields": "fields",
	"sortable": "sortable",
	"table": "table",
	"sorted": "sorted",
	"azureFileBrowseTableWrapper": "azureFileBrowseTableWrapper"
};
export default ___CSS_LOADER_EXPORT___;
