import * as types from "./actionTypes";
import * as lookupApi from "../../apis/lookupApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getStatesSuccess(states) {
	return { type: types.LU_GET_STATES_SUCCESS, states };
}

/*
 *  Thunks
 *
 */

export function getStates(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return lookupApi
				.getStates(clientId, accessToken)
				.then((res) => {
					dispatch(getStatesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
