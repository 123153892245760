import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Grid, Modal } from "semantic-ui-react";
import * as XLSX from "xlsx";

const ViewerDataExportModal = ({ timecourseData, growthCurveData, groundData }, ref) => {
	const [modalOpen, setModalOpen] = useState(false);

	ViewerDataExportModal.displayName = "ViewerDataExportModal";

	timecourseData = [1];
	growthCurveData = [1];
	groundData = [1];

	function writeDataToExcel() {
		var workbook = XLSX.utils.book_new();

		var worksheet1 = XLSX.utils.aoa_to_sheet([
			["NDVI"],
			["Field", "Range", "Column", "Company", "Trial", "Plot", "Rep", "Seed", "Flight 1", "Flight 2", "Flight 3"],
			["Test Field", "1", "1", "Test Company", "Test Trial", "101", "1", "Test Seed", ".5", ".75", ".8"]
		]);

		var worksheet2 = XLSX.utils.aoa_to_sheet([
			["NDRE"],
			["Field", "Range", "Column", "Company", "Trial", "Plot", "Rep", "Seed", "Flight 1", "Flight 2", "Flight 3"],
			["Test Field", "1", "1", "Test Company", "Test Trial", "101", "1", "Test Seed", ".2", ".35", ".5"]
		]);

		XLSX.utils.book_append_sheet(workbook, worksheet1, "Test 1");
		XLSX.utils.book_append_sheet(workbook, worksheet2, "Test 2");

		XLSX.writeFile(workbook, "test.xlsx");
	}

	function hasData(array) {
		return Array.isArray(array) && array.length > 0;
	}

	function getCheckboxesToRender() {
		var renderTimecourseCheckbox = hasData(timecourseData);
		var renderDigitalPhenotypesCheckbox = hasData(growthCurveData);
		var renderGroundDataCheckbox = hasData(groundData);

		return (
			<>
				<Grid.Column width={16} textAlign="center">
					{renderTimecourseCheckbox && (
						<Grid.Row>
							<Checkbox label={"Timecourse Data"}></Checkbox>
						</Grid.Row>
					)}
					{renderDigitalPhenotypesCheckbox && (
						<Grid.Row>
							<Checkbox label={"Digital Phenotypes"}></Checkbox>
						</Grid.Row>
					)}
					{renderGroundDataCheckbox && (
						<Grid.Row>
							<Checkbox fitted label={"Growth Curve"}></Checkbox>
						</Grid.Row>
					)}
				</Grid.Column>
			</>
		);
	}

	return (
		<Modal
			open={modalOpen}
			size={"mini"}
			trigger={
				<Button
					style={{ visibility: "hidden" }}
					ref={ref}
					onClick={() => {
						setModalOpen(true);
					}}
				>
					Show Modal
				</Button>
			}
		>
			<Modal.Header>Export Viewer Data</Modal.Header>
			<Modal.Content>
				<Grid>
					{getCheckboxesToRender()}
					<Grid.Row textAlign="center">
						<Grid.Column width={16}>
							<Button
								style={{ backgroundColor: "#2185D0", color: "white" }}
								onClick={() => {
									writeDataToExcel();
									setModalOpen(false);
								}}
							>
								Export
							</Button>
							<Button /*style={{ backgroundColor: "#DB2828", color: "white" }}*/ onClick={() => setModalOpen(false)}>
								Cancel
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Content>
		</Modal>
	);
};

ViewerDataExportModal.propTypes = {
	timecourseData: PropTypes.object,
	growthCurveData: PropTypes.object,
	groundData: PropTypes.object
};

export default React.forwardRef(ViewerDataExportModal);
