import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import { Breadcrumb } from "semantic-ui-react";
// import { useUserAuth } from "../../../hooks/useUserAuth";
import { useAuth0 } from "../../../auth/auth0";

import { moduleNames, allRoutes, pageNotFoundRoute } from "../../routes";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import * as entityActions from "../../../redux/actions/entityActions";
// import * as protocolActions from "../../../redux/actions/protocolActions";
import { guidRegex } from "../../../utilities/regex";
import { toTitleCase } from "../../../utilities/jsUtils";

const Breadcrumbs = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	// const userAuth = useUserAuth();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));
	const protocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	// const user = useSelector((state) => state.user);
	const currentClientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [buildingBreadcrumbs, setBuildingBreadcrumbs] = useState(true);

	const [retrievingEntityNames, setRetrievingEntityNames] = useState(true);
	const [flightName, setFlightName] = useState(null);
	const [fieldName, setFieldName] = useState(null);
	const [trialName, setTrialName] = useState(null);
	const [protocolName, setProtocolName] = useState(null);

	const [breadcrumbs, setBreadcrumbData] = useState([]);

	useEffect(() => {
		if (!moduleNavigation.protocolId) {
			getEntityNames();
		} else {
			getProtocolEntity();
		}
	}, [moduleNavigation.currentLocation, currentClientId]);

	async function getProtocolEntity() {
		if (protocolData?.id && protocolData?.id === moduleNavigation.protocolId) {
			setProtocolName(protocolData.protocolName);
			setRetrievingEntityNames(false);
		}
	}

	async function getEntityNames() {
		setRetrievingEntityNames(true);
		const accessToken = await getTokenSilently();
		if (!moduleNavigation.protocolId) {
			if (
				!(
					moduleNavigation.flightId === entityNames?.flightId &&
					moduleNavigation.fieldId === entityNames?.fieldId &&
					moduleNavigation.trialId === entityNames?.trialId
				)
			) {
				dispatch(
					entityActions.getEntityNames(
						moduleNavigation.flightId,
						moduleNavigation.fieldId,
						moduleNavigation.trialId,
						accessToken
					)
				)
					.then((res) => {
						setFlightName(res.flightName);
						setFieldName(res.fieldName);
						setTrialName(res.trialName);
						setRetrievingEntityNames(false);
					})
					.catch((err) => {
						setRetrievingEntityNames(false);
						console.log(err);
					});
			}
		}
	}

	useEffect(() => {
		if (!retrievingEntityNames) {
			setBreadcrumbData(generateBreadcrumbs());
		}
	}, [moduleNavigation.currentLocation, retrievingEntityNames]);

	function formatBreadcrumbSection(str) {
		if (str) {
			var formattedStr = str.replace("-", " ");
			formattedStr = toTitleCase(formattedStr);
			return formattedStr;
		}
		return null;
	}

	function generateBreadcrumbs() {
		//-- Breadcrumbs are generated based on the url
		//-- If there is an Id for a module (i.e. flight, field, or trial), then we can add it to the breadcrumb
		//-- The last item in the breadcrumb is always the active submodule (or the module in case of a module not having any submodules)
		const moduleIdsInUrl = moduleNavigation.moduleIdsFromUrl;
		const activeSubmodule = moduleNavigation.activeSubmodule;

		let bcs = [];
		_.forIn(moduleIdsInUrl, (currentModuleId, moduleName) => {
			let breadcrumb = { isModule: true, module: moduleName, displayText: moduleName };
			if (currentModuleId?.match(guidRegex)) {
				breadcrumb.id = currentModuleId;
				breadcrumb.showInTrail =
					activeSubmodule.showModuleIdInBreadcrumb === undefined || activeSubmodule.showModuleIdInBreadcrumb
						? true
						: false;
				switch (breadcrumb.module) {
					case "flights":
						breadcrumb.url = moduleNavigation.createFlightLink(false, currentModuleId);
						breadcrumb.displayText = flightName;
						break;
					case "fields":
						breadcrumb.url = moduleNavigation.createFieldLink(true, currentModuleId);
						breadcrumb.displayText = fieldName;
						break;
					case "trials":
						breadcrumb.url = moduleNavigation.createTrialLink(true, currentModuleId);
						breadcrumb.displayText = trialName;
						break;
					case "protocols":
						breadcrumb.url = moduleNavigation.createProtocolLink(true, currentModuleId);
						breadcrumb.displayText = protocolName;
						break;
					default:
						breadcrumb.url = null;
						breadcrumb.displayText = null;
						break;
				}
			}

			if (moduleNames.includes(breadcrumb.module) && allRoutes.find((r) => r.name === breadcrumb.module).submodules) {
				bcs.push(breadcrumb);
			}
		});

		const pathIsDisabled = !moduleNavigation.currentPathIsEnabled && Object.keys(moduleIdsInUrl)[0] !== "home";
		const usePageNotFound =
			pathIsDisabled ||
			(bcs.length === 0 && !moduleNames.includes(Object.keys(moduleIdsInUrl)[0])) ||
			!allRoutes.map((r) => r.name).includes(Object.keys(moduleIdsInUrl)[Object.keys(moduleIdsInUrl).length - 1]);

		//-- Add the final breadcrumb
		bcs.push({
			isModule: false,
			module: null,
			id: "activeSubmodule",
			url: null,
			displayText: usePageNotFound
				? pageNotFoundRoute.linkText
				: bcs.length === 0
				? Object.keys(moduleIdsInUrl)[0] === "home"
					? "Going home..."
					: Object.keys(moduleIdsInUrl)[0] //-- Handles case where the module has no submodules
				: activeSubmodule.linkText ?? pageNotFoundRoute.linkText //-- Handles case where the module has submodules, but an submodule could not be found
		});

		setBuildingBreadcrumbs(false);
		return bcs;
	}

	return buildingBreadcrumbs ? (
		<Breadcrumb>
			<Breadcrumb.Section>
				<NavLink to={moduleNavigation.homeModule.paths[0]}>Home</NavLink>
			</Breadcrumb.Section>
		</Breadcrumb>
	) : (
		<Breadcrumb>
			<Breadcrumb.Section>
				<NavLink to={moduleNavigation.homeModule.paths[0]}>Home</NavLink>
			</Breadcrumb.Section>
			{
				//-- Build the module crumbs
				_.map(
					breadcrumbs.filter((bc) => bc.isModule),
					(breadcrumb) => {
						return (
							<React.Fragment key={`module_${breadcrumb.module}_crumbs`}>
								{breadcrumb.displayText && (
									<>
										<Breadcrumb.Divider key={breadcrumb.module + "_divider"} icon="right arrow" />
										<Breadcrumb.Section key={breadcrumb.module}>
											{formatBreadcrumbSection(breadcrumb.module)}
										</Breadcrumb.Section>

										{breadcrumb.showInTrail && breadcrumb.id && (
											<>
												<Breadcrumb.Divider key={breadcrumb.id + "divider"} icon="right arrow" />
												<Breadcrumb.Section key={breadcrumb.id}>
													<NavLink key={breadcrumb.id + "_navlink"} to={breadcrumb.url}>
														{breadcrumb.displayText}
													</NavLink>
												</Breadcrumb.Section>
											</>
										)}
									</>
								)}
							</React.Fragment>
						);
					}
				)
			}
			{/*  //-- Build the submodule crumbs */}
			<Breadcrumb.Divider icon="right arrow" />
			<Breadcrumb.Section active>
				{formatBreadcrumbSection(breadcrumbs.find((bc) => !bc.isModule).displayText)}
			</Breadcrumb.Section>
		</Breadcrumb>
	);
};

export default Breadcrumbs;
