import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { useAuth0 } from "../../../auth/auth0";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { Modal, Icon, Loader } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

import { toast } from "react-toastify";

import * as trialActions from "../../../redux/actions/trialActions";

import PubUnpubTrialFlights from ".";
import "./loaderWorkaround.css";

const PubUnpubTrialFlightsFromSearch = ({ trialId }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	//-- Trial data
	const [workingTrial, setWorkingTrial] = useState({});
	const [trialFlights, setTrialFlights] = useState([]);
	const [trialOwnerUserId, setTrialOwnerUserId] = useState(null);
	const [trialOwnerClientId, setTrialOwnerClientId] = useState(null);
	const [isTrialOwner, setIsTrialOwner] = useState(false);
	const [flightScheduleOptions, setFlightScheduleOptions] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [innerModalOpen, setInnerModalOpen] = useState(false);

	useEffect(() => {
		if (modalOpen) {
			getTrialData(userAuth.currentClientId);
		}
	}, [modalOpen]);

	async function getTrialData(clientId) {
		setLoading(true);

		const accessToken = await getTokenSilently();
		dispatch(trialActions.getTrialPublishingData(clientId, trialId, accessToken))
			.then((res) => {
				if (res) {
					setTrialData(res.trialData);
					setTrialFlights(res.trialFlights.concat(res.doNotPublishTrialFlights));
					setFlightScheduleOptions(res.flightScheduleOptions);
				}
			})
			.catch((err) => {
				console.log("Error loading trial publishing data");
				console.log(err);
				setModalOpen(false);
				setLoading(false);
				setRefreshing(false);
			});
	}

	const setTrialData = (trialData) => {
		const td = {
			clientId: trialData.trialInfo.clientId,
			id: trialData.trialInfo.trialId,
			name: trialData.trialInfo.trialName,
			companyId: trialData.trialInfo.trialOwnerCompanyId,
			company: trialData.trialInfo.trialOwnerCompanyName,
			trialOwnerCompanyName: trialData.trialInfo.trialOwnerCompanyName,
			trialOwnerClientId: trialData.trialInfo.trialOwnerClientId,
			trialOwnerClientName: trialData.trialInfo.trialOwnerClientName,
			trialOwnerPersonId: trialData.trialInfo.trialOwnerPersonId,
			trialOwnerIsPending: trialData.trialInfo.trialOwnerIsPending,
			trialOwner: trialData.trialInfo.trialOwnerPersonId
				? `${trialData.trialInfo.trialOwnerFirstName} ${trialData.trialInfo.trialOwnerLastName}`
				: "N/A",
			trialOwnerEmail: trialData.trialInfo.trialOwnerEmailAddress,
			trialSponsorCompanyName: trialData.trialInfo.trialSponsorCompanyName ?? "N/A",
			trialSponsorClientId: trialData.trialInfo.trialSponsorClientId,
			trialSponsorClientName: trialData.trialInfo.trialSponsorClientName,
			trialSponsorPersonId: trialData.trialInfo.trialSponsorPersonId,
			trialSponsorIsPending: trialData.trialInfo.trialSponsorIsPending,
			trialSponsor: trialData.trialInfo.trialSponsorPersonId
				? `${trialData.trialInfo.trialSponsorFirstName} ${trialData.trialInfo.trialSponsorLastName}`
				: "N/A",
			trialSponsorEmail: trialData.trialInfo.trialSponsorEmailAddress,
			fieldResearcher: trialData.trialInfo.fieldResearcher,
			location: trialData.trialInfo.farmName,
			gpsCoordinates:
				trialData.trialInfo.plot101Lat && trialData.trialInfo.plot101Long
					? `${trialData.trialInfo.plot101Lat}, ${trialData.trialInfo.plot101Long}`
					: ``,
			plantDate: trialData.trialInfo.plantDate ? new Date(trialData.trialInfo.plantDate) : null,
			harvestDate: trialData.trialInfo.harvestDate ? new Date(trialData.trialInfo.harvestDate) : null,
			treatments: trialData.treatments,
			repCount: trialData.trialInfo.repCount,
			notes: trialData.trialInfo.notes ? trialData.trialInfo.notes : "",
			cooperatorName: trialData.trialInfo.cooperatorName,
			city: trialData.trialInfo.farmCity,
			state: trialData.trialInfo.farmState,
			flightScheduleId: trialData.trialInfo.flightScheduleId
		};

		setWorkingTrial(td);
		setTrialOwnerUserId(trialData.trialInfo.trialOwnerUserId);
		setTrialOwnerClientId(trialData.trialInfo.trialOwnerClientId);

		let trialOwnerCheck =
			trialData.trialInfo.trialOwnerClientId === userAuth.currentClientId ||
			trialData.trialInfo.trialOwnerUserId === userAuth.user.userInfo.basic.userId;
		setIsTrialOwner(trialOwnerCheck);

		let trialUserName = trialOwnerCheck ? td.trialSponsor : td.trialOwner;
		let isPending = trialOwnerCheck ? td.trialSponsorIsPending : td.trialOwnerIsPending;
		if (trialUserName === "N/A") {
			setModalOpen(false);
			if (trialOwnerCheck) {
				toast.warn("Trial Sponsor must be set before trials can be published.");
			} else {
				toast.warn("Trial Owner must be set before trials can be published.");
			}
		} else if (isPending) {
			setModalOpen(false);
			if (trialOwnerCheck) {
				toast.warn("Trial Sponsor does not have a valid license to publish this trial to.");
			} else {
				toast.warn("Trial Owner does not have a valid license to publish this trial to.");
			}
		} else {
			setInnerModalOpen(true);
		}

		setLoading(false);
		setRefreshing(false);
	};

	function handlePublishUnpublishSave() {
		setRefreshing(true);
		setModalOpen(false);
	}

	return (
		<>
			<Modal
				trigger={
					<Tooltip
						placement="bottom"
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						trigger="hover"
						overlay={<p>Publishing</p>}
						transitionName="rc-tooltip-zoom"
					>
						<Icon
							style={{ color: "#4183c4", cursor: "pointer" }}
							name="leanpub"
							onClick={() => {
								setModalOpen(true);
							}}
						/>
					</Tooltip>
				}
				open={modalOpen}
			>
				{loading || refreshing ? (
					<>
						<Modal.Header>
							<Loader active className="workaround" />
							Getting Publishing/Unpublishing Data...
						</Modal.Header>
					</>
				) : (
					<PubUnpubTrialFlights
						trialFlights={trialFlights}
						isTrialOwner={
							trialOwnerUserId === userAuth.user.userInfo.basic.userId ||
							trialOwnerClientId === userAuth.currentClientId
						}
						handlePublishUnpublishSave={handlePublishUnpublishSave}
						trialUserName={isTrialOwner ? workingTrial.trialSponsor : workingTrial.trialOwner}
						isPending={isTrialOwner ? workingTrial.trialSponsorIsPending : workingTrial.trialOwnerIsPending}
						openModal={innerModalOpen}
						handleCancel={() => {
							setModalOpen(false);
							setInnerModalOpen(false);
						}}
						flightScheduleId={workingTrial.flightScheduleId}
						flightScheduleOptions={flightScheduleOptions}
						cooperatorName={workingTrial.cooperatorName}
						city={workingTrial.city}
						state={workingTrial.state}
						trialName={workingTrial.name}
					/>
				)}
			</Modal>
		</>
	);
};

PubUnpubTrialFlightsFromSearch.propTypes = {
	trialId: PropTypes.string.isRequired
};

export default PubUnpubTrialFlightsFromSearch;
