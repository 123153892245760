import { combineReducers } from "redux";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";
import protocolDataReducer from "./protocolDataReducer";
import orthoUploadReducer from "./orthoUploadReducer";
import orthoViewerReducer from "./orthoViewerReducer";
import entityNamesReducer from "./entityNamesReducer";
import apiCallsInProgress from "./apiStatusReducer";
import meanComparisonReducer from "./meanComparisonReducer";
import flightElevationOrthoUploadReducer from "./flightElevationOrthoUploadReducer";
import flightMultibandOrthoUploadReducer from "./flightMultibandOrthoUploadReducer";
import clientLogoReducer from "./clientLogoReducer";
import autoUploadedOrthos from "./autoUploadedOrthoReducer";

const rootReducer = combineReducers({
	user: userReducer,
	clients: clientReducer,
	protocolData: protocolDataReducer,
	orthoUploads: orthoUploadReducer,
	flightElevationOrthoUploads: flightElevationOrthoUploadReducer,
	flightMultibandOrthoUploads: flightMultibandOrthoUploadReducer,
	orthoViewer: orthoViewerReducer,
	meanComparisonData: meanComparisonReducer,
	entityNames: entityNamesReducer,
	clientLogoUri: clientLogoReducer,
	apiCallsInProgress,
	autoUploadedOrthos
});

export default rootReducer;
