import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";

import { useDispatch } from "react-redux";

import { Button, Modal, Table, Dropdown, Pagination, Popup, Icon, Segment, Checkbox, Form, Select } from "semantic-ui-react";
import Tooltip from "rc-tooltip";
import "semantic-ui-css/semantic.min.css";

import { useAuth0 } from "../../../auth/auth0";

import { useUserAuth } from "../../../hooks/useUserAuth";

import * as trialActions from "../../../redux/actions/trialActions";

import { toast } from "react-toastify";

const PubUnpubTrialFlights = ({
	trialFlights,
	isTrialOwner,
	handlePublishUnpublishSave,
	trialUserName,
	isPending,
	openModal,
	handleCancel,
	trialName,
	flightScheduleId,
	cooperatorName,
	city,
	state,
	flightScheduleOptions,
	updateFilteredData,
}) => {
	const dispatch = useDispatch();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();

	//-- Datasource
	const [dataSource, setDataSource] = useState(_.orderBy(trialFlights, ["flightDate"]));

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	//-- UI Control
	const [showPublishing, setShowPublishing] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [flightScheduleDataOptions, setFlightScheduleDataOptions] = useState([]);
	const [updatedFlightScheduleId, setUpdatedFlightScheduleId] = useState([]);

	//-- Initial view load
	useEffect(() => {
		if (trialFlights) {
			setTotalPages(Math.ceil(trialFlights.length / itemsPerPage));
			setDataSource(_.orderBy(trialFlights, ["flightDate"]));
			setTableData(dataSource);
		}
	}, [trialFlights]);

	//-- Handle sorting and pagination changes
	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	//-- Open modal immediately
	useEffect(() => {
		if (openModal) {
			setShowPublishing(openModal);
		}
	}, [openModal]);

	useEffect(() => {
		if (flightScheduleOptions) {
			setInitialFlightScheduleNameOptions(flightScheduleOptions);
		}
	}, [flightScheduleOptions]);

	function setInitialFlightScheduleNameOptions(flightScheduleOptions) {
		let tempOptions =
			_.map(flightScheduleOptions, (flightSchedule) => {
				const opt = {
					key: flightSchedule.id,
					value: flightSchedule.id,
					text: flightSchedule.name
				};
				return opt;
			});
		tempOptions = _.orderBy(tempOptions, ["text"]);
		setFlightScheduleDataOptions(tempOptions);
		setUpdatedFlightScheduleId(flightScheduleId);
	}

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return new moment(d[clickedColumn]).format();
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total|treatmentId/i)) {
				setDataSource(_.sortBy(dataSource, [clickedColumn]));
			} else {
				setDataSource(
					_.sortBy(dataSource, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	};

	function handlePublish(trialId, flightId) {
		if (isTrialOwner) {
			setDataSource(
				_.map(dataSource, (ds) => {
					return ds.trialId === trialId && ds.flightId === flightId
						? { ...ds, isPublishedToTrialSponsor: !ds.isPublishedToTrialSponsor }
						: ds;
				})
			);
		} else {
			setDataSource(
				_.map(dataSource, (ds) => {
					return ds.trialId === trialId && ds.flightId === flightId
						? { ...ds, isPublishedToTrialOwner: !ds.isPublishedToTrialOwner }
						: ds;
				})
			);
		}
	}

	function handlePublishAll() {
		if (isTrialOwner) {
			setDataSource(
				_.map(dataSource, (ds) => {
					return { ...ds, isPublishedToTrialSponsor: true };
				})
			);
		} else {
			setDataSource(
				_.map(dataSource, (ds) => {
					return { ...ds, isPublishedToTrialOwner: true };
				})
			);
		}
	}

	function handleUnpublishAll() {
		if (isTrialOwner) {
			setDataSource(
				_.map(dataSource, (ds) => {
					return { ...ds, isPublishedToTrialSponsor: false };
				})
			);
		} else {
			setDataSource(
				_.map(dataSource, (ds) => {
					return { ...ds, isPublishedToTrialOwner: false };
				})
			);
		}
	}

	async function handleSave() {
		const trialId = trialFlights[0].trialId;

		setIsProcessing(true);
		const accessToken = await getTokenSilently();
		if (isTrialOwner) {
			handleSaveForTrialOwner(accessToken, trialId);
		} else {
			handleSaveForCRO(accessToken, trialId);
		}
	}

	function handleSaveForCRO(accessToken, trialId) {
		const trialOwnerUserId = trialFlights[0].publishedToTrialOwnerUserId;

		let publishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.isPublishedToTrialOwner === true && !tf.doNotPublish) {
				return tf;
			}
		});

		publishTrialFlights = _.map(publishTrialFlights, (f) => {
			return _.pick(f, ["flightId", "flightName", "flightDate", "trialName", "doNotPublish", "isPublishedToTrialOwner", "isPublishedToTrialSponsor"]);
		});

		let unpublishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.isPublishedToTrialOwner === false && !tf.doNotPublish) {
				return tf;
			}
		});

		unpublishTrialFlights = _.map(unpublishTrialFlights, (f) => {
			return _.pick(f, ["flightId", "doNotPublish", "isPublishedToTrialSponsor", "isPublishedToTrialOwner"]);
		});

		let doNotPublishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.doNotPublish) {
				return tf;
			}
		});

		dispatch(
			trialActions.publishTrialFlights(
				userAuth.currentClientId,
				trialId,
				trialName,
				publishTrialFlights,
				trialOwnerUserId,
				updatedFlightScheduleId,
				doNotPublishTrialFlights,
				accessToken
			)
		)
			.then(() => {
				dispatch(
					trialActions.unpublishTrialFlights(userAuth.currentClientId, trialId, unpublishTrialFlights, accessToken)
				).then(() => {
					handlePublishUnpublishSave();
					if (updateFilteredData !== undefined) {
						updateFilteredData(trialId, updatedFlightScheduleId, publishTrialFlights, unpublishTrialFlights, doNotPublishTrialFlights);
					}

					setIsProcessing(false);
					toast.success("Flight published");
					setShowPublishing(false);
				});
			})
			.catch((err) => {
				setIsProcessing(false);
				toast.error("Unable to publish this flight");
				console.log(err);
			});
	}

	function handleSaveForTrialOwner(accessToken, trialId) {
		const trialSponsorUserId = trialFlights[0].publishedToTrialSponsorUserId;

		let publishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.isPublishedToTrialSponsor === true && !tf.doNotPublish) {
				return tf;
			}
		});
		publishTrialFlights = _.map(publishTrialFlights, (f) => {
			return _.pick(f, ["flightId", "flightName", "flightDate", "trialName", "doNotPublish", "isPublishedToTrialSponsor", "isPublishedToTrialOwner"]);
		});
		let unpublishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.isPublishedToTrialSponsor === false && !tf.doNotPublish) {
				return tf;
			}
		});

		unpublishTrialFlights = _.map(unpublishTrialFlights, (f) => {
			return _.pick(f, ["flightId", "doNotPublish", "isPublishedToTrialSponsor", "isPublishedToTrialOwner"]);
		});

		let doNotPublishTrialFlights = _.filter(dataSource, (tf) => {
			if (tf.doNotPublish) {
				return tf;
			}
		});

		dispatch(
			trialActions.publishTrialFlightsToTrialSponsor(
				userAuth.currentClientId,
				trialId,
				trialName,
				publishTrialFlights,
				trialSponsorUserId,
				updatedFlightScheduleId,
				doNotPublishTrialFlights,
				accessToken
			)
		)
			.then(() => {
				dispatch(
					trialActions.unpublishTrialFlightsForTrialSponsor(
						userAuth.currentClientId,
						trialId,
						unpublishTrialFlights,
						accessToken
					)
				).then(() => {
					handlePublishUnpublishSave();
					if (updateFilteredData !== undefined) {
						updateFilteredData(trialId, updatedFlightScheduleId, publishTrialFlights, unpublishTrialFlights, doNotPublishTrialFlights);
					}
					setIsProcessing(false);
					toast.success("Flight published");
					setShowPublishing(false);
				});
			})
			.catch((err) => {
				setIsProcessing(false);
				toast.error("Unable to publish this flight");
				console.log(err);
			});
	}

	return (
		<Modal
			trigger={
				<Segment basic>
					<Button
						primary
						content="Publishing/Unpublishing"
						onClick={() => {
							if (trialUserName === "N/A") {
								setShowPublishing(false);
								if (isTrialOwner) {
									toast.warn("Trial Sponsor must be set before trials can be published.");
								} else {
									toast.warn("Trial Owner must be set before trials can be published.");
								}
							} else if (isPending) {
								setShowPublishing(false);
								if (isTrialOwner) {
									toast.warn("Trial Sponsor does not have a valid license to publish this trial to.");
								} else {
									toast.warn("Trial Owner does not have a valid license to publish this trial to.");
								}
							} else {
								setShowPublishing(true);
							}
						}}
					/>
					{trialUserName === "N/A" ? (
						<Popup
							content={
								isTrialOwner
									? "Trial Sponsor must be set before trials can be published."
									: "Trial Owner must be set before trials can be published."
							}
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
					) : null}
					{isPending && trialUserName !== "N/A" ? (
						<Popup
							content={
								isTrialOwner
									? "Trial Sponsor does not have a valid license to publish this trial to."
									: "Trial Owner does not have a valid license to publish this trial to."
							}
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
					) : null}
				</Segment>
			}
			open={showPublishing}
			onClose={() => setShowPublishing(false)}
		>
			<Modal.Header>{trialName} ({cooperatorName}, {city}, {state})</Modal.Header>
			<Modal.Header>Publishing/Unpublishing - {trialUserName}
				&nbsp;<span style={{ float: "right", marginTop: "-14px" }}>
					<Form.Field
						id="form-select-flight-schedule"
						control={Select}
						placeholder="Flight Schedule"
						search
						disabled={false}
						options={flightScheduleDataOptions}
						value={updatedFlightScheduleId}
						fluid
						onChange={(event, { value }) => {
							setUpdatedFlightScheduleId(value);
						}}
					/>
				</span>
				<span style={{ float: "right", paddingRight: "10px" }}>Flight Schedule</span>
			</Modal.Header>
			<Modal.Content style={{ paddingTop: "10px", maxHeight: "calc(75vh)", overflowY: "auto" }}>
				<Table celled striped sortable selectable color="blue" textAlign="center" >
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={sortColumn === "uasName" ? sortDirection : null}
								onClick={() => handleSort("uasName")}
							>
								UAS
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "flight" ? sortDirection : null}
								onClick={() => handleSort("flightDate")}
							>
								Flight
							</Table.HeaderCell>
							<Table.HeaderCell>Do Not Publish</Table.HeaderCell>
							<Table.HeaderCell>Publishing</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(
							tableData,
							({ trialId, flightId, flightName, isPublishedToTrialOwner, isPublishedToTrialSponsor, doNotPublish, uasName }, index) => {
								return (
									<Table.Row key={index}>
										<Table.Cell>{uasName}</Table.Cell>
										<Table.Cell>{flightName}</Table.Cell>
										<Table.Cell>
											<Checkbox
												checked={doNotPublish}
												onClick={(e, { checked }) => {
													let copy = _.cloneDeep(tableData);
													let trialIndex = _.find(copy, (c) => {
														return c.trialId === trialId && c.flightId === flightId;
													});

													trialIndex.doNotPublish = checked;
													setTableData(copy);

													let trialFlightsCopy = _.cloneDeep(dataSource);
													let trialFlightCopyIndex = _.find(trialFlightsCopy, (c) => {
														return c.trialId === trialId && c.flightId === flightId;
													});

													trialFlightCopyIndex.doNotPublish = checked;
													setDataSource(trialFlightsCopy);
												}}
											/>
										</Table.Cell>
										<Table.Cell>
											{isTrialOwner ? (
												<Button
													color={doNotPublish ? "grey" : isPublishedToTrialSponsor && !doNotPublish ? "red" : "green"}
													content={doNotPublish ? "Do Not Publish" : isPublishedToTrialSponsor && !doNotPublish ? "Published" : "Publish"}
													disabled={doNotPublish}
													onClick={() => handlePublish(trialId, flightId)}
												/>
											) : isPublishedToTrialSponsor && !isTrialOwner ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={<p>Cannot unpublish because flight has been published to the trial sponsor.</p>}
													transitionName="rc-tooltip-zoom"
												>
													<Button
														color={doNotPublish ? "grey" : isPublishedToTrialOwner && !doNotPublish ? "red" : "green"}
														content={doNotPublish ? "Do Not Publish" : isPublishedToTrialOwner && !doNotPublish ? "Published" : "Publish"}
														disabled={doNotPublish}
													/>
												</Tooltip>
											) : (
												<Button
													disabled={doNotPublish || (isPublishedToTrialSponsor && !isTrialOwner)}
													color={doNotPublish ? "grey" : isPublishedToTrialOwner && !doNotPublish ? "red" : "green"}
													content={doNotPublish ? "Do Not Publish" : isPublishedToTrialOwner && !doNotPublish ? "Published" : "Publish"}
													onClick={() => handlePublish(trialId, flightId)}
												/>
											)}
										</Table.Cell>
									</Table.Row>
								);
							}
						)}
					</Table.Body>
					<Table.Footer>
						<Table.Row textAlign="right">
							<Table.HeaderCell colSpan="13">
								<span style={{ marginRight: 10 }}>
									{dataSource.length === 1 ? `${dataSource.length} flight` : `${dataSource.length} flights`}
								</span>
								<Pagination
									onPageChange={(e, pageInfo) => {
										setActivePage(pageInfo.activePage);
									}}
									boundaryRange={5}
									siblingRange={1}
									totalPages={totalPages}
									activePage={activePage}
								/>
								<Dropdown
									onChange={(e, { value }) => {
										setItemsPerPage(value);
									}}
									selection
									options={[
										{ key: 1, text: 10, value: 10 },
										{ key: 2, text: 15, value: 15 },
										{ key: 3, text: 25, value: 25 },
										{ key: 4, text: 50, value: 50 },
										{ key: 5, text: 100, value: 100 },
										{ key: 6, text: 1000, value: 1000 }
									]}
									value={itemsPerPage}
									compact
									style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
								/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="yellow"
					content="Publish All"
					onClick={() => {
						handlePublishAll();
					}}
					loading={isProcessing}
					disabled={isProcessing}
				/>
				<Button
					color="yellow"
					content="Unpublish All"
					onClick={() => {
						handleUnpublishAll();
					}}
					loading={isProcessing}
					disabled={isProcessing}
				/>
				<Button
					primary
					content="Save"
					onClick={() => {
						handleSave();
					}}
					loading={isProcessing}
					disabled={isProcessing}
				/>
				<Button
					content="Cancel"
					onClick={() => {
						setDataSource(trialFlights);
						setTableData(dataSource);
						setShowPublishing(false);
						setUpdatedFlightScheduleId(flightScheduleId);
						if (handleCancel) handleCancel();
					}}
					disabled={isProcessing}
				/>
			</Modal.Actions>
		</Modal>
	);
};

PubUnpubTrialFlights.propTypes = {
	trialFlights: PropTypes.array,
	isTrialOwner: PropTypes.bool,
	handlePublishUnpublishSave: PropTypes.func,
	trialUserName: PropTypes.string,
	isPending: PropTypes.bool,
	openModal: PropTypes.bool,
	handleCancel: PropTypes.func,
	trialName: PropTypes.string,
	flightScheduleId: PropTypes.string,
	cooperatorName: PropTypes.string,
	city: PropTypes.string,
	state: PropTypes.string,
	flightScheduleOptions: PropTypes.array,
	updateFilteredData: PropTypes.func
};

export default PubUnpubTrialFlights;
