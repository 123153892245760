import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal, Loader, Icon, Popup, Message } from "semantic-ui-react";
import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { HubConnectionBuilder } from "@microsoft/signalr";

import * as orthoActions from "../../../../redux/actions/orthoActions";
import styles from "./styles.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const UploadMultibandImage = ({
	multibandImage,
	sensorId,
	clientId,
	azureBrowseCallback,
	azureUploadSuccessCallback,
	selectedAzureFile,
	componentId
}) => {
	const fileInputRef = useRef();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	//-- Data
	const [flightMultibandImage, setFlightMultibandImage] = useState(null);
	const [selectedOrtho, setSelectedOrtho] = useState(null);
	const [selectedOrthoName, setSelectedOrthoName] = useState(multibandImage?.name ?? "");
	const [isSelectedFileLocal, setisSelectedFileLocal] = useState(false);
	const uploadingFlightMultibandOrthos = useSelector((state) => state.flightMultibandOrthoUploads);
	const chunkSize = 1048576 * 3; //-- 3MB

	//-- UI Control
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [validationErrorState, setValidationErrorState] = useState(false);
	const [validationErrorMessage, setValidationErrorMessage] = useState("");

	//-- SignalR
	const [signalRConnection] = useState(setupSignalRConnection());
	const orthoRef = useRef(flightMultibandImage);
	const [uploadStatus, setUploadStatus] = useState(null);

	useEffect(() => {
		//-- On page leave or component unload, stop the signalR connection
		return () => stopSignalRConnection();
	}, []);

	useEffect(() => {
		if (!_.isEmpty(uploadingFlightMultibandOrthos)) {
			const updatedSelectedOrtho = uploadingFlightMultibandOrthos.find(
				(x) => x.flightId === moduleNavigation.flightId || x.multibandImageId === flightMultibandImage.multibandImageId
			);
			if (updatedSelectedOrtho) {
				if (
					updatedSelectedOrtho.maxNumberRetryAttempts <= updatedSelectedOrtho.retryAttempt &&
					updatedSelectedOrtho.showRetryButton
				) {
					setValidationErrorState(true);
					setValidationErrorMessage(
						"Ortho failed to upload. Please try again or contact support if the error continues."
					);
				}
				setFlightMultibandImage(updatedSelectedOrtho);
				setSelectedOrthoName(updatedSelectedOrtho.fileName);
			} else if (flightMultibandImage?.isUploading) {
				setFlightMultibandImage({ ...flightMultibandImage, showTooltip: true });
			}
		} else if (flightMultibandImage?.isUploading && uploadStatus !== "Uploaded") {
			setFlightMultibandImage({ ...flightMultibandImage, showTooltip: true });
		}
	}, [uploadingFlightMultibandOrthos]);

	useEffect(() => {
		if (multibandImage) {
			let clonedMultibandImage = _.cloneDeep(multibandImage);
			clonedMultibandImage.showRetryButton =
				clonedMultibandImage.isUploading && clonedMultibandImage.uploadStatus === "Uploading Image Chunks";
			clonedMultibandImage.flightId = moduleNavigation.flightId;
			clonedMultibandImage.isUploaded = !clonedMultibandImage.isUploading;
			orthoRef.current = clonedMultibandImage;
			setUploadStatus(clonedMultibandImage.uploadStatus);
			setFlightMultibandImage(clonedMultibandImage);
		}
	}, [multibandImage]);

	useEffect(() => {
		if (
			signalRConnection &&
			signalRConnection?.state === "Disconnected" &&
			flightMultibandImage?.isUploading === true
		) {
			signalRConnection
				.start()
				.then(() => {
					signalRConnection.on("multibandUploadStatus", (uploadStatus) => {
						//-- uploadStatus has the following properties
						//-- multibandImageId
						//-- isUploading
						//-- uploadStatus
						if (
							orthoRef.current.multibandImageId === uploadStatus.multibandImageId ||
							orthoRef.current.multibandImageId === uploadStatus.tempMultibandImageId
						) {
							setUploadStatus(uploadStatus.status);

							if (uploadStatus.status === "Uploaded") {
								dispatch(orthoActions.removeMultibandOrthoUpload(flightMultibandImage.flightId));

								setFlightMultibandImage({
									...flightMultibandImage,
									isUploaded: true,
									isUploading: false,
									showRetryButton: false
								});
							}
						}
					});
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [signalRConnection, flightMultibandImage]);

	useEffect(() => {
		if (flightMultibandImage) {
			orthoRef.current = flightMultibandImage;

			if (flightMultibandImage.isUploaded) {
				stopSignalRConnection();
			}
		}
	}, [flightMultibandImage]);

	useEffect(() => {
		if (selectedAzureFile) {
			const newFlightMultibandImage = {
				flightId: moduleNavigation.flightId,
				sensorId: sensorId,
				name: selectedAzureFile?.fileName,
				clientId: clientId,
				isUploading: false,
				isUploaded: false,
				isDeleting: false
			};

			setFlightMultibandImage(newFlightMultibandImage);
			setSelectedOrtho(selectedAzureFile);
			setSelectedOrthoName(selectedAzureFile?.fileName);
			setisSelectedFileLocal(false);
		}
	}, [selectedAzureFile]);

	function setupSignalRConnection() {
		const url = `${process.env.aerialPlotConnections.aerialImageApi.baseUrl}orthoUploadHub`;
		const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

		return newConnection;
	}

	function stopSignalRConnection() {
		if (signalRConnection) {
			signalRConnection.stop();
		}
	}

	function handleFileBrowse() {
		fileInputRef.current.click();
	}

	function fileSelectedHandler(event) {
		event.persist();

		if (event.target.files.length > 0) {
			const multibandFileData = event.target.files[0];
			if (flightMultibandImage) {
				const isValid = isFlightMultibandImageValid(multibandFileData);
				if (!isValid) {
					return;
				}
				let clonedMultibandImage = _.cloneDeep(flightMultibandImage);
				clonedMultibandImage.name = multibandFileData.name;
				setFlightMultibandImage(clonedMultibandImage);
			} else {
				let newFlightMultibandImage = {
					flightId: moduleNavigation.flightId,
					sensorId: sensorId,
					name: multibandFileData.name,
					clientId: clientId,
					isUploading: false,
					isUploaded: false,
					isDeleting: false
				};
				setFlightMultibandImage(newFlightMultibandImage);
			}

			setSelectedOrtho(multibandFileData);
			setSelectedOrthoName(multibandFileData.name);
			setisSelectedFileLocal(true);
		}
	}

	function isFlightMultibandImageValid(multibandFileData) {
		if (flightMultibandImage.showRetryButton === true && !multibandFileData) {
			setValidationErrorState(true);
			setValidationErrorMessage(
				"To retry an uploading ortho, you must Browse the same file: " + flightMultibandImage.name
			);
			toast.warn("Retrying failed");
			return false;
		} else if (flightMultibandImage.showRetryButton === true && flightMultibandImage.name !== multibandFileData.name) {
			setValidationErrorState(true);
			setValidationErrorMessage(
				"To retry an uploading ortho, you must Browse the same file: " + flightMultibandImage.name
			);
			toast.warn("Retrying failed");
			return false;
		} else {
			setValidationErrorState(false);
			setValidationErrorMessage("");

			return true;
		}
	}

	function handleDeleteModalOpen() {
		setDeleteModalOpen(!deleteModalOpen);
	}

	async function deleteFlightMultibandImage() {
		setIsDeleting(true);
		const accessToken = await getTokenSilently();
		let updatedFlightMultibandImage = _.cloneDeep(flightMultibandImage);
		updatedFlightMultibandImage.isDeleting = true;
		dispatch(orthoActions.updateUploadingMultibandOrtho(updatedFlightMultibandImage));
		dispatch(
			orthoActions.deleteFlightMultibandImage(flightMultibandImage.flightMultibandImageId, clientId, accessToken)
		)
			.then(() => {
				toast.success("Flight Multispectral Image deleted successfully!");
				setIsDeleting(false);
				setFlightMultibandImage(null);
				handleDeleteModalOpen();
				setSelectedOrtho(null);
				setSelectedOrthoName("");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to delete Flight Multispectral Image.");
				setIsDeleting(false);
			});
	}

	async function downloadOrtho() {
		setIsDownloading(true);
		const accessToken = await getTokenSilently();
		dispatch(
			orthoActions.downloadFlightMultibandOrtho(
				flightMultibandImage.flightMultibandImageId,
				flightMultibandImage.name,
				clientId,
				accessToken
			)
		)
			.then(() => {
				setIsDownloading(false);
				toast.success("Ortho downloaded.");
			})
			.catch(() => {
				setIsDownloading(false);
				toast.error("Ortho failed to download. Please try again.");
			});
	}

	async function fileUploadHandler() {
		if (isSelectedFileLocal) {
			var image = _.cloneDeep(flightMultibandImage);
			image.isUploading = true;
			image.uploadPercent = 0;
			image.uploadingChunks = true;
			setFlightMultibandImage(image);

			const accessToken = await getTokenSilently();

			let recordToCreate = new FormData();
			recordToCreate.set("flightId", image.flightId);
			recordToCreate.set("sensorId", image.sensorId);
			recordToCreate.set("fileName", selectedOrtho.name);

			dispatch(orthoActions.addFlightMultibandOrthoUpload(image));
			dispatch(
				orthoActions.createInitialMultiBandFlightImageRecord(
					recordToCreate,
					clientId,
					selectedOrtho,
					chunkSize,
					image,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error("Image record failed to create");
			});
		} else {
			azureFileUploadHandler();
		}
	}

	async function azureFileUploadHandler() {
		var image = _.cloneDeep(flightMultibandImage);
		setFlightMultibandImage({ ...image, isUploading: true, uploadPercent: 100, uploadingChunks: false });
		dispatch(
			orthoActions.addFlightMultibandOrthoUpload({
				...image,
				isUploading: true,
				uploadPercent: 100,
				uploadingChunks: false
			})
		);
		const accessToken = await getTokenSilently();
		// null properties are not needed for the azure file copy, but are stubbed for the orthoMetadata object
		const orthoMetadata = {
			flightId: flightMultibandImage.flightId,
			tempFlightImageId: flightMultibandImage.multibandImageId,
			sensorId: flightMultibandImage.sensorId,
			sensorName: flightMultibandImage.sensorName,
			orthoImageTypeId: null,
			orthoImageTypeAbbreviation: null,
			fieldId: null,
			trialId: null,
			plotId: null,
			fileName: flightMultibandImage.name,
			clientId
		};
		const bodyData = { orthoMetadata, azureShareFileItem: selectedAzureFile };
		dispatch(orthoActions.copyAzureFileToShareMultiband(bodyData, accessToken, clientId))
			.then((res) => {
				azureUploadSuccessCallback(bodyData);
				toast.success("Azure file copied successfully.");
				setFlightMultibandImage({
					...flightMultibandImage,
					uploadedFlightImageId: res.data,
					isUploaded: true,
					isUploading: false
				});

				dispatch(orthoActions.removeMultibandOrthoUpload(flightMultibandImage.flightId));
			})
			.catch((e) => {
				console.error(e);
				if (e.statusCode === 500) {
					setFlightMultibandImage({ ...flightMultibandImage, isUploading: false });
					toast.error("Azure file copy failed. Please try again.");
					dispatch(orthoActions.removeMultibandOrthoUpload(flightMultibandImage.flightId));
				} else if (e.statusCode === 400) {
					setFlightMultibandImage({ ...flightMultibandImage, isUploading: false });
					toast.error(e.response.data.Message);
					dispatch(orthoActions.removeMultibandOrthoUpload(flightMultibandImage.flightId));
				} else {
					setFlightMultibandImage({
						...flightMultibandImage,
						isUploading: true,
						uploadPercent: 100,
						showTooltip: true
					});
				}
			});
	}

	async function retryUpload() {
		const isValid = isFlightMultibandImageValid(selectedOrtho);
		if (!isValid) {
			return;
		}
		var image = _.cloneDeep(flightMultibandImage);
		image.isUploading = true;
		image.showRetryButton = false;

		const accessToken = await getTokenSilently();

		let recordToCreate = new FormData();
		recordToCreate.set("flightId", image.flightId);
		recordToCreate.set("sensorId", image.sensorId);
		recordToCreate.set("fileName", selectedOrtho.name);
		dispatch(orthoActions.addFlightMultibandOrthoUpload(image));
		if (image.uploadingChunks === true) {
			dispatch(
				orthoActions.retryMultibandImageUploadChunks(
					recordToCreate,
					clientId,
					selectedOrtho,
					chunkSize,
					image,
					flightMultibandImage?.flightMultibandImageId,
					flightMultibandImage.numberOfChunksUploaded,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error(`Failed to upload ${selectedOrtho.name}`);
			});
		}
	}

	return (
		<>
			<Form error={validationErrorState}>
				<Message error content={validationErrorMessage} />
				<Form.Group>
					{isDownloading ? (
						<Loader active inline />
					) : (
						<Form.Button
							icon="download"
							disabled={
								!flightMultibandImage?.isUploaded ||
								isDeleting ||
								!userAuth.hasApplicationArea(ApplicationArea.FlightDownloadOrtho, clientId)
							}
							onClick={downloadOrtho}
						/>
					)}
					{isDeleting ? (
						<Loader active inline />
					) : (
						<Modal
							open={deleteModalOpen}
							trigger={
								<Form.Button onClick={handleDeleteModalOpen} icon="trash" disabled={isDownloading}></Form.Button>
							}
						>
							<Modal.Header>Delete Uploaded Flight Multispectral Ortho</Modal.Header>
							<Modal.Content>
								<Modal.Description>
									<p>Are you sure you want to delete {selectedOrthoName}?</p>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button floated="right" negative onClick={deleteFlightMultibandImage}>
									Delete
								</Button>
								<Button floated="right" onClick={handleDeleteModalOpen}>
									Cancel
								</Button>
								<br style={{ clear: "both" }} />
							</Modal.Actions>
						</Modal>
					)}
					{flightMultibandImage?.showRetryButton ||
					(!flightMultibandImage?.isUploaded && !flightMultibandImage?.isUploading) ? (
						<Form.Button
							content="Browse Local..."
							labelPosition="left"
							icon="file"
							onClick={() => handleFileBrowse()}
						/>
					) : null}

					{flightMultibandImage?.showRetryButton ||
					(!flightMultibandImage?.isUploaded && !flightMultibandImage?.isUploading && userAuth.isApAdmin) ? (
						<Form.Button icon="cloud" onClick={() => azureBrowseCallback(componentId)} />
					) : null}

					<input
						id="multibandImageUpload"
						ref={fileInputRef}
						type="file"
						accept=".tif"
						hidden
						onChange={fileSelectedHandler}
					/>
					<Form.Input type="text" width="5" readOnly value={selectedOrthoName}></Form.Input>
					{flightMultibandImage?.isUploaded ||
					flightMultibandImage?.isUploading ||
					flightMultibandImage?.showRetryButton ? null : (
						<Form.Button primary type="submit" onClick={fileUploadHandler}>
							Upload
						</Form.Button>
					)}
					{flightMultibandImage?.isUploading ? (
						flightMultibandImage?.uploadPercent < 100 ? (
							<div className={styles.centerText}>
								{flightMultibandImage.retryingUpload === true ? (
									<p>
										{flightMultibandImage.uploadPercent} % - Retrying upload in {flightMultibandImage.retryInSeconds}{" "}
										seconds. Attempt: {flightMultibandImage.retryAttempt} out of{" "}
										{flightMultibandImage.maxNumberRetryAttempts}
									</p>
								) : (
									<p>{flightMultibandImage.uploadPercent} %</p>
								)}
							</div>
						) : (
							<>
								<Loader active inline>
									{uploadStatus ?? flightMultibandImage?.uploadStatus}
								</Loader>
								{flightMultibandImage?.showTooltip ? (
									<Popup
										content="Image is processing on the server. To check if the image has finished processing, reload the page by pressing F5."
										trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
									/>
								) : null}
							</>
						)
					) : null}
					{flightMultibandImage?.showRetryButton && (
						<Form.Button color="red" content="Retry Upload" onClick={retryUpload} />
					)}
				</Form.Group>
			</Form>
		</>
	);
};

UploadMultibandImage.propTypes = {
	multibandImage: PropTypes.object,
	sensorId: PropTypes.string,
	clientId: PropTypes.string,
	azureBrowseCallback: PropTypes.func,
	azureUploadSuccessCallback: PropTypes.func,
	selectedAzureFile: PropTypes.object,
	componentId: PropTypes.number
};

export default UploadMultibandImage;
