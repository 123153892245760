import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import OrthoDropdownComponent from "./orthoDropdownComponent";

const OrthoDropdownParentComponent = React.forwardRef(
	(
		{
			level,
			edit,
			viewer,
			setCenterCoordinate,
			setOrthoImageTypeId,
			heatmapTimelineRef,
			setOrthoTypes,
			buildMapImage,
			movePlot,
			handleFieldDropdownChange,
			removeFieldGrid,
			jumpToCoords,
			redrawPlots,
			imageFound
		},
		parentRef
	) => {
		const [showDropdowns, setShowDropdowns] = useState(false);
		let dropdownRef = React.useRef(null);

		OrthoDropdownParentComponent.displayName = "OrthoDropdownParentComponent";

		useImperativeHandle(parentRef, () => {
			return {
				showDropdowns: toggleDropdowns,
				innerRef: dropdownRef
			};
		});

		function toggleDropdowns() {
			setShowDropdowns(true);
		}

		return (
			<>
				{showDropdowns && (
					<OrthoDropdownComponent
						ref={dropdownRef}
						level={level}
						edit={edit}
						viewer={viewer}
						setCenterCoordinate={(coord) => setCenterCoordinate(coord)}
						setOrthoIdToPass={(id) => setOrthoImageTypeId(id)}
						heatmapTimelineRef={heatmapTimelineRef}
						setOrthoTypes={(orthoTypes) => setOrthoTypes(orthoTypes)}
						buildMapImage={(res, orthoId) => buildMapImage(res, orthoId)}
						movePlot={(key, e) => movePlot(key, e)}
						handleFieldDropdownChange={(option) => handleFieldDropdownChange(option)}
						removeFieldGrid={(fieldId) => removeFieldGrid(fieldId)}
						jumpToCoords={(coords) => jumpToCoords(coords)}
						redrawPlots={(flightId, fieldId, trialId, sensorChanged, res, orthoId, first) =>
							redrawPlots(flightId, fieldId, trialId, sensorChanged, res, orthoId, first)
						}
						imageFound={(truth) => imageFound(truth)}
					/>
				)}
			</>
		);
	}
);

OrthoDropdownParentComponent.propTypes = {
	level: PropTypes.string.isRequired,
	edit: PropTypes.bool.isRequired,
	viewer: PropTypes.bool.isRequired,
	setCenterCoordinate: PropTypes.func.isRequired,
	buildMapImage: PropTypes.func.isRequired,
	movePlot: PropTypes.func.isRequired,
	handleFieldDropdownChange: PropTypes.func.isRequired,
	setOrthoImageTypeId: PropTypes.func.isRequired,
	heatmapTimelineRef: PropTypes.object,
	removeFieldGrid: PropTypes.func.isRequired,
	setOrthoTypes: PropTypes.func.isRequired,
	jumpToCoords: PropTypes.func.isRequired,
	redrawPlots: PropTypes.func.isRequired,
	imageFound: PropTypes.func.isRequired
};

export default OrthoDropdownParentComponent;
