import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Segment, Table, Dropdown, Button, Icon, Form, Checkbox } from "semantic-ui-react";
import _ from "lodash";
import { produce } from "immer";

const FieldAdvancedAnalyticsResults = ({
	quantifiedRegionTypeOptions,
	quantifiedRegionGeometryOptions,
	fieldQuantifiedRegions,
	quantifiedRegionsToDelete,
	setQuantifiedRegionsToDelete,
	setQuantifiedRegionsToCreate,
	setQuantifiedRegionsToUpdate,
	field,
	saveAttempted
}) => {
	const [quantifiedRegions, setQuantifiedRegions] = useState(fieldQuantifiedRegions);

	useEffect(() => {
		if (fieldQuantifiedRegions) {
			setQuantifiedRegions(fieldQuantifiedRegions);
		}
	}, [fieldQuantifiedRegions]);

	useEffect(() => {
		let createdQuantifiedRegions = [];
		let updatedQuantifiedRegions = [];
		let quantifiedRegionsClone = _.cloneDeep(quantifiedRegions);
		_.map(quantifiedRegionsClone, (qr) => {
			const qrName = _.find(quantifiedRegionTypeOptions, (o) => {
				return o.value === qr.quantifiedRegionTypeId;
			});
			qr.quantifiedRegionTypeName = qrName?.text;
			if (qr.id === null) {
				createdQuantifiedRegions.push(qr);
			} else if (qr.isDirty) {
				updatedQuantifiedRegions.push(qr);
			}
		});
		setQuantifiedRegionsToCreate(createdQuantifiedRegions);
		setQuantifiedRegionsToUpdate(updatedQuantifiedRegions);
	}, [quantifiedRegions]);

	function addQuantifiedRegion() {
		let width = Math.round(field.columnWidth * 0.5 * 10000) / 10000;
		let length = Math.round(field.rangeLength * 0.75 * 10000) / 10000;

		let newQuantifiedRegion = {
			id: null,
			quantifiedRegionTypeId:
				quantifiedRegions?.length === 0
					? _.find(quantifiedRegionTypeOptions, { text: "Plot-Centered with Subsamples" })?.key
					: undefined,
			quantifiedRegionTypeName: quantifiedRegions?.length === 0 ? "Plot-Centered with Subsamples" : "",
			width: width,
			length: length,
			columns: 2,
			ranges: 10,
			quantifiedRegionGeometryId: _.find(quantifiedRegionGeometryOptions, { text: "Rectangle" })?.key,
			quantifiedWidth: Math.round((width / 2) * 10000) / 10000,
			quantifiedWidthManuallyUpdated: false,
			quantifiedLength: Math.round((length / 10) * 10000) / 10000,
			quantifiedLengthManuallyUpdated: false,
			error: false,
			isDefault: quantifiedRegions?.length === 0
		};
		let updatedQuantifiedRegions = [...quantifiedRegions, newQuantifiedRegion];
		setQuantifiedRegions(updatedQuantifiedRegions);
	}

	function removeQuantifiedRegion(index) {
		let updatedQuantifiedRegions = [...quantifiedRegions];
		let selectedQuantifiedRegion = quantifiedRegions[index];
		if (index !== -1) {
			if (selectedQuantifiedRegion.id !== null) {
				let dQuantifiedRegions = quantifiedRegionsToDelete;
				dQuantifiedRegions.push(selectedQuantifiedRegion.id);
				setQuantifiedRegionsToDelete(dQuantifiedRegions);
			}
			updatedQuantifiedRegions.splice(index, 1);
			setQuantifiedRegions(updatedQuantifiedRegions);
		}
	}

	function isUnique(arr) {
		var tmpArr = [];
		for (var obj in arr) {
			const isDuplicate = _.some(tmpArr, (t) => {
				return (
					t.quantifiedRegionTypeId === arr[obj].quantifiedRegionTypeId &&
					t.quantifiedRegionGeometryId === arr[obj].quantifiedRegionGeometryId
				);
			});
			if (!isDuplicate) {
				tmpArr.push({
					quantifiedRegionTypeId: arr[obj].quantifiedRegionTypeId,
					quantifiedRegionGeometryId: arr[obj].quantifiedRegionGeometryId
				});
			} else {
				return false; // Duplicate value for property1 found
			}
		}
		return true; // No duplicate values found for property1
	}

	function getMaxNumberOfUniqueQuantifiedRegionTypes() {
		const max = quantifiedRegionTypeOptions?.length * quantifiedRegionGeometryOptions?.length;
		const currentNumberOfQuantifiedRegions = quantifiedRegions.length;

		return currentNumberOfQuantifiedRegions < max;
	}

	function canQuantifiedRegionHaveSubsamples(quantifiedRegionId) {
		const isPlotCentered =
			_.find(quantifiedRegionTypeOptions, (qr) => {
				return qr.value === quantifiedRegionId;
			})?.text === "Plot-Centered";

		return isPlotCentered;
	}

	function calculateSubsampleWidth(width, columns) {
		if (width && columns) {
			return Math.round(Number(width / columns) * 10000) / 10000;
		}

		return null;
	}

	function calculateSubsampleLength(length, ranges) {
		if (length && ranges) {
			return Math.round(Number(length / ranges) * 10000) / 10000;
		}

		return null;
	}

	function isFullRowQuantifiedRegion(quantifiedRegionTypeId) {
		const qrName = _.find(quantifiedRegionTypeOptions, (q) => {
			return q.value === quantifiedRegionTypeId;
		})?.text;
		if (qrName === "Full Row") {
			return true;
		}

		return false;
	}

	function isStandCountQuantifiedRegion(qrTypeId) {
		return _.find(quantifiedRegionTypeOptions, { key: qrTypeId })?.text === "Stand Count";
	}

	return (
		<Segment basic>
			<Form>
				<Table celled striped selectable color="blue" textAlign="center">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell></Table.HeaderCell>
							<Table.HeaderCell>Position</Table.HeaderCell>
							<Table.HeaderCell>Width (ft)</Table.HeaderCell>
							<Table.HeaderCell>Length (ft)</Table.HeaderCell>
							<Table.HeaderCell>Sub-Columns</Table.HeaderCell>
							<Table.HeaderCell>Sub-Ranges</Table.HeaderCell>
							<Table.HeaderCell>Subsample Geometry</Table.HeaderCell>
							<Table.HeaderCell>Subsample Quantified Width (ft)</Table.HeaderCell>
							<Table.HeaderCell>Subsample Quantified Length (ft)</Table.HeaderCell>
							<Table.HeaderCell>Default for Viewing</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{_.map(quantifiedRegions, (fqr, index) => {
							const canQRHaveSubsamples = canQuantifiedRegionHaveSubsamples(fqr.quantifiedRegionTypeId);
							return (
								<Table.Row key={index}>
									<Table.Cell>
										<Button circular icon color="black" onClick={() => removeQuantifiedRegion(index)}>
											<Icon name="minus" corner="bottom right" />
										</Button>
									</Table.Cell>
									<Table.Cell>
										{
											<Dropdown
												options={_.filter(quantifiedRegionTypeOptions, function (qr) {
													let quantifiedRegionExists = quantifiedRegions.find(
														(x) => x.quantifiedRegionTypeId === qr.value
													);

													return qr.value === fqr.quantifiedRegionTypeId || !quantifiedRegionExists;
												})}
												disabled={fqr.hasAnalysisResults}
												value={fqr.quantifiedRegionTypeId}
												error={saveAttempted === true && !fqr.quantifiedRegionTypeId}
												onChange={(event, { value }) => {
													const isQRPlotCentered = canQuantifiedRegionHaveSubsamples(value);
													const isQRStandCount = isStandCountQuantifiedRegion(value);
													const isQRFullRow = isFullRowQuantifiedRegion(value);

													const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
														const qr = quantifiedRegions[index];
														const quantifiedWidth = calculateSubsampleLength(
															qr.width,
															isQRPlotCentered ? 1 : qr.columns
														);
														const quantifiedLength = calculateSubsampleWidth(
															isQRFullRow || isQRStandCount ? field.rangeLength : qr.length,
															isQRPlotCentered || isQRStandCount ? 1 : qr.ranges
														);
														data[index] = {
															...qr,
															quantifiedRegionTypeId: value,
															length:
																isFullRowQuantifiedRegion(value) || isStandCountQuantifiedRegion(value)
																	? field.rangeLength
																	: qr.length,
															columns: isQRPlotCentered ? 1 : qr.columns,
															ranges: isQRPlotCentered || isQRStandCount ? 1 : qr.ranges,
															quantifiedLength: quantifiedLength ?? qr.quantifiedLength,
															quantifiedWidth: quantifiedWidth ?? qr.quantifiedWidth,
															isDirty: true
														};
													});
													setQuantifiedRegions(quantifiedRegionsClone);
												}}
											/>
										}
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-width"
											value={fqr.width}
											disabled={fqr.hasAnalysisResults}
											error={
												saveAttempted === true && (!Number(fqr.width) || Number(fqr.width) > Number(field.columnWidth))
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													const qr = quantifiedRegions[index];
													const quantifiedWidth = calculateSubsampleLength(value, qr.columns);
													data[index] = {
														...qr,
														width: value,
														quantifiedWidth: quantifiedWidth ? quantifiedWidth : qr.quantifiedWidth,
														isDirty: true
													};
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-length"
											value={fqr.length}
											disabled={fqr.hasAnalysisResults || isFullRowQuantifiedRegion(fqr.quantifiedRegionTypeId)}
											error={
												saveAttempted === true &&
												(!Number(fqr.length) || Number(fqr.length) > Number(field.rangeLength))
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													const qr = quantifiedRegions[index];
													const quantifiedLength = calculateSubsampleWidth(value, qr.ranges);
													data[index] = {
														...qr,
														length: value,
														quantifiedLength: quantifiedLength ? quantifiedLength : qr.quantifiedLength,
														isDirty: true
													};
												});

												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-columns"
											value={fqr.columns}
											disabled={canQRHaveSubsamples || fqr.hasAnalysisResults}
											error={
												saveAttempted === true &&
												(!Number(fqr.columns) ||
													(canQRHaveSubsamples && fqr.columns !== 1) ||
													Number(fqr.ranges) * Number(fqr.columns) > 100)
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													const qr = quantifiedRegions[index];
													const quantifiedWidth = calculateSubsampleLength(qr.width, value);
													data[index] = {
														...qr,
														columns: value,
														quantifiedWidth: quantifiedWidth ? quantifiedWidth : qr.quantifiedWidth,
														isDirty: true
													};
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-ranges"
											value={fqr.ranges}
											disabled={
												canQRHaveSubsamples ||
												fqr.hasAnalysisResults ||
												isStandCountQuantifiedRegion(fqr.quantifiedRegionTypeId)
											}
											error={
												saveAttempted === true &&
												(!Number(fqr.ranges) ||
													(canQRHaveSubsamples && fqr.ranges !== 1) ||
													Number(fqr.ranges) * Number(fqr.columns) > 100)
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													const qr = quantifiedRegions[index];
													const quantifiedLength = calculateSubsampleWidth(qr.length, value);
													data[index] = {
														...qr,
														ranges: value,
														quantifiedLength: quantifiedLength ? quantifiedLength : qr.quantifiedLength,
														isDirty: true
													};
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										{
											<Dropdown
												options={quantifiedRegionGeometryOptions}
												value={fqr.quantifiedRegionGeometryId}
												disabled={fqr.hasAnalysisResults}
												error={saveAttempted === true && !fqr.quantifiedRegionGeometryId}
												onChange={(event, { value }) => {
													const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
														data[index] = {
															...quantifiedRegions[index],
															quantifiedRegionGeometryId: value,
															isDirty: true
														};
													});
													setQuantifiedRegions(quantifiedRegionsClone);
												}}
											/>
										}
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-quantified-width"
											value={fqr.quantifiedWidth}
											disabled={fqr.hasAnalysisResults}
											error={
												saveAttempted === true &&
												(!Number(fqr.quantifiedWidth) ||
													Number(fqr.quantifiedWidth) > Number(fqr.width) / Number(fqr.columns))
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													data[index] = { ...quantifiedRegions[index], quantifiedWidth: value, isDirty: true };
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										<Form.Input
											id="input-quantified-length"
											value={fqr.quantifiedLength}
											disabled={fqr.hasAnalysisResults}
											error={
												saveAttempted === true &&
												(!Number(fqr.quantifiedLength) ||
													Number(fqr.quantifiedLength) > Number(fqr.length) / Number(fqr.ranges))
											}
											onChange={(e, { value }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													data[index] = { ...quantifiedRegions[index], quantifiedLength: value, isDirty: true };
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
									<Table.Cell>
										<Checkbox
											checked={fqr.isDefault}
											onClick={(e, { checked }) => {
												const quantifiedRegionsClone = produce(quantifiedRegions, (data) => {
													// eslint-disable-next-line
													data = _.map(data, (d, idx) => {
														let isUpdated = false;
														const isCurrentIndex = idx === index;
														if (isCurrentIndex) {
															isUpdated = true;
														}

														//-- Check if a record is getting set to default === true and if the current recrd isDefault === true and it is NOT the record that is getting updated
														if (checked === true && d.isDefault === true && !isCurrentIndex) {
															isUpdated = true;
														}

														d.isDirty = isUpdated;
														d.isDefault = index === idx ? checked : false;
														console.log(d);
														return d;
													});
												});
												setQuantifiedRegions(quantifiedRegionsClone);
											}}
										/>
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Form>
			<br />
			{isUnique(quantifiedRegions) && getMaxNumberOfUniqueQuantifiedRegionTypes() ? (
				<Button primary onClick={addQuantifiedRegion}>
					+ Add Quantified Region
				</Button>
			) : null}
		</Segment>
	);
};

FieldAdvancedAnalyticsResults.propTypes = {
	quantifiedRegionTypeOptions: PropTypes.array.isRequired,
	quantifiedRegionGeometryOptions: PropTypes.array.isRequired,
	fieldQuantifiedRegions: PropTypes.array,
	quantifiedRegionsToDelete: PropTypes.array,
	setQuantifiedRegionsToDelete: PropTypes.func,
	setQuantifiedRegionsToCreate: PropTypes.func,
	setQuantifiedRegionsToUpdate: PropTypes.func,
	field: PropTypes.object.isRequired,
	saveAttempted: PropTypes.bool
};

export default FieldAdvancedAnalyticsResults;
