import React from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { Grid, Form, TextArea } from "semantic-ui-react";

const TrialInfoForTrialSponsor = ({ workingTrial, trialOwnerEmailAddress, trialSponsorEmailAddress }) => {
	const DATE_FORMAT = "M/D/YYYY";
	return (
		<Form>
			<Grid verticalAlign="middle">
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<Form.Field>
								<label htmlFor="form-input-trial-name">Trial Name</label>
							</Form.Field>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-trial-name" defaultValue={workingTrial.name} readOnly />
					</Grid.Column>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-input-field-researcher">Field Researcher</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-field-researcher" value={workingTrial.fieldResearcher ?? ""} readOnly />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-input-trial-owner-client">Trial Owner Org</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input
							id="form-input-trial-owner-client"
							value={`${
								workingTrial.trialOwnerClientName && workingTrial.trialOwnerClientName !== null
									? workingTrial.trialOwnerClientName
									: "N/A"
							}`}
							readOnly
						/>
					</Grid.Column>

					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-input-location">Location</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-location" value={workingTrial.location ?? ""} readOnly />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-select-trial-owner">Trial Owner</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-trial-owner" value={`${workingTrial.trialOwner}` ?? ""} readOnly />
						{trialOwnerEmailAddress && <a href={`mailto: ${trialOwnerEmailAddress}`}>Send email to Trial Owner</a>}
					</Grid.Column>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-input-gps-coordinates" style={{ margin: "unset" }}>
								GPS Coordinates
							</label>
							<label style={{ margin: "unset" }}>(Plot 101)</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-gps-coordinates" value={workingTrial.gpsCoordinates ?? ""} readOnly />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-input-trial-sponsor-client">Trial Sponsor Org</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input
							id="form-input-trial-sponsor-client"
							value={`${
								workingTrial.trialSponsorClientName && workingTrial.trialSponsorClientName !== null
									? workingTrial.trialSponsorClientName
									: "N/A"
							}`}
							readOnly
						/>
					</Grid.Column>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-dateinput-plant-date">Plant Date</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input
							id="form-input-plant-date"
							value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
							readOnly
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-select-trial-owner">Trial Sponsor</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input id="form-input-trial-owner" value={`${workingTrial.trialSponsor}` ?? ""} readOnly />
						{trialSponsorEmailAddress && (
							<a href={`mailto: ${trialSponsorEmailAddress}`}>Send email to Trial Sponsor</a>
						)}
					</Grid.Column>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="5">
						<Form.Input
							id="form-input-harvest-date"
							value={workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""}
							readOnly
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Column width="2" textAlign="right">
					<Form.Field>
						<label htmlFor="form-input-treatments">Treatment Count</label>
					</Form.Field>
				</Grid.Column>
				<Grid.Column width="1" textAlign="right">
					<Form.Input id="form-input-treatments" value={workingTrial.treatments.length ?? ""} readOnly />
				</Grid.Column>
				<Grid.Column width="4" />{" "}
				<Grid.Column width="2" textAlign="right">
					<Form.Field>
						<label htmlFor="form-input-reps">Rep Count</label>
					</Form.Field>
				</Grid.Column>
				<Grid.Column width="1" textAlign="right">
					<Form.Input id="form-input-reps" value={workingTrial.repCount ?? ""} readOnly />
				</Grid.Column>
				<Grid.Row>
					<Grid.Column width="2" textAlign="right">
						<Form.Field>
							<label htmlFor="form-text-area-notes">Notes</label>
						</Form.Field>
					</Grid.Column>
					<Grid.Column width="12">
						<TextArea
							id="form-text-area-notes"
							defaultValue={workingTrial.notes}
							rows="5"
							style={{ opacity: 0.5 }}
							readOnly
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form>
	);
};

TrialInfoForTrialSponsor.propTypes = {
	workingTrial: PropTypes.object.isRequired,
	isTrialOwnerOrTrialSponsor: PropTypes.bool.isRequired,
	trialOwnerEmailAddress: PropTypes.string.isRequired,
	trialSponsorEmailAddress: PropTypes.string.isRequired,
	isTrialOwner: PropTypes.bool.isRequired
};

export default TrialInfoForTrialSponsor;
