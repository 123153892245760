import * as types from "./actionTypes";

/*
 *  Action Creators
 *
 */
export function setCurrentFlightIdSuccess(flightId) {
	return { type: types.OV_SET_CURRENT_FLIGHT_ID, flightId };
}

/*
 *  Thunks
 *
 */
export function setCurrentFlightId(flightId) {
	return function (dispatch) {
		dispatch(setCurrentFlightIdSuccess(flightId));
	};
}
