import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import { Segment, Step, IconGroup, Icon, Message, Loader } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import NewEditFlight from "../NewEditFlight";
import OrthoUpload from "../OrthoUpload";
import OrthoAlignment from "../OrthoAlignment";
import AddFieldsToFlight from "../AddFieldsToFlight";
import FlightAnalysis from "../FlightAnalysis";

import * as flightImageActions from "../../../../redux/actions/flightImageActions";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

//-- "info": NewEditFlight
//-- "orthoUpload": OrthoUpload
//-- "alignFields": OrthoAlignment
//-- "addNewFields": AddFieldsToFlight
//-- "plotAnalysis": FlightAnalysis

const FlightProcess = ({ activeStep, mode }) => {
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	const [step] = useState(activeStep);
	const [flightUploadedMessage, setFlightUploadedMessage] = useState("");
	const [checkImageUploadComplete, setCheckImageUploadComplete] = useState(false);

	useEffect(() => {
		if (step === "alignFields" || step === "addNewFields" || step === "plotAnalysis") {
			areFlightImagesUploaded();
		} else {
			setCheckImageUploadComplete(true);
		}
	}, []);

	async function areFlightImagesUploaded() {
		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			if ((step === "alignFields" || step === "addNewFields") && !res.rasterUpload) {
				setFlightUploadedMessage(
					"The orthos for this flight have not yet finished processing. Please try again in a few minutes."
				);
			} else if (step === "plotAnalysis" && !res.azureUpload) {
				setFlightUploadedMessage(
					"The orthos for this flight have not yet finished processing. Please wait while they finish before analyzing the flight."
				);
			}

			setCheckImageUploadComplete(true);
		});
	}

	function removeUploadStatusMessage() {
		setFlightUploadedMessage("");
	}

	return (
		<Segment basic id="flight-process-steps" style={{ marginLeft: 50 }}>
			<Step.Group widths={5}>
				<Step
					link
					active={step === "info"}
					disabled={!userAuth.hasApplicationArea(ApplicationArea.FlightInfo, clientId)}
					onClick={() => history.push(moduleNavigation.createFlightLink(true, null, "info"))}
				>
					<Icon name="paper plane" />
					<Step.Content>
						<Step.Title>Flight Info</Step.Title>
						<Step.Description>High-level flight information</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "orthoUpload"}
					disabled={!moduleNavigation.flightId || !userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, clientId)}
					onClick={() => history.push(moduleNavigation.createFlightLink(true, null, "ortho-upload"))}
				>
					<Icon name="cloud upload" />
					<Step.Content>
						<Step.Title>Ortho Upload</Step.Title>
						<Step.Description>Upload orthomosaic aerial images</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "alignFields"}
					disabled={!moduleNavigation.flightId || !userAuth.hasApplicationArea(ApplicationArea.FlightAlignFields, clientId)}
					onClick={() => history.push(moduleNavigation.createFlightLink(true, null, "align-fields"))}
				>
					<Icon name="object ungroup outline" />
					<Step.Content>
						<Step.Title>Align Fields</Step.Title>
						<Step.Description>Make sure known fields are placed properly on the ortho</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "addNewFields"}
					disabled={!moduleNavigation.flightId || !userAuth.hasApplicationArea(ApplicationArea.FlightAddNewFields, clientId)}
					onClick={() => history.push(moduleNavigation.createFlightLink(true, null, "add-new-fields"))}
				>
					<IconGroup style={{ marginRight: 10 }}>
						<Icon name="object ungroup outline" corner="top left" style={{ fontSize: 30, textShadow: "unset" }} />
						<Icon name="plus" corner="bottom right" style={{ fontSize: 15 }} />
					</IconGroup>
					<Step.Content>
						<Step.Title>Add New Fields</Step.Title>
						<Step.Description>Identify additional fields in the ortho</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "plotAnalysis"}
					disabled={!moduleNavigation.flightId || (!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysis, clientId) 
										&& !userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId))}
					onClick={() => history.push(moduleNavigation.createFlightLink(true, null, "plot-analysis"))}
				>
					<Icon name="chart line" />
					<Step.Content>
						<Step.Title>Plot Analysis</Step.Title>
						<Step.Description>Analyze plots and view results</Step.Description>
					</Step.Content>
				</Step>
			</Step.Group>
			<>
				{flightUploadedMessage ? (
					<Message warning content={flightUploadedMessage} style={{ marginTop: "unset" }} />
				) : null}
				<Segment id="flight-process-step-content" style={{ marginTop: "unset", paddingBottom: "unset", minHeight: 70 }}>
					{(() => {
						switch (step) {
							default:
							case "info":
								return <NewEditFlight mode={moduleNavigation.flightId && mode !== "new" ? "edit" : "new"} />;
							case "orthoUpload":
								return <OrthoUpload flightName={entityNames?.flightName} />;
							case "alignFields":
								return checkImageUploadComplete && entityNames?.flightId === moduleNavigation.flightId ? (
									<OrthoAlignment flightName={entityNames?.flightName} />
								) : (
									<Loader active>Verifying Upload Status</Loader>
								);
							case "addNewFields":
								return checkImageUploadComplete && entityNames?.flightId === moduleNavigation.flightId ? (
									<AddFieldsToFlight flightName={entityNames?.flightName} />
								) : (
									<Loader active>Verifying Upload Status</Loader>
								);
							case "plotAnalysis":
								return (
									<FlightAnalysis
										flightName={entityNames?.flightName}
										removeUploadStatusMessage={removeUploadStatusMessage}
									/>
								);
						}
					})()}
				</Segment>
			</>
		</Segment>
	);
};

FlightProcess.propTypes = {
	activeStep: PropTypes.string.isRequired,
	mode: PropTypes.string.isRequired
};

export default FlightProcess;
