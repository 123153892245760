import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { produce } from "immer";
import * as tableShader from "../../../Lumber/TableShader";
import Tooltip from "rc-tooltip";

import { Segment, Table, Checkbox, Grid, Loader, Icon } from "semantic-ui-react";
import "./styles.css";

const AreaUnderTheCurveTable = ({
	data,
	aucData,
	updateFlightAndTreatmentSelections,
	groupedTrialData,
	setGroupedTrialData,
	groundTrialDataChecked,
	flights,
	setFlights,
	flightDataChecked,
	footerData,
	flightsInCurveModel,
	runMeanComparison,
	gduByDay,
	loadingStats = false,
	setNeedToUpdateAnalytics,
	meanComparisonDataAuc,
	needToUpdateAnalytics,
	initialGroupTrialDataCheckedCount,
	hideLetters,
	setHideLetters,
	initialFlightDataCheckedCount,
	hideJustAUCLetters,
	setHideJustAUCLetters,
	needToRerunStats,
	allMeanComparisonData,
	uniqueAnalysesCount,
	isForAnalysisTimecoursePage = false
}) => {
	const DATE_FORMAT = "M/D/YYYY";
	const weatherDataEnabled = true;

	//-- Data
	const [toggleAll, setToggleAll] = useState(true);

	function isFlightChecked(flight) {
		const flightIndex = _.findIndex(
			flightDataChecked,
			(f) =>
				f.flightId === flight.flightId ||
				(f.isPlantFlight && flight.isPlantFlight) ||
				(f.isHarvestFlight && flight.isHarvestFlight)
		);
		let checked = null;
		if (flightsInCurveModel?.length > 0) {
			checked = _.some(flightsInCurveModel, (fdcm) => {
				return (
					fdcm.flightId === flight.flightId ||
					(fdcm.harvestFlight && flight.isHarvestFlight) ||
					(fdcm.plantFlight && flight.isPlantFlight)
				);
			});
		}
		checked = checked !== null ? checked : flightIndex !== -1 ? flightDataChecked[flightIndex].checked : true;

		return checked;
	}

	useEffect(() => {
		let uniqueFlights = _.uniqBy(data, "flightId").map(function (flight) {
			return {
				flightId: flight.flightId,
				flightDate: flight.flightDate,
				analysisName: flight.analysisName,
				checked: flight.uncheckFlight ?? isFlightChecked(flight),
				isHarvestFlight: flight.isHarvestFlight,
				isPlantFlight: flight.isPlantFlight,
				unbalanced: flight.unbalanced,
				flightName: flight.flightName
			};
		});

		setFlights(uniqueFlights);

		//Filter out unchecked treatments to update shading
		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});
		const filteredUncheckedTreatments = _.filter(data, (d) => {
			return !_.includes(treatmentIds, d.trialTreatmentId);
		});

		//Adding a flag check here so that the coloring doesn't change when treatments are unchecked and assessments are changed. Coloring should only change after hitting update analytics
		const flightGroupedData = !hideLetters
			? _.groupBy(filteredUncheckedTreatments, "flightId")
			: _.groupBy(data, "flightId");

		tableShader.calculateShadingForGroupedTreatment(
			flightGroupedData,
			"trialAnalysisResultAverageValue",
			"99, 184, 115"
		);

		let groupedData = _.groupBy(data, "trialTreatmentId");
		let maxNumberOfObjects = _.max(Object.values(groupedData).map((a) => a.length));
		let groupedObjects = [];
		let uniqueFlightIds = _.map(uniqueFlights, (uf) => {
			return uf.flightId;
		});

		_.map(groupedData, (treatments) => {
			// -- Adding fake treatments for flight dates where the treatment doesnt exist
			// -- This is needed to make sure there are enough cells on the auc table
			let treatmentsClone = _.cloneDeep(treatments);

			_.map(treatmentsClone, (tc) => {
				//--If the checked prop doesn't exist then create it and set it, if it does exist then just set it
				if (tc.checked === undefined) {
					return (tc["checked"] =
						groundTrialDataChecked.length > 0
							? groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatments[0].trialTreatmentId)]
							: true);
				} else {
					return (tc.checked =
						groundTrialDataChecked.length > 0
							? groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatments[0].trialTreatmentId)]
							: true);
				}
			});

			if (treatments.length < maxNumberOfObjects) {
				let uniqueTreatmentFlightIds = _.map(treatments, (tr) => {
					return tr.flightId;
				});
				const missingFlights = _.filter(uniqueFlightIds, (ufi) => {
					return !_.includes(uniqueTreatmentFlightIds, ufi);
				});

				_.map(missingFlights, (flight) => {
					let fakeTreatment = _.cloneDeep(treatments[0]);
					fakeTreatment.checked =
						groundTrialDataChecked.length > 0
							? groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatments[0].trialTreatmentId)]
							: false;
					fakeTreatment.color = "rgba(211,211,211)";
					fakeTreatment.trialAnalysisResultAverageValue = 0;
					fakeTreatment.flightId = flight;
					fakeTreatment.flightDate = uniqueFlights.find((x) => x.flightId === flight).flightDate;
					fakeTreatment.flightName = uniqueFlights.find((x) => x.flightId === flight).flightName;
					fakeTreatment.isFakeTreatment = true;

					treatmentsClone.push(fakeTreatment);
				});
			}

			treatmentsClone = _.orderBy(treatmentsClone, ["flightDate"]);
			let hasFakeTreatments = treatmentsClone.length !== treatments.length;
			const aucValues = _.filter(aucData, (ad) => {
				return (
					ad.trialTreatmentId ===
					(hasFakeTreatments ? treatmentsClone[0].trialTreatmentId : treatments[0].trialTreatmentId)
				);
			});

			let aucAvg = _.meanBy(aucValues, (av) => av.totalAuc);
			let aucStandardDeviation = calculateStandardDeviation(aucValues);
			let treatmentData = {
				treatments: hasFakeTreatments ? treatmentsClone : treatments,
				trialTreatmentId: treatments[0].trialTreatmentId,
				areaUnderTheCurve: aucAvg,
				standardDeviation: aucStandardDeviation,
				checked:
					groundTrialDataChecked.length > 0
						? hasFakeTreatments
							? groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatmentsClone[0].trialTreatmentId)]
							: groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatments[0].trialTreatmentId)]
						: true,
				strikethrough:
					groundTrialDataChecked.length > 0
						? hasFakeTreatments
							? !groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatmentsClone[0].trialTreatmentId)]
							: !groundTrialDataChecked[_.indexOf(Object.keys(groupedData), "" + treatments[0].trialTreatmentId)]
						: false,
				hasFakeTreatments: hasFakeTreatments
			};

			groupedObjects.push(treatmentData);
		});
		setGroupedTrialData(groupedObjects);
		updateFlightAndTreatmentSelections(uniqueFlights, groupedObjects);
	}, [data]);

	useEffect(() => {
		if (groupedTrialData && groupedTrialData.length > 0) {
			let updatedGroupedTrials = _.cloneDeep(groupedTrialData);
			_.map(flights, (flight) => {
				_.map(updatedGroupedTrials, (treatments) => {
					let treatmentForFlight = _.find(treatments.treatments, (treatment) => {
						return treatment.flightId === flight.flightId;
					});
					if (treatmentForFlight) {
						treatmentForFlight.include = flight.checked;
					}
				});
			});

			recalculateAverageAndStdev(updatedGroupedTrials);
		}
	}, [flights]);

	function recalculateAverageAndStdev(updatedGroupedTrials) {
		_.map(updatedGroupedTrials, (treatments) => {
			let filtedteredTreatments = _.filter(treatments.treatments, (treatment) => {
				return treatment.include === true;
			});

			let aucValues = _.filter(aucData, (ad) => {
				return ad.trialTreatmentId === treatments.trialTreatmentId;
			});

			if (filtedteredTreatments && filtedteredTreatments.length > 0 && aucValues && aucValues.length > 0) {
				treatments.areaUnderTheCurve = _.meanBy(aucValues, (av) => {
					return av.totalAuc;
				});
				treatments.standardDeviation = calculateStandardDeviation(aucValues);
			}
		});

		//Filter out unchecked treatments to update shading
		let treatmentIds = [];
		_.filter(groundTrialDataChecked, (item, index) => {
			if (!item) {
				treatmentIds.push(index + 1);
			}
		});
		const filteredUncheckedTreatments = _.filter(updatedGroupedTrials, (d) => {
			return !_.includes(treatmentIds, d.trialTreatmentId);
		});

		tableShader.calculateShadingForTreatment(filteredUncheckedTreatments, "areaUnderTheCurve", "90, 138, 198");

		_.map(updatedGroupedTrials, (ugt) => {
			ugt.hasFakeTreatments = _.some(
				_.filter(ugt.treatments, (t) => t.checked),
				(t) => t.isFakeTreatment
			);
		});

		if (!_.isEmpty(updatedGroupedTrials)) {
			setGroupedTrialData(updatedGroupedTrials);
		}
	}

	const calculateStandardDeviation = (treatmentData) => {
		let standardDeviation = 0;
		let average = _.meanBy(treatmentData, (t) => {
			return t.totalAuc;
		});

		standardDeviation = Math.sqrt(
			treatmentData.reduce(function (sq, treatment) {
				return sq + Math.pow(treatment.totalAuc - average, 2);
			}, 0) /
				(treatmentData.length - 1)
		);

		return standardDeviation;
	};

	function toggleCheckAll() {
		setNeedToUpdateAnalytics(true);
		let tempGroundData = _.cloneDeep(groupedTrialData);
		tempGroundData.map((trial) => {
			trial.checked = !toggleAll;
		});
		setGroupedTrialData(tempGroundData);

		let checkedTreatmentCountUpdated = _.map(tempGroundData, (trialData) => {
			return trialData.checked;
		});

		//Logic to hide the analysis average letters when flights are unchecked
		if (
			!_.isEqual(initialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
			initialGroupTrialDataCheckedCount.length !== 0
		) {
			setHideLetters(true);
		} else if (initialGroupTrialDataCheckedCount.length === 0) {
			if (_.some(tempGroundData, (ut) => !ut.checked)) {
				setHideLetters(true);
			} else {
				setHideLetters(false);
			}
		} else {
			setHideLetters(false);
		}
	}
	async function updateCheckAll() {
		const toggleValue = toggleAll;
		setToggleAll(!toggleValue);
		return;
	}

	function updateFlightChecked(index) {
		const updatedSelection = produce(flights, (draft) => {
			draft[index] = { ...flights[index], ["checked"]: !flights[index].checked };
		});

		setFlights(updatedSelection);
		setNeedToUpdateAnalytics(true);

		let checkedFlightsCountUpdated = _.map(updatedSelection, (flightData) => {
			return flightData.checked;
		});

		//Logic to hide the auc letters when flights are unchecked
		if (
			!_.isEqual(initialFlightDataCheckedCount, checkedFlightsCountUpdated) &&
			initialFlightDataCheckedCount.length !== 0
		) {
			setHideJustAUCLetters(true);
		} else if (initialFlightDataCheckedCount.length === 0) {
			if (_.some(updatedSelection, (ut) => !ut.checked)) {
				setHideJustAUCLetters(true);
			} else {
				setHideJustAUCLetters(false);
			}
		} else {
			setHideJustAUCLetters(false);
		}
	}

	function updateTreatmentChecked(index) {
		let updatedTreatment = produce(groupedTrialData, (draft) => {
			draft[index] = { ...groupedTrialData[index], ["checked"]: !groupedTrialData[index].checked };
		});

		setNeedToUpdateAnalytics(true);
		let checkedTreatmentCountUpdated = _.map(updatedTreatment, (trialData) => {
			return trialData.checked;
		});

		let tempHideLetter = false;

		//Logic to hide the analysis average letters when flights are unchecked
		if (
			!_.isEqual(initialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
			initialGroupTrialDataCheckedCount.length !== 0
		) {
			tempHideLetter = true;
		} else if (initialGroupTrialDataCheckedCount.length === 0) {
			if (_.some(updatedTreatment, (ut) => !ut.checked)) {
				tempHideLetter = true;
			}
		}

		setHideLetters(tempHideLetter);

		let treatmentIds = [];
		_.filter(updatedTreatment, (item, index) => {
			if (!item.checked) {
				treatmentIds.push(index + 1);
			}
		});

		let filteredUncheckedTreatments = _.filter(data, (d) => {
			return !_.includes(treatmentIds, d.trialTreatmentId);
		});

		let flightGroupedData = _.groupBy(filteredUncheckedTreatments, "flightId");
		let clone = _.cloneDeep(updatedTreatment);

		//Recalculate coloring for auc values
		tableShader.calculateShadingForTreatment(
			_.filter(clone, (f) => {
				return f.checked;
			}),
			"areaUnderTheCurve",
			"90, 138, 198"
		);

		//Recalculate coloring for treatment values
		tableShader.calculateShadingForGroupedTreatment(
			flightGroupedData,
			"trialAnalysisResultAverageValue",
			"99, 184, 115"
		);
		_.map(flightGroupedData, (fgd) => {
			_.map(fgd, (treatment) => {
				let matchingTreatment = _.find(clone, (ut) => {
					return ut.trialTreatmentId === treatment.trialTreatmentId;
				});
				let matchingIndex = _.findIndex(matchingTreatment.treatments, (mt) => {
					return mt.flightId === treatment.flightId;
				});
				matchingTreatment.treatments[matchingIndex].color = treatment.color;
			});
		});
		setGroupedTrialData(clone);
	}

	function hasPlantFlight() {
		const plantFlight = _.some(flights, (f) => {
			return f.isPlantFlight === true;
		});
		return plantFlight;
	}

	return (
		<Segment basic style={{ width: "100%", overflowX: "auto", maxHeight: 800, overflowY: "auto" }}>
			<Table collapsing selectable celled>
				<Table.Header>
					<Table.Row textAlign="center">
						<Table.HeaderCell></Table.HeaderCell>
						<Table.HeaderCell rowSpan="1" colSpan="2">
							Treatment
						</Table.HeaderCell>
						<Table.HeaderCell rowSpan="1" colSpan={flights.length}>
							{flights.length > 0 ? flights[0].analysisName : null} Timecourse
						</Table.HeaderCell>
						<Table.HeaderCell rowSpan="1" colSpan="2">
							Area Under the Curve
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row textAlign="center">
						<Table.HeaderCell>
							<Checkbox
								label="Check All"
								checked={toggleAll}
								disabled={
									(loadingStats || allMeanComparisonData?.length !== uniqueAnalysesCount?.length) &&
									isForAnalysisTimecoursePage
								}
								onClick={() => {
									if (
										!loadingStats &&
										(allMeanComparisonData?.length === uniqueAnalysesCount?.length || !isForAnalysisTimecoursePage)
									) {
										updateCheckAll().then(toggleCheckAll());
									}
								}}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>#</Table.HeaderCell>
						<Table.HeaderCell>Name</Table.HeaderCell>
						{_.map(flights, (flight, index) => {
							return (
								<Table.HeaderCell textAlign="center" key={index}>
									<Checkbox
										checked={flight.checked}
										onClick={() => {
											if (
												!loadingStats &&
												(allMeanComparisonData?.length === uniqueAnalysesCount?.length || !isForAnalysisTimecoursePage)
											) {
												updateFlightChecked(index);
											}
										}}
										disabled={
											(loadingStats || allMeanComparisonData?.length !== uniqueAnalysesCount?.length) &&
											isForAnalysisTimecoursePage
										}
									/>
									<br />
									{moment(flight.flightDate).local().format(DATE_FORMAT)}
									<br />
									{!flight.isPlantFlight && weatherDataEnabled ? (
										gduByDay.length > 0 ? (
											"GDUs: " +
											gduByDay
												.slice(
													0,
													gduByDay.findIndex((item) => moment(item.day).isSame(moment(flight.flightDate), "day"))
												)
												.reduce((a, b) => a + b.gdu, 0)
												.toFixed(0)
										) : (
											"N/A"
										)
									) : gduByDay.length > 0 ? (
										<br />
									) : (
										""
									)}
								</Table.HeaderCell>
							);
						})}
						<Table.HeaderCell>AVG</Table.HeaderCell>
						<Table.HeaderCell>STDEV</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(groupedTrialData, (trials, index) => {
						return (
							<Table.Row key={index}>
								<Table.Cell textAlign="center" collapsing>
									<Checkbox
										checked={trials.checked}
										disabled={
											(loadingStats || allMeanComparisonData?.length !== uniqueAnalysesCount?.length) &&
											isForAnalysisTimecoursePage
										}
										onClick={() => {
											if (
												!loadingStats &&
												(allMeanComparisonData?.length === uniqueAnalysesCount?.length || !isForAnalysisTimecoursePage)
											) {
												updateTreatmentChecked(index);
											}
										}}
									/>
								</Table.Cell>
								<Table.Cell textAlign="center" collapsing>
									{trials.trialTreatmentId}
								</Table.Cell>
								<Table.Cell style={{ minWidth: "505px" }}>
									{trials && trials.treatments && trials.treatments.length > 0
										? trials.treatments[0].treatmentName
										: null}
								</Table.Cell>
								{_.map(trials.treatments, (treatment, subIndex) => {
									return runMeanComparison ? (
										<Table.Cell
											key={subIndex}
											textAlign="right"
											style={{
												backgroundColor:
													trials.checked && (treatment.include ?? treatment.checked) ? treatment.color : "lightGray"
											}}
										>
											<Grid colSpan={2} style={{ width: "150px" }} columns="equal">
												<Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
													{treatment.isPlantFlight || treatment.isHarvestFlight
														? ""
														: (treatment.trialAnalysisResultAverageValue && trials.checked) ||
														  (!trials.strikethrough && treatment.isFakeTreatment) ||
														  (trials.strikethrough && trials.hasFakeTreatments && treatment.checked)
														? Number(treatment.trialAnalysisResultAverageValue.toFixed(5))
														: ""}
												</Grid.Column>
												&ensp;&ensp;
												<Grid.Column
													style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", overflowWrap: "break-word" }}
												>
													{needToRerunStats ||
													(!treatment.checked === undefined && !treatment.checked) ||
													!trials.checked ||
													(hideLetters && needToUpdateAnalytics) ||
													!treatment.include ||
													(!trials.strikethrough && treatment.isFakeTreatment) ||
													(trials.strikethrough && trials.hasFakeTreatments && treatment.checked) ? (
														""
													) : (treatment.letter && !loadingStats) || !treatment.trialAnalysisResultAverageValue ? (
														treatment.letter
													) : (
														<Loader active size="tiny" />
													)}
												</Grid.Column>
											</Grid>
										</Table.Cell>
									) : (
										<Table.Cell
											key={subIndex}
											textAlign="center"
											style={{
												backgroundColor:
													trials.checked && (treatment.include ?? treatment.checked) ? treatment.color : "lightGray"
											}}
										>
											<Grid style={{ width: "150px" }}>
												<Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
													{treatment.isPlantFlight || treatment.isHarvestFlight
														? ""
														: (treatment.trialAnalysisResultAverageValue && trials.checked) ||
														  (!trials.strikethrough && treatment.isFakeTreatment) ||
														  (trials.strikethrough && trials.hasFakeTreatments && treatment.checked)
														? Number(treatment.trialAnalysisResultAverageValue.toFixed(5))
														: ""}
												</Grid.Column>
											</Grid>
										</Table.Cell>
									);
								})}

								<Table.Cell
									textAlign="right"
									collapsing
									style={{
										backgroundColor:
											trials.color === "rgb(90, 138, 198, NaN%)" && trials.checked
												? ""
												: trials.checked && !hideJustAUCLetters
												? trials.color
												: hideJustAUCLetters
												? ""
												: "lightGray"
									}}
								>
									<Grid colSpan={2} style={{ width: "150px" }} columns="equal">
										<Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
											{trials.checked && !hideJustAUCLetters ? Number(trials.areaUnderTheCurve.toFixed(5)) : ""}
										</Grid.Column>
										&ensp;&ensp;
										<Grid.Column
											style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", overflowWrap: "break-word" }}
										>
											{!loadingStats
												? _.find(meanComparisonDataAuc?.letteringPairs, (mcd) => {
														return (
															mcd.treatmentId == trials.trialTreatmentId &&
															trials.checked &&
															!hideLetters &&
															!hideJustAUCLetters &&
															!needToRerunStats
														);
												  })?.letter ?? ""
												: !trials.strikethrough && <Loader active size="tiny" />}
											{trials.hasFakeTreatments && (
												<>
													&ensp;&ensp;
													<Tooltip
														placement="top"
														mouseEnterDelay={0}
														mouseLeaveDelay={0}
														trigger="hover"
														overlay={
															<div>
																Value contains less flight data than other treatment values. Uncheck flight(s) to
																balance data across all treatments
															</div>
														}
													>
														<Icon name="info circle"></Icon>
													</Tooltip>{" "}
												</>
											)}
										</Grid.Column>
									</Grid>
								</Table.Cell>
								<Table.Cell
									textAlign="center"
									collapsing
									style={{
										backgroundColor: trials.checked && !hideJustAUCLetters ? "" : hideJustAUCLetters ? "" : "lightGray"
									}}
								>
									{(trials.checked && isNaN(trials.standardDeviation)) || !isFinite(trials.standardDeviation)
										? 0
										: trials.checked && !hideJustAUCLetters
										? Number(trials.standardDeviation.toFixed(5))
										: ""}
								</Table.Cell>
							</Table.Row>
						);
					})}
					{loadingStats
						? null
						: _.map(footerData, (footer, index) => {
								return (
									<Table.Row key={index}>
										<Table.Cell colSpan={hasPlantFlight() ? 4 : 3}>{footer[0]?.footerName}</Table.Cell>
										{_.map(footer, ({ data, footerName, include }, subIndex) => {
											return (
												<Table.Cell key={subIndex} textAlign="center" collapsing>
													{data && (!hideLetters || !needToUpdateAnalytics)
														? (footerName === "P-Value" && needToRerunStats) ||
														  !_.some(groundTrialDataChecked, (gtdc) => {
																return gtdc === true;
														  })
															? ""
															: footerName === "P-Value" && data === null
															? "N/A"
															: Number(Number(data).toFixed(5)) !== 0
															? Number(Number(data).toFixed(5))
															: "<0.00001"
														: include && (!hideLetters || !needToUpdateAnalytics) && !needToRerunStats
														? "-"
														: ""}
												</Table.Cell>
											);
										})}
										{_.some(groupedTrialData[0]?.treatments, (tr) => tr.isHarvestFlight) && <Table.Cell colSpan="1" />}
										{meanComparisonDataAuc &&
										(!hideLetters || !needToUpdateAnalytics) &&
										!hideJustAUCLetters &&
										_.some(groundTrialDataChecked, (gtdc) => {
											return gtdc === true;
										}) ? (
											<Table.Cell colSpan="2" textAlign="center">
												{footer[0]?.footerName === "Global Mean"
													? Number(Number(meanComparisonDataAuc?.mean).toFixed(5))
													: footer[0]?.footerName === "CV"
													? Number(Number(meanComparisonDataAuc?.cv).toFixed(5))
													: needToRerunStats
													? ""
													: Number(Number(meanComparisonDataAuc?.pValue).toFixed(5)) === 0
													? "<0.00001"
													: Number(Number(meanComparisonDataAuc?.pValue).toFixed(5))}
											</Table.Cell>
										) : (
											<Table.Cell colSpan="2" />
										)}
									</Table.Row>
								);
						  })}
				</Table.Body>
			</Table>
		</Segment>
	);
};

AreaUnderTheCurveTable.propTypes = {
	data: PropTypes.array.isRequired,
	aucData: PropTypes.array.isRequired,
	updateFlightAndTreatmentSelections: PropTypes.func,
	groupedTrialData: PropTypes.array.isRequired,
	setGroupedTrialData: PropTypes.func,
	groundTrialDataChecked: PropTypes.array.isRequired,
	flights: PropTypes.array.isRequired,
	setFlights: PropTypes.func.isRequired,
	flightDataChecked: PropTypes.array.isRequired,
	footerData: PropTypes.array.isRequired,
	flightsInCurveModel: PropTypes.array,
	runMeanComparison: PropTypes.bool,
	gduByDay: PropTypes.array,
	loadingStats: PropTypes.bool,
	setNeedToUpdateAnalytics: PropTypes.func,
	meanComparisonDataAuc: PropTypes.object.isRequired,
	needToUpdateAnalytics: PropTypes.bool,
	initialGroupTrialDataCheckedCount: PropTypes.array,
	hideLetters: PropTypes.bool,
	setHideLetters: PropTypes.func,
	initialFlightDataCheckedCount: PropTypes.array,
	hideJustAUCLetters: PropTypes.bool,
	setHideJustAUCLetters: PropTypes.func,
	needToRerunStats: PropTypes.bool,
	allMeanComparisonData: PropTypes.array,
	uniqueAnalysesCount: PropTypes.array,
	isForAnalysisTimecoursePage: PropTypes.bool
};

export default AreaUnderTheCurveTable;
