const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function inspectPlotData(plotInfoCsv, plotFieldDataVm, accessToken) {
	const plotFormData = new FormData();

	plotFormData.append("plotInfoCsv", plotInfoCsv, plotInfoCsv.name);

	for (const name in plotFieldDataVm) {
		let val = plotFieldDataVm[name] === "" ? null : plotFieldDataVm[name];
		plotFormData.set(name, val);
	}

	return fetch(`${aerialApiUri}api/v1/Plots/InspectPlotData`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: plotFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}
