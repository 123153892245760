import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../../../auth/auth0";
import * as ControlFunctions from "./orthoControlFunctions.js";

const AlignPlotsModal = React.forwardRef(({ draw, canvas, orthoImageTypeId, controlComponentRefs }, ref) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	useImperativeHandle(ref, () => {
		return {
			setLoading: (val) => setLoading(val),
			setModalOpen: (val) => setModalOpen(val),
			handleClick: () => setModalOpen(true)
		};
	});

	AlignPlotsModal.displayName = "AlignPlotsModal";

	function handleCancel() {
		setModalOpen(false);
	}

	function handleAlign() {
		setLoading(true);
		ControlFunctions.alignPlots(
			draw,
			canvas(),
			orthoImageTypeId(),
			moduleNavigation,
			dispatch,
			clientId,
			getTokenSilently,
			ref,
			controlComponentRefs
		);
	}

	// function handleScale() {
	// 	setLoading(true);
	// 	ControlFunctions.scalePlots(
	// 		draw,
	// 		canvas(),
	// 		orthoImageTypeId(),
	// 		moduleNavigation,
	// 		dispatch,
	// 		clientId,
	// 		getTokenSilently,
	// 		ref,
	// 		controlComponentRefs
	// 	);
	// }

	// function handleScaleAndAlign() {
	// 	setLoading(true);
	// 	ControlFunctions.scaleAndAlignPlots(
	// 		draw,
	// 		canvas(),
	// 		orthoImageTypeId(),
	// 		moduleNavigation,
	// 		dispatch,
	// 		clientId,
	// 		getTokenSilently,
	// 		ref,
	// 		controlComponentRefs
	// 	);
	// }

	// Creating hidden button and calling fake click to trigger modal to open
	// - Workaround for not using states in the layer above
	return (
		<>
			<Modal open={modalOpen}>
				<Modal.Header>Align Plots for Selected Field</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<p>
							Aligning plots will attempt to center plots based on their rows. Plots that have trouble being aligned
							will be marked according to the legend at the bottom of this page. This process may take some time to
							complete.
						</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					{/* <Button
						type="button"
						floated="right"
						onClick={() => handleScaleAndAlign()}
						primary
						loading={loading}
						disabled={loading}
					>
						Scale and Align Plots
					</Button> */}
					<Button
						type="button"
						floated="right"
						onClick={() => handleAlign()}
						positive
						loading={loading}
						disabled={loading}
					>
						Align Plots
					</Button>
					{/* <Button
						type="button"
						floated="right"
						onClick={() => handleScale()}
						positive
						loading={loading}
						disabled={loading}
					>
						Scale Plots
					</Button> */}
					<Button type="button" floated="right" onClick={() => handleCancel()} loading={loading} disabled={loading}>
						Cancel
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
});

AlignPlotsModal.propTypes = {
	draw: PropTypes.object.isRequired,
	canvas: PropTypes.func,
	orthoImageTypeId: PropTypes.func,
	controlComponentRefs: PropTypes.object
};

export default AlignPlotsModal;
