import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Segment, Dimmer, Loader } from "semantic-ui-react";

import { useAuth0 } from "../../auth/auth0";

import { useModuleNavigation } from "../../hooks/useModuleNavigation";

const Logout = () => {
	const { logout } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		moduleNavigation.removeCurrentPath();
		logout({ returnTo: process.env.aerialPlotConnections.aerialPlotWeb.returnTo });
	}, []);

	return (
		<Segment textAlign="center" style={{ minHeight: 116 }}>
			<Dimmer inverted active>
				<Loader inverted>Logging out</Loader>
			</Dimmer>
		</Segment>
	);
};

Logout.propTypes = {
	logoutFunction: PropTypes.func
};

export default Logout;
