import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import _ from "lodash";
import * as queryString from "query-string";

import { Segment, Menu, Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as clientActions from "../../../../redux/actions/clientActions";

import CmClient from "./CmClient";
import CmMembership from "./CmMembership";

const ClientManagement = () => {
	const location = useLocation();
	const history = useHistory();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	const urlQueryStrings = queryString.parse(location.search);
	const qsActive = urlQueryStrings.active;

	//-- Data Source
	const [clientInfo, setClientInfo] = useState(null);

	//-- Dropdown Options
	const [clientOptions, setClientOptions] = useState(null);

	//-- Selected Data
	const [selectedClientId, setSelectedClientId] = useState(null);

	const client = "client";
	const membership = "membership";

	const [activeItem, setActiveItem] = useState(client);

	useEffect(() => {
		if (userAuth.isReady) {
			if (!clientOptions) {
				getClients();
			} else if (!selectedClientId) {
				setSelectedClientId(clientOptions[0].value);
			}
		}
	}, [userAuth.isReady, clientOptions]);

	const getClients = async () => {
		const accessToken = await getTokenSilently();
		dispatch(clientActions.getClients(userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					const clientOpts = _.map(res.data, ({ clientId, name }) => {
						const opt = { key: clientId, value: clientId, text: name };
						return opt;
					});

					setClientOptions(clientOpts);
				} else {
					toast.error("Unable to load clients. Please try again.");
				}
			})
			.catch(() => {
				toast.error("Unable to load clients. Please try again.");
			});
	};

	useEffect(() => {
		if (selectedClientId) {
			getClientInfo(selectedClientId);
		}
	}, [selectedClientId]);

	async function getClientInfo(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(clientActions.getClientInfo(accessToken, clientId, userAuth.currentClientId))
			.then((res) => {
				setClientInfo(res.data);
			})
			.catch(() => {
				toast.error("Unable to load client info. Please try again.");
			});
	}

	useEffect(() => {
		setActiveItem(getActiveItemFromQueryString());
	}, [location.search]);

	function getActiveItemFromQueryString() {
		switch (qsActive) {
			default:
			case client:
				return client;
			case membership:
				return membership;
		}
	}

	const clientSaved = (clientId) => {
		setSelectedClientId(clientId);
		getClients();
	};

	const memberSaved = () => {
		getClientInfo(selectedClientId);
	};

	const memberRemoved = () => {
		getClientInfo(selectedClientId);
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Client Management</h2>
			<Dropdown
				selection
				options={clientOptions}
				value={selectedClientId}
				onChange={(event, { value }) => {
					setSelectedClientId(value);
				}}
				loading={!clientOptions}
				style={{ float: "right" }}
			></Dropdown>
			<hr style={{ clear: "both" }} />
			<Menu pointing>
				<Menu.Item
					name="Client"
					active={activeItem === client}
					onClick={() => {
						history.push(`?active=${client}`);
					}}
				/>
				<Menu.Item
					name="Membership"
					active={activeItem === membership}
					onClick={() => {
						history.push(`?active=${membership}`);
					}}
				/>
			</Menu>
			{activeItem === client && <CmClient clientData={clientInfo} onClientSaved={clientSaved} />}
			{activeItem === membership && (
				<CmMembership clientData={clientInfo} onMemberSaved={memberSaved} onMemberRemoved={memberRemoved} />
			)}
		</Segment>
	);
};

export default ClientManagement;
