import * as types from "./actionTypes";
import * as cropApi from "../../apis/cropApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getCropSuccess() {
	return { type: types.CROP_GET_CROP_SUCCESS };
}

export function getCropDetailsSuccess() {
	return { type: types.CROP_GET_CROP_DETAILS_SUCCESS };
}

export function createCropSuccess() {
	return { type: types.CROP_CREATE_SUCCESS };
}

export function updateCropSuccess() {
	return { type: types.CROP_UPDATE_SUCCESS };
}

export function deleteCropSuccess() {
	return { type: types.CROP_DELETE_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getCrops(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return cropApi
				.getCrops(accessToken, clientId)
				.then((res) => {
					dispatch(getCropSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCropDetails(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return cropApi
				.getCropDetails(accessToken, clientId)
				.then((res) => {
					dispatch(getCropDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createCrop(accessToken, crop) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return cropApi
				.createCrop(accessToken, crop)
				.then((res) => {
					dispatch(createCropSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateCrop(accessToken, crop) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return cropApi
				.updateCrop(accessToken, crop)
				.then((res) => {
					dispatch(updateCropSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteCrop(accessToken, uavId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return cropApi
				.deleteCrop(accessToken, uavId)
				.then((res) => {
					dispatch(deleteCropSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
