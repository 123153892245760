import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuth0 } from "../../../../auth/auth0";
import _ from "lodash";

import { Segment, Grid, Form, Button, Divider } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";

import * as userLicenseActions from "../../../../redux/actions/userLicenseActions";
import { validateForm, validObject } from "./formValidation";

import "../styles.css";

const LmManageUserLicense = ({
	userLicenseToUpdate,
	clientOptions,
	potentialUserOptions,
	licenseOptions,
	onCancel,
	onUserLicenseUpdated,
	onUserLicenseRemoved
}) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- UI Control
	const [updating, setUpdating] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [validation, setValidation] = useState(validObject());

	//-- Data
	const [userLicense, setUserLicense] = useState(userLicenseToUpdate);

	//-- Filtered Data
	const [filteredLicenseOptions, setFilteredLicenseOptions] = useState(licenseOptions);

	useEffect(() => {
		if (userLicenseToUpdate !== null) {
			const apAdmin = _.find(licenseOptions, (lOpt) => {
				return lOpt?.text === "ApAdmin";
			})?.value;

			const selectedClientName = _.find(clientOptions, (cOpt) => {
				return cOpt.value === userLicenseToUpdate.clientId;
			});
			if (selectedClientName.text !== "aerialPLOT") {
				if (userLicense.licenseId === apAdmin) {
					setUserLicense((userLicense) => ({ ...userLicense, licenseId: null }));
				}
				const flOpt = _.filter(licenseOptions, (lOpt) => {
					return lOpt.value !== apAdmin;
				});
				setFilteredLicenseOptions(flOpt);
			} else {
				setFilteredLicenseOptions(licenseOptions);
			}
		}
	}, [userLicenseToUpdate.clientId]);

	const updateUserLicense = async () => {
		const formValidation = validateForm(userLicense);
		setValidation(formValidation);
		if (
			formValidation.clientIdError === true ||
			formValidation.userIdError === true ||
			formValidation.licenseIdError === true ||
			formValidation.signUpDateError === true ||
			formValidation.expiresDateError === true
		) {
			toast.error("All fields must be filled out.");
			return;
		}
		setUpdating(true);
		const accessToken = await getTokenSilently();
		dispatch(userLicenseActions.updateUserLicense(accessToken, userLicense))
			.then((res) => {
				if (res.statusCode === 200) {
					onUserLicenseUpdated();
					toast.success("User License saved successfully");
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								onUserLicenseUpdated();
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}

				setUpdating(false);
			})
			.catch((err) => {
				toast.error("Unable to save user license. Please try again.");
				console.log(err);
				setUpdating(false);
			});
	};

	const removeUserLicense = async () => {
		setRemoving(true);
		const accessToken = await getTokenSilently();
		dispatch(userLicenseActions.removeUserLicense(accessToken, userLicenseToUpdate.userLicenseId))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("User License removed successfully");
				}
				onUserLicenseRemoved();
				setRemoving(false);
			})
			.catch((err) => {
				toast.error("Unable to remove user license. Please try again.");
				console.log(err);
				setRemoving(false);
			});
	};

	return (
		<Segment basic>
			<Form>
				<Grid id="license-management-grid" verticalAlign="middle" columns="equal">
					<Grid.Row hidden>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-client">Client*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="14">
							<Form.Input
								id="form-input-client"
								value={_.find(clientOptions, { value: userLicense.clientId })?.text}
								readOnly
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-user">User*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="14">
							<Form.Input
								id="form-input-user"
								value={_.find(potentialUserOptions, { value: userLicense.userId })?.text}
								readOnly
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-license">License*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="14">
							<Form.Select
								id="form-input-license"
								selection
								placeholder="Select license"
								options={filteredLicenseOptions ?? []}
								loading={!filteredLicenseOptions}
								value={userLicense.licenseId}
								error={validation.licenseIdError}
								onChange={(event, { value }) => {
									setUserLicense((userLicense) => ({ ...userLicense, licenseId: value }));
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-sign-up-date">Sign Up Date*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="6">
							<Form.Field
								id="form-sign-up-date"
								control={DateTimePicker}
								includeTime={false}
								value={userLicense.signUpDate ? new Date(userLicense.signUpDate) : null}
								error={validation.signUpDateError}
								onChange={(date) => {
									setUserLicense((userLicense) => ({ ...userLicense, signUpDate: date }));
								}}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-expires-date">Expires Date*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="6">
							<Form.Field
								id="form-expires-date"
								control={DateTimePicker}
								includeTime={false}
								value={userLicense.expiresDate ? new Date(userLicense.expiresDate) : null}
								error={validation.expiresDateError}
								onChange={(date) => {
									setUserLicense((userLicense) => ({ ...userLicense, expiresDate: date }));
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Divider />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-added-by">Added By</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Input
								id="form-input-added-by"
								value={
									userLicense.addedByUserFirstName === "System"
										? userLicense.addedByUserFirstName
										: `${userLicense.addedByUserFirstName} ${userLicense.addedByUserLastName}`
								}
								readOnly
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row textAlign="right" style={{ paddingBottom: "unset" }}>
						<Grid.Column>
							<Form.Field>
								<Button id="form-button-cancel" type="button" content="Cancel" onClick={onCancel} disabled={updating} />
								<Button
									id="form-button-save"
									type="button"
									primary
									content="Save"
									onClick={updateUserLicense}
									loading={updating}
									disabled={updating || removing}
								/>
								<Button
									id="form-button-remove"
									type="button"
									negative
									content="Remove License"
									onClick={removeUserLicense}
									loading={removing}
									disabled={removing || updating}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

LmManageUserLicense.propTypes = {
	userLicenseToUpdate: PropTypes.object,
	clientOptions: PropTypes.array,
	potentialUserOptions: PropTypes.array,
	licenseOptions: PropTypes.array,
	clientId: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onUserLicenseUpdated: PropTypes.func.isRequired,
	onUserLicenseRemoved: PropTypes.func.isRequired
};

export default LmManageUserLicense;
