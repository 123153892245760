import * as types from "./actionTypes";
import * as orthoApi from "../../apis/orthoApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

/*
 *  Action Creators
 *
 */

export function addOrthoUpload(orthoToAdd) {
	return { type: types.ORTHO_ADD_ORTHO_UPLOAD, orthoToAdd };
}

export function autoOrthoUpload(autoUploadOrtho) {
	return { type: types.ORTHO_AUTO_ORTHO_UPLOAD, autoUploadOrtho };
}

export function autoOrthoRemove(autoUploadOrtho) {
	return { type: types.ORTHO_AUTO_ORTHO_REMOVE, autoUploadOrtho };
}

export function updateUploadingOrtho(orthoToUpdate) {
	return { type: types.ORTHO_UPDATE_UPLOADING_ORTHO_PROGRESS, orthoToUpdate };
}

export function addFlightElevationOrthoUpload(orthoToAdd) {
	return { type: types.ORTHO_ADD_FLIGHT_ELEVATION_ORTHO_UPLOAD, orthoToAdd };
}
export function addFlightMultibandOrthoUpload(orthoToAdd) {
	return { type: types.ORTHO_ADD_FLIGHT_MULTIBAND_ORTHO_UPLOAD, orthoToAdd };
}

export function updateUploadingElevationOrtho(orthoToUpdate) {
	return { type: types.ORTHO_UPDATE_UPLOADING_ELEVATION_ORTHO_PROGRESS, orthoToUpdate };
}

export function updateUploadingMultibandOrtho(orthoToUpdate) {
	return { type: types.ORTHO_UPDATE_UPLOADING_MULTIBAND_ORTHO_PROGRESS, orthoToUpdate };
}

export function removeOrthoUpload(orthoIdToRemove) {
	return { type: types.ORTHO_REMOVE_ORTHO_UPLOAD, orthoIdToRemove };
}

export function removeElevationOrthoUpload(flightIdToRemove) {
	return { type: types.ORTHO_REMOVE_ELEVATION_ORTHO_UPLOAD, flightIdToRemove };
}

export function removeMultibandOrthoUpload(flightIdToRemove) {
	return { type: types.ORTHO_REMOVE_MULTIBAND_ORTHO_UPLOAD, flightIdToRemove };
}

export function downloadOrthoSuccess() {
	return { type: types.ORTHO_DOWNLOAD_SUCCESS };
}

export function cutOrthoSuccess() {
	return { type: types.ORTHO_CUT_SUCCESS };
}

export function downloadFlightElevationOrthoSuccess() {
	return { type: types.ORTHO_DOWNLOAD_FLIGHT_ELEVATION_ORTHO_SUCCESS };
}

export function downloadFlightMultibandOrthoSuccess() {
	return { type: types.ORTHO_DOWNLOAD_FLIGHT_ELEVATION_ORTHO_SUCCESS };
}

export function deleteFlightMultibandImageSuccess() {
	return { type: types.FLIGHT_DELETE_FLIGHT_MULTIBAND_IMAGE_SUCCESS };
}

export function downloadAzureFilesListSuccess() {
	return { type: types.ORTHO_DOWNLOAD_AZURE_FILES_LIST_SUCCESS };
}

export function copyAzureFileToShareSuccess() {
	return { type: types.ORTHO_COPY_AZURE_FILE_TO_SHARE_SUCCESS };
}

export function createInitialFlightImageRecordSuccess() {
	return { type: types.ORTHO_CREATE_INTIAL_FLIGHT_IMAGE_RECORD_SUCCESS };
}

export function createInitialMultiBandFlightImageRecordSuccess() {
	return { type: types.ORTHO_CREATE_INTIAL_MULTI_BAND_FLIGHT_IMAGE_RECORD_SUCCESS };
}

export function createInitialFlightElevationImageRecordSuccess() {
	return { type: types.ORTHO_CREATE_INTIAL_FLIGHT_ELEVATION_IMAGE_RECORD_SUCCESS };
}

export function retryFlightElevationImageUploadChunksSuccess() {
	return { type: types.ORTHO_RETRY_FLIGHT_ELEVATION_IMAGE_UPLOAD_CHUNKS_SUCCESS };
}

export function retryOrthoImageUploadChunksSuccess() {
	return { type: types.ORTHO_RETRY_ORTHO_IMAGE_UPLOAD_CHUNKS_SUCCESS };
}

export function retryMultibandFlightImageUploadChunksSuccess() {
	return { type: types.ORTHO_RETRY_MULTI_BAND_FLIGHT_IMAGE_UPLOAD_CHUNKS_SUCCESS };
}

export function uploadImageChunkSuccess() {
	return { type: types.ORTHO_UPLOAD_IMAGE_CHUNK_SUCCESS };
}

export function getViewerFlightImageSuccess() {
	return { type: types.ORTHO_GET_VIEWER_FLIGHT_IMAGE_SUCCESS };
}

export function generateOrthoFromMultibanOrthoSuccess() {
	return { type: types.ORTHO_GENERATE_ORTHO_FROM_MULTIBAND_ORTHO_SUCCESS };
}

export function cutPlotImagesSuccess() {
	return { type: types.ORTHO_CUT_PLOT_IMAGES_SUCCESS };
}

export function isFieldImageUploadedSuccess() {
	return { type: types.ORTHO_IS_FIELD_IMAGE_UPLOADED_SUCCESS };
}

export function getPlotImagesUploadedStatusSuccess() {
	return { type: types.ORTHO_GET_PLOT_IMAGES_UPLOADED_STATUS };
}

export function retryMapboxUploadSuccess() {
	return { type: types.ORTHO_RETRY_MAPBOX_UPLOAD_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function downloadOrtho(flightImageId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.downloadOrtho(flightImageId, clientId, accessToken)
				.then((res) => {
					dispatch(downloadOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function cutOrtho(cutParams, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.cutOrtho(cutParams, accessToken)
				.then((res) => {
					dispatch(cutOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function downloadFlightElevationOrtho(flightElevationImageId, fileName, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.downloadFlightElevationOrtho(flightElevationImageId, fileName, clientId, accessToken)
				.then((res) => {
					dispatch(downloadFlightElevationOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function downloadFlightMultibandOrtho(flightMultibandImageId, fileName, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.downloadFlightMultibandOrtho(flightMultibandImageId, fileName, clientId, accessToken)
				.then((res) => {
					dispatch(downloadFlightElevationOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFlightMultibandImage(flightMultibandImageId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.deleteFlightMultibandImage(flightMultibandImageId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteFlightMultibandImageSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function downloadAzureFilesList(directoryName, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.downloadAzureFilesList(directoryName, accessToken)
				.then((res) => {
					if (res?.statusCode === 200) {
						dispatch(downloadAzureFilesListSuccess());
						resolve(res);
					} else {
						dispatch(apiCallError(res));
						reject(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function copyAzureFileToShare(azureFileData, accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.copyAzureFileToShare(azureFileData, accessToken, clientId)
				.then((res) => {
					if (res?.statusCode === 200) {
						dispatch(copyAzureFileToShareSuccess());
						resolve(res);
					} else {
						dispatch(apiCallError(res));
						reject(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function copyAzureFileToShareMultiband(azureFileData, accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.copyAzureFileToShareMultiband(azureFileData, accessToken, clientId)
				.then((res) => {
					if (res?.statusCode === 200) {
						dispatch(copyAzureFileToShareSuccess());
						resolve(res);
					} else {
						dispatch(apiCallError(res));
						reject(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function copyAzureFileToShareElevation(azureFileData, accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.copyAzureFileToShareElevation(azureFileData, accessToken, clientId)
				.then((res) => {
					if (res?.statusCode === 200) {
						dispatch(copyAzureFileToShareSuccess());
						resolve(res);
					} else {
						dispatch(apiCallError(res));
						reject(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateOrthoFromMultibandOrtho(clientId, selectedOrtho, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.generateOrthoFromMultibandOrtho(clientId, selectedOrtho, accessToken)
				.then((res) => {
					if (res?.statusCode === 200) {
						dispatch(generateOrthoFromMultibanOrthoSuccess());
						resolve(res);
					} else if (res?.statusCode === 400) {
						dispatch(apiCallError(res));
						reject(res);
						toast.error("Ortho failed to generate. Please try again.");
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createInitialFlightImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.createInitialFlightImageRecord(initialRecord, clientId, selectedFile, chunkSize, selectedOrtho, accessToken)
				.then((res) => {
					dispatch(createInitialFlightImageRecordSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createInitialMultiBandFlightImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.createInitialMultiBandFlightImageRecord(
					initialRecord,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					accessToken
				)
				.then((res) => {
					dispatch(createInitialMultiBandFlightImageRecordSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createInitialFlightElevationImageRecord(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.createInitialFlightElevationImageRecord(
					initialRecord,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					accessToken
				)
				.then((res) => {
					dispatch(createInitialFlightElevationImageRecordSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function uploadImageChunk(formData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.uploadImageChunk(formData, accessToken)
				.then((res) => {
					dispatch(uploadImageChunkSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getViewerFlightImage(flightId, flightImageId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.getViewerFlightImage(flightId, flightImageId, clientId, accessToken)
				.then((res) => {
					dispatch(getViewerFlightImageSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function cutPlotImages(orthoToCut, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.cutPlotImages(orthoToCut, accessToken)
				.then((res) => {
					dispatch(cutPlotImagesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function isFieldImageUploaded(imageToCheck, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.isFieldImageUploaded(imageToCheck, accessToken)
				.then((res) => {
					dispatch(isFieldImageUploadedSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPlotImagesUploadedStatus(plotImagesToGet, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.getPlotImagesUploadedStatus(plotImagesToGet, accessToken)
				.then((res) => {
					dispatch(getPlotImagesUploadedStatusSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function retryFlightElevationImageUploadChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightElevationImageId,
	startingChunkNumber,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoApi
				.retryUploadingFlightElevationImageChunks(
					initialRecord,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					flightElevationImageId,
					startingChunkNumber,
					accessToken
				)
				.then((res) => {
					dispatch(retryFlightElevationImageUploadChunksSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function retryFlightImageUploadChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightImageId,
	startingChunkNumber,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return orthoApi
				.retryOrthoImageUploadChunks(
					initialRecord,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					flightImageId,
					startingChunkNumber,
					accessToken
				)
				.then((res) => {
					dispatch(retryOrthoImageUploadChunksSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function retryMultibandImageUploadChunks(
	initialRecord,
	clientId,
	selectedFile,
	chunkSize,
	selectedOrtho,
	flightMultibandImageId,
	startingChunkNumber,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return orthoApi
				.retryUploadingMultibandFlightImageChunks(
					initialRecord,
					clientId,
					selectedFile,
					chunkSize,
					selectedOrtho,
					flightMultibandImageId,
					startingChunkNumber,
					accessToken
				)
				.then((res) => {
					dispatch(retryMultibandFlightImageUploadChunksSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function retryMapboxUpload(flightImageId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return orthoApi
				.retryMapboxUpload(flightImageId, clientId, accessToken)
				.then((res) => {
					dispatch(retryMapboxUploadSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
