import _ from "lodash";
export function getPercisions(values, propertyName) {
	return _.map(values, (v) => {
		v[propertyName] = getPercision(v[propertyName]);
		return v;
	});
}

export function getPercision(value) {
	if (value) {
		const calculatedPercisionValue = calculatePercision(value);
		return calculatedPercisionValue;
	}

	return value;
}

function calculatePercision(value) {
	if (Number(value) >= 1000) {
		value = Number(Number(value).toFixed(0));
	} else if (Number(value) < 1000 && Number(value) > 1) {
		value = Number(Number(value).toFixed(2));
	} else {
		value = Number(Number(value).toFixed(5));
	}
	return value;
}
