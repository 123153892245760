import Login from "../auth/Login";
import SetupNeeded from "../auth/SetupNeeded";
import Eula from "../auth/Eula";
import SubscriptionExpired from "../auth/SubscriptionExpired";
import Logout from "../auth/Logout";
import AuthCallback from "../auth/AuthCallback";
import Home from "./Content/Home";
import PageNotFound from "./Content/PageNotFound";
import Support from "./Content/Support";
import AccessDenied from "../auth/AccessDenied";

import { dashboardRoutes } from "./routes.dashboard";
import { flightRoutes } from "./routes.flights";
import { fieldRoutes } from "./routes.fields";
import { trialRoutes } from "./routes.trials";
import { protocolRoutes } from "./routes.protocols.js";

import { settingsRoutesAp } from "./routes.settings.ap";
import { settingsRoutesClient } from "./routes.settings.client";
import AutoLogin from "../auth/AutoLogin";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

/*
 * Overview of routing pattern
 *
 */
//-- /
//-- /module
//-- /module/:id[/submodule[/:id]]...
//--  submodule could be viewer, edit, analysis, etc.
//-- /module/:id[/module[/:id]][/submodule[/:id]]...

/*
 * All routes
 *
 */
//-- 								(default to login, but bypass if authenticated)
//-- /							(default to login, but bypass if authenticated)
//-- /login
//-- /auth-callback
//-- /logout
//-- /setup-needed

//-- /dashboard

//-- /flights[/*]
//-- /fields[/*]
//-- /trials[/*]

//-- /page-not-found

//-- private is a reserved word, so use secured instead

export const moduleRoutes = [
	{
		id: "login",
		name: "login",
		enabled: true,
		login: true,
		logout: false,
		home: false,
		linkText: "Login",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["", "/", "/login"],
		exact: true,
		component: Login,
		module: "login",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "auto-login",
		name: "auto-login",
		enabled: true,
		login: true,
		logout: false,
		home: false,
		linkText: "Auto Login",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/auto-login"],
		exact: true,
		component: AutoLogin,
		module: "login",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "auth-callback",
		name: "auth-callback",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Auth Callback",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/auth-callback"],
		exact: true,
		component: AuthCallback,
		module: "auth-callback",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "logout",
		name: "logout",
		enabled: true,
		login: false,
		logout: true,
		home: false,
		linkText: "Logout",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/logout"],
		exact: true,
		component: Logout,
		module: "logout",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "setup-needed",
		name: "setup-needed",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Setup Needed",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/setup-needed"],
		exact: true,
		component: SetupNeeded,
		module: "setup-needed",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "eula",
		name: "eula",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Eula",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/eula"],
		exact: true,
		component: Eula,
		module: "eula",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "subscription-expired",
		name: "subscription-expired",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Subscription Expired",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/subscription-expired"],
		exact: true,
		component: SubscriptionExpired,
		module: "subscription-expired",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "access-denied",
		name: "access-denied",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Access Denied",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/access-denied"],
		exact: true,
		component: AccessDenied,
		module: "access-denied",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "home",
		name: "home",
		enabled: true,
		login: false,
		logout: false,
		home: true,
		linkText: "Home",
		icon: "home",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: ["/home"],
		exact: true,
		component: Home,
		module: "home",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "dashboard",
		name: "dashboard",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Home",
		icon: "home",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: ["/dashboard"],
		exact: true,
		component: null,
		module: "dashboard",
		submodules: dashboardRoutes,
		applicationAreas:[ApplicationArea.HomeOverview, ApplicationArea.HomeFavorites, ApplicationArea.HomeRecentlyUpdated, ApplicationArea.HomeRecentlyPublished],
		settings: false
	},
	{
		id: "flights",
		name: "flights",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Flights",
		icon: "paper plane",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: ["/flights"],
		exact: true,
		component: null,
		module: "flights",
		submodules: flightRoutes,
		applicationAreas:[ApplicationArea.FlightSearch, ApplicationArea.FlightViewer, ApplicationArea.FlightNew, ApplicationArea.FlightEdit, 
											ApplicationArea.FlightInfo, ApplicationArea.FlightOrthoUpload, ApplicationArea.FlightGenerateOrtho, ApplicationArea.FlightDownloadOrtho, 
											ApplicationArea.FlightAddNewFields, ApplicationArea.FlightAlignFields, ApplicationArea.FlightPlotAnalysis],
		settings: false
	},
	{
		id: "fields",
		name: "fields",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Fields",
		icon: "th large",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: ["/fields"],
		exact: true,
		component: null,
		module: "fields",
		submodules: fieldRoutes,
		applicationAreas:[ApplicationArea.FieldSearch, ApplicationArea.FieldViewer, ApplicationArea.FieldNew, ApplicationArea.FieldEdit,
											ApplicationArea.FieldHeatMap, ApplicationArea.FieldOutline, ApplicationArea.FieldGroundData],
		settings: false
	},
	{
		id: "trials",
		name: "trials",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Trials",
		icon: "leaf",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: ["/trials"],
		exact: true,
		component: null,
		module: "trials",
		submodules: trialRoutes,
		applicationAreas:[ApplicationArea.TrialSearch, ApplicationArea.TrialPublishing, ApplicationArea.TrialEdit, ApplicationArea.TrialHeatMap, 
										 	ApplicationArea.TrialViewer, ApplicationArea.TrialVisualHeatmapShading, ApplicationArea.TrialVisualHeatmapValues, ApplicationArea.TrialExportFunctionality, 
											ApplicationArea.TrialDamagePlots, ApplicationArea.TrialExcludePlots, ApplicationArea.TrialFlagPlots, ApplicationArea.TrialAnalysis, 
											ApplicationArea.TrialAnalysisTimecourse, ApplicationArea.TrialAnalysisBoxWhisker, ApplicationArea.TrialAnalysisSummaryTable, ApplicationArea.TrialAnalysisCorrelationMatrix, 
											ApplicationArea.TrialOutline, ApplicationArea.TrialGroundData, ApplicationArea.TrialGroundDataCRUD, ApplicationArea.TrialGroundDataTemplate, 
											ApplicationArea.TrialCurveModels, ApplicationArea.TrialWeather, ApplicationArea.TrialDamagePlotsCRUD, ApplicationArea.TrialExcludePlotsCRUD],
		settings: false
	},
	{
		id: "protocols",
		name: "protocols",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Protocols",
		icon: "folder open",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: ["/protocols"],
		exact: true,
		component: null,
		module: "protocols",
		submodules: protocolRoutes,
		applicationAreas:[ApplicationArea.ProtocolSearch, ApplicationArea.ProtocolNew, ApplicationArea.ProtocoEdit, ApplicationArea.ProtocolOverview,
											ApplicationArea.ProtocolAnalysis],
		settings: false
	},
	{
		id: "support",
		name: "support",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Support",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: ["/support"],
		exact: false,
		component: Support,
		module: "support",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "page-not-found",
		name: "page-not-found",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Page Not Found",
		icon: "",
		cornerIcon: null,
		showInNav: false,
		secured: false,
		paths: [""],
		exact: false,
		component: PageNotFound,
		module: "page-not-found",
		submodules: null,
		applicationAreas: [],
		settings: false
	},
	{
		id: "ap-settings",
		name: "ap-settings",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "aerialPLOT Settings",
		icon: "plane",
		cornerIcon: null,
		showInNav: true,
		secured: false,
		paths: ["/ap-settings"],
		exact: false,
		component: null,
		module: "ap-settings",
		submodules: settingsRoutesAp,
		applicationAreas:[ApplicationArea.ApSettings],
		settings: true
	},
	{
		id: "client-settings",
		name: "client-settings",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Client Settings",
		icon: "building",
		cornerIcon: null,
		showInNav: true,
		secured: false,
		paths: ["/client-settings"],
		exact: false,
		component: null,
		module: "client-settings",
		submodules: settingsRoutesClient,
		applicationAreas:[ApplicationArea.ClientSettings],
		settings: true
	}
];

const getModuleNames = () => {
	const moduleNames = moduleRoutes.map((m) => m.name);
	return moduleNames;
};
export const moduleNames = getModuleNames();

const getAllRoutes = () => {
	const submoduleRoutes = moduleRoutes.reduce((accumulator, { enabled, submodules }) => {
		return enabled && submodules ? accumulator.concat(submodules) : accumulator.concat([]);
	}, []);

	const allRoutes = submoduleRoutes.concat(moduleRoutes);
	return allRoutes;
};
export const allRoutes = getAllRoutes();

const getLoginRoute = () => {
	const loginRoutes = moduleRoutes.filter((r) => r.login);
	let loginRoute = {};
	if (loginRoutes.length > 0) {
		loginRoute = loginRoutes[0];
	}

	return loginRoute;
};
export const loginRoute = getLoginRoute();

const getHomeRoute = () => {
	const homeRoutes = moduleRoutes.filter((r) => r.home && r.enabled);
	let homeRoute = {};
	if (homeRoutes.length > 0) {
		homeRoute = homeRoutes[0];
	}

	return homeRoute;
};
export const homeRoute = getHomeRoute();

const getPageNotFoundRoute = () => {
	const pageNotFoundRoute = moduleRoutes.find((r) => r.name === "page-not-found");
	return pageNotFoundRoute;
};
export const pageNotFoundRoute = getPageNotFoundRoute();

export default {
	moduleRoutes,
	moduleNames,
	allRoutes,
	loginRoute,
	homeRoute,
	pageNotFoundRoute
};
