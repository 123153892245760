// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#field-heatmap-grid {\n\tmargin: unset;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Fields/FieldHeatmap/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd","sourcesContent":["#field-heatmap-grid {\n\tmargin: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-heatmap-grid": "field-heatmap-grid"
};
export default ___CSS_LOADER_EXPORT___;
