import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Dropdown, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import * as outlierFunctions from "../../../Lumber/DataAnalysisCriteria/outlierFunctions";

const AnalysisBoxWhiskerChart = () => {
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const DATE_FORMAT = "M/D/YYYY";

	const CanvasJSChart = CanvasJSReact.CanvasJSChart;
	const [chartOptions, setChartOptions] = useState({});

	//-- Data
	const [analysisResults, setAnalysisResults] = useState([]);
	const [filteredAnalysisResults, setFilteredAnalysisResults] = useState([]);
	const [meanComparisonDataPoints, setMeanComparisonDataPoints] = useState([]);
	const [cachedData, setCachedData] = useState([]);
	const [rawPresetOptions, setRawPresetOptions] = useState([]);
	const [selectedAssessmentName, setSelectedAssessmentName] = useState(null);

	//-- Dropdown Options
	const [selectedAnalysisOption, setSelectedAnalysisOption] = useState("");
	const [flightOptions, setFlightOptions] = useState([]);
	const [filteredFlightOptions, setFilteredFlightOptions] = useState([]);
	const [selectedFlightOption, setSelectedFlightOption] = useState("");
	const [groundDataOptions, setGroundDataOptions] = useState([]);
	const [trialAnalysisOptions, setTrialAnalysisOptions] = useState([]);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [selectedQuantifiedRegionTypeOption, setSelectedQuantifiedRegionTypeOption] = useState(null);
	const [selectedAnalyticType, setSelectedAnalyticType] = useState(null);
	const [allAssessmentTypes, setAllAssessmentTypes] = useState(null);

	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [selectedMeanComparisonOption, setSelectedMeanComparisonOption] = useState(null);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [selectedAlphaOption, setSelectedAlphaOption] = useState(null);
	const [growthCurveOptions, setGrowthCurveOptions] = useState([]);

	//Data Analysis Critera
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	//--UI Control
	const [loading, setLoading] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);
	const meanComparisonEnabled = true;
	const [growthCurveSelected, setGrowthCurveSelected] = useState(false);
	const [hasNoData, setHasNoData] = useState(false);
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [previousAssessmentName, setPreviousAssessmentName] = useState(null);
	const [disableColors, setDisableColors] = useState(false);

	const colors = !disableColors
		? [
			"#920000",
			"#006DDB",
			"#B66DFF",
			"#24FF24",
			"#FFFF6D",
			"#004949",
			"#009292",
			"#FF6DB6",
			"#FFB6DB",
			"#490092",
			"#924900",
			"#DB6D00",
			"#6DB6FF",
			"#B6DBFF",
			"#000000"
		]
		: ["#920000"];
	const shapes = ["circle"];
	let colorIdx = 0;
	let shapeIdx = 0;

	useEffect(() => {
		getDataOptions();
	}, []);

	useEffect(() => {
		if (selectedAnalysisOption && flightOptions && flightOptions.length > 0) {
			changeFlightOptions();
		}
	}, [selectedAnalysisOption, selectedQuantifiedRegionTypeOption, selectedAnalyticType]);

	useEffect(() => {
		if (
			selectedAlphaOption &&
			alphaOptions.length > 0 &&
			selectedMeanComparisonOption &&
			meanComparisonOptions.length > 0 &&
			flightOptions.length > 0 &&
			!needToUpdateAnalytics
		) {
			getAnalysisData();
		}
	}, [selectedAlphaOption, selectedMeanComparisonOption]);

	useEffect(() => {
		if (
			selectedFlightOption &&
			selectedQuantifiedRegionTypeOption &&
			selectedAlphaOption &&
			selectedMeanComparisonOption &&
			!needToUpdateAnalytics
		) {
			updateCanvasData();
		}
	}, [selectedFlightOption, selectedAnalysisOption, selectedQuantifiedRegionTypeOption, selectedAnalyticType]);

	useEffect(() => {
		if (analysisResults.length > 0) {
			stageChartOptions();
		}
	}, [filteredAnalysisResults, disableColors]);

	useEffect(() => {
		if (meanComparisonEnabled && chartOptions.data) {
			let modifiedData = chartOptions.data;
			modifiedData[_.findIndex(modifiedData, { type: "line" })].dataPoints = meanComparisonDataPoints;

			setChartOptions({ ...chartOptions, data: modifiedData });
		} else if (meanComparisonEnabled === false) {
			let modifiedData = chartOptions.data;
			modifiedData[_.findIndex(modifiedData, { type: "line" })].dataPoints = [];

			setChartOptions({ ...chartOptions, data: modifiedData });
		}
	}, [meanComparisonEnabled]);

	const getNextMarker = () => {
		const marker = { color: colors[colorIdx], shape: shapes[shapeIdx] };
		if (colorIdx + 1 >= colors.length) {
			colorIdx = 0;
			//shapeIdx++;
		} else {
			colorIdx++;
		}
		return marker;
	};

	async function getDataOptions() {
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.getTrialAnalysisDataOptions(userAuth.currentClientId, moduleNavigation.trialId, accessToken)
		).then((res) => {
			setGroundDataOptions(res.data.groundDataOptions);
			setTrialAnalysisOptions(res.data.trialAnalysisOptions);
			setGrowthCurveOptions(res.data.growthCurveOptions);
			setupQuantifiedRegionOptions(res);

			let doesTrialHaveStandCountData = false;
			_.map(res.data.trialAnalysisOptions, (tao) => {
				if (_.some(tao.analysisTypes, (at) => at.name.includes("Stand Count (LAI)"))) {
					doesTrialHaveStandCountData = true;
				}
			});
			//Remove Stand Count from the advanced analysis type options if no stand count data exists
			if (!doesTrialHaveStandCountData) {
				let standCountIndex = res.data.presetOptions.analysisTypes.findIndex(x => x.name === "Stand Count");
				res.data.presetOptions.analysisTypes.splice(standCountIndex, 1);
				setRawPresetOptions(res.data.presetOptions);
			}
			else {
				setRawPresetOptions(res.data.presetOptions);
			}

			let combinedAssessmentTypes = [];
			let combinedAssessmentDates = [];

			//-- Ortho Type Presets
			let orthos = _.map(res.data.trialAnalysisOptions, ({ analysisTypes, flightId, flightDate }) => {
				combinedAssessmentTypes = combinedAssessmentTypes.concat(analysisTypes);
				//-- Setup the flight dates
				combinedAssessmentDates.push({
					id: flightId,
					date: flightDate
				});

				return analysisTypes;
			});
			orthos = _.flatten(orthos);
			let orthoTypePresets = analysisDropDownListFunctions.getOrthoTypesForTrialAnalysis(orthos);

			//-- Ground Data Presets
			let groundDataOpts = _.map(
				res.data.groundDataOptions,
				({ groundDataAssessments, groundDatasetId, assessmentDate }) => {
					combinedAssessmentTypes = combinedAssessmentTypes.concat(groundDataAssessments);
					combinedAssessmentDates.push({
						id: groundDatasetId,
						date: assessmentDate
					});
					return groundDataAssessments;
				}
			);
			groundDataOpts = _.flatten(groundDataOpts);

			let groundDataPresets = analysisDropDownListFunctions.getGroundDataPresetsForTrialAnalysis(groundDataOpts);

			//-- Growth Curve Presets
			_.forEach(res.data.growthCurveOptions, (gco) => {
				combinedAssessmentDates.push({
					id: gco.curveModelName,
					date: gco.curveModelAnalysisDateTime
				});
			});
			let growthCurvePresets = analysisDropDownListFunctions.getGrowthCurvePresets(res.data.growthCurveOptions);
			let analyses = orthoTypePresets.concat(groundDataPresets).concat(growthCurvePresets);
			setAllAssessmentTypes(analyses);

			// Default to NDVI if available
			let ndviIndex = _.findIndex(analyses, { fullname: "NDVI" });
			let analysisValue =
				ndviIndex !== -1 ? analyses[ndviIndex]?.analysisid : analyses[1]?.analysisid ?? analyses[0]?.analysisid;
			//-- Checks if list only contains ground data assessments
			if (!analysisValue) {
				let firstIndexWithValue = _.findIndex(analyses, (a) => a.value && !a.analysisid);
				if (firstIndexWithValue !== -1) {
					analysisValue = analyses[firstIndexWithValue]?.value;
				}
			}
			setSelectedAnalysisOption(analysisValue);
			const flights = _.orderBy(combinedAssessmentDates, ["date"], ["asc"]);
			const flightList = _.map(flights, (flight) => {
				return {
					key: flight.id,
					value: flight.id,
					text: moment(flight.date).local().format(DATE_FORMAT)
				};
			});

			setFlightOptions(flightList);
			const filteredFlightList = _.filter(flightList, (flight) => {
				let isGroundData = _.findIndex(res.data.groundDataOptions, { groundDatasetId: flight.key }) !== -1;
				if (isGroundData) {
					return _.some(res.data.groundDataOptions, (r) => {
						return (
							r.groundDatasetId === flight.value && // Checks if ground dataset id matches
							_.some(r.groundDataAssessments, (gda) => {
								// Checks if assessment id exists in dataset
								return gda.id === analysisValue;
							})
						);
					});
				} else {
					return _.some(res.data.trialAnalysisOptions, (r) => {
						return (
							r.flightId === flight.value &&
							_.some(r.analysisTypes, (at) => {
								return at.id === analysisValue;
							})
						);
					});
				}
			});

			//-- setting mean comparison options
			let meanComparisonTemp = [];
			_.forEach(res.data.meanComparisonOptions, (option) => {
				meanComparisonTemp.push({ key: option.id, value: option.id, text: option.name });
			});

			let defaultMeanComparison = _.find(meanComparisonTemp, (mct) => {
				return mct.text == "Tukey HSD";
			});

			setSelectedMeanComparisonOption(res.data.defaultMeanComparisonId ?? defaultMeanComparison.key);
			setMeanComparisonOptions(meanComparisonTemp);

			//-- setting alpha options
			let alphaOptions = [];
			_.forEach(res.data.alphaOptions, (option) => {
				alphaOptions.push({ key: option.id, value: option.id, text: option.value });
			});

			let defaultAlphaOption = _.find(alphaOptions, (ao) => {
				return ao.text == 0.1;
			});

			setSelectedAlphaOption(res.data.defaultAlphaValueId ?? defaultAlphaOption.key);
			setAlphaOptions(alphaOptions);

			setFilteredFlightOptions(filteredFlightList);
			setSelectedFlightOption(filteredFlightList[0]?.value);
		});
	}

	function setupQuantifiedRegionOptions(res) {
		let quantifiedRegionOptions = _.map(res.data.quantifiedRegionTypeOptions, (qrt) => {
			return {
				key: qrt.id,
				value: qrt.id,
				text: qrt.name
			};
		});
		setQuantifiedRegionTypeOptions(quantifiedRegionOptions);
		const defaultQuantifiedRegion =
			_.find(res.data.quantifiedRegionTypeOptions, (tao) => {
				return tao.isDefaultQuantifiedRegion === true;
			})?.id ?? res.data.quantifiedRegionTypeOptions[0].id;
		setSelectedQuantifiedRegionTypeOption(defaultQuantifiedRegion);
	}

	function changeFlightOptions() {
		if (_.some(growthCurveOptions, { curveModelName: selectedAnalysisOption })) {
			const filteredFlightList = _.filter(flightOptions, (flight) => {
				return flight.id === selectedAnalysisOption;
			});
			setGrowthCurveSelected(true);
			setFilteredFlightOptions(filteredFlightList);
			setSelectedFlightOption(null);
		} else {
			setGrowthCurveSelected(false);
			const filteredFlightList = _.filter(flightOptions, (flight) => {
				let isGroundData = _.findIndex(groundDataOptions, { groundDatasetId: flight.key }) !== -1;
				if (isGroundData) {
					return _.some(groundDataOptions, (r) => {
						return (
							r.groundDatasetId === flight.value && // Checks if ground dataset id matches
							_.some(r.groundDataAssessments, (gda) => {
								// Checks if assessment id exists in dataset
								return gda.id === selectedAnalysisOption;
							})
						);
					});
				} else {
					return _.some(trialAnalysisOptions, (r) => {
						return (
							r.flightId === flight.value &&
							_.some(r.analysisTypes, (at) => {
								return (
									at.id === selectedAnalysisOption && at.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption
								);
							})
						);
					});
				}
			});

			setFilteredFlightOptions(filteredFlightList);

			let selectedFlightExistsInOptions = _.findIndex(filteredFlightList, { value: selectedFlightOption }) !== -1;
			if (!selectedFlightExistsInOptions) {
				setSelectedFlightOption(filteredFlightList[0].value);
			}
		}
	}

	function updateCanvasData() {
		let assessmentCombinationExists = false;
		_.forEach(groundDataOptions, ({ groundDataAssessments, groundDatasetId }) => {
			let assessmentExists = _.findIndex(groundDataAssessments, { id: selectedAnalysisOption }) !== -1;
			if (groundDatasetId === selectedFlightOption && assessmentExists) {
				assessmentCombinationExists = true;
				return false; // break loop
			}
		});

		if (!assessmentCombinationExists) {
			_.forEach(trialAnalysisOptions, ({ analysisTypes, flightId }) => {
				let assessmentExists = _.findIndex(analysisTypes, { id: selectedAnalysisOption }) !== -1;
				if (flightId === selectedFlightOption && assessmentExists) {
					assessmentCombinationExists = true;
					return false;
				}
			});
		}

		if (assessmentCombinationExists) {
			let dataIsCached = _.findIndex(cachedData, {
				assessmentType: selectedAnalysisOption,
				assessmentId: selectedFlightOption,
				meanComparisonAlphaValue: selectedAlphaOption,
				quantifiedRegionTypeId: selectedQuantifiedRegionTypeOption,
				isPlotUniformity: analysisDropDownListFunctions.isPlotUniformity(
					rawPresetOptions.analysisTypes,
					selectedAnalyticType
				)
			});
			if (dataIsCached !== -1) {
				stageResults(cachedData[dataIsCached].data, true);
			} else {
				getAnalysisData();
			}
		} else if (_.findIndex(growthCurveOptions, { curveModelName: selectedAnalysisOption }) !== -1) {
			let dataIsCached = _.findIndex(cachedData, {
				growthCurveName: selectedAnalysisOption,
				meanComparisonAlphaValue: _.find(alphaOptions, (ao) => {
					return ao.key == selectedAlphaOption;
				}).text
			});
			if (dataIsCached !== -1) {
				stageResults(cachedData[dataIsCached].data, true);
			} else {
				getAnalysisData();
			}
		} else {
			setHasNoData(true);
		}
	}

	async function getAnalysisData() {
		let growthCurveData = _.find(growthCurveOptions, { curveModelName: selectedAnalysisOption });
		let isGroundData =
			!growthCurveData && _.findIndex(groundDataOptions, { groundDatasetId: selectedFlightOption }) !== -1;

		setChartLoading(true);
		setChartOptions([]);
		const accessToken = await getTokenSilently();
		dispatch(
			growthCurveData
				? analysisActions.getAnalysisResultsForTrialWithMeanComparison(
					userAuth.currentClientId,
					moduleNavigation.trialId,
					_.find(growthCurveOptions, (growthCurveOptions) => {
						return growthCurveOptions.curveModelName == selectedAnalysisOption;
					})?.analysisId, // analysisId
					null, // flightId
					growthCurveData.quantifiedRegionTypeId, // quantifiedRegionTypeId
					growthCurveData.analysisTypeId, // analysis type id
					false, // uniformity value
					null, // groundDatasetAssessmentId'
					null, // groundDatasetId
					_.find(growthCurveOptions, (growthCurveOptions) => {
						return growthCurveOptions.curveModelName == selectedAnalysisOption;
					})?.curveModelAnalysisId, //curveModelAnalysisId
					selectedMeanComparisonOption,
					selectedAlphaOption,
					accessToken,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				)
				: isGroundData
					? analysisActions.getAnalysisResultsForTrialWithMeanComparison(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						null, // analysisId
						null, // flightId
						null, // quantifiedRegionTypeId
						null, // analysis type id
						false, // uniformity value
						selectedAnalysisOption, // groundDatasetAssessmentId
						selectedFlightOption, // groundDatasetId
						null, //curveModelAnalysisId
						selectedMeanComparisonOption,
						selectedAlphaOption,
						accessToken,
						removeDamagedOrExcluded,
						removeOutliers,
						outlierType
					)
					: analysisActions.getAnalysisResultsForTrialWithMeanComparison(
						userAuth.currentClientId,
						moduleNavigation.trialId,
						selectedAnalysisOption, // analysisId
						selectedFlightOption, // flightId
						selectedQuantifiedRegionTypeOption, // quantifiedRegionTypeId
						null, // analysis type id
						analysisDropDownListFunctions.isPlotUniformity(rawPresetOptions.analysisTypes, selectedAnalyticType),
						null, // groundDatasetAssessmentId
						null, // groundDatasetId
						null, //curveModelAnalysisId
						selectedMeanComparisonOption,
						selectedAlphaOption,
						accessToken,
						removeDamagedOrExcluded,
						removeOutliers,
						outlierType
					)
		)
			.then((res) => {
				res = _.filter(res, (r) => {
					return r.excludeFromAssessment === false;
				});
				setAnalysisResults(res);
				stageResults(res, false);
				setLoading(false);
				setHasNoData(false);
			})
			.catch(() => {
				setChartLoading(false);
				setHasNoData(true);
				setLoading(false);
			});
	}

	function stageResults(trialData, isCached) {
		trialData = _.filter(trialData, (td) => !td.excludeFromAssessment);

		//groupBy will make create a property per unique trialTreatmentId on a singular object
		let res = _.groupBy(trialData, "trialTreatmentId");
		if (!isCached) {
			setCachedData(
				cachedData.concat([
					{
						assessmentType: selectedAnalysisOption,
						assessmentId: selectedFlightOption,
						meanComparisonAlphaValue: _.find(alphaOptions, (ao) => {
							return ao.key == selectedAlphaOption;
						}).value,
						quantifiedRegionTypeId: selectedQuantifiedRegionTypeOption,
						isPlotUniformity: analysisDropDownListFunctions.isPlotUniformity(
							rawPresetOptions.analysisTypes,
							selectedAnalyticType
						),
						data: trialData
					}
				])
			);
		}
		setFilteredAnalysisResults(res);
		setHasNoData(false);
	}

	function stageChartOptions() {
		let allOutliers = [];
		let scatterPlots = {};
		let currentIndex = 0;
		let dataPoints = _.map(filteredAnalysisResults, (results, index) => {
			let valuesWithMetaData =
				results[0].flightId == null
					? results[0].curveModelName
						? _.map(results, (r) => ({ value: r.plotAnalysisResultValue, plotName: r.plotName, rep: r.plotReplicate }))
						: _.map(results, (r) => ({
							value: r.groundDatasetAssessmentValue,
							plotName: r.plotName,
							rep: r.plotReplicate
						}))
					: _.map(results, (r) => ({ value: r.plotAnalysisResultValue, plotName: r.plotName, rep: r.plotReplicate }));

			valuesWithMetaData = _.filter(valuesWithMetaData, (vm) => {
				return vm.value !== null;
			});

			const values = _.map(valuesWithMetaData, "value");

			const median = outlierFunctions.getMedian(values);
			const q1 = outlierFunctions.getQ1(values);
			const q3 = outlierFunctions.getQ3(values);

			let min = Number(Math.min(...values).toFixed(5));
			let max = Number(Math.max(...values).toFixed(5));

			const repsValuesWithMetaData = _.groupBy(valuesWithMetaData, "rep");
			_.map(repsValuesWithMetaData, (rvm, rep) => {
				_.map(rvm, (vm) => {
					if (!scatterPlots[rep]) {
						scatterPlots[rep] = [];
					}
					scatterPlots[rep].push({
						x: currentIndex,
						y: vm.value,
						plotName: vm.plotName,
						treatmentNum: currentIndex + 1
					});
				});
			});
			currentIndex++;
			return {
				label: `${parseInt(index)}`,
				y: [min, q1, q3, max, median]
			};
		});

		let letteringLabels = [];

		_.map(filteredAnalysisResults, (results) => {
			letteringLabels.push({
				label: results[0].letter,
				y: null
			});
		});

		let allMeanComparisonLetterA = true;
		_.forEach(letteringLabels, (ll) => {
			if (ll.label && ll.label !== "a") {
				allMeanComparisonLetterA = false;
				return false;
			}
		});

		if (allMeanComparisonLetterA) {
			_.map(letteringLabels, (ll) => {
				ll.label = "-";
			});
		}

		setMeanComparisonDataPoints(letteringLabels);

		let opt = {
			animationEnabled: true,
			theme: "light2",
			title: {
				text: "Trial Data",
				padding: {
					top: 20
				}
			},
			legend: {
				horizontalAlign: "right"
			},
			axisY: {
				title: selectedAssessmentName,
				includeZero: false
			},
			axisX: {
				title: "Treatment",
				titeFontSize: 20,
				includeZero: false,
				interval: 1
			},
			axisX2: {
				includeZero: false,
				interval: 1
			},
			data: [
				{
					type: "boxAndWhisker",
					color: "black",
					upperBoxColor: "#DAE3F3",
					lowerBoxColor: "#67A2DB",
					indexLabelFontSize: 16,
					dataPoints: dataPoints
				},
				{
					axisXType: "secondary",
					type: "line",
					color: "#00000000",
					showInLegend: !disableColors,
					name: "Replicates",
					dataPoints: letteringLabels
				},
				{
					type: "scatter",
					color: "black",
					markerType: "cross",
					toolTipContent: null,
					dataPoints: allOutliers
				}
			]
		};

		for (let [i, sp] of Object.entries(scatterPlots)) {
			const marker = getNextMarker();
			opt.data.push({
				type: "scatter",
				color: marker.color,
				markerType: marker.shape,
				markerBorderColor: "black",
				markerBorderThickness: 1,
				name: `${i}`,
				toolTipContent: `<b>Plot: </b>{plotName} <br/><b>Treatment: </b>{treatmentNum} <br/><b>Replicate: </b>${i} <br/><b>Value: </b>{y}`,
				showInLegend: !disableColors,
				dataPoints: sp
			});
		}

		setChartLoading(false);
		setChartOptions(opt);
	}

	function updateSelectedAnalysis(value) {
		if (previousAssessmentName === "second run") {
			setNeedToUpdateAnalytics(true);
		}

		if (previousAssessmentName === "first run") {
			setPreviousAssessmentName("second run");
		}

		if (previousAssessmentName === null || previousAssessmentName === undefined) {
			setPreviousAssessmentName("first run");
		}

		setSelectedAnalysisOption(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setSelectedQuantifiedRegionTypeOption(value);
	}

	function updateSelectedAnalysisType(value) {
		setSelectedAnalyticType(value);
	}

	function updateAnalytics() {
		getAnalysisData();
		//updateCanvasData();
		setNeedToUpdateAnalytics(false);
	}

	return loading ? (
		<Loader active inline="centered" />
	) : analysisResults.length === 0 ? (
		<Segment>
			<p>{"We could not find analysis data for this trial."}</p>
		</Segment>
	) : (
		<div>
			<Segment basic>
				<AnalysisDropDownLists
					rawPresetOptions={rawPresetOptions}
					allAssessmentTypes={allAssessmentTypes}
					quantifiedRegionTypes={quantifiedRegionTypeOptions}
					defaultQuantifiedRegion={selectedQuantifiedRegionTypeOption}
					updatedSelectedAnalysisType={updateSelectedAnalysisType}
					updateSelectedAnalysis={updateSelectedAnalysis}
					updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
					setSelectedAssessmentName={setSelectedAssessmentName}
					isApAdmin={userAuth.isApAdmin}
					afterElement={
						<div style={{ display: "grid", paddingLeft: 55, paddingTop: 15 }}>
							<label htmlFor="checkbox-toggle-graph-colors" style={{ display: "unset" }}>
								Disable Graph Colors
							</label>
							<Checkbox
								id="checkbox-toggle-graph-colors"
								value={disableColors}
								onChange={() => setDisableColors(!disableColors)}
								toggle
							/>
						</div>
					}
				>
					{!growthCurveSelected && (
						<div>
							<label htmlFor="dropdown-select-flight" style={{ display: "unset" }}>
								Assessment Date
							</label>
							<Dropdown
								id="dropdown-select-flight"
								width="1"
								selection
								fluid
								search
								options={filteredFlightOptions}
								onChange={(event, { value }) => {
									setSelectedFlightOption(value);
									setNeedToUpdateAnalytics(true);
								}}
								value={selectedFlightOption}
							/>
						</div>
					)}
					<div>
						<label htmlFor="dropdown-select-mean-comparison-value" style={{ display: "unset" }}>
							Mean Comparisons
						</label>
						<Dropdown
							id="dropdown-select-mean-comparison-value"
							width="1"
							selection
							fluid
							options={meanComparisonOptions}
							onChange={(event, { value }) => {
								setSelectedMeanComparisonOption(value);
								setNeedToUpdateAnalytics(true);
							}}
							value={selectedMeanComparisonOption}
						/>
					</div>
					<div>
						<label htmlFor="dropdown-select-alpha" style={{ display: "unset" }}>
							&alpha; Value
						</label>
						<Dropdown
							id="dropdown-select-alpha"
							width="1"
							selection
							fluid
							options={alphaOptions}
							onChange={(event, { value }) => {
								setSelectedAlphaOption(value);
								setNeedToUpdateAnalytics(true);
							}}
							value={selectedAlphaOption}
						/>
					</div>
					<div>
						<label htmlFor="form-assessment" style={{ display: "block" }}>
							Remove Exclusions
						</label>
						<DataAnalysisCriteria
							removeDamagedOrExcluded={removeDamagedOrExcluded}
							removeOutliers={removeOutliers}
							outlierType={outlierType}
							setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
							setRemoveOutliers={setRemoveOutliers}
							setOutlierType={setOutlierType}
							setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
						/>
						<Popup
							trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
							content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
						/>
					</div>
					<div style={{ alignSelf: "end" }}>
						<Button
							primary={!needToUpdateAnalytics}
							negative={needToUpdateAnalytics}
							content="Update Analytics"
							onClick={updateAnalytics}
							disabled={loading} // || tukeyRunning}
							loading={chartLoading}
						/>
					</div>
				</AnalysisDropDownLists>
			</Segment>

			<Segment loading={chartLoading} style={{ marginTop: "60px", paddingBottom: "40px" }}>
				{hasNoData ? <p>{"No Data for this assessment combination."}</p> : <CanvasJSChart options={chartOptions} />}
			</Segment>
		</div>
	);
};

export default AnalysisBoxWhiskerChart;
