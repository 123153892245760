const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getUserLicenses(accessToken) {
	return fetch(`${aerialPlotApi}api/v1/UserLicenses`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function addUserLicense(accessToken, userLicense) {
	return fetch(`${aerialPlotApi}api/v1/UserLicenses`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(userLicense)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateUserLicense(accessToken, userLicense) {
	return fetch(`${aerialPlotApi}api/v1/UserLicenses`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(userLicense)
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function removeUserLicense(accessToken, userLicenseId) {
	return fetch(`${aerialPlotApi}api/v1/UserLicenses?userLicenseId=${userLicenseId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
