import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import { Segment, Step, Icon, Loader } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as protocolActions from "../../../../redux/actions/protocolActions";

import ProtocolInfo from "../ProtocolInfo";
import Applications from "../Applications";
import Treatments from "../Treatments";
import Assessments from "../Assessments";
import AssociateTrials from "../AssociateTrials";
import Summary from "../Summary";

const ProtocolProcess = ({ activeStep, mode }) => {
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	const [step] = useState(activeStep);
	const [loading, setLoading] = useState(true);
	const [failedToLoad, setFailedToLoad] = useState(false);

	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	useEffect(() => {
		if (mode === "new") {
			dispatch(protocolActions.resetProtocolData());
			setLoading(false);
		} else if (
			(reduxProtocolData?.id === null &&
				moduleNavigation.protocolId !== null &&
				moduleNavigation.activeSubmodule.name !== "new") ||
			reduxProtocolData?.id !== moduleNavigation.protocolId
		) {
			getProtocolData();
		} else if (loading) {
			setLoading(false);
		}
	}, []);

	async function getProtocolData() {
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolData(moduleNavigation.protocolId, userAuth.currentClientId, accessToken))
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setFailedToLoad(true);
				setLoading(false);
			});
	}

	return failedToLoad ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>{"Failed to load protocol data. Please try again"}</h2>
		</Segment>
	) : loading ? (
		<Loader active />
	) : (
		<Segment basic id="protocol-process-steps" style={{ marginLeft: 50, paddingBottom: "unset" }}>
			<Step.Group widths={6}>
				<Step
					link
					active={step === "info"}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "info"))}
				>
					<Icon name="folder open" />
					<Step.Content>
						<Step.Title>Protocol Info</Step.Title>
						<Step.Description>Capture metadata and design settings</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "applications"}
					disabled={!moduleNavigation.protocolId || moduleNavigation.activeSubmodule.name === "new"}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "applications"))}
				>
					<Icon name="calendar check outline" />
					<Step.Content>
						<Step.Title>Applications</Step.Title>
						<Step.Description>Capture trial-specific field applications</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "treatments"}
					disabled={
						!moduleNavigation.protocolId ||
						moduleNavigation.activeSubmodule.name === "new" ||
						reduxProtocolData?.applications?.length === 0
					}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "treatments"))}
				>
					<Icon name="list ol" />
					<Step.Content>
						<Step.Title>Treatments</Step.Title>
						<Step.Description>Define specific treatment information</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "assessments"}
					disabled={
						!moduleNavigation.protocolId ||
						moduleNavigation.activeSubmodule.name === "new" ||
						reduxProtocolData?.treatmentData?.length === 0
					}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "assessments"))}
				>
					<Icon name="file alternate outline" />
					<Step.Content>
						<Step.Title>Assessments</Step.Title>
						<Step.Description>Define ground data to be collected</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "associateTrials"}
					disabled={
						!moduleNavigation.protocolId ||
						moduleNavigation.activeSubmodule.name === "new" ||
						reduxProtocolData?.treatmentData?.length === 0
					}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "associate-trials"))}
				>
					<Icon name="linkify" />
					<Step.Content>
						<Step.Title>Associate Trials</Step.Title>
						<Step.Description>Link existing trials for data aggregation</Step.Description>
					</Step.Content>
				</Step>

				<Step
					link
					active={step === "summary"}
					disabled={
						!moduleNavigation.protocolId ||
						moduleNavigation.activeSubmodule.name === "new" ||
						reduxProtocolData?.treatmentData?.length === 0
					}
					onClick={() => history.push(moduleNavigation.createProtocolLink(true, null, "summary"))}
				>
					<Icon name="save" />
					<Step.Content>
						<Step.Title>Summary</Step.Title>
						<Step.Description>Review multi-trial linkages and finalize protocol</Step.Description>
					</Step.Content>
				</Step>
			</Step.Group>
			<>
				<Segment id="flight-process-step-content" style={{ marginTop: "unset", paddingBottom: "unset", minHeight: 70 }}>
					{(() => {
						switch (step) {
							default:
							case "info":
								return <ProtocolInfo mode={moduleNavigation.protocolId && mode !== "new" ? "edit" : "new"} />;
							case "applications":
								return <Applications protocolName={reduxProtocolData?.protocolName} />;
							case "treatments":
								return <Treatments />;
							case "assessments":
								return <Assessments protocolName={reduxProtocolData?.protocolName} />;
							case "associateTrials":
								return (
									<AssociateTrials
										protocolName={reduxProtocolData?.protocolName}
										growingSeasonId={reduxProtocolData?.growingSeasonId}
										cropId={reduxProtocolData?.cropId}
										numberOfTreatments={reduxProtocolData?.treatments}
									/>
								);
							case "summary":
								return <Summary protocolName={reduxProtocolData?.protocolName} />;
						}
					})()}
				</Segment>
			</>
		</Segment>
	);
};

ProtocolProcess.propTypes = {
	activeStep: PropTypes.string.isRequired,
	mode: PropTypes.string.isRequired
};

export default ProtocolProcess;
