import * as uuid from "uuid";

const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;
const statisticsApi = process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl;

export function getCurveModels(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/CurveModels?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getCurveModelData(trialId, curveModelId, clientId, accessToken) {
	return fetch(
		`${statisticsApi}api/v1/Plot/CurveModelData?trialId=${trialId}&curveModelId=${curveModelId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function runCurveModels(trialId, curveModelId, clientId, accessToken) {
	return fetch(
		`${statisticsApi}api/v1/Plot/RunCurveModels?trialId=${trialId}&curveModelId=${curveModelId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function saveCurveAnalysis(
	trialId,
	analysisId,
	analysisTypeId,
	analysisTypeName,
	curveModelId,
	clientId,
	curveModelDataAnalysisId,
	curveModelFitFunctionId,
	selectedFlights,
	curveModelName,
	useGDU,
	quantifiedRegionTypeId,
	accessToken
) {
	const curveAnalysisFormData = new FormData();

	curveAnalysisFormData.set("trialId", trialId ?? "");
	curveAnalysisFormData.set("analysisId", analysisId ?? "");
	curveAnalysisFormData.set("analysisTypeId", analysisTypeId ?? "");
	curveAnalysisFormData.set("analysisTypeName", analysisTypeName ?? "");
	curveAnalysisFormData.set("curveModelDataAnalysisId", curveModelDataAnalysisId ?? "");
	curveAnalysisFormData.set("curveModelId", curveModelId ?? "");
	curveAnalysisFormData.set("curveModelFitFunctionId", curveModelFitFunctionId ?? "");
	curveAnalysisFormData.set("clientId", clientId ?? "");
	curveAnalysisFormData.set("curveModelName", curveModelName ?? "");
	curveAnalysisFormData.set("useGDU", useGDU ?? "");
	curveAnalysisFormData.set("quantifiedRegionTypeId", quantifiedRegionTypeId ?? "");

	let index = 0;
	for (const selectedFlight of selectedFlights) {
		curveAnalysisFormData.append(`SelectedFlights[${index}].id`, selectedFlight.id ?? "");
		curveAnalysisFormData.append(`SelectedFlights[${index}].flightId`, selectedFlight.flightId ?? "");
		curveAnalysisFormData.append(`SelectedFlights[${index}].plantFlight`, selectedFlight.isPlantFlight ?? "");
		curveAnalysisFormData.append(`SelectedFlights[${index}].harvestFlight`, selectedFlight.isHarvestFlight ?? "");
		index++;
	}

	return fetch(`${aerialPlotApi}api/v1/CurveModels/SaveCurveAnalysis`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: curveAnalysisFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteCurveAnalysis(curveModelDataAnalysisId, clientId, accessToken) {
	return fetch(
		`${aerialPlotApi}api/v1/CurveModels/DeleteCurveAnalysis?curveModelDataAnalysisId=${curveModelDataAnalysisId}&clientId=${clientId}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getCurveModelBatchAssessments(trialId, clientId, accessToken) {
	return fetch(
		`${aerialPlotApi}api/v1/CurveModels/GetCurveModelBatchAssessments?trialId=${trialId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function runBatchAssessments(requestData, accessToken) {
	const curveAnalysisFormData = new FormData();

	let requestIndex = 0;
	for (const request of requestData) {
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].trialId`, request.trialId ?? "");
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].analysisId`, request.analysisId ?? "");
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].analysisTypeId`, request.analysisTypeId ?? "");
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].analysisTypeName`, request.analysisTypeName ?? "");
		curveAnalysisFormData.set(
			`CurveModelAnalyses[${requestIndex}].curveModelDataAnalysisId`,
			request.curveModelDataAnalysisId ?? ""
		);
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].curveModelId`, request.curveModelId ?? "");
		curveAnalysisFormData.set(
			`CurveModelAnalyses[${requestIndex}].curveModelFitFunctionId`,
			request.curveModelFitFunctionId ?? ""
		);
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].clientId`, request.clientId ?? "");
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].curveModelName`, request.curveModelName ?? "");
		curveAnalysisFormData.set(`CurveModelAnalyses[${requestIndex}].useGDU`, request.useGDU ?? "");
		curveAnalysisFormData.set(
			`CurveModelAnalyses[${requestIndex}].quantifiedRegionTypeId`,
			request.quantifiedRegionTypeId ?? ""
		);

		let index = 0;
		for (const selectedFlight of request.selectedFlights) {
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].SelectedFlights[${index}].id`,
				selectedFlight.id ?? ""
			);
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].SelectedFlights[${index}].flightId`,
				selectedFlight.flightId ?? uuid.v4() //-- this id will get wiped in the backend
			);
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].SelectedFlights[${index}].plantFlight`,
				selectedFlight.plantFlight ?? false
			);
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].SelectedFlights[${index}].harvestFlight`,
				selectedFlight.harvestFlight ?? false
			);
			index++;
		}

		index = 0;
		for (const treatmentId of request.selectedTreatmentIds) {
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].SelectedTreatments[${index}]`,
				treatmentId ? parseInt(treatmentId) : ""
			);
			index++;
		}

		index = 0;
		for (const auc of request.aucData) {
			curveAnalysisFormData.append(`CurveModelAnalyses[${requestIndex}].AucData[${index}].value`, auc.totalAuc ?? "");
			curveAnalysisFormData.append(`CurveModelAnalyses[${requestIndex}].AucData[${index}].plotId`, auc.plotId ?? "");
			index++;
		}

		index = 0;
		for (const auc of request.otherAucData) {
			curveAnalysisFormData.append(
				`CurveModelAnalyses[${requestIndex}].AucDataGDU[${index}].value`,
				auc.totalAuc ?? ""
			);
			curveAnalysisFormData.append(`CurveModelAnalyses[${requestIndex}].AucDataGDU[${index}].plotId`, auc.plotId ?? "");
			index++;
		}

		requestIndex++;
	}

	return fetch(`${aerialPlotApi}api/v1/CurveModels/RunBatchAssessments`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: curveAnalysisFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function runBatchCurveModels(trialId, clientId, accessToken) {
	return fetch(`${statisticsApi}api/v1/Plot/RunBatchCurveModels?trialId=${trialId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getCurveModelDataResultsForTrial(trialId, curveModelId, clientId, accessToken) {
	return fetch(
		`${statisticsApi}api/v1/Plot/GetCurveModelAnalysisResultsForTrial?clientId=${clientId}&trialId=${trialId}&curveModelId=${curveModelId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}
