import * as types from "./actionTypes";
import * as trialApi from "../../apis/trialApi.ts";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getTrialSearchDataOptionsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_SEARCH_DATA_OPTIONS_SUCCESS };
}

export function getTrialFavoritesSuccess() {
	return { type: types.TRIAL_GET_TRIAL_FAVORITES_SUCCESS };
}

export function getTrialFavoriteGuidsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_FAVORITE_GUIDS_SUCCESS };
}

export function createTrialFavoriteSuccess() {
	return { type: types.TRIAL_CREATE_TRIAL_FAVORITE_SUCCESS };
}

export function deleteTrialFavoriteSuccess() {
	return { type: types.TRIAL_DELETE_TRIAL_FAVORITE_SUCCESS };
}

export function getTrialAnalysisDataOptionsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_ANALYSIS_DATA_OPTIONS_SUCCESS };
}

export function getSummaryTableDataOptionsSuccess() {
	return { type: types.TRIAL_GET_SUMMARY_TABLE_DATA_OPTIONS_SUCCESS };
}

export function searchForTrialsSuccess() {
	return { type: types.TRIAL_SEARCH_FOR_TRIALS_SUCCESS };
}

export function getTrialsInFieldSuccess() {
	return { type: types.TRIAL_GET_TRIALS_IN_FIELD_SUCCESS };
}

export function getRecentlyUpdatedTrialsSuccess() {
	return { type: types.TRIAL_GET_RECENTLY_UPDATED_TRIALS_SUCCESS };
}

export function getRecentlyPublishedTrialsSuccess() {
	return { type: types.TRIAL_GET_RECENTLY_PUBLISHED_TRIALS_SUCCESS };
}

export function getTrialOptionsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_OPTIONS_SUCCESS };
}

export function getTrialOutlineDataSuccess() {
	return { type: types.TRIAL_GET_OUTLINE_DATA_SUCCESS };
}

export function getTrialDataSuccess() {
	return { type: types.TRIAL_GET_TRIAL_DATA_SUCCESS };
}

export function saveAssessmentSelectionForSummaryTableSuccess() {
	return { type: types.TRIAL_SAVE_ASSESSMENT_SELECTION_FOR_SUMMARY_TABLE_SUCCESS };
}

export function updateTrialSuccess() {
	return { type: types.TRIAL_UPDATE_TRIAL_SUCCESS };
}

export function updateTrialTreatmentSuccess() {
	return { type: types.TRIAL_UPDATE_TREATMENTS_SUCCESS };
}

// export function getTrialSoilFertilityDataSuccess() {
// 	return { type: types.TRIAL_GET_SOIL_FERTILITY_SUCCESS };
// }

// export function getTrialSoilFertilityOptionsSuccess() {
// 	return { type: types.TRIAL_GET_SOIL_FERTILITY_OPTIONS_SUCCESS };
// }

// export function createTrialSoilFertilityDataSuccess() {
// 	return { type: types.TRIAL_CREATE_SOIL_FERTILITY_SUCCESS };
// }

// export function updateTrialSoilFertilityDataSuccess() {
// 	return { type: types.TRIAL_UPDATE_SOIL_FERTILITY_SUCCESS };
// }

export function getTrialPlotsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_PLOTS_SUCCESS };
}

export function updateTrialTreatmentsSuccess() {
	return { type: types.TRIAL_UPDATE_TRIAL_TREATMENTS_SUCCESS };
}

export function publishTrialFlightSuccess() {
	return { type: types.TRIAL_PUBLISH_TRIAL_FLIGHT_SUCCESS };
}

export function publishTrialFlightsSuccess() {
	return { type: types.TRIAL_PUBLISH_TRIAL_FLIGHTS_SUCCESS };
}

export function publishTrialFlightToTrialSponsorSuccess() {
	return { type: types.TRIAL_PUBLISH_TRIAL_FLIGHT_TO_TRIAL_SPONSOR_SUCCESS };
}

export function publishTrialFlightsToTrialSponsorSuccess() {
	return { type: types.TRIAL_PUBLISH_TRIAL_FLIGHTS_TO_TRIAL_SPONSOR_SUCCESS };
}

export function unpublishTrialFlightSuccess() {
	return { type: types.TRIAL_UNPUBLISH_TRIAL_FLIGHT_SUCCESS };
}

export function unpublishTrialFlightsSuccess() {
	return { type: types.TRIAL_UNPUBLISH_TRIAL_FLIGHTS_SUCCESS };
}

export function unpublishTrialFlightForTrialSponsorSuccess() {
	return { type: types.TRIAL_UNPUBLISH_TRIAL_FLIGHT_FOR_TRIAL_SPONSOR_SUCCESS };
}

export function unpublishTrialFlightsForTrialSponsorSuccess() {
	return { type: types.TRIAL_UNPUBLISH_TRIAL_FLIGHTS_FOR_TRIAL_SPONSOR_SUCCESS };
}

export function getTrialsPublishedToClientOrUserSuccess() {
	return { type: types.TRIAL_GET_TRIALS_PUBLISHED_TO_CLIENT_OR_USER_SUCCESS };
}

export function savePublishedTrialFlightClientIdAssignmentsSuccess() {
	return { type: types.TRIAL_SAVE_PUBLISHED_TRIAL_FLIGHT_CLIENT_ID_ASSIGNMENTS_SUCCESS };
}

export function getTrialsPublishedByClientSuccess() {
	return { type: types.TRIAL_GET_TRIALS_PUBLISHED_BY_CLIENT_SUCCESS };
}

export function getDefaultViewerSettingForTrialSuccess() {
	return { type: types.TRIAL_GET_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS };
}

export function createDefaultViewerSettingForTrialSuccess() {
	return { type: types.TRIAL_CREATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS };
}

export function updateDefaultViewerSettingForTrialSuccess() {
	return { type: types.TRIAL_UPDATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS };
}

export function setPublishedTrialFlightViewedSuccess() {
	return { type: types.TRIAL_SET_PUBLISHED_TRIAL_FLIGHT_VIEWED_SUCCESS };
}

export function getTrialPublishingDataSuccess() {
	return { type: types.TRIAL_GET_TRIAL_PUBLISHING_DATA_SUCCESS };
}

export function getBulkTrialPublishingDataSuccess() {
	return { type: types.TRIAL_GET_BULK_PUBLISHING_DATA_SUCCESS };
}

export function publishBulkTrialFlightsSuccess() {
	return { type: types.PUBLISH_BULK_TRIAL_FLIGHTS_SUCCESS };
}

export function setTrialOwnerEmailAddressSuccess() {
	return { type: types.TRIAL_SET_TRIAL_OWNER_EMAIL_ADDRESS_SUCCESS };
}

export function setTrialSponsorEmailAddressSuccess() {
	return { type: types.TRIAL_SET_TRIAL_SPONSOR_EMAIL_ADDRESS_SUCCESS };
}

export function sendNewUserEmailSuccess() {
	return { type: types.TRIAL_SEND_NEW_USER_EMAIL_SUCCESS };
}

export function getGduDataForTrialsSuccess() {
	return { type: types.TRIAL_GET_METADATA_FOR_TRIALS_SUCCESS };
}

export function getPlotAnalysisStatusForTrialSuccess() {
	return { type: types.TRIAL_GET_PLOT_ANALYSIS_STATUS_FOR_TRIAL_SUCCESS };
}

export function getMetadataForTrialsSuccess() {
	return { type: types.TRIAL_GET_METADATA_FOR_TRIALS_SUCCESS };
}

export function getAnnotationSummaryForTrialSuccess() {
	return { type: types.TRIAL_GET_ANNOTATION_SUMMARY_FOR_TRIAL_SUCCESS };
}

export function getTrialMetaTagOptionsSuccess() {
	return { type: types.TRIAL_GET_TRIAL_META_TAG_OPTIONS_SUCCESS };
}

export function removeTrialOwnerSuccess() {
	return { type: types.TRIAL_REMOVE_TRIAL_OWNER_SUCCESS };
}

export function removeTrialSponsorSuccess() {
	return { type: types.TRIAL_REMOVE_TRIAL_SPONSOR_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getTrialSearchDataOptions(clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialSearchDataOptions(clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialSearchDataOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialFavorites(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialFavorites(clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialFavoritesSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialFavoriteGuids(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialFavoriteGuids(clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialFavoriteGuidsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getRecentlyPublishedTrials(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getRecentlyPublishedTrials(clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getRecentlyPublishedTrialsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createTrialFavorite(clientId, accessToken, trialId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.createTrialFavorite(clientId, accessToken, trialId)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createTrialFavoriteSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteTrialFavorite(clientId, accessToken, trialId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.deleteTrialFavorite(clientId, accessToken, trialId)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(deleteTrialFavoriteSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialAnalysisDataOptions(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialAnalysisDataOptions(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialAnalysisDataOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getSummaryTableDataOptions(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getSummaryTableDataOptions(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getSummaryTableDataOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function searchForTrials(searchParams, clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.searchForTrials(searchParams, clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(searchForTrialsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialsInField(fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialsInField(fieldId, clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialsInFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getRecentlyUpdatedTrials(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getRecentlyUpdatedTrials(clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getRecentlyUpdatedTrialsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialOptions(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialOptions(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialOutlineData(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialOutlineData(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialOutlineDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialData(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialData(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveAssessmentSelectionForSummaryTable(summaryTableSelectionData, accessToken, clientId, trialId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.saveAssessmentSelectionForSummaryTable(summaryTableSelectionData, accessToken, clientId, trialId)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(saveAssessmentSelectionForSummaryTableSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateTrial(trial, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.updateTrial(trial, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(updateTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function publishTrialFlight(clientId, trialId, flightId, trialOwnerUserId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.publishTrialFlight(clientId, trialId, flightId, trialOwnerUserId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(publishTrialFlightSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function publishTrialFlights(
	clientId,
	trialId,
	trialName,
	flightIds,
	trialOwnerUserId,
	flightScheduleId,
	doNotPublishTrialFlights,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.publishTrialFlights(
					clientId,
					trialId,
					trialName,
					flightIds,
					trialOwnerUserId,
					flightScheduleId,
					doNotPublishTrialFlights,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(publishTrialFlightsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function publishTrialFlightToTrialSponsor(clientId, trialId, flightId, trialSponsorUserId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.publishTrialFlightToTrialSponsor(clientId, trialId, flightId, trialSponsorUserId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(publishTrialFlightToTrialSponsorSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function publishTrialFlightsToTrialSponsor(
	clientId,
	trialId,
	trialName,
	flights,
	trialSponsorUserId,
	flightScheduleId,
	doNotPublishTrialFlights,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.publishTrialFlightsToTrialSponsor(
					clientId,
					trialId,
					trialName,
					flights,
					trialSponsorUserId,
					flightScheduleId,
					doNotPublishTrialFlights,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(publishTrialFlightsToTrialSponsorSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function unpublishTrialFlights(clientId, trialId, flightIds, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.unpublishTrialFlights(clientId, trialId, flightIds, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(unpublishTrialFlightsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function unpublishTrialFlight(clientId, trialId, flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.unpublishTrialFlight(clientId, trialId, flightId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(unpublishTrialFlightSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function unpublishTrialFlightsForTrialSponsor(clientId, trialId, flightIds, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.unpublishTrialFlightsForTrialSponsor(clientId, trialId, flightIds, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(unpublishTrialFlightsForTrialSponsorSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function unpublishTrialFlightForTrialSponsor(clientId, trialId, flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.unpublishTrialFlightForTrialSponsor(clientId, trialId, flightId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(unpublishTrialFlightForTrialSponsorSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialsPublishedToClientOrUser(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialsPublishedToClientOrUser(clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialsPublishedToClientOrUserSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function savePublishedTrialFlightClientIdAssignments(assignments, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.savePublishedTrialFlightClientIdAssignments(assignments, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(savePublishedTrialFlightClientIdAssignmentsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialsPublishedByClient(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialsPublishedByClient(clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialsPublishedByClientSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getDefaultViewerSettingForTrial(trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getDefaultViewerSettingForTrial(trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getDefaultViewerSettingForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createDefaultViewerSettingForTrial(
	clientId,
	trialId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.createDefaultViewerSettingForTrial(
					clientId,
					trialId,
					toggleHeatmapData,
					toggleImageType,
					viewableFlightId,
					viewableOrthoImageTypeId,
					groundDatasetId,
					groundDataAssessmentId,
					curveModelAnalysisId,
					analysisIdForCurveModel,
					analysisId,
					analysisFlightId,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(createDefaultViewerSettingForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateDefaultViewerSettingForTrial(
	clientId,
	id,
	trialId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.updateDefaultViewerSettingForTrial(
					clientId,
					id,
					trialId,
					toggleHeatmapData,
					toggleImageType,
					viewableFlightId,
					viewableOrthoImageTypeId,
					groundDatasetId,
					groundDataAssessmentId,
					curveModelAnalysisId,
					analysisIdForCurveModel,
					analysisId,
					analysisFlightId,
					accessToken
				)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(updateDefaultViewerSettingForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function setPublishedTrialFlightViewed(qsPtfk, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.setPublishedTrialFlightViewed(qsPtfk, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(setPublishedTrialFlightViewedSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialPublishingData(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialPublishingData(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getTrialPublishingDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getBulkTrialPublishingData(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getBulkTrialPublishingData(clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getBulkTrialPublishingDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function publishBulkTrialFlights(clientId, filteredBulkTrialData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.publishBulkTrialFlights(clientId, filteredBulkTrialData, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(publishBulkTrialFlightsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function setTrialOwnerEmailAddress(
	emailAddress,
	trialId,
	clientId,
	trialOwnerClientId,
	trialOwnerChangingTrialOwner,
	publishedToTrialOwner,
	fieldResearcherUserId,
	trialSponsorUserId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.setTrialOwnerEmailAddress(
					emailAddress,
					trialId,
					clientId,
					trialOwnerClientId,
					trialOwnerChangingTrialOwner,
					publishedToTrialOwner,
					fieldResearcherUserId,
					trialSponsorUserId,
					accessToken
				)
				.then((res) => {
					dispatch(setTrialOwnerEmailAddressSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function setTrialSponsorEmailAddress(
	emailAddress,
	trialId,
	clientId,
	trialSponsorClientId,
	trialSponsorChangingTrialSponsor,
	publishedToTrialSponsor,
	fieldResearcherUserId,
	trialOwnerUserId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.setTrialSponsorEmailAddress(
					emailAddress,
					trialId,
					clientId,
					trialSponsorClientId,
					trialSponsorChangingTrialSponsor,
					publishedToTrialSponsor,
					fieldResearcherUserId,
					trialOwnerUserId,
					accessToken
				)
				.then((res) => {
					dispatch(setTrialSponsorEmailAddressSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function sendNewUserEmail(emailAddress, clientName, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.sendNewUserEmail(emailAddress, clientName, clientId, accessToken)
				.then((res) => {
					dispatch(sendNewUserEmailSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

// export function getSoilFertilityOptions(clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall);

// 			return trialApi
// 				.getSoilFertilityOptions(clientId, accessToken)
// 				.then((res) => {
// 					dispatch(getTrialSoilFertilityOptionsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function getSoilFertilityDataForTrial(trialId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall);

// 			return trialApi
// 				.getSoilFertilityDataForTrial(trialId, clientId, accessToken)
// 				.then((res) => {
// 					dispatch(getTrialSoilFertilityDataSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function createSoilFertilityDataFromTrial(soilFertility, trialId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall);

// 			return trialApi
// 				.createSoilFertilityDataFromTrial(soilFertility, trialId, clientId, accessToken)
// 				.then((res) => {
// 					dispatch(createTrialSoilFertilityDataSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function updateSoilFertilityData(soilFertility, soilFertilityId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall);

// 			return trialApi
// 				.updateSoilFertilityData(soilFertility, soilFertilityId, clientId, accessToken)
// 				.then((res) => {
// 					dispatch(updateTrialSoilFertilityDataSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

export function getTrialPlotsForMapping(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall);

			return trialApi
				.getTrialPlotsForMapping(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getTrialPlotsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getMetadataForTrials(trials, currentClientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getMetadataForTrials(trials, currentClientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getMetadataForTrialsSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPlotAnalysisStatusForTrial(
	trialId,
	clientId,
	accessToken,
	analysisId = undefined,
	curveModelAnalysisId = undefined,
	checkFlights = true,
	checkCurveModels = true
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getPlotAnalysisStatusForTrial(
					trialId,
					clientId,
					analysisId,
					curveModelAnalysisId,
					checkFlights,
					checkCurveModels,
					accessToken
				)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getPlotAnalysisStatusForTrialSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateTrialTreatments(clientId, treatments, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.updateTrialTreatments(clientId, treatments, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(updateTrialTreatmentsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnnotationSummaryForTrial(trialId, currentClientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getAnnotationSummaryForTrial(trialId, currentClientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getAnnotationSummaryForTrialSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialMetaTagOptions(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.getTrialMetaTagOptions(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getTrialMetaTagOptionsSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function removeTrialOwner(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.removeTrialOwner(trialId, clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(removeTrialOwnerSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function removeTrialSponsor(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return trialApi
				.removeTrialSponsor(trialId, clientId, accessToken)
				.then((res) => {
					if (res.errors && res.errors.length > 0) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(removeTrialSponsorSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
