import * as types from "./actionTypes";
import * as clientLogoApi from "../../apis/clientLogoApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getLogoUrlSuccess(uri) {
	return { type: types.CLIENT_LOGOS_GET_LOGO_URL_SUCCESS, clientLogoUri: uri };
}

export function uploadClientLogoSuccess() {
	return { type: types.CLIENT_LOGOS_UPLOAD_CLIENT_LOGO_SUCCESS };
}

export function deleteClientLogoSuccess() {
	return { type: types.CLIENT_LOGOS_DELETE_CLIENT_LOGO_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getClientLogoUrl(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientLogoApi
				.getLogoUrl(clientId, accessToken)
				.then((res) => {
					dispatch(getLogoUrlSuccess(res));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function uploadClientLogo(formFile, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientLogoApi
				.uploadClientLogo(formFile, clientId, accessToken)
				.then((res) => {
					dispatch(uploadClientLogoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteClientLogo(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return clientLogoApi
				.deleteClientLogo(clientId, accessToken)
				.then((res) => {
					dispatch(deleteClientLogoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
