import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAuth0 } from "../auth0";
import { useModuleNavigation } from "../../hooks/useModuleNavigation";
//import { useUserAuth } from "../../../hooks/useUserAuth";
import { useUserAuth } from "../../hooks/useUserAuth";
import { Segment } from "semantic-ui-react";

const SubscriptionExpired = () => {
	const { isAuthenticated } = useAuth0();
	const userAuth = useUserAuth();

	const user = useSelector((state) => (state.user ? state.user : null));

	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		//-- If the user is logged in and manually goes to /setup-needed, redirect them to the home page b/c they weren't trying to access any other part of the app
		if (!isAuthenticated || !user || !user.userInfo || userAuth.isSubsriptionActive) {
			moduleNavigation.goHome();
		}
	}, [userAuth.currentClient]);

	return userAuth.isSubsriptionActive ? null : (
		<Segment style={{ marginTop: 15 }} textAlign="center">
			<h1>aerialPLOT Subscription Expired</h1>
			<br />
			<p>
				{`Your ${userAuth?.currentClient?.clientName} subscription has expired. Please contact aerialPLOT support at`}{" "}
				<a href="mailto:support@aerialplot.com">support@aerialplot.com</a>
			</p>
			<br />
		</Segment>
	);
};

export default SubscriptionExpired;
