const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getConfigureUasesData(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Uas/GetConfigureUasesData?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getUasesDetails(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Uas/GetUasesDetails?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getSensors(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Uas/GetSensors?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getUavs(accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Uas/GetUavs`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createUas(accessToken, uas) {
	const uasFormData = new FormData();

	for (const name in uas) {
		let val = uas[name] === "" ? null : uas[name];

		if (name !== "sensors") {
			if (val) {
				uasFormData.set(name, val);
			}
		} else {
			let index = 0;
			for (const rId of uas.sensors) {
				uasFormData.append(`Sensors[${index}]`, rId);
				index++;
			}
		}
	}

	return fetch(`${aerialPlotApi}api/v1/UAS`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: uasFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateUas(accessToken, uas) {
	const uasFormData = new FormData();

	for (const name in uas) {
		let val = uas[name] === "" ? null : uas[name];

		if (name !== "sensors") {
			if (val) {
				uasFormData.set(name, val);
			}
		} else {
			let index = 0;
			for (const rId of uas.sensors) {
				uasFormData.append(`Sensors[${index}]`, rId);
				index++;
			}
		}
	}

	return fetch(`${aerialPlotApi}api/v1/UAS`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: uasFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteUas(accessToken, uasId, clientId) {
	return fetch(`${aerialPlotApi}api/v1/UAS?uasId=${uasId}&clientId=${clientId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
