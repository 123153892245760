// @flow weak

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
	position: "absolute",
	width: "100%",
	height: 40,
	transform: "translate(0%, -50%)",
	cursor: "pointer",
	zIndex: 1
};

const railInnerStyle = {
	position: "absolute",
	width: "100%",
	height: 2,
	transform: "translate(0%, -50%)",
	borderRadius: 4,
	pointerEvents: "none",
	backgroundColor: "rgb(155,155,155)"
};

export function SliderRail({ getRailProps }) {
	return (
		<Fragment>
			<div style={railOuterStyle} {...getRailProps()} />
			<div style={railInnerStyle} />
		</Fragment>
	);
}

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired
};
import "rc-tooltip/assets/bootstrap.css";
// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
	return (
		<Fragment>
			<div
				style={{
					left: `${percent}%`,
					position: "absolute",
					transform: "translate(-50%, -50%)",
					WebkitTapHighlightColor: "rgba(0,0,0,0)",
					zIndex: 5,
					width: 24,
					height: 42,
					cursor: "pointer",
					backgroundColor: "none"
				}}
				{...getHandleProps(id)}
			/>
			<div
				role="slider"
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				style={{
					left: `calc(${percent}% + 3px)`,
					position: "absolute",
					transform: "translate(-50%, -50%)",
					width: 20,
					height: 20,
					borderRadius: "50%",
					backgroundColor: disabled ? "#666666" : "#000000"
				}}
			/>
		</Fragment>
	);
}

Handle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Handle.defaultProps = {
	disabled: false
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
	return (
		<div
			style={{
				position: "absolute",
				transform: "translate(0%, -50%)",
				height: 2,

				backgroundColor: disabled ? "#999" : "rgb(0, 0, 0)",
				borderRadius: 4,
				cursor: "pointer",
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`
			}}
			{...getTrackProps()}
		/>
	);
}

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Track.defaultProps = {
	disabled: false
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, format }) {
	return (
		<>
			<div>
				<div
					style={{
						position: "absolute",
						marginTop: -5,
						width: 10,
						height: 10,
						backgroundColor: tick.color,
						borderRadius: 10,
						left: `calc(${tick.percent}% - 2px)`
					}}
				/>
				<Tooltip
					placement="bottom"
					mouseEnterDelay={0}
					mouseLeaveDelay={0}
					trigger="hover"
					overlay={<div>{tick.tooltip}</div>}
					transitionName="rc-tooltip-zoom"
				>
					<div
						style={{
							position: "absolute",
							marginTop: 15 * tick.marginTopMultiplier,
							fontSize: 10,
							textAlign: "left",
							fontFamily: "Arial, san-serif",
							marginLeft: "-1%",
							width: "fit-content",
							left: `${tick.percent}%`,
							cursor: "default",
							minWidth: "3%"
						}}
					>
						{format(tick.value)}
					</div>
				</Tooltip>
			</div>
		</>
	);
}

Tick.propTypes = {
	tick: PropTypes.shape({
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
		marginTopMultiplier: PropTypes.number.isRequired,
		tooltip: PropTypes.object
	}).isRequired,
	count: PropTypes.number.isRequired,
	format: PropTypes.func.isRequired,
	color: PropTypes.string.isRequired
};

Tick.defaultProps = {
	format: (d) => d
};
