import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import { Form, Button, Icon, Popup, Modal, Header, Loader, Segment, Message, Input } from "semantic-ui-react";

import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useHistory } from "react-router-dom";

import UnitConverter from "../../../Lumber/UnitConverter";

import * as fieldActions from "../../../../redux/actions/fieldActions";

import { validateForm } from "./formValidation";
import "./style.css";
import DeleteConfirmationModal from "../FieldWizard/deleteModal";

const NewFieldForm = ({
	setFieldId,
	fieldId,
	fromViewer,
	handleCancel,
	handleFinish,
	handleSave,
	setActiveStep,
	navigateToNextStep,
	setModeState,
	mode
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();

	//-- Data source
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Dropdown options
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [cropOptions, setCropOptions] = useState([]);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	const [farms, setFarms] = useState([]);

	//-- Field data
	const [field, setField] = useState({
		name: "",
		growingSeasonId: "select",
		farmId: "select",
		cropId: "select",
		plantDate: null,
		harvestDate: null,
		numOfColumns: "",
		numOfRanges: "",
		rowsPerPlot: "4",
		rowSpacing: "30",
		alleyLength: "5",
		passesPerPlot: "1",
		planterOffset: "0",
		clientId: null,
		columnWidth: "10",
		rangeLength: "45",
		cooperatorCompanyId: null
	});

	const [calculatedColWidth, setCalculatedColWidth] = useState(10);
	const [calculatedColWidthModalOpen, setCalculatedColWidthModalOpen] = useState(false);

	//-- Validation
	const [formError, setFormError] = useState(false);
	const [validation, setValidation] = useState({});

	//-- UI Control
	const [fieldLoading, setFieldLoading] = useState(true);
	const [infoLoading, setInfoLoading] = useState(true);
	const [canEditFieldDimensions, setCanEditFieldDimensions] = useState(mode === "new" ? true : false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	// const [modeState, updateModeState] = useState(mode);
	// const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	// useEffect(() => {
	// 	if (mode) {
	// 		updateModeState(mode);
	// 	}
	// }, [mode]);

	useEffect(() => {
		if (clientId) {
			getOptionData(clientId);

			setField((field) => ({ ...field, clientId: clientId }));

			if (!_.isEmpty(fieldId) && mode === "edit") {
				getField();
			} else {
				setFieldLoading(false);
			}
		}
	}, [clientId]);

	// useEffect(() => {
	// 	if (!_.isEmpty(fieldId) && mode === "edit") {
	// 		getField();
	// 	} else {
	// 		setFieldLoading(false);
	// 	}
	// }, []);

	async function getOptionData(clientId) {
		const accessToken = await getTokenSilently();
		setInfoLoading(true);
		dispatch(fieldActions.getFieldInfo(clientId, moduleNavigation.flightId, accessToken))
			.then((res) => {
				setInitialGrowingSeasonOptions(res.growingSeasons);
				setInitialCropOptions(res.crops);
				setInitialFarmOptions(res.farms);
				setInitialCooperatorCompanyOptions(res.cooperatorCompanies, res.farms);
				setInfoLoading(false);
			})
			.catch(() => {
				toast.error("Error loading field info. Please try again.");
				setInfoLoading(false);
			});
	}

	const setInitialGrowingSeasonOptions = (growingSeasonResults) => {
		if (growingSeasonOptions) {
			setGrowingSeasonOptions(
				[{ key: "select", value: "select", text: "Select" }].concat(
					_.map(growingSeasonResults, ({ growingSeasonId, name }) => {
						const opt = {
							key: growingSeasonId,
							value: growingSeasonId,
							text: name
						};
						return opt;
					})
				)
			);
		}
	};

	const setInitialCropOptions = (cropResults) => {
		if (cropOptions) {
			setCropOptions(
				[{ key: "select", value: "select", text: "Select" }].concat(
					_.map(cropResults, ({ cropId, name }) => {
						const opt = { key: cropId, value: cropId, text: name };
						return opt;
					})
				)
			);
		}
	};

	const setInitialFarmOptions = (farmResults) => {
		if (farmOptions) {
			setFarms(farmResults);
			setFarmOptions(
				[{ key: "select", value: "select", text: "Select" }].concat(
					_.map(farmResults, ({ farmId, name }) => {
						const opt = { key: farmId, value: farmId, text: name };
						return opt;
					})
				)
			);
		}
	};

	const setInitialCooperatorCompanyOptions = (cooperatorCompanyResults, farmResults) => {
		if (cooperatorCompanyOptions) {
			let existingCooperatorCompanyIdsInFarms = _.uniq(
				_.map(
					_.filter(farmResults, (fr) => fr.cooperatorCompanyId),
					"cooperatorCompanyId"
				)
			);
			setCooperatorCompanyOptions(
				[{ key: "select", value: "select", text: "Select" }].concat(
					_.map(
						_.filter(cooperatorCompanyResults, (ccr) => existingCooperatorCompanyIdsInFarms.includes(ccr.id)),
						({ id, name }) => {
							return { key: id, value: id, text: name };
						}
					)
				)
			);
		}
	};

	async function getField(newFieldId = null) {
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getField(newFieldId ?? fieldId, accessToken))
			.then((res) => {
				if (res.clientId === clientId) {
					res.harvestDate = res.harvestDate ? new Date(res.harvestDate) : null;
					res.plantDate = res.plantDate ? new Date(res.plantDate) : null;
					setField(res);
					setCanEditFieldDimensions(res.canEditFieldDimensions && !(fieldId && fromViewer));
					setFieldLoading(false);
				}
			})
			.catch(() => {
				toast.error("Failed to load field");
				setField(null);
				setFieldLoading(false);
			});
	}

	useEffect(() => {
		if (growingSeasonOptions && growingSeasonOptions.length === 2) {
			setField((field) => ({
				...field,
				growingSeasonId: growingSeasonOptions[1].value
			}));
		}
	}, [growingSeasonOptions]);

	useEffect(() => {
		if (!field.cropId && !_.isEmpty(cropOptions)) {
			setField((field) => ({ ...field, cropId: cropOptions[0].value }));
		}
	}, [cropOptions]);

	useEffect(() => {
		if (farmOptions && farmOptions.length === 2) {
			setField((field) => ({ ...field, farmId: farmOptions[1].value }));
		}
	}, [farmOptions]);

	async function saveField(continueToNextPage, finish, skipColumnWidth = false) {
		let calcValid = true;

		if (!skipColumnWidth) {
			calcValid = recalculateColumnWidth();
		}

		const formValid = validateForm(field);
		setValidation(formValid);
		setFormError(formValid.error);
		if (formValid.error || !calcValid) {
			return;
		}
		const accessToken = await getTokenSilently();
		setIsSaving(true);
		dispatch(fieldActions.saveNewField(field, accessToken))
			.then((res) => {
				toast.success("Field saved successfully");
				if (handleSave != null) {
					field.crop = _.find(cropOptions, { key: field.cropId }).text;
					field.city = _.find(farms, { farmId: field.farmId }).city;
					field.state = _.find(farms, { farmId: field.farmId }).state;
					handleSave(res, field);
				}
				setIsSaving(false);
				setFieldId(res.fieldId);

				if (continueToNextPage) {
					setModeState("edit");
					navigateToNextStep(res.fieldId);
				} else if (finish) {
					handleFinish(res.fieldId);
				} else {
					setModeState("edit");
					getField(res.fieldId);
				}
			})
			.catch((error) => {
				if (error.errors && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				} else {
					toast.error("Field failed to save field");
				}

				setIsSaving(false);
			});
	}

	async function editField(continueToNextPage, finish, skipColumnWidth = false) {
		let calcValid = true;

		if (!skipColumnWidth) {
			calcValid = recalculateColumnWidth();
		}

		const formValid = validateForm(field);
		setValidation(formValid);
		setFormError(formValid.error);
		if (formValid.error || !calcValid) {
			return;
		}
		const accessToken = await getTokenSilently();
		setIsSaving(true);
		dispatch(fieldActions.editField(field, accessToken))
			.then(() => {
				handleSave(null, field);
				toast.success("Field saved successfully");
				setIsSaving(false);

				if (continueToNextPage) {
					navigateToNextStep();
				} else if (finish) {
					setActiveStep("");
					handleFinish(fieldId);
				}
			})
			.catch((error) => {
				if (error.errors && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				} else {
					toast.error("Field failed to update");
				}

				setIsSaving(false);
			});
	}

	async function deleteField() {
		const accessToken = await getTokenSilently();
		setIsDeleting(true);
		dispatch(fieldActions.deleteField(fieldId, clientId, accessToken))
			.then(() => {
				toast.success("Field deleted successfully");
				setIsDeleting(false);

				if (fromViewer) {
					handleCancel();
				} else {
					history.push("/fields");
				}
			})
			.catch(() => {
				toast.error("Field failed delete");
				setIsDeleting(false);
			});
	}

	function recalculateColumnWidth() {
		if (
			field &&
			!isNaN(field.rowSpacing) &&
			!isNaN(field.rowsPerPlot) &&
			!isNaN(field.planterOffset) &&
			!isNaN(field.passesPerPlot)
		) {
			let actualWidth =
				(Number(field.rowSpacing) * Number(field.rowsPerPlot) +
					Number(field.planterOffset) * Number(field.passesPerPlot)) /
				12;

			actualWidth = Number(actualWidth.toFixed(8));

			setCalculatedColWidth(actualWidth);

			if (actualWidth !== Number(Number(field.columnWidth).toFixed(8))) {
				setCalculatedColWidthModalOpen(true);
				return false;
			}

			return true;
		}
	}

	return infoLoading || fieldLoading ? (
		<Loader active />
	) : !infoLoading && !fieldLoading && !field ? (
		<Segment basic>
			<p>{"This field could not be found."}</p>
		</Segment>
	) : (
		<Segment basic>
			<Form error={formError}>
				{mode === "edit" && !fromViewer ? (
					<DeleteConfirmationModal
						handleDeleteField={() => deleteField()}
						floated="right"
						isDeleting={isDeleting}
						disabled={isSaving || isDeleting}
						loading={isDeleting}
					/>
				) : null}

				<Button
					id="form-button-save"
					floated="right"
					color="blue"
					content={"Save"}
					onClick={() => {
						mode === "new" ? saveField(false, false) : editField(false, false);
					}}
					disabled={isSaving || isDeleting}
					loading={isSaving}
				/>
				<Button
					id="form-button-save-and-continue"
					floated="right"
					color="green"
					content={"Save & Continue"}
					onClick={() => {
						mode === "new" ? saveField(true, false) : editField(true, false);
					}}
					disabled={isSaving || isDeleting}
					loading={isSaving}
				/>
				{fromViewer && (
					<Button
						id="form-button-cancel"
						type="button"
						floated="right"
						disabled={isSaving || isDeleting}
						onClick={() => {
							setIsSaving(true);
							handleCancel();
						}}
					>
						Cancel
					</Button>
				)}
				<Form.Field width="10" style={{ marginBottom: 10 }}>
					<Message error list={validation.errorList} />
				</Form.Field>
				<Form.Group>
					<Form.Input
						label="Name*"
						id="form-input-name"
						width="6"
						value={field.name}
						error={validation.nameError}
						onChange={(e, { value }) => {
							setField((field) => ({ ...field, name: value }));
						}}
					/>
					<Form.Select
						label="Growing Season*"
						width="6"
						search
						options={growingSeasonOptions}
						value={field.growingSeasonId}
						error={validation.growingSeasonError}
						onChange={(e, { value }) => {
							setField((field) => ({ ...field, growingSeasonId: value }));
						}}
						loading={!growingSeasonOptions || growingSeasonOptions.length === 0}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Select
						label="Farm*"
						width="6"
						id="form-select-farm"
						search
						options={
							field?.cooperatorCompanyId
								? _.filter(farmOptions, (fo) => {
										let matchingFarm = _.find(farms, { farmId: fo.key });
										return field.cooperatorCompanyId === matchingFarm?.cooperatorCompanyId;
								  })
								: farmOptions
						}
						value={field.farmId}
						error={validation.farmError}
						onChange={(e, { value }) => {
							setField((field) => ({ ...field, farmId: value }));
						}}
						loading={!farmOptions || farmOptions.length === 0}
					/>
					<Form.Select
						label="Cooperator Company*"
						width="6"
						id="form-select-cooperator-company"
						search
						options={cooperatorCompanyOptions}
						value={field.cooperatorCompanyId}
						error={validation.cooperatorCompanyError}
						onChange={(e, { value }) => {
							if (value === "select") {
								setField((field) => ({ ...field, cooperatorCompanyId: null }));
							} else {
								let matchingFarmIds = _.map(
									_.filter(farms, (f) => f.cooperatorCompanyId === value),
									"farmId"
								);
								if (matchingFarmIds.length > 0 && !matchingFarmIds.includes(field?.farmId)) {
									setField((field) => ({ ...field, cooperatorCompanyId: value, farmId: matchingFarmIds[0] }));
								} else {
									setField((field) => ({ ...field, cooperatorCompanyId: value }));
								}
							}
						}}
						loading={!cropOptions || cropOptions.length === 0}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Select
						label="Crop*"
						width="6"
						id="form-select-crop"
						search
						options={cropOptions}
						value={field.cropId}
						error={validation.cropError}
						onChange={(e, { value }) => {
							setField((field) => ({ ...field, cropId: value }));
						}}
						loading={!cropOptions || cropOptions.length === 0}
					/>
					<Form.Field
						id="form-date-time-picker-plant-date"
						width="3"
						control={DateTimePicker}
						label="Plant Date"
						includeTime={false}
						value={field.plantDate}
						error={validation.plantDateError}
						onChange={(date) => {
							if (date) {
								date.setHours(0, 0, 0, 0);
							}
							setField((field) => ({ ...field, plantDate: date }));
						}}
					/>
					<Form.Field
						id="form-date-time-picker-harvest-date"
						width="3"
						control={DateTimePicker}
						label="Harvest Date"
						includeTime={false}
						value={field.harvestDate}
						error={validation.harvestDateError}
						onChange={(date) => {
							if (date) {
								date.setHours(0, 0, 0, 0);
							}
							setField((field) => ({ ...field, harvestDate: date }));
						}}
					/>
				</Form.Group>
				<Form.Group></Form.Group>
				<Form.Group>
					<Form.Field width="3">
						<label style={{ display: "unset" }}>Number of Columns*</label>
						<Form.Input
							id="form-input-number-of-columns"
							value={field.numOfColumns}
							readOnly={!canEditFieldDimensions}
							type="number"
							error={validation.numOfColumnsError}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, numOfColumns: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="3" />
					<Form.Field width="3">
						<label style={{ display: "unset" }}>Number of Ranges*</label>
						<Form.Input
							id="form-input-number-of-ranges"
							value={field.numOfRanges}
							readOnly={!canEditFieldDimensions}
							type="number"
							error={validation.numOfRangesError}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, numOfRanges: value }));
							}}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width="3" error={validation.columnWidthError || validation.columnWidthBoundsError}>
						<label style={{ display: "unset" }}>Column Width*</label>
						<Popup
							content="Width of each column in feet"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							id="form-input-column-width"
							label={{ basic: true, content: "ft." }}
							labelPosition="right"
							value={field.columnWidth}
							readOnly={!canEditFieldDimensions}
							type="number"
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, columnWidth: value }));
							}}
						/>
						<Modal open={calculatedColWidthModalOpen}>
							<Modal.Header>WARNING!</Modal.Header>
							<Modal.Content>
								<Modal.Description>
									<Header>
										The entered Column Width ({field.columnWidth}) does not match the projected Column Width (
										{calculatedColWidth})
									</Header>
									<p>
										Accepting the entered Column Width will result in this field being drawn incorrectly on flights.
									</p>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button
									type="button"
									primary
									onClick={() => {
										if (mode === "new") {
											saveField(false, false, true);
										} else {
											editField(false, false, true);
										}
										setCalculatedColWidthModalOpen(false);
									}}
									floated="right"
									style={{ marginBottom: 10 }}
								>
									Accept
								</Button>
								<Button
									type="button"
									onClick={() => setCalculatedColWidthModalOpen(false)}
									floated="right"
									style={{ marginBottom: 10 }}
								>
									Change
								</Button>
								<br style={{ clear: "both" }} />
							</Modal.Actions>
						</Modal>
					</Form.Field>
					<Form.Field width="3" style={{ marginTop: "auto" }}>
						<UnitConverter
							hidden={canEditFieldDimensions === false}
							defaults={{
								unitType: "length",
								unit1: "m",
								unit2: "ft",
								unit1Value: 0,
								unit2Value: Number(field.columnWidth)
							}}
							userConfigurable={false}
							changeUnit1={true}
							twoWayConversion={false}
							onUnitConverted={(data) => setField((field) => ({ ...field, columnWidth: data.toValue }))}
						/>
					</Form.Field>
					<Form.Field width="3" error={validation.rangeLengthError}>
						<label style={{ display: "unset" }}>Range Length*</label>
						<Popup
							content="Length of each range in feet"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							id="form-input-range-length"
							label={{ basic: true, content: "ft." }}
							labelPosition="right"
							value={field.rangeLength}
							readOnly={!canEditFieldDimensions}
							type="number"
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, rangeLength: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="3" style={{ marginTop: "auto" }}>
						<UnitConverter
							hidden={canEditFieldDimensions === false}
							defaults={{
								unitType: "length",
								unit1: "m",
								unit2: "ft",
								unit1Value: 0,
								unit2Value: Number(field.rangeLength)
							}}
							userConfigurable={false}
							changeUnit1={true}
							twoWayConversion={false}
							onUnitConverted={(data) => setField((field) => ({ ...field, rangeLength: data.toValue }))}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field style={{ width: "135px" }}>
						<label style={{ display: "unset" }}>Rows per Plot*</label>
						<Form.Input
							id="form-input-rows-per-plot"
							value={field.rowsPerPlot}
							type="number"
							readOnly={!canEditFieldDimensions}
							error={validation.rowsPerPlotError}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, rowsPerPlot: value }));
							}}
						/>
					</Form.Field>
					<Form.Field style={{ width: "135px" }}>
						<label style={{ display: "unset" }}>Passes per Plot*</label>
						<Popup
							content="The number of passes the planter performs on the plot"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Form.Input
							id="form-input-passes-per-plot"
							value={field.passesPerPlot}
							type="number"
							readOnly={!canEditFieldDimensions}
							error={validation.passesPerPlotError}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, passesPerPlot: value }));
							}}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width="3" error={validation.rowSpacingError}>
						<label style={{ display: "unset" }}>Row Spacing*</label>
						<Popup
							content="The space between plots in inches"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							id="form-input-row-spacing"
							label={{ basic: true, content: "in." }}
							labelPosition="right"
							value={field.rowSpacing}
							type="number"
							readOnly={!canEditFieldDimensions}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, rowSpacing: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="3" style={{ marginTop: "auto" }}>
						<UnitConverter
							hidden={canEditFieldDimensions === false}
							defaults={{
								unitType: "length",
								unit1: "cm",
								unit2: "in",
								unit1Value: 0,
								unit2Value: Number(field.rowSpacing)
							}}
							userConfigurable={false}
							changeUnit1={true}
							twoWayConversion={false}
							onUnitConverted={(data) => setField((field) => ({ ...field, rowSpacing: data.toValue }))}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width="3" error={validation.alleyLengthError}>
						<label style={{ display: "unset" }}>Alley Length*</label>
						<Popup
							content="The lengh of the alley between plots in feet"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							id="form-input-alley-length"
							label={{ basic: true, content: "ft." }}
							labelPosition="right"
							value={field.alleyLength}
							readOnly={!canEditFieldDimensions}
							type="number"
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, alleyLength: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="3" style={{ marginTop: "auto" }}>
						<UnitConverter
							hidden={canEditFieldDimensions === false}
							defaults={{
								unitType: "length",
								unit1: "cm",
								unit2: "in",
								unit1Value: 0,
								unit2Value: Number(field.alleyLength)
							}}
							userConfigurable={false}
							changeUnit1={true}
							twoWayConversion={false}
							onUnitConverted={(data) => setField((field) => ({ ...field, alleyLength: data.toValue }))}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width="3" error={validation.planterOffsetError}>
						<label style={{ display: "unset" }}>Planter Offset*</label>
						<Popup
							content="The planter's offset in inches"
							trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
						/>
						<Input
							id="form-input-planter-offset"
							label={{ basic: true, content: "in." }}
							labelPosition="right"
							value={field.planterOffset}
							type="number"
							readOnly={!canEditFieldDimensions}
							onChange={(e, { value }) => {
								setField((field) => ({ ...field, planterOffset: value }));
							}}
						/>
					</Form.Field>
					<Form.Field width="3" style={{ marginTop: "auto" }}>
						<UnitConverter
							hidden={canEditFieldDimensions === false}
							defaults={{
								unitType: "length",
								unit1: "cm",
								unit2: "in",
								unit1Value: 0,
								unit2Value: Number(field.planterOffset)
							}}
							userConfigurable={false}
							changeUnit1={true}
							twoWayConversion={false}
							onUnitConverted={(data) => setField((field) => ({ ...field, planterOffset: data.toValue }))}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field>
						<label style={{ minWidth: 80, opacity: 0.75 }}>* Required</label>
					</Form.Field>
				</Form.Group>
			</Form>
		</Segment>
	);
};

NewFieldForm.propTypes = {
	setFieldId: PropTypes.func,
	fieldId: PropTypes.string,
	fromViewer: PropTypes.bool,
	handleCancel: PropTypes.func,
	handleFinish: PropTypes.func,
	handleSave: PropTypes.func,
	setActiveStep: PropTypes.func,
	setModeState: PropTypes.func,
	mode: PropTypes.string,
	navigateToNextStep: PropTypes.func
};

export default NewFieldForm;
