// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rotatePlotValues {\n\twhite-space: nowrap;\n}\n\n.rotatePlotValues > div {\n\ttransform: translate(1px, 28px) rotate(270deg);\n\twidth: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/Heatmap/styles.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,8CAA8C;CAC9C,WAAW;AACZ","sourcesContent":[".rotatePlotValues {\n\twhite-space: nowrap;\n}\n\n.rotatePlotValues > div {\n\ttransform: translate(1px, 28px) rotate(270deg);\n\twidth: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotatePlotValues": "rotatePlotValues"
};
export default ___CSS_LOADER_EXPORT___;
