export const objectIsNullOrEmpty = (obj) => {
	const isObjectNullOrEmpty = !obj || Object.keys(obj).length === 0;
	return isObjectNullOrEmpty;
};

export const toTitleCase = (str) => {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	return splitStr.join(" ");
};

export const getDistinctObjects = (arr) => {
	if (!arr) {
		return null;
	}

	if (arr.length === 0) {
		return [];
	}

	const distinctArray = [];
	const map = new Map();

	for (const item of arr) {
		if (!map.has(item.id)) {
			map.set(item.id, true);
			distinctArray.push(item);
		}
	}

	return distinctArray;
};

/**
 *
 * @param {string} bgHex
 * @returns
 */
export const getContrastColor = (bgHex) => {
	let A = 1;
	if (bgHex.length > 9) {
		A = parseInt(bgHex.substring(7, 9), 16) / 255;
	}
	let R = (parseInt(bgHex.substring(1, 3), 16) / 255) * A + 1 * (1 - A);
	let G = (parseInt(bgHex.substring(3, 5), 16) / 255) * A + 1 * (1 - A);
	let B = (parseInt(bgHex.substring(5, 7), 16) / 255) * A + 1 * (1 - A);

	R = R <= 0.04045 ? R / 12.92 : ((R + 0.055) / 1.055) ** 2.4;
	G = G <= 0.04045 ? G / 12.92 : ((G + 0.055) / 1.055) ** 2.4;
	B = B <= 0.04045 ? B / 12.92 : ((B + 0.055) / 1.055) ** 2.4;

	const L = 0.2126 * R + 0.7152 * G + 0.0722 * B;

	const blackConstrast = (L + 0.05) / 0.05;
	const whiteContrast = 1.05 / (L + 0.05);

	return blackConstrast >= whiteContrast ? "#000000" : "#ffffff";
};
