const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getEula(accessToken) {
	return fetch(`${aerialApiUri}api/v1/Eula`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function setEulaStatus(accessToken, accepted) {
	return fetch(`${aerialApiUri}api/v1/Eula/SetEulaStatus?accepted=${accepted}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getEulaStatus(accessToken) {
	return fetch(`${aerialApiUri}api/v1/Eula/GetEulaStatus`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
