const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

/**
 *
 * @param {File} treatmentInfoCSV
 * @param {*} trialData
 * @param {string} accessToken
 */
export function inspectTreatmentData(treatmentInfoCSV, trialData, accessToken) {
	const treatmentFormData = new FormData();

	treatmentFormData.append("treatmentInfoCsv", treatmentInfoCSV, treatmentInfoCSV.name);

	for (const name in trialData) {
		let val = trialData[name] === "" ? null : trialData[name];
		treatmentFormData.set(name, val);
	}

	return fetch(`${aerialApiUri}api/v1/Treatments/InspectTreatmentData`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: treatmentFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}
