import _ from "lodash";
import { getContrastColor } from "../../../utilities/jsUtils";

export function calculateShadingForGroupedTreatment(valuesToShade, propertyName, rgbValue) {
	const shadedValues = _.map(valuesToShade, (values) => {
		calculateShadingForTreatment(values, propertyName, rgbValue);
	});

	return shadedValues;
}

export function calculateShadingForGroupedTreatmentReversed(valuesToShade, propertyName, rgbValue) {
	const shadedValues = _.map(valuesToShade, (values) => {
		calculateShadingForTreatmentReversed(values, propertyName, rgbValue);
	});

	return shadedValues;
}

export function calculateShadingForTreatment(valuesToShade, propertyName, rgbValue, reversed = false) {
	let filteredValuesToShade = _.filter(valuesToShade, (v) => v.treatmentChecked === undefined || v.treatmentChecked);
	let values = _.map(filteredValuesToShade, propertyName);
	values = _.map(values, (v) => {
		return Math.abs(v);
	});

	let filteredValues = _.filter(values, (x) => {
		return !isNaN(x);
	});

	let min = Number(Math.min(...filteredValues));
	let max = Number(Math.max(...filteredValues));

	let opacity = 0;
	_.map(valuesToShade, (value) => {
		const absValue = Math.abs(value[propertyName]);
		if ((absValue === max && absValue === min) || absValue === min) {
			opacity = 0;
		} else if (absValue === max) {
			opacity = 100;
		} else {
			opacity = reversed ? getOpacityReverse(absValue, min, max) : getOpacity(absValue, min, max);
		}
		value.color = `rgb(${rgbValue}, ${opacity}%)`;
		value.textColor = getContrastColor("#02b95f" + opacity.toString(16));
		return value;
	});
	return valuesToShade;
}

function getOpacity(value, min, max) {
	const diff = max - min;
	const valueMinusMax = max - value;
	const opacity = 100 - (valueMinusMax / diff) * 100;
	return opacity;
}

export function calculateShadingForTreatmentReversed(valuesToShade, propertyName, rgbValue) {
	let filteredValuesToShade = _.filter(valuesToShade, (v) => v.treatmentChecked === undefined || v.treatmentChecked);
	let values = _.map(filteredValuesToShade, propertyName);
	values = _.map(values, (v) => {
		return Math.abs(v);
	});
	let min = Number(Math.min(...values));
	let max = Number(Math.max(...values));

	let opacity = 0;
	_.map(valuesToShade, (value) => {
		const absValue = Math.abs(value[propertyName]);
		if ((absValue === max && absValue === min) || absValue === min) {
			opacity = 100;
		} else if (absValue === max) {
			opacity = 0;
		} else {
			opacity = getOpacityReverse(absValue, min, max);
		}
		value.color = `rgb(${rgbValue}, ${opacity}%)`;
		return value;
	});

	return valuesToShade;
}

function getOpacityReverse(value, min, max) {
	const diff = max - min;
	const valueMinusMax = max - value;
	const opacity = (valueMinusMax / diff) * 100;
	return opacity;
}
