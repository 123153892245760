import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as trialActions from "../../../../redux/actions/trialActions";

import { Loader, Message, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import Ortho from "../../../Lumber/OrthoViewer";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

import { useAuth0 } from "../../../../auth/auth0";
import "./style.css";

const TrialViewer = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const userSettings = useUserSettings();
	const { getTokenSilently } = useAuth0();

	//-- Data
	const [trialName, setTrialName] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [imageFound, setImageFound] = useState(null);
	const [dataDone, setDataDone] = useState(true);

	useEffect(() => {
		if (entityNames?.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			getPlotAnalysisStatus();
			setLoading(false);
		}
	}, [entityNames]);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			trialActions.getPlotAnalysisStatusForTrial(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	return loading ? (
		<Loader active />
	) : (!loading && !trialName) || imageFound === false ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Viewer</h2>
			<hr />
			<Segment>
				<p>{"We could not find this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Viewer</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
					refreshPage={true}
				></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			{!dataDone && (
				<Message warning>
					Plot analysis data for this trial is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			<Ortho
				level="trial"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default TrialViewer;
