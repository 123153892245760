import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { Segment, Button, Loader, Grid, Header } from "semantic-ui-react";
import * as applicationActions from "../../../../redux/actions/applicationActions";
import { toast } from "react-toastify";
import _ from "lodash";
import * as uuid from "uuid";
import Application from "./Application";
import moment from "moment";

import ProtocolFavoritingWidget from "../../Widgets/Favorites/ProtocolFavoritingWidget";
import { useUserSettings } from "../../../../hooks/useUserSettings";

const Applications = ({ protocolName }) => {
	const DATE_FORMAT = "MM/DD/YYYY h:mma";
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();

	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	//-- Data
	const [applications, setApplications] = useState([]);

	//-- Data Sources
	const [applicationQuestions, setApplicationQuestions] = useState(null);

	//-- Validation
	const [formErrors, setFormErrors] = useState(null);
	// const [errors, setErrors] = useState({});

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (reduxProtocolData?.id != null && moduleNavigation.protocolId != null) {
			retrieveApplications(reduxProtocolData?.id);
		}
		getApplicationOptions();
	}, []);

	//retrieve applications that are associated with this protocol
	async function retrieveApplications(protocolId) {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(applicationActions.getProtocolApplications(protocolId, userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.data) {
					setApplications(res.data);
				}
				setLoading(false);
			})
			.catch((err) => {
				toast.error(err);
				setLoading(false);
			});
	}
	async function getApplicationOptions() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(applicationActions.getApplicationOptions(userAuth.currentClientId, reduxProtocolData.cropId, accessToken))
			.then((res) => {
				setLoading(false);
				_.map(res.data, (application) => {
					return moment.utc(application.growthPhaseDateUTC).format(DATE_FORMAT);
				});
				setApplicationQuestions(res.data);
			})
			.catch((err) => {
				toast.error(err);
				setLoading(false);
			});
	}

	async function handleSave(continueProcess) {
		let valid = validateForm();

		if (valid) {
			setLoading(true);
			const accessToken = await getTokenSilently();
			dispatch(
				applicationActions.saveApplications(
					applications,
					moduleNavigation.protocolId,
					userAuth.currentClientId,
					accessToken
				)
			)
				.then((res) => {
					toast.success("Applications saved successfully.");

					setLoading(false);
					if (!continueProcess && !moduleNavigation.protocolId) {
						history.push(moduleNavigation.createProtocolLink(true, res.data?.id, "applications"));
					}

					if (continueProcess) {
						history.push(moduleNavigation.createProtocolLink(true, res.data?.id, "treatments"));
					}
				})
				.catch((err) => {
					console.error(err);
					toast.error("Applications failed to save.");

					setLoading(false);
				});
		} else {
			toast.error("Please fix errors before saving.");
		}
	}

	function removeApplication(application) {
		let applicationsTemp = _.cloneDeep(applications);
		let index = _.findIndex(applicationsTemp, { id: application.id });
		applicationsTemp.splice(index, 1);
		let newCode = 0;
		_.map(applicationsTemp, (app) => {
			app.code = String.fromCharCode(97 + newCode).toUpperCase();
			newCode++;
		});
		setApplications(applicationsTemp);
	}

	function addApplication() {
		let applicationsTemp = _.cloneDeep(applications);
		let fakeApp = {
			code: applicationsTemp ? String.fromCharCode(97 + applicationsTemp.length).toUpperCase() : "A",
			protocolId: moduleNavigation.protocolId,
			id: uuid.v4()
		};
		applicationsTemp.push(fakeApp);
		setApplications(applicationsTemp);
	}

	function updateApplication(id, property, value) {
		let tempApplications = _.cloneDeep(applications);
		let matchingApplicationIndex = _.findIndex(tempApplications, { id: id });

		if (matchingApplicationIndex !== -1) {
			tempApplications[matchingApplicationIndex][`${property}`] = value;
		}
		setApplications(tempApplications);
	}

	function validateForm() {
		let valid = true;
		let errorList = formErrors;
		_.map(applications, (application) => {
			if (application.applicationTypeId == null) {
				toast.error("Must select and Application Type");
				valid = false;
			}

			if (application.applicationMethodId == null) {
				toast.error("Must select and Application Method");
				valid = false;
			}

			if (application.applicationPlacementId == null) {
				toast.error("Must select and Application Placement");
				valid = false;
			}

			if (application.groundDataGrowthPhaseId == null) {
				toast.error("Must select and Growth Phase");
				valid = false;
			}

			if (application.groundDataTimingMethodId != null) {
				let methodType = _.find(applicationQuestions.timingMethods, {
					id: application.groundDataTimingMethodId
				})?.methodtype;
				if (methodType == "Date") {
					if (application.growthPhaseDateUTC == null) {
						toast.error("Must select a valid date");
						valid = false;
					}
				} else if (methodType == "Text") {
					if (application.growthPhaseText == null) {
						toast.error("Must put some valid text for your growth phase");
						valid = false;
					}
				} else if (methodType == "StartEnd") {
					if (application.growthStageStartStageId == null || application.growthStageEndStageId == null) {
						toast.error("Must select a start and end date");
						valid = false;
					}
				} else if (methodType == "Start") {
					if (application.GrowthStageStartStageId == null) {
						toast.error("Must select a valid date");
						valid = false;
					}
				} else if (methodType == "int") {
					if (application.growthPhaseInteger == null) {
						toast.error("Must input a valid integer");
						valid = false;
					}
				}
			}
		});
		setFormErrors(errorList);
		return valid;
	}

	return loading ? (
		<Loader active />
	) : (
		<>
			<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
				<Button
					id="form-button-save"
					floated="right"
					primary
					content={"Save"}
					onClick={() => {
						handleSave(false);
					}}
					disabled={loading}
					loading={loading}
				/>
				<Button
					id="form-button-continue"
					floated="right"
					color="green"
					content={"Save and Continue"}
					onClick={() => {
						handleSave(true);
					}}
					disabled={loading}
					loading={loading}
				/>
				<Button
					id="form-button-back"
					floated="left"
					secondary
					content={"Back"}
					onClick={() => {
						history.push(moduleNavigation.createProtocolLink(true, null, "info"));
					}}
					disabled={loading}
					loading={loading}
				/>
			</Segment>
			<Segment basic>
				<h2 style={{ float: "left" }}>Applications</h2>
				<div style={{ float: "right" }}>
					<ProtocolFavoritingWidget
						style={{ display: "inline" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						protocolId={moduleNavigation.protocolId.toUpperCase()}
						userSettings={userSettings}
					></ProtocolFavoritingWidget>
					<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{protocolName}</i>
					</h2>
				</div>
				<hr style={{ clear: "both" }} />
				<Grid>
					<Grid.Row textAlign="center" verticalAlign="middle" style={{ padding: "5px" }}>
						{/* <Grid.Column width="1">
							<Header as="h3"></Header>
						</Grid.Column> */}
						<Grid.Column width="2" textAlign="right">
							<Header as="h3">Code</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Application Type</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Application Method</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Application Placement</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Growth Phase</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Timing Method</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Specific Timing</Header>
						</Grid.Column>
						<Grid.Column width="2">
							<Header as="h3">Notes</Header>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<>
					{_.map(applications, (application) => {
						return (
							<Application
								key={application.id}
								application={application}
								applicationQuestions={applicationQuestions}
								removeApplication={removeApplication}
								updateApplication={updateApplication}
							/>
						);
					})}
				</>
				<Button primary onClick={() => addApplication()}>
					+ Add Application
				</Button>
			</Segment>
		</>
	);
};

Applications.propTypes = {
	protocolName: PropTypes.string
};

export default Applications;
