import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { useDispatch } from "react-redux";
//Weather Data elements are the only ones on this page to use lodash
import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
//Weather Data Actions
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";

import TimecourseComponent from "../../../Lumber/Timecourse";

const Timecourse = () => {
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();

	//-- Data
	const [timecourseData, setTimecourseData] = useState(null);
	const [trialData, setTrialData] = useState(null);
	const [allMeanComparisonOptions, setAllMeanComparisonOptions] = useState(null);
	const [gduByDay, setGDUByDay] = useState(null);
	const weatherDataEnabled = true;
	const [rawPresetOptions, setRawPresetOptions] = useState(null);

	useEffect(() => {
		if (userAuth.isReady && moduleNavigation.trialId) {
			getTimecourseData();
		}
	}, [userAuth.isReady, moduleNavigation.trialId]);

	async function getTimecourseData() {
		const accessToken = await getTokenSilently();
		dispatch(statisticsActions.resetMeanComparisonData());
		dispatch(
			analysisActions.getAnalysisResultsForTrial(
				userAuth.currentClientId,
				moduleNavigation.trialId,
				accessToken,
				"",
				"",
				"",
				"",
				true,
				"",
				false,
				true
			)
		).then((res) => {
			//-- REMOVE STAND COUNT DATA FROM TIMECOURSE PAGES
			res.plotAnalysisResults = _.filter(res.plotAnalysisResults, (par) => !par.analysisName.includes("Stand Count"));

			//Remove Stand Count from the advanced analysis type options
			let standCountIndex = res.presetOptions.analysisTypes.findIndex(x => x.name === "Stand Count");
			res.presetOptions.analysisTypes.splice(standCountIndex, 1);
			setRawPresetOptions(res.presetOptions);

			dispatch(trialActions.getTrialData(userAuth.currentClientId, moduleNavigation.trialId, accessToken)).then(
				(trialData) => {
					dispatch(statisticsActions.getMeanComparisonsOptions(userAuth.currentClientId, accessToken)).then(
						(options) => {
							if (weatherDataEnabled && trialData.trialInfo.plantDate) {
								dispatch(
									weatherDataActions.getGDUForTrial(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
								)
									.then((gdu) => {
										setGDUByDay(gdu.data);
										res.plotAnalysisResults = _.filter(
											res.plotAnalysisResults,
											(r) => r.curveModelDataAnalysisId === null
										);
										setTimecourseData(res.plotAnalysisResults);
										setTrialData(trialData);
										setAllMeanComparisonOptions({
											data: {
												...options.data,
												defaultAlphaValueId: trialData.trialInfo.alphaId,
												defaultMeanComparisonId: trialData.trialInfo.meanComparisonId
											}
										});
									})
									.catch((err) => {
										res.plotAnalysisResults = _.filter(
											res.plotAnalysisResults,
											(r) => r.curveModelDataAnalysisId === null
										);
										setTimecourseData(res.plotAnalysisResults);
										setTrialData(trialData);
										setAllMeanComparisonOptions({
											data: {
												...options.data,
												defaultAlphaValueId: trialData.trialInfo.alphaId,
												defaultMeanComparisonId: trialData.trialInfo.meanComparisonId
											}
										});
										console.log(err);
									});
							} else {
								res.plotAnalysisResults = _.filter(
									res.plotAnalysisResults,
									(r) => r.curveModelDataAnalysisId === null && r.analysisId !== null
								);
								setTimecourseData(res.plotAnalysisResults);
								setTrialData(trialData);
								setAllMeanComparisonOptions({
									data: {
										...options.data,
										defaultAlphaValueId: trialData.trialInfo.alphaId,
										defaultMeanComparisonId: trialData.trialInfo.meanComparisonId
									}
								});
							}
						}
					);
				}
			);
		});
	}

	return (
		<>
			{timecourseData && trialData ? (
				<TimecourseComponent
					analysisDataResults={timecourseData}
					trialDataResults={trialData}
					allMeanComparisonOptions={allMeanComparisonOptions}
					//Weather Data disabled list of daily GDUs
					gduList={gduByDay}
					rawPresetOptions={rawPresetOptions}
					plantDate={trialData.trialInfo.plantDate}
					isForAnalysisTimecoursePage={true}
				/>
			) : (
				<Loader inline="centered" active />
			)}
		</>
	);
};

export default Timecourse;
