import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CloudCoverageTypes } from "../../../Lumber/CloudCoverageTypes";
import { YesNoOptions } from "../../../Lumber/YesNoOptions";

import _ from "lodash";
import moment from "moment";

import {
	Segment,
	Form,
	Select,
	List,
	Checkbox,
	Button,
	Loader,
	Message,
	Modal,
	Header,
	Input,
	Grid
} from "semantic-ui-react";

import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import UnitConverter from "../../../Lumber/UnitConverter";

import * as flightActions from "../../../../redux/actions/flightActions";
import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as flightImageActions from "../../../../redux/actions/flightImageActions";

import FmAddEditFarm from "../../../Lumber/Farms/FmAddEditFarm.js";

import "./styles.css";

const NewEditFlight = ({ mode = "new" }) => {
	moment.locale("en");
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const history = useHistory();
	const { getTokenSilently } = useAuth0();

	//-- Data source
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Dropdown options
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [uasOptions, setUasOptions] = useState([]);
	const [operatorOptions, setOperatorOptions] = useState([]);

	//-- Flight data
	const [flight, setFlight] = useState({
		clientId: null,
		flightDateTime: new Date(new Date().setHours(12, 0, 0, 0)),
		plan: "",
		name: "",
		operatorPersonId: "",
		altitude: "",
		growingSeasons: [],
		farms: [],
		uasId: "",
		canDelete: false,
		hasUploadedOrthos: false
	});
	const [initialUasId, setInitialUasId] = useState("");
	const [modifyUas, setModifyUas] = useState({
		flightId: null,
		clientId: null,
		uasId: null,
		orthoImageTypeIdsToDelete: [],
		orthoImageTypeIdsToChange: []
	});

	//-- Field data
	const [fields, setFields] = useState([]);

	//-- UI Control
	const [isSaving, setIsSaving] = useState(false);
	const [infoLoading, setInfoLoading] = useState(true);
	const [flightLoading, setFlightLoading] = useState(true);
	const [failedToLoad, setFailedToLoad] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [farmCreateModalOpen, setFarmCreateModalOpen] = useState(false);
	const [uasModalOpen, setUasModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [nextStep, setNextStep] = useState(false);

	//--Validation
	const [formError, setFormError] = useState(false);
	const [errorList, setErrorList] = useState([]);

	useEffect(() => {
		if (clientId) {
			getOptionData(clientId);

			setFlight((flight) => ({ ...flight, clientId: clientId }));

			if (!_.isEmpty(moduleNavigation.flightId) && mode === "edit") {
				getFlight();
				getFieldsInFlight();
			} else {
				setFlightLoading(false);
			}
		}
	}, [clientId]);

	//we need to know what fields are already associated with a flight
	async function getFieldsInFlight() {
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getFields(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			setFields(res);
		});
	}

	async function getFlight() {
		const accessToken = await getTokenSilently();
		dispatch(flightActions.getFlight(moduleNavigation.flightId, accessToken))
			.then((res) => {
				res.flightDateTime = new Date(res.flightDateTime);
				setFlight(res);
				setFlightLoading(false);
				setInitialUasId(res.uasId);
			})
			.catch((err) => {
				console.log(err);
				setFailedToLoad(true);
			});
	}

	async function getOptionData(clientId) {
		const accessToken = await getTokenSilently();
		setInfoLoading(true);
		dispatch(flightActions.getFlightInfo(clientId, accessToken))
			.then((res) => {
				initializeFlightOperatorOptions(res.operators);
				initializeGrowingSeasonOptions(res.growingSeasons);
				initializeFarmOptions(res.farms);
				initializeUasOptions(res.uases);
				setInfoLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setInfoLoading(false);
				setFailedToLoad(true);
			});
	}

	const initializeGrowingSeasonOptions = (growingSeasons) => {
		const growingSeasonsOpts = _.map(growingSeasons, (growingSeason) => {
			const opt = {
				key: growingSeason.growingSeasonId,
				value: growingSeason.growingSeasonId,
				text: growingSeason.name
			};
			return opt;
		});
		setGrowingSeasonOptions(growingSeasonsOpts);
	};

	const initializeFlightOperatorOptions = (flightOperators) => {
		const flightOperatorsOpts = _.map(flightOperators, (operator) => {
			const opt = {
				key: operator.personId,
				value: operator.personId,
				text: operator.firstName + " " + operator.lastName
			};
			return opt;
		});
		setOperatorOptions(flightOperatorsOpts);
	};

	const initializeFarmOptions = (farms) => {
		farms = _.filter(farms, (f) => f.clientId === clientId);
		const farmOpts = _.map(farms, (farm) => {
			const opt = { key: farm.farmId, value: farm.farmId, text: farm.name };
			return opt;
		});
		setFarmOptions(farmOpts);
	};
	const initializeUasOptions = (uases) => {
		const uasOpts = _.map(uases, (uas) => {
			const opt = {
				key: uas.uasId,
				value: uas.uasId,
				text: uas.name,
				description: uas.description,
				orthoimagetypeids: uas.orthoImageTypeIds
			};
			return opt;
		});
		setUasOptions(uasOpts);
	};

	const updateGrowingSeasons = (event, data, key) => {
		let growingSeasons = flight.growingSeasons;

		if (data.checked === true) {
			growingSeasons.push(key);
			setFlight({ ...flight, growingSeasons: growingSeasons });
		} else {
			let fieldList = fields.filter((field) => field.growingSeasonId === key);
			let fieldNames = "";
			if (fieldList.length > 0) {
				fieldList.map((field) => {
					fieldNames = fieldNames + "\n " + field.name;
				});
				toast.error("Cannot remove Growing Season because it is being used by the following fields: " + fieldNames);
				return;
			}

			setFlight({
				...flight,
				growingSeasons: growingSeasons.filter((x) => x !== key)
			});
		}
	};

	const updateFarms = (event, data, key) => {
		let farms = flight.farms;

		if (data.checked === true) {
			farms.push(key);
			setFlight({ ...flight, farms: farms });
		} else {
			setFlight({ ...flight, farms: farms.filter((x) => x !== key) });
		}
	};

	const validateForm = () => {
		let error = false;

		let updatedErrorList = [];
		if (_.isEmpty(flight.plan) || flight.plan.length > 50) {
			updatedErrorList.push("Flight Plan cannot be empty or more than 50 characters.");
			error = true;
		}

		if (flight.flightDateTime === null) {
			updatedErrorList.push("Flight Date/Time cannot be empty.");
			error = true;
		}

		if (_.isEmpty(flight.name) || flight.name.length > 50) {
			updatedErrorList.push("Flight Name cannot be empty or more than 50 characters.");
			error = true;
		}

		if (_.isEmpty(flight.operatorPersonId)) {
			updatedErrorList.push("Operator cannot be empty.");
			error = true;
		}

		if (flight.altitude < 0) {
			updatedErrorList.push("Altitude cannot be empty or less than 0.");
			error = true;
		}

		if (_.isEmpty(flight.growingSeasons)) {
			updatedErrorList.push("At least one growing season must be selected.");
			error = true;
		}

		if (_.isEmpty(flight.farms)) {
			updatedErrorList.push("At least one farm must be selected.");
			error = true;
		}

		if (_.isEmpty(flight.uasId)) {
			updatedErrorList.push("UAS cannot be empty.");
			error = true;
		}

		if (
			flight.droneSpeed < 0 ||
			flight.droneSpeed > 50 ||
			(isNaN(flight.droneSpeed) && flight.droneSpeed !== undefined)
		) {
			updatedErrorList.push("Drone Speed must be between 0 and 50");
			error = true;
		}

		if (flight.windSpeed < 0 || flight.windSpeed > 50) {
			updatedErrorList.push("Wind Speed must be between 0 and 50");
			error = true;
		}

		setErrorList(updatedErrorList);
		setFormError(error);
		return error;
	};

	async function saveFlight() {
		const error = validateForm();
		if (error === true) {
			return;
		}

		setIsSaving(true);
		const accessToken = await getTokenSilently();
		dispatch(flightActions.saveNewFlight(flight, accessToken))
			.then((res) => {
				toast.success("Flight successfully saved.");
				setIsSaving(false);
				history.push(moduleNavigation.createFlightLink(false, res, "ortho-upload"));
			})
			.catch(() => {
				toast.error("Error saving flight. Please try again.");
				setIsSaving(false);
			});
	}

	async function updateFlight(goToNextStep = false, tempModifyUas = null) {
		const error = validateForm();
		if (error === true) {
			return;
		}

		const accessToken = await getTokenSilently();
		setIsSaving(true);
		if (modifyUas.flightId || tempModifyUas) {
			let modifyUasToPass = tempModifyUas ? tempModifyUas : modifyUas;
			dispatch(flightImageActions.modifyUas(modifyUasToPass, accessToken))
				.then(() => {
					dispatch(flightActions.updateFlight(flight, accessToken))
						.then(() => {
							toast.success("Flight successfully updated.");
							setIsSaving(false);
							setUasModalOpen(false);
							if (goToNextStep) {
								history.push(moduleNavigation.createFlightLink(true, null, "ortho-upload"));
							}
						})
						.catch(() => {
							toast.error("Error updating flight. Please try again.");
							setIsSaving(false);
						});
				})
				.catch(() => {
					toast.error("Error changing UASes.");
					setIsSaving(false);
				});
		} else {
			dispatch(flightActions.updateFlight(flight, accessToken))
				.then(() => {
					toast.success("Flight successfully updated.");
					setIsSaving(false);
					if (goToNextStep) {
						history.push(moduleNavigation.createFlightLink(true, null, "ortho-upload"));
					}
				})
				.catch(() => {
					toast.error("Error updating flight. Please try again.");
					setIsSaving(false);
				});
		}
	}

	async function deleteFlight() {
		setIsDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(flightActions.deleteFlight(flight.flightId, flight.clientId, accessToken))
			.then(() => {
				toast.success("Flight successfully deleted.");
				setIsDeleting(false);
				history.push("/flights");
			})
			.catch(() => {
				toast.error("Failed to delete flight.");
				setIsDeleting(false);
			});
	}

	const handleDeleteModalOpen = () => {
		if (flight.canDelete) {
			setDeleteModalOpen(!deleteModalOpen);
		} else {
			toast.warn("Flight has published data. If you want to delete the flight, unpublish the data and try again.");
		}
	};

	const handleSave = (goToNextStep = false) => {
		if (mode === "edit" && initialUasId !== flight.uasId) {
			if (fields.length > 0) {
				toast.error("Error changing UASes. Need to remove fields from flight before changing UAS");
				return;
			}
			let initialOrthoImageTypeIds = _.find(uasOptions, { key: initialUasId }).orthoimagetypeids;
			let newOrthoImageTypeIds = _.find(uasOptions, { key: flight.uasId }).orthoimagetypeids;

			let orthosToChange = [];
			let orthosToDelete = [];

			_.map(initialOrthoImageTypeIds, (id) => {
				if (newOrthoImageTypeIds.includes(id)) {
					orthosToChange.push(id);
				} else {
					orthosToDelete.push(id);
				}
			});

			let tempModifyUas = {
				flightId: flight.flightId,
				clientId: flight.clientId,
				uasId: flight.uasId,
				orthoImageTypeIdsToChange: orthosToChange,
				orthoImageTypeIdsToDelete: orthosToDelete
			};

			setModifyUas(tempModifyUas);

			if (orthosToDelete.length > 0) {
				setNextStep(goToNextStep);
				setUasModalOpen(true);
			} else {
				updateFlight(goToNextStep, tempModifyUas);
			}
		} else {
			setModifyUas((modifyUas) => ({ ...modifyUas, flightId: null }));
			updateFlight(goToNextStep);
		}
	};

	return failedToLoad === true ? (
		<p>{"Failed to load flight data. Please try again"}</p>
	) : infoLoading || flightLoading ? (
		<Loader active />
	) : (
		<Segment basic id="new-edit-flight-segment" style={{ marginLeft: 50, paddingBottom: "unset" }}>
			<Form error={formError}>
				<Form.Field width="10" style={{ marginBottom: 10 }}>
					<Message error list={errorList} />
				</Form.Field>
				<Form.Group>
					<Form.Input
						width="5"
						label="Flight Plan*"
						placeholder="Plan"
						type="text"
						error={formError && (_.isEmpty(flight.plan) || flight.plan.length > 50)}
						value={flight.plan}
						onChange={(event, { value }) => {
							if (
								flight.flightDateTime &&
								value &&
								(!flight.name || flight.name.match(/\d{4}-\d{2}-\d{2}_\d{2}:\d{2} [ap]m_.+/gi))
							) {
								setFlight((flight) => ({
									...flight,
									name: `${moment(flight.flightDateTime).format("YYYY-MM-DD_hh:mm a")}_${value}`
								}));
							}
							setFlight((flight) => ({ ...flight, plan: value }));
						}}
					/>
					<Form.Field width="5" error={formError && flight.flightDateTime === null}>
						<label>{"Flight Date & Time*"}</label>
						<DateTimePicker
							id="flightDateTimePicker"
							includeTime={true}
							onChange={(value) => {
								if (
									flight.plan &&
									value &&
									(!flight.name || flight.name.match(/\d{4}-\d{2}-\d{2}_\d{2}:\d{2} [ap]m_.+/gi))
								) {
									setFlight((flight) => ({
										...flight,
										name: `${moment(value).format("YYYY-MM-DD_hh:mm a")}_${flight.plan}`
									}));
								}
								setFlight((flight) => ({ ...flight, flightDateTime: value }));
							}}
							value={flight.flightDateTime}
							placeholder="Date/Time"
						/>
					</Form.Field>
					<Form.Field width="4">
						<label>Drone Speed</label>
						<Input
							type="decimal"
							onKeyDown={(evt) => ["e", "E", "+", "-", " "].includes(evt.key) && evt.preventDefault()}
							placeholder="Drone Speed"
							label={{
								basic: true,
								content: "mph"
							}}
							labelPosition="right"
							value={flight.droneSpeed}
							onChange={(event, { value }) => {
								setFlight((flight) => ({ ...flight, droneSpeed: value }));
							}}
							error={formError && flight.droneSpeed < 0}
						/>
					</Form.Field>
					<Form.Field
						control={Select}
						label="Cloud Coverage"
						placeholder="Cloud Coverage"
						search
						options={CloudCoverageTypes}
						value={flight.cloudCoverage}
						width="4"
						onChange={(event, { value }) => {
							setFlight((flight) => ({ ...flight, cloudCoverage: value }));
						}}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						width="5"
						label="Flight Name*"
						placeholder="Name"
						type="text"
						value={flight.name}
						error={formError && (_.isEmpty(flight.name) || flight.name.length > 50)}
						onChange={(event, { value }) => {
							setFlight((flight) => ({ ...flight, name: value }));
						}}
					/>
					<Form.Field
						id="form-select-operator"
						control={Select}
						label="Operator*"
						placeholder="Operator"
						search
						options={operatorOptions}
						value={flight.operatorPersonId}
						error={formError && _.isEmpty(flight.operatorPersonId)}
						width="5"
						onChange={(event, { value }) => {
							setFlight((flight) => ({ ...flight, operatorPersonId: value }));
						}}
					/>
					<Form.Field width="4">
						<label>Wind Speed</label>
						<Input
							type="number"
							onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
							placeholder="Wind Speed"
							label={{
								basic: true,
								content: "mph"
							}}
							labelPosition="right"
							value={flight.windSpeed}
							onChange={(event, { value }) => {
								setFlight((flight) => ({ ...flight, windSpeed: value }));
							}}
							error={formError && flight.windSpeed < 0}
						/>
					</Form.Field>
					<Form.Field
						control={Select}
						label="In flight pauses"
						placeholder="In flight pauses"
						search
						options={YesNoOptions}
						value={flight.inFlightPauses}
						width="4"
						onChange={(event, { value }) => {
							setFlight((flight) => ({ ...flight, inFlightPauses: value }));
						}}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Field width="5">
						<label>Altitude</label>
						<Input
							type="number"
							placeholder="Altitude"
							label={{
								basic: true,
								content: "ft"
							}}
							labelPosition="right"
							value={flight.altitude}
							onChange={(event, { value }) => {
								setFlight((flight) => ({ ...flight, altitude: value }));
							}}
							error={formError && flight.altitude < 0}
						/>
					</Form.Field>
					<UnitConverter
						defaults={{
							unitType: "length",
							unit1: "m",
							unit2: "ft",
							unit1Value: 0,
							unit2Value: Number(flight.altitude)
						}}
						userConfigurable={false}
						changeUnit1={true}
						twoWayConversion={false}
						onUnitConverted={(data) => setFlight((flight) => ({ ...flight, altitude: data.toValue }))}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Field width="5" error={formError && _.isEmpty(flight.growingSeasons)}>
						<Grid>
							<Grid.Row style={{ height: "40px", paddingTop: "0px" }} verticalAlign="middle">
								<Grid.Column width="8" floated="left">
									<label style={{ fontWeight: "bold" }} htmlFor="form-list-growing-seasons">
										Growing Seasons*
									</label>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<List
							id="form-list-growing-seasons"
							size="big"
							style={{
								height: 166,
								overflowY: "auto",
								border: "1px solid #DEDEDF",
								backgroundColor: "#FFFFFF",
								marginTop: "0px"
							}}
						>
							{_.map(growingSeasonOptions, ({ key, text }) => {
								return (
									<List.Item key={key}>
										<List.Content>
											<Checkbox
												label={text}
												style={{ padding: 5 }}
												checked={flight.growingSeasons.some((x) => x === key)}
												onChange={(event, data) => {
													updateGrowingSeasons(event, data, key);
												}}
											/>
										</List.Content>
									</List.Item>
								);
							})}
						</List>
					</Form.Field>
					<Form.Field width="5" error={formError && _.isEmpty(flight.farms)}>
						<Grid>
							<Grid.Row style={{ height: "40px", paddingTop: "0px" }} verticalAlign="middle">
								<Grid.Column width="8" floated="left">
									<label style={{ fontWeight: "bold" }} htmlFor="form-list-farms">
										Farms*
									</label>
								</Grid.Column>
								<Grid.Column style={{ padding: "0px" }} width="4" floated="right">
									<Modal
										open={farmCreateModalOpen}
										trigger={
											<Button
												floated="right"
												size="mini"
												color="green"
												content="New Farm"
												onClick={() => {
													setFarmCreateModalOpen(true);
												}}
											/>
										}
									>
										<Modal.Header>New Farm</Modal.Header>
										<Modal.Content style={{ paddingBottom: "unset" }}>
											<FmAddEditFarm
												clientId={clientId}
												farm={null}
												onCancel={() => {
													setFarmCreateModalOpen(false);
												}}
												onFarmAdded={(farmId, farmName) => {
													// When a farm is created, add it to the list displayed on the flight info page which launched the modal.
													let localFarmOptions = _.cloneDeep(farmOptions);

													localFarmOptions = localFarmOptions.concat({
														key: farmId,
														value: farmId,
														text: farmName
													});

													let sortedLocalFarmOptions = localFarmOptions.sort((f1, f2) => (f1.text > f2.text ? 1 : -1));

													setFarmOptions(sortedLocalFarmOptions);

													// Dismiss the farm creation modal.
													setFarmCreateModalOpen(false);
												}}
												onFarmUpdated={null}
											/>
										</Modal.Content>
									</Modal>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<List
							id="form-list-farms"
							size="big"
							style={{
								height: 166,
								overflowY: "auto",
								border: "1px solid #DEDEDF",
								backgroundColor: "#FFFFFF",
								marginTop: "0px"
							}}
						>
							{_.map(farmOptions, ({ key, text }) => {
								return (
									<List.Item key={key}>
										<List.Content>
											<Checkbox
												label={text}
												style={{ padding: 5 }}
												checked={flight.farms.some((x) => x === key)}
												onChange={(event, data) => {
													updateFarms(event, data, key);
												}}
											/>
										</List.Content>
									</List.Item>
								);
							})}
						</List>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field
						id="form-select-uas"
						control={Select}
						label="UAS*"
						placeholder="UAS"
						search
						options={uasOptions}
						value={flight.uasId}
						width="10"
						error={formError && _.isEmpty(flight.uasId)}
						onChange={(event, { value }) => {
							setFlight((flight) => ({ ...flight, uasId: value }));
						}}
					/>
				</Form.Group>
				<Form.Group style={{ marginBottom: 0 }}>
					<Form.Field>
						<label style={{ minWidth: 80, opacity: 0.75 }}>* Required</label>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width="16">
						<label htmlFor="form-button-save" style={{ display: "none" }}>
							Save
						</label>
						{flight.flightId && moduleNavigation.flightId ? (
							<div style={{ marginTop: 10 }}>
								<Button
									id="form-button-save-and-continue"
									floated="right"
									color="green"
									content={"Save & Continue"}
									loading={isSaving}
									onClick={() => {
										handleSave(true);
									}}
								/>
								<Button
									id="form-button-save"
									floated="right"
									primary
									content="Save"
									loading={isSaving}
									onClick={() => {
										handleSave(false);
									}}
								/>
								<Modal
									open={deleteModalOpen}
									trigger={<Button negative floated="right" content="Delete Flight" onClick={handleDeleteModalOpen} />}
									style={{ paddingBottom: 10 }}
								>
									{flight.hasUploadedOrthos ? (
										<Modal.Header>
											WARNING - Deleting this Flight will delete all ortho and analysis data associated to this flight
										</Modal.Header>
									) : (
										<Modal.Header>Delete Flight</Modal.Header>
									)}

									<Modal.Content>
										<Modal.Description>
											<Header>Are you sure want to delete the flight?</Header>
											<Button
												onClick={deleteFlight}
												negative
												floated="right"
												loading={isDeleting}
												disabled={isDeleting}
											>
												Delete
											</Button>
											<Button floated="right" onClick={handleDeleteModalOpen} disabled={isDeleting}>
												Cancel
											</Button>
										</Modal.Description>
									</Modal.Content>
								</Modal>
								<Modal open={uasModalOpen} style={{ paddingBottom: 10 }}>
									<Modal.Header>
										WARNING - Selecting this UAS will delete previous images and all data associated with them
									</Modal.Header>
									<Modal.Content>
										<Modal.Description>
											<Header>Are you sure want to select this UAS?</Header>
											<Button
												floated="right"
												onClick={() => updateFlight(nextStep)}
												color="green"
												disabled={isSaving}
												loading={isSaving}
											>
												Confirm
											</Button>
											<Button floated="right" onClick={() => setUasModalOpen(false)} disabled={isSaving}>
												Cancel
											</Button>
										</Modal.Description>
									</Modal.Content>
								</Modal>
							</div>
						) : (
							<Button
								id="form-button-save"
								floated="right"
								primary
								content="Save"
								loading={isSaving}
								onClick={(event) => {
									saveFlight(event);
								}}
							/>
						)}
					</Form.Field>
				</Form.Group>
			</Form>
		</Segment>
	);
};

NewEditFlight.propTypes = {
	mode: PropTypes.string.isRequired
};

export default NewEditFlight;
