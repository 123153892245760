import React, { FC } from "react";
import { Grid } from "semantic-ui-react";
import _ from "lodash";
import "./arrow.css";
import { getContrastColor } from "../../../../utilities/jsUtils.js";

type TrialMapData = {
	treatmentId: string;
	trialTreatmentId: number;
	treatmentName: string;
	treatmentDescription: string;
	treatmentTypeName: string;
	plotId: string;
	plotName: string;
	column: number;
	range: number;
	seed: string;
	replicate: number;
	color?: string;
};

const PlotBlock: FC<{
	name?: string;
	id?: number;
	color?: string;
}> = ({ name, id, color }) => {
	return (
		<div
			style={{
				backgroundColor: color ?? "#a9a9a9",
				width: 50,
				height: 50,
				color: getContrastColor(color ?? "#a9a9a9"),
				border: "1px solid #000000"
			}}
		>
			<p style={{ position: "absolute", top: 3, left: 5 }}>{name ?? ""}</p>
			<p style={{ position: "absolute", bottom: 3, left: 5 }}>{id ?? ""}</p>
		</div>
	);
};

// const Arrow: FC<{ length: number; direction: "horizontal" | "vertical"; flipped?: boolean }> = ({
// 	length,
// 	direction,
// 	flipped
// }) => {
// 	return direction === "horizontal" ? (
// 		<div
// 			style={{
// 				display: "flex",
// 				flexDirection: "row",
// 				alignItems: "center",
// 				transform: `${flipped ? "rotate(180deg)" : ""}`,
// 				marginLeft: `${flipped ? "-3px" : ""}`
// 			}}
// 		>
// 			<div className="horizontalArrowShaft" style={{ width: length }} />
// 			<div className="horizontalArrowHead" />
// 		</div>
// 	) : (
// 		<div
// 			style={{
// 				display: "flex",
// 				flexDirection: "column",
// 				alignItems: "center",
// 				marginTop: "-10px",
// 				marginBottom: "-5px"
// 			}}
// 		>
// 			<div className="verticalArrowHead" />
// 			<div className="verticalArrowShaft" style={{ height: length }} />
// 		</div>
// 	);
// };

type Props = {
	plots: TrialMapData[][];
};

const TrialMap: FC<Props> = ({ plots }) => {
	return (
		<>
			<Grid style={{ justifyContent: "center", margin: 0 }}>
				{/* <Grid.Row style={{ padding: 0 }}>
					<Arrow direction="horizontal" length={plots[0].length * 64 + 10} flipped={(plots.length - 1) % 2 === 0} />
				</Grid.Row> */}
				{_.map(plots, (range, i) => {
					// const reverseIdx = plots.length - i - 1;

					return (
						<>
							<Grid.Row style={{ padding: 0, marginTop: ".3rem", flexWrap: "nowrap" }}>
								{/* {reverseIdx % 2 === 1 && (
									<Grid.Column style={{ padding: 0, width: 0 }}>
										<Arrow direction="vertical" length={64} />
									</Grid.Column>
								)} */}
								{_.map(range, (plot) => {
									return (
										<Grid.Column
											style={{ width: "fit-content", padding: 0, marginLeft: ".7rem", marginBottom: ".4rem" }}
										>
											<PlotBlock name={plot?.plotName} id={plot?.trialTreatmentId} color={plot?.color} />
										</Grid.Column>
									);
								})}
								{/* {reverseIdx % 2 === 0 && (
									<Grid.Column style={{ padding: 0, width: 10, paddingLeft: "1rem" }}>
										<Arrow direction="vertical" length={64} />
									</Grid.Column>
								)} */}
							</Grid.Row>
							{/* <Grid.Row style={{ padding: 0 }}>
								<Arrow direction="horizontal" length={range.length * 64 + 10} flipped={reverseIdx % 2 === 1} />
							</Grid.Row> */}
						</>
					);
				})}
			</Grid>
		</>
	);
};

export default TrialMap;
