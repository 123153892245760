import React, { useEffect } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { Segment } from "semantic-ui-react";

import MapboxGl from "mapbox-gl";
import ReactMapboxGl from "react-mapbox-gl";
import * as turf from "@turf/turf";
import ShapeFileControls from "./shapeFileControls";

const ShapeFileMapboxComponent = ({ shapeFilePoints, savingShapeFilePoints, saveShapeFilePoints }) => {
	const shapeFileControlsRef = React.createRef();

	useEffect(() => {
		if (savingShapeFilePoints === true) {
			saveShapeFilePoints(
				shapeFileControlsRef?.current?.lowerLeftPoint?.geometry?.coordinates,
				shapeFileControlsRef.current.upperLeftPoint?.geometry?.coordinates
			);
		}
	}, [savingShapeFilePoints]);

	let rawLabelCollection = [];
	const Map = ReactMapboxGl({
		accessToken: process.env.MAPBOX_ACCESS_TOKEN,
		maxZoom: 23,
		//Min zoom is set because a lot of shape file points causes lots of lag when zooming far out
		minZoom: 14,
		bearingSnap: 0
	});

	function setupMap(map) {
		addTrialsLayer(map);

		map.on("mouseover", "fields", () => {
			map.getCanvas().style.cursor = "pointer";
		});

		map.on("mouseleave", "fields", () => {
			map.getCanvas().style.cursor = "default";
		});

		map.on("click", "fields", (e) => {
			recolor(e, map);
			if (shapeFileControlsRef.current.pointToSet == "ll") {
				shapeFileControlsRef.current.setLowerLeftPoint(e.features[0]);
				shapeFileControlsRef.current.setPointToSet("ul");
			} else if (shapeFileControlsRef.current.pointToSet == "ul") {
				shapeFileControlsRef.current.setUpperLeftPoint(e.features[0]);
				shapeFileControlsRef.current.setPointToSet("ll");
			}
		});

		let openPopup = null;

		map.on("mouseenter", "fields", (e) => {
			openPopup = new MapboxGl.Popup({ closeButton: false })
				.setLngLat(e.features[0].geometry.coordinates)
				.setHTML(`<p style="font-size: 15px; font-weight: bold;">${e.features[0].properties.name}</p>`)
				.addTo(map);
		});

		map.on("mouseleave", "fields", () => {
			openPopup.remove();
		});

		let points = _.map(shapeFilePoints, (point) => {
			return turf.point([point[0], point[1]]);
		});
		let bboxCoords = turf.bbox(turf.featureCollection(points));

		map.fitBounds(MapboxGl.LngLatBounds.convert(bboxCoords), {
			padding: 30,
			animate: false
		});
	}

	function addTrialsLayer(map) {
		let labelCollection = {
			type: "FeatureCollection",
			features: []
		};

		_.map(shapeFilePoints, (point, index) => {
			let labelToAdd = {
				id: index,
				type: "Feature",
				properties: {
					name: `${point[0]}, ${point[1]}`
				},
				geometry: {
					type: "Point",
					coordinates: turf.point([point[0], point[1]]).geometry.coordinates
				}
			};
			labelCollection.features.push(labelToAdd);
		});

		map.addSource("fieldSource", {
			type: "geojson",
			data: labelCollection
		});

		map.addLayer({
			id: "fields",
			type: "circle",
			source: "fieldSource",
			paint: {
				"circle-color": "#ffff00"
			}
		});

		rawLabelCollection = labelCollection;
	}

	function recolor(e, map) {
		let filteredLabelCollection = _.cloneDeep(rawLabelCollection);
		filteredLabelCollection.features = _.filter(rawLabelCollection.features, (rlc) => {
			return (
				rlc.id !== e.features[0].id &&
				rlc.id !== shapeFileControlsRef?.current?.lowerLeftPoint?.id &&
				rlc.id !== shapeFileControlsRef?.current?.upperLeftPoint?.id
			);
		});

		map.removeLayer("fields");
		map.removeSource("fieldSource");

		map.addSource("fieldSource", {
			type: "geojson",
			data: filteredLabelCollection
		});

		map.addLayer({
			id: "fields",
			type: "circle",
			source: "fieldSource",
			paint: {
				"circle-color": "#ffff00"
			}
		});

		setLowerLeftLayer(e, map);
		setUpperLeftLayer(e, map);
	}

	function setLowerLeftLayer(e, map) {
		if (shapeFileControlsRef.current.pointToSet == "ll") {
			let lowerLeftCollection = _.cloneDeep(rawLabelCollection);
			lowerLeftCollection.features = _.filter(rawLabelCollection.features, (rlc) => {
				return rlc.id === e.features[0].id;
			});

			if (shapeFileControlsRef.current.lowerLeftPoint != null) {
				map.removeLayer("lowerLeft");
				map.removeSource("lowerLeftSource");
			}

			map.addSource("lowerLeftSource", {
				type: "geojson",
				data: lowerLeftCollection
			});

			map.addLayer({
				id: "lowerLeft",
				type: "circle",
				source: "lowerLeftSource",
				paint: {
					"circle-color": "#00FF00"
				}
			});
		}
	}

	function setUpperLeftLayer(e, map) {
		if (shapeFileControlsRef.current.pointToSet == "ul") {
			let upperLeftCollection = _.cloneDeep(rawLabelCollection);
			upperLeftCollection.features = _.filter(rawLabelCollection.features, (rlc) => {
				return rlc.id === e.features[0].id;
			});

			if (shapeFileControlsRef.current.upperLeftPoint != null) {
				map.removeLayer("upperLeft");
				map.removeSource("upperLeftSource");
			}

			map.addSource("upperLeftSource", {
				type: "geojson",
				data: upperLeftCollection
			});

			map.addLayer({
				id: "upperLeft",
				type: "circle",
				source: "upperLeftSource",
				paint: {
					"circle-color": "#00FF00"
				}
			});
		}
	}

	return (
		<Segment basic id="overviewMapboxComponent">
			<ShapeFileControls ref={shapeFileControlsRef} numberOfPoints={shapeFilePoints?.length} />
			<Map
				style={"mapbox://styles/mapbox/satellite-v9"}
				containerStyle={{
					height: "calc(100vh - 550px)",
					width: "100%"
				}}
				center={[0, 0]}
				zoom={[1]}
				onStyleLoad={(e) => {
					setupMap(e);
				}}
			></Map>
		</Segment>
	);
};

ShapeFileMapboxComponent.propTypes = {
	shapeFilePoints: PropTypes.array,
	savingShapeFilePoints: PropTypes.bool,
	saveShapeFilePoints: PropTypes.func
};

export default ShapeFileMapboxComponent;
