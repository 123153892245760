import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";

import Tooltip from "rc-tooltip";
import { Dropdown, Icon, Image, Input, Menu } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";

import logo from "../../../assets/logo-large.png";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";
import "./style.css";

//-- #073763 = rgb(7, 55, 99)

const Header = () => {
	const { isAuthenticated } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const [clients, setClients] = useState(null);
	const clientLogoUri = useSelector((state) => (state.clientLogoUri ? state.clientLogoUri : null));

	useEffect(() => {
		if (_.isEmpty(clients)) {
			initializeClientOptions(userAuth.user.userInfo.clientMemberships);
		}
	}, [userAuth.user.userInfo.clientMemberships]);

	function initializeClientOptions(clients) {
		const clientOpts = _.map(clients, (client) => {
			const opt = {
				key: client.clientId,
				value: client.clientId,
				text: client.clientName
			};
			return opt;
		});

		setClients(clientOpts);

		userAuth.handleClientChange(userAuth.currentClientId, userAuth.currentClientId);
	}

	return (
		<header>
			<Menu
				borderless
				size="large"
				stackable
				style={{
					height: 50,
					borderRadius: "unset",
					backgroundColor: "rgb(7, 55, 99, .75)"
				}}
			>
				<Menu.Item
					as={Link}
					to={moduleNavigation.homeModule.paths[0]}
					style={{ border: "unset", marginLeft: 7 }}
					name="home"
					fitted
					onClick={(e) => {
						if (!userAuth.isSubsriptionActive) {
							e.preventDefault();
						}
					}}
				>
					<Image src={logo} size="small" />
				</Menu.Item>

				{isAuthenticated &&
					renderAuthenticated(
						userAuth,
						userAuth.user.userInfo,
						clientLogoUri,
						clients,
						userAuth.currentClientId,
						userAuth.handleClientChange
					)}
			</Menu>
		</header>
	);
};

const renderAuthenticated = (userAuth, userInfo, clientLogoUri, clients, currentClientId, handleClientChange) => {
	const [clientList, setClientList] = useState([]);

	useEffect(() => {
		setClientList(clients);
	}, [clients]);

	const updateClientList = (query) => {
		const re = RegExp(_.escapeRegExp(query), "i");
		setClientList(_.filter(clients, (client) => re.test(client.text)));
	};

	return (
		<>
			<Menu.Menu id="userContextMenu" position="right">
				<span style={{ color: "#FFFFFF", marginTop: "15px" }}>{`Hello, ${userInfo.basic.firstName}!`}</span>
				<Menu.Item name="userContext">
					<Dropdown
						style={{ color: "#FFFFFF" }}
						trigger={
							<span>
								{clientLogoUri ? (
									<Image src={clientLogoUri} size="tiny" style={{ maxWidth: "80px", maxHeight: "60px" }} inline />
								) : (
									clients &&
									clients.length > 1 && (
										<span>
											(
											{
												clients.find((c) => {
													return c.value === currentClientId;
												}).text
											}
											)
										</span>
									)
								)}
							</span>
						}
					>
						<Dropdown.Menu style={{ overflowY: "auto", maxHeight: "600px", minWidth: "300px", zIndex: "1100" }}>
							<Dropdown.Header
								style={{ fontWeight: "unset", textTransform: "unset", opacity: ".75" }}
								content={
									<Tooltip
										placement="bottom"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										trigger="hover"
										overlay={`${userInfo.basic.emailAddress}`}
										transitionName="rc-tooltip-zoom"
									>
										<span>
											Signed in as <strong>{`${userInfo.basic.firstName} ${userInfo.basic.lastName}`}</strong>
										</span>
									</Tooltip>
								}
							/>
							<Dropdown.Item>
								<Link to="/logout" style={{ display: "block" }}>
									<Icon name="sign out" size="small" />
									<span style={{ color: "#000000" }}>Log Out</span>
								</Link>
							</Dropdown.Item>
							<Dropdown.Divider />
							{(userAuth.isApAdmin ||
								userAuth.hasApplicationArea(ApplicationArea.ApSettings, userAuth.currentClientId) ||
								userAuth.hasApplicationArea(ApplicationArea.ClientSettings, userAuth.currentClientId)) && (
								<>
									<Dropdown.Item>
										<Link to={userAuth.isApAdmin ? "/ap-settings" : "/client-settings"} style={{ display: "block" }}>
											<Icon name="cog" size="small" />
											<span style={{ color: "#000000" }}>Settings</span>
										</Link>
									</Dropdown.Item>
									<Dropdown.Divider />
								</>
							)}
							<Dropdown.Item>
								<Link to="/support" style={{ display: "block" }}>
									<Icon name="help" size="small" />
									<span style={{ color: "#000000" }}>Support</span>
								</Link>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Header
								style={{ fontWeight: "unset", textTransform: "unset", opacity: ".75" }}
								content={clientList && clientList.length === 1 ? `Organization` : `Organizations`}
							/>
							{clients && clients.length > 1 && (
								<Input
									onClick={(e) => e.stopPropagation()}
									onChange={(e, { value }) => {
										updateClientList(value);
									}}
									icon="search"
									iconPosition="left"
								/>
							)}
							{_.map(clientList, ({ key, value, text }) => {
								return (
									<Dropdown.Item
										className="clientContainer"
										style={{}}
										key={key}
										id={value}
										onClick={(event, data) => {
											handleClientChange(data.id);
										}}
									>
										{value === currentClientId ? <Icon name="check circle outline" color="green" /> : null}
										{text}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Item>
			</Menu.Menu>
		</>
	);
};

export default Header;
