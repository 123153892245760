import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function meanComparisonReducer(state = initialState.timecourseMeanComparisonData, action) {
	switch (action.type) {
		case types.STATISTICS_GET_MEAN_COMPARISON_FOR_TIMECOURSE_SUCCESS:
			//check current state to see if current combination of meancomparisonId, alphaId exists if it does do nothing, otherwise add new entry to state array
			if (state.length > 0) {
				if (
					!state.some((s) => {
						return (
							s.alphaId == action.alphaId &&
							s.meanComparisonId == action.meanComparisonId &&
							s.analysisId == action.analysisId
						);
					})
				) {
					//if the mean hasnt already been run add a new entry
					let newArray = state.slice();
					newArray.splice(state.length, 0, action);
					return newArray;
				} else {
					return state;
				}
			}
			return [...state, action];

		case types.RESET_TIMECOURSE_MEAN_COMPARISON_DATA:
			return initialState.timecourseMeanComparisonData;

		default:
			return state;
	}
}
