import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";

import { Segment, Table, Loader } from "semantic-ui-react";

const BulkPublicationSummary = ({ trialData, setFilteredBulkTrialData }) => {
	const [groupedData, setGroupedData] = useState([]);

	const DATE_FORMAT = "M/D/YYYY";
	const loading = false;

	useEffect(() => {
		if (trialData.length > 0) {
			let filteredData = [];
			let filteredFlights = [];
			_.map(trialData, (td) => {
				if (_.some(td.unPublishedTrialFlights, (untf) => untf.isChecked)) {
					let filteredCheckedFlights = _.filter(td.unPublishedTrialFlights, (utf) => utf.isChecked);
					td.flightCount = filteredCheckedFlights.length;
					td.minFlight = _.minBy(_.filter(td.unPublishedTrialFlights, (utf) => utf.isChecked), "flightDate").flightDate;
					td.maxFlight = _.maxBy(_.filter(td.unPublishedTrialFlights, (utf) => utf.isChecked), "flightDate").flightDate;
					filteredData.push(td);
					let tempTd = _.cloneDeep(td);
					tempTd.unPublishedTrialFlights = filteredCheckedFlights;
					filteredFlights.push(tempTd);
				}
			})
			let tempGroupedData = _.groupBy(filteredData, "companyName");
			setGroupedData(tempGroupedData);
			setFilteredBulkTrialData(filteredFlights);

		}
	}, [trialData]);

	return loading ? (
		<Loader active />
	) : !loading ? (
		<Segment>
			<Table celled striped sortable selectable color="blue" textAlign="center">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Company</Table.HeaderCell>
						<Table.HeaderCell>Trials</Table.HeaderCell>
						<Table.HeaderCell>Flights</Table.HeaderCell>
						<Table.HeaderCell>Flight Date Range</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						_.map(groupedData, (data, index) => {
							return (
								<Table.Row key={index}
								>
									<Table.Cell>
										{index}
									</Table.Cell>
									<Table.Cell>
										{data.length}
									</Table.Cell>
									<Table.Cell>
										{_.sumBy(
											data, 'flightCount')}
									</Table.Cell>
									<Table.Cell>
										{moment(_.minBy(
											data, 'minFlight').minFlight).local().format(DATE_FORMAT)} - {moment(_.maxBy(
												data, 'maxFlight').maxFlight).local().format(DATE_FORMAT)}
									</Table.Cell>
								</Table.Row>
							);
						})
					}
				</Table.Body>
			</Table>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Bulk Publication Summary</h2>

		</Segment>
	);
}
BulkPublicationSummary.propTypes = {
	trialData: PropTypes.array,
	setFilteredBulkTrialData: PropTypes.func
};
export default BulkPublicationSummary;