// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransformComponent-module_container__3NwNd {\n  width: unset;\n  height: unset;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Trials/TrialViewer/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".TransformComponent-module_container__3NwNd {\n  width: unset;\n  height: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransformComponent-module_container__3NwNd": "TransformComponent-module_container__3NwNd"
};
export default ___CSS_LOADER_EXPORT___;
