import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function autoUploadedOrthoReducer(state = initialState.autoUploadedOrthos, action) {
	switch (action.type) {
	case types.ORTHO_AUTO_ORTHO_UPLOAD:{
		let newArray = state.slice();
		newArray.splice(state.length, 0, action.autoUploadOrtho);
		return newArray;
	}
	case types.ORTHO_AUTO_ORTHO_REMOVE:{
		action.autoUploadOrtho = [];
		return action.autoUploadOrtho;
	}
	default:
		return state;
}
}
