const statisticsApiUri = process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl;

export function getMeanComparisonForAuc(aucData, clientId, meanComparisonId, alphaId, isDataUnbalanced, accessToken) {
	return fetch(
		`${statisticsApiUri}api/v1/MeanComparison/GetMeanComparisonLettersForAuc?clientId=${clientId}&meanComparisonId=${meanComparisonId}&alphaId=${alphaId}&unbalancedData=${isDataUnbalanced}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(aucData)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getMeanComparisonForTimecourse(
	timecourseData,
	clientId,
	analysisId,
	meanComparisonId,
	alphaId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	return fetch(
		`${statisticsApiUri}api/v1/MeanComparison/GetMeanComparisonLettersForTimecourse?clientId=${clientId}&analysisId=${analysisId}&meanComparisonId=${meanComparisonId}&alphaId=${alphaId}
			&removeDamagedOrExcluded=${removeDamagedOrExcluded}&removeOutliers=${removeOutliers}&outlierType=${outlierType}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(timecourseData)
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getProtocolTukeyData(data, alphaId, meanComparisonId, clientId, accessToken) {
	let tukeyFormData = new FormData();
	let index = 0;

	for (const d of data) {
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].FieldId`, d.fieldId ?? "");
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].AssessmentId`, d.assessmentId);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].TrialTreatmentId`, d.trialTreatmentId);
		tukeyFormData.append(
			`ProtocolPlotData.ProtocolData[${index}].AnalysisValue`,
			parseFloat(d.analysisValue.toFixed(5))
		);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].TrialId`, d.trialId);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].PlotReplicate`, d.plotReplicate);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].PlotId`, d.plotId);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].DataNormalizationId`, d.dataNormalizationId);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].MeanComparisonId`, meanComparisonId);
		tukeyFormData.append(`ProtocolPlotData.ProtocolData[${index}].AlphaId`, alphaId);
		index++;
	}

	return fetch(
		`${statisticsApiUri}api/v1/MeanComparison/GetProtocolMeanComparisonData?meanComparisonId=${meanComparisonId}&clientId=${clientId}&alphaId=${alphaId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: tukeyFormData
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getMeanComparisonsOptions(clientId, accessToken) {
	return fetch(`${statisticsApiUri}api/v1/MeanComparison/GetMeanComparisonsOptions?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}
