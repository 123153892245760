import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";

import { useAuth0 } from "../auth0";
const AutoLogin = () => {
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		loginWithRedirect();
	}, []);

	return <Loader active />;
};

export default AutoLogin;
