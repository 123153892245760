const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getOrthoImageTypes(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/OrthoImageType/orthoImageType?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
	.then((res) => {
		return res.json();
	})
	.then((data) => {
		return data;
	});
}

export function getOrthoImageTypeDetails(clientId, accessToken) {
	return fetch(`${aerialPlotApi}api/v1/OrthoImageType/GetOrthoImageTypeDetails?clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
	})
	.then((res) => {
		return res.json();
	})
	.then((data) => {
		return data;
	});
}
