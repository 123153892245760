import React, { useEffect, useState } from "react";
import { Table, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";

import _ from "lodash";

const AnovaTable = ({ anovaData, compiledDataLoading, hideData = true, wasThereAnError = false }) => {
	//-- Data
	const [denominatorDfs, setDenominatorDfs] = useState([]);
	const [fValues, setFValues] = useState([]);
	const [pValues, setPValues] = useState([]);

	//-- UI Control
	const [tableLoading, setTableLoading] = useState(true);

	useEffect(() => {
		if (anovaData) {
			setupTableData();
		}
	}, [anovaData]);

	useEffect(() => {
		setTableLoading(compiledDataLoading);
	}, [compiledDataLoading]);

	const setupTableData = () => {
		setDenominatorDfs(normalizeData(anovaData?.denominatorDf, 1, false));
		setFValues(normalizeData(anovaData?.fValues, 5, true));
		setPValues(normalizeData(anovaData?.pValues, 5, false));

		setTableLoading(false);
	};

	const normalizeData = (data, decimalsToRound, runPercisionConversion) => {
		if (data) {
			return _.map(data, (d) => {
				if (d === "-") {
					return d;
				} else {
					let parsedNumber = parseFloat(d);
					let fixedNumber = parsedNumber.toFixed(decimalsToRound);
					if (runPercisionConversion === true) {
						return valuePercisionConverter.getPercision(fixedNumber);
					}

					return Number(fixedNumber) == 0 ? "<0.00001" : fixedNumber;
				}
			});
		}
	};

	return tableLoading ? (
		<Loader active />
	) : (
		<Table celled striped>
			<Table.Header>
				<Table.Row textAlign="center">
					<Table.HeaderCell style={{ zIndex: "0" }}></Table.HeaderCell>
					<Table.HeaderCell style={{ zIndex: "0" }}>DF</Table.HeaderCell>
					<Table.HeaderCell style={{ zIndex: "0" }}>F-Value</Table.HeaderCell>
					<Table.HeaderCell style={{ zIndex: "0" }}>p-Value</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				<Table.Row>
					<Table.HeaderCell textAlign="left">Treatment</Table.HeaderCell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : denominatorDfs[0] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : fValues[0] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : pValues[0] ?? "-"}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.HeaderCell textAlign="left">Trial</Table.HeaderCell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : denominatorDfs[1] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : fValues[1] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : pValues[1] ?? "-"}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.HeaderCell textAlign="left">Treatment:Trial</Table.HeaderCell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : denominatorDfs[2] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : fValues[2] ?? "-"}</Table.Cell>
					<Table.Cell textAlign="left">{wasThereAnError ? "N/A" : hideData === true ? "-" : pValues[2] ?? "-"}</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	);
};

AnovaTable.propTypes = {
	anovaData: PropTypes.object,
	compiledDataLoading: PropTypes.bool,
	hideData: PropTypes.bool,
	wasThereAnError: PropTypes.bool
};

export default AnovaTable;
