import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal, Loader, Icon, Popup, Message } from "semantic-ui-react";
import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { HubConnectionBuilder } from "@microsoft/signalr";

import * as orthoActions from "../../../../redux/actions/orthoActions";
import * as flightActions from "../../../../redux/actions/flightActions";
import styles from "./styles.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const UploadedElevationImage = ({
	elevationImage,
	sensorId,
	clientId,
	azureBrowseCallback,
	azureUploadSuccessCallback,
	selectedAzureFile,
	componentId
}) => {
	const fileInputRef = useRef();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	//-- Data
	const [flightElevationImage, setFlightElevationImage] = useState(elevationImage);
	const [selectedOrtho, setSelectedOrtho] = useState(null);
	const [selectedOrthoName, setSelectedOrthoName] = useState(elevationImage?.name ?? "");
	const [isSelectedFileLocal, setisSelectedFileLocal] = useState(false);
	const uploadingFlightElevationOrthos = useSelector((state) => state.flightElevationOrthoUploads);
	const chunkSize = 1048576 * 3; //-- 3MB

	//-- UI Control
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [validationErrorState, setValidationErrorState] = useState(false);
	const [validationErrorMessage, setValidationErrorMessage] = useState("");

	//-- SignalR
	const [signalRConnection] = useState(setupSignalRConnection());
	const orthoRef = useRef(flightElevationImage);
	const [uploadStatus, setUploadStatus] = useState("Processing on Server");

	useEffect(() => {
		//-- On page leave or component unload, stop the signalR connection
		return () => stopSignalRConnection();
	}, []);

	useEffect(() => {
		if (!_.isEmpty(uploadingFlightElevationOrthos)) {
			const updatedSelectedOrtho = uploadingFlightElevationOrthos.find(
				(x) =>
					x.flightId === moduleNavigation.flightId ||
					x.flightElevationImageId === flightElevationImage?.flightElevationImageId
			);
			if (updatedSelectedOrtho) {
				//-- Check if the ortho failed to upload
				if (
					updatedSelectedOrtho.maxNumberRetryAttempts <= updatedSelectedOrtho.retryAttempt &&
					updatedSelectedOrtho.showRetryButton
				) {
					setValidationErrorState(true);
					setValidationErrorMessage(
						"Ortho failed to upload. Please try again or contact support if the error continues."
					);
				}
				setFlightElevationImage(updatedSelectedOrtho);
				setSelectedOrthoName(updatedSelectedOrtho.fileName);
			} else if (flightElevationImage?.isUploading) {
				setFlightElevationImage({ ...flightElevationImage, showTooltip: true });
			}
		} else if (flightElevationImage?.isUploading && uploadStatus !== "Uploaded") {
			setFlightElevationImage({ ...flightElevationImage, showTooltip: true });
		}
	}, [uploadingFlightElevationOrthos]);

	useEffect(() => {
		if (elevationImage) {
			var clonedElevationImage = _.cloneDeep(elevationImage);
			clonedElevationImage.showRetryButton =
				clonedElevationImage.isUploading && clonedElevationImage.uploadStatus === "Uploading Image Chunks";
			clonedElevationImage.flightId = moduleNavigation.flightId;
			clonedElevationImage.isUploaded = !clonedElevationImage.isUploading;
			orthoRef.current = clonedElevationImage;
			setUploadStatus(clonedElevationImage.uploadStatus);
			setFlightElevationImage(clonedElevationImage);
		}
	}, [elevationImage]);

	useEffect(() => {
		if (flightElevationImage) {
			var clonedElevationImage = _.cloneDeep(flightElevationImage);
			orthoRef.current = clonedElevationImage;
			if (clonedElevationImage.isUploaded) {
				stopSignalRConnection();
			}
		}
	}, [flightElevationImage]);

	function setupSignalRConnection() {
		const url = `${process.env.aerialPlotConnections.aerialImageApi.baseUrl}orthoUploadHub`;
		const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

		return newConnection;
	}

	function stopSignalRConnection() {
		if (signalRConnection) {
			signalRConnection.stop();
		}
	}

	useEffect(() => {
		if (
			signalRConnection &&
			signalRConnection?.state === "Disconnected" &&
			flightElevationImage?.isUploading === true
		) {
			signalRConnection
				.start()
				.then(() => {
					signalRConnection.on("elevationUploadStatus", (uploadStatus) => {
						//-- uploadStatus has the following properties
						//-- flightElevationImageId
						//-- isUploading
						//-- uploadStatus
						if (
							orthoRef.current.flightElevationImageId === uploadStatus.flightElevationImageId ||
							orthoRef.current.flightElevationImageId === uploadStatus.tempFlightElevationImageId
						) {
							setUploadStatus(uploadStatus.status);

							if (uploadStatus.status === "Uploaded") {
								dispatch(orthoActions.removeElevationOrthoUpload(flightElevationImage.flightId));

								setFlightElevationImage({
									...flightElevationImage,
									isUploaded: true,
									isUploading: false,
									showRetryButton: false
								});
							}
						}
					});
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [signalRConnection, flightElevationImage]);

	useEffect(() => {
		if (selectedAzureFile) {
			const newFlightElevationImage = {
				flightId: moduleNavigation.flightId,
				sensorId: sensorId,
				name: selectedAzureFile?.fileName,
				clientId: clientId,
				isUploading: false,
				isUploaded: false,
				isDeleting: false
			};
			setFlightElevationImage(newFlightElevationImage);
			setSelectedOrtho(selectedAzureFile);
			setSelectedOrthoName(selectedAzureFile?.fileName);
			setisSelectedFileLocal(false);
		}
	}, [selectedAzureFile]);

	function handleFileBrowse() {
		fileInputRef.current.click();
	}

	function fileSelectedHandler(event) {
		event.persist();

		if (event.target.files.length > 0) {
			const elevationFileData = event.target.files[0];
			if (flightElevationImage) {
				const isValid = isFlightElevationImageValid(elevationFileData);
				if (!isValid) {
					return;
				}
				let clonedFlightElevationImage = _.cloneDeep(flightElevationImage);

				clonedFlightElevationImage.name = elevationFileData.name;
				setFlightElevationImage(clonedFlightElevationImage);
			} else {
				let newFlightElevationImage = {
					flightId: moduleNavigation.flightId,
					sensorId: sensorId,
					name: elevationFileData.name,
					clientId: clientId,
					isUploading: false,
					isUploaded: false,
					isDeleting: false
				};
				setFlightElevationImage(newFlightElevationImage);
			}

			setSelectedOrtho(elevationFileData);
			setSelectedOrthoName(elevationFileData.name);
			setisSelectedFileLocal(true);
		}
	}

	function isFlightElevationImageValid(elevationFileData) {
		if (flightElevationImage.showRetryButton === true && !elevationFileData) {
			setValidationErrorState(true);
			setValidationErrorMessage(
				"To retry an uploading ortho, you must Browse the same file: " + flightElevationImage.name
			);
			toast.warn("Retrying failed");
			return false;
		} else if (flightElevationImage.showRetryButton === true && flightElevationImage.name !== elevationFileData.name) {
			setValidationErrorState(true);
			setValidationErrorMessage(
				"To retry an uploading ortho, you must Browse the same file: " + flightElevationImage.name
			);
			toast.warn("Retrying failed");

			return false;
		} else {
			setValidationErrorState(false);
			setValidationErrorMessage("");

			return true;
		}
	}

	function handleDeleteModalOpen() {
		setDeleteModalOpen(!deleteModalOpen);
	}

	async function deleteFlightElevationImage() {
		setIsDeleting(true);
		const accessToken = await getTokenSilently();
		let updatedFlightElevationImage = _.cloneDeep(flightElevationImage);
		updatedFlightElevationImage.isDeleting = true;
		dispatch(orthoActions.updateUploadingElevationOrtho(updatedFlightElevationImage));
		dispatch(
			flightActions.deleteFlightElevationImage(flightElevationImage.flightElevationImageId, clientId, accessToken)
		)
			.then(() => {
				toast.success("Flight Elevation Image deleted successfully!");
				setIsDeleting(false);
				setFlightElevationImage(null);
				handleDeleteModalOpen();
				setSelectedOrtho(null);
				setSelectedOrthoName("");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Failed to delete Flight Elevation Image.");
				setIsDeleting(false);
			});
	}

	async function downloadOrtho() {
		setIsDownloading(true);
		const accessToken = await getTokenSilently();
		dispatch(
			orthoActions.downloadFlightElevationOrtho(
				flightElevationImage.flightElevationImageId,
				flightElevationImage.name,
				clientId,
				accessToken
			)
		)
			.then(() => {
				setIsDownloading(false);
				toast.success("Ortho downloaded.");
			})
			.catch(() => {
				setIsDownloading(false);
				toast.error("Ortho failed to download. Please try again.");
			});
	}

	async function fileUploadHandler() {
		if (isSelectedFileLocal) {
			var image = _.cloneDeep(flightElevationImage);
			image.isUploading = true;
			image.uploadPercent = 0;
			image.uploadingChunks = true;
			setFlightElevationImage(image);

			const accessToken = await getTokenSilently();

			let recordToCreate = new FormData();
			recordToCreate.set("flightId", image.flightId);
			recordToCreate.set("sensorId", image.sensorId);
			recordToCreate.set("fileName", selectedOrtho.name);

			dispatch(orthoActions.addFlightElevationOrthoUpload(image));
			dispatch(
				orthoActions.createInitialFlightElevationImageRecord(
					recordToCreate,
					clientId,
					selectedOrtho,
					chunkSize,
					image,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error("Image record failed to create");
			});
		} else {
			azureFileUploadHandler();
		}
	}

	async function azureFileUploadHandler() {
		var image = _.cloneDeep(flightElevationImage);
		setFlightElevationImage({ ...image, isUploading: true, uploadPercent: 100, uploadingChunks: false });
		dispatch(
			orthoActions.addFlightElevationOrthoUpload({
				...image,
				isUploading: true,
				uploadPercent: 100,
				uploadingChunks: false
			})
		);

		const accessToken = await getTokenSilently();

		// null properties are not needed for the azure file copy, but are stubbed for the orthoMetadata object
		const orthoMetadata = {
			flightId: flightElevationImage.flightId,
			tempFlightImageId: flightElevationImage.flightElevationImageId,
			sensorId: flightElevationImage.sensorId,
			sensorName: flightElevationImage.sensorName,
			orthoImageTypeId: null,
			orthoImageTypeAbbreviation: null,
			fieldId: null,
			trialId: null,
			plotId: null,
			fileName: flightElevationImage.name,
			clientId
		};
		const bodyData = { orthoMetadata, azureShareFileItem: selectedAzureFile };
		dispatch(orthoActions.copyAzureFileToShareElevation(bodyData, accessToken, clientId))
			.then((res) => {
				azureUploadSuccessCallback(bodyData);
				toast.success("Azure file copied successfully.");
				setFlightElevationImage({
					...flightElevationImage,
					uploadedFlightImageId: res.data,
					isUploaded: true,
					isUploading: false
				});

				dispatch(orthoActions.removeElevationOrthoUpload(flightElevationImage.flightId));
			})
			.catch((e) => {
				console.error(e);
				if (e.statusCode === 500) {
					setFlightElevationImage({ ...flightElevationImage, isUploading: false });
					toast.error("Azure file copy failed. Please try again.");
					dispatch(orthoActions.removeElevationOrthoUpload(flightElevationImage.flightId));
				} else if (e.statusCode === 400) {
					setFlightElevationImage({ ...flightElevationImage, isUploading: false });
					toast.error(e.response.data.Message);
					dispatch(orthoActions.removeElevationOrthoUpload(flightElevationImage.flightId));
				} else {
					setFlightElevationImage({
						...flightElevationImage,
						isUploading: true,
						uploadPercent: 100,
						showTooltip: true
					});
				}
			});
	}

	async function retryUpload() {
		const isValid = isFlightElevationImageValid(selectedOrtho);
		if (!isValid) {
			return;
		}
		var image = _.cloneDeep(flightElevationImage);
		image.isUploading = true;
		image.showRetryButton = false;

		const accessToken = await getTokenSilently();

		let recordToCreate = new FormData();
		recordToCreate.set("flightId", image.flightId);
		recordToCreate.set("sensorId", image.sensorId);
		recordToCreate.set("fileName", selectedOrtho.name);
		dispatch(orthoActions.addFlightElevationOrthoUpload(image));
		if (image.uploadingChunks === true) {
			dispatch(
				orthoActions.retryFlightElevationImageUploadChunks(
					recordToCreate,
					clientId,
					selectedOrtho,
					chunkSize,
					image,
					flightElevationImage.flightElevationImageId,
					flightElevationImage.numberOfChunksUploaded + 1,
					accessToken
				)
			).catch((e) => {
				console.error(e);
				toast.error("Failed to upload DEM ortho.");
			});
		}
	}

	return (
		<>
			<Form error={validationErrorState}>
				<Message error content={validationErrorMessage} />
				<Form.Group>
					{isDownloading ? (
						<Loader active inline />
					) : (
						<Form.Button
							icon="download"
							disabled={isDeleting || !userAuth.hasApplicationArea(ApplicationArea.FlightDownloadOrtho, clientId)}
							onClick={downloadOrtho}
						/>
					)}
					{isDeleting ? (
						<Loader active inline />
					) : (
						<Modal
							open={deleteModalOpen}
							trigger={
								<Form.Button onClick={handleDeleteModalOpen} icon="trash" disabled={isDownloading}></Form.Button>
							}
						>
							<Modal.Header>Delete Uploaded Flight Elevation Ortho</Modal.Header>
							<Modal.Content>
								<Modal.Description>
									<p>Are you sure you want to delete {selectedOrthoName}?</p>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button floated="right" negative onClick={deleteFlightElevationImage}>
									Delete
								</Button>
								<Button floated="right" onClick={handleDeleteModalOpen}>
									Cancel
								</Button>
								<br style={{ clear: "both" }} />
							</Modal.Actions>
						</Modal>
					)}
					{flightElevationImage?.showRetryButton ||
					(!flightElevationImage?.isUploading && !flightElevationImage?.isUploaded) ? (
						<>
							<Form.Button
								content={"Browse Local..."}
								labelPosition="left"
								icon="file"
								onClick={() => handleFileBrowse()}
							/>
						</>
					) : null}

					{flightElevationImage?.showRetryButton ||
					(!flightElevationImage?.isUploaded && !flightElevationImage?.isUploading && userAuth.isApAdmin) ? (
						<Form.Button icon="cloud" onClick={() => azureBrowseCallback(componentId)} />
					) : null}

					<input
						id="elevationImageUpload"
						ref={fileInputRef}
						type="file"
						accept=".tif"
						hidden
						onChange={fileSelectedHandler}
					/>
					<Form.Input type="text" width="5" readOnly value={selectedOrthoName}></Form.Input>
					{flightElevationImage?.isUploaded ||
					flightElevationImage?.isUploading ||
					flightElevationImage?.showRetryButton ||
					(flightElevationImage?.isUploading === false && flightElevationImage?.isUploaded === undefined) ? null : (
						<Form.Button primary type="submit" onClick={fileUploadHandler}>
							Upload
						</Form.Button>
					)}
					{flightElevationImage?.isUploading ? (
						flightElevationImage?.uploadPercent < 100 ? (
							<div className={styles.centerText}>
								{flightElevationImage.retryingUpload === true ? (
									<p>
										{flightElevationImage.uploadPercent} % - Retrying upload in {flightElevationImage.retryInSeconds}{" "}
										seconds. Attempt: {flightElevationImage.retryAttempt} out of{" "}
										{flightElevationImage.maxNumberRetryAttempts}
									</p>
								) : (
									<p>{flightElevationImage.uploadPercent} %</p>
								)}
							</div>
						) : (
							<>
								<Loader active inline>
									{uploadStatus}
								</Loader>
								{flightElevationImage?.showTooltip ? (
									<Popup
										content="Image is processing on the server. To check if the image has finished processing, reload the page by pressing F5."
										trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
									/>
								) : null}
							</>
						)
					) : null}
					{flightElevationImage?.showRetryButton ? (
						<Form.Button color="red" content="Retry Upload" onClick={retryUpload} />
					) : null}
				</Form.Group>
			</Form>
		</>
	);
};

UploadedElevationImage.propTypes = {
	elevationImage: PropTypes.object,
	sensorId: PropTypes.string,
	clientId: PropTypes.string,
	azureBrowseCallback: PropTypes.func,
	azureUploadSuccessCallback: PropTypes.func,
	selectedAzureFile: PropTypes.object,
	componentId: PropTypes.object
};

export default UploadedElevationImage;
