import isEventAtCoordinates from "@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates.js";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const NewFieldMode = { ...MapboxDraw.modes.draw_line_string };

NewFieldMode.clickAnywhere = function (state, e) {
	if (
		state.currentVertexPosition > 0 &&
		isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])
	) {
		return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
	}
	this.updateUIClasses({ mouse: Constants.cursors.ADD });
	state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
	state.currentVertexPosition++;
	state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
	if (state.currentVertexPosition === 2) {
		return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
	}
};

export default NewFieldMode;
