import * as azure from "@azure/storage-file-share";

const aerialImageUri = process.env.aerialPlotConnections.aerialImageApi.baseUrl;

export function getLogoUrl(clientId, accessToken) {
	return fetch(`${aerialImageUri}api/v1/Download/GetLogoUrl?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			if (data.data.uri && data.data.fileName) {
				const shareClient = new azure.ShareClient(data.data.uri);
				const fileClient = shareClient.getDirectoryClient("documents").getFileClient(data.data.fileName);

				return fileClient.storageClientContext.url;
			} else {
				return null;
			}
		});
}

export function uploadClientLogo(formFile, clientId, accessToken) {
	let formData = new FormData();
	formData.append("formFile", formFile, formFile.name);

	return fetch(`${aerialImageUri}api/v1/Upload/UploadClientLogo?clientId=${clientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function deleteClientLogo(clientId, accessToken) {
	return fetch(`${aerialImageUri}api/v1/Delete/ClientLogo?clientId=${clientId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});
}
