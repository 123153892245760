import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Loader, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import Ortho from "../../../Lumber/OrthoViewer";

const FlightViewer = () => {
	const moduleNavigation = useModuleNavigation();

	//-- Data
	const [flightName, setFlightName] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [imageFound, setImageFound] = useState(null);

	useEffect(() => {
		if (entityNames?.flightId && moduleNavigation.flightId.toUpperCase() === entityNames.flightId.toUpperCase()) {
			setFlightName(entityNames.flightName);
			setLoading(false);
		}
	}, [entityNames]);

	return loading ? (
		<Loader active />
	) : (!loading && !flightName) || imageFound === false ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Flight Viewer</h2>
			<hr />
			<Segment>
				<p>{"This flight could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Flight Viewer</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{flightName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			<Ortho
				level="flight"
				edit={true}
				viewer={true}
				setOrthoImageTypes={() => {}}
				setDraw={() => {}}
				setImageFound={(truth) => setImageFound(truth)}
			/>
		</Segment>
	);
};

export default FlightViewer;
