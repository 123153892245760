import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Accordion, Button, Form, Grid, Icon, Loader, Popup, Segment, Transition } from "semantic-ui-react";
import * as uuid from "uuid";
import * as protocolApi from "../../../../apis/protocolApi";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import MetaTagsTable from "./MetaTagsTable.tsx";
import ProtocolDataSummaryTable from "./ProtocolDataSummaryTable";
import ProtocolSiteSpecificSummaryTable from "./ProtocolSiteSpecificSummaryTable";
import "./style.css";

const ProtocolSummaryTable = ({
	assessmentOptions,
	dataNormalizationOptions,
	trialIds,
	treatmentData,
	dashboardOptions,
	meanComparisonOptions,
	alphaOptions,
	presetOptions,
	defaultMeanComparisonOption,
	defaultAlphaOption,
	presetData,
	setIsDirty,
	setSaveAction
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const getProtocolTrialFilters = async (clientId) => {
		const accessToken = await getTokenSilently();
		const json = protocolApi.getProtocolTrialFilters(clientId, accessToken);
		return json;
	};

	const { isLoading: trialFiltersLoading, data: protocolTrialFilters } = useQuery({
		queryKey: ["protocol trial filters", userAuth.currentClientId],
		queryFn: () => getProtocolTrialFilters(userAuth.currentClientId),
		select: (data) => data.data
	});

	const updateTrialsMetaTags = async (updateInfo) => {
		const accessToken = await getTokenSilently();
		return protocolApi.updateTrialsMetaTags(updateInfo, userAuth.currentClientId, accessToken);
	};

	const { mutate: updateMetaTags } = useMutation({
		mutationFn: (updateInfo) => updateTrialsMetaTags(updateInfo),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["meta factors", userAuth.currentClientId] });
			toast.success("Meta Tags updated successfully!");
		},
		onError: (error) => {
			toast.error("Meta Tags failed to update.");
			console.error(error);
		}
	});

	//-- UI Control
	const [selectedAssessmentOptions, setSelectedAssessmentOptions] = useState(null);
	const [combinedAssessmentOptions, setCombinedAssessmentOptions] = useState(null);
	const [selectedCombinedAssessmentOption, setSelectedCombinedAssessmentOption] = useState(null);
	const [trialsChecked, setTrialsChecked] = useState(null);
	const [initTrialsChecked, setInitTrialsChecked] = useState({});
	const [compiledData, setCompiledData] = useState([]);
	const [compiledTukeyData, setCompiledTukeyData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [assessmentsChecked, setAssessmentsChecked] = useState(null);
	const [treatmentsChecked, setTreatmentsChecked] = useState(null);
	const [activeIndex, setActiveIndex] = useState(false);
	const [selectedMeanComparisonOption, setSelectedMeanComparisonOption] = useState(defaultMeanComparisonOption);
	const [selectedAlphaOption, setSelectedAlphaOption] = useState(defaultAlphaOption);
	const [selectedAssessmentPresetOption, setSelectedAssessmentPresetOption] = useState(null);
	const [compiledMeanComparisonsRunning, setCompiledMeanComparisonsRunning] = useState(true);
	const [hideMeanComparisonResults, setHideMeanComparisonResults] = useState(true);
	const [singleSiteTukeyRunning, setSingleSiteTukeyRunning] = useState(true);
	const [isUpdatingChange, setIsUpdatingChange] = useState(true);
	const [initialTreatmentCheckedList, setInitialTreatmentCheckedList] = useState([]);
	const [hideUncheckedTreatmentData, setHideUncheckedTreatmentData] = useState(false);
	const [hideLetters, setHideLetters] = useState(false);
	const [didCheckedAssessmentsChange, setDidCheckedAssessmentsChange] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [noData, setNoData] = useState(false);
	const [trialFilter, setTrialFilter] = useState({
		value: "",
		filters: {
			quality: [],
			status: [],
			response: []
		}
	});

	const trialFitlerOptions = useMemo(() => {
		const opts =
			protocolTrialFilters?.map((opt) => ({
				key: opt.id,
				text: opt.name,
				filters: {
					quality: opt.trialDataQualityIds,
					status: opt.trialExclusionStatusIds,
					response: opt.trialSiteResponseIds
				},
				value: opt.id
			})) ?? [];
		opts.push({
			key: "custom",
			text: "Custom",
			filters: {},
			value: "custom"
		});

		return opts;
	}, [protocolTrialFilters]);
	//-- Data Sources
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	const [rawTrialData, setRawTrialData] = useState(null);
	const [siteSpecificData, setSiteSpecificData] = useState(null);
	const [trialData, setTrialData] = useState(null);
	const [singleTrialAnovaValues, setSingleTrialAnovaValues] = useState([]);

	//Data Analysis Critera
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	const filteredSiteSpecificData = useMemo(
		() =>
			_.filter(siteSpecificData, (ssd) => {
				return ssd.iscurvemodel == String(true) || ssd.isStandCount == String(true)
					? ssd.assessmentId == selectedCombinedAssessmentOption.assessmentid
					: ssd.assessmentId == selectedCombinedAssessmentOption.assessmentid &&
							ssd.plannedTimingId == selectedCombinedAssessmentOption.plannedtimingid;
			}),
		[siteSpecificData, selectedCombinedAssessmentOption]
	);

	const trialInfo = useMemo(
		() =>
			_.sortBy(
				_.map(
					_.filter(trialData, (td) =>
						_.map(_.uniqBy(filteredSiteSpecificData, "trialId"), "trialId").includes(td.trialInfo.trialId)
					),
					(td) => td.trialInfo
				),
				["farmState", "farmCity", "trialName", "cooperatorName", "trialId"]
			),
		[trialData, filteredSiteSpecificData]
	);

	useEffect(() => {
		getTrialData();
	}, []);

	useEffect(() => {
		initializeTreatmentsChecked();
	}, [treatmentData, trialIds]);

	useEffect(() => {
		if (trialData && trialFilter?.value && trialFilter.value !== "custom") {
			for (let { trialInfo } of trialData) {
				if (
					!trialFilter.filters.status.includes(trialInfo.trialExclusionStatusId) &&
					!trialFilter.filters.response.includes(trialInfo.trialSiteResponseId) &&
					!trialFilter.filters.quality.includes(trialInfo.trialDataQualityId)
				) {
					updateCheckedTrial(true, trialInfo.trialId, true);
				} else {
					updateCheckedTrial(false, trialInfo.trialId, true);
				}
			}
		}
	}, [trialFilter, trialData, compiledMeanComparisonsRunning]);

	useEffect(() => {
		if (treatmentsChecked && trialsChecked) {
			updateCheckedSiteData();
		}
	}, [trialsChecked]);

	useEffect(() => {
		if (siteSpecificData?.length > 0 && isUpdatingChange) {
			updateCompiledData(false);
		}
	}, [isUpdatingChange, siteSpecificData]);

	useEffect(() => {
		if (selectedAssessmentOptions && assessmentsChecked == null && rawTrialData == null) {
			initializeAssessmentsChecked();
			let rawTrialDataByReference = [];
			getAnalysisResults(rawTrialDataByReference);
		}
		setHideMeanComparisonResults(true);
	}, [selectedAssessmentOptions]);

	useEffect(() => {
		if (rawTrialData && reduxProtocolData && selectedAssessmentOptions && trialData) {
			if (!trialsChecked) {
				initializeTrialsChecked();
			}
			let tempRequiredLength = selectedAssessmentOptions.length;
			if (rawTrialData.length == tempRequiredLength) {
				calculateSiteData();
			}
		}
	}, [rawTrialData, reduxProtocolData, trialData]);

	useEffect(() => {
		if (reduxProtocolData) {
			if (!defaultMeanComparisonOption) {
				setSelectedMeanComparisonOption(reduxProtocolData?.meanComparisonId ?? meanComparisonOptions[0]?.key);
			}

			if (!defaultAlphaOption) {
				setSelectedAlphaOption(reduxProtocolData?.alphaId ?? alphaOptions[0]?.key);
			}
		}
	}, [reduxProtocolData]);

	useEffect(() => {
		if (assessmentOptions && dashboardOptions) {
			let tempSelectedAssessmentOptions = [];
			let tempCombinedAssessmentOptions = [];
			let defaultDataNormalizationOption =
				_.find(dataNormalizationOptions, { text: "% MEAN" }) ?? dataNormalizationOptions[0];
			_.map(
				_.filter(assessmentOptions, (assessmentOption) => {
					//ignore all assessments other than curve models
					return assessmentOption.iscurvemodel == String(true) || assessmentOption.isStandCount == String(true);
				}),
				(ao) => {
					//for growth curves there will never be a planned timing
					tempSelectedAssessmentOptions.push({
						assessmentId: ao.key,
						assessmentName: ao.text,
						plannedTimingId: null,
						plannedTimingName: null,
						dataNormalizationId: defaultDataNormalizationOption.key,
						dataNormalizationName: defaultDataNormalizationOption.text,
						isgrounddata: ao.isgrounddata,
						iscurvemodel: ao.iscurvemodel,
						isStandCount: ao.isStandCount ?? "false",
						unitName: ao.unitName,
						curveModelAnalysisId: ao.curvemodelanalysisid,
						analysisId: ao.analysisid,
						analysisTypeId: ao.analysistypeid,
						visible: true
					});

					const guid = uuid.v4();
					tempCombinedAssessmentOptions.push({
						key: guid,
						value: guid,
						text: ao.text,
						assessmentid: ao.key,
						plannedtimingid: null,
						analysistypeid: ao.analysistypeid
					});
				}
			);
			_.map(dashboardOptions.groundDataAssessments, (gda) => {
				tempSelectedAssessmentOptions.push({
					assessmentId: gda.groundDataAssessmentId,
					assessmentName: gda.groundDataAssessmentName,
					plannedTimingId: gda.groundDataGrowthPhaseId,
					plannedTimingName: gda.groundDataGrowthPhaseName,
					dataNormalizationId: defaultDataNormalizationOption.key,
					dataNormalizationName: defaultDataNormalizationOption.text,
					isgrounddata: true,
					iscurvemodel: false,
					isStandCount: false,
					unitName: gda.unitName,
					curveModelAnalysisId: null,
					analysisId: null,
					analysisTypeId: null,
					visible: true
				});

				const guid = uuid.v4();
				//temp combined assessment options is for the site specific table
				tempCombinedAssessmentOptions.push({
					key: guid,
					value: guid,
					text: gda.groundDataAssessmentName + ", " + gda.groundDataGrowthPhaseName,
					assessmentid: gda.groundDataAssessmentId,
					plannedtimingid: gda.groundDataGrowthPhaseId
				});
			});
			setSelectedAssessmentOptions(tempSelectedAssessmentOptions);
			setCombinedAssessmentOptions(tempCombinedAssessmentOptions);

			let defaultAssessmentOption = _.find(tempCombinedAssessmentOptions, (t) => t.text.includes("Total Plant Health"));

			setSelectedCombinedAssessmentOption(defaultAssessmentOption ?? tempCombinedAssessmentOptions[0]);
		}
	}, [assessmentOptions, dashboardOptions]);

	useEffect(() => {
		if (selectedCombinedAssessmentOption && !compiledMeanComparisonsRunning) {
			getSingleTrialAucMeanComparisonData();
		}
	}, [selectedCombinedAssessmentOption]);

	useEffect(() => {
		if (selectedAssessmentPresetOption) {
			updateAssessmentsWithPreset();
		}
	}, [selectedAssessmentPresetOption]);

	function initializeTreatmentsChecked() {
		const tChecked = _.map(treatmentData, (td) => {
			return { trialTreatmentId: td.trialTreatmentId, treatmentChecked: true, dirty: false };
		});
		setTreatmentsChecked(tChecked);
	}

	function initializeTrialsChecked() {
		const tChecked = _.map(trialData, (t) => {
			return { trialId: t.trialInfo.trialId, fieldId: t.trialInfo.fieldId, trialChecked: true };
		});
		setTrialsChecked(tChecked);
		setTrialFilter(trialFitlerOptions.find((opt) => opt.text === "Remove Excluded Trials"));
	}

	function initializeAssessmentsChecked() {
		const aChecked = _.map(selectedAssessmentOptions, (sao) => {
			return {
				assessmentId: sao.assessmentId,
				plannedTimingId: sao.plannedTimingId,
				assessmentChecked: false,
				curveModelAnalysisId: sao.curveModelAnalysisId
			};
		});
		setAssessmentsChecked(aChecked);

		if (!selectedAssessmentPresetOption) {
			setSelectedAssessmentPresetOption(_.find(presetData, { default: true })?.id ?? presetOptions[0]?.key);
		}
	}

	//-- this function gets the data that is used to build out the site specific components
	async function getTrialData() {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getMetadataForTrials(trialIds, userAuth.currentClientId, accessToken))
			.then((res) => {
				//split data up per trial
				setTrialData(res);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	async function getAnalysisResults(rawTrialDataByReference) {
		const accessToken = await getTokenSilently();
		let standCountAnalysisId = _.find(selectedAssessmentOptions, (sao) => {
			return sao.assessmentName.includes("Stand Count (LAI)");
		});
		if (!needToUpdateAnalytics || hideMeanComparisonResults) {
			setLoading(true);
		}
		await Promise.all(
			_.map(trialIds, (trialId) => {
				return dispatch(
					analysisActions.GetPlotAnalysisResultsForTrialForProtocolSummaryTable(
						userAuth.currentClientId,
						trialId,
						accessToken,
						removeOutliers,
						removeDamagedOrExcluded,
						outlierType,
						standCountAnalysisId ? standCountAnalysisId?.analysisId : ""
					)
				).then((res) => {
					return res.data;
				});
			})
		)
			.then((res) => {
				let condensedResponse = [];
				_.map(res, (r) => {
					_.map(r, (ir) => {
						condensedResponse.push(ir);
					});
				});
				let filteredTreatments = [];
				_.map(selectedAssessmentOptions, (sao) => {
					let f = _.filter(condensedResponse, (cr) => {
						return (
							cr.trialTreatmentId <= reduxProtocolData.treatments &&
							cr.excludeFromAssessment === false &&
							(cr.curveModelName == sao.assessmentId ||
								(cr.assessmentId == sao.assessmentId && cr.plannedTimingId == sao.plannedTimingId) ||
								(sao.isStandCount === String(true) && sao.analysisId === cr.analysisId))
						);
					});
					filteredTreatments.push(f);
				});

				rawTrialDataByReference.push(...filteredTreatments);
				let tempRequiredLength = selectedAssessmentOptions.length;
				if (rawTrialDataByReference.length == tempRequiredLength) {
					setRawTrialData(rawTrialDataByReference);
					setIsUpdatingChange(true);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toast.error("Failed to load trial data.");
			});
	}

	function calculateSiteData() {
		let siteData = [];
		let trialTreatmentAllFailures = [];

		_.map(selectedAssessmentOptions, (sao) => {
			const assessment = _.find(assessmentOptions, (ao) => {
				return ao.value === sao.assessmentId;
			});
			let filteredTrialData = _.filter(rawTrialData, (rtd) => {
				return (
					rtd.length > 0 &&
					(sao.isStandCount == String(true)
						? rtd[0].analysisId == sao.assessmentId
						: sao.iscurvemodel == String(true)
						? rtd[0].curveModelName == sao.assessmentId
						: rtd[0].assessmentId == sao.assessmentId && rtd[0].plannedTimingId == sao.plannedTimingId)
				);
			});

			_.map(filteredTrialData, (td) => {
				td = _.filter(td, (d) => !d.failed || (d.curveModelName !== null && d.curveModelName?.includes("AUC")));
				let uniqueTrials = _.uniqBy(td, "trialId");
				//loop over unique trials and return entries from td associated to each trial
				_.map(uniqueTrials, (trial) => {
					//-- Insert new record for failure tracking
					trialTreatmentAllFailures.push({ trialId: trial.trialId, assessmentId: sao.assessmentId, failed: true });

					let filteredTd = _.filter(td, { trialId: trial.trialId });
					const dataNormalization = getDataNormalizationModifier(filteredTd, sao.assessmentId, sao.dataNormalizationId);
					let trialDataTemp = [];
					for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
						var trialTreatmentData = _.map(
							_.filter(filteredTd, (trial) => {
								return (
									trial.trialTreatmentId === i &&
									trial.excluded !== true &&
									(trial.curveModelName == sao.assessmentId ||
										trial.assessmentId == sao.assessmentId ||
										(sao.isStandCount == String(true) && trial.analysisId == sao.assessmentId)) &&
									(trial.groundDatasetAssessmentValue !== null ||
										trial.curveModelAnalysisValue !== null ||
										trial.standCountAnalysisResultValue !== null)
								);
							}),
							assessment.isStandCount === String(true)
								? "standCountAnalysisResultValue"
								: assessment.isgrounddata == String(true)
								? "groundDatasetAssessmentValue"
								: "curveModelAnalysisValue"
						);

						var trialTreatmentPlotData = _.map(
							_.filter(filteredTd, (trial) => {
								return (
									trial.trialTreatmentId === i &&
									trial.excluded !== true &&
									(trial.curveModelName == sao.assessmentId ||
										trial.assessmentId == sao.assessmentId ||
										(sao.isStandCount == String(true) && trial.analysisId == sao.assessmentId)) &&
									(trial.groundDatasetAssessmentValue !== null ||
										trial.curveModelAnalysisValue !== null ||
										trial.standCountAnalysisResultValue !== null)
								);
							}),
							(d) => {
								return {
									plotReplicate: d.plotReplicate,
									plotId: d.plotId,
									fieldId: d.fieldId,
									analysisValue:
										((assessment.isStandCount === String(true)
											? d.standCountAnalysisResultValue
											: assessment.isgrounddata == String(true)
											? d.groundDatasetAssessmentValue
											: d.curveModelAnalysisValue) /
											dataNormalization) *
										getDataNormalizationMultiplier(sao.dataNormalizationId),
									trialTreatmentId: d.trialTreatmentId,
									assessmentId:
										assessment.isStandCount == String(true)
											? d.analysisId
											: assessment.isgrounddata == String(true)
											? d.assessmentId
											: d.curveModelName,
									trialId: d.trialId,
									dataNormalizationId: sao.dataNormalizationId
								};
							}
						);

						const trialMetaData = trialData.find((tdata) => tdata.trialInfo.trialId === trial.trialId).trialInfo;

						if (filteredTd?.length > 0) {
							let siteObject = {
								trialId: filteredTd[0].trialId,
								trialName: filteredTd[0].trialName,
								cooperatorName: trialMetaData.cooperatorName,
								trialTreatmentId: i,
								assessmentId: sao.assessmentId,
								assessmentName: sao.assessmentName,
								iscurvemodel: sao.iscurvemodel,
								isStandCount: sao.isStandCount,
								unitName: filteredTd.unitName,
								plannedTimingId: sao.plannedTimingId,
								average: 0,
								excluded: true,
								stateName: filteredTd[0].stateName,
								city: filteredTd[0].city,
								fieldId: filteredTd[0].fieldId,
								plots: trialTreatmentPlotData,
								trialChecked: trialsChecked
									? trialsChecked.find((tc) => tc.trialId === filteredTd[0].trialId).trialChecked
									: true,
								treatmentChecked: treatmentsChecked
									? treatmentsChecked.find((tc) => tc.trialTreatmentId === i).treatmentChecked
									: true,
								trialDataQualityId: trialMetaData.trialDataQualityId,
								trialExclusionStatusId: trialMetaData.trialExclusionStatusId,
								trialSiteResponseId: trialMetaData.trialSiteResponseId
							};
							if (trialTreatmentData.length > 0) {
								trialTreatmentData = _.map(trialTreatmentData, (data) => {
									return (data / dataNormalization) * getDataNormalizationMultiplier(sao.dataNormalizationId);
								});
								const average = _.meanBy(trialTreatmentData);
								siteObject.average = average;

								siteObject.excluded = false;
								_.find(
									trialTreatmentAllFailures,
									(t) => t.trialId === trial.trialId && t.assessmentId === sao.assessmentId
								).failed = false;
							}
							//Filter out trials with no selected assessment data
							else {
								siteObject.average = null;
							}
							trialDataTemp.push(siteObject);
						}
					}
					siteData = siteData.concat(trialDataTemp);
				});
			});
		});

		//-- Adjust excluded flags for all failed plots
		let failures = _.filter(trialTreatmentAllFailures, (t) => t.failed);
		_.map(failures, (f) => {
			//-- Find matching trial-assessment data
			_.map(
				_.filter(siteData, (s) => s.assessmentId === f.assessmentId && s.trialId === f.trialId),
				(fd) => {
					fd.allFailed = true;
				}
			);
		});

		setSiteSpecificData(siteData);
	}

	function getDataNormalizationMultiplier(dataNormalizationId) {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			dataNormalizationId
		]).text.toLowerCase();

		switch (selectedDataNormalizationName) {
			case "% mean":
			case "% control":
			case "% commercial":
			case "% gsp":
				return 100;
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getDataNormalizationModifier(trialData, assessmentId, dataNormalizationId) {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			dataNormalizationId
		]).text.toLowerCase();
		switch (selectedDataNormalizationName) {
			case "% mean":
				return getPercentMean(trialData, assessmentId);
			case "% control":
			case "% commercial":
			case "% gsp":
				return getPercentByTreatmentType(trialData, assessmentId, dataNormalizationId);
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getPercentMean(trialData, assessmentId) {
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === assessmentId;
		});
		const data = _.map(
			_.filter(trialData, (td) => {
				return (
					td.excluded !== true &&
					(td.groundDatasetAssessmentValue !== null ||
						td.curveModelAnalysisValue !== null ||
						td.standCountAnalysisResultValue !== null)
				);
			}),
			assessment.isStandCount === String(true)
				? "standCountAnalysisResultValue"
				: assessment.isgrounddata == String(true)
				? "groundDatasetAssessmentValue"
				: "curveModelAnalysisValue"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function getPercentByTreatmentType(trialData, assessmentId, dataNormalizationId) {
		const assessment = _.find(assessmentOptions, (ao) => {
			return ao.value === assessmentId;
		});
		const treatmentTypeId = _.find(dataNormalizationOptions, (dno) => {
			return dno.value === dataNormalizationId;
		}).treatmenttypeid;
		const trialTreament = _.find(treatmentData, (td) => {
			return td.treatmentTypeId === treatmentTypeId;
		});
		const data = _.map(
			_.filter(trialData, (td) => {
				return (
					td.trialTreatmentId === trialTreament?.trialTreatmentId &&
					td.excluded !== true &&
					(td.groundDatasetAssessmentValue !== null ||
						td.curveModelAnalysisValue !== null ||
						td.standCountAnalysisResultValue !== null)
				);
			}),
			assessment.isStandCount === String(true)
				? "standCountAnalysisResultValue"
				: assessment.isgrounddata == String(true)
				? "groundDatasetAssessmentValue"
				: "curveModelAnalysisValue"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function updateCompiledData(isForUncheckingTrial, updatedTrialsChecked = null) {
		let averageData = [];
		if (siteSpecificData.length > 0) {
			for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
				const treatment = _.find(treatmentData, { trialTreatmentId: i });

				let updatedSiteData = _.map(siteSpecificData, (ssd) => {
					const treatment = _.find(treatmentsChecked, (tc) => {
						return tc.trialTreatmentId === ssd.trialTreatmentId;
					});

					ssd.treatmentChecked = treatment.treatmentChecked;
					return ssd;
				});

				//Filter out trials with no plot data
				updatedSiteData = _.filter(updatedSiteData, (tda) => {
					return tda?.plots.length > 0;
				});

				//for each treatment we need to get each assessment
				const treatmentAssessmentDataAverages = _.filter(updatedSiteData, (sd) => {
					return (
						sd.trialTreatmentId === i &&
						_.find(treatmentsChecked, (tc) => {
							return tc.trialTreatmentId === sd.trialTreatmentId;
						})?.treatmentChecked !== false &&
						sd.trialChecked !== false
					);
				});

				const treatmentAssessmentDataAveragesForStrikeThrough = _.filter(updatedSiteData, (sd) => {
					return sd.trialTreatmentId === i && sd.trialChecked !== false;
				});

				//Building a list of treatments with missing treatments in the trials. This will help determine which treatment needs an info icon
				let treatmentGroup = _.groupBy(siteSpecificData, "trialTreatmentId");
				let maxNumberOfObjects = _.max(Object.values(treatmentGroup).map((a) => a.length));
				let treatmentsMissingInTrials = [];
				let checkedAssessmentIds = _.map(
					_.filter(assessmentsChecked, (ac) => ac.assessmentChecked),
					"assessmentId"
				);
				let checkedTrialIds = _.map(
					_.filter(updatedTrialsChecked === null ? trialsChecked : updatedTrialsChecked, (tc) => tc.trialChecked),
					"trialId"
				);
				_.map(treatmentGroup, (tg) => {
					let filteredTreatmentGroup = _.filter(
						tg,
						(t) => checkedAssessmentIds.includes(t.assessmentId) && checkedTrialIds.includes(t.trialId)
					);
					if (
						tg.length != maxNumberOfObjects ||
						_.some(filteredTreatmentGroup, (t) => {
							return t.excluded === true && !t.allFailed;
						})
					) {
						treatmentsMissingInTrials.push(tg[0].trialTreatmentId);
					}
				});

				let compiledTreatment = {
					trialTreatmentId: i,
					treatmentTypeName: treatment.treatmentTypeName,
					treatmentName: treatment.name,
					assessmentData: _.map(treatmentAssessmentDataAverages, (tada) => {
						return {
							fieldId: tada.fieldId,
							assessmentId: tada.assessmentId,
							plannedTimingId: tada.plannedTimingId, //this will be null for all growth curve data
							assessmentAverage: tada.average,
							unitName: tada.unitName,
							plots: tada.plots
						};
					}),
					assessmentDataForStrikeThrough: _.map(treatmentAssessmentDataAveragesForStrikeThrough, (tada) => {
						return {
							fieldId: tada.fieldId,
							assessmentId: tada.assessmentId,
							plannedTimingId: tada.plannedTimingId, //this will be null for all growth curve data
							assessmentAverage: tada.average,
							unitName: tada.unitName,
							plots: tada.plots
						};
					}),
					treatmentChecked: _.find(updatedSiteData, (ssd) => {
						return ssd.trialTreatmentId === i;
					})?.treatmentChecked,
					trialId: _.find(siteSpecificData, (ssd) => {
						return ssd.trialTreatmentId === i;
					})?.trialId,
					flightDate: _.find(siteSpecificData, (ssd) => {
						return ssd.trialTreatmentId === i;
					})?.flightDate,
					hasMissingTreatments: treatmentsMissingInTrials.includes(i)
				};
				averageData.push(compiledTreatment);
			}
			setLoading(false);
			setIsUpdatingChange(false);
			setCompiledData(averageData);

			if (!isForUncheckingTrial) {
				getCompiledMeanComparisonData(averageData);
			}
		} else {
			setNoData(true);
		}
	}

	async function getCompiledMeanComparisonData(averageData = null) {
		setCompiledMeanComparisonsRunning(true);
		let allPlotData = [];
		let dataToRemove = [];

		_.map(averageData ?? compiledData, (d) => {
			if (_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === d.trialTreatmentId)?.treatmentChecked) {
				_.map(d.assessmentData, (ad) => {
					let isAssessmentChecked = _.find(assessmentsChecked, {
						assessmentId: ad.assessmentId,
						plannedTimingId: ad.plannedTimingId
					})?.assessmentChecked;

					//-- If any list of plots is empty, assessment failed and we want to remove the entire treatment from getting clds
					if (isAssessmentChecked) {
						if (ad.plots.length > 0) {
							_.map(ad.plots, (p) => {
								allPlotData.push(p);
							});
						} else {
							dataToRemove.push({ assessmentId: ad.assessmentId, trialTreatmentId: d.trialTreatmentId });
						}
					}
				});
			}
		});

		allPlotData = _.filter(
			allPlotData,
			(apd) =>
				_.findIndex(
					dataToRemove,
					(dtr) => dtr.assessmentId === apd.assessmentId && dtr.trialTreatmentId === apd.trialTreatmentId
				) === -1
		);

		let groupedPlotData = _.groupBy(allPlotData, "assessmentId");
		let dataToPass = [];

		let updatedCompiledTukeyData = _.cloneDeep(compiledTukeyData);

		_.map(groupedPlotData, (g) => {
			dataToPass.push(g);
		});

		getSingleTrialAucMeanComparisonData();

		if (_.uniqBy(allPlotData, "trialId")?.length > 1) {
			if (dataToPass.length > 0) {
				await Promise.all(
					_.map(dataToPass, async (plotData) => {
						const accessToken = await getTokenSilently();
						return dispatch(
							statisticsActions.getCompiledTukeyData(
								plotData,
								selectedAlphaOption,
								selectedMeanComparisonOption,
								userAuth.currentClientId,
								accessToken
							)
						).then((res) => {
							return res;
						});
					})
				)
					.then((res) => {
						let condensedData = [];
						_.map(res, (r) => {
							_.map(r.data?.protocolData, (pd) => {
								condensedData.push(pd);
							});
						});

						let tukeyData = _.groupBy(condensedData, "assessmentId");

						_.map(tukeyData, (td) => {
							assignTukeyLetters(updatedCompiledTukeyData, td);
						});

						setCompiledTukeyData(updatedCompiledTukeyData);

						setCompiledMeanComparisonsRunning(false);
						setHideMeanComparisonResults(false);

						if (needToUpdateAnalytics) {
							setNeedToUpdateAnalytics(false);
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error("There was an issue getting mean comparison values for the multi-trial datasets");

						setCompiledMeanComparisonsRunning(false);
						setHideMeanComparisonResults(false);

						if (needToUpdateAnalytics) {
							setNeedToUpdateAnalytics(false);
						}
					});
			} else {
				setCompiledTukeyData(updatedCompiledTukeyData);

				setCompiledMeanComparisonsRunning(false);
				setHideMeanComparisonResults(false);

				if (needToUpdateAnalytics) {
					setNeedToUpdateAnalytics(false);
				}
			}
		} else if (allPlotData.length === 0) {
			setCompiledMeanComparisonsRunning(false);
			setHideMeanComparisonResults(false);

			if (needToUpdateAnalytics) {
				setNeedToUpdateAnalytics(false);
			}
		} else {
			toast.error("Cannot run multi-trial mean comparisons when only 1 trial dataset is available");

			setCompiledMeanComparisonsRunning(false);

			if (needToUpdateAnalytics) {
				setNeedToUpdateAnalytics(false);
			}
		}

		toast.success("All data has finished loading.", { toastId: "allFinished" });
	}

	function assignTukeyLetters(updatedCompiledData, tukeyData) {
		let tukeyDataToAdd = _.cloneDeep(tukeyData);
		if (_.every(tukeyData, (td) => td.tukeyLetters === "a")) {
			_.forEach(tukeyDataToAdd, (td) => (td.tukeyLetters = "-"));
		}

		updatedCompiledData[tukeyData[0].assessmentId] = tukeyDataToAdd;
	}

	async function getSingleTrialAucMeanComparisonData() {
		setSingleSiteTukeyRunning(true);
		let uniqueTrials = _.map(_.map(trialData, "trialInfo"), "trialId");

		let allPlotData = [];
		_.map(rawTrialData, (rtd) => {
			allPlotData = [...allPlotData, ...rtd];
		});

		let filteredTreatmentsChecked = _.map(_.filter(treatmentsChecked, "treatmentChecked"), "trialTreatmentId");
		allPlotData = _.filter(allPlotData, (a) => filteredTreatmentsChecked.includes(a.trialTreatmentId));

		const accessToken = await getTokenSilently();
		await Promise.all(
			_.map(uniqueTrials, (td) => {
				const requestData = {
					data: _.map(
						_.filter(allPlotData, (tpsd) => {
							return (
								tpsd.trialId === td &&
								(tpsd.assessmentId === selectedCombinedAssessmentOption.assessmentid ||
									tpsd.curveModelName === selectedCombinedAssessmentOption.assessmentid ||
									((tpsd.analysisName === "Stand Count (LAI)" || tpsd.analysisName === "Stand Count (LAI_M3M)") &&
										tpsd.analysisId === selectedCombinedAssessmentOption.assessmentid)) &&
								(tpsd.analysisTypeId === selectedCombinedAssessmentOption.analysistypeid ||
									(tpsd.analysisTypeId === "00000000-0000-0000-0000-000000000000" &&
										selectedCombinedAssessmentOption.analysistypeid === undefined)) //-- It seems like ground data has no analysis type so we need to check for empty analysis types
							);
						}),
						(tpsd) => {
							let totalAuc =
								tpsd.standCountAnalysisResultValue !== null && tpsd.standCountAnalysisResultValue !== 0
									? tpsd.standCountAnalysisResultValue //-- Make sure this doesn't come to the frontend as 0
									: tpsd.curveModelDataAnalysisId
									? tpsd.curveModelAnalysisValue
									: tpsd.assessmentId
									? tpsd.groundDatasetAssessmentValue
									: tpsd.plotAnalysisResultValue;

							return {
								trialTreatmentId: tpsd.trialTreatmentId,
								plotReplicate: tpsd.plotReplicate,
								totalAuc: totalAuc
							};
						}
					)
				};

				if (requestData.data?.length > 0) {
					return dispatch(
						statisticsActions.getMeanComparisonForAuc(
							requestData,
							userAuth.currentClientId,
							selectedMeanComparisonOption,
							selectedAlphaOption,
							true,
							accessToken
						)
					).then((res) => {
						res.trialId = td;

						let allLettersAreA = _.every(res.letteringPairs, ["letter", "a"]);

						_.map(res.letteringPairs, (l) => {
							l.trialId = td;
							l.trialTreatmentId = l.treatmentId;
							l.tukeyLetters = allLettersAreA ? "-" : l.letter;
							l.pValue = res.pValue;
							l.cv = res.cv;
						});
						return res;
					});
				} else {
					return Promise.resolve([]);
				}
			})
		).then((res) => {
			res = _.filter(res, (r) => r.letteringPairs);

			setSingleTrialAnovaValues(res);

			setSingleSiteTukeyRunning(false);
		});
	}

	function updateSelectedDataNormalization(index, selectedDataNormalization) {
		if (index != null && selectedDataNormalization) {
			let selectedAssessmentOptionsTemp = _.cloneDeep(selectedAssessmentOptions);
			let selectedDataNormalizationTemp = _.find(dataNormalizationOptions, { key: selectedDataNormalization });
			selectedAssessmentOptionsTemp[index].dataNormalizationId = selectedDataNormalizationTemp?.key;
			selectedAssessmentOptionsTemp[index].dataNormalizationName = selectedDataNormalizationTemp?.text;
			setSelectedAssessmentOptions(selectedAssessmentOptionsTemp);
		}
	}

	function updateCheckedTreatment(value, trialTreatmentId) {
		const updatedTreatmentsChecked = _.map(treatmentsChecked, (tc) => {
			if (tc.trialTreatmentId === trialTreatmentId) {
				tc.treatmentChecked = value;
				tc.dirty = true;
			}

			return tc;
		});
		setTreatmentsChecked(updatedTreatmentsChecked);
		setHideMeanComparisonResults(true);

		//Update unchecked treatments flag for graying and emptying out cells
		let treatmentCheckedList = [];
		let orderedTreatmentList = _.orderBy(updatedTreatmentsChecked, "trialTreatmentId");
		_.filter(orderedTreatmentList, (utc) => {
			if (utc.treatmentChecked) {
				treatmentCheckedList.push(true);
			} else {
				treatmentCheckedList.push(false);
			}
		});

		if (!_.isEmpty(initialTreatmentCheckedList) && !_.isEqual(initialTreatmentCheckedList, treatmentCheckedList)) {
			setHideUncheckedTreatmentData(true);
		} else if (
			_.isEmpty(initialTreatmentCheckedList) &&
			_.some(updatedTreatmentsChecked, (tcl) => !tcl.treatmentChecked)
		) {
			setHideUncheckedTreatmentData(true);
		} else {
			setHideUncheckedTreatmentData(false);
		}
	}

	function updateSelectedAssessmentOptionChecked(assessmentId, plannedTimingId, checked) {
		const updatedAssessmentsChecked = _.map(assessmentsChecked, (ac) => {
			if (ac.assessmentId == assessmentId && ac.plannedTimingId == plannedTimingId) {
				ac.assessmentChecked = checked;
			}

			return ac;
		});
		setAssessmentsChecked(updatedAssessmentsChecked);
	}

	function updateCheckedSiteData() {
		let updatedSiteData = _.map(siteSpecificData, (ssd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === ssd.trialTreatmentId;
			});

			ssd.treatmentChecked = treatment.treatmentChecked;
			return ssd;
		});

		updatedSiteData = _.map(updatedSiteData, (usd) => {
			const trial = _.find(trialsChecked, (tc) => {
				return tc.trialId === usd.trialId && usd.fieldId == tc.fieldId;
			});

			usd.trialChecked = trial.trialChecked;
			return usd;
		});

		setSiteSpecificData(updatedSiteData);
	}

	function updateCheckedTrial(value, trialId, silent = false) {
		if (!silent) {
			setTrialFilter(trialFitlerOptions.find((opt) => opt.text === "Custom"));
		}

		const updatedTrialsChecked = _.map(trialsChecked, (tc) => {
			if (tc.trialId === trialId) {
				tc.trialChecked = value;
				tc.dirty = true;
			}

			return tc;
		});
		setIsUpdatingChange(false);
		setTrialsChecked(updatedTrialsChecked);

		let updatedInitChecked = initTrialsChecked;
		if (updatedInitChecked[trialId] === undefined) {
			updatedInitChecked[trialId] = value;
			setInitTrialsChecked(updatedInitChecked);
		} else {
			setNeedToUpdateAnalytics(true);
		}
		//Logic to hide the auc letters when trials are unchecked

		if (
			Object.keys(initTrialsChecked).length > 0 &&
			!updatedTrialsChecked.every((t) => t.trialChecked === updatedInitChecked[t.trialId])
		) {
			setHideLetters(true);
		} else {
			setHideLetters(false);
		}
		//Update data summary table averages and colors when a trial is checked/unchecked
		if (updatedTrialsChecked?.length > 0) {
			updateCheckedSiteData();
			updateCompiledData(true, updatedTrialsChecked);
		}
	}

	const accordianClick = () => {
		const newIndex = !activeIndex;
		setActiveIndex(newIndex);
	};

	const swapAssessmentUp = (index) => {
		let clonedAssessmentOptions = _.cloneDeep(selectedAssessmentOptions);

		let temp = { ...clonedAssessmentOptions[index], visible: !clonedAssessmentOptions[index - 1].visible };
		clonedAssessmentOptions[index] = {
			...clonedAssessmentOptions[index - 1],
			visible: !clonedAssessmentOptions[index].visible
		};
		clonedAssessmentOptions[index - 1] = temp;

		setSelectedAssessmentOptions(clonedAssessmentOptions);
	};

	const swapAssessmentDown = (index) => {
		let clonedAssessmentOptions = _.cloneDeep(selectedAssessmentOptions);

		let temp = { ...clonedAssessmentOptions[index], visible: !clonedAssessmentOptions[index + 1].visible };
		clonedAssessmentOptions[index] = {
			...clonedAssessmentOptions[index + 1],
			visible: !clonedAssessmentOptions[index].visible
		};
		clonedAssessmentOptions[index + 1] = temp;

		setSelectedAssessmentOptions(clonedAssessmentOptions);
	};

	const updateAssessmentsWithPreset = () => {
		//-- If none, user is customizing selections or page is just loading
		if (selectedAssessmentPresetOption !== "none") {
			//-- Find matching preset
			let matchingPreset = _.find(presetData, { id: selectedAssessmentPresetOption });

			if (matchingPreset) {
				//-- Turn off all assessments
				let updatedAssessmentsChecked = _.map(assessmentsChecked, (ac) => {
					ac.assessmentChecked = false;

					return ac;
				});

				//-- Get all assessments to turn on
				let allAssessmentsToCheck = [];
				_.map(matchingPreset.sections, ({ presetAnalyses, presetCurveModels, presetGroundData }) => {
					allAssessmentsToCheck = [...allAssessmentsToCheck, ..._.map(presetAnalyses, "analysisId")];
					allAssessmentsToCheck = [...allAssessmentsToCheck, ..._.map(presetCurveModels, "curveModelAnalysisId")];
					allAssessmentsToCheck = [...allAssessmentsToCheck, ..._.map(presetGroundData, "groundDataAssessmentId")];
				});

				//-- Turn on assessments that are included in preset
				_.map(updatedAssessmentsChecked, (uac) => {
					if (
						allAssessmentsToCheck.includes(uac.assessmentId) ||
						allAssessmentsToCheck.includes(uac.curveModelAnalysisId)
					) {
						uac.assessmentChecked = true;
					}
				});

				setAssessmentsChecked(updatedAssessmentsChecked);
			}
		}
	};

	async function updateMeanComparisonData() {
		if (_.some(treatmentsChecked, (tc) => tc.dirty) || _.some(trialsChecked, (tc) => tc.dirty)) {
			updateCheckedSiteData();

			let clonedTreatmentsChecked = _.cloneDeep(treatmentsChecked);
			setTreatmentsChecked(
				_.map(clonedTreatmentsChecked, (tc) => {
					tc.dirty = false;
					return tc;
				})
			);

			setTrialsChecked((prev) =>
				_.map(prev, (tc) => {
					tc.dirty = false;
					return tc;
				})
			);
		}

		//Reset button color
		setDidCheckedAssessmentsChange(false);
		//Set up unchecked treatments and trials lists for gray shading and data cell emptying
		setHideUncheckedTreatmentData(false);
		setHideLetters(false);
		setInitTrialsChecked({});

		let treatmentCheckedList = [];
		let orderedTreatmentList = _.orderBy(treatmentsChecked, "trialTreatmentId");
		_.filter(orderedTreatmentList, (utc) => {
			if (utc.treatmentChecked) {
				treatmentCheckedList.push(true);
			} else {
				treatmentCheckedList.push(false);
			}
		});

		setInitialTreatmentCheckedList(treatmentCheckedList);

		if (needToUpdateAnalytics) {
			let rawTrialDataByReference = [];
			setCompiledMeanComparisonsRunning(true);
			await getAnalysisResults(rawTrialDataByReference);
		} else {
			updateCompiledData(false);
		}
	}

	const handleSaveMetaTags = (info) => {
		const dataMap = new Map(info.map((i) => [i.trialId, i]));
		setTrialData((prev) =>
			prev.map((td) => {
				const curr = dataMap.get(td.trialInfo.trialId);
				if (curr) {
					return { ...td, trialInfo: curr };
				}
				return td;
			})
		);
		updateMetaTags(info);
	};

	return noData ? (
		<Segment basic>
			<p>{"We could not find data for this protocol."}</p>
		</Segment>
	) : (
		<Segment basic>
			<Form>
				{!loading ? (
					<>
						<Segment className="ribbonBanner">
							<Accordion fluid>
								<Accordion.Title active={activeIndex} style={{ cursor: "unset" }}>
									<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
										<div
											onClick={accordianClick}
											style={{ display: "flex", fontSize: "1.28571429rem", fontWeight: "700", cursor: "pointer" }}
										>
											<Icon name="dropdown" />
											Summary Table Selections
										</div>
										<div>
											<Form.Field inline>
												<label htmlFor="form-mean-comparison">Mean Comparion</label>
												<Popup
													content="A statistical test or p-value adjustment that evaluates differences among all pairs of treatments"
													trigger={<Icon name="info circle" link style={{ marginLeft: -5 }} />}
												/>
											</Form.Field>
											<Form.Select
												id="form-mean-comparison"
												fluid
												options={meanComparisonOptions}
												onChange={(event, { value }) => {
													setSelectedMeanComparisonOption(value);
													setHideMeanComparisonResults(true);
												}}
												disabled={compiledMeanComparisonsRunning}
												value={selectedMeanComparisonOption}
											/>
										</div>
										<div>
											<Form.Field>
												<label htmlFor="form-alpha-value">Significance/alpha</label>
											</Form.Field>
											<Form.Select
												id="form-alpha-value"
												fluid
												options={alphaOptions}
												onChange={(event, { value }) => {
													setHideMeanComparisonResults(true);
													setSelectedAlphaOption(value);
												}}
												disabled={compiledMeanComparisonsRunning}
												value={selectedAlphaOption}
											/>
										</div>
										<div>
											<Form.Field>
												<label htmlFor="form-presets">Assessment Presets</label>
											</Form.Field>
											<Form.Select
												id="form-presets"
												fluid
												options={presetOptions}
												onChange={(event, { value }) => {
													setSelectedAssessmentPresetOption(value);
													setHideMeanComparisonResults(true);
												}}
												disabled={compiledMeanComparisonsRunning}
												value={selectedAssessmentPresetOption}
											/>
										</div>
										<div>
											<Form.Field>
												<label htmlFor="form-assessment">Remove Plot Exclusions</label>
											</Form.Field>
											<DataAnalysisCriteria
												removeDamagedOrExcluded={removeDamagedOrExcluded}
												removeOutliers={removeOutliers}
												outlierType={outlierType}
												setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
												setRemoveOutliers={setRemoveOutliers}
												setOutlierType={setOutlierType}
												setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
											/>
											<Popup
												trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
												content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
											/>
										</div>
										<div>
											<Form.Field>
												<label htmlFor="form-trial-filters">Trial Filters</label>
											</Form.Field>
											<Form.Select
												style={{ maxWidth: 250 }}
												id="form-trial-filters"
												options={trialFitlerOptions}
												loading={trialFiltersLoading}
												value={trialFilter?.value}
												onChange={(_, { value, options }) => {
													setTrialFilter(options.find((opt) => opt.value === value));
												}}
											/>
										</div>
										<div style={{ alignSelf: "end" }}>
											<Button
												primary
												negative={hideMeanComparisonResults || needToUpdateAnalytics || didCheckedAssessmentsChange}
												fluid
												style={{ marginBottom: "2px" }}
												floated="left"
												disabled={compiledMeanComparisonsRunning || isEditing}
												onClick={() => updateMeanComparisonData()}
												loading={compiledMeanComparisonsRunning}
											>
												Update Analytics
											</Button>
										</div>
									</div>
								</Accordion.Title>
								<Accordion.Content active={activeIndex}>
									<Segment style={{ overflowY: "scroll", overflowX: "hidden", height: "50vh" }}>
										<Grid>
											{_.map(selectedAssessmentOptions, (selectedAssessmentOption, index) => {
												return (
													<Transition
														animation={"bounce"}
														duration={300}
														visible={selectedAssessmentOption.visible}
														key={index}
													>
														<Grid.Row width="16">
															<Grid.Column style={{ width: "40px" }}>
																<Form.Checkbox
																	id={"form-assessment-enabled-" + index}
																	style={index == 0 ? { marginTop: 35 } : { marginTop: 10 }}
																	disabled={compiledMeanComparisonsRunning}
																	checked={
																		_.find(assessmentsChecked, (ac) => {
																			return (
																				ac.assessmentId == selectedAssessmentOption.assessmentId &&
																				(selectedAssessmentOption.iscurvemodel ||
																					ac.plannedTimingId == selectedAssessmentOption.plannedTimingId)
																			);
																		})?.assessmentChecked
																	}
																	onChange={(event, data) => {
																		//-- If user manually clicks checkbox, turn off presets
																		setSelectedAssessmentPresetOption("none");
																		updateSelectedAssessmentOptionChecked(
																			selectedAssessmentOption.assessmentId,
																			selectedAssessmentOption.plannedTimingId,
																			data.checked
																		);
																		//Use only for showing that the update analytics button needs to be clicked to get p-values
																		setDidCheckedAssessmentsChange(true);
																	}}
																/>
															</Grid.Column>
															<Grid.Column width="3">
																{index == 0 && (
																	<Form.Field>
																		<label htmlFor="form-assessment">Assessment</label>
																	</Form.Field>
																)}
																<Form.Input
																	id="form-assessment"
																	fluid
																	disabled={true}
																	value={selectedAssessmentOption.assessmentName}
																/>
															</Grid.Column>
															<Grid.Column width="3">
																{index == 0 && (
																	<Form.Field>
																		<label htmlFor="form-planned-timing">Planned Timing</label>
																	</Form.Field>
																)}
																<Form.Input
																	id="form-planned-timing"
																	fluid
																	disabled={true}
																	value={
																		selectedAssessmentOption.plannedTimingName
																			? selectedAssessmentOption.plannedTimingName
																			: ""
																	}
																/>
															</Grid.Column>
															<Grid.Column width="3">
																{index == 0 && (
																	<Form.Field>
																		<label htmlFor="form-data-normalization">Data Normalization</label>
																	</Form.Field>
																)}
																<Form.Select
																	id="form-data-normalization"
																	fluid
																	options={dataNormalizationOptions}
																	onChange={(event, { value }) => {
																		updateSelectedDataNormalization(index, value);
																		setNeedToUpdateAnalytics(true);
																	}}
																	disabled={compiledMeanComparisonsRunning}
																	value={selectedAssessmentOption.dataNormalizationId}
																/>
															</Grid.Column>
															<Grid.Column width="3">
																{index == 0 && (
																	<Form.Field style={{ marginLeft: "35px" }}>
																		<label htmlFor="form-arrows">Swap Order</label>
																	</Form.Field>
																)}
																{index != 0 && (
																	<Button
																		onClick={() => swapAssessmentUp(index)}
																		disabled={compiledMeanComparisonsRunning}
																	>
																		<Icon name="angle up" size="large" style={{ margin: 0 }} />
																	</Button>
																)}
																{index != selectedAssessmentOptions.length - 1 && (
																	<Button
																		onClick={() => swapAssessmentDown(index)}
																		disabled={compiledMeanComparisonsRunning}
																		style={{ marginLeft: index == 0 ? "70px" : 0 }}
																	>
																		<Icon name="angle down" size="large" style={{ margin: 0 }} />
																	</Button>
																)}
															</Grid.Column>
														</Grid.Row>
													</Transition>
												);
											})}
										</Grid>
									</Segment>
								</Accordion.Content>
							</Accordion>
						</Segment>
						<Grid>
							{_.filter(assessmentsChecked, (ac) => ac.assessmentChecked)?.length > 0 ? (
								<>
									<Grid.Row centered style={{ overflow: "auto", flexWrap: "nowrap" }}>
										<Grid.Column>
											<Form.Field>
												<ProtocolDataSummaryTable
													compiledData={compiledData}
													tukeyData={compiledTukeyData}
													selectedAssessmentOptions={_.filter(selectedAssessmentOptions, (sao) => {
														return _.find(assessmentsChecked, (ac) => {
															return (
																ac.curveModelName == sao.assessmentId ||
																(ac.assessmentId == sao.assessmentId && ac.plannedTimingId == sao.plannedTimingId)
															);
														})?.assessmentChecked;
													})}
													updateCheckedTreatment={updateCheckedTreatment}
													treatmentsChecked={treatmentsChecked}
													disableCheckBoxes={singleSiteTukeyRunning || compiledMeanComparisonsRunning}
													compiledMeanComparisonsRunning={compiledMeanComparisonsRunning}
													selectedAssessmentPresetOption={selectedAssessmentPresetOption}
													presetData={presetData}
													trialsChecked={trialsChecked}
													hideUncheckedTreatmentData={hideUncheckedTreatmentData}
													hideLetters={hideLetters}
												/>
											</Form.Field>
											<br style={{ clear: "both" }} />
										</Grid.Column>
									</Grid.Row>
								</>
							) : (
								<>
									<Segment style={{ marginTop: 15, marginBottom: 15, width: "100%" }} textAlign="center">
										<h2>{"No assessments selected."}</h2>
									</Segment>
								</>
							)}
							<hr style={{ width: "100%" }} />
							<Grid.Row centered style={{ textAlign: "center" }}>
								<h2>Site-Specific Data</h2>
							</Grid.Row>
							<Grid.Row centered style={{ textAlign: "center" }}>
								<Grid.Column width="3" style={{ width: "25%" }}>
									<Form.Select
										id="form-data-normalization"
										fluid
										options={combinedAssessmentOptions}
										disabled={compiledMeanComparisonsRunning || singleSiteTukeyRunning}
										onChange={(event, { value }) => {
											setSelectedCombinedAssessmentOption(_.filter(combinedAssessmentOptions, { key: value })[0]);
										}}
										value={selectedCombinedAssessmentOption?.key}
									/>
								</Grid.Column>
							</Grid.Row>
							{siteSpecificData?.length > 0 && trialData && (
								<div className="trialTableWrapper">
									<Grid.Row style={{ padding: 0 }}>
										<MetaTagsTable
											trialInfo={trialInfo}
											onSave={handleSaveMetaTags}
											setSaveAction={setSaveAction}
											setIsDirty={setIsDirty}
											setIsEditing={setIsEditing}
										/>
									</Grid.Row>
									<Grid.Row style={{ padding: 0 }}>
										<ProtocolSiteSpecificSummaryTable
											siteSpecificData={filteredSiteSpecificData}
											updateCheckedTrial={updateCheckedTrial}
											appenedPercent={_.find(selectedAssessmentOptions, (sao) => {
												return (
													(sao.assessmentId == selectedCombinedAssessmentOption.assessmentid &&
														sao.plannedTimingId == selectedCombinedAssessmentOption.plannedtimingid) ||
													sao.assessmentId == selectedCombinedAssessmentOption.assessmentid
												);
											})?.dataNormalizationName?.includes("%")}
											disableCheckBoxes={compiledMeanComparisonsRunning || singleSiteTukeyRunning}
											tukeyData={_.flatten(
												_.map(singleTrialAnovaValues, (data) => {
													return data.letteringPairs;
												})
											)}
											tukeyDataRunning={compiledMeanComparisonsRunning || singleSiteTukeyRunning}
											hideMeanComparisonResults={hideMeanComparisonResults}
											treatmentsChecked={treatmentsChecked}
											hideUncheckedTreatmentData={hideUncheckedTreatmentData}
											hideLetters={hideLetters}
										/>
									</Grid.Row>
								</div>
							)}
						</Grid>
					</>
				) : (
					<Grid.Row>
						<Grid.Column width="16">
							<Loader active></Loader>
						</Grid.Column>
					</Grid.Row>
				)}
			</Form>
		</Segment>
	);
};

ProtocolSummaryTable.propTypes = {
	assessmentOptions: PropTypes.array,
	plannedTimingOptions: PropTypes.array,
	dataNormalizationOptions: PropTypes.array,
	treatmentData: PropTypes.array,
	trialIds: PropTypes.array,
	dashboardOptions: PropTypes.object,
	meanComparisonOptions: PropTypes.array,
	alphaOptions: PropTypes.array,
	presetOptions: PropTypes.array,
	defaultMeanComparisonOption: PropTypes.string,
	defaultAlphaOption: PropTypes.string,
	presetData: PropTypes.array,
	setIsDirty: PropTypes.func,
	setSaveAction: PropTypes.func
};

export default ProtocolSummaryTable;
