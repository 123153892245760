import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import Tooltip from "rc-tooltip";
import {
	Button,
	Divider,
	Dropdown,
	Form,
	Grid,
	Icon,
	Message,
	Modal,
	Pagination,
	Segment,
	Table,
	TableCell
} from "semantic-ui-react";
import InspectPlotData from "../../InspectPlotData";
import "./styles.css";

import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import DataSetModal from "./datasetmodal";

import { toast } from "react-toastify";
import * as groundDataActions from "../../../../redux/actions/groundDataActions";
import * as protocolActions from "../../../../redux/actions/protocolActions";

import { Prompt, useHistory } from "react-router-dom";
import ProtocolFavoritingWidget from "../../../Content/Widgets/Favorites/ProtocolFavoritingWidget";
import { ApplicationArea } from "../../ApplicationAreas";

const NewGroundData = ({
	groundDataQuestions,
	growthStages,
	persons,
	outlineData,
	fileName,
	onSave,
	isForProtocol = false,
	onBack,
	protocolName
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();
	const DATE_FORMAT = "MM/DD/YYYY h:mm A";

	//-- dataset Modal
	const [openAddDatasetModal, setOpenAddDatasetModal] = useState(false);

	//-- Data Sources
	const [personNames, setPersonNames] = useState([]);
	const [groundDataAssessments, setGroundDataAssessments] = useState([]);
	const [groundDataAssessmentTypes, setGroundDataAssessmentTypes] = useState([]);
	const [groundDataUnits, setGroundDataUnits] = useState([]);
	const [groundDataMethods, setGroundDataMethods] = useState([]);
	const [existingPlots, setExistingPlots] = useState([]);

	const [csvData, setCsvData] = useState([]);
	const [numOfPlots, setNumOfPlots] = useState(0);
	const userAuth = useUserAuth();

	//-- Selected Options
	const [groundDataLayout, setGroundDataLayout] = useState(null);
	const [groundDataLayoutInspection, setGroundDataLayoutInspection] = useState(null);

	//-- UI Control
	const [saving, setSaving] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasExistingData, setHasExistingData] = useState(false);
	const [confirmNavModal, setConfirmNavModal] = useState(false);
	const [anyChanges, setAnyChanges] = useState(false);

	//-- dataset Assessments
	const [newDataSet, setNewDataSets] = useState(null);
	const [groundDataSets, setGroundDataSets] = useState([]);
	const [selectedGrounddataSet, setSelectedGrounddataSet] = useState(null);

	//-- dataset Assessments Table
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [path, setPath] = useState("");

	useEffect(() => {
		if (confirmNavModal && !anyChanges && path) {
			history.push(path);
		}
	}, [confirmNavModal, anyChanges, path]);

	useEffect(() => {
		if (groundDataSets.length === 0 && groundDataQuestions) {
			checkForDatasets();
		}
	}, []);

	useEffect(() => {
		if (newDataSet) {
			let sets = groundDataSets;
			let newDatasetId;
			if (sets.length > 0) {
				//find the largest id within the dataset list and add 1
				newDatasetId = _.maxBy(groundDataSets, "datasetNumericalId").datasetNumericalId + 1;
			} else {
				newDatasetId = 1;
			}
			newDataSet.datasetNumericalId = newDatasetId;
			setGroundDataSets((oldDataSet) => [...oldDataSet, newDataSet]);
			setTotalPages(Math.ceil(sets.length / itemsPerPage));
		}
	}, [newDataSet]);

	useEffect(() => {
		if (groundDataQuestions) {
			initializeGroundDataAssessments(groundDataQuestions.groundDataAssessments);
			initializeGroundDataAssessmentTypes(groundDataQuestions.groundDataAssessmentTypes);
			initializeGroundDataUnits(groundDataQuestions.groundDataUnits);
			initializeGroundDataMethods(groundDataQuestions.groundDataMethods);
		}
	}, [groundDataQuestions]);

	useEffect(() => {
		if (persons) {
			initializePersons();
		}
	}, [persons]);

	useEffect(() => {
		if (outlineData) {
			initializeCsvData();
		}
	}, [outlineData, groundDataSets]);

	function saveDataSet(dataset) {
		setNewDataSets(dataset);
		setAnyChanges(true);
	}

	function validateDataSet(dataset) {
		//check to see if the collected date is the same as another dataset
		if (dataset.groundDataset.selectedTimingMethodType === "Date") {
			if (
				_.some(groundDataSets, (gds) => {
					return (
						moment(gds.groundDataset.plannedTiming).format(DATE_FORMAT) ==
							moment.utc(dataset.groundDataset.plannedTiming, DATE_FORMAT).format(DATE_FORMAT) &&
						dataset.datasetNumericalId != gds.datasetNumericalId
					);
				})
			) {
				return "You already have a dataset planned for this time. Please choose a different date & time";
			}
		}
		if (
			dataset.groundDataset.actualDateCollected != "Invalid date" &&
			_.some(groundDataSets, (gds) => {
				return (
					moment(gds.groundDataset.actualDateCollected).format(DATE_FORMAT) ==
						moment.utc(dataset.groundDataset.actualDateCollected, DATE_FORMAT).format(DATE_FORMAT) &&
					dataset.datasetNumericalId != gds.datasetNumericalId
				);
			})
		) {
			return "You already have a dataset collected at this time. Please choose a different date & time";
		}
	}

	function editDataSet(dataset) {
		//edit the existing dataset in the list of datasets
		const gdsTemp = _.map(groundDataSets);
		let index = _.findIndex(groundDataSets, { datasetNumericalId: dataset.datasetNumericalId });
		gdsTemp[index] = dataset;
		setGroundDataSets(gdsTemp);
		setAnyChanges(true);
	}

	function deleteDataSet(dataset) {
		const gdsTemp = _.map(groundDataSets);
		let index = _.findIndex(groundDataSets, { datasetNumericalId: dataset.datasetNumericalId });
		gdsTemp.splice(index, 1);
		setGroundDataSets(gdsTemp);
		setAnyChanges(true);
	}

	async function checkForDatasets() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(
			groundDataActions.getGroundDatasets(
				userAuth.currentClientId,
				accessToken,
				moduleNavigation.trialId === null ? moduleNavigation.fieldId : null,
				moduleNavigation.trialId ?? null,
				moduleNavigation.protocolId ?? null
			)
		)
			.then((res) => {
				const plotMap = _.reduce(
					res.groundDataPlots,
					(acc, curr) => {
						if (acc[curr.groundDatasetAssessmentDataId]?.length) {
							acc[curr.groundDatasetAssessmentDataId].push(curr.value);
						} else {
							acc[curr.groundDatasetAssessmentDataId] = [curr.value];
						}

						return acc;
					},
					{}
				);

				const assessmentMap = _.reduce(
					res.groundDatasetAssessmentData,
					(acc, curr) => {
						if (acc[curr.groundDatasetId]?.length) {
							acc[curr.groundDatasetId].push(...plotMap[curr.id]);
						} else {
							acc[curr.groundDatasetId] = plotMap[curr.id];
						}

						return acc;
					},
					{}
				);

				setExistingPlots(res.groundDataPlots);

				let existingGroundDatasets = [];
				_.map(res.groundDatasets, (dataset) => {
					let plannedTiming = null;
					if (dataset.plannedTimingMethodType !== "None") {
						if (dataset.plannedTimingMethodType === "Date") {
							plannedTiming = moment(dataset.plannedTimingDateUtc).format(DATE_FORMAT);
						} else if (dataset.plannedTimingMethodType === "Text") {
							plannedTiming = dataset.plannedTimingText;
						} else if (dataset.plannedTimingMethodType === "StartEnd") {
							plannedTiming = {
								growthStageStart: dataset.plannedTimingGrowthStageStartId,
								growthStageEnd: dataset.plannedTimingGrowthStageEndId
							};
						} else if (dataset.plannedTimingMethodType === "int") {
							plannedTiming = dataset.plannedTimingInteger;
						} else if (dataset.plannedTimingMethodType === "Start") {
							plannedTiming = {
								growthStageStart: dataset.plannedTimingGrowthStageStartId
							};
						}
					}

					let hasData = false;
					if (assessmentMap[dataset.id]?.length > 0) {
						hasData = assessmentMap[dataset.id][0] !== undefined;
					}

					let existingDataset = {
						clientId: userAuth.currentClientId,
						datasetNumericalId: dataset.datasetNumericalId,
						datasetId: dataset.id,
						fieldHasImportedData: true,
						assessments: [],
						trials: [],
						groundDataset: {
							actualDateCollected: dataset.dateCollectedUtc
								? moment(dataset.dateCollectedUtc).format(DATE_FORMAT)
								: "Invalid date",
							currentDateCollected: dataset.dateCollectedUtc
								? moment.utc(dataset.dateCollectedUtc).local().format(DATE_FORMAT)
								: "Invalid date",
							collectedByPersonId: dataset.collectedByPersonId,
							groundDatasetTemplateId: dataset.groundDatasetTemplateId,
							growthPhaseId: dataset.plannedGrowthPhaseId,
							growthStageId: dataset.actualGrowthStageId,
							plannedTiming: plannedTiming,
							selectedTimingMethodId: dataset.plannedTimingMethodId,
							selectedTimingMethodType: dataset.plannedTimingMethodType,
							hasData: hasData
						}
					};

					existingGroundDatasets.push(existingDataset);
				});

				let filteredGroundDatasetAssessmentData = [];
				let groundDataAssessmentDataAverages = [];
				_.map(res.groundDatasetAssessmentData, (gdad) => {
					let averageAssessment = _.find(
						groundDataQuestions.groundDataAssessments,
						(gda) => gda.averageFor !== "00000000-0000-0000-0000-000000000000" && gda.id === gdad.groundDataAssessmentId
					);
					if (averageAssessment) {
						groundDataAssessmentDataAverages.push({ ...gdad, averageFor: averageAssessment?.averageFor });
					} else {
						filteredGroundDatasetAssessmentData.push(gdad);
					}
				});

				_.map(_.sortBy(filteredGroundDatasetAssessmentData, "order"), (gdad) => {
					let matchingAverage =
						_.find(groundDataAssessmentDataAverages, {
							averageFor: gdad.groundDataAssessmentId,
							groundDatasetId: gdad.groundDatasetId
						}) ?? null;

					let groundDatasetAssessmentsForDataset = _.map(
						_.filter(filteredGroundDatasetAssessmentData, (fgdad) => fgdad.groundDatasetId === gdad.groundDatasetId),
						"groundDataAssessmentId"
					);
					let calculated = _.find(groundDataQuestions.groundDataCalculations, (gdc) => {
						return gdc.groundDataAssessmentId === gdad.groundDataAssessmentId;
					});
					let calculationForAssessmentId = null;
					if (calculated) {
						if (groundDatasetAssessmentsForDataset.includes(calculated.calculationForAssessmentId)) {
							calculationForAssessmentId = calculated.calculationForAssessmentId;
						}
					}
					let existingAssessment = {
						assessment: _.find(groundDataQuestions.groundDataAssessments, { id: gdad.groundDataAssessmentId }) ?? null,
						assessmentId: gdad.groundDataAssessmentId,
						assessmentAverage: matchingAverage
							? {
									..._.find(groundDataQuestions.groundDataAssessments, { id: matchingAverage.groundDataAssessmentId }),
									groundDatasetAssessmentDataId: matchingAverage.id
							  }
							: null,
						groundDataSampleDefinitionPart:
							_.find(groundDataQuestions.groundDataSampleDefinitionParts, {
								id: gdad.groundDataSampleDefinitionPartId
							}) ?? null,
						groundDataSampleDefinitionType:
							_.find(groundDataQuestions.groundDataSampleDefinitionTypes, {
								id: gdad.groundDataSampleDefinitionTypeId
							}) ?? null,
						groundDatasetAssessmentDataId: gdad.id,
						id: gdad.order,
						length: gdad.length,
						method: _.find(groundDataQuestions.groundDataMethods, { id: gdad.groundDataMethodId }) ?? null,
						name: null, // ??
						calculationForAssessmentId: calculationForAssessmentId,
						width: gdad.width,
						sampleDefinitionNumber: gdad.sampleDefinitionNumber,
						samplesPerPlot: _.find(groundDataQuestions.groundDataSamples, { id: gdad.groundDataSampleId }) ?? null,
						source: _.find(groundDataQuestions.groundDataSources, { id: gdad.groundDataSourceId }) ?? null,
						templateId: gdad.groundDatasetTemplateId,
						unit: _.find(groundDataQuestions.groundDataUnits, { id: gdad.groundDataUnitId }) ?? null,
						radius: gdad.radius,
						notes: gdad.notes,
						device: _.find(groundDataQuestions.groundDataDevices, { id: gdad.groundDataDeviceId }) ?? null
					};

					_.find(existingGroundDatasets, { datasetId: gdad.groundDatasetId })?.assessments.push(existingAssessment);
				});

				if (existingGroundDatasets.length > 0) {
					setHasExistingData(true);
				}

				if (moduleNavigation.datasetId) {
					let existingDataset = _.find(existingGroundDatasets, { datasetId: moduleNavigation.datasetId });

					if (existingDataset) {
						editDataset(existingDataset);
					}
				}

				setGroundDataSets(existingGroundDatasets);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	function initializePersons() {
		const personOpts = _.map(persons, (ps) => {
			const opt = {
				personId: ps.personId,
				fullName: ps.firstName + " " + ps.lastName
			};
			return opt;
		});

		setPersonNames(personOpts);
	}

	function initializeGroundDataAssessments(assessments) {
		setGroundDataAssessments(assessments);
	}

	function initializeGroundDataAssessmentTypes(assessmentTypes) {
		setGroundDataAssessmentTypes(assessmentTypes);
	}

	function initializeGroundDataUnits(units) {
		setGroundDataUnits(units);
	}

	function initializeGroundDataMethods(methods) {
		setGroundDataMethods(methods);
	}

	function initializeCsvData() {
		if (outlineData && outlineData.length > 0 && groundDataSets && groundDataSets.length > 0) {
			setCsvData(
				outlineData.map((tod) => {
					let csvRecord = {
						Field: tod.field,
						Range: tod.range,
						Column: tod.column,
						Company: tod.company,
						Trial: tod.trial,
						Treatment: tod.trialTreatmentId,
						Plot: tod.plot,
						Rep: tod.rep,
						Seed: tod.seed
					};

					let assessmentNames = [];

					_.map(groundDataSets, ({ groundDataset, assessments, datasetId }) => {
						if (groundDataset.actualDateCollected !== null && groundDataset.actualDateCollected !== "Invalid date") {
							_.map(assessments, (a) => {
								if (a.source.name.toLowerCase() !== "calculated") {
									let formattedDate = moment
										.utc(groundDataset.actualDateCollected, DATE_FORMAT)
										.local()
										.format(DATE_FORMAT);

									if (a.samplesPerPlot && a.samplesPerPlot.name !== "1") {
										for (let sample = 1; sample <= parseInt(a.samplesPerPlot.name); sample++) {
											let assessmentName = `${a.assessment.name} - ${sample} - ${formattedDate}`;
											assessmentNames.push({
												name: assessmentName,
												id: a.assessment.id,
												subsample: sample,
												datasetId: datasetId
											});
										}
									} else {
										let assessmentName = `${a.assessment.name} - ${formattedDate}`;
										assessmentNames.push({
											name: assessmentName,
											id: a.assessment.id,
											subsample: 1,
											datasetId: datasetId
										});
									}
								}
							});
						}
					});

					// Initially set all assessments to null
					_.map(assessmentNames, (an) => {
						csvRecord[an.name] = null;
					});

					_.map(groundDataSets, ({ assessments, datasetId }) => {
						_.map(assessments, ({ groundDatasetAssessmentDataId, assessmentId }) => {
							let matchingPlots = _.filter(existingPlots, {
								groundDatasetAssessmentDataId: groundDatasetAssessmentDataId,
								plotId: tod.plotId
							});

							if (matchingPlots.length > 0) {
								_.map(
									_.filter(assessmentNames, (an) => an.id === assessmentId && an.datasetId === datasetId),
									(an) => {
										let matchingPlot = _.find(matchingPlots, { subsample: an.subsample });
										csvRecord[an.name] = matchingPlot?.value;
									}
								);
							}
						});
					});

					return csvRecord;
				})
			);

			setNumOfPlots(outlineData.length);
		}
	}

	function fileSelectedHandler(file) {
		setGroundDataLayout(file);
	}

	const fileRemovedHandler = () => {
		setGroundDataLayout(null);
	};

	function groundDataInspectionSuccessHandler(inspectionResult) {
		setGroundDataLayoutInspection(inspectionResult);
		console.log(groundDataLayoutInspection);
	}

	//-- TODO update this function to handle the new datasetList
	//-- Just pass the object to the createGroundData function and we will make the changes there
	async function saveGroundData() {
		let groundDataSetsObj = createGroundDataSetObject();
		setSaving(true);
		const accessToken = await getTokenSilently();
		if (isForProtocol) {
			dispatch(
				protocolActions.saveAssessments(
					groundDataSetsObj,
					moduleNavigation.protocolId,
					userAuth.currentClientId,
					accessToken
				)
			)
				.then(() => {
					toast.success("Datasets saved successfully.");
					setSaving(false);
				})
				.catch((err) => {
					setSaving(false);
					if (!err?.messages && !err?.data) {
						toast.error("Failed to save ground data.");
					} else if (Array.isArray(err.messages) && err.messages.length > 0) {
						_.map(err.messages, ({ text }) => {
							toast.error(text);
						});
					} else if (err.data.Message) {
						toast.error(err.data.Message);
					} else {
						toast.error(err.data);
					}
				});
		} else {
			if (hasExistingData) {
				dispatch(
					groundDataActions.editGroundData(
						groundDataLayout,
						groundDataSetsObj,
						outlineData,
						moduleNavigation.trialId ? null : moduleNavigation.fieldId,
						moduleNavigation.trialId,
						accessToken
					)
				)
					.then(() => {
						toast.success("Ground Data uploaded successfully.");
						setSaving(false);
						setAnyChanges(false);
						onSave();
					})
					.catch((err) => {
						setSaving(false);
						if (!err?.messages && !err?.data) {
							toast.error("Failed to save ground data.");
						} else if (Array.isArray(err.messages) && err.messages.length > 0) {
							_.map(err.messages, ({ text }) => {
								toast.error(text);
							});
						} else if (err.data.Message) {
							toast.error(err.data.Message);
						} else {
							toast.error(err.data);
						}
					});
			} else {
				dispatch(
					groundDataActions.createGroundData(
						groundDataLayout,
						groundDataSetsObj,
						outlineData,
						moduleNavigation.trialId,
						accessToken
					)
				)
					.then(() => {
						toast.success("Ground Data uploaded successfully.");
						setSaving(false);
						setAnyChanges(false);
						onSave();
					})
					.catch((err) => {
						if (Array.isArray(err.messages) && err.messages.length > 0) {
							_.map(err.messages, ({ text }) => {
								toast.error(text);
							});
						} else {
							toast.error(err.data);
						}
						setSaving(false);
					});
			}
		}
	}

	function createGroundDataSetObject() {
		let validations = [];
		_.map(groundDataSets, (groundDataSet) => {
			validations = validations.concat(
				_.filter(groundDataSet.assessments, (assessment) => {
					let groundDataSource = _.find(groundDataQuestions.groundDataSources, {
						id: assessment.source.id
					});
					return groundDataSource && groundDataSource.name !== "Calculated";
				})
			);
		});
		validations = _.uniqBy(validations, (validation) => {
			return validation.assessment.id;
		});

		let tempGroundDatasets = _.cloneDeep(groundDataSets);
		_.map(groundDataSets, ({ assessments, datasetNumericalId }) => {
			let assessmentsToAdd = [];

			_.map(assessments, (a) => {
				if (a.assessmentAverage !== null && a.assessmentAverage !== undefined) {
					const newAssessment = {
						...a,
						source: _.find(groundDataQuestions.groundDataSources, { name: "Calculated" }),
						assessmentAverage: null,
						assessmentId: a.assessmentAverage.id,
						assessment: a.assessmentAverage,
						samplesPerPlot: null,
						groundDatasetAssessmentDataId: a.assessmentAverage.groundDatasetAssessmentDataId ?? null,
						id: a.assessmentAverage.groundDatasetAssessmentDataId ?? null,
						device: null,
						averageFor: a.assessmentId
					};

					assessmentsToAdd.push(newAssessment);
				}
			});

			if (assessmentsToAdd.length > 0) {
				let matchingDatasetIndex = _.findIndex(tempGroundDatasets, { datasetNumericalId: datasetNumericalId });

				if (matchingDatasetIndex !== -1) {
					tempGroundDatasets[matchingDatasetIndex].assessments =
						tempGroundDatasets[matchingDatasetIndex].assessments.concat(assessmentsToAdd);
				}
			}
		});

		let groundDataSetObj = {
			clientId: userAuth.currentClientId,
			groundDataSets: tempGroundDatasets,
			fieldHasImportedData: false,
			groundDataValidation: _.map(validations, (validation) => {
				let groundDataUnit = _.find(groundDataUnits, {
					id: validation.unit?.id
				});
				return {
					assessmentName: validation.assessment.name,
					dataType: groundDataUnit?.dataType,
					numOfPlots: numOfPlots
				};
			})
		};
		return groundDataSetObj;
	}

	function handleAddDatasetModalOpen() {
		setOpenAddDatasetModal(!openAddDatasetModal);
	}

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/dateCollected/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setGroundDataSets(
					_.sortBy(
						groundDataSets,
						function (d) {
							return moment.utc(d[clickedColumn]).local().format(DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else {
				setGroundDataSets(
					_.sortBy(groundDataSets, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			setGroundDataSets(groundDataSets);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setGroundDataSets(groundDataSets.reverse());
		setGroundDataSets(groundDataSets);
	}

	function editDataset(datasetToEdit) {
		setIsEditing(true);
		setSelectedGrounddataSet(datasetToEdit);
		handleAddDatasetModalOpen();
	}
	function onClose() {
		setSelectedGrounddataSet(null);
		setIsEditing(false);
	}

	return (
		<>
			<Segment basic loading={loading}>
				{anyChanges && <Message warning header="Unsaved Changes!" content="There are unsaved changes on this page" />}
				{isForProtocol && (
					<>
						<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
							<Button
								id="form-button-save"
								floated="right"
								primary
								loading={saving}
								disabled={
									saving || !userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)
								}
								content={"Save"}
								onClick={() => {
									saveGroundData();
								}}
							/>
							<Button
								id="form-button-continue"
								floated="right"
								color="green"
								loading={saving}
								disabled={
									saving || !userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)
								}
								content={"Save and Continue"}
								onClick={() => {
									saveGroundData().then(() => {
										setAnyChanges(false);
										onSave(true);
									});
								}}
							/>
							<Button
								id="form-button-back"
								floated="left"
								secondary
								loading={saving}
								disabled={saving}
								content={"Back"}
								onClick={() => {
									onBack();
								}}
							/>
						</Segment>
						<Segment basic>
							<h2 style={{ float: "left" }}>Assessments</h2>
							<div style={{ float: "right" }}>
								<ProtocolFavoritingWidget
									style={{ display: "inline" }}
									clientId={userAuth.currentClientId.toUpperCase()}
									protocolId={moduleNavigation.protocolId.toUpperCase()}
									userSettings={userSettings}
								></ProtocolFavoritingWidget>
								<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
									<i>{protocolName}</i>
								</h2>
							</div>
						</Segment>
						<Divider />
					</>
				)}
				<Segment basic>
					<Table style={{ marginBottom: "-1px", borderBottom: "none" }} celled striped sortable selectable color="blue">
						<Table.Header>
							<Table.Row textAlign="center">
								<Table.HeaderCell
									sorted={sortColumn === "datasetNumericalId" ? sortDirection : null}
									onClick={() => handleSort("datasetNumericalId")}
								>
									Dataset ID
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "assessment" ? sortDirection : null}
									onClick={() => handleSort("assessment")}
								>
									Assessment
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "growthPhase" ? sortDirection : null}
									onClick={() => handleSort("growthPhase")}
								>
									Growth Phase
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === "plannedTiming" ? sortDirection : null}
									onClick={() => handleSort("plannedTiming")}
								>
									Planned Timing
								</Table.HeaderCell>
								{!isForProtocol && (
									<>
										<Table.HeaderCell
											sorted={sortColumn === "actualGrowthStage" ? sortDirection : null}
											onClick={() => handleSort("actualGrowthStage")}
										>
											Actual Growth Stage
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "dateCollected" ? sortDirection : null}
											onClick={() => handleSort("dateCollected")}
										>
											Date Collected
										</Table.HeaderCell>
										<Table.HeaderCell
											sorted={sortColumn === "collectedBy" ? sortDirection : null}
											onClick={() => handleSort("collectedBy")}
										>
											Collected By
										</Table.HeaderCell>
									</>
								)}
								<Table.HeaderCell>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(_.sortBy(groundDataSets, "datasetNumericalId"), (td) => {
								return (
									<Table.Row key={td?.datasetNumericalId}>
										<Table.Cell>{td?.datasetNumericalId}</Table.Cell>
										<Table.Cell>
											{_.map(td?.assessments, (assessment) => {
												return (
													<>
														<div key={assessment.assessment?.id}>{assessment.assessment?.name}</div>
														{assessment.assessmentAverage && (
															<div key={assessment.assessmentAverage?.id}>{assessment.assessmentAverage?.name}</div>
														)}
													</>
												);
											})}
										</Table.Cell>
										<Table.Cell>
											{
												// TODO this will need updated once I am pulling actual data
												_.find(groundDataQuestions.groundDataGrowthPhases, (growthPhase) => {
													{
														return growthPhase.id === td?.groundDataset.growthPhaseId;
													}
												})?.name
											}
										</Table.Cell>

										{td?.groundDataset.selectedTimingMethodType === "None" && (
											<TableCell>
												{
													_.find(groundDataQuestions.groundDataGrowthPhases, (growthPhase) => {
														{
															return growthPhase.id === td?.groundDataset.growthPhaseId;
														}
													})?.name
												}
											</TableCell>
										)}
										{td?.groundDataset.selectedTimingMethodType !== "None" &&
											td?.groundDataset.selectedTimingMethodType !== "StartEnd" &&
											td?.groundDataset.selectedTimingMethodType !== "int" &&
											td?.groundDataset.selectedTimingMethodType !== "Start" && (
												<TableCell>{td?.groundDataset?.plannedTiming}</TableCell>
											)}
										{td?.groundDataset.selectedTimingMethodType === "int" && (
											<TableCell>
												{td?.groundDataset?.plannedTiming}{" "}
												{
													_.find(groundDataQuestions.groundDataTimingMethods, {
														id: td?.groundDataset?.selectedTimingMethodId
													})?.name
												}
											</TableCell>
										)}
										{td?.groundDataset.selectedTimingMethodType === "StartEnd" && (
											<Table.Cell>
												{td?.groundDataset?.plannedTiming["growthStageStart"] &&
												td?.groundDataset?.plannedTiming["growthStageEnd"]
													? _.find(growthStages, (growthStage) => {
															{
																return growthStage.id === td?.groundDataset?.plannedTiming["growthStageStart"];
															}
													  })?.name
													: "TBD"}
												-
												{td?.groundDataset?.plannedTiming["growthStageStart"] &&
												td?.groundDataset?.plannedTiming["growthStageEnd"]
													? _.find(growthStages, (growthStage) => {
															{
																return growthStage.id === td?.groundDataset?.plannedTiming["growthStageEnd"];
															}
													  })?.name
													: "TBD"}
											</Table.Cell>
										)}
										{td?.groundDataset?.selectedTimingMethodType === "Start" && (
											<Table.Cell>
												{_.find(growthStages, (growthStage) => {
													return growthStage.id === td?.groundDataset?.plannedTiming["growthStageStart"];
												})?.name ?? "TBD"}
											</Table.Cell>
										)}

										{!isForProtocol && (
											<>
												<Table.Cell>
													{td?.groundDataset.growthStageId
														? _.find(growthStages, (growthStage) => {
																{
																	return growthStage.id === td?.groundDataset.growthStageId;
																}
														  })?.name
														: "TBD"}
												</Table.Cell>

												{td?.groundDataset.actualDateCollected != "Invalid date" ? (
													<Table.Cell>
														{moment.utc(td?.groundDataset.actualDateCollected, DATE_FORMAT).local().format(DATE_FORMAT)}
													</Table.Cell>
												) : (
													<Table.Cell warning>
														TBD
														<Tooltip
															key={td?.datasetNumericalId}
															placement="top"
															mouseEnterDelay={0}
															mouseLeaveDelay={0}
															trigger="hover"
															overlay={<p>Date Collected must be entered in order to upload data for this dataset.</p>}
															transitionName="rc-tooltip-zoom"
														>
															<Icon name="info circle" style={{ "margin-left": "5px" }} />
														</Tooltip>
													</Table.Cell>
												)}

												<Table.Cell>
													{td?.groundDataset.collectedByPersonId
														? _.find(personNames, (person) => {
																{
																	return person.personId === td?.groundDataset.collectedByPersonId;
																}
														  })?.fullName
														: "TBD"}
												</Table.Cell>
											</>
										)}
										<Table.Cell textAlign="center">
											<Button.Group>
												<Button
													icon
													onClick={() => editDataset(td)}
													disabled={
														!userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)
													}
												>
													<Icon name="pencil" />
												</Button>
												<Button
													icon
													onClick={() => deleteDataSet(td)}
													style={{ fairmarginLeft: "2px" }}
													disabled={
														!userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)
													}
												>
													<Icon name="trash" />
												</Button>
											</Button.Group>
										</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
						<Table.Footer>
							<Table.Row textAlign="right">
								<Table.HeaderCell colSpan="11">
									<span style={{ marginRight: 10 }}>
										{groundDataSets?.length === 1
											? `${groundDataSets?.length} dataset found`
											: `${groundDataSets?.length} datasets found`}
									</span>
									<Pagination
										onPageChange={(e, pageInfo) => {
											setActivePage(pageInfo.activePage);
										}}
										boundaryRange={5}
										siblingRange={1}
										totalPages={totalPages}
										activePage={activePage}
									/>
									<Dropdown
										onChange={(e, { value }) => {
											setItemsPerPage(value);
										}}
										selection
										options={[
											{ key: 1, text: 5, value: 5 },
											{ key: 2, text: 10, value: 10 },
											{ key: 3, text: 15, value: 15 },
											{ key: 4, text: 25, value: 25 },
											{ key: 5, text: 50, value: 50 }
										]}
										value={itemsPerPage}
										compact
										style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
									/>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					</Table>
					<div
						style={{
							position: "sticky",
							bottom: 0,
							overflow: "hidden",
							paddingTop: ".28571429rem",
							marginTop: "-.28571429rem"
						}}
					>
						<div
							style={{
								width: "100%",
								height: ".57142858rem",
								marginTop: "-.57142858rem",
								border: "1px solid rgba(34,36,38,.15)",
								borderRadius: ".28571429rem",
								boxShadow: "white 0px 0px 0 .28571429rem"
							}}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								padding: "10px 0",
								backgroundColor: "white"
							}}
						>
							<Button
								primary
								icon="plus"
								labelPosition="left"
								content="Add Dataset"
								onClick={handleAddDatasetModalOpen}
								disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)}
							/>
							<Button
								content="Save"
								positive
								loading={saving}
								disabled={
									saving || !userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId)
								}
								onClick={() => saveGroundData()}
							/>
						</div>
					</div>
					<DataSetModal
						groundDatasetToEdit={selectedGrounddataSet}
						groundDataQuestions={groundDataQuestions}
						growthStages={growthStages}
						persons={persons}
						onSave={saveDataSet}
						onEdit={editDataSet}
						isEditing={isEditing}
						onClose={onClose}
						openAddDatasetModal={openAddDatasetModal}
						handleAddDatasetModalOpen={handleAddDatasetModalOpen}
						validateDataSet={validateDataSet}
						outlineData={outlineData}
						isForProtocol={isForProtocol}
					/>
				</Segment>
				<Form style={{ marginTop: "2em" }}>
					<Divider />
					<Grid>
						{!isForProtocol &&
						(userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataCRUD, userAuth.currentClientId) ||
							userAuth.hasApplicationArea(ApplicationArea.TrialGroundDataTemplate, userAuth.currentClientId)) ? (
							<>
								<Grid.Row>
									<Grid.Column width="12">
										<label style={{ display: "unset" }}>Ground Data Layout</label>
										<br />
										<br />
										<InspectPlotData
											trialFieldData={{
												clientId: userAuth.currentClientId,
												groundDataSets: groundDataSets,
												groundDataValidation: _.map(
													_.filter(groundDataAssessments, (gda) => {
														let groundDataMethod = _.find(groundDataMethods, {
															groundDataAssessmentId: gda?.id
														});
														if (groundDataMethod && groundDataMethod.name !== "CALCULATED") return true;
													}),
													({ id, name }) => {
														let groundDataAssessmentType = _.find(groundDataAssessmentTypes, {
															groundDataAssessmentId: id
														});
														let groundDataUnit = _.find(groundDataUnits, {
															groundDataAssessmentTypeId: groundDataAssessmentType?.id
														});
														return {
															assessmentName: name,
															dataType: groundDataUnit?.dataType,
															numOfPlots: numOfPlots
														};
													}
												),
												// assessments: groundDatasetAssessments,
												fieldHasImportedData: false // TODO: Change when adding edit func
											}}
											outlineData={outlineData}
											onFileSelected={fileSelectedHandler}
											onFileRemoved={fileRemovedHandler}
											onInspectionSuccessful={groundDataInspectionSuccessHandler}
											groundData={true}
											createGroundDataSetObject={createGroundDataSetObject}
										/>
										{csvData && (
											<>
												<br />
												{_.some(groundDataSets, (gd) => gd.groundDataset.actualDateCollected != "Invalid date") ? (
													<CSVLink
														filename={fileName}
														data={csvData}
														enclosingCharacter={""}
														className="ui primary left floated button"
														disabled={
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialGroundDataTemplate,
																userAuth.currentClientId
															)
														}
													>
														Ground Data Template
													</CSVLink>
												) : (
													<Button
														id="button-fake-template"
														primary
														onClick={() =>
															toast.error("Cannot generate template without applying Date Collected on any dataset")
														}
														disabled={
															!userAuth.hasApplicationArea(
																ApplicationArea.TrialGroundDataTemplate,
																userAuth.currentClientId
															)
														}
													>
														Ground Data Template
													</Button>
												)}
												<br />
											</>
										)}
									</Grid.Column>
								</Grid.Row>
							</>
						) : null}
					</Grid>
				</Form>
			</Segment>
			<Prompt
				when={anyChanges}
				message={(location) => {
					setPath(location.pathname);
					setConfirmNavModal(true);
					return false;
				}}
			/>
			<Modal open={confirmNavModal}>
				<Modal.Header>Confirm Navigation</Modal.Header>
				<Modal.Content>
					<Modal.Description>There are unsaved changes on this page.</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button floated="left" type="button" onClick={() => setConfirmNavModal(false)} disabled={saving}>
						Cancel
					</Button>
					<Button
						floated="right"
						positive
						loading={saving}
						disabled={saving}
						content="Save & Continue"
						onClick={() => {
							saveGroundData();
						}}
					/>
					<Button
						floated="right"
						negative
						loading={saving}
						disabled={saving}
						content="Discard Changes"
						onClick={() => {
							setAnyChanges(false);
						}}
					/>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
};

NewGroundData.propTypes = {
	groundDataQuestions: PropTypes.object,
	growthStages: PropTypes.array,
	persons: PropTypes.array,
	outlineData: PropTypes.array,
	fileName: PropTypes.string,
	onSave: PropTypes.func,
	isForProtocol: PropTypes.bool,
	onBack: PropTypes.func,
	protocolName: PropTypes.string
};

export default NewGroundData;
