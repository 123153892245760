import * as types from "./actionTypes";
import * as orthoImageTypeApi from "../../apis/orthoImageTypeApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getOrthoImageTypeDetailsSuccess() {
	return { type: types.ORTHO_GET_ORTHO_IMAGE_TYPE_DETAILS_SUCCESS };
}

export function getOrthoImageTypesSuccess() {
	return { type: types.ORTHO_GET_ORTHO_IMAGE_TYPES_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getOrthoImageTypeDetails(clientId, accessToken) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoImageTypeApi
				.getOrthoImageTypeDetails(clientId, accessToken)
				.then(res => {
					dispatch(getOrthoImageTypesSuccess());
					resolve(res);
				})
				.catch(error => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getOrthoImageTypes(clientId, accessToken) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return orthoImageTypeApi
				.getOrthoImageTypes(clientId, accessToken)
				.then(res => {
					dispatch(getOrthoImageTypeDetailsSuccess());
					resolve(res);
				})
				.catch(error => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
