export function validObject() {
	let validation = {
		clientIdError: false,
		userIdError: false,
		licenseIdError: false,
		signUpDateError: false,
		expiresDateError: false
	};

	return validation;
}

export function validateForm(userLicense) {
	let validation = validObject();

	if (!userLicense.clientId) {
		validation.clientIdError = true;
	}

	if (!userLicense.userId) {
		validation.userIdError = true;
	}

	if (!userLicense.licenseId) {
		validation.licenseIdError = true;
	}

	if (!userLicense.signUpDate) {
		validation.signUpDateError = true;
	}

	if (!userLicense.expiresDate) {
		validation.expiresDateError = true;
	}

	return validation;
}
