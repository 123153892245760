// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.accordion .title .ui.selection.dropdown .dropdown.icon {\n\ttransform: rotate(90deg);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Widgets/SummaryTable/dropdown.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;AACzB","sourcesContent":[".ui.accordion .title .ui.selection.dropdown .dropdown.icon {\n\ttransform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"accordion": "accordion",
	"title": "title",
	"selection": "selection",
	"dropdown": "dropdown",
	"icon": "icon"
};
export default ___CSS_LOADER_EXPORT___;
