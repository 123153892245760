import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment, Grid, Form, Loader, Button, Icon } from "semantic-ui-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { CSVLink } from "react-csv";

import { getDistinctObjects } from "../../../../utilities/jsUtils";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";

import Heatmap from "../../../Lumber/Heatmap";
import Timelineslider from "../../../Lumber/TimelineSlider";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";

import "./styles.css";

const FieldHeatmapLegacy = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const history = useHistory();

	const ANALYSIS_DATE_FORMAT = "MM/DD/YYYY h:mma";

	//-- Data Sources
	const userAuth = useUserAuth();
	const [fieldHeatmapData, setFieldHeatmapData] = useState(null);
	const [flights, setFlights] = useState(null);
	const [analyses, setAnalyses] = useState(null);
	const [trials, setTrials] = useState(null);
	const [rawPresetOptions, setRawPresetOptions] = useState(null);
	const [plotSubsampleStatuses, setPlotSubsampleStatuses] = useState(null);

	//-- Timeline nodes
	const [flightNodes, setFlightNodes] = useState(null);

	//-- Dropdown options
	const [trialOptions, setTrialOptions] = useState([]);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [allAssessments, setAllAssessments] = useState([]);

	//-- Selected option
	const [flightId, setFlightId] = useState(null);
	const [analysisId, setAnalysisId] = useState(null);
	const [quantifiedRegionTypeId, setQuantifiedRegionTypeId] = useState(null);
	const [analysisType, setAnalysisType] = useState(null);

	const [analysisResults, setAnalysisResults] = useState(null);

	//-- CSV
	const [fileName, setFileName] = useState("FieldHeatmap.xlsx");
	const [csvData, setCsvData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);

	const [showTrialOutline, setShowTrialOutline] = useState(false);

	const isForLegacy = true;

	//-- Get/Set - Field heatmap data
	useEffect(() => {
		if (!fieldHeatmapData && userAuth.currentClientId) {
			getFieldHeatmapData(moduleNavigation.fieldId);
		}
	}, [userAuth.currentClientId]);

	async function getFieldHeatmapData(fieldId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(analysisActions.getAnalysisResultsForField(userAuth.currentClientId, fieldId, isForLegacy, accessToken))
			.then((res) => {
				const subsampleOrdering = [
					{ type: "Plot-Centered with Subsamples", order: 1 },
					{ type: "Plot-Centered", order: 2 },
					{ type: "Row-Centered", order: 3 },
					{ type: "Full Row", order: 4 },
					{ type: "Between-Row", order: 5 }
				];

				_.map(res.plotAnalysisResults, (par) => {
					par.subsampleOrder = _.find(subsampleOrdering, { type: par.quantifiedRegionTypeName })?.order;
					par.analysisOrder =
						_.find(
							res.presetOptions.timeSeriesPresets,
							(po) => po.analysisId === par.analysisId && po.analysisTypeId === par.analysisTypeId
						)?.order || Infinity;
				});

				let tempPlotAnalysisResults = _.sortBy(res.plotAnalysisResults, ["subsampleOrder", "analysisOrder"]);

				setFieldHeatmapData(tempPlotAnalysisResults);
				setRawPresetOptions(res.presetOptions);
				setPlotSubsampleStatuses(res.plotSubsampleStatuses);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	useEffect(() => {
		if (fieldHeatmapData) {
			//-- analyses
			let anlyss = fieldHeatmapData.map((fhmd) => {
				const a = {};
				a.id = fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId;
				a.name = fhmd.analysisName ? fhmd.analysisName : fhmd.assessmentName;
				a.subsample = fhmd.subsample;
				return a;
			});

			let idsWithSubsamples = _.uniq(
				_.map(
					_.filter(anlyss, (a) => a.subsample && a.subsample > 1),
					"id"
				)
			);

			anlyss = _.filter(anlyss, (a) => !idsWithSubsamples.includes(a.id));

			anlyss = _.sortBy(_.uniqWith(anlyss, _.isEqual), ["name"]);

			setAnalyses(anlyss);

			//-- trials
			let trls = fieldHeatmapData
				.filter((fmhd) => fmhd.trialId)
				.map((fhmd) => {
					const t = {};
					t.id = fhmd.trialId;
					t.name = fhmd.trialName;
					return t;
				});

			trls = getDistinctObjects(trls);
			setTrials(trls);

			//-- quantified regions
			let quantifiedRegions = _.map(_.uniqBy(fieldHeatmapData, "quantifiedRegionTypeId"), (qr) => {
				return { key: qr.quantifiedRegionTypeId, value: qr.quantifiedRegionTypeId, text: qr.quantifiedRegionTypeName };
			});

			setQuantifiedRegionTypeOptions(quantifiedRegions);

			setLoading(false);
		}
	}, [fieldHeatmapData]);

	useEffect(() => {
		if (
			fieldHeatmapData &&
			analysisId &&
			(quantifiedRegionTypeId || _.every(fieldHeatmapData, (fhmd) => fhmd.assessmentId))
		) {
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			//-- flights
			let flts = fieldHeatmapData
				.filter(
					(fhmd) =>
						(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
						//-- flight analysis quantified region data
						(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Aalysis Type
						(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Ground Data subsamples
						(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample))
				)
				.map((fhmd) => {
					const f = {};
					f.id = fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId;
					f.name = fhmd.flightName;
					f.date = fhmd.flightDate ? fhmd.flightDate : fhmd.groundDatasetDate;
					f.groundData = fhmd.flightId === null;
					f.excludeFromAssessment = fhmd.excludeFromAssessment;
					return f;
				});

			flts = getDistinctObjects(flts);
			let flightDifferences = _.difference(flts, flights);
			if (flightDifferences.length > 0) {
				setFlights(flts);
			}

			setFlights(flts);
		}
	}, [fieldHeatmapData, analysisId, quantifiedRegionTypeId]);

	//-- Set node and ddl options
	useEffect(() => {
		if (flights && flights.length > 0) {
			setProcessing(true);

			setFlightNodes(
				_.map(flights, ({ id, date }) => {
					const opt = {
						id: id,
						date: moment(date).local(),
						color: "#4183c4",
						clickable: true,
						firstActive: moduleNavigation.flightId === id ? true : false,
						tooltip: (
							<>
								{`Flight Date & Time`} <br /> {moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
							</>
						)
					};
					return opt;
				})
			);

			if (moduleNavigation.flightId) {
				setFlightId(moduleNavigation.flightId);
			} else {
				setFlightId(flights[0].id);
			}

			setProcessing(false);
		}
	}, [flights]);

	useEffect(() => {
		if (analyses && analyses.length > 0) {
			setProcessing(true);

			let analyticOptions = [];
			let orthos = analysisDropDownListFunctions.getOrthoTypes(fieldHeatmapData);
			let groundData = analysisDropDownListFunctions.getGroundDataPresets(fieldHeatmapData);
			let growthCurves = analysisDropDownListFunctions.getGrowthCurvePresets(fieldHeatmapData);

			analyticOptions = orthos.concat(groundData).concat(growthCurves);
			setAllAssessments(analyticOptions);

			setProcessing(false);
		}
	}, [analyses]);

	useEffect(() => {
		if (trials && trials.length > 0) {
			setProcessing(true);
			setTrialOptions(
				_.map(trials, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			);

			setProcessing(false);
		}
	}, [trials]);

	useEffect(() => {
		//-- analysis results
		if (flightId && analysisId && quantifiedRegionTypeId) {
			setProcessing(true);

			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			var ars = fieldHeatmapData
				.filter(
					(fhmd) =>
						(fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId) === flightId &&
						//-- flight analysis quantified region data
						(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Analysis Type
						(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
						(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
						(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample))
				)
				.map((fhmd) => {
					var r = {};
					r.id = fhmd.flightDataAnalysisResultId;
					r.x = fhmd.plotRange;
					r.y = fhmd.plotColumn;
					r.trialId = fhmd.trialId;
					r.trialName = fhmd.trialName;
					r.analysis = fhmd.analysisName;
					r.value = fhmd.flightId ? fhmd.plotAnalysisResultValue : fhmd.groundDatasetAssessmentValue;
					r.tooltipText = generatePlotTooltipText(fhmd);
					r.excludeFromAssessment = fhmd.excludeFromAssessment;
					r.maxRanges = fhmd.maxRanges;
					r.maxColumns = fhmd.maxColumns;
					return r;
				});
			setAnalysisResults(ars);
			setProcessing(false);
		} else {
			setAnalysisResults([]);
		}
	}, [flightId, analysisId, quantifiedRegionTypeId, analysisType]);

	function generatePlotTooltipText(plotData) {
		let tooltipText = {};
		if (plotData.plotName) {
			tooltipText["Plot"] = plotData.plotName;
		}

		if (plotData.trialTreatmentId) {
			tooltipText["Treatment Id"] = plotData.trialTreatmentId;
		}

		if (plotData.plotReplicate) {
			tooltipText["Replicate"] = plotData.plotReplicate;
		}

		if (plotData.plotSeed) {
			tooltipText["Seed"] = plotData.plotSeed;
		}

		return tooltipText;
	}

	function setCsvInfo(timeSeriesPresets) {
		if (fieldHeatmapData && fieldHeatmapData.length > 0) {
			const fieldName = fieldHeatmapData[0].fieldName;

			setFileName(`${fieldName}_FieldHeatmap_${moment().format("YYYYMMDD_hhmma")}.csv`);

			//Create a uniq list of results or the plot/column pair for a field will be duplicated for each analysis
			let filteredFieldHeatmapData = _.uniqBy(fieldHeatmapData, (par) => {
				return [par.fieldName, par.plotRange, par.plotColumn, par.flightId ? par.flightId : par.groundDatasetId].join();
			});

			_.map(filteredFieldHeatmapData, (fd) => {
				if (fd.flightDate === null) {
					fd.flightDate = fd.groundDatasetDate;
				}
			});

			filteredFieldHeatmapData = _.sortBy(filteredFieldHeatmapData, [
				"flightDate",
				"flightId",
				"groundDatasetId",
				"plotName"
			]);

			let uniqueAnalyses = Object.keys(
				_.groupBy(
					_.filter(fieldHeatmapData, (d) => d.flightId !== null),
					(d) => `${d.analysisName} - ${d.quantifiedRegionTypeName}`
				)
			);

			let uniqueAssessmentKeys = Object.keys(
				_.groupBy(
					_.filter(fieldHeatmapData, (d) => d.flightId === null),
					(d) => `${d.assessmentId} - ${d.subsample}`
				)
			).sort();
			let checkedAssessments = [];
			let uniqueAssessments = [];

			_.map(uniqueAssessmentKeys.reverse(), (ua) => {
				if (!checkedAssessments.includes(ua.split(" - ")[0])) {
					if (!(parseInt(ua.split(" - ")[1]) > 1)) {
						uniqueAssessments.push(ua.split(" - ")[0]);
					} else {
						uniqueAssessments.push(ua);
					}

					checkedAssessments.push(ua.split(" - ")[0]);
				} else {
					uniqueAssessments.push(ua);
				}
			});
			uniqueAssessments.reverse();

			let hasGroundData = false;
			let hasFlightData = false;
			let hasExcludedData = false;
			let hasFlaggedData = false;
			let hasNotes = false;

			_.map(fieldHeatmapData, (data) => {
				if (data.excluded || data.excludeFromAssessment) {
					hasExcludedData = true;
				}
				if (data.flagged) {
					hasFlaggedData = true;
				}

				if (data.analysisId !== null) {
					hasFlightData = true;
				}

				if (data.groundDatasetId !== null) {
					hasGroundData = true;
				}

				if (data.notes !== null) {
					hasNotes = true;
				}
			});

			//-- Flags for subsample annotations and notes
			let hasPcwsAnnotations = false;
			let hasPcwsNotes = false;
			let hasPcAnnotations = false;
			let hasPcNotes = false;
			let hasBrAnnotations = false;
			let hasBrNotes = false;
			let hasFrAnnotations = false;
			let hasFrNotes = false;
			let hasRcAnnotations = false;
			let hasRcNotes = false;

			_.map(plotSubsampleStatuses, (pss) => {
				if (_.some(pss.plotCenteredWithSubsampleStatuses, (status) => status.excluded || status.flagged)) {
					hasPcwsAnnotations = true;

					if (_.some(pss.plotCenteredWithSubsampleStatuses, (status) => status.notes)) {
						hasPcwsNotes = true;
					}
				}

				if (_.some(pss.plotCenteredStatuses, (status) => status.excluded || status.flagged)) {
					hasPcAnnotations = true;

					if (_.some(pss.plotCenteredStatuses, (status) => status.notes)) {
						hasPcNotes = true;
					}
				}

				if (_.some(pss.betweenRowStatuses, (status) => status.excluded || status.flagged)) {
					hasBrAnnotations = true;

					if (_.some(pss.betweenRowStatuses, (status) => status.notes)) {
						hasBrNotes = true;
					}
				}

				if (_.some(pss.fullRowStatuses, (status) => status.excluded || status.flagged)) {
					hasFrAnnotations = true;

					if (_.some(pss.fullRowStatuses, (status) => status.notes)) {
						hasFrNotes = true;
					}
				}

				if (_.some(pss.rowCenteredStatuses, (status) => status.excluded || status.flagged)) {
					hasRcAnnotations = true;

					if (_.some(pss.rowCenteredStatuses, (status) => status.notes)) {
						hasRcNotes = true;
					}
				}
			});

			//-- Quantified region types in dataset
			const quantifiedRegions = _.uniq(_.map(uniqueAnalyses, (a) => a.split(" - ")[1]));

			//-- Map from qrType to plotSubsampleStatus property name
			const qrTypeMaps = [
				{
					type: "Plot-Centered with Subsamples",
					statusName: "plotCenteredWithSubsampleStatuses",
					abbreviation: "pcws"
				},
				{ type: "Plot-Centered", statusName: "plotCenteredStatuses", abbreviation: "pc" },
				{ type: "Between-Row", statusName: "betweenRowStatuses", abbreviation: "br" },
				{ type: "Full Row", statusName: "fullRowStatuses", abbreviation: "fr" },
				{ type: "Row-Centered", statusName: "rowCenteredStatuses", abbreviation: "rc" }
			];

			_.forEach(plotSubsampleStatuses, (pss) => {
				_.forEach(qrTypeMaps, ({ statusName }) => {
					if (pss[statusName]?.length > 0 && !pss[statusName][0]?.partial) {
						let matchingPlot = _.find(
							fieldHeatmapData,
							(thd) => thd.plotId === pss.plotId && thd.flightId === pss.flightId
						);

						if (matchingPlot) {
							if (pss[statusName][0]?.excluded) {
								matchingPlot.excluded = true;
								hasExcludedData = true;
							}

							if (pss[statusName][0]?.flagged) {
								matchingPlot.flagged = true;
								hasFlaggedData = true;
							}

							matchingPlot.notes = pss[statusName][0]?.notes;
						}
					}
				});
			});

			setCsvData(
				filteredFieldHeatmapData.map((fed) => {
					let data = {
						Field: fed.fieldName,
						Range: fed.plotRange,
						Column: fed.plotColumn,
						Company: fed.companyName,
						Trial: fed.trialName,
						Treatment: fed.trialTreatmentId,
						Plot: fed.plotName,
						Rep: fed.plotReplicate,
						Seed: fed.plotSeed
					};

					if (hasExcludedData || hasFlaggedData) {
						let matchingPlot = fieldHeatmapData.find((p) => p.plotId === fed.plotId && p.flightId === fed.flightId);

						if (matchingPlot?.excludeFromAssessment || matchingPlot?.excluded || matchingPlot?.flagged) {
							if (matchingPlot.excluded)
								data["Annotations - Entire Plot"]
									? (data["Annotations - Entire Plot"] += "; Excluded")
									: (data["Annotations - Entire Plot"] = "Excluded");
							if (matchingPlot.flagged)
								data["Annotations - Entire Plot"]
									? (data["Annotations - Entire Plot"] += "; Flagged")
									: (data["Annotations - Entire Plot"] = "Flagged");
						} else {
							data["Annotations - Entire Plot"] = null;
						}
					}

					if (hasNotes) {
						data["Notes - Entire Plot"] = fed?.notes ?? null;
					}

					data.AssessmentDate = moment(fed.flightId ? fed.flightDate : fed.groundDatasetDate)
						.local()
						.format(ANALYSIS_DATE_FORMAT);

					if (hasFlightData) {
						_.map(quantifiedRegions, (qr) => {
							_.map(
								_.filter(uniqueAnalyses, (a) => a.split(" - ")[1] === qr),
								(a) => {
									let value = null;
									const analysisName = a.split(" - ")[0];
									const qrType = a.split(" - ")[1];

									value = fieldHeatmapData.find(
										(p) =>
											p.plotId === fed.plotId &&
											p.flightId === fed.flightId &&
											p.analysisName == analysisName &&
											p.quantifiedRegionTypeName == qrType &&
											p.isPlotUniformityValue === false
									);
									let preset = "";
									if (value) {
										preset = _.find(timeSeriesPresets, (tsp) => {
											return tsp.analysisid === value.analysisId && tsp.analysistypeid === value.analysisTypeId;
										});
										data[preset?.text ? preset?.text + " - " + qrType : a] = value?.plotAnalysisResultValue;
									}

									if (preset?.text?.includes("Stand Count")) {
										data["Counted Row Length (ft)"] = value?.countedRowLength;
										data["Counted Region (acres)"] = value?.countedRegion.toFixed(5);
										data["Population(plants/acre)"] = value?.standCountAnalysisResultValue;
									}
								}
							);

							//-- Subsample annotations
							let matchingQrType = _.find(qrTypeMaps, { type: qr });
							let matchingPlotSubsampleStatus = _.find(
								plotSubsampleStatuses,
								(pss) =>
									pss.plotId === fed.plotId &&
									(pss.flightId === fed.flightId ||
										pss.isAllData ||
										(pss.dateAndTime && new Date(pss.dateAndTime) <= new Date(fed.flightDate)))
							);
							if (matchingPlotSubsampleStatus) {
								setupQrAnnotationColumns(data, qr, qrTypeMaps, matchingPlotSubsampleStatus);
							} else {
								if (hasPcwsAnnotations && matchingQrType.abbreviation === "pcws") {
									data[`Annotations - ${qr}`] = null;
									if (hasPcwsNotes) data[`Notes - ${qr}`] = null;
								}
								if (hasPcAnnotations && matchingQrType.abbreviation === "pc") {
									data[`Annotations - ${qr}`] = null;
									if (hasPcNotes) data[`Notes - ${qr}`] = null;
								}
								if (hasBrAnnotations && matchingQrType.abbreviation === "br") {
									data[`Annotations - ${qr}`] = null;
									if (hasBrNotes) data[`Notes - ${qr}`] = null;
								}
								if (hasFrAnnotations && matchingQrType.abbreviation === "fr") {
									data[`Annotations - ${qr}`] = null;
									if (hasFrNotes) data[`Notes - ${qr}`] = null;
								}
								if (hasRcAnnotations && matchingQrType.abbreviation === "rc") {
									data[`Annotations - ${qr}`] = null;
									if (hasRcNotes) data[`Notes - ${qr}`] = null;
								}
							}
						});

						//-- Plot Uniformity
						_.map(uniqueAnalyses, (a) => {
							let value = null;
							const analysisName = a.split(" - ")[0];
							const qrType = a.split(" - ")[1];

							if (qrType === "Plot-Centered with Subsamples") {
								value = fieldHeatmapData.find(
									(p) =>
										p.plotId === fed.plotId &&
										p.flightId === fed.flightId &&
										p.analysisName == analysisName &&
										p.quantifiedRegionTypeName == qrType &&
										p.isPlotUniformityValue == false
								);
								let preset = "";
								if (value) {
									preset = _.find(timeSeriesPresets, (tsp) => {
										return tsp.analysisid === value.analysisId && tsp.analysistypeid === value.analysisTypeId;
									});
								}

								let plotUniformity = fieldHeatmapData.find(
									(p) =>
										p.plotId === fed.plotId &&
										p.flightId === fed.flightId &&
										p.analysisName == analysisName &&
										p.quantifiedRegionTypeName == qrType &&
										p.isPlotUniformityValue == true
								);
								if (plotUniformity) {
									const name = preset?.text ?? analysisName;
									data[`${name} - Uniformity`] = plotUniformity?.plotAnalysisResultValue;
								}
							}
						});
					}

					if (hasGroundData) {
						_.map(uniqueAssessments, (ua) => {
							let value = null;
							// let maxSubsample = _.max(
							// 	_.map(
							// 		_.filter(fieldHeatmapData, (p) => p.plotId === fed.plotId && p.assessmentId === ua.split(" - ")[0]),
							// 		"subsample"
							// 	)
							// );
							// let currentSubsample = ua.split(" - ").length > 1 ? parseInt(ua.split(" - ")[1]) : 1;
							let assessmentName = _.find(
								fieldHeatmapData,
								(p) => p.assessmentId === ua.split(" - ")[0]
							)?.assessmentName;

							if (ua.includes(" - ")) {
								value =
									fieldHeatmapData.find(
										(p) =>
											p.plotId === fed.plotId &&
											p.groundDatasetId === fed.groundDatasetId &&
											p.assessmentId === ua.split(" - ")[0] &&
											p.subsample === parseInt(ua.split(" - ")[1])
									)?.groundDatasetAssessmentValue ?? null;
								data[`${assessmentName} - ${ua.split(" - ")[1]}`] = value;
							} else {
								value =
									fieldHeatmapData.find(
										(p) =>
											p.plotId === fed.plotId &&
											p.groundDatasetId === fed.groundDatasetId &&
											p.assessmentId === ua.split(" - ")[0] &&
											(p.subsample === 1 || p.subsample === null)
									)?.groundDatasetAssessmentValue ?? null;
								data[assessmentName] = value;
							}

							// if (maxSubsample === currentSubsample && maxSubsample === 1) {
							// 	data[`${assessmentName.split(" (")[0]} AssessmentDateTime`] =
							// 		value === null
							// 			? null
							// 			: fieldHeatmapData.find(
							// 					(p) =>
							// 						p.plotId === fed.plotId &&
							// 						p.groundDatasetId === fed.groundDatasetId &&
							// 						p.assessmentId === ua.split(" - ")[0] &&
							// 						(p.subsample === 1 || p.subsample === null)
							// 			  )?.groundDatasetDate
							// 			? moment
							// 					.utc(
							// 						fieldHeatmapData.find(
							// 							(p) =>
							// 								p.plotId === fed.plotId &&
							// 								p.groundDatasetId === fed.groundDatasetId &&
							// 								p.assessmentId === ua.split(" - ")[0] &&
							// 								(p.subsample === 1 || p.subsample === null)
							// 						).groundDatasetDate
							// 					)
							// 					.local()
							// 					.format(ANALYSIS_DATE_FORMAT)
							// 			: null;
							// }
						});
					}

					return data;
				})
			);
		}
	}

	function setupQrAnnotationColumns(data, qr, qrTypeMaps, matchingPlotSubsampleStatus) {
		let mappedStatus = _.find(qrTypeMaps, { type: qr })?.statusName;
		let partial = matchingPlotSubsampleStatus[mappedStatus][0]?.partial;

		if (
			_.some(matchingPlotSubsampleStatus[mappedStatus], "excluded") &&
			!data[`Annotations - ${qr}`]?.includes("excluded")
		)
			data[`Annotations - ${qr}`]
				? (data[`Annotations - ${qr}`] += `; ${partial ? "partially " : ""}excluded`)
				: (data[`Annotations - ${qr}`] = `${partial ? "partially " : ""}excluded`);
		if (
			_.some(matchingPlotSubsampleStatus[mappedStatus], "flagged") &&
			!data[`Annotations - ${qr}`]?.includes("flagged")
		)
			data[`Annotations - ${qr}`]
				? (data[`Annotations - ${qr}`] += `; ${partial ? "partially " : ""}flagged`)
				: (data[`Annotations - ${qr}`] = `${partial ? "partially " : ""}flagged`);

		if (_.some(matchingPlotSubsampleStatus[mappedStatus], "notes")) {
			data[`Notes - ${qr}`] = _.map(
				_.uniqBy(_.filter(matchingPlotSubsampleStatus[mappedStatus], "notes"), "notes"),
				(mpss) => `${mpss.notes}; `
			);
		}
	}

	function updateSelectedAnalysis(value) {
		setAnalysisId(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setQuantifiedRegionTypeId(value);
	}

	function updateSelectedAnalysisType(value) {
		setAnalysisType(value);
	}

	async function updatePresetList(timeSeriesPresets) {
		if (!csvData) {
			setLoading(true);
			await timeout(500);
			setCsvInfo(timeSeriesPresets);
			setLoading(false);
		}
	}

	function timeout(delay) {
		return new Promise((resolve) => {
			setTimeout(resolve, delay);
		});
	}

	return loading ? (
		<Loader active />
	) : !loading && (!fieldHeatmapData || fieldHeatmapData.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Field Heatmap</h2>
			<hr />
			<Segment>
				<p>{"Heatmap data for this field could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Field Heatmap</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{fieldHeatmapData[0].fieldName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			<Form>
				<Segment basic>
					<AnalysisDropDownLists
						rawPresetOptions={rawPresetOptions}
						allAssessmentTypes={allAssessments}
						quantifiedRegionTypes={quantifiedRegionTypeOptions}
						defaultQuantifiedRegion={
							_.find(fieldHeatmapData, (t) => {
								return t.isDefaultQuantifiedRegion === true;
							})?.quantifiedRegionTypeId
						}
						updatedSelectedAnalysisType={updateSelectedAnalysisType}
						updateSelectedAnalysis={updateSelectedAnalysis}
						updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
						updatePresetList={updatePresetList}
						isApAdmin={userAuth.isApAdmin}
						heatmap={true}
					>
						<div style={{ width: "calc(100% - 1000px)" }} />
						<Form.Field>
							<label htmlFor="form-select-analysis" style={{ display: "unset" }}>
								Assessment
							</label>
						</Form.Field>
						<Form.Field>
							<label htmlFor="form-select-trial" style={{ display: "unset" }}>
								Trial
							</label>
						</Form.Field>
						<Form.Select
							id="form-select-trial"
							search
							placeholder={_.isEmpty(trialOptions) ? "No trials in this field" : "Go to trial heatmap"}
							options={trialOptions}
							onChange={(event, { value }) => {
								history.push(moduleNavigation.createTrialLink(true, value, "heatmap"));
							}}
							loading={!trialOptions}
							selectOnBlur={false}
						/>
						{csvData && (
							<CSVLink filename={fileName} data={csvData} enclosingCharacter={""}>
								Download Heatmap Data
							</CSVLink>
						)}
					</AnalysisDropDownLists>
				</Segment>
				<Grid id="field-heatmap-grid" verticalAlign="middle">
					{!_.isEmpty(flightNodes) && (
						<Grid.Row style={{ paddingBottom: 30 }}>
							<Grid.Column>
								<Segment basic style={{ width: "90%", margin: "0 auto" }}>
									<Timelineslider
										dates={flightNodes}
										updateSelected={(selected) => {
											setFlightId(selected.id);
										}}
									/>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row centered style={{ border: "1px solid" }}>
						<Grid.Column width="16">
							{!processing && _.isEmpty(analysisResults) ? (
								<Segment basic>
									<p>
										{
											"We couldn't find data for the field or selected analysis. Please run an analysis to see the heatmap."
										}
									</p>
								</Segment>
							) : processing ? (
								<></>
							) : (
								<TransformWrapper limitToBounds={false} centerOnInit>
									{({ zoomIn, zoomOut, centerView }) => (
										<React.Fragment>
											<div className="tools" style={{ float: "left" }}>
												<Button icon onClick={() => zoomIn()}>
													<Icon name="plus" />
												</Button>
												<Button icon onClick={() => zoomOut()}>
													<Icon name="minus" />
												</Button>
												<Button icon onClick={() => centerView(1)}>
													<Icon name="refresh" />
												</Button>
												{!_.isEmpty(trials) && (
													<Button icon onClick={() => setShowTrialOutline(!showTrialOutline)}>
														<Icon name="clone outline" />
													</Button>
												)}
											</div>
											<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
												<Heatmap data={analysisResults} showTrialOutline={showTrialOutline} isField={true} />
											</TransformComponent>
										</React.Fragment>
									)}
								</TransformWrapper>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default FieldHeatmapLegacy;
