import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth0 } from "./auth0";

import { useModuleNavigation } from "../hooks/useModuleNavigation";

const PrivateRoute = ({ children, ...rest }) => {
	const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		moduleNavigation.saveCurrentPath();

		if (loading || isAuthenticated) {
			return;
		}

		loginWithRedirect();
	}, [loading, isAuthenticated]);

	return isAuthenticated ? <Route {...rest}>{children}</Route> : null;
};

PrivateRoute.propTypes = {
	children: PropTypes.element.isRequired
};

export default PrivateRoute;
