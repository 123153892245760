import React, { useEffect, useState } from "react";
import { Button, Icon, Loader } from "semantic-ui-react";
import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "./style.css";

import * as trialActions from "../../../../redux/actions/trialActions";

import { useAuth0 } from "../../../../auth/auth0";
import { useDispatch } from "react-redux";

const TrialFavoritingWidget = ({
	clientId,
	trialId,
	userSettings,
	displayMode = "title",
	refreshPage = false,
	style = {}
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const [isFavorited, setIsFavorited] = useState(false);
	const [hasRun, setHasRun] = useState(false);
	const [enabled, setEnabled] = useState(false);

	// Make sure the favorite state is set correctly on first load.
	useEffect(() => {
		setHasRun(true);
	}, []);

	useEffect(() => {
		if (hasRun && userSettings.trialFavoriteGuids) {
			let isTrialFavorited = userSettings.trialIsFavorited(trialId.toLowerCase());
			setIsFavorited(isTrialFavorited);
			setEnabled(true);
		}
	}, [hasRun, userSettings.trialFavoriteGuids]);

	function buttonClicked() {
		setEnabled(false);
		attemptToggleFavorite();
		setIsFavorited(!isFavorited);
	}

	async function attemptToggleFavorite() {
		const accessToken = await getTokenSilently();

		// If we need to favorite the current trial...
		if (isFavorited != true) {
			dispatch(trialActions.createTrialFavorite(clientId, accessToken, trialId))
				.then(() => {
					userSettings.getTrialFavoriteGuids(() => setEnabled(true));
					if (refreshPage) {
						window.location.reload(true);
					}
				})
				.catch((err) => {
					toast.error("Failed to favorite trial.");
					console.error(err);
					setIsFavorited(!isFavorited);
				});
		}
		// Otherwise, if we need to unfavorite the current trial...
		else {
			dispatch(trialActions.deleteTrialFavorite(clientId, accessToken, trialId))
				.then(() => {
					userSettings.getTrialFavoriteGuids(() => setEnabled(true));
					if (refreshPage) {
						window.location.reload(true);
					}
				})
				.catch((err) => {
					toast.error("Failed to unfavorite a trial.");
					console.error(err);
					setIsFavorited(!isFavorited);
				});
		}
	}

	return enabled === true ? (
		<Tooltip
			placement="bottom"
			mouseEnterDelay={0}
			mouseLeaveDelay={0}
			trigger="hover"
			overlay={isFavorited ? <p>Unfavorite</p> : <p>Favorite</p>}
			transitionName="rc-tooltip-zoom"
		>
			{(() => {
				switch (displayMode) {
					case "title":
						return (
							<Button
								style={{ textAlign: "center", backgroundColor: "rgba(255,255,255,0)", padding: "0px", ...style }}
								onClick={buttonClicked}
							>
								<Button.Content>
									{isFavorited ? (
										<Icon name="star" size="large" style={{ textAlign: "center", color: "rgb(65,101,134)" }}></Icon>
									) : (
										<Icon name="star outline" size="large" style={{ textAlign: "center" }}></Icon>
									)}
								</Button.Content>
							</Button>
						);
					case "table":
						return (
							<Button
								className="trial-favoriting-button"
								style={{ textAlign: "center", backgroundColor: "rgba(255,255,255,0)", ...style }}
								onClick={buttonClicked}
							>
								<Button.Content>
									{isFavorited ? (
										<Icon name="star" size="small" style={{ textAlign: "center", color: "rgb(65,101,134)" }}></Icon>
									) : (
										<Icon name="star outline" size="small" style={{ textAlign: "center" }}></Icon>
									)}
								</Button.Content>
							</Button>
						);
					default:
				}
			})()}
		</Tooltip>
	) : displayMode === "title" ? (
		<Loader active inline size="small" style={{ padding: "11px 21px", marginRight: "5px" }} />
	) : displayMode === "table" ? (
		<Loader active inline size="tiny" style={{ padding: "11px 21px", marginRight: "5px" }} />
	) : (
		<Loader active inline size="small" style={{ padding: "11px 21px", marginRight: "5px" }} />
	);
};

TrialFavoritingWidget.propTypes = {
	clientId: PropTypes.string,
	trialId: PropTypes.string,
	userSettings: PropTypes.object,
	displayMode: PropTypes.string,
	refreshPage: PropTypes.bool,
	style: PropTypes.object
};

export default TrialFavoritingWidget;
