import React from "react";
import PropTypes from "prop-types";

import { Button, Icon, Message, Modal, Table } from "semantic-ui-react";
import Tooltip from "rc-tooltip";

const TreatmentDataInspectionResults = ({ inspectionResults, columns, open, setOpen, onAccept, willSave }) => {
	return (
		<Modal open={open} style={{ maxHeight: "85%" }}>
			<Modal.Header>Treatment Inpsection Results</Modal.Header>
			<Modal.Content>
				{!inspectionResults ? (
					<p>No Results</p>
				) : (
					<>
						<h3>High Level Results</h3>
						{inspectionResults.validations.highLevel.length === 0 &&
						inspectionResults.inspections.highLevel.length === 0 ? (
							<Message positive content="All good here!" />
						) : (
							<>
								<Message
									warning
									hidden={
										inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 0).length === 0 &&
										inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 1).length === 0 &&
										inspectionResults.inspections.highLevel.filter((hlv) => hlv.type === 0).length === 0 &&
										inspectionResults.inspections.highLevel.filter((hlv) => hlv.type === 1).length === 0
									}
									list={inspectionResults.validations.highLevel
										.filter((hlv) => hlv.type === 0)
										.concat(inspectionResults.validations.highLevel.filter((hli) => hli.type === 1))
										.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 0))
										.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 1))
										.map((hl) => hl.message)}
								/>
								<Message
									error
									hidden={
										inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 2).length === 0 &&
										inspectionResults.inspections.highLevel.filter((hli) => hli.type === 2).length === 0
									}
									list={inspectionResults.validations.highLevel
										.filter((hlv) => hlv.type === 2)
										.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 2))
										.map((hl) => hl.message)}
								/>
							</>
						)}
						<h3>Individual Record Results</h3>
						{!inspectionResults.validations.importTreatmentData
							.concat(inspectionResults.inspections.importTreatmentData)
							.some((tpda) => !tpda.isValid) ? (
							<Message positive content="All records are good!" />
						) : (
							<Message
								error
								content="Please review the following records for errors and reupload the file once they have been fixed."
							/>
						)}
						<div style={{ maxHeight: "35vh", overflowX: "auto" }}>
							<Table celled striped selectable>
								<Table.Header>
									<Table.Row textAlign="center">
										<Table.HeaderCell textAlign="center">#</Table.HeaderCell>
										{columns.map((col, idx) => (
											<Table.HeaderCell textAlign="center" key={idx}>
												{col.charAt(0).toUpperCase() + col.slice(1)}
											</Table.HeaderCell>
										))}
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{(!inspectionResults.validations.areValid
										? inspectionResults.validations.importTreatmentData
										: inspectionResults.inspections.importTreatmentData
									).map((result, r) => (
										<Table.Row key={r + 1}>
											<Table.Cell>{r + 1}</Table.Cell>
											{columns.map((col, c) => (
												<Table.Cell
													key={(r + 1) * 10 + c}
													positive={result[col + "Vr"] && result[col + "Vr"].type === 1}
													warning={result[col + "Vr"] && result[col + "Vr"].type === 0}
													error={result[col + "Vr"] && result[col + "Vr"].type === 2}
												>
													{result[col + "Vr"] && (
														<Tooltip
															placement="bottom"
															mouseEnterDelay={0}
															mouseLeaveDelay={0.1}
															trigger="hover"
															overlay={<div>{result[col + "Vr"].message}</div>}
															transitionName="rc-tooltip-zoom"
														>
															<Icon name="attention" />
														</Tooltip>
													)}
													{result[col]}
												</Table.Cell>
											))}
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="Cancel"
					onClick={() => {
						setOpen(false);
					}}
				/>
				<Button
					primary
					disabled={!inspectionResults?.areValid}
					content={willSave ? "Apply & Save" : "Apply"}
					onClick={() => {
						onAccept();
						setOpen(false);
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};

TreatmentDataInspectionResults.propTypes = {
	inspectionResults: PropTypes.object,
	columns: PropTypes.arrayOf(PropTypes.string).isRequired,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	onAccept: PropTypes.func,
	willSave: PropTypes.bool
};

export default TreatmentDataInspectionResults;
