export function validateGps(gps) {
	let validation = { error: false, errorList: [] };

	if (gps.llLat && (gps.llLat < -90 || gps.llLat > 90)) {
		validation.errorList.push("LL Latitude cannot be less than -90 or greater than 90.");
		validation.llLatError = true;
		validation.error = true;
	}

	if (gps.ulLat && (gps.ulLat < -90 || gps.ulLat > 90)) {
		validation.errorList.push("UL Latitude cannot be less than -90 or greater than 90.");
		validation.ulLatError = true;
		validation.error = true;
	}

	if (gps.llLong && (gps.llLong < -180 || gps.llLong > 180)) {
		validation.errorList.push("LL Longitude cannot be less than -180 or greater than 180.");
		validation.llLongError = true;
		validation.error = true;
	}

	if (gps.ulLong && (gps.ulLong < -180 || gps.ulLong > 180)) {
		validation.errorList.push("UL Longitude cannot be less than -180 or greater than 180.");
		validation.ulLongError = true;
		validation.error = true;
	}

	if ((gps.llLat && !gps.llLong) || (!gps.llLat && gps.llLong)) {
		validation.errorList.push("Both LL coordinates are required");
		validation.llLatError = true;
		validation.llLongError = true;
		validation.error = true;
	}

	if ((gps.ulLat && !gps.ulLong) || (!gps.ulLat && gps.ulLong)) {
		validation.errorList.push("Both UL coordinates are required");
		validation.ulLatError = true;
		validation.ulLongError = true;
		validation.error = true;
	}

	if (gps.llLat && gps.llLat && !gps.ulLat && !gps.ulLong && !gps.degreeHeading) {
		validation.errorList.push(
			"Either LL Coordinates and UL Coordinates or LL Coordinates and Degree Heading are required."
		);
		validation.ulLatError = true;
		validation.ulLongError = true;
		validation.degreeHeadingError = true;
		validation.error = true;
	}

	if (gps.degreeHeading && (gps.degreeHeading < -180 || gps.degreeHeading > 180)) {
		validation.errorList.push("Degree Heading cannot be less than -180 or greater than 180.");
		validation.degreeHeadingError = true;
		validation.error = true;
	}

	return validation;
}
