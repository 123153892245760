import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import _ from "lodash";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

import {
	Segment,
	Table,
	Pagination,
	Dropdown,
	Button,
	Modal,
	Header,
	Loader,
	Popup,
	Icon,
	Message
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";

import { CSVLink } from "react-csv";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as flightImageActions from "../../../../redux/actions/flightImageActions";
import * as flightActions from "../../../../redux/actions/flightActions";
import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as quantifiedRegionActions from "../../../../redux/actions/quantifiedRegionsActions";
import * as flightsPlotsActions from "../../../../redux/actions/flightsPlotsActions";

import * as orthoControlFunctions from "../../../Lumber/OrthoViewer/orthoControlFunctions";

const FlightAnalysis = ({ removeUploadStatusMessage, flightName }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userAuth = useUserAuth();

	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();

	const DATE_FORMAT = "MM/DD/YYYY";
	const ANALYSIS_DATE_FORMAT = "MM/DD/YYYY h:mma";

	//-- Data source
	const [dataSource, setDataSource] = useState([]);

	//-- Selected data
	const [clientId, setClientId] = useState("");
	const [flightDate, setFlightDate] = useState(null);
	const [fieldAnalysisResults, setFieldAnalysisResults] = useState(null);
	const [plotAnalysisResults, setPlotAnalysisResults] = useState(null);
	const [analysesOnFlight, setAnalysesOnFlight] = useState(null);
	const [quantifiedRegions, setQuantifiedRegions] = useState(null);

	//-- Paging
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	//-- Sorting
	const [tableData, setTableData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("descending");

	//-- CSV
	const [flightFileName, setFlightFileName] = useState("FlightAnalysisResults.csv");
	const [flightCsvData, setFlightCsvData] = useState(null);
	const [plotFileName, setPlotFileName] = useState("PlotAnalysisResults.csv");
	const [plotCsvData, setPlotCsvData] = useState(null);

	//-- UI Control
	const [analysisRunning, setAnalysisRunning] = useState(false);
	const [rerunAnalysisModalOpen, setRerunAnalysisModalOpen] = useState(false);
	const [rerunAnalysisRunning, setRerunAnalysisRunning] = useState(false);
	const [standCountRunning, setStandCountRunning] = useState(false);
	const [rerunStandCountModalOpen, setRerunStandCountModalOpen] = useState(false);
	const [rerunStandCountRunning, setRerunStandCountRunning] = useState(false);
	const [loading, setLoading] = useState(true);
	const [failedToLoad, setFailedToLoad] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [analyzingOnServer, setAnalyzingOnServer] = useState(false);
	const [fieldsThatExceedImageSize, setFieldsThatExceedImageSize] = useState([]);
	const [deleteAnalysisModalOpen, setDeleteAnalysisModalOpen] = useState(false);
	const [deletingFlightAnalysisIsRunning, setDeletingFlightAnalysisIsRunning] = useState(false);
	const [dataDone, setDataDone] = useState(true);

	//-- Initial view load
	useEffect(() => {
		if (!fieldAnalysisResults) {
			getPlotAnalysisStatus();
			getAnalysisData();
		} else if (fieldAnalysisResults && analysesOnFlight && quantifiedRegions) {
			setDataSource(fieldAnalysisResults ?? []);
			setFlightCsvInfo();
		}
	}, [fieldAnalysisResults, analysesOnFlight, quantifiedRegions]);

	useEffect(() => {
		if (!_.isEmpty(plotAnalysisResults) && !_.isEmpty(analysesOnFlight) && !_.isEmpty(quantifiedRegions)) {
			setPlotCsvInfo();
		}
	}, [plotAnalysisResults, analysesOnFlight, quantifiedRegions]);

	useEffect(() => {
		if (dataSource) {
			const newTotalPages = Math.ceil(dataSource.length / itemsPerPage);
			setTotalPages(newTotalPages);
			setActivePage(newTotalPages != 0 && activePage > newTotalPages ? newTotalPages : activePage);
			setTableData(dataSource.slice(activePage * itemsPerPage - itemsPerPage, activePage * itemsPerPage));
		}
	}, [dataSource, itemsPerPage, activePage, sortColumn, sortDirection]);

	async function getAnalysisData() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(
			analysisActions.getAnalysisResultsForFlight(moduleNavigation.flightId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				if (res) {
					setClientId(res.clientId);
					setFlightDate(res.flightDate);
					setFieldAnalysisResults(res.fieldAnalysisResults);
					setPlotAnalysisResults(res.plotAnalysisResultsForFields);
					setAnalysesOnFlight(res.analysesOnFlight);
					setQuantifiedRegions(res.quantifiedRegionTypes);
					setAnalyzingOnServer(res.flightAnalyzing);
					setShowTooltip(res.flightAnalyzing);
				}
				setLoading(false);
				setFailedToLoad(false);
			})
			.catch(() => {
				toast.error("Unable to load analysis data.");
				setLoading(false);
				setFailedToLoad(true);
			});
	}

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			flightActions.getPlotAnalysisStatusForFlight(moduleNavigation.flightId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	function handleSort(clickedColumn) {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setDataSource(
					_.sortBy(
						dataSource,
						function (d) {
							return moment(d[clickedColumn]).local().format(DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|col|range|altitude|total/i)) {
				setDataSource(_.sortBy(dataSource, (prop) => prop[clickedColumn] || ""));
			} else {
				let sortedDataSource = _.sortBy(dataSource, [
					(d) =>
						_.find(d.analysisResults, (ar) => {
							return ar.analysisName == clickedColumn;
						})?.value
				]);
				setDataSource(sortedDataSource);
			}

			setSortDirection("ascending");
			setTableData(dataSource);

			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setDataSource(dataSource.reverse());
		setTableData(dataSource);
	}

	function setFlightCsvInfo() {
		if (fieldAnalysisResults && fieldAnalysisResults.length > 0) {
			setFlightFileName(`FieldAnalysisResults_${moment().format("YYYYMMDD_hhmma")}.csv`);
			const csvData = fieldAnalysisResults.map((ar) => {
				let data = {
					Field: ar.fieldName,
					Crop: ar.cropName,
					FlightDate: moment(flightDate).local().format("MM/DD/YYYY"),
					PlantDate: ar.plantDate ? moment(ar.plantDate).local().format(DATE_FORMAT) : null
				};

				_.map(analysesOnFlight, (a) => {
					_.map(quantifiedRegions, (qr) => {
						let value = null;

						value = ar.analysisResults.find((r) => r.analysisId === a.id && r.quantifiedRegionTypeId === qr.id);
						let name = `${a.name} - ${qr.name}`;
						data[name] = value?.value;

						data[`${name} Date Time`] = value?.dateTime
							? moment.utc(value?.dateTime).local().format(ANALYSIS_DATE_FORMAT)
							: null;
					});
				});

				return data;
			});
			setFlightCsvData(csvData);
		}
	}

	function setPlotCsvInfo() {
		if (plotAnalysisResults && plotAnalysisResults.length > 0) {
			setPlotFileName(`PlotAnalysisResults_${moment().format("YYYYMMDD_hhmma")}.csv`);

			//Create a uniq list of results or the plot/column pair for a field will be duplicated for each analysis
			let filteredPlotAnalysisResults = _.sortBy(
				_.uniqBy(plotAnalysisResults, (par) => {
					return [par.fieldName, par.plotRange, par.plotColumn].join();
				}),
				["fieldName", "plotColumn", "plotRange"]
			);
			setPlotCsvData(
				filteredPlotAnalysisResults.map((par) => {
					let data = {
						Field: par.fieldName,
						Range: par.plotRange,
						Column: par.plotColumn,
						Company: par.companyName,
						Trial: par.trialName,
						Treatment: par.trialTreatmentId,
						Plot: par.plotName,
						Rep: par.plotReplicate,
						Seed: par.plotSeed,
						Crop: par.cropName,
						FlightDate: moment(flightDate).local().format("MM/DD/YYYY"),
						PlantDate: par.plantDate ? moment(par.plantDate).local().format(DATE_FORMAT) : null
					};

					_.map(analysesOnFlight, (a) => {
						_.map(quantifiedRegions, (qr) => {
							let value = null;
							value = plotAnalysisResults.find(
								(r) => r.plotId === par.plotId && r.analysisId === a.id && r.quantifiedRegionTypeId === qr.id
							);
							const name = `${a.name} - ${qr.name}`;
							data[name] = value?.plotAnalysisResultValue;

							if (a.name.includes("Stand Count")) {
								data["Counted Row Length (ft)"] = value?.countedRowLength;
								data["Counted Region (acres)"] = value?.countedRegion.toFixed(5);
								data["Population(plants/acre)"] = value?.standCountAnalysisResultValue;
							}

							data[`${name} Date Time`] = value?.plotAnalysisDate
								? moment.utc(value?.dateTime).local().format(ANALYSIS_DATE_FORMAT)
								: null;
						});
					});

					return data;
				})
			);
		}
	}

	async function checkCutImages(rerun = false) {
		toast.info("Checking if images have been processed");

		if (rerun) {
			setRerunAnalysisRunning(true);
		} else {
			setAnalysisRunning(true);
		}

		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.checkCutImages(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			if (!res?.data) {
				toast.info("Images failed the cut process. Re-cutting them now...");
				recutFieldImages(rerun);
			} else {
				if (rerun) {
					handleRerunAnalysisModalChecks();
				} else {
					analyzeFlight();
				}
			}
		});
	}

	async function recutFieldImages(rerun) {
		const accessToken = await getTokenSilently();
		dispatch(flightsPlotsActions.getRecutParameters(moduleNavigation.flightId, clientId, accessToken)).then(
			async (res) => {
				if (res?.data) {
					let plots = _.map(res.data.flightsPlots, (plot) => {
						return {
							id: plot.plotId,
							type: "Feature",
							properties: {
								type: "plot",
								field: plot.fieldId,
								fieldName: plot.fieldName,
								companyName: plot.companyName,
								trialName: `${plot.companyName} - ${plot.trialName}`,
								trial: plot.trialId,
								fieldRangeLength: plot.plotRangeLength,
								fieldColWidth: plot.plotColumnWidth,
								fieldRowCount: plot.fieldRowsPerPlot,
								fieldRowSpacing: plot.fieldRowSpacing,
								fieldRowsPerPlot: plot.rowsPerPlot,
								fieldPassesPerPlot: plot.passesPerPlot,
								fieldPlanterOffset: plot.planterOffset,
								locked: true,
								portColor: "#0ac944",
								new: false,
								analyzed: plot.analyzed,
								crop: plot.crop,
								plantDate: plot.plantDate !== "0001-01-01T00:00:00" ? plot.plantDate : -1,
								harvestDate: plot.harvestDate !== "0001-01-01T00:00:00" ? plot.harvestDate : -1,
								city: plot.city,
								state: plot.state,
								ranges: plot.ranges,
								columns: plot.columns,
								range: plot.range,
								column: plot.column,
								name: plot.plotName,
								quantifiedHeight: plot.quantifiedHeight,
								quantifiedWidth: plot.quantifiedWidth,
								scaleFactor: plot.scaleFactor,
								treatment: plot.treatment,
								flightDate: plot.flightDate
							},
							geometry: {
								type: "Polygon",
								coordinates: [
									[
										[plot.llLong, plot.llLat],
										[plot.ulLong, plot.ulLat],
										[plot.urLong, plot.urLat],
										[plot.lrLong, plot.lrLat],
										[plot.llLong, plot.llLat]
									]
								]
							}
						};
					});

					let fieldIds = _.map(_.uniqBy(res.data.flightsPlots, "fieldId"), (fp) => {
						return { id: fp.fieldId };
					});

					orthoControlFunctions.setPlots(plots);
					await orthoControlFunctions.cutImage(
						clientId,
						moduleNavigation.flightId,
						res.data.orthoImageTypes,
						accessToken,
						dispatch,
						true,
						false,
						fieldIds,
						[],
						true
					);

					if (rerun) {
						handleRerunAnalysisModalChecks();
					} else {
						analyzeFlight();
					}
				}
			}
		);
	}

	async function analyzeFlight() {
		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			if (!res.azureUpload) {
				toast.warn("Images have not finished processing. Please try again shortly");
				setAnalysisRunning(false);
			} else {
				dispatch(
					quantifiedRegionActions.checkIfFlightsQuantifiedRegionsExist(moduleNavigation.flightId, clientId, accessToken)
				).then((r) => {
					if (r?.data) {
						removeUploadStatusMessage();
						toast.info("Analyzing flight. Please wait for the process to finish.");
						dispatch(analysisActions.analyzeFlight(moduleNavigation.flightId, clientId, false, accessToken))
							.then(() => {
								setAnalysisRunning(false);
								getAnalysisData();
								toast.success("Flight analyzed successfully.");
							})
							.catch((e) => {
								//429 = Too Many Requests
								if (e.status === 429) {
									setAnalysisRunning(false);
									toast.error("The server is currently busy. Please try again in a few minutes.");
								} else if (e.status) {
									setAnalysisRunning(false);
									toast.error("Unable to analyze flight.");
								} else {
									//Show tooltip
									setShowTooltip(true);
								}
							});
					} else {
						toast.warn("Quantified Regions have not finished drawing. Please try again shortly");
						setAnalysisRunning(false);
					}
				});
			}
		});
	}

	async function standCount() {
		toast.info("Checking if images have been processed");
		setStandCountRunning(true);
		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			if (!res.azureUpload) {
				toast.warn("Images have not finished processing. Please try again shortly");
				setStandCountRunning(false);
			} else {
				dispatch(
					quantifiedRegionActions.checkIfFlightsQuantifiedRegionsExist(moduleNavigation.flightId, clientId, accessToken)
				).then((r) => {
					if (r?.data) {
						removeUploadStatusMessage();
						toast.info("Stand counting flight. Please wait for the process to finish.");
						dispatch(fieldActions.getFieldsInFlightInfomration(moduleNavigation.flightId, clientId, accessToken)).then(
							(res) => {
								const fields = _.map(_.filter(res, ["imageSizeExceedsAnalysisSize", true]), (filteredField) => {
									return filteredField.fieldName;
								});
								if (fields.length === 0) {
									dispatch(analysisActions.standCountFlight(moduleNavigation.flightId, clientId, false, accessToken))
										.then(() => {
											setStandCountRunning(false);
											getAnalysisData();
											toast.success("Flight stand counted successfully.");
										})
										.catch((e) => {
											//429 = Too Many Requests
											if (e.status === 429) {
												setStandCountRunning(false);
												toast.error("The server is currently busy. Please try again in a few minutes.");
											} else if (e.status) {
												setStandCountRunning(false);
												toast.error("Unable to stand count flight.");
											} else {
												//Show tooltip
												setShowTooltip(true);
											}
										});
								} else {
									setStandCountRunning(false);
									setFieldsThatExceedImageSize(fields);
									toast.warn("Field images are too large.");
								}
							}
						);
					} else {
						toast.warn("Quantified Regions have not finished drawing. Please try again shortly");
						setStandCountRunning(false);
					}
				});
			}
		});
	}

	async function rerunStandCount() {
		toast.info("Checking if images have been processed");
		setRerunStandCountModalOpen(false);
		setRerunStandCountRunning(true);
		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			if (!res.azureUpload) {
				toast.warn("Images have not finished processing. Please try again shortly");
				setRerunStandCountRunning(false);
			} else {
				dispatch(
					quantifiedRegionActions.checkIfFlightsQuantifiedRegionsExist(moduleNavigation.flightId, clientId, accessToken)
				).then((r) => {
					if (r?.data) {
						removeUploadStatusMessage();
						toast.info("Stand counting flight. Please wait for the process to finish.");
						dispatch(fieldActions.getFieldsInFlightInfomration(moduleNavigation.flightId, clientId, accessToken)).then(
							(res) => {
								const fields = _.map(_.filter(res, ["imageSizeExceedsAnalysisSize", true]), (filteredField) => {
									return filteredField.fieldName;
								});
								if (fields.length === 0) {
									dispatch(analysisActions.standCountFlight(moduleNavigation.flightId, clientId, true, accessToken))
										.then(() => {
											setRerunStandCountRunning(false);
											getAnalysisData();
											toast.success("Flight stand counted successfully.");
										})
										.catch((e) => {
											//429 = Too Many Requests
											if (e.status === 429) {
												setRerunStandCountRunning(false);
												toast.error("The server is currently busy. Please try again in a few minutes.");
											} else if (e.status) {
												setRerunStandCountRunning(false);
												toast.error("Unable to stand count flight.");
											} else {
												//Show tooltip
												setShowTooltip(true);
											}
										});
								} else {
									setStandCountRunning(false);
									setFieldsThatExceedImageSize(fields);
									toast.warn("Field images are too large.");
								}
							}
						);
					} else {
						toast.warn("Quantified Regions have not finished drawing. Please try again shortly");
						setRerunStandCountRunning(false);
					}
				});
			}
		});
	}

	async function rerunAnalysis() {
		toast.info("Re-Analyzing flight. Please wait for the process to finish.");
		setRerunAnalysisModalOpen(false);
		setRerunAnalysisRunning(true);
		const accessToken = await getTokenSilently();
		dispatch(analysisActions.analyzeFlight(moduleNavigation.flightId, clientId, true, accessToken))
			.then(() => {
				setRerunAnalysisRunning(false);
				getAnalysisData();
				toast.success("Flight analyzed successfully.");
			})
			.catch((e) => {
				//429 = Too Many Requests
				if (e.status === 429) {
					setRerunAnalysisRunning(false);
					toast.error("The server is currently busy. Please try again in a few minutes.");
				}
				if (e.status) {
					setRerunAnalysisRunning(false);
					toast.error("Unable to analyze flight.");
				} else {
					//Show tooltip
					setShowTooltip(true);
				}
			});
	}

	async function handleRerunAnalysisModalChecks() {
		const accessToken = await getTokenSilently();
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
			removeUploadStatusMessage();
			if (!res.azureUpload) {
				setRerunAnalysisRunning(false);
				toast.warn("Images have not finished processing. Please try again shortly");
			} else {
				dispatch(
					quantifiedRegionActions.checkIfFlightsQuantifiedRegionsExist(moduleNavigation.flightId, clientId, accessToken)
				).then((r) => {
					if (r?.data) {
						setRerunAnalysisRunning(false);
						setRerunAnalysisModalOpen(true);
					} else {
						toast.warn("Quantified Regions have not finished drawing. Please try again shortly");
						setRerunAnalysisRunning(false);
					}
				});
			}
		});
	}

	async function handleRerunAnalysisModalOpen() {
		if (rerunAnalysisModalOpen === true) {
			setRerunAnalysisModalOpen(false);
		} else {
			checkCutImages(true);
		}
	}

	async function handleRerunStandCountModalOpen() {
		if (rerunStandCountModalOpen === true) {
			setRerunStandCountModalOpen(false);
		} else {
			toast.info("Checking if images have been processed");
			setRerunStandCountRunning(true);
			const accessToken = await getTokenSilently();
			dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken)).then((res) => {
				removeUploadStatusMessage();
				setRerunStandCountRunning(false);
				if (!res.azureUpload) {
					toast.warn("Images have not finished processing. Please try again shortly");
				} else {
					dispatch(
						quantifiedRegionActions.checkIfFlightsQuantifiedRegionsExist(
							moduleNavigation.flightId,
							clientId,
							accessToken
						)
					).then((r) => {
						if (r?.data) {
							setRerunStandCountModalOpen(true);
						} else {
							toast.warn("Quantified Regions have not finished drawing. Please try again shortly");
							setRerunStandCountRunning(false);
						}
					});
				}
			});
		}
	}

	function handleDeleteAnalysisModalOpen() {
		setDeleteAnalysisModalOpen(!deleteAnalysisModalOpen);
	}

	async function deleteFlightAnalysis() {
		toast.info("Deleting flight analysis. Please wait for the process to finish.");
		setDeleteAnalysisModalOpen(false);
		setDeletingFlightAnalysisIsRunning(true);
		const accessToken = await getTokenSilently();
		dispatch(analysisActions.deleteFlightAnalysis(moduleNavigation.flightId, clientId, accessToken))
			.then(() => {
				//setRerunAnalysisRunning(false);
				setDeletingFlightAnalysisIsRunning(false);
				getAnalysisData();
				toast.success("Flight analysis data deleted.");
			})
			.catch((e) => {
				console.log(e);
				setDeletingFlightAnalysisIsRunning(false);
			});
	}

	function getAnalysisResult(analysisResults, analysis, quantifiedRegion) {
		const result = _.find(analysisResults, (far) => {
			return far.analysisId === analysis.id && far.quantifiedRegionTypeId === quantifiedRegion.id;
		});

		return result;
	}

	return failedToLoad === true || (dataSource.length === 0 && !loading) ? (
		<Segment basic style={{ marginTop: 15, marginBottom: 15 }}>
			<p>{"We could not find analysis data for this flight."}</p>
		</Segment>
	) : loading ? (
		<Loader active />
	) : (
		<>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{flightName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			<Segment basic style={{ minHeight: 50 }}>
				{fieldsThatExceedImageSize.length > 0 ? (
					<Message warning style={{ marginTop: "unset" }}>
						<p>The following fields image sizes are too large for stand counting:</p>
						<Message.List items={fieldsThatExceedImageSize}></Message.List>
					</Message>
				) : null}
				{!dataDone && (
					<Message warning>
						Plot analysis data for this flight is currently being recalculated, so data may not be complete or up to
						date. Please refresh the page in a few minutes.
					</Message>
				)}
				<Button
					id="form-button-back"
					floated="left"
					secondary
					content={"Back"}
					onClick={() => {
						history.push(moduleNavigation.createFlightLink(true, null, "add-new-fields"));
					}}
				/>
				{userAuth.isApAdmin === true ||
				userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId) ? (
					<Modal
						id="modal-delete"
						open={deleteAnalysisModalOpen}
						trigger={
							<Button
								id="button-delete"
								negative
								floated="right"
								content="Delete"
								disabled={
									analysisRunning ||
									rerunAnalysisRunning ||
									standCountRunning ||
									rerunStandCountRunning ||
									!dataSource ||
									analyzingOnServer ||
									dataSource.length === 0 ||
									deletingFlightAnalysisIsRunning
								}
								loading={deletingFlightAnalysisIsRunning}
								onClick={handleDeleteAnalysisModalOpen}
							/>
						}
					>
						<Modal.Header>
							WARNING - All existing flight analysis data and trials published will be deleted!
						</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								<Header>Are you sure want to delete the flight data?</Header>
								<Button floated="right" id="button-delete-action" onClick={deleteFlightAnalysis} negative>
									Delete
								</Button>
								<Button floated="right" id="button-close-delete-modal" onClick={handleDeleteAnalysisModalOpen}>
									Cancel
								</Button>
								<br style={{ clear: "both" }} />
							</Modal.Description>
						</Modal.Content>
					</Modal>
				) : null}

				<Button
					id="button-analyze"
					floated="right"
					color="green"
					content={"Analyze"}
					loading={analysisRunning || analyzingOnServer}
					disabled={
						rerunAnalysisRunning ||
						analysisRunning ||
						standCountRunning ||
						rerunStandCountRunning ||
						!dataSource ||
						analyzingOnServer ||
						dataSource.length === 0 ||
						deletingFlightAnalysisIsRunning ||
						!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId)
					}
					onClick={() => checkCutImages(false)}
				/>

				<Modal
					id="modal-reanalyze"
					open={rerunAnalysisModalOpen}
					trigger={
						<Button
							id="button-reanalyze"
							primary
							floated="right"
							content="Re-Analyze"
							disabled={
								analysisRunning ||
								rerunAnalysisRunning ||
								standCountRunning ||
								rerunStandCountRunning ||
								!dataSource ||
								analyzingOnServer ||
								dataSource.length === 0 ||
								deletingFlightAnalysisIsRunning ||
								!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId)
							}
							loading={rerunAnalysisRunning}
							onClick={() => handleRerunAnalysisModalOpen()}
						/>
					}
				>
					<Modal.Header>WARNING - All existing flight analysis data will be deleted!</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<Header>Are you sure want to re-analyze the flight?</Header>
							<Button floated="right" id="button-reanalyze-action" onClick={rerunAnalysis} primary>
								Re-Analyze
							</Button>
							<Button floated="right" id="button-close-analysis-modal" onClick={() => handleRerunAnalysisModalOpen()}>
								Cancel
							</Button>
							<br style={{ clear: "both" }} />
						</Modal.Description>
					</Modal.Content>
				</Modal>
				{showTooltip ? (
					<Popup
						content="Fields are analyzing/stand counting on the server. To check if the analysis process is done, reload the page by pressing F5."
						trigger={<Icon name="info circle" style={{ marginLeft: 5, float: "right" }} />}
					/>
				) : null}
			</Segment>
			<Segment basic>
				<Button
					id="button-stand-count"
					floated="right"
					color="green"
					content={"Stand Count"}
					loading={standCountRunning || analyzingOnServer}
					disabled={
						rerunAnalysisRunning ||
						analysisRunning ||
						standCountRunning ||
						rerunStandCountRunning ||
						!dataSource ||
						analyzingOnServer ||
						dataSource.length === 0 ||
						deletingFlightAnalysisIsRunning ||
						!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId)
					}
					onClick={standCount}
				/>
				<Modal
					id="modal-rerun-stand-count"
					open={rerunStandCountModalOpen}
					trigger={
						<Button
							id="button-rerun-stand-count"
							primary
							floated="right"
							content="Rerun Stand Count"
							disabled={
								analysisRunning ||
								rerunAnalysisRunning ||
								standCountRunning ||
								rerunStandCountRunning ||
								!dataSource ||
								analyzingOnServer ||
								dataSource.length === 0 ||
								deletingFlightAnalysisIsRunning ||
								!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId)
							}
							loading={rerunStandCountRunning}
							onClick={handleRerunStandCountModalOpen}
						/>
					}
				>
					<Modal.Header>WARNING - All existing flight stand count data will be deleted!</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<Header>Are you sure want to re-analyze the flight?</Header>
							<Button id="button-rerun-stand-count-action" onClick={rerunStandCount} primary>
								Rerun Stand Count
							</Button>
							<Button id="button-close-stand-count-modal" onClick={handleRerunStandCountModalOpen}>
								Cancel
							</Button>
						</Modal.Description>
					</Modal.Content>
				</Modal>
			</Segment>
			<Segment basic>
				<p style={{ float: "right", textAlign: "right" }}>
					Stand counting will only be accurate for flights that are pre V4.
				</p>
			</Segment>
			<Segment basic>
				<span style={{ float: "right", textAlign: "right" }}>
					{dataSource.length === 1 ? `${dataSource.length} field in flight` : `${dataSource.length} fields in flight`}
					{flightCsvData && userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId) && (
						<>
							<br />
							<CSVLink filename={flightFileName} data={flightCsvData} enclosingCharacter={""}>
								Download
							</CSVLink>
						</>
					)}
				</span>
				{plotCsvData && !userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysisCRUD, clientId) && (
					<CSVLink filename={plotFileName} data={plotCsvData} enclosingCharacter={""}>
						Download Plot Analysis
					</CSVLink>
				)}
			</Segment>
			<Segment basic style={{ overflow: "auto", flexWrap: "nowrap" }}>
				<Table celled striped sortable selectable color="blue" structured>
					<Table.Header>
						<Table.Row textAlign="center">
							<Table.HeaderCell
								sorted={sortColumn === "fieldName" ? sortDirection : null}
								onClick={() => handleSort("fieldName")}
								rowSpan="2"
								style={{ width: "20px" }}
							>
								Field
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "cropName" ? sortDirection : null}
								onClick={() => handleSort("cropName")}
								rowSpan="2"
								style={{ width: "20px" }}
							>
								Crop
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={sortColumn === "plantDate" ? sortDirection : null}
								onClick={() => handleSort("plantDate")}
								rowSpan="2"
								style={{ width: "20px" }}
							>
								Plant Date
							</Table.HeaderCell>
							{_.map(analysesOnFlight, (aof) => {
								return (
									<Table.HeaderCell key={aof.id} colSpan={quantifiedRegions.length}>
										{aof.name}
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
						<Table.Row textAlign="center">
							{_.map(analysesOnFlight, (aof) => {
								return _.map(quantifiedRegions, (qr) => {
									return (
										<Table.HeaderCell
											key={qr.id.concat(aof.id)}
											sorted={sortColumn === `${aof.name} - ${qr.name}` ? sortDirection : null}
											onClick={() => handleSort(`${aof.name} - ${qr.name}`)}
											style={{ width: "20px" }}
										>
											{qr.name}
										</Table.HeaderCell>
									);
								});
							})}
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{_.map(tableData, ({ fieldId, fieldName, cropName, plantDate, analysisResults }) => {
							return (
								<Table.Row key={fieldId}>
									<Table.Cell>
										<Link to={moduleNavigation.createFieldLink(true, fieldId)}>{fieldName}</Link>
									</Table.Cell>
									<Table.Cell>{cropName}</Table.Cell>
									<Table.Cell>{plantDate ? moment(plantDate).local().format(DATE_FORMAT) : null}</Table.Cell>
									{_.map(analysesOnFlight, (aof) => {
										return _.map(quantifiedRegions, (qr) => {
											const result = getAnalysisResult(analysisResults, aof, qr);
											return (
												<Tooltip
													key={`${aof.id}-${qr.id}`}
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													trigger="hover"
													overlay={
														<div>
															{aof.name}:{" "}
															{result?.dateTime ? (
																moment.utc(result?.dateTime).local().format(ANALYSIS_DATE_FORMAT)
															) : (
																<span style={{ opacity: 0.5 }}>Not run</span>
															)}
														</div>
													}
													transitionName="rc-tooltip-zoom"
												>
													<Table.Cell
														positive={result?.goodResult === true}
														error={result?.goodResult === false}
														textAlign="center"
													>
														{result?.goodResult === true ? (
															result?.value?.toFixed(4) ?? "-"
														) : (
															<span style={{ opacity: 0.5 }}>Not run</span>
														)}
													</Table.Cell>
												</Tooltip>
											);
										});
									})}
								</Table.Row>
							);
						})}
					</Table.Body>

					<Table.Footer>
						<Table.Row textAlign="right">
							<Table.HeaderCell colSpan={3 + analysesOnFlight?.length * quantifiedRegions?.length}>
								<span style={{ marginRight: 10 }}>
									{dataSource.length === 1
										? `${dataSource.length} field in flight`
										: `${dataSource.length} fields in flight`}
								</span>
								<Pagination
									onPageChange={(e, pageInfo) => {
										setActivePage(pageInfo.activePage);
									}}
									boundaryRange={5}
									siblingRange={1}
									totalPages={totalPages}
									activePage={activePage}
								/>
								<Dropdown
									onChange={(e, { value }) => {
										setItemsPerPage(value);
									}}
									selection
									options={[
										{ key: 1, text: 5, value: 5 },
										{ key: 2, text: 10, value: 10 },
										{ key: 3, text: 15, value: 15 },
										{ key: 4, text: 25, value: 25 },
										{ key: 5, text: 50, value: 50 }
									]}
									value={itemsPerPage}
									compact
									style={{ marginLeft: 5, height: 42, paddingTop: 12 }}
								/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</>
	);
};

FlightAnalysis.propTypes = {
	removeUploadStatusMessage: PropTypes.func.isRequired,
	flightName: PropTypes.string.isRequired
};

export default FlightAnalysis;
