const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getCrops(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Crop?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
export function getCropDetails(accessToken) {
	return fetch(`${aerialPlotApi}api/v1/Crop/GetCropDetails`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createCrop(accessToken, crop) {
	const cropFormData = new FormData();

	for (const name in crop) {
		let val = crop[name] === "" ? null : crop[name];

		if (val) {
			if (typeof val === "string") val = val?.trim();
			cropFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Crop`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: cropFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateCrop(accessToken, crop) {
	const cropFormData = new FormData();

	for (const name in crop) {
		let val = crop[name] === "" ? null : crop[name];

		if (val) {
			if (typeof val === "string") val = val?.trim();
			cropFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Crop`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: cropFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteCrop(accessToken, cropId) {
	return fetch(`${aerialPlotApi}api/v1/Crop?cropId=${cropId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
