import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import { Loader, Segment } from "semantic-ui-react";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

import AssessmentLog from "../../../Lumber/AssessmentLog";
const TrialAssessmentLog = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	const userSettings = useUserSettings();

	const [trialName, setTrialName] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (entityNames.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(entityNames.trialName);
			setLoading(false);
		}
	}, [entityNames]);

	return loading ? (
		<Loader active />
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Assessment Log</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget style={{ display: "inline"}} clientId={userAuth.currentClientId.toUpperCase()} trialId={moduleNavigation.trialId.toUpperCase()} userSettings={userSettings}></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			<AssessmentLog isTrial={true} isField={false} name={trialName} />
		</Segment>
	);
};

export default TrialAssessmentLog;
