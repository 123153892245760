const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function updateWeatherData(dateRange, clientId, accessToken) {
	return fetch(
		aerialPlotApi +
		"api/v1/WeatherData/UpdateWeatherData?startDate=" +
		dateRange.startDate +
		"&endDate=" +
		dateRange.endDate +
		"&clientId=" +
		clientId,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getGDUForTrial(trialId, clientId, accessToken) {
	return fetch(aerialPlotApi + "api/v1/WeatherData/GrowingDegreeUnits?trialId=" + trialId + "&clientId=" + clientId, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getCurrentGDUForTrial(trialId, clientId, accessToken) {
	return fetch(
		aerialPlotApi + "api/v1/WeatherData/GrowingDegreeUnitsCurrent?trialId=" + trialId + "&clientId=" + clientId,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getGDUForTrialHistorical(trialId, clientId, accessToken) {
	return fetch(
		aerialPlotApi + "api/v1/WeatherData/GrowingDegreeUnitsHistorical?trialId=" + trialId + "&clientId=" + clientId,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function validateWeatherData(trialId, clientId, accessToken) {
	return fetch(aerialPlotApi + "api/v1/WeatherData/ValidateWeatherData?trialId=" + trialId + "&clientId=" + clientId, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then(() => {
			return getGDUForTrial(trialId, clientId, accessToken);
			//return data;
		});
}

export function validateCurrentWeatherData(trialId, clientId, accessToken) {
	return fetch(
		aerialPlotApi + "api/v1/WeatherData/ValidateCurrentWeatherData?trialId=" + trialId + "&clientId=" + clientId,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then(() => {
			return getCurrentGDUForTrial(trialId, clientId, accessToken);
			//return data;
		});
}

export function getHistoricalWeatherData(trialId, clientId, accessToken) {
	return fetch(
		aerialPlotApi + "api/v1/WeatherData/GetHistoricalWeatherData?trialId=" + trialId + "&clientId=" + clientId,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getTrialDailyWeather(trialId, clientId, accessToken) {
	return fetch(aerialPlotApi + "api/v1/WeatherData/GetTrialDailyWeather?trialId=" + trialId + "&clientId=" + clientId, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createHistoricalWeatherData(trialId, clientId, accessToken) {
	return fetch(
		aerialPlotApi + "api/v1/WeatherData/CreateHistoricalWeatherData?trialId=" + trialId + "&clientId=" + clientId,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateHistoricalWeatherData(dateRange, clientId, accessToken) {
	return fetch(
		aerialPlotApi +
		"api/v1/WeatherData/UpdateHistoricalWeatherData?startDate=" +
		dateRange.startDate +
		"&endDate=" +
		dateRange.endDate +
		"&clientId=" +
		clientId,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function inspectWeatherData(weatherDataCsv, newWeatherDataSetsVm, clientId, accessToken) {
	const weatherDataSetsFormData = new FormData();
	let datasetIndex = 0;

	if (newWeatherDataSetsVm.length > 0) {
		newWeatherDataSetsVm.splice(newWeatherDataSetsVm.length - 1, 1);
	}

	/*for (const name in newWeatherDataSetsVm) {
		let val = newWeatherDataSetsVm[name] === "" ? null : newWeatherDataSetsVm[name];
		if (typeof val !== "object") {
			weatherDataSetsFormData.set(name, val);
		}
	}*/
	for (const name in newWeatherDataSetsVm) {
		let val = newWeatherDataSetsVm[name] === "" ? null : newWeatherDataSetsVm[name];
		//weatherDataSetsFormData.set(name, val);
		//weatherDataSetsFormData.append("newWeatherDataVm", val);
		for (const t in val) {
			let v = val[t] === "" ? null : val[t];
			if (v) {
				weatherDataSetsFormData.set(t, v);
			}
		}
		if (typeof val !== "object") {
			weatherDataSetsFormData.set(name, val);
		}
	}

	//weatherDataSetsFormData.append(
	//`WeatherDataSets[${datasetIndex}].Temperature`, newWeatherDataSetsVm[0].Temperature);
	weatherDataSetsFormData.append("weatherDataCsv", weatherDataCsv, weatherDataCsv.name);

	for (const newWeatherDataVm of newWeatherDataSetsVm) {
		weatherDataSetsFormData.append(`WeatherDataSets[${datasetIndex}].weatherDataset.Day`, newWeatherDataVm.Day);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.DailyHigh`,
			newWeatherDataVm.DailyHigh
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.DailyLow`,
			newWeatherDataVm.DailyLow
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.Precipitation`,
			newWeatherDataVm.Precipitation
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.WindSpeed`,
			newWeatherDataVm.WindSpeed
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.WindSpeedMean`,
			newWeatherDataVm.WindSpeedMean
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.Temperature`,
			newWeatherDataVm.Temperature
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.Humidity`,
			newWeatherDataVm.Humidity
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.SolarEnergy`,
			newWeatherDataVm.SolarEnergy === undefined ? "" : newWeatherDataVm.SolarEnergy
		);
		weatherDataSetsFormData.append(
			`WeatherDataSets[${datasetIndex}].weatherDataset.SolarRadiation`,
			newWeatherDataVm.SolarRadiation === undefined ? "" : newWeatherDataVm.SolarRadiation
		);
		datasetIndex = datasetIndex + 1;
	}

	//weatherDataSetsFormData.append(`Temperature`,
	//newWeatherDataSetsVm[0].Temperature === undefined ? null : newWeatherDataSetsVm[0].Temperature);

	/*for (const newWeatherDataVm of newWeatherDataSetsVm.groundDataSets) {
		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.DateCollected`,
			newWeatherDataVm.groundDataset?.actualDateCollected &&
				newWeatherDataVm.groundDataset?.actualDateCollected !== "Invalid date"
				? newWeatherDataVm.groundDataset?.actualDateCollected
				: ""
		);
		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.CollectedByPersonId`,
			newWeatherDataVm.groundDataset?.collectedByPersonId ?? ""
		);

		//weatherDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.FieldId`, outlineData[0].fieldId);
		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GrowthStageId`,
			newWeatherDataVm.groundDataset?.growthStageId ?? ""
		);
		//weatherDataSetsFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.Crop`, outlineData[0].crop);
		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDatasetTemplateId`,
			newWeatherDataVm.groundDataset?.groundDatasetTemplateId &&
				newWeatherDataVm.groundDataset?.groundDatasetTemplateId !== "none"
				? newWeatherDataVm.groundDataset?.groundDatasetTemplateId
				: ""
		);

		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataTimingMethodId`,
			newWeatherDataVm.groundDataset?.selectedTimingMethodId ?? ""
		);
		weatherDataSetsFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataGrowthPhaseId`,
			newWeatherDataVm.groundDataset?.growthPhaseId ?? ""
		);

		if (newWeatherDataVm.groundDataset?.selectedTimingMethodType === "Date") {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseDate`,
				newWeatherDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newWeatherDataVm.groundDataset?.selectedTimingMethodType === "StartEnd") {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newWeatherDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseEndStageId`,
				newWeatherDataVm.groundDataset?.plannedTiming?.growthStageEnd ?? ""
			);
		} else if (newWeatherDataVm.groundDataset?.selectedTimingMethodType === "int") {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseInteger`,
				newWeatherDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newWeatherDataVm.groundDataset?.selectedTimingMethodType === "Text") {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseText`,
				newWeatherDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newWeatherDataVm.groundDataset?.selectedTimingMethodType === "Start") {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newWeatherDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
		}
		index = 0;
		for (const data of newWeatherDataVm.assessments) {
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].DateCollected`,
				newWeatherDataVm.groundDataset?.currentDateCollected &&
					newWeatherDataVm.groundDataset?.currentDateCollected !== "Invalid date"
					? newWeatherDataVm.groundDataset?.currentDateCollected
					: ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Name`,
				data.assessment.name
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataAssessmentId`,
				data.assessment.id
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].AverageFor`,
				data?.assessment?.averageFor ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataUnitId`,
				data.unit.id
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleId`,
				data.sampleDefinitionNumber?.id ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SampleDefinitionNumber`,
				data.sampleDefinitionNumber?.name ?? "1"
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataDeviceId`,
				data.device?.id ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataMethodId`,
				data.method.id
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceId`,
				data.source.id
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceName`,
				data.source.name
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionTypeId`,
				data.groundDataSamplePart?.groundDataSampleDefinitionTypeId ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionPartId`,
				data.groundDataSamplePart?.id ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Width`,
				data.width ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Length`,
				data.length ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Radius`,
				data.radius ?? ""
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Order`,
				index + 1
			);
			weatherDataSetsFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Notes`,
				data.notes ?? ""
			);
			index++;
		}

		datasetIndex++;
	}

	let index = 0;
	/*for (const data of outlineData) {
		weatherDataSetsFormData.append(`PlotInfo[${index}].PlotId`, data.plotId);
		weatherDataSetsFormData.append(`PlotInfo[${index}].Range`, data.range);
		weatherDataSetsFormData.append(`PlotInfo[${index}].Column`, data.column);
		weatherDataSetsFormData.append(`PlotInfo[${index}].TrialId`, data.trialId ?? "");
		index++;
	}*/

	/*index = 0;
	for (const validation of newWeatherDataSetsVm.groundDataValidation) {
		weatherDataSetsFormData.append(`GroundDataValidation[${index}].AssessmentName`, validation.assessmentName);
		weatherDataSetsFormData.append(`GroundDataValidation[${index}].DataType`, validation.dataType);
		weatherDataSetsFormData.append(`GroundDataValidation[${index}].NumOfPlots`, validation.numOfPlots);

		index++;
	}*/
	return fetch(aerialPlotApi + "api/v1/WeatherData/InspectWeatherData", {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: weatherDataSetsFormData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getGduDataForTrials(trials, currentClientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/WeatherData/GetGduDataForTrials?clientId=${currentClientId}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function generateWeatherDataExcel(trialId, currentDateTimeString, clientId, accessToken) {
	return fetch(`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/WeatherData/GenerateTrialWeatherDataExcel?trialId=${trialId}&currentDateTimeString=${currentDateTimeString}&clientId=${clientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function generateProtocolWeatherDataExcel(
	trials,
	clientId,
	clientName,
	currentDateTimeString,
	accessToken
) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/WeatherData/GenerateProtocolWeatherDataExcel?clientId=${clientId}&clientName=${clientName}&currentDateTimeString=${currentDateTimeString}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

