import * as types from "./actionTypes";
import * as uasApi from "../../apis/uasApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getConfigureUasesDataSuccess() {
	return { type: types.UAS_GET_CONFIGURE_UASES_DATA_SUCCESS };
}

export function getUasesDetailsSuccess() {
	return { type: types.UAS_GET_UASES_DETAILS_SUCCESS };
}

export function getSensorsSuccess() {
	return { type: types.UAS_GET_SENSORS_SUCCESS };
}

export function getUavsSuccess() {
	return { type: types.UAS_GET_UAVS_SUCCESS };
}

export function createUasSuccess() {
	return { type: types.UAS_CREATE_SUCCESS };
}

export function updateUasSuccess() {
	return { type: types.UAS_UPDATE_SUCCESS };
}

export function deleteUasSuccess() {
	return { type: types.UAS_DELETE_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getConfigureUasesData(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.getConfigureUasesData(accessToken, clientId)
				.then((res) => {
					dispatch(getConfigureUasesDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getUasesDetails(accessToken, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.getUasesDetails(accessToken, clientId)
				.then((res) => {
					dispatch(getUasesDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getSensors(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.getSensors(accessToken)
				.then((res) => {
					dispatch(getSensorsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getUavs(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.getUavs(accessToken)
				.then((res) => {
					dispatch(getUavsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createUas(accessToken, uas) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.createUas(accessToken, uas)
				.then((res) => {
					dispatch(createUasSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateUas(accessToken, uas) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.updateUas(accessToken, uas)
				.then((res) => {
					dispatch(updateUasSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteUas(accessToken, uasId, clientId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return uasApi
				.deleteUas(accessToken, uasId, clientId)
				.then((res) => {
					dispatch(deleteUasSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
