import * as types from "./actionTypes";
import * as companyApi from "../../apis/companyApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getCompanyManagementInfoSuccess() {
	return { type: types.COMPANIES_GET_COMPANY_MANAGEMENT_INFO_SUCCESS };
}

export function createCompanySuccess() {
	return { type: types.COMPANIES_CREATE_COMPANY_SUCCESS };
}

export function editCompanySuccess() {
	return { type: types.COMPANIES_EDIT_COMPANY_SUCCESS };
}

export function deleteCompanySuccess() {
	return { type: types.COMPANIES_DELETE_COMPANY_SUCCESS };
}

export function generateCompanyCodeSuccess() {
	return { type: types.COMPANIES_GENERATE_COMPANY_CODE_SUCCESS };
}

export function getCooperatorCompaniesSuccess() {
	return { type: types.COMPANIES_GET_COOPERATOR_COMPANIES_SUCCESS };
}

export function getCompanyTypesSuccess() {
	return { type: types.COMPANIES_GET_COMPANY_TYPES_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getCompanyManagementInfo(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.getCompanyManagementInfo(clientId, accessToken)
				.then((res) => {
					dispatch(getCompanyManagementInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createCompany(company, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.createCompany(company, accessToken)
				.then((res) => {
					dispatch(createCompanySuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function editCompany(company, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.editCompany(company, accessToken)
				.then((res) => {
					dispatch(editCompanySuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteCompany(companyId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.deleteCompany(companyId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteCompanySuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateCompanyCode(clientId, companyName, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.generateCompanyCode(clientId, companyName, accessToken)
				.then((res) => {
					dispatch(generateCompanyCodeSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCooperatorCompanies(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.getCooperatorCompanies(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getCooperatorCompaniesSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCompanyTypes(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return companyApi
				.getCompanyTypes(clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getCompanyTypesSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
