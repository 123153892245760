import React from "react";
import PropTypes from "prop-types";
import {
	NEUTRAL_CORRELATION_COLOR,
	NEGATIVE_CORRELATION_COLOR,
	POSITIVE_CORRELATION_COLOR
} from "../CorrelationColors";

import { Grid } from "semantic-ui-react";

const CorrelationLegend = ({negativeCorrelationColor, neutralCorrelationColor, positiveCorrelationColor}) => {
	return (
		<Grid>
			<Grid.Row centered={true} style={{ padding: "0 0 0 0" }}>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>Strong Positive</p>
				</Grid.Column>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>None</p>
				</Grid.Column>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>Strong Negative</p>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row centered={true} style={{ padding: "0 0 0 0" }}>
				<Grid.Column width="9" stretched style={{ height: "2rem" }}>
					<div
						style={{
							background:
								"linear-gradient(0.25turn,rgb(" +
								positiveCorrelationColor.red +
								", " +
								positiveCorrelationColor.green +
								", " +
								positiveCorrelationColor.blue +
								"), rgb(" +
								neutralCorrelationColor.red +
								", " +
								neutralCorrelationColor.green +
								", " +
								neutralCorrelationColor.blue +
								"), rgb(" +
								negativeCorrelationColor.red +
								", " +
								negativeCorrelationColor.green +
								", " +
								negativeCorrelationColor.blue +
								"))"
						}}
					></div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row centered={true} style={{ padding: "0 0 0 0" }}>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>1</p>
				</Grid.Column>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>0</p>
				</Grid.Column>
				<Grid.Column width="4">
					<p style={{ textAlign: "center" }}>-1</p>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

CorrelationLegend.propTypes = {
	negativeCorrelationColor: PropTypes.object,
	neutralCorrelationColor: PropTypes.object,
	positiveCorrelationColor: PropTypes.object
};

CorrelationLegend.defaultProps = {
	neutralCorrelationColor: NEUTRAL_CORRELATION_COLOR,
	negativeCorrelationColor: NEGATIVE_CORRELATION_COLOR,
	positiveCorrelationColor: POSITIVE_CORRELATION_COLOR
};

export default CorrelationLegend;
