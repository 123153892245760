import PropTypes from "prop-types";

import { BitmapLayer } from "@deck.gl/layers";
import { TileLayer } from "@deck.gl/geo-layers";

import * as uuid from "uuid";

class FiveColorBitmapLayer extends BitmapLayer {
	getShaders() {
		return {
			...super.getShaders(),
			inject: {
				"fs:#decl": `
						uniform vec4 color1;
						uniform vec4 color2;
						uniform vec4 color3;
						uniform vec4 color4;
						uniform vec4 color5;

						uniform float min;
						uniform float max;
					`,
				"fs:DECKGL_FILTER_COLOR": `
						vec4 maxColor = vec4(vec3(max), 0);
						vec4 minColor = vec4(vec3(min), 0);

						vec4 range = maxColor - minColor;
						vec4 modifiedColor =  vec4(((vec4(clamp(color.rgb, min, max), 1) - minColor) / range).rgb, 1);
						
						float grayscale = dot(modifiedColor.rgb, vec3(0.299, 0.587, 0.114));
						float originalGS = dot(color.rgb, vec3(0.299, 0.587, 0.114));

						if (originalGS < 0.03) {
							discard;
						} else {
							vec4 newColor = mix(color1, color2, smoothstep(0.0, 0.25, grayscale));
							newColor = mix(newColor, color3, smoothstep(0.25, 0.5, grayscale));
							newColor = mix(newColor, color4, smoothstep(0.5, 0.75, grayscale));
							color = mix(newColor, color5, smoothstep(0.75, 1.0, grayscale));
						}
						`
			}
		};
	}

	draw(opts) {
		const { colorRange, min, max } = this.props;

		opts.uniforms.color1 = colorRange[0].map((x) => x / 255);
		opts.uniforms.color2 = colorRange[1].map((x) => x / 255);
		opts.uniforms.color3 = colorRange[2].map((x) => x / 255);
		opts.uniforms.color4 = colorRange[3].map((x) => x / 255);
		opts.uniforms.color5 = colorRange[4].map((x) => x / 255);

		opts.uniforms.min = Number.parseFloat(min);
		opts.uniforms.max = Number.parseFloat(max);

		super.draw(opts);
	}
}

class SevenColorBitmapLayer extends BitmapLayer {
	getShaders() {
		return {
			...super.getShaders(),
			inject: {
				"fs:#decl": `
						uniform vec4 color1;
						uniform vec4 color2;
						uniform vec4 color3;
						uniform vec4 color4;
						uniform vec4 color5;
						uniform vec4 color6;
						uniform vec4 color7;

						uniform float min;
						uniform float max;
					`,
				"fs:DECKGL_FILTER_COLOR": `
						vec4 maxColor = vec4(vec3(max), 0);
						vec4 minColor = vec4(vec3(min), 0);

						vec4 range = maxColor - minColor;
						vec4 modifiedColor = vec4(((vec4(clamp(color.rgb, min, max), 1) - minColor) / range).rgb, 1);

						float grayscale = dot(modifiedColor.rgb, vec3(0.299, 0.587, 0.114));
						float originalGS = dot(color.rgb, vec3(0.299, 0.587, 0.114));

						if (originalGS < 0.03) {
							discard;
						} else {
							vec4 newColor = mix(color1, color2, smoothstep(0.0, 0.167, grayscale));
							newColor = mix(newColor, color3, smoothstep(0.167, 0.33, grayscale));
							newColor = mix(newColor, color4, smoothstep(0.33, 0.5, grayscale));
							newColor = mix(newColor, color5, smoothstep(0.5, 0.67, grayscale));
							newColor = mix(newColor, color6, smoothstep(0.67, 0.837, grayscale));
							color = mix(newColor, color7, smoothstep(0.837, 1.0, grayscale));
						}
					`
			}
		};
	}

	draw(opts) {
		const { colorRange, min, max } = this.props;

		opts.uniforms.color1 = colorRange[0].map((x) => x / 255);
		opts.uniforms.color2 = colorRange[1].map((x) => x / 255);
		opts.uniforms.color3 = colorRange[2].map((x) => x / 255);
		opts.uniforms.color4 = colorRange[3].map((x) => x / 255);
		opts.uniforms.color5 = colorRange[4].map((x) => x / 255);
		opts.uniforms.color6 = colorRange[5].map((x) => x / 255);
		opts.uniforms.color7 = colorRange[6].map((x) => x / 255);

		opts.uniforms.min = Number.parseFloat(min);
		opts.uniforms.max = Number.parseFloat(max);

		super.draw(opts);
	}
}

class ClampedBitmapLayer extends BitmapLayer {
	getShaders() {
		return {
			...super.getShaders(),
			inject: {
				"fs:#decl": `
						uniform float min;
						uniform float max;
					`,
				"fs:DECKGL_FILTER_COLOR": `
						vec4 maxColor = vec4(vec3(max), 0);
						vec4 minColor = vec4(vec3(min), 0);

						vec4 range = maxColor - minColor;
						vec4 clampedColor = vec4(((vec4(clamp(color.rgb, min, max), 1) - minColor) / range).rgb, 1);

						float originalGS = dot(color.rgb, vec3(0.299, 0.587, 0.114));

						if (originalGS < 0.03) {
							discard;
						} else {
							color = clampedColor;
						}
					`
			}
		};
	}

	draw(opts) {
		const { min, max } = this.props;

		opts.uniforms.min = Number.parseFloat(min);
		opts.uniforms.max = Number.parseFloat(max);

		super.draw(opts);
	}
}

export class RYGLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new FiveColorBitmapLayer({
					id: `shader-ryg-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					colorRange: [
						[128, 0, 0, 255],
						[255, 0, 0, 255],
						[255, 255, 0, 255],
						[0, 255, 0, 255],
						[0, 128, 0, 255]
					],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}
RYGLayer.layerName = "RYGLayer";
RYGLayer.propTypes = {
	data: PropTypes.object.isRequired
};

export class ThermalLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new FiveColorBitmapLayer({
					id: `shader-thermal-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					colorRange: [
						[0, 0, 69, 255],
						[69, 0, 128, 255],
						[255, 0, 0, 255],
						[255, 255, 0, 255],
						[255, 255, 255, 255]
					],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}

ThermalLayer.propTypes = {
	data: PropTypes.object.isRequired
};

export class GreenFireBlueLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new FiveColorBitmapLayer({
					id: `shader-gfb-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					colorRange: [
						[0, 0, 0, 255],
						[0, 0, 255, 255],
						[0, 255, 0, 255],
						[255, 255, 0, 255],
						[255, 255, 255, 255]
					],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}

GreenFireBlueLayer.propTypes = {
	data: PropTypes.object.isRequired
};

export class SpectralLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new SevenColorBitmapLayer({
					id: `shader-spectral-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					colorRange: [
						[117, 0, 0, 255],
						[209, 0, 0, 255],
						[212, 130, 0, 255],
						[255, 255, 0, 255],
						[77, 255, 0, 255],
						[0, 255, 247, 255],
						[0, 17, 255, 255]
					],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}

SpectralLayer.propTypes = {
	data: PropTypes.object.isRequired
};

export class BlueYellowLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new SevenColorBitmapLayer({
					id: `shader-by-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					colorRange: [
						[0, 0, 255, 255],
						[157, 2, 215, 255],
						[205, 52, 181, 255],
						[234, 95, 148, 255],
						[250, 135, 117, 255],
						[255, 177, 78, 255],
						[255, 215, 0, 255]
					],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}

BlueYellowLayer.propTypes = {
	data: PropTypes.object.isRequired
};

export class ClampedLayer extends TileLayer {
	constructor({ data }) {
		super({
			data: data.tileUrl,
			tileSize: 256,
			id: data.layerId,
			minZoom: 0,
			maxZoom: 26,
			pickable: true,
			maxRequests: 0,
			maxCacheSize: 1000000,
			onTileUnload: () => {
				console.log("Tile removed from cache");
			},
			onTileError: () => {},

			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north }
				} = props.tile;

				return new ClampedBitmapLayer({
					id: `shader-clamped-${uuid.v4()}`,
					data: null,
					image: props.data,
					bounds: [west, south, east, north],
					min: data.min,
					max: data.max
				});
			}
		});
	}
}

ClampedLayer.propTypes = {
	data: PropTypes.object.isRequired
};
