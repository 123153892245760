import JSZip from "jszip";
//Convert Excel data to array buffer
//https://github.com/SheetJS/js-xlsx/blob/master/README.md
export function s2ab(s) {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
	return buf;
}

export function str2bytes(str) {
	var bytes = new Uint8Array(str.length);
	for (var i = 0; i < str.length; i++) {
		bytes[i] = str.charCodeAt(i);
	}
	return bytes;
}

export function downloadExcelFile(res, excelFileName) {
	//window.atob decodes a base64 string
	const file = new Blob([s2ab(window.atob(res))], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	});

	const element = document.createElement("a");
	element.href = URL.createObjectURL(file);
	element.download = excelFileName;

	// simulate link click
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
	document.body.removeChild(element);
}

export function downloadZippedExcelFile(res, protocolName) {
	//Need to replace dots because folders can't have them in the name
	let newProtocolName = protocolName.split('.').join('_')
	const element = document.createElement("a");
	const zip = new JSZip();
	res.forEach((el) => {
		//window.atob decodes a base64 string
		const file = new Blob([s2ab(window.atob(el.trialData))], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		});

		zip.file(el.trialName, file);
	});

	element.download = newProtocolName;
	zip
		.generateAsync({
			type: "blob",
			compression: "DEFLATE",
			compressionOptions: {
				level: 5
			}
		})
		.then(function (content) {
			element.href = URL.createObjectURL(content);
			// simulate link click
			document.body.appendChild(element); // Required for this to work in FireFox
			element.click();
			document.body.removeChild(element);
		});
}
