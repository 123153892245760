// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card {\n\twidth: 300px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tbackground-color: #fff;\n\tborder-radius: 0.28571429rem;\n\tborder: 1px solid rgba(34, 36, 38, 0.15);\n\tpadding: 15px;\n\tmargin: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/AdminTools/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,sBAAsB;CACtB,sBAAsB;CACtB,4BAA4B;CAC5B,wCAAwC;CACxC,aAAa;CACb,WAAW;AACZ","sourcesContent":[".card {\n\twidth: 300px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tbackground-color: #fff;\n\tborder-radius: 0.28571429rem;\n\tborder: 1px solid rgba(34, 36, 38, 0.15);\n\tpadding: 15px;\n\tmargin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card"
};
export default ___CSS_LOADER_EXPORT___;
