// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-editable:focus {\n\toutline: 0;\n}\n\n.content-editable {\n\tpadding: 1rem !important;\n}\n\n.ui.table td.narrow {\n\tpadding: 0;\n}\n\n.ui.table td {\n\tpadding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Protocols/Treatments/styles.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,aAAa;AACd","sourcesContent":[".content-editable:focus {\n\toutline: 0;\n}\n\n.content-editable {\n\tpadding: 1rem !important;\n}\n\n.ui.table td.narrow {\n\tpadding: 0;\n}\n\n.ui.table td {\n\tpadding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-editable": "content-editable",
	"ui": "ui",
	"table": "table",
	"narrow": "narrow"
};
export default ___CSS_LOADER_EXPORT___;
