import _ from "lodash";

export function validateQuantifiedRegions(quantifiedRegions, field) {
	let validation = { error: false, errorList: [] };
	quantifiedRegions = _.map(quantifiedRegions, (qr) => {
		qr = validateQuantifiedRegion(qr, field, validation);
		return qr;
	});

	buildErrorList(validation, field);

	return validation;
}

function validateQuantifiedRegion(quantifiedRegion, field, validation) {
	//-- quantified region type
	if (!quantifiedRegion.quantifiedRegionTypeId) {
		validation.quantifiedRegionTypeIdError = true;
		validation.error = true;
	}

	if (quantifiedRegion.quantifiedRegionTypeName === "Plot-Centered" && quantifiedRegion.columns !== 1) {
		validation.plotCenteredColumnsError = true;
		validation.error = true;
	}

	if (quantifiedRegion.quantifiedRegionTypeName === "Plot-Centered" && quantifiedRegion.ranges !== 1) {
		validation.plotCenteredRangesError = true;
		validation.error = true;
	}

	if (
		quantifiedRegion.quantifiedRegionTypeName === "Row-Centered" ||
		quantifiedRegion.quantifiedRegionTypeName === "Full Row"
	) {
		const evenNumberOfPlotColumns = field.rowsPerPlot % 2 === 0;
		const evenNumberOfQRColumns = quantifiedRegion.columns % 2 === 0;
		const bothTypesSimilar = evenNumberOfPlotColumns === evenNumberOfQRColumns;

		if (!bothTypesSimilar || quantifiedRegion.columns > field.rowsPerPlot) {
			validation.unevenNumberOfColumns = true;
			validation.error = true;
		}
	}

	if (quantifiedRegion.quantifiedRegionTypeName === "Between-Row") {
		const evenNumberOfPlotColumns = field.rowsPerPlot % 2 === 0;
		const evenNumberOfQRColumns = quantifiedRegion.columns % 2 === 0;
		const bothTypesSimilar = evenNumberOfPlotColumns === evenNumberOfQRColumns;

		if (bothTypesSimilar || quantifiedRegion.columns > field.rowsPerPlot - 1) {
			validation.evenNumberOfColumns = true;
			validation.error = true;
		}
	}

	if (!Number(quantifiedRegion.width) > 0 || Number(quantifiedRegion.width) > Number(field.columnWidth)) {
		validation.widthError = true;
		validation.error = true;
	}

	if (!Number(quantifiedRegion.length) > 0 || Number(quantifiedRegion.length) > Number(field.rangeLength)) {
		validation.lengthError = true;
		validation.error = true;
	}

	if (!Number(quantifiedRegion.columns) > 0) {
		validation.columnsError = true;
		validation.error = true;
	}

	if (!Number(quantifiedRegion.ranges) > 0) {
		validation.rangesError = true;
		validation.error = true;
	}

	if (Number(quantifiedRegion.ranges) * Number(quantifiedRegion.columns) > 100) {
		validation.tooManySubsamplesError = true;
		validation.error = true;
	}

	if (!quantifiedRegion.quantifiedRegionGeometryId) {
		validation.quantifiedRegionGeometryIdError = true;
		validation.error = true;
	}

	if (
		!Number(quantifiedRegion.quantifiedWidth) > 0 ||
		Number(quantifiedRegion.quantifiedWidth) > Number(quantifiedRegion.width) / Number(quantifiedRegion.columns)
	) {
		validation.quantifiedWidthError = true;
		validation.error = true;
	}

	if (
		!Number(quantifiedRegion.quantifiedLength) > 0 ||
		Number(quantifiedRegion.quantifiedLength) > Number(quantifiedRegion.length) / Number(quantifiedRegion.ranges)
	) {
		validation.quantifiedLengthError = true;
		validation.error = true;
	}

	return validation;
}

function buildErrorList(validation, field) {
	if (validation.quantifiedRegionTypeIdError === true) {
		validation.errorList.push("Position is required.");
	}

	if (validation.widthError === true) {
		validation.errorList.push("Width is required and must be equal to or less than the field column width.");
	}

	if (validation.lengthError === true) {
		validation.errorList.push("Length is required and must be equal to or less than the field range length.");
	}

	if (validation.columnsError === true) {
		validation.errorList.push("Columns is required.");
	}

	if (validation.plotCenteredColumnsError === true) {
		validation.errorList.push("Plot-Centered quantified region types must have 1 column");
	}

	if (validation.plotCenteredRangesError === true) {
		validation.errorList.push("Plot-Centered quantified region types must have 1 range");
	}

	if (validation.rangesError === true) {
		validation.errorList.push("Ranges is required.");
	}

	if (validation.tooManySubsamplesError === true) {
		validation.errorList.push("The max number of subsamples per quantified region is 100.");
	}

	if (validation.quantifiedRegionGeometryIdError === true) {
		validation.errorList.push("Subsample Geometry is required.");
	}

	if (validation.quantifiedWidthError === true) {
		validation.errorList.push("Quantified Width is required and must be equal to the width / columns or less.");
	}

	if (validation.quantifiedLengthError === true) {
		validation.errorList.push("Quantified Length is required and must be equal to the length / ranges or less.");
	}

	if (validation.unevenNumberOfColumns === true) {
		if (field.rowsPerPlot % 2 === 0) {
			validation.errorList.push(
				`Field has an even number of rows per plot (${field.rowsPerPlot}). Row-Centered and Full Row quantified region types require the number of rows per plot to be even and the max number of rows per plot cannot be more than the number of rows per plot in the field.`
			);
		} else {
			validation.errorList.push(
				`Field has an odd number of rows per plot (${field.rowsPerPlot}). Row-Centered and Full Row quantified region types require the number of rows per plot to be odd and the max number of rows per plot cannot be more than the number of rows per plot in the field.`
			);
		}
	}

	if (validation.evenNumberOfColumns === true) {
		if (field.rowsPerPlot % 2 === 0) {
			validation.errorList.push(
				`Field has an even number of rows per plot (${field.rowsPerPlot}). Between-Row quantified region types require the number of rows per plot to be odd and the max number of rows per plot cannot be more than the number of rows per plot minus 1 in the field.`
			);
		} else {
			validation.errorList.push(
				`Field has an odd number of rows per plot (${field.rowsPerPlot}). Between-Row quantified region types require the number of rows per plot to be even and the max number of rows per plot cannot be more than the number of rows per plot minus 1 in the field.`
			);
		}
	}
}
