import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Loader, Form } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as sensorActions from "../../../../redux/actions/sensorActions";

const SensorManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const dispatch = useDispatch();

	//-- Data Source
	const [sensors, setSensors] = useState(null);
	const [clientName, setClientName] = useState(null);

	//-- Selected Data
	const [selectedSensor, setSelectedSensor] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			setClientName(userAuth.user.userInfo.clientMemberships.find((cm) => cm.clientId === clientId).clientName);
			getData();
		}
		setMode("update");
	}, [userAuth.isReady, clientId]);

	const getData = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(sensorActions.getSensorDetailsData(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setSensors(res.data);
				} else {
					toast.error("Unable to load sensor data. Please try again.");
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load sensors. Please try again.");
			});
	};

	//-- Sensors
	useEffect(() => {
		if (sensors && sensors.length > 0) {
			if (!selectedSensor) {
				sensorSelected(sensors[0].id, sensors[0].name);
			} else {
				sensorSelected(selectedSensor.id, selectedSensor.name);
			}
			setMode("update");
		}
	}, [sensors]);

	useEffect(() => {
		if (sensors !== null) {
			setLoading(false);
		}
	}, [sensors, selectedSensor]);

	const sensorSelected = (sensorId, sensorName) => {
		if (sensorId || sensorName) {
			const selSensor = sensors.find((s) => s.id === sensorId || s.name === sensorName);
			setSelectedSensor(selSensor);
			setMode("update");
		}
	};

	function prepareNewSensorForm() {
		setSelectedSensor(null);
		setMode("create");
	}

	const createSensor = async () => {
		setSaving(true);
		const accessToken = await getTokenSilently();
		dispatch(sensorActions.createSensor(accessToken, selectedSensor))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Sensor created");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch(() => {
				toast.error("Unable to create Sensor. Please try again.");
				setSaving(false);
			});
	};

	const updateSensor = async () => {
		setSaving(true);
		const accessToken = await getTokenSilently();
		dispatch(sensorActions.updateSensor(accessToken, selectedSensor))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Sensor updated");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch((error) => {
				toast.error("Unable to update Sensor. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	const deleteSensor = async () => {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(sensorActions.deleteSensor(accessToken, selectedSensor.id))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Sensor deleted");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setDeleting(false);
			})
			.catch((error) => {
				toast.error("Unable to delete Sensor. Please try again.");
				console.log(error);
				setDeleting(false);
			});
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Configure Sensor</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{clientName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
				<Form>
					<Grid id="configure-sensor-grid" columns="equal">
						<Grid.Row>
							<Grid.Column width={4}>
								<label htmlFor="form-select-sensor" className="fieldLabel">
									Sensors
								</label>
								<Form.Button
									id="new-sensor"
									color="green"
									content="New Sensor"
									style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
									onClick={() => prepareNewSensorForm()}
								/>
								<List
									id="select-sensor"
									selection
									size="big"
									style={{
										minHeight: 150,
										maxHeight: 300,
										marginTop: "unset",
										overflowY: "auto",
										border: "1px solid #DEDEDF",
										backgroundColor: "#FFFFFF"
									}}
								>
									{_.map(sensors, ({ id, name }) => {
										return (
											<List.Item
												key={id}
												active={selectedSensor?.id === id}
												onClick={() => {
													sensorSelected(id, name);
												}}
											>
												<List.Content>
													<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
												</List.Content>
											</List.Item>
										);
									})}
								</List>
							</Grid.Column>
							<Grid.Column>
								<Form.Group widths={"equal"}>
									<Form.Input
										id="form-input-name"
										width={12}
										label="Sensor"
										value={selectedSensor?.name ?? ""}
										required
										onChange={(event) => setSelectedSensor({ ...selectedSensor, name: event.target.value })}
									/>
									<Form.Input
										id="form-input-company"
										width={12}
										label="Company"
										value={selectedSensor?.company ?? ""}
										required
										onChange={(event) => setSelectedSensor({ ...selectedSensor, company: event.target.value })}
									/>
								</Form.Group>
								<Form.Group style={{ marginBottom: 3 }}>
									<label htmlFor="form-text-area-description" className="fieldLabel" style={{ marginLeft: 7 }}>
										Description
									</label>
								</Form.Group>
								<Form.Group>
									<Form.TextArea
										id="form-text-area-description"
										width={16}
										value={selectedSensor?.description ?? ""}
										onChange={(event) => setSelectedSensor({ ...selectedSensor, description: event.target.value })}
										rows="5"
									/>
								</Form.Group>
								<hr style={{ clear: "both" }} />
								<Form.Group>
									<Form.Checkbox
										id="form-sensor-enabled"
										width={3}
										key="1"
										label="Sensor Enabled?"
										style={{ marginTop: 33 }}
										checked={selectedSensor?.enabled ?? false}
										onClick={(event, data) => {
											setSelectedSensor({ ...selectedSensor, enabled: data.checked });
										}}
									/>

									<Form.Checkbox
										id="form-sensor-multispectral"
										width={3}
										label="Multispectral?"
										style={{ marginTop: 33 }}
										checked={selectedSensor?.multispectral ?? false}
										onChange={(event, data) => {
											setSelectedSensor({ ...selectedSensor, multispectral: data.checked });
										}}
									/>

									<Form.Checkbox
										id="form-sensor-integratedReflectanceCalibration"
										width={3}
										label="Integrated Reflectance Calibration?"
										style={{ marginTop: 33 }}
										checked={selectedSensor?.integratedReflectanceCalibration ?? false}
										onChange={(event, data) => {
											setSelectedSensor({ ...selectedSensor, integratedReflectanceCalibration: data.checked });
										}}
									/>

									<Form.Checkbox
										id="form-sensor-rgb"
										width={3}
										label="RGB?"
										style={{ marginTop: 33 }}
										checked={selectedSensor?.rgb ?? false}
										onChange={(event, data) => {
											setSelectedSensor({ ...selectedSensor, rgb: data.checked });
										}}
									/>

									<Form.Checkbox
										id="form-sensor-thermal"
										width={3}
										label="Thermal?"
										style={{ marginTop: 33 }}
										checked={selectedSensor?.thermal ?? false}
										onChange={(event, data) => {
											setSelectedSensor({ ...selectedSensor, thermal: data.checked });
										}}
									/>
								</Form.Group>
								<Form.Group style={{ float: "right" }}>
									{mode === "update" && (selectedSensor?.canDelete ?? false) && (
										<Form.Button
											floated="right"
											negative
											content="Delete"
											onClick={() => {
												deleteSensor();
											}}
											disabled={deleting}
											loading={deleting}
										/>
									)}
									<Form.Button
										floated="right"
										color="green"
										content={mode === "create" ? "Create" : "Update"}
										onClick={() => {
											if (mode === "create") {
												createSensor();
											} else {
												updateSensor();
											}
										}}
										disabled={saving}
										loading={saving}
									/>
								</Form.Group>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			)}
		</Segment>
	);
};

export default SensorManagement;
