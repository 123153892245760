import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Dropdown, Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import * as ControlFunctions from "./orthoControlFunctions.js";
import _ from "lodash";

import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";

import * as fieldActions from "../../../redux/actions/fieldActions";

const AddExistingFieldsModal = React.forwardRef(
	({ draw, addFieldModal, canvas, dropdownRef, componentRefs }, addExistingFieldsModal) => {
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();
		const moduleNavigation = useModuleNavigation();
		const userAuth = useUserAuth();

		const [modalOpen, setModalOpen] = useState(false);
		const [fieldOptions, setFieldOptions] = useState(null);
		const [fieldId, setFieldId] = useState(null);
		const [loading, setLoading] = useState(false);

		AddExistingFieldsModal.displayName = "AddExistingFieldsModal";

		useEffect(() => {
			if (modalOpen) {
				getDropdownOptions();
			}
		}, [modalOpen]);

		async function getDropdownOptions() {
			if (moduleNavigation.flightId) {
				const accessToken = await getTokenSilently();
				dispatch(fieldActions.getUnusedFields(userAuth.currentClientId, moduleNavigation.flightId, accessToken)).then(
					(res) => {
						setFieldOptions(
							_.map(res, (record, index) => {
								if (index === 0) {
									setFieldId(record.fieldId);
								}

								const opt = {
									key: record.fieldId,
									value: record.fieldId,
									text: record.name
								};
								return opt;
							})
						);
					}
				);
			}
		}

		function handleAddField() {
			setModalOpen(false);
			addFieldModal.current.handleClick();
		}

		async function handleAddPlots() {
			setLoading(true);
			const accessToken = await getTokenSilently();
			dispatch(fieldActions.getNewFieldInfo(fieldId, userAuth.currentClientId, accessToken)).then((res) => {
				if (res.field) {
					ControlFunctions.handleFieldAdd(
						res.plots,
						res.field,
						res.field.id,
						res.quantifiedRegions,
						draw,
						canvas(),
						dropdownRef,
						componentRefs
					);

					let filteredOptions = _.filter(fieldOptions, ({ key }) => {
						return key !== fieldId;
					});

					setFieldOptions(filteredOptions);
					setFieldId(filteredOptions.length > 0 ? filteredOptions[0].key : null);
					setModalOpen(false);
				} else {
					toast.error("This field hasn't finished being set up.", { autoClose: 10000 });
				}

				setLoading(false);
			});
		}

		return (
			<>
				<Modal
					trigger={
						<Button
							style={{ visibility: "hidden" }}
							ref={addExistingFieldsModal}
							onClick={() => {
								setModalOpen(true);
							}}
						/>
					}
					open={modalOpen}
				>
					<Modal.Header>Add Field</Modal.Header>
					<Modal.Content>
						<Grid>
							<Grid.Row>
								<Grid.Column width="7">
									<Dropdown
										selection
										fluid
										options={fieldOptions}
										placeholder={_.isEmpty(fieldOptions) ? "No existing fields without coordinates found" : "Select"}
										value={fieldId}
										onChange={(event, { value }) => {
											setFieldId(value);
										}}
										disabled={loading}
										loading={!fieldOptions}
									/>
								</Grid.Column>
								<Grid.Column width="3">
									<Button
										color="green"
										content={"Add"}
										disabled={!fieldOptions || fieldOptions.length === 0 || loading}
										loading={loading}
										onClick={() => {
											handleAddPlots();
										}}
									/>
								</Grid.Column>
								<Grid.Column width="3">
									<Button
										secondary
										content={"New Field"}
										disabled={loading}
										loading={loading}
										onClick={() => {
											handleAddField();
										}}
									/>
								</Grid.Column>
								<Grid.Column width="3">
									<Button
										content={"Cancel"}
										floated="right"
										disabled={loading}
										loading={loading}
										onClick={() => {
											draw.current.draw.changeMode("simple_select");
											setModalOpen(false);
										}}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Content>
				</Modal>
			</>
		);
	}
);

AddExistingFieldsModal.propTypes = {
	draw: PropTypes.object.isRequired,
	addFieldModal: PropTypes.object.isRequired,
	canvas: PropTypes.object,
	dropdownRef: PropTypes.object,
	componentRefs: PropTypes.object
};

export default AddExistingFieldsModal;
