import * as types from "./actionTypes";
import * as applicationApi from "../../apis/applicationApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
/*
 *  Action Creators
 *
 */

export function getApplicationOptionsSuccess() {
	return { type: types.APPLICATIONS_GET_APPLICATION_OPTIONS_SUCCESS };
}
export function getApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_GET_APPLICATIONS_SUCCESS, applications };
}
export function saveApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_SAVE_APPLICATIONS_SUCCESS, applications };
}
export function createApplicationsSuccess() {
	return { type: types.APPLICATIONS_CREATE_APPLICATIONS_SUCCESS };
}
export function updateApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_UPDATE_APPLICATIONS_SUCCESS, applications };
}
// export function deleteApplicationsSuccess() {
// 	return { type: types.APPLICATIONS_DELETE_APPLICATIONS_SUCCESS };
// }
// export function propagateProtocolApplicationsToTrialsSuccess() {
// 	return { type: types.APPLICATIONS_PROPAGATE_PROTOCOL_APPLICATIONS_TO_TRIALS_SUCCESS };
// }
/*
 *  Thunks
 *
 */

export function getApplicationOptions(clientId, cropId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.getApplicationOptions(clientId, cropId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getApplicationOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolApplications(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.getProtocolApplications(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getApplicationsSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialApplications(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.getTrialApplications(trialId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getApplicationsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createProtocolApplications(applications, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.createProtocolApplications(applications, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createApplicationsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

// export function createTrialApplications(applications, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.createTrialApplications(applications, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(createApplicationsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function updateProtocolApplications(applications, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.updateProtocolApplications(applications, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(updateApplicationsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function updateTrialApplications(applications, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.updateTrialApplications(applications, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(updateApplicationsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function deleteProtocolApplications(applicationIds, protocolId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.deleteProtocolApplications(applicationIds, protocolId, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(deleteApplicationsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

// export function deleteTrialApplications(applicationIds, trialId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.deleteTrialApplications(applicationIds, trialId, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(deleteApplicationsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }

export function saveApplications(applications, protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.saveApplications(applications, protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(saveApplicationsSuccess(["pass"]));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateApplications(applications, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.updateApplications(applications, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(updateApplicationsSuccess(["pass"]));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

// export function updateAndDeleteProtocolApplications(applications, applicationIds, protocolId, clientId, accessToken) {
// 	return (dispatch) => {
// 		dispatch(updateProtocolApplications(applications, clientId, accessToken));
// 		dispatch(deleteProtocolApplications(applicationIds, protocolId, clientId, accessToken));
// 	};
// }

// export function propagateProtocolApplicationsToTrials(applications, protocolId, clientId, accessToken) {
// 	return (dispatch) => {
// 		return new Promise((resolve, reject) => {
// 			dispatch(beginApiCall());

// 			return applicationApi
// 				.propagateProtocolApplicationsToTrials(applications, protocolId, clientId, accessToken)
// 				.then((res) => {
// 					if (res.statusCode !== 200) {
// 						dispatch(apiCallError(res.data));
// 						reject(res.data);
// 					}
// 					dispatch(propagateProtocolApplicationsToTrialsSuccess());
// 					resolve(res);
// 				})
// 				.catch((error) => {
// 					dispatch(apiCallError(error));
// 					reject(error);
// 				});
// 		});
// 	};
// }
