import * as types from "./actionTypes";
import * as analysisApi from "../../apis/analysisApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function analyzeFlightSuccess() {
	return { type: types.ANALYSIS_ANALYZE_FLIGHT_SUCCESS };
}

export function getAnalysesSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSES_SUCCESS };
}

export function getAnalysesDetailsSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSES_DETAILS_SUCCESS };
}

export function standCountFlightSuccess() {
	return { type: types.ANALYSIS_STAND_COUNT_SUCCESS };
}

export function deleteFlightAnalysisSuccess() {
	return { type: types.ANALYSIS_DELETE_FLIGHT_ANALYSIS_SUCCESS };
}

export function getAnalysisResultsForFlightSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FLIGHT_SUCCESS };
}

export function getAnalysisResultsForFieldSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS };
}

export function getAnalysisResultsForTrialSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS };
}

export function getAnalysisResultsForSummaryTableSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_SUMMARY_TABLE_SUCCESS };
}

export function getAnalysisResultsForTrialWithMeanComparisonSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_WITH_MEAN_COMPARISON_SUCCESS };
}

export function getCorrelationPlotAnalysisResultsForTrialSuccess() {
	return { type: types.ANALYSUS_GET_CORRELATION_PLOT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS };
}

export function getCorrelationMatrixDataSuccess() {
	return { type: types.ANALYSIS_GET_CORRELATION_MATRIX_DATA_SUCCESS };
}

export function getPlotAnalysisResultsForTrialForProtocolSummaryTableSuccess() {
	return { type: types.ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_FOR_PROTOCOL_SUMMARY_TABLE_SUCCESS };
}

export function getHeatmapAnalysisResultsForTrialSuccess() {
	return { type: types.ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS };
}

export function getHeatmapAnalysisResultsForFieldSuccess() {
	return { type: types.ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS };
}

export function getProtocolAnalysisResultsForTimecourseSuccess() {
	return { type: types.PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TIMECOURSE_SUCCESS };
}

export function getProtocolAnalysisResultsForBoxWhiskerSuccess() {
	return { type: types.PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_BOX_WHISKER_SUCCESS };
}

export function getProtocolAnalysisResultsForPianoChartsSuccess() {
	return { type: types.PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_PIANO_CHARTS_SUCCESS };
}

export function getFlightAnalysisResultsForTrialSuccess() {
	return { type: types.ANALYSIS_GET_FLIGHT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS };
}

export function deleteAnalysisDataForAdminsSuccess() {
	return { type: types.ANALYSIS_DELETE_ANALYSIS_DATA_FOR_ADMIN_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getAnalyses(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalyses(clientId, accessToken)
				.then((res) => {
					if (res.ok === false) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(getAnalysesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysesDetails(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysesDetails(clientId, accessToken)
				.then((res) => {
					if (res.ok === false) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(getAnalysesDetailsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function analyzeFlight(flightId, clientId, rerunAnalysis, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.analyzeFlight(flightId, clientId, rerunAnalysis, accessToken)
				.then((res) => {
					if (res.ok === false) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(analyzeFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function standCountFlight(flightId, clientId, rerunAnalysis, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.standCountFlight(flightId, clientId, rerunAnalysis, accessToken)
				.then((res) => {
					if (res.ok === false) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(standCountFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFlightAnalysis(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.deleteFlightAnalysis(flightId, clientId, accessToken)
				.then((res) => {
					if (res.ok === false) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(deleteFlightAnalysisSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysisResultsForFlight(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysisResultsForFlight(flightId, clientId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getAnalysisResultsForFlightSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysisResultsForField(clientId, fieldId, isForLegacy, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysisResultsForField(clientId, fieldId, isForLegacy, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getAnalysisResultsForFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysisResultsForTrial(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeDamagedOrExcluded = true
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysisResultsForTrial(
					clientId,
					trialId,
					accessToken,
					groundDataAssessmentId,
					groundDataGrowthPhaseId,
					curveModelAnalysisId,
					analysisId,
					onlyGetAnalysisData,
					analysisTypeId,
					onlyGetGrowthCurveData,
					removeDamagedOrExcluded
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getAnalysisResultsForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolAnalysisResultsForTimecourse(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getProtocolAnalysisResultsForTimecourse(
					clientId,
					trialId,
					accessToken,
					groundDataAssessmentId,
					groundDataGrowthPhaseId,
					curveModelAnalysisId,
					analysisId,
					onlyGetAnalysisData,
					analysisTypeId,
					onlyGetGrowthCurveData,
					removeOutliers,
					removeDamagedOrExcluded,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getProtocolAnalysisResultsForTimecourseSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolAnalysisResultsForBoxWhisker(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getProtocolAnalysisResultsForBoxWhisker(
					clientId,
					trialId,
					accessToken,
					groundDataAssessmentId,
					groundDataGrowthPhaseId,
					curveModelAnalysisId,
					analysisId,
					onlyGetAnalysisData,
					analysisTypeId,
					onlyGetGrowthCurveData,
					removeOutliers,
					removeDamagedOrExcluded,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getProtocolAnalysisResultsForBoxWhiskerSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getProtocolAnalysisResultsForPianoCharts(
	clientId,
	trialId,
	accessToken,
	groundDataAssessmentId = "",
	groundDataGrowthPhaseId = "",
	curveModelAnalysisId = "",
	analysisId = "",
	onlyGetAnalysisData = false,
	analysisTypeId = "",
	onlyGetGrowthCurveData = false,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getProtocolAnalysisResultsForPianoCharts(
					clientId,
					trialId,
					accessToken,
					groundDataAssessmentId,
					groundDataGrowthPhaseId,
					curveModelAnalysisId,
					analysisId,
					onlyGetAnalysisData,
					analysisTypeId,
					onlyGetGrowthCurveData,
					removeOutliers,
					removeDamagedOrExcluded,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getProtocolAnalysisResultsForPianoChartsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysisResultsForTrialWithMeanComparison(
	clientId,
	trialId,
	analysisId,
	flightId,
	quantifiedRegionTypeId,
	analysisTypeId,
	isPlotUniformity,
	groundDataAssessmentId,
	groundDatasetId,
	curveModelAnalysisId,
	meanComparisonId,
	alphaId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysisResultsForTrialWithMeanComparison(
					clientId,
					trialId,
					analysisId,
					flightId,
					quantifiedRegionTypeId,
					analysisTypeId,
					isPlotUniformity,
					groundDataAssessmentId,
					groundDatasetId,
					curveModelAnalysisId,
					meanComparisonId,
					alphaId,
					accessToken,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getAnalysisResultsForTrialWithMeanComparisonSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getAnalysisResultsForSummaryTable(
	clientId,
	trialId,
	analysisId,
	quantifiedRegionTypeId,
	analysisTypeId,
	groundDataAssessmentId,
	growthCurveAnalysisId,
	selectedMeanComparisonId,
	alphaValueId,
	accessToken,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getAnalysisResultsForSummaryTable(
					clientId,
					trialId,
					analysisId,
					quantifiedRegionTypeId,
					analysisTypeId,
					groundDataAssessmentId,
					growthCurveAnalysisId,
					selectedMeanComparisonId,
					alphaValueId,
					accessToken,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getAnalysisResultsForSummaryTableSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCorrelationPlotAnalysisResultsForTrial(
	clientId,
	trialId,
	analysisId,
	flightId,
	groundDataAssessmentId,
	groundDatasetId,
	curveModelAnalysisId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getCorrelationPlotAnalysisResultsForTrial(
					clientId,
					trialId,
					analysisId,
					flightId,
					groundDataAssessmentId,
					groundDatasetId,
					curveModelAnalysisId,
					accessToken
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getCorrelationPlotAnalysisResultsForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCorrelationMatrixData(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getCorrelationMatrixData(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getCorrelationMatrixDataSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function GetPlotAnalysisResultsForTrialForProtocolSummaryTable(
	clientId,
	trialId,
	accessToken,
	removeOutliers,
	removeDamagedOrExcluded,
	outlierType,
	standCountAnalysisId
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.GetPlotAnalysisResultsForTrialForProtocolSummaryTable(
					clientId,
					trialId,
					accessToken,
					removeOutliers,
					removeDamagedOrExcluded,
					outlierType,
					standCountAnalysisId
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getPlotAnalysisResultsForTrialForProtocolSummaryTableSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getHeatmapAnalysisResultsForTrial(
	clientId,
	trialId,
	analysisId,
	analysisTypeId,
	analysisTypeName,
	assessmentId,
	curveModelAnalysisId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getHeatmapAnalysisResultsForTrial(
					clientId,
					trialId,
					analysisId,
					analysisTypeId,
					analysisTypeName,
					assessmentId,
					curveModelAnalysisId,
					accessToken
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getHeatmapAnalysisResultsForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getHeatmapAnalysisResultsForField(
	clientId,
	fieldId,
	analysisId,
	analysisTypeName,
	assessmentId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getHeatmapAnalysisResultsForField(clientId, fieldId, analysisId, analysisTypeName, assessmentId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getHeatmapAnalysisResultsForFieldSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFlightAnalysisResultsForTrial(clientId, trialId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.getFlightAnalysisResultsForTrial(clientId, trialId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFlightAnalysisResultsForTrialSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteAnalysisDataForAdmins(accessToken, params = {}) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return analysisApi
				.deleteAnalysisDataForAdmins(params.flightId, params.fieldId, params.trialId, params.protocolId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(deleteAnalysisDataForAdminsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
