// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.dimmer .ui.workaround.loader:before {\n\tborder-color: rgba(0, 0, 0, 0.1);\n}\n\n.ui.dimmer .ui.workaround.loader:after {\n\tborder-color: #767676 transparent transparent;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/Timecourse/styles.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;AACjC;;AAEA;CACC,6CAA6C;AAC9C","sourcesContent":[".ui.dimmer .ui.workaround.loader:before {\n\tborder-color: rgba(0, 0, 0, 0.1);\n}\n\n.ui.dimmer .ui.workaround.loader:after {\n\tborder-color: #767676 transparent transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"dimmer": "dimmer",
	"workaround": "workaround",
	"loader": "loader"
};
export default ___CSS_LOADER_EXPORT___;
