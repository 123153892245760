import React, { useState, useEffect } from "react";
import { Segment, Button, Loader, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useAuth0 } from "../../../../auth/auth0";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as flightsPlotsActions from "../../../../redux/actions/flightsPlotsActions";
import * as ControlFunctions from "../../../Lumber/OrthoViewer/orthoControlFunctions";
import FieldAdvancedAnalyticsResults from "./FieldAdvancedAnalyticsResults";

import { validateQuantifiedRegions } from "./Validation";
import DeleteConfirmationModal from "../FieldWizard/deleteModal";

const FieldAdvancedAnalytics = ({ fieldId, fromViewer, handleFinish, handleCancel, handleSave, navigateBack }) => {
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	const [fieldAdvancedAnalyticsData, setFieldAdvancedAnalyticsData] = useState(null);
	const [fieldName, setFieldName] = useState("");
	const [field, setField] = useState(null);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [quantifiedGeometryOptions, setQuantifiedRegionGeometryOptions] = useState([]);
	const [fieldQuantifiedRegions, setFieldQuantifiedRegions] = useState([]);
	const [quantifiedRegionsToDelete, setQuantifiedRegionsToDelete] = useState([]);
	const [quantifiedRegionsToCreate, setQuantifiedRegionsToCreate] = useState([]);
	const [quantifiedRegionsToUpdate, setQuantifiedRegionsToUpdate] = useState([]);
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- FieldId
	const [fieldIdToUse, setFieldIdToUse] = useState("");

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [saveAttempted, setSaveAttempted] = useState(false);
	const [validation, setValidation] = useState(null);

	useEffect(() => {
		setFieldIdToUse(fieldId);
	}, [fieldId]);

	useEffect(() => {
		if (fieldIdToUse) {
			getFieldAdvancedAnalyticsData();
		}
	}, [fieldIdToUse]);

	useEffect(() => {
		if (fieldAdvancedAnalyticsData) {
			//-- Setup DDLs
			setFieldName(fieldAdvancedAnalyticsData?.field?.name);
			setField(fieldAdvancedAnalyticsData?.field);
			setQuantifiedRegionTypeOptionsState(fieldAdvancedAnalyticsData?.quantifiedRegionOptions?.quantifiedRegionTypes);
			setQuantifiedRegionGeometryOptionsState(
				fieldAdvancedAnalyticsData?.quantifiedRegionOptions?.quantifiedRegionGeometries
			);

			//-- Setup existing quantified regions
			let fieldQRs = fieldAdvancedAnalyticsData?.fieldQuantifiedRegions ?? [];
			fieldQRs = _.map(fieldQRs, (f) => {
				f.error = false;
				return f;
			});
			setFieldQuantifiedRegions(fieldQRs);
		}
	}, [fieldAdvancedAnalyticsData]);

	async function getFieldAdvancedAnalyticsData() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.getFieldAdvancedAnalyticsData(fieldIdToUse, clientId, accessToken))
			.then((res) => {
				setFieldAdvancedAnalyticsData(res);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}

	function setQuantifiedRegionTypeOptionsState(types) {
		setQuantifiedRegionTypeOptions(
			_.map(types, ({ id, name }) => {
				const opt = { key: id, value: id, text: name };
				return opt;
			})
		);
	}

	function setQuantifiedRegionGeometryOptionsState(geometries) {
		setQuantifiedRegionGeometryOptions(
			_.map(geometries, ({ id, name }) => {
				const opt = { key: id, value: id, text: name };
				return opt;
			})
		);
	}

	async function save(finish) {
		setSaving(true);
		setSaveAttempted(true);
		let copyQuantifiedRegionsToCreate = _.cloneDeep(quantifiedRegionsToCreate);
		let copyQuantifiedRegionsToUpdate = _.cloneDeep(quantifiedRegionsToUpdate);
		let combinedQuantifiedRegions = copyQuantifiedRegionsToCreate.concat(copyQuantifiedRegionsToUpdate);
		let validationQuantifiedRegions = validateQuantifiedRegions(combinedQuantifiedRegions, field);
		setValidation(validationQuantifiedRegions);
		if (validationQuantifiedRegions.error === true) {
			setSaving(false);
			return;
		}

		const accessToken = await getTokenSilently();
		dispatch(
			fieldActions.saveFieldQuantifiedRegions(
				fieldIdToUse,
				clientId,
				quantifiedRegionsToCreate,
				quantifiedRegionsToUpdate,
				quantifiedRegionsToDelete,
				accessToken
			)
		)
			.then((res) => {
				let createdQuantifiedRegions = _.cloneDeep(quantifiedRegionsToCreate);
				const updatedQuantifiedRegions = _.cloneDeep(quantifiedRegionsToUpdate);
				const deletedQuantifiedRegions = _.cloneDeep(quantifiedRegionsToDelete);

				_.map(createdQuantifiedRegions, (cqr) => {
					cqr.id = _.find(
						res.data,
						(r) =>
							r.quantifiedRegionTypeId === cqr.quantifiedRegionTypeId &&
							r.quantifiedRegionGeometryId === cqr.quantifiedRegionGeometryId
					)?.id;
				});

				//-- This flag means the field is already on a flight
				if (field.canEditFieldDimensions === false || (fromViewer && handleSave)) {
					handleSettingQuantifiedRegions(
						createdQuantifiedRegions,
						updatedQuantifiedRegions,
						deletedQuantifiedRegions,
						accessToken,
						finish
					);
				}

				setSaveAttempted(false);
				setFieldQuantifiedRegions(res.data);
				setQuantifiedRegionsToDelete([]);
				setQuantifiedRegionsToCreate([]);
				setQuantifiedRegionsToUpdate([]);

				if (finish === true) {
					if (res.data?.length === 0) {
						toast.error("Field must have quantified regions to place it on a flight.");
						setSaving(false);
						return;
					}
					if (handleFinish) {
						handleFinish(fieldIdToUse);
					} else if (fromViewer && handleSave) {
						handleCancel();
					}
				}

				if (field.canEditFieldDimensions === true && !(fromViewer && handleSave)) {
					toast.success("Saved quantified regions successfully!");
				}

				setSaving(false);
			})
			.catch((err) => {
				setSaving(false);
				console.log(err);
				if (err.data) {
					toast.error(err.data);
				} else {
					toast.error("Failed to save quantified regions. Please try agian.");
				}
			});
	}

	function handleSettingQuantifiedRegions(
		createdQuantifiedRegions,
		updatedQuantifiedRegions,
		deletedQuantifiedRegions,
		accessToken,
		finish
	) {
		if (createdQuantifiedRegions?.length > 0 || updatedQuantifiedRegions?.length > 0) {
			dispatch(fieldActions.getFlightsWithFieldOrTrial(clientId, fieldIdToUse, null, accessToken)).then(
				async (flights) => {
					let flightOrthoCombinations = [];

					_.map(flights, ({ flightId, orthoImageTypeId, sensorId }) => {
						if (
							!flightOrthoCombinations.some((flight) => {
								return (
									flight.flightId === flightId &&
									flight.orthoImageTypeId === orthoImageTypeId &&
									flight.sensorId === sensorId
								);
							})
						) {
							flightOrthoCombinations.push({
								flightId: flightId,
								orthoImageTypeId: orthoImageTypeId,
								sensorId: sensorId
							});
						}
					});

					let existingFlights = _.uniqBy(flightOrthoCombinations, "flightId");

					// eslint-disable-next-line
					await Promise.all(
						_.map(existingFlights, async ({ flightId }, index) => {
							await dispatch(
								flightsPlotsActions.getExistingFlightsPlotsInField(flightId, fieldIdToUse, clientId, accessToken)
							).then((res) => {
								existingFlights[index].plots = _.map(res.plots, (plot) => {
									return {
										id: plot.plotId,
										type: "Feature",
										properties: {
											type: "plot",
											field: plot.fieldId,
											fieldName: plot.fieldName,
											trial: plot.trialId,
											trialName: `${plot.companyName} - ${plot.trialName}`,
											fieldColWidth: plot.plotColumnWidth,
											locked: true,
											scaleFactor: 1,
											fieldRangeLength: plot.plotRangeLength,
											fieldRowCount: plot.fieldRowsPerPlot,
											fieldRowSpacing: plot.fieldRowSpacing,
											fieldPlanterOffset: plot.planterOffset
										},
										geometry: {
											type: "Polygon",
											coordinates: [
												[
													[plot.llLong, plot.llLat],
													[plot.ulLong, plot.ulLat],
													[plot.urLong, plot.urLat],
													[plot.lrLong, plot.lrLat],
													[plot.llLong, plot.llLat]
												]
											]
										}
									};
								});

								const cQuantifiedRegions = _.filter(res.quantifiedRegionWithDimensions, (qrd) => {
									return _.some(createdQuantifiedRegions, (cqr) => {
										return cqr.id === qrd.fieldQuantifiedRegionId;
									});
								});

								const uQuantifiedRegions = _.filter(res.quantifiedRegionWithDimensions, (qrd) => {
									return _.some(updatedQuantifiedRegions, (uqr) => {
										return uqr.id === qrd.fieldQuantifiedRegionId;
									});
								});
								existingFlights[index].quantifiedRegions = [].concat(cQuantifiedRegions).concat(uQuantifiedRegions);
								existingFlights[index].quantifiedRegionOptions = res.quantifiedRegionOptions;
							});
						})
					);

					//-- Create list of field quantified region ids to create
					let fieldQuantifiedRegionIds = [
						..._.map(createdQuantifiedRegions, "id"),
						..._.map(updatedQuantifiedRegions, "id")
					];

					//-- Remove current flight from list to update
					let flightsToUpdate = fromViewer
						? _.filter(existingFlights, (f) => f.flightId?.toLowerCase() != moduleNavigation.flightId?.toLowerCase())
						: existingFlights;

					// eslint-disable-next-line
					await Promise.all(
						_.forEach(flightsToUpdate, async ({ flightId, plots, quantifiedRegions, quantifiedRegionOptions }) => {
							if (fromViewer) {
								ControlFunctions.setTempPlots(plots);
								ControlFunctions.setTempSubsamplesToAdd(quantifiedRegions);

								await ControlFunctions.saveQuantifiedRegions(
									flightId,
									dispatch,
									accessToken,
									fieldQuantifiedRegionIds,
									fromViewer
								);
							} else {
								ControlFunctions.setPlots(plots);
								ControlFunctions.setSubsamplesToAdd(quantifiedRegions);
								ControlFunctions.setQuantifiedRegionOptions(quantifiedRegionOptions);

								await ControlFunctions.saveQuantifiedRegions(flightId, dispatch, accessToken, fieldQuantifiedRegionIds);
							}
						})
					).then(() => {
						setSaving(false);
						toast.success("Saved quantified regions successfully!");
					});

					if (fromViewer) {
						let quantifiedRegionsToModify = _.find(
							existingFlights,
							(f) => f.flightId?.toLowerCase() == moduleNavigation.flightId?.toLowerCase()
						)?.quantifiedRegions;
						handleSave(quantifiedRegionsToModify, deletedQuantifiedRegions, finish);
					}
				}
			);
		} else if (deletedQuantifiedRegions?.length > 0) {
			if (fromViewer) {
				handleSave(null, deletedQuantifiedRegions, finish);
			}

			setSaving(false);
			toast.success("Saved quantified regions successfully!");
		}
	}

	async function deleteField() {
		const accessToken = await getTokenSilently();
		dispatch(fieldActions.deleteField(fieldIdToUse, clientId, accessToken))
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return loading ? (
		<Loader active inline="centered" />
	) : (
		<Segment basic>
			<Segment basic style={{ marginBottom: 50 }}>
				<Button
					id="form-button-back"
					floated="left"
					color="black"
					content={"Back"}
					disabled={saving}
					onClick={navigateBack}
				/>
				{!fromViewer && (
					<DeleteConfirmationModal handleDeleteField={() => deleteField()} floated="right" disabled={saving} />
				)}
				<Button
					id="form-button-save"
					floated="right"
					color="blue"
					content={"Save"}
					onClick={() => {
						save(false);
					}}
					disabled={saving}
					loading={saving}
				/>
				{fromViewer && (
					<>
						<Button
							id="form-button-save-and-finish"
							floated="right"
							color="green"
							content={"Save & Finish"}
							onClick={() => {
								save(true);
							}}
							disabled={saving}
							loading={saving}
						/>
						<Button
							id="form-button-cancel"
							type="button"
							floated="right"
							disabled={saving}
							onClick={() => {
								setSaving(true);
								handleCancel();
							}}
						>
							Cancel
						</Button>
					</>
				)}
			</Segment>
			<Segment basic>
				<h2 style={{ float: "left" }}>Quantified Regions</h2>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{fieldName}</i>
				</h2>

				<hr style={{ clear: "both" }} />
			</Segment>
			<Segment basic>
				{validation?.error === true ? <Message error list={validation?.errorList} /> : null}

				<FieldAdvancedAnalyticsResults
					quantifiedRegionTypeOptions={quantifiedRegionTypeOptions}
					quantifiedRegionGeometryOptions={quantifiedGeometryOptions}
					fieldQuantifiedRegions={fieldQuantifiedRegions}
					quantifiedRegionsToDelete={quantifiedRegionsToDelete}
					setQuantifiedRegionsToDelete={setQuantifiedRegionsToDelete}
					setQuantifiedRegionsToCreate={setQuantifiedRegionsToCreate}
					setQuantifiedRegionsToUpdate={setQuantifiedRegionsToUpdate}
					field={field}
					saveAttempted={saveAttempted}
				/>
			</Segment>
		</Segment>
	);
};

FieldAdvancedAnalytics.propTypes = {
	fieldId: PropTypes.string,
	deleteField: PropTypes.func,
	fromViewer: PropTypes.bool,
	handleFinish: PropTypes.func,
	handleCancel: PropTypes.func,
	handleSave: PropTypes.func,
	navigateBack: PropTypes.func
};

export default FieldAdvancedAnalytics;
