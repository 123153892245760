import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { toast } from "react-toastify";
import { Dropdown, Grid, Loader, Segment, Checkbox } from "semantic-ui-react";

import { useAuth0 } from "../../../auth/auth0";
import { useUserAuth } from "../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";

import * as ControlFunctions from "./orthoControlFunctions.js";
import * as analysisActions from "../../../redux/actions/analysisActions";

import Timelineslider from "../../Lumber/TimelineSlider";

const HeatmapTimeline = React.forwardRef(
	(
		{
			level,
			heatmapOverlayShading,
			heatmapOverlayLabels,
			setPlotAnalysisResults,
			heatmapOverlayLabelButton,
			plotLabels,
			plotLabelButton
		},
		parentRef
	) => {
		HeatmapTimeline.displayName = "HeatmapTimeline";
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();
		const userAuth = useUserAuth();
		const moduleNavigation = useModuleNavigation();

		useImperativeHandle(parentRef, () => {
			return {
				setAnalysisOptions: (opts) => {
					setAnalysisOptions(opts);
				},
				getAnalysisOptions: () => {
					return analysisOptions;
				},
				setSelectedAnalysisId: (id) => {
					let matchingAnalysisOption = _.find(analysisOptions, { key: id })?.key;

					if (!matchingAnalysisOption) {
						matchingAnalysisOption = _.find(analysisOptions, (ao) => ao.key.includes(id))?.key;
					}

					setSelectedAnalysisId(matchingAnalysisOption);
				},
				getSelectedAnalysisId: () => {
					return selectedAnalysisId;
				},
				getHeatmapEnabled: () => {
					return heatmapEnabled;
				},
				setHeatmapEnabled: (status) => {
					setHeatmapEnabled(status);

					if (status !== heatmapEnabled && analysisData?.length > 0) {
						heatmapOverlayShading();

						if (heatmapOverlayLabelButton?.current) {
							heatmapOverlayLabelButton.current.setDisabled(!heatmapOverlayLabelButton.current.checkDisabled);
						}

						if (heatmapOverlayLabelButton?.current?.checkToggleState) {
							heatmapOverlayLabels();
						}
					}
				},
				getSelectedFlightId: () => {
					return selectedFlightId;
				},
				setSelectedFlightId: (id) => {
					//-- Setting up this useState so we can assign selected timeline node after new timeline nodes are built
					//-- instead of before, in case the node doesn't exist
					setTimelineRefreshNodeId(id);

					setSelectedFlightId(id);

					//-- Call this in case the timeline nodes don't need to be rebuilt
					updateTimelineSelected(id);
				},
				getSelectedAnalysisType: () => {
					return selectedAnalysisType;
				},
				getPlotAnalysisResults: () => {
					return getPlotAnalysisResults();
				}
			};
		});

		//-- Data source
		const [analysisOptions, setAnalysisOptions] = useState(null);
		const [analysisData, setAnalysisData] = useState(null);
		const [fieldId, setFieldId] = useState(null);
		const [trialId, setTrialId] = useState(null);

		const reduxFlightId = useSelector((state) => (state.orthoViewer ? state.orthoViewer.currentFlightId : null));

		//-- Lists of timeline nodes
		const [analysisNodes, setAnalysisNodes] = useState([]);

		//-- Selected values
		const [selectedFlightId, setSelectedFlightId] = useState(null);
		const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);
		const [selectedAnalysisType, setSelectedAnalysisType] = useState(null);

		//-- Control
		const [loading, setLoading] = useState(true);
		const [heatmapEnabled, setHeatmapEnabled] = useState(false);
		const [timelineRefreshNodeId, setTimelineRefreshNodeId] = useState(null);

		const AnalysisType = {
			Analysis: "Analysis",
			Assessment: "Assessment",
			CurveModel: "CurveModel"
		};

		useEffect(() => {
			if (level === "field") {
				setFieldId(moduleNavigation.fieldId);
			} else if (level === "trial") {
				setTrialId(moduleNavigation.trialId);
			}
		}, [level]);

		useEffect(() => {
			if (selectedAnalysisId) {
				getPlotAnalysisResults();
			}
		}, [selectedAnalysisId]);

		useEffect(() => {
			if (analysisOptions) {
				heatmapOverlayShading();
				if (heatmapOverlayLabelButton?.current && plotLabelButton?.current) {
					if (heatmapEnabled) {
						heatmapOverlayLabels();
						heatmapOverlayLabelButton.current.setDisabled(false);
						plotLabelButton.current.setToggleState(false);
						heatmapOverlayLabelButton.current.setToggleState(true);
					} else {
						if (!plotLabelButton.current.checkToggleState) {
							plotLabels();
							plotLabelButton.current.setToggleState(true);
						}
						heatmapOverlayLabelButton.current.setDisabled(true);
						heatmapOverlayLabelButton.current.setToggleState(false);
					}
				}
			}
		}, [heatmapEnabled]);

		async function getPlotAnalysisResults() {
			const accessToken = await getTokenSilently();
			setLoading(true);

			//-- Remove previous heatmap data from viewer
			let localHeatmapLabelState = false;
			if (heatmapEnabled && analysisData?.length > 0) {
				heatmapOverlayShading();

				if (heatmapOverlayLabelButton?.current?.checkToggleState) {
					localHeatmapLabelState = true;
					heatmapOverlayLabels();
				}
			}

			//-- Find which analysis data to retrieve
			let analysisOptionsData = ControlFunctions.getAnalysisOptions();
			let analysisId,
				analysisTypeId,
				analysisTypeName,
				assessmentId,
				curveModelAnalysisId = null;

			analysisId = _.find(analysisOptionsData.analysisOptions, {
				analysisId: selectedAnalysisId.split("/")[0]
			})?.analysisId;
			assessmentId = _.find(analysisOptionsData.groundDataOptions, { assessmentId: selectedAnalysisId })?.assessmentId;

			analysisTypeId = selectedAnalysisId.split("/")[selectedAnalysisId.split("/").length - 1];
			analysisTypeName = analysisTypeId
				? _.find([...analysisOptionsData.analysisOptions, ...analysisOptionsData.curveModelAnalysisOptions], {
						analysisTypeId: analysisTypeId
				  })?.analysisTypeName
				: null;
			let curveModelData = _.find(
				analysisOptionsData.curveModelAnalysisOptions,
				(cmao) =>
					cmao.analysisId === selectedAnalysisId.split("/")[0] &&
					cmao.curveModelAnalysisId === selectedAnalysisId.split("/")[1]
			);

			if (curveModelData) {
				analysisId = curveModelData.analysisId;
				curveModelAnalysisId = curveModelData.curveModelAnalysisId;
			}

			dispatch(
				level === "field"
					? analysisActions.getHeatmapAnalysisResultsForField(
							userAuth.currentClientId,
							fieldId,
							analysisId,
							analysisTypeName,
							assessmentId,
							accessToken
					  )
					: analysisActions.getHeatmapAnalysisResultsForTrial(
							userAuth.currentClientId,
							trialId,
							analysisId,
							analysisTypeId,
							analysisTypeName,
							assessmentId,
							curveModelAnalysisId,
							accessToken
					  )
			)
				.then((res) => {
					//-- Need to map datasetId to flightId for ground data assessments
					if (assessmentId && res.data?.length > 0) {
						_.map(res.data, (d) => (d.flightId = d.datasetId));
					}

					//-- Skip data filtering for curve models
					if (curveModelAnalysisId) {
						setPlotAnalysisResults(res.data);
					}

					//-- Turns heatmap shading back on
					if (heatmapEnabled && analysisData?.length > 0) {
						heatmapOverlayShading();

						if (localHeatmapLabelState) {
							heatmapOverlayLabels();
						}
					}

					let analysisType = curveModelAnalysisId
						? AnalysisType.CurveModel
						: assessmentId
						? AnalysisType.Assessment
						: AnalysisType.Analysis;

					setAnalysisData(res.data);
					setupTimelineNodes(res.data, analysisType);

					setSelectedAnalysisType(analysisType);

					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					toast.error(`Failed to get analysis results for this ${level}`);

					setLoading(false);
					setHeatmapEnabled(false);
					if (heatmapOverlayLabelButton?.current) {
						heatmapOverlayLabelButton.current.setDisabled(!heatmapOverlayLabelButton.current.checkDisabled);
					}
				});
		}

		function setupTimelineNodes(data = null, analysisType = null) {
			let nodes = [];
			if (
				(analysisType ?? selectedAnalysisType) === AnalysisType.Analysis ||
				(analysisType ?? selectedAnalysisType) === AnalysisType.Assessment
			) {
				nodes = _.map(_.uniqBy(data ?? analysisData, "flightId"), (ad) => {
					return {
						id: ad.flightId,
						date: moment(ad.dateCollected).local(),
						color: "#2185D0",
						clickable: true,
						firstActive: false,
						tooltip: (
							<>
								{`${analysisType ?? selectedAnalysisType} Date & Time`} <br />{" "}
								{moment(ad.dateCollected).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
							</>
						)
					};
				});
			}
			//-- Default Selection logic
			if ((timelineRefreshNodeId || reduxFlightId) && nodes.length > 0) {
				var targetIndex = _.findIndex(nodes, { id: timelineRefreshNodeId ?? reduxFlightId });
				if (targetIndex != -1) {
					nodes[targetIndex].firstActive = true;
					_.map(
						_.filter(nodes, (n) => n.id !== (timelineRefreshNodeId ?? reduxFlightId)),
						(n) => (n.firstActive = false)
					);
				}
				setTimelineRefreshNodeId(null);
			}

			//-- Catches if no nodes are set to active
			if (!_.some(nodes, (n) => n.firstActive) && nodes.length > 0) {
				let sortedNodes = _.sortBy(nodes, (n) => n.date);
				_.find(nodes, { id: sortedNodes[0].id }).firstActive = true;
			}

			let activeId = _.find(nodes, "firstActive")?.id ?? null;
			setSelectedFlightId(activeId);
			//-- manually overriding analysis type for workaround with weird curve model interactions
			setupPlotValues(data, activeId === null ? AnalysisType.CurveModel : analysisType, activeId);
			setAnalysisNodes(nodes);
		}

		//-- Sets plot analysis values for non-curve models
		function setupPlotValues(data = null, analysisType = null, flightId = null) {
			if ((analysisType ?? selectedAnalysisType) !== AnalysisType.CurveModel) {
				let filteredAnalysisData = _.filter(
					data ?? analysisData,
					(ad) => ad.flightId === (flightId ?? selectedFlightId)
				);

				setPlotAnalysisResults(filteredAnalysisData);
			}

			if (heatmapEnabled) {
				//-- Calling this twice refreshes cells
				heatmapOverlayShading();
				heatmapOverlayShading();

				if (heatmapOverlayLabelButton?.current?.checkToggleState) {
					heatmapOverlayLabels();
					heatmapOverlayLabels();
				}
			}
		}

		//-- Attempts to refresh the timeline nodes if needed and selects the node id
		function updateTimelineSelected(id) {
			if (analysisNodes?.length > 0) {
				let localAnalysisNodes = _.cloneDeep(analysisNodes);

				_.map(localAnalysisNodes, (n) => (n.firstActive = n.id === id));

				setAnalysisNodes(localAnalysisNodes);
			}
		}

		return (
			<>
				<Grid.Column width="5">
					<Grid style={{ width: "100%" }}>
						<Grid.Row verticalAlign="middle" centered style={{ paddingTop: 0 }}>
							<Grid.Column textAlign="right" width="4" style={{ padding: "0px" }}>
								<label style={{ fontSize: "16px" }} htmlFor="select-analysis">
									<strong>Heatmap Data</strong>
								</label>
							</Grid.Column>
							<Grid.Column width="10">
								<Dropdown
									id="select-analysis"
									selection
									fluid
									search
									options={analysisOptions}
									value={selectedAnalysisId}
									onChange={(event, { value }) => {
										setSelectedAnalysisId(value);
									}}
									loading={!analysisOptions}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width="10">
					{loading && analysisData !== null ? (
						<Loader active />
					) : (
						<Segment basic style={{ paddingTop: 5, paddingRight: "unset", paddingBottom: 30 }}>
							<Segment basic style={{ width: "90%", margin: "0 auto" }}>
								<Timelineslider
									dates={analysisNodes}
									updateSelected={(selected) => {
										setSelectedFlightId(selected.id);
										setupPlotValues(null, null, selected.id);
									}}
								/>
							</Segment>
						</Segment>
					)}
				</Grid.Column>
				<Grid.Column width="1" verticalAlign="top" style={{ paddingLeft: 30, marginTop: 8 }}>
					<Checkbox
						toggle
						disabled={loading}
						checked={heatmapEnabled}
						onClick={() => {
							setHeatmapEnabled(!heatmapEnabled);
						}}
						onChange={() => {
							// heatmapOverlayShading();
							// heatmapOverlayLabels();
							// plotLabels();
							// if (heatmapOverlayLabelButton?.current?.checkToggleState) {
							// 	heatmapOverlayLabels();
							// }
						}}
					/>
				</Grid.Column>
			</>
		);
	}
);

HeatmapTimeline.propTypes = {
	level: PropTypes.string.isRequired,
	heatmapOverlayShading: PropTypes.func.isRequired,
	heatmapOverlayLabels: PropTypes.func.isRequired,
	setPlotAnalysisResults: PropTypes.func.isRequired,
	heatmapOverlayLabelButton: PropTypes.object,
	plotLabels: PropTypes.func.isRequired,
	plotLabelButton: PropTypes.object
};

export default HeatmapTimeline;
