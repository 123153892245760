import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form, Segment, Grid, Button, Icon } from "semantic-ui-react";
import "./style.css";

const Assessment = ({
	id,
	groundDataAssessment,
	groundDataAssessmentsOptions,
	setGroundDataAssessmentsOptions,
	groundDataAssessmentsOptionsFiltered,
	groundDataAssessmentAverageOptions,
	groundDataSampleDefinitionPartOptions,
	groundDataUnitsOptions,
	groundDataSamplesOptions,
	groundDataMethodsOptions,
	groundDataDevicesOptions,
	updateAssessment,
	updateAssessmentObject,
	assessmentSourceOptions,
	groundDataQuestions,
	removeAssessment,
	addCalculatedAssessment,
	setAbleToSave,
	hasData
}) => {
	//-- Selected Options
	const [averageAssessment, setAverageAssessment] = useState(null);
	const [selectedGroundDataAssessment, setSelectedGroundDataAssessment] = useState(
		groundDataAssessment?.assessment?.id
	);
	const [selectedGroundDataUnit, setSelectedGroundDataUnit] = useState(groundDataAssessment?.unit?.id);
	const [selectedGroundDataSample, setSelectedGroundDataSample] = useState(groundDataAssessment?.samplesPerPlot?.id);
	const [selectedGroundDataSource, setSelectedGroundDataSource] = useState(groundDataAssessment?.source?.id);
	const [selectedGroundDataMethod, setSelectedGroundDataMethod] = useState(groundDataAssessment?.method?.id);
	const [selectedGroundDataDevice, setSelectedGroundDataDevice] = useState(groundDataAssessment?.device?.id);
	const [groundDataSampleDefinitionNumber, setGroundDataSampleDefinitionNumber] = useState(1);
	const [selectedGroundDataSamplePart, setSelectedGroundDataSamplePart] = useState(
		groundDataAssessment?.groundDataSampleDefinitionPart?.id
	);

	const [width, setWidth] = useState(groundDataAssessment?.width);
	const [length, setLength] = useState(groundDataAssessment?.length);
	const [radius, setRadius] = useState(groundDataAssessment?.radius);
	const [notes, setNotes] = useState(groundDataAssessment?.notes);

	//-- New Ground Data Options
	const [containsAverage, setContainsAverage] = useState(false);

	//-- Form Controls
	const [selectedSourceDisabled, setSelectedSourceDisabled] = useState(hasData);
	const [selectedMethodDisabled, setSelectedMethodDisabled] = useState(hasData);
	const [selectedAssessmentDisabled, setSelectedAssessmentDisabled] = useState(hasData);
	const [selectedUnitDisabled, setSelectedUnitDisabled] = useState(hasData);
	const [selectedSamplePerPlotDisabled, setSelectedSamplePerPlotDisabled] = useState(hasData);
	const [selectedSampleDefinitionNumberDisabled, setSelectedSampleDefinitionNumberDisabled] = useState(hasData);
	const [selectedSampleDefinitionTypeDisabled, setSelectedSampleDefinitionTypeDisabled] = useState(hasData);
	const [selectedWidthRequired, setSelectedWidthRequired] = useState(false);
	const [selectedLengthRequired, setSelectedLengthRequired] = useState(false);
	const [selectedRadiusRequired, setSelectedRadiusRequired] = useState(false);
	const [removeAssessmentDisabled, setRemoveAssessmentDisabled] = useState(hasData);
	const [selectedWidthDisabled, setSelectedWidthDisabled] = useState(hasData);
	const [selectedLengthDisabled, setSelectedLengthDisabled] = useState(hasData);
	const [selectedRadiusDisabled, setSelectedRadiusDisabled] = useState(hasData);
	const [selectedNotesDisabled, setSelectedNotesDisabled] = useState(hasData);
	const [selectedDeviceDisabled, setSelectedDeviceDisabled] = useState(hasData);

	//-- Filtered Form Options
	// const [filteredUnitOptions, setFilteredUnitOptions] = useState([]);

	useEffect(() => {
		if (groundDataAssessment.calculationForAssessmentId) {
			setRemoveAssessmentDisabled(true);
			setSelectedSourceDisabled(true);
			setSelectedMethodDisabled(true);
			setSelectedAssessmentDisabled(true);
			setSelectedUnitDisabled(true);
			setSelectedSamplePerPlotDisabled(true);
			setSelectedSampleDefinitionNumberDisabled(true);
			setSelectedSampleDefinitionTypeDisabled(true);
			setSelectedWidthDisabled(true);
			setSelectedLengthDisabled(true);
			setSelectedRadiusDisabled(true);
			setSelectedNotesDisabled(true);
			setSelectedDeviceDisabled(true);
		}

		if (groundDataAssessment.templateId) {
			setSelectedGroundDataAssessment(groundDataAssessment?.assessment?.id);
			setSelectedGroundDataUnit(groundDataAssessment?.unit?.id);
			setSelectedGroundDataSample(groundDataAssessment?.samplesPerPlot?.id);
			setSelectedGroundDataSource(groundDataAssessment?.source?.id);
			setSelectedGroundDataMethod(groundDataAssessment?.method?.id);
			setSelectedGroundDataDevice(groundDataAssessment?.device?.id);
			setSelectedGroundDataSamplePart(groundDataAssessment?.groundDataSampleDefinitionPart?.id);
			setWidth(groundDataAssessment?.width);
			setLength(groundDataAssessment?.length);
			setRadius(groundDataAssessment?.radius);
			setNotes(groundDataAssessment?.notes);
		}
	}, [groundDataAssessment]);

	//-- Source Dropdown
	useEffect(() => {
		let selectedDataSource = _.find(groundDataQuestions.groundDataSources, (source) => {
			return source.id === selectedGroundDataSource;
		});
		if (
			selectedGroundDataSource &&
			!groundDataAssessment.templateId &&
			!groundDataAssessment.calculationForAssessmentId &&
			!groundDataAssessment.groundDatasetAssessmentDataId
		) {
			setSelectedMethodDisabled(false);
		}
		updateAssessment(id, "source", selectedDataSource);
	}, [selectedGroundDataSource]);

	//-- Method Dropdown
	useEffect(() => {
		let selectedMethod = _.find(groundDataQuestions.groundDataMethods, (methods) => {
			return methods.id === selectedGroundDataMethod;
		});
		if (
			selectedGroundDataMethod &&
			!groundDataAssessment.templateId &&
			!groundDataAssessment.calculationForAssessmentId &&
			!groundDataAssessment.groundDatasetAssessmentDataId
		) {
			setSelectedAssessmentDisabled(false);
		}
		updateAssessment(id, "method", selectedMethod);
	}, [selectedGroundDataMethod]);

	//-- Assessment Dropdown
	useEffect(() => {
		let selectedAssessment = _.find(groundDataQuestions.groundDataAssessments, (assessment) => {
			return assessment.id === selectedGroundDataAssessment;
		});

		let updateObj = {};

		if (containsAverage) {
			let assessmentAverage = _.find(groundDataQuestions.groundDataAssessments, (gdAssessment) => {
				return gdAssessment.averageFor === selectedGroundDataAssessment;
			});
			updateObj.assessmentAverage = assessmentAverage;
			setAverageAssessment(assessmentAverage);
		}

		if (
			selectedGroundDataAssessment &&
			!groundDataAssessment.templateId &&
			!groundDataAssessment.calculationForAssessmentId &&
			!groundDataAssessment.groundDatasetAssessmentDataId &&
			!_.some(groundDataQuestions.groundDataSources, (source) => {
				return source.id === selectedGroundDataSource && source.name === "Calculated";
			})
		) {
			setSelectedUnitDisabled(false);
		}
		//check to see what the selected data source is,
		//if its calculated, check the calculated assessments and pull out/create the associated assessments
		if (
			_.some(groundDataQuestions.groundDataSources, (source) => {
				return source.id === selectedGroundDataSource && source.name === "Calculated";
			}) &&
			(!groundDataAssessment.templateId || groundDataAssessment.templateId === groundDataAssessment.id) &&
			!groundDataAssessment.groundDatasetAssessmentDataId
		) {
			//create assessments for each assessment returned
			let assessment = selectedGroundDataAssessment;
			addCalculatedAssessment(assessment, groundDataAssessment?.id);

			let calculatedAssessments = _.filter(groundDataQuestions.groundDataCalculations, (calculations) => {
				return calculations.calculationForAssessmentId === selectedGroundDataAssessment;
			});
			let sampleDefPart = _.find(groundDataQuestions.groundDataSampleDefinitionParts, (part) => {
				return part.id === calculatedAssessments[0]?.groundDataSampleDefinitionPartId;
			});
			updateObj.groundDataSampleDefinitionPart = sampleDefPart;
			setSelectedGroundDataSamplePart(sampleDefPart.id);

			//If we have a calculated assessment all of the subsequent controls are going to be disabled and will need to be populated
			let unit = _.find(groundDataQuestions.groundDataUnits, (unit) => {
				return unit.groundDataAssessmentId === selectedGroundDataAssessment;
			});
			updateObj.unit = unit;
			setSelectedGroundDataUnit(unit?.id);

			let spp = _.find(groundDataQuestions.groundDataSamples, (samples) => {
				return samples.name === "1";
			});

			updateObj.sampleDefinitionNumber = groundDataSampleDefinitionNumber;
			updateObj.samplesPerPlot = spp;
			setSelectedGroundDataSample(spp?.id);
		}

		//-- Handle disabling selected assessments so that we dont have duplicates
		if (groundDataAssessmentsOptions.length > 0) {
			if (groundDataAssessment?.assessment?.id) {
				let gdsoOriginal = _.find(groundDataAssessmentsOptions, (gdo) => {
					return gdo.key === groundDataAssessment?.assessment?.id;
				});
				if (gdsoOriginal) {
					gdsoOriginal.disabled = false;
					const gdsoTempOriginal = _.map(groundDataAssessmentsOptions);
					let index = _.findIndex(groundDataAssessmentsOptions, { key: groundDataAssessment?.assessment?.id });
					gdsoTempOriginal[index] = gdsoOriginal;
					setGroundDataAssessmentsOptions(gdsoTempOriginal);
				}
			}

			let gdsoNew = _.find(groundDataAssessmentsOptions, (gdo) => {
				return gdo.key === selectedGroundDataAssessment;
			});
			if (gdsoNew) {
				gdsoNew.disabled = true;
				const gdsoNewTemp = _.map(groundDataAssessmentsOptions);
				let index = _.findIndex(groundDataAssessmentsOptions, { key: selectedGroundDataAssessment });
				gdsoNewTemp[index] = gdsoNew;
				setGroundDataAssessmentsOptions(gdsoNewTemp);
			}
		}
		updateObj.assessment = selectedAssessment;
		updateAssessmentObject(id, updateObj);
	}, [selectedGroundDataAssessment]);

	//-- Unit Dropdown
	useEffect(() => {
		let selectedUnit = _.find(groundDataQuestions.groundDataUnits, (unit) => {
			return unit.id === selectedGroundDataUnit;
		});
		if (
			selectedGroundDataUnit &&
			!groundDataAssessment.templateId &&
			!groundDataAssessment.calculationForAssessmentId &&
			!groundDataAssessment.groundDatasetAssessmentDataId &&
			!_.some(groundDataQuestions.groundDataSources, (source) => {
				return source.id === selectedGroundDataSource && source.name === "Calculated";
			})
		) {
			setSelectedSamplePerPlotDisabled(false);
		}

		updateAssessment(id, "unit", selectedUnit);
	}, [selectedGroundDataUnit]);

	//-- Samples Per Plot
	useEffect(() => {
		let selectedSamplesPerPlot = _.find(groundDataQuestions.groundDataSamples, (samples) => {
			return samples.id === selectedGroundDataSample;
		});

		let objectToUpdate = {};

		//Create/remove an average assessment
		if (Number(selectedSamplesPerPlot?.name) > 1) {
			let assessmentAverage = _.find(groundDataQuestions.groundDataAssessments, (gdAssessment) => {
				return gdAssessment.averageFor === selectedGroundDataAssessment;
			});
			objectToUpdate.assessmentAverage = assessmentAverage;
			setAverageAssessment(assessmentAverage);
			setContainsAverage(true);
		} else if (containsAverage) {
			objectToUpdate.assessmentAverage = null;
			setAverageAssessment(null);
			setContainsAverage(false);
		}

		if (
			selectedGroundDataSample &&
			!groundDataAssessment.templateId &&
			!groundDataAssessment.calculationForAssessmentId &&
			!groundDataAssessment.groundDatasetAssessmentDataId &&
			!_.some(groundDataQuestions.groundDataSources, (source) => {
				return source.id === selectedGroundDataSource && source.name === "Calculated";
			})
		) {
			setSelectedSampleDefinitionNumberDisabled(false);
			setSelectedSampleDefinitionTypeDisabled(false);
		}
		objectToUpdate.samplesPerPlot = selectedSamplesPerPlot;
		updateAssessmentObject(id, objectToUpdate);
	}, [selectedGroundDataSample]);

	//-- sample definition number
	useEffect(() => {
		updateAssessment(id, "sampleDefinitionNumber", groundDataSampleDefinitionNumber);
	}, [groundDataSampleDefinitionNumber]);

	//-- Sample Definition Part/Type since they are combined into one drop down
	useEffect(() => {
		let selectedDataSamplePart = _.find(groundDataQuestions.groundDataSampleDefinitionParts, (part) => {
			return part.id === selectedGroundDataSamplePart;
		});
		if (selectedDataSamplePart) {
			//these are string for some reason
			setSelectedWidthRequired(selectedDataSamplePart.widthRequired);
			setSelectedLengthRequired(selectedDataSamplePart.lengthRequired);
			setSelectedRadiusRequired(selectedDataSamplePart.radiusRequired);
			setAbleToSave(true);
		}
		updateAssessment(id, "groundDataSampleDefinitionPart", selectedDataSamplePart);
	}, [selectedGroundDataSamplePart]);

	useEffect(() => {
		updateAssessment(id, "width", width);
	}, [width]);

	useEffect(() => {
		updateAssessment(id, "length", length);
	}, [length]);

	useEffect(() => {
		updateAssessment(id, "radius", radius);
	}, [radius]);

	useEffect(() => {
		updateAssessment(id, "notes", notes);
	}, [notes]);

	useEffect(() => {
		let selectedDevice = _.find(groundDataQuestions.groundDataDevices, (device) => {
			return device.id === selectedGroundDataDevice;
		});
		updateAssessment(id, "device", selectedDevice);
	}, [selectedGroundDataDevice]);

	function handleRemovingAssessment(groundDataAssessment) {
		let gdsoOriginal = _.find(groundDataAssessmentsOptions, (gdo) => {
			return gdo.key === groundDataAssessment?.assessment?.id;
		});

		//re-enable the selected assessment from the dropdowns if it is to be removed
		if (gdsoOriginal) {
			gdsoOriginal.disabled = false;
			const gdsoTempOriginal = _.map(groundDataAssessmentsOptions);
			let index = _.findIndex(groundDataAssessmentsOptions, { key: groundDataAssessment?.assessment?.id });
			gdsoTempOriginal[index] = gdsoOriginal;
			setGroundDataAssessmentsOptions(gdsoTempOriginal);
		}

		removeAssessment(groundDataAssessment);
	}

	return (
		<>
			<div style={{ display: "grid" }}>
				<Button
					disabled={removeAssessmentDisabled}
					icon
					onClick={() => handleRemovingAssessment(groundDataAssessment)}
					onKeyPress={() => {}}
					style={{ background: "white", alignSelf: "center", width: "fit-content", margin: "auto" }}
				>
					<Icon name="minus circle" />
				</Button>
				<Grid style={{ margin: 0 }}>
					<Segment>
						<Grid.Column>
							<Form.Field>
								<label htmlFor="form-select-assessment-source">Source</label>
							</Form.Field>
							<Form.Select
								disabled={selectedSourceDisabled}
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-select-assessment-source"
								search
								options={assessmentSourceOptions}
								value={selectedGroundDataSource}
								onChange={(event, { value }) => {
									setSelectedGroundDataSource(value);
								}}
							></Form.Select>
							<Form.Field>
								<label htmlFor="form-select-method">Assessment Method</label>
							</Form.Field>
							<Form.Select
								disabled={selectedMethodDisabled}
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-select-method"
								search
								options={groundDataMethodsOptions}
								value={selectedGroundDataMethod}
								onChange={(event, { value }) => {
									setSelectedGroundDataMethod(value);
								}}
							></Form.Select>
							<Form.Field>
								<label htmlFor="form-select-assessment-type">Assessment Type</label>
							</Form.Field>
							<Form.Select
								disabled={selectedAssessmentDisabled}
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-select-assessment-type"
								search
								options={groundDataAssessmentsOptionsFiltered}
								value={selectedGroundDataAssessment}
								onChange={(event, { value }) => {
									setSelectedGroundDataAssessment(value);
								}}
							></Form.Select>
							<Form.Field>
								<label htmlFor="form-select-unit">Units</label>
							</Form.Field>
							<Form.Select
								disabled={selectedUnitDisabled}
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-select-unit"
								search
								options={groundDataUnitsOptions}
								value={selectedGroundDataUnit}
								onChange={(event, { value }) => {
									setSelectedGroundDataUnit(value);
								}}
							></Form.Select>
							<Form.Field>
								<label htmlFor="form-select-sample">Samples Per Plot</label>
							</Form.Field>
							<Form.Select
								disabled={selectedSamplePerPlotDisabled}
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-select-sample"
								search
								options={groundDataSamplesOptions}
								value={selectedGroundDataSample}
								onChange={(event, { value }) => {
									setSelectedGroundDataSample(value);
								}}
							></Form.Select>
							<Grid columns={2} widths="equal">
								<Grid.Row>
									<Grid.Column>
										<Form.Field>
											<label htmlFor="form-input-sameple-definition-number">Sample Definition Number</label>
										</Form.Field>
										<Form.Input
											fluid
											disabled={selectedSampleDefinitionNumberDisabled}
											id="form-input-sameple-definition-number"
											defaultValue={groundDataSampleDefinitionNumber}
											type="Number"
											onBlur={(event) => {
												setGroundDataSampleDefinitionNumber(event.target.value);
											}}
										></Form.Input>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<label htmlFor="form-select-sample">Sample Definition Part</label>
										</Form.Field>
										<Form.Select
											fluid
											disabled={selectedSampleDefinitionTypeDisabled}
											id="form-select-sample"
											search
											options={groundDataSampleDefinitionPartOptions}
											value={selectedGroundDataSamplePart}
											onChange={(event, { value }) => {
												setSelectedGroundDataSamplePart(value);
											}}
										></Form.Select>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid columns={2} widths="equal">
								{selectedWidthRequired && (
									<Grid.Column>
										<Form.Field>
											<label htmlFor="form-input-width">Width</label>
										</Form.Field>
										<Form.Input
											fluid
											disabled={selectedWidthDisabled}
											id="form-input-width"
											defaultValue={width}
											type="Number"
											onBlur={(event) => {
												setWidth(event.target.value);
											}}
										></Form.Input>
									</Grid.Column>
								)}
								{selectedLengthRequired && (
									<Grid.Column>
										<Form.Field>
											<label htmlFor="form-input-length">Length</label>
										</Form.Field>
										<Form.Input
											fluid
											disabled={selectedLengthDisabled}
											id="form-input-length"
											defaultValue={length}
											type="Number"
											onBlur={(event) => {
												setLength(event.target.value);
											}}
										></Form.Input>
									</Grid.Column>
								)}
								{selectedRadiusRequired && (
									<Grid.Column>
										<Form.Field>
											<label htmlFor="form-input-radius">Radius</label>
										</Form.Field>
										<Form.Input
											fluid
											disabled={selectedRadiusDisabled}
											id="form-input-radius"
											defaultValue={radius}
											type="Number"
											onBlur={(event) => {
												setRadius(event.target.value);
											}}
										></Form.Input>
									</Grid.Column>
								)}
							</Grid>
							<Form.Field>
								<label htmlFor="form-select-device">Device</label>
							</Form.Field>
							<Form.Select
								id="form-select-device"
								disabled={selectedDeviceDisabled}
								search
								options={groundDataDevicesOptions}
								value={selectedGroundDataDevice}
								//required unless the assessment is calculated
								onChange={(event, { value }) => {
									setSelectedGroundDataDevice(value);
								}}
							></Form.Select>
							<Form.Field>
								<label htmlFor="form-text-area-notes">Notes</label>
							</Form.Field>
							<Form.TextArea
								id="form-text-area-notes"
								disabled={selectedNotesDisabled}
								defaultValue={notes ?? ""}
								rows="5"
								onBlur={(event) => {
									setNotes(event.target.value);
								}}
							/>
						</Grid.Column>
					</Segment>
				</Grid>
			</div>
			{containsAverage && (
				<div style={{ display: "grid" }}>
					<Button
						disabled={true}
						icon
						onClick={() => removeAssessment(groundDataAssessment)}
						onKeyPress={() => {}}
						style={{ background: "white", color: "white", width: "fit-content", margin: "auto" }}
					>
						<Icon name="minus circle" />
					</Button>
					<Grid style={{ margin: 0 }}>
						<Segment>
							<Form.Select
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-average-assessment-source"
								search
								disabled={true}
								options={assessmentSourceOptions}
								value={averageAssessment?.groundDataSourceId}
							></Form.Select>
							<Form.Select
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-average-method"
								search
								disabled={true}
								options={groundDataMethodsOptions}
								value={averageAssessment?.groundDataMethodId}
							></Form.Select>
							<Form.Select
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-average-assessment-type"
								search
								disabled={true}
								options={groundDataAssessmentAverageOptions}
								value={averageAssessment?.id}
							></Form.Select>
							<Form.Select
								style={{ marginTop: "10px", marginBottom: "10px" }}
								id="form-average-unit"
								search
								disabled={true}
								options={groundDataUnitsOptions}
								value={selectedGroundDataUnit}
							></Form.Select>
							{selectedWidthRequired && (
								<Grid.Column>
									<Form.Input
										label="width"
										fluid
										id="form-average-width"
										value={width}
										disabled={true}
										type="Number"
										onChange={(event, { value }) => {
											setWidth(value);
										}}
									></Form.Input>
								</Grid.Column>
							)}
							{selectedLengthRequired && (
								<Grid.Column>
									<Form.Input
										label="length"
										fluid
										id="form-average-length"
										value={length}
										disabled={true}
										type="Number"
										onChange={(event, { value }) => {
											setLength(value);
										}}
									></Form.Input>
								</Grid.Column>
							)}
							{selectedRadiusRequired && (
								<Grid.Column>
									<Form.Input
										label="radius"
										fluid
										id="form-average-radius"
										value={radius}
										disabled={true}
										type="Number"
										onChange={(event, { value }) => {
											setRadius(value);
										}}
									></Form.Input>
								</Grid.Column>
							)}
						</Segment>
					</Grid>
				</div>
			)}
		</>
	);
};

Assessment.propTypes = {
	id: PropTypes.string,
	groundDataAssessment: PropTypes.object,
	groundDataAssessmentsOptions: PropTypes.array,
	setGroundDataAssessmentsOptions: PropTypes.func,
	groundDataAssessmentsOptionsFiltered: PropTypes.array,
	groundDataAssessmentAverageOptions: PropTypes.array,
	groundDataSampleDefinitionPartOptions: PropTypes.array,
	groundDataUnitsOptions: PropTypes.array,
	groundDataSamplesOptions: PropTypes.array,
	groundDataMethodsOptions: PropTypes.array,
	groundDataDevicesOptions: PropTypes.array,
	updateAssessment: PropTypes.func,
	updateAssessmentObject: PropTypes.func,
	assessmentSourceOptions: PropTypes.array,
	groundDataQuestions: PropTypes.object,
	removeAssessment: PropTypes.func,
	addCalculatedAssessment: PropTypes.func,
	setAbleToSave: PropTypes.func,
	dateCollected: PropTypes.string,
	hasData: PropTypes.bool
};

export default Assessment;
