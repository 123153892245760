import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";
import FieldWizard from "../../Content/Fields/FieldWizard/index.js";
import * as ControlFunctions from "./orthoControlFunctions";
import * as fieldActions from "../../../redux/actions/fieldActions";

import { toast } from "react-toastify";
import { useAuth0 } from "../../../auth/auth0";
import { useDispatch, useSelector } from "react-redux";

const EditFieldModal = React.forwardRef(
	({ validateSelectedField, deselectField, disableAlignFieldsButton, dropdownRef }, editFieldModal) => {
		const dispatch = useDispatch();
		const { getTokenSilently } = useAuth0();

		const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

		const [disabled, setDisabled] = useState(true);
		const [modalOpen, setModalOpen] = useState(false);
		const [fieldId, setFieldId] = useState(null);

		EditFieldModal.displayName = "EditFieldModal";

		useImperativeHandle(editFieldModal, () => {
			return {
				setDisabled: (val) => {
					setDisabled(val);
				},
				checkDisabled: disabled
			};
		});

		async function handleCancel() {
			toast.info("Checking for field changes...");
			const accessToken = await getTokenSilently();
			dispatch(fieldActions.getNewFieldInfo(fieldId, clientId, accessToken)).then((res) => {
				ControlFunctions.applyEditFieldChanges(res.plots, res.field, dropdownRef);

				deselectField();
				setDisabled(true);
				disableAlignFieldsButton();
				setModalOpen(false);
			});
		}

		function handleSave(qrModify, qrDelete, finish) {
			//-- Handle modifying qrs on current flight
			if (qrModify?.length > 0 || qrDelete?.length > 0) {
				ControlFunctions.handleModifyQrFromFieldModal(fieldId, qrModify, qrDelete);
			}

			if (finish) {
				handleCancel();
			}
		}

		return (
			<>
				<Modal
					trigger={
						<Tooltip
							placement="left"
							align={{ offset: [8, 0] }}
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={
								<>
									<p>Edit selected field</p>
								</>
							}
							transitionName="rc-tooltip-zoom"
						>
							<Button
								icon
								size="big"
								disabled={disabled}
								onClick={() => {
									let validFieldId = validateSelectedField();

									if (validFieldId) {
										setFieldId(validFieldId);
										setModalOpen(true);
									}
								}}
							>
								<Icon.Group>
									<Icon size="large" name="bars" />
									<Icon corner style={{ fontSize: 14 }} name="pencil" />
								</Icon.Group>
							</Button>
						</Tooltip>
					}
					open={modalOpen}
					size="fullscreen"
				>
					<Modal.Header>Edit Field</Modal.Header>
					<Modal.Content>
						<FieldWizard
							activeStep="info"
							handleCancel={() => handleCancel()}
							handleSave={(qrModify, qrDelete, finish) => handleSave(qrModify, qrDelete, finish)}
							fromViewer={true}
							mode="edit"
							fieldIdFromViewer={fieldId}
						/>
					</Modal.Content>
				</Modal>
			</>
		);
	}
);

EditFieldModal.propTypes = {
	validateSelectedField: PropTypes.func.isRequired,
	deselectField: PropTypes.func.isRequired,
	disableAlignFieldsButton: PropTypes.func.isRequired,
	dropdownRef: PropTypes.object.isRequired
};

export default EditFieldModal;
