import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Loader, Segment } from "semantic-ui-react";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import NewEditFieldForm from "./newFieldForm";

const NewEditField = ({
	mode,
	setModeState,
	setFieldId,
	fieldId,
	fromViewer,
	handleCancel,
	handleFinish,
	navigateToNextStep
}) => {
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();

	//-- Data
	const [fieldName, setFieldName] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));

	//-- UI Control
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (
			((entityNames?.fieldId && moduleNavigation?.fieldId?.toUpperCase() === entityNames?.fieldId?.toUpperCase()) ||
				fieldId) &&
			mode === "edit"
		) {
			setFieldName(entityNames.fieldName);
			setLoading(false);
		} else if (mode === "new") {
			setLoading(false);
		}
	}, [entityNames, moduleNavigation?.fieldId, fieldId]);

	function handleSave(fieldSaveResponse, field) {
		if (fieldSaveResponse) {
			if (!fromViewer) {
				history.push(`/fields/${fieldSaveResponse.fieldId}/edit`);
			}
		} else if (field) {
			setFieldName(field.name);
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && !fieldId && mode === "edit" ? (
		<Segment basic style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Edit Field</h2>
			<hr />
			<Segment basic>
				<p>{"This field could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment basic style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>{mode === "new" ? "New Field" : "Edit Field"}</h2>
			{mode === "edit" && (
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{fieldName}</i>
				</h2>
			)}
			<hr style={{ clear: "both" }} />
			<NewEditFieldForm
				setFieldId={(id) => setFieldId(id)}
				fieldId={fieldId}
				fromViewer={fromViewer}
				handleCancel={() => handleCancel()}
				handleFinish={(id) => handleFinish(id)}
				handleSave={(res, field) => handleSave(res, field)}
				navigateToNextStep={navigateToNextStep}
				setModeState={(m) => setModeState(m)}
				mode={mode}
			/>
		</Segment>
	);
};

NewEditField.propTypes = {
	mode: PropTypes.string.isRequired,
	setModeState: PropTypes.func,
	setFieldId: PropTypes.func,
	fieldId: PropTypes.string,
	fromViewer: PropTypes.bool,
	handleCancel: PropTypes.func,
	handleFinish: PropTypes.func,
	navigateToNextStep: PropTypes.func
};

export default NewEditField;
