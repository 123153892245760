import * as types from "./actionTypes";
import * as flightImageApi from "../../apis/flightImageApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getFlightImageSuccess() {
	return { type: types.FI_GET_FLIGHT_IMAGES_SUCCESS };
}

export function getIsUploadedSuccess() {
	return { type: types.FI_GET_IS_UPLOADED_SUCCESS };
}

export function modifyUasSuccess() {
	return { type: types.FI_MODIFY_UAS_SUCCESS };
}

export function checkCutImagesSuccess() {
	return { type: types.FI_CHECK_CUT_IMAGES_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getFlightImage(clientId, flightId, orthoImageTypeId, fieldId, trialId, plotId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightImageApi
				.getFlightImage(clientId, flightId, orthoImageTypeId, fieldId, trialId, plotId, accessToken)
				.then((res) => {
					dispatch(getFlightImageSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function isUploaded(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightImageApi
				.isUploaded(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(getIsUploadedSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function modifyUas(modifyUas, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightImageApi
				.modifyUas(modifyUas, accessToken)
				.then((res) => {
					dispatch(modifyUasSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function checkCutImages(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightImageApi
				.checkCutImages(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(checkCutImagesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
