import { std } from "mathjs";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";
import _ from "lodash";

export function calculateStandardDeviationForCompiledTable(data) {
	if (data === null || data === undefined || _.isEmpty(data)) {
		return "N/A";
	}
	const values = _.map(data, "average");
	const mean = _.meanBy(values);
	const standardDeviation = (std(values) / mean) * 100;
	const stdDev = valuePercisionConverter.getPercision(standardDeviation);
	return `${Math.abs(stdDev)}%`;
}
