import isEventAtCoordinates from "@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates.js";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants.js";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import doubleClickZoom from "@mapbox/mapbox-gl-draw/src/lib/double_click_zoom.js";

const LineMeasurementMode = { ...MapboxDraw.modes.draw_line_string };

LineMeasurementMode.onSetup = function (opts) {
	opts = opts || {};
	const featureId = opts.featureId;

	let line, currentVertexPosition;
	let direction = "forward";
	if (featureId) {
		line = this.getFeature(featureId);
		if (!line) {
			throw new Error("Could not find a feature with the provided featureId");
		}
		let from = opts.from;
		if (from && from.type === "Feature" && from.geometry && from.geometry.type === "Point") {
			from = from.geometry;
		}
		if (from && from.type === "Point" && from.coordinates && from.coordinates.length === 2) {
			from = from.coordinates;
		}
		if (!from || !Array.isArray(from)) {
			throw new Error("Please use the `from` property to indicate which point to continue the line from");
		}
		const lastCoord = line.coordinates.length - 1;
		if (line.coordinates[lastCoord][0] === from[0] && line.coordinates[lastCoord][1] === from[1]) {
			currentVertexPosition = lastCoord + 1;
			// add one new coordinate to continue from
			line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
		} else if (line.coordinates[0][0] === from[0] && line.coordinates[0][1] === from[1]) {
			direction = "backwards";
			currentVertexPosition = 0;
			// add one new coordinate to continue from
			line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
		} else {
			throw new Error("`from` should match the point at either the start or the end of the provided LineString");
		}
	} else {
		line = this.newFeature({
			type: Constants.geojsonTypes.FEATURE,
			properties: {
				meta: "drawn_feature"
			},
			geometry: {
				type: Constants.geojsonTypes.LINE_STRING,
				coordinates: []
			}
		});
		currentVertexPosition = 0;
		this.addFeature(line);
	}

	this.clearSelectedFeatures();
	doubleClickZoom.disable(this);
	this.updateUIClasses({ mouse: Constants.cursors.ADD });
	this.activateUIButton(Constants.types.LINE);
	this.setActionableState({
		trash: true
	});

	return {
		line,
		currentVertexPosition,
		direction
	};
};

LineMeasurementMode.clickAnywhere = function (state, e) {
	if (
		state.currentVertexPosition > 0 &&
		isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])
	) {
		return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
	}
	this.updateUIClasses({ mouse: Constants.cursors.ADD });
	state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
	state.currentVertexPosition++;
	state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
	if (state.currentVertexPosition === 2) {
		return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
	}
};

export default LineMeasurementMode;
