import * as types from "./actionTypes";
import * as weatherDataApi from "../../apis/weatherDataApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function updateWeatherDataSuccess() {
	return { type: types.WEATHER_DATA_UPDATE_WEATHER_DATA_SUCCESS };
}

export function updateHistoricalWeatherDataSuccess() {
	return { type: types.WEATHER_DATA_HISTORICAL_UPDATE_WEATHER_DATA_SUCCESS };
}

export function createHistoricalWeatherDataSuccess() {
	return { type: types.WEATHER_DATA_CREATE_HISTORICAL_WEATHER_DATA_SUCCESS };
}

export function getGDUForTrialSuccess() {
	return { type: types.WEATHER_DATA_GET_GDU_FOR_TRIAL_SUCCESS };
}

export function getGDUForTrialHistoricalSuccess() {
	return { type: types.WEATHER_DATA_GET_GDU_FOR_TRIAL_HISTORICAL_SUCCESS };
}

export function getHistoricalWeatherDataSuccess() {
	return { type: types.WEATHER_DATA_GET_HISTORICAL_FOR_TRIAL_SUCCESS };
}

export function getTrialDailyWeatherSuccess() {
	return { type: types.WEATHER_DATA_GET_DAILY_WEATHER_FOR_TRIAL_SUCCESS };
}

export function inspectWeatherDataSuccess() {
	return { type: types.WEATHER_DATA_INSPECT_WEATHER_DATA_SUCCESS };
}

export function getGduDataForTrialsSuccess() {
	return { type: types.WEATHER_DATA_GET_GDU_DATA_FOR_TRIALS_SUCCESS };
}

export function generateWeatherDataExcelSuccess() {
	return { type: types.WEATHER_DATA_GENERATE_EXCEL_SUCCESS };
}

export function generateProtocolWeatherDataExcelSuccess() {
	return { type: types.ANALYSIS_GENERATE_PROTOCOL_WEATHER_DATA_EXCEL_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function updateWeatherData(dateRange, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.updateWeatherData(dateRange, clientId, accessToken)
				.then((res) => {
					dispatch(updateWeatherDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateHistoricalWeatherData(dateRange, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.updateHistoricalWeatherData(dateRange, clientId, accessToken)
				.then((res) => {
					dispatch(updateHistoricalWeatherDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createHistoricalWeatherData(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.createHistoricalWeatherData(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(createHistoricalWeatherDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getGDUForTrial(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.validateWeatherData(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getGDUForTrialSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCurrentGDUForTrial(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.validateCurrentWeatherData(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getGDUForTrialSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getHistoricalWeatherData(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.getHistoricalWeatherData(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getHistoricalWeatherDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getTrialDailyWeather(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.getTrialDailyWeather(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getTrialDailyWeatherSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getGDUForTrialHistorical(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.getGDUForTrialHistorical(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getGDUForTrialHistoricalSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function inspectWeatherData(weatherDataCsv, newWeatherDataVm, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return weatherDataApi
				.inspectWeatherData(weatherDataCsv, newWeatherDataVm, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						console.log(res);
						reject(res.data);
					}
					dispatch(inspectWeatherDataSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					console.log(error);
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getGduDataForTrials(trials, currentClientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.getGduDataForTrials(trials, currentClientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getGduDataForTrialsSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateWeatherDataExcel(trialId, currentDateTimeString, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return weatherDataApi
				.generateWeatherDataExcel(trialId, currentDateTimeString, clientId, accessToken)
				.then((res) => {
					dispatch(generateWeatherDataExcelSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateProtocolWeatherDataExcel(
	trials,
	clientId,
	clientName,
	currentDateTimeString,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return weatherDataApi
				.generateProtocolWeatherDataExcel(trials, clientId, clientName, currentDateTimeString, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(generateProtocolWeatherDataExcelSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
