export function getAssessmentLogOptions(trialId, fieldId, accessToken, clientId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Assessments/AssessmentLogOptions?clientId=${clientId}
		${trialId ? `&trialId=${trialId}` : ''}${fieldId ? `&fieldId=${fieldId}` : ''}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getAssessmentLog(accessToken, trialId, fieldId, clientId) {
	const baseUri = `${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Assessments/AssessmentLog?`;
	const subUri = trialId
		? `trialId=${trialId}&clientId=${clientId}`
		: `fieldId=${fieldId}&clientId=${clientId}`;
	const uri = baseUri + subUri;
	return fetch(uri, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
