import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment, Grid, Form, Loader, Button, Icon, Popup, Message } from "semantic-ui-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { toast } from "react-toastify";

import { getDistinctObjects } from "../../../../utilities/jsUtils";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as excelGeneratorActions from "../../../../redux/actions/excelGeneratorActions";

import Heatmap from "../../../Lumber/Heatmap";
import Timelineslider from "../../../Lumber/TimelineSlider";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";
import * as excelGenerationFunctions from "../../../../utilities/excelGeneration";

import "./styles.css";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const TrialHeatmap = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();

	//-- Data Sources
	const [trialHeatmapData, setTrialHeatmapData] = useState(null);
	const [flights, setFlights] = useState(null);
	const [analyses, setAnalyses] = useState(null);
	const [rawPresetOptions, setRawPresetOptions] = useState(null);
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));
	const [trialName, setTrialName] = useState(null);

	//-- Timeline nodes
	const [flightNodes, setFlightNodes] = useState(null);

	//-- Dropdown options
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [allAssessments, setAllAssessments] = useState([]);

	//-- Selected option
	const [flightId, setFlightId] = useState(null);
	const [analysisId, setAnalysisId] = useState(null);
	const [quantifiedRegionTypeId, setQuantifiedRegionTypeId] = useState(null);
	const [analysisType, setAnalysisType] = useState(null);

	const [analysisResults, setAnalysisResults] = useState(null);

	//-- Excel
	const [excelFileName, setExcelFileName] = useState("TrialHeatmap.xlsx");
	const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [dataDone, setDataDone] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [growthCurveSelected, setGrowthCurveSelected] = useState(false);

	//-- Get/Set - Trial heatmap data
	useEffect(() => {
		if (userAuth.currentClientId && !trialHeatmapData) {
			getTrialHeatmapData(moduleNavigation.trialId);
		}
	}, [userAuth.currentClientId]);

	useEffect(() => {
		if (entityNames?.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			getPlotAnalysisStatus();
		}
	}, [entityNames]);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			trialActions.getPlotAnalysisStatusForTrial(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	async function getTrialHeatmapData(trialId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(analysisActions.getAnalysisResultsForTrial(userAuth.currentClientId, trialId, accessToken))
			.then((res) => {
				const subsampleOrdering = [
					{ type: "Plot-Centered with Subsamples", order: 1 },
					{ type: "Plot-Centered", order: 2 },
					{ type: "Row-Centered", order: 3 },
					{ type: "Full Row", order: 4 },
					{ type: "Between-Row", order: 5 }
				];

				_.map(res.plotAnalysisResults, (par) => {
					par.subsampleOrder = _.find(subsampleOrdering, { type: par.quantifiedRegionTypeName })?.order;
					par.analysisOrder =
						_.find(
							res.presetOptions.timeSeriesPresets,
							(po) => po.analysisId === par.analysisId && po.analysisTypeId === par.analysisTypeId
						)?.order || Infinity;
				});

				res.plotAnalysisResults = addFabricatedPlots(res.plotAnalysisResults);

				let tempPlotAnalysisResults = _.sortBy(res.plotAnalysisResults, ["subsampleOrder", "analysisOrder"]);

				setTrialHeatmapData(tempPlotAnalysisResults);

				let doesTrialHaveStandCountData = false;
				if (
					_.some(
						tempPlotAnalysisResults,
						(tpar) => tpar.analysisName !== null && tpar.analysisName.includes("Stand Count (LAI)")
					)
				) {
					doesTrialHaveStandCountData = true;
				}
				//Remove Stand Count from the advanced analysis type options if no stand count data exists
				if (!doesTrialHaveStandCountData) {
					let standCountIndex = res.presetOptions.analysisTypes.findIndex((x) => x.name === "Stand Count");
					res.presetOptions.analysisTypes.splice(standCountIndex, 1);
					setRawPresetOptions(res.presetOptions);
				} else {
					setRawPresetOptions(res.presetOptions);
				}

				const trialName = tempPlotAnalysisResults[0].trialName;
				setExcelFileName(`${trialName}_TrialHeatmap_${moment().format("YYYYMMDD_hhmma")}.xlsx`);
			})
			.catch((err) => {
				toast.error("Error loading Trial Heatmap. Please try again.");
				setLoading(false);
				console.log(err);
			});
	}

	useEffect(() => {
		if (trialHeatmapData) {
			//-- analyses
			let anlyss = trialHeatmapData.map((fhmd) => {
				const a = {};
				a.id = fhmd.curveModelName ? fhmd.curveModelName : fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId;
				a.name = fhmd.analysisName
					? fhmd.analysisName
					: fhmd.assessmentName
					? fhmd.assessmentName
					: fhmd.curveModelName;
				a.subsample = fhmd.subsample;
				return a;
			});

			let idsWithSubsamples = _.uniq(
				_.map(
					_.filter(anlyss, (a) => a.subsample && a.subsample > 1),
					"id"
				)
			);

			anlyss = _.filter(anlyss, (a) => !idsWithSubsamples.includes(a.id));

			anlyss = _.sortBy(_.uniqWith(anlyss, _.isEqual), ["name"]);

			//-- quantified regions
			let quantifiedRegions = _.map(_.uniqBy(trialHeatmapData, "quantifiedRegionTypeId"), (qr) => {
				return { key: qr.quantifiedRegionTypeId, value: qr.quantifiedRegionTypeId, text: qr.quantifiedRegionTypeName };
			});

			quantifiedRegions = _.filter(quantifiedRegions, (qr) => {
				return qr.text !== null;
			});
			setQuantifiedRegionTypeOptions(quantifiedRegions);

			setAnalyses(anlyss);

			setLoading(false);
		}
	}, [trialHeatmapData]);

	//-- Set node and ddl options
	useEffect(() => {
		if (flights && flights.length > 0) {
			setProcessing(true);
			setFlightNodes(
				_.map(flights, ({ id, date, published, groundData }) => {
					const opt = {
						id: id,
						date: moment(date).local(),
						published: published,
						color: published ? "#77F777" : "#2185D0",
						groundData: groundData,
						clickable: true,
						firstActive: moduleNavigation.flightId === id || moduleNavigation.timelineFlightId === id,
						tooltip: (
							<>
								{`Flight Date & Time:`}{" "}
								<span style={{ opacity: 0.75 }}>{moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}</span>
								{!groundData && (
									<>
										<br />
										{`Published:`} <span style={{ opacity: 0.75 }}>{published ? "Yes" : "No"}</span>
									</>
								)}
							</>
						)
					};
					return opt;
				})
			);

			if (moduleNavigation.timelineFlightId && !flightId) {
				setFlightId(moduleNavigation.timelineFlightId);
			} else if (moduleNavigation.flightId && !flightId) {
				setFlightId(moduleNavigation.flightId);
			} else {
				setFlightId(flights[0].id);
			}

			setProcessing(false);
		}
	}, [flights]);

	useEffect(() => {
		if (analyses && analyses.length > 0) {
			setProcessing(true);

			//-- Setup the assessment options for the DDLs
			let analyticOptions = [];
			let orthos = analysisDropDownListFunctions.getOrthoTypes(trialHeatmapData);
			let groundData = analysisDropDownListFunctions.getGroundDataPresets(trialHeatmapData);
			let growthCurves = analysisDropDownListFunctions.getGrowthCurvePresets(trialHeatmapData);

			analyticOptions = orthos.concat(groundData).concat(growthCurves);
			setAllAssessments(allAssessments?.length > 0 ? allAssessments : analyticOptions);

			setProcessing(false);
		}
	}, [analyses]);

	useEffect(() => {
		//-- analysis results
		if (
			trialHeatmapData &&
			analysisId &&
			(quantifiedRegionTypeId || _.every(trialHeatmapData, (thd) => thd.assessmentId))
		) {
			setProcessing(true);
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			//-- flights
			let flts = trialHeatmapData
				.filter((thd) => {
					return thd.curveModelDataAnalysisId != null
						? analysisId == thd.curveModelName
						: (thd.analysisId ? thd.analysisId : thd.assessmentId) === (subsampleId ?? analysisId) &&
								//-- flight analysis quantified region data
								(thd.quantifiedRegionTypeId === quantifiedRegionTypeId || thd.groundDatasetId || thd.assessmentId) &&
								//-- Aalysis Type
								(thd.analysisTypeId === analysisType || thd.groundDatasetId || thd.assessmentId) &&
								//-- Ground Data subsamples
								(subsampleId === null || (subsampleId !== null && subsample == thd.subsample));
				})
				.map((fhmd) => {
					const f = {};
					f.id = fhmd.flightId
						? fhmd.flightId
						: fhmd.groundDatasetId
						? fhmd.groundDatasetId
						: fhmd.curveModelDataAnalysisId
						? fhmd.curveModelDataAnalysisId
						: fhmd.analysisId;
					f.name = fhmd.flightName;
					f.date = fhmd.flightDate
						? fhmd.flightDate
						: fhmd.groundDatasetDate
						? fhmd.groundDatasetDate
						: fhmd.curveModelAnalysisDateTime;
					f.published =
						fhmd.view === "Researcher"
							? fhmd.publishedToTrialOwner
							: fhmd.view === "Owner"
							? fhmd.publishedToTrialSponsor
							: false;
					f.publishedToTrialSponsor = fhmd.publishedToTrialSponsor;
					f.trialOwnerUserId = fhmd.trialOwnerUserId;
					f.trialOwnerFirstName = fhmd.trialOwnerFirstName;
					f.trialOwnerLastName = fhmd.trialOwnerLastName;
					f.trialSponsorUserId = fhmd.trialSponsorUserId;
					f.trialSponsorFirstName = fhmd.trialSponsorFirstName;
					f.trialSponsorLastName = fhmd.trialSponsorLastName;
					f.view = fhmd.view;
					f.groundData = fhmd.flightId === null && fhmd.CurveModelAnalysisId === null;
					f.excludeFromAssessment = fhmd.excludeFromAssessment;
					return f;
				});

			flts = getDistinctObjects(flts);

			let flightDifferences = _.difference(flts, flights);
			if (flightDifferences.length > 0) {
				setFlights(flts);
			}

			setProcessing(false);
		}
	}, [trialHeatmapData, analysisId, quantifiedRegionTypeId]);

	useEffect(() => {
		if (flightId && analysisId) {
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			const fTrialHeatmapData = _.sortBy(
				trialHeatmapData.filter((fhmd) => {
					return fhmd.curveModelDataAnalysisId != null
						? flightId == fhmd.curveModelDataAnalysisId && analysisId == fhmd.curveModelName
						: (fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId) === flightId &&
								//-- flight analysis quantified region data
								(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
								//-- Analysis Type
								(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
								(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
								(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample));
				}),
				["plotColumn", "plotRange"]
			);

			var ars = fTrialHeatmapData.map((fhmd) => {
				var r = {};
				r.id = fhmd.flightDataAnalysisResultId;
				r.x = fhmd.plotRange;
				r.y = fhmd.plotColumn;
				r.trialId = fhmd.trialId;
				r.trialName = fhmd.trialName;
				r.analysis = fhmd.analysisName ?? fhmd.curveModelName;
				r.value = fhmd.curveModelName
					? fhmd.curveModelAnalysisValue
					: fhmd.flightId
					? fhmd.plotAnalysisResultValue
					: fhmd.groundDatasetAssessmentValue;
				r.tooltipText = generatePlotTooltipText(fhmd);
				r.excludeFromAssessment = fhmd.excludeFromAssessment;
				r.maxRanges = fhmd.maxRanges;
				r.maxColumns = fhmd.maxColumns;
				r.isGrowthCurveData = fhmd.curveModelName != null;
				r.failed = fhmd.curveModelName?.includes("AUC") ? false : fhmd.failed;
				return r;
			});

			if (ars.length > 0) {
				setGrowthCurveSelected(ars[0].isGrowthCurveData);
			}

			setAnalysisResults(ars);
		} else {
			setAnalysisResults([]);
		}
	}, [flightId, analysisId, quantifiedRegionTypeId, analysisType]);

	function addFabricatedPlots(plotAnalysisResults) {
		let plotsWithFirstAnalysisId = _.uniqBy(
			_.filter(
				plotAnalysisResults,
				(par) => par.analysisOrder === _.minBy(plotAnalysisResults, "analysisOrder")?.analysisOrder && par.analysisName
			),
			"plotId"
		);

		let growthCurveAnalyses = _.uniqBy(
			_.filter(plotAnalysisResults, (d) => d.curveModelAnalysisId),
			(d) => [d.curveModelAnalysisId, d.analysisId, d.analysisTypeId, d.quantifiedRegionTypeId].join()
		);

		let plotsToFabricate = [];
		_.map(growthCurveAnalyses, (gca) => {
			let allPlotsInCurveModel = _.filter(
				plotAnalysisResults,
				(p) =>
					p.curveModelAnalysisId == gca.curveModelAnalysisId &&
					p.curveModelId === gca.curveModelId &&
					p.analysisId == gca.analysisId &&
					p.quantifiedRegionTypeId == gca.quantifiedRegionTypeId &&
					p.analysisTypeId == gca.analysisTypeId
			);

			if (allPlotsInCurveModel.length !== plotsWithFirstAnalysisId?.length) {
				_.map(plotsWithFirstAnalysisId, (up) => {
					let matchingPlotResult = _.find(
						plotAnalysisResults,
						(p) =>
							p.plotId === up.plotId &&
							p.curveModelAnalysisId == gca.curveModelAnalysisId &&
							p.curveModelId === gca.curveModelId &&
							p.analysisId == gca.analysisId &&
							p.quantifiedRegionTypeId == gca.quantifiedRegionTypeId &&
							p.analysisTypeId == gca.analysisTypeId
					);

					if (!matchingPlotResult) {
						let plotToFabricate = {
							...allPlotsInCurveModel[0],
							plotId: up.plotId,
							plotName: up.plotName,
							plotRange: up.plotRange,
							plotColumn: up.plotColumn,
							curveModelAnalysisValue: -1,
							excludeFromAssessment: true,
							excluded: up.excluded,
							flagged: up.flagged,
							descriptiveCompany: up.descriptiveCompany,
							trialName: up.trialName,
							trialTreatmentId: up.trialTreatmentId,
							plotReplicate: up.plotReplicate
						};

						plotsToFabricate.push(plotToFabricate);
					}
				});
			}
		});

		if (plotsToFabricate.length > 0) {
			plotAnalysisResults = [...plotAnalysisResults, ...plotsToFabricate];
		}

		return plotAnalysisResults;
	}

	function generatePlotTooltipText(plotData) {
		let tooltipText = {};
		tooltipText["Plot"] = plotData.plotName;

		if (plotData.trialTreatmentId) {
			tooltipText["Treatment Id"] = plotData.trialTreatmentId;
		}

		if (plotData.plotReplicate) {
			tooltipText["Replicate"] = plotData.plotReplicate;
		}

		if (plotData.plotSeed) {
			tooltipText["Seed"] = plotData.plotSeed;
		}

		return tooltipText;
	}

	async function generateTrialHeatmapExcel(removeExcluded, isForAdmin) {
		const accessToken = await getTokenSilently();
		setExcelDownloadLoading(true);
		let currentDateTimeString = JSON.stringify(new Date().toLocaleString());
		dispatch(
			excelGeneratorActions.generateTrialHeatmapExcel(
				userAuth.currentClientId,
				userAuth.currentClient.clientName,
				moduleNavigation.trialId,
				isForAdmin,
				currentDateTimeString,
				removeExcluded,
				accessToken
			)
		)
			.then((res) => {
				if (!removeExcluded) {
					excelGenerationFunctions.downloadExcelFile(res, excelFileName.split(".")[0] + "_Without_Exclusions.xlsx");
				} else {
					excelGenerationFunctions.downloadExcelFile(res, excelFileName);
				}

				setExcelDownloadLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setExcelDownloadLoading(false);
				console.log(err);
				toast.error("Error downloading excel file. Please try again.");
			});
	}

	function updateSelectedAnalysis(value) {
		setAnalysisId(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setQuantifiedRegionTypeId(value);
	}

	function updateSelectedAnalysisType(value) {
		setAnalysisType(value);
	}

	return loading ? (
		<Loader active />
	) : !loading && (!trialHeatmapData || trialHeatmapData.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Heatmap</h2>
			<hr />
			<Segment>
				<p>{"We could not find heatmap data for this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Heatmap</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
				></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			{!dataDone && (
				<Message warning>
					Plot analysis data for this trial is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			<Form>
				<Grid id="trial-heatmap-grid" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column>
							<Form.Field>
								{rawPresetOptions ? (
									<AnalysisDropDownLists
										rawPresetOptions={rawPresetOptions}
										allAssessmentTypes={allAssessments}
										quantifiedRegionTypes={quantifiedRegionTypeOptions}
										defaultQuantifiedRegion={
											_.find(trialHeatmapData, (t) => {
												return t.isDefaultQuantifiedRegion === true;
											})?.quantifiedRegionTypeId
										}
										updatedSelectedAnalysisType={updateSelectedAnalysisType}
										updateSelectedAnalysis={updateSelectedAnalysis}
										updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
										isApAdmin={userAuth.isApAdmin}
										heatmap={true}
									>
										<div style={{ width: "calc(100% - 1000px)" }} />
										{moduleNavigation.fieldId &&
											userAuth.hasApplicationArea(ApplicationArea.FieldHeatMap, userAuth.currentClientId) && (
												<Link
													to={moduleNavigation.createFieldLink(true, null, "heatmap")}
													style={{ whiteSpace: "nowrap" }}
												>
													View Field Heatmap
												</Link>
											)}
										{trialHeatmapData && (
											<a
												loading={excelDownloadLoading}
												onClick={() => generateTrialHeatmapExcel(true, false)}
												style={{ whiteSpace: "nowrap", cursor: "pointer" }}
											>
												Download Heatmap Data
											</a>
										)}
										{trialHeatmapData && userAuth.isApAdmin && (
											<a
												loading={excelDownloadLoading}
												onClick={() => generateTrialHeatmapExcel(false, false)}
												style={{ whiteSpace: "nowrap", cursor: "pointer" }}
											>
												Download Heatmap Data (without exclusions)
											</a>
										)}
										{trialHeatmapData && userAuth.isApAdmin && (
											<a
												loading={excelDownloadLoading}
												onClick={() => generateTrialHeatmapExcel(true, true)}
												style={{ whiteSpace: "nowrap", cursor: "pointer" }}
											>
												Download Heatmap Data (Admin View)
											</a>
										)}
										{trialHeatmapData && excelDownloadLoading ? <Loader active></Loader> : null}
										{trialHeatmapData && (
											<Popup
												trigger={<Icon name="info circle" />}
												flowing
												hoverable
												content={
													<Grid.Column style={{ width: "fit-content" }}>
														Click &nbsp;
														<Link to={moduleNavigation.createTrialLink(true, null, "heatmap-legacy")}>Here</Link> &nbsp;
														for legacy page with CSV file download.
													</Grid.Column>
												}
											/>
										)}
									</AnalysisDropDownLists>
								) : (
									<></>
								)}
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					{!_.isEmpty(flightNodes) && !growthCurveSelected && (
						<Grid.Row style={{ paddingBottom: 30 }}>
							<Grid.Column>
								<Segment basic style={{ width: "90%", margin: "0 auto" }}>
									<Timelineslider
										dates={flightNodes}
										updateSelected={(selected) => {
											setFlightId(selected.id);
										}}
									/>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row centered style={{ border: "1px solid" }}>
						<Grid.Column width="16">
							{!processing && _.isEmpty(analysisResults) ? (
								<Segment basic>
									<p>
										{
											"We couldn't find data for the trial or selected analysis. Please run an analysis to see the heatmap."
										}
									</p>
								</Segment>
							) : processing ? (
								<></>
							) : (
								<TransformWrapper limitToBounds={false} centerOnInit>
									{({ zoomIn, zoomOut, centerView }) => (
										<React.Fragment>
											<div className="tools" style={{ float: "left" }}>
												<Button icon onClick={() => zoomIn()}>
													<Icon name="plus" />
												</Button>
												<Button icon onClick={() => zoomOut()}>
													<Icon name="minus" />
												</Button>
												<Button icon onClick={() => centerView(1)}>
													<Icon name="refresh" />
												</Button>
											</div>
											<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
												<Heatmap data={analysisResults} />
											</TransformComponent>
										</React.Fragment>
									)}
								</TransformWrapper>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default TrialHeatmap;
