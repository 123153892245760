import * as types from "./actionTypes";
import * as flightApi from "../../apis/flightApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as orthoActions from "./orthoActions";

/*
 *  Action Creators
 *
 */

export function getFlightInfoSuccess() {
	return { type: types.FLIGHT_GET_FLIGHT_INFO_SUCCESS };
}

export function saveNewFlightSuccess() {
	return { type: types.FLIGHT_SAVE_NEW_FLIGHT_SUCCESS };
}

export function updateFlightSuccess() {
	return { type: types.FLIGHT_UPDATE_FLIGHT_SUCCESS };
}

export function getFlightSuccess() {
	return { type: types.FLIGHT_GET_FLIGHT_SUCCESS };
}

export function getFlightLogDataOptionsSuccess() {
	return { type: types.FLIGHT_GET_FLIGHT_LOG_DATA_OPTIONS_SUCCESS };
}

export function getFlightLogSuccess() {
	return { type: types.FLIGHT_GET_FLIGHT_LOG_SUCCESS };
}

export function deleteFlightSuccess() {
	return { type: types.FLIGHT_DELETE_FLIGHT_SUCCESS };
}

export function getSensorsForFlightSuccess(sensors) {
	return { type: types.FLIGHT_GET_SENSORS_FOR_FLIGHT_SUCCESS, sensors };
}

export function getOrthosForFlightSuccess() {
	return { type: types.FLIGHT_GET_ORTHOS_FOR_FLIGHT_SUCCESS };
}

export function downloadOrthoSuccess() {
	return { type: types.FLIGHT_DOWNLOAD_ORTHOS_FOR_FLIGHT_SUCCESS };
}

export function deleteOrthoSuccess() {
	return { type: types.FLIGHT_DELETE_ORTHOS_FOR_FLIGHT_SUCCESS };
}

export function deleteFlightElevationImageSuccess() {
	return { type: types.FLIGHT_DELETE_FLIGHT_ELEVATION_IMAGE_SUCCESS };
}

export function getViewerOrthoOptionsSuccess() {
	return { type: types.FLIGHT_GET_VIEWER_ORTHO_OPTIONS_SUCCESS };
}

export function getExistingPlotsSuccess() {
	return { type: types.FLIGHT_GET_EXISTING_PLOTS_SUCCESS };
}

export function getPlotAnalysisStatusForFlightSuccess() {
	return { type: types.FLIGHT_GET_PLOT_ANALYSIS_STATUS_FOR_FLIGHT_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getFlightInfo(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getFlightInfo(clientId, accessToken)
				.then((res) => {
					dispatch(getFlightInfoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveNewFlight(flight, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.saveNewFlight(flight, accessToken)
				.then((res) => {
					dispatch(saveNewFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateFlight(flight, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.updateFlight(flight, accessToken)
				.then((res) => {
					dispatch(updateFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFlight(flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getFlight(flightId, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFlightSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFlightLogDataOptions(clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getFlightLogDataOptions(clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFlightLogDataOptionsSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getFlightLog(startDateTime, endDateTime, clientId, searchAllClients, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getFlightLog(startDateTime, endDateTime, clientId, searchAllClients, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(getFlightLogSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFlight(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.deleteFlight(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getOrthosForFlight(flightId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getOrthosForFlight(flightId, accessToken)
				.then((res) => {
					dispatch(getOrthosForFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getViewerOrthoOptions(flightId, fieldId, trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getViewerOrthoOptions(flightId, fieldId, trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getViewerOrthoOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateUploadingOrtho(updatedUploadingOrtho) {
	return function (dispatch) {
		dispatch(
			orthoActions.updateUploadingOrtho({
				id: updatedUploadingOrtho.id,
				uploadPercent: updatedUploadingOrtho.uploadPercent
			})
		);
	};
}

export function deleteOrthoForFlight(orthoId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.deleteUploadedOrtho(orthoId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function downloadOrthoForFlight(orthoId, fileName, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.downloadUploadedOrtho(orthoId, fileName, clientId, accessToken)
				.then((res) => {
					dispatch(downloadOrthoSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteFlightElevationImage(flightElevationImageId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.deleteFlightElevationImage(flightElevationImageId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteFlightElevationImageSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getExistingPlots(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.getExistingPlots(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(getExistingPlotsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getPlotAnalysisStatusForFlight(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightApi
				.GetPlotAnalysisStatusForFlight(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(getPlotAnalysisStatusForFlightSuccess());
					resolve(res.data);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
