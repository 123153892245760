import * as types from "./actionTypes";
import * as eulaApi from "../../apis/eulaApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getEulaSuccess() {
	return { type: types.EULA_GET_EULA_SUCCESS };
}

export function setEulaStatusSuccess() {
	return { type: types.EULA_SET_EULA_STATUS_SUCCESS };
}

export function getEulaStatusSuccess() {
	return { type: types.EULA_GET_EULA_STATUS_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getEula(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return eulaApi
				.getEula(accessToken)
				.then((res) => {
					dispatch(getEulaSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function setEulaStatus(accessToken, accepted) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return eulaApi
				.setEulaStatus(accessToken, accepted)
				.then((res) => {
					dispatch(setEulaStatusSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getEulaStatus(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return eulaApi
				.getEulaStatus(accessToken)
				.then((res) => {
					dispatch(getEulaStatusSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
