// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.ui.table thead tr:first-child > th {\r\n    position: sticky !important;\r\n    top: -15px; /* table expands when scrolling so needed to float higher to prevent a strange overlap, set to 0 if you want to see what this fixes*/\r\n    z-index: 1;\r\n    background: white;\r\n}\r\n\r\n.ui.table thead tr:nth-child(2) > th {\r\n    position: sticky !important;\r\n    top: 31px; /* amount of space needed for top header*/\r\n    z-index: 1;\r\n    background: white;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/Content/Widgets/AreaUnderTheCurveTable/styles.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;IAC3B,UAAU,EAAE,oIAAoI;IAChJ,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,SAAS,EAAE,yCAAyC;IACpD,UAAU;IACV,iBAAiB;AACrB","sourcesContent":["\r\n.ui.table thead tr:first-child > th {\r\n    position: sticky !important;\r\n    top: -15px; /* table expands when scrolling so needed to float higher to prevent a strange overlap, set to 0 if you want to see what this fixes*/\r\n    z-index: 1;\r\n    background: white;\r\n}\r\n\r\n.ui.table thead tr:nth-child(2) > th {\r\n    position: sticky !important;\r\n    top: 31px; /* amount of space needed for top header*/\r\n    z-index: 1;\r\n    background: white;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"table": "table"
};
export default ___CSS_LOADER_EXPORT___;
