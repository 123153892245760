import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Divider, Header } from "semantic-ui-react";
import { useAuth0 } from "../../../auth/auth0";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../hooks/useUserAuth";
import * as analysisActions from "../../../redux/actions/analysisActions";
import "./styles.css";

const AdminTools = ({ style, handleClose }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	//const [modulesWithIds, setModulesWithIds] = useState([]);
	const [disableButton, setDisableButton] = useState(true);

	useEffect(() => {
		// 	//let temp = Object.entries(moduleNavigation.moduleIdsFromUrl);
		// 	setModulesWithIds();
		switch (moduleNavigation.activeModule.name) {
			case "trials":
				setDisableButton(!moduleNavigation.trialId);
				break;
			case "fields":
				setDisableButton(!moduleNavigation.fieldId);
				break;
			case "flights":
				setDisableButton(!moduleNavigation.flightId);
				break;
			case "protocols":
				setDisableButton(!moduleNavigation.protocolId);
				break;
			default:
				setDisableButton(true);
				break;
		}
	}, [moduleNavigation]);

	const purgeData = async () => {
		if (userAuth.isApAdmin) {
			const accessToken = await getTokenSilently();
			switch (moduleNavigation.activeModule.name) {
				case "flights":
					toast.info("Recalculating flight analysis data...");
					await dispatch(
						analysisActions.deleteAnalysisDataForAdmins(accessToken, { flightId: moduleNavigation.flightId })
					);
					break;
				case "fields":
					toast.info("Recalculating field analysis data...");
					await dispatch(
						analysisActions.deleteAnalysisDataForAdmins(accessToken, { fieldId: moduleNavigation.fieldId })
					);
					break;
				case "trials":
					toast.info("Recalculating trial analysis data...");
					await dispatch(
						analysisActions.deleteAnalysisDataForAdmins(accessToken, { trialId: moduleNavigation.trialId })
					);
					break;
				case "protocols":
					toast.info("Recalculating protocol analysis data...");
					await dispatch(
						analysisActions.deleteAnalysisDataForAdmins(accessToken, { protocolId: moduleNavigation.protocolId })
					);
					break;
				default:
					break;
			}
			location.reload();
		}
		handleClose();
	};

	return (
		<div className="card" style={{ ...style }}>
			<Header style={{ margin: 0 }}>Admin Tools</Header>
			<Divider />
			<Button
				negative
				content="Force Recalculate Analysis Data"
				disabled={disableButton}
				onClick={() => {
					purgeData();
				}}
			/>
		</div>
	);
};

export default AdminTools;

AdminTools.propTypes = {
	style: PropTypes.object,
	handleClose: PropTypes.func
};
