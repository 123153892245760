import * as types from "./actionTypes";
//import * as analysisApi from "../../apis/analysisApi";
import * as excelGeneratorApi from "../../apis/excelGeneratorApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function generateFieldHeatmapExcelSuccess() {
	return { type: types.ANALYSIS_GENERATE_FIELD_HEATMAP_EXCEL_SUCCESS };
}

export function generateTrialHeatmapExcelSuccess() {
	return { type: types.ANALYSIS_GENERATE_TRIAL_HEATMAP_EXCEL_SUCCESS };
}

export function generateProtocolHeatmapExcelSuccess() {
	return { type: types.ANALYSIS_GENERATE_PROTOCOL_HEATMAP_EXCEL_SUCCESS };
}

export function generateFieldHeatmapExcel(
	clientId,
	clientName,
	fieldId,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return excelGeneratorApi
				.generateFieldHeatmapExcel(clientId, clientName, fieldId, currentDateTimeString, removeExcluded, accessToken)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(generateFieldHeatmapExcelSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateTrialHeatmapExcel(
	clientId,
	clientName,
	trialId,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return excelGeneratorApi
				.generateTrialHeatmapExcel(
					clientId,
					clientName,
					trialId,
					isForAdmin,
					currentDateTimeString,
					removeExcluded,
					accessToken
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(generateTrialHeatmapExcelSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function generateProtocolHeatmapExcel(
	trials,
	clientId,
	clientName,
	isForAdmin,
	currentDateTimeString,
	removeExcluded,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());
			return excelGeneratorApi
				.generateProtocolHeatmapExcel(
					trials,
					clientId,
					clientName,
					isForAdmin,
					currentDateTimeString,
					removeExcluded,
					accessToken
				)
				.then((res) => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						dispatch(generateProtocolHeatmapExcelSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
