import * as types from "./actionTypes";
import * as quantifiedRegionsApi from "../../apis/quantifiedRegionApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function createFlightsQuantifiedRegionsSuccess() {
	return { type: types.QUANTIFIED_REGIONS_CREATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS };
}

export function updateFlightsQuantifiedRegionsSuccess() {
	return { type: types.QUANTIFIED_REGIONS_UPDATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS };
}

export function checkIfFlightsQuantifiedRegionsExistSuccess() {
	return { type: types.QUANTIFIED_REGIONS_CHECK_IF_FLIGHTS_QUANTIFIED_REGIONS_EXIST_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function createFlightsQuantifiedRegions(plotData, accessToken, fieldQuantifiedRegionIds) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return quantifiedRegionsApi
				.createFlightsQuantifiedRegions(plotData, accessToken, fieldQuantifiedRegionIds)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(createFlightsQuantifiedRegionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateFlightsQuantifiedRegions(plotData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return quantifiedRegionsApi
				.updateFlightsQuantifiedRegions(plotData, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(updateFlightsQuantifiedRegionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function checkIfFlightsQuantifiedRegionsExist(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return quantifiedRegionsApi
				.checkIfFlightsQuantifiedRegionsExist(flightId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res));
						reject(res);
					}
					dispatch(checkIfFlightsQuantifiedRegionsExistSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
