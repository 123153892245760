// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#notesColumn {\n\talign-self: unset !important;\n}\n\n.ui.form input[type=\"text\"].rw-input {\n\tborder: none;\n}\n\n.ui.form .rw-date-picker {\n\tmin-width: unset;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Trials/EditTrial/style.css"],"names":[],"mappings":"AAAA;CACC,4BAA4B;AAC7B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":["#notesColumn {\n\talign-self: unset !important;\n}\n\n.ui.form input[type=\"text\"].rw-input {\n\tborder: none;\n}\n\n.ui.form .rw-date-picker {\n\tmin-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesColumn": "notesColumn",
	"ui": "ui",
	"form": "form",
	"rw-input": "rw-input",
	"rw-date-picker": "rw-date-picker"
};
export default ___CSS_LOADER_EXPORT___;
