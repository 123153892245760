import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Header } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../../../auth/auth0";
import { toast } from "react-toastify";

import * as OrthoControlFunctions from "./orthoControlFunctions";
import * as flightImageActions from "../../../redux/actions/flightImageActions";

const OrthoConfirmModal = React.forwardRef(({ navTo, orthoImageTypes, type, unblock }, modalRef) => {
	OrthoConfirmModal.displayName = "OrthoConfirmModal";

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	async function handleConfirm() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		toast.info("Checking if images have been processed");
		dispatch(flightImageActions.isUploaded(moduleNavigation.flightId, clientId, accessToken))
			.then((res) => {
				setLoading(false);
				if (!res.rasterUpload) {
					toast.warn("Images have not finished processing. Please try again shortly", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
					setLoading(false);
					setModalOpen(false);
				} else {
					OrthoControlFunctions.handleSave(
						moduleNavigation.flightId,
						clientId,
						orthoImageTypes(),
						type,
						getTokenSilently,
						dispatch,
						() => {
							//-- Check if current path is same as path trying to route to
							if (navTo() == location.pathname) {
								window.location.reload(false);
							} else {
								history.push(navTo());
							}
						}
					);
				}
			})
			.catch((e) => {
				toast.error("Failed to verify images processed.");
				console.log(e);
				setLoading(false);
			});
	}

	return (
		<>
			<Button
				style={{ visibility: "hidden", position: "absolute" }}
				onClick={() => setModalOpen(true)}
				ref={modalRef}
				loading={loading}
			/>
			<Modal open={modalOpen}>
				<Header>Unsaved Field Changes</Header>
				<Modal.Content>
					<Modal.Description>
						<p>
							There are unlocked fields in this flight. If you continue, these fields will not be saved and you will
							lose your changes. Are you sure you want to continue?
						</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						type="button"
						floated="right"
						onClick={() => {
							unblock();

							//-- Check if current path is same as path trying to route to
							if (navTo() == location.pathname) {
								window.location.reload(false);
							} else {
								history.push(navTo());
							}
						}}
						negative
						loading={loading}
						disabled={loading}
					>
						Discard All Changes
					</Button>
					<Button
						type="button"
						floated="right"
						onClick={() => handleConfirm()}
						positive
						loading={loading}
						disabled={loading}
					>
						Save & Continue
					</Button>
					<Button type="button" floated="right" onClick={() => setModalOpen(false)} disabled={loading}>
						Cancel
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
});

OrthoConfirmModal.propTypes = {
	navTo: PropTypes.func.isRequired,
	orthoImageTypes: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	unblock: PropTypes.func.isRequired
};

export default OrthoConfirmModal;
