import { useEffect } from "react";
import PropTypes from "prop-types";

import { useAuth0 } from "../auth0";

import { useModuleNavigation } from "../../hooks/useModuleNavigation";

const AuthCallback = () => {
	const { isAuthenticated } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	useEffect(() => {
		//-- If the user isn't authenticated, send them to the login page. Do not auto log in
		if (!isAuthenticated) {
			moduleNavigation.goToLogin();
		}

		//-- Take the user to the location stored in localstorage
		if (isAuthenticated) {
			moduleNavigation.navigateToSavedPath();
		}
	}, []);

	return null;
};

AuthCallback.propTypes = {
	authenticationState: PropTypes.string
};

export default AuthCallback;
