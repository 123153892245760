const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getSensors(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Sensors?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
export function getSensorDetailsData(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Sensors/GetSensorDetails?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createSensor(accessToken, sensor) {
	const sensorFormData = new FormData();

	for (const name in sensor) {
		let val = sensor[name] === "" ? null : sensor[name];

		if (val) {
			if (typeof val === "string") val = val?.trim();
			sensorFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Sensors`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: sensorFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateSensor(accessToken, sensor) {
	const sensorFormData = new FormData();

	for (const name in sensor) {
		let val = sensor[name] === "" ? null : sensor[name];

		if (val) {
			if (typeof val === "string") val = val?.trim();
			sensorFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Sensors`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: sensorFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteSensor(accessToken, sensorId) {
	return fetch(`${aerialPlotApi}api/v1/Sensors?sensorId=${sensorId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
