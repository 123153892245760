import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Segment, Loader, Menu, Button, Icon, Modal, Message } from "semantic-ui-react";

import { toast } from "react-toastify";
import _ from "lodash";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as curveModelsActions from "../../../../redux/actions/curveModelsActions";
import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";

import CurveData from "./CurveData";
import FlightSelection from "./FlightSelection";
import BulkAssessments from "./BulkAssessments";
import "./styles.css";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const TrialCurveModels = () => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Data Sources
	const [curveModels, setCurveModels] = useState([]);
	const [curveFitFunctions, setCurveFitFunctions] = useState([]);
	const [analysisTypes, setAnalysisTypes] = useState([]);
	const [curveModelBatchAssessments, setCurveModelBatchAssessments] = useState([]);
	const [curveModelData, setCurveModelData] = useState(null);
	const [timecourseData, setTimecourseData] = useState(null);
	const [trialData, setTrialData] = useState(null);
	const [selectedFlights, setSelectedFlights] = useState(null);
	const [availableAnalyses, setAvailableAnalyses] = useState(null);
	const [flightSelectionAnalysisId, setFlightSelectionAnalysisId] = useState(null);
	const [flightSelectionCurveFitFunctionId, setFlightSelectionCurveFitFunctionId] = useState(null);
	const [flightSelectionQuantifiedRegionTypeId, setFlightSelectionQuantifiedRegionTypeId] = useState(null);
	const [flightSelectionAnalysisTypeId, setFlightSelectionAnalysisTypeId] = useState(null);
	const [allMeanComparisonOptions, setAllMeanComparisonOptions] = useState(null);
	const [gduByDay, setGDUByDay] = useState(null);

	//-- Dropdown options
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [selectedQuantifiedRegionTypeOption, setSelectedQuantifiedRegionTypeOption] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [loadingCurveModelData, setLoadingCurveModelData] = useState(true);
	const [loadingTimecourseData, setLoadingTimecourseData] = useState(true);
	const [loadingModalData, setLoadingModalData] = useState(false);
	const [activeItem, setActiveItem] = useState("");
	const [curveFitModalOpen, setCurveFitModalOpen] = useState(false);
	const [showFlightsWarning, setShowFlightsWarning] = useState(false);
	const [dataDone, setDataDone] = useState(true);
	const bulkActiveItem = "Bulk";

	//Weather Data States
	const [useGDU, setUseGDU] = useState(false);
	const weatherDataEnabled = true;

	useEffect(() => {
		if (userAuth.isReady) {
			getCurveModels();
			getCurveModelAnalysisStatus();
		}
	}, [userAuth.isReady]);

	useEffect(() => {
		if (activeItem && activeItem !== bulkActiveItem) {
			getCurveModelData();
			if (!timecourseData) {
				getTimecourseData();
			}
			//Weather Data Component
			if (weatherDataEnabled && !gduByDay) {
				getGDUByDay(moduleNavigation.trialId);
			}
		}
	}, [activeItem]);

	useEffect(() => {
		if (curveModelData?.analyses && curveModelData?.flightSelections) {
			setAnalysesToSelect(curveModelData.analyses, curveModelData.flightSelections);
		}
	}, [curveModelData]);

	useEffect(() => {
		if (timecourseData) {
			const quantifiedRegionTypes = _.map(_.uniqBy(timecourseData, "quantifiedRegionTypeId"), (q) => {
				return { key: q.quantifiedRegionTypeId, value: q.quantifiedRegionTypeId, text: q.quantifiedRegionTypeName };
			});
			setQuantifiedRegionTypeOptions(quantifiedRegionTypes);
			setSelectedQuantifiedRegionTypeOption(selectedQuantifiedRegionTypeOption ?? quantifiedRegionTypes[0].key);
		}
	}, [timecourseData]);

	useEffect(() => {
		if (weatherDataEnabled && useGDU && gduByDay?.length === 0) {
			getGDUByDay(moduleNavigation.trialId);
		}
	}, [useGDU]);

	async function getCurveModelAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			trialActions.getPlotAnalysisStatusForTrial(
				moduleNavigation.trialId,
				userAuth.currentClientId,
				accessToken,
				undefined,
				undefined,
				false,
				true
			)
		);
		setDataDone(res);
	}

	async function getCurveModels() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(curveModelsActions.getCurveModels(clientId, accessToken))
			.then((res) => {
				setCurveModels(res.data.curveModels);
				setActiveItem(userAuth.isApAdmin || userAuth.isApSupport ? bulkActiveItem : res.data.curveModels[0]?.id);
				setCurveFitFunctions(res.data.curveModelFitFunctions);
				setAnalysisTypes(res.data.analysisTypes);
				setCurveModelBatchAssessments(res.data.curveModelBatchAssessments);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err);
				setLoading(false);
			});
	}

	async function getCurveModelData() {
		const accessToken = await getTokenSilently();
		setLoadingCurveModelData(true);
		dispatch(
			curveModelsActions.getCurveModelDataResultsForTrial(moduleNavigation.trialId, activeItem, clientId, accessToken)
		)
			.then((res) => {
				setCurveModelData(res.data);
				//This might be outdated
				if (weatherDataEnabled && _.some(res.data.plotDataResults, (pdr) => pdr.isGDU && pdr.value !== 0)) {
					setUseGDU(true);
					setLoadingCurveModelData(false);
				} else {
					setLoadingCurveModelData(false);
				}
				setLoadingCurveModelData(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingCurveModelData(false);
			});
	}

	async function runCurveModels() {
		const accessToken = await getTokenSilently();
		setLoadingCurveModelData(true);
		dispatch(curveModelsActions.runCurveModels(moduleNavigation.trialId, activeItem, clientId, accessToken))
			.then((res) => {
				setCurveModelData(res.data);
				//This might be outdated
				if (weatherDataEnabled && _.some(res.data.plotDataResults, (pdr) => pdr.isGDU && pdr.value !== 0)) {
					setUseGDU(true);
					setLoadingCurveModelData(false);
				} else {
					setLoadingCurveModelData(false);
				}
				setLoadingCurveModelData(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingCurveModelData(false);
			});
	}

	async function getTimecourseData() {
		setLoadingTimecourseData(true);
		const accessToken = await getTokenSilently();
		dispatch(
			// analysisActions.getFlightAnalysisResultsForTrial(userAuth.currentClientId, moduleNavigation.trialId, accessToken)
			analysisActions.getAnalysisResultsForTrial(
				userAuth.currentClientId,
				moduleNavigation.trialId,
				accessToken,
				"",
				"",
				"",
				"",
				true
			)
		)
			.then((res) => {
				dispatch(trialActions.getTrialData(userAuth.currentClientId, moduleNavigation.trialId, accessToken))
					.then((trialData) => {
						dispatch(statisticsActions.getMeanComparisonsOptions(userAuth.currentClientId, accessToken)).then(
							(options) => {
								setTimecourseData(res.plotAnalysisResults);
								setTrialData(trialData);
								setAllMeanComparisonOptions(options);
								setLoadingTimecourseData(false);
							}
						);
					})
					.catch((err) => {
						setLoadingTimecourseData(false);
						console.log(err);
						toast.error(err);
					});
			})
			.catch((err) => {
				setLoadingTimecourseData(false);
				console.log(err);
				toast.error(err);
			});
	}

	async function getGDUByDay(trialId) {
		const accessToken = await getTokenSilently();

		dispatch(weatherDataActions.getGDUForTrial(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setGDUByDay(res.data);
				setLoadingCurveModelData(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function handleCurveFitModalOpen() {
		setCurveFitModalOpen(!curveFitModalOpen);
	}

	function getSelectedFlights(flights) {
		setSelectedFlights(flights);
		checkFlightWarnings(flights);
	}

	function checkFlightWarnings(flights) {
		if (_.filter(flights, (s) => s.checked)?.length < 5) {
			setShowFlightsWarning(true);
		} else {
			setShowFlightsWarning(false);
		}
	}

	function getFlightSelectionAnalysisId(value) {
		setFlightSelectionAnalysisId(value);
	}

	function getFlightSelectionCurveFitFunctionId(value) {
		setFlightSelectionCurveFitFunctionId(value);
	}

	function getFlightSelectionQuantifiedRegionTypeId(value) {
		setFlightSelectionQuantifiedRegionTypeId(value);
	}

	function getFlightSelectionAnalysisTypeId(value) {
		setFlightSelectionAnalysisTypeId(value);
	}

	async function save() {
		setLoadingModalData(true);
		const accessToken = await getTokenSilently();
		let flightsToSave = _.filter(selectedFlights, "checked");
		let activeItemName = _.find(curveModels, { id: activeItem })?.name;
		dispatch(
			curveModelsActions.saveCurveAnalysis(
				moduleNavigation.trialId,
				flightSelectionAnalysisId,
				flightSelectionAnalysisTypeId,
				_.find(analysisTypes, { id: flightSelectionAnalysisTypeId })?.name,
				activeItem,
				userAuth.currentClientId,
				null,
				flightSelectionCurveFitFunctionId,
				flightsToSave,
				activeItemName,
				useGDU,
				flightSelectionQuantifiedRegionTypeId,
				accessToken
			)
		)
			.then(() => {
				runCurveModels();
				handleCurveFitModalOpen();
				toast.success("Curve model selections saved successfully.");
				setLoadingModalData(false);
			})
			.catch((err) => {
				console.log(err);
				if (err?.data?.Message) {
					toast.error(err.data.Message);
				} else {
					toast.error("There was a problem saving this curve model.");
				}
				setLoadingModalData(false);
			});
	}

	function setAnalysesToSelect(analyses, flightSelections) {
		const analysisTypeIds = _.map(analysisTypes, "id");

		const aOpts = _.filter(analyses, (a) => {
			const hasFlights = _.some(flightSelections, (fs) => {
				return fs.analysisId === a.analysisId;
			});

			let filteredFlightSelections = _.filter(flightSelections, (fs) => fs.analysisId === a.analysisId);
			const analysisOnEveryType = _.every(analysisTypeIds, (ati) =>
				_.map(filteredFlightSelections, "analysisTypeId").includes(ati)
			);

			return !hasFlights || !analysisOnEveryType;
		});

		setAvailableAnalyses(aOpts);
	}

	function filterAvailableAnalysesByTimecourseData() {
		const aOpts = _.filter(availableAnalyses, (a) => {
			const hasTrialData = _.some(timecourseData, (td) => {
				return td.analysisId === a.analysisId;
			});

			return hasTrialData;
		});

		return aOpts;
	}

	return loading ? (
		<Loader active />
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<Menu pointing>
				{(userAuth.isApAdmin || userAuth.isApSupport) && (
					<Menu.Item
						key={bulkActiveItem}
						name="aerialLYTX"
						active={activeItem === bulkActiveItem}
						onClick={() => setActiveItem(bulkActiveItem)}
					/>
				)}
				{_.map(curveModels, (cm) => {
					return (
						<Menu.Item
							key={cm.id}
							name={cm.name}
							active={activeItem === cm.id}
							onClick={() => {
								setActiveItem(cm.id);
							}}
						></Menu.Item>
					);
				})}
			</Menu>

			{!dataDone && (
				<Message warning>
					Curve Model analysis data for this trial is currently being recalculated, so data may not be complete or up to
					date. Please refresh the page in a few minutes.
				</Message>
			)}

			{activeItem === bulkActiveItem ? (
				<BulkAssessments />
			) : (
				<>
					{loadingCurveModelData ? (
						<Segment basic>
							<Loader active />
						</Segment>
					) : curveModelData?.curveModelAnalyses && curveModelData?.flightSelections?.length > 0 ? (
						_.map(
							_.uniqBy(
								_.find(curveModels, { id: activeItem })?.name == "Full Season"
									? _.filter(curveModelData.plotDataResults, (d) =>
											_.map(curveModelData?.flightSelections, "curveModelDataAnalysisId").includes(
												d.curveModelDataAnalysisId
											)
									  )
									: curveModelData?.flightSelections,
								(fs) =>
									[fs.analysisId, fs.analysisTypeId, fs.quantifiedRegionTypeId, fs.curveModelDataAnalysisId].join()
							),
							(p) => {
								const plotData = _.filter(curveModelData.plotDataResults, (pdr) => {
									return (
										pdr.analysisId === p.analysisId &&
										pdr.analysisTypeId === p.analysisTypeId &&
										pdr.quantifiedRegionTypeId === p.quantifiedRegionTypeId
									);
								});

								return (
									<CurveData
										key={`${p.analysisId}-${p.analysisTypeId}-${p.quantifiedRegionTypeId}`}
										curveModelAnalyses={curveModelData.curveModelAnalyses}
										plotDataResults={plotData}
										flightSelections={_.filter(curveModelData.flightSelections, (fs) => {
											return fs.curveModelDataAnalysisId === p.curveModelDataAnalysisId;
										})}
										timecourseData={_.filter(timecourseData, (td) => {
											return (
												td.analysisId === p.analysisId &&
												(_.find(analysisTypes, { id: p.analysisTypeId })?.name === "Uniformity"
													? td.isPlotUniformityValue
													: !td.isPlotUniformityValue) &&
												td.quantifiedRegionTypeId === p.quantifiedRegionTypeId &&
												_.some(curveModelData.flightSelections, (fs) => {
													return (
														fs.flightId === td.flightId && p.curveModelDataAnalysisId === fs.curveModelDataAnalysisId
													);
												})
											);
										})}
										trialData={trialData}
										viewPlantFlight={_.some(curveModelData.flightSelections, (fs) => {
											return fs.plantFlight === true && p.curveModelDataAnalysisId === fs.curveModelDataAnalysisId;
										})}
										viewHarvestFlight={_.some(curveModelData.flightSelections, (fs) => {
											return fs.harvestFlight === true && p.curveModelDataAnalysisId === fs.curveModelDataAnalysisId;
										})}
										currentAnalysis={_.find(curveModelData?.analyses, (a) => {
											return a.analysisId === p.analysisId;
										})}
										currentAnalysisType={_.find(analysisTypes, { id: p.analysisTypeId })?.name}
										allTimecourseData={timecourseData}
										flightsInCurveModel={_.filter(curveModelData.flightSelections, (fs) => {
											return (
												fs.analysisId === p.analysisId &&
												fs.analysisTypeId === p.analysisTypeId &&
												fs.quantifiedRegionTypeId === p.quantifiedRegionTypeId
											);
										})}
										curveFitFunctions={curveFitFunctions}
										curveModels={curveModels}
										activeItem={activeItem}
										getCurveModelData={() => getCurveModelData()}
										allMeanComparisonOptions={allMeanComparisonOptions}
										gduByDays={gduByDay}
										useGDUCurveData={useGDU}
										quantifiedRegionTypeOptions={quantifiedRegionTypeOptions}
										curveModelBatchAssessments={_.filter(
											curveModelBatchAssessments,
											(cmba) => cmba.curveModelId === activeItem
										)}
										analysisTypes={analysisTypes}
										currentCurveModelFitFunctionId={
											_.find(curveModelData.flightSelections, (fs) => {
												return p.curveModelDataAnalysisId === fs.curveModelDataAnalysisId;
											})?.curveModelFitFunctionId
										}
									/>
								);
							}
						)
					) : null}

					{availableAnalyses?.length > 0 &&
					(userAuth.hasApplicationArea(ApplicationArea.TrialCurveModelsCRUD, userAuth.currentClientId) ||
						userAuth.hasApplicationArea(ApplicationArea.TrialCurveModels, userAuth.currentClientId)) ? (
						<>
							<Modal
								open={curveFitModalOpen}
								trigger={
									<Button
										positive
										icon
										labelPosition="left"
										onClick={handleCurveFitModalOpen}
										loading={loadingTimecourseData}
										disabled={loadingTimecourseData}
									>
										<Icon name="plus circle" />
										Add New Curve
									</Button>
								}
								size="fullscreen"
								centered
							>
								<Modal.Header>Choose Flights for Curve Model Analysis</Modal.Header>
								<Modal.Content>
									{showFlightsWarning && selectedFlights && (
										<Message warning>
											<Message.Header>
												Selecting less than 5 flights can result in curve fit data being inaccurate or not running at
												all.
											</Message.Header>
										</Message>
									)}
									<FlightSelection
										timecourseData={timecourseData}
										trialData={trialData}
										analyses={filterAvailableAnalysesByTimecourseData()}
										getSelectedFlights={getSelectedFlights}
										curveFitFunctions={curveFitFunctions}
										analysisTypes={analysisTypes}
										curveModelBatchAssessments={_.filter(
											curveModelBatchAssessments,
											(cmba) => cmba.curveModelId === activeItem
										)}
										getFlightSelectionAnalysisId={getFlightSelectionAnalysisId}
										getFlightSelectionCurveFitFunctionId={getFlightSelectionCurveFitFunctionId}
										allMeanComparisonOptions={allMeanComparisonOptions}
										gduByDay={gduByDay}
										quantifiedRegionTypeOptions={quantifiedRegionTypeOptions}
										quantifiedRegionTypeValue={selectedQuantifiedRegionTypeOption}
										getFlightSelectionQuantifiedRegionTypeId={getFlightSelectionQuantifiedRegionTypeId}
										getFlightSelectionAnalysisTypeId={getFlightSelectionAnalysisTypeId}
									/>
								</Modal.Content>
								<Modal.Actions>
									<Button
										type="button"
										floated="right"
										primary
										onClick={save}
										disabled={loadingModalData}
										loading={loadingModalData}
									>
										Save & Run Curve Fit
									</Button>
									<Button
										type="button"
										floated="right"
										onClick={handleCurveFitModalOpen}
										disabled={loadingModalData}
										loading={loadingModalData}
									>
										Cancel
									</Button>
									<br style={{ clear: "both" }} />
								</Modal.Actions>
							</Modal>
						</>
					) : null}
				</>
			)}
		</Segment>
	);
};

export default TrialCurveModels;
