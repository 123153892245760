import React from "react";

import { Segment } from "semantic-ui-react";

const PageNotFound = () => (
  <Segment basic style={{ marginTop: 10, marginRight: 50 }}>
    <Segment>
      <h1>Huh, we couldn&apos;t find that page.</h1>
    </Segment>
  </Segment>
);

export default PageNotFound;
