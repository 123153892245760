import * as types from "./actionTypes";
import * as curveModelsApi from "../../apis/curveModelsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getCurveModelsSuccess() {
	return { type: types.CURVE_MODELS_GET_CURVE_MODELS_SUCCESS };
}

export function getCurveModelDataSuccess() {
	return { type: types.CURVE_MODELS_GET_CURVE_MODEL_DATA_SUCCESS };
}

export function runCurveModelsSuccess() {
	return { type: types.CURVE_MODELS_RUN_CURVE_MODELS_SUCCESS };
}

export function saveCurveAnalysisSuccess() {
	return { type: types.CURVE_MODELS_SAVE_AND_RUN_CURVE_ANALYSIS_SUCCESS };
}

export function deleteCurveAnalysisSuccess() {
	return { type: types.CURVE_MODELS_DELETE_CURVE_ANALYSIS_SUCCESS };
}

export function getCurveModelBatchAssessmentsSuccess() {
	return { type: types.CURVE_MODELS_GET_CURVE_MODEL_BATCH_ASSESSMENTS_SUCCESS };
}

export function runBatchAssessmentsSuccess() {
	return { type: types.CURVE_MODELS_RUN_BATCH_ASSESSMENTS_SUCCESS };
}

export function runBatchCurveModelsSuccess() {
	return { type: types.CURVE_MODELS_RUN_BATCH_CURVE_MODELS_SUCCESS };
}

export function getCurveModelResultsForTrialSuccess() {
	return { type: types.CURVE_MODELS_GET_CURVE_MODLE_RESULTS_FOR_TRIAL_SUCCESS };
}

/*
 *  Thunks
 *
 */
export function getCurveModels(clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.getCurveModels(clientId, accessToken)
				.then((res) => {
					dispatch(getCurveModelsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCurveModelData(trialId, curveModelId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.getCurveModelData(trialId, curveModelId, clientId, accessToken)
				.then((res) => {
					dispatch(getCurveModelDataSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function runCurveModels(trialId, curveModelId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.runCurveModels(trialId, curveModelId, clientId, accessToken)
				.then((res) => {
					dispatch(runCurveModelsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveCurveAnalysis(
	trialId,
	analysisId,
	analysisTypeId,
	analysisTypeName,
	curveModelId,
	clientId,
	curveModelDataAnalysisId,
	curveModelFitFunctionId,
	selectedFlights,
	curveModelName,
	useGDU,
	quantifiedRegionTypeId,
	accessToken
) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.saveCurveAnalysis(
					trialId,
					analysisId,
					analysisTypeId,
					analysisTypeName,
					curveModelId,
					clientId,
					curveModelDataAnalysisId,
					curveModelFitFunctionId,
					selectedFlights,
					curveModelName,
					useGDU,
					quantifiedRegionTypeId,
					accessToken
				)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res));
						reject(res);
					} else {
						dispatch(saveCurveAnalysisSuccess());
						resolve(res);
					}
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteCurveAnalysis(curveModelDataAnalysisId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.deleteCurveAnalysis(curveModelDataAnalysisId, clientId, accessToken)
				.then((res) => {
					dispatch(deleteCurveAnalysisSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCurveModelBatchAssessments(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.getCurveModelBatchAssessments(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getCurveModelBatchAssessmentsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function runBatchAssessments(requestData, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.runBatchAssessments(requestData, accessToken)
				.then((res) => {
					dispatch(runBatchAssessmentsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function runBatchCurveModels(trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.runBatchCurveModels(trialId, clientId, accessToken)
				.then((res) => {
					dispatch(runBatchCurveModelsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getCurveModelDataResultsForTrial(trialId, curveModelId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return curveModelsApi
				.getCurveModelDataResultsForTrial(trialId, curveModelId, clientId, accessToken)
				.then((res) => {
					dispatch(getCurveModelBatchAssessmentsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
