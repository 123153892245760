// Given the required information about planned timing, determine the string representation.
function formatPlannedTiming(
	assessmentName,
	timingMethodType,
	timingMethodName,
	growthPhase,
	growthPhaseInteger,
	growthStageStart,
	growthStageEnd
) {
	switch (timingMethodType) {
		case "Date":
			return assessmentName + " - " + timingMethodName;
		case "StartEnd":
			return assessmentName + " - " + (growthStageStart ? growthStageStart : "TBD") + " - " + (growthStageEnd ? growthStageEnd : "TBD");
		case "int":
			return assessmentName + " - " + growthPhaseInteger + " " + timingMethodName;
		case "None":
			return assessmentName + " - " + growthPhase;
		case "Start":
			return assessmentName + " - " + (growthStageStart ? growthStageStart : "TBD");
		default:
			return assessmentName + " - " + growthPhase;
	}
}

export default formatPlannedTiming;

/*
	let testCases = [
		{
			timingMethodType: "Date",
			timingMethodName: "R6",
			growthPhase: "R6",
			growthPhaseInteger: null,
			growthStageStart: new Date("2020-08-01 10:28:00.0000000"),
			growthStageEnd: null
		},
		{
			timingMethodType: "StartEnd",
			timingMethodName: "R6",
			growthPhase: "R6",
			growthPhaseInteger: null,
			growthStageStart: new Date("2020-08-01 10:28:00.0000000"),
			growthStageEnd: new Date("2020-08-03 10:28:00.0000000")
		},
		{
			timingMethodType: "int",
			timingMethodName: "DAE",
			growthPhase: "R6",
			growthPhaseInteger: "14",
			growthStageStart: "2020-08-01 10:28:00.0000000",
			growthStageEnd: "2020-08-01 10:28:00.0000000"
		},
		{
			timingMethodType: "None",
			timingMethodName: "Growth Phase",
			growthPhase: "R8",
			growthPhaseInteger: null,
			growthStageStart: "2020-08-01 10:28:00.0000000",
			growthStageEnd: "2020-08-01 10:28:00.0000000"
		},
		{
			timingMethodType: "Start",
			timingMethodName: "Exact Growth Stage",
			growthPhase: "R8",
			growthPhaseInteger: null,
			growthStageStart: new Date("2020-08-01 10:28:00.0000000"),
			growthStageEnd: "2020-08-01 10:28:00.0000000"
		}
	];
	
	for(let testCase of testCases){
		let formattedPlannedTiming = FormatPlannedTiming(
			testCase.timingMethodType,
			testCase.timingMethodName,
			testCase.growthPhase,
			testCase.growthPhaseInteger,
			testCase.growthStageEnd,
			testCase.growthStageEnd);
	
		console.log(formattedPlannedTiming);
	}
*/
