import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Segment, Step, Icon } from "semantic-ui-react";
import NewEditField from "../NewEditField";
import FieldLayout from "../FieldLayout";
import FieldGpsSetup from "../FieldGpsSetup";
import FieldAdvancedAnalytics from "../FieldAdvancedAnalytics";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

const FieldWizard = ({
	activeStep,
	mode,
	fromViewer,
	handleCancel,
	handleFinish,
	handleSave,
	fieldIdFromViewer = null
}) => {
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const [step, setActiveStep] = useState(activeStep);
	const [modeState, setModeState] = useState(mode);
	const [fieldId, setFieldId] = useState(moduleNavigation.fieldId ?? fieldIdFromViewer);

	function navigateToInfo() {
		setActiveStep("info");
		if (!fromViewer) {
			history.push(moduleNavigation.createFieldLink(true, null, "edit"));
		}
	}

	function navigateToLayout(fieldId) {
		setActiveStep("layout");
		if (!fromViewer) {
			const fieldLink = fieldId
				? moduleNavigation.createFieldLink(null, fieldId, "layout")
				: moduleNavigation.createFieldLink(true, null, "layout");
			history.push(fieldLink);
		}
	}

	function navigateToGpsSetup() {
		setActiveStep("gps-setup");
		if (!fromViewer) {
			history.push(moduleNavigation.createFieldLink(true, null, "gps-setup"));
		}
	}

	function navigateToAdvancedAnalytics() {
		setActiveStep("advanced-analytics");
		if (!fromViewer) {
			history.push(moduleNavigation.createFieldLink(true, null, "advanced-analytics"));
		}
	}

	return (
		<Segment basic>
			<Step.Group widths={4}>
				<Step link active={step === "info"} onClick={() => navigateToInfo()}>
					<Icon name="clipboard list" />
					<Step.Content>
						<Step.Title>Field Info</Step.Title>
						<Step.Description>Field metadata</Step.Description>
					</Step.Content>
				</Step>
				<Step link active={step === "layout"} disabled={!fieldId} onClick={() => navigateToLayout()}>
					<Icon name="table" />
					<Step.Content>
						<Step.Title>Field Layout</Step.Title>
						<Step.Description>Detailed plot randomizations</Step.Description>
					</Step.Content>
				</Step>
				<Step link active={step === "gps-setup"} disabled={!fieldId} onClick={() => navigateToGpsSetup()}>
					<Icon name="compass outline" />
					<Step.Content>
						<Step.Title>GPS Setup</Step.Title>
						<Step.Description>Define field position by GPS</Step.Description>
					</Step.Content>
				</Step>
				<Step
					link
					active={step === "advanced-analytics"}
					disabled={!fieldId}
					onClick={() => navigateToAdvancedAnalytics()}
				>
					<Icon name="chart bar outline" />
					<Step.Content>
						<Step.Title>Advanced Analytics</Step.Title>
						<Step.Description>Define areas for analytics</Step.Description>
					</Step.Content>
				</Step>
			</Step.Group>

			<Segment id="field-process-step-content" style={{ marginTop: "unset", paddingBottom: "unset", minHeight: 70 }}>
				{(() => {
					switch (step) {
						default:
						case "info":
							return (
								<NewEditField
									mode={fieldId && modeState !== "new" ? "edit" : "new"}
									setModeState={(state) => setModeState(state)}
									setFieldId={(id) => setFieldId(id)}
									fieldId={fieldId}
									fromViewer={fromViewer}
									handleCancel={() => handleCancel()}
									handleFinish={(id) => handleFinish(id)}
									navigateToNextStep={navigateToLayout}
								/>
							);
						case "layout":
							return (
								<FieldLayout
									fieldId={fieldId}
									fromViewer={fromViewer}
									handleCancel={() => handleCancel()}
									handleFinish={(id) => handleFinish(id)}
									navigateToNextStep={navigateToGpsSetup}
									navigateBack={navigateToInfo}
								/>
							);
						case "gps-setup":
							return (
								<FieldGpsSetup
									fieldId={fieldId}
									fromViewer={fromViewer}
									handleCancel={() => handleCancel()}
									handleFinish={(id) => handleFinish(id)}
									navigateToNextStep={navigateToAdvancedAnalytics}
									navigateBack={navigateToLayout}
								/>
							);
						case "advanced-analytics":
							return (
								<FieldAdvancedAnalytics
									fieldId={fieldId}
									fromViewer={fromViewer}
									handleCancel={handleCancel}
									handleFinish={handleFinish}
									handleSave={handleSave}
									navigateBack={navigateToGpsSetup}
								/>
							);
					}
				})()}
			</Segment>
		</Segment>
	);
};

FieldWizard.propTypes = {
	activeStep: PropTypes.string.isRequired,
	mode: PropTypes.string.isRequired,
	fromViewer: PropTypes.bool,
	handleCancel: PropTypes.func,
	handleFinish: PropTypes.func,
	handleSave: PropTypes.func,
	fieldIdFromViewer: PropTypes.string
};

export default FieldWizard;
