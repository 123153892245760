import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { Table, Segment, Message, Icon } from "semantic-ui-react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const PlotDataInspectionResults = ({ inspectionResults, groundDataColumns }) => {
	return !inspectionResults ? (
		<Segment basic style={{ minHeight: 20 }}>
			No Results
		</Segment>
	) : (
		<>
			<Segment basic>
				<h3>High Level Results</h3>
				{inspectionResults.validations.highLevel.length === 0 &&
					inspectionResults.inspections.highLevel.length === 0 && <Message positive content="All good here!" />}
				{(inspectionResults.validations.highLevel.length > 0 || inspectionResults.inspections.highLevel.length > 0) && (
					<>
						<Message
							warning
							hidden={
								inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 0).length === 0 &&
								inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 1).length === 0 &&
								inspectionResults.inspections.highLevel.filter((hlv) => hlv.type === 0).length === 0 &&
								inspectionResults.inspections.highLevel.filter((hlv) => hlv.type === 1).length === 0
							}
							list={inspectionResults.validations.highLevel
								.filter((hlv) => hlv.type === 0)
								.concat(inspectionResults.validations.highLevel.filter((hli) => hli.type === 1))
								.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 0))
								.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 1))
								.map((hl) => hl.message)}
						/>
						<Message
							error
							hidden={
								inspectionResults.validations.highLevel.filter((hlv) => hlv.type === 2).length === 0 &&
								inspectionResults.inspections.highLevel.filter((hli) => hli.type === 2).length === 0
							}
							list={inspectionResults.validations.highLevel
								.filter((hlv) => hlv.type === 2)
								.concat(inspectionResults.inspections.highLevel.filter((hli) => hli.type === 2))
								.map((hl) => hl.message)}
						/>
					</>
				)}
				<h3>Individual Record Results</h3>
				{!(groundDataColumns?.length > 0
					? inspectionResults.validations.importGroundData.concat(inspectionResults.inspections.importGroundData)
					: inspectionResults.validations.importPlotData.concat(inspectionResults.inspections.importPlotData)
				).some((tpda) => !tpda.isValid) ? (
					<Message positive content="All records are good!" />
				) : (
					<Message
						error
						content="Please review the following records for errors and reupload the file once they have been fixed."
					/>
				)}
				<Table celled striped selectable>
					<Table.Header>
						<Table.Row textAlign="center">
							<Table.HeaderCell>Field</Table.HeaderCell>
							<Table.HeaderCell>Range</Table.HeaderCell>
							<Table.HeaderCell>Column</Table.HeaderCell>
							<Table.HeaderCell>Company</Table.HeaderCell>
							<Table.HeaderCell>Trial</Table.HeaderCell>
							<Table.HeaderCell>Treatment</Table.HeaderCell>
							<Table.HeaderCell>Plot</Table.HeaderCell>
							<Table.HeaderCell>Rep</Table.HeaderCell>
							<Table.HeaderCell>Seed</Table.HeaderCell>
							{groundDataColumns?.length > 0 &&
								_.map(groundDataColumns, (gdc, index) => {
									return (
										<Table.HeaderCell key={index}>{`${gdc.name[0].toUpperCase()}${gdc.name.substring(
											1
										)}`}</Table.HeaderCell>
									);
								})}
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{_.map(
							!inspectionResults.validations.isValid
								? groundDataColumns?.length > 0
									? inspectionResults.validations.importGroundData
									: inspectionResults.validations.importPlotData
								: groundDataColumns?.length > 0
								? inspectionResults.inspections.importGroundData
								: inspectionResults.inspections.importPlotData,
							(result, index) => {
								return (
									<Table.Row key={index}>
										<Table.Cell
											positive={result.fieldVr && result.fieldVr.type === 1}
											warning={result.fieldVr && result.fieldVr.type === 0}
											error={result.fieldVr && result.fieldVr.type === 2}
										>
											{result.fieldVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.fieldVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.field}
										</Table.Cell>
										<Table.Cell
											positive={result.rangeVr && result.rangeVr.type === 1}
											warning={result.rangeVr && result.rangeVr.type === 0}
											error={result.rangeVr && result.rangeVr.type == 2}
										>
											{result.rangeVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.rangeVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.range}
										</Table.Cell>
										<Table.Cell
											positive={result.columnVr && result.columnVr.type === 1}
											warning={result.columnVr && result.columnVr.type === 0}
											error={result.columnVr && result.columnVr.type === 2}
										>
											{result.columnVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.columnVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.column}
										</Table.Cell>
										<Table.Cell
											positive={result.companyVr && result.companyVr.type === 1}
											warning={result.companyVr && result.companyVr.type === 0}
											error={result.companyVr && result.companyVr.type === 2}
										>
											{result.companyVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.companyVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.company}
										</Table.Cell>
										<Table.Cell
											positive={result.trialVr && result.trialVr.type === 1}
											warning={result.trialVr && result.trialVr.type === 0}
											error={result.trialVr && result.trialVr.type === 2}
										>
											{result.trialVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.trialVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.trial}
										</Table.Cell>
										<Table.Cell
											positive={result.treatmentVr && result.treatmentVr.type === 1}
											warning={result.treatmentVr && result.treatmentVr.type === 0}
											error={result.treatmentVr && result.treatmentVr.type === 2}
										>
											{result.treatmentVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.treatmentVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.treatment}
										</Table.Cell>
										<Table.Cell
											positive={result.plotVr && result.plotVr.type === 1}
											warning={result.plotVr && result.plotVr.type === 0}
											error={result.plotVr && result.plotVr.type === 2}
										>
											{result.plotVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.plotVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.plot}
										</Table.Cell>
										<Table.Cell
											positive={result.repVr && result.repVr.type === 1}
											warning={result.repVr && result.repVr.type === 0}
											error={result.repVr && result.repVr.type === 2}
										>
											{result.repVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.repVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.rep}
										</Table.Cell>
										<Table.Cell
											positive={result.seedVr && result.seedVr.type === 1}
											warning={result.seedVr && result.seedVr.type === 0}
											error={result.seedVr && result.seedVr.type === 2}
										>
											{result.seedVr ? (
												<Tooltip
													placement="bottom"
													mouseEnterDelay={0}
													mouseLeaveDelay={0.1}
													trigger="hover"
													overlay={<div>{result.seedVr.message}</div>}
													transitionName="rc-tooltip-zoom"
												>
													<Icon name="attention" />
												</Tooltip>
											) : null}
											{result.seed}
										</Table.Cell>
										{groundDataColumns?.length > 0 &&
											_.map(groundDataColumns, (gdc) => {
												return (
													<Table.Cell
														key={gdc.vr}
														positive={
															result.assessmentColumnsVr[gdc.vr] && result.assessmentColumnsVr[gdc.vr].type === 1
														}
														warning={
															result.assessmentColumnsVr[gdc.vr] && result.assessmentColumnsVr[gdc.vr].type === 0
														}
														error={result.assessmentColumnsVr[gdc.vr] && result.assessmentColumnsVr[gdc.vr].type === 2}
													>
														{result.assessmentColumnsVr[gdc.vr] ? (
															<Tooltip
																placement="bottom"
																mouseEnterDelay={0}
																mouseLeaveDelay={0.1}
																trigger="hover"
																overlay={<div>{result.assessmentColumnsVr[gdc.vr].message}</div>}
																transitionName="rc-tooltip-zoom"
															>
																<Icon name="attention" />
															</Tooltip>
														) : null}
														{result.assessmentColumns[gdc.name]}
													</Table.Cell>
												);
											})}
									</Table.Row>
								);
							}
						)}
					</Table.Body>
				</Table>
			</Segment>
		</>
	);
};

PlotDataInspectionResults.propTypes = {
	inspectionResults: PropTypes.object,
	groundDataColumns: PropTypes.array
};

export default PlotDataInspectionResults;
