import * as turf from "@turf/turf";
import _ from "lodash";
import MapboxGl from "mapbox-gl";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import * as uuid from "uuid";
import * as analysisActions from "../../../redux/actions/analysisActions";
import * as autoAlignActions from "../../../redux/actions/autoAlignActions";
import * as fieldActions from "../../../redux/actions/fieldActions";
import * as flightImageActions from "../../../redux/actions/flightImageActions";
import * as flightsPlotsActions from "../../../redux/actions/flightsPlotsActions";
import * as markedPlotActions from "../../../redux/actions/markedPlotActions";
import * as orthoActions from "../../../redux/actions/orthoActions";
import * as quantifiedRegionActions from "../../../redux/actions/quantifiedRegionsActions";
import { getPercision } from "../ValuePercisionConverter";

import { MapboxLayer } from "@deck.gl/mapbox";
import colorGradient from "./../Heatmap/colorGradient";
import {
	BlueYellowLayer,
	ClampedLayer,
	GreenFireBlueLayer,
	RYGLayer,
	SpectralLayer,
	ThermalLayer
} from "./orthoShaders";

import damagedIcon from "../../../assets/damaged.svg";
import partialExcludedIcon from "../../../assets/partialExcluded.svg";
import partialFlagIcon from "../../../assets/partialFlag.svg";
import flaggedIcon from "../../../assets/flagged.svg";

let allPlots = [];
let allFields = [];
let allTrials = [];
let allLLPoints = [];
let allRows = [];
let selectedFieldId = null;
let selectedTrialId = null;
let disableModifying = false;
let initialPlots = [];
let fieldCombined = [];
let fieldPivotPoints = [];
let trialPlots = [];
let movingFieldProperties = [];
let nonPlotDrawingsDeleted = [];
const fineConstant = 4;
const coarseConstant = 4;
let orthoId = null;
let markedPlots = [];
let markedSubsamples = [];
let markedPlotOptions = [];
let annotationClassOptions = [];
let defaultViewerSetting = null;
let currentFlightId = null;
let tilesetIds = [];
let activeShader = "";
let lastAppliedShader = (minThreshold, maxThreshold) => {
	minThreshold, maxThreshold;
};
let defaultMinShaderThreshold = 0.0;
let defaultMaxShaderThreshold = 1.0;
let plotPopup = null;
let subsamples = [];
let subsamplesToAdd = [];
let quantifiedRegionOptions = [];
let drawnFeature = null;

//-- Button Toggles
let llToggle = true;
let fieldToggle = true;
let plotBoundaryToggle = true;
let rowsToggle = false;
let trialsToggle = false;
let fieldLabelsToggle = false;
let trialLabelsToggle = false;
let plotLabelsToggle = false;
let defMarkersToggle = false;
let pivotToggle = false;
let styleToggled = false;
let singleFeatureToggle = false;
let pcToggle = false;
let pcQrToggle = false;
let rcQrToggle = false;
let brQrToggle = false;
let frQrToggle = false;
let scQrToggle = false;

//-- Updating QR within edit field modal
let tempPlots = [];
let tempSubsamplesToAdd = [];

//-- Heatmap shader purposes
let heatmapOverlayShadingToggled = false;
let heatmapOverlayLabelsToggled = false;
let plotAnalysisResults = [];
let analysisOptions = [];

//-- Default Viewer Setting

const scalingMetric = 0.001;
const trialColors = [
	"#a83232",
	"#c5db35",
	"#07a1e8",
	"#9607e8",
	"#e807e1",
	"#e80774",
	"#e87407",
	"#a0ff52",
	"#52ff8c",
	"#5adbc4",
	"#64bdd9",
	"#519bdb",
	"#6e69ff",
	"#cd69ff",
	"#ff69da",
	"#ff698f",
	"#ffa078",
	"#c6ed64",
	"#3fbec4",
	"#491bb5"
];

const shaderLayers = {
	by: BlueYellowLayer,
	gfb: GreenFireBlueLayer,
	ryg: RYGLayer,
	spectral: SpectralLayer,
	thermal: ThermalLayer,
	clamped: ClampedLayer
};

export function setOrthoId(id) {
	orthoId = id;
}

export function sgetOrthoId() {
	return orthoId;
}
export function styleToggle() {
	styleToggled = !styleToggled;
}

export function checkStyleToggle() {
	return styleToggled;
}

export function getDefaultViewerSetting() {
	return defaultViewerSetting;
}

export function setDefaultViewerSetting(targetDefaultViewerSetting) {
	defaultViewerSetting = targetDefaultViewerSetting;
}

// Toggle triggered when lower left toggle button is clicked
export function lowerLeftToggle(draw) {
	if (draw && draw.current) {
		if (!llToggle) {
			let featuresToAdd = {
				type: "FeatureCollection",
				features: []
			};

			if (!fieldToggle) {
				recalculateFieldBoundaries();
			}

			let llToggleFields = _.cloneDeep(allFields);

			if (singleFeatureToggle) {
				llToggleFields = _.filter(llToggleFields, ({ id }) => {
					return id === selectedFieldId;
				});
			}

			_.map(llToggleFields, ({ geometry, id }) => {
				let llPoint = {
					id: uuid.v4(),
					type: "Feature",
					properties: { field: id, type: "llPoint" },
					geometry: {
						type: "Point",
						coordinates: geometry.coordinates[0][0]
					}
				};

				allLLPoints.push(llPoint);
				featuresToAdd.features.push(llPoint);
			});

			draw.current.draw.add(featuresToAdd);
		} else {
			draw.current.draw.delete(_.map(allLLPoints, "id"));
			allLLPoints = [];
		}

		llToggle = !llToggle;
	}
}

// Creates boundaries for fields and trials using the existing plots
export function findBoundaries(plots, type, border) {
	let lowerLeft = 0,
		upperLeft = 0,
		upperRight = 0,
		lowerRight = 0;
	let plotBearings = [];

	if (plots.features.length === 1) {
		const coordinates = plots.features[0].geometry.coordinates[0];
		lowerLeft = coordinates[0];
		upperLeft = coordinates[1];
		upperRight = coordinates[2];
		lowerRight = coordinates[3];

		manageBearings(plotBearings, coordinates);
	} else {
		let fieldBearings = {};

		_.forEach(plots.features, (plot) => {
			let fieldBearing = _.round(turf.bearing(plot.geometry.coordinates[0][0], plot.geometry.coordinates[0][1]), 5);

			fieldBearings[fieldBearing] = isNaN(fieldBearings[fieldBearing]) ? 1 : fieldBearings[fieldBearing] + 1;
		});

		let fieldBearing = parseFloat(
			Object.entries(fieldBearings).reduce((a, b) => {
				if (b[1] > a[1]) {
					return b;
				}
				return a;
			})[0]
		);

		let fieldDirection =
			fieldBearing >= 0 && fieldBearing < 90
				? "ne"
				: fieldBearing >= 90 && fieldBearing <= 180
				? "se"
				: fieldBearing <= 0 && fieldBearing > -90
				? "nw"
				: "sw";

		//-- Get all coords
		let allCoords = turf.coordAll(turf.featureCollection(plots.features));

		//-- Split into x/y
		let allXCoords = _.map(allCoords, (c, i) => {
			return { coords: c[0], index: i };
		});
		let allYCoords = _.map(allCoords, (c, i) => {
			return { coords: c[1], index: i };
		});

		//-- Get extremes
		let maxX = _.maxBy(allXCoords, (c) => c.coords);
		let minX = _.minBy(allXCoords, (c) => c.coords);
		let maxY = _.maxBy(allYCoords, (c) => c.coords);
		let minY = _.minBy(allYCoords, (c) => c.coords);

		//-- Map back into points
		let maxXCoords = [allXCoords[maxX.index].coords, allYCoords[maxX.index].coords];
		let minXCoords = [allXCoords[minX.index].coords, allYCoords[minX.index].coords];
		let maxYCoords = [allXCoords[maxY.index].coords, allYCoords[maxY.index].coords];
		let minYCoords = [allXCoords[minY.index].coords, allYCoords[minY.index].coords];

		//-- Create crossing lines from each extreme point following the bearing and perpendicular bearing of the field
		let maxXBearingLine = turf.lineString([
			turf.destination(maxXCoords, 5, fieldBearing - 180).geometry.coordinates,
			maxXCoords,
			turf.destination(maxXCoords, 5, fieldBearing).geometry.coordinates
		]);
		let maxXPerpBearingLine = turf.lineString([
			turf.destination(maxXCoords, 5, fieldBearing + 90).geometry.coordinates,
			maxXCoords,
			turf.destination(maxXCoords, 5, fieldBearing - 90).geometry.coordinates
		]);
		let minXBearingLine = turf.lineString([
			turf.destination(minXCoords, 5, fieldBearing - 180).geometry.coordinates,
			minXCoords,
			turf.destination(minXCoords, 5, fieldBearing).geometry.coordinates
		]);
		let minXPerpBearingLine = turf.lineString([
			turf.destination(minXCoords, 5, fieldBearing + 90).geometry.coordinates,
			minXCoords,
			turf.destination(minXCoords, 5, fieldBearing - 90).geometry.coordinates
		]);
		let maxYBearingLine = turf.lineString([
			turf.destination(maxYCoords, 5, fieldBearing - 180).geometry.coordinates,
			maxYCoords,
			turf.destination(maxYCoords, 5, fieldBearing).geometry.coordinates
		]);
		let maxYPerpBearingLine = turf.lineString([
			turf.destination(maxYCoords, 5, fieldBearing + 90).geometry.coordinates,
			maxYCoords,
			turf.destination(maxYCoords, 5, fieldBearing - 90).geometry.coordinates
		]);
		let minYBearingLine = turf.lineString([
			turf.destination(minYCoords, 5, fieldBearing - 180).geometry.coordinates,
			minYCoords,
			turf.destination(minYCoords, 5, fieldBearing).geometry.coordinates
		]);
		let minYPerpBearingLine = turf.lineString([
			turf.destination(minYCoords, 5, fieldBearing + 90).geometry.coordinates,
			minYCoords,
			turf.destination(minYCoords, 5, fieldBearing - 90).geometry.coordinates
		]);

		//-- Get intersect of every extreme line
		let maxXIntersects = [],
			minXIntersects = [],
			maxYIntersects = [],
			minYIntersects = [];

		//---- Bearing line and perp bearing lines change intersections based on field direction
		if (fieldDirection === "nw" || fieldDirection === "se") {
			maxXIntersects = [
				turf.lineIntersect(maxYBearingLine, minYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, minYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXPerpBearingLine, maxYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, maxXPerpBearingLine).features[0].geometry.coordinates
			];

			minXIntersects = [
				turf.lineIntersect(minXBearingLine, maxYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYBearingLine, maxYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYBearingLine, minXPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXBearingLine, minXPerpBearingLine).features[0].geometry.coordinates
			];

			maxYIntersects = [
				turf.lineIntersect(maxYBearingLine, minXPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, maxYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, minXPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxYBearingLine, maxYPerpBearingLine).features[0].geometry.coordinates
			];

			minYIntersects = [
				turf.lineIntersect(minYBearingLine, maxXPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXBearingLine, minYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXBearingLine, maxXPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYBearingLine, minYPerpBearingLine).features[0].geometry.coordinates
			];
		} else {
			maxXIntersects = [
				turf.lineIntersect(maxYPerpBearingLine, minYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXPerpBearingLine, minYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, maxYPerpBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXBearingLine, maxXPerpBearingLine).features[0].geometry.coordinates
			];

			minXIntersects = [
				turf.lineIntersect(minXPerpBearingLine, maxYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYPerpBearingLine, maxYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYPerpBearingLine, minXBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXPerpBearingLine, minXBearingLine).features[0].geometry.coordinates
			];

			maxYIntersects = [
				turf.lineIntersect(maxYPerpBearingLine, minXBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXPerpBearingLine, maxYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxXPerpBearingLine, minXBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(maxYPerpBearingLine, maxYBearingLine).features[0].geometry.coordinates
			];

			minYIntersects = [
				turf.lineIntersect(minYPerpBearingLine, maxXBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXPerpBearingLine, minYBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minXPerpBearingLine, maxXBearingLine).features[0].geometry.coordinates,
				turf.lineIntersect(minYPerpBearingLine, minYBearingLine).features[0].geometry.coordinates
			];
		}

		//-- Find combination of intersects that produce largest area
		let largestArea = 1;
		let largestIntersects = [0, 0, 0, 0];

		_.forEach(maxXIntersects, (a, i) => {
			_.forEach(maxYIntersects, (b, ii) => {
				_.forEach(minXIntersects, (c, iii) => {
					_.forEach(minYIntersects, (d, iv) => {
						let tempPolygon = turf.polygon([
							[maxXIntersects[i], minYIntersects[iv], minXIntersects[iii], maxYIntersects[ii], maxXIntersects[i]]
						]);

						let checkArea = turf.area(tempPolygon);

						if (checkArea > largestArea) {
							largestArea = checkArea;
							largestIntersects = [i, ii, iii, iv];
						}
					});
				});
			});
		});

		//-- Assign extremes to corners based on field direction
		switch (fieldDirection) {
			case "ne":
				lowerLeft = minXIntersects[largestIntersects[2]];
				upperLeft = maxYIntersects[largestIntersects[1]];
				upperRight = maxXIntersects[largestIntersects[0]];
				lowerRight = minYIntersects[largestIntersects[3]];
				break;
			case "nw":
				lowerLeft = minYIntersects[largestIntersects[3]];
				upperLeft = minXIntersects[largestIntersects[2]];
				upperRight = maxYIntersects[largestIntersects[1]];
				lowerRight = maxXIntersects[largestIntersects[0]];
				break;
			case "se":
				lowerLeft = maxYIntersects[largestIntersects[1]];
				upperLeft = maxXIntersects[largestIntersects[0]];
				upperRight = minYIntersects[largestIntersects[3]];
				lowerRight = minXIntersects[largestIntersects[2]];
				break;
			case "sw":
				lowerLeft = maxXIntersects[largestIntersects[0]];
				upperLeft = minYIntersects[largestIntersects[3]];
				upperRight = minXIntersects[largestIntersects[2]];
				lowerRight = maxYIntersects[largestIntersects[1]];
				break;
			default:
				break;
		}

		//-- Handles adding buffer to boundaries
		_.forEach(
			_.map(_.map(_.map(plots.features, "geometry"), "coordinates"), (c) => c[0]),
			(c) => {
				manageBearings(plotBearings, c);
			}
		);
	}

	let initialLL = lowerLeft;

	// Add buffer
	let bearing = _.maxBy(plotBearings, "count").bearing;
	if (border) {
		let buffer =
			(plots.features[0].properties.fieldColWidth > 10 ? 10 : plots.features[0].properties.fieldColWidth) /
			3.281 /
			1000; // Convert ft to km
		// Push point horizontally outward
		lowerLeft = turf.destination(lowerLeft, buffer, bearing.ulToLl).geometry.coordinates;
		upperLeft = turf.destination(upperLeft, buffer, bearing.llToUl).geometry.coordinates;
		upperRight = turf.destination(upperRight, buffer, bearing.ulToUr).geometry.coordinates;
		lowerRight = turf.destination(lowerRight, buffer, bearing.llToLr).geometry.coordinates;
		// Push point vertically outward
		lowerLeft = turf.destination(lowerLeft, buffer, bearing.lrToLl).geometry.coordinates;
		upperLeft = turf.destination(upperLeft, buffer, bearing.urToUl).geometry.coordinates;
		upperRight = turf.destination(upperRight, buffer, bearing.lrToUr).geometry.coordinates;
		lowerRight = turf.destination(lowerRight, buffer, bearing.urToLr).geometry.coordinates;
	} else if (!border && type === "field") {
		let buffer = 0.25 / 3.281 / 1000; // small distance to show field boundary
		// Push point horizontally outward
		lowerLeft = turf.destination(lowerLeft, buffer, bearing.ulToLl).geometry.coordinates;
		upperLeft = turf.destination(upperLeft, buffer, bearing.llToUl).geometry.coordinates;
		upperRight = turf.destination(upperRight, buffer, bearing.ulToUr).geometry.coordinates;
		lowerRight = turf.destination(lowerRight, buffer, bearing.llToLr).geometry.coordinates;
		// Push point vertically outward
		lowerLeft = turf.destination(lowerLeft, buffer, bearing.lrToLl).geometry.coordinates;
		upperLeft = turf.destination(upperLeft, buffer, bearing.urToUl).geometry.coordinates;
		upperRight = turf.destination(upperRight, buffer, bearing.lrToUr).geometry.coordinates;
		lowerRight = turf.destination(lowerRight, buffer, bearing.urToLr).geometry.coordinates;
	}

	// Assign new pivot points
	if (type === "field") {
		if (_.findIndex(fieldPivotPoints, { id: plots.features[0].properties.field }) === -1) {
			fieldPivotPoints.push({
				id: plots.features[0].properties.field,
				pivot: {
					id: uuid.v4(),
					type: "Feature",
					properties: {
						type: "pivot",
						field: plots.features[0].properties.field,
						portColor: "#7b32a8"
					},
					geometry: {
						type: "Point",
						coordinates: lowerLeft
					}
				}
			});
		}
	}

	let boundaries = {
		id: type === "field" ? plots.features[0].properties.field : plots.features[0].properties.trial,
		type: "Feature",
		properties: {
			plots: plots.features,
			type: type,
			field: plots.features[0].properties.field,
			name: type === "field" ? plots.features[0].properties.fieldName : plots.features[0].properties.trialName,
			portColor: type === "field" ? (plots.features[0].properties.locked ? "#03fcf8" : "#FF0000") : "#FF0000",
			locked: plots.features[0].properties.locked,
			crop: plots.features[0].properties.crop,
			plantDate: plots.features[0].properties.plantDate,
			harvestDate: plots.features[0].properties.harvestDate,
			city: plots.features[0].properties.city,
			state: plots.features[0].properties.state,
			ranges: plots.features[0].properties.ranges,
			columns: plots.features[0].properties.columns,
			rangeLength: plots.features[0].properties.fieldRangeLength,
			columnWidth: plots.features[0].properties.fieldColWidth,
			scaleFactor: plots.features[0].properties.scaleFactor,
			initialLL: initialLL
		},
		geometry: {
			type: "Polygon",
			coordinates: [[lowerLeft, upperLeft, upperRight, lowerRight, lowerLeft]]
		}
	};

	if (type === "field") {
		allFields.push(boundaries);
	}
	if (type === "trial") {
		allTrials.push(boundaries);
	}
}

function manageBearings(plotBearings, coordinates) {
	let localBearing = {
		llToLr: turf.bearing(coordinates[0], coordinates[3]),
		llToUl: turf.bearing(coordinates[0], coordinates[1]),
		ulToLl: turf.bearing(coordinates[1], coordinates[0]),
		ulToUr: turf.bearing(coordinates[1], coordinates[2]),
		urToUl: turf.bearing(coordinates[2], coordinates[1]),
		urToLr: turf.bearing(coordinates[2], coordinates[3]),
		lrToLl: turf.bearing(coordinates[3], coordinates[0]),
		lrToUr: turf.bearing(coordinates[3], coordinates[2])
	};
	let index = _.findIndex(plotBearings, { bearing: localBearing });
	if (index === -1) {
		plotBearings.push({
			bearing: localBearing,
			coords: coordinates,
			count: 1
		});
	} else {
		plotBearings[index].count += 1;
	}
}

// Recreates fields if plots were moved since last creation
function recalculateFieldBoundaries(border = false) {
	let fields = [];

	_.map(allPlots, ({ properties }, index) => {
		if (fields.length === 0 || !fields.some((field) => field.id === properties.field)) {
			let newField = {
				type: "FeatureCollection",
				id: properties.field,
				features: []
			};
			fields.push(newField);
		}

		fields[_.findIndex(fields, { id: properties.field })].features.push(allPlots[index]);
	});

	allFields = [];
	_.map(fields, (field) => {
		findBoundaries(field, "field", border);
	});
}

// Toggle triggered when field toggle is clicked
export function fieldBoundaryToggle(draw) {
	if (draw && draw.current) {
		if (!fieldToggle) {
			recalculateFieldBoundaries();

			let fieldsToDraw = _.cloneDeep(allFields);

			if (singleFeatureToggle) {
				fieldsToDraw = _.filter(fieldsToDraw, ({ id }) => {
					return id === selectedFieldId;
				});
			}

			let newFeatures = {
				type: "FeatureCollection",
				features: fieldsToDraw
			};

			draw.current.draw.add(newFeatures);

			bringSubsamplesToTopLayer(draw);
		} else {
			draw.current.draw.delete(_.map(allFields, "id"));
		}

		fieldToggle = !fieldToggle;
	}
}

// Toggle triggered when plot toggle button is clicked
export function plotToggle(draw) {
	if (draw && draw.current) {
		if (!plotBoundaryToggle) {
			let plotsToDraw = _.cloneDeep(allPlots);

			if (singleFeatureToggle && !selectedTrialId) {
				plotsToDraw = _.filter(plotsToDraw, ({ properties }) => {
					return properties.field === selectedFieldId;
				});
			} else if (singleFeatureToggle && selectedTrialId) {
				plotsToDraw = _.filter(plotsToDraw, ({ properties }) => {
					return properties.trial === selectedTrialId;
				});
			}

			let featuresToAdd = {
				type: "FeatureCollection",
				features: plotsToDraw
			};

			draw.current.draw.add(featuresToAdd);

			//-- Recoloring trial plots
			if (trialsToggle) {
				trialToggle(draw);
				trialToggle(draw);
			}

			bringSubsamplesToTopLayer(draw);
		} else {
			draw.current.draw.delete(_.map(allPlots, "id"));
		}

		plotBoundaryToggle = !plotBoundaryToggle;
	}
}

// Toggle triggered when row toggle button is clicked
export function rowToggle(draw) {
	if (draw && draw.current) {
		if (!rowsToggle) {
			draw.current.draw.changeMode("simple_select");

			let featuresToAdd = {
				type: "FeatureCollection",
				features: []
			};

			if (fieldCombined.length > 0) {
				uncombinePlotsInField(draw);
			}

			drawRows(featuresToAdd);

			draw.current.draw.add(featuresToAdd);

			bringSubsamplesToTopLayer(draw);
		} else {
			draw.current.draw.delete(_.map(allRows, "id"));
			allRows = [];
		}

		rowsToggle = !rowsToggle;
	}
}

function drawRows(featuresToAdd = null) {
	let plotsToDraw = _.cloneDeep(allPlots);

	if (singleFeatureToggle && !selectedTrialId) {
		plotsToDraw = _.filter(plotsToDraw, ({ properties }) => {
			return properties.field === selectedFieldId;
		});
	} else if (singleFeatureToggle && selectedTrialId) {
		plotsToDraw = _.filter(plotsToDraw, ({ properties }) => {
			return properties.trial === selectedTrialId;
		});
	}

	_.map(plotsToDraw, ({ id, properties, geometry }) => {
		let bearing = turf.bearing(geometry.coordinates[0][0], geometry.coordinates[0][1]);
		let perpendicularBearing = bearing + 90;

		let coordsToRotateAround = [geometry.coordinates[0][0][0], geometry.coordinates[0][0][1]];

		let firstRow = true;
		_.times(properties.fieldRowCount, (i) => {
			let fieldRowSpacing = properties.fieldRowSpacing * properties.scaleFactor;
			let lineToAdd = {
				type: "Feature",
				id: uuid.v4(),
				properties: {
					field: properties.field,
					trial: properties.trial,
					plot: id,
					rowNumber: i + 1,
					type: "row"
				},
				geometry: {
					type: "LineString",
					coordinates: []
				}
			};

			//Set the field row spacing to half to line up the rows correctly
			if (firstRow) {
				fieldRowSpacing = fieldRowSpacing / 2;
				fieldRowSpacing += (properties.fieldPlanterOffset * properties.scaleFactor) / 2;
				firstRow = false;
			}

			let initialPoint = turf.destination(
				coordsToRotateAround,
				fieldRowSpacing / 12 / 3.281 / 1000,
				perpendicularBearing
			).geometry.coordinates;

			let endingPoint = turf.destination(
				initialPoint,
				(properties.fieldRangeLength * properties.scaleFactor) / 3.281 / 1000,
				bearing
			).geometry.coordinates;

			coordsToRotateAround = initialPoint;

			lineToAdd.geometry.coordinates.push(initialPoint, endingPoint);
			if (featuresToAdd) {
				featuresToAdd.features.push(lineToAdd);
			}
			allRows.push(lineToAdd);
		});
	});
}

// Resets the viewport to the original settings
export function resetOrientation(canvas, center, level, draw, dropdownRef, controlComponentRefs) {
	if (canvas && center.length > 0) {
		if (level === "flight") {
			canvas.flyTo({
				bearing: 0,
				center: center,
				zoom: 17,
				speed: 0.5,
				pitch: 0
			});
		} else {
			fitToHeight(canvas, level, true);
		}

		toggleSingleFieldTrial(draw, canvas, null, controlComponentRefs);

		if (fieldCombined.length > 0) {
			uncombinePlotsInField(draw, false);
		}

		if (dropdownRef) {
			dropdownRef.current.innerRef.current.setThirdDropdownId("all");
		}
	}
}

// Recreates trial boundaries if any plots were moved since previous creation
function recalculateTrialBoundaries(border) {
	let trials = [];

	_.map(allPlots, ({ properties }, index) => {
		if (properties.trial && properties.trial !== "none") {
			if (trials.length === 0 || !trials.some((trial) => trial.id === properties.trial)) {
				let newTrial = {
					type: "FeatureCollection",
					id: properties.trial,
					features: []
				};
				trials.push(newTrial);
			}

			trials[_.findIndex(trials, { id: properties.trial })].features.push(allPlots[index]);
		}
	});

	allTrials = [];
	_.map(trials, (trial) => {
		findBoundaries(trial, "trial", border);
	});
}

// Toggle triggered when trial toggle button is clicked
export function trialToggle(draw) {
	if (draw && draw.current) {
		if (!trialsToggle) {
			//-- Redraw trial boundaries just in case
			recalculateTrialBoundaries(false);

			//-- Get all existing plots with trials
			//---- Get assigned trial color index
			let plotsWithTrials = _.cloneDeep(allTrials);
			_.map(plotsWithTrials, (plots, index) => {
				plots.trialColorIndex = index;
			});

			//-- If single feature toggled, only display plots with selected trial
			if (singleFeatureToggle && selectedTrialId) {
				plotsWithTrials = _.filter(plotsWithTrials, ({ id }) => {
					return id === selectedTrialId;
				});
			}

			//-- Find matching plots to manipulate in allPlots object
			_.forEach(plotsWithTrials, ({ id, trialColorIndex }) => {
				_.forEach(allPlots, (plot) => {
					if (plot.properties.trial === id) {
						//-- Create trial plot object to hold for later use
						let trialPlot = {
							id: uuid.v4(),
							type: "Feature",
							properties: {
								...plot.properties,
								trialColor: trialColors[trialColorIndex % 20],
								plotId: plot.id
							},
							geometry: plot.geometry
						};

						//-- Pick trial color from master list
						plot.trialColor = trialColors[trialColorIndex % 20];

						//-- Check for existing mapbox feature
						let featureExists = draw.current.draw.get(plot.id);

						if (featureExists) {
							//-- Redrawing plot
							draw.current.draw.setFeatureProperty(plot.id, "trialColor", trialColors[trialColorIndex % 20]);

							let feature = draw.current.draw.get(plot.id);
							draw.current.draw.add(feature);
						}

						//-- Add trial plot objects to global
						trialPlots.push(trialPlot);
					}
				});
			});

			bringSubsamplesToTopLayer(draw);
		} else {
			//-- Look for matching plot in the allPlots object
			_.map(trialPlots, (tp) => {
				_.map(allPlots, (ap) => {
					if (ap.id === tp.properties.plotId) {
						//-- Redrawing plot
						let feature = draw.current.draw.get(ap.id);

						//-- Remove trialColor property to recolor plot with original color
						if (feature) {
							delete feature.properties.trialColor;

							draw.current.draw.add(feature);
						}
					}
				});
			});

			//-- Clear all trial plots
			trialPlots = [];

			bringSubsamplesToTopLayer(draw);
		}

		//-- Toggle opposite state
		trialsToggle = !trialsToggle;
	}
}

export function fieldPivotToggle(draw) {
	if (draw.current) {
		if (!pivotToggle) {
			let newFeatures = {
				type: "FeatureCollection",
				features: _.map(fieldPivotPoints, "pivot")
			};

			draw.current.draw.add(newFeatures);
		} else {
			draw.current.draw.delete(_.map(_.map(fieldPivotPoints, "pivot"), "id"));
		}

		pivotToggle = !pivotToggle;
	}
}

export function fieldPivotLlSet(draw) {
	if (draw.current) {
		if (fieldCombined.length > 0) {
			uncombinePlotsInField(draw);
		}

		// Delete pivot drawings if enabled
		let pivotToggleState = _.cloneDeep(pivotToggle);
		if (pivotToggleState) {
			fieldPivotToggle(draw);
		}

		// Enable ll if not enabled
		let llState = _.cloneDeep(llToggle);
		if (!llState) {
			lowerLeftToggle(draw);
		}

		_.map(fieldPivotPoints, (pivotPoint) => {
			let llPoint = _.filter(allLLPoints, ({ properties }) => {
				if (properties.field === pivotPoint.id) return true;
			})[0];
			let center = turf.center(llPoint).geometry.coordinates;
			pivotPoint.pivot.geometry.coordinates = center;
		});

		// Reset to original state
		if (pivotToggleState) {
			fieldPivotToggle(draw);
		}
		if (!llState) {
			lowerLeftToggle(draw);
		}
	}
}

// Polyfill function to make fit to height/width work with a bearing
function fitBoundsRotated(bounds, options, eventData, canvas) {
	options = {
		padding: {
			top: 0,
			bottom: 0,
			right: 0,
			left: 0
		},
		offset: [0, 0],
		maxZoom: canvas.transform.maxZoom,
		...options
	};

	if (typeof options.padding === "number") {
		const p = options.padding;
		options.padding = {
			top: p,
			bottom: p,
			right: p,
			left: p
		};
	}

	bounds = MapboxGl.LngLatBounds.convert(bounds);
	const paddingOffset = [options.padding.left - options.padding.right, options.padding.top - options.padding.bottom],
		lateralPadding = Math.min(options.padding.right, options.padding.left),
		verticalPadding = Math.min(options.padding.top, options.padding.bottom);
	options.offset = [options.offset[0] + paddingOffset[0], options.offset[1] + paddingOffset[1]];

	options.bearing = options.bearing || canvas.getBearing();

	const offset = MapboxGl.Point.convert(options.offset),
		tr = canvas.transform,
		nw = tr.project(bounds.getNorthWest()),
		se = tr.project(bounds.getSouthEast()),
		size = se.sub(nw);

	const theta = options.bearing * (Math.PI / 180),
		W = size.x * Math.abs(Math.cos(theta)) + size.y * Math.abs(Math.sin(theta)),
		H = size.x * Math.abs(Math.sin(theta)) + size.y * Math.abs(Math.cos(theta)),
		rotatedSize = { x: W, y: H },
		scaleX = (tr.width - lateralPadding * 2 - Math.abs(offset.x) * 2) / rotatedSize.x,
		scaleY = (tr.height - verticalPadding * 2 - Math.abs(offset.y) * 2) / rotatedSize.y;

	if (scaleY < 0 || scaleX < 0) {
		if (typeof console !== "undefined")
			console.warn("Map cannot fit within canvas with the given bounds, padding, and/or offset.");
		return canvas;
	}

	options.center = tr.unproject(nw.add(se).div(2));
	options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), options.maxZoom);

	return options.linear ? canvas.easeTo(options, eventData) : canvas.flyTo(options, eventData);
}

// Finds and changes viewport to fit the height of the selected field/trial
export function fitToHeight(canvas, type, originalPos = false) {
	if ((type === "field" && allFields.length > 0) || (type === "trial" && allTrials.length > 0)) {
		if (type === "field" && !selectedFieldId) {
			selectedFieldId = _.last(allFields).id;
		} else if (type === "trial" && !selectedTrialId) {
			selectedTrialId = _.last(allTrials).id;
		}

		let coords =
			type === "field"
				? _.find(allFields, { id: selectedFieldId }).geometry.coordinates[0]
				: _.find(allTrials, { id: selectedTrialId }).geometry.coordinates[0];

		if (coords) {
			//-- Get current viewport bearing
			let viewportLl = canvas.unproject([0, canvas.getCanvas().height]).toArray();
			let viewportUl = canvas.unproject([0, 0]).toArray();
			let viewportBearing = turf.bearing(viewportLl, viewportUl);

			//-- Identify all bearings of feature
			let llToUl = turf.bearing(coords[0], coords[1]);
			let ulToUr = turf.bearing(coords[1], coords[2]);
			let urToLr = turf.bearing(coords[2], coords[3]);
			let lrToLl = turf.bearing(coords[3], coords[0]);

			//-- Adjusting for range to be 0 - 360
			if (viewportBearing < 0) viewportBearing = 360 - Math.abs(viewportBearing);
			if (llToUl < 0) llToUl = 360 - Math.abs(llToUl);
			if (ulToUr < 0) ulToUr = 360 - Math.abs(ulToUr);
			if (urToLr < 0) urToLr = 360 - Math.abs(urToLr);
			if (lrToLl < 0) lrToLl = 360 - Math.abs(lrToLl);

			//-- Viewport bearing must be less than feature bearing
			let closestBearing = [llToUl, ulToUr, urToLr, lrToLl].reduce((prev, curr) =>
				Math.abs(curr - viewportBearing) < Math.abs(prev - viewportBearing) ? curr : prev
			);

			//-- Assign what points need to be captured on the given bearing
			let firstPoint, secondPoint;
			if (closestBearing.toPrecision(4) == llToUl.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[3]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[1], coords[2]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == ulToUr.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[1]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[2], coords[3]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == urToLr.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[3]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[1], coords[2]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == lrToLl.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[1]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[2], coords[3]).geometry.coordinates;
			}

			if (closestBearing > 180) closestBearing -= 360;

			let bearing = turf.bearing(coords[0], coords[1]);
			let bounds = originalPos
				? turf.bbox(
						turf.multiLineString([
							[
								turf.midpoint(coords[0], coords[3]).geometry.coordinates,
								turf.midpoint(coords[1], coords[2]).geometry.coordinates
							]
						])
				  )
				: turf.bbox(turf.multiLineString([[firstPoint, secondPoint]]));

			fitBoundsRotated(
				[
					[bounds[0], bounds[1]],
					[bounds[2], bounds[3]]
				],
				{
					bearing: originalPos ? bearing : closestBearing
				},
				{},
				canvas
			);
		}
	}
}

// Finds and changes viewport to fit the width of the selected field
export function fitToWidth(canvas, type) {
	if ((type === "field" && allFields.length > 0) || (type === "trial" && allTrials.length > 0)) {
		if (type === "field" && !selectedFieldId) {
			selectedFieldId = _.last(allFields).id;
		} else if (type === "trial" && !selectedTrialId) {
			selectedTrialId = _.last(allTrials).id;
		}

		let coords =
			type === "field"
				? _.find(allFields, { id: selectedFieldId }).geometry.coordinates[0]
				: _.find(allTrials, { id: selectedTrialId }).geometry.coordinates[0];

		if (coords) {
			//-- Get current viewport bearing
			let viewportLl = canvas.unproject([0, canvas.getCanvas().height]).toArray();
			let viewportUl = canvas.unproject([0, 0]).toArray();
			let viewportBearing = turf.bearing(viewportLl, viewportUl);

			//-- Identify all bearings of feature
			let llToUl = turf.bearing(coords[0], coords[1]);
			let ulToUr = turf.bearing(coords[1], coords[2]);
			let urToLr = turf.bearing(coords[2], coords[3]);
			let lrToLl = turf.bearing(coords[3], coords[0]);

			//-- Adjusting for range to be 0 - 360
			if (viewportBearing < 0) viewportBearing = 360 - Math.abs(viewportBearing);
			if (llToUl < 0) llToUl = 360 - Math.abs(llToUl);
			if (ulToUr < 0) ulToUr = 360 - Math.abs(ulToUr);
			if (urToLr < 0) urToLr = 360 - Math.abs(urToLr);
			if (lrToLl < 0) lrToLl = 360 - Math.abs(lrToLl);

			//-- Viewport bearing must be less than feature bearing
			let closestBearing = [llToUl, ulToUr, urToLr, lrToLl].reduce((prev, curr) =>
				Math.abs(curr - viewportBearing) < Math.abs(prev - viewportBearing) ? curr : prev
			);

			let firstPoint, secondPoint;
			if (closestBearing.toPrecision(4) == llToUl.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[1]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[2], coords[3]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == ulToUr.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[1], coords[2]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[0], coords[3]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == urToLr.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[0], coords[1]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[2], coords[3]).geometry.coordinates;
			}
			if (closestBearing.toPrecision(4) == lrToLl.toPrecision(4)) {
				firstPoint = turf.midpoint(coords[1], coords[2]).geometry.coordinates;
				secondPoint = turf.midpoint(coords[0], coords[3]).geometry.coordinates;
			}

			if (closestBearing > 180) closestBearing -= 360;

			let bounds = turf.bbox(turf.multiLineString([[firstPoint, secondPoint]]));

			fitBoundsRotated(
				[
					[bounds[0], bounds[1]],
					[bounds[2], bounds[3]]
				],
				{
					bearing: closestBearing
				},
				{},
				canvas
			);
		}
	}
}

export function plotLabelToggle(draw, canvas, componentRefs) {
	if (canvas) {
		let filteredPlots = _.cloneDeep(allPlots);

		if (!plotLabelsToggle) {
			//-- Turn off heatmap values if enabled
			if (componentRefs.heatmapOverlayLabelsRef?.current?.checkToggleState) {
				heatmapOverlayLabels(draw, canvas, componentRefs);
			}

			let labelCollection = {
				type: "FeatureCollection",
				features: []
			};

			//-- Filter on field id
			if (singleFeatureToggle && selectedFieldId) {
				filteredPlots = _.filter(allPlots, (ap) => ap.properties.field === selectedFieldId);
			}

			//-- Filter on trial id
			if (singleFeatureToggle && selectedTrialId) {
				filteredPlots = _.filter(allPlots, (ap) => ap.properties.trial === selectedTrialId);
			}

			_.map(filteredPlots, (plot) => {
				let center = turf.centroid(plot).geometry.coordinates;

				if (plot.properties.name !== null && plot.properties.treatment !== null) {
					let labelToAdd = {
						type: "Feature",
						properties: {
							name: `${plot.properties.name} \n ${plot.properties.treatment}`,
							field: plot.properties.field,
							type: "plotLabel"
						},
						geometry: {
							type: "Point",
							coordinates: center
						}
					};
					labelCollection.features.push(labelToAdd);
				}
			});

			let sourceId = uuid.v4();
			canvas.addSource(sourceId, {
				type: "geojson",
				data: labelCollection
			});

			canvas.addLayer({
				id: "plot-labels",
				type: "symbol",
				source: sourceId,
				layout: {
					"text-field": ["format", ["get", "name"], { "font-scale": 1.2 }],
					"text-variable-anchor": ["center"],
					"text-size": 24,
					"text-justify": "auto"
				},
				paint: {
					"text-color": "#ffffff",
					"text-halo-color": "#000000",
					"text-halo-width": 1
				},
				minzoom: 20
			});
		} else {
			if (canvas.getLayer("plot-labels")) {
				canvas.removeLayer("plot-labels");
			}
		}

		plotLabelsToggle = !plotLabelsToggle;
	}
}

export function defMarkerToggle(canvas, controlComponentRefs) {
	if (canvas) {
		const images = [
			{ svg: damagedIcon, name: "Excluded", size: [64, 64] },
			{ svg: flaggedIcon, name: "Flagged", size: [64, 64] },
			{ svg: partialFlagIcon, name: "PartiallyFlagged", size: [64, 64] },
			{ svg: partialExcludedIcon, name: "PartiallyExcluded", size: [64, 64] }
		];

		images.map((ob) => {
			if (!canvas.hasImage(ob.name)) {
				const img = new Image(ob.size[0], ob.size[1]);
				img.onload = () => canvas.addImage(ob.name, img, { sdf: "true" });
				img.src = ob.svg;
			}
		});

		let filteredPlots = _.cloneDeep(allPlots);
		if (!defMarkersToggle) {
			let defCollection = {
				type: "FeatureCollection",
				features: []
			};

			//-- Filter on field id
			if (singleFeatureToggle && selectedFieldId) {
				filteredPlots = _.filter(allPlots, (ap) => ap.properties.field === selectedFieldId);
			}

			//-- Filter on trial id
			if (singleFeatureToggle && selectedTrialId) {
				filteredPlots = _.filter(allPlots, (ap) => ap.properties.trial === selectedTrialId);
			}

			const markedIds = new Set(_.map(markedSubsamples, "quantifiedRegionId"));

			const firstPlot = filteredPlots[0];
			const firstPlotSubsamples = _.filter(subsamples, (ps) => ps.properties.plotId === firstPlot.id);
			const splitFirstPlotSubsamples = _.groupBy(firstPlotSubsamples, "properties.quantifiedRegionTypeName");

			let minSideLen = 10;
			if (firstPlot && firstPlot.properties) {
				minSideLen = Math.min(firstPlot.properties.fieldColWidth, firstPlot.properties.fieldRangeLength);
			}

			const size = Math.log10(minSideLen);
			const visibleAtZoom = 24 - size;

			const quantifiedRegionTypeRefrence = _.reduce(
				Object.keys(splitFirstPlotSubsamples),
				(acc, type) => {
					acc[type] = {};
					acc[type].count = splitFirstPlotSubsamples[type].length;
					return acc;
				},
				{}
			);

			_.map(filteredPlots, (plot) => {
				let plotDef = _.filter(markedPlots, (mp) => mp.plotId === plot.id);

				const lowerLeft = plot.geometry.coordinates[0][0];
				const lowerRight = plot.geometry.coordinates[0][3];
				const upperLeft = plot.geometry.coordinates[0][1];

				const bearing1 = turf.bearing(lowerLeft, lowerRight);
				const bearing2 = turf.bearing(lowerLeft, upperLeft);

				let paddedLowerLeft = turf.destination(lowerLeft, 3.5 * size * size, bearing1, { units: "feet" }).geometry
					.coordinates;
				paddedLowerLeft = turf.destination(paddedLowerLeft, 3.5 * size * size, bearing2, { units: "feet" }).geometry
					.coordinates;

				const statusOrder = {
					Excluded: 0,
					Flagged: 1
				};

				const learnedStatuses = {};

				plotDef.forEach((pdef) => {
					let dateAndTime = new Date(pdef.dateAndTime ? pdef.dateAndTime : new Date(Date.UTC(0, 0))).getTime();
					let plotStatus = {
						status: pdef.plotStatusName,
						level: "Entire Plot",
						notes: pdef.notes ?? "",
						quantifiedRegionNames: "",
						statusOrder: statusOrder[pdef.plotStatusName],
						levelOrder: 0,
						isAllData: pdef.isAllData,
						annotationClassifier: pdef.annotationClassifier,
						modifiedBy: pdef.modifiedBy,
						modifiedDateTime: moment(pdef.modifiedDateTime).local().format("MM/DD/YY")
					};

					if (learnedStatuses[dateAndTime]) {
						learnedStatuses[dateAndTime].push(plotStatus);
					} else {
						learnedStatuses[dateAndTime] = [plotStatus];
					}
				});

				const plotSubsamples = _.filter(
					subsamples,
					(ps) => ps.properties.plotId === plot.id && markedIds.has(ps.properties.quantifiedRegionId)
				);

				if (plotSubsamples.length > 0) {
					const datedMarkedSubsamples = _.map(markedSubsamples, (ms) => {
						if (ms.isAllData) {
							return {
								...ms,
								dateAndTime: new Date(Date.UTC(0, 0))
							};
						}
						return ms;
					});

					const markedTimeframeSubsamples = Object.values(
						_.groupBy(datedMarkedSubsamples, (dms) =>
							[new Date(dms.dateAndTime).toLocaleString(), dms.annotationClassifier, dms.modifiedBy].join()
						)
					);

					for (let mts of markedTimeframeSubsamples) {
						const markedPlotSubsamples = _.reduce(
							mts,
							(acc, cur) => {
								const mps = _.find(plotSubsamples, { properties: { quantifiedRegionId: cur.quantifiedRegionId } });
								if (mps) {
									return [
										...acc,
										{
											...cur,
											quantifiedRegionNumber: mps.properties.quantifiedRegionNumber,
											quantifiedRegionTypeName: mps.properties.quantifiedRegionTypeName,
											column: mps.properties.column,
											range: mps.properties.range
										}
									];
								}
								return acc;
							},
							[]
						);

						if (markedPlotSubsamples.length > 0) {
							let excludedPlotSubsamples = _.filter(markedPlotSubsamples, {
								quantifiedRegionStatusName: "Excluded"
							});
							let flaggedPlotSubsamples = _.filter(markedPlotSubsamples, {
								quantifiedRegionStatusName: "Flagged"
							});

							excludedPlotSubsamples = _.groupBy(excludedPlotSubsamples, "quantifiedRegionTypeName");
							flaggedPlotSubsamples = _.groupBy(flaggedPlotSubsamples, "quantifiedRegionTypeName");

							const checkIfAll = (splitMarkedSubsamples) => {
								const qrstids = Object.keys(splitMarkedSubsamples);
								for (let id of qrstids) {
									if (splitMarkedSubsamples[id].length === quantifiedRegionTypeRefrence[id].count) {
										return true;
									}
								}
								return false;
							};

							const timeframeStatus = [];

							const learnStatus = (splitMarkedSubsamples, statusName, statusOrder) => {
								if (Object.keys(splitMarkedSubsamples).length > 0) {
									const subsampleTypeNumbers = {};
									let notes = "";

									const qrstids = Object.keys(splitMarkedSubsamples);
									for (let id of qrstids) {
										const subsampleNumbers = _.map(splitMarkedSubsamples[id], "quantifiedRegionNumber");
										subsampleTypeNumbers[id] = subsampleNumbers.sort((a, b) => a - b);

										const notesSet = _.uniq(_.map(splitMarkedSubsamples[id], "notes"));

										notes += _.reduce(notesSet, (acc, n) => (n ? acc + n + ", " : ""), "");
									}

									// taken from https://stackoverflow.com/questions/2270910/how-to-reduce-consecutive-integers-in-an-array-to-hyphenated-range-expressions
									const getRanges = (array) => {
										var ranges = [],
											rstart,
											rend;
										for (var i = 0; i < array.length; i++) {
											rstart = array[i];
											rend = rstart;
											while (array[i + 1] - array[i] == 1) {
												rend = array[i + 1]; // increment the index if the numbers sequential
												i++;
											}
											ranges.push(rstart === rend ? "S" + rstart + "" : "S" + rstart + "–S" + rend);
										}
										return ranges;
									};

									_.map(Object.keys(subsampleTypeNumbers), (key) => {
										subsampleTypeNumbers[key] = getRanges(subsampleTypeNumbers[key]);
									});

									notes = notes.slice(0, notes.length - 2);

									let level = "Partial";

									if (checkIfAll(splitMarkedSubsamples)) {
										level = "Entire Plot";
									}

									timeframeStatus.push({
										status: statusName,
										level: level,
										notes: notes,
										quantifiedRegionNames: subsampleTypeNumbers,
										statusOrder: statusOrder,
										levelOrder: level === "Partial" ? 1 : 0,
										isAllData: markedPlotSubsamples[0]?.isAllData,
										annotationClassifier: markedPlotSubsamples[0]?.annotationClassifier,
										modifiedBy: markedPlotSubsamples[0]?.modifiedBy,
										modifiedDateTime: moment(markedPlotSubsamples[0]?.modifiedDateTime).local().format("MM/DD/YY")
									});
								}
							};

							learnStatus(excludedPlotSubsamples, "Excluded", 0);
							learnStatus(flaggedPlotSubsamples, "Flagged", 1);

							const dateAndTime = new Date(markedPlotSubsamples[0].dateAndTime).getTime();
							if (learnedStatuses[dateAndTime]) {
								learnedStatuses[dateAndTime].push(...timeframeStatus);
							} else {
								learnedStatuses[dateAndTime] = timeframeStatus;
							}
						}
					}
				}

				let sortedLearnedStatuses = Object.keys(learnedStatuses).sort().reverse();

				sortedLearnedStatuses.unshift(sortedLearnedStatuses.pop());

				sortedLearnedStatuses = sortedLearnedStatuses.reduce((acc, cur) => {
					acc[cur] = learnedStatuses[cur];
					return acc;
				}, {});

				let displayStatus = _.sortBy(_.flattenDeep(Object.values(learnedStatuses)), ["statusOrder", "levelOrder"])[0];

				if (displayStatus) {
					let defLabelToAdd = {
						type: "Feature",
						properties: {
							name:
								displayStatus.level === "Partial" && displayStatus.status === "Excluded"
									? "PartiallyExcluded"
									: displayStatus.level === "Partial" && displayStatus.status === "Flagged"
									? "PartiallyFlagged"
									: displayStatus.status,
							field: plot.properties.field,
							type: "plotLabel",
							color:
								displayStatus.status === "Flagged"
									? "#ffa500"
									: displayStatus.status === "Excluded"
									? "#d03f2c"
									: "#ff0000",
							statuses: sortedLearnedStatuses,
							plotName: plot.properties.name ?? ""
						},
						geometry: {
							type: "Point",
							coordinates: paddedLowerLeft
						}
					};
					defCollection.features.push(defLabelToAdd);
				}
			});

			let defId = uuid.v4();
			canvas.addSource(defId, {
				type: "geojson",
				data: defCollection
			});

			canvas.addLayer({
				id: `def-labels`,
				type: "symbol",
				source: defId,
				layout: {
					"icon-image": ["get", "name"],
					"icon-size": ["interpolate", ["linear"], ["zoom"], visibleAtZoom - 5.5, 0, visibleAtZoom, size],
					"icon-allow-overlap": true
				},
				paint: {
					"icon-color": ["get", "color"]
				}
			});
		} else {
			if (canvas.getLayer(`def-labels`)) {
				canvas.removeLayer(`def-labels`);
			}
		}

		if (controlComponentRefs?.defMarkerToggleRef?.current) {
			controlComponentRefs.defMarkerToggleRef.current.setToggleState(!defMarkersToggle);
		}
		defMarkersToggle = !defMarkersToggle;
	}
}

// Event fired when add field button is clicked
export function addField(draw, measureDistanceRef) {
	if (!disableModifying) {
		if (draw.current) {
			if (fieldCombined.length > 0) {
				uncombinePlotsInField(draw, false);
			}

			measureDistanceRef.current.setActive(false);
			draw.current.draw.changeMode("new_field_mode");

			toast.info("Select the lower left position of the field", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	} else {
		toast.error("Cannot modify field while images are being processed", {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		});
	}
}

// Rotate feature right
export function rotateRight(draw, orthoAnalyzedModal, canvas, e) {
	if (draw.current) {
		if (
			draw.current.draw.getSelected().features.length !== 0 &&
			draw.current.draw.getSelected().features[0].properties.type !== "pivot"
		) {
			let featuresToRotate = draw.current.draw.getSelected().features;
			let isFieldSelected = _.map(featuresToRotate, "properties.type").includes("field");
			let nonFieldsToRotate = [];
			let rotateWithPivot = [];
			_.map(featuresToRotate, (feature) => {
				(feature.properties.type === "field" ||
					feature.properties.type === "subsample" ||
					feature.properties.type === "plot") &&
				isFieldSelected
					? rotateWithPivot.push(feature)
					: nonFieldsToRotate.push(feature);
			});

			_.map(rotateWithPivot, (feature) => {
				let fieldId =
					feature.properties.field ??
					_.find(allFields, (f) => _.map(f.properties.plots, "id").includes(feature.properties.plotId)).id;

				let options = {
					pivot: _.find(fieldPivotPoints, { id: fieldId }).pivot.geometry.coordinates
				};
				feature = turf.transformRotate(
					feature,
					(0.0375 / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1),
					options
				);
				draw.current.draw.add(feature);

				updateArea(draw, feature, orthoAnalyzedModal, canvas);
			});

			_.map(nonFieldsToRotate, (feature) => {
				feature = turf.transformRotate(
					feature,
					(0.075 / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1)
				);

				draw.current.draw.add(feature);

				updateArea(draw, feature, orthoAnalyzedModal, canvas);
			});
		} else {
			toast.warn("Please select a feature to rotate", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	}
}

// Rotate feature left
export function rotateLeft(draw, orthoAnalyzedModal, canvas, e) {
	if (draw.current) {
		if (
			draw.current.draw.getSelected().features.length !== 0 &&
			draw.current.draw.getSelected().features[0].properties.type !== "pivot"
		) {
			let featuresToRotate = draw.current.draw.getSelected().features;
			let isFieldSelected = _.map(featuresToRotate, "properties.type").includes("field");
			let nonFieldsToRotate = [];
			let rotateWithPivot = [];
			_.map(featuresToRotate, (feature) => {
				(feature.properties.type === "field" ||
					feature.properties.type === "subsample" ||
					feature.properties.type === "plot") &&
				isFieldSelected
					? rotateWithPivot.push(feature)
					: nonFieldsToRotate.push(feature);
			});

			_.map(rotateWithPivot, (feature) => {
				let fieldId =
					feature.properties.field ??
					_.find(allFields, (f) => _.map(f.properties.plots, "id").includes(feature.properties.plotId)).id;

				let options = {
					pivot: _.find(fieldPivotPoints, { id: fieldId }).pivot.geometry.coordinates
				};
				feature = turf.transformRotate(
					feature,
					(-0.075 / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1),
					options
				);
				draw.current.draw.add(feature);

				updateArea(draw, feature, orthoAnalyzedModal, canvas);
			});

			_.map(nonFieldsToRotate, (feature) => {
				feature = turf.transformRotate(
					feature,
					(-0.075 / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1)
				);

				draw.current.draw.add(feature);

				updateArea(draw, feature, orthoAnalyzedModal, canvas);
			});
		} else {
			toast.warn("Please select a feature to rotate", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	}
}

// Gracefully handles combining plots
export function combine(e) {
	fieldCombined.push(e.createdFeatures[0].id);
	_.map(e.deletedFeatures, ({ properties, id }) => {
		if (!properties.locked) {
			_.remove(allPlots, { id: id });
		}
	});
}

// Gracefully handles uncombining plots
export function uncombine(e, draw, canvas, controlComponentRefs) {
	let createdFields = _.uniqBy(e.createdFeatures, (cf) => cf.properties.field);

	_.map(createdFields, (cf, fieldIndex) => {
		if (!cf.properties.locked) {
			_.map(cf.properties.plots, (plot, index) => {
				plot.properties.isDirty = true;
				plot.geometry.coordinates = e.deletedFeatures[fieldIndex].geometry.coordinates[index + 1]; // Skip the first index as that is the field drawing
				allPlots.push(plot);
			});
		}
	});

	// Delete newly created plots
	draw.current.draw.delete(_.map(e.createdFeatures, "id"));
	refreshToggles(draw, canvas, controlComponentRefs);
}

// Adds labels to fields
export function fieldLabelToggle(canvas, ref, invert = false) {
	if (canvas) {
		if (!fieldLabelsToggle && !canvas.getLayer("field-labels")) {
			let labelCollection = {
				type: "FeatureCollection",
				features: []
			};

			recalculateFieldBoundaries();

			let fieldsToLabel = _.cloneDeep(allFields);
			if (singleFeatureToggle && selectedFieldId) {
				fieldsToLabel = _.filter(fieldsToLabel, { id: selectedFieldId });
			}

			_.map(fieldsToLabel, (field) => {
				let labelToAdd = {
					type: "Feature",
					properties: {
						name: field.properties.name,
						crop: field.properties.crop,
						plantDate:
							field.properties.plantDate && field.properties.plantDate !== -1
								? moment(field.properties.plantDate).format("MM/DD/YYYY")
								: -1,
						harvestDate:
							field.properties.harvestDate && field.properties.harvestDate !== -1
								? moment(field.properties.harvestDate).format("MM/DD/YYYY")
								: -1,
						city: field.properties.city,
						state: field.properties.state,
						ranges: field.properties.ranges,
						columns: field.properties.columns,
						field: field.id,
						type: "fieldLabel"
					},
					geometry: {
						type: "Point",
						coordinates: turf.centroid(field).geometry.coordinates
					}
				};
				labelCollection.features.push(labelToAdd);
			});

			let sourceId = uuid.v4();
			canvas.addSource(sourceId, {
				type: "geojson",
				data: labelCollection
			});

			canvas.addLayer({
				id: "field-labels",
				type: "symbol",
				source: sourceId,
				layout: {
					"text-field": ["get", "name"],
					"text-variable-anchor": ["center"],
					"text-size": 24,
					"text-radial-offset": 0.5,
					"text-justify": "auto"
				},
				paint: {
					"text-color": "#c90a0a",
					"text-halo-color": invert ? "#000000" : "#ffffff",
					"text-halo-width": 0.25
				}
			});

			if (ref) ref.current.setToggleState(true);
		} else {
			if (canvas?.getLayer("field-labels")) {
				canvas.removeLayer("field-labels");

				if (ref) ref.current.setToggleState(false);
			}
		}

		fieldLabelsToggle = !fieldLabelsToggle;
	}
}

// Adds labels to trials
export function trialLabelToggle(canvas, invert = false) {
	if (canvas) {
		if (!trialLabelsToggle && !canvas.getLayer("trial-labels")) {
			let labelCollection = {
				type: "FeatureCollection",
				features: []
			};

			recalculateTrialBoundaries(false);

			let trialsToLabel = _.cloneDeep(allTrials);
			if (singleFeatureToggle && selectedTrialId) {
				trialsToLabel = _.filter(trialsToLabel, { id: selectedTrialId });
			}

			_.map(trialsToLabel, (trial) => {
				let labelToAdd = {
					type: "Feature",
					properties: {
						name: trial.properties.name,
						field: trial.properties.field,
						trial: trial.id,
						type: "trialLabel"
					},
					geometry: {
						type: "Point",
						coordinates: turf.centroid(trial).geometry.coordinates
					}
				};

				labelCollection.features.push(labelToAdd);
			});

			let sourceId = uuid.v4();
			canvas.addSource(sourceId, {
				type: "geojson",
				data: labelCollection
			});

			canvas.addLayer({
				id: "trial-labels",
				type: "symbol",
				source: sourceId,
				layout: {
					"text-field": ["get", "name"],
					"text-variable-anchor": ["center"],
					"text-size": 30,
					"text-radial-offset": 0.5,
					"text-justify": "auto"
				},
				paint: {
					"text-color": "#03fcf8",
					"text-halo-color": invert ? "#000000" : "#ffffff",
					"text-halo-width": 0.55
				}
			});
		} else {
			if (canvas.getLayer("trial-labels")) {
				canvas.removeLayer("trial-labels");
			}
		}

		trialLabelsToggle = !trialLabelsToggle;
	}
}

export async function lockToggle(draw, canvas, dropdownRef, controlComponentRefs) {
	if (allFields && allFields.length > 0) {
		if (!disableModifying) {
			let multipleFieldIds = draw.current.draw.getSelected().features;
			if (multipleFieldIds.length > 0) {
				// Returns list of field ids for selected field drawings
				multipleFieldIds = _.map(
					_.map(
						_.filter(multipleFieldIds, ({ properties }) => {
							return properties.type === "field";
						}),
						"properties"
					),
					"field"
				);
			} else {
				if (!selectedFieldId) {
					selectedFieldId = _.last(allFields).id;
				}

				multipleFieldIds = [selectedFieldId];
			}

			if (fieldCombined.length > 0) {
				uncombinePlotsInField(draw, false);
			}

			// Recreating the field ddl
			let thirdDropdownList = dropdownRef.current.innerRef.current.currentThirdDropdownOptions();
			let modifiedList = _.filter(thirdDropdownList, ({ key }) => {
				if (!multipleFieldIds.includes(key)) return true;
			});

			_.forEach(multipleFieldIds, (fieldId) => {
				let fieldName = null;
				let isFieldLocking = false;

				let plotIds = [];
				_.map(allPlots, (plot) => {
					if (plot.properties.field === fieldId) {
						if (plot.properties.locked) {
							plot.properties.portColor = "#ffb820";
						} else {
							isFieldLocking = true;
							plot.properties.portColor = "#0ac944";
						}
						fieldName = plot.properties.fieldName;
						plot.properties.locked = !plot.properties.locked;

						plotIds.push(plot.id);
					}
				});

				modifiedList.push({
					key: fieldId,
					value: fieldId,
					text: fieldName,
					icon: (
						<>
							<Icon
								style={{ float: "right" }}
								name="trash"
								link
								onClick={(e) => dropdownRef.current.innerRef.current.handleDeleteClick(e, fieldId)}
							/>
							<Icon style={{ float: "right" }} name={isFieldLocking ? "check" : "warning"} />
						</>
					)
				});

				if (isFieldLocking) {
					if (controlComponentRefs.lockToggleRef && controlComponentRefs.lockToggleRef.current) {
						controlComponentRefs.lockToggleRef.current.setLocked(true);
					}

					toast.success("Field " + fieldName + " was locked successfully", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				} else {
					if (controlComponentRefs.lockToggleRef && controlComponentRefs.lockToggleRef.current) {
						controlComponentRefs.lockToggleRef.current.setLocked(false);
					}

					toast.success("Field " + fieldName + " was unlocked successfully", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				}
			});

			dropdownRef.current.innerRef.current.setThirdDropdownOptions(modifiedList);
			refreshToggles(draw, canvas, controlComponentRefs);
		} else {
			toast.error("Cannot modify field while images are being processed", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	}
}

// Nudging plots
export function movePlot(dir, draw, canvas, orthoAnalyzedModal, level, edit, viewer, e) {
	if (draw.current) {
		if (level === "field" && dir === "Shift") {
			let selectedFeatures = draw.current.draw.getSelected().features;
			draw.current.draw.changeMode("simple_select", {
				featureIds: _.map(
					_.filter(selectedFeatures, ({ properties }) => {
						return properties.type !== "field";
					}),
					"id"
				)
			});
		} else if (dir !== "Shift" && level === "flight" && !(edit && viewer)) {
			let collection = draw.current.draw.getSelectedIds();
			let allFeatures = draw.current.draw.getAll();
			let movementMetric = (0.00000055625 / 2 / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1); // Roughly 1/10 of a foot - 1/20 for fine control
			let offsetX = 0,
				offsetY = 0,
				denominator = 0,
				viewportDir = null;
			let viewportBearing = canvas.getBearing();
			if (viewportBearing >= 0 && viewportBearing <= 90) {
				denominator = 90;
				viewportDir = "ne";
				offsetX = movementMetric * (viewportBearing / denominator);
				offsetY = movementMetric - offsetX;
			} else if (viewportBearing >= 90 && viewportBearing <= 180) {
				denominator = 90;
				viewportBearing -= 90;
				viewportDir = "se";
				offsetX = movementMetric * (viewportBearing / denominator);
				offsetY = movementMetric - offsetX;
			} else if (viewportBearing <= 0 && viewportBearing >= -90) {
				denominator = -90;
				viewportDir = "nw";
				offsetX = Math.abs(movementMetric * (viewportBearing / denominator));
				offsetY = movementMetric - offsetX;
			} else if (viewportBearing <= -90 && viewportBearing >= -180) {
				denominator = -90;
				viewportBearing += 90;
				viewportDir = "sw";
				offsetX = Math.abs(movementMetric * (viewportBearing / denominator));
				offsetY = movementMetric - offsetX;
			}

			if (collection.length > 0) {
				collection.forEach((feature) => {
					let indexOfFeatureToUpdate = allFeatures.features.findIndex((filtered) => filtered.id === feature),
						featureToUpdate = allFeatures.features[indexOfFeatureToUpdate];

					if (featureToUpdate.geometry.type === "MultiPolygon") {
						featureToUpdate.geometry.coordinates.forEach((feature) => {
							feature[0].forEach((coordinate) => {
								arrowSwitches(viewportDir, dir, coordinate, offsetX, offsetY);
							});
						});
					} else if (featureToUpdate.geometry.type === "Polygon") {
						featureToUpdate.geometry.coordinates[0].forEach((coordinate) => {
							arrowSwitches(viewportDir, dir, coordinate, offsetX, offsetY);
						});
					} else if (featureToUpdate.geometry.type === "LineString") {
						featureToUpdate.geometry.coordinates.forEach((coordinate) => {
							arrowSwitches(viewportDir, dir, coordinate, offsetX, offsetY);
						});
					} else {
						arrowSwitches(viewportDir, dir, featureToUpdate.geometry.coordinates, offsetX, offsetY);
					}
				});

				draw.current.draw.set(allFeatures);
				updateArea(draw, false, orthoAnalyzedModal, canvas);
			}
		}
	}
}

function arrowSwitches(viewportDir, dir, coordinate, offsetX, offsetY) {
	switch (viewportDir) {
		case "ne":
			switch (dir) {
				case "ArrowUp":
					coordinate[0] += offsetX;
					coordinate[1] += offsetY;
					break;
				case "ArrowDown":
					coordinate[0] -= offsetX;
					coordinate[1] -= offsetY;
					break;
				case "ArrowLeft":
					coordinate[0] -= offsetY;
					coordinate[1] += offsetX;
					break;
				case "ArrowRight":
					coordinate[0] += offsetY;
					coordinate[1] -= offsetX;
					break;
				default:
					break;
			}
			break;
		case "se":
			switch (dir) {
				case "ArrowUp":
					coordinate[0] += offsetY;
					coordinate[1] -= offsetX;
					break;
				case "ArrowDown":
					coordinate[0] -= offsetY;
					coordinate[1] += offsetX;
					break;
				case "ArrowLeft":
					coordinate[0] += offsetX;
					coordinate[1] += offsetY;
					break;
				case "ArrowRight":
					coordinate[0] -= offsetX;
					coordinate[1] -= offsetY;
					break;
				default:
					break;
			}
			break;
		case "nw":
			switch (dir) {
				case "ArrowUp":
					coordinate[0] -= offsetX;
					coordinate[1] += offsetY;
					break;
				case "ArrowDown":
					coordinate[0] += offsetX;
					coordinate[1] -= offsetY;
					break;
				case "ArrowLeft":
					coordinate[0] -= offsetY;
					coordinate[1] -= offsetX;
					break;
				case "ArrowRight":
					coordinate[0] += offsetY;
					coordinate[1] += offsetX;
					break;
				default:
					break;
			}
			break;
		case "sw":
			switch (dir) {
				case "ArrowUp":
					coordinate[0] -= offsetY;
					coordinate[1] -= offsetX;
					break;
				case "ArrowDown":
					coordinate[0] += offsetY;
					coordinate[1] += offsetX;
					break;
				case "ArrowLeft":
					coordinate[0] += offsetX;
					coordinate[1] -= offsetY;
					break;
				case "ArrowRight":
					coordinate[0] -= offsetX;
					coordinate[1] += offsetY;
					break;
				default:
					break;
			}
			break;
		default:
			break;
	}
}

// Click event handler.
export function handleClickEvent(draw, canvas, dropdownRef, componentRefs, level, event) {
	//-- No event on right clicks
	let highestLevelFeatureIsPlot = [];
	let highestLevelFeatureIsSubsample = [];

	let features = draw.current.draw.getSelected().features;

	if (
		event &&
		["direct_select", "simple_select"].includes(draw.current.draw.getMode()) &&
		!_.some(features, (f) => f.properties.meta === "drawn_feature")
	) {
		//-- Get all features at the click location
		let featuresAtClick = canvas.queryRenderedFeatures(event.point, {
			layers: ["gl-draw-polygon-fill-inactive.cold", "gl-draw-polygon-fill-active.hot"]
		});

		let selectedFeatureIds = [];

		if (event.originalEvent.shiftKey) {
			selectedFeatureIds = [..._.map(features, "id")];
		}

		//-- See if a subsample exists at the click location
		let subsampleExists = _.some(_.map(featuresAtClick, "properties"), (f) => f.user_type == "subsample");

		//-- Remove features on hot layer
		//-- This is the layer that has selected features on it
		featuresAtClick = _.filter(featuresAtClick, (f) => !f.source.includes("hot"));

		//-- if subsample doesn't exist, check if plot does
		if (!subsampleExists) {
			highestLevelFeatureIsPlot = _.filter(_.map(featuresAtClick, "properties"), (f) => f.user_type == "plot");

			//-- Remove any subsamples that are selected
			let allPlotIds = _.map(allPlots, "id");
			selectedFeatureIds = _.filter(selectedFeatureIds, (id) => allPlotIds.includes(id));

			selectedFeatureIds = [...selectedFeatureIds, ..._.map(highestLevelFeatureIsPlot, "id")];

			if (highestLevelFeatureIsPlot.length > 0) {
				draw.current.draw.changeMode("simple_select", { featureIds: selectedFeatureIds });
			}
		} else {
			//-- Remove any plots that are selected; only want one type selected
			let allPlotIds = _.map(allPlots, "id");
			selectedFeatureIds = _.filter(selectedFeatureIds, (id) => !allPlotIds.includes(id));

			highestLevelFeatureIsSubsample = _.filter(
				_.map(featuresAtClick, "properties"),
				(f) => f.user_type == "subsample"
			);
			selectedFeatureIds = [...selectedFeatureIds, ..._.map(highestLevelFeatureIsSubsample, "id")];
			draw.current.draw.changeMode("simple_select", { featureIds: selectedFeatureIds });
		}
	}

	let fields = _.filter(features, ({ properties }) => {
		return properties.type === "field";
	});

	if (
		(features.length === 0 ||
			(features[0].geometry.type !== "MultiPolygon" && features[0].geometry.type !== "Point")) &&
		fieldCombined.length !== 0
	) {
		uncombinePlotsInField(draw, true);
	}

	if (
		features.length > 0 &&
		features[0].geometry.type !== "MultiPolygon" &&
		features[0].geometry.type !== "Point" &&
		features[0].properties.type !== "field" &&
		(features[0].properties.type === "plot" || features[0].properties.type === "subsample")
	) {
		componentRefs.flagPlotsRef.current.setDisabled(false);

		if (features[0].properties.type === "plot") {
			componentRefs.flagPlotsRef.current.isSubsample(false);
		} else {
			componentRefs.flagPlotsRef.current.isSubsample(true);
		}
	} else {
		componentRefs.flagPlotsRef.current.setDisabled(true);
	}

	if (
		features.length > 0 &&
		features[0].geometry.type !== "MultiPolygon" &&
		features[0].geometry.type !== "Point" &&
		features[0].properties.type === "field"
	) {
		if (componentRefs.alignPlotsRef.current) {
			componentRefs.alignPlotsRef.current.setDisabled(false);
		}

		if (componentRefs.editFieldModal.current) {
			componentRefs.editFieldModal.current.setDisabled(false);
		}
	} else {
		if (componentRefs.alignPlotsRef.current) {
			componentRefs.alignPlotsRef.current.setDisabled(true);
		}

		if (componentRefs.editFieldModal.current) {
			componentRefs.editFieldModal.current.setDisabled(true);
		}
	}

	// if (draw.current.draw.getMode() === "new_field_mode") {
	// 	checkClickCounter(addFieldModal, addExistingFieldsModal, clientId, getTokenSilently, dispatch, flightId);
	// }

	if (_.findIndex(allRows, { id: draw.current.draw.getSelectedIds()[0] }) !== -1) {
		selectPlotFromRow(draw, draw.current.draw.getSelectedIds()[0]);
	} else {
		if (
			features.length === 1 &&
			features[0].geometry.type === "Point" &&
			features[0].properties.type !== "pivot" &&
			features[0].properties.meta !== "vertex"
		) {
			if (fieldCombined.length > 0) {
				let fieldId = _.find(allLLPoints, { id: draw.current.draw.getSelectedIds()[0] }).properties.field;
				uncombinePlotsInField(draw, false);
				zoomToField(draw, canvas, fieldId, dropdownRef, componentRefs, level);
			} else {
				zoomToField(draw, canvas, undefined, dropdownRef, componentRefs, level);
			}
		}

		if (
			features.length === 1 &&
			features[0].properties.type === "plot" &&
			highestLevelFeatureIsSubsample.length === 0 &&
			rowsToggle
		) {
			let plotId = features[0].id;
			let rowId = _.filter(allRows, ({ properties }) => {
				return properties.plot === plotId;
			})[0].id;

			selectPlotFromRow(draw, rowId);
		}

		// Greater than 1 field means combine multiple fields
		_.map(fields, (f, index) => {
			if (f.geometry.type !== "MultiPolygon" && level === "flight") {
				selectedFieldId = f.id;
				index > 0 ? combinePlotsInField(draw, true) : combinePlotsInField(draw);
			}
		});
	}
}

// Creates new grid after 2 clicks
async function checkClickCounter(
	addFieldModal,
	addExistingFieldsModal,
	clientId,
	getTokenSilently,
	dispatch,
	flightId
) {
	const accessToken = await getTokenSilently();
	dispatch(fieldActions.getUnusedFields(clientId, flightId, accessToken)).then((res) => {
		if (res || res.length > 0) {
			addExistingFieldsModal.current.handleClick();
		} else {
			addFieldModal.current.handleClick();
		}
	});
}

// Zooms to field of given field id
function zoomToField(draw, canvas, fieldId, dropdownRef, componentRefs, level) {
	if (fieldId === undefined) {
		let indexOfLLPoint = _.findIndex(allLLPoints, {
			id: draw.current.draw.getSelectedIds()[0]
		});

		if (_.map(allLLPoints, "properties.field") !== _.map(allFields, "id")) {
			lowerLeftToggle(draw); // resyncs ll points with field ids
			if (!llToggle) {
				// make sure ll points are on if not
				lowerLeftToggle(draw);
			}
		}

		selectedFieldId = allLLPoints[indexOfLLPoint].properties.field;
	} else {
		selectedFieldId = fieldId;
	}

	if (dropdownRef && level === "flight") {
		dropdownRef.current.innerRef.current.setThirdDropdownId(selectedFieldId);
	}

	toggleSingleFieldTrial(draw, canvas, "field", componentRefs);
	fitToHeight(canvas, "field", true);

	if (componentRefs.alignPlotsRef && componentRefs.alignPlotsRef.current) {
		componentRefs.alignPlotsRef.current.setDisabled(true);
	}

	if (componentRefs.lockToggleRef && componentRefs.lockToggleRef.current) {
		let locked = _.find(allFields, { id: selectedFieldId }).properties.locked;
		componentRefs.lockToggleRef.current.setLocked(locked);
	}
}

// Called every update event. Updates plots if need to
function updateArea(draw, feature, modal, canvas) {
	let features = feature ? [feature] : draw.current.draw.getSelected().features;
	if (features?.length > 0) {
		if (features[0].properties.type !== "pivot") {
			if (features.some((x) => x.properties.locked)) {
				toast.warn("Your changes to this feature will not be saved while it's locked", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				});
				return false;
			}

			let plotHasAnalysisData = false;
			if (features[0].properties.type === "field" || features[0].properties.type === "trial") {
				if (features[0].properties.plots.some((x) => x.properties.analyzed)) {
					plotHasAnalysisData = true;
					modal.current.updateFieldId(features[0].properties.field);
					modal.current.updateActiveElement(document.activeElement);
					modal.current.updateDraw(draw);
					modal.current.updateFeature(feature);
					modal.current.updateCanvas(canvas);
					modal.current.openModal();
				}
			} else if (features[0].properties.type === "plot") {
				if (features.some((x) => x.properties.analyzed)) {
					plotHasAnalysisData = true;
					modal.current.updateFieldId(features[0].properties.field);
					modal.current.updateActiveElement(document.activeElement);
					modal.current.updateDraw(draw);
					modal.current.updateFeature(feature);
					modal.current.updateCanvas(canvas);
					modal.current.openModal();
				}
			}
			if (!plotHasAnalysisData) {
				let plots = [];
				if (features[0].properties.type === "field" || features[0].properties.type === "trial") {
					plots = features[0].properties.plots;
					_.forEach(plots, (plot) => {
						plot.properties.isDirty = true;
					});
					draw.current.draw.setFeatureProperty(features[0].id, "plots", plots);
				} else {
					_.forEach(features, ({ id, properties, geometry }) => {
						if (properties.type === "plot") {
							let allFeatures = draw.current.draw.getAll().features;
							let fieldFeature = _.find(
								allFeatures,
								(af) => af.properties.type === "field" && af.id === properties.field
							);

							//-- Check if the field is found in the mapbox feature list
							//-- If found, update mapbox metadata
							if (fieldFeature) {
								let fieldToUpdate =
									allFields[_.findIndex(allFields, (f) => _.map(f.properties.plots, "id").includes(id))];
								let plotIndex = _.findIndex(fieldToUpdate.properties.plots, { id: id });

								let plotsFeature = fieldFeature.properties.plots;
								plotsFeature[plotIndex].geometry = geometry;

								draw.current.draw.setFeatureProperty(fieldFeature.id, "plots", plotsFeature);
								fieldToUpdate.properties.plots[plotIndex].geometry = geometry;
							}

							allPlots[_.findIndex(allPlots, { id: id })].geometry = geometry;
							allPlots[_.findIndex(allPlots, { id: id })].properties.isDirty = true;
							delete allPlots[_.findIndex(allPlots, { id: id })].properties.alignColor;
						}
					});
				}
			}
		} else {
			let index = _.findIndex(_.map(fieldPivotPoints, "pivot"), { id: features[0].id });
			fieldPivotPoints[index].pivot = features[0];
		}
	}
}

export function deleteAnaylzedData(fieldId, draw, feature) {
	_.forEach(allPlots, (plot) => {
		if (plot.properties.field === fieldId) {
			plot.properties.analyzed = false;
		}
	});
	_.forEach(allFields, (field) => {
		if (field.id === fieldId) {
			_.forEach(field.properties.plots, (plot) => {
				plot.properties.analyzed = false;
				plot.properties.isDirty = true;
			});
		}
	});

	let features = feature ? [feature] : draw.current.draw.getSelected().features;
	let plots = [];
	_.forEach(features, (feature) => {
		if (feature.properties.type === "field" || feature.properties.type === "trial") {
			_.forEach(feature.properties.plots, (plot) => {
				plot.properties.analyzed = false;
				plot.properties.isDirty = true;
				plots.push(plot);
			});
		} else if (feature.properties.type === "plot") {
			feature.properties.analyzed = false;
			feature.properties.isDirty = true;
			plots.push(feature);
		}
	});

	let selectedIds = draw.current.draw.getSelectedIds();
	plotToggle(draw);
	plotToggle(draw);
	fieldBoundaryToggle(draw);
	fieldBoundaryToggle(draw);
	draw.current.draw.setFeatureProperty(features[0].id, "plots", plots);

	draw.current.draw.changeMode("simple_select", { featureIds: selectedIds });
}

// Draws a new grid based on the lower left and upper left points
function drawGrid(evt, draw, canvas, dropdownRef, componentRefs) {
	let rows = fieldRanges,
		cols = fieldCols,
		plotHeight = fieldRangeLength,
		plotWidth = fieldColWidth;

	const actualWidth = plotWidth;

	//Create upper left point if upper left is not provided from field form
	if (lowerLeftLat && lowerLeftLong && !upperLeftLat && !upperLeftLong) {
		let buffer = (fieldRanges * fieldRangeLength) / 3.281 / 1000; // Convert ft to km
		let upperLeftCoordinates = turf.destination([lowerLeftLong, lowerLeftLat], buffer, degreeHeading).geometry
			.coordinates;
		upperLeftLong = upperLeftCoordinates[0];
		upperLeftLat = upperLeftCoordinates[1];
	}

	let lineString = evt,
		llXCoord = lowerLeftLong && lowerLeftLong != "" ? lowerLeftLong : lineString.geometry.coordinates[0][0],
		llYCoord = lowerLeftLat && lowerLeftLat != "" ? lowerLeftLat : lineString.geometry.coordinates[0][1],
		ulXCoord = upperLeftLong && upperLeftLong != "" ? upperLeftLong : lineString.geometry.coordinates[1][0],
		ulYCoord = upperLeftLat && upperLeftLat != "" ? upperLeftLat : lineString.geometry.coordinates[1][1];

	lowerLeftLat = null;
	lowerLeftLong = null;
	upperLeftLat = null;
	upperLeftLong = null;

	let initialBearing = turf.bearing([llXCoord, llYCoord], [ulXCoord, ulYCoord]);

	let perpendicularBearing = initialBearing + 90;
	let oppositePerpBearing = initialBearing - 90;

	// Push the initial points half of the field row spacing, convert to feet, convert to km
	let llOffset = turf.destination(
		turf.point([llXCoord, llYCoord]),
		fieldRowSpacing / 2 / 12 / 3.281 / 1000,
		oppositePerpBearing
	);
	llXCoord = llOffset.geometry.coordinates[0];
	llYCoord = llOffset.geometry.coordinates[1];

	let ulOffset = turf.destination(
		turf.point([ulXCoord, ulYCoord]),
		fieldRowSpacing / 2 / 12 / 3.281 / 1000,
		oppositePerpBearing
	);
	ulXCoord = ulOffset.geometry.coordinates[0];
	ulYCoord = ulOffset.geometry.coordinates[1];

	let newFeatureCollection = {
		type: "FeatureCollection",
		features: []
	};

	let xCoordinateToRotateAround = llXCoord,
		yCoordinateToRotateAround = llYCoord,
		previousRowXCoordinate = llXCoord,
		previousRowYCoordinate = llYCoord;

	_.times(rows, (rowIndex) => {
		let firstColumnInRow = true;

		_.times(cols, (colIndex) => {
			let plot = _.find(plotsToCreate, {
				range: rowIndex + 1,
				column: colIndex + 1
			});

			let newPolygon = {
				id: plot.id,
				type: "Feature",
				properties: {
					type: "plot",
					field: fieldId,
					fieldName: fieldName,
					trial: plot.trialId === "00000000-0000-0000-0000-000000000000" ? null : plot.trialId,
					trialName: plot.trialName,
					companyName: plot.companyName,
					fieldRangeLength: fieldRangeLength,
					fieldColWidth: actualWidth,
					fieldRowCount: fieldRowCount,
					fieldRowSpacing: fieldRowSpacing,
					quantifiedHeight: quantifiedHeight,
					quantifiedWidth: quantifiedWidth,
					locked: false,
					portColor: "#ffb820",
					new: true,
					isDirty: true,
					analyzed: false,
					crop: crop,
					plantDate: plantDate,
					harvestDate: harvestDate,
					city: city,
					state: state,
					ranges: rows,
					columns: cols,
					range: rowIndex + 1,
					column: colIndex + 1,
					scaleFactor: 1,
					alleyLength: alleyLength,
					fieldPlanterOffset: planterOffset
				},
				geometry: {
					type: "Polygon",
					coordinates: []
				}
			};

			let lowerLeft = 0,
				upperLeft = 0,
				upperRight = 0,
				lowerRight = 0;

			if (firstColumnInRow) {
				xCoordinateToRotateAround = previousRowXCoordinate;
				yCoordinateToRotateAround = previousRowYCoordinate;
			}

			lowerLeft = [xCoordinateToRotateAround, yCoordinateToRotateAround];

			//Need to calculate the height and width change based on the radius of the earth in meters
			//6378137 = Radius of Earth in meters
			//3.281 = Conversion from feet to meters
			//https://stackoverflow.com/questions/2839533/adding-distance-to-a-gps-coordinate
			upperLeft = turf.destination(lowerLeft, plotHeight / 3.281 / 1000, initialBearing).geometry.coordinates;

			if (firstColumnInRow) {
				previousRowXCoordinate = upperLeft[0];
				previousRowYCoordinate = upperLeft[1];
				firstColumnInRow = false;
			}

			upperRight = turf.destination(upperLeft, actualWidth / 3.281 / 1000, perpendicularBearing).geometry.coordinates;

			lowerRight = turf.destination(lowerLeft, actualWidth / 3.281 / 1000, perpendicularBearing).geometry.coordinates;

			xCoordinateToRotateAround = lowerRight[0];
			yCoordinateToRotateAround = lowerRight[1];

			let newCoords = [lowerLeft, upperLeft, upperRight, lowerRight, lowerLeft];

			let existingIndex = _.findIndex(allPlots, { id: plot.id });
			if (existingIndex !== -1) {
				_.pullAt(allPlots, existingIndex);
			}

			newPolygon.geometry.coordinates.push(newCoords);
			newFeatureCollection.features.push(newPolygon);
			allPlots.push(newPolygon);

			let initialPlot = _.cloneDeep(newPolygon);
			initialPlots.push(initialPlot);
		});
	});

	let thirdDropdownList = dropdownRef.current.innerRef.current.currentThirdDropdownOptions();

	if (newFeatureCollection.features && newFeatureCollection.features.length > 0) {
		let modifiedList = thirdDropdownList.concat({
			key: newFeatureCollection.features[0].properties.field,
			value: newFeatureCollection.features[0].properties.field,
			text: newFeatureCollection.features[0].properties.fieldName,
			icon: (
				<>
					<Icon
						style={{ float: "right" }}
						name="trash"
						link
						onClick={(e) =>
							dropdownRef.current.innerRef.current.handleDeleteClick(
								e,
								newFeatureCollection.features[0].properties.field
							)
						}
					/>
					<Icon style={{ float: "right" }} name="warning" />
				</>
			)
		});

		dropdownRef.current.innerRef.current.setThirdDropdownOptions(modifiedList);
	}

	if (
		componentRefs.llToggleRef &&
		componentRefs.llToggleRef.current &&
		componentRefs.llToggleRef.current.checkDisabled
	) {
		_.map(componentRefs, (ref) => {
			if (
				ref &&
				ref.current &&
				ref != componentRefs.alignPlotsRef &&
				ref != componentRefs.quantifiedRegionToggleRef &&
				ref != componentRefs.editFieldModal &&
				ref != componentRefs.heatmapOverlayLabelsRef
			) {
				ref.current.setDisabled(false);
			}
		});
	}

	draw.current.draw.delete(lineString.id);

	recalculateFieldBoundaries();
	refreshToggles(draw, canvas, componentRefs);

	if (allFields && allFields.length > 0) {
		zoomToField(draw, canvas, _.last(allFields).id, dropdownRef, componentRefs, "flight");
	}

	// Reset field measurements - used for a check in checkValidField
	fieldRanges = -1;
	fieldCols = -1;
	fieldColWidth = -1;
	fieldRangeLength = -1;
	plotsToCreate = [];
	fieldId = "";
	crop = "";
	plantDate = -1;
	harvestDate = -1;
	city = "";
	state = "";
	alleyLength = -1;
	planterOffset = -1;
	lowerLeftLat = null;
	lowerLeftLong = null;
	upperLeftLat = null;
	upperLeftLong = null;
	degreeHeading = null;
	quantifiedHeight = null;
	quantifiedWidth = null;
}

// Checks if we need to re-draw any toggleable functionality
export function refreshToggles(draw, canvas, controlComponentRefs, orthoId = null) {
	if (fieldToggle) {
		fieldBoundaryToggle(draw);
		fieldBoundaryToggle(draw);
	}

	if (pivotToggle) {
		fieldPivotToggle(draw);
		fieldPivotToggle(draw);
	}

	if (fieldLabelsToggle) {
		fieldLabelToggle(canvas);
		fieldLabelToggle(canvas);
	}

	if (plotBoundaryToggle) {
		plotToggle(draw);
		plotToggle(draw);
	}

	if (rowsToggle) {
		rowToggle(draw);
		rowToggle(draw);
	}

	if (trialsToggle) {
		trialToggle(draw);
		trialToggle(draw);
	}

	if (llToggle) {
		lowerLeftToggle(draw);
		lowerLeftToggle(draw);
	}

	if (trialLabelsToggle) {
		trialLabelToggle(canvas);
		trialLabelToggle(canvas);
	}

	if (plotLabelsToggle) {
		plotLabelToggle(draw, canvas, controlComponentRefs);
		plotLabelToggle(draw, canvas, controlComponentRefs);
	}

	if (defMarkersToggle) {
		defMarkerToggle(canvas, controlComponentRefs);
		defMarkerToggle(canvas, controlComponentRefs);
	}

	if (pcToggle || pcQrToggle || rcQrToggle || brQrToggle || frQrToggle || scQrToggle) {
		quantifiedRegionToggle(draw, true);
	}

	if (heatmapOverlayShadingToggled) {
		heatmapOverlayShading(draw, canvas, controlComponentRefs, orthoId);
		heatmapOverlayShading(draw, canvas, controlComponentRefs, orthoId);
	}

	if (controlComponentRefs.heatmapOverlayLabelsRef?.current?.checkToggleState) {
		heatmapOverlayLabels(draw, canvas, controlComponentRefs, orthoId);
		heatmapOverlayLabels(draw, canvas, controlComponentRefs, orthoId);
	}
}

export function resetControls() {
	allPlots = [];
	allFields = [];
	allTrials = [];
	allLLPoints = [];
	allRows = [];
	llToggle = true;
	fieldToggle = true;
	plotBoundaryToggle = true;
	rowsToggle = false;
	trialsToggle = false;
	fieldLabelsToggle = false;
	trialLabelsToggle = false;
	plotLabelsToggle = false;
	selectedFieldId = null;
	selectedTrialId = null;
	disableModifying = false;
	initialPlots = [];
	fieldCombined = [];
	pivotToggle = false;
	fieldPivotPoints = [];
	singleFeatureToggle = false;
	trialPlots = [];
	movingFieldProperties = [];
	nonPlotDrawingsDeleted = [];
	analysisOptions = [];
	subsamples = [];
	subsamplesToAdd = [];
	quantifiedRegionOptions = [];
	heatmapOverlayShadingToggled = false;
	pcToggle = false;
	pcQrToggle = false;
	rcQrToggle = false;
	brQrToggle = false;
	frQrToggle = false;
	scQrToggle = false;
	tilesetIds = [];
}

let fieldRanges = -1;
let fieldCols = -1;
let fieldColWidth = -1;
let fieldRangeLength = -1;
let fieldRowCount = -1;
let fieldRowSpacing = -1;
let fieldName = "";
let plotsToCreate = [];
let fieldId = "";
let crop = "";
let plantDate = -1;
let harvestDate = -1;
let city = "";
let state = "";
let alleyLength = -1;
let planterOffset = -1;
let lowerLeftLat = null;
let lowerLeftLong = null;
let upperLeftLat = null;
let upperLeftLong = null;
let degreeHeading = null;
let quantifiedHeight = null;
let quantifiedWidth = null;
let newFieldLine = null;

// Sets up creation of grid
export function handleFieldAdd(plots, field, newFieldId, quantifiedRegions, draw, canvas, dropdownRef, componentRefs) {
	fieldRanges = parseInt(field.numOfRanges);
	fieldCols = parseInt(field.numOfColumns);
	fieldColWidth = field.columnWidth;
	fieldRangeLength = field.rangeLength;
	fieldRowCount = field.rowsPerPlot;
	fieldRowSpacing = field.rowSpacing;
	quantifiedHeight = field.quantifiedLength;
	quantifiedWidth = field.quantifiedWidth;
	fieldName = field.name;
	plotsToCreate = plots;
	fieldId = newFieldId;
	crop = field.crop;
	plantDate = field.plantDate;
	harvestDate = field.harvestDate;
	city = field.city;
	state = field.state;
	alleyLength = field.alleyLength;
	planterOffset = field.planterOffset;
	lowerLeftLat = field.llLat;
	lowerLeftLong = field.llLong;
	upperLeftLat = field.ulLat;
	upperLeftLong = field.ulLong;
	degreeHeading = field.degreeHeading;

	checkValidField(newFieldLine, draw, canvas, dropdownRef, componentRefs);
}

export function checkValidField(e, draw, canvas, dropdownRef, componentRefs) {
	if (draw.current.draw.getMode() === "simple_select") {
		if (
			fieldRanges !== -1 ||
			fieldCols !== -1 ||
			fieldColWidth !== -1 ||
			fieldRangeLength !== -1 ||
			fieldName !== "" ||
			plotsToCreate.length === 0 ||
			fieldId !== ""
		) {
			drawGrid(e, draw, canvas, dropdownRef, componentRefs);
		} else {
			draw.current.draw.delete(e.id);
		}
	}
}

// On initial page load, retrieve the plot data for the flight viewer.
export async function addExistingPlotsInFlight(
	flightId,
	getTokenSilently,
	dispatch,
	draw,
	canvas,
	componentRefs,
	clientId
) {
	const accessToken = await getTokenSilently();
	dispatch(flightsPlotsActions.getExistingFlightsPlotsInFlight(flightId, clientId, accessToken)).then((res) => {
		if (res.plots.length > 0) {
			_.map(componentRefs, (ref) => {
				if (
					ref &&
					ref.current &&
					ref != componentRefs.flagPlotsRef &&
					ref != componentRefs.alignPlotsRef &&
					ref != componentRefs.quantifiedRegionToggleRef &&
					ref != componentRefs.editFieldModal
				) {
					ref.current.setDisabled(false);

					if (ref == componentRefs.lockToggleRef) {
						ref.current.setLocked(true);
					}
				}
			});

			markedPlots = res.markedPlots;
			markedSubsamples = res.markedQuantifiedRegions;
			markedPlotOptions = res.markedPlotOptions;
			annotationClassOptions = res.annotationClassOptions;

			_.map(res.plots, (plot) => {
				let plotToAdd = {
					id: plot.plotId,
					type: "Feature",
					properties: {
						type: "plot",
						field: plot.fieldId,
						fieldName: plot.fieldName,
						companyName: plot.companyName,
						trialName: `${plot.companyName} - ${plot.trialName}`,
						trial: plot.trialId,
						fieldRangeLength: plot.plotRangeLength,
						fieldColWidth: plot.plotColumnWidth,
						fieldRowCount: plot.fieldRowsPerPlot,
						fieldRowSpacing: plot.fieldRowSpacing,
						fieldRowsPerPlot: plot.rowsPerPlot,
						fieldPassesPerPlot: plot.passesPerPlot,
						fieldPlanterOffset: plot.planterOffset,
						locked: true,
						portColor: "#0ac944",
						new: false,
						analyzed: plot.analyzed,
						crop: plot.crop,
						plantDate: plot.plantDate !== "0001-01-01T00:00:00" ? plot.plantDate : -1,
						harvestDate: plot.harvestDate !== "0001-01-01T00:00:00" ? plot.harvestDate : -1,
						city: plot.city,
						state: plot.state,
						ranges: plot.ranges,
						columns: plot.columns,
						range: plot.range,
						column: plot.column,
						name: plot.plotName,
						quantifiedHeight: plot.quantifiedHeight,
						quantifiedWidth: plot.quantifiedWidth,
						scaleFactor: plot.scaleFactor,
						treatment: plot.treatment,
						flightDate: plot.flightDate
					},
					geometry: {
						type: "Polygon",
						coordinates: [
							[
								[plot.llLong, plot.llLat],
								[plot.ulLong, plot.ulLat],
								[plot.urLong, plot.urLat],
								[plot.lrLong, plot.lrLat],
								[plot.llLong, plot.llLat]
							]
						]
					}
				};

				let existingIndex = _.findIndex(allPlots, { id: plot.plotId });
				if (existingIndex !== -1) {
					_.pullAt(allPlots, existingIndex);
				}

				allPlots.push(plotToAdd);
				initialPlots.push(_.cloneDeep(plotToAdd));
			});

			currentFlightId = flightId;
			refreshToggles(draw, canvas, componentRefs);
		}
	});
}

export async function addExistingPlotsInField(
	flightId,
	fieldId,
	getTokenSilently,
	dispatch,
	draw,
	canvas,
	align,
	readjust = true,
	first,
	componentRefs,
	clientId,
	orthoId = null,
	fromFlightDropdown = false,
	defaultViewerSettingCallback = null
) {
	const accessToken = await getTokenSilently();
	dispatch(flightsPlotsActions.getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken)).then((res) => {
		let newFeatureCollection = {
			type: "FeatureCollection",
			features: []
		};

		markedPlots = res.markedPlots;
		markedSubsamples = res.markedQuantifiedRegions;
		markedPlotOptions = res.markedPlotOptions;
		annotationClassOptions = res.annotationClassOptions;
		quantifiedRegionOptions = res.quantifiedRegionOptions;
		defaultViewerSetting = res.defaultViewerSetting;

		_.map(res.plots, (plot) => {
			let plotToAdd = {
				id: plot.plotId,
				type: "Feature",
				properties: {
					type: "plot",
					field: plot.fieldId,
					fieldName: plot.fieldName,
					trial: plot.trialId,
					trialName: `${plot.companyName} - ${plot.trialName}`,
					fieldRangeLength: plot.plotRangeLength,
					fieldColWidth: plot.plotColumnWidth,
					fieldRowCount: plot.fieldRowsPerPlot,
					fieldRowSpacing: plot.fieldRowSpacing,
					fieldRowsPerPlot: plot.rowsPerPlot,
					fieldPassesPerPlot: plot.passesPerPlot,
					fieldPlanterOffset: plot.planterOffset,
					locked: align,
					portColor: align ? "#0ac944" : "#ffb820",
					new: true,
					isDirty: true,
					crop: plot.crop,
					plantDate: plot.plantDate !== "0001-01-01T00:00:00" ? plot.plantDate : -1,
					harvestDate: plot.harvestDate !== "0001-01-01T00:00:00" ? plot.harvestDate : -1,
					city: plot.city,
					state: plot.state,
					ranges: plot.ranges,
					columns: plot.columns,
					range: plot.range,
					column: plot.column,
					name: plot.plotName,
					quantifiedHeight: plot.quantifiedHeight,
					quantifiedWidth: plot.quantifiedWidth,
					scaleFactor: plot.scaleFactor,
					treatment: plot.treatment,
					flightDate: plot.flightDate
				},
				geometry: {
					type: "Polygon",
					coordinates: [
						[
							[plot.llLong, plot.llLat],
							[plot.ulLong, plot.ulLat],
							[plot.urLong, plot.urLat],
							[plot.lrLong, plot.lrLat],
							[plot.llLong, plot.llLat]
						]
					]
				}
			};

			let existingIndex = _.findIndex(allPlots, { id: plot.plotId });
			if (existingIndex !== -1) {
				_.pullAt(allPlots, existingIndex);
			}

			newFeatureCollection.features.push(plotToAdd);
			allPlots.push(plotToAdd);
			initialPlots.push(_.cloneDeep(plotToAdd));
		});

		//-- Field was added through dropdown list, add subsample dimensions to subsamplesToAdd
		if (!fromFlightDropdown) {
			setupQuantifiedRegions(res.quantifiedRegions, componentRefs);
		}

		if (analysisOptions?.length === 0) {
			setupAnalysisOptions(res.analysisOptions, componentRefs, res.defaultViewerSetting);
		}

		selectedFieldId = fieldId;
		currentFlightId = flightId;
		refreshToggles(draw, canvas, componentRefs, orthoId);

		if (readjust || first) {
			setViewerOrientation(canvas, "field", fromFlightDropdown);
		}

		// On the first time this function runs, tell the viewer page to set the controls to their defaults.
		if (first) {
			if (res.defaultViewerSetting !== null) {
				heatmapOverlayShadingToggled = res.defaultViewerSetting.toggleHeatmapData;
				if (res.defaultViewerSetting.toggleHeatmapData) {
					componentRefs.heatmapOverlayLabelsRef.current.setDisabled(
						!componentRefs.heatmapOverlayLabelsRef.current.checkDisabled
					);
				}
			}
			defaultViewerSettingCallback();
		}

		if (componentRefs.alignPlotsRef?.current) {
			componentRefs.alignPlotsRef.current.setDisabled(true);
		}

		if (componentRefs.lockToggleRef?.current) {
			let locked = _.find(allFields, { id: selectedFieldId }).properties.locked;
			componentRefs.lockToggleRef.current.setLocked(locked);
		}

		if (componentRefs.llToggleRef?.current?.checkDisabled) {
			_.map(componentRefs, (ref) => {
				if (
					ref == componentRefs.flagPlotsRef ||
					(ref == componentRefs.heatmapOverlayLabelsRef && componentRefs.heatmapOverlayLabelsRef?.current)
				) {
					ref.current.setDisabled(true);
				} else if (ref?.current) {
					ref.current.setDisabled(false);
				}
			});
		}
	});
}

export async function addExistingPlotsInTrial(
	flightId,
	trialId,
	getTokenSilently,
	dispatch,
	draw,
	canvas,
	align,
	first,
	clientId,
	controlComponentRefs,
	orthoId,
	defaultViewerSettingCallback = null
) {
	const accessToken = await getTokenSilently();
	dispatch(flightsPlotsActions.getExistingFlightsPlotsInTrial(flightId, trialId, clientId, accessToken)).then((res) => {
		let newFeatureCollection = {
			type: "FeatureCollection",
			features: []
		};

		markedPlots = res.markedPlots;
		markedSubsamples = res.markedQuantifiedRegions;
		markedPlotOptions = res.markedPlotOptions;
		annotationClassOptions = res.annotationClassOptions;
		quantifiedRegionOptions = res.quantifiedRegionOptions;
		defaultViewerSetting = res.defaultViewerSetting;

		_.map(res.plots, (plot) => {
			let plotToAdd = {
				id: plot.plotId,
				type: "Feature",
				properties: {
					type: "plot",
					field: plot.fieldId,
					fieldName: plot.fieldName,
					trial: plot.trialId,
					trialName: `${plot.companyName} - ${plot.trialName}`,
					companyName: plot.companyName,
					fieldRangeLength: plot.plotRangeLength,
					fieldColWidth: plot.plotColumnWidth,
					fieldRowCount: plot.fieldRowsPerPlot,
					fieldRowSpacing: plot.fieldRowSpacing,
					fieldRowsPerPlot: plot.rowsPerPlot,
					fieldPassesPerPlot: plot.passesPerPlot,
					fieldPlanterOffset: plot.planterOffset,
					locked: align,
					portColor: align ? "#0ac944" : "#ffb820",
					new: true,
					isDirty: true,
					crop: plot.crop,
					plantDate: plot.plantDate !== "0001-01-01T00:00:00" ? plot.plantDate : -1,
					harvestDate: plot.harvestDate !== "0001-01-01T00:00:00" ? plot.harvestDate : -1,
					city: plot.city,
					state: plot.state,
					ranges: plot.ranges,
					columns: plot.columns,
					range: plot.range,
					column: plot.column,
					quantifiedHeight: plot.quantifiedHeight,
					quantifiedWidth: plot.quantifiedWidth,
					name: plot.plotName,
					scaleFactor: plot.scaleFactor,
					treatment: plot.treatment,
					replicate: plot.replicate,
					flightDate: plot.flightDate
				},
				geometry: {
					type: "Polygon",
					coordinates: [
						[
							[plot.llLong, plot.llLat],
							[plot.ulLong, plot.ulLat],
							[plot.urLong, plot.urLat],
							[plot.lrLong, plot.lrLat],
							[plot.llLong, plot.llLat]
						]
					]
				}
			};

			let existingIndex = _.findIndex(allPlots, { id: plot.plotId });
			if (existingIndex !== -1) {
				_.pullAt(allPlots, existingIndex);
			}

			newFeatureCollection.features.push(plotToAdd);
			allPlots.push(plotToAdd);
			initialPlots.push(_.cloneDeep(plotToAdd));
		});

		setupQuantifiedRegions(res.quantifiedRegions, controlComponentRefs);

		if (analysisOptions?.length === 0) {
			setupAnalysisOptions(res.analysisOptions, controlComponentRefs, res.defaultViewerSetting);
		}

		if (first) {
			recalculateTrialBoundaries(false);
			setViewerOrientation(canvas, "trial");
		}

		// On the first time this function runs, tell the viewer page to set the controls to their defaults.
		if (first) {
			if (res.defaultViewerSetting !== null) {
				heatmapOverlayShadingToggled = res.defaultViewerSetting.toggleHeatmapData;
				if (res.defaultViewerSetting.toggleHeatmapData) {
					controlComponentRefs.heatmapOverlayLabelsRef.current.setDisabled(
						!controlComponentRefs.heatmapOverlayLabelsRef.current.checkDisabled
					);
				}
			}
			defaultViewerSettingCallback();
		}

		currentFlightId = flightId;
		refreshToggles(draw, canvas, controlComponentRefs, orthoId);
	});
}

function setupAnalysisOptions(analysisOpts, componentRefs, defaultViewerSetting) {
	analysisOptions = analysisOpts;

	let opts = [];
	if (analysisOptions?.analysisOptions?.length > 0) {
		analysisOptions.analysisOptions = _.sortBy(
			analysisOptions.analysisOptions,
			[(opt) => opt.order || Infinity, "analysisName"],
			["desc"]
		);

		opts.push({ key: "a-divider", value: "a-divider", text: "Timecourse Data", disabled: true });
		opts = [
			...opts,
			..._.map(analysisOptions.analysisOptions, ({ analysisId, analysisName, analysisTypeId }) => {
				return {
					key: `${analysisId}/${analysisTypeId}`,
					value: `${analysisId}/${analysisTypeId}`,
					text: analysisName
				};
			})
		];
	}

	if (analysisOptions?.curveModelAnalysisOptions?.length > 0) {
		opts.push({ key: "cm-divider", value: "cm-divider", text: "Digital Phenotypes", disabled: true });
		opts = [
			...opts,
			..._.map(
				_.sortBy(
					_.filter(analysisOptions.curveModelAnalysisOptions, (cmao) => cmao.displayName !== null),
					[(opt) => opt.order || Infinity, "curveModelAnalysisAbbreviation"],
					["desc"]
				),
				({
					analysisAbbreviation,
					analysisId,
					curveModelAnalysisAbbreviation,
					curveModelAnalysisId,
					displayName,
					analysisTypeId
				}) => {
					return {
						key: `${analysisId}/${curveModelAnalysisId}/${analysisTypeId}`,
						value: `${analysisId}/${curveModelAnalysisId}/${analysisTypeId}`,
						text: `${displayName} (${curveModelAnalysisAbbreviation}_${analysisAbbreviation})`
					};
				}
			)
		];
	}

	if (analysisOptions?.groundDataOptions?.length > 0) {
		opts.push({ key: "gd-divider", value: "gd-divider", text: "Ground Data", disabled: true });
		opts = [
			...opts,
			..._.map(analysisOptions.groundDataOptions, ({ assessmentId, assessmentName }) => {
				return {
					key: assessmentId,
					value: assessmentId,
					text: assessmentName
				};
			})
		];
	}

	let selectedAnalysisId = _.find(opts, { text: "Vegetation (NDVI)" })?.key ?? opts[1]?.key;

	if (componentRefs?.heatmapTimelineRef?.current) {
		componentRefs.heatmapTimelineRef.current.setAnalysisOptions(opts);
		if (defaultViewerSetting === null) {
			componentRefs.heatmapTimelineRef.current.setSelectedAnalysisId(selectedAnalysisId);
		}
	}
}

export async function redrawPlots(
	flightId,
	fieldId,
	trialId,
	sensorChanged,
	res,
	orthoId,
	getTokenSilently,
	dispatch,
	draw,
	canvas,
	align,
	first,
	componentRefs,
	clientId,
	defaultViewerSettingCallback = null
) {
	if (sensorChanged) {
		allPlots = [];
		initialPlots = [];

		refreshToggles(draw, canvas, componentRefs, orthoId);
	}

	if (fieldId) {
		addExistingPlotsInField(
			flightId,
			fieldId,
			getTokenSilently,
			dispatch,
			draw,
			canvas,
			align,
			false,
			first,
			componentRefs,
			clientId,
			orthoId,
			false,
			defaultViewerSettingCallback
		);
	} else if (trialId) {
		addExistingPlotsInTrial(
			flightId,
			trialId,
			getTokenSilently,
			dispatch,
			draw,
			canvas,
			align,
			first,
			clientId,
			componentRefs,
			orthoId,
			defaultViewerSettingCallback
		);
	}

	buildMapImage(res, orthoId, canvas, align, componentRefs);
}

export async function handleFieldDropdownChange(
	draw,
	canvas,
	align,
	field,
	flightId,
	getTokenSilently,
	dispatch,
	center,
	orthoImageTypeId,
	clientId,
	componentRefs
) {
	if (fieldCombined.length > 0) {
		uncombinePlotsInField(draw, false);
	}

	if (componentRefs.alignPlotsRef && componentRefs.alignPlotsRef.current) {
		componentRefs.alignPlotsRef.current.setDisabled(true);
	}

	if (componentRefs.editFieldModal?.current) {
		componentRefs.editFieldModal.current.setDisabled(true);
	}

	if (field.key === "all") {
		resetOrientation(canvas, center, "flight", draw, null, componentRefs);
	} else {
		const accessToken = await getTokenSilently();
		let flightImageInfo = await getFlightImage(clientId, flightId, orthoImageTypeId, field.key, accessToken, dispatch);

		// If flight image doesn't exist, get last flight's field coords if they exist
		if (!flightImageInfo.id && _.findIndex(allFields, { id: field.key }) === -1) {
			let flightToCopy = null;
			dispatch(fieldActions.getFlightsWithFieldOrTrial(clientId, field.key, null, accessToken)).then((res) => {
				//-- If the field was added and then removed before it was locked and saved AND this is the first flight the field was added to, there will not be any coordinates
				if (res && res.length > 0) {
					flightToCopy = _.last(res).flightId;
					addExistingPlotsInField(
						flightToCopy,
						field.key,
						getTokenSilently,
						dispatch,
						draw,
						canvas,
						align,
						true,
						null,
						componentRefs,
						clientId,
						null,
						true
					);
				} else {
					selectedFieldId = field.key;
					toggleSingleFieldTrial(draw, canvas, "field", componentRefs);
					fitToHeight(canvas, "field", true);
				}
			});
		} else {
			// If plots don't exist, add, otherwise fly to field.
			// If on add field page, just fly to field
			if (!align && _.findIndex(allFields, { id: field.key }) === -1) {
				addExistingPlotsInField(
					flightId,
					field.key,
					getTokenSilently,
					dispatch,
					draw,
					canvas,
					align,
					true,
					null,
					componentRefs,
					clientId,
					null,
					true
				);
			} else {
				selectedFieldId = field.key;
				toggleSingleFieldTrial(draw, canvas, "field", componentRefs);
				fitToHeight(canvas, "field", true);

				if (componentRefs.lockToggleRef && componentRefs.lockToggleRef.current) {
					let locked = _.find(allFields, { id: selectedFieldId }).properties.locked;
					componentRefs.lockToggleRef.current.setLocked(locked);
				}
			}
		}
	}
}

function getFlightImage(clientId, flightId, orthoImageTypeId, fieldId, accessToken, dispatch) {
	let flightImageInfo = dispatch(
		flightImageActions.getFlightImage(
			clientId,
			flightId,
			orthoImageTypeId,
			fieldId,
			"00000000-0000-0000-0000-000000000000",
			"00000000-0000-0000-0000-000000000000",
			accessToken
		)
	).then((res) => {
		return {
			id: res.id,
			ll: [parseFloat(res.llLong), parseFloat(res.llLat)],
			ul: [parseFloat(res.ulLong), parseFloat(res.ulLat)],
			lr: [parseFloat(res.lrLong), parseFloat(res.lrLat)],
			ur: [parseFloat(res.urLong), parseFloat(res.urLat)]
		};
	});

	return flightImageInfo;
}

let layerIds = [];
let previousPage = null;
export function buildMapImage(res, orthoId, canvas, edit) {
	if (res && res.id) {
		if (canvas) {
			tilesetIds = _.map(res.mapboxUploads, "tilesetId");
			// Check if image has multiple tiles, if so disable the shader & histogram features as they are broken
			// if (tilesetIds.length > 1) {
			// 	componentsRefs?.applyShaderRef.current.setDisabled(true);
			// 	componentsRefs?.shaderHistogramRef.current.setDisabled(true);
			// } else {
			// 	componentsRefs?.applyShaderRef.current.setDisabled(false);
			// 	componentsRefs?.shaderHistogramRef.current.setDisabled(false);
			// }

			// Resetting layers if user changes page. Handles going from align fields to add fields
			if (previousPage !== edit) {
				layerIds = [];
			}
			previousPage = edit;
			// if layer already exists, move the image to the front
			_.forEach(res.mapboxUploads, ({ tilesetId }, i) => {
				if (
					_.indexOf(layerIds, `ortho-${res.flightId}__${orthoId}__${i}`) !== -1 &&
					canvas.getLayer(`ortho-${res.flightId}__${orthoId}__${i}`)
				) {
					canvas.setPaintProperty(`ortho-${res.flightId}__${orthoId}__${i}`, "raster-opacity", 1);
				} else {
					// need to add the ortho to the layer list before call so that we can set the canvas up beforehand
					layerIds.push(`ortho-${res.flightId}__${orthoId}__${i}`);
					canvas.addSource(`ortho-${res.flightId}__${orthoId}__${i}`, {
						type: "raster",
						url: `mapbox://${tilesetId}`
					});

					canvas.addLayer(
						{
							id: `ortho-${res.flightId}__${orthoId}__${i}`,
							source: `ortho-${res.flightId}__${orthoId}__${i}`,
							type: "raster",
							layout: { visibility: "visible" },
							paint: { "raster-opacity": 1, "raster-opacity-transition": { duration: 300 } }
						},
						// put the layer before this one
						// this is the layer on top of the background, but before all drawings
						"gl-draw-point-point-stroke-inactive.cold"
					);
				}
			});

			_.forEach(layerIds, (id) => {
				const layer = canvas.getLayer(id);
				if (layer && !id.includes(`ortho-${res.flightId}__${orthoId}`)) {
					canvas.setPaintProperty(id, "raster-opacity", 0);
				}
			});

			if (activeShader) {
				refreshShaderLayer(canvas);
			}
		}
	}
}

export async function cutImage(
	clientId,
	flightId,
	orthoImageTypes,
	accessToken,
	dispatch,
	skipToast = false,
	skipFields = false,
	fieldsToCreate = [],
	fieldsToUpdate = [],
	recutAllImages = false
) {
	if (!skipToast) {
		toast.info("Preparing modified images...", {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		});
	}

	let cutParams = {
		clientId: clientId,
		flightId: flightId,
		orthoImageTypes: orthoImageTypes,
		recutAllImages: recutAllImages,
		updatedImages: []
	};

	if (!skipFields) {
		recalculateFieldBoundaries(true);
		_.map(
			_.filter(allFields, (af) => _.map([...fieldsToCreate, ...fieldsToUpdate], "id").includes(af.id)),
			({ id, geometry, properties }) => {
				if (properties.locked) {
					let bbox = turf.bbox(turf.feature(geometry));
					let bboxPolygon = turf.bboxPolygon(bbox);

					let ll = [bboxPolygon.geometry.coordinates[0][3][0], bboxPolygon.geometry.coordinates[0][3][1]];
					let ul = [bboxPolygon.geometry.coordinates[0][0][0], bboxPolygon.geometry.coordinates[0][0][1]];
					let lr = [bboxPolygon.geometry.coordinates[0][2][0], bboxPolygon.geometry.coordinates[0][2][1]];
					let ur = [bboxPolygon.geometry.coordinates[0][1][0], bboxPolygon.geometry.coordinates[0][1][1]];

					let field = {
						llLat: ll[1],
						llLong: ll[0],
						ulLat: ul[1],
						ulLong: ul[0],
						lrLat: lr[1],
						lrLong: lr[0],
						urLat: ur[1],
						urLong: ur[0],
						fieldId: id,
						trialId: null,
						plotId: null
					};

					cutParams.updatedImages.push(field);
				}
			}
		);
	}

	recalculateTrialBoundaries(true);
	_.map(
		_.filter(allTrials, (at) => _.map([...fieldsToCreate, ...fieldsToUpdate], "id").includes(at.properties.field)),
		({ id, properties, geometry }) => {
			if (properties.locked) {
				let bbox = turf.bbox(turf.feature(geometry));
				let bboxPolygon = turf.bboxPolygon(bbox);

				let ll = [bboxPolygon.geometry.coordinates[0][3][0], bboxPolygon.geometry.coordinates[0][3][1]];
				let ul = [bboxPolygon.geometry.coordinates[0][0][0], bboxPolygon.geometry.coordinates[0][0][1]];
				let lr = [bboxPolygon.geometry.coordinates[0][2][0], bboxPolygon.geometry.coordinates[0][2][1]];
				let ur = [bboxPolygon.geometry.coordinates[0][1][0], bboxPolygon.geometry.coordinates[0][1][1]];

				let trial = {
					llLat: ll[1],
					llLong: ll[0],
					ulLat: ul[1],
					ulLong: ul[0],
					lrLat: lr[1],
					lrLong: lr[0],
					urLat: ur[1],
					urLong: ur[0],
					fieldId: properties.field,
					trialId: id,
					plotId: null
				};

				cutParams.updatedImages.push(trial);
			}
		}
	);

	if (cutParams.updatedImages.length !== 0) {
		await dispatch(orthoActions.cutOrtho(cutParams, accessToken));
	}
}

async function createFlightsPlots(newPlots, flightId, accessToken, dispatch) {
	let plotsToAdd = [];

	_.map(newPlots.features, ({ id, geometry, properties }) => {
		let plotToAdd = {
			plotId: id,
			flightId: flightId,
			scaleFactor: properties.scaleFactor,
			ll: {
				long: geometry.coordinates[0][0][0],
				lat: geometry.coordinates[0][0][1]
			},
			ul: {
				long: geometry.coordinates[0][1][0],
				lat: geometry.coordinates[0][1][1]
			},
			ur: {
				long: geometry.coordinates[0][2][0],
				lat: geometry.coordinates[0][2][1]
			},
			lr: {
				long: geometry.coordinates[0][3][0],
				lat: geometry.coordinates[0][3][1]
			}
		};

		plotsToAdd.push(plotToAdd);
	});

	let response = await dispatch(flightsPlotsActions.createFlightsPlots(plotsToAdd, accessToken)).then(() => {
		return true;
	});

	return response;
}

export async function removeFieldGrid(
	flightId,
	fieldId,
	clientId,
	draw,
	canvas,
	getTokenSilently,
	dispatch,
	dropdownRef,
	removeFields,
	controlComponentRefs
) {
	if (fieldCombined.length > 0) {
		uncombinePlotsInField(draw);
	}

	if (_.findIndex(allFields, { id: fieldId }) !== -1) {
		const accessToken = await getTokenSilently();
		dispatch(analysisActions.getAnalysisResultsForFlight(flightId, clientId, accessToken)).then((data) => {
			let fieldResults = _.find(data.fieldAnalysisResults, { fieldId: fieldId });
			if (!fieldResults) {
				let plotsToRemove = [];

				_.map(allPlots, (plot) => {
					if (plot.properties.field === fieldId) {
						plotsToRemove.push(plot);
					}
				});

				singleFeatureToggle = false;
				selectedFieldId = null;
				selectedTrialId = null;

				let plotIdsToRemove = _.map(plotsToRemove, "id");

				draw.current.draw.delete(plotIdsToRemove);
				_.pullAll(allPlots, plotsToRemove);
				refreshToggles(draw, canvas, controlComponentRefs);

				dropdownRef.current.innerRef.current.setThirdDropdownId("all");

				if (removeFields) {
					let fieldsInDropdown = dropdownRef.current.innerRef.current.currentThirdDropdownOptions();
					let prunedDdl = _.filter(fieldsInDropdown, ({ key }) => key !== fieldId);

					dropdownRef.current.innerRef.current.setThirdDropdownOptions(prunedDdl);

					let index = _.findIndex(_.map(fieldPivotPoints, "pivot"), { id: fieldId });
					fieldPivotPoints.splice(index, 1);
				}
			} else {
				let fieldAnalyzed = _.some(fieldResults?.analysisResults) ? true : false;

				if (!fieldAnalyzed) {
					toast.info("Deleting field from flight...", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});

					dispatch(flightsPlotsActions.getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken)).then(
						(res) => {
							if (res.plots) {
								dispatch(fieldActions.deleteFieldInFlight(flightId, fieldId, clientId, accessToken))
									.then(() => {
										toast.success("Field deleted successfully", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: true,
											closeOnClick: true,
											pauseOnHover: true
										});
									})
									.catch(() => {
										toast.error("Error deleting field", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: true,
											closeOnClick: true,
											pauseOnHover: true
										});
									});
							}
							let plotsToRemove = [];

							_.map(allPlots, (plot) => {
								if (plot.properties.field === fieldId) {
									plotsToRemove.push(plot);
								}
							});

							singleFeatureToggle = false;
							selectedFieldId = null;
							selectedTrialId = null;

							let plotIdsToRemove = _.map(plotsToRemove, "id");

							draw.current.draw.delete(plotIdsToRemove);
							_.pullAll(allPlots, plotsToRemove);
							refreshToggles(draw, canvas, controlComponentRefs);

							updateQrToggleList(controlComponentRefs);

							if (removeFields) {
								let fieldsInDropdown = dropdownRef.current.innerRef.current.currentThirdDropdownOptions();
								let prunedDdl = _.filter(fieldsInDropdown, ({ key }) => key !== fieldId);

								dropdownRef.current.innerRef.current.setThirdDropdownOptions(prunedDdl);

								let index = _.findIndex(_.map(fieldPivotPoints, "pivot"), { id: fieldId });
								fieldPivotPoints.splice(index, 1);
							}
						}
					);
				} else {
					toast.error("Cannot remove a field that has been analyzed", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				}
			}
		});
	} else {
		toast.error("That field is not yet in this flight", {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		});
	}
}

export function checkPlotsSaved(draw) {
	if (fieldCombined.length > 0) {
		uncombinePlotsInField(draw, false);
	}

	// Checks if any plots are not saved
	return _.findIndex(_.map(allPlots, "properties"), { isDirty: true });
}

export function checkPlotsLocked(draw) {
	if (fieldCombined.length > 0) {
		uncombinePlotsInField(draw, false);
	}

	// Checks if any plots are not locked
	return _.findIndex(_.map(allPlots, "properties"), { locked: false });
}

export async function handleSave(flightId, clientId, orthoImageTypes, type, getTokenSilently, dispatch, redirect) {
	// Create lists of fields to update and create
	let fieldIdsToUpdate = [];
	let fieldsToCreate = [];
	const accessToken = await getTokenSilently();
	_.map(allPlots, (plot) => {
		if (plot.properties.locked) {
			if (plot.properties.new) {
				if (_.findIndex(fieldsToCreate, { id: plot.properties.field }) == -1) {
					fieldsToCreate.push({ id: plot.properties.field, plots: [] });
				}

				let fieldIndex = _.findIndex(fieldsToCreate, { id: plot.properties.field });
				fieldsToCreate[fieldIndex].plots.push(plot);
			} else {
				let initialPlot = _.find(initialPlots, { id: plot.id });
				if (!fieldIdsToUpdate.includes(plot.properties.field)) {
					let plotHasChanged = false;
					_.map(initialPlot.geometry.coordinates[0], (initialCoords, index) => {
						if (
							initialCoords[0] !== plot.geometry.coordinates[0][index][0] ||
							initialCoords[1] !== plot.geometry.coordinates[0][index][1]
						) {
							plotHasChanged = true;
						}
					});

					if (plotHasChanged || plot.properties.isDirty) {
						fieldIdsToUpdate.push(plot.properties.field);
					}
				}
			}
		}
	});

	// Create flights plots for each new field
	// eslint-disable-next-line
	const creatingPlots = await Promise.all(
		_.map(fieldsToCreate, async ({ plots }) => {
			let fieldToCreate = {
				type: "FeatureCollection",
				features: plots
			};

			await createFlightsPlots(fieldToCreate, flightId, accessToken, dispatch);
		})
	);

	// Create full list of plots to fields
	let fieldsToUpdate = [];
	_.map(fieldIdsToUpdate, (fieldId) => {
		let fieldToUpdate = {
			id: fieldId,
			plots: _.filter(allPlots, ({ properties }) => {
				return properties.field === fieldId;
			})
		};

		fieldsToUpdate.push(fieldToUpdate);
	});

	// Update flights plots for each changed field
	// eslint-disable-next-line
	const updatingPlots = await Promise.all(
		_.map(fieldsToUpdate, async ({ plots }) => {
			let plotsToUpdate = [];

			_.map(plots, ({ id, geometry, properties }) => {
				let plotToUpdate = {
					flightId: flightId,
					plotId: id,
					scaleFactor: properties.scaleFactor,
					lowerLeft: {
						lat: geometry.coordinates[0][0][1],
						long: geometry.coordinates[0][0][0]
					},
					upperLeft: {
						lat: geometry.coordinates[0][1][1],
						long: geometry.coordinates[0][1][0]
					},
					upperRight: {
						lat: geometry.coordinates[0][2][1],
						long: geometry.coordinates[0][2][0]
					},
					lowerRight: {
						lat: geometry.coordinates[0][3][1],
						long: geometry.coordinates[0][3][0]
					}
				};

				plotsToUpdate.push(plotToUpdate);
			});

			await dispatch(flightsPlotsActions.updateFlightsPlots(plotsToUpdate, clientId, accessToken));
		})
	);

	// Create quantified regions
	if (fieldsToCreate?.length > 0) {
		let plotIdsToCreate = _.map(_.flatten(_.map(fieldsToCreate, "plots")), "id");
		let plotFeatures = _.filter(allPlots, (ap) => plotIdsToCreate.includes(ap.id));
		let plotObjs = _.map(plotFeatures, (p) => {
			return {
				id: p.id,
				fieldId: p.properties.field,
				flightId: flightId,
				scaleFactor: p.properties.scaleFactor,
				fieldRangeLength: p.properties.fieldRangeLength,
				fieldColWidth: p.properties.fieldColWidth,
				rowCount: p.properties.fieldRowCount,
				rowSpacing: p.properties.fieldRowSpacing,
				planterOffset: p.properties.fieldPlanterOffset,
				ll: {
					lat: p.geometry.coordinates[0][0][1],
					long: p.geometry.coordinates[0][0][0]
				},
				ul: {
					lat: p.geometry.coordinates[0][1][1],
					long: p.geometry.coordinates[0][1][0]
				},
				ur: {
					lat: p.geometry.coordinates[0][2][1],
					long: p.geometry.coordinates[0][2][0]
				},
				lr: {
					lat: p.geometry.coordinates[0][3][1],
					long: p.geometry.coordinates[0][3][0]
				}
			};
		});

		dispatch(quantifiedRegionActions.createFlightsQuantifiedRegions(plotObjs, accessToken));
	}

	// Update quantified regions of updated plots
	let plotIdsToUpdate = _.map(
		_.filter(allPlots, (ap) => fieldIdsToUpdate.includes(ap.properties.field)),
		"id"
	);
	if (plotIdsToUpdate?.length > 0) {
		let plotFeatures = _.filter(allPlots, (ap) => plotIdsToUpdate.includes(ap.id));
		let plotObjs = _.map(plotFeatures, (p) => {
			return {
				id: p.id,
				fieldId: p.properties.field,
				flightId: flightId,
				scaleFactor: p.properties.scaleFactor,
				fieldRangeLength: p.properties.fieldRangeLength,
				fieldColWidth: p.properties.fieldColWidth,
				rowCount: p.properties.fieldRowCount,
				rowSpacing: p.properties.fieldRowSpacing,
				planterOffset: p.properties.fieldPlanterOffset,
				ll: {
					lat: p.geometry.coordinates[0][0][1],
					long: p.geometry.coordinates[0][0][0]
				},
				ul: {
					lat: p.geometry.coordinates[0][1][1],
					long: p.geometry.coordinates[0][1][0]
				},
				ur: {
					lat: p.geometry.coordinates[0][2][1],
					long: p.geometry.coordinates[0][2][0]
				},
				lr: {
					lat: p.geometry.coordinates[0][3][1],
					long: p.geometry.coordinates[0][3][0]
				}
			};
		});

		dispatch(quantifiedRegionActions.updateFlightsQuantifiedRegions(plotObjs, accessToken));
	}

	// Mark all plots as saved
	_.map(allPlots, ({ properties }) => {
		properties.isDirty = false;
	});

	// Only call cut if fields have been modified
	if (fieldsToCreate.length > 0 || fieldsToUpdate.length > 0) {
		cutImage(clientId, flightId, orthoImageTypes, accessToken, dispatch, false, false, fieldsToCreate, fieldsToUpdate);
	}

	// Will redirect to next page without waiting for cut to be finished
	redirect();
}

export function setSubsamplesToAdd(quantifiedRegions) {
	subsamplesToAdd = quantifiedRegions;
}

export function setTempSubsamplesToAdd(quantifiedRegions) {
	tempSubsamplesToAdd = quantifiedRegions;
}

export function setQuantifiedRegionOptions(quantifiedRegionTypes) {
	quantifiedRegionOptions = quantifiedRegionTypes;
}

export async function saveQuantifiedRegions(flightId, dispatch, accessToken, fieldQuantifiedRegionIds, fromViewer) {
	// Create quantified regions
	if (subsamplesToAdd?.length > 0 || (fromViewer && tempSubsamplesToAdd?.length > 0)) {
		let plotIdsToCreate = _.map(_.uniqBy(fromViewer ? tempSubsamplesToAdd : subsamplesToAdd, "plotId"), "plotId");
		let plotFeatures = _.filter(fromViewer ? tempPlots : allPlots, (ap) => plotIdsToCreate.includes(ap.id));
		let plotObjs = _.map(plotFeatures, (p) => {
			return {
				id: p.id,
				fieldId: p.properties.field,
				flightId: flightId,
				scaleFactor: p.properties.scaleFactor,
				fieldRangeLength: p.properties.fieldRangeLength,
				fieldColWidth: p.properties.fieldColWidth,
				rowCount: p.properties.fieldRowCount,
				rowSpacing: p.properties.fieldRowSpacing,
				planterOffset: p.properties.fieldPlanterOffset,
				ll: {
					lat: p.geometry.coordinates[0][0][1],
					long: p.geometry.coordinates[0][0][0]
				},
				ul: {
					lat: p.geometry.coordinates[0][1][1],
					long: p.geometry.coordinates[0][1][0]
				},
				ur: {
					lat: p.geometry.coordinates[0][2][1],
					long: p.geometry.coordinates[0][2][0]
				},
				lr: {
					lat: p.geometry.coordinates[0][3][1],
					long: p.geometry.coordinates[0][3][0]
				}
			};
		});

		dispatch(quantifiedRegionActions.createFlightsQuantifiedRegions(plotObjs, accessToken, fieldQuantifiedRegionIds));
	}
}

function toggleSingleFieldTrial(draw, canvas, type, controlComponentRefs) {
	if (type) {
		singleFeatureToggle = true;
		refreshToggles(draw, canvas, controlComponentRefs);
		let allFeatures = draw.current.draw.getAll().features;
		let idsToDelete = [];

		if (type === "field") {
			if (!selectedFieldId) {
				selectedFieldId = _.last(allFields).id;
			}

			let plotIdsInField = _.map(
				_.filter(allFeatures, ({ properties }) => properties.field === selectedFieldId),
				"id"
			);

			idsToDelete = _.filter(allFeatures, ({ id, properties }) => {
				// Check if the id is the field id for field features or if the plot field id matches
				if (id !== selectedFieldId && properties.type === "field") {
					// || !(properties.field === selectedFieldId)) {
					return true;
				} else if (!plotIdsInField.includes(id) && properties.type === "plot") {
					return true;
				} else if (!plotIdsInField.includes(properties.plotId) && properties.type === "subsample") {
					return true;
				}
			});
		} else {
			if (!selectedTrialId) {
				selectedTrialId = _.last(allTrials).id;
			}

			idsToDelete = _.filter(allFeatures, ({ id, properties }) => {
				if (
					(id !== selectedTrialId && properties.plots) ||
					(properties.trial !== undefined && properties.trial !== selectedTrialId)
				) {
					return true;
				}
			});
		}

		draw.current.draw.delete(_.map(idsToDelete, "id"));
	} else {
		singleFeatureToggle = false;
		refreshToggles(draw, canvas, controlComponentRefs);
	}
}

function combinePlotsInField(draw, addToSelection = false) {
	// Selects all plots in field
	let selectedFeatures = [];
	if (!addToSelection) {
		recalculateFieldBoundaries();
	} else {
		selectedFeatures = draw.current.draw.getSelected().features;
		selectedFeatures = _.filter(selectedFeatures, ({ id }) => {
			return id !== selectedFieldId;
		});
	}

	draw.current.draw.changeMode("simple_select", {
		featureIds: []
	});

	let plots = _.map(_.find(allFields, { id: selectedFieldId }).properties.plots, "id");
	let selectedPlots = _.cloneDeep(plots);

	// Hide subsamples if they are toggled
	//-- Don't care about default subsamples
	if (pcToggle || pcQrToggle || rcQrToggle || brQrToggle || frQrToggle || scQrToggle) {
		let subsamplesInPlot = _.filter(subsamples, (s) => plots.includes(s.properties.plotId));

		// Filter on toggled subsample type
		let qrTypeId = _.find(quantifiedRegionOptions, (qro) => {
			if (pcToggle) return qro.name === "Plot-Centered";
			if (pcQrToggle) return qro.name === "Plot-Centered with Subsamples";
			if (rcQrToggle) return qro.name === "Row-Centered";
			if (brQrToggle) return qro.name === "Between-Row";
			if (frQrToggle) return qro.name === "Full Row";
			if (scQrToggle) return qro.name === "Stand Count";
		})?.id;

		let subsampleIds = _.map(
			_.filter(subsamplesInPlot, (sp) => sp.properties.quantifiedRegionTypeId === qrTypeId),
			"id"
		);
		draw.current.draw.delete(subsampleIds);
	}

	// Combine field
	selectedPlots.unshift(selectedFieldId);

	// Make sure plots are drawn
	let plotToggleState = _.cloneDeep(plotBoundaryToggle);
	if (!plotBoundaryToggle) {
		plotToggle(draw);
	}

	draw.current.draw.changeMode("simple_select", {
		featureIds: selectedPlots
	});

	// Combines selected features
	draw.current.draw.combineFeatures();

	let selectedIds = [];
	if (addToSelection) {
		selectedIds = selectedIds.concat(_.map(selectedFeatures, "id"));
	}
	selectedIds = selectedIds.concat(draw.current.draw.getSelected().features[0].id);

	// Select rows if toggled -- Don't combine
	if (rowsToggle) {
		selectedIds = selectedIds.concat(
			_.map(
				_.filter(allRows, ({ properties }) => {
					if (plots.includes(properties.plot)) return true;
				}),
				"id"
			)
		);
	}

	// Select ll point if toggled
	if (llToggle) {
		selectedIds = selectedIds.concat(
			allLLPoints[_.findIndex(_.map(allLLPoints, "properties"), { field: selectedFieldId })].id
		);
	}

	draw.current.draw.changeMode("simple_select", {
		featureIds: selectedIds
	});

	if (!plotToggleState) {
		plotToggle(draw);
	}
}

export function uncombinePlotsInField(draw, reselect) {
	if (draw && draw.current) {
		let idToSelect = draw.current.draw.getSelectedIds();

		draw.current.draw.changeMode("simple_select", {
			featureIds: [...fieldCombined]
		});

		// Uncombines selected features
		draw.current.draw.uncombineFeatures();

		// De-select field, re-select original feature clicked
		fieldCombined = [];
		if (idToSelect.length > 0 && reselect) {
			draw.current.draw.changeMode("simple_select", {
				featureIds: idToSelect
			});
		} else {
			draw.current.draw.changeMode("simple_select");
		}
	}
}

function selectPlotFromRow(draw, rowId) {
	if (plotBoundaryToggle) {
		let plotId = _.find(allRows, { id: rowId }).properties.plot;

		// Select other rows
		let idsToSelect = [plotId].concat(
			_.map(
				_.filter(allRows, ({ properties }) => properties.plot === plotId),
				"id"
			)
		);

		draw.current.draw.changeMode("simple_select", {
			featureIds: idsToSelect
		});
	} else {
		selectedFieldId = _.find(allRows, { id: rowId }).properties.field;
		combinePlotsInField(draw);
	}
}

function bringSubsamplesToTopLayer(draw) {
	if (pcToggle || pcQrToggle || rcQrToggle || brQrToggle || frQrToggle || scQrToggle) {
		let subsampleFeatureIds = _.map(
			_.filter(draw.current.draw.getAll().features, (feature) => feature.properties.type === "subsample"),
			"id"
		);

		_.map(subsampleFeatureIds, (featureId) => {
			let feature = draw.current.draw.get(featureId);
			draw.current.draw.delete(featureId);
			draw.current.draw.add(feature);
		});
	}
}

export function scaleOutward(draw, e) {
	if (draw && draw.current) {
		if (fieldCombined.length === 1) {
			let featuresToSelect = [];

			_.map(fieldCombined, (combinedFieldId) => {
				//-- Find field to scale
				let feature = draw.current.draw.get(combinedFieldId);
				if (!feature.properties.locked) {
					let fieldIndex = _.findIndex(allFields, { id: feature.properties.field });
					let field = allFields[fieldIndex];

					//-- Add scale factor by static metric
					let newScaleFactor =
						Math.round(
							(field.properties.scaleFactor +
								(scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1) +
								Number.EPSILON) *
								1000
						) / 1000;

					//-- Get the current scale period
					//---- 1 being the first time a user scales
					let scalePeriod = parseFloat(
						(
							Math.abs(1 - newScaleFactor) /
							((scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1))
						).toFixed(3)
					);

					//-- Find the actual rate of change
					//-- https://www.mathsisfun.com/money/compound-interest-derivation.html
					let scale =
						newScaleFactor !== 1
							? Math.pow(newScaleFactor, 1 / scalePeriod)
							: 1 + (scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1);

					//-- If user was scaling inward, apply inverse scale until object is smaller than the original scale
					if (scale < 1) {
						scale = Math.abs(1 - (scale - 1));
					}

					//-- Apply scale to drawn object
					turf.transformScale(feature, scale, {
						origin: field.properties.initialLL,
						mutate: true
					});

					//-- Update scale on plot and field properties
					allFields[fieldIndex].properties.scaleFactor = parseFloat(parseFloat(newScaleFactor).toFixed(3)); // setting precision to 3 decimals, converting back to number
					_.forEach(feature.properties.plots, (plot) => {
						plot.properties.scaleFactor = parseFloat(parseFloat(newScaleFactor).toFixed(3));
					});

					draw.current.draw.add(feature);

					featuresToSelect.push(feature.properties.field);
				} else {
					toast.warn(`Cannot scale field ${feature.properties.fieldName} while it is locked`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				}
			});

			uncombinePlotsInField(draw, true);
			_.map(featuresToSelect, (id, index) => {
				selectedFieldId = id;
				index > 0 ? combinePlotsInField(draw, true) : combinePlotsInField(draw);
			});
		} else if (fieldCombined.length > 1) {
			// Selecting multiple fields during scaling causes major lag
			// Take out if-else when allowing multiple fields
			toast.info("Please only select one field to scale", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		} else {
			toast.info("Select a field to scale", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	}
}

export function scaleInward(draw, e) {
	if (draw && draw.current) {
		if (fieldCombined.length === 1) {
			let featuresToSelect = [];

			_.map(fieldCombined, (combinedFieldId) => {
				//-- Find field to scale
				let feature = draw.current.draw.get(combinedFieldId);
				if (!feature.properties.locked) {
					let fieldIndex = _.findIndex(allFields, { id: feature.properties.field });
					let field = allFields[fieldIndex];

					//-- Subtract scale factor by static metric
					let newScaleFactor =
						Math.round(
							(field.properties.scaleFactor -
								(scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1) +
								Number.EPSILON) *
								1000
						) / 1000;

					//-- Get the current scale period
					//---- 1 being the first time a user scales
					let scalePeriod = parseFloat(
						(
							Math.abs(1 - newScaleFactor) /
							((scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1))
						).toFixed(3)
					);

					//-- Find the actual rate of change
					//-- https://www.mathsisfun.com/money/compound-interest-derivation.html
					let scale =
						newScaleFactor !== 1
							? Math.pow(newScaleFactor, 1 / scalePeriod)
							: 1 - (scalingMetric / (e.ctrlKey ? fineConstant : 1)) * (e.altKey ? coarseConstant : 1);

					//-- If user was scaling outward, apply inverse scale until object is smaller than the original scale
					if (scale > 1) {
						scale = Math.abs(1 - (scale - 1));
					}

					//-- Apply scale to drawn object
					turf.transformScale(feature, scale, {
						origin: field.properties.initialLL,
						mutate: true
					});

					//-- Update scale on plot and field properties
					allFields[fieldIndex].properties.scaleFactor = parseFloat(parseFloat(newScaleFactor).toFixed(3));
					_.forEach(feature.properties.plots, (plot) => {
						plot.properties.scaleFactor = parseFloat(parseFloat(newScaleFactor).toFixed(3));
					});

					draw.current.draw.add(feature);

					featuresToSelect.push(feature.properties.field);
				} else {
					toast.warn(`Cannot scale field ${feature.properties.fieldName} while it is locked`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true
					});
				}
			});

			uncombinePlotsInField(draw, true);
			_.map(featuresToSelect, (id, index) => {
				selectedFieldId = id;
				index > 0 ? combinePlotsInField(draw, true) : combinePlotsInField(draw);
			});
		} else if (fieldCombined.length > 1) {
			// Selecting multiple fields during scaling causes major lag
			toast.info("Please only select one field to scale", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		} else {
			toast.info("Select a field to scale", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}
	}
}

export function setPlots(plots) {
	allPlots = plots;
}

export function setTempPlots(plots) {
	tempPlots = plots;
}

export function handleDragging(draw) {
	if (movingFieldProperties?.length === 0) {
		let featuresSelected = draw.current.draw.getSelected().features;

		if (fieldCombined.length > 0 && featuresSelected[0].geometry.coordinates.length > 1) {
			const fieldDrawings = _.filter(featuresSelected, (feature) => {
				return feature.properties.type === "field";
			});
			let featuresToKeep = [];
			let featuresToRemove = [];

			_.map(featuresSelected, (feature) => {
				feature.properties.type === "llPoint" ? featuresToKeep.push(feature) : featuresToRemove.push(feature);
			});

			let featureIdsToKeep = _.map(featuresToKeep, "id");
			let featureIdsToRemove = _.map(featuresToRemove, "id");

			nonPlotDrawingsDeleted = _.filter(featuresToRemove, ({ id }) => {
				return !_.map(fieldDrawings, "id").includes(id);
			});
			movingFieldProperties = fieldDrawings;

			draw.current.draw.delete([...featureIdsToRemove, ...featureIdsToKeep]);

			// Stripping away the extra plots
			let draggableFieldDrawing = _.map(fieldDrawings, (f) => {
				return {
					...f,
					geometry: {
						coordinates: [f.geometry.coordinates[0][0]],
						type: "Polygon"
					}
				};
			});

			let featuresToAdd = {
				type: "FeatureCollection",
				features: [...draggableFieldDrawing, ...featuresToKeep]
			};

			draw.current.draw.add(featuresToAdd);

			featureIdsToKeep.unshift(..._.map(draggableFieldDrawing, "id"));

			draw.current.draw.changeMode("simple_select", {
				featureIds: featureIdsToKeep
			});
		}
	}
}

export function handleCreate(
	e,
	draw,
	canvas,
	dropdownRef,
	controlComponentRefs,
	addFieldModal,
	addExistingFieldsModal,
	clientId,
	getTokenSilently,
	dispatch,
	flightId
) {
	let currentMode = draw.current.draw.getMode();
	if (currentMode === "new_field_mode") {
		if (e.features[0].geometry.type === "LineString") {
			newFieldLine = e.features[0];
			draw.current.draw.delete(e.features[0].id);
			checkClickCounter(addFieldModal, addExistingFieldsModal, clientId, getTokenSilently, dispatch, flightId);
		}
	} else if (currentMode === "simple_select") {
		checkValidField(e, draw, canvas, dropdownRef, controlComponentRefs);
	} else {
		if (drawnFeature) {
			clearMeasurements(draw);
		}
		drawnFeature = e.features[0];
		if (drawnFeature) {
			if (currentMode === "line_measure") {
				measureLineDistance(drawnFeature, canvas);
			}
		}
	}
}

export function handleUpdate(e, draw, feature, orthoAnalyzedModal, canvas) {
	if (fieldCombined.length > 0) {
		handleEndDragging(draw);
	} else {
		updateArea(draw, feature, orthoAnalyzedModal, canvas);
	}

	if (e.features[0].properties.meta === "drawn_feature") {
		drawnFeature = e.features[0];
		let currentMode = drawnFeature.geometry.type;
		if (drawnFeature) {
			if (currentMode === "LineString") {
				measureLineDistance(drawnFeature, canvas);
			}
		}
	}
}

export function handleEndDragging(draw) {
	if (movingFieldProperties.length > 0) {
		let selectedFeatures = draw.current.draw.getSelected().features;
		let movedFields = [];
		let nonFieldSelectedFeatures = [];

		_.map(selectedFeatures, (feature) => {
			feature.properties.type === "field" ? movedFields.push(feature) : nonFieldSelectedFeatures.push(feature);
		});

		if (
			_.filter(movedFields, ({ properties }) => {
				return properties.locked;
			}).length !== 0
		) {
			toast.warn("Your changes to this feature will not be saved while it's locked", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true
			});
		}

		let nonFieldSelectedFeatureIds = _.map(nonFieldSelectedFeatures, "id");

		let featuresToAdd = {
			type: "FeatureCollection",
			features: []
		};

		let deltaX = 0;
		let deltaY = 0;

		_.map(movedFields, (movedField) => {
			let fieldProperties = _.find(movingFieldProperties, { id: movedField.id });

			deltaX = movedField.geometry.coordinates[0][0][0] - fieldProperties.geometry.coordinates[0][0][0][0];
			deltaY = movedField.geometry.coordinates[0][0][1] - fieldProperties.geometry.coordinates[0][0][0][1];

			// Add delta to all plot coordinates
			_.map(fieldProperties.geometry.coordinates, (coordinates) => {
				_.map(coordinates[0], (xy) => {
					xy[0] += deltaX;
					xy[1] += deltaY;
				});
			});

			featuresToAdd.features.push(fieldProperties);
		});

		draw.current.draw.delete([..._.map(movedFields, "id"), ...nonFieldSelectedFeatureIds]);

		featuresToAdd.features = featuresToAdd.features.concat(nonFieldSelectedFeatures);

		movingFieldProperties = [];

		// Add delta to all non plot coordinates
		_.map(nonPlotDrawingsDeleted, (feature) => {
			if (feature.geometry.type === "Polygon") {
				_.map(feature.geometry.coordinates[0], (coordinates) => {
					coordinates[0] += deltaX;
					coordinates[1] += deltaY;
				});
			} else {
				_.map(feature.geometry.coordinates, (coordinates) => {
					coordinates[0] += deltaX;
					coordinates[1] += deltaY;
				});
			}

			featuresToAdd.features.push(feature);
		});

		nonPlotDrawingsDeleted = [];

		draw.current.draw.add(featuresToAdd);

		let addedFeatureIds = _.map(featuresToAdd.features, "id");

		draw.current.draw.changeMode("simple_select", {
			featureIds: addedFeatureIds.concat(nonFieldSelectedFeatureIds)
		});
	}
}

export function selectRow(plotId, draw) {
	if (plotId && draw) {
		let plot = _.find(allPlots, { id: plotId });

		let plotsToSelectFrom = _.cloneDeep(trialPlots);
		let plotIdsWithTrials = _.map(_.map(plotsToSelectFrom, "properties"), "plotId");

		_.map(allPlots, (singlePlot) => {
			if (!plotIdsWithTrials.includes(singlePlot.id)) {
				plotsToSelectFrom.push(singlePlot);
			}
		});

		let plotsToSelect = _.filter(plotsToSelectFrom, ({ properties }) => {
			if (trialPlots.length > 0 && singleFeatureToggle) {
				return properties.trial === plot.properties.trial && properties.range === plot.properties.range;
			} else {
				return properties.field === plot.properties.field && properties.range === plot.properties.range;
			}
		});

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(plotsToSelect, (p) => p.properties.plotId ?? p.id)
		});
	}
}

export function selectColumn(plotId, draw) {
	if (plotId && draw) {
		let plot = _.find(allPlots, { id: plotId });

		let plotsToSelectFrom = _.cloneDeep(trialPlots);
		let plotIdsWithTrials = _.map(_.map(plotsToSelectFrom, "properties"), "plotId");

		_.map(allPlots, (singlePlot) => {
			if (!plotIdsWithTrials.includes(singlePlot.id)) {
				plotsToSelectFrom.push(singlePlot);
			}
		});

		let plotsToSelect = _.filter(plotsToSelectFrom, ({ properties }) => {
			if (trialPlots.length > 0 && singleFeatureToggle) {
				return properties.trial === plot.properties.trial && properties.column === plot.properties.column;
			} else {
				return properties.field === plot.properties.field && properties.column === plot.properties.column;
			}
		});

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(plotsToSelect, (p) => p.properties.plotId ?? p.id)
		});
	}
}

export function selectGrid(plotId, draw, level) {
	if (plotId && draw) {
		let plot = _.find(allPlots, { id: plotId });

		let plotsToSelectFrom = _.cloneDeep(trialPlots);
		let plotIdsWithTrials = _.map(_.map(plotsToSelectFrom, "properties"), "plotId");

		_.map(allPlots, (singlePlot) => {
			if (!plotIdsWithTrials.includes(singlePlot.id)) {
				plotsToSelectFrom.push(singlePlot);
			}
		});

		let plotsToSelect = _.filter(plotsToSelectFrom, ({ properties }) => {
			return level !== "trial"
				? properties.field === plot.properties.field
				: properties.trial === plot.properties.trial;
		});

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(plotsToSelect, (p) => p.properties.plotId ?? p.id)
		});
	}
}

export function selectRep(plotId, subsampleId, draw) {
	if ((plotId || subsampleId) && draw) {
		if (!plotId) {
			plotId = _.find(subsamples, { id: subsampleId })?.properties.plotId;
		}

		let plot = _.find(allPlots, { id: plotId });

		let plotsToSelectFrom = _.cloneDeep(trialPlots);
		let plotIdsWithTrials = _.map(_.map(plotsToSelectFrom, "properties"), "plotId");

		_.map(allPlots, (singlePlot) => {
			if (!plotIdsWithTrials.includes(singlePlot.id)) {
				plotsToSelectFrom.push(singlePlot);
			}
		});

		let plotsToSelect = _.filter(plotsToSelectFrom, ({ properties }) => {
			if (trialPlots.length > 0 && singleFeatureToggle) {
				return properties.trial === plot.properties.trial && properties.replicate === plot.properties.replicate;
			} else {
				return properties.field === plot.properties.field && properties.replicate === plot.properties.replicate;
			}
		});

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(plotsToSelect, (p) => p.properties.plotId ?? p.id)
		});
	}
}

export function selectSubsampleRow(subsampleId, draw) {
	if (subsampleId && draw) {
		let subsample = _.find(subsamples, { id: subsampleId });

		let subsamplesToSelect = _.filter(
			subsamples,
			(s) =>
				s.properties.fieldQuantifiedRegionId === subsample.properties.fieldQuantifiedRegionId &&
				s.properties.plotId === subsample.properties.plotId &&
				s.properties.range === subsample.properties.range
		);

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(subsamplesToSelect, "id")
		});
	}
}

export function selectSubsampleColumn(subsampleId, draw) {
	if (subsampleId && draw) {
		let subsample = _.find(subsamples, { id: subsampleId });

		let subsamplesToSelect = _.filter(
			subsamples,
			(s) =>
				s.properties.fieldQuantifiedRegionId === subsample.properties.fieldQuantifiedRegionId &&
				s.properties.plotId === subsample.properties.plotId &&
				s.properties.column === subsample.properties.column
		);

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(subsamplesToSelect, "id")
		});
	}
}

export function selectSubsampleGrid(subsampleId, draw) {
	if (subsampleId && draw) {
		let subsample = _.find(subsamples, { id: subsampleId });

		let subsamplesToSelect = _.filter(
			subsamples,
			(s) =>
				s.properties.fieldQuantifiedRegionId === subsample.properties.fieldQuantifiedRegionId &&
				s.properties.plotId === subsample.properties.plotId
		);

		draw.current.draw.changeMode("simple_select", {
			featureIds: _.map(subsamplesToSelect, "id")
		});
	}
}

export function isFieldCombined() {
	return fieldCombined.length > 0;
}

export async function deleteExistingMarkedFeatures(
	features,
	draw,
	dispatch,
	getTokenSilently,
	clientId,
	canvas,
	controlComponentRefs,
	modalRef,
	queryClient
) {
	const accessToken = await getTokenSilently();

	const featureIdsToReselect = draw.current.draw.getSelectedIds();

	const plotDeleteInfo = { clientId: clientId, markedPlots: [], plotTrialPairs: [] };
	const quantifiedRegionDeleteInfo = { clientId: clientId, markedQuantifiedRegions: [], plotTrialPairs: [] };

	for (const feature of features) {
		if (feature.plotId) {
			markedPlots = _.filter(
				markedPlots,
				(mp) =>
					mp.plotId !== feature.plotId ||
					mp.flightId !== feature.flightId ||
					new Date(mp.dateAndTime).toLocaleString() !== new Date(feature.dateAndTime).toLocaleString() ||
					mp.isAllData !== feature.isAllData ||
					mp.plotStatusId !== feature.plotStatusId
			);

			plotDeleteInfo.markedPlots.push({
				plotId: feature.plotId,
				flightId: feature.isAllData ? null : feature.flightId,
				dateAndTime: feature.isAllData ? null : feature.dateAndTime,
				isAllData: feature.isAllData,
				plotStatusId: feature.plotStatusId
			});

			let matchingPlotFeature = _.find(
				draw.current.draw.getAll().features,
				(f) => (f.id === feature.plotId || f.properties.plotId === feature.plotId) && !f.properties.quantifiedRegionId
			);

			plotDeleteInfo.plotTrialPairs.push({
				plotId: feature.plotId,
				trialId: matchingPlotFeature.properties.trial
			});
		} else if (feature.quantifiedRegionId) {
			markedSubsamples = _.filter(
				markedSubsamples,
				(ms) =>
					ms.quantifiedRegionId !== feature.quantifiedRegionId ||
					ms.flightId !== feature.flightId ||
					new Date(ms.dateAndTime).toLocaleString() !== new Date(feature.dateAndTime).toLocaleString() ||
					ms.isAllData !== feature.isAllData ||
					ms.quantifiedRegionStatusId !== feature.plotStatusId
			);

			const matchingSubsampleFeature = _.find(
				draw.current.draw.getAll().features,
				(f) => f.properties.quantifiedRegionId === feature.quantifiedRegionId
			);

			if (matchingSubsampleFeature) {
				applyNewSubsampleBoundaryColors([matchingSubsampleFeature], draw);
			} else {
				const tempFeature = {
					properties: {
						quantifiedRegionId: feature.quantifiedRegionId,
						flightDate: feature.dateAndTime,
						flightId: feature.flightId
					}
				};
				applyNewSubsampleBoundaryColors([tempFeature], draw, false);
			}

			quantifiedRegionDeleteInfo.markedQuantifiedRegions.push({
				quantifiedRegionId: feature.quantifiedRegionId,
				flightId: feature.isAllData ? null : feature.flightId,
				dateAndTime: feature.isAllData ? null : feature.dateAndTime,
				isAllData: feature.isAllData,
				plotStatusId: feature.plotStatusId
			});

			let matchingParentPlot = _.find(
				draw.current.draw.getAll().features,
				(f) => f.properties.plotId === feature.parentPlotId || f.id === feature.parentPlotId
			);

			quantifiedRegionDeleteInfo.plotTrialPairs.push({
				plotId: feature.parentPlotId,
				trialId: matchingParentPlot.properties.trial
			});
		}
	}
	if (plotDeleteInfo.markedPlots.length > 0) {
		await dispatch(markedPlotActions.deleteMarkedPlots(plotDeleteInfo, accessToken));
	}

	if (quantifiedRegionDeleteInfo.markedQuantifiedRegions.length > 0) {
		await dispatch(markedPlotActions.deleteMarkedQuantifiedRegions(quantifiedRegionDeleteInfo, accessToken));
	}

	controlComponentRefs?.heatmapTimelineRef?.current?.getPlotAnalysisResults();
	modalRef.current.doneDeleting();

	if (defMarkersToggle) {
		defMarkerToggle(canvas, controlComponentRefs);
		defMarkerToggle(canvas, controlComponentRefs);
	}

	controlComponentRefs?.flagPlotsRef?.current?.closeModal();
	draw.current.draw.changeMode("simple_select", { featureIds: featureIdsToReselect });
	queryClient.invalidateQueries({ queryKey: ["annotation data"] });
}

function setSelectedPlotStatuses(checkedOptions, notes, draw, timelineRef, navigationFlightId, flightOptions) {
	let toastPlotStatusId = "plot-status";
	let flightId = navigationFlightId ?? timelineRef.current.currentFlightId;
	let selectedFeatures = draw.current.draw.getSelected().features;
	if (selectedFeatures.length > 0) {
		let plotFeatures = _.filter(selectedFeatures, (feature) => {
			return feature.properties.type === "plot";
		});
		if (plotFeatures.length > 0) {
			_.map(plotFeatures, (feature) => {
				let plotId = feature.properties?.plotId ?? feature.id;
				let trialId = feature.properties.trial;

				//-- Find matching marked plot to replace
				let matchingMarkedPlot = _.findIndex(
					markedPlots,
					(mp) =>
						mp.plotId === plotId &&
						((mp.flightId === flightId &&
							new Date(mp.dateAndTime).getTime() === new Date(timelineRef.current.getCurrentFlightDate()).getTime()) ||
							(mp.dateAndTime === checkedOptions.dateAndTime && !mp.isAllData) ||
							(mp.isAllData &&
								checkedOptions.timeframe === "All Data" &&
								mp.plotStatusName === checkedOptions.plotStatusName))
				);

				if (matchingMarkedPlot !== -1) {
					_.pullAt(markedPlots, [matchingMarkedPlot]);
				}

				let dateAndTime =
					checkedOptions.dateAndTime ??
					(checkedOptions.timeframe !== "All Data"
						? _.find(flightOptions, { flightId: flightId })?.flightDateAndTime
						: null);

				markedPlots.push({
					plotId: plotId,
					plotStatusName: checkedOptions.plotStatusName,
					plotStatusId: checkedOptions.plotStatusId,
					dateAndTime: dateAndTime,
					isAllData: checkedOptions.timeframe === "All Data",
					flightId:
						checkedOptions.timeframe === "This Flight Onward" || checkedOptions.timeframe === "One Time"
							? flightId
							: null,
					notes: notes,
					trialId: trialId,
					annotationClassifier: checkedOptions.annotationClassifier,
					oneTime: checkedOptions.plotStatusName === "Excluded" ? checkedOptions.oneTime : false,
					modifiedBy: checkedOptions.modifiedBy,
					modifiedDateTime: checkedOptions.modifiedDateTime
				});
			});
		} else {
			if (!toast.isActive(toastPlotStatusId)) {
				toast.error("No plots were selected", {
					toastId: toastPlotStatusId
				});
			}
		}
	} else {
		if (!toast.isActive(toastPlotStatusId)) {
			toast.error("No features were selected", {
				toastId: toastPlotStatusId
			});
		}
	}
}

function setSelectedSubsampleStatuses(checkedOptions, notes, draw, timelineRef, flightOptions) {
	let selectedFeatures = draw.current.draw.getSelected().features;
	let flightId = timelineRef.current.currentFlightId;
	if (selectedFeatures.length > 0) {
		let subsampleFeatures = _.filter(selectedFeatures, (feature) => {
			return feature.properties.type === "subsample";
		});
		if (subsampleFeatures.length > 0) {
			_.map(subsampleFeatures, (feature) => {
				let quantifiedRegionId = feature.properties.quantifiedRegionId;
				let trialId = feature.properties.trial;

				let matchingMarkedSubsample = _.findIndex(
					markedSubsamples,
					(ms) =>
						ms.quantifiedRegionId === quantifiedRegionId &&
						((ms.flightId === flightId &&
							new Date(ms.dateAndTime).getTime() === new Date(timelineRef.current.getCurrentFlightDate()).getTime()) ||
							(ms.dateAndTime === checkedOptions.dateAndTime && !ms.isAllData) ||
							(ms.isAllData &&
								checkedOptions.timeframe === "All Data" &&
								ms.quantifiedRegionStatusName === checkedOptions.plotStatusName))
				);

				if (matchingMarkedSubsample !== -1) {
					_.pullAt(markedSubsamples, [matchingMarkedSubsample]);
				}

				let dateAndTime =
					checkedOptions.dateAndTime ??
					(checkedOptions.timeframe !== "All Data"
						? _.find(flightOptions, { flightId: flightId })?.flightDateAndTime
						: null);

				markedSubsamples.push({
					quantifiedRegionId: quantifiedRegionId,
					quantifiedRegionStatusName: checkedOptions.plotStatusName,
					quantifiedRegionStatusId: checkedOptions.plotStatusId,
					isAllData: checkedOptions.timeframe === "All Data",
					dateAndTime: dateAndTime,
					flightId:
						checkedOptions.timeframe === "This Flight Onward" || checkedOptions.timeframe === "One Time"
							? flightId
							: null,
					notes: notes,
					trialId: trialId,
					annotationClassifier: checkedOptions.annotationClassifier,
					oneTime: checkedOptions.plotStatusName === "Excluded" ? checkedOptions.oneTime : false,
					modifiedBy: checkedOptions.modifiedBy,
					modifiedDateTime: checkedOptions.modifiedDateTime
				});
			});

			//-- Apply new boundary colors
			applyNewSubsampleBoundaryColors(subsampleFeatures, draw);
		}
	}
}

const applyNewSubsampleBoundaryColors = (subsampleFeatures, draw, redraw = true) => {
	_.map(subsampleFeatures, (sf) => {
		let allStatusesForSubsample = _.map(
			_.filter(
				markedSubsamples,
				(ms) =>
					ms.quantifiedRegionId === sf.properties.quantifiedRegionId &&
					((new Date(ms.dateAndTime).getTime() <= new Date(sf.properties.flightDate).getTime() &&
						ms.quantifiedRegionStatusName !== "Excluded") ||
						(new Date(ms.dateAndTime).getTime() === new Date(sf.properties.flightDate).getTime() &&
							ms.quantifiedRegionStatusName === "Excluded") ||
						ms.flightId === sf.properties.flightId ||
						ms.isAllData)
			),
			"quantifiedRegionStatusName"
		);

		let matchingSubsampleIndex = _.findIndex(
			subsamples,
			(s) => s.properties.quantifiedRegionId === sf.properties.quantifiedRegionId
		);
		if (matchingSubsampleIndex !== -1) {
			subsamples[matchingSubsampleIndex].properties.portColor = "#42adf5";

			if (allStatusesForSubsample.includes("Excluded") || allStatusesForSubsample.includes("Flagged")) {
				subsamples[matchingSubsampleIndex].properties.defColor = "#ff00ff";
			} else {
				delete subsamples[matchingSubsampleIndex].properties.defColor;
			}

			if (redraw) {
				draw.current.draw.delete(subsamples[matchingSubsampleIndex].id);
				draw.current.draw.add(subsamples[matchingSubsampleIndex]);
			}
		}
	});
};

export function handleApplyStatuses(
	checkedOptions,
	notes,
	draw,
	timelineRef,
	modalRef,
	clientId,
	dispatch,
	getTokenSilently,
	canvas,
	controlComponentRefs,
	navigationFlightId = null,
	flightOptions,
	queryClient
) {
	let selectedFeatures = draw.current.draw.getSelected().features;
	let featureTypes = _.map(selectedFeatures, "properties.type");
	let subsample = _.some(featureTypes, (ft) => ft === "subsample");

	if (subsample) {
		applySubsampleStatuses(
			checkedOptions,
			notes,
			draw,
			timelineRef,
			modalRef,
			clientId,
			dispatch,
			getTokenSilently,
			canvas,
			controlComponentRefs,
			flightOptions
		);
	} else {
		applyPlotStatuses(
			checkedOptions,
			notes,
			draw,
			timelineRef,
			modalRef,
			clientId,
			dispatch,
			getTokenSilently,
			canvas,
			controlComponentRefs,
			navigationFlightId,
			flightOptions
		);
	}

	queryClient.invalidateQueries({ queryKey: ["annotation data"] });
}

export async function applyPlotStatuses(
	checkedOptions,
	notes,
	draw,
	timelineRef,
	modalRef,
	clientId,
	dispatch,
	getTokenSilently,
	canvas,
	controlComponentRefs,
	navigationFlightId = null,
	flightOptions
) {
	let toastPlotStatusId = "plot-status";
	let flightId = navigationFlightId ?? timelineRef.current.currentFlightId;
	let selectedFeatures = draw.current.draw.getSelected().features;
	if (selectedFeatures.length > 0) {
		let plotFeatures = _.filter(selectedFeatures, (feature) => {
			return feature.properties.type === "plot";
		});
		if (plotFeatures.length > 0) {
			const accessToken = await getTokenSilently();
			let plotIds = _.map(plotFeatures, "id");
			let plotTrialPairs = _.map(plotFeatures, (pfs) => ({ plotId: pfs.id, trialId: pfs.properties.trial }));
			if (plotFeatures[0].properties.plotId) {
				plotIds = _.map(_.map(plotFeatures, "properties"), "plotId");
			}
			dispatch(
				markedPlotActions.updateMarkedPlots(accessToken, {
					clientId: clientId,
					flightId: flightId,
					plotNotes: notes,
					plotStatusId: checkedOptions.plotStatusId,
					timeframe: checkedOptions.timeframe,
					dateAndTime: checkedOptions.dateAndTime,
					plotTrialPairs: plotTrialPairs,
					plotIds: plotIds,
					annotationClassId: checkedOptions.annotationClassId
				})
			)
				.then(() => {
					setSelectedPlotStatuses(checkedOptions, notes, draw, timelineRef, navigationFlightId, flightOptions);
					if (defMarkersToggle) {
						defMarkerToggle(canvas, controlComponentRefs);
						defMarkerToggle(canvas, controlComponentRefs);
					}

					controlComponentRefs?.heatmapTimelineRef?.current?.getPlotAnalysisResults();
					toast.success("Plot statuses updated successfully.");

					modalRef.current.closeModal();
				})
				.catch((err) => {
					toast.error(err);
					modalRef.current.closeModal();
				});
		} else {
			if (!toast.isActive(toastPlotStatusId)) {
				toast.error("No plots were selected", {
					toastId: toastPlotStatusId
				});
			}
			modalRef.current.closeModal();
		}
	} else {
		if (!toast.isActive(toastPlotStatusId)) {
			toast.error("No features were selected", {
				toastId: toastPlotStatusId
			});
		}
		modalRef.current.closeModal();
	}
}

export async function applySubsampleStatuses(
	checkedOptions,
	notes,
	draw,
	timelineRef,
	modalRef,
	clientId,
	dispatch,
	getTokenSilently,
	canvas,
	controlComponentRefs,
	flightOptions
) {
	let toastSubsampleStatusId = "subsample-status";
	let flightId = timelineRef.current.currentFlightId;
	let selectedFeatures = draw.current.draw.getSelected().features;
	if (selectedFeatures.length > 0) {
		let subsampleFeatures = _.filter(selectedFeatures, (feature) => {
			return feature.properties.type === "subsample";
		});
		if (subsampleFeatures.length > 0) {
			let subsampleIds = _.map(subsampleFeatures, "properties.quantifiedRegionId");
			let plotTrialPairs = _.map(subsampleFeatures, (pfs) => ({
				plotId: pfs.properties.plotId,
				trialId: pfs.properties.trial
			}));

			const accessToken = await getTokenSilently();
			dispatch(
				markedPlotActions.updateMarkedPlots(accessToken, {
					clientId: clientId,
					flightId: flightId,
					quantifiedRegionNotes: notes,
					quantifiedRegionStatusId: checkedOptions.plotStatusId,
					dateAndTime: checkedOptions.dateAndTime,
					timeframe: checkedOptions.timeframe,
					plotTrialPairs: plotTrialPairs,
					quantifiedRegionIds: subsampleIds,
					annotationClassId: checkedOptions.annotationClassId
				})
			)
				.then(() => {
					setSelectedSubsampleStatuses(checkedOptions, notes, draw, timelineRef, flightOptions);

					if (defMarkersToggle) {
						defMarkerToggle(canvas, controlComponentRefs);
						defMarkerToggle(canvas, controlComponentRefs);
					}

					controlComponentRefs?.heatmapTimelineRef?.current?.getPlotAnalysisResults();
					toast.success("Subsample statuses updated successfully.");

					modalRef.current.closeModal();
				})
				.catch((err) => {
					toast.error(err);
					modalRef.current.closeModal();
				});
		} else {
			if (!toast.isActive(toastSubsampleStatusId)) {
				toast.error("No subsamples were selected", {
					toastId: toastSubsampleStatusId
				});
			}
			modalRef.current.closeModal();
		}
	} else {
		if (!toast.isActive(toastSubsampleStatusId)) {
			toast.error("No features were selected", {
				toastId: toastSubsampleStatusId
			});
		}
		modalRef.current.closeModal();
	}
}

export function getMarkedPlots() {
	return markedPlots;
}

export function getMarkedPlotOptions() {
	return markedPlotOptions;
}

export function getAnnotationClassOptions() {
	return annotationClassOptions;
}

export function getMarkedSubsamples() {
	return markedSubsamples;
}

export function setCurrentFlightId(id) {
	currentFlightId = id;
}

export function getCurrentFlightId() {
	return currentFlightId;
}

export async function alignPlots(
	draw,
	canvas,
	orthoImageTypeId,
	moduleNavigation,
	dispatch,
	clientId,
	getTokenSilently,
	modalRef,
	controlComponentRefs
) {
	let selectedFeatures = draw.current.draw.getSelected().features;

	let fieldFeatures = _.filter(selectedFeatures, (f) => f.properties.type === "field");

	if (fieldFeatures.length > 1) {
		toast.error("Can only align one field at a time.");
		modalRef.current.setLoading(false);
	} else if (fieldFeatures.length === 0 || selectedFeatures.length === 0) {
		toast.error("No fields were selected.");
		modalRef.current.setLoading(false);
	} else {
		let fieldFeature = _.find(allFields, (f) => f.properties.field === fieldFeatures[0].properties.field);

		if (fieldFeature.properties.locked) {
			toast.error("Cannot align locked fields.");
			modalRef.current.setLoading(false);
		} else {
			uncombinePlotsInField(draw, false);
			let pageSize = 0;

			let objectToPass = {
				flightId: moduleNavigation.flightId,
				orthoImageTypeId: orthoImageTypeId,
				numberOfRows: fieldFeature.properties.plots[0].properties.fieldRowCount,
				fieldRangeLength: fieldFeature.properties.plots[0].properties.fieldRangeLength,
				fieldColumnWidth: fieldFeature.properties.plots[0].properties.fieldColWidth,
				fieldPlanterOffset: fieldFeature.properties.plots[0].properties.fieldPlanterOffset,
				fieldRowSpacing: fieldFeature.properties.plots[0].properties.fieldRowSpacing,
				scaleFactor: fieldFeature.properties.scaleFactor,
				rangeData: [],
				plots: []
			};

			let j = 0;
			let ranges = [];
			let splitRangeData = [[]];
			let splitPlots = [[]];

			for (let i = 1; i < fieldFeature.properties.plots[0].properties.ranges + 1; i++) {
				let rangeFeatures = {
					type: "FeatureCollection",
					features: _.filter(fieldFeature.properties.plots, (p) => p.properties.range == i)
				};
				let rangeBbox = turf.bbox(rangeFeatures);

				let rangeBboxPolygon = turf.bboxPolygon(rangeBbox);
				pageSize += turf.area(rangeBboxPolygon);

				if (pageSize > 20000) {
					j++;
					splitRangeData.push([]);
					splitPlots.push([]);
					pageSize = 0;
				}

				let lowerLeft = [
					rangeBboxPolygon.geometry.coordinates[0][3][0],
					rangeBboxPolygon.geometry.coordinates[0][3][1]
				];
				let upperLeft = [
					rangeBboxPolygon.geometry.coordinates[0][0][0],
					rangeBboxPolygon.geometry.coordinates[0][0][1]
				];
				let lowerRight = [
					rangeBboxPolygon.geometry.coordinates[0][2][0],
					rangeBboxPolygon.geometry.coordinates[0][2][1]
				];
				let upperRight = [
					rangeBboxPolygon.geometry.coordinates[0][1][0],
					rangeBboxPolygon.geometry.coordinates[0][1][1]
				];

				let rangeToAdd = {
					range: i,
					llLat: lowerLeft[1],
					llLong: lowerLeft[0],
					ulLat: upperLeft[1],
					ulLong: upperLeft[0],
					urLat: upperRight[1],
					urLong: upperRight[0],
					lrLat: lowerRight[1],
					lrLong: lowerRight[0]
				};

				splitRangeData[j].push(rangeToAdd);
				ranges[i] = j;
			}

			_.map(fieldFeature.properties.plots, (p) => {
				let plot = _.find(allPlots, (ap) => ap.id == p.id);

				let coordinates = plot.geometry.coordinates[0];

				let plotToAdd = {
					column: plot.properties.column,
					range: plot.properties.range,
					llLat: coordinates[0][1],
					llLong: coordinates[0][0],
					ulLat: coordinates[1][1],
					ulLong: coordinates[1][0],
					urLat: coordinates[2][1],
					urLong: coordinates[2][0],
					lrLat: coordinates[3][1],
					lrLong: coordinates[3][0]
				};

				splitPlots[ranges[plot.properties.range]].push(plotToAdd);
			});

			const accessToken = await getTokenSilently();

			Promise.all(
				_.map(splitRangeData, (_v, i) => {
					objectToPass.rangeData = splitRangeData[i];
					objectToPass.plots = splitPlots[i];
					dispatch(autoAlignActions.alignPlots(objectToPass, clientId, accessToken))
						.then((res) => {
							_.map(res.plots, (plot) => {
								let matchingPlot = _.find(
									allPlots,
									(p) =>
										p.properties.field == fieldFeatures[0].properties.field &&
										p.properties.range == plot.range &&
										p.properties.column == plot.column
								);

								if (matchingPlot) {
									matchingPlot.geometry.coordinates = [
										[
											[plot.ll.long, plot.ll.lat],
											[plot.ul.long, plot.ul.lat],
											[plot.ur.long, plot.ur.lat],
											[plot.lr.long, plot.lr.lat],
											[plot.ll.long, plot.ll.lat]
										]
									];

									if (plot.failed) {
										matchingPlot.properties.alignColor = "#648FFF";
									} else if (plot.overRotated) {
										matchingPlot.properties.alignColor = "#FFB000";
									} else if (plot.confidence <= 0.1) {
										matchingPlot.properties.alignColor = "#DC267F";
									} else if (plot.confidence > 0.1 && plot.confidence <= 0.2) {
										matchingPlot.properties.alignColor = "#FE6100";
									} else if (plot.confidence > 0.2 && plot.confidence <= 0.3) {
										matchingPlot.properties.alignColor = "#FFB000";
									}
								}
							});

							controlComponentRefs.alignPlotsRef.current.setDisabled(true);
							refreshToggles(draw, canvas, controlComponentRefs);

							toast.success("Aligned plots successfully!");

							modalRef.current.setLoading(false);
							modalRef.current.setModalOpen(false);
						})
						.catch((err) => {
							console.log(err);
							toast.error("An error occurred while aligning plots.");
							modalRef.current.setLoading(false);
						});
				})
			);
		}
	}
}

export function validateSelectedField(draw) {
	if (draw?.current) {
		let selectedFeatures = draw.current.draw.getSelected().features;
		let fieldFeatures = _.filter(selectedFeatures, (f) => f.properties.type === "field");

		if (fieldFeatures.length > 1) {
			toast.error("Can only edit one field at a time.");
		} else if (fieldFeatures.length === 0 || selectedFeatures.length === 0) {
			toast.error("No fields were selected.");
		} else {
			let fieldFeature = _.find(allFields, (f) => f.properties.field === fieldFeatures[0].properties.field);

			return fieldFeature.properties.field;
		}
	}
}
export async function scalePlots(
	draw,
	canvas,
	orthoImageTypeId,
	moduleNavigation,
	dispatch,
	clientId,
	getTokenSilently,
	modalRef,
	controlComponentRefs
) {
	let selectedFeatures = draw.current.draw.getSelected().features;

	let fieldFeatures = _.filter(selectedFeatures, (f) => f.properties.type === "field");

	if (fieldFeatures.length > 1) {
		toast.error("Can only scale one field at a time.");
		modalRef.current.setLoading(false);
	} else if (fieldFeatures.length === 0 || selectedFeatures.length === 0) {
		toast.error("No fields were selected.");
		modalRef.current.setLoading(false);
	} else {
		let fieldFeature = _.find(allFields, (f) => f.properties.field === fieldFeatures[0].properties.field);

		if (fieldFeature.properties.locked) {
			toast.error("Cannot scale locked fields.");
			modalRef.current.setLoading(false);
		} else {
			uncombinePlotsInField(draw, false);

			let objectToPass = {
				flightId: moduleNavigation.flightId,
				orthoImageTypeId: orthoImageTypeId,
				numberOfRows: fieldFeature.properties.plots[0].properties.fieldRowCount,
				fieldRangeLength: fieldFeature.properties.plots[0].properties.fieldRangeLength,
				fieldColumnWidth: fieldFeature.properties.plots[0].properties.fieldColWidth,
				fieldPlanterOffset: fieldFeature.properties.plots[0].properties.fieldPlanterOffset,
				fieldRowSpacing: fieldFeature.properties.plots[0].properties.fieldRowSpacing,
				scaleFactor: fieldFeature.properties.scaleFactor,
				rangeData: [],
				plots: []
			};

			for (let i = 1; i < fieldFeature.properties.plots[0].properties.ranges + 1; i++) {
				let rangeFeatures = {
					type: "FeatureCollection",
					features: _.filter(fieldFeature.properties.plots, (p) => p.properties.range == i)
				};
				let rangeBbox = turf.bbox(rangeFeatures);

				let rangeBboxPolygon = turf.bboxPolygon(rangeBbox);
				let lowerLeft = [
					rangeBboxPolygon.geometry.coordinates[0][3][0],
					rangeBboxPolygon.geometry.coordinates[0][3][1]
				];
				let upperLeft = [
					rangeBboxPolygon.geometry.coordinates[0][0][0],
					rangeBboxPolygon.geometry.coordinates[0][0][1]
				];
				let lowerRight = [
					rangeBboxPolygon.geometry.coordinates[0][2][0],
					rangeBboxPolygon.geometry.coordinates[0][2][1]
				];
				let upperRight = [
					rangeBboxPolygon.geometry.coordinates[0][1][0],
					rangeBboxPolygon.geometry.coordinates[0][1][1]
				];

				let rangeToAdd = {
					range: i,
					llLat: lowerLeft[1],
					llLong: lowerLeft[0],
					ulLat: upperLeft[1],
					ulLong: upperLeft[0],
					urLat: upperRight[1],
					urLong: upperRight[0],
					lrLat: lowerRight[1],
					lrLong: lowerRight[0]
				};

				objectToPass.rangeData.push(rangeToAdd);
			}

			_.map(fieldFeature.properties.plots, (p) => {
				let plot = _.find(allPlots, (ap) => ap.id == p.id);

				let coordinates = plot.geometry.coordinates[0];

				let plotToAdd = {
					column: plot.properties.column,
					range: plot.properties.range,
					llLat: coordinates[0][1],
					llLong: coordinates[0][0],
					ulLat: coordinates[1][1],
					ulLong: coordinates[1][0],
					urLat: coordinates[2][1],
					urLong: coordinates[2][0],
					lrLat: coordinates[3][1],
					lrLong: coordinates[3][0]
				};

				objectToPass.plots.push(plotToAdd);
			});

			const accessToken = await getTokenSilently();
			dispatch(autoAlignActions.scalePlots(objectToPass, clientId, accessToken))
				.then((res) => {
					_.map(res.plots, (plot) => {
						let matchingPlot = _.find(
							allPlots,
							(p) =>
								p.properties.field == fieldFeatures[0].properties.field &&
								p.properties.range == plot.range &&
								p.properties.column == plot.column
						);

						if (matchingPlot) {
							matchingPlot.geometry.coordinates = [
								[
									[plot.ll.long, plot.ll.lat],
									[plot.ul.long, plot.ul.lat],
									[plot.ur.long, plot.ur.lat],
									[plot.lr.long, plot.lr.lat],
									[plot.ll.long, plot.ll.lat]
								]
							];
							matchingPlot.properties.scaleFactor = res.scale_factor;

							if (plot.failed) {
								matchingPlot.properties.alignColor = "#648FFF";
							} else if (plot.overRotated) {
								matchingPlot.properties.alignColor = "#FFB000";
							} else if (plot.confidence <= 0.1) {
								matchingPlot.properties.alignColor = "#DC267F";
							} else if (plot.confidence > 0.1 && plot.confidence <= 0.2) {
								matchingPlot.properties.alignColor = "#FE6100";
							} else if (plot.confidence > 0.2 && plot.confidence <= 0.3) {
								matchingPlot.properties.alignColor = "#FFB000";
							}
						}
					});

					controlComponentRefs.alignPlotsRef.current.setDisabled(true);
					refreshToggles(draw, canvas, controlComponentRefs);

					toast.success("Scaled plots successfully!");

					modalRef.current.setLoading(false);
					modalRef.current.setModalOpen(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("An error occurred while scaling plots.");
					modalRef.current.setLoading(false);
				});
		}
	}
}

export async function scaleAndAlignPlots(
	draw,
	canvas,
	orthoImageTypeId,
	moduleNavigation,
	dispatch,
	clientId,
	getTokenSilently,
	modalRef,
	controlComponentRefs
) {
	let selectedFeatures = draw.current.draw.getSelected().features;

	let fieldFeatures = _.filter(selectedFeatures, (f) => f.properties.type === "field");

	if (fieldFeatures.length > 1) {
		toast.error("Can only scale one field at a time.");
		modalRef.current.setLoading(false);
	} else if (fieldFeatures.length === 0 || selectedFeatures.length === 0) {
		toast.error("No fields were selected.");
		modalRef.current.setLoading(false);
	} else {
		let fieldFeature = _.find(allFields, (f) => f.properties.field === fieldFeatures[0].properties.field);

		if (fieldFeature.properties.locked) {
			toast.error("Cannot scale locked fields.");
			modalRef.current.setLoading(false);
		} else {
			uncombinePlotsInField(draw, false);

			let objectToPass = {
				flightId: moduleNavigation.flightId,
				orthoImageTypeId: orthoImageTypeId,
				numberOfRows: fieldFeature.properties.plots[0].properties.fieldRowCount,
				fieldRangeLength: fieldFeature.properties.plots[0].properties.fieldRangeLength,
				fieldColumnWidth: fieldFeature.properties.plots[0].properties.fieldColWidth,
				fieldPlanterOffset: fieldFeature.properties.plots[0].properties.fieldPlanterOffset,
				fieldRowSpacing: fieldFeature.properties.plots[0].properties.fieldRowSpacing,
				scaleFactor: fieldFeature.properties.scaleFactor,
				rangeData: [],
				plots: []
			};

			for (let i = 1; i < fieldFeature.properties.plots[0].properties.ranges + 1; i++) {
				let rangeFeatures = {
					type: "FeatureCollection",
					features: _.filter(fieldFeature.properties.plots, (p) => p.properties.range == i)
				};
				let rangeBbox = turf.bbox(rangeFeatures);

				let rangeBboxPolygon = turf.bboxPolygon(rangeBbox);
				let lowerLeft = [
					rangeBboxPolygon.geometry.coordinates[0][3][0],
					rangeBboxPolygon.geometry.coordinates[0][3][1]
				];
				let upperLeft = [
					rangeBboxPolygon.geometry.coordinates[0][0][0],
					rangeBboxPolygon.geometry.coordinates[0][0][1]
				];
				let lowerRight = [
					rangeBboxPolygon.geometry.coordinates[0][2][0],
					rangeBboxPolygon.geometry.coordinates[0][2][1]
				];
				let upperRight = [
					rangeBboxPolygon.geometry.coordinates[0][1][0],
					rangeBboxPolygon.geometry.coordinates[0][1][1]
				];

				let rangeToAdd = {
					range: i,
					llLat: lowerLeft[1],
					llLong: lowerLeft[0],
					ulLat: upperLeft[1],
					ulLong: upperLeft[0],
					urLat: upperRight[1],
					urLong: upperRight[0],
					lrLat: lowerRight[1],
					lrLong: lowerRight[0]
				};

				objectToPass.rangeData.push(rangeToAdd);
			}

			_.map(fieldFeature.properties.plots, (p) => {
				let plot = _.find(allPlots, (ap) => ap.id == p.id);

				let coordinates = plot.geometry.coordinates[0];

				let plotToAdd = {
					column: plot.properties.column,
					range: plot.properties.range,
					llLat: coordinates[0][1],
					llLong: coordinates[0][0],
					ulLat: coordinates[1][1],
					ulLong: coordinates[1][0],
					urLat: coordinates[2][1],
					urLong: coordinates[2][0],
					lrLat: coordinates[3][1],
					lrLong: coordinates[3][0]
				};

				objectToPass.plots.push(plotToAdd);
			});

			const accessToken = await getTokenSilently();
			dispatch(autoAlignActions.scaleAndAlignPlots(objectToPass, clientId, accessToken))
				.then((res) => {
					_.map(res.plots, (plot) => {
						let matchingPlot = _.find(
							allPlots,
							(p) =>
								p.properties.field == fieldFeatures[0].properties.field &&
								p.properties.range == plot.range &&
								p.properties.column == plot.column
						);

						if (matchingPlot) {
							matchingPlot.geometry.coordinates = [
								[
									[plot.ll.long, plot.ll.lat],
									[plot.ul.long, plot.ul.lat],
									[plot.ur.long, plot.ur.lat],
									[plot.lr.long, plot.lr.lat],
									[plot.ll.long, plot.ll.lat]
								]
							];
							matchingPlot.properties.scaleFactor = res.scale_factor;

							if (plot.failed) {
								matchingPlot.properties.alignColor = "#648FFF";
							} else if (plot.overRotated) {
								matchingPlot.properties.alignColor = "#FFB000";
							} else if (plot.confidence <= 0.1) {
								matchingPlot.properties.alignColor = "#DC267F";
							} else if (plot.confidence > 0.1 && plot.confidence <= 0.2) {
								matchingPlot.properties.alignColor = "#FE6100";
							} else if (plot.confidence > 0.2 && plot.confidence <= 0.3) {
								matchingPlot.properties.alignColor = "#FFB000";
							}
						}
					});

					controlComponentRefs.alignPlotsRef.current.setDisabled(true);
					refreshToggles(draw, canvas, controlComponentRefs);

					toast.success("Scaled and aligned plots successfully!");

					modalRef.current.setLoading(false);
					modalRef.current.setModalOpen(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("An error occurred while scaling and aligning plots.");
					modalRef.current.setLoading(false);
				});
		}
	}
}

function getTileUrl() {
	return tilesetIds.map(
		(tilesetId) =>
			`${process.env.MAPBOX_TILE_URL}${tilesetId}/{z}/{x}/{y}.jpg?access_token=${process.env.MAPBOX_ACCESS_TOKEN}`
	);
}

export function setDefaultShader(shader) {
	activeShader = shader;
}

export function getActiveShader() {
	return activeShader;
}

export function getMinThreshold() {
	return defaultMinShaderThreshold;
}

export function getMaxThreshold() {
	return defaultMaxShaderThreshold;
}

export function setMinThreshold(min) {
	if (min >= 0 && min < defaultMaxShaderThreshold) defaultMinShaderThreshold = min;
}

export function setMaxThreshold(max) {
	if (max > defaultMinShaderThreshold && max <= 1) defaultMaxShaderThreshold = max;
}

export function refreshShaderLayer(canvas) {
	defaultMinShaderThreshold = 0;
	defaultMaxShaderThreshold = 1;

	switch (activeShader) {
		case "thermal":
			applyThermalShader(canvas);
			break;
		case "ryg":
			applyRYGShader(canvas);
			break;
		case "gfb":
			applyGreenFireBlueShader(canvas);
			break;
		case "spectral":
			applySpectralShader(canvas);
			break;
		case "by":
			applyBlueYellowShader(canvas);
			break;
		case "clamped":
			applyClampedShader(canvas);
			break;
		default:
			break;
	}
}

export function removeShader(canvas) {
	let deckGlLayerIdList = _.map(canvas.__deck?.layerManager?.getLayers(), "id");

	for (let deckGlLayerId of deckGlLayerIdList) {
		if (_.some(Object.keys(shaderLayers), (code) => deckGlLayerId.includes(`shader-${code}-layer`))) {
			canvas.removeLayer(deckGlLayerId);
		}
	}

	activeShader = "";

	lastAppliedShader = (minThreshold, maxThreshold) => {
		minThreshold, maxThreshold;
	};
}

function applyShader(canvas, minThreshold, maxThreshold, shaderLayerCode) {
	removeShader(canvas);

	const tileUrls = getTileUrl();
	for (let tileUrl of tileUrls) {
		let layerId = `shader-${shaderLayerCode}-layer-${uuid.v4()}`;

		let data = {
			tileUrl: tileUrl,
			min: minThreshold ?? defaultMinShaderThreshold,
			max: maxThreshold ?? defaultMaxShaderThreshold,
			layerId: layerId
		};

		if (minThreshold !== null && minThreshold !== undefined) {
			defaultMinShaderThreshold = minThreshold;
		}

		if (maxThreshold !== null && maxThreshold !== undefined) {
			defaultMaxShaderThreshold = maxThreshold;
		}

		canvas.addLayer(
			new MapboxLayer({ id: layerId, type: shaderLayers[shaderLayerCode], data: data }),
			"gl-draw-point-point-stroke-inactive.cold"
		);
	}

	activeShader = shaderLayerCode;

	lastAppliedShader = (minThreshold, maxThreshold) => {
		applyShader(canvas, minThreshold, maxThreshold, shaderLayerCode);
	};
}

export function applyThermalShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "thermal");
}

export function applyRYGShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "ryg");
}

export function applyGreenFireBlueShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "gfb");
}

export function applySpectralShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "spectral");
}

export function applyBlueYellowShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "by");
}

export function applyClampedShader(canvas, minThreshold, maxThreshold) {
	applyShader(canvas, minThreshold, maxThreshold, "clamped");
}

export function applyLastAppliedShader(minThreshold, maxThreshold) {
	return lastAppliedShader(minThreshold, maxThreshold);
}

export function MeasureDistanceToggle(draw, measureDistanceRef) {
	clearMeasurements(draw, measureDistanceRef);
	measureDistanceRef.current.setActive(true);
	draw.current.draw.changeMode("line_measure", {});
}

function measureLineDistance(line, canvas) {
	const lineDistance = Math.round(turf.length(line, { units: "feet" }) * 100) / 100;
	plotPopup?.remove();
	plotPopup = new MapboxGl.Popup({ closeButton: false, closeOnClick: false })
		.setLngLat(line.geometry.coordinates.at(-1))
		.setHTML(
			`<div class="distance-container">
						${lineDistance + " feet"}
						</div>`
		)
		.addTo(canvas);
}

export function clearMeasurements(draw, measureDistanceRef) {
	measureDistanceRef.current.setActive(false);
	if (drawnFeature) {
		draw.current.draw.delete(drawnFeature.id);
		plotPopup?.remove();
		drawnFeature = null;
	}
}

//-- Sets up existing quantified regions
function setupQuantifiedRegions(quantifiedRegions, refs) {
	if (quantifiedRegions?.length > 0) {
		//-- Clear existing list of subsamples
		//-- Need this when switching between flights
		subsamples = [];

		const maxColumns = {};
		const firstPlotQrs = _.filter(quantifiedRegions, { plotId: quantifiedRegions[0].plotId });
		const splitFirstPlotQrs = _.groupBy(firstPlotQrs, "quantifiedRegionTypeId");
		_.map(Object.keys(splitFirstPlotQrs), (type) => {
			maxColumns[type] = _.maxBy(splitFirstPlotQrs[type], "column").column;
		});

		_.map(quantifiedRegions, (qr) => {
			const { name: plotName, scaleFactor, trial } = _.find(allPlots, { id: qr.plotId })?.properties;

			//-- Find marked subsample color
			let matchingMarkedSubsamples = _.filter(
				markedSubsamples,
				(ms) =>
					ms.quantifiedRegionId === qr.quantifiedRegionId &&
					((new Date(ms.dateAndTime).getTime() <= new Date(qr.flightDate).getTime() &&
						ms.quantifiedRegionStatusName !== "Excluded") ||
						(new Date(ms.dateAndTime).getTime() === new Date(qr.flightDate).getTime() &&
							ms.quantifiedRegionStatusName === "Excluded") ||
						ms.flightId === qr.flightId ||
						ms.isAllData)
			);

			let quantifiedRegionNumber = maxColumns[qr.quantifiedRegionTypeId] * (qr.range - 1) + qr.column;

			let qrToAdd = {
				id: qr.id === "00000000-0000-0000-0000-000000000000" ? uuid.v4() : qr.id,
				type: "Feature",
				properties: {
					type: "subsample",
					column: qr.column,
					range: qr.range,
					plotId: qr.plotId,
					plotName: plotName,
					quantifiedRegionNumber: quantifiedRegionNumber,
					quantifiedRegionName: `S${
						quantifiedRegionNumber > 9 ? quantifiedRegionNumber : "0" + quantifiedRegionNumber
					}`,
					quantifiedRegionTypeId: qr.quantifiedRegionTypeId,
					quantifiedRegionTypeName: _.find(quantifiedRegionOptions, { id: qr.quantifiedRegionTypeId })?.name ?? null,
					quantifiedRegionId: qr.quantifiedRegionId,
					flightsQuantifiedRegionId: qr.id,
					length: qr.length,
					width: qr.width,
					quantifiedLength: qr.quantifiedLength,
					quantifiedWidth: qr.quantifiedWidth,
					quantifiedRadius: qr.quantifiedRadius,
					quantifiedRegionGeometryName: qr.quantifiedRegionGeometryName,
					fieldQuantifiedRegionId: qr.fieldQuantifiedRegionId,
					flightDate: qr.flightDate,
					flightId: qr.flightId,
					trial: trial,
					scaleFactor: scaleFactor,
					portColor: "#42adf5",
					locked: true
				},
				geometry: {
					type: "Polygon",
					coordinates: [
						[
							[parseFloat(qr.ll.long), parseFloat(qr.ll.lat)],
							[parseFloat(qr.ul.long), parseFloat(qr.ul.lat)],
							[parseFloat(qr.ur.long), parseFloat(qr.ur.lat)],
							[parseFloat(qr.lr.long), parseFloat(qr.lr.lat)],
							[parseFloat(qr.ll.long), parseFloat(qr.ll.lat)]
						]
					]
				}
			};

			let allStatusesForSubsample = _.map(matchingMarkedSubsamples, "quantifiedRegionStatusName");
			if (allStatusesForSubsample.includes("Excluded") || allStatusesForSubsample.includes("Flagged")) {
				qrToAdd.properties.defColor = "#ff00ff";
			}

			subsamples.push(qrToAdd);
		});

		if (refs.quantifiedRegionToggleRef?.current) {
			updateQrToggleList(refs);
		}
	}
}

export function getSubsamples() {
	return subsamples;
}

export function handleModifyQrFromFieldModal(fieldId, qrModifyList, qrDeleteList) {
	if (fieldId) {
		if (qrModifyList?.length > 0) {
			//-- Remove all modified quantified regions from subsamplesToAdd and subsamples lists
			let fieldQuantifiedRegionIdsToModify = _.uniqBy(qrModifyList, "fieldQuantifiedRegionId");
			subsamplesToAdd = _.filter(
				subsamplesToAdd,
				(sta) => !fieldQuantifiedRegionIdsToModify.includes(sta.properties.fieldQuantifiedRegionId)
			);

			subsamples = _.filter(
				subsamples,
				(s) => !fieldQuantifiedRegionIdsToModify.includes(s.properties.fieldQuantifiedRegionId)
			);

			//-- Re-add quantified regions to be redrawn on save
			subsamplesToAdd = [...subsamplesToAdd, ...qrModifyList];

			//-- Make plots dirty so the unsaved changes popup triggers
			let plotIdsToDirty = _.map(_.uniqBy(qrModifyList, "plotId"), "plotId");
			_.map(allPlots, (p) => {
				if (plotIdsToDirty.includes(p.id)) {
					p.properties.isDirty = true;
				}
			});
		}

		if (qrDeleteList?.length > 0) {
			subsamplesToAdd = _.filter(
				subsamplesToAdd,
				(sta) => !qrDeleteList.includes(sta.properties.fieldQuantifiedRegionId)
			);
		}
	}
}

function updateQrToggleList(refs) {
	let uniqueQrTypeIds = _.map(
		_.uniqBy(subsamples, (s) => s.properties.quantifiedRegionTypeId),
		"properties.quantifiedRegionTypeId"
	);
	let uniqueQrTypeNames = _.map(
		_.filter(quantifiedRegionOptions, (qro) => uniqueQrTypeIds.includes(qro.id)),
		"name"
	);

	refs.quantifiedRegionToggleRef.current.setActiveList(uniqueQrTypeNames);
}

export function quantifiedRegionToggle(draw, refresh, qr) {
	let choice = qr;

	if (!choice) {
		choice = pcQrToggle
			? "Plot-Centered with Subsamples"
			: rcQrToggle
			? "Row-Centered"
			: brQrToggle
			? "Between-Row"
			: frQrToggle
			? "Full Row"
			: scQrToggle
			? "Stand Count"
			: "Plot-Centered";
	}

	if (choice === "Plot-Centered with Subsamples") {
		plotCenteredWithSubsamplesQrToggle(draw);
		if (refresh) plotCenteredWithSubsamplesQrToggle(draw);

		//-- These turn off other toggles if they were active
		if (pcToggle) plotCenteredQrToggle(draw);
		if (rcQrToggle) rowCenteredQrToggle(draw);
		if (brQrToggle) betweenRowQrToggle(draw);
		if (frQrToggle) fullRowQrToggle(draw);
		if (scQrToggle) standCountQrToggle(draw);
	} else if (choice === "Row-Centered") {
		rowCenteredQrToggle(draw);
		if (refresh) rowCenteredQrToggle(draw);

		if (pcToggle) plotCenteredQrToggle(draw);
		if (pcQrToggle) plotCenteredWithSubsamplesQrToggle(draw);
		if (brQrToggle) betweenRowQrToggle(draw);
		if (frQrToggle) fullRowQrToggle(draw);
		if (scQrToggle) standCountQrToggle(draw);
	} else if (choice === "Between-Row") {
		betweenRowQrToggle(draw);
		if (refresh) betweenRowQrToggle(draw);

		if (pcToggle) plotCenteredQrToggle(draw);
		if (pcQrToggle) plotCenteredWithSubsamplesQrToggle(draw);
		if (rcQrToggle) rowCenteredQrToggle(draw);
		if (frQrToggle) fullRowQrToggle(draw);
		if (scQrToggle) standCountQrToggle(draw);
	} else if (choice === "Full Row") {
		fullRowQrToggle(draw);
		if (refresh) fullRowQrToggle(draw);

		if (pcToggle) plotCenteredQrToggle(draw);
		if (pcQrToggle) plotCenteredWithSubsamplesQrToggle(draw);
		if (rcQrToggle) rowCenteredQrToggle(draw);
		if (brQrToggle) betweenRowQrToggle(draw);
		if (scQrToggle) standCountQrToggle(draw);
	} else if (choice === "Plot-Centered") {
		plotCenteredQrToggle(draw);
		if (refresh) plotCenteredQrToggle(draw);

		if (pcQrToggle) plotCenteredWithSubsamplesQrToggle(draw);
		if (rcQrToggle) rowCenteredQrToggle(draw);
		if (brQrToggle) betweenRowQrToggle(draw);
		if (frQrToggle) fullRowQrToggle(draw);
		if (scQrToggle) standCountQrToggle(draw);
	} else if (choice === "Stand Count") {
		standCountQrToggle(draw);
		if (refresh) standCountQrToggle(draw);

		if (pcToggle) plotCenteredQrToggle(draw);
		if (pcQrToggle) plotCenteredWithSubsamplesQrToggle(draw);
		if (rcQrToggle) rowCenteredQrToggle(draw);
		if (brQrToggle) betweenRowQrToggle(draw);
		if (frQrToggle) fullRowQrToggle(draw);
	}
}

export function plotCenteredQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Plot-Centered" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		//-- Removes legacy subsamples without coords
		filteredQrs = _.filter(filteredQrs, (qr) => !isNaN(qr.geometry.coordinates[0][0][0]));

		if (!pcToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		pcToggle = !pcToggle;
	}
}

export function plotCenteredWithSubsamplesQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Plot-Centered with Subsamples" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		if (!pcQrToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		pcQrToggle = !pcQrToggle;
	}
}

export function setPlotAnalysisResults(values) {
	plotAnalysisResults = values;
}

export function betweenRowQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Between-Row" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		if (!brQrToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		brQrToggle = !brQrToggle;
	}
}

export function rowCenteredQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Row-Centered" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		if (!rcQrToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		rcQrToggle = !rcQrToggle;
	}
}

export function fullRowQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Full Row" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		if (!frQrToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		frQrToggle = !frQrToggle;
	}
}

function standCountQrToggle(draw) {
	if (draw?.current) {
		let pcTypeId = _.find(quantifiedRegionOptions, { name: "Stand Count" })?.id;
		//-- Find all subsamples and drawn subsamples with the same type id
		let filteredQrs = _.filter(
			[...subsamples, ...draw.current.draw.getAll().features],
			(s) => s.properties.quantifiedRegionTypeId === pcTypeId
		);

		if (!scQrToggle) {
			let qrsToAdd = {
				type: "FeatureCollection",
				features: filteredQrs
			};

			draw.current.draw.add(qrsToAdd);
		} else {
			draw.current.draw.delete(_.map(filteredQrs, "id"));
		}

		scQrToggle = !scQrToggle;
	}
}

export function heatmapOverlayShading(draw, canvas, controlComponentRefs) {
	let plotsToggled = controlComponentRefs.plotToggleRef.current.checkToggleState;

	//-- Don't turn on if plots aren't on
	//---- & remove any trace of this function
	if (!plotsToggled) {
		heatmapOverlayLabelsToggled = true;

		//-- Create list of plots to search from
		//---- Filter as needed
		let plotsToSearch = allPlots;
		plotsToSearch = _.filter(plotsToSearch, (p) => p.properties.heatmapColor);

		//-- This updates the plot metadata to not show heatmap when plots are re-toggled
		_.map(plotsToSearch, (p) => {
			let matchingPlotIndex = _.findIndex(allPlots, { id: p.id });

			if (matchingPlotIndex !== -1) {
				allPlots[matchingPlotIndex].properties.heatmapOpacity = 0;
			}
		});
	} else if (!heatmapOverlayShadingToggled) {
		if (plotAnalysisResults?.length > 0 || defaultViewerSetting) {
			//-- Make sure all plots are uncombined
			uncombinePlotsInField(draw);

			//-- Turn off ortho shaders
			removeShader(canvas);

			//-- Color gradient taken from heatmap page
			const minValue = Math.min(
				..._.map(
					_.filter(
						plotAnalysisResults,
						(par) => par.plotAnalysisResultValue !== 0 && par.plotAnalysisResultValue !== null
					),
					"plotAnalysisResultValue"
				)
			);
			const maxValue = Math.max(
				..._.map(
					_.filter(
						plotAnalysisResults,
						(par) => par.plotAnalysisResultValue !== 0 && par.plotAnalysisResultValue !== null
					),
					"plotAnalysisResultValue"
				)
			);
			const midValue = median(
				_.map(
					_.filter(
						plotAnalysisResults,
						(par) => par.plotAnalysisResultValue !== 0 && par.plotAnalysisResultValue !== null
					),
					"plotAnalysisResultValue"
				)
			);

			const cg = new colorGradient(minValue, midValue, maxValue);

			//-- Apply coloring to all plots with analysis data
			_.map(plotAnalysisResults, (par) => {
				let rgb =
					!par.excluded && par.plotAnalysisResultValue !== null
						? cg.getRgbValuesAtValue(par.plotAnalysisResultValue)
						: [127, 127, 127];
				let heatmapColor = rgbToHex(rgb);

				let matchingPlotIndex = _.findIndex(allPlots, { id: par.plotId });

				//-- Set plot color & opacity metadata
				if (matchingPlotIndex !== -1) {
					allPlots[matchingPlotIndex].properties.heatmapColor = heatmapColor;
					allPlots[matchingPlotIndex].properties.heatmapOpacity = 0.65;
				}

				//-- Check for existing mapbox feature
				let featureExists = draw.current.draw.get(par.plotId);

				if (featureExists) {
					draw.current.draw.setFeatureProperty(par.plotId, "heatmapColor", heatmapColor);
					draw.current.draw.setFeatureProperty(par.plotId, "heatmapOpacity", 0.65);

					//-- Redrawing plot
					let feature = draw.current.draw.get(par.plotId);
					draw.current.draw.add(feature);
				}
			});

			//-- Apply shading changes to plots without results
			_.map(
				_.filter(allPlots, (ap) => ap.properties.heatmapOpacity !== 0.65),
				(ap) => {
					ap.properties.heatmapColor = "#808080";
					ap.properties.heatmapOpacity = 0.65;

					//-- Check for existing mapbox feature
					let featureExists = draw.current.draw.get(ap.id);

					if (featureExists) {
						draw.current.draw.setFeatureProperty(ap.id, "heatmapColor", "#808080");
						draw.current.draw.setFeatureProperty(ap.id, "heatmapOpacity", 0.65);

						//-- Redrawing plot
						let feature = draw.current.draw.get(ap.id);
						draw.current.draw.add(feature);
					}
				}
			);

			bringSubsamplesToTopLayer(draw);
		} else {
			toast.error("No analysis results to display for this ortho type");
			heatmapOverlayShadingToggled = true; //-- Will turn off the toggle
			controlComponentRefs.heatmapTimelineRef.current.setHeatmapEnabled(false);
		}
	} else {
		//-- Set plot metadata heatmap opacity to 0 to hide coloring
		_.map(
			_.filter(allPlots, (p) => p.properties.heatmapColor),
			(p) => {
				let matchingPlotIndex = _.findIndex(allPlots, { id: p.id });

				if (matchingPlotIndex !== -1) {
					allPlots[matchingPlotIndex].properties.heatmapOpacity = 0;
				}

				//-- Check for existing mapbox feature
				let featureExists = draw.current.draw.get(p.id);

				if (featureExists) {
					draw.current.draw.setFeatureProperty(p.id, "heatmapOpacity", 0);

					//-- Redrawing plot
					let feature = draw.current.draw.get(p.id);
					draw.current.draw.add(feature);
				}
			}
		);

		bringSubsamplesToTopLayer(draw);
	}

	if (fieldLabelsToggle) {
		fieldLabelToggle(canvas, null, !heatmapOverlayShadingToggled);
		fieldLabelToggle(canvas, null, !heatmapOverlayShadingToggled);
	}
	if (trialLabelsToggle) {
		trialLabelToggle(canvas, !heatmapOverlayShadingToggled);
		trialLabelToggle(canvas, !heatmapOverlayShadingToggled);
	}

	heatmapOverlayShadingToggled = !heatmapOverlayShadingToggled;
}

export function heatmapOverlayLabels(draw, canvas, controlComponentRefs) {
	let plotsToggled = controlComponentRefs.plotToggleRef.current.checkToggleState;

	//-- Don't turn on if plots aren't on
	//---- & remove any trace of this function
	if (!plotsToggled) {
		heatmapOverlayLabelsToggled = true;

		//-- Turns off labels
		if (canvas.getLayer("plot-labels")) {
			canvas.removeLayer("plot-labels");
		}
	} else if (!heatmapOverlayLabelsToggled) {
		if (plotAnalysisResults?.length > 0) {
			//-- Make sure all plots are uncombined
			uncombinePlotsInField(draw);

			//-- Turn off plot labels if on
			if (plotLabelsToggle) {
				if (canvas.getLayer("plot-labels")) {
					canvas.removeLayer("plot-labels");
				}

				controlComponentRefs.plotLabelToggleRef.current.setToggleState(false);
				plotLabelsToggle = !plotLabelsToggle;
			}

			let labelCollection = {
				type: "FeatureCollection",
				features: []
			};

			//-- Add all plot labels to be added
			_.map(plotAnalysisResults, (par) => {
				//-- Create list of plots to search from
				//---- Filter as needed
				let plotsToSearch = allPlots;

				if (singleFeatureToggle && selectedTrialId) {
					plotsToSearch = _.filter(allPlots, (ap) => _.map(trialPlots, "properties.plotId").includes(ap.id));
				}

				let matchingPlot = _.find(plotsToSearch, { id: par.plotId });

				if (matchingPlot && par.plotAnalysisResultValue !== null) {
					//-- Place at centroid of plot
					let center = turf.centroid(matchingPlot).geometry.coordinates;

					let labelToAdd = {
						type: "Feature",
						properties: {
							name: `${getPercision(par.plotAnalysisResultValue)}`,
							plot: matchingPlot.id,
							field: matchingPlot.properties.field,
							type: "plotLabel"
						},
						geometry: {
							type: "Point",
							coordinates: center
						}
					};

					labelCollection.features.push(labelToAdd);
				}
			});

			//-- Mapbox layer needs a source
			let sourceId = uuid.v4();
			canvas.addSource(sourceId, {
				type: "geojson",
				data: labelCollection
			});

			//-- Create layer & style
			//-- Calling plot-labels so it shares same name as plotLabels so they don't appear at same time
			canvas.addLayer({
				id: "plot-labels",
				type: "symbol",
				source: sourceId,
				layout: {
					"text-field": ["format", ["get", "name"], { "font-scale": 1.2 }],
					"text-variable-anchor": ["center"],
					"text-size": 24,
					"text-justify": "auto"
				},
				paint: {
					"text-color": "#ffffff",
					"text-halo-color": "#000000",
					"text-halo-width": 0.65
				},
				minzoom: 19
			});
		} else {
			toast.error("No analysis results to display for this ortho type");
			heatmapOverlayLabelsToggled = true; //-- Will turn off the toggle
		}
	} else {
		//-- Turns off labels
		if (canvas.getLayer("plot-labels")) {
			canvas.removeLayer("plot-labels");
		}
	}

	heatmapOverlayLabelsToggled = !heatmapOverlayLabelsToggled;
	controlComponentRefs.heatmapOverlayLabelsRef.current.setToggleState(heatmapOverlayLabelsToggled);
}

function rgbValueToHex(rgb) {
	var hex = Math.round(rgb).toString(16);
	if (hex.length < 2) {
		hex = "0" + hex;
	}
	return hex;
}

function rgbToHex(rgb) {
	var red = rgbValueToHex(rgb[0]);
	var green = rgbValueToHex(rgb[1]);
	var blue = rgbValueToHex(rgb[2]);
	return `#${red}${green}${blue}`;
}

function median(numbers) {
	const numsLen = numbers.length;
	let median = 0;

	numbers.sort();

	if (numsLen % 2 === 0) {
		median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
	} else {
		median = numbers[(numsLen - 1) / 2];
	}

	return median;
}

export function getAnalysisOptions() {
	return analysisOptions;
}

export function applyEditFieldChanges(plots, field, dropdownRef) {
	if (plots?.length > 0) {
		//-- Update plot metadata
		_.map(plots, (p) => {
			let matchingPlotIndex = _.findIndex(allPlots, { id: p.id });

			if (matchingPlotIndex !== -1) {
				allPlots[matchingPlotIndex].properties = {
					...allPlots[matchingPlotIndex].properties,
					fieldName: field.name,
					name: p.name,
					treatment: p.treatment,
					trial: p.trialId === "00000000-0000-0000-0000-000000000000" ? null : p.trialId,
					isDirty: true,
					plantDate: field.plantDate,
					harvestDate: field.harvestDate
				};
			}
		});

		//-- Fix field name in field ddl
		let modifiedList = dropdownRef.current.innerRef.current.currentThirdDropdownOptions();
		_.find(modifiedList, { key: field.id }).text = field.name;

		dropdownRef.current.innerRef.current.setThirdDropdownOptions(modifiedList);
	}
}

export function rotateFeatureRight(canvas, level) {
	let feature = level === "field" ? allFields[0] : allTrials[0];
	if (feature) {
		let plots = feature.properties.plots;

		//-- Find and create bbox of all plots in feature
		let bbox = turf.bbox(turf.featureCollection(plots));
		let bboxPolygon = turf.bboxPolygon(bbox);

		//-- Get current viewport bearing
		let viewportLl = canvas.unproject([0, canvas.getCanvas().height]).toArray();
		let viewportUl = canvas.unproject([0, 0]).toArray();
		let viewportBearing = turf.bearing(viewportLl, viewportUl);

		let coords = plots[0].geometry.coordinates[0];

		//-- Identify all bearings of feature
		let llToUl = turf.bearing(coords[0], coords[1]);
		let ulToUr = turf.bearing(coords[1], coords[2]);
		let urToLr = turf.bearing(coords[2], coords[3]);
		let lrToLl = turf.bearing(coords[3], coords[0]);

		//-- Adjusting for range to be 0 - 360
		if (viewportBearing < 0) viewportBearing = 360 - Math.abs(viewportBearing);
		if (llToUl < 0) llToUl = 360 - Math.abs(llToUl);
		if (ulToUr < 0) ulToUr = 360 - Math.abs(ulToUr);
		if (urToLr < 0) urToLr = 360 - Math.abs(urToLr);
		if (lrToLl < 0) lrToLl = 360 - Math.abs(lrToLl);

		//-- Viewport bearing must be less than feature bearing
		let closestRightSnap =
			viewportBearing <= llToUl && viewportBearing > lrToLl
				? llToUl
				: viewportBearing <= ulToUr && viewportBearing > llToUl
				? ulToUr
				: viewportBearing <= urToLr && viewportBearing > ulToUr
				? urToLr
				: lrToLl;

		//-- Make sure viewport bearing didn't stay at same bearing
		if (viewportBearing.toPrecision(4) == llToUl.toPrecision(4)) closestRightSnap = lrToLl;
		if (viewportBearing.toPrecision(4) == ulToUr.toPrecision(4)) closestRightSnap = llToUl;
		if (viewportBearing.toPrecision(4) == urToLr.toPrecision(4)) closestRightSnap = ulToUr;
		if (viewportBearing.toPrecision(4) == lrToLl.toPrecision(4)) closestRightSnap = urToLr;

		if (closestRightSnap > 180) closestRightSnap -= 360;

		fitBoundsRotated(
			[bboxPolygon.geometry.coordinates[0][0], bboxPolygon.geometry.coordinates[0][2]],
			{
				bearing: closestRightSnap
			},
			{},
			canvas
		);
	}
}

export function rotateFeatureLeft(canvas, level) {
	let feature = level === "field" ? allFields[0] : allTrials[0];
	if (feature) {
		let plots = feature.properties.plots;

		//-- Find and create bbox of all plots in feature
		let bbox = turf.bbox(turf.featureCollection(plots));
		let bboxPolygon = turf.bboxPolygon(bbox);

		//-- Get current viewport bearing
		let viewportLl = canvas.unproject([0, canvas.getCanvas().height]).toArray();
		let viewportUl = canvas.unproject([0, 0]).toArray();
		let viewportBearing = turf.bearing(viewportLl, viewportUl);

		let coords = plots[0].geometry.coordinates[0];

		//-- Identify all bearings of feature
		let llToUl = turf.bearing(coords[0], coords[1]);
		let ulToUr = turf.bearing(coords[1], coords[2]);
		let urToLr = turf.bearing(coords[2], coords[3]);
		let lrToLl = turf.bearing(coords[3], coords[0]);

		//-- Adjusting for range to be 0 - 360
		if (viewportBearing < 0) viewportBearing = 360 - Math.abs(viewportBearing);
		if (llToUl < 0) llToUl = 360 - Math.abs(llToUl);
		if (ulToUr < 0) ulToUr = 360 - Math.abs(ulToUr);
		if (urToLr < 0) urToLr = 360 - Math.abs(urToLr);
		if (lrToLl < 0) lrToLl = 360 - Math.abs(lrToLl);

		//-- Viewport bearing must be less than feature bearing
		let closestLeftSnap =
			viewportBearing <= llToUl && viewportBearing > lrToLl
				? lrToLl
				: viewportBearing <= ulToUr && viewportBearing > llToUl
				? llToUl
				: viewportBearing <= urToLr && viewportBearing > ulToUr
				? ulToUr
				: urToLr;

		//-- Make sure viewport bearing didn't stay at same bearing
		if (viewportBearing.toPrecision(4) == llToUl.toPrecision(4)) closestLeftSnap = ulToUr;
		if (viewportBearing.toPrecision(4) == ulToUr.toPrecision(4)) closestLeftSnap = urToLr;
		if (viewportBearing.toPrecision(4) == urToLr.toPrecision(4)) closestLeftSnap = lrToLl;
		if (viewportBearing.toPrecision(4) == lrToLl.toPrecision(4)) closestLeftSnap = llToUl;

		if (closestLeftSnap > 180) closestLeftSnap -= 360;

		fitBoundsRotated(
			[bboxPolygon.geometry.coordinates[0][0], bboxPolygon.geometry.coordinates[0][2]],
			{
				bearing: closestLeftSnap
			},
			{},
			canvas
		);
	}
}

function setViewerOrientation(canvas, level, override = false) {
	if (allPlots?.length > 0) {
		let minRange = _.minBy(allPlots, (ap) => ap.properties.range).properties.range;
		let maxRange = _.maxBy(allPlots, (ap) => ap.properties.range).properties.range;
		let minColumn = _.minBy(allPlots, (ap) => ap.properties.column).properties.column;
		let maxColumn = _.maxBy(allPlots, (ap) => ap.properties.column).properties.column;

		let ranges = maxRange - minRange;
		let columns = maxColumn - minColumn;

		let length = parseFloat(allPlots[0].properties.fieldRangeLength);
		let width = parseFloat(allPlots[0].properties.fieldColWidth);

		if (ranges * length > columns * width && !override) {
			rotateFeatureRight(canvas, level);
		} else {
			fitToHeight(canvas, level, true);
		}
	}
}

export const checkAnnotationPermissions = async (
	markedPlots,
	markedQuantifiedRegions,
	clientId,
	dispatch,
	getTokenSilently,
	annotationRef,
	type
) => {
	if (markedPlots?.length > 0 || markedQuantifiedRegions?.length > 0) {
		let accessToken = await getTokenSilently();
		dispatch(markedPlotActions.checkAnnotationPermissions(markedPlots, markedQuantifiedRegions, clientId, accessToken))
			.then((res) => {
				if (type === "add" && res.data.existingFeatures && (res.data.isCreator || res.data.isOwner)) {
					annotationRef.current.showOverwriteModal();
				} else if (res.data.isCreator) {
					annotationRef.current.passCreator();
				} else if (res.data.isOwner) {
					annotationRef.current.showOwnerModal();
				} else {
					annotationRef.current.showBlockModal();
				}
			})
			.catch((err) => {
				console.error(err);
				toast.error("Error validating annotation permissions.");
			});
	}
};
