import _ from "lodash";
import moment from "moment";
import * as uuid from "uuid";
import React from "react";
import { Popup, Icon } from "semantic-ui-react";

const DATE_FORMAT = "M/D/YYYY";

export function getAreaUnderTheCurve(
	data,
	analysisValue,
	trialDataResults,
	useGDU,
	cumulativeGdus,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType,
	gduByDay = null
) {
	let skipOutlierFilter = !_.some(data, (rd) => rd?.isOutlier3 !== undefined || rd?.isOutlier1_5 !== undefined);

	data = _.sortBy(data, ["flightDate"]);
	data = _.filter(data, (r) => {
		return (
			r.analysisId === analysisValue &&
			(removeDamagedOrExcluded ? !r.excludeFromAssessment && !r.excluded : true) &&
			(skipOutlierFilter ||
				(removeOutliers && outlierType === 3
					? r.isOutlier3 === false
					: removeOutliers && outlierType === 1.5
					? r.isOutlier1_5 === false
					: true))
		);
	});
	let aucValues = calculateAucForTreatmentsAndReps(
		data,
		analysisValue,
		trialDataResults,
		useGDU,
		cumulativeGdus,
		gduByDay
	);
	aucValues = _.sortBy(aucValues, ["trialTreatmentId"]);
	return aucValues;
}

function calculateAucForTreatmentsAndReps(
	treatmentValues,
	analysisValue,
	trialDataResults,
	useGDU,
	cumulativeGdus,
	gduByDay = null
) {
	let grouping = _.groupBy(treatmentValues, (tv) => {
		return `${tv.trialTreatmentId}-${tv.plotReplicate}`;
	});

	let uniqueFlights = _.uniqBy(treatmentValues, "flightId").map(function (flight) {
		return {
			flightId: flight.flightId,
			flightDate: flight.flightDate,
			flightName: flight.flightName
		};
	});
	let uniqueFlightIds = _.map(uniqueFlights, (uf) => {
		return uf.flightId;
	});
	let hasFakeTreatments = false;
	let fakeTreatments = [];
	let treatmentGrouping = _.groupBy(treatmentValues, (tv) => tv.trialTreatmentId);
	let maxFlights = _.max(
		Object.values(_.map(treatmentGrouping, (tg) => _.uniqBy(tg, (t) => t.flightId))).map((a) => a.length)
	);

	_.map(treatmentGrouping, (treatments) => {
		// -- Adding fake treatments for flight dates where the treatment doesnt exist
		// -- This is needed to make sure there are enough cells on the auc table
		let treatmentFlights = _.uniqBy(treatments, (t) => t.flightId);
		let replicateGrouping = _.groupBy(treatments, (tg) => tg.plotReplicate);

		_.map(replicateGrouping, (rg) => {
			let treatmentsClone = _.cloneDeep(rg);

			if (treatmentFlights.length < maxFlights) {
				let uniqueTreatmentFlightIds = _.map(treatments, (tr) => {
					return tr.flightId;
				});
				const missingFlights = _.filter(uniqueFlightIds, (ufi) => {
					return !_.includes(uniqueTreatmentFlightIds, ufi);
				});

				_.map(missingFlights, (flight) => {
					let fakeTreatment = _.cloneDeep(rg[0]);
					fakeTreatment.color = "";
					fakeTreatment.trialAnalysisResultAverageValue = 0;
					fakeTreatment.flightId = flight;
					fakeTreatment.flightDate = uniqueFlights.find((x) => x.flightId === flight).flightDate;
					fakeTreatment.flightName = uniqueFlights.find((x) => x.flightId === flight).flightName;
					fakeTreatment.isFakeTreatment = true;
					fakeTreatment.excludeFromAssessment = true;

					treatmentsClone.push(fakeTreatment);
				});

				hasFakeTreatments = true;
			}

			fakeTreatments.push(treatmentsClone);
		});
	});

	let numberOfAucValues = _.uniqBy(treatmentValues, "flightId").length;
	grouping = hasFakeTreatments
		? removeIncompleteDatasets(fakeTreatments, numberOfAucValues)
		: removeIncompleteDatasets(grouping, numberOfAucValues);

	if (useGDU && gduByDay && gduByDay.length > 0) {
		cumulativeGdus = calculateCumulativeGdu(grouping[0], gduByDay[0].data);
	}

	return _.map(grouping, (trialReplicates) => {
		let auc = calculateAreaUnderTheCurve(trialReplicates, useGDU, cumulativeGdus);
		return {
			trialTreatmentId: trialReplicates[0].trialTreatmentId,
			trialId: trialDataResults.trialInfo.trialId,
			trialName: trialDataResults.trialInfo.trialName,
			city: trialDataResults.trialInfo.farmCity,
			stateName: trialDataResults.trialInfo.farmState,
			cooperatorName: trialDataResults.trialInfo.cooperatorName,
			plotReplicate: trialReplicates[0].plotReplicate,
			range: trialReplicates[0].plotRange,
			totalAuc: auc,
			excluded: false, //todo will need to fix this
			analysisId: analysisValue,
			maxTreatmentId: Math.max(..._.map(trialDataResults.treatments, "trialTreatmentId")),
			plotId: _.find(trialReplicates, (tr) => {
				return tr.plotId;
			})?.plotId,
			plotName: _.find(trialReplicates, (tr) => {
				return tr.plotId;
			})?.plotName,
			fieldId: trialReplicates[0].fieldId,
			isGDU: useGDU
		};
	});
}

function calculateAreaUnderTheCurve(treatmentData, useGDU, cumulativeGdus) {
	let areaUnderTheCurve = 0;
	let compoundedArea = 0;
	if (treatmentData.length < 2) {
		return areaUnderTheCurve;
	}
	treatmentData = _.filter(treatmentData, (td) => {
		return !td.isFakeTreatment;
	});
	if (useGDU === true) {
		_.map(treatmentData, (treatment, index) => {
			if (index !== treatmentData.length - 1) {
				const base = treatment.plotAnalysisResultValue + treatmentData[index + 1].plotAnalysisResultValue;

				//-- If the flight is not found, then we are looking at the plant/harvest flight
				let currentGdu =
					_.find(cumulativeGdus, (cg) => {
						return cg.flightId === treatment.flightId;
					})?.gdu ?? 0;

				//-- If the flight is not found, then we are looking at the plant/harvest flight
				let nextGdu =
					_.find(cumulativeGdus, (ncg) => {
						return ncg.flightId === treatmentData[index + 1].flightId;
					})?.gdu ?? 0;

				//Handle Harvest Date
				if (treatmentData[index + 1]?.isHarvestFlight) {
					nextGdu = cumulativeGdus[cumulativeGdus.length - 1]?.gdu;
				}

				const height = Number(nextGdu) - Number(currentGdu);

				compoundedArea += (1 / 2) * height * base;
			}
		});
	} else {
		_.map(treatmentData, (treatment, index) => {
			if (index !== treatmentData.length - 1) {
				const base = treatment.plotAnalysisResultValue + treatmentData[index + 1].plotAnalysisResultValue;
				const currentTreatmentDate = moment(treatment.flightDate).startOf("day");
				const nextTreatmentDate = moment(treatmentData[index + 1].flightDate).startOf("day");
				const height = nextTreatmentDate.diff(currentTreatmentDate, "days");
				compoundedArea += (1 / 2) * height * base;
			}
		});
	}

	areaUnderTheCurve = compoundedArea;
	return areaUnderTheCurve;
}

function removeIncompleteDatasets(groupedData, numberOfAucValues) {
	return _.filter(groupedData, (gd) => {
		return gd.length === numberOfAucValues;
	});
}

export function addEmptyFlightForPlantOrHarvestDate(
	date,
	treatments,
	trialTreatments,
	analyses,
	isHarvestFlight,
	isPlantFlight,
	currentClientId
) {
	if (date !== null) {
		const hasFlightDateFlight = _.some(treatments, (treatmentData) => {
			return moment(treatmentData.flightDate).local().format(DATE_FORMAT) === moment(date).local().format(DATE_FORMAT);
		});

		if (!hasFlightDateFlight) {
			let exclusions = _.filter(treatments, (tr) => tr.excludeFromAssessment || tr.excluded);
			let uniquePlotReplicates = _.uniqBy(treatments, "plotReplicate");
			let treatmentsToReturn = [];

			const flightId = uuid.v4();
			_.forEach(analyses, (analysis) => {
				_.forEach(trialTreatments, (treatment) => {
					_.forEach(uniquePlotReplicates, (rep) => {
						const obj = {
							analysisId: analysis.value,
							analysisName: analysis.text,
							clientId: currentClientId,
							flightDate: date,
							flightId: flightId,
							flightName: "",
							plotAnalysisResultValue: 0,
							plotReplicate: rep.plotReplicate,
							plotRange: rep.plotRange,
							trialAnalysisResultAverageValue: 0,
							trialAnalysisResultValues: null,
							trialId: uuid.v4(),
							trialName: "",
							trialSampleSize: "",
							trialTreatmentId: treatment.trialTreatmentId,
							excluded:
								_.find(
									exclusions,
									(e) => e.plotReplicate === rep.plotReplicate && e.trialTreatmentId === treatment.trialTreatmentId
								)?.excluded ?? false,
							excludeFromAssessment:
								_.find(
									exclusions,
									(e) => e.plotReplicate === rep.plotReplicate && e.trialTreatmentId === treatment.trialTreatmentId
								)?.excludeFromAssessment ?? false,
							isHarvestFlight: isHarvestFlight,
							isPlantFlight: isPlantFlight,
							quantifiedRegionTypeId: null,
							analysisTypeId: null,
							isOutlier3: false,
							isOutlier1_5: false
						};
						treatmentsToReturn.push(obj);
					});
				});
			});
			return treatmentsToReturn;
		}
	}
}

export function setupTrialAverages(
	res,
	analyses,
	analysisIndex,
	trialDataResults,
	addPlantFight = false,
	addHarvestFlight = false,
	removeDamagedOrExcluded,
	removeOutliers,
	outlierType
) {
	let skipOutlierFilter = !_.some(res, (rd) => rd?.isOutlier3 !== undefined || rd?.isOutlier1_5 !== undefined);

	res = _.filter(res, (r) => {
		return (
			(removeDamagedOrExcluded
				? !r.excludeFromAssessment && !r.excluded
				: r.excludeFromAssessment === true || r.excludeFromAssessment === false) &&
			(skipOutlierFilter ||
				(removeOutliers && outlierType === 3
					? r.isOutlier3 === false
					: removeOutliers && outlierType === 1.5
					? r.isOutlier1_5 === false
					: true))
		);
	});
	if (addPlantFight === true) {
		const plantTreatments = addEmptyFlightForPlantOrHarvestDate(
			trialDataResults.trialInfo.plantDate,
			res,
			trialDataResults.treatments,
			analyses,
			false,
			true
		);

		if (plantTreatments) {
			res = res.concat(plantTreatments);
		}
	}

	if (addHarvestFlight === true) {
		const harvestTreatments = addEmptyFlightForPlantOrHarvestDate(
			trialDataResults.trialInfo.harvestDate,
			res,
			trialDataResults.treatments,
			analyses,
			true,
			false
		);
		if (harvestTreatments) {
			res = res.concat(harvestTreatments);
		}
	}

	let groupedByTreatmentId = _.groupBy(res, (d) => {
		return `${d.trialTreatmentId}-${d.flightId}-${d.analysisId}-${d.analysisTypeId}`;
	});

	let avgObjects = _.map(groupedByTreatmentId, (treatmentValues) => {
		const trial = _.filter(trialDataResults.treatments, (td) => {
			return td.trialTreatmentId === treatmentValues[0].trialTreatmentId;
		});
		let avgObj = {
			analysisId: treatmentValues[0].analysisId,
			analysisName: treatmentValues[0].analysisName,
			clientId: treatmentValues[0].clientId,
			flightDate: treatmentValues[0].flightDate,
			flightId: treatmentValues[0].flightId,
			flightName: treatmentValues[0].flightName,
			treatmentName: trial && trial.length > 0 ? trial[0].treatmentName : "",
			trialId: treatmentValues[0].trialId,
			trialName: treatmentValues[0].trialName,
			trialTreatmentId: treatmentValues[0].trialTreatmentId,
			trialAnalysisResultAverageValue: _.meanBy(treatmentValues, (tv) => tv.plotAnalysisResultValue),
			isHarvestFlight: treatmentValues[0].isHarvestFlight ?? false,
			isPlantFlight: treatmentValues[0].isPlantFlight ?? false,
			uncheckFlight: treatmentValues[0].uncheckFlight ? !treatmentValues[0].uncheckFlight : null,
			analysisTypeId: treatmentValues[0].analysisTypeId
		};
		return avgObj;
	});

	avgObjects = _.sortBy(avgObjects, ["trialTreatmentId"]);

	avgObjects = _.orderBy(avgObjects, ["flightDate"], ["asc"]);

	_.map(_.uniqBy(avgObjects, "flightId"), (f) => {
		if (f.flightId) {
			let filteredRes = _.filter(
				res,
				(r) => r.flightId === f.flightId && r.analysisId === analyses[analysisIndex]?.value
			);

			let groupedByFlight = _.groupBy(filteredRes, (a) => {
				return `${a.trialTreatmentId}-${a.plotReplicate}`;
			});

			//let maxReplicates = _.max(_.map(filteredRes, "plotReplicate"));
			let unbalancedData = false;

			_.forEach(groupedByFlight, (g) => {
				let matchingReplicates = _.filter(filteredRes, (fr) => fr.plotReplicate === g[0].plotReplicate);

				if (
					matchingReplicates &&
					_.some(matchingReplicates, "excludeFromAssessment") &&
					!_.every(matchingReplicates, "excludeFromAssessment")
				) {
					unbalancedData = true;
					return false;
				}
			});

			_.map(
				_.filter(avgObjects, (ao) => ao.flightId === f.flightId),
				(fao) => {
					fao.unbalanced = unbalancedData;
				}
			);
		}
	});

	return avgObjects;
}

export function getAnalysisIndex(analysis, analyses) {
	let analysisIndex = 0;
	if (analysis) {
		analysisIndex = _.findIndex(analyses, { value: analysis });
	} else {
		// Default to NDVI if available
		analysisIndex = _.findIndex(analyses, (a) => a.text === "Aerial NDVI" || a.text === "Aerial NDVI_M3M");
		if (analysisIndex === -1) {
			analysisIndex = 0;
		}
	}
	return analysisIndex;
}

export function calculateCumulativeGdu(data, gduByDay) {
	if (gduByDay?.length > 0) {
		data = _.uniqBy(data, "flightId");
		var cumulativeGdus = _.map(data, (flight) => {
			let flightGDU = { flightId: flight.flightId, gdu: 0 };
			flightGDU.gdu = gduByDay
				.slice(
					0,
					gduByDay.findIndex((item) => moment(item.day).isSame(moment(flight.flightDate), "day"))
				)
				.reduce((a, b) => a + b.gdu, 0)
				.toFixed(0);
			return flightGDU;
		});
		return cumulativeGdus;
	}
}

export function buildGduErrorPopup(hasGduFormula, plantDate) {
	if (!hasGduFormula) {
		return (
			<Popup
				content="There currently is no formula for Growing Degree Units for this type of crop."
				trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
			/>
		);
	} else if (!plantDate) {
		return (
			<Popup
				content="Plant Date is required to calculate Growing Degree Units. Please add a plant date to this trial."
				trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
			/>
		);
	}
}

export function buildGduErrorPopupForProtocol(hasGduFormula, plantDate) {
	if (!plantDate) {
		return (
			<Popup
				content="Plant Date is required to calculate Growing Degree Units. Please add a plant date to all trials in this protocol."
				trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
			/>
		);
	} else if (!hasGduFormula) {
		return (
			<Popup
				content="There currently is no formula for Growing Degree Units for this type of crop."
				trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
			/>
		);
	}
}

//cropName - string
export function checkForGDUFormula(cropName) {
	switch (cropName) {
		case "Soybeans":
		case "Corn":
		case "Sweet Corn":
		case "Cucumbers":
			return true;
		default:
			return false;
	}
}
