import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Loader, Button, Grid, Message } from "semantic-ui-react";

import { toast } from "react-toastify";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as curveModelsActions from "../../../../redux/actions/curveModelsActions";
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";
import * as timecourseCalculations from "../../../Lumber/AreaUnderTheCurve";

import BulkAssessmentsTable from "./BulkAssessmentsTable";
import _ from "lodash";

const BulkAssessments = () => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	//-- Data Sources
	const [gduByDay, setGduByDay] = useState([]);
	const [batchAssessments, setBatchAssessments] = useState([]);
	const [curveModelAnalysisResults, setCurveModelanalysisResults] = useState([]);
	const [flightSelections, setFlightSelections] = useState([]);
	const [plotAnalysisResults, setPlotAnalysisResults] = useState([]);
	const [batchRunPlotAnalysisResults, setBatchRunPlotAnalysisResults] = useState([]);
	const [trialData, setTrialData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [canCalculateGdus, setCanCalculateGdus] = useState(false);

	const validGduCrops = ["Corn", "Soybeans", "Sweet Corn"];

	useEffect(() => {
		getCurveModelBatchAssessments();
	}, []);

	const getCurveModelBatchAssessments = async () => {
		const accessToken = await getTokenSilently();
		setLoading(true);

		dispatch(
			curveModelsActions.getCurveModelBatchAssessments(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				setBatchAssessments(res.data.batchAssessments);
				setCurveModelanalysisResults(res.data.curveModelAnalysisResults);
				setPlotAnalysisResults(res.data.plotAnalysisResults);
				setTrialData(res.data.trialData);
				//Get trial batch curve model data from database for initial load
				setBatchRunPlotAnalysisResults(res.data.curveModelBatchAnalysisResultsForTrial);

				let flightSelectionsByPhenotype = [];
				_.map(res.data.batchAssessments, (ba) => {
					let selectionsForPhenotype = _.filter(
						res.data.flightSelections,
						(fs) =>
							fs.analysisId === ba.analysisId &&
							fs.analysisTypeId === ba.analysisTypeId &&
							fs.curveModelId === ba.curveModelId &&
							(fs.quantifiedRegionTypeId === ba.quantifiedRegionTypeId ||
								fs.quantifiedRegionTypeId === ba.secondaryQuantifiedRegionTypeId)
					);

					if (selectionsForPhenotype.length === 0) {
						selectionsForPhenotype = createFlightSelections(ba, res.data.plotAnalysisResults, res.data.trialData);
					} else {
						_.map(selectionsForPhenotype, (sfp) => (sfp.existsOnAnalysis = true));
					}

					let fsOpt = {
						curveModelPhenotypeId: ba.curveModelPhenotypeId,
						selections: selectionsForPhenotype
					};

					flightSelectionsByPhenotype.push(fsOpt);
				});

				setFlightSelections(_.flatten(_.map(flightSelectionsByPhenotype, "selections")));

				let calculateGdus = validGduCrops.includes(res.data.plotAnalysisResults[0]?.cropName);
				setCanCalculateGdus(calculateGdus);

				if (calculateGdus) getGDUByDay(moduleNavigation.trialId);
				else setLoading(false);
			})
			.catch((err) => {
				toast.error("Error getting curve model assessments");
				console.error(err);
			});
	};

	const createFlightSelections = (batchAssessment, plots, trialData) => {
		let uniqueFlights = _.uniqBy(plots, "flightId");
		let uniqueFlightsForAnalysis = _.uniqBy(
			_.filter(
				plots,
				(p) =>
					p.analysisId === batchAssessment.analysisId &&
					(p.quantifiedRegionTypeId === batchAssessment.quantifiedRegionTypeId ||
						p.quantifiedRegionTypeId === batchAssessment.secondaryQuantifiedRegionTypeId)
			),
			"flightId"
		);

		if (trialData.trialInfo.plantDate) {
			uniqueFlights.push({
				flightId: null,
				flightDate: trialData.trialInfo.plantDate,
				plantFlight: true,
				harvestFlight: false
			});
		}

		if (trialData.trialInfo.harvestDate) {
			uniqueFlights.push({
				flightId: null,
				flightDate: trialData.trialInfo.harvestDate,
				plantFlight: false,
				harvestFlight: true
			});
		}

		let flights = [];
		_.map(uniqueFlights, (flight) => {
			let flightToAdd = {
				analysisId: batchAssessment.analysisId,
				analysisTypeId: batchAssessment.analysisTypeId,
				curveModelId: batchAssessment.curveModelId,
				quantifiedRegionTypeId: batchAssessment.quantifiedRegionTypeId,
				flightId: !(flight.plantFlight || flight.harvestFlight) ? flight.flightId : null,
				harvestFlight: flight.harvestFlight ?? false,
				plantFlight: flight.plantFlight ?? false,
				existsOnAnalysis: _.map(uniqueFlightsForAnalysis, "flightId").includes(flight.flightId)
			};

			flights.push(flightToAdd);
		});
		return flights;
	};

	const getGDUByDay = async (trialId) => {
		const accessToken = await getTokenSilently();

		dispatch(weatherDataActions.getGDUForTrial(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setGduByDay(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleBatchRun = async () => {
		const accessToken = await getTokenSilently();
		setProcessing(true);

		let requestObject = [];

		let treatmentIds = _.map(trialData.treatments, "trialTreatmentId");
		let cumulativeGdus = canCalculateGdus
			? timecourseCalculations.calculateCumulativeGdu(plotAnalysisResults, gduByDay)
			: null;

		_.map(
			_.filter(batchAssessments, (ba) => ba.status === "Ready - Batched"),
			(ba) => {
				//-- Check whether we can use the secondary qr or not
				let quantifiedRegionTypeIdToUse = ba.quantifiedRegionTypeId;
				let plotsByQuantifiedRegionTypeId = _.filter(
					plotAnalysisResults,
					(par) => par.quantifiedRegionTypeId === ba.quantifiedRegionTypeId
				);

				if (plotsByQuantifiedRegionTypeId.length === 0) {
					plotsByQuantifiedRegionTypeId = _.filter(
						plotAnalysisResults,
						(par) => par.quantifiedRegionTypeId === ba.secondaryQuantifiedRegionTypeId
					);

					if (plotsByQuantifiedRegionTypeId.length !== 0) {
						quantifiedRegionTypeIdToUse = ba.secondaryQuantifiedRegionTypeId;
					}
				}

				let filteredFlightSelections = _.filter(
					flightSelections,
					(fs) =>
						fs.analysisId === ba.analysisId &&
						fs.analysisTypeId === ba.analysisTypeId &&
						fs.curveModelId === ba.curveModelId &&
						(fs.quantifiedRegionTypeId === ba.quantifiedRegionTypeId ||
							fs.quantifiedRegionTypeId === ba.secondaryQuantifiedRegionTypeId)
				);

				let curveModelDataAnalysisId =
					_.find(filteredFlightSelections, (ffs) => ffs.curveModelDataAnalysisId)?.curveModelDataAnalysisId ?? null;

				let filteredFlightIds = _.map(filteredFlightSelections, "flightId");

				//-- Add empty plot analysis result values
				let analyses = _.map(_.uniqBy(batchAssessments, "analysisId"), (ba) => {
					return { key: ba.analysisId, value: ba.analysisId, text: ba.analysisName };
				});
				let plotAnalysisResultsWithBlanks = _.cloneDeep(plotAnalysisResults);
				if (_.some(filteredFlightSelections, "plantFlight")) {
					let plantData = timecourseCalculations.addEmptyFlightForPlantOrHarvestDate(
						plotAnalysisResults[0]?.trialPlantDate,
						plotAnalysisResults,
						trialData.treatments,
						analyses,
						false,
						true,
						userAuth.currentClientId
					);

					if (plantData) plotAnalysisResultsWithBlanks = plotAnalysisResultsWithBlanks.concat(plantData);
				}
				if (_.some(filteredFlightSelections, "harvestFlight")) {
					let harvestData = timecourseCalculations.addEmptyFlightForPlantOrHarvestDate(
						plotAnalysisResults[0]?.trialHarvestDate,
						plotAnalysisResults,
						trialData.treatments,
						analyses,
						true,
						false,
						userAuth.currentClientId
					);

					if (harvestData) plotAnalysisResultsWithBlanks = plotAnalysisResultsWithBlanks.concat(harvestData);
				}

				//-- Check for plot uniformity values
				let combinedAucData = [];
				if (ba.analysisTypeName === "Uniformity") {
					let plotAnalysisResultValues = _.filter(
						plotAnalysisResultsWithBlanks,
						(par) =>
							(par.isPlotUniformityValue &&
								par.quantifiedRegionTypeId === quantifiedRegionTypeIdToUse &&
								filteredFlightIds.includes(par.flightId)) ||
							par.isPlantFlight ||
							par.isHarvestFlight
					);

					if (canCalculateGdus) {
						combinedAucData = timecourseCalculations.getAreaUnderTheCurve(
							plotAnalysisResultValues,
							ba.analysisId,
							trialData,
							true,
							cumulativeGdus
						);
					}
					combinedAucData = combinedAucData.concat(
						timecourseCalculations.getAreaUnderTheCurve(
							plotAnalysisResultValues,
							ba.analysisId,
							trialData,
							false,
							cumulativeGdus
						)
					);
				} else {
					let plotAnalysisResultValues = _.filter(
						plotAnalysisResultsWithBlanks,
						(par) =>
							(!par.isPlotUniformityValue &&
								par.quantifiedRegionTypeId === quantifiedRegionTypeIdToUse &&
								filteredFlightIds.includes(par.flightId)) ||
							par.isPlantFlight ||
							par.isHarvestFlight
					);

					if (canCalculateGdus) {
						combinedAucData = timecourseCalculations.getAreaUnderTheCurve(
							plotAnalysisResultValues,
							ba.analysisId,
							trialData,
							true,
							cumulativeGdus
						);
					}
					combinedAucData = combinedAucData.concat(
						timecourseCalculations.getAreaUnderTheCurve(
							plotAnalysisResultValues,
							ba.analysisId,
							trialData,
							false,
							cumulativeGdus
						)
					);
				}

				combinedAucData = _.sortBy(combinedAucData, ["trialTreatmentId"]);

				let request = {
					trialId: moduleNavigation.trialId,
					analysisId: ba.analysisId,
					analysisTypeId: ba.analysisTypeId,
					analysisTypeName: ba.analysisTypeName,
					curveModelDataAnalysisId: curveModelDataAnalysisId,
					curveModelId: ba.curveModelId,
					curveModelFitFunctionId: ba.curveModelFitFunctionId,
					clientId: userAuth.currentClientId,
					curveModelName: ba.curveModelName,
					useGDU: true,
					quantifiedRegionTypeId: quantifiedRegionTypeIdToUse,
					selectedFlights: filteredFlightSelections,
					selectedTreatmentIds: treatmentIds,
					aucData: _.filter(combinedAucData, (cad) => !cad.isGDU),
					otherAucData: _.filter(combinedAucData, (cad) => cad.isGDU)
				};

				requestObject.push(request);
			}
		);

		//-- Make sure plot analysis results contains data for batched assessments
		let uniqueCombinations = _.uniqBy(requestObject, (r) => [r.analysisId, r.quantifiedRegionTypeId].join());
		let failedCombinations = [];
		_.map(uniqueCombinations, (uc) => {
			let combinationExists = _.find(
				plotAnalysisResults,
				(par) => par.analysisId === uc.analysisId && par.quantifiedRegionTypeId === uc.quantifiedRegionTypeId
			);
			if (!combinationExists) {
				toast.error(
					`No plot analysis results for analysis ${_.find(batchAssessments, (ba) => ba.analysisId === uc.analysisId).analysisName
					} with quantified region ${_.find(batchAssessments, (ba) => ba.quantifiedRegionTypeId === uc.quantifiedRegionTypeId)
						.quantifiedRegionTypeName
					}`,
					{
						toastId: uc.analysisId + uc.quantifiedRegionTypeId,
						autoClose: 10000
					}
				);
				failedCombinations.push(uc.analysisId + uc.quantifiedRegionTypeId);
			}
		});

		if (failedCombinations.length > 0) {
			setProcessing(false);
			return;
		}

		if (requestObject.length > 0) {
			dispatch(curveModelsActions.runBatchAssessments(requestObject, accessToken))
				.then(() => {
					toast.info(
						"Growth Curve selections saved successfully. Please wait while growth curves are being ran to check for errors."
					);
					dispatch(
						curveModelsActions.runBatchCurveModels(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
					).then(() => {
						getCurveModelBatchAssessments();
						toast.success("All curve model analyses ran successfully!");
						setProcessing(false);
					});
				})
				.catch((error) => {
					console.error(error);
					toast.error("An error occurred while run curve model analysis");
					setProcessing(false);
				});
		} else {
			setProcessing(false);
			toast.warning("No assessments are set up to run");
		}
	};

	const removeCurveModelData = async (phenotypeId) => {
		setProcessing(true);

		let phenotype = _.find(batchAssessments, { curveModelPhenotypeId: phenotypeId });
		let flightsOnPhenotype = _.filter(
			flightSelections,
			(fs) =>
				fs.analysisId === phenotype.analysisId &&
				fs.analysisTypeId === phenotype.analysisTypeId &&
				fs.curveModelId === phenotype.curveModelId &&
				(fs.quantifiedRegionTypeId === phenotype.quantifiedRegionTypeId ||
					fs.quantifiedRegionTypeId === phenotype.secondaryQuantifiedRegionTypeId)
		);

		let curveModelDataAnalysisId = _.find(
			flightsOnPhenotype,
			(fop) => fop.curveModelDataAnalysisId
		)?.curveModelDataAnalysisId;

		if (curveModelDataAnalysisId) {
			const accessToken = await getTokenSilently();
			dispatch(curveModelsActions.deleteCurveAnalysis(curveModelDataAnalysisId, userAuth.currentClientId, accessToken))
				.then(() => {
					//-- Update flight selections
					let filteredFlightSelections = _.filter(
						flightSelections,
						(fs) =>
							!(
								fs.analysisId === phenotype.analysisId &&
								fs.analysisTypeId === phenotype.analysisTypeId &&
								fs.curveModelId === phenotype.curveModelId &&
								(fs.quantifiedRegionTypeId === phenotype.quantifiedRegionTypeId ||
									fs.quantifiedRegionTypeId === phenotype.secondaryQuantifiedRegionTypeId)
							)
					);
					let newFlightSelectionsForPhenotype = createFlightSelections(phenotype, plotAnalysisResults, trialData);
					filteredFlightSelections = [...filteredFlightSelections, ...newFlightSelectionsForPhenotype];

					setFlightSelections(filteredFlightSelections);

					//-- Update curve model analysis results
					let filteredCurveModelAnalysisResults = _.filter(
						curveModelAnalysisResults,
						(cmar) =>
							!(
								cmar.analysisId === phenotype.analysisId &&
								cmar.analysisTypeId === phenotype.analysisTypeId &&
								cmar.curveModelId === phenotype.curveModelId &&
								(cmar.quantifiedRegionTypeId === phenotype.quantifiedRegionTypeId ||
									cmar.quantifiedRegionTypeId === phenotype.secondaryQuantifiedRegionTypeId)
							)
					);

					setCurveModelanalysisResults(filteredCurveModelAnalysisResults);

					//-- Update batch assessment status
					let localBatchAssessments = _.cloneDeep(batchAssessments);
					let baIndex = _.findIndex(localBatchAssessments, { curveModelPhenotypeId: phenotypeId });
					localBatchAssessments[baIndex].status = null;
					localBatchAssessments[baIndex].savedStatus = null;

					setBatchAssessments(localBatchAssessments);

					toast.success("Curve model data deleted successfully.");
					setProcessing(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("There was a problem deleting this curve model.");
					setProcessing(false);
				});
		} else {
			toast.error("No curve model data associated with that phenotype");
			setProcessing(false);
		}
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column width="12">
						<h2>Phenotype/Growth Parameter aerialLYTX</h2>
					</Grid.Column>
					<Grid.Column width="4" textAlign="right">
						<Button
							style={{ float: "right" }}
							loading={loading || processing}
							disabled={loading || processing}
							primary
							onClick={() => handleBatchRun()}
						>
							Perform Batch Run
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{trialData?.trialInfo?.plantDate === null && (
				<Message warning>
					No Plant Date has been added to the trial. This will cause all LGF growth curves to fail.
				</Message>
			)}
			{loading ? (
				<Loader active inline style={{ marginLeft: "50%" }} />
			) : (
				<BulkAssessmentsTable
					batchAssessments={batchAssessments}
					curveModelAnalysisResults={curveModelAnalysisResults}
					flightSelections={flightSelections}
					plotAnalysisResults={plotAnalysisResults}
					batchRunPlotAnalysisResults={batchRunPlotAnalysisResults}
					gduByDays={gduByDay}
					trialData={trialData}
					canCalculateGdus={canCalculateGdus}
					setBatchAssessments={(ba) => setBatchAssessments(ba)}
					setFlightSelections={(fs) => setFlightSelections(fs)}
					removeCurveModelData={(id) => removeCurveModelData(id)}
				/>
			)}
		</>
	);
};

export default BulkAssessments;
