import MapboxDashboardComponent from "./Content/Dashboard/mapboxWrapper";
import { ApplicationArea } from "./Lumber/ApplicationAreas";
import BulkPublishing from "./Content/Trials/BulkPublishing";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const dashboardRoutes = [
	{
		id: "dasboard-overview",
		name: "overview",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Overview",
		icon: "tachometer alternate",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/dashboard`, `/dashboard/overview`],
		exact: true,
		component: () => MapboxDashboardComponent("all"),
		module: "dashboard",
		applicationAreas: [ApplicationArea.HomeOverview],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "dashboard-favorites",
		name: "favorites",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Favorites",
		icon: "star",
		showInNav: true,
		secured: true,
		paths: [`/dashboard/favorites`],
		exact: true,
		component: () => MapboxDashboardComponent("favorites"),
		module: "dashboard",
		applicationAreas: [ApplicationArea.HomeFavorites],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "recently-updated",
		name: "recently-updated",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Recently Updated",
		icon: "bell",
		showInNav: true,
		secured: true,
		paths: [`/dashboard/recently-updated`],
		exact: true,
		component: () => MapboxDashboardComponent("updated"),
		module: "dashboard",
		applicationAreas: [ApplicationArea.HomeRecentlyUpdated],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "recently-published",
		name: "recently-published",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Recently Published",
		icon: "leanpub",
		showInNav: true,
		secured: true,
		paths: [`/dashboard/recently-published`],
		exact: true,
		component: () => MapboxDashboardComponent("published"),
		module: "dashboard",
		applicationAreas: [ApplicationArea.HomeRecentlyPublished],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "bulk-publishing",
		name: "bulk-publishing",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Bulk Publishing",
		icon: "leanpub",
		cornerIcon: "share",
		showInNav: true,
		secured: true,
		paths: [`/dashboard/bulk-publishing`],
		exact: true,
		component: BulkPublishing,
		module: "dashboard",
		applicationAreas: [ApplicationArea.HomeBulkPublishing],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	}
];

export default {
	dashboardRoutes
};
