export function validateForm(fieldLayoutInspection) {
	let validation = { error: false, errorList: [] };

	if (!!fieldLayoutInspection && !fieldLayoutInspection?.areValid) {
		validation.errorList.push(
			"Field Layout contains errors that need to be addressed before it can be saved with the field."
		);
		validation.fieldLayout = true;
		validation.error = true;
	}
	return validation;
}
