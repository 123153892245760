import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import * as uuid from "uuid";

import {
	Button,
	Checkbox,
	Divider,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	List,
	Message,
	Modal,
	Segment
} from "semantic-ui-react";
import Assessment from "../Assessment";
import "./styles.css";

import _ from "lodash";
import moment from "moment";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import { DateTimePicker } from "react-widgets";

import { toast } from "react-toastify";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
// import { digital } from "units-converter";

const DataSetModal = ({
	groundDatasetToEdit,
	groundDataQuestions,
	growthStages,
	persons,
	onSave,
	onEdit,
	onClose,
	openAddDatasetModal,
	isEditing,
	isForProtocol = false,
	handleAddDatasetModalOpen,
	validateDataSet,
	outlineData
}) => {
	const DATE_FORMAT = "MM/DD/YYYY h:mma";
	const moduleNavigation = useModuleNavigation();

	//-- Data Sources
	const [groundDataAssessments, setGroundDataAssessments] = useState([]);
	// const [groundDataSources, setGroundDataSources] = useState([]);
	const [groundDataSamples, setGroundDataSamples] = useState([]);
	const [groundDataMethods, setGroundDataMethods] = useState([]);
	const [groundDataDevices, setGroundDataDevices] = useState([]);
	const [groundDatasetTemplates, setGroundDatasetTemplates] = useState([]);
	// const user = useSelector((state) => (state.user ? state.user : null));
	const userAuth = useUserAuth();

	//-- Dropdown Options
	const [groundDatasetTemplatesOptions, setGroundDatasetTemplatesOptions] = useState([]);
	const [growthStagesOptions, setGrowthStagesOptions] = useState([]);
	const [growthStagesOptionsEnd, setGrowthStagesOptionsEnd] = useState([]);
	const [personsOptions, setPersonsOptions] = useState([]);
	const [groundDataAssessmentsOptions, setGroundDataAssessmentsOptions] = useState([]);

	const [groundDataUnitsOptions, setGroundDataUnitsOptions] = useState([]);
	const [groundDataSamplesOptions, setGroundDataSamplesOptions] = useState([]);
	const [groundDataMethodsOptions, setGroundDataMethodsOptions] = useState([]);
	const [groundDataDevicesOptions, setGroundDataDevicesOptions] = useState([]);
	const [groundDataGrowthPhaseOptions, setGroundDataGrowthPhaseOptions] = useState([]);
	const [groundDataTimingMethods, setGroundDataTimingMethods] = useState([]);

	const [groundDataSampleDefinitionPartOptions, setGroundDataSampleDefinitionPartOptions] = useState([]);
	const [groundDataSourceOptions, setGroundDataSourceOptions] = useState([]);

	//-- Selected Options
	const [selectedGroundDatasetTemplate, setSelectedGroundDatasetTemplate] = useState(null);
	const [selectedGrowthStage, setSelectedGrowthStage] = useState(null);
	const [selectedTimingMethodId, setSelectedTimingMethodId] = useState(null);
	const [selectedTimingMethodType, setSelectedTimingMethodType] = useState(null);
	const [selectedPerson, setSelectedPerson] = useState(null);
	const [groundDatasetAssessments, setGroundDatasetAssessments] = useState([]);
	const [dateCollected, setDateCollected] = useState(null);
	const [selectedGrowthPhase, setSelectedGrowthPhase] = useState(null);
	const [selectedTargetGrowthStageStart, setSelectedTargetGrowthStageStart] = useState(null);
	const [selectedTargetGrowthStageEnd, setSelectedTargetGrowthStageEnd] = useState(null);
	const [targetManualPlantingTiming, setTargetManualPlantingTiming] = useState(null);
	const [targetCollectionDate, setTargetCollectionDate] = useState(null);
	const [daTimingNumber, setDaTimingNumber] = useState(null);
	const [calculatedDatasetAssessments, setCalculatedDatasetAssessments] = useState(null);
	const [trialsForDataset, setTrialsForDataset] = useState(
		_.filter(_.uniqBy(outlineData, "trialId"), (data) => data.trialId !== null)
	);
	const [selectedGroundDatasetTemplates, setSelectedGroundDatasetTemplates] = useState([]);
	const [currentTemplatesMap, setCurrentTemplatesMap] = useState({});
	const [editedTemplatesSet, setEditedTemplatesSet] = useState(() => new Set());
	const [hasData, setHasData] = useState(false);

	//-- UI Control
	const [saving, setSaving] = useState(false);
	const [openTrialModal, setOpenTrialModal] = useState(false);
	const [ableToSave, setAbleToSave] = useState(false);
	const [requiredDataSetFieldsSet, setRequiredDataSetFieldsSet] = useState(false);
	const [templateNamesMap, setTemplateNamesMap] = useState({});

	function resetDatasetModal() {
		resetDropDownOptions();
		setGroundDatasetAssessments([]);
		setSelectedGroundDatasetTemplate(null);
		setSelectedGrowthStage(null);
		setSelectedTimingMethodId(groundDataTimingMethods[0]?.key);
		setSelectedPerson(null);
		setDateCollected(null);
		setSelectedGrowthPhase(groundDataGrowthPhaseOptions[0]?.key);
		setSelectedTargetGrowthStageStart(null);
		setSelectedTargetGrowthStageEnd(null);
		setTargetManualPlantingTiming(null);
		setTargetCollectionDate(null);
		setDaTimingNumber(null);
		setTrialsForDataset(_.filter(_.uniqBy(outlineData, "trialId"), (data) => data.trialId !== null));
		setCurrentTemplatesMap({});
		setSelectedGroundDatasetTemplates([]);
		setHasData(false);
	}

	useEffect(() => {
		if (selectedGrowthPhase && selectedTimingMethodId) {
			setRequiredDataSetFieldsSet(true);
		}
	}, [selectedGrowthPhase, selectedTimingMethodId]);

	useEffect(() => {
		if (groundDataQuestions) {
			resetDropDownOptions();
		}
	}, [groundDataQuestions]);

	useEffect(() => {
		if (groundDatasetToEdit) {
			let tempMomentDate;
			setSelectedGroundDatasetTemplate(groundDatasetToEdit.groundDataset?.groundDatasetTemplateId);
			setSelectedGrowthStage(groundDatasetToEdit.groundDataset?.growthStageId);
			setSelectedPerson(groundDatasetToEdit.groundDataset?.collectedByPersonId);
			tempMomentDate = moment(groundDatasetToEdit.groundDataset?.currentDateCollected, DATE_FORMAT);
			setDateCollected(
				tempMomentDate.isValid()
					? new Date(
							tempMomentDate.year(),
							tempMomentDate.month(),
							tempMomentDate.date(),
							tempMomentDate.hour(),
							tempMomentDate.minute()
					  )
					: null
			);
			setSelectedGrowthPhase(groundDatasetToEdit.groundDataset?.growthPhaseId);

			setSelectedTimingMethodId(groundDatasetToEdit.groundDataset?.selectedTimingMethodId);
			let localSelectedTimingMethodType = _.find(groundDataQuestions.groundDataTimingMethods, {
				id: groundDatasetToEdit.groundDataset?.selectedTimingMethodId
			})?.methodType;
			setSelectedTimingMethodType(localSelectedTimingMethodType);

			if (localSelectedTimingMethodType != "None") {
				if (localSelectedTimingMethodType === "Date") {
					tempMomentDate = moment(groundDatasetToEdit.groundDataset?.plannedTiming, DATE_FORMAT);
					setTargetCollectionDate(
						tempMomentDate.isValid()
							? new Date(
									tempMomentDate.year(),
									tempMomentDate.month(),
									tempMomentDate.date(),
									tempMomentDate.hour(),
									tempMomentDate.minute()
							  )
							: null
					);
				} else if (localSelectedTimingMethodType === "Text") {
					setTargetManualPlantingTiming(groundDatasetToEdit.groundDataset?.plannedTiming);
				} else if (localSelectedTimingMethodType === "StartEnd") {
					setSelectedTargetGrowthStageStart(groundDatasetToEdit.groundDataset?.plannedTiming["growthStageStart"]);
					setSelectedTargetGrowthStageEnd(groundDatasetToEdit.groundDataset?.plannedTiming["growthStageEnd"]);
				} else if (localSelectedTimingMethodType === "int") {
					setDaTimingNumber(groundDatasetToEdit.groundDataset?.plannedTiming);
				} else if (localSelectedTimingMethodType === "Start") {
					setSelectedTargetGrowthStageStart(groundDatasetToEdit.groundDataset?.plannedTiming["growthStageStart"]);
				}
			}
			setCurrentTemplatesMap(_.groupBy(groundDatasetToEdit.assessments, "templateId"));
			setGroundDatasetAssessments(groundDatasetToEdit.assessments);
			setTrialsForDataset(groundDatasetToEdit.trials);
			setHasData(groundDatasetToEdit.groundDataset?.hasData);
		}
	}, [groundDatasetToEdit]);

	useEffect(() => {
		if (growthStages) {
			initializeGrowthStages();
		}
	}, [growthStages]);

	useEffect(() => {
		if (persons) {
			initializePersons();
		}
	}, [persons]);

	useEffect(() => {
		if (calculatedDatasetAssessments) {
			pushAssessment(calculatedDatasetAssessments);
		}
	}, [calculatedDatasetAssessments]);

	useEffect(() => {
		if (selectedGroundDatasetTemplate) {
			initializeAutoDisplayAssessments();
		}
	}, [selectedGroundDatasetTemplate]);

	useEffect(() => {
		setSelectedGroundDatasetTemplates(Object.keys(currentTemplatesMap));
	}, [currentTemplatesMap]);

	const resetDropDownOptions = () => {
		initializeGroundDataAssessments(groundDataQuestions.groundDataAssessments);
		initializeGroundDataUnits(groundDataQuestions.groundDataUnits);
		initializeGroundDataSamples(groundDataQuestions.groundDataSamples);
		initializeGroundDataMethods(groundDataQuestions.groundDataMethods);
		initializeGroundDataDevices(groundDataQuestions.groundDataDevices);
		initializeGroundGrowthPhases(groundDataQuestions.groundDataGrowthPhases);
		initializeGroundDataTimingMethods(groundDataQuestions.groundDataTimingMethods);

		initializeGroundDataUnits(groundDataQuestions.groundDataUnits);
		initializeGroundDataSampleDefinitionPartOptions(
			groundDataQuestions.groundDataSampleDefinitionParts,
			groundDataQuestions.groundDataSampleDefinitionTypes
		);
		initializeGroundDataSampleOptions(groundDataQuestions.groundDataSamples);
		initializeGroundDataSourceOptions(groundDataQuestions.groundDataSources);
		initializeGroundDataTemplateOptions(groundDataQuestions.groundDatasetTemplates);
	};

	const handleDataSetTemplatesSelected = (templates) => {
		// Get a copy of the current templates map (this maps templateId -> assessments)
		const currentTemplatesMapTemp = _.cloneDeep(currentTemplatesMap);
		const currentTemplates = Object.keys(currentTemplatesMapTemp);
		const newTemplates = _.filter(templates, (t) => !currentTemplates.includes(t));
		const removedTemplates = _.filter(currentTemplates, (t) => !templates.includes(t));

		// Also get all assessmentes that don't belong to templates
		let assessmentsWithoutTemplates = _.filter(groundDatasetAssessments, (assessments) => {
			return assessments.templateId === null;
		});

		// We will be reconstructing groundDatasetAssessments everytime we make a change to selected templates
		let newGroundDatasetAssessments = [];

		// First remove the template/assessments that we want removed
		for (let template of removedTemplates) {
			delete currentTemplatesMapTemp[template];
			setEditedTemplatesSet((prev) => {
				const next = new Set(prev);
				next.delete(template);
				return next;
			});
		}

		// Add in all assessments that currently belong to a template
		for (let template of currentTemplates) {
			let tempid = newGroundDatasetAssessments.length - 1;

			let constructedAssessments = _.map(currentTemplatesMapTemp[template], (a) => {
				tempid++;
				return {
					...a,
					id: tempid
				};
			});

			newGroundDatasetAssessments = [...newGroundDatasetAssessments, ...constructedAssessments];
		}

		// Now add in new assessments based on the new templates
		for (let template of newTemplates) {
			//add assessments tied to the template
			//get each of the assessments from the list of templates(since we are only filtering by unique template id)
			let assessmentsFromTemplate = _.filter(groundDatasetTemplates, (tplt) => {
				return tplt.id === template;
			});

			let tempid = newGroundDatasetAssessments.length - 1;
			//now that we have the assessments that we need, we need to actually construct the assessments
			let constructedAssessments = _.map(assessmentsFromTemplate, (aft) => {
				tempid++;
				return {
					assessment: groundDataQuestions.groundDataAssessments.find(
						(assessment) => assessment.id === aft.groundDataAssessmentId
					),
					method: groundDataQuestions.groundDataMethods.find((method) => method.id === aft.groundDataMethodId),
					groundDataSampleDefinitionPart: groundDataQuestions.groundDataSampleDefinitionParts.find(
						(part) => part.id === aft.groundDataSampleDefinitionPartId
					),
					groundDataSampleDefinitionType: groundDataQuestions.groundDataSampleDefinitionTypes.find(
						(type) => type.id === aft.groundDataSampleDefinitionTypeId
					),
					samplesPerPlot: groundDataQuestions.groundDataSamples.find((sample) => sample.id === aft.groundDataSampleId),
					source: groundDataQuestions.groundDataSources.find((source) => source.id === aft.groundDataSourceId),
					unit: groundDataQuestions.groundDataUnits.find((unit) => unit.id === aft.groundDataUnitId),
					name: aft.name,
					width: aft.width ?? 5,
					length: aft.length ?? 40,
					radius: aft.radius ?? 40,
					assessmentId: aft.groundDataAssessmentId,
					sampleDefinitionNumber: aft.sampleDefinitionNumber,
					groundDatasetAssessmentDataId: null,
					notes: aft.notes,
					device: groundDataQuestions.groundDataDevices.find((device) => device.id === aft.groundDataDeviceId),
					id: tempid,
					templateId: template
				};
			});
			currentTemplatesMapTemp[template] = constructedAssessments;
			newGroundDatasetAssessments = [...newGroundDatasetAssessments, ...constructedAssessments];
		}
		// Add in all assessments that don't belong to a template
		newGroundDatasetAssessments = [...newGroundDatasetAssessments, ...assessmentsWithoutTemplates];

		// Update the current map and assessments
		setCurrentTemplatesMap(currentTemplatesMapTemp);
		setGroundDatasetAssessments(newGroundDatasetAssessments);
	};

	function initializeGroundGrowthPhases(groundDataGrowthPhases) {
		const groundDataGrowthPhaseOpts = _.map(groundDataGrowthPhases, (gdgp) => {
			const opt = {
				key: gdgp.id,
				value: gdgp.id,
				text: gdgp.name
			};
			return opt;
		});
		setGroundDataGrowthPhaseOptions(groundDataGrowthPhaseOpts);
		setSelectedGrowthPhase(groundDataGrowthPhaseOpts[0]?.key);
	}

	function initializeGroundDataSampleDefinitionPartOptions(
		groundDataSampleDefinitionParts,
		groundDataSampleDefinitionTypes
	) {
		let index = 0;
		const sampledefinitionPartOpts = _.map(groundDataSampleDefinitionParts, (sdp) => {
			const opt = {
				key: index,
				value: sdp.id,
				text:
					_.find(groundDataSampleDefinitionTypes, (sdtOpt) => {
						return sdtOpt.id === sdp.groundDataSampleDefinitionTypeId;
					})?.name +
					" - " +
					sdp.name,
				sampledefinitionpartid: sdp.id,
				sampledefinitiontypeid: sdp.groundDataSampleDefinitionTypeId,
				lengthrequired: String(sdp.lengthRequired),
				widthrequired: String(sdp.widthRequired),
				radiusrequired: String(sdp.radiusRequired)
			};
			index++;
			return opt;
		});
		setGroundDataSampleDefinitionPartOptions(sampledefinitionPartOpts);
	}

	function initializeGroundDataSampleOptions(groundDataSamples) {
		const groundDataSampleOpts = _.map(groundDataSamples, (gds) => {
			const opt = {
				key: gds.id,
				value: gds.id,
				text: gds.name
			};
			return opt;
		});
		setGroundDataSamplesOptions(groundDataSampleOpts);
	}

	function initializeGroundDataSourceOptions(groundDataSources) {
		const groundDataSourceOpts = _.map(groundDataSources, (gds) => {
			const opt = {
				key: gds.id,
				value: gds.id,
				text: gds.name
			};
			return opt;
		});
		setGroundDataSourceOptions(groundDataSourceOpts);
	}

	function initializeGroundDataTemplateOptions(groundDataTemplates) {
		const groundDataTemplateOpts = _.uniqBy(groundDataTemplates, "id").map((gdt) => {
			const opt = {
				key: gdt.assessmentId,
				value: gdt.id,
				text: gdt.name
			};
			return opt;
		});
		setGroundDatasetTemplates(groundDataTemplates);
		setGroundDatasetTemplatesOptions(groundDataTemplateOpts);

		const templateNameMap = _.reduce(
			_.uniqBy(groundDataTemplates, "id"),
			(acc, curr) => {
				acc[curr.id] = curr.name;
				return acc;
			},
			{}
		);
		setTemplateNamesMap(templateNameMap);
	}

	function initializeGroundDataTimingMethods(groundDataTimingMethods) {
		const groundDataTimingMethodOpts = _.map(groundDataTimingMethods, ({ id, name }) => {
			const opt = {
				key: id,
				value: id,
				text: name
			};
			return opt;
		});
		setGroundDataTimingMethods(groundDataTimingMethodOpts);
		setSelectedTimingMethodId(groundDataTimingMethodOpts[0]?.key);

		let methodType = _.find(groundDataTimingMethods, { id: groundDataTimingMethodOpts[0]?.key })?.methodType;
		setSelectedTimingMethodType(methodType);
	}

	function initializeGrowthStages() {
		const growthStageOpts = _.map(growthStages, (gs) => {
			const opt = {
				key: gs.id,
				value: gs.id,
				text: gs.name,
				disabled: false
			};
			return opt;
		});

		setGrowthStagesOptions(growthStageOpts);
		setGrowthStagesOptionsEnd(growthStageOpts);
	}

	function initializePersons() {
		const personsOpts = _.map(persons, (p) => {
			const opt = {
				key: p.personId,
				value: p.personId,
				text: `${p.firstName} ${p.lastName}`
			};
			return opt;
		});
		setPersonsOptions(personsOpts);
	}

	function initializeGroundDataAssessments(assessments) {
		const assessmentOpts = _.map(assessments, (a) => {
			const opt = {
				key: a.id,
				value: a.id,
				text: a.name,
				methodid: a.groundDataMethodId,
				sourceid: a.groundDataSourceId,
				averagefor: a.averageFor,
				disabled: false
			};
			return opt;
		});
		setGroundDataAssessments(assessments);
		setGroundDataAssessmentsOptions(assessmentOpts);
	}

	function initializeGroundDataUnits(units) {
		const unitsOpts = _.map(units, (u) => {
			const opt = {
				key: u.id,
				value: u.id,
				text: u.name,
				assessmentid: u.groundDataAssessmentId
			};
			return opt;
		});
		setGroundDataUnitsOptions(unitsOpts);
	}

	function initializeGroundDataSamples(samples) {
		setGroundDataSamples(samples);
	}

	function initializeGroundDataMethods(methods) {
		const methodOpts = _.map(methods, (m) => {
			const opt = {
				key: m.id,
				value: m.id,
				text: m.name
				//This is lower case because the console will throw an error about unintentional prop types being passed - JY
				// assessmentid: m.groundDataAssessmentId,
			};
			return opt;
		});

		setGroundDataMethods(methods);
		setGroundDataMethodsOptions(methodOpts);
	}

	function initializeGroundDataDevices(devices) {
		const devicesOpts = _.map(devices, (d) => {
			const opt = {
				key: d.id,
				value: d.id,
				text: d.name,
				grounddatamethodid: d.groundDataMethodId
			};
			return opt;
		});
		setGroundDataDevices(devices);
		setGroundDataDevicesOptions(devicesOpts);
	}

	function initializeAutoDisplayAssessments() {
		let assessmentArr = [];
		_.map(groundDataAssessments, (assessment) => {
			if (assessment.autoDisplay === true) {
				//TODO: find all of the auto display objects for this assessment
				const sample = _.find(groundDataSamples, function (s) {
					return s.autoDisplay === true;
				});

				const method = _.find(groundDataMethods, function (m) {
					return m.groundDataAssessmentId === assessment.id && m.autoDisplay === true;
				});

				const device = _.find(groundDataDevices, function (d) {
					return d.groundDataMethodId === method.id && d.autoDisplay === true;
				});

				let assessmentObj = {
					id: uuid.v4(),
					assessment: assessment,
					// assessmentType: assessmentType,
					// unit: unit,
					sample: sample,
					method: method,
					device: device,
					length: 40,
					width: 5
				};
				assessmentArr.push(assessmentObj);
			}
		});
	}

	async function saveGroundData() {
		let groundDataObj = createGroundDataObject();
		//need to check to see if we actually have trials before we open the dialog. This shouldnt happen and if it does we could end up with orphaned data sets
		let trialsTemp = _.filter(_.uniqBy(outlineData, "trialId"), (data) => data.trialId !== null);
		//if there isnt a trial already and as long as we are on fields make the user add a trial
		if (!isEditing && moduleNavigation.trialId == null && trialsTemp.length > 0 && !openTrialModal) {
			setOpenTrialModal(true);
		} else {
			//check to see if the dataset has a trial or field associated with it
			setSaving(true);
			const errors = validation(groundDataObj);
			if (!errors) {
				if (isEditing) {
					onEdit(groundDataObj);
				} else {
					onSave(groundDataObj);
				}
				onClose();
				handleAddDatasetModalOpen();
				setSaving(false);
				resetDatasetModal();
			} else {
				setSaving(false);
			}
		}
	}

	function cancelModal() {
		resetDatasetModal();
		onClose();
		handleAddDatasetModalOpen();
	}

	function removeAssessment(assessment) {
		let assessmentsTemp = _.cloneDeep(groundDatasetAssessments);

		//--check to see if there are any assessments created for this calculation
		let assessmentsForCalculation = _.filter(assessmentsTemp, (gda) => {
			return gda.calculationForAssessmentId === assessment.assessment?.id;
		});
		if (assessmentsForCalculation.length > 0) {
			_.map(assessmentsForCalculation, (afc) => {
				let index = _.findIndex(assessmentsTemp, { id: afc.id });
				assessmentsTemp[index].calculationForAssessmentId = null;
			});
		}
		let index = _.findIndex(assessmentsTemp, { id: assessment.id });
		assessmentsTemp.splice(index, 1);

		//-- Remove calculated children as well
		assessmentsTemp = _.filter(assessmentsTemp, (at) => at.parentId !== assessment.id);

		setGroundDatasetAssessments(assessmentsTemp);

		// Also remove from template map and update "custom" status
		if (assessment.templateId) {
			const currentTemplatesMapTemp = _.cloneDeep(currentTemplatesMap);
			let templateAssesmentsTemp = _.cloneDeep(currentTemplatesMap[assessment.templateId]);

			if (_.find(templateAssesmentsTemp, { parentId: assessment.id })) {
				templateAssesmentsTemp = [];
			} else {
				const foundIdx = _.findIndex(templateAssesmentsTemp, { id: assessment.id });
				templateAssesmentsTemp.splice(foundIdx, 1);
			}
			currentTemplatesMapTemp[assessment.templateId] = templateAssesmentsTemp;
			if (currentTemplatesMapTemp[assessment.templateId].length === 0) {
				delete currentTemplatesMapTemp[assessment.templateId];
				setEditedTemplatesSet((prev) => {
					const next = new Set(prev);
					next.delete(assessment.templateId);
					return next;
				});
			} else {
				setEditedTemplatesSet((prev) => new Set(prev).add(assessment.templateId));
			}

			setCurrentTemplatesMap(currentTemplatesMapTemp);
		}
	}

	function createGroundDataObject() {
		let groundDataObj = {
			clientId: userAuth.currentClientId,
			fieldHasImportedData: false, // TODO: Change later}
			groundDataset: createdGroundDatasetObject(),
			assessments: groundDatasetAssessments,
			datasetNumericalId: isEditing && groundDatasetToEdit ? groundDatasetToEdit.datasetNumericalId : -1,
			datasetId: isEditing && groundDatasetToEdit ? groundDatasetToEdit.datasetId : null
		};

		return groundDataObj;
	}

	function createdGroundDatasetObject() {
		let groundDatasetObject = {
			actualDateCollected: moment.utc(dateCollected).format(DATE_FORMAT), //actual timing
			currentDateCollected: moment(dateCollected).format(DATE_FORMAT), //planned timing
			collectedByPersonId: selectedPerson,
			plannedTiming: setPlannedTiming(),
			selectedTimingMethodType: selectedTimingMethodType,
			selectedTimingMethodId: selectedTimingMethodId,
			growthStageId: selectedGrowthStage,
			growthPhaseId: selectedGrowthPhase,
			groundDatasetTemplateId: selectedGroundDatasetTemplate,
			trialsToIncludeForField: _.map(trialsForDataset, (tfds) => {
				return tfds.trialId;
			})
		};
		return groundDatasetObject;
	}

	function setPlannedTiming() {
		if (selectedTimingMethodType != "None") {
			if (selectedTimingMethodType === "Date") return moment(targetCollectionDate).format(DATE_FORMAT);
			else if (selectedTimingMethodType === "Text") return targetManualPlantingTiming;
			else if (selectedTimingMethodType === "StartEnd")
				return {
					growthStageStart: selectedTargetGrowthStageStart,
					growthStageEnd: selectedTargetGrowthStageEnd
				};
			else if (selectedTimingMethodType === "Start")
				return {
					growthStageStart: selectedTargetGrowthStageStart
				};
			else if (selectedTimingMethodType === "int") return daTimingNumber;
		}
		return null;
	}

	function updateAssessment(id, property, value) {
		let tempGroundDatasetAssessments = _.cloneDeep(groundDatasetAssessments);
		let matchingGroundDatasetAssessmentIndex = _.findIndex(tempGroundDatasetAssessments, { id: id });

		if (matchingGroundDatasetAssessmentIndex !== -1) {
			tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex][`${property}`] = value;
		}

		// Also update it in it's template (if it has one)
		if (
			matchingGroundDatasetAssessmentIndex !== -1 &&
			tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex].templateId
		) {
			const templateId = tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex].templateId;
			const currentTemplatesMapTemp = _.cloneDeep(currentTemplatesMap);
			const templateAssesmentsTemp = _.cloneDeep(currentTemplatesMap[templateId]);

			if (templateAssesmentsTemp) {
				const foundIdx = _.findIndex(templateAssesmentsTemp, { id: id });
				templateAssesmentsTemp[foundIdx] = tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex];
				currentTemplatesMapTemp[templateId] = templateAssesmentsTemp;

				setCurrentTemplatesMap(currentTemplatesMapTemp);
				// setEditedTemplatesSet((prev) => new Set(prev).add(templateId));
			}
		}

		setGroundDatasetAssessments(tempGroundDatasetAssessments);
	}

	function updateAssessmentObject(id, obj) {
		let tempGroundDatasetAssessments = _.cloneDeep(groundDatasetAssessments);
		let matchingGroundDatasetAssessmentIndex = _.findIndex(tempGroundDatasetAssessments, { id: id });

		if (matchingGroundDatasetAssessmentIndex !== -1) {
			_.map(Object.keys(obj), (key) => {
				tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex][`${key}`] = obj[key];
			});
		}

		// Also update it in it's template (if it has one)
		if (
			matchingGroundDatasetAssessmentIndex !== -1 &&
			tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex].templateId
		) {
			const templateId = tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex].templateId;
			const currentTemplatesMapTemp = _.cloneDeep(currentTemplatesMap);
			const templateAssesmentsTemp = _.cloneDeep(currentTemplatesMap[templateId]);

			if (templateAssesmentsTemp) {
				const foundIdx = _.findIndex(templateAssesmentsTemp, { id: id });
				templateAssesmentsTemp[foundIdx] = tempGroundDatasetAssessments[matchingGroundDatasetAssessmentIndex];
				currentTemplatesMapTemp[templateId] = templateAssesmentsTemp;

				setCurrentTemplatesMap(currentTemplatesMapTemp);
				// setEditedTemplatesSet((prev) => new Set(prev).add(templateId));
			}
		}

		setGroundDatasetAssessments(tempGroundDatasetAssessments);
	}

	//passing id because we need to update the sample def part based on the returned assesments
	function addCalculatedAssessment(selectedGroundDataAssessment, parentId) {
		let calculatedAssessmentDetails = [];
		let calculatedAssessments = _.filter(groundDataQuestions.groundDataCalculations, (calculations) => {
			return calculations.calculationForAssessmentId === selectedGroundDataAssessment;
		});

		_.map(calculatedAssessments, (calculatedAssessment) => {
			let assessmentObj = {
				id: uuid.v4(),
				parentId: parentId,
				templateId: parentId,
				assessment: _.find(groundDataQuestions.groundDataAssessments, (assessment) => {
					return assessment.id === calculatedAssessment.groundDataAssessmentId;
				}),
				assessmentId: calculatedAssessment.groundDataAssessmentId,
				assessmentType: null,
				unit: _.find(groundDataQuestions.groundDataUnits, (unit) => {
					return unit.id === calculatedAssessment.groundDataUnitId;
				}),
				samplesPerPlot: _.find(groundDataQuestions.groundDataSamples, (samples) => {
					return samples.id === calculatedAssessment.groundDataSampleId;
				}),
				method: _.find(groundDataQuestions.groundDataMethods, (method) => {
					return method.id === calculatedAssessment.groundDataMethodId;
				}),
				groundDataSampleDefinitionPart: _.find(groundDataQuestions.groundDataSampleDefinitionParts, (part) => {
					return part.id === calculatedAssessment.groundDataSampleDefinitionPartId;
				}),
				groundDataSampleType: _.find(groundDataQuestions.groundDataSampleDefinitionTypes, (type) => {
					return type.id === calculatedAssessment.groundDataSampleDefinitionTypeId;
				}),
				source: _.find(groundDataQuestions.groundDataSources, (source) => {
					return source.id === calculatedAssessment.groundDataSourceId;
				}),
				sampleDefinitionNumber: 1,
				calculationForAssessmentId: calculatedAssessment.calculationForAssessmentId,
				device: null,
				notes: calculatedAssessment.notes,
				length: 40,
				width: 5,
				radius: 40
			};
			calculatedAssessmentDetails.push(assessmentObj);
		});

		setCalculatedDatasetAssessments(calculatedAssessmentDetails);
	}

	function pushAssessment(assessments) {
		let parentId = assessments[0]?.parentId;
		let clonedTemplateMap = _.cloneDeep(currentTemplatesMap);

		//-- Filtering out existing children
		let clonedGroundDatasetAssessments = _.cloneDeep(groundDatasetAssessments);
		clonedGroundDatasetAssessments = _.filter(clonedGroundDatasetAssessments, (cgda) => cgda.parentId !== parentId);
		delete clonedTemplateMap[parentId];

		//-- Assigning a template id for grouping
		const gdaIndexToEdit = _.findIndex(clonedGroundDatasetAssessments, { id: parentId });
		if (gdaIndexToEdit !== -1) {
			if (!clonedGroundDatasetAssessments[gdaIndexToEdit].templateId) {
				clonedGroundDatasetAssessments[gdaIndexToEdit].templateId = parentId;
				clonedTemplateMap[parentId] = [clonedGroundDatasetAssessments[gdaIndexToEdit]];
			}
		}

		//-- Merging existing and new calculated assessments
		clonedGroundDatasetAssessments = [...clonedGroundDatasetAssessments, ...assessments];
		if (clonedTemplateMap[parentId]?.length) {
			clonedTemplateMap[parentId].push(...assessments);
		} else {
			clonedTemplateMap[parentId] = [...assessments];
		}
		setTemplateNamesMap({ ...templateNamesMap, [parentId]: "Calculated Assessment" });
		setCurrentTemplatesMap(clonedTemplateMap);
		setGroundDatasetAssessments(clonedGroundDatasetAssessments);
	}

	function addAssessment() {
		let assessmentObj = {
			id: uuid.v4(),
			assessment: null,
			assessmentAverage: null,
			assessmentId: null,
			assessmentType: null,
			unit: null,
			samplesPerPlot: null,
			sampleDefinitionNumber: null,
			groundDataSampleDefinitionPart: null,
			calculationForAssessmentId: null,
			method: null,
			device: null,
			templateId: null,
			length: 40,
			width: 5,
			radius: 40,
			notes: null
		};

		setGroundDatasetAssessments(groundDatasetAssessments.concat(assessmentObj));
	}

	//checks to see if a list has duplicates
	function hasDuplicates(a) {
		return _.uniqBy(a, "assessment.id").length !== a.length;
	}

	function validation(groundDataObj) {
		let messages = [];

		const allLengthWidthsValid = _.every(groundDatasetAssessments, (gda) => {
			return gda.length !== "" && gda.width !== "";
		});

		if (hasDuplicates(groundDatasetAssessments)) {
			messages.push(
				"Your dataset contains duplicate assessments. You need to remove one of the duplicates in order to save your dataset."
			);
		}

		if (!allLengthWidthsValid) {
			messages.push("Length and Width is required");
		}
		let potentialMessage = validateDataSet(groundDataObj);
		if (potentialMessage) {
			messages.push(potentialMessage);
		}
		if (
			(selectedTimingMethodType != "None" && groundDataObj.groundDataset.plannedTiming == null) ||
			groundDataObj.groundDataset.plannedTiming == "Invalid date"
		) {
			messages.push("Specific Timing values can not be incomplete");
		}

		_.map(messages, (m) => {
			toast.error(m);
		});

		return messages.length > 0;
	}

	function handleTargetGrowthStageStartChanged(growthStageStart) {
		const endStageOptions = _.map(growthStagesOptions);
		let index = _.findIndex(growthStagesOptions, { key: growthStageStart });
		const filteredEndStageOptions = endStageOptions.splice(index + 1, growthStagesOptions.length - 1);
		setGrowthStagesOptionsEnd(filteredEndStageOptions);
		setSelectedTargetGrowthStageStart(growthStageStart);
	}

	function handleTargetGrowthStageEndChanged(growthStageEnd) {
		setSelectedTargetGrowthStageEnd(growthStageEnd);
	}

	function handleTimingChange(value) {
		let methodType = _.find(groundDataQuestions.groundDataTimingMethods, { id: value })?.methodType;
		setSelectedTimingMethodType(methodType);
	}

	function toggleTrialModal() {
		setOpenTrialModal(!openTrialModal);
	}

	const buildAssessments = (assessments) => {
		return _.map(assessments, (gda) => {
			return (
				<Assessment
					key={gda.id}
					id={gda.id}
					groundDataAssessment={gda}
					groundDataAssessmentsOptionsFiltered={_.filter(groundDataAssessmentsOptions, (aOpts) => {
						return (
							aOpts.sourceid === gda.source?.id &&
							aOpts.methodid === gda.method?.id &&
							aOpts.averagefor === "00000000-0000-0000-0000-000000000000"
						);
					})}
					groundDataAssessmentsOptions={groundDataAssessmentsOptions}
					groundDataAssessmentAverageOptions={_.filter(groundDataAssessmentsOptions, (aOpts) => {
						return aOpts.averagefor != "00000000-0000-0000-0000-000000000000";
					})}
					groundDataUnitsOptions={_.filter(groundDataUnitsOptions, (uOpts) => {
						return uOpts.assessmentid === gda.assessment?.id;
					})}
					groundDataSamplesOptions={groundDataSamplesOptions}
					groundDataMethodsOptions={groundDataMethodsOptions}
					groundDataDevicesOptions={_.filter(groundDataDevicesOptions, (dOpts) => {
						return dOpts.grounddatamethodid === gda.method?.id;
					})}
					updateAssessment={updateAssessment}
					updateAssessmentObject={updateAssessmentObject}
					assessmentSourceOptions={groundDataSourceOptions}
					groundDataSampleDefinitionPartOptions={groundDataSampleDefinitionPartOptions}
					groundDataQuestions={groundDataQuestions}
					setGroundDataAssessmentsOptions={setGroundDataAssessmentsOptions}
					removeAssessment={removeAssessment}
					addCalculatedAssessment={addCalculatedAssessment}
					setAbleToSave={setAbleToSave}
					dateCollected={dateCollected}
					hasData={hasData}
				/>
			);
		});
	};

	return (
		<Modal id="add-dataset-modal" open={openAddDatasetModal} style={{ width: "90%" }}>
			{!groundDatasetToEdit && <Modal.Header>Create New Dataset</Modal.Header>}
			{groundDatasetToEdit && <Modal.Header>Edit Dataset</Modal.Header>}
			<Modal.Content scrolling>
				<Segment basic>
					<Modal.Description>
						<Form>
							<Grid verticalAlign="middle">
								{hasData && (
									<Grid.Row>
										<Grid.Column>
											<Message
												warning
												header="Dataset cannot be edited"
												content="This dataset has already had existing data uploaded and cannot be edited"
											/>
										</Grid.Column>
									</Grid.Row>
								)}
								<Grid.Row columns="equal">
									<Grid.Column textAlign="center">
										<h2>Target Collection</h2>
									</Grid.Column>
									{!isForProtocol && (
										<Grid.Column textAlign="center">
											<h2>Actual Collection</h2>
										</Grid.Column>
									)}
								</Grid.Row>
								<Grid.Row columns="equal">
									<Grid.Column textAlign="left">
										<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
											<div style={{ display: "grid" }}>
												<Form.Field>
													<label htmlFor="form-select-growth-phase">Growth Phase</label>
												</Form.Field>
												<Form.Select
													id="form-select-growth-phase"
													search
													required
													error={!selectedGrowthPhase}
													options={groundDataGrowthPhaseOptions}
													value={selectedGrowthPhase}
													onChange={(event, { value }) => {
														setSelectedGrowthPhase(value);
													}}
												/>
											</div>
											<div style={{ display: "grid" }}>
												<Form.Field>
													<label htmlFor="form-select-timing-method">Timing Method</label>
												</Form.Field>
												<Form.Select
													id="form-select-timing-method"
													search
													required
													options={groundDataTimingMethods}
													value={selectedTimingMethodId}
													error={!selectedTimingMethodId}
													onChange={(event, { value }) => {
														setSelectedTimingMethodId(value);
														handleTimingChange(value);
													}}
												/>
											</div>
											{selectedTimingMethodType !== "None" && (
												<div style={{ display: "grid" }}>
													<Form.Field>
														<label htmlFor="form-specific-timing">Specific Timing</label>
													</Form.Field>
													{selectedTimingMethodType === "Date" && (
														<Form.Field
															id="form-specific-timing"
															control={DateTimePicker}
															includeTime={true}
															value={targetCollectionDate ? new Date(targetCollectionDate) : null}
															onChange={(date) => {
																if (date) {
																	setTargetCollectionDate(date);
																} else {
																	setTargetCollectionDate(null);
																}
															}}
														/>
													)}
													{selectedTimingMethodType === "Text" && (
														<Form.Input
															id="form-specific-timing"
															defaultValue={targetManualPlantingTiming}
															onBlur={(event) => {
																setTargetManualPlantingTiming(event.target.value);
															}}
														/>
													)}
													{selectedTimingMethodType === "StartEnd" && (
														<div style={{ display: "flex", gap: "20px" }}>
															<Form.Select
																id="form-target-growth-stage-start"
																fluid
																search
																options={growthStagesOptions}
																value={selectedTargetGrowthStageStart}
																onChange={(event, { value }) => {
																	handleTargetGrowthStageStartChanged(value);
																	//check to see what the end date is and verify that its order is after the start
																}}
															/>
															<Form.Select
																id="form-target-growth-stage-end"
																search
																fluid
																options={growthStagesOptionsEnd}
																value={selectedTargetGrowthStageEnd}
																onChange={(event, { value }) => {
																	handleTargetGrowthStageEndChanged(value);
																}}
															/>
														</div>
													)}
													{selectedTimingMethodType === "Start" && (
														<Form.Select
															id="form-target-growth-stage-start"
															fluid
															search
															options={growthStagesOptions}
															value={selectedTargetGrowthStageStart}
															onChange={(event, { value }) => {
																handleTargetGrowthStageStartChanged(value);
															}}
														></Form.Select>
													)}
													{selectedTimingMethodType === "int" && (
														<Form.Input
															id="form-specific-timing"
															defaultValue={daTimingNumber}
															fluid
															onBlur={(event) => {
																if (!isNaN(event.target.value)) {
																	setDaTimingNumber(event.target.value);
																}
															}}
														/>
													)}
												</div>
											)}
										</div>
									</Grid.Column>
									<Grid.Column textAlign="left">
										<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
											{!isForProtocol && (
												<>
													<div style={{ dipslay: "grid" }}>
														<Form.Field>
															<label htmlFor="form-date-time-picker-date-collected">Date Collected</label>
														</Form.Field>
														<Form.Field
															id="form-date-time-picker-date-collected"
															control={DateTimePicker}
															includeTime={true}
															value={dateCollected ? new Date(dateCollected) : null}
															onChange={(date) => {
																if (date) {
																	setDateCollected(date);
																} else {
																	setDateCollected(null);
																}
															}}
														/>
													</div>
													<div style={{ dipslay: "grid" }}>
														<Form.Field>
															<label htmlFor="form-growth-stage">Growth Stage</label>
														</Form.Field>
														<Form.Select
															clearable
															id="form-select-growth-stage"
															search
															options={growthStagesOptions}
															value={selectedGrowthStage}
															onChange={(event, { value }) => {
																setSelectedGrowthStage(value);
															}}
														></Form.Select>
													</div>
													<div style={{ dipslay: "grid" }}>
														{" "}
														<Form.Field>
															<label htmlFor="form-collected-by">Collected By</label>
														</Form.Field>
														<Form.Select
															clearable
															id="form-select-collected-by"
															search
															options={personsOptions}
															value={selectedPerson}
															onChange={(event, { value }) => {
																setSelectedPerson(value);
															}}
														></Form.Select>
													</div>
												</>
											)}
										</div>
									</Grid.Column>
								</Grid.Row>
								<Divider />
								<Grid.Row style={{ height: "600px" }}>
									<Grid.Column style={{ flexGrow: "1" }}>
										<div
											style={{
												display: "flex",
												overflow: "auto",
												flexWrap: "nowrap",
												gap: "10px",
												flexBasis: "start",
												alignItems: "flex-start"
											}}
										>
											{_.map(
												Object.entries(_.groupBy(groundDatasetAssessments, "templateId")),
												([template, assessments]) => {
													return templateNamesMap[template] !== undefined ? (
														<div style={{ display: "grid" }}>
															<Button
																icon="minus circle"
																onClick={() => {
																	const temp = _.cloneDeep(currentTemplatesMap);
																	delete temp[template];
																	handleDataSetTemplatesSelected(Object.keys(temp));
																}}
																disabled={hasData}
																onKeyPress={() => {}}
																style={{ background: "white", width: "fit-content", margin: "auto" }}
															/>
															<Segment style={{ marginTop: "0" }}>
																<Header>
																	Template: {templateNamesMap[template]}{" "}
																	{editedTemplatesSet.has(template) && "(Custom)"}
																</Header>
																<div
																	style={{
																		display: "flex",
																		flexWrap: "nowrap",
																		gap: "10px",
																		flexBasis: "start",
																		alignItems: "flex-start"
																	}}
																>
																	{buildAssessments(assessments)}
																</div>
															</Segment>
														</div>
													) : (
														<>{buildAssessments(assessments)}</>
													);
												}
											)}
										</div>
									</Grid.Column>
									<Grid.Column style={{ width: "fit-content", maxWidth: "200px" }}>
										<Button
											id="button-add-assment"
											primary
											onClick={addAssessment}
											//fluid
											icon
											disabled={hasData}
											//content="Add Assessment"
											labelPosition="left"
										>
											<Icon name="plus" />
											Add Assessment
										</Button>
										<Dropdown
											style={{ marginTop: "10px" }}
											button
											fluid
											pointing
											labeled
											multiple
											direction="left"
											icon="plus"
											labelPosition="left"
											text="Add Template"
											className="green icon"
											selectOnBlur={false}
											disabled={hasData}
											options={groundDatasetTemplatesOptions}
											value={selectedGroundDatasetTemplates}
											onChange={(event, { value }) => {
												handleDataSetTemplatesSelected(value);
											}}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
						<br style={{ clear: "both" }} />
					</Modal.Description>
				</Segment>
			</Modal.Content>
			<Modal id="add-trial-to-dataset-modal" open={openTrialModal} style={{ width: "30%" }}>
				<Modal.Content>
					<Modal.Header>Select Trials for the Dataset</Modal.Header>
					<Modal.Description>
						<List
							id="form-select-trials"
							size="big"
							style={{
								overflowY: "auto",
								border: "1px solid #DEDEDF",
								backgroundColor: "#FFFFFF",
								marginTop: 4,
								minHeight: 100,
								maxHeight: 168
							}}
						>
							{_.map(
								_.filter(_.uniqBy(outlineData, "trialId"), (data) => data.trialId !== null),
								(value) => {
									return (
										<List.Item key={value?.trialId}>
											<List.Content>
												<Checkbox
													label={value?.trial}
													style={{ padding: 5 }}
													checked={trialsForDataset?.includes(value)}
													onChange={(event, data) => {
														if (data.checked) {
															setTrialsForDataset(trialsForDataset.concat(value));
														} else {
															setTrialsForDataset(trialsForDataset.filter((t) => t !== value));
														}
													}}
												/>
											</List.Content>
										</List.Item>
									);
								}
							)}
						</List>
					</Modal.Description>
					<Divider />
					<Modal.Actions>
						<Button
							content="Save"
							color="green"
							loading={saving}
							disabled={saving}
							floated="right"
							onClick={() => {
								toggleTrialModal();
								saveGroundData();
							}}
						/>
						<Button floated="right" id="button-close-trials-modal" onClick={() => toggleTrialModal()}>
							Cancel
						</Button>
					</Modal.Actions>
				</Modal.Content>
			</Modal>
			<Divider />
			<Button
				style={{ marginBottom: "5px", marginRight: "5px" }}
				content="Save"
				color="green"
				loading={saving}
				disabled={saving || groundDatasetAssessments.length === 0 || !ableToSave || !requiredDataSetFieldsSet}
				floated="right"
				onClick={() => saveGroundData()}
			/>
			<Button
				style={{ marginBottom: "5px", marginRight: "5px" }}
				floated="right"
				id="button-close-analysis-modal"
				onClick={cancelModal}
			>
				Cancel
			</Button>
		</Modal>
	);
};

DataSetModal.propTypes = {
	groundDatasetToEdit: PropTypes.object,
	groundDataQuestions: PropTypes.object,
	growthStages: PropTypes.array,
	persons: PropTypes.array,
	onSave: PropTypes.func,
	onEdit: PropTypes.func,
	onClose: PropTypes.func,
	openAddDatasetModal: PropTypes.bool,
	isEditing: PropTypes.bool,
	isForProtocol: PropTypes.bool,
	handleAddDatasetModalOpen: PropTypes.func,
	validateDataSet: PropTypes.func,
	outlineData: PropTypes.array
};

export default DataSetModal;
