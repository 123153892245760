const imageApiUri = process.env.aerialPlotConnections.aerialImageApi.baseUrl;
const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getFieldsInFlight(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/Flight?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getUsedFieldsInFlight(flightId, orthoImageTypeId, clientId, accessToken) {
	return fetch(
		aerialApiUri +
			"api/v1/" +
			"Fields/UsedFieldsInOrtho?flightId=" +
			flightId +
			"&orthoImageTypeId=" +
			orthoImageTypeId +
			"&clientId=" +
			clientId,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getUnusedFieldsInFlight(clientId, flightId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/UnusedFieldsInOrtho?clientId=${clientId}&flightId=${flightId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getFieldInfo(clientId, flightId, accessToken) {
	let getFieldInfoUrl = `${aerialApiUri}api/v1/Fields/GetNewEditFieldInfo?clientId=${clientId}`;
	getFieldInfoUrl = flightId !== null ? `${getFieldInfoUrl}&flightId=${flightId}` : `${getFieldInfoUrl}`;

	return fetch(getFieldInfoUrl, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function saveNewField(field, accessToken) {
	const fieldFormData = new FormData();

	for (const name in field) {
		if (name !== "fieldLayout") {
			let val = field[name];

			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			fieldFormData.set(name, val);
		}
	}

	return fetch(`${aerialApiUri}api/v1/Fields`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: fieldFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getField(fieldId, accessToken) {
	return fetch(aerialApiUri + "api/v1/" + "Fields?fieldId=" + fieldId, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function editField(field, accessToken) {
	const fieldFormData = new FormData();

	for (const name in field) {
		if (name !== "fieldLayout") {
			let val = field[name] === null ? "" : field[name];

			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			fieldFormData.set(name, val);
		}
	}

	return fetch(`${aerialApiUri}api/v1/Fields`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: fieldFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export async function deleteField(fieldId, clientId, accessToken) {
	var deleteFieldImage = await fetch(`${imageApiUri}api/v1/Delete/Field?fieldId=${fieldId}&clientId=${clientId}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		}
	});

	const deleteImageRes = await deleteFieldImage;

	if (deleteImageRes.ok === true) {
		var deleteImage = await fetch(`${aerialApiUri}api/v1/Fields?fieldId=${fieldId}&clientId=${clientId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			}
		});

		return await deleteImage;
	}

	return deleteImageRes;
}

export function getFieldSearchDataOptions(clientId, searchAllClients, accessToken) {
	return fetch(
		aerialApiUri + `api/v1/Fields/FieldSearchDataOptions?clientId=${clientId}&searchAllClients=${searchAllClients}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function searchForFields(searchParams, clientId, searchAllClients, accessToken) {
	return fetch(
		aerialApiUri +
			`api/v1/Fields/SearchForFields?clientId=${clientId}&plantDateFrom=${searchParams.plantDateFrom}&plantDateTo=${searchParams.plantDateTo}&searchAllClients=${searchAllClients}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getFlightsWithFieldOrTrial(clientId, fieldId, trialId, accessToken) {
	let url = `${aerialApiUri}api/v1/Fields/FlightsWithFieldOrTrial?clientId=${clientId}`;

	if (fieldId) {
		url += `&fieldId=${fieldId}`;
	}

	if (trialId) {
		url += `&trialId=${trialId}`;
	}

	return fetch(url, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export async function deleteFieldInFlight(flightId, fieldId, clientId, accessToken) {
	var deleteFieldImage = await fetch(
		`${imageApiUri}api/v1/Delete/FieldInFlight?flightId=${flightId}&fieldId=${fieldId}&clientId=${clientId}`,
		{
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			}
		}
	);

	const deleteImageRes = await deleteFieldImage;

	if (deleteImageRes.ok === true) {
		return fetch(
			`${aerialApiUri}api/v1/Fields/DeleteFieldInFlight?flightId=${flightId}&fieldId=${fieldId}&clientId=${clientId}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`
				}
			}
		)
			.then((res) => res)
			.then((data) => data);
	}
}

export function getFieldOutlineData(clientId, fieldId, accessToken) {
	return fetch(aerialApiUri + `api/v1/Fields/GetFieldOutlineData?clientId=${clientId}&fieldId=${fieldId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getFieldAndPlots(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/FieldAndPlots?fieldId=${fieldId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getFieldsInFlightOrthoInformation(flightId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}api/v1/OrthoUploadData/FieldsInFlightOrthoSize?flightId=${flightId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getShapeFilePoints(shpFile, shxFile, accessToken) {
	const formData = new FormData();

	formData.append("shpFile", shpFile, shpFile.name);
	formData.append("shxFile", shxFile, shxFile.name);
	return fetch(`${imageApiUri}api/v1/Fields/ShapeFilePoints`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getFieldLayoutInfo(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/Layout?fieldId=${fieldId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function editFieldLayout(field, fieldLayout, accessToken) {
	const fieldFormData = new FormData();

	fieldFormData.append("plotInfoCsv", fieldLayout, fieldLayout.name);

	for (const name in field) {
		let val = field[name] === null ? "" : field[name];

		fieldFormData.set(name, val);
	}

	return fetch(`${aerialApiUri}api/v1/Fields/Layout`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: fieldFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getFieldAdvancedAnalyticsData(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/AdvancedAnalytics?fieldId=${fieldId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function getNewFieldInfo(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/GetNewFieldInfo?fieldId=${fieldId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function saveFieldQuantifiedRegions(
	fieldId,
	clientId,
	quantifiedRegionsToCreate,
	quantifiedRegionsToUpdate,
	quantifiedRegionsToDelete,
	accessToken
) {
	let quantifiedRegionsFormData = new FormData();
	quantifiedRegionsFormData.set("fieldId", fieldId);
	quantifiedRegionsFormData.set("clientId", clientId);

	quantifiedRegionsToCreate.forEach((q, index) => {
		quantifiedRegionsFormData.append(
			`NewFieldQuantifiedRegions[${index}].QuantifiedRegionTypeId`,
			q.quantifiedRegionTypeId
		);
		quantifiedRegionsFormData.append(
			`NewFieldQuantifiedRegions[${index}].QuantifiedRegionTypeName`,
			q.quantifiedRegionTypeName
		);
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].Width`, q.width);
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].Length`, q.length);
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].Columns`, q.columns);
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].Ranges`, q.ranges);
		quantifiedRegionsFormData.append(
			`NewFieldQuantifiedRegions[${index}].QuantifiedRegionGeometryId`,
			q.quantifiedRegionGeometryId
		);
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].QuantifiedWidth`, q.quantifiedWidth ?? "");
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].QuantifiedLength`, q.quantifiedLength ?? "");
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].QuantifiedRadius`, q.quantifiedRadius ?? "");
		quantifiedRegionsFormData.append(`NewFieldQuantifiedRegions[${index}].IsDefault`, q.isDefault ?? "");
	});

	quantifiedRegionsToUpdate.forEach((q, index) => {
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].Id`, q.id);
		quantifiedRegionsFormData.append(
			`EditFieldQuantifiedReigons[${index}].QuantifiedRegionTypeId`,
			q.quantifiedRegionTypeId
		);
		quantifiedRegionsFormData.append(
			`NewFieldQuantifiedRegions[${index}].QuantifiedRegionTypeName`,
			q.quantifiedRegionTypeName
		);
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].Width`, q.width);
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].Length`, q.length);
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].Columns`, q.columns);
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].Ranges`, q.ranges);
		quantifiedRegionsFormData.append(
			`EditFieldQuantifiedReigons[${index}].QuantifiedRegionGeometryId`,
			q.quantifiedRegionGeometryId
		);
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].QuantifiedWidth`, q.quantifiedWidth ?? "");
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].QuantifiedLength`, q.quantifiedLength ?? "");
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].QuantifiedRadius`, q.quantifiedRadius ?? "");
		quantifiedRegionsFormData.append(`EditFieldQuantifiedReigons[${index}].IsDefault`, q.isDefault ?? "");
	});
	quantifiedRegionsToDelete.forEach((q, index) => {
		quantifiedRegionsFormData.append(`DeletedFieldQuantifiedRegionIds[${index}]`, q);
	});

	return fetch(`${aerialApiUri}api/v1/QuantifiedRegions`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: quantifiedRegionsFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function saveFieldCoordinates(fieldId, llLat, ulLat, llLong, ulLong, degreeHeading, clientId, accessToken) {
	let formData = new FormData();
	formData.set("fieldId", fieldId);
	formData.set("clientId", clientId);
	formData.set("llLat", llLat ?? "");
	formData.set("ulLat", ulLat ?? "");
	formData.set("llLong", llLong ?? "");
	formData.set("ulLong", ulLong ?? "");
	formData.set("degreeHeading", degreeHeading ?? "");

	return fetch(`${aerialApiUri}api/v1/Fields/EditCoordinates`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getDefaultViewerSettingForField(clientId, fieldId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/GetDefaultViewerSettingForField?clientId=${clientId}&fieldId=${fieldId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function createDefaultViewerSettingForField(
	clientId,
	fieldId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	const newDefaultViewerSettingFormData = new FormData();
	newDefaultViewerSettingFormData.set("fieldId", fieldId ?? "");
	newDefaultViewerSettingFormData.set("toggleHeatmapData", toggleHeatmapData ?? "");
	newDefaultViewerSettingFormData.set("toggleImageType", toggleImageType ?? "");
	newDefaultViewerSettingFormData.set("viewableFlightId", viewableFlightId ?? "");
	newDefaultViewerSettingFormData.set("viewableOrthoImageTypeId", viewableOrthoImageTypeId ?? "");
	newDefaultViewerSettingFormData.set("groundDatasetId", groundDatasetId ?? "");
	newDefaultViewerSettingFormData.set("groundDataAssessmentId", groundDataAssessmentId ?? "");
	newDefaultViewerSettingFormData.set("curveModelAnalysisId", curveModelAnalysisId ?? "");
	newDefaultViewerSettingFormData.set("analysisIdForCurveModel", analysisIdForCurveModel ?? "");
	newDefaultViewerSettingFormData.set("analysisId", analysisId ?? "");
	newDefaultViewerSettingFormData.set("analysisFlightId", analysisFlightId ?? "");

	return fetch(`${aerialApiUri}api/v1/Fields/CreateDefaultViewerSettingForField?clientId=${clientId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: newDefaultViewerSettingFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function updateDefaultViewerSettingForField(
	clientId,
	id,
	fieldId,
	toggleHeatmapData,
	toggleImageType,
	viewableFlightId,
	viewableOrthoImageTypeId,
	groundDatasetId,
	groundDataAssessmentId,
	curveModelAnalysisId,
	analysisIdForCurveModel,
	analysisId,
	analysisFlightId,
	accessToken
) {
	const targetDefaultViewerSettingFormData = new FormData();
	targetDefaultViewerSettingFormData.set("id", id ?? "");
	targetDefaultViewerSettingFormData.set("fieldId", fieldId ?? "");
	targetDefaultViewerSettingFormData.set("toggleHeatmapData", toggleHeatmapData ?? "");
	targetDefaultViewerSettingFormData.set("toggleImageType", toggleImageType ?? "");
	targetDefaultViewerSettingFormData.set("viewableFlightId", viewableFlightId ?? "");
	targetDefaultViewerSettingFormData.set("viewableOrthoImageTypeId", viewableOrthoImageTypeId ?? "");
	targetDefaultViewerSettingFormData.set("groundDatasetId", groundDatasetId ?? "");
	targetDefaultViewerSettingFormData.set("groundDataAssessmentId", groundDataAssessmentId ?? "");
	targetDefaultViewerSettingFormData.set("curveModelAnalysisId", curveModelAnalysisId ?? "");
	targetDefaultViewerSettingFormData.set("analysisIdForCurveModel", analysisIdForCurveModel ?? "");
	targetDefaultViewerSettingFormData.set("analysisId", analysisId ?? "");
	targetDefaultViewerSettingFormData.set("analysisFlightId", analysisFlightId ?? "");

	return fetch(`${aerialApiUri}api/v1/Fields/UpdateDefaultViewerSettingForField?clientId=${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: targetDefaultViewerSettingFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function getPlotAnalysisStatusForField(fieldId, clientId, accessToken) {
	return fetch(`${aerialApiUri}api/v1/Fields/GetPlotAnalysisStatusForField?fieldId=${fieldId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}
