import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function clientLogoReducer(state = initialState.clientLogoUri, action) {
	switch (action.type) {
		case types.CLIENT_LOGOS_GET_LOGO_URL_SUCCESS:
			return action.clientLogoUri;

		case types.CLIENT_LOGOS_DELETE_CLIENT_LOGO_SUCCESS:
			return null;

		default:
			return state;
	}
}
