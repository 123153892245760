import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Icon, Loader, Popup, Segment } from "semantic-ui-react";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as protocolActions from "../../../../redux/actions/protocolActions";
import * as statisticsActions from "../../../../redux/actions/statisticsActions";
import * as trialActions from "../../../../redux/actions/trialActions";
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";
import * as protocolApi from "../../../../apis/protocolApi";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";
import * as areaUnderTheCurve from "../../../Lumber/AreaUnderTheCurve";
import DataAnalysisCriteria from "../../../Lumber/DataAnalysisCriteria";
import LineChart from "../../Widgets/Charts/lineChart";
import AnovaTable from "./AnovaTable";
import ProtocolBoxWhiskerChart from "./ProtocolBoxWhiskerChart";
import ProtocolDataNormalizationTable from "./ProtocolDataNormalizationTable";
import ProtocolSiteSpecificTable from "./ProtocolSiteSpecificTable";
import ProtocolTimecourseSiteSpecificTable from "./ProtocolTimecourseSiteSpecificTable";
import "./style.css";

import _ from "lodash";
import { std } from "mathjs";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MetaTagsTable from "./MetaTagsTable.tsx";

const ProtocolTimecourse = ({
	dataNormalizationOptions,
	meanComparisonOptions,
	treatmentData,
	alphaOptions,
	trialIds,
	trialAnalysisOptions,
	statelessDataNormalizationOptions,
	defaultMeanComparisonOption,
	defaultAlphaOption,
	setWeatherDataDone,
	toggleMetaFactorModal,
	setIsDirty,
	setSaveAction
}) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const getProtocolTrialFilters = async (clientId) => {
		const accessToken = await getTokenSilently();
		const json = protocolApi.getProtocolTrialFilters(clientId, accessToken);
		return json;
	};

	const { isLoading: trialFiltersLoading, data: protocolTrialFilters } = useQuery({
		queryKey: ["protocol trial filters", userAuth.currentClientId],
		queryFn: () => getProtocolTrialFilters(userAuth.currentClientId),
		select: (data) => data.data
		// refetchOnWindowFocus: false, // might want?
	});

	const updateTrialsMetaTags = async (updateInfo) => {
		const accessToken = await getTokenSilently();
		return protocolApi.updateTrialsMetaTags(updateInfo, userAuth.currentClientId, accessToken);
	};

	const { mutate: updateMetaTags } = useMutation({
		mutationFn: (updateInfo) => updateTrialsMetaTags(updateInfo),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["meta factors", userAuth.currentClientId] });
			toast.success("Meta Tags updated successfully!");
		},
		onError: (error) => {
			toast.error("Meta Tags failed to update.");
			console.error(error);
		}
	});

	const [plannedTimingOptions, setPlannedTimingOptions] = useState([
		{
			key: 1,
			value: 1,
			text: "DAP"
		}
	]);
	const [selectedOrthoTypeOption, setSelectedOrthoTypeOption] = useState(null);
	const [selectedNormalizationOption, setSelectedNormalizationOption] = useState(null);
	const [selectedMeanComparisonTestOption, setSelectedMeanComparisonTestOption] = useState(defaultMeanComparisonOption);
	const [selectedAlphaOption, setSelectedAlphaOption] = useState(defaultAlphaOption);
	const [selectedPlannedTimingOption, setSelectedPlannedTimingOption] = useState(null);
	const [timecourseStart, setTimecourseStart] = useState(0);
	const [timecourseEnd, setTimecourseEnd] = useState(0);
	const [initialTimecourseStart, setInitialTimecourseStart] = useState(0);
	const [selectedQuantifiedRegionTypeOption, setSelectedQuantifiedRegionTypeOption] = useState(null);
	const [selectedAnalysisTypeOption, setSelectedAnalysisTypeOption] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [treatmentsChecked, setTreatmentsChecked] = useState(null);
	const [trialsChecked, setTrialsChecked] = useState(null);
	const [initTrialsChecked, setInitTrialsChecked] = useState({});
	const [showAveragesChecked, setShowAveragesChecked] = useState(true);
	const [tukeyRunning, setTukeyRunning] = useState(false);
	const [useGdu, setUseGdu] = useState(false);
	const [showTwoWayAnova, setShowTwoWayAnova] = useState(false);
	const [hideMeanComparisonResults, setHideMeanComparisonResults] = useState(false);
	const [firstTimeLoad, setFirstTimeLoad] = useState(true);
	const [allTrialsHavePlantDateSet, setAllTrialsHavePlantDateSet] = useState(false);
	const [needToRerunStats, setNeedToRerunStats] = useState(false);
	const [initialGroupTrialDataCheckedCount, setInitialGroupTrialDataCheckedCount] = useState([]);
	const [hideLetters, setHideLetters] = useState(false);
	const [needToRerunNormalization, setNeedToRerunNormalization] = useState(false);
	const [hideLineCharts, setHideLineCharts] = useState(false);
	const [uniqueAnalysesCount, setUniqueAnalysesCount] = useState(null);
	const [initialDataMessageSent, setInitialDataMessageSent] = useState(false);
	const [oneTrialDatasetError, setOneTrialDatasetError] = useState(false);
	const [multiTrialDatasetError, setMultiTrialDatasetError] = useState(false);
	const [hideUncheckedTrialData, setHideUncheckedTrialData] = useState(false);
	const [disableColors, setDisableColors] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [trialFilter, setTrialFilter] = useState({
		value: "",
		filters: {
			quality: [],
			status: [],
			response: []
		}
	});

	const trialFilterOptions = useMemo(() => {
		const opts =
			protocolTrialFilters?.map((opt) => ({
				key: opt.id,
				text: opt.name,
				filters: {
					quality: opt.trialDataQualityIds,
					status: opt.trialExclusionStatusIds,
					response: opt.trialSiteResponseIds
				},
				value: opt.id
			})) ?? [];
		opts.push({
			key: "custom",
			text: "Custom",
			filters: {},
			value: "custom"
		});

		return opts;
	}, [protocolTrialFilters]);

	//-- Data Sources
	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));
	const [rawTrialData, setRawTrialData] = useState(null);
	const [rawPresetOptions, setRawPresetOptions] = useState(null);
	const [filteredSiteSpecificData, setFilteredSiteSpecificData] = useState(null);
	const [compiledData, setCompiledData] = useState(null);
	const [allCompiledData, setAllCompiledData] = useState([]);
	const [compiledTukeyData, setCompiledTukeyData] = useState([]);
	const [compiledTrialTukeyData, setCompiledTrialTukeyData] = useState([]);
	const [allCompiledTukeyData, setAllCompiledTukeyData] = useState([]);
	const [allCompiledTrialTukeyData, setAllCompiledTrialTukeyData] = useState([]);
	const [trialPlotSpecificData, setTrialPlotSpecificData] = useState(null);
	const [trialPlotSpecificDataCopy, setTrialPlotSpecificDataCopy] = useState(null);
	const [allTrialPlotSpecificData, setAllTrialPlotSpecificData] = useState([]);
	const [timecourseData, setTimecourseData] = useState(null);
	const [trialData, setTrialData] = useState(null);
	const [filteredTrialData, setFilteredTrialData] = useState(null);
	const [filteredTrialDataCopy, setFilteredTrialDataCopy] = useState(null);
	const [filteredAucData, setFilteredAucData] = useState([]);
	const [aucData, setAucData] = useState([]);
	const [gduByDay, setGDUByDay] = useState(null);
	const [allAssessmentTypes, setAllAssessmentTypes] = useState(null);
	const [selectedAssessmentName, setSelectedAssessmentName] = useState(null);

	const weatherDataEnabled = true;
	//const [weatherDataDone, setWeatherDataDone] = useState(false);
	const [anovaData, setAnovaData] = useState(null);
	const [allAnovaData, setAllAnovaData] = useState([]);
	const [singleTrialAnovaValues, setSingleTrialAnovaValues] = useState(null);
	const [allSingleTrialAnovaValues, setAllSingleTrialAnovaValues] = useState([]);

	const [allAucData, setAllAucData] = useState([]);
	const [allFilteredAucData, setAllFilteredAucData] = useState([]);
	const [allFilteredSiteSpecificData, setAllFilteredSiteSpecificData] = useState([]);

	//-- Dropdown Options
	const [orthoTypeOptions, setOrthoTypeOptions] = useState(null);
	const [filteredDataNormalizationOptions, setFilteredDataNormalizationOptions] = useState(null);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);

	//Data Analysis Critera
	const [needToUpdateAnalytics, setNeedToUpdateAnalytics] = useState(false);
	const [removeDamagedOrExcluded, setRemoveDamagedOrExcluded] = useState(true);
	const [removeOutliers, setRemoveOutliers] = useState(false);
	const [outlierType, setOutlierType] = useState(3);

	const trialInfo = useMemo(
		() =>
			_.sortBy(
				_.map(
					_.filter(trialData, (td) =>
						_.map(_.uniqBy(filteredSiteSpecificData, "trialId"), "trialId").includes(td.trialInfo.trialId)
					),
					(td) => td.trialInfo
				),
				["farmState", "farmCity", "trialName", "cooperatorName", "trialId"]
			),
		[trialData, filteredSiteSpecificData]
	);

	useEffect(() => {
		getTrialData(); //get data for all the trials
	}, []);

	useEffect(() => {
		if (trialFilterOptions.length) {
			setTrialFilter(trialFilterOptions.find((opt) => opt.text === "Remove Excluded Trials"));
		}
	}, [trialFilterOptions]);

	useEffect(() => {
		if (trialData && trialFilter?.value && trialFilter.value !== "custom") {
			for (let { trialInfo } of trialData) {
				if (
					!trialFilter.filters.status.includes(trialInfo.trialExclusionStatusId) &&
					!trialFilter.filters.response.includes(trialInfo.trialSiteResponseId) &&
					!trialFilter.filters.quality.includes(trialInfo.trialDataQualityId)
				) {
					updateTrialsChecked(true, trialInfo.trialId, true);
				} else {
					updateTrialsChecked(false, trialInfo.trialId, true);
				}
			}
		}
	}, [trialFilter, trialData, tukeyRunning]);

	//UseEffect for setting unique analysis count which is used to disable stuff when loading
	useEffect(() => {
		if (!_.isEmpty(timecourseData) && !_.isEmpty(rawPresetOptions)) {
			//-- Remove analyses that are only associated with 1 trial
			const analysesByTrial = _.uniqBy(
				_.filter(timecourseData, (td) => td.analysisId && td.analysisName),
				(td) => [td.analysisId, td.trialId].join()
			);

			let count = _.filter(
				_.uniqBy(
					_.filter(timecourseData, (r) => {
						return (
							r.quantifiedRegionTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) =>
										(rpo.analysisId === r.analysisId &&
											(rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
										(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
								) || selectedQuantifiedRegionTypeOption) &&
							r.analysisTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
								)?.analysisTypeId || selectedAnalysisTypeOption)
						);
					}),
					(rd) => [rd.analysisId, rd.analysisTypeId].join()
				),
				(u) => {
					return (
						u.analysisTypeId !== null &&
						_.filter(analysesByTrial, (abt) => abt.analysisId === u.analysisId)?.length > 1 &&
						_.find(
							rawPresetOptions?.timeSeriesPresets,
							(rp) => rp.analysisId === u.analysisId && rp.analysisTypeId === u.analysisTypeId
						)
					);
				}
			);
			setUniqueAnalysesCount(count);
		}
	}, [allCompiledTrialTukeyData]);

	//UseEffect for generating loading messages
	useEffect(() => {
		if (
			timecourseData?.length > 0 &&
			allCompiledTukeyData?.length > 0 &&
			allSingleTrialAnovaValues?.length > 0 &&
			singleTrialAnovaValues
		) {
			if (
				!loading &&
				!tukeyRunning &&
				allCompiledTukeyData?.length === uniqueAnalysesCount?.length &&
				initialDataMessageSent
			) {
				toast.success("All data has finished loading.", { toastId: "allFinished" });
			} else {
				if (
					aucData?.length > 0 &&
					!loading &&
					!tukeyRunning &&
					!initialDataMessageSent &&
					!multiTrialDatasetError &&
					!oneTrialDatasetError
				) {
					if (!_.isEmpty(singleTrialAnovaValues[0]?.letteringPairs)) {
						toast.info("Selected assessment data has finished loading. Remaining assessment data is still loading.", {
							autoClose: 8000
						});
						setInitialDataMessageSent(true);
					}
				}
			}
		}
	}, [allCompiledTukeyData, allSingleTrialAnovaValues, initialDataMessageSent]);

	useEffect(() => {
		if (plannedTimingOptions) {
			setSelectedPlannedTimingOption(plannedTimingOptions[0].key);
		}
	}, [plannedTimingOptions]);

	//Weather Data UseEffect to Add GDU Timing Option
	useEffect(() => {
		if (weatherDataEnabled) {
			if (gduByDay && plannedTimingOptions.length === 1) {
				const tempPTO = plannedTimingOptions;
				tempPTO.push({
					key: 2,
					value: 2,
					text: "GDU"
				});
				setPlannedTimingOptions(tempPTO);
			}
		}
	}, [gduByDay]);

	useEffect(() => {
		if (dataNormalizationOptions) {
			const percentMean = _.find(dataNormalizationOptions, (d) => {
				return d.text.toLowerCase() === "% mean";
			})?.key;
			setSelectedNormalizationOption(percentMean ?? dataNormalizationOptions[0]?.key);
		}
	}, [dataNormalizationOptions]);

	useEffect(() => {
		if (!_.isEmpty(filteredAucData) && _.isEmpty(filteredSiteSpecificData) && firstTimeLoad) {
			calculateSiteData();
		}
		if (firstTimeLoad && !_.isEmpty(timecourseData) && !_.isEmpty(allFilteredAucData)) {
			let uniqueAnalyses = _.filter(
				_.uniqBy(
					_.filter(timecourseData, (r) => {
						return (
							r.quantifiedRegionTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) =>
										(rpo.analysisId === r.analysisId &&
											(rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
										(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
								) || selectedQuantifiedRegionTypeOption) &&
							r.analysisTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
								)?.analysisTypeId || selectedAnalysisTypeOption)
						);
					}),
					(rd) => [rd.analysisId, rd.analysisTypeId].join()
				),
				(u) => {
					return (
						u.analysisTypeId !== null &&
						_.find(
							rawPresetOptions?.timeSeriesPresets,
							(rp) => rp.analysisId === u.analysisId && rp.analysisTypeId === u.analysisTypeId
						)
					);
				}
			);

			//Get all AUC data on initial load
			_.map(uniqueAnalyses, (analysis) => {
				calculateSiteData(analysis.analysisId, analysis.analysisTypeId);
			});
		}
	}, [filteredAucData]);

	useEffect(() => {
		if (
			filteredSiteSpecificData?.length > 0 &&
			selectedAlphaOption &&
			selectedMeanComparisonTestOption &&
			firstTimeLoad &&
			_.isEmpty(compiledTukeyData)
		) {
			updateCompiledData(filteredSiteSpecificData, false);
		}
	}, [filteredSiteSpecificData]);

	useEffect(() => {
		if (!_.isEmpty(allCompiledData) && selectedOrthoTypeOption !== null && !firstTimeLoad) {
			let compiledData = [];
			_.map(allCompiledData, (acd) => {
				_.map(acd, (a) => {
					if (a.analysisId === selectedOrthoTypeOption && a.analysisTypeId === selectedAnalysisTypeOption) {
						compiledData.push(a);
					}
				});
			});
			setCompiledData(compiledData);
			let tukeyData = _.filter(allCompiledTukeyData, (key) => {
				let tukeyKey = Object.keys(key);
				if (tukeyKey[0] === selectedOrthoTypeOption && key.analysisTypeId === selectedAnalysisTypeOption) {
					return key;
				}
			});

			let trialTukeyData = _.filter(allCompiledTrialTukeyData, (key) => {
				let tukeyKey = Object.keys(key);
				if (tukeyKey[0] === selectedOrthoTypeOption && key.analysisTypeId === selectedAnalysisTypeOption) {
					return key;
				}
			});

			if (!_.isEmpty(tukeyData)) {
				setCompiledTukeyData(tukeyData[0]);
			}

			if (!_.isEmpty(trialTukeyData)) {
				setCompiledTrialTukeyData(trialTukeyData[0]);
			}

			let tempSingleTrialAnovaValues = [];
			tempSingleTrialAnovaValues = _.filter(allSingleTrialAnovaValues, (acd) => {
				return acd.analysisId === selectedOrthoTypeOption && acd.analysisTypeId === selectedAnalysisTypeOption;
			});

			if (!_.isEmpty(tempSingleTrialAnovaValues)) {
				setSingleTrialAnovaValues(tempSingleTrialAnovaValues[0]);
			}

			let anovaData = _.filter(allAnovaData, (aad) => {
				return aad.analysisId === selectedOrthoTypeOption && aad.analysisTypeId === selectedAnalysisTypeOption;
			});

			if (!_.isEmpty(anovaData)) {
				setAnovaData(anovaData[0]);
			}
		}
	}, [selectedAssessmentName, treatmentsChecked]);

	useEffect(() => {
		if (timecourseData) {
			if (!orthoTypeOptions || orthoTypeOptions.length === 0) {
				const analyses = _.uniqBy(
					_.filter(timecourseData, (tcd) => tcd.analysisId && tcd.analysisName),
					"analysisId"
				).map(function (analysis) {
					return { key: analysis.analysisId, value: analysis.analysisId, text: analysis.analysisName };
				});

				if (firstTimeLoad) {
					const analysesByTrial = _.uniqBy(
						_.filter(timecourseData, (td) => td.analysisId && td.analysisName),
						(td) => [td.analysisId, td.trialId].join()
					);

					//-- Default to Aerial NDVI
					let defaultOrthoType = _.find(analyses, { text: "Aerial NDVI" })?.key;

					//-- Check if Aerial NDVI exists and has at least 2 trials associated with it
					if (_.filter(analysesByTrial, (abt) => abt.analysisId === defaultOrthoType)?.length < 2) {
						//-- Loop over all analyses and find first one with at least 2 trials associated with it
						_.map(analyses, (a) => {
							if (_.filter(analysesByTrial, (abt) => abt.analysisId === a.key)?.length > 1) {
								defaultOrthoType = a.key;
								return;
							}
						});
					}

					setSelectedOrthoTypeOption(defaultOrthoType ?? analyses[0]?.key);
				}

				setOrthoTypeOptions(analyses);
				getTrialDataForTimeCourse();
			}

			if (quantifiedRegionTypeOptions.length === 0) {
				initializeQuantifiedRegionOptions();
			}
		}
	}, [timecourseData]);

	useEffect(() => {
		updateCheckedSiteData();
		updateCheckedTrialPlotSpecificData();
		updateCompiledDataChecked();
		if (!firstTimeLoad) {
			setAllCompiledData([]);
			updateCompiledData(filteredSiteSpecificData, false, true);

			let uniqueAnalyses = _.filter(
				_.uniqBy(
					_.filter(timecourseData, (r) => {
						return (
							r.quantifiedRegionTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) =>
										(rpo.analysisId === r.analysisId &&
											(rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
										(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
								) || selectedQuantifiedRegionTypeOption) &&
							r.analysisTypeId ===
								(_.find(
									rawPresetOptions?.timeSeriesPresets,
									(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
								)?.analysisTypeId || selectedAnalysisTypeOption)
						);
					}),
					(rd) => [rd.analysisId, rd.analysisTypeId].join()
				),
				(u) => {
					return (
						u.analysisTypeId !== null &&
						_.find(
							rawPresetOptions?.timeSeriesPresets,
							(rp) => rp.analysisId === u.analysisId && rp.analysisTypeId === u.analysisTypeId
						)
					);
				}
			);

			_.map(uniqueAnalyses, (analysis) => {
				recalculateSiteDataWhenTrialsAreUnchecked(analysis.analysisId, analysis.analysisTypeId);
			});
		}
	}, [trialsChecked]);

	useEffect(() => {
		if (rawTrialData && reduxProtocolData) {
			if (!treatmentsChecked) {
				initializeTreatmentsChecked();
			}
			if (!trialsChecked) {
				initializeTrialsChecked();
			}
		}
	}, [rawTrialData, reduxProtocolData]);

	useEffect(() => {
		if (rawTrialData?.length > 0 && !firstTimeLoad) {
			finishUpdateMeanComparisonData();
		}
	}, [rawTrialData]);

	useEffect(() => {
		if (selectedOrthoTypeOption && selectedAssessmentName && trialData?.length > 0) {
			if (firstTimeLoad) {
				let timeCourseDataCopy = _.cloneDeep(timecourseData);
				let timeCourseDataTemp = _.filter(timeCourseDataCopy, (tcd) => {
					return tcd.analysisId == selectedOrthoTypeOption && tcd.analysisTypeId === selectedAnalysisTypeOption;
				});

				let trialDataCopy = _.cloneDeep(trialData);
				let trialDataTemp = _.filter(trialDataCopy, (tdc) => {
					return _.some(timeCourseDataTemp, (tcdt) => {
						return tcdt.trialId == tdc.trialInfo.trialId;
					});
				});

				setFilteredTrialData(trialDataTemp);
				setFilteredTrialDataCopy(trialDataTemp);
			} else {
				let trialsOnAssessment = _.map(
					_.uniqBy(
						_.filter(
							timecourseData,
							(td) => td.analysisId === selectedOrthoTypeOption && td.analysisTypeId === selectedAnalysisTypeOption
						),
						(td) => td.trialId
					),
					"trialId"
				);

				let trialDataTemp = _.filter(trialData, (td) => trialsOnAssessment.includes(td.trialId));

				setFilteredTrialData(trialDataTemp);
				setFilteredTrialDataCopy(trialDataTemp);

				filterAllAucData();
				filterAllFilteredSiteSpecificData();
				filterAllTrialPlotSpecificData();
			}
		}
	}, [selectedAssessmentName, trialData]);

	useEffect(() => {
		if (dataNormalizationOptions && treatmentData) {
			const filteredValues = _.filter(dataNormalizationOptions, (dno) => {
				const check =
					dno.treatmenttypeid === null ||
					_.some(treatmentData, (td) => {
						return dno.treatmenttypeid == td.treatmentTypeId;
					});
				return check;
			});
			setFilteredDataNormalizationOptions(filteredValues);
		}
	}, [dataNormalizationOptions, treatmentData]);

	//Weather Data UseEffect to Get Daily GDU values
	useEffect(() => {
		if (weatherDataEnabled) {
			if (!gduByDay && trialData) {
				getGduByDay(trialData);
			}
		}
	}, [trialData]);

	async function getGduByDay(trials) {
		const accessToken = await getTokenSilently();
		let trialIds = _.map(trials, "trialInfo.trialId");
		let weatherDataTemp = [];
		dispatch(weatherDataActions.getGduDataForTrials(trialIds, userAuth.currentClientId, accessToken))
			.then((res) => {
				_.forEach(res, (r) => {
					weatherDataTemp.push({ trialId: r.trialDataViewModel?.trialInfo.trialId, data: r });
				});
				setGDUByDay(res);
				setWeatherDataDone(true);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		if (
			selectedOrthoTypeOption &&
			selectedQuantifiedRegionTypeOption &&
			timecourseData?.length > 0 &&
			filteredTrialData?.length > 0 &&
			selectedAnalysisTypeOption
		) {
			if (firstTimeLoad) {
				calculateAuc(
					filteredTrialData,
					timecourseData,
					selectedOrthoTypeOption,
					selectedQuantifiedRegionTypeOption,
					selectedAnalysisTypeOption
				);

				let uniqueAnalyses = _.filter(
					_.uniqBy(
						_.filter(timecourseData, (r) => {
							return (
								r.quantifiedRegionTypeId ===
									(_.find(
										rawPresetOptions?.timeSeriesPresets,
										(rpo) =>
											(rpo.analysisId === r.analysisId &&
												(rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
											(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
									) || selectedQuantifiedRegionTypeOption) &&
								r.analysisTypeId ===
									(_.find(
										rawPresetOptions?.timeSeriesPresets,
										(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
									)?.analysisTypeId || selectedAnalysisTypeOption)
							);
						}),
						(rd) => [rd.analysisId, rd.analysisTypeId].join()
					),
					(u) => {
						return (
							u.analysisTypeId !== null &&
							_.find(
								rawPresetOptions?.timeSeriesPresets,
								(rp) => rp.analysisId === u.analysisId && rp.analysisTypeId === u.analysisTypeId
							)
						);
					}
				);

				//Get all AUC data on initial load
				_.map(uniqueAnalyses, (analysis) => {
					let timeCourseDataCopy = _.cloneDeep(timecourseData);
					let timeCourseDataTemp = _.filter(timeCourseDataCopy, (tcd) => {
						return tcd.analysisId == analysis.analysisId && tcd.analysisTypeId === analysis.analysisTypeId;
					});

					let trialDataCopy = _.cloneDeep(trialData);
					let trialDataTemp = _.filter(trialDataCopy, (tdc) => {
						return _.some(timeCourseDataTemp, (tcdt) => {
							return tcdt.trialId == tdc.trialInfo.trialId;
						});
					});
					calculateAllAuc(
						trialDataTemp,
						timecourseData,
						analysis.analysisId,
						selectedQuantifiedRegionTypeOption,
						analysis.analysisTypeId
					);
				});
			}
		}
	}, [
		timecourseData,
		filteredTrialData,
		selectedOrthoTypeOption,
		selectedQuantifiedRegionTypeOption,
		selectedAnalysisTypeOption
	]);

	useEffect(() => {
		if (compiledData && compiledData.length > 0 && firstTimeLoad === true) {
			updateMeanComparisonData();
			setFirstTimeLoad(false);
		}
	}, [compiledData]);

	useEffect(() => {
		if (reduxProtocolData) {
			if (!defaultMeanComparisonOption) {
				setSelectedMeanComparisonTestOption(reduxProtocolData?.meanComparisonId ?? meanComparisonOptions[0]?.key);
			}

			if (!defaultAlphaOption) {
				setSelectedAlphaOption(reduxProtocolData?.alphaId ?? alphaOptions[0]?.key);
			}
		}
	}, [reduxProtocolData]);

	useEffect(() => {
		if (filteredTrialData && filteredTrialData.length > 0) {
			let hasAllPlantDates = _.every(filteredTrialData, (td) => {
				return td?.trialInfo?.plantDate;
			});

			setAllTrialsHavePlantDateSet(hasAllPlantDates);
		}
	}, [filteredTrialData]);

	function filterAllAucData() {
		if (!_.isEmpty(allAucData)) {
			let filteredByAnalysisType = _.filter(allAucData, (afad) => {
				return (afad.analysisTypeId = selectedAnalysisTypeOption);
			});
			let aucData = [];
			_.map(filteredByAnalysisType, (aud) => {
				_.map(aud, (a) => {
					if (a.analysisId === selectedOrthoTypeOption) {
						aucData.push(a);
					}
				});
			});
			let trialAucData = [];
			let trialData = _.groupBy(aucData, "trialId");
			_.map(trialData, (data) => {
				let tempData = data;
				trialAucData.push(tempData);
			});
			setAucData(trialAucData);
			setFilteredAucData(trialAucData);
		}
	}

	function filterAllFilteredSiteSpecificData() {
		if (!_.isEmpty(allFilteredSiteSpecificData)) {
			let aucData = [];
			_.map(allFilteredSiteSpecificData, (aud) => {
				_.map(aud, (a) => {
					if (a.analysisId === selectedOrthoTypeOption && a.analysisTypeId === selectedAnalysisTypeOption) {
						aucData.push(a);
					}
				});
			});

			let updatedSiteData = _.map(aucData, (ssd) => {
				const treatment = _.find(treatmentsChecked, (tc) => {
					return tc.trialTreatmentId === ssd.trialTreatmentId;
				});

				ssd.treatmentChecked = treatment.treatmentChecked;
				return ssd;
			});

			updatedSiteData = _.map(updatedSiteData, (ssd) => {
				const trial = _.find(trialsChecked, (tc) => {
					return tc.trialId === ssd.trialId;
				});
				ssd.trialChecked = trial.trialChecked;
				return ssd;
			});
			setFilteredSiteSpecificData(updatedSiteData);
		}
	}

	function filterAllTrialPlotSpecificData() {
		if (!_.isEmpty(allTrialPlotSpecificData)) {
			let trialPlotSpecificData = [];
			_.map(allTrialPlotSpecificData, (aud) => {
				_.map(aud, (a) => {
					if (a.analysisId === selectedOrthoTypeOption && a.analysisTypeId === selectedAnalysisTypeOption) {
						trialPlotSpecificData.push(a);
					}
				});
			});

			let updatedCheckedData = _.map(trialPlotSpecificData, (tpsd) => {
				const treatment = _.find(treatmentsChecked, (tc) => {
					return tc.trialTreatmentId === tpsd.trialTreatmentId;
				});

				tpsd.treatmentChecked = treatment.treatmentChecked;
				return tpsd;
			});

			updatedCheckedData = _.map(updatedCheckedData, (tpsd) => {
				const trial = _.find(trialsChecked, (tc) => {
					return tc.trialId === tpsd.trialId;
				});

				tpsd.trialChecked = trial.trialChecked;
				return tpsd;
			});

			setTrialPlotSpecificData(updatedCheckedData);
			setTrialPlotSpecificDataCopy(updatedCheckedData);
		}
	}

	function calculateAuc(
		trialsInfos,
		allTimecourseData,
		orthoType,
		quantifiedRegionId,
		analysisTypeId,
		isForRecalc = false
	) {
		let auc = [];
		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});
		_.map(trialsInfos, (trialsD) => {
			let plantAndHarvestFlightValues = [];

			if (timecourseStart !== null && Number(timecourseStart) === 0) {
				if (trialsD.trialInfo.harvestDate) {
					const harvestTreatments = areaUnderTheCurve.addEmptyFlightForPlantOrHarvestDate(
						trialsD.trialInfo.harvestDate,
						_.filter(allTimecourseData, (tcd) => {
							return tcd.trialId == trialsD.trialInfo.trialId && tcd.quantifiedRegionTypeId === quantifiedRegionId;
						}),
						trialsD.treatments,
						orthoTypeOptions,
						true,
						false,
						userAuth.currentClientId
					);
					if (harvestTreatments) {
						plantAndHarvestFlightValues = plantAndHarvestFlightValues.concat(harvestTreatments);
					}
				}

				if (trialsD.trialInfo.plantDate) {
					const plantTreatments = areaUnderTheCurve.addEmptyFlightForPlantOrHarvestDate(
						trialsD.trialInfo.plantDate,
						_.filter(allTimecourseData, (tcd) => {
							return tcd.trialId == trialsD.trialInfo.trialId && tcd.quantifiedRegionTypeId === quantifiedRegionId;
						}),
						trialsD.treatments,
						orthoTypeOptions,
						false,
						true,
						userAuth.currentClientId
					);

					if (plantTreatments) {
						plantAndHarvestFlightValues = plantAndHarvestFlightValues.concat(plantTreatments);
					}
				}
			}

			auc.push(
				areaUnderTheCurve.getAreaUnderTheCurve(
					[
						..._.filter(allTimecourseData, (tcd) => {
							return (
								tcd.trialId == trialsD.trialInfo.trialId &&
								tcd.quantifiedRegionTypeId === quantifiedRegionId &&
								tcd.analysisTypeId === analysisTypeId
							);
						}),
						...plantAndHarvestFlightValues
					],
					orthoType,
					trialsD,
					useGdu,
					null,
					removeDamagedOrExcluded,
					removeOutliers,
					outlierType,
					_.filter(gduByDay, (gbd) => {
						return gbd.trialId == trialsD.trialInfo.trialId;
					}),
					uncheckedList
				)
			);
		});
		setAucData(auc);
		setFilteredAucData(auc);
		if (isForRecalc) {
			calculateSiteData(null, null, auc);
		}
	}

	function calculateAllAuc(trialsInfos, allTimecourseData, orthoType, quantifiedRegionId, analysisTypeId) {
		let tempAucValues = [];
		_.map(trialsInfos, (trialsD) => {
			let plantAndHarvestFlightValues = [];

			if (timecourseStart !== null && Number(timecourseStart) === 0) {
				if (trialsD.trialInfo.harvestDate) {
					const harvestTreatments = areaUnderTheCurve.addEmptyFlightForPlantOrHarvestDate(
						trialsD.trialInfo.harvestDate,
						_.filter(allTimecourseData, (tcd) => {
							return tcd.trialId == trialsD.trialInfo.trialId && tcd.quantifiedRegionTypeId === quantifiedRegionId;
						}),
						trialsD.treatments,
						orthoTypeOptions,
						true,
						false,
						userAuth.currentClientId
					);
					if (harvestTreatments) {
						plantAndHarvestFlightValues = plantAndHarvestFlightValues.concat(harvestTreatments);
					}
				}

				if (trialsD.trialInfo.plantDate) {
					const plantTreatments = areaUnderTheCurve.addEmptyFlightForPlantOrHarvestDate(
						trialsD.trialInfo.plantDate,
						_.filter(allTimecourseData, (tcd) => {
							return tcd.trialId == trialsD.trialInfo.trialId && tcd.quantifiedRegionTypeId === quantifiedRegionId;
						}),
						trialsD.treatments,
						orthoTypeOptions,
						false,
						true,
						userAuth.currentClientId
					);

					if (plantTreatments) {
						plantAndHarvestFlightValues = plantAndHarvestFlightValues.concat(plantTreatments);
					}
				}
			}

			let aucValues = areaUnderTheCurve.getAreaUnderTheCurve(
				[
					..._.filter(allTimecourseData, (tcd) => {
						return (
							tcd.trialId == trialsD.trialInfo.trialId &&
							tcd.quantifiedRegionTypeId === quantifiedRegionId &&
							tcd.analysisTypeId === analysisTypeId
						);
					}),
					...plantAndHarvestFlightValues
				],
				orthoType,
				trialsD,
				useGdu,
				null,
				removeDamagedOrExcluded,
				removeOutliers,
				outlierType,
				_.filter(gduByDay, (gbd) => {
					return gbd.trialId == trialsD.trialInfo.trialId;
				})
			);
			aucValues.analysisTypeId = analysisTypeId;
			setAllAucData((allAucData) => [...allAucData, aucValues]);
			setAllFilteredAucData((allFilteredAucData) => [...allFilteredAucData, aucValues]);
			tempAucValues.push(aucValues);
		});

		if (needToUpdateAnalytics && !firstTimeLoad) {
			reCalculateSiteData(orthoType, tempAucValues, analysisTypeId);
		}
	}

	function filterTimeCourseDataByStartAndEndTimes(updatedRawTrialData) {
		let dapTrialData = [];
		const flattenedRawTrialData = _.flatten(updatedRawTrialData);
		if (timecourseEnd == 0) {
			return flattenedRawTrialData;
		}

		if (!useGdu) {
			_.map(trialData, (td) => {
				let firstFlightDate =
					td.trialInfo.plantDate ??
					_.minBy(
						_.filter(flattenedRawTrialData, (x) => {
							return x.trialId === td.trialInfo.trialId;
						}),
						"flightDate"
					)?.flightDate;
				let flightDateFilteredResults = _.filter(flattenedRawTrialData, (adr) => {
					return (
						adr.trialId === td.trialInfo.trialId &&
						moment(adr.flightDate) <= moment(firstFlightDate).add(Number(timecourseEnd), "d") &&
						moment(adr.flightDate) >= moment(firstFlightDate).add(Number(timecourseStart), "d")
					);
				});
				dapTrialData = dapTrialData.concat(flightDateFilteredResults);
			});
		}

		if (useGdu) {
			_.map(trialData, (td) => {
				let trialsGDUsByDay = _.find(gduByDay, (gdu) => {
					return gdu.trialId === td.trialInfo.trialId;
				});

				let flightGDUs = areaUnderTheCurve.calculateCumulativeGdu(flattenedRawTrialData, trialsGDUsByDay.data);

				flightGDUs = _.filter(flightGDUs, (fg) => {
					return Number(fg.gdu) <= timecourseEnd && Number(fg.gdu) >= timecourseStart;
				});

				let flightDateFilteredResults = _.filter(flattenedRawTrialData, (adr) => {
					return (
						adr.trialId === td.trialInfo.trialId &&
						_.some(flightGDUs, (fg) => {
							return fg.flightId === adr.flightId;
						})
					);
				});
				dapTrialData = dapTrialData.concat(flightDateFilteredResults);
			});
		}
		return dapTrialData;
	}

	async function updateMeanComparisonData() {
		setHideLetters(false);
		setOneTrialDatasetError(false);
		setMultiTrialDatasetError(false);
		//-- Filter if any treatment has changed
		if (_.some(treatmentsChecked, (tc) => tc?.dirty)) {
			//-- Reset dirty flags
			let clonedTreatmentsChecked = _.map(_.cloneDeep(treatmentsChecked), (tc) => {
				tc.dirty = false;
				return tc;
			});
			setTreatmentsChecked(clonedTreatmentsChecked);
		}

		if (needToUpdateAnalytics && !firstTimeLoad) {
			setInitialTimecourseStart(timecourseStart);
			setTukeyRunning(true);
			setAucData([]);
			setFilteredAucData([]);
			setCompiledData([]);
			setAllCompiledData([]);
			setCompiledTukeyData([]);
			setSingleTrialAnovaValues([]);
			setCompiledTrialTukeyData([]);
			setFilteredSiteSpecificData([]);
			setTrialPlotSpecificDataCopy([]);
			setTrialPlotSpecificData([]);
			setAllAnovaData([]);
			setAllCompiledTrialTukeyData([]);
			setAllAucData([]);
			setAllCompiledTukeyData([]);
			setAllFilteredAucData([]);
			setAllFilteredSiteSpecificData([]);
			setAllTrialPlotSpecificData([]);
			setAllSingleTrialAnovaValues([]);
			setFilteredTrialDataCopy([]);
			setUniqueAnalysesCount(null);
			setInitialDataMessageSent(false);
			setHideUncheckedTrialData(false);

			let treatmentCheckedList = [];
			let orderedTreatmentList = _.orderBy(treatmentsChecked, "trialTreatmentId");
			_.filter(orderedTreatmentList, (utc) => {
				if (utc.treatmentChecked) {
					treatmentCheckedList.push(true);
				} else {
					treatmentCheckedList.push(false);
				}
			});
			setInitialGroupTrialDataCheckedCount(treatmentCheckedList);
			setInitTrialsChecked({});

			getTrialData();
		} else {
			setNeedToRerunStats(false);
			setNeedToRerunNormalization(false);
			setHideLineCharts(false);
		}
	}

	function finishUpdateMeanComparisonData() {
		let tempTimecourseData = filterTimeCourseDataByStartAndEndTimes(rawTrialData);

		let timeCourseDataCopy = _.cloneDeep(tempTimecourseData);
		let timeCourseDataTemp = _.filter(timeCourseDataCopy, (tcd) => {
			return tcd.analysisId == selectedOrthoTypeOption && tcd.analysisTypeId === selectedAnalysisTypeOption;
		});

		let trialDataCopy = _.cloneDeep(trialData);
		let trialDataTemp = _.filter(trialDataCopy, (tdc) => {
			return _.some(timeCourseDataTemp, (tcdt) => {
				return tcdt.trialId == tdc.trialInfo.trialId;
			});
		});

		setFilteredTrialData(trialDataTemp);

		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});
		let clone = trialDataTemp;
		_.map(clone, (ftd) => {
			_.remove(ftd.treatments, (t) => {
				return _.includes(uncheckedList, t.trialTreatmentId);
			});
		});
		setFilteredTrialDataCopy(clone);

		calculateAuc(
			trialDataTemp,
			tempTimecourseData,
			selectedOrthoTypeOption,
			selectedQuantifiedRegionTypeOption,
			selectedAnalysisTypeOption,
			true
		);

		let uniqueAnalyses = _.filter(
			_.uniqBy(
				_.filter(timecourseData, (r) => {
					return (
						r.quantifiedRegionTypeId ===
							(_.find(
								rawPresetOptions?.timeSeriesPresets,
								(rpo) =>
									(rpo.analysisId === r.analysisId &&
										(rpo.analysisTypeId === r.analysisTypeId)?.quantifiedRegionTypeId) ||
									(rpo.analysisTypeId === r.analysisTypeId)?.secondaryQuantifiedRegionTypeId
							) || selectedQuantifiedRegionTypeOption) &&
						r.analysisTypeId ===
							(_.find(
								rawPresetOptions?.timeSeriesPresets,
								(rpo) => rpo.analysisId === r.analysisId && rpo.analysisTypeId === r.analysisTypeId
							)?.analysisTypeId || selectedAnalysisTypeOption)
					);
				}),
				(rd) => [rd.analysisId, rd.analysisTypeId].join()
			),
			(u) => {
				return (
					u.analysisTypeId !== null &&
					_.find(
						rawPresetOptions?.timeSeriesPresets,
						(rp) => rp.analysisId === u.analysisId && rp.analysisTypeId === u.analysisTypeId
					)
				);
			}
		);

		//Get all AUC data on initial load
		_.map(uniqueAnalyses, (analysis) => {
			let timeCourseDataCopy = _.cloneDeep(timecourseData);
			let timeCourseDataTemp = _.filter(timeCourseDataCopy, (tcd) => {
				return tcd.analysisId == analysis.analysisId && tcd.analysisTypeId === analysis.analysisTypeId;
			});

			let trialDataCopy = _.cloneDeep(trialData);
			let trialDataTemp = _.filter(trialDataCopy, (tdc) => {
				return _.some(timeCourseDataTemp, (tcdt) => {
					return tcdt.trialId == tdc.trialInfo.trialId;
				});
			});
			calculateAllAuc(
				trialDataTemp,
				tempTimecourseData,
				analysis.analysisId,
				selectedQuantifiedRegionTypeOption,
				analysis.analysisTypeId
			);
		});

		setNeedToRerunStats(false);
		setNeedToRerunNormalization(false);
		setHideLineCharts(false);
	}

	//-- this function gets the data that is used to build out the site specific components
	async function getTrialDataForTimeCourse() {
		const accessToken = await getTokenSilently();

		dispatch(trialActions.getMetadataForTrials(trialIds, userAuth.currentClientId, accessToken))
			.then((res) => {
				//Add props to make sorting easier (sorting it the same way as the site table)
				_.map(res, (trial) => {
					trial.farmState = trial.trialInfo.farmState;
					trial.farmCity = trial.trialInfo.farmCity;
					trial.trialName = trial.trialInfo.trialName;
					trial.trialId = trial.trialInfo.trialId;
					trial.cooperatorName = trial.trialInfo.cooperatorName;
					trial.hasExclusions = trial.trialInfo.hasExclusions;
					trial.hasFlaggings = trial.trialInfo.hasFlaggings;
					trial.hasPartialExclusions = trial.trialInfo.hasPartialExclusions;
					trial.hasPartialFlaggings = trial.trialInfo.hasPartialFlaggings;
				});
				let orderedData = _.sortBy(res, ["farmState", "farmCity", "trialName", "cooperatorName", "trialId"]);

				setTrialData(orderedData);
				setLoading(false);
			})
			.catch((err) => {
				toast.error("Failed to retrieve Trial Data. Please contact support.");
				console.error(err);
			});
	}

	function getSingleSiteAucMeanComparisons(data) {
		//-- Only look at analyses that have more than 1 trial associated with them
		const analysesByTrial = _.uniqBy(
			_.filter(data, (d) => d.analysisId && d.analysisName),
			(d) => [d.analysisId, d.trialId].join()
		);

		let filteredTrialAnalysisOptions = _.filter(
			trialAnalysisOptions,
			(ao) => _.filter(analysesByTrial, (abt) => abt.analysisId === ao.id)?.length > 1
		);

		let defaultAnalysisId =
			selectedOrthoTypeOption ??
			_.find(filteredTrialAnalysisOptions, { displayName: "NDVI" })?.id ??
			filteredTrialAnalysisOptions[0]?.id;
		let quantifiedRegionTypeId =
			selectedQuantifiedRegionTypeOption ??
			_.find(filteredTrialAnalysisOptions, { quantifiedRegionTypeName: "Plot-Centered with Subsamples" })
				?.quantifiedRegionTypeId ??
			filteredTrialAnalysisOptions[0]?.quantifiedRegionTypeId;
		let analysisTypeId =
			selectedAnalysisTypeOption ??
			_.find(filteredTrialAnalysisOptions, { analysisTypeName: "Aerial" })?.analysisTypeId ??
			filteredTrialAnalysisOptions[0]?.analysisTypeId;

		//-- Get mean comparison results for currently selected analysis
		getProtocolAucMeanComparisonData(defaultAnalysisId, analysisTypeId, quantifiedRegionTypeId, false);

		//-- Get mean comparison results for rest of analyses
		_.map(
			_.filter(
				trialAnalysisOptions,
				(tao) =>
					tao.quantifiedRegionTypeId === quantifiedRegionTypeId &&
					!(tao.id === defaultAnalysisId && tao.analysisTypeId === analysisTypeId)
			),
			(a) => {
				getProtocolAucMeanComparisonData(a.id, a.analysisTypeId, quantifiedRegionTypeId, true);
			}
		);
	}

	async function getProtocolAucMeanComparisonData(analysisId, analysisTypeId, quantifiedRegionTypeId, isForAllData) {
		const accessToken = await getTokenSilently();

		let uncheckedTrials = trialsChecked
			? _.map(
					_.filter(trialsChecked, (tc) => !tc.trialChecked),
					"trialId"
			  )
			: [];
		let uncheckedTreatments = treatmentsChecked
			? _.map(
					_.filter(treatmentsChecked, (tc) => !tc.treatmentChecked),
					"trialTreatmentId"
			  )
			: [];

		let isUniformity =
			_.find(trialAnalysisOptions, { analysisTypeId: analysisTypeId })?.analysisTypeName === "Uniformity";
		let meanComparisonId =
			selectedMeanComparisonTestOption ?? reduxProtocolData?.meanComparisonId ?? meanComparisonOptions[0]?.key;
		let alphaId = selectedAlphaOption ?? reduxProtocolData?.alphaId ?? alphaOptions[0]?.key;
		let dataNormalizationId =
			selectedNormalizationOption ??
			_.find(statelessDataNormalizationOptions, { name: "% MEAN" })?.id ??
			statelessDataNormalizationOptions[0]?.id;

		dispatch(
			protocolActions.getAucMeanComparisonLettersForProtocol(
				uncheckedTrials,
				uncheckedTreatments,
				moduleNavigation.protocolId,
				userAuth.currentClientId,
				analysisId,
				analysisTypeId,
				null,
				null,
				null,
				quantifiedRegionTypeId,
				meanComparisonId,
				alphaId,
				dataNormalizationId,
				useGdu,
				removeDamagedOrExcluded,
				isUniformity,
				accessToken
			)
		).then((res) => {
			//-- Reformatting data to match existing case
			_.map(res, (r) => {
				_.map(r.letteringPairs, (lp) => {
					lp.trialId = r.trialId;
					lp.trialTreatmentId = lp.treatmentId;
					lp.tukeyLetters = lp.letter;
					lp.pValue = r.pValue;
					lp.cv = r.cv;
					lp.mean = r.mean;
				});
			});

			if (!isForAllData) {
				setSingleTrialAnovaValues(res);
			}

			res.analysisId = analysisId;
			res.analysisTypeId = analysisTypeId;
			setAllSingleTrialAnovaValues((allSingleTrialAnovaValues) => [...allSingleTrialAnovaValues, res]);
		});
	}

	async function getTrialData() {
		const accessToken = await getTokenSilently();
		if (!needToUpdateAnalytics || (hideMeanComparisonResults && !needToUpdateAnalytics)) {
			setLoading(true);
		}
		return await Promise.all(
			_.map(trialIds, (id) => {
				return dispatch(
					analysisActions.getProtocolAnalysisResultsForTimecourse(
						userAuth.currentClientId,
						id,
						accessToken,
						"",
						"",
						"",
						"",
						true,
						"",
						false,
						removeOutliers,
						removeDamagedOrExcluded,
						outlierType
					)
				).then((res) => {
					//-- set the raw preset options for the protocol
					if (!rawPresetOptions) {
						//Remove Stand Count from the advanced analysis type options
						let standCountIndex = res.presetOptions.analysisTypes.findIndex((x) => x.name === "Stand Count");
						res.presetOptions.analysisTypes.splice(standCountIndex, 1);
						setRawPresetOptions(res.presetOptions);
					}

					//Filter out excluded plots and outliers
					let skipOutlierFilter = !_.some(
						res.plotAnalysisResults,
						(rd) => rd?.isOutlier3 !== undefined || rd?.isOutlier1_5 !== undefined
					);

					let filteredData = _.filter(res.plotAnalysisResults, (r) => {
						return (
							(removeDamagedOrExcluded ? r.excludeFromAssessment === false : true) &&
							(skipOutlierFilter ||
								(removeOutliers && outlierType === 3
									? r.isOutlier3 === false
									: removeOutliers && outlierType === 1.5
									? r.isOutlier1_5 === false
									: true))
						);
					});
					//-- REMOVING STAND COUNT DATA FROM TIMECOURSE PAGE
					return _.filter(filteredData, (par) => !par.analysisName?.includes("Stand Count"));
				});
			})
		)
			.then((res) => {
				let filteredTreatments = [];
				let timecourseTemp = [];
				_.filter(res, (r) => {
					let f = _.filter(r, (trial) => {
						return trial.trialTreatmentId <= reduxProtocolData.treatments;
					});
					filteredTreatments.push(f);
					timecourseTemp = [...timecourseTemp, ...f];
				});

				getSingleSiteAucMeanComparisons(timecourseTemp);

				const tempFilteredTreatments = filteredTreatments;
				//this data will be used for each of the time course components, its one large list as opposed to being split up by trial, which is probably wrong
				if (timecourseStart != null && timecourseEnd != null && !firstTimeLoad) {
					let dapTrialData = [];
					const flattenedRawTrialData = _.flatten(filteredTreatments);
					if (timecourseEnd == 0) {
						setTimecourseData(flattenedRawTrialData);
						setRawTrialData(filteredTreatments);
						return filteredTreatments;
					}

					if (!useGdu) {
						_.map(trialData, (td) => {
							let firstFlightDate =
								td.trialInfo.plantDate ??
								_.minBy(
									_.filter(flattenedRawTrialData, (x) => {
										return x.trialId === td.trialInfo.trialId;
									}),
									"flightDate"
								)?.flightDate;
							let flightDateFilteredResults = _.filter(flattenedRawTrialData, (adr) => {
								return (
									adr.trialId === td.trialInfo.trialId &&
									moment(adr.flightDate) <= moment(firstFlightDate).add(Number(timecourseEnd), "d") &&
									moment(adr.flightDate) >= moment(firstFlightDate).add(Number(timecourseStart), "d")
								);
							});
							dapTrialData = dapTrialData.concat(flightDateFilteredResults);
						});
					}

					if (useGdu) {
						_.map(trialData, (td) => {
							let trialsGDUsByDay = _.find(gduByDay, (gdu) => {
								return gdu.trialId === td.trialInfo.trialId;
							});

							let flightGDUs = areaUnderTheCurve.calculateCumulativeGdu(flattenedRawTrialData, trialsGDUsByDay.data);

							flightGDUs = _.filter(flightGDUs, (fg) => {
								return Number(fg.gdu) <= timecourseEnd && Number(fg.gdu) >= timecourseStart;
							});

							let flightDateFilteredResults = _.filter(flattenedRawTrialData, (adr) => {
								return (
									adr.trialId === td.trialInfo.trialId &&
									_.some(flightGDUs, (fg) => {
										return fg.flightId === adr.flightId;
									})
								);
							});
							dapTrialData = dapTrialData.concat(flightDateFilteredResults);
						});
					}
					setTimecourseData(dapTrialData);
				} else {
					setTimecourseData(timecourseTemp);
				}

				setRawTrialData(filteredTreatments);
				let tempAllAssessmentTypes = analysisDropDownListFunctions.getOrthoTypes(timecourseTemp);

				//-- Filter out assessment types where only 1 trial exists
				const analysesByTrial = _.uniqBy(timecourseTemp, (t) => [t.analysisId, t.trialId].join());
				tempAllAssessmentTypes = _.filter(
					tempAllAssessmentTypes,
					(at) => _.filter(analysesByTrial, (t) => t.analysisId === at.analysisid)?.length > 1
				);

				setAllAssessmentTypes(tempAllAssessmentTypes);
				setLoading(false);
				return tempFilteredTreatments;

				//once trial data has been acquired and the states are set use a useeffect to get site specific data calculateSiteData and we also get the site specific data
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				//toast.error("Failed to load trial data.");
			});
	}

	//This is to get the compiled data to recalculate on the fly when trials are unchecked
	function recalculateSiteDataWhenTrialsAreUnchecked(analysis, analysisTypeId) {
		let siteData = [];
		let plotObjects = [];
		const orthoTypeName = _.find(orthoTypeOptions, (oto) => {
			return oto.value === analysis ?? selectedOrthoTypeOption;
		})?.text;

		let filteredByAnalysisType = _.filter(allFilteredAucData, (afad) => {
			return afad.analysisTypeId === (analysisTypeId ?? selectedAnalysisTypeOption);
		});
		let aucData = [];
		_.map(filteredByAnalysisType, (aud) => {
			_.map(aud, (a) => {
				if (a.analysisId === (analysis ?? selectedOrthoTypeOption)) {
					aucData.push(a);
				}
			});
		});

		let trialAucData = [];
		let trialData = _.groupBy(aucData, "trialId");
		_.map(trialData, (data) => {
			let tempData = data;
			trialAucData.push(tempData);
		});

		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});
		//need to get rid of the duplicates
		_.map(trialAucData, (aucTrialD) => {
			let filterAucTrialD = _.filter(aucTrialD, (atd) => {
				return !_.includes(uncheckedList, atd.trialTreatmentId);
			});
			const dataNormalization = getDataNormalizationModifier(filterAucTrialD);

			let trialData = [];

			for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
				var aucTreatmentData = _.map(
					_.filter(aucTrialD, (trial) => {
						return trial.trialTreatmentId === i;
					})
				);

				if (aucTreatmentData?.length === 0) continue;

				var trialTreatmentPlotData = _.map(
					_.filter(aucTrialD, (trial) => {
						return trial.trialTreatmentId === i && trial.excluded !== true;
					}),
					(d) => {
						return {
							plotReplicate: d.plotReplicate,
							plotId: d.plotId,
							fieldId: d.fieldId,
							analysisValue: (d.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
							trialTreatmentId: d.trialTreatmentId,
							assessmentId: aucTrialD[0].analysisId,
							trialId: d.trialId,
							dataNormalizationId: selectedNormalizationOption,
							alphaId: selectedAlphaOption,
							meanComparisonId: selectedMeanComparisonTestOption,
							trialName: d.trialName,
							excludeFromAssessment: d.excludeFromAssessment,
							isOutlier3: d.isOutlier3,
							isOutlier1_5: d.isOutlier1_5
						};
					}
				);

				if (aucTrialD?.length > 0) {
					//for box and whisker plot
					_.map(aucTreatmentData, (t) => {
						const trialChecked =
							_.find(trialsChecked, (tc) => {
								return tc.trialId === aucTrialD[0].trialId;
							})?.trialChecked ?? true;
						let plotObject = {
							trialId: t.trialId,
							trialName: t.trialName,
							stateName: t.stateName,
							city: t.city,
							cooperatorName: t.cooperatorName,
							trialTreatmentId: i,
							plotReplicate: t.plotReplicate,
							average: (t.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
							excluded: false,
							//excludeFromAssessment: false,
							excludeFromAssessment: t.excludeFromAssessment,
							isOutlier3: t.isOutlier3,
							isOutlier1_5: t.isOutlier1_5,
							trialChecked: trialChecked,
							treatmentChecked:
								_.find(treatmentsChecked, (tc) => {
									return tc.trialTreatmentId === i;
								})?.treatmentChecked ?? true,
							analysisId: t.analysisId,
							analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
							flightDate: t.flightDate
						};

						plotObjects.push(plotObject);
					});

					let siteObject = {
						trialId: aucTrialD[0].trialId,
						trialName: aucTrialD[0].trialName,
						stateName: aucTrialD[0].stateName,
						city: aucTrialD[0].city,
						cooperatorName: aucTrialD[0].cooperatorName,
						trialTreatmentId: i,
						average: 0,
						standardDeviation: 0,
						excluded: true,
						trialChecked:
							_.find(trialsChecked, (tc) => {
								return tc.trialId === aucTrialD[0].trialId;
							})?.trialChecked ?? true,
						treatmentChecked:
							_.find(treatmentsChecked, (tc) => {
								return tc.trialTreatmentId === i;
							})?.treatmentChecked ?? true,
						analysisId: aucTrialD[0].analysisId,
						analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
						flightDate: aucTrialD[0].flightDate,
						plots: trialTreatmentPlotData,
						assessmentName: orthoTypeName
					};
					if (aucTreatmentData.length > 0) {
						aucTreatmentData = _.map(aucTreatmentData, (data) => {
							return (data.totalAuc / dataNormalization) * getDataNormalizationMultiplier();
						});
						const average = _.meanBy(aucTreatmentData);
						siteObject.average = average;

						const standardDeviation = std(aucTreatmentData) / average;
						siteObject.standardDeviation = standardDeviation;

						siteObject.excluded = aucTreatmentData.every((item) => item === 0) ? true : false;
					}

					trialData.push(siteObject);
				}
			}
			siteData = siteData.concat(trialData);
		});

		if (selectedAlphaOption && selectedMeanComparisonTestOption) {
			updateCompiledData(siteData, true, true);
		}
	}

	function calculateSiteData(analysis = null, analysisTypeId = null, aucDataForRecalc = null) {
		let siteData = [];
		let plotObjects = [];
		const orthoTypeName = _.find(orthoTypeOptions, (oto) => {
			return oto.value === analysis ?? selectedOrthoTypeOption;
		})?.text;

		let filteredByAnalysisType = _.filter(allFilteredAucData, (afad) => {
			return afad.analysisTypeId === (analysisTypeId ?? selectedAnalysisTypeOption);
		});
		let aucData = [];
		_.map(filteredByAnalysisType, (aud) => {
			_.map(aud, (a) => {
				if (a.analysisId === (analysis ?? selectedOrthoTypeOption)) {
					aucData.push(a);
				}
			});
		});

		let trialAucData = [];
		let trialData = _.groupBy(aucData, "trialId");
		_.map(trialData, (data) => {
			let tempData = data;
			trialAucData.push(tempData);
		});

		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});
		//need to get rid of the duplicates
		_.map(
			analysis === null && aucDataForRecalc === null
				? filteredAucData
				: analysis === null && aucDataForRecalc !== null
				? aucDataForRecalc
				: trialAucData,
			(aucTrialD) => {
				let filterAucTrialD = _.filter(aucTrialD, (atd) => {
					return !_.includes(uncheckedList, atd.trialTreatmentId);
				});
				const dataNormalization = getDataNormalizationModifier(filterAucTrialD);

				let trialData = [];

				for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
					var aucTreatmentData = _.map(
						_.filter(aucTrialD, (trial) => {
							return trial.trialTreatmentId === i;
						})
					);

					if (aucTreatmentData?.length === 0) continue;

					var trialTreatmentPlotData = _.map(
						_.filter(aucTrialD, (trial) => {
							return trial.trialTreatmentId === i && trial.excluded !== true;
						}),
						(d) => {
							return {
								plotReplicate: d.plotReplicate,
								plotId: d.plotId,
								plotName: d.plotName,
								fieldId: d.fieldId,
								analysisValue: (d.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
								trialTreatmentId: d.trialTreatmentId,
								assessmentId: aucTrialD[0].analysisId,
								trialId: d.trialId,
								dataNormalizationId: selectedNormalizationOption,
								alphaId: selectedAlphaOption,
								meanComparisonId: selectedMeanComparisonTestOption,
								trialName: d.trialName,
								excludeFromAssessment: d.excludeFromAssessment,
								isOutlier3: d.isOutlier3,
								isOutlier1_5: d.isOutlier1_5
							};
						}
					);

					if (aucTrialD?.length > 0) {
						//for box and whisker plot
						_.map(aucTreatmentData, (t) => {
							const trialChecked =
								_.find(trialsChecked, (tc) => {
									return tc.trialId === aucTrialD[0].trialId;
								})?.trialChecked ?? true;
							let plotObject = {
								trialId: t.trialId,
								trialName: t.trialName,
								stateName: t.stateName,
								city: t.city,
								cooperatorName: t?.cooperatorName,
								trialTreatmentId: i,
								plotName: t.plotName,
								plotReplicate: t.plotReplicate,
								average: (t.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
								excluded: false,
								//excludeFromAssessment: false,
								excludeFromAssessment: t.excludeFromAssessment,
								isOutlier3: t.isOutlier3,
								isOutlier1_5: t.isOutlier1_5,
								trialChecked: trialChecked,
								treatmentChecked:
									_.find(treatmentsChecked, (tc) => {
										return tc.trialTreatmentId === i;
									})?.treatmentChecked ?? true,
								analysisId: t.analysisId,
								analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
								flightDate: t.flightDate
							};

							plotObjects.push(plotObject);
						});

						let siteObject = {
							trialId: aucTrialD[0].trialId,
							trialName: aucTrialD[0].trialName,
							stateName: aucTrialD[0].stateName,
							city: aucTrialD[0].city,
							cooperatorName: aucTrialD[0].cooperatorName,
							trialTreatmentId: i,
							average: 0,
							standardDeviation: 0,
							excluded: true,
							trialChecked:
								_.find(trialsChecked, (tc) => {
									return tc.trialId === aucTrialD[0].trialId;
								})?.trialChecked ?? true,
							treatmentChecked:
								_.find(treatmentsChecked, (tc) => {
									return tc.trialTreatmentId === i;
								})?.treatmentChecked ?? true,
							analysisId: aucTrialD[0].analysisId,
							analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
							flightDate: aucTrialD[0].flightDate,
							plots: trialTreatmentPlotData,
							assessmentName: orthoTypeName
						};
						if (aucTreatmentData.length > 0) {
							aucTreatmentData = _.map(aucTreatmentData, (data) => {
								return (data.totalAuc / dataNormalization) * getDataNormalizationMultiplier();
							});
							const average = _.meanBy(aucTreatmentData);
							siteObject.average = average;

							const standardDeviation = std(aucTreatmentData) / average;
							siteObject.standardDeviation = standardDeviation;

							siteObject.excluded = aucTreatmentData.every((item) => item === 0) ? true : false;
						}

						trialData.push(siteObject);
					}
				}
				siteData = siteData.concat(trialData);
			}
		);

		let filteredData = _.filter(
			siteData,
			(sd) =>
				sd.analysisId === selectedOrthoTypeOption &&
				sd.analysisTypeId === selectedAnalysisTypeOption &&
				!isNaN(parseFloat(sd.average))
		);
		if (!_.isEmpty(filteredData) && analysis === null) {
			let filteredPlots = _.filter(
				plotObjects,
				(p) =>
					p.analysisId === selectedOrthoTypeOption &&
					p.analysisTypeId === selectedAnalysisTypeOption &&
					!isNaN(parseFloat(p.average))
			);

			setFilteredSiteSpecificData(filteredData);
			setTrialPlotSpecificData(filteredPlots);
			setTrialPlotSpecificDataCopy(filteredPlots);

			if (selectedAlphaOption && selectedMeanComparisonTestOption) {
				//now when sitespecific data is set we move on to updatecompiled data which is called from a useeffect
				updateCompiledData(filteredData, false);
			}
		}

		if (firstTimeLoad && analysis !== null && !_.isEmpty(siteData)) {
			setAllFilteredSiteSpecificData((allFilteredSiteSpecificData) => [...allFilteredSiteSpecificData, siteData]);
			setAllTrialPlotSpecificData((allTrialPlotSpecificData) => [...allTrialPlotSpecificData, plotObjects]);
			//now when sitespecific data is set we move on to updatecompiled data which is called from a useeffect
			updateCompiledData(siteData, true);
		}
	}

	//Separate recalc site data function to run when the Update Analytics button is clicked
	function reCalculateSiteData(analysis, filteredData, analysisTypeId) {
		let siteData = [];
		let plotObjects = [];
		const orthoTypeName = _.find(orthoTypeOptions, (oto) => {
			return oto.value === analysis ?? selectedOrthoTypeOption;
		})?.text;

		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});
		//need to get rid of the duplicates
		_.map(filteredData, (aucTrialD) => {
			let filterAucTrialD = _.filter(aucTrialD, (atd) => {
				return !_.includes(uncheckedList, atd.trialTreatmentId);
			});
			const dataNormalization = getDataNormalizationModifier(filterAucTrialD);

			let trialData = [];

			for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
				var aucTreatmentData = _.map(
					_.filter(aucTrialD, (trial) => {
						return trial.trialTreatmentId === i;
					})
				);

				if (aucTreatmentData?.length === 0) continue;

				var trialTreatmentPlotData = _.map(
					_.filter(aucTrialD, (trial) => {
						return trial.trialTreatmentId === i && trial.excluded !== true;
					}),
					(d) => {
						return {
							plotReplicate: d.plotReplicate,
							plotId: d.plotId,
							plotName: d.plotName,
							fieldId: d.fieldId,
							analysisValue: (d.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
							trialTreatmentId: d.trialTreatmentId,
							assessmentId: aucTrialD[0].analysisId,
							trialId: d.trialId,
							dataNormalizationId: selectedNormalizationOption,
							alphaId: selectedAlphaOption,
							meanComparisonId: selectedMeanComparisonTestOption,
							trialName: d.trialName,
							excludeFromAssessment: d.excludeFromAssessment,
							isOutlier3: d.isOutlier3,
							isOutlier1_5: d.isOutlier1_5
						};
					}
				);

				if (aucTrialD?.length > 0) {
					//for box and whisker plot
					_.map(aucTreatmentData, (t) => {
						const trialChecked =
							_.find(trialsChecked, (tc) => {
								return tc.trialId === aucTrialD[0].trialId;
							})?.trialChecked ?? true;
						let plotObject = {
							trialId: t.trialId,
							trialName: t.trialName,
							stateName: t.stateName,
							city: t.city,
							cooperatorName: t?.cooperatorName,
							trialTreatmentId: i,
							plotReplicate: t.plotReplicate,
							plotName: t.plotName,
							average: (t.totalAuc / dataNormalization) * getDataNormalizationMultiplier(),
							excluded: false,
							//excludeFromAssessment: false,
							excludeFromAssessment: t.excludeFromAssessment,
							isOutlier3: t.isOutlier3,
							isOutlier1_5: t.isOutlier1_5,
							trialChecked: trialChecked,
							treatmentChecked:
								_.find(treatmentsChecked, (tc) => {
									return tc.trialTreatmentId === i;
								})?.treatmentChecked ?? true,
							analysisId: t.analysisId,
							analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
							flightDate: t.flightDate
						};

						plotObjects.push(plotObject);
					});

					let siteObject = {
						trialId: aucTrialD[0].trialId,
						trialName: aucTrialD[0].trialName,
						stateName: aucTrialD[0].stateName,
						city: aucTrialD[0].city,
						cooperatorName: aucTrialD[0]?.cooperatorName,
						trialTreatmentId: i,
						average: 0,
						standardDeviation: 0,
						excluded: true,
						trialChecked:
							_.find(trialsChecked, (tc) => {
								return tc.trialId === aucTrialD[0].trialId;
							})?.trialChecked ?? true,
						treatmentChecked:
							_.find(treatmentsChecked, (tc) => {
								return tc.trialTreatmentId === i;
							})?.treatmentChecked ?? true,
						analysisId: aucTrialD[0].analysisId,
						analysisTypeId: analysisTypeId ?? selectedAnalysisTypeOption,
						flightDate: aucTrialD[0].flightDate,
						plots: trialTreatmentPlotData,
						assessmentName: orthoTypeName
					};
					if (aucTreatmentData.length > 0) {
						aucTreatmentData = _.map(aucTreatmentData, (data) => {
							return (data.totalAuc / dataNormalization) * getDataNormalizationMultiplier();
						});
						const average = _.meanBy(aucTreatmentData);
						siteObject.average = average;

						const standardDeviation = std(aucTreatmentData) / average;
						siteObject.standardDeviation = standardDeviation;

						siteObject.excluded = aucTreatmentData.every((item) => item === 0) ? true : false;
					}

					trialData.push(siteObject);
				}
			}
			siteData = siteData.concat(trialData);
		});

		setAllFilteredSiteSpecificData((allFilteredSiteSpecificData) => [...allFilteredSiteSpecificData, siteData]);
		setAllTrialPlotSpecificData((allTrialPlotSpecificData) => [...allTrialPlotSpecificData, plotObjects]);
		//now when sitespecific data is set we move on to updatecompiled data which is called from a useeffect
		updateCompiledData(siteData, true);
	}

	function updateCompiledData(siteData = null, isForAllData = false, isForTrialUpdate = false) {
		let averageData = [];
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedNormalizationOption
		]).text.toLowerCase();
		for (var i = 1; i <= reduxProtocolData.treatments; ++i) {
			const treatment = _.find(treatmentData, { trialTreatmentId: i });

			let treatmentDataAverages = _.filter(siteData ?? filteredSiteSpecificData, (sd) => {
				return sd.trialTreatmentId === i && sd.trialChecked !== false;
			});

			//Filter out trials with no plot data
			treatmentDataAverages = _.filter(treatmentDataAverages, (tda) => {
				return tda?.plots.length > 0;
			});

			if (treatmentDataAverages?.length > 0) {
				const averages = _.map(treatmentDataAverages, "average");
				const average = _.meanBy(averages);

				const standardDeviations = _.map(treatmentDataAverages, "standardDeviation");
				const standardDeviation = _.meanBy(standardDeviations) * 100;

				//Building a list of treatments with missing treatments in the trials. This will help determine which treatment needs an info icon
				let treatmentGroup = _.groupBy(
					_.filter(siteData ?? filteredSiteSpecificData, (fd) => {
						return fd.trialChecked !== false;
					}),
					"trialTreatmentId"
				);

				let maxNumberOfObjects = _.max(Object.values(treatmentGroup).map((a) => a.length));
				let treatmentsMissingInTrials = [];
				_.map(treatmentGroup, (tg) => {
					if (
						tg.length != maxNumberOfObjects ||
						_.some(tg, (t) => {
							return t.excluded === true;
						})
					) {
						treatmentsMissingInTrials.push(tg[0].trialTreatmentId);
					}
				});

				let compiledTreatment = {
					trialTreatmentId: i,
					treatmentTypeName: treatment.treatmentTypeName,
					treatmentName: treatment.name,
					average: average,
					standardDeviation: standardDeviation,
					treatmentChecked:
						_.find(treatmentsChecked, (tc) => {
							return tc.trialTreatmentId === i;
						})?.treatmentChecked ?? true,
					trialId: _.filter(siteData ?? filteredSiteSpecificData, (sd) => {
						return sd.trialTreatmentId === i && sd.trialChecked !== false;
					})?.trialId,
					trialName: _.filter(siteData ?? filteredSiteSpecificData, (sd) => {
						return sd.trialTreatmentId === i && sd.trialChecked !== false;
					})?.trialName,
					city: _.filter(siteData ?? filteredSiteSpecificData, (sd) => {
						return sd.trialTreatmentId === i && sd.trialChecked !== false;
					})?.city,
					stateName: _.filter(siteData ?? filteredSiteSpecificData, (sd) => {
						return sd.trialTreatmentId === i && sd.trialChecked !== false;
					})?.stateName,
					assessmentData: _.map(treatmentDataAverages, (td) => {
						return {
							plots: td.plots
						};
					}),
					flightDate: _.find(timecourseData, (tcd) => {
						return (
							tcd.trialTreatmentId === i &&
							tcd.trialId ==
								_.filter(siteData ?? filteredSiteSpecificData, (sd) => {
									return sd.trialTreatmentId === i && sd.trialChecked !== false;
								})?.trialId
						);
					})?.flightDate,
					hasMissingTreatments: treatmentsMissingInTrials.includes(i),
					analysisId: siteData !== null ? siteData[0]?.analysisId : filteredSiteSpecificData[0]?.analysisId,
					analysisTypeId: siteData !== null ? siteData[0]?.analysisTypeId : filteredSiteSpecificData[0]?.analysisTypeId
				};
				averageData.push(compiledTreatment);
			}
		}
		if (selectedDataNormalizationName === "rank order") {
			getRankOrder(averageData);
			_.map(averageData, (ad) => {
				ad.reversed = true;
			});
		}
		//this should only be set once and used as a master list
		if (!isForAllData) {
			setCompiledData(averageData);
			if (!isForTrialUpdate) {
				getCompiledTukeyData(averageData, false);
			}
		} else {
			setAllCompiledData((allCompiledData) => [...allCompiledData, averageData]);
			if (!isForTrialUpdate) {
				getCompiledTukeyData(averageData, true);
			}
		}
	}

	async function getCompiledTukeyData(averageData, isForAllData = false) {
		if (!isForAllData) {
			setTukeyRunning(true);
		}

		let allPlotData = [];

		_.map(averageData, (d) => {
			if (d.treatmentChecked && !d.hasMissingTreatments) {
				_.map(d.assessmentData, (ad) => {
					_.map(
						_.filter(ad.plots, (pl) => pl.plotId),
						(p) => {
							allPlotData.push(p);
						}
					);
				});
			}
		});

		let groupedPlotData = _.groupBy(allPlotData, "assessmentId");
		let dataToPass = [];
		_.map(groupedPlotData, (g) => {
			dataToPass.push(g);
		});

		let updatedCompiledTukeyData = _.cloneDeep(compiledTukeyData);
		let updatedCompiledTrialTukeyData = _.cloneDeep(compiledTrialTukeyData);

		if (_.uniqBy(allPlotData, "trialId")?.length > 1) {
			if (dataToPass.length > 0) {
				await Promise.all(
					_.map(dataToPass, async (plotData) => {
						const accessToken = await getTokenSilently();
						return dispatch(
							statisticsActions.getCompiledTukeyData(
								plotData,
								selectedAlphaOption,
								selectedMeanComparisonTestOption,
								userAuth.currentClientId,
								accessToken
							)
						).then((res) => {
							return res;
						});
					})
				)
					.then((res) => {
						//-- So. Many. Maps
						let condensedData = [];
						let condensedTrialData = [];
						_.map(res, (r) => {
							setupAnovaData(r.data.protocolData[0], isForAllData, averageData[0]?.analysisTypeId);
							_.map(r.data.protocolData, (pd) => {
								condensedData.push(pd);
							});
							_.map(r.data.trialData, (td) => {
								condensedTrialData.push(td);
							});
						});

						let tukeyData = _.groupBy(condensedData, "assessmentId");

						_.map(tukeyData, (td) => {
							assignCompiledTukeyLetters(updatedCompiledTukeyData, td);
						});

						if (!isForAllData) {
							setCompiledTukeyData(updatedCompiledTukeyData);

							let tukeyTrialData = _.groupBy(condensedTrialData, "assessmentId");
							_.map(tukeyTrialData, (ttd) => {
								assignCompiledTukeyLetters(updatedCompiledTrialTukeyData, ttd);
							});

							setCompiledTrialTukeyData(updatedCompiledTrialTukeyData);
							setTukeyRunning(false);
						} else {
							updatedCompiledTukeyData.analysisTypeId = averageData[0]?.analysisTypeId;
							setAllCompiledTukeyData((allCompiledTukeyData) => [...allCompiledTukeyData, updatedCompiledTukeyData]);

							let tukeyTrialData = _.groupBy(condensedTrialData, "assessmentId");
							_.map(tukeyTrialData, (ttd) => {
								assignCompiledTukeyLetters(updatedCompiledTrialTukeyData, ttd);
							});
							updatedCompiledTrialTukeyData.analysisTypeId = averageData[0]?.analysisTypeId;
							setAllCompiledTrialTukeyData((allCompiledTrialTukeyData) => [
								...allCompiledTrialTukeyData,
								updatedCompiledTrialTukeyData
							]);
						}

						setHideMeanComparisonResults(false);

						if (needToUpdateAnalytics) {
							setNeedToUpdateAnalytics(false);
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error("There was an issue getting mean comparison values for the multi-trial datasets", {
							toastId: "multiError"
						});

						if (!isForAllData) {
							setMultiTrialDatasetError(true);

							setTukeyRunning(false);
							setHideMeanComparisonResults(false);

							if (needToUpdateAnalytics) {
								setNeedToUpdateAnalytics(false);
							}
						} else {
							updatedCompiledTukeyData[averageData[0].analysisId] = null;
							updatedCompiledTukeyData.analysisTypeId = averageData[0].analysisTypeId;
							updatedCompiledTrialTukeyData[averageData[0].analysisId] = null;
							updatedCompiledTrialTukeyData.analysisTypeId = averageData[0].analysisTypeId;
							setAllCompiledTukeyData((allCompiledTukeyData) => [...allCompiledTukeyData, updatedCompiledTukeyData]);
							setAllCompiledTrialTukeyData((allCompiledTrialTukeyData) => [
								...allCompiledTrialTukeyData,
								updatedCompiledTrialTukeyData
							]);
						}
					});
			} else {
				if (!isForAllData) {
					setCompiledTukeyData(updatedCompiledTukeyData);
					setCompiledTrialTukeyData(updatedCompiledTrialTukeyData);
					setTukeyRunning(false);
				} else {
					setAllCompiledTukeyData((allCompiledTukeyData) => [...allCompiledTukeyData, updatedCompiledTukeyData]);

					setAllCompiledTrialTukeyData((allCompiledTrialTukeyData) => [
						...allCompiledTrialTukeyData,
						updatedCompiledTrialTukeyData
					]);
				}

				if (needToUpdateAnalytics) {
					setNeedToUpdateAnalytics(false);
				}
			}
		} else {
			if (!isForAllData) {
				toast.error("Cannot run multi-trial mean comparisons when only 1 trial dataset is available", {
					toastId: "singleError"
				});

				setOneTrialDatasetError(true);

				setTukeyRunning(false);

				if (needToUpdateAnalytics) {
					setNeedToUpdateAnalytics(false);
				}
			} else {
				let assessmentTypes = _.filter(
					allAssessmentTypes,
					(ast) =>
						!(ast.analysisid === averageData[0].analysisId && ast.analysistypeid === averageData[0].analysisTypeId)
				);
				let presetOptions = {
					...rawPresetOptions,
					timeSeriesPresets: _.filter(
						rawPresetOptions.timeSeriesPresets,
						(tsp) =>
							!(tsp.analysisId === averageData[0].analysisId && tsp.analysisTypeId === averageData[0].analysisTypeId)
					)
				};
				setAllAssessmentTypes(assessmentTypes);
				setRawPresetOptions(presetOptions);
			}
		}
	}

	function setupAnovaData(meanComparisonResult, isForAllData, analysisTypeId) {
		let tAnovaData = {
			numeratorDf: meanComparisonResult?.numeratorDF,
			denominatorDf: meanComparisonResult?.denominatorDF,
			fValues: meanComparisonResult?.fValues,
			pValues: meanComparisonResult?.pValues,
			analysisId: meanComparisonResult?.assessmentId,
			analysisTypeId: analysisTypeId
		};

		if (!isForAllData) {
			setAnovaData(tAnovaData);
		} else {
			setAllAnovaData((allAnovaData) => [...allAnovaData, tAnovaData]);
		}
	}

	function assignCompiledTukeyLetters(updatedCompiledData, tukeyData) {
		let tukeyDataToAdd = _.cloneDeep(tukeyData);
		if (_.every(tukeyData, (td) => td.tukeyLetters === "a")) {
			_.forEach(tukeyDataToAdd, (td) => (td.tukeyLetters = "-"));
		}

		updatedCompiledData[tukeyData[0].assessmentId] = tukeyDataToAdd;
	}

	function initializeTreatmentsChecked() {
		const tChecked = _.map(treatmentData, (td) => {
			return { trialTreatmentId: td.trialTreatmentId, treatmentChecked: true, dirty: false };
		});
		setTreatmentsChecked(tChecked);
	}

	function updateCheckedTreatment(value, trialTreatmentId) {
		const updatedTreatmentsChecked = _.map(treatmentsChecked, (tc) => {
			if (tc.trialTreatmentId === trialTreatmentId) {
				tc.treatmentChecked = value;
				tc.dirty = true;
			}

			return tc;
		});
		setTreatmentsChecked(updatedTreatmentsChecked);

		let uncheckedList = [];
		_.filter(updatedTreatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});

		let clone = _.cloneDeep(filteredTrialData);
		_.map(clone, (ftd) => {
			_.remove(ftd.treatments, (t) => {
				return _.includes(uncheckedList, t.trialTreatmentId);
			});
		});
		setFilteredTrialDataCopy(clone);

		let trialPlotSpecificDataClone = _.cloneDeep(trialPlotSpecificData);

		_.map(trialPlotSpecificDataClone, (tpsd) => {
			if (_.includes(uncheckedList, tpsd.trialTreatmentId)) {
				tpsd.treatmentChecked = false;
			} else {
				tpsd.treatmentChecked = true;
			}
		});
		setTrialPlotSpecificDataCopy(trialPlotSpecificDataClone);

		_.map(allFilteredSiteSpecificData, (afssd) => {
			_.map(afssd, (data) => {
				if (_.includes(uncheckedList, data.trialTreatmentId)) {
					data.treatmentChecked = false;
				} else {
					data.treatmentChecked = true;
				}
			});
		});

		const updatedFilteredSiteSpecificData = _.map(filteredSiteSpecificData, (fspd) => {
			if (fspd.trialTreatmentId === trialTreatmentId) {
				fspd.treatmentChecked = value;
				fspd.dirty = true;
			}

			return fspd;
		});
		updateCompiledDataChecked();
		setFilteredSiteSpecificData(updatedFilteredSiteSpecificData);
		setHideMeanComparisonResults(true);
		setNeedToUpdateAnalytics(true);
	}

	function updateTrialsChecked(value, trialId, silent = false) {
		if (!silent) {
			setTrialFilter(trialFilterOptions.find((opt) => opt.text === "Custom"));
		}

		const updatedTrialsChecked = _.map(trialsChecked, (tc) => {
			if (tc.trialId === trialId) {
				tc.trialChecked = value;
			}

			return tc;
		});

		let updatedInitChecked = initTrialsChecked;
		if (updatedInitChecked[trialId] === undefined) {
			updatedInitChecked[trialId] = value;
			setInitTrialsChecked(updatedInitChecked);
		}
		setTrialsChecked(updatedTrialsChecked);

		if (
			Object.keys(initTrialsChecked).length > 0 &&
			!updatedTrialsChecked.every((t) => t.trialChecked === updatedInitChecked[t.trialId])
		) {
			setHideUncheckedTrialData(true);
		} else {
			setHideUncheckedTrialData(false);
		}
		setNeedToUpdateAnalytics(true);
	}

	function updateCheckedSiteData() {
		if (!_.isEmpty(filteredSiteSpecificData)) {
			let updatedSiteData = _.map(filteredSiteSpecificData, (ssd) => {
				const treatment = _.find(treatmentsChecked, (tc) => {
					return tc.trialTreatmentId === ssd.trialTreatmentId;
				});

				ssd.treatmentChecked = treatment.treatmentChecked;
				return ssd;
			});

			updatedSiteData = _.map(updatedSiteData, (ssd) => {
				const trial = _.find(trialsChecked, (tc) => {
					return tc.trialId === ssd.trialId;
				});
				ssd.trialChecked = trial.trialChecked;
				return ssd;
			});
			setFilteredSiteSpecificData(updatedSiteData);
			setHideMeanComparisonResults(true);
		}
	}

	function updateCheckedTrialPlotSpecificData() {
		let updatedCheckedData = _.map(trialPlotSpecificData, (tpsd) => {
			const treatment = _.find(treatmentsChecked, (tc) => {
				return tc.trialTreatmentId === tpsd.trialTreatmentId;
			});

			tpsd.treatmentChecked = treatment.treatmentChecked;
			return tpsd;
		});

		updatedCheckedData = _.map(updatedCheckedData, (tpsd) => {
			const trial = _.find(trialsChecked, (tc) => {
				return tc.trialId === tpsd.trialId;
			});

			tpsd.trialChecked = trial.trialChecked;
			return tpsd;
		});

		const updatedAucData = _.map(aucData, (aucD) => {
			if (aucD?.length !== 0) {
				const trial = _.find(trialsChecked, (tc) => {
					return tc.trialId === aucD[0].trialId;
				});
				aucD.trialChecked = trial.trialChecked;
			}

			return aucD;
		});

		setFilteredAucData(updatedAucData);
		setTrialPlotSpecificData(updatedCheckedData);
		setTrialPlotSpecificDataCopy(updatedCheckedData);
	}

	function updateCompiledDataChecked() {
		_.map(allCompiledData, (cd) => {
			_.map(cd, (d) => {
				const treatment = _.find(treatmentsChecked, (tc) => {
					return tc.trialTreatmentId === d.trialTreatmentId;
				});

				d.treatmentChecked = treatment.treatmentChecked;
				return d;
			});
		});
	}

	function initializeTrialsChecked() {
		const tChecked = _.map(trialIds, (t) => {
			return { trialId: t, trialChecked: true };
		});
		setTrialsChecked(tChecked);
	}

	function initializeQuantifiedRegionOptions() {
		const uniqQuantifiedRegions = _.uniqBy(timecourseData, "quantifiedRegionTypeId");
		const qrTypes = _.map(uniqQuantifiedRegions, (qr) => {
			return { key: qr.quantifiedRegionTypeId, value: qr.quantifiedRegionTypeId, text: qr.quantifiedRegionTypeName };
		});

		setQuantifiedRegionTypeOptions(qrTypes);

		//-- Default Quantified Region
		const defaultQuantifiedRegion =
			_.find(qrTypes, (q) => {
				return q.text.toLowerCase() == "plot-centered with subsamples";
			})?.value ?? qrTypes[0]?.value;

		setSelectedQuantifiedRegionTypeOption(defaultQuantifiedRegion);
	}

	//-- TODO Should move this into a seperate js file

	function getRankOrder(averageData) {
		averageData = _.orderBy(averageData, ["average"], ["desc"]);
		_.map(averageData, (ad, index) => {
			ad.average = index + 1;
		});

		return _.sortBy(averageData, "trialTreatmentId");
	}

	function getDataNormalizationModifier(trialData) {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedNormalizationOption
		]).text.toLowerCase();
		switch (selectedDataNormalizationName) {
			case "% mean":
				return getPercentMean(trialData);
			case "% control":
			case "% commercial":
			case "% gsp":
				return getPercentByTreatmentType(trialData);
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getDataNormalizationMultiplier() {
		const selectedDataNormalizationName = _.find(dataNormalizationOptions, [
			"value",
			selectedNormalizationOption
		]).text.toLowerCase();

		switch (selectedDataNormalizationName) {
			case "% mean":
			case "% control":
			case "% commercial":
			case "% gsp":
				return 100;
			case "none":
			case "rank order":
			default:
				//-- No modifications to none, just display raw data
				return 1;
		}
	}

	function getPercentMean(trialData) {
		const data = _.map(
			_.filter(trialData, (td) => {
				return td.excluded !== true && td.plotAnalysisResultValue !== null;
			}),
			"totalAuc"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function getPercentByTreatmentType(trialData) {
		const treatmentTypeId = _.find(dataNormalizationOptions, (dno) => {
			return dno.value === selectedNormalizationOption;
		}).treatmenttypeid;
		const trialTreament = _.find(treatmentData, (td) => {
			return td.treatmentTypeId === treatmentTypeId;
		});
		const data = _.map(
			_.filter(trialData, (td) => {
				return td.trialTreatmentId === trialTreament?.trialTreatmentId && td.excluded !== true && td.totalAuc !== null;
			}),
			"totalAuc"
		);
		const mean = _.meanBy(data);
		return mean;
	}

	function updateSelectedAnalysis(value) {
		setSelectedOrthoTypeOption(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setSelectedQuantifiedRegionTypeOption(value);
	}

	function updateSelectedAnalysisType(value) {
		setSelectedAnalysisTypeOption(value);
	}

	function updateSelectedAssessmentName(value) {
		setSelectedAssessmentName(value);
	}

	function getLineChartData(trialsD) {
		let uncheckedList = [];
		_.filter(treatmentsChecked, (utc) => {
			if (!utc.treatmentChecked) {
				uncheckedList.push(utc.trialTreatmentId);
			}
		});

		let shouldContainHarvestFlight = !!trialsD.trialInfo.harvestDate;

		//This helps us filter out harvest flight from the line charts depending on the "End" date set for DAP
		if (trialsD.trialInfo.harvestDate && timecourseEnd !== 0 && !useGdu) {
			let firstFlightDate =
				timecourseStart === 0
					? trialsD.trialInfo.plantDate ??
					  _.minBy(
							_.filter(timecourseData, (x) => {
								return x.trialId === trialsD.trialInfo.trialId;
							}),
							"flightDate"
					  )?.flightDate
					: null;
			let endFlightDate = moment(firstFlightDate).add(Number(timecourseEnd), "d");
			shouldContainHarvestFlight = moment(trialsD.trialInfo.harvestDate) <= endFlightDate;
		}

		//This helps us filter out harvest flight from the line charts depending on the "End" date set for GDU
		if (trialsD.trialInfo.harvestDate && timecourseEnd !== 0 && useGdu) {
			let plantAndHarvestFlightValues = _.cloneDeep(timecourseData);
			const harvestTreatments = areaUnderTheCurve.addEmptyFlightForPlantOrHarvestDate(
				trialsD.trialInfo.harvestDate,
				_.filter(timecourseData, (tcd) => {
					return (
						tcd.trialId == trialsD.trialInfo.trialId &&
						tcd.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption
					);
				}),
				trialsD.treatments,
				orthoTypeOptions,
				true,
				false,
				userAuth.currentClientId
			);
			if (harvestTreatments) {
				plantAndHarvestFlightValues = plantAndHarvestFlightValues.concat(harvestTreatments);
			}

			let trialsGDUsByDay = _.find(gduByDay, (gdu) => {
				return gdu.trialId === trialsD.trialInfo.trialId;
			});

			let flightGDUs = areaUnderTheCurve.calculateCumulativeGdu(plantAndHarvestFlightValues, trialsGDUsByDay.data);
			let maxGDUValue = _.maxBy(flightGDUs, (fd) => Number(fd.gdu)).gdu;

			shouldContainHarvestFlight = timecourseData <= Number(maxGDUValue);
		}

		let lineChartData = areaUnderTheCurve.setupTrialAverages(
			_.filter(timecourseData, (tcd) => {
				return (
					tcd.trialId == trialsD.trialInfo.trialId &&
					tcd.analysisId === selectedOrthoTypeOption &&
					tcd.quantifiedRegionTypeId === selectedQuantifiedRegionTypeOption &&
					tcd.analysisTypeId === selectedAnalysisTypeOption &&
					!_.includes(uncheckedList, tcd.trialTreatmentId)
				);
			}),
			selectedOrthoTypeOption,
			0,
			trialsD,
			Number(initialTimecourseStart) === 0, //using initial so that the line charts dont get updated until the update analytics button is clicked
			shouldContainHarvestFlight,
			removeDamagedOrExcluded,
			removeOutliers,
			outlierType
		);

		return lineChartData;
	}

	return (
		<Segment basic>
			{loading ? null : (
				<>
					{orthoTypeOptions != null &&
					dataNormalizationOptions != null &&
					plannedTimingOptions != null &&
					allAssessmentTypes ? (
						<AnalysisDropDownLists
							rawPresetOptions={rawPresetOptions}
							allAssessmentTypes={allAssessmentTypes}
							quantifiedRegionTypes={quantifiedRegionTypeOptions}
							defaultQuantifiedRegion={
								_.find(rawTrialData, (r) => {
									return r.isDefaultQuantifiedRegion === true;
								})?.quantifiedRegionTypeId
							}
							updateSelectedAnalysis={updateSelectedAnalysis}
							updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
							updatedSelectedAnalysisType={updateSelectedAnalysisType}
							hideAnalyticTypes={true}
							setSelectedAssessmentName={updateSelectedAssessmentName}
							isProtocolTimecourse={true}
							isApAdmin={userAuth.isApAdmin}
							allMeanComparisonData={allCompiledTrialTukeyData}
							uniqueAnalysesCount={uniqueAnalysesCount}
							setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
							defaultSelectedAnalysisType={selectedAnalysisTypeOption}
							defaultSelectedAnalysisOption={selectedOrthoTypeOption}
							defaultSelectedQuantifiedRegionTypeOption={selectedQuantifiedRegionTypeOption}
							style={{ zIndex: 999 }}
						>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">Normalization</label>
								</Form.Field>
								<Form.Select
									id="form-assessment"
									selection
									fluid
									disabled={
										loading || tukeyRunning || allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset"
									}}
									options={filteredDataNormalizationOptions}
									onChange={(event, { value }) => {
										setHideMeanComparisonResults(true);
										setSelectedNormalizationOption(value);
										setNeedToUpdateAnalytics(true);
										setNeedToRerunNormalization(true);
									}}
									value={selectedNormalizationOption}
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">Timecourse</label>
								</Form.Field>
								<Form.Select
									id="form-assessment"
									selection
									fluid
									disabled={
										loading ||
										tukeyRunning ||
										!allTrialsHavePlantDateSet ||
										!areaUnderTheCurve.checkForGDUFormula(timecourseData[0]?.cropName) ||
										allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset"
									}}
									options={plannedTimingOptions}
									onChange={(event, { value }) => {
										//2 is currently GDU, 1 is DAP.
										value === 2 ? setUseGdu(true) : setUseGdu(false);
										setHideMeanComparisonResults(true);
										setSelectedPlannedTimingOption(value);
										setNeedToRerunNormalization(true);
										setHideLineCharts(true);
										setNeedToUpdateAnalytics(true);
									}}
									value={selectedPlannedTimingOption}
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">Start</label>
									{!allTrialsHavePlantDateSet &&
									!loading &&
									!tukeyRunning &&
									allCompiledTrialTukeyData?.length === uniqueAnalysesCount?.length
										? areaUnderTheCurve.buildGduErrorPopupForProtocol(
												areaUnderTheCurve.checkForGDUFormula(timecourseData[0]?.cropName),
												allTrialsHavePlantDateSet
										  )
										: null}
								</Form.Field>
								<Form.Input
									id="form-timcourse-start"
									type="text"
									defaultValue={timecourseStart}
									fluid
									disabled={
										loading ||
										tukeyRunning ||
										!allTrialsHavePlantDateSet ||
										allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset",
										width: "5rem"
									}}
									onBlur={(event) => {
										if (
											timecourseStart != event.target.value &&
											Number(event?.target?.value) <= Number(timecourseEnd)
										) {
											setHideMeanComparisonResults(true);
											setNeedToUpdateAnalytics(true);
											setTimecourseStart(event.target.value);
										} else if (Number(event?.target?.value) > Number(timecourseEnd)) {
											setTimecourseStart(event.target.value);
											toast.error("Start Day needs to be less than or equal to End Day");
										}
									}}
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">End</label>
								</Form.Field>
								<Form.Input
									id="form-timcourse-end"
									type="text"
									defaultValue={timecourseEnd}
									fluid
									disabled={
										loading ||
										tukeyRunning ||
										!allTrialsHavePlantDateSet ||
										allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset",
										width: "5rem"
									}}
									onBlur={(event) => {
										if (timecourseEnd != event.target.value) {
											setHideMeanComparisonResults(true);
											setTimecourseEnd(event.target.value);
											setNeedToUpdateAnalytics(true);
										}
									}}
								/>
							</div>
							<div>
								<Form.Field inline>
									<label htmlFor="form-assessment">Mean Comparison</label>
									<Popup
										content="A statistical test or p-value adjustment that evaluates differences among all pairs of treatments"
										trigger={<Icon name="info circle" link style={{ marginLeft: 5 }} />}
									/>
								</Form.Field>
								<Form.Select
									id="form-assessment"
									selection
									fluid
									options={meanComparisonOptions}
									disabled={
										loading || tukeyRunning || allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset"
									}}
									onChange={(event, { value }) => {
										setHideMeanComparisonResults(true);
										setSelectedMeanComparisonTestOption(value);
										setNeedToUpdateAnalytics(true);
										setNeedToRerunStats(true);
									}}
									value={selectedMeanComparisonTestOption}
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">Alpha</label>
								</Form.Field>
								<Form.Select
									id="form-assessment"
									selection
									fluid
									options={alphaOptions}
									disabled={
										loading || tukeyRunning || allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
									}
									style={{
										color: allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ? "gray" : "unset"
									}}
									onChange={(event, { value }) => {
										setHideMeanComparisonResults(true);
										setSelectedAlphaOption(value);
										setNeedToUpdateAnalytics(true);
										setNeedToRerunStats(true);
									}}
									value={selectedAlphaOption}
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-assessment">Remove Plot Exclusions</label>
								</Form.Field>
								<DataAnalysisCriteria
									removeDamagedOrExcluded={removeDamagedOrExcluded}
									removeOutliers={removeOutliers}
									outlierType={outlierType}
									setRemoveDamagedOrExcluded={setRemoveDamagedOrExcluded}
									setRemoveOutliers={setRemoveOutliers}
									setOutlierType={setOutlierType}
									setNeedToUpdateAnalytics={setNeedToUpdateAnalytics}
									disabled={allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length}
								/>
								<Popup
									trigger={<Icon style={{ marginLeft: 10, verticalAlign: "top" }} name="info circle" />}
									content="When toggled to the 'on' position any regions the user has previously marked as excluded will be discarded from analyses"
								/>
							</div>
							<div>
								<Form.Field>
									<label htmlFor="form-trial-filters">Trial Filters</label>
								</Form.Field>
								<Form.Select
									id="form-trial-filters"
									options={trialFilterOptions}
									loading={trialFiltersLoading}
									value={trialFilter?.value}
									onChange={(_, { value, options }) => {
										setTrialFilter(options.find((opt) => opt.value === value));
									}}
								/>
							</div>
							<div style={{ alignSelf: "end" }}>
								<Button
									primary={!hideMeanComparisonResults || !needToUpdateAnalytics}
									negative={hideMeanComparisonResults || needToUpdateAnalytics}
									content="Update Analytics"
									onClick={() => {
										if (needToUpdateAnalytics) {
											if (
												_.filter(treatmentsChecked, (tc) => tc.treatmentChecked).length < 2 ||
												_.filter(trialsChecked, (tc) => tc.trialChecked).length < 2
											) {
												toast.error("At least two treatments and two trials need to be selected");
											} else {
												setTukeyRunning(true);
												updateMeanComparisonData();
											}
										}
									}}
									disabled={
										loading ||
										tukeyRunning ||
										allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length ||
										isEditing
									}
									loading={tukeyRunning}
								/>
							</div>
						</AnalysisDropDownLists>
					) : (
						<></>
					)}
				</>
			)}
			{timecourseData?.length === 0 ? (
				<Segment style={{ width: "100%" }}>
					<p>{"We could not find data for this protocol."}</p>
				</Segment>
			) : (
				<Form>
					<Grid>
						{loading ? null : (
							<Grid.Row>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
								<Grid.Column width="4" textAlign="center">
									<h2>Compiled Protocol Data</h2>
								</Grid.Column>
								<Grid.Column width="6" verticalAlign="middle">
									<hr style={{ width: "100%" }} />
								</Grid.Column>
							</Grid.Row>
						)}

						{filteredSiteSpecificData != null &&
						filteredSiteSpecificData?.length > 0 &&
						compiledData != null &&
						compiledData.length > 0 &&
						trialPlotSpecificData != null &&
						trialPlotSpecificData.length > 0 ? (
							<Grid.Row>
								<Grid.Column width="8">
									<ProtocolDataNormalizationTable
										compiledData={compiledData}
										tukeyData={compiledTukeyData}
										disableCheckBoxes={tukeyRunning}
										selectedAssessmentOption={selectedOrthoTypeOption}
										dataNormalization={
											_.find(dataNormalizationOptions, (dno) => {
												return dno.value === selectedNormalizationOption;
											}).text
										}
										updateCheckedTreatment={updateCheckedTreatment}
										treatmentsChecked={treatmentsChecked}
										siteSpecificData={filteredSiteSpecificData}
										updateTrialsChecked={updateTrialsChecked}
										trialsChecked={trialsChecked}
										tukeySiteData={compiledTrialTukeyData}
										initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
										hideLetters={hideLetters}
										setHideLetters={setHideLetters}
										needToRerunStats={needToRerunStats}
										needToRerunNormalization={needToRerunNormalization}
										allMeanComparisonData={allCompiledTrialTukeyData}
										uniqueAnalysesCount={uniqueAnalysesCount}
										setTreatmentsChecked={setTreatmentsChecked}
										hideUncheckedTrialData={hideUncheckedTrialData}
										isForProtocolTimecoursePage={true}
										oneTrialDatasetError={oneTrialDatasetError}
										multiTrialDatasetError={multiTrialDatasetError}
									/>
								</Grid.Column>
								<Grid.Column width="8">
									<Grid.Row>
										{filteredSiteSpecificData ? (
											<ProtocolBoxWhiskerChart
												tukeyData={compiledTukeyData[selectedOrthoTypeOption]}
												tukeyRunning={tukeyRunning}
												data={_.filter(
													showAveragesChecked
														? !_.some(treatmentsChecked, (tc) => tc.treatmentChecked)
															? filteredSiteSpecificData
															: _.filter(filteredSiteSpecificData, (ssd) => {
																	return ssd.treatmentChecked && ssd.trialChecked && _.some(ssd.plots, (p) => p.plotId);
															  })
														: !_.some(treatmentsChecked, (tc) => tc.treatmentChecked)
														? trialPlotSpecificDataCopy
														: _.filter(trialPlotSpecificDataCopy, (tps) => {
																return tps.treatmentChecked && tps.trialChecked && tps.plotName;
														  })
												)}
												axisYTitle={`AUC ${selectedAssessmentName} (${
													_.find(filteredDataNormalizationOptions, { key: selectedNormalizationOption })?.text
												})`}
												chartHeight={500}
												allTreatmentData={_.filter(
													showAveragesChecked ? filteredSiteSpecificData : trialPlotSpecificDataCopy
												)}
												needToRerunStats={needToRerunStats}
												needToRerunNormalization={needToRerunNormalization}
												initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
												allTreatmentsUnchecked={!_.some(treatmentsChecked, (tc) => tc.treatmentChecked)}
												hideUncheckedTrialData={hideUncheckedTrialData}
												isCompiled={showAveragesChecked ? "averages" : "allReps"}
												disableColors={disableColors}
											/>
										) : (
											<>There is no data to display for the DAP range</>
										)}
									</Grid.Row>
									<Grid.Row>
										<Grid>
											<Grid.Column width="4">
												<Form.Radio
													checked={showAveragesChecked}
													label="Trial Averages"
													disabled={
														loading || tukeyRunning || allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
													}
													onChange={() => {
														setShowAveragesChecked(true);
													}}
												/>
												<Form.Radio
													checked={!showAveragesChecked}
													label="All Replicates"
													disabled={
														loading || tukeyRunning || allCompiledTrialTukeyData?.length !== uniqueAnalysesCount?.length
													}
													onChange={() => {
														setShowAveragesChecked(false);
													}}
												/>
												<Form.Checkbox
													value={disableColors}
													onChange={() => setDisableColors(!disableColors)}
													label="Disable Graph Colors"
													toggle
												/>
											</Grid.Column>
											<Grid.Column width="12">
												<AnovaTable
													anovaData={anovaData}
													hideData={
														tukeyRunning ||
														hideLetters ||
														needToRerunStats ||
														needToRerunNormalization ||
														hideUncheckedTrialData
													}
													wasThereAnError={multiTrialDatasetError || oneTrialDatasetError}
												/>
											</Grid.Column>
										</Grid>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
						) : (
							<></>
						)}
						{loading ? null : (
							<>
								<Grid.Row>
									<Grid.Column width="7" verticalAlign="middle">
										<hr style={{ width: "100%" }} />
									</Grid.Column>
									<Grid.Column width="2" textAlign="center">
										<h2>Trial Data</h2>
									</Grid.Column>
									<Grid.Column width="7" verticalAlign="middle">
										<hr style={{ width: "100%" }} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="16" textAlign="center">
										<h4>{`AUC ${selectedAssessmentName} (${
											_.find(filteredDataNormalizationOptions, { key: selectedNormalizationOption })?.text
										})`}</h4>
									</Grid.Column>
									<Grid.Column width="8" style={{ display: "none" }}>
										<Form.Group inline>
											<label>Perform Mean Comparisons as: </label>
											<Form.Radio
												checked={showTwoWayAnova}
												label={"Single Multi-Location Data Set"}
												disabled={loading || tukeyRunning}
												onChange={() => {
													setHideMeanComparisonResults(true);
													setShowTwoWayAnova(true);
												}}
											></Form.Radio>
											<Form.Radio
												checked={!showTwoWayAnova}
												label={"Seperate, Isolated Data Sets"}
												disabled={loading || tukeyRunning}
												onChange={() => {
													setHideMeanComparisonResults(true);
													setShowTwoWayAnova(false);
												}}
											></Form.Radio>
										</Form.Group>
									</Grid.Column>
								</Grid.Row>
							</>
						)}
						{filteredSiteSpecificData && trialInfo && filteredSiteSpecificData?.length > 0 ? (
							<div className="trialTableWrapper">
								<Grid.Row style={{ padding: 0 }}>
									<MetaTagsTable
										trialInfo={trialInfo}
										onSave={(info) => {
											const dataMap = new Map(info.map((i) => [i.trialId, i]));
											setTrialData((prev) =>
												prev.map((td) => {
													const curr = dataMap.get(td.trialInfo.trialId);
													if (curr) {
														return { ...td, trialInfo: curr };
													}
													return td;
												})
											);
											updateMetaTags(info);
										}}
										setSaveAction={setSaveAction}
										setIsDirty={setIsDirty}
										setIsEditing={setIsEditing}
									/>
								</Grid.Row>
								<Grid.Row style={{ padding: 0 }}>
									<ProtocolSiteSpecificTable
										siteSpecificData={filteredSiteSpecificData}
										tukeyData={
											showTwoWayAnova
												? compiledTrialTukeyData[selectedOrthoTypeOption]
												: _.flatten(
														_.map(singleTrialAnovaValues, (data) => {
															return data.letteringPairs;
														})
												  )
										}
										tukeyDataLoading={tukeyRunning}
										disableCheckBoxes={tukeyRunning}
										updateCheckedTrial={updateTrialsChecked}
										appenedPercent={_.find(dataNormalizationOptions, [
											"value",
											selectedNormalizationOption
										]).text.includes("%")}
										hideMeanComparisonResults={hideMeanComparisonResults}
										needToRerunNormalization={needToRerunNormalization}
										initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
										needToRerunStats={needToRerunStats}
										allMeanComparisonData={allCompiledTrialTukeyData}
										uniqueAnalysesCount={uniqueAnalysesCount}
										hideCheckAll={false}
									/>
								</Grid.Row>
							</div>
						) : null}
						<Grid.Row centered>
							<Grid.Column>
								<Grid>
									{timecourseData != null &&
									timecourseData.length > 0 &&
									trialIds &&
									filteredTrialData != null &&
									filteredTrialData.length > 0 &&
									trialsChecked != null ? (
										<>
											<Grid.Row>
												<Grid.Column width="6" verticalAlign="middle">
													<hr style={{ width: "100%" }} />
												</Grid.Column>
												<Grid.Column width="4" textAlign="center">
													<h2>Trial Growth Curves</h2>
												</Grid.Column>
												<Grid.Column width="6" verticalAlign="middle">
													<hr style={{ width: "100%" }} />
												</Grid.Column>
											</Grid.Row>
											{_.map(filteredTrialDataCopy, (trialsD) => {
												return (
													<Grid.Row key={trialsD.trialInfo.trialId}>
														<Grid.Column width="3">
															<ProtocolTimecourseSiteSpecificTable
																trialId={trialsD.trialInfo.trialId}
																trialName={trialsD.trialInfo.trialName}
																cooperatorName={trialsD.trialInfo.cooperatorName}
																farmCity={trialsD.trialInfo.farmCity}
																farmState={trialsD.trialInfo.farmState}
																clientId={userAuth.currentClientId}
																hasExclusions={trialsD.trialInfo.hasExclusions}
																hasFlaggings={trialsD.trialInfo.hasFlaggings}
																hasPartialExclusions={trialsD.trialInfo.hasPartialExclusions}
																hasPartialFlaggings={trialsD.trialInfo.hasPartialFlaggings}
																toggleMetaFactorModal={toggleMetaFactorModal}
															/>
														</Grid.Column>
														<Grid.Column width="13" style={{ marginTop: "-40px" }}>
															<Grid>
																<Grid.Column width="8">
																	<LineChart
																		data={getLineChartData(trialsD)}
																		analysisName={
																			selectedAssessmentName?.includes("Uniformity")
																				? selectedAssessmentName
																				: _.find(orthoTypeOptions, (oto) => {
																						return oto.key === selectedOrthoTypeOption;
																				  })?.text
																		}
																		gduByDay={
																			gduByDay
																				? _.filter(gduByDay, (gduList) => {
																						return gduList.trialId === trialsD.trialInfo.trialId;
																				  })[0]?.data
																				: null
																		}
																		useGDU={useGdu}
																		hideLineCharts={hideLineCharts}
																	/>
																</Grid.Column>

																<Grid.Column width="8" style={{ marginTop: "20px" }}>
																	{trialPlotSpecificDataCopy?.length > 0 ? (
																		<ProtocolBoxWhiskerChart
																			data={_.filter(trialPlotSpecificDataCopy, (ssd) => {
																				return ssd.trialId === trialsD.trialInfo.trialId;
																			})}
																			tukeyData={
																				showTwoWayAnova
																					? _.filter(compiledTrialTukeyData[selectedOrthoTypeOption], (data) => {
																							return data.trialId === trialsD.trialInfo.trialId;
																					  })
																					: _.find(singleTrialAnovaValues, (data) => {
																							return data.trialId === trialsD.trialInfo.trialId;
																					  })?.letteringPairs
																			}
																			tukeyRunning={tukeyRunning}
																			hideMeanComparisonResults={hideMeanComparisonResults}
																			needToRerunStats={needToRerunStats}
																			needToRerunNormalization={needToRerunNormalization}
																			initialGroupTrialDataCheckedCount={initialGroupTrialDataCheckedCount}
																			disableColors={disableColors}
																		/>
																	) : null}
																</Grid.Column>
															</Grid>
														</Grid.Column>
													</Grid.Row>
												);
											})}
										</>
									) : (
										<></>
									)}
									<>{loading ? <Loader active inline="centered" /> : <> </>}</>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			)}
		</Segment>
	);
};

ProtocolTimecourse.propTypes = {
	dataNormalizationOptions: PropTypes.array,
	meanComparisonOptions: PropTypes.array,
	treatmentData: PropTypes.array,
	alphaOptions: PropTypes.array,
	trialIds: PropTypes.array,
	trialAnalysisOptions: PropTypes.array,
	statelessDataNormalizationOptions: PropTypes.array,
	allMeanComparisonOptions: PropTypes.object,
	defaultMeanComparisonOption: PropTypes.string,
	defaultAlphaOption: PropTypes.string,
	setWeatherDataDone: PropTypes.func,
	toggleMetaFactorModal: PropTypes.func,
	setIsDirty: PropTypes.func,
	setSaveAction: PropTypes.func
};
export default ProtocolTimecourse;
