import * as plotApi from "../../apis/plotApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

/*
 *  Thunks
 *
 */
export function inspectPlotData(plotInfoCsv, plotFieldDataVm, accessToken) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return plotApi
				.inspectPlotData(plotInfoCsv, plotFieldDataVm, accessToken)
				.then(res => {
					if (res.errors) {
						reject(res);
						dispatch(apiCallError(res));
					} else {
						resolve(res);
					}
				})
				.catch(error => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
