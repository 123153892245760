import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

var convert = require("convert-units");

import "./styles.css";

import { supportedUnitTypes } from "./supportedUnitTypes";

const Converter = ({
	defaults = {
		unitType: null,
		unit1: null,
		unit2: null,
		unit1Value: 0,
		unit2Value: 0
	},
	onClose = null,
	onAccept = null,
	userConfigurable = true,
	changeUnit1 = true,
	twoWayConversion = true
}) => {
	const [loading, setLoading] = useState(true);
	const [localUnitTypes] = useState(supportedUnitTypes);

	const [unitTypeOptions, setUnitTypeOptions] = useState([]);
	const [unit1Options, setUnit1Options] = useState([]);
	const [unit2Options, setUnit2Options] = useState([]);

	const [unitTypeSelected, setUnitTypeSelected] = useState(null);
	const [unit1Selected, setUnit1Selected] = useState(null);
	const [unit2Selected, setUnit2Selected] = useState(null);

	const [unit1Value, setUnit1Value] = useState(defaults.unit1Value);
	const [unit2Value, setUnit2Value] = useState(defaults.unit2Value);

	useEffect(() => {
		const enabledUnitTypes = localUnitTypes.filter((ut) => ut.enabled);
		if (enabledUnitTypes.length > 0) {
			setUnitTypeOptions(() => {
				return enabledUnitTypes.map((ut) => {
					const option = {
						key: ut.unitType,
						text: ut.displayText,
						value: ut.unitType
					};

					return option;
				});
			});
		}
	}, [localUnitTypes]);

	useEffect(() => {
		if (unitTypeOptions && unitTypeOptions.length > 0) {
			let defaultValue = unitTypeOptions.length === 1 ? unitTypeOptions[0].value : null;

			if (defaults?.unitType && unitTypeOptions.map((uto) => uto.key).includes(defaults.unitType)) {
				defaultValue = defaults.unitType;
			}

			setUnitTypeSelected(defaultValue);
		}
	}, [unitTypeOptions]);

	useEffect(() => {
		if (unitTypeSelected) {
			setLoading(true);
			const units = convert().possibilities(unitTypeSelected);

			const unitOptions = units.map((u) => {
				const info = convert().describe(u);
				const option = {
					key: info.abbr,
					text: info.plural,
					value: info.abbr
				};

				return option;
			});

			setUnit1Options(unitOptions);
			setUnit2Options(unitOptions);

			let defaultUnit1 = unitOptions[0].value;
			if (defaults?.unit1 && unitOptions.map((uo) => uo.key).includes(defaults.unit1)) {
				defaultUnit1 = defaults.unit1;
			}

			setUnit1Selected(defaultUnit1);

			let defaultUnit2 = unitOptions[1].value;
			if (defaults?.unit2 && unitOptions.map((uo) => uo.key).includes(defaults.unit2)) {
				defaultUnit2 = defaults.unit2;
			}

			setUnit2Selected(defaultUnit2);

			setUnit1Value(defaults?.unit1Value ?? 0);
			setUnit2Value(defaults?.unit2Value ?? 0);

			setLoading(false);
		}
	}, [unitTypeSelected]);

	//-- Set up this way to easily change the behavior of the converter. Right now it behaves exactly like the Google converter
	useEffect(() => {
		if (unit1Value === 0 && unit2Value !== 0) {
			convertValue("unit2", unit2Value);
		} else if (unit1Value !== 0 && unit2Value === 0) {
			convertValue("unit1", unit1Value);
		} else {
			convertValue("unit1", unit1Value);
		}
	}, [unit1Selected, unit2Selected]);

	function convertValue(from, value) {
		value = String(value);
		if (unitTypeSelected && unit1Selected && unit2Selected) {
			if (from === "unit1") {
				const converted = convert(value).from(unit1Selected).to(unit2Selected);
				setUnit2Value(converted);
			} else {
				const converted = convert(value).from(unit2Selected).to(unit1Selected);
				setUnit1Value(converted);
			}
		}
	}

	return unitTypeOptions.length === 0 ? (
		<>`Please enable a unit type to do conversions`</>
	) : loading ? (
		"Loading..."
	) : (
		<Form id="unitConverterForm" widths="equal" style={{ width: 300 }}>
			<Form.Group>
				<Form.Dropdown
					label="Unit Type"
					placeholder="Unit Type"
					selection
					fluid
					style={{ textAlign: "center" }}
					options={unitTypeOptions}
					disabled={!userConfigurable}
					icon={userConfigurable && unitTypeOptions.length > 0 ? "dropdown" : null}
					onChange={(event, data) => setUnitTypeSelected(data.value)}
					value={unitTypeSelected}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Dropdown
					label="Units"
					placeholder="Unit"
					selection
					fluid
					style={{ textAlign: "center" }}
					disabled={!changeUnit1 || !unitTypeSelected}
					icon={changeUnit1 && unitTypeSelected ? "dropdown" : null}
					error={unitTypeSelected && !unit1Selected}
					options={unit1Options}
					onChange={(event, data) => {
						setUnit1Selected(data.value);
					}}
					value={unit1Selected}
				/>

				<Form.Dropdown
					label="&nbsp;"
					placeholder="Unit"
					selection
					fluid
					style={{ textAlign: "center" }}
					disabled={!userConfigurable || !unitTypeSelected}
					icon={userConfigurable && unitTypeSelected ? "dropdown" : null}
					error={unitTypeSelected && !unit2Selected}
					options={unit2Options}
					onChange={(event, data) => {
						setUnit2Selected(data.value);
					}}
					value={unit2Selected}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Field>
					<label>Values</label>
					<Input
						id="unitOneValueInput"
						type="number"
						label={{
							basic: true,
							content: unit1Selected
						}}
						labelPosition="right"
						fluid
						style={{ textAlign: "center" }}
						disabled={!unitTypeSelected}
						onChange={(event, data) => {
							setUnit1Value(data.value);
							convertValue("unit1", data.value);
						}}
						value={unit1Value}
					/>
				</Form.Field>

				<Form.Field>
					<label>&nbsp;</label>
					<Input
						id="unitTwoValueInput"
						type="number"
						label={{
							basic: true,
							content: unit2Selected,
							size: "small"
						}}
						fluid
						labelPosition="right"
						disabled={!twoWayConversion || !unitTypeSelected}
						onChange={(event, data) => {
							setUnit2Value(data.value);
							convertValue("unit2", data.value);
						}}
						value={unit2Value}
					/>
				</Form.Field>
			</Form.Group>
			{(onClose || onAccept) && (
				<Form.Group>
					{onClose && <Form.Button label="&nbsp;" content="Close" type="button" fluid onClick={() => onClose()} />}
					{onAccept && (
						<Form.Button
							label="&nbsp;"
							content="Accept"
							type="button"
							primary
							fluid
							onClick={() =>
								onAccept({
									fromUnit: unit1Selected,
									fromValue: Number(unit1Value),
									toUnit: unit2Selected,
									toValue: Number(unit2Value)
								})
							}
						/>
					)}
				</Form.Group>
			)}
		</Form>
	);
};

Converter.propTypes = {
	defaults: PropTypes.object,
	onClose: PropTypes.func,
	onAccept: PropTypes.func,
	userConfigurable: PropTypes.bool,
	changeUnit1: PropTypes.bool,
	twoWayConversion: PropTypes.bool
};

export default Converter;
