import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAuth0 } from "../../../auth/auth0";

import _ from "lodash";

import { toast } from "react-toastify";

import { Grid, Input, Button, Icon, Modal } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import * as plotActions from "../../../redux/actions/plotActions";
import * as groundDataActions from "../../../redux/actions/groundDataActions";

import PlotDataInspectionResults from "./PlotDataInspectionResults";

const InspectPlotData = ({
	trialFieldData,
	onFileSelected,
	onFileRemoved,
	onInspectionSuccessful,
	groundData,
	outlineData,
	createGroundDataSetObject
}) => {
	const dispatch = useDispatch();
	const fileInputRef = useRef();
	const { getTokenSilently } = useAuth0();

	const [trialDataFile, setTrialDataFile] = useState(null);
	const [runningTrialInspections, setRunningTrialInspections] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const [inspectionResults, setInspectionResults] = useState(null);
	const [dataOverwriteWarning, showDataOverwriteWarning] = useState(false);
	const [groundDataColumns, setGroundDataColumns] = useState([]);

	useEffect(() => {
		runInspection();
	}, [trialDataFile]);

	async function runInspection() {
		if (trialDataFile) {
			setRunningTrialInspections(true);
			const accessToken = await getTokenSilently();
			let groundDataSetsObj = groundData ? createGroundDataSetObject() : null;
			dispatch(
				groundData
					? groundDataActions.inspectGroundData(trialDataFile, groundDataSetsObj, outlineData, accessToken) //check to see if this is still going to work
					: plotActions.inspectPlotData(trialDataFile, trialFieldData, accessToken)//check to see if this is still going to work
			)
				.then((res) => {
					if (groundData) {
						let assessmentColumns = res.validations.importGroundData[0].assessmentColumns;
						//Get the property names of all of the dynamic assessment columns
						let filteredColumns = Object.getOwnPropertyNames(assessmentColumns);

						setGroundDataColumns(
							_.map(filteredColumns, (fc) => {
								return {
									name: fc,
									vr: `${fc}Vr`
								};
							})
						);
					}

					setInspectionResults(res);
					setShowResults(true);
					setRunningTrialInspections(false);
					onInspectionSuccessful(res);
				})
				.catch((error) => {
					if (error.errors && error.errors.length > 0) {
						toast.error(error.errors[0]);
					} else if (error) {
						toast.error(error);
					} else {
						toast.error("An unknown error occurred while inspecting the plot data file.");
					}
					setRunningTrialInspections(false);
				});
		}
	}

	function handleFileBrowse() {
		if (trialFieldData?.fieldHasImportedData) { 
			showDataOverwriteWarning(true);
		} else {
			fileInputRef.current.click();
		}
	}

	function fileSelectedHandler(event) {
		event.persist();

		if (event.target.files.length > 0) {
			const plotInfoCsv = event.target.files[0];
			setTrialDataFile(plotInfoCsv);
			onFileSelected(plotInfoCsv);
		}
	}

	const fileRemovedHandler = () => {
		//-- Need to set the value of the file input to null. Otherwise, the onChange event won't fire if you try selecting another file.
		document.getElementById("fieldLayoutUpload").value = null;
		setTrialDataFile(null);
		onFileRemoved();
	};

	return (
		<>
			<Grid columns="16">
				<Grid.Row columns="16" verticalAlign="bottom" style={{ padding: "unset" }}>
					<Grid.Column width={8}>
						<Input type="text" action readOnly value={trialDataFile?.name ?? ""}>
							<Button
								content="Browse..."
								labelPosition="left"
								icon="file"
								disabled={
									groundData
										? !!trialDataFile || !trialFieldData.clientId
										: !!trialDataFile ||
										  !trialFieldData.clientId ||
										  !trialFieldData.name ||
										  !trialFieldData.numOfColumns ||
										  !trialFieldData.numOfRanges
								}
								style={{ borderTopRightRadius: "unset", borderBottomRightRadius: "unset" }}
								onClick={() => {
									handleFileBrowse();
								}}
							/>
							<input style={{ borderTopLeftRadius: "unset", borderBottomLeftRadius: "unset" }} />
							<Button icon disabled={!trialDataFile} onClick={fileRemovedHandler}>
								<Icon name="trash" />
							</Button>
						</Input>
						<input
							id="fieldLayoutUpload"
							ref={fileInputRef}
							type="file"
							accept=".csv"
							hidden
							onChange={fileSelectedHandler}
						/>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 20, paddingRight: "unset" }}>
						<Modal
							size="large"
							trigger={
								<Button
									content="Inspection Results"
									positive={
										trialDataFile && !runningTrialInspections && inspectionResults && inspectionResults.areValid
									}
									negative={
										trialDataFile && !runningTrialInspections && inspectionResults && !inspectionResults.areValid
									}
									loading={runningTrialInspections}
									disabled={!trialDataFile || runningTrialInspections}
									onClick={() => setShowResults(true)}
									style={{ height: 37.8, paddingTop: 6 }}
								/>
							}
							open={showResults}
							onClose={() => setShowResults(false)}
						>
							<Modal.Header>{groundData ? "Ground Data Inspection" : "Trial Data Inspection"}</Modal.Header>
							<Modal.Content scrolling>
								<Modal.Description>
									<PlotDataInspectionResults
										inspectionResults={inspectionResults}
										groundDataColumns={groundDataColumns}
									/>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button onClick={() => setShowResults(false)}>Close</Button>
							</Modal.Actions>
						</Modal>
					</Grid.Column>
					<Grid.Column width={3} style={{ paddingLeft: 10, paddingRight: "unset" }}>
						<Button
							content="Rerun Inspection"
							loading={runningTrialInspections}
							disabled={!trialDataFile || runningTrialInspections}
							onClick={() => runInspection()}
							style={{ height: 37.8, paddingTop: 6 }}
						/>
					</Grid.Column>
				</Grid.Row>
				{!groundData && (
					<Grid.Row columns="16" verticalAlign="top" style={{ padding: "unset" }}>
						<Grid.Column width={4}>
							<Link to="/PlotInfoTemplate.csv" target="_blank" download style={{ fontSize: 12 }}>
								Field Layout Template
							</Link>
						</Grid.Column>
						<Grid.Column width={4}></Grid.Column>
					</Grid.Row>
				)}
			</Grid>
			<Modal open={dataOverwriteWarning}>
				<Modal.Header>Import Field Layout</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<p>
							Any layout information previously imported will be replaced with the data in the new file. To see current
							field layout, click the <i>Current field layout</i> link.
						</p>
						<p>
							If you would like to edit the layout for this field, download the current field layout, make your changes,
							and then import those changes.
						</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						type="button"
						floated="right"
						onClick={() => {
							showDataOverwriteWarning(false);
							fileInputRef.current.click();
						}}
						primary
					>
						Continue
					</Button>
					<Button type="button" floated="right" onClick={() => showDataOverwriteWarning(false)}>
						Cancel
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
};

InspectPlotData.propTypes = {
	trialFieldData: PropTypes.object.isRequired,
	onFileSelected: PropTypes.func.isRequired,
	onFileRemoved: PropTypes.func.isRequired,
	onInspectionSuccessful: PropTypes.func.isRequired,
	groundData: PropTypes.bool,
	outlineData: PropTypes.array,
	createGroundDataSetObject: PropTypes.func 
};

export default InspectPlotData;
