import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Segment, Dropdown, Grid, Loader, Checkbox, Divider } from "semantic-ui-react";
import _ from "lodash";

import { NEUTRAL_CORRELATION_COLOR, NEGATIVE_CORRELATION_COLOR, POSITIVE_CORRELATION_COLOR } from "./CorrelationColors";

import FullCorrelationMatrix from "./FullCorrelationMatrix";
import TimecourseCorrelationMatrix from "./TimecourseCorrelationMatrix";
import AerialLYTXCorrelationMatrix from "./AerialLYTXCorrelationMatrix";
import CorrelationLegend from "./CorrelationLegend";
import formatPlannedTiming from "./FormatPlannedTiming";
export * from "./CorrelationColors";
import * as analysisActions from "../../../../redux/actions/analysisActions";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import "./style.scss";

const CorrelationMatrix = () => {
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();

	const [pageLoading, setPageLoading] = useState(true);
	const [anchorLoading, setAnchorLoading] = useState(true);
	const [orthoLoading, setOrthoLoading] = useState(true);
	const [plotAnalysisLoading, setPlotAnalysisLoading] = useState(true);
	const [matrixLoading, setMatrixLoading] = useState(true);
	const [filteredDataLoading, setFilteredDataLoading] = useState(true);

	const [rawPresetOptions, setRawPresetOptions] = useState(null);
	const [rawData, setRawData] = useState(null);
	const [filteredData, setFilteredData] = useState(null);

	const [anchorAssessmentOptions, setAnchorAssessmentOptions] = useState(null);
	const [selectedAnchorAssessment, setSelectedAnchorAssessment] = useState(null);

	const [orthoOptions, setOrthoOptions] = useState(null);
	const [selectedOrthoOptions, setSelectedOrthoOptions] = useState([]);
	const [allOrthosSelected, setAllOrthosSelected] = useState(true);
	const [groundDataSelected, setGroundDataSelected] = useState(true);

	const [plotAnalysisOptions, setPlotAnalysisOptions] = useState(null);
	const [selectedPlotAnalysisOptions, setSelectedPlotAnalysisOptions] = useState([]);
	const [allPlotAnalysisOptionsSelected, setAllPlotAnalysisOptionsSelected] = useState(true);

	const [matrixOptions, setmatrixOptions] = useState(null);
	const [selectedMatrixType, setSelectedMatrixType] = useState(null);

	const [showParentComponent, setShowParentComponent] = useState(true);
	const [showFullMatrix, setShowFullMatrix] = useState(true);
	const [showTimecourseMatrix, setShowTimecourseMatrix] = useState(true);
	const [showAeriallytxMatrix, setShowAeriallytxMatrix] = useState(true);

	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [selectedQuantifiedRegionTypeId, setSelectedQuantifiedRegionTypeId] = useState(null);

	let noDataElement = (
		<Segment>
			<p>{"We could not find analysis data for this trial."}</p>
		</Segment>
	);

	let matrixTypes = {
		fullMatrix: "Full Matrix",
		timecourseMatrix: "Timecourse Matrix",
		aerialLYTXMatrix: "AerialLYTX Matrix"
	};

	////////////////
	// useEffects //
	////////////////

	// 1. When the page is ready, make the call to load the raw data.
	useEffect(() => {
		if (userAuth.isReady && moduleNavigation.trialId) {
			getRawData();
		}
	}, [userAuth.isReady, moduleNavigation.trialId]);

	// 2. When the raw data is returned, load the dropdown and checkbox data into their elements.
	useEffect(() => {
		if (rawData != null) {
			buildAnchorOptionsDropdownData();
			buildOrthoOptionsCheckboxData();
			buildPlotAnalysisOptionsCheckboxData();
			buildmatrixOptions();
		}
	}, [rawData]);

	// 3. Once the filter controls have been populated, apply them to the raw data to get the filtered data set.
	useEffect(() => {
		if (anchorLoading == false && orthoLoading == false && plotAnalysisLoading == false && matrixLoading == false) {
			filterRawData();
		}
	}, [
		anchorAssessmentOptions,
		orthoOptions,
		plotAnalysisOptions,
		matrixOptions,
		selectedOrthoOptions,
		selectedPlotAnalysisOptions
	]);

	useEffect(() => {
		if (filteredDataLoading == false) {
			setPageLoading(false);
		}
	}, [filteredData]);

	/////////////////////
	// Setup Functions //
	/////////////////////

	async function getRawData() {
		const accessToken = await getTokenSilently();
		dispatch(analysisActions.getCorrelationMatrixData(userAuth.currentClientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				setRawPresetOptions(res.data.presetOptions);
				determineShowableMatrices(res.data.correlationMatrix);
				//-- Setup the quantified region type options for analysis data

				let qrTypes = _.map(
					_.uniqBy(res.data.correlationMatrix.plotAnalysisCorrelationData, "quantifiedRegionTypeId"),
					(p) => {
						return { key: p.quantifiedRegionTypeId, value: p.quantifiedRegionTypeId, text: p.quantifiedRegionTypeName };
					}
				);

				setSelectedQuantifiedRegionTypeId(qrTypes[0].value);
				setQuantifiedRegionTypeOptions(qrTypes);
			})
			.catch(() => {
				toast.error("Failed to load correlation data.");
			});
	}

	// Given the results of the call to the API, determine which of the matrix types can be shown based on the data present.
	function determineShowableMatrices(apiCallResult) {
		setRawData(apiCallResult);

		// If there is no data in the response from the API, show the 'no data' segment instead of the normal parent component.
		if (
			apiCallResult.anchorAssessmentOptionsData.length == 0 &&
			apiCallResult.groundAssessmentCorrelationData.length == 0 &&
			apiCallResult.growthCurveCorrelationData.length == 0 &&
			apiCallResult.plotAnalysisCorrelationData.length == 0 &&
			apiCallResult.orthoOptionsData.length == 0
		) {
			setShowParentComponent(false);
		} else {
			setShowParentComponent(true);
		}

		// Otherwise, if there is at least some data - determine which of the matrix components we actually can show.
		if (
			apiCallResult.growthCurveCorrelationData.length == 0 &&
			apiCallResult.groundAssessmentCorrelationData.length == 0
		) {
			setShowFullMatrix(false);
		} else {
			setShowFullMatrix(true);
		}

		if (
			apiCallResult.plotAnalysisCorrelationData.length == 0 ||
			(apiCallResult.growthCurveCorrelationData.length == 0 &&
				apiCallResult.groundAssessmentCorrelationData.length == 0)
		) {
			setShowTimecourseMatrix(false);
		} else {
			setShowTimecourseMatrix(true);
		}

		if (apiCallResult.growthCurveCorrelationData.length == 0) {
			setShowAeriallytxMatrix(false);
		} else {
			setShowAeriallytxMatrix(true);
		}
	}

	function filterRawData() {
		let filteredData = _.cloneDeep(rawData);

		// Apply filters to the growth curve data.
		filteredData.growthCurveCorrelationData = rawData.growthCurveCorrelationData.filter(
			(element) => selectedOrthosIncludesAnalysisId(element.analysisId) === true
		);

		// Apply filters to the plot analysis data.
		filteredData.plotAnalysisCorrelationData = rawData.plotAnalysisCorrelationData.filter(
			(element) => selectedPlotAnalysisIncludesAnalysisId(element.analysisId) === true
		);

		filteredData = buildDisplayNameMap(filteredData);

		setFilteredData(filteredData);
		setFilteredDataLoading(false);
	}

	function selectedOrthosIncludesAnalysisId(targetAnalysisId) {
		// For each possible ortho options...
		for (let index = 0; index < selectedOrthoOptions.length; index++) {
			// If the option is checked...
			if (selectedOrthoOptions[index] == true) {
				// See if the analysis we are checking exists as an associated value for that ortho...
				for (const [analysisIds] of Object.values(orthoOptions[index])) {
					if (analysisIds.includes(targetAnalysisId)) {
						return true;
					}
				}
			}
		}

		return false;
	}

	function selectedPlotAnalysisIncludesAnalysisId(targetAnalysisId) {
		for (let index = 0; index < selectedPlotAnalysisOptions.length; index++) {
			if (selectedPlotAnalysisOptions[index] == true) {
				if (plotAnalysisOptions[index].value == targetAnalysisId) {
					return true;
				}
			}
		}
		return false;
	}

	function buildAnchorOptionsDropdownData() {
		let anchorOptionsDropdownData = [];

		// Format the received Growth Curve Assessment options from the API.
		for (const [key, value] of Object.entries(rawData.anchorAssessmentOptionsData.options)) {
			const displayName = _.find(
				rawPresetOptions.curveModelAnalysisDisplayNames,
				(dn) =>
					dn.primaryCurveModelAnalysisId === value[0].curveModelAnalysisId &&
					dn.analysisId === value[0].analysisId &&
					dn.analysisTypeId === value[0].analysisTypeId &&
					(dn.primaryQuantifiedRegionTypeId === value[0].quantifiedRegionTypeId ||
						dn.secondaryQuantifiedRegionTypeId === value[0].quantifiedRegionTypeId)
			);

			if (displayName) {
				const text = `${displayName.name} (${value[0].curveModelName})`;
				anchorOptionsDropdownData.push({ key: key, text: text, value: key });
			}
		}

		// For each dataset...
		const groundDataSetGroupedData = _.groupBy(rawData.groundAssessmentCorrelationData, "groundDatasetId");
		_.forEach(groundDataSetGroupedData, (groundDatasetGroup) => {
			// Build a distinct list of the assessment ids which are averaged in another assessment in this dataset.
			const averagedAssessmentIds = _(groundDatasetGroup)
				.filter((obj) => {
					return obj["averageFor"] != null;
				})
				.uniqBy((obj) => {
					return obj["averageFor"];
				})
				.map("averageFor")
				.value();

			// For each ground data assessment in the dataset...
			let assessmentGroupedData = _.groupBy(groundDatasetGroup, "groundDataAssessmentId");
			_.forEach(assessmentGroupedData, (assessmentGroup) => {
				let firstAssessmentRecordInGroup = assessmentGroup[0];
				// If the Id for this assessment does not appear in the averageFor field of another assessment on this dataset, show it.
				if (!_.includes(averagedAssessmentIds, firstAssessmentRecordInGroup["groundDataAssessmentId"])) {
					let values = [];
					assessmentGroup.forEach((obj) => {
						values.push({
							plotRange: obj.plotRange,
							plotColumn: obj.plotColumn,
							value: obj.value,
							plotId: obj.plotId,
							excludeFromAssessment: obj.excludeFromAssessment
						});
					});

					// Append on the Ground Data Assessment options.
					let display = formatPlannedTiming(
						firstAssessmentRecordInGroup["assessmentName"],
						firstAssessmentRecordInGroup["timingMethodType"],
						firstAssessmentRecordInGroup["timingMethodName"],
						firstAssessmentRecordInGroup["growthStageName"],
						firstAssessmentRecordInGroup["growthPhaseInteger"],
						firstAssessmentRecordInGroup["growthPhaseStart"],
						firstAssessmentRecordInGroup["growthPhaseEnd"]
					);

					anchorOptionsDropdownData.push({ key: display, text: display, value: display });

					// For this assessment, assign all of the corresponding values as the resulting data when this assessment is selected.
					rawData.anchorAssessmentOptionsData.options[display] = values;
				}
			});
		});

		setAnchorAssessmentOptions(anchorOptionsDropdownData);

		// Find the index of the first element with "Adjusted Yield" in the name.
		let adjustedYieldElementIndex = _.findIndex(anchorOptionsDropdownData, function (option) {
			return option.value.includes("Adjusted Yield");
		});

		// IF there was an anchor assessment for adjusted yield, set it's index as selected.
		if (adjustedYieldElementIndex != -1) {
			setSelectedAnchorAssessment(anchorOptionsDropdownData[adjustedYieldElementIndex]?.value);
		}
		// Otherwise, just use the first option as the default.
		else {
			setSelectedAnchorAssessment(anchorOptionsDropdownData[0]?.value);
		}

		setAnchorLoading(false);
	}

	function buildOrthoOptionsCheckboxData() {
		let orthoOptionsCheckboxData = [];
		for (const [displayName, analysisIds] of Object.entries(rawData.orthoOptionsData.options)) {
			orthoOptionsCheckboxData.push({ name: displayName, value: analysisIds });
		}
		setOrthoOptions(orthoOptionsCheckboxData);
		setSelectedOrthoOptions(new Array(orthoOptionsCheckboxData.length).fill(true));
		setOrthoLoading(false);
	}

	function buildPlotAnalysisOptionsCheckboxData() {
		let plotAnalysisOptionsCheckboxData = [];

		const analysisGroupedPlotAnalysisRecords = _.groupBy(rawData.plotAnalysisCorrelationData, "analysisId");
		_.forEach(analysisGroupedPlotAnalysisRecords, (plotAnalysisGroup) => {
			const firstRecordInGroup = plotAnalysisGroup[0];
			plotAnalysisOptionsCheckboxData.push({
				name: firstRecordInGroup.analysisName,
				value: firstRecordInGroup.analysisId
			});
		});

		setPlotAnalysisOptions(plotAnalysisOptionsCheckboxData);
		setSelectedPlotAnalysisOptions(new Array(plotAnalysisOptionsCheckboxData.length).fill(true));
		setPlotAnalysisLoading(false);
	}

	function buildmatrixOptions() {
		let matrixOptionsDropdownData = [
			{ key: "Full Matrix", text: "Full Matrix", value: matrixTypes.fullMatrix },
			{ key: "Timecourse Matrix", text: "Timecourse", value: matrixTypes.timecourseMatrix },
			{ key: "AerialLYTX Matrix", text: "AerialLYTX", value: matrixTypes.aerialLYTXMatrix }
		];

		setmatrixOptions(matrixOptionsDropdownData);
		setSelectedMatrixType(matrixOptionsDropdownData[0].value);
		setMatrixLoading(false);
	}

	function buildDisplayNameMap(fd) {
		const growthCurveCorrelationData = _.map(fd.growthCurveCorrelationData, (fdgcc) => {
			const displayName = _.find(
				rawPresetOptions.curveModelAnalysisDisplayNames,
				(dn) =>
					dn.primaryCurveModelAnalysisId === fdgcc.curveModelAnalysisId &&
					dn.analysisId === fdgcc.analysisId &&
					dn.analysisTypeId === fdgcc.analysisTypeId &&
					(dn.primaryQuantifiedRegionTypeId === fdgcc.quantifiedRegionTypeId ||
						dn.secondaryQuantifiedRegionTypeId === fdgcc.quantifiedRegionTypeId)
			);

			if (displayName) {
				fdgcc.curveModelDisplayName = `${displayName.name} (${fdgcc.curveModelName})`;
				fdgcc.order = displayName.order;
			}

			return fdgcc;
		});

		fd.growthCurveCorrelationData = growthCurveCorrelationData;

		return fd;
		//setFilteredData(temp);
		// growthCurveAnalyses = _.sortBy(
		// 	growthCurveAnalyses,
		// 	[(opt) => opt.order || Infinity, "curveModelName"],
		// 	["desc"]
		// );
	}

	////////////////////////
	// Component Creation //
	////////////////////////

	function getMatrixToRender() {
		let matrixToRender;

		// FULL MATRIX
		if (selectedMatrixType == matrixTypes.fullMatrix) {
			if (showFullMatrix) {
				matrixToRender = (
					<FullCorrelationMatrix
						growthCurveData={filteredData.growthCurveCorrelationData}
						groundData={filteredData.groundAssessmentCorrelationData}
						groundDataSelected={groundDataSelected}
						selectedAnchor={selectedAnchorAssessment}
						colors={{
							positiveColor: POSITIVE_CORRELATION_COLOR,
							negativeColor: NEGATIVE_CORRELATION_COLOR,
							neutralColor: NEUTRAL_CORRELATION_COLOR
						}}
					></FullCorrelationMatrix>
				);
			} else {
				matrixToRender = noDataElement;
			}
		}
		// TIMECOURSE MATRIX
		else if (selectedMatrixType == matrixTypes.timecourseMatrix) {
			if (showTimecourseMatrix) {
				let targetAnchorAssessmentData = rawData.anchorAssessmentOptionsData.options[selectedAnchorAssessment];
				matrixToRender = (
					<TimecourseCorrelationMatrix
						plotAnalysisData={filteredData.plotAnalysisCorrelationData}
						anchorAssessmentData={targetAnchorAssessmentData}
						colors={{
							positiveColor: POSITIVE_CORRELATION_COLOR,
							negativeColor: NEGATIVE_CORRELATION_COLOR,
							neutralColor: NEUTRAL_CORRELATION_COLOR
						}}
						selectedQuantifiedRegionTypeId={selectedQuantifiedRegionTypeId}
					></TimecourseCorrelationMatrix>
				);
			} else {
				matrixToRender = noDataElement;
			}
		}
		// AERIALYTX MATRIX
		else if (selectedMatrixType == matrixTypes.aerialLYTXMatrix) {
			if (showAeriallytxMatrix) {
				let targetAnchorAssessmentData = rawData.anchorAssessmentOptionsData.options[selectedAnchorAssessment];
				matrixToRender = (
					<AerialLYTXCorrelationMatrix
						growthCurveData={filteredData.growthCurveCorrelationData}
						anchorAssessmentData={targetAnchorAssessmentData}
						colors={{
							positiveColor: POSITIVE_CORRELATION_COLOR,
							negativeColor: NEGATIVE_CORRELATION_COLOR,
							neutralColor: NEUTRAL_CORRELATION_COLOR
						}}
					></AerialLYTXCorrelationMatrix>
				);
			} else {
				matrixToRender = noDataElement;
			}
		}

		return matrixToRender;
	}

	// TODO: Define const for matrix types (as string)
	function getCheckboxListToRender(selectedMatrixType) {
		// FULL MATRIX
		if (selectedMatrixType == matrixTypes.fullMatrix) {
			return getFullMatrixCheckboxListToRender(showFullMatrix);
		}
		// TIME COURSE MATRIX
		else if (selectedMatrixType == matrixTypes.timecourseMatrix) {
			return getTimeCourseMatrixCheckboxListToRender(showTimecourseMatrix);
		}
		// AERIALYTX MATRIX
		else if (selectedMatrixType == matrixTypes.aerialLYTXMatrix) {
			return getAerialLytxMatrixCheckboxListToRender(showAeriallytxMatrix);
		}
	}

	function getAerialLytxMatrixCheckboxListToRender(enabled) {
		let checkboxList = [];

		if (enabled) {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allOrthosSelected}
					onChange={() => {
						const updatedSelection = new Array(orthoOptions.length).fill(!allOrthosSelected);
						setAllOrthosSelected(!allOrthosSelected);
						setGroundDataSelected(!allOrthosSelected);
						setSelectedOrthoOptions(updatedSelection);
					}}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			orthoOptions.map(({ name }, index) => {
				checkboxList.push(
					<Checkbox
						key={"checkbox-" + index}
						label={name}
						checked={selectedOrthoOptions[index]}
						onChange={() => {
							const updatedSelection = selectedOrthoOptions.map((elementChecked, subIndex) => {
								return subIndex === index ? !elementChecked : elementChecked;
							});
							setSelectedOrthoOptions(updatedSelection);
						}}
						style={{ display: "block" }}
					></Checkbox>
				);
			});

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		} else {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allOrthosSelected}
					disabled={true}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		}
	}

	function getFullMatrixCheckboxListToRender(enabled) {
		let checkboxList = [];

		if (enabled) {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allOrthosSelected}
					onChange={() => {
						const updatedSelection = new Array(orthoOptions.length).fill(!allOrthosSelected);
						setAllOrthosSelected(!allOrthosSelected);
						setGroundDataSelected(!allOrthosSelected);
						setSelectedOrthoOptions(updatedSelection);
					}}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			orthoOptions.map(({ name }, index) => {
				checkboxList.push(
					<Checkbox
						key={"checkbox-" + index}
						label={name}
						checked={selectedOrthoOptions[index]}
						onChange={() => {
							const updatedSelection = selectedOrthoOptions.map((elementChecked, subIndex) => {
								return subIndex === index ? !elementChecked : elementChecked;
							});
							setSelectedOrthoOptions(updatedSelection);
						}}
						style={{ display: "block" }}
					></Checkbox>
				);
			});

			checkboxList.push(
				<Checkbox
					key={"ground-data-checkbox"}
					label={"Ground Data"}
					checked={groundDataSelected}
					onChange={() => {
						const updatedSelection = !groundDataSelected;
						setGroundDataSelected(updatedSelection);
					}}
					style={{ display: "block" }}
				></Checkbox>
			);

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		} else {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allOrthosSelected}
					disabled={true}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		}
	}

	function getTimeCourseMatrixCheckboxListToRender(enabled) {
		let checkboxList = [];

		if (enabled) {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allPlotAnalysisOptionsSelected}
					onChange={() => {
						const updatedSelection = new Array(plotAnalysisOptions.length).fill(!allPlotAnalysisOptionsSelected);
						setAllPlotAnalysisOptionsSelected(!allPlotAnalysisOptionsSelected);
						setSelectedPlotAnalysisOptions(updatedSelection);
					}}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			plotAnalysisOptions.map(({ name }, index) => {
				checkboxList.push(
					<Checkbox
						key={"checkbox-" + index}
						label={name}
						checked={selectedPlotAnalysisOptions[index]}
						onChange={() => {
							const updatedSelection = selectedPlotAnalysisOptions.map((elementChecked, subIndex) => {
								return subIndex === index ? !elementChecked : elementChecked;
							});
							setSelectedPlotAnalysisOptions(updatedSelection);
						}}
						style={{ display: "block" }}
					></Checkbox>
				);
			});

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		} else {
			checkboxList.push(
				<Checkbox
					key={"all-checkbox"}
					label={"Check/Uncheck All"}
					checked={allPlotAnalysisOptionsSelected}
					disabled={true}
				></Checkbox>
			);

			checkboxList.push(<Divider key="checkbox-divider"></Divider>);

			return <div style={{ overflow: "auto" }}>{checkboxList}</div>;
		}
	}

	function getAnchorAssessmentDropdownToRender() {
		let enabledDropdown = (
			<>
				<label htmlFor="dropdown-select-anchor-assessment" style={{ display: "unset" }}>
					Anchor Assessment
				</label>
				<Dropdown
					id="dropdown-select-anchor-assessment"
					fluid
					selection
					onChange={(event, { value }) => {
						setSelectedAnchorAssessment(value);
					}}
					options={anchorAssessmentOptions}
					defaultValue={selectedAnchorAssessment}
				/>
			</>
		);

		let disabledDropdown = (
			<>
				<label htmlFor="dropdown-select-anchor-assessment" style={{ display: "unset" }}>
					Anchor Assessment
				</label>
				<Dropdown
					id="dropdown-select-anchor-assessment"
					fluid
					selection
					disabled={true}
					options={anchorAssessmentOptions}
					defaultValue={selectedAnchorAssessment}
				/>
			</>
		);

		if (selectedMatrixType == matrixTypes.fullMatrix) {
			return showFullMatrix ? enabledDropdown : disabledDropdown;
		} else if (selectedMatrixType == matrixTypes.timecourseMatrix) {
			return showTimecourseMatrix ? enabledDropdown : disabledDropdown;
		} else if (selectedMatrixType == matrixTypes.aerialLYTXMatrix) {
			return showAeriallytxMatrix ? enabledDropdown : disabledDropdown;
		} else {
			return disabledDropdown;
		}
	}

	return (
		<>
			{showParentComponent ? (
				<Segment basic>
					{pageLoading ? (
						<Loader active={true} />
					) : (
						<Grid>
							<Grid.Row centered={true}>
								<Grid.Column width="2"></Grid.Column>

								{/* Anchor Assessment Dropdown */}
								<Grid.Column width="3">{getAnchorAssessmentDropdownToRender()}</Grid.Column>

								{/* Matrix Type Dropdown */}
								<Grid.Column width="3">
									<label htmlFor="dropdown-select-matrix-type" style={{ display: "unset" }}>
										Matrix Type
									</label>
									<Dropdown
										id="dropdown-select-matrix-type"
										selection
										fluid
										onChange={(event, { value }) => {
											setSelectedMatrixType(value);
										}}
										options={matrixOptions}
										defaultValue={selectedMatrixType}
									/>
								</Grid.Column>
								{selectedMatrixType === matrixTypes.timecourseMatrix && (
									<Grid.Column width="3">
										<label htmlFor="dropdown-select-quantified-region-type" style={{ display: "unset" }}>
											Quantified Region Type
										</label>
										<Dropdown
											id="dropdown-select-quantified-region-type"
											selection
											fluid
											options={quantifiedRegionTypeOptions}
											value={selectedQuantifiedRegionTypeId}
											onChange={(event, { value }) => {
												setSelectedQuantifiedRegionTypeId(value);
											}}
										/>
									</Grid.Column>
								)}

								{/* Correlation Legend */}
								<Grid.Column width="3">
									<CorrelationLegend></CorrelationLegend>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row centered={true}>
								{/* Ortho Checkbox List */}
								<Grid.Column width="2">{getCheckboxListToRender(selectedMatrixType)}</Grid.Column>

								{/* Correlation Matrix Table */}
								<Grid.Column width="14" style={{ display: "grid", padding: "0px" }}>
									{getMatrixToRender()}
								</Grid.Column>
							</Grid.Row>
						</Grid>
					)}
				</Segment>
			) : (
				noDataElement
			)}
		</>
	);
};

export default CorrelationMatrix;
