export const ApplicationArea = {
	HomeOverview: "HomeOverview",
	HomeFavorites: "HomeFavorites",
	HomeRecentlyUpdated: "HomeRecentlyUpdated",
	HomeRecentlyPublished: "HomeRecentlyPublished",
	HomeBulkPublishing: "HomeBulkPublishing",
	FlightSearch: "FlightSearch",
	FlightNew: "FlightNew",
	FlightViewer: "FlightViewer",
	FlightInfo: "FlightInfo",
	FlightEdit: "FlightEdit",
	FlightOrthoUpload: "FlightOrthoUpload",
	FlightGenerateOrtho: "FlightGenerateOrtho",
	FlightDownloadOrtho: "FlightDownloadOrtho",
	FlightAlignFields: "FlightAlignFields",
	FlightAddNewFields: "FlightAddNewFields",
	FlightPlotAnalysis: "FlightPlotAnalysis",
	FlightPlotAnalysisCRUD: "FlightPlotAnalysisCRUD",
	FieldSearch: "FieldSearch",
	FieldNew: "FieldNew",
	FieldEdit: "FieldEdit",
	FieldViewer: "FieldViewer",
	FieldHeatMap: "FieldHeatMap",
	FieldVisualHeatmapValues: "FieldVisualHeatmapValues",
	FieldOutline: "FieldOutline",
	FieldGroundData: "FieldGroundData",
	TrialSearch: "TrialSearch",
	TrialPublishing: "TrialPublishing",
	TrialViewer: "TrialViewer",
	TrialEdit: "TrialEdit",
	TrialHeatMap: "TrialHeatMap",
	TrialVisualHeatmapShading: "TrialVisualHeatmapShading",
	TrialVisualHeatmapValues: "TrialVisualHeatmapValues",
	TrialExportFunctionality: "TrialExportFunctionality",
	TrialDamagePlots: "TrialDamagePlots",
	TrialDamagePlotsCRUD: "TrialDamagePlotsCRUD",
	TrialExcludePlots: "TrialExcludePlots",
	TrialExcludePlotsCRUD: "TrialExcludePlotsCRUD",
	TrialFlagPlots: "TrialFlagPlots",
	TrialAnalysis: "TrialAnalysis",
	TrialAnalysisTimecourse: "TrialAnalysisTimecourse",
	TrialAnalysisBoxWhisker: "TrialAnalysisBoxWhisker",
	TrialAnalysisSummaryTable: "TrialAnalysisSummaryTable",
	TrialAnalysisCorrelationMatrix: "TrialAnalysisCorrelationMatrix",
	TrialAnalysisCorrelationPlots: "TrialAnalysisCorrelationPlots",
	TrialOutline: "TrialOutline",
	TrialGroundData: "TrialGroundData",
	TrialGroundDataCRUD: "TrialGroundDataCRUD",
	TrialGroundDataTemplate: "TrialGroundDataTemplate",
	TrialCurveModels: "TrialCurveModels",
	TrialCurveModelsCRUD: "TrialCurveModelsCRUD",
	TrialWeather: "TrialWeather",
	ProtocolSearch: "ProtocolSearch",
	ProtocolNew: "ProtocolNew",
	ProtocolEdit: "ProtocolEdit",
	ProtocolOverview: "ProtocolOverview",
	ProtocolAnalysis: "ProtocolAnalysis",
	ClientSettings: "ClientSettings",
	ApSettings: "ApSettings",
	ExcludeAnnotation: "ExcludeAnnotation"
};