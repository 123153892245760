// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input:read-only {\n\topacity: 0.5;\n}\n\n#form-date-time-picker-plant-date_input,\n#form-date-time-picker-harvest-date_input {\n\tborder: unset;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Fields/NewEditField/style.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;;CAEC,aAAa;AACd","sourcesContent":["input:read-only {\n\topacity: 0.5;\n}\n\n#form-date-time-picker-plant-date_input,\n#form-date-time-picker-harvest-date_input {\n\tborder: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-date-time-picker-plant-date_input": "form-date-time-picker-plant-date_input",
	"form-date-time-picker-harvest-date_input": "form-date-time-picker-harvest-date_input"
};
export default ___CSS_LOADER_EXPORT___;
