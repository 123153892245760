import * as types from "./actionTypes";
import * as userLicenseApi from "../../apis/userLicenseApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function getUserLicensesSuccess() {
	return { type: types.LICENSES_GET_USER_LICENSES };
}

export function addUserLicenseSuccess() {
	return { type: types.LICENSES_ADD_USER_LICENSE };
}

export function updateUserLicenseSuccess() {
	return { type: types.LICENSES_UPDATE_USER_LICENSE };
}

export function removeUserLicenseSuccess() {
	return { type: types.LICENSES_REMOVE_USER_LICENSE };
}

/*
 *  Thunks
 *
 */
export function getUserLicenses(accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userLicenseApi
				.getUserLicenses(accessToken)
				.then((res) => {
					dispatch(getUserLicensesSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function addUserLicense(accessToken, userLicense) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userLicenseApi
				.addUserLicense(accessToken, userLicense)
				.then((res) => {
					dispatch(addUserLicenseSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateUserLicense(accessToken, userLicense) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userLicenseApi
				.updateUserLicense(accessToken, userLicense)
				.then((res) => {
					dispatch(updateUserLicenseSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function removeUserLicense(accessToken, userLicenseId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return userLicenseApi
				.removeUserLicense(accessToken, userLicenseId)
				.then((res) => {
					dispatch(removeUserLicenseSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
