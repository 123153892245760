import * as types from "./actionTypes";
import * as flightsPlotsApi from "../../apis/flightsPlotsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */

export function getExistingFlightsPlotsInFlightSuccess() {
	return { type: types.FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FLIGHT };
}

export function getExistingFlightsPlotsInFieldSuccess() {
	return { type: types.FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FIELD };
}

export function getExistingFlightsPlotsInTrialSuccess() {
	return { type: types.FP_GET_EXISTING_FLIGHTS_PLOTS_IN_TRIAL };
}

export function createFlightsPlotsSuccess() {
	return { type: types.FP_CREATE_FLIGHTS_PLOTS };
}

export function updateFlightsPlotsSuccess() {
	return { type: types.FP_UPDATE_FLIGHTS_PLOTS_SUCCESS };
}

export function getRecutParametersSuccess() {
	return { type: types.FP_GET_RECUT_PARAMETERS_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function getExistingFlightsPlotsInFlight(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.getExistingFlightsPlotsInFlight(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(getExistingFlightsPlotsInFlightSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.getExistingFlightsPlotsInField(flightId, fieldId, clientId, accessToken)
				.then((res) => {
					dispatch(getExistingFlightsPlotsInFieldSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getExistingFlightsPlotsInTrial(flightId, trialId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.getExistingFlightsPlotsInTrial(flightId, trialId, clientId, accessToken)
				.then((res) => {
					dispatch(getExistingFlightsPlotsInTrialSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function createFlightsPlots(plotsToAdd, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.createFlightsPlots(plotsToAdd, accessToken)
				.then((res) => {
					dispatch(createFlightsPlotsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function updateFlightsPlots(plotsToUpdate, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.updateFlightsPlots(plotsToUpdate, clientId, accessToken)
				.then((res) => {
					dispatch(updateFlightsPlotsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getRecutParameters(flightId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return flightsPlotsApi
				.getRecutParameters(flightId, clientId, accessToken)
				.then((res) => {
					dispatch(getRecutParametersSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
